<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Create Multiple Invoices</h2>
          </div>
          <div class="idb-block-content">
            <div class="form-row">
              <div class="form-group col-md-2" :class="{ invalid: $v.from.$error }">
                <label for="to" class="required">From</label>
                <vuejsDatepicker
                  name="from"
                  id="from"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :disabled-dates="{
                    from: this.to
                    }"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="$v.from.$model"
                  @input="clearLoad"
                ></vuejsDatepicker>
                <validation-messages v-model="$v.from" name="from"></validation-messages>
              </div>
              <div class="form-group col-md-2" :class="{ invalid: $v.to.$error }">
                <label for="to" class="required">To</label>
                <vuejsDatepicker
                  name="to"
                  id="to"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :disabled-dates="{
                    to: this.from
                    }"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="$v.to.$model"
                  @input="clearLoad"
                ></vuejsDatepicker>
                <validation-messages v-model="$v.to" name="from"></validation-messages>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click="checkValidation() && load()"
              :disabled="isLoading"
            >Load Invoice details</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loaded">
      <div class="row">
        <div class="col-md-12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>Invoices</h2>
            </div>
            <div class="idb-block-content">
              <div class="row">
                <div class="col-md-12">
                  <vue-good-table
                    :rows="invoices"
                    :columns="columns"
                    :pagination-options="{
                      enabled:true,
                      jumpFirstOrLast:true,
                    }"
                    :sort-options="{
                      enabled: true,
                      initialSortBy: {field: 'total', type: 'desc'}
                    }"
                    styleClass="vgt-table striped bordered"
                    ref="invoiceTable"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span
                        v-if="props.column.field !== 'name' && props.column.field !== 'actions'"
                        :class="tdClass(props.row, props.column.field)"
                      >{{props.formattedRow[props.column.field]}}</span>
                      <span v-else-if="props.column.field === 'actions'">
                        <button
                          class="btn btn-link"
                          type="button"
                          @click.prevent="details(props.row,$event)"
                        >Details</button>
                      </span>
                      <span v-else>{{props.formattedRow[props.column.field]}}</span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
              <div class="row"></div>
            </div>
            <div class="idb-block-footer">
              <div class="form-inline">
                <button
                  type="submit"
                  class="btn btn-success"
                  @click="openSubmitModal()"
                  :disabled="isLoading"
                >Create Invoices</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <invoice-submit-modal ref="modal" v-on:submit="submit"></invoice-submit-modal>
    </div>
  </div>
</template>

<script>
// Third Party
import moment from 'moment'
import axios from 'axios'

// Components
import InvoiceSubmitModal from '@/Components/Platform/Customer/Invoicing/InvoiceSubmitModal'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'
import invoicingMixin from '@/Components/Platform/Customer/Invoicing/invoicingMixin'

// Validations
import {
  required
} from 'vuelidate/lib/validators'

export default {
  name: 'CreateMultipleInvoices',
  mixins: [loading, invoicingMixin],
  components: {
    InvoiceSubmitModal
  },
  computed: {
    requestData () {
      return {
        fromDate: this.from,
        toDate: this.to
      }
    }
  },
  data () {
    return {
      from: null,
      to: null,
      loaded: false,
      invoices: [],
      shouldLoad: false,
      columns: [
        {
          label: 'Client Name',
          field: 'clientName',
          sortable: true
        },
        {
          label: 'Amount (£)',
          field: 'amount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },
        {
          label: 'Service Charges (£)',
          field: 'serviceCharge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },
        {
          label: 'VAT (£)',
          field: 'vatAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },
        {
          label: 'Total',
          field: 'total',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        }
      ]
    }
  },
  methods: {
    async load () {
      try {
        this.$snapbar.hide()
        this.loaded = false
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/Multiple`,
          {
            params: {
              ...this.requestData
            },
            showload: true,
            showerror: true,
            errormessage: 'Invoices Failed to load'
          })

        this.invoices = response.data

        this.loaded = true
      } catch { }
    },
    openSubmitModal () {
      this.$refs.modal.$refs.invoiceSubmit.show()
    },
    async submit (invoiceDate, invoiceComment) {
      try {
        var records = this.invoices.sort((a, b) => a.paygateId > b.paygateId ? 1 : -1).map(i => `${i.paygateId}${i.total.toFixed(2)}`)

        console.log(this.requestData)

        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/CreateMultiple`,
          {
            ...this.requestData,
            composite: records.join(''),
            invoiceDate: invoiceDate,
            invoiceComment: invoiceComment
          },
          {
            showload: true,
            showerror: true,
            errormessage: 'Invoices failed to create'
          })

        this.clearLoad()
        this.$toastr.s(
          'Invoices Generated',
          'Generated'
        )
      } catch { }
    },
    clearLoad () {
      this.loaded = false
      this.invoices = []
    }
  },
  created () {
    this.from = moment().add(-1, 'month').startOf('month').toDate()
    this.to = moment().add(-1, 'month').endOf('month').toDate()
  },
  validations () {
    return {
      from: { required },
      to: { required }
    }
  }
}
</script>

<style>
</style>
