<template>
  <div>
    <div class="row">
      <div class="col-md-12" v-if="!oppositeFile">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>{{recreateLabel}} Single {{label}}</h2>
          </div>
          <div class="idb-block-content">
            <div class="form-row">
              <div class="form-group col-md-2" :class="{ invalid: $v.from.$error }">
                <label for="to" class="required">From</label>
                <vuejsDatepicker
                  name="from"
                  id="from"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :use-utc="false"
                  :disabled-dates="{
                    from: this.to
                    }"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="$v.from.$model"
                  @input="clearLoad"
                ></vuejsDatepicker>
                <validation-messages v-model="$v.from" name="from"></validation-messages>
              </div>
              <div class="form-group col-md-2" :class="{ invalid: $v.to.$error }">
                <label for="to" class="required">To</label>
                <vuejsDatepicker
                  name="to"
                  id="to"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :use-utc="false"
                  :disabled-dates="{
                    to: this.from
                    }"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="$v.to.$model"
                  @input="clearLoad"
                ></vuejsDatepicker>
                <validation-messages v-model="$v.to" name="from"></validation-messages>
              </div>

              <div
                class="form-group col-md-4"
                :class="{ invalid: $v.client.$error }"
                v-if="!recreate"
              >
                <label class="required">Client</label>

                <b-form-select
                  v-model="$v.client.$model"
                  :options="clients"
                  text-field="label"
                  value-field="id"
                  @change="clearLoad"
                ></b-form-select>
                <validation-messages v-model="$v.client" name="Client"></validation-messages>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click="checkValidation() && load()"
              :disabled="isLoading"
            >Load Invoice details</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loaded">
      <div class="row">
        <div class="col-md-12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>Charges</h2>
            </div>
            <div class="idb-block-content">
              <div class="row">
                <div class="col-md-12">
                  <checkbox-table
                    :paginationOptions="paginationOptions"
                    :sort-options="sortOptions"
                    :isLoading.sync="isTableLoading"
                    :rows="rows"
                    :columns="columns"
                    :lineNumbers="true"
                    mode="remote"
                    :totalRows="totalRecords"
                    :selectAllMethod="selectAll"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"
                    styleClass="vgt-table striped bordered"
                    ref="table"
                    :select-options="{ enabled: true, selectionText: 'Charges selected' }"
                    v-model="selections"
                  >
                    <template slot="table-actions">
                      <div>
                        <button
                          @click.prevent="clearTableFilters"
                          class="btn btn-link"
                          v-b-popover.hover.top.d500="'Clear filters'"
                        >
                          <span class="fa-stack m-auto" style="font-size: 10px">
                            <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                            <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                          </span>
                        </button>

                        <button
                          @click.prevent="load"
                          class="btn btn-link"
                          v-b-popover.hover.top.d500="'Refresh the data in the table'"
                        >
                          <i class="fas fa-sync-alt pointer dimmedIcon"></i>
                        </button>
                      </div>
                    </template>
                    <template slot="table-row" slot-scope="props">
                      <span
                        v-if="props.column.field === 'charge' || props.column.field === 'serviceCharge'|| props.column.field === 'vatAmount'"
                        :class="tdClass(props.row, props.column.field)"
                      >{{props.formattedRow[props.column.field]}}</span>
                      <span v-else>{{props.formattedRow[props.column.field]}}</span>
                    </template>
                    <template slot="extra-select-info">
                      <div class="mr-15">
                        <span class="mr-20">
                          Total Amount:
                          <span
                            :class="moneyClass(totalCharge)"
                          >£{{moneyFormat(totalCharge)}}</span>
                        </span>
                        <span class="mr-20">
                          Total Service Charge:
                          <span
                            :class="moneyClass(totalServiceCharge)"
                          >£{{moneyFormat(totalServiceCharge)}}</span>
                        </span>
                        <span class="mr-20">
                          Total VAT:
                          <span
                            :class="moneyClass(totalVatAmount)"
                          >£{{moneyFormat(totalVatAmount)}}</span>
                        </span>
                        <span>
                          Total Amount to {{label}}:
                          <span
                            :class="moneyClass(totalAmount)"
                          >£{{moneyFormat(totalAmount)}}</span>
                        </span>
                      </div>
                    </template>
                  </checkbox-table>
                </div>
              </div>
            </div>
            <div class="idb-block-footer">
              <div class="btn-group dropup">
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle ml-3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  :disabled="isLoading"
                >Create {{label}}</button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" @click="openSubmitModal(false)">With Selected Charges</a>
                  <a class="dropdown-item" @click="openSubmitModal(true)">With All Charges</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <invoice-submit-modal ref="modal" v-on:submit="submit" :credit-note="isCreditNote"></invoice-submit-modal>
    </div>
  </div>
</template>

<script>
// Third Party
import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'

// Components
import InvoiceSubmitModal from '@/Components/Platform/Customer/Invoicing/InvoiceSubmitModal'
import CheckboxTable from '@/Assets/Components/CheckboxTable/CheckboxTable'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'
import invoicingMixin from '@/Components/Platform/Customer/Invoicing/invoicingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

// Validations
import {
  requiredIf
} from 'vuelidate/lib/validators'

// Constants
import { billingEnum as billing } from '@/Assets/Constants/billing'

export default {
  name: 'CreateSingleInvoice',
  mixins: [loading, invoicingMixin, tableFilterMixin],
  props: {
    oppositeFile: { type: Boolean, default: false },
    recreate: { type: Boolean, default: false },
    clientId: { type: String },
    invoiceId: { type: String },
    creditNote: { type: Boolean, default: false }
  },
  components: {
    InvoiceSubmitModal,
    CheckboxTable
  },
  computed: {
    requestData () {
      return {
        fromDate: this.from,
        toDate: this.to,
        client: this.client,
        invoiceId: this.invoiceId,
        recreate: this.recreate,
        creditNote: this.creditNote
      }
    },
    totalCharge () {
      return _.sumBy(this.selections, 'charge')
    },
    totalServiceCharge () {
      return _.sumBy(this.selections, 'serviceCharge')
    },
    totalVatAmount () {
      return _.sumBy(this.selections, 'vatAmount')
    },
    totalAmount () {
      return this.totalServiceCharge + this.totalVatAmount
    },
    isCreditNote () {
      return this.totalAmount < 0 || this.creditNote
    },
    label () {
      return this.isCreditNote ? 'Credit Note' : 'Invoice'
    },
    recreateLabel () {
      return this.recreate ? 'Re-Create' : 'Create'
    }
  },
  data () {
    return {
      from: null,
      to: null,
      client: null,
      loaded: false,
      rows: [],
      clients: [],
      shouldLoad: false,
      columns: [
        {
          label: 'Client',
          field: 'clientName'
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter by type',
            filterDropdownItems: Object.values(billing)
          }
        },
        {
          label: 'Customer Reference',
          field: 'customerReference',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Amount (£)',
          field: 'charge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },
        {
          label: 'Service Charges (£)',
          field: 'serviceCharge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },
        {
          label: 'VAT (£)',
          field: 'vatAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        }
      ],
      serverParams: {
        sort: [{ field: 'date', type: 'desc' }]
      },
      sortOptions: { enabled: true, initialSortBy: { field: 'date', type: 'desc' } },
      selections: [],
      allCharges: false,
      shouldSaveTableFilters: false
    }
  },
  methods: {
    async load () {
      try {
        this.$snapbar.hide()
        this.isTableLoading = true

        var url = this.oppositeFile ? `${process.env.VUE_APP_PLATFORM_API_URL}Invoice/Opposite` : `${process.env.VUE_APP_PLATFORM_API_URL}Invoice/Single`

        var response = await axios.get(url,
          {
            params: {
              ...this.buildGoodTableQuery(),
              ...this.requestData
            },
            showload: true,
            showerror: true,
            errormessage: 'Charges Failed to load'
          })

        this.rows = response.data.data
        this.totalRecords = response.data.count
        this.loaded = true
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    async loadClients () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/Clients`, {
          showerror: true,
          errormessage: 'Clients Failed To Load'
        })

        this.clients = response.data
      } catch { }
    },
    clearLoad () {
      this.loaded = false
      this.rows = []
      this.selections = []
    },
    async selectAll () {
      try {
        this.$snapbar.hide()

        var url = this.oppositeFile ? `${process.env.VUE_APP_PLATFORM_API_URL}Invoice/Opposite/SelectAll` : `${process.env.VUE_APP_PLATFORM_API_URL}Invoice/Single/SelectAll`

        var response = await axios.get(url,
          {
            params: {
              ...this.buildGoodTableQuery(),
              ...this.requestData
            },
            showload: false,
            showerror: true,
            errormessage: 'Charges Failed to select'
          })
        return response.data
      } catch { }
    },
    openSubmitModal (allCharges) {
      this.allCharges = allCharges

      var records = this.selections.map(i => i.id)

      if ((this.allCharges && this.totalRecords === 0) || (!this.allCharges && records.length === 0)) {
        this.$toastr.w(
          `Cannot create an ${this.label} with no charges`,
          'Warning'
        )
        return
      }

      this.$refs.modal.$refs.invoiceSubmit.show()
    },
    async submit (invoiceDate, invoiceComment) {
      try {
        var records = this.selections.map(i => i.id)

        var url = this.oppositeFile ? `${process.env.VUE_APP_PLATFORM_API_URL}Invoice/CreateOpposite` : `${process.env.VUE_APP_PLATFORM_API_URL}Invoice/CreateSingle`
        var response = await axios.post(url,
          {
            ...this.requestData,
            invoiceDate: invoiceDate,
            invoiceComment: invoiceComment,
            SelectedCharges: records,
            AllCharges: this.allCharges
          },
          {
            showload: true,
            showerror: true,
            errormessage: 'Invoices failed to create'
          })

        this.clearLoad()
        this.$toastr.s(
          `Invoice Generated - ${response.data}`,
          'Generated'
        )

        if (this.recreate || this.oppositeFile) {
          this.$router.replace({ name: 'ListInvoices' })
        }
      } catch { }
    }
  },
  async created () {
    this.from = moment().add(-1, 'month').startOf('month').utc().toDate()
    this.to = moment().add(-1, 'month').endOf('month').utc().toDate()
    if (!this.oppositeFile) {
      await this.loadClients()
      if (this.recreate) {
        this.client = this.clientId
      }
    } else {
      this.load()
    }
  },
  validations () {
    return {
      from: { required: requiredIf(() => !this.oppositeFile) },
      to: { required: requiredIf(() => !this.oppositeFile) },
      client: { required: requiredIf(() => !this.oppositeFile && !this.recreate) }
    }
  }
}
</script>

<style>
</style>
