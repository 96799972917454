<template>
  <div class="form-group row">
    <label class="col-form-label col-md-3">Linked Clients</label>
    <div class="col-md-9">
      <vue-good-table
        ref="customerContacts"
        :columns="customerColumns"
        :rows="customersFormatted"
        :totalRows="customers.length "
        :pagination-options="{
                    enabled: true,
                    perPage: 10,
                    dropdownAllowAll: false
                  }"
        styleClass="vgt-table striped bordered"
      ></vue-good-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['customers'],
  computed: {
    customersFormatted () {
      return this.customers.map(c => { return { name: c } })
    }
  },
  data () {
    return {
      customerColumns: [{
        label: 'Name',
        field: 'name'
      }]

    }
  }
}
</script>

<style>
</style>
