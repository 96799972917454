<template>
  <div>
    <b-modal
      @show="clearForm"
      @shown="focusFirst"
      id="bankingContactModal"
      ref="bankingContactModal"
      variant="primary"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
      :title="title"
      scrollable
      size="lg"
    >
      <div class="container-fluid">
        <!-- Institution Name -->
        <div class="form-group row" :class="{invalid: $v.contact.institutionName.$error}">
          <label class="label-control col-md-3 required">Institution</label>
          <div class="col-md-6">
            <input
              ref="institutionName"
              type="text"
              class="form-control"
              v-model="$v.contact.institutionName.$model"
            />
            <validation-messages v-model="$v.contact.institutionName"></validation-messages>
          </div>
        </div>

        <!-- Title -->
        <div class="form-group row" :class="{invalid: $v.contact.branchName.$error}">
          <label class="label-control col-md-3 required">Branch Name</label>
          <div class="col-md-6">
            <input
              ref="branchName"
              type="text"
              class="form-control"
              v-model="$v.contact.branchName.$model"
            />
            <validation-messages v-model="$v.contact.branchName"></validation-messages>
          </div>
        </div>

        <!-- Branch Title -->
        <div class="form-group row" :class="{invalid: $v.contact.branchTitle.$error}">
          <label class="label-control col-md-3">Branch Title</label>
          <div class="col-md-6">
            <input
              ref="branchTitle"
              type="text"
              class="form-control"
              v-model="$v.contact.branchTitle.$model"
            />
            <validation-messages v-model="$v.contact.branchTitle"></validation-messages>
          </div>
        </div>

        <!-- Title -->
        <div class="form-group row" :class="{invalid: $v.contact.title.$error}">
          <label class="label-control col-md-3 required">Title</label>
          <div class="col-md-6">
            <b-form-select
              class="form-control"
              :options="titles"
              value-field="name"
              text-field="name"
              v-model="$v.contact.title.$model"
            />
            <validation-messages v-model="$v.contact.title"></validation-messages>
          </div>
        </div>

        <!-- Name -->
        <div class="form-group row" :class="{invalid: $v.contact.name.$error}">
          <label class="label-control col-md-3 required">Name</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="$v.contact.name.$model" />
            <validation-messages v-model="$v.contact.name"></validation-messages>
          </div>
        </div>

        <!-- Job Title -->
        <div class="form-group row" :class="{invalid: $v.contact.jobTitle.$error}">
          <label class="label-control col-md-3">Job Title</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="$v.contact.jobTitle.$model" />
            <validation-messages v-model="$v.contact.jobTitle"></validation-messages>
          </div>
        </div>

        <!-- Email -->
        <div class="form-group row" :class="{invalid: $v.contact.email.$error}">
          <label class="label-control col-md-3 required">Email</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="$v.contact.email.$model" />
            <validation-messages v-model="$v.contact.email"></validation-messages>
          </div>
        </div>

        <!-- Phone -->
        <div class="form-group row" :class="{invalid: $v.contact.phone.$error}">
          <label class="label-control col-md-3">Phone</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="$v.contact.phone.$model" />
            <validation-messages v-model="$v.contact.phone">
              <small
                class="form-text small"
                v-if="!$v.contact.phone.phoneNumber"
              >This is not a valid phone number</small>
            </validation-messages>
          </div>
        </div>

        <!-- Mobile -->
        <div class="form-group row" :class="{invalid: $v.contact.mobile.$error}">
          <label class="label-control col-md-3">Mobile</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="$v.contact.mobile.$model" />
            <validation-messages v-model="$v.contact.mobile">
              <small
                class="form-text small"
                v-if="!$v.contact.mobile.phoneNumber"
              >This is not a valid phone number</small>
            </validation-messages>
          </div>
        </div>

        <!-- Postcode -->
        <div class="form-group row" :class="{invalid: $v.contact.postcode.$error}">
          <label class="label-control col-md-3 required">Postcode</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="$v.contact.postcode.$model" />
            <validation-messages v-model="$v.contact.postcode">
              <small
                class="form-text small"
                v-if="$v.contact.postcode.$invalid"
              >This is not a valid postcode</small>
            </validation-messages>
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-info"
              @click="getAddress"
              type="button"
              :disabled="$v.contact.postcode.$invalid"
            >Lookup Address</button>
          </div>
        </div>

        <transition name="fade">
          <div
            class="form-group row"
            v-if="foundAddresses.length > 0 && !this.addressLookupInProgress"
          >
            <label class="label-control col-md-3">Select an Address</label>
            <div class="col-md-6">
              <select
                class="form-control"
                aria-label="Please select an address"
                v-model="selectedAddress"
                @change="setAddress"
              >
                <option selected>Please select an address</option>
                <option
                  v-for="foundAddress in foundAddresses"
                  :key="foundAddress.addressLine1"
                  :value="foundAddress"
                >{{foundAddress.addressLine1}}, {{foundAddress.addressTown}}, {{foundAddress.addressCounty}}</option>
              </select>
            </div>
          </div>
        </transition>

        <!-- Address -->
        <div class="form-group row" :class="{invalid: $v.contact.address1.$error}">
          <label class="label-control col-md-3">Address</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model.trim="$v.contact.address1.$model" />
          </div>
        </div>
        <div class="form-group row" :class="{invalid: $v.contact.address2.$error}">
          <label class="label-control col-md-3">Address 2</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model.trim="$v.contact.address2.$model" />
          </div>
        </div>
        <div class="form-group row" :class="{invalid: $v.contact.address3.$error}">
          <label class="label-control col-md-3">Address 3</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model.trim="$v.contact.address3.$model" />
          </div>
        </div>
        <div class="form-group row" :class="{invalid: $v.contact.address4.$error}">
          <label class="label-control col-md-3">Address 4</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model.trim="$v.contact.address4.$model" />
          </div>
        </div>

        <!-- Town -->
        <div class="form-group row" :class="{invalid: $v.contact.town.$error}">
          <label class="label-control col-md-3">Town</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="$v.contact.town.$model" />
            <validation-messages v-model="$v.contact.town"></validation-messages>
          </div>
        </div>

        <!-- County -->
        <div class="form-group row" :class="{invalid: $v.contact.county.$error}">
          <label class="label-control col-md-3">County</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="$v.contact.county.$model" />
            <validation-messages v-model="$v.contact.county"></validation-messages>
          </div>
        </div>

        <!-- Contact Type -->
        <div class="form-group row">
          <label class="col-form-label col-md-3">Contact Type</label>
          <div class="col-md-6">
            <b-form-select v-model="$v.contact.contactType.$model" :options="contactTypes" text-field="name" value-field="value"></b-form-select>
          </div>
        </div>

        <!-- Notes -->
        <div class="form-group row" :class="{invalid: $v.contact.notes.$error}">
          <label class="label-control col-md-3">Notes</label>
          <div class="col-md-6">
            <textarea class="form-control" v-model="$v.contact.notes.$model" />
            <validation-messages v-model="$v.contact.notes"></validation-messages>
          </div>
        </div>
      </div>

      <template slot="modal-footer">
        <button type="button" class="btn btn-success mr-auto" @click="addContact">{{title}}</button>
        <button type="button" class="btn btn-danger ml-auto" @click="cancel">{{cancelButtonText}}</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { phoneNumber, email } from '@/Assets/Validators'
import swal from 'sweetalert2'
import {
  required
} from 'vuelidate/lib/validators'
import Enum from "@/Enums/Collections";
import {helpers} from "vuelidate/lib/validators";
const postcodeVal = helpers.regex('postcodeVal', /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/i )

export default {
  name: 'BankingContact',
  props: {
    type: {
      type: String
    },
    contacts: {
      type: Array
    }
  },
  computed: {
    title () {
      switch (this.type) {
        case 'Edit':
          return 'Edit Contact'
        case 'Create':
          return 'Add Contact'
      }
      return ''
    },
    cancelButtonText () {
      switch (this.type) {
        case 'Edit':
          return 'Cancel'
        case 'Create':
          return 'Close'
      }
      return ''
    }
  },
  data () {
    return {
      titles: Enum.Payer.Titles.enumValues,
      contact: {
        bankingContactId: '00000000-0000-0000-0000-000000000000',
        institutionName: null,
        branchName: null,
        branchTitle: null,
        title: null,
        name: null,
        address1: null,
        address2: null,
        address3: null,
        address4: null,
        town: null,
        county: null,
        email: null,
        phone: null,
        mobile: null,
        jobTitle: null,
        contactType: 0,
        notes: null,
        postcode: null
      },
      savedContact: null,
      contactTypes: [
        { name: 'General', value: 0 },
        { name: 'Technical', value: 1 },
        { name: 'Contractual', value: 2 },
        { name: 'Billing', value: 3 }
      ],
      addressLookupInProgress: false,
      foundAddresses: [],
      selectedAddress: null
    }
  },
  methods: {
    clearForm () {
      this.contact = {
        bankingContactId: '00000000-0000-0000-0000-000000000000',
        institutionName: null,
        branchName: null,
        branchTitle: null,
        title: null,
        name: null,
        address1: null,
        address2: null,
        address3: null,
        address4: null,
        town: null,
        county: null,
        email: null,
        phone: null,
        mobile: null,
        jobTitle: null,
        contactType: 0,
        notes: null,
        postcode: null
      }
      this.foundAddresses = []
      this.$v.$reset()
    },
    focusFirst () {
      this.$refs.institutionName.focus()
    },
    hideModal () {
      this.$refs.bankingContactModal.hide()
    },
    addContact () {
      console.log(this.contact)
      if (this.checkValidation()) {
        if (this.contacts === undefined) {
          this.contacts = []
        }
        if (this.type === 'Create') {
          // this.contacts.push(this.contact)
          this.$emit('edit', this.contact)
        } else {
          this.$emit('edit', this.contact)
        }
        this.hideModal()
      }
    },
    cancel () {
      if (this.type === 'Edit') {
        this.contact.bankingContactId = this.savedContact.bankingContactId
        this.contact.institutionName = this.savedContact.institutionName
        this.contact.branchName = this.savedContact.branchName
        this.contact.branchTitle = this.savedContact.branchTitle
        this.contact.title = this.savedContact.title
        this.contact.name = this.savedContact.name
        this.contact.address1 = this.savedContact.address1
        this.contact.address2 = this.savedContact.address2
        this.contact.address3 = this.savedContact.address3
        this.contact.address4 = this.savedContact.address4
        this.contact.town = this.savedContact.town
        this.contact.county = this.savedContact.county
        this.contact.email = this.savedContact.email
        this.contact.phone = this.savedContact.phone
        this.contact.mobile = this.savedContact.mobile
        this.contact.jobTitle = this.savedContact.jobTitle
        this.contact.contactType = this.savedContact.contactType
        this.contact.notes = this.savedContact.notes
        this.contact.postcode = this.savedContact.postcode
      }
      this.hideModal()
    },
    populateContact (contact) {
      this.savedContact = JSON.parse(JSON.stringify(contact))
      this.contact = contact
      this.$v.$reset()
    },
    async getAddress () {
      if (this.$v.contact.postcode.$invalid) {
        console.log('Postcode Invalid, aborting address lookup.')
        return
      }
      this.foundAddresses = []
      this.addressLookupInProgress = true
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}addresslookup`, {
          params: { postcode: this.contact.postcode }
        })
        console.log(response)
        if (response.data.addresses.length === 0) {
          this.$toastr.w('Postcode Not Found', 'Sorry, we could not find that address')
        } else if (response.data.addresses.length === 1) {
          this.foundAddresses = response.data.addresses[0]
        } else {
          this.foundAddresses = response.data.addresses
        }
      } catch (e) {
        this.$toastr.w('Postcode Not Found', 'Sorry, we could not find that address')
      } finally {
        this.addressLookupInProgress = false
      }
    },
    setAddress () {
      if (this.selectedAddress != null) {
        this.contact.address1 = this.selectedAddress.addressLine1
        this.contact.address2 = this.selectedAddress.addressLine2
        this.contact.address3 = this.selectedAddress.addressLine3
        this.contact.address4 = this.selectedAddress.addressLine4
        this.contact.county = this.selectedAddress.addressCounty
        this.contact.town = this.selectedAddress.addressTown
      }
    }
  },
  validations () {
    return {
      contact: {
        bankingContactId: {},
        institutionName: { required },
        branchName: { required },
        branchTitle: {},
        title: { required },
        name: { required },
        address1: {},
        address2: {},
        address3: {},
        address4: {},
        town: {},
        county: {},
        email: { email, required },
        phone: { phoneNumber },
        mobile: { phoneNumber },
        jobTitle: {},
        contactType: {},
        notes: {},
        postcode: { required, postcodeVal }
      }
    }
  }
}
</script>

<style>
</style>
