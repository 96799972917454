import Store from '@/Store/index'
import licence from '@/Assets/Constants/licence'
import roles from '@/Assets/Constants/roles'

export default [
  {
    menu_title: 'UK Bank Account',
    menu_icon: 'ti-check-box',
    path: '/validate/Validation/BankAccount',
    showMethod: async () => {
      return Store.getters.hasLicence(licence.validate_modcheck) && Store.getters.isInRoles([roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.Marketing, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations])
    },
    title: 'UK Bank Account Validation'
  },
  {
    menu_title: 'IBAN',
    menu_icon: 'ti-check',
    path: '/validate/Validation/IBAN',
    showMethod: async () => {
      return Store.getters.hasLicence(licence.validate_iban) && Store.getters.isInRoles([roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.Marketing, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations])
    },
    title: 'International Bank Account Number Validation'
  }
]
