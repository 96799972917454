<template>
  <div id="infoAction">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block-content">
          <div v-if="action.id !== undefined" id="actionDetails">
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Title</strong>
              </div>
              <div class="col-md-10">
                {{ this.action.title }}
                <span class="pull-right">
                  <a href="#" target="_blank">
                    <i class="far fa-question-circle"></i>
                  </a>
                </span>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Message</strong>
              </div>
              <div class="col-md-10">{{ this.action.message }}</div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Details</strong>
              </div>
              <div v-if="action.actionSubClass === 'Info'" class="col-md-10">
                <div v-for="detail in this.action.data.details" :key="detail.messageText" :value="detail.messageText" style="padding-bottom: 15px;">
                  {{ detail.messageText }}
                </div>
              </div>
              <div v-else class="col-md-10">
                {{ this.action.data.details }}
              </div>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row form-group">
            <div class="col-md-12">
              <button v-show="showSummaryButton"
                type="button"
                class="btn btn-primary pull-left"
                v-on:click="showSubmissionSummary"
              >View Submission Summary</button>
              <button
                id="delete"
                type="button"
                class="btn btn-danger pull-right"
                v-on:click="deleteAction"
              >Delete Action</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      action: {},
      showSummaryButton: false,
      paygateId: ''
    }
  },

  methods: {
    async getAction () {
      var actionId = this.$store.getters.actionId
      if (actionId !== undefined) {
        var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/' + actionId
        var response = await axios.get(url)
        this.action = response.data
        for (var i = 0; i < this.action.data.details.length; i++) {
          if (this.action.data.details[i].messageTitle === 'SUBERROR') {
            this.showSummaryButton = true
          }
        }
      }
    },

    async deleteAction () {
      var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/deleteRejected/' + this.action.id
      await axios.delete(url)
      this.$router.push('/actions')
    },

    getMessageSeverity (severity) {
      if (severity === 0) {
        return 'Info'
      } else if (severity === 1) {
        return 'Warning'
      } else {
        return 'Fix'
      }
    },

    showSubmissionSummary () {
      const routeData = this.$router.resolve({ path: '/payments/common/viewSubmissionSummary', query: { submissionId: this.action.actionedObjectId, fileNumber: 0, paygateId: this.paygateId } })
      window.open(routeData.href, '_blank')
    }
  },

  async created () {
    this.getAction()
    if (this.isSystemUser) {
      this.paygateId = this.selectedCustomer
    } else {
      this.paygateId = this.$store.getters.getClaim('paygate_id').value
    }
  }
}
</script>
