import Store from '@/Store/index'
import roles from '@/Assets/Constants/roles'
import licence from '@/Assets/Constants/licence'

// Actions
const UserDetails = () => import(/* webpackChunkName: "platform-action-user" */ '@/Components/Platform/Actions/Types/User/UserDetails')
const GroupDetails = () => import(/* webpackChunkName: "platform-action-group" */ '@/Components/Platform/Actions/Types/Group/GroupDetails')
const BankAccountDetails = () => import(/* webpackChunkName: "platform-action-bankaccount" */ '@/Components/Platform/Actions/Types/BankAccount/BankAccountDetails')
const BureauCustomerActionDetails = () => import(/* webpackChunkName: "platform-action-bankaccount" */ '@/Components/Platform/Actions/Types/BureauCustomer/BureauCustomerActionDetails')
const AntiFraudListDetails = () => import(/* webpackChunkName: "platform-action-antifraudlist" */ '@/Components/Platform/Actions/Types/AntiFraud/AntiFraudListDetails')
const InfoAction = () => import(/* webpackChunkName: "platform-actionInfo" */ '@/Assets/Components/Actions/InfoAction')
const CustomerRequestDetails = () => import(/* webpackChunkName: "platform-customerinfo" */ '@/Components/Platform/Actions/Types/Customer/CustomerRequestDetails')

// Customer
const CustomerEdit = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/CustomerEdit')
const CustomerEmails = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Emails')
const CustomerAudits = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Audits/CustomerAudits')
const CustomerAuditDetails = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Audits/CustomerAuditDetails')
const CustomerLicence = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Licence/EditCustomerLicence')
const ReadOnlyCustomerLicence = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Licence/ReadOnlyCustomerLicence')
const OnboardingUrls = () => import(/* webpackChunkName: "platform-onboarding" */ '@/Components/Platform/Customer/OnboardingUrls')
const AgentDetails = () => import(/* webpackChunkName: "platform-agent-details" */ '@/Components/Platform/Customer/Agent/AgentDetails')
const ReportSubscriptions = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Subscriptions/ManageReportSubscriptions')
const PartNumbers = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/PartNumbers/PartNumbers')
const CustomerAssociations = () => import(/* webpackChunkName: "platform-customer-associations" */ '@/Components/Platform/Customer/Associations/CustomerAssociations')
const CustomerAssociation = () => import(/* webpackChunkName: "platform-customer-associations" */ '@/Components/Platform/Customer/Associations/EditCustomerAssociations')
const CustomerChildren = () => import(/* webpackChunkName: "platform-customer-children" */ '@/Components/Platform/Customer/Children/ManageCustomerChildren')
const CustomerSearch = () => import(/* webpackChunkName: "platform-customer-search" */ '@/Components/Platform/Customer/Search/CustomerSearch')
const IncompleteCustomers = () => import(/* webpackChunkName: "platform-customer-search" */ '@/Components/Platform/Customer/IncompleteCustomers')

// Billing

const ProcessBilling = () => import(/* webpackChunkName: "platform-billing" */ '@/Components/Platform/Customer/Billing/Processing/ProcessBilling')
const BillingReportJFTP = () => import(/* webpackChunkName: "platform-billing" */ '@/Components/Platform/Customer/Billing/Report/BillingReport')
const BillingReportDetails = () => import(/* webpackChunkName: "platform-billing" */ '@/Components/Platform/Customer/Billing/Report/BillingReportDetails')

// Charges
const ChargeConfiguration = () => import(/* webpackChunkName: "platform-billing" */ '@/Components/Platform/Customer/Charges/Configuration/ChargeConfiguration')
const Charges = () => import(/* webpackChunkName: "platform-billing" */ '@/Components/Platform/Customer/Charges/Charges')
const ChargeDetail = () => import(/* webpackChunkName: "platform-billing" */ '@/Components/Platform/Customer/Charges/ChargeDetail')
const ChargeCreate = () => import(/* webpackChunkName: "platform-billing" */ '@/Components/Platform/Customer/Charges/ChargeCreate')
const ListChargeConfigurationGroups = () => import(/* webpackChunkName: "platform-billing" */ '@/Components/Platform/Customer/Charges/ChargeSetGroups/ListChargeConfigurationGroups')
const ChargeConfigurationGroupEdit = () => import(/* webpackChunkName: "platform-billing" */ '@/Components/Platform/Customer/Charges/ChargeSetGroups/ChargeConfigurationGroupEdit')
const HistoricChargeConfigurationList = () => import(/* webpackChunkName: "platform-billing" */'@/Components/Platform/Customer/Charges/HistoricChargeConfiguration/HistoricChargeConfigurationsList')
const HistoricChargeConfiguration = () => import(/* webpackChunkName: "platform-billing" */'@/Components/Platform/Customer/Charges/HistoricChargeConfiguration/HistoricChargeConfigurationDetail')

// Settlements
const PotentialSettlements = () => import(/* webpackChunkName: "platform-settlements" */ '@/Components/Platform/Customer/Settlements/Potential/PotentialSettlements')
const ProcessedSettlements = () => import(/* webpackChunkName: "platform-settlements" */ '@/Components/Platform/Customer/Settlements/Processed/ProcessedSettlements')

const SettlementReport = () => import(/* webpackChunkName: "platform-settlement-reports" */ '@/Components/Platform/Customer/Settlements/Report/SettlementReport')
const SettlementReportDetails = () => import(/* webpackChunkName: "platform-settlement-reports" */ '@/Components/Platform/Customer/Settlements/Report/SettlementReportDetails')

// Invoicing
const CreateSingleInvoice = () => import(/* webpackChunkName: "platform-invoicing-all" */ '@/Components/Platform/Customer/Invoicing/CreateSingleInvoice')
const CreateMultipleInvoices = () => import(/* webpackChunkName: "platform-invoicing-all" */ '@/Components/Platform/Customer/Invoicing/CreateMultipleInvoices')
const ListInvoices = () => import(/* webpackChunkName: "platform-invoicing" */ '@/Components/Platform/Customer/Invoicing/List/ListInvoices')
const InvoiceDetails = () => import(/* webpackChunkName: "platform-invoicing" */ '@/Components/Platform/Customer/Invoicing/List/InvoiceDetails')
const InvoiceAnalysis = () => import(/* webpackChunkName: "platform-invoicing-analysis" */ '@/Components/Platform/Customer/Invoicing/Analysis/InvoiceAnalysis')

// Customer Certificates
const CustomerCertificates = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Certificates/ManageCustomerCertificates')
const CustomerCertificateCreate = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Certificates/CustomerCertificateCreate')

// Customer Dashboard
const EditCustomerDashboard = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Dashboard/EditCustomerDashboard')

// Users
const UserEdit = () => import(/* webpackChunkName: "platform-user" */ '@/Components/Platform/User/UserEdit')
const ListUsers = () => import(/* webpackChunkName: "platform-user" */ '@/Components/Platform/User/ListUsers')
const ImportUsers = () => import(/* webpackChunkName: "platform-user" */ '@/Components/Platform/User/ImportUsers')

// Service Accounts
const ListServiceAccounts = () => import(/* webpackChunkName: "platform-serviceaccount" */ '@/Components/Platform/ServiceAccounts/ListServiceAccounts')
const ServiceAccountEdit = () => import(/* webpackChunkName: "platform-serviceaccount" */ '@/Components/Platform/ServiceAccounts/ServiceAccountEdit')

// Groups
const GroupEdit = () => import(/* webpackChunkName: "platform-group" */ '@/Components/Platform/Group/GroupEdit')
const ListGroups = () => import(/* webpackChunkName: "platform-group" */ '@/Components/Platform/Group/ListGroups')
const ImportGroups = () => import(/* webpackChunkName: "platform-group" */ '@/Components/Platform/Group/ImportGroups')

// Bank Account
const BankAccountEdit = () => import(/* webpackChunkName: "platform-bankaccount" */ '@/Components/Platform/BankAccount/BankAccountEdit')
const ListBankAccounts = () => import(/* webpackChunkName: "platform-bankaccount" */ '@/Components/Platform/BankAccount/ListBankAccounts')

const SunEdit = () => import(/* webpackChunkName: "platform-suns" */ '@/Components/Platform/Suns/SunEdit')
const ListSuns = () => import(/* webpackChunkName: "platform-suns" */ '@/Components/Platform/Suns/ListSuns')

// Security Policy
const UserAccountsSecurityPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/UserAccounts')
const BankAccountSecurityPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/BankAccounts')
const PaymentGroupsSecurityPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/PaymentGroups')
const LoginSecurityPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/LoginPolicy')
const InactivityTimeoutPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/InactivityTimeout')
const DayTimeRestrictionsPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/DayTimeRestrictions')
const IPAddressRestrictionsPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/IPAddressRestrictions')
const PasswordComplexityPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/PasswordComplexity')
const IncorrectPasswordPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/IncorrectPassword')

// Usb Tokens
const AssignUsbToken = () => import(/* webpackChunkName: "platform-token" */ '@/Components/Platform/UsbTokens/AssignUsbToken')
const ListUsbTokens = () => import(/* webpackChunkName: "platform-token" */ '@/Components/Platform/UsbTokens/ListUsbTokens')
const ListExpiringUsbTokens = () => import(/* webpackChunkName: "platform-token" */ '@/Components/Platform/UsbTokens/ListExpiringUsbTokens')

// GDPR
const GDPRSearch = () => import(/* webpackChunkName: "platform-search" */ '@/Components/Platform/GDPR/GDPRSearch')
const DeleteGroupData = () => import(/* webpackChunkName: "platform-search" */ '@/Components/Platform/GDPR/DeleteGroupData')

// Billing & Reconciliation
// const Ledger = () => import(/* webpackChunkName: "platform-bandr-ledger" */ '@/Views/JFTP/Billing/Ledger/LedgerDashboard')
// const Balances = () => import(/* webpackChunkName: "platform-bandr-balances" */ '@/Views/JFTP/Billing/Ledger/Balances')
const NominalCodes = () => import(/* webpackChunkName: "platform-bandr-nominalcodes" */ '@/Views/JFTP/Reconcilliation/NominalCodes/NominalCodes')
const BanklineFileList = () => import(/* webpackChunkName: "platform-bandr-banklinefilelist" */ '@/Views/JFTP/Reconcilliation/Bankline/BanklineFileList')
const BanklineTransactions = () => import(/* webpackChunkName: "platform-bandr-banklinetransactions" */ '@/Views/JFTP/Reconcilliation/Bankline/BanklineTransactions')
const BanklineAccountBalances = () => import(/* webpackChunkName: "platform-bandr-banklineaccountbalances" */ '@/Views/JFTP/Reconcilliation/Bankline/BanklineAccountBalances')
const BankLedgerBalances = () => import(/* webpackChunkName: "platform-bandr-bankledgerbalances" */ '@/Views/JFTP/Reconcilliation/Ledger/BankLedgerBalances')
const LedgerTransactions = () => import(/* webpackChunkName: "platform-bandr-ledgertransactions" */ '@/Views/JFTP/Reconcilliation/Ledger/LedgerTransactions')
const IndemnityClaims = () => import(/* webpackChunkName: "platform-bandr-indemnityclaims" */ '@/Views/JFTP/Reconcilliation/Ledger/IndemnityClaims')
const ClientBalances = () => import(/* webpackChunkName: "platform-bandr-clientbalances" */ '@/Views/JFTP/Reconcilliation/Ledger/ClientBalances')
const LedgerClientLiability = () => import(/* webpackChunkName: "platform-bandr-ledgerclientliability" */ '@/Views/JFTP/Reconcilliation/Ledger/LedgerClientLiability')
const NominalCodeTotals = () => import(/* webpackChunkName: "platform-bandr-nominalCodeTotals" */ '@/Views/JFTP/Reconcilliation/Ledger/NominalCodeTotals')

const UploadBanklineFile = () => import(/* webpackChunkName: "platform-bandr-ubf" */ '@/Views/JFTP/Billing/Bankline/UploadBanklineFile')
const BanklineOutbound = () => import(/* webpackChunkName: "platform-bandr-blob" */ '@/Views/JFTP/Billing/Bankline/BanklineOutbound')
const BanklineInbound = () => import(/* webpackChunkName: "platform-bandr-blib" */ '@/Views/JFTP/Billing/Bankline/BanklineInbound')

const BanklineNotifications = () => import(/* webpackChunkName: "platform-bandr-bld" */ '@/Views/JFTP/Billing/Bankline/BanklineNotifications')
const BanklineAstFile = () => import(/* webpackChunkName: "platform-bandr-blfast" */ '@/Views/JFTP/Billing/Bankline/BanklineAstFile')
const BanklineAstMFile = () => import(/* webpackChunkName: "platform-bandr-blfast" */ '@/Views/JFTP/Billing/Bankline/BanklineAstMFile')
const BanklinePaymentFile = () => import(/* webpackChunkName: "platform-bandr-blpf" */ '@/Views/JFTP/Billing/Bankline/BanklinePaymentFile')
const BanklineAbsFile = () => import(/* webpackChunkName: "platform-bandr-blfabs" */ '@/Views/JFTP/Billing/Bankline/BanklineAbsFile')
const BanklineAstRow = () => import(/* webpackChunkName: "platform-bandr-blastr" */ '@/Views/JFTP/Billing/Bankline/BanklineAstRow')
const BanklinePaymentRow = () => import(/* webpackChunkName: "platform-bandr-blpr" */ '@/Views/JFTP/Billing/Bankline/BanklinePaymentRow')
const BanklineAbsRow = () => import(/* webpackChunkName: "platform-bandr-blabsr" */ '@/Views/JFTP/Billing/Bankline/BanklineAbsRow')
const BanklineConfig = () => import(/* webpackChunkName: "platform-bandr-blcfg" */ '@/Views/JFTP/Billing/Bankline/BanklineConfig')
const BanklinePayment = () => import(/* webpackChunkName: "platform-bandr-blp" */ '@/Views/JFTP/Billing/Bankline/BanklinePayment')
const BanklinePaymentEdit = () => import(/* webpackChunkName: "platform-bandr-blpe" */ '@/Views/JFTP/Billing/Bankline/BanklinePaymentEdit')
const BanklineChapsPayment = () => import(/* webpackChunkName: "platform-bandr-blcp" */ '@/Views/JFTP/Billing/Bankline/BanklineChapsPayment')
const BanklineChapsPaymentEdit = () => import(/* webpackChunkName: "platform-bandr-blcpe" */ '@/Views/JFTP/Billing/Bankline/BanklineChapsPaymentEdit')

// System
const ManageHsm = () => import(/* webpackChunkName: "platform-hsm" */ '@/Components/Platform/System/ManageHsm/ManageHsm')
const ListHsm = () => import(/* webpackChunkName: "platform-hsm" */ '@/Components/Platform/System/ManageHsm/ListHsm')
const LoggingSettings = () => import(/* webpackChunkName: "platform-logging" */ '@/Components/Platform/System/Logging/LoggingSettings')
const MaintenanceWindowsList = () => import(/* webpackChunkName: "platform-maintenancewindow" */ '@/Components/Platform/System/MaintenanceWindows/MaintenanceWindowsList')
const MaintenanceWindowEdit = () => import(/* webpackChunkName: "platform-maintenancewindow" */ '@/Components/Platform/System/MaintenanceWindows/MaintenanceWindowEdit')
const EISCDImport = () => import(/* webpackChunkName: "platform-EISCD" */ '@/Components/Platform/System/Validate/EISCDImport')
const WeightTableImport = () => import(/* webpackChunkName: "platform-WeightTable" */ '@/Components/Platform/System/Validate/WeightTableImport')
const LogViewer = () => import(/* webpackChunkName: "platform-log-viewer" */ '@/Components/Platform/System/Logging/LogViewer')
const EventLog = () => import(/* webpackChunkName: "platform-event-log" */ '@/Components/Platform/System/EventLog/EventLog')
const EventLogConfig = () => import(/* webpackChunkName: "platform-event-log" */ '@/Components/Platform/System/EventLog/EventLogConfig')
const EventView = () => import(/* webpackChunkName: "platform-event-view" */ '@/Components/Platform/System/EventLog/EventView')
const ListSecureLimits = () => import(/* webpackChunkName: "platform-securelimits" */ '@/Components/Platform/System/SecureLimits/ListSecureLimits')
const SecureLimitsEdit = () => import(/* webpackChunkName: "platform-securelimits" */ '@/Components/Platform/System/SecureLimits/SecureLimitsEdit')
const ListVat = () => import(/* webpackChunkName: "platform-vat" */ '@/Components/Platform/System/VAT/ListVat')
const VatEdit = () => import(/* webpackChunkName: "platform-vat" */ '@/Components/Platform/System/VAT/VatEdit')
const ReportProcessorStatus = () => import(/* webpackChunkName: "bacs-reports" */ '@/Views/Bacs/BacsReports/ReportProcessorStatus')
const RecurringChargesSettings = () => import(/* webpackChunkName: "platform-charges-settings" */ '@/Components/Platform/System/ChargeSettings/RecurringChargesSettings')
const CreateTestData = () => import(/* webpackChunkName: "platform-test-data" */ '@/Components/Platform/System/TestData/CreateTestData')

// Reports
const ActionReport = () => import(/* webpackChunkName: "platform-actionreport" */ '@/Components/Platform/Reports/Actions/ActionsReport')
const ValidateReport = () => import(/* webpackChunkName: "platform-MonthlyReport" */ '@/Components/Platform/Reports/Validate/ValidateReport')
const LiabilityReport = () => import(/* webpackChunkName: "platform-liabilityreport" */ '@/Components/Platform/Reports/Liabilities/CustomerLiabilityReport')
const MonthlyFeesReport = () => import(/* webpackChunkName: "platform-MonthlyFeesReport" */ '@/Components/Platform/Reports/MonthlyFees/MonthlyFeesReport')
const PsAdHocFeesReport = () => import(/* webpackChunkName: "platform-PsAdHocFeesReport" */ '@/Components/Platform/Reports/PsAdHocFees/PsAdHocFeesReport')

const HoldingReport = () => import(/* webpackChunkName: "platform-holdingreport" */ '@/Components/Platform/Reports/Holding/HoldingReport')

// Email Functionality
const Bounces = () => import(/* webpackChunkName: "platform-emailbounces" */ '@/Components/Platform/EmailAdministration/Bounces')
const BounceReport = () => import(/* webpackChunkName: "platform-emailbounces" */ '@/Components/Platform/EmailAdministration/BounceReport')
const SetUsers = () => import(/* webpackChunkName: "platform-emailmonitoring" */ '@/Components/Platform/System/EmailMonitoring/SetUsers')
const ThresholdAddresses = () => import(/* webpackChunkName: "platform-payer-threshold" */ '@/Components/Platform/PayerThreshold/SetEmailAddresses')

// Onboarding Reporting
const OnboardingApiUsage = () => import(/* webpackChunkName: "platform-onboarding-report" */ '@/Components/Platform/Onboarding/OnboardingApiUsage')

// News Stories
const ListStories = () => import(/* webpackChunkName: "platform-manage-news" */ '@/Components/Platform/System/News/ListNewsStories')
const EditStory = () => import(/* webpackChunkName: "platform-manage-news" */ '@/Components/Platform/System/News/EditNewsStory')
const News = () => import(/* webpackChunkName: "platform-news" */ '@/Assets/Components/Dashboard/Widgets/General/News')

const AppSettings = () => import(/* webpackChunkName: "platform-app-settings" */ '@/Components/Platform/System/AppSettings/AppSettings')

// Anti-fraud lists
const ListAntiFraudLists = () => import(/* webpackChunkName: "platform-anti-fraud-list" */ '@/Components/Platform/AntiFraud/ListAntiFraudLists')
const AntiFraudListEdit = () => import(/* webpackChunkName: "platform-anti-fraud-list-edit" */ '@/Components/Platform/AntiFraud/AntiFraudListEdit')

// Template Administration
const TemplateApproval = () => import(/* webpackChunkName: "jftp-templateapproval" */ '@/Views/JFTP/Collections/TemplateApproval')
const TemplateAction = () => import(/* webpackChunkName: "jftp-templateapproval" */ '@/Views/JFTP/Collections/TemplateAction')
const TemplateApprovalAction = () => import(/* webpackChunkName: "jftp-templateapproval" */ '@/Views/JFTP/Collections/TemplateApprovalAction')

// Commission Agents
const CommissionAgentListAgent = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Agents/ListAgents')
const CommissionAgentCreateAgent = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Agents/CreateAgent')
const CommissionAgentAdministration = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Agents/AgentAdministration')
const CommissionAgentCreateContact = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Agents/CreateContact')
const CommissionAgentContactAdministration = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Agents/ContactAdministration')
const CommissionAgentCreateAssociation = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Agents/CreateAssociation')
const CommissionAgentViewAssociation = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Agents/ViewAssociation')
const CommissionAgentListAssociations = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Agents/ListAssociations')
const CommissionAgentViewAssociationCommissionEdit = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Agents/ViewAssociation')

const CommissionAgentListCommissions = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Commission/ListCommissions')
const CommissionAgentCommissionEdit = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Commission/CommissionEdit')
const CommissionAgentCommissionAdd = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Commission/CommissionAdd')

const CommissionAgentListStatements = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Statements/ListStatements')
const CommissionAgentListStatementsSelect = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Statements/ListStatements')

const CommissionAgentListCommissionForStatement = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Statements/ListCommissionForStatement')
const CommissionAgentStatmentCommissionAdd = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Commission/CommissionAdd')

const CommissionAgentListStatementAuditTrail = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/StatementAuditTrail/ListAuditTrailStatement')
const CommissionAgentAuditTrailStatement = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/StatementAuditTrail/AuditTrailStatement')
const CommissionAgentAuditTrailEmail = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/StatementAuditTrail/AuditTrailEmail')

const CommissionAgentAuditTrailAgent = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/AgentsAuditTrail/AuditTrailAgent')
const CommissionAgentContactAdministrationFromAuditTrail = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Agents/ContactAdministration')
const CommissionAgentViewAssociationFromAuditTrail = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Agents/ViewAssociation')

const CommissionAgentPaymentDetailReport = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Reports/PaymentDetailReport')
const CommissionAgentCommissionComparisonReport = () => import(/* webpackChunkName: "jftp-commissionagents" */ '@/Components/Platform/CommissionAgents/Reports/CommissionComparisonReport')

// define your routes here
export default [
  // #region Customer
  {
    name: 'CustomerCreate',
    path: '/admin/clients/create',
    component: CustomerEdit,
    meta: {
      title: 'Create Client',
      auth: [roles.LZAdmin, roles.Implementations]
    },
    props: { status: 'Create' }
  },
  {
    name: 'CustomerEdit',
    path: '/admin/clients/edit/:paygateId',
    component: CustomerEdit,
    meta: {
      title: 'Edit Client',
      auth: [roles.LZAdmin, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  {
    name: 'CustomerDetails',
    path: '/admin/clients/details',
    component: CustomerEdit,
    meta: {
      title: 'Client Details',
      auth: [roles.Limited, roles.ReadOnly, roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    },
    props: () => ({ status: 'Edit' })
  },
  {
    name: 'IncompleteCustomers',
    path: '/admin/clients/incomplete',
    component: IncompleteCustomers,
    meta: {
      title: 'Client Details',
      auth: [roles.LZAdmin, roles.Implementations]
    }
  },
  {
    name: 'CustomerRequestDetails',
    path: '/admin/clients/request/details',
    component: CustomerRequestDetails,
    meta: {
      title: 'Client Request Details',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  {
    name: 'AgentDetails',
    path: '/admin/clients/agent',
    component: AgentDetails,
    meta: {
      title: 'Agent Details',
      auth: roles.AgentConfigure,
      licence: [licence.agent]
    }
  },
  // {
  //   name: 'ManageCustomerChildren',
  //   path: '/admin/client/children',
  //   component: CustomerChildren,
  //   meta: {
  //     title: 'Child Clients',
  //     auth: [roles.ManageChildCustomers, roles.SystemUser]
  //   }
  // },
  {
    name: 'CustomerAssociations',
    path: '/admin/client/associations',
    component: CustomerAssociations,
    meta: {
      title: 'Client Associations',
      auth: [roles.LZAdmin, roles.Finance, roles.Implementations]
    }
  },
  {
    name: 'CreateCustomerAssociation',
    path: '/admin/client/associations/create',
    component: CustomerAssociation,
    meta: {
      title: 'Client Association',
      auth: [roles.LZAdmin, roles.Finance, roles.Implementations]
    },
    props: { status: 'Create' }
  },
  {
    name: 'EditCustomerAssociation',
    path: '/admin/client/associations/edit/:id',
    component: CustomerAssociation,
    meta: {
      title: 'Client Association',
      auth: [roles.LZAdmin, roles.Finance, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  {
    name: 'CustomerEmail',
    path: '/admin/clients/emails',
    component: CustomerEmails,
    meta: {
      title: 'Client Emails',
      auth: [roles.LZAdmin, roles.ClientAdmin, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    },
    props: () => ({ paygateId: Store.getters.selectedCustomer, status: 'Edit' })
  },
  {
    name: 'EditCustomerLicence',
    path: '/admin/clients/licence',
    component: CustomerLicence,
    meta: {
      title: 'Edit Client Licence',
      auth: [roles.LZAdmin, roles.Implementations]
    }
  },
  {
    name: 'CustomerCertificates',
    path: '/admin/client/certificates',
    component: CustomerCertificates,
    meta: {
      title: 'Client Certificates',
      auth: [roles.LZAdmin, roles.DevOps, roles.Implementations]
    }
  },
  {
    name: 'CreateCustomerCertificate',
    path: '/admin/client/certificate/create',
    component: CustomerCertificateCreate,
    meta: {
      title: 'Create Client Certificate',
      auth: [roles.LZAdmin, roles.DevOps, roles.Implementations]
    }
  },
  {
    name: 'CustomerAudits',
    path: '/admin/clients/auditlogs',
    component: CustomerAudits,
    meta: {
      title: 'Client Audit Logs',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    }
  },
  {
    name: 'AuditDetails',
    path: '/admin/clients/auditlog/:auditId',
    component: CustomerAuditDetails,
    meta: {
      title: 'Client Audit Details',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    },
    props: true
  },
  {
    name: 'CustomerDashboard',
    path: '/admin/clients/dashboard',
    component: EditCustomerDashboard,
    meta: {
      title: 'Client Dashboard',
      auth: roles.ClientAdmin
    }
  },
  {
    name: 'CustomerOnboarding',
    path: '/admin/customers/onboarding',
    component: OnboardingUrls,
    meta: {
      title: 'Customer Allowed URLs',
      auth: [roles.SystemUser, roles.LZAdmin]
    }
  },
  {
    name: 'ListChargeConfigurationGroups',
    path: '/admin/charge/chargeconfigurationgroups',
    component: ListChargeConfigurationGroups,
    meta: {
      title: 'Charge Configuration Groups',
      auth: [roles.LZAdmin, roles.Finance, roles.Implementations]
    }
  },
  {
    name: 'ChargeConfigurationGroupCreate',
    path: '/admin/charge/chargeconfigurationgroups/create',
    component: ChargeConfigurationGroupEdit,
    meta: {
      title: 'Create Charge Configuration Group',
      auth: [roles.LZAdmin, roles.Finance, roles.Implementations]
    },
    props: { status: 'Create' }
  },
  {
    name: 'ChargeConfigurationGroupEdit',
    path: '/admin/charge/chargeconfigurationgroups/edit/:id',
    component: ChargeConfigurationGroupEdit,
    meta: {
      title: 'Edit Charge Configuration Group',
      auth: [roles.LZAdmin, roles.Finance, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  {
    name: 'ReportSubscriptions',
    path: '/admin/clients/reportsubscriptions',
    component: ReportSubscriptions,
    meta: {
      title: 'Report Subscriptions',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.Implementations]
    }
  },
  {
    name: 'PartNumbers',
    path: '/admin/clients/partnumbers',
    component: PartNumbers,
    meta: {
      title: 'Part Numbers',
      auth: [roles.LZAdmin, roles.Finance]
    }
  },
  {
    name: 'CustomerSearch',
    path: '/admin/clients/customerSearch',
    component: CustomerSearch,
    meta: {
      title: 'Search',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.Implementations]
    }
  },

  // #endregion

  // Billing

  {
    name: 'ChargeConfiguration',
    path: '/admin/charge/configuration',
    component: ChargeConfiguration,
    meta: {
      title: 'Charge Configuration',
      auth: [roles.LZAdmin, roles.Finance, roles.Implementations]
    }
  },
  {
    name: 'Charges',
    path: '/admin/charge/charges',
    component: Charges,
    meta: {
      title: 'Charges',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Implementations]
    }
  },
  {
    name: 'ChargeDetail',
    path: '/admin/charge/charges/detail/:id/:showBackButton',
    component: ChargeDetail,
    meta: {
      title: 'Charge Details',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Implementations]
    },
    props: true
  },
  {
    name: 'ChargeCreate',
    path: '/admin/charge/charges/create',
    component: ChargeCreate,
    meta: {
      title: 'Charge Create',
      auth: [roles.LZAdmin, roles.Finance, roles.Implementations]
    }
  },
  {
    name: 'HistoricChargeConfigurationList',
    path: '/admin/charge/historic',
    component: HistoricChargeConfigurationList,
    meta: {
      title: 'Historic Billing Configurations',
      auth: [roles.LZAdmin, roles.Finance, roles.Implementations]
    }
  },
  {
    name: 'HistoricChargeConfiguration',
    path: '/admin/charge/historic/detail/:effectiveDate',
    component: HistoricChargeConfiguration,
    meta: {
      title: 'Historic Billing Configuration',
      auth: [roles.LZAdmin, roles.Finance, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query })
  },

  // Settlements
  {
    name: 'PotentialSettlements',
    path: '/admin/settlements/potential',
    component: PotentialSettlements,
    meta: {
      title: 'Potential Settlements',
      auth: [roles.Finance]
    }
  },

  {
    name: 'ProcessedSettlements',
    path: '/admin/settlements/processed',
    component: ProcessedSettlements,
    meta: {
      title: 'Processed Settlements',
      auth: [roles.Finance]
    }
  },
  {
    name: 'SettlementReport',
    path: '/admin/settlements/report',
    component: SettlementReport,
    meta: {
      title: 'Settlement Report',
      auth: [roles.Finance, roles.Standard, roles.ClientAdmin]
    }
  },
  {
    name: 'SettlementReportDetails',
    path: '/admin/settlements/report/:id',
    component: SettlementReportDetails,
    meta: {
      title: 'Settlement Report Details',
      auth: [roles.Finance, roles.Standard, roles.ClientAdmin]
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  {
    name: 'ProcessBilling',
    path: '/admin/billing/process',
    component: ProcessBilling,
    meta: {
      title: 'Process Billing',
      auth: [roles.Finance]
    }
  },
  {
    name: 'BillingReportJFTP',
    path: '/admin/billing/report',
    component: BillingReportJFTP,
    meta: {
      title: 'Billing Report',
      auth: [roles.Finance]
    }
  },
  {
    name: 'BillingReportDetails',
    path: '/admin/billing/:id',
    component: BillingReportDetails,
    meta: {
      title: 'Billing Report Details',
      auth: [roles.Finance]
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  // Invoicing
  {
    name: 'CreateSingleInvoice',
    path: '/admin/invoicing/single',
    component: CreateSingleInvoice,
    meta: {
      title: 'Create Single Invoices',
      auth: [roles.Finance]
    },
    // eslint-disable-next-line eqeqeq
    props: (route) => ({ ...route.params, ...route.query, oppositeFile: route.query.oppositeFile == 'true', recreate: route.query.recreate == 'true', creditNote: route.query.creditNote == 'true' })
  },
  {
    name: 'CreateMultipleInvoices',
    path: '/admin/invoicing/multiple',
    component: CreateMultipleInvoices,
    meta: {
      title: 'Create Multiple Invoices',
      auth: [roles.Finance]
    }
  },
  {
    name: 'ListInvoices',
    path: '/admin/invoicing/list',
    component: ListInvoices,
    meta: {
      title: 'List Invoices',
      auth: [roles.Finance, roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Qa, roles.Developer, roles.Implementations]
    }
  },
  {
    name: 'InvoiceDetails',
    path: '/admin/invoicing/details/:id',
    component: InvoiceDetails,
    meta: {
      title: 'Invoice Details',
      auth: [roles.Finance, roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Qa, roles.Developer, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  {
    name: 'InvoiceAnalysis',
    path: '/admin/invoicing/analysis',
    component: InvoiceAnalysis,
    meta: {
      title: 'Invoice Analysis',
      auth: [roles.Finance]
    }
  },
  // #region Users
  {
    name: 'UserCreate',
    path: '/admin/users/create',
    component: UserEdit,
    meta: {
      title: 'Create User',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.DevOps, roles.Implementations]
    },
    props: { status: 'Create' }
  },
  {
    name: 'UserEdit',
    path: '/admin/users/edit/:userId',
    component: UserEdit,
    meta: {
      title: 'Edit User',
      auth: [roles.Limited, roles.ReadOnly, roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  {
    name: 'UserDetails',
    path: '/admin/users/details',
    component: UserDetails,
    meta: {
      title: 'User Details',
      auth: [roles.Limited, roles.ReadOnly, roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  {
    name: 'Users',
    path: '/admin/users/list',
    component: ListUsers,
    meta: {
      title: 'Users',
      auth: [roles.Limited, roles.ReadOnly, roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    }
  },
  {
    name: 'ImportUsers',
    path: '/admin/users/import',
    component: ImportUsers,
    meta: {
      title: 'Import Users',
      auth: [roles.LZAdmin, roles.Implementations]
    }
  },
  // #endregion

  // #region Service Accounts
  {
    name: 'ServiceAccounts',
    path: '/admin/serviceaccounts/list',
    component: ListServiceAccounts,
    meta: {
      title: 'Service Accounts',
      auth: [roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    }
  },
  {
    name: 'ServiceAccountCreate',
    path: '/admin/serviceaccounts/create',
    component: ServiceAccountEdit,
    meta: {
      title: 'Create Service Account',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Implementations]
    },
    props: (route) => ({ status: 'Create' })
  },
  {
    name: 'ServiceAccountEdit',
    path: '/admin/serviceaccounts/edit/:serviceAccountId',
    component: ServiceAccountEdit,
    meta: {
      title: 'Edit Service Account',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  {
    name: 'ServiceAccountUserEdit',
    path: '/admin/serviceaccounts/edit/roles/:userId',
    component: UserEdit,
    meta: {
      title: 'Edit Service Account',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  // #endregion

  // #region Groups
  {
    name: 'GroupCreate',
    path: '/admin/groups/create',
    component: GroupEdit,
    meta: {
      title: 'Create Group',
      auth: [roles.LZAdmin, roles.Implementations]
    },
    props: { status: 'Create' }
  },
  {
    name: 'GroupEdit',
    path: '/admin/groups/edit/:groupId',
    component: GroupEdit,
    meta: {
      title: 'Edit Group',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  {
    name: 'GroupDetails',
    path: '/admin/groups/details',
    component: GroupDetails,
    meta: {
      title: 'Group Details'
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  {
    name: 'Groups',
    path: '/admin/groups/list',
    component: ListGroups,
    meta: {
      title: 'Groups',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    }
  },
  {
    name: 'ImportGroups',
    path: '/admin/groups/import',
    component: ImportGroups,
    meta: {
      title: 'Import Groups',
      auth: [roles.LZAdmin, roles.Implementations]
    }
  },
  // #endregion

  // #region Bank Accounts
  {
    name: 'BankAccountCreate',
    path: '/admin/bankaccounts/create',
    component: BankAccountEdit,
    meta: {
      title: 'Create Bank Account',
      auth: [roles.Finance]
    },
    props: { status: 'Create' }
  },
  {
    name: 'BankAccountEdit',
    path: '/admin/bankaccounts/edit/:id',
    component: BankAccountEdit,
    meta: {
      title: 'Edit Bank Account',
      auth: [roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  {
    name: 'BankAccountDetails',
    path: '/admin/bankaccounts/details',
    component: BankAccountDetails,
    meta: {
      title: 'Bank Account Details',
      auth: [roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  {
    name: 'BankAccounts',
    path: '/admin/bankaccounts/list',
    component: ListBankAccounts,
    meta: {
      title: 'Bank Accounts',
      auth: [roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    }
  },
  // #endregion

  // #region SUNs

  {
    name: 'SunCreate',
    path: '/admin/sun/create',
    component: SunEdit,
    meta: {
      title: 'Create A Sun',
      auth: [roles.Finance, roles.Implementations]
    },
    props: { status: 'Create' }
  },
  {
    name: 'SunEdit',
    path: '/admin/sun/edit/:id',
    component: SunEdit,
    meta: {
      title: 'Edit Sun',
      auth: [roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  {
    name: 'Suns',
    path: '/admin/SUNs/list',
    component: ListSuns,
    meta: {
      title: 'SUNs',
      auth: [roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    }
  },

  // #endregion

  // #region Bureau
  {
    name: 'BureauCustomerActionDetails',
    path: '/admin/bureaucustomers/details',
    component: BureauCustomerActionDetails,
    meta: {
      title: 'Bureau Customer Details'
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  // #endregion

  // #region Security Policy
  {
    path: '/admin/securitypolicy/useraccounts',
    component: UserAccountsSecurityPolicy,
    meta: {
      title: 'Security Policy User Accounts',
      auth: [roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer]
    }
  },
  {
    path: '/admin/securitypolicy/bankaccounts',
    component: BankAccountSecurityPolicy,
    meta: {
      title: 'Security Policy Bank Accounts',
      auth: [roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer]
    }
  },
  {
    path: '/admin/securitypolicy/paymentgroups',
    component: PaymentGroupsSecurityPolicy,
    meta: {
      title: 'Security Policy Payment Groups',
      auth: [roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer]
    }
  },
  {
    path: '/admin/securitypolicy/login',
    component: LoginSecurityPolicy,
    meta: {
      title: 'Security Policy Login',
      auth: [roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer]
    }
  },
  {
    path: '/admin/securitypolicy/inactivitytimeout',
    component: InactivityTimeoutPolicy,
    meta: {
      title: 'Security Policy Inactivity Timeout',
      auth: [roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer]
    }
  },
  {
    path: '/admin/securitypolicy/timerestrictions',
    component: DayTimeRestrictionsPolicy,
    meta: {
      title: 'Security Policy Time Restrictions',
      auth: [roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer]
    }
  },
  {
    path: '/admin/securitypolicy/ipaddressrestrictions',
    component: IPAddressRestrictionsPolicy,
    meta: {
      title: 'Security Policy IP Address Restrictions',
      auth: [roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer]
    }
  },
  {
    path: '/admin/securitypolicy/passwordcomplexity',
    component: PasswordComplexityPolicy,
    meta: {
      title: 'Security Policy Password Complexity',
      auth: [roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer]
    }
  },
  {
    path: '/admin/securitypolicy/incorrectpassword',
    component: IncorrectPasswordPolicy,
    meta: {
      title: 'Security Policy Incorrect Password',
      auth: [roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer]
    }
  },

  // #endregion

  // #region Maintenance Windows

  {
    name: 'MaintenanceWindowList',
    path: '/admin/system/maintenancewindows',
    component: MaintenanceWindowsList,
    meta: {
      title: 'Maintenance Windows',
      auth: [roles.DevOps]
    }
  },
  {
    name: 'MaintenanceWindowCreate',
    path: '/admin/system/maintenancewindow/create',
    component: MaintenanceWindowEdit,
    meta: {
      title: 'Create Maintenance Window',
      auth: [roles.DevOps]
    },
    props: { status: 'Create' }
  },
  {
    name: 'MaintenanceWindowEdit',
    path: '/admin/system/maintenancewindow/edit/:id',
    component: MaintenanceWindowEdit,
    meta: {
      title: 'Edit Maintenance Window',
      auth: [roles.DevOps]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },

  // #endregion

  // #region HSM
  {
    path: '/admin/system/listhsm',
    component: ListHsm,
    meta: {
      title: "HSM's",
      auth: roles.DevOps
    }
  },
  {
    name: 'ManageHsm',
    path: '/admin/system/managehsm',
    component: ManageHsm,
    meta: {
      title: 'Manage HSM',
      auth: roles.DevOps
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  // #endregion

  // #region USB Tokens
  {
    name: 'ListUsbTokens',
    path: '/admin/usbtokens',
    component: ListUsbTokens,
    meta: {
      title: 'USB Tokens',
      auth: roles.DevOps,
      licence: [licence.mfa_usb_tokens]
    }
  },
  {
    name: 'AssignUsbToken',
    path: '/admin/usbtokens/assign',
    component: AssignUsbToken,
    meta: {
      title: 'Assign USB Token',
      auth: roles.DevOps,
      licence: [licence.mfa_usb_tokens]
    }
  },
  {
    name: 'ListExpiringUsbTokens',
    path: '/admin/usbtokens/expiring',
    component: ListExpiringUsbTokens,
    meta: {
      title: 'Expiring USB Tokens',
      auth: roles.DevOps,
      licence: [licence.mfa_usb_tokens]
    }
  },

  // #endregion

  {
    name: 'GDPRSearch',
    path: '/admin/GDPR/Search',
    component: GDPRSearch,
    meta: {
      title: 'GDPR Search',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Implementations]
    }
  },
  {
    name: 'DeleteGroupData',
    path: '/admin/GDPR/DeleteGroupData',
    component: DeleteGroupData,
    meta: {
      title: 'Delete Group Data',
      auth: [roles.LZAdmin, roles.Implementations]
    }
  },
  // {
  //   name: 'Ledger',
  //   path: '/admin/bandr/ledger',
  //   component: Ledger,
  //   meta: {
  //     title: 'Delete Group Data',
  //     auth: roles.Finance
  //   }
  // },
  {
    name: 'Bankline Outbound',
    path: '/admin/bandr/bankline/banklineoutbound',
    component: BanklineOutbound,
    meta: {
      title: 'BanklineOutbound',
      auth: roles.Finance
    }
  },
  {
    name: 'Bankline Inbound',
    path: '/admin/bandr/bankline/banklineinbound',
    component: BanklineInbound,
    meta: {
      title: 'BanklineInbound',
      auth: roles.Finance
    }
  },
  {
    name: 'Bankline Notifications',
    path: '/admin/bandr/bankline/banklinenotifications',
    component: BanklineNotifications,
    meta: {
      title: 'BanklineNotifications',
      auth: roles.Finance
    }
  },
  {
    name: 'Bankline File',
    path: '/admin/bandr/bankline/banklineastfile/:id',
    component: BanklineAstFile,
    meta: {
      title: 'BanklineAstFile',
      auth: roles.Finance
    }
  },
  {
    name: 'Bankline File.',
    path: '/admin/bandr/bankline/banklineastmfile/:id',
    component: BanklineAstMFile,
    meta: {
      title: 'BanklineAstMFile',
      auth: roles.Finance
    }
  },
  {
    name: 'Bankline Payment File',
    path: '/admin/bandr/bankline/banklinepaymentfile/:id',
    component: BanklinePaymentFile,
    meta: {
      title: 'BanklinePaymentFile',
      auth: roles.Finance
    }
  },
  {
    name: 'Bankline Balance File',
    path: '/admin/bandr/bankline/banklineabsfile/:id',
    component: BanklineAbsFile,
    meta: {
      title: 'BanklineAbsFile',
      auth: roles.Finance
    }
  },
  {
    name: 'Bankline Row',
    path: '/admin/bandr/bankline/banklineastrow/:id',
    component: BanklineAstRow,
    meta: {
      title: 'BanklineRow',
      auth: roles.Finance
    }
  },
  {
    name: 'Bankline Row Edit',
    path: '/admin/bandr/bankline/banklineastrow/edit/:id',
    component: BanklineAstRow,
    meta: {
      title: 'BanklineRow',
      auth: roles.Finance
    }
  },
  {
    name: 'Bankline Payment Row',
    path: '/admin/bandr/bankline/banklinepaymentrow/:id',
    component: BanklinePaymentRow,
    meta: {
      title: 'BanklinePaymentRow',
      auth: roles.Finance
    }
  },
  {
    name: 'Bankline Balance Row',
    path: '/admin/bandr/bankline/banklineabsrow/:id',
    component: BanklineAbsRow,
    meta: {
      title: 'BanklineRow',
      auth: roles.Finance
    }
  },
  {
    name: 'Bankline Config',
    path: '/admin/bandr/bankline/banklineconfig',
    component: BanklineConfig,
    meta: {
      title: 'BanklineConfig',
      auth: [roles.Finance, roles.DevOps]
    }
  },
  {
    name: 'Bankline Payment',
    path: '/admin/bandr/bankline/banklinepayment',
    component: BanklinePayment,
    meta: {
      title: 'BanklinePayment',
      auth: roles.Finance
    }
  },
  {
    name: 'Edit Bankline Payment',
    path: '/admin/bandr/bankline/banklinepaymentedit/:id',
    component: BanklinePaymentEdit,
    meta: {
      title: 'BanklinePaymentEdit',
      auth: roles.Finance
    }
  },
  {
    name: 'Bankline CHAPS Payment',
    path: '/admin/bandr/bankline/banklinechapspayment',
    component: BanklineChapsPayment,
    meta: {
      title: 'BanklineChapsPayment',
      auth: roles.Finance
    }
  },
  {
    name: 'Edit Bankline Chaps Payment',
    path: '/admin/bandr/bankline/banklinechapspaymentedit/:id',
    component: BanklineChapsPaymentEdit,
    meta: {
      title: 'BanklineChapsPaymentEdit',
      auth: roles.Finance
    }
  },
  {
    name: 'Balances',
    path: '/admin/bandr/bankline/uploadfile',
    component: UploadBanklineFile,
    meta: {
      title: 'UploadBanklineFile',
      auth: roles.Finance
    }
  },
  // {
  //   name: 'UploadBanklineFile',
  //   path: '/admin/bandr/balances',
  //   component: Balances,
  //   meta: {
  //     title: 'Delete Group Data',
  //     auth: roles.Finance
  //   }
  // },
  {
    name: 'NominalCodes',
    path: '/admin/bandr/nominalcodes',
    component: NominalCodes,
    meta: {
      title: 'Nominal Codes',
      auth: [roles.Finance]
    }
  },
  {
    name: 'Bankline File List',
    path: '/admin/bandr/banklinefilelist',
    component: BanklineFileList,
    meta: {
      title: 'BanklineFileList',
      auth: [roles.Finance]
    }
  },
  {
    name: 'Bankline Transactions',
    path: '/admin/bandr/banklinetransactions/:id',
    component: BanklineTransactions,
    meta: {
      title: 'BanklineTransactions',
      auth: [roles.Finance]
    }
  },
  {
    name: 'Bankline Account Balances',
    path: '/admin/bandr/banklineaccountbalances/:id',
    component: BanklineAccountBalances,
    meta: {
      title: 'BanklineAccountBalances',
      auth: [roles.Finance]
    }
  },
  {
    name: 'Bank Ledger Balances',
    path: '/admin/bandr/bankledgerbalances',
    component: BankLedgerBalances,
    meta: {
      title: 'BankLedgerBalances',
      auth: [roles.Finance]
    }
  },
  {
    name: 'IndemnityClaims',
    path: '/admin/bandr/indemnityclaims',
    component: IndemnityClaims,
    meta: {
      title: 'IndemnityClaims',
      auth: [roles.Finance]
    }
  },
  {
    name: 'Ledger Transactions',
    path: '/admin/bandr/ledgertransactions',
    component: LedgerTransactions,
    meta: {
      title: 'LedgerTransactions',
      auth: [roles.Finance]
    }
  },
  {
    name: 'Client Balances',
    path: '/admin/bandr/clientbalances',
    component: ClientBalances,
    meta: {
      title: 'ClientBalances',
      auth: [roles.Finance]
    }
  },
  {
    name: 'Ledger Client Liability',
    path: '/admin/bandr/ledgerclientliability',
    component: LedgerClientLiability,
    meta: {
      title: 'LedgerClientLiability',
      auth: [roles.Finance]
    }
  },
  {
    name: 'Nominal Code Totals',
    path: '/admin/bandr/nominalCodeTotals',
    component: NominalCodeTotals,
    meta: {
      title: 'NominalCodeTotals',
      auth: [roles.Finance]
    }
  },
  // #region Logging Settings
  {
    path: '/admin/system/logging',
    component: LoggingSettings,
    meta: {
      title: 'Logging Settings',
      auth: [roles.DevOps, roles.Developer]
    }
  },
  // #endregion
  {
    path: '/admin/reports/actionsreport',
    component: ActionReport,
    meta: {
      title: 'Actions Report',
      auth: [roles.ClientAdmin, roles.LZAdmin, roles.Implementations]
    }
  },
  {
    path: '/admin/reports/validatereport',
    component: ValidateReport,
    meta: {
      title: 'Validate Report',
      auth: roles.Finance
    }
  },
  {
    path: '/admin/reports/liability',
    component: LiabilityReport,
    meta: {
      title: 'Liability Report',
      auth: roles.Finance
    }
  },

  {
    path: '/admin/reports/monthlyfees',
    component: MonthlyFeesReport,
    meta: {
      title: 'Monthly Fees Report',
      auth: roles.Finance
    }
  },

  {
    path: '/admin/reports/psadhoc',
    component: PsAdHocFeesReport,
    meta: {
      title: 'PS and Ad-Hoc Fees Report',
      auth: roles.Finance
    }
  },
  {
    path: '/admin/reports/holding',
    component: HoldingReport,
    meta: {
      title: 'Holding Report',
      auth: roles.Finance
    }
  },

  {
    path: '/admin/email/bounces',
    component: Bounces,
    meta: {
      title: 'Email Bounces',
      auth: roles.SystemUser
    }
  },
  {
    path: '/admin/email/bounces/:bounceId',
    props: true,
    component: BounceReport,
    meta: {
      title: 'Email Bounces',
      auth: roles.SystemUser
    }
  },
  {
    path: '/actions/info',
    component: InfoAction,
    props: true,
    meta: {}
  },
  {
    path: '/admin/system/emailmonitoring',
    component: SetUsers,
    props: true,
    meta: {
      title: 'Email Monitoring'
    }
  },
  {
    path: '/admin/system/onboardingapiusage',
    component: OnboardingApiUsage,
    props: true,
    meta: {
      title: 'Onboarding Api Usage'
    }
  },
  {
    path: '/admin/system/stories',
    component: ListStories,
    props: true,
    meta: {
      title: 'Manage News Stories',
      auth: [roles.Marketing, roles.DevOps]
    }
  },
  {
    name: 'CreateNewsStory',
    path: '/admin/system/story/Create',
    component: EditStory,
    meta: {
      title: 'Create News Story',
      auth: [roles.Marketing, roles.DevOps]
    },
    props: { status: 'Create' }
  },
  {
    name: 'EditNewsStory',
    path: '/admin/system/story/:id',
    component: EditStory,
    meta: {
      title: 'Edit News Story',
      auth: [roles.Marketing, roles.DevOps]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  {
    path: '/news/:id',
    component: News,
    props: true,
    meta: {
      title: 'View News Stories'
    }
  },
  {
    path: '/admin/system/appsettings',
    component: AppSettings,
    meta: {
      title: 'Edit App Settings',
      auth: roles.DevOps
    }
  },
  {
    name: 'ListVat',
    path: '/admin/system/vat',
    component: ListVat,
    meta: {
      title: 'List VAT',
      auth: [roles.Finance, roles.LZAdmin, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  {
    name: 'CreateVat',
    path: '/admin/system/vat/create',
    component: VatEdit,
    meta: {
      title: 'Create VAT',
      auth: [roles.Finance, roles.LZAdmin, roles.Implementations]
    },
    props: { status: 'Create' }
  },
  {
    name: 'EditVat',
    path: '/admin/system/vat/edit/:id',
    component: VatEdit,
    meta: {
      title: 'Edit VAT',
      auth: [roles.Finance, roles.LZAdmin, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  {
    name: 'RecurringChargesSettings',
    path: '/admin/system/reccuringchargesettings',
    component: RecurringChargesSettings,
    meta: {
      title: 'Recurring Charges Settings',
      auth: [roles.Finance, roles.LZAdmin, roles.Implementations]
    }
  },
  // #region Anti-Fraud List
  {
    name: 'AntiFraudLists',
    path: '/admin/antifraud/list',
    component: ListAntiFraudLists,
    meta: {
      title: 'Anti-Fraud Lists',
      auth: [roles.ManageAntiFraud]
    }
  },
  {
    name: 'AntiFraudListCreate',
    path: '/admin/antifraud/create',
    component: AntiFraudListEdit,
    meta: {
      title: 'Create Anti-Fraud List',
      auth: [roles.ManageAntiFraud]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Create' })
  },
  {
    name: 'AntiFraudListEdit',
    path: '/admin/antifraud/edit/:id',
    component: AntiFraudListEdit,
    meta: {
      title: 'Edit Anti-Fraud List',
      auth: [roles.ManageAntiFraud]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  {
    name: 'AntiFraudListDetails',
    path: '/admin/antifraud/details',
    component: AntiFraudListDetails,
    meta: {
      title: 'Anti-Fraud List Details'
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  // endregion
  // {
  //   name: 'PayerThresholdEmails',
  //   path: '/admin/customerthreshold/emails',
  //   component: ThresholdAddresses,
  //   meta: {
  //     title: 'Set Customer Threshold Outgoing Emails',
  //     auth: [ roles.LZAdmin, roles.CustomerAdministrator, roles.Implementations]
  //   }
  // },
  {
    path: '/admin/system/ImportEISCD',
    component: EISCDImport,
    meta: {
      title: 'EISCD Import',
      auth: roles.DevOps
    }
  },
  {
    path: '/admin/system/ImportWeightTable',
    component: WeightTableImport,
    meta: {
      title: 'Weight Table Import',
      auth: roles.DevOps
    }
  },
  {
    path: '/admin/system/logging/view',
    props: true,
    component: LogViewer,
    meta: {
      title: 'View Logs',
      auth: [roles.DevOps, roles.Qa, roles.Developer, roles.LZAdmin, roles.Implementations]
    }
  },
  {
    path: '/admin/system/eventlog/eventlog',
    props: true,
    component: EventLog,
    meta: {
      title: 'Event Log',
      auth: [roles.LZAdmin, roles.SystemUser, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    }
  },
  {
    path: '/admin/system/eventlog/eventlogconfig',
    props: true,
    component: EventLogConfig,
    meta: {
      title: 'Event Log Config',
      auth: [roles.DevOps]
    }
  },
  {
    path: '/admin/system/eventlog/eventview/:id',
    props: true,
    component: EventView,
    meta: {
      title: 'Event View',
      auth: [roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]
    }
  },
  {
    name: 'ListSecureLimits',
    path: '/admin/system/securelimits',
    component: ListSecureLimits,
    meta: {
      title: 'List Secure Limits',
      auth: [roles.Finance, roles.Implementations]
    }
  },
  {
    name: 'SecureLimitsCreate',
    path: '/admin/system/securelimits/create',
    component: SecureLimitsEdit,
    meta: {
      title: 'Create Secure Limits',
      auth: [roles.Finance, roles.Implementations]
    },
    props: { status: 'Create' }
  },
  {
    name: 'SecureLimitsEdit',
    path: '/admin/system/securelimits/edit/:id',
    component: SecureLimitsEdit,
    meta: {
      title: 'Edit Secure Limits',
      auth: [roles.Finance, roles.Implementations]
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },
  // {
  //   path: '/admin/system/devops',
  //   props: true,
  //   component: DevOpsDashboard,
  //   meta: {
  //     title: 'DevOps Dashboard',
  //     auth: roles.SystemUser
  //   }
  // },
  {
    path: '/admin/collections/templateapproval',
    name: 'Template Approval',
    props: true,
    component: TemplateApproval,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Template Approval',
      auth: [],
      title: 'Collections / Messages / Template Approval'
    }
  },
  {
    path: '/admin/collections/templateaction/:templateId',
    name: 'Template Action',
    props: true,
    component: TemplateAction,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Template Approval Action',
      auth: [],
      title: 'Collections / Messages / Template Approval Action'
    }
  },
  {
    path: '/admin/collections/templateapproval/:templateId',
    name: 'Template Action',
    props: true,
    component: TemplateApprovalAction,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Template Approval Action',
      auth: [],
      title: 'Collections / Messages / Template Approval Action'
    }
  },

  // #region Commission Agents

  {
    name: 'CommissionAgentListAgent',
    path: '/admin/commissionagents/agents',
    props: false,
    component: CommissionAgentListAgent,
    meta: {
      title: 'Commission Agents',
      auth: roles.Finance
    }
  },

  {
    name: 'CommissionAgentCreateAgent',
    path: '/admin/commissionagents/agents/create',
    props: false,
    component: CommissionAgentCreateAgent,
    meta: {
      title: 'Create Agent',
      auth: roles.Finance
    }
  },

  {
    name: 'CommissionAgentAdministration',
    path: '/admin/commissionagents/agent/:id',
    component: CommissionAgentAdministration,
    meta: {
      title: 'Commission Agents',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },

  {
    name: 'CommissionAgentCreateContact',
    path: '/admin/commissionagents/agent/createcontact/:id',
    component: CommissionAgentCreateContact,
    meta: {
      title: 'Commission Agents',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },

  {
    name: 'CommissionAgentContactAdministration',
    path: '/admin/commissionagents/agent/contacts/:agentid/:contactid',
    component: CommissionAgentContactAdministration,
    meta: {
      title: 'Commission Agents',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  {
    name: 'CommissionAgentCreateAssociation',
    path: '/admin/commissionagents/agent/createassociation/:agentid',
    component: CommissionAgentCreateAssociation,
    meta: {
      title: 'Commission Agents',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },

  {
    name: 'CommissionAgentViewAssociation',
    path: '/admin/commissionagents/agent/viewassociation/:associationid',
    component: CommissionAgentViewAssociation,
    meta: {
      title: 'Commission Agents',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  {
    name: 'CommissionAgentViewAssociationCommissionEdit',
    path: '/admin/commissionagents/agent/viewassociation/:associationid/:commissionid',
    component: CommissionAgentViewAssociationCommissionEdit,
    meta: {
      title: 'Commission Agents',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  {
    name: 'CommissionAgentListAssociations',
    path: '/admin/commissionagents/agent/listassociations/:agentid',
    component: CommissionAgentListAssociations,
    meta: {
      title: 'Commission Agents',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },

  {
    name: 'CommissionAgentListCommissions',
    path: '/admin/commissionagents/commissions',
    // props: false,
    component: CommissionAgentListCommissions,
    meta: {
      title: 'Commissions',
      auth: roles.Finance
    }
  },

  {
    name: 'CommissionAgentCommissionEdit',
    path: '/admin/commissionagents/commissions/edit/:commissionid',
    component: CommissionAgentCommissionEdit,
    meta: {
      title: 'Commission Agents',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },

  {
    name: 'CommissionAgentCommissionAdd',
    path: '/admin/commissionagents/commissions/add',
    props: false,
    component: CommissionAgentCommissionAdd,
    meta: {
      title: 'Commissions',
      auth: roles.Finance
    }
  },
  {
    name: 'CommissionAgentListStatements',
    path: '/admin/commissionagents/statements',
    props: false,
    component: CommissionAgentListStatements,
    meta: {
      title: 'Commissions',
      auth: roles.Finance
    }
  },
  {
    name: 'CommissionAgentListStatementsSelect',
    path: '/admin/commissionagents/statements/:statementid',
    component: CommissionAgentListStatementsSelect,
    meta: {
      title: 'Commissions',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },
  {
    name: 'CommissionAgentListCommissionForStatement',
    path: '/admin/commissionagents/statements/commissions/:statementid',
    component: CommissionAgentListCommissionForStatement,
    meta: {
      title: 'Commissions',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },

  {
    name: 'CommissionAgentStatmentCommissionAdd',
    path: '/admin/commissionagents/statements/create/:statementid',
    component: CommissionAgentStatmentCommissionAdd,
    meta: {
      title: 'Commissions',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },

  {
    name: 'CommissionAgentListStatementAuditTrail',
    path: '/admin/commissionagents/statementaudittrail',
    props: false,
    component: CommissionAgentListStatementAuditTrail,
    meta: {
      title: 'Audit Trail',
      auth: roles.Finance
    }
  },

  {
    name: 'CommissionAgentAuditTrailStatement',
    path: '/admin/commissionagents/audittrailstatement/:id',
    component: CommissionAgentAuditTrailStatement,
    meta: {
      title: 'Commissions',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },

  {
    name: 'CommissionAgentAuditTrailEmail',
    path: '/admin/commissionagents/audittrailemail/:id',
    component: CommissionAgentAuditTrailEmail,
    meta: {
      title: 'Commissions',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },

  {
    name: 'CommissionAgentAuditTrailAgent',
    path: '/admin/commissionagents/audittrailagent/:id',
    component: CommissionAgentAuditTrailAgent,
    meta: {
      title: 'Commissions',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query })
  },

  {
    name: 'CommissionAgentContactAdministrationFromAuditTrail',
    path: '/admin/commissionagents/agentaudittrail/contacts/:agentid/:contactid',
    component: CommissionAgentContactAdministrationFromAuditTrail,
    meta: {
      title: 'Commissions',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'FromAgentAuditTrail' })
  },

  {
    name: 'CommissionAgentViewAssociationFromAuditTrail',
    path: '/admin/commissionagents/agentaudittrail/viewassociation/:associationid',
    component: CommissionAgentViewAssociationFromAuditTrail,
    meta: {
      title: 'Commission Agents',
      auth: roles.Finance
    },
    props: (route) => ({ ...route.params, ...route.query, status: 'FromAgentAuditTrail' })
  },

  // Commission Agent Reports
  {
    name: 'CommissionAgentPaymentDetailReport',
    path: '/admin/commissionagents/reports/paymentdetailreport',
    props: false,
    component: CommissionAgentPaymentDetailReport,
    meta: {
      title: 'Commissions',
      auth: roles.Finance
    }
  },
  {
    name: 'CommissionAgentCommissionComparisonReport',
    path: '/admin/commissionagents/reports/commissioncomparisonreport',
    props: false,
    component: CommissionAgentCommissionComparisonReport,
    meta: {
      title: 'Commissions',
      auth: roles.Finance
    }
  },

  // #endregion

  {
    name: 'ReportProcessorStatus',
    path: '/payments/bacsReports/reportProcessorStatus',
    component: ReportProcessorStatus,
    meta: {
      title: 'Report Processor Status',
      auth: [roles.DevOps]
    }
  },
  {
    name: 'CreateTestData',
    path: '/admin/system/createtestdata',
    component: CreateTestData,
    meta: {
      title: 'Create Test Data',
      auth: [roles.Developer, roles.Qa]
    }
  }
]
