<template>
  <div id="reportProcessorStatus">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Report Processor Status
              <span class="pull-right">
              </span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Status</strong>
              </div>
              <div class="col-md-8">
                {{ reportProcessorStatus }}
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <div class="row form-group">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="unlockReportProcessor"
                  :disabled="!isReportProcessorRunning"
                >Unlock Report Processor</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  
  export default {
    data () {
      return {
        isReportProcessorRunning: false,
        reportProcessorStatus: ''
      }
    },
  
    methods: {
      async getReportProcessorStatus () {
        var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getReportProcessorStatus`)
        if (response.data !== null) {
          this.isReportProcessorRunning = response.data.isReportProcessorRunning
          if (this.isReportProcessorRunning) {
            this.reportProcessorStatus = 'Report Processor is running'
          } else {
            this.reportProcessorStatus = 'Report Processor is not running so does not need to be unlocked'
          }
        }
      },
  
      async unlockReportProcessor () {
        var response = await axios.post(`${process.env.VUE_APP_BACS_API_URL}bacs/report/unlockReportProcessor`)
        if (response.data !== null) {
          if (response.data.unlockResult.status === 'Deleted') {
            this.isReportProcessorRunning = false
            this.reportProcessorStatus = 'Report Processor has been unlocked'
          }
        }
      }
    },
   
    async mounted () {
      this.getReportProcessorStatus()
    }
  }
  </script>
  