<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Bureau Customer Verification
          <help-icon></help-icon>
          <favourite-icon></favourite-icon>
        </h2>
        <div class="col-md-12">
          <div class="group-colour" :style="{ 'background-color': 'black' }"></div>
          <span>
            Filename:
            <b>"{{ Filename }}"</b>
          </span>
        </div>

        <div class="col-md-12">
          <div class="group-colour" :style="{ 'background-color': 'black' }"></div>
          <span>
            <b>{{ totalRecordsInImport }}</b> bureau customers contained in
            import
          </span>
        </div>

        <div class="col-md-12">
          <div class="group-colour" :style="{ 'background-color': 'green' }"></div>
          <span>
            <b>{{ TotalRecordsVerified }}</b>
            can be imported
          </span>
        </div>
        <div class="col-md-12">
          <div class="group-colour" :style="{ 'background-color': 'red' }"></div>
          <span>
            <b>{{ TotalRecordsNeedVerification }}</b> contain issues that need
            be addressed
          </span>
        </div>
        <div class="col-md-12">
          <div class="group-colour" :style="{ 'background-color': 'orange' }"></div>
          <span>
            <b>{{ TotalRecordsDeleted }}</b> records are removed from the import
          </span>
        </div>
      </div>

      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :rows="rows"
          :columns="columns"
          :searchOptions="{ enabled: true }"
          mode="remote"
          :totalRows="totalRecords"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          @on-sort-change="onSortChange"
          ref="table"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
        >
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'name'">
              <div
                v-if="props.row.deleted"
                class="group-colour"
                :style="{ 'background-color': 'orange' }"
              ></div>
              <div
                v-else-if="props.row.errorlevel > 0"
                class="group-colour"
                :style="{ 'background-color': 'red' }"
              ></div>
              <div v-else class="group-colour" :style="{ 'background-color': 'green' }"></div>
              <span v-if="props.row.deleted" class="pl-2">
                <small>
                  <i>{{ props.row.name }}</i>
                </small>
              </span>
              <span v-else>{{ props.row.name }}</span>
            </span>

            <span v-else-if="props.column.field === 'serviceUserNumber'">
              <span v-if="props.row.deleted" class="pl-2">
                <small>
                  <i>{{ props.row.serviceUserNumber }}</i>
                </small>
              </span>
              <span v-else>{{ props.row.serviceUserNumber }}</span>
            </span>
            <span v-else-if="props.column.field === 'errorMessages'">
              <span v-if="props.row.bureauImportStatus == 'Editing'">
                <span v-if="props.row.deleted === true">
                  <small>
                    <i>Removed from import</i>
                  </small>
                </span>
                <ul
                  v-else-if="
                    props.row.errorlevel > 0 && props.row.deleted === false
                  "
                >
                  <li v-for="error in props.row.errorMessages" :key="error">
                    <i>{{ error }}</i>
                  </li>
                </ul>
              </span>
              <span v-else style="color: red">{{ props.row.bureauImportStatus }}</span>
            </span>
            <span v-else-if="props.column.field === 'actions'">
              <button
                class="fa fa-edit mr-1"
                id="onRecordClick"
                style="color: green; padding: 0; border: none; background: none"
                @click="onRecordClick(props.row)"
                title="Edit this Bureau Customer"
                :disabled="props.row.bureauImportStatus !== 'Editing'"
              ></button>

              <button
                v-if="props.row.deleted"
                class="fa fa-trash-restore mr-1"
                id="onRecordRestore"
                style="
                  color: orange;
                  padding: 0;
                  border: none;
                  background: none;
                "
                title="Restore this Bureau Customer to import"
                :disabled="props.row.bureauImportStatus !== 'Editing'"
                @click="onRecordRestore(props.row)"
              ></button>
              <button
                v-else
                class="fa fa-trash-alt mr-1"
                id="onRecordDelete"
                style="color: red; padding: 0; border: none; background: none"
                title="Remove this Bureau Customer from import"
                @click="onRecordDelete(props.row)"
                :disabled="props.row.bureauImportStatus !== 'Editing'"
              ></button>

              <button
                v-if="props.row.deleted"
                class="fa fa-file-import mr-1 disabled"
                id="onImportSingleDisabled"
                title="Cannot import this Bureau Customer while deleted"
                style="color: grey; padding: 0; border: none; background: none"
                :disabled="props.row.bureauImportStatus !== 'Editing'"
              ></button>

              <button
                v-else
                class="fa fa-file-import mr-1"
                id="onImportSingle"
                style="color: black; padding: 0; border: none; background: none"
                title="Import just this Bureau Customer"
                @click="onImportSingle(props.row)"
                :disabled="props.row.bureauImportStatus !== 'Editing'"
              ></button>
            </span>
            <span v-else-if="props.column.field === 'checkbox'">
              <p-check
                class="p-default p-curve"
                @change="addToBCArray(props.row)"
                :disabled="
                  props.row.deleted === true ||
                  props.row.bureauImportStatus !== 'Editing'
                "
              ></p-check>
            </span>
          </template>
        </vue-good-table>
      </div>

      <div class="idb-block-footer">
        <span v-if="this.checkboxesSelected === true">
          <button class="btn btn-primary" @click="onVerifySelectedRecords()">Verify Selected</button>
          <button class="btn btn-primary" @click="onImportSelectedRecords()">Import Selected</button>
        </span>
        <span v-else>
          <button class="btn btn-primary" @click="onVerifyRecords()">Verify Records</button>
          <button class="btn btn-primary" @click="onImportClick()">Complete Import</button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import _ from 'lodash'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import auth from '@/Assets/Components/Authentication/auth.js'
import Utility from '@/Assets/Mixins/Utility'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import colours from '@/Assets/Constants/colours'

export default {
  mixins: [
    Utility,
    tableFilterMixin
  ],
  data () {
    return {
      BureauImportStatus: {},
      bureauCustomerData: {},
      importSessionId: '',
      BureauCustomerImportIds: [],
      Filename: '',
      totalRecordsInImport: 0,
      totalRecords: 0,
      totalPages: 0,
      TotalRecordsVerified: 0,
      TotalRecordsNeedVerification: 0,
      TotalRecordsDeleted: 0,
      columns: [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber'
        },
        {
          label: 'Status',
          field: 'errorMessages'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false
        },
        {
          label: '',
          field: 'checkbox',
          sortable: false
        }
      ],
      rows: [],
      serverParams: {
        sort: [{ field: 'errorLevel', type: 'desc' }]
      },
      checkboxesSelected: false,

      // signalR stuff
      writebackStatusHubUrl: '',
      writebackStatusConnection: null
    }
  },
  watch: {
    'serverParams.perPage': function (val, oldVal) {
      this.load()
    }
  },
  async mounted () {
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        this.writebackStatusConnection = new HubConnectionBuilder().withUrl(this.writebackStatusHubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.writebackStatusConnection.start()
        this.writebackStatusConnection.on('RefreshWritebackStatus', data => {
          console.log('signalR says: ', data)
          this.updateBureauCustomerStatus(data)
        })
        // this.writebackStatusConnection.on('UploadComplete', data => {
        //   this.$toastr.s(data.originalFileName + '. successfully uploaded')
        // })
      })
  },
  created () {
    this.BureauImportStatus = this.loadBureauImportStatus()
    this.writebackStatusHubUrl = process.env.VUE_APP_BUREAU_API_URL + 'hubs/writebackstatushub'

    if (this.$route.params.id === '00000000-0000-0000-0000-000000000000') {
      // push it back towards the imported customer list
    } else {
      this.importSessionId = this.$route.params.id
      this.getFileDetails()
      this.load()
    }
  },

  methods: {
    updateBureauCustomerStatus (data) {
      if (data.bureauCustomerId === this.importSessionId) {
        if (data.recordStatus === 'AllComplete') {
          this.$router.push({ name: 'ImportBureauCustomers' })
        }
      } else {
        for (var j = 0; j < this.bureauCustomerData.length; j++) {
          if (this.bureauCustomerData[j].bureauCustomerId === data.bureauCustomerId) {
            this.bureauCustomerData[j].bureauImportStatus = data.recordStatus
          }
        }
      }
    },

    onRecordClick (item) {
      this.$router.push({ name: 'ImportBureauCustomerEdit', params: { id: item.bureauCustomerId } })
    },
    addToBCArray (item) {
      if (!_.includes(this.BureauCustomerImportIds, item.bureauCustomerId)) {
        this.BureauCustomerImportIds.push(item.bureauCustomerId)
      } else {
        var idString = item.bureauCustomerId + ''
        var i = 0
        var Arr = []
        for (i = 0; i < this.BureauCustomerImportIds.length; i++) {
          if (this.BureauCustomerImportIds[i] !== idString) {
            Arr.push(this.BureauCustomerImportIds[i])
          }
        }
        this.BureauCustomerImportIds = Arr
      }
      this.checkboxesSelected = !(this.BureauCustomerImportIds === 0)
    },
    onRecordDelete: async function (item) {
      var result = await swal({
        title: 'Remove this Bureau Customer',
        text: 'Are you sure you want to remove this bureau customer from the import?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result) {
        await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/removeBureauCustomerFromImport',
          { importSessionId: this.importSessionId, bureauCustomerId: item.bureauCustomerId }, { showload: true })
          .then(response => {
            if (response.data.toastType === 2) {
              this.$toastr.s(response.data.toastMessage)
            } else {
              this.$toastr.e(response.data.toastMessage)
            }
            this.load()
          })
      }
      await this.getFileDetails()
    },
    onRecordRestore: async function (item) {
      var result = await swal({
        title: 'Restore Bureau Customer into Import',
        text: 'Are you sure you want to restore this bureau customer back into the import?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result) {
        await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/restoreBureauCustomerToImport',
          { importSessionId: this.importSessionId, bureauCustomerId: item.bureauCustomerId }, { showload: true })
          .then(response => {
            if (response.data.toastType === 2) {
              this.$toastr.s(response.data.toastMessage)
            } else {
              this.$toastr.e(response.data.toastMessage)
            }
            this.load()
          })
      }
      await this.getFileDetails()
    },

    onUpdateClick: async function (item) {
      var params = {
        params: {
          importSessionId: this.ImportSessionId,
          bureauCustomerId: this.BureauCustomerId,
          name: item.name,
          serviceusernumber: item.serviceusernumber
        }
      }
      await axios.put(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/quickUpdateBureauCustomer', params, { showload: true })
        .then(response => {
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
          this.$router.push({ name: 'Import', params: { id: this.ImportSessionId } })
        })
    },
    getFileDetails: async function () {
      await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/getbureaucustomerimportfiledetails/' + this.importSessionId, { showload: true })
        .then(response => {
          this.Filename = response.data.filename
          this.totalRecordsInImport = response.data.totalRecords
          this.TotalRecordsVerified = response.data.recordsVerified
          this.TotalRecordsNeedVerification = response.data.recordsRequireVerification
          this.TotalRecordsDeleted = response.data.recordsDeleted
        })
        .catch(error => {
          this.$toastr.e(error)
        })
    },
    onVerifySingle: async function (item) {
      var bureauCustomerIdToVerify = []
      bureauCustomerIdToVerify.push(item.bureauCustomerId)
      var params = {
        importSessionId: this.importSessionId,
        bureauCustomerIds: bureauCustomerIdToVerify
      }
      await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/verifyImportedBureauCustomersSubset', params, { showload: true })
        .then(response => {
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
          this.load()
        })
      await this.getFileDetails()
    },
    onImportSingle: async function (item) {
      var custname = item.name

      var result = await swal({
        title: 'Bureau Customer Import',
        text: 'Are you sure you want to import only ' + custname + '?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      var WritebackBureauCustomerImportIds = []
      WritebackBureauCustomerImportIds.push(item.bureauCustomerId)
      var params = {
        importSessionId: this.importSessionId,
        bureauCustomerIds: WritebackBureauCustomerImportIds,
        writebackAll: false
      }

      if (result) {
        await this.PerformWriteback(params)
      }
      await this.getFileDetails()
    },
    onVerifySelectedRecords: async function () {
      if (this.BureauCustomerImportIds.length === 0) { return }

      var params = {
        importSessionId: this.importSessionId,
        bureauCustomerIds: this.BureauCustomerImportIds
      }
      await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/verifyImportedBureauCustomersSubset', params, { showload: true })
        .then(response => {
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
          this.load()
        })
      await this.getFileDetails()
    },
    onImportSelectedRecords: async function () {
      if (this.BureauCustomerImportIds.length === 0) { return }

      var result = await swal({
        title: 'Bureau Customer Import',
        text: 'Are you sure you want to import the selected records?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      var params = {
        importSessionId: this.importSessionId,
        bureauCustomerIds: this.BureauCustomerImportIds,
        writebackAll: false
      }

      if (result) {
        await this.PerformWriteback(params)
      }
      await this.getFileDetails()
    },

    onVerifyRecords: async function () {
      await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomer/verifyImportedBureauCustomers/' + this.importSessionId, { showload: true })
        .then(response => {
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
          this.load()
        })
      await this.getFileDetails()
    },

    onImportClick: async function () {
      var result = await swal({
        title: 'Complete Bureau Customer Import',
        text: 'Are you sure you want to import these bureau customers?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      var params = {
        importSessionId: this.importSessionId,
        bureauCustomerIds: [],
        writebackAll: true
      }

      if (result) {
        this.PerformWriteback(params)
      }
    },

    PerformWriteback: async function (params) {
      await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/writebackBureauCustomer', params, { showload: true })
        .then(response => {
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
          this.load()
          //          this.$router.push({ name: 'ImportBureauCustomerVerify', params: { id: this.importSessionId } })
        })
      await this.getFileDetails()
    },

    // other methods
    async load () {
      console.log('importsessionid', this.importSessionId)
      try {
        this.isTableLoading = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/getbureaucustomerverificationrecordsquery', { params: { ...this.buildGoodTableQuery(), importsessionid: this.importSessionId }, showload: true })
        this.rows = response.data.data
      } catch { } finally {
        this.isTableLoading = false
      }
    },

    async loadOLD () {
      this.isLoading = true
      var query = {
        params: {
          fields: 'name,serviceusernumber,errormessages,bureaucustomerid,errorlevel,deleted,accountnumber,sortcode,ImportSession_Id,problemfields,verifyRequired,bureauImportStatus'
        }
      }
      query.params.paygateId = this.$store.state.common.paygateId
      query.params.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
      query.params.perPage = this.serverParams.perPage
      query.params.page = this.serverParams.currentPage
      query.params.importSessionId = this.importSessionId
      if (this.serverParams.searchKey !== '') {
        query.params.searchFilter = this.serverParams.searchKey
      }
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/getbureaucustomerverificationquery', query, { showload: true })
        this.bureauCustomerData = response.data.data
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
        this.totalPages = response.data.meta.totalPages
        this.currentPage = 1
        this.isLoading = false
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
