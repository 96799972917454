<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>View Association for {{AgentName}}</h2>
      </div>
      <div class="idb-block-content">
        <!-- Customer and date selection -->
        <div class="row form-group">
          <label class="col-form-label col-md-3">Description</label>
          <div class="col-md-6">
            <input
              v-focus
              type="text"
              class="form-control"
              v-model.trim="$v.detail.AssociationName.$model"
              :disabled="true"
            />
            <!-- Validation -->
            <validation-messages
              v-model="$v.detail.AssociationName"
              :an="true"
              name="association description"
            ></validation-messages>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label col-md-3">Customers</label>
          <div class="col-md-6">
            <vue-select
              v-model="$v.selectedCustomer.$model"
              :options="customers"
              label="customerName"
              :closeOnSelect="true"
              @input="selectCustomer()"
              :disabled="true"
            ></vue-select>
          </div>
        </div>

        <!-- Dates -->

        <div class="row form-group">
          <label class="col-form-label col-md-3">Effective Date</label>
          <div class="col-md-4 col-sm-6">
            <transition name="fadein">
              <div v-if="dateType=='date'" class="right-inner-addon">
                <datepicker
                  id="datePicker"
                  v-model="selectedDate"
                  :disabled-dates="disabledDates"
                  format="dd/MM/yyyy"
                  input-class="form-control datepicker"
                  @input="onUpdateModelDates()"
                  :disabled="true"
                />
                <i class="fa fa-calendar"></i>
              </div>
            </transition>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-3">Add an End Date to this Commission Record</label>
          <div class="col-md-6">
            <p-check class="p-switch p-fill" color="primary" v-model="EndDateEnable"></p-check>
          </div>
        </div>

        <div class="row form-group" v-if="EndDateEnable">
          <label class="col-form-label col-md-3">End Date</label>
          <div class="col-md-4 col-sm-6">
            <transition name="fadein">
              <div v-if="dateType=='date'" class="right-inner-addon">
                <datepicker
                  id="endDatePicker"
                  v-model="selectedEndDate"
                  :disabled-dates="disabledEndDates"
                  format="dd/MM/yyyy"
                  input-class="form-control datepicker"
                  @input="onUpdateModelDates()"
                />
                <i class="fa fa-calendar"></i>
              </div>
            </transition>
          </div>
          <div class="col-md-2 col-sm-12">
            <h6
              v-show="endDateWarnVisible"
              class="text-warning"
            >Please verify this date as it has changed</h6>
          </div>
        </div>

        <!-- Percentage and fixed fee selections -->
        <div role="tablist">
          <!-- Processing Fees -->

          <b-card no-body>
            <b-card-header header-tag="header" class="p-2" role="tab">
              <b-row v-b-toggle="'processing'" class="payment-header pt-0">
                <b-col label cols="12">Processing Fees</b-col>
              </b-row>
            </b-card-header>

            <b-collapse
              :id="'processing'"
              accordion="my-accordion"
              @show="onShowProcessing()"
              @hide="onHideProcessing()"
              role="tabpanel"
            >
              <!-- Advance Notice Letter -->
              <div class="row form-group">
                <label class="label-control col-md-3">Advance Notice Letter</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.AdvanceNoticeLetterPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="AdvanceNoticeLetterPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.AdvanceNoticeLetterFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>
              <!-- Advance Notice Email -->
              <div class="row form-group">
                <label class="label-control col-md-3">Advance Notice Email</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.AdvanceNoticeEmailPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="AdvanceNoticeEmailPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.AdvanceNoticeEmailFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Advance Notice SMS -->
              <div class="row form-group">
                <label class="label-control col-md-3">Advance Notice SMS</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.AdvanceNoticeSMSPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="AdvanceNoticeSMSPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.AdvanceNoticeSMSFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Collections -->
              <div class="row form-group">
                <label class="label-control col-md-3">Collections</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.CollectionsPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="CollectionsPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.CollectionsFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Confirmation Letter -->
              <div class="row form-group">
                <label class="label-control col-md-3">Confirmation Letter</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.ConfirmationLetterPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="ConfirmationLetterPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.ConfirmationLetterFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Failed Collections -->
              <div class="row form-group">
                <label class="label-control col-md-3">Failed Collections</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.FailedCollectionsPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="FailedCollectionsPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.FailedCollectionsFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>
              <!-- Indemnity Claims -->
              <div class="row form-group">
                <label class="label-control col-md-3">Indemnity Claims</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.IndemnityClaimsPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="IndemnityClaimsPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.IndemnityClaimsFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Payer Cancellation -->
              <div class="row form-group">
                <label class="label-control col-md-3">Customer Cancellation</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.PayerCancellationPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="PayerCancellationPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.PayerCancellationFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Payer Reinstate -->
              <div class="row form-group">
                <label class="label-control col-md-3">Customer Reinstate</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.PayerReinstatePercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="PayerReinstatePercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.PayerReinstateFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Payer Setup -->
              <div class="row form-group">
                <label class="label-control col-md-3">Customer Setup</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.PayerSetupPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="PayerSetupPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.PayerSetupFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Refunds -->
              <div class="row form-group">
                <label class="label-control col-md-3">Refunds</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.RefundsPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="RefundsPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.RefundsFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Validate Bank Account -->
              <div class="row form-group">
                <label class="label-control col-md-3">Validate Bank Account</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.ValidateBankAccountPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="ValidateBankAccountPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.ValidateBankAccountFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Validate IBAN -->
              <div class="row form-group">
                <label class="label-control col-md-3">Validate IBAN</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.ValidateIBANPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="ValidateIBANPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.ValidateIBANFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>
            </b-collapse>
          </b-card>
          <!-- Add Hoc -->
          <b-card no-body>
            <b-card-header header-tag="header" class="p-2" role="tab">
              <b-row v-b-toggle="'adhoc'" class="payment-header pt-0">
                <b-col label cols="12">Ad-Hoc Fees</b-col>
              </b-row>
            </b-card-header>

            <b-collapse
              :id="'adhoc'"
              accordion="my-accordion"
              @show="onShowAdhoc()"
              @hide="onHideAdhoc()"
              role="tabpanel"
            >
              <!-- Credit Note -->
              <div class="row form-group">
                <label class="label-control col-md-3">Credit Note</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.CreditNotePercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="CreditNotePercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.CreditNoteFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Termination Fee -->
              <div class="row form-group">
                <label class="label-control col-md-3">Termination Fee</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.TerminationFeePercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="TerminationFeePercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.TerminationFeeFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Bankline STD 1 or 2 day payment -->
              <div class="row form-group">
                <label class="label-control col-md-3">Bankline STD 1 or 2 day payment</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.BanklineSTD1Or2Percentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="BanklineSTD1Or2Percentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.BanklineSTD1Or2FixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Bankline STD 0 day payment -->
              <div class="row form-group">
                <label class="label-control col-md-3">Bankline STD 0 day payment</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.BanklineSTD0Percentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="BanklineSTD0Percentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.BanklineSTD0FixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Bankline URG Payment -->
              <div class="row form-group">
                <label class="label-control col-md-3">Bankline URG Payment</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.BanklineUrgentPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="BanklineUrgentPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.BanklineUrgentFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>
            </b-collapse>
          </b-card>

          <!-- Setup Fees -->
          <b-card no-body>
            <b-card-header header-tag="header" class="p-2" role="tab">
              <b-row v-b-toggle="'setupfees'" class="payment-header pt-0">
                <b-col label cols="12">Setup Fees</b-col>
              </b-row>
            </b-card-header>

            <b-collapse
              :id="'setupfees'"
              accordion="my-accordion"
              @show="onShowSetupFees()"
              @hide="onHideSetupFees()"
              role="tabpanel"
            >
              <!-- BACS Migration -->
              <div class="row form-group">
                <label class="label-control col-md-3">BACS Migration</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.BACSMigrationPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="BACSMigrationPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.BACSMigrationFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Customer Set Up -->
              <div class="row form-group">
                <label class="label-control col-md-3">Customer Set Up</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.CustomerSetupPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="CustomerSetupPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.CustomerSetupFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- FM Service User Setup -->
              <div class="row form-group">
                <label class="label-control col-md-3">FM Service User Setup</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.FMServiceUserSetupPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="FMServiceUserSetupPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.FMServiceUserSetupFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Payer Migration -->
              <div class="row form-group">
                <label class="label-control col-md-3">Customer Migration</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.PayerMigrationPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="PayerMigrationPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.PayerMigrationFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Reports -->
              <div class="row form-group">
                <label class="label-control col-md-3">Reports</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.ReportsPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="ReportsPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.ReportsFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- Service User Modification -->
              <div class="row form-group">
                <label class="label-control col-md-3">Service User Modification</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.ServiceUserModificationPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="ServiceUserModificationPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.ServiceUserModificationFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>
            </b-collapse>
          </b-card>

          <!-- Periodic Charges -->
          <b-card no-body>
            <b-card-header header-tag="header" class="p-2" role="tab">
              <b-row v-b-toggle="'periodiccharges'" class="payment-header pt-0">
                <b-col label cols="12">Periodic Charges</b-col>
              </b-row>
            </b-card-header>

            <b-collapse
              :id="'periodiccharges'"
              accordion="my-accordion"
              @show="onShowPeriodicCharges()"
              @hide="onHidePeriodicCharges()"
              role="tabpanel"
            >
              <!-- Admin Charge -->
              <div class="row form-group">
                <label class="label-control col-md-3">Admin Charge</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.AdminChargePercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="AdminChargePercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.AdminChargeFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- BACS File Submission Charge -->
              <div class="row form-group">
                <label class="label-control col-md-3">BACS File Submission Charge</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.BACSFileSubmissionChargePercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="BACSFileSubmissionChargePercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.BACSFileSubmissionChargeFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>

              <!-- OSU Charge -->
              <div class="row form-group">
                <label class="label-control col-md-3">OSU Charge</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.OSUChargePercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="OSUChargePercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.OSUChargeFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-footer">
          <button class="btn btn-primary" @click="onSaveDetailClick">Save</button>

          <button
            v-if="commissionEditEntry"
            class="btn btn-primary pull-right"
            @click="onReturnToCommissionEntry"
            type="button"
          >
            <i class="glyphicon ti-trash rpad"></i>Return To Commission
          </button>

          <button
            v-if="showAuditTrailButton"
            class="btn btn-primary"
            type="submit"
            id="submit-btn"
            @click="onGoToAuditTrail()"
          >Back To AuditTrail</button>
          <button
            v-else
            class="btn btn-danger pull-right"
            @click="onCanceDetailClick"
            type="button"
          >
            <i class="glyphicon ti-trash rpad"></i>Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import {
  required,
  maxLength,
  numeric,
  minValue,
  maxValue,
  between
} from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import VueSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
export default {
  mixins: [loading, tableFilterMixin, bacsMixin],
  components: { VueSelect, TheMask, Datepicker },
  props: {
    associationid: String,
    commissionid: { type: String, default: '' },
    status: String
  },
  data () {
    return {
      dateType: 'date',
      selectedDate: new Date(),
      selectedEndDate: new Date(),
      EndDateEnable: false,
      disabledDates: {

      },
      disabledEndDates: {
        to: new Date(2021, 0, 1),
        from: this.selectedDate
      },
      endDateWarnVisible: false,
      AgentName: '',
      ContactName: '',
      detail: {
        AgentId: '',
        CustomerName: '',
        PaygateId: '',
        EffectiveDate: new Date(),
        EndDate: '',
        AssociationId: '',
        AssociationName: '',
        CreditNotePercentage: '',
        TerminationFeePercentage: '',
        BanklineSTD1Or2Percentage: '',
        BanklineSTD0Percentage: '',
        BanklineUrgentPercentage: '',
        OSUChargePercentage: '',
        BACSFileSubmissionChargePercentage: '',
        AdminChargePercentage: '',
        BACSMigrationPercentage: '',
        CustomerSetupPercentage: '',
        FMServiceUserSetupPercentage: '',
        PayerMigrationPercentage: '',
        ReportsPercentage: '',
        ServiceUserModificationPercentage: '',

        AdvanceNoticeLetterPercentage: '',
        AdvanceNoticeEmailPercentage: '',
        AdvanceNoticeSMSPercentage: '',
        CollectionsPercentage: '',
        ConfirmationLetterPercentage: '',
        FailedCollectionsPercentage: '',
        IndemnityClaimsPercentage: '',
        PayerCancellationPercentage: '',
        PayerReinstatePercentage: '',
        PayerSetupPercentage: '',
        RefundsPercentage: '',
        ValidateBankAccountPercentage: '',
        ValidateIBANPercentage: '',

        CreditNoteFixedFee: '',
        TerminationFeeFixedFee: '',
        BanklineSTD1Or2FixedFee: '',
        BanklineSTD0FixedFee: '',
        BanklineUrgentFixedFee: '',
        OSUChargeFixedFee: '',
        BACSFileSubmissionChargeFixedFee: '',
        AdminChargeFixedFee: '',
        BACSMigrationFixedFee: '',
        CustomerSetupFixedFee: '',
        FMServiceUserSetupFixedFee: '',
        PayerMigrationFixedFee: '',
        ReportsFixedFee: '',
        ServiceUserModificationFixedFee: '',

        AdvanceNoticeLetterFixedFee: '',
        AdvanceNoticeEmailFixedFee: '',
        AdvanceNoticeSMSFixedFee: '',
        CollectionsFixedFee: '',
        ConfirmationLetterFixedFee: '',
        FailedCollectionsFixedFee: '',
        IndemnityClaimsFixedFee: '',
        PayerCancellationFixedFee: '',
        PayerReinstateFixedFee: '',
        PayerSetupFixedFee: '',
        RefundsFixedFee: '',
        ValidateBankAccountFixedFee: '',
        ValidateIBANFixedFee: ''
      },

      selectedCustomer: {},
      customers: [],
      commissionEditEntry: false,
      showAuditTrailButton: false
    }
  },
  created () {
    console.log('associationid', this.associationid)
    console.log('commissionid', this.commissionid)
    if (this.commissionid === undefined || this.commissionid === '') {
      this.commissionEditEntry = false
      console.log('standard view mode')
    } else {
      this.commissionEditEntry = true
      console.log('commission edit view mode')
    }
    if (this.status === 'FromAgentAuditTrail') {
      console.log('From The Audit Trail')
      this.showAuditTrailButton = true
    }
  },
  methods: {
    async load () {
      await this.GetAssociation()
      //      await this.GetScreenControl()
      await this.GetAgent()

      await this.GetCustomerListings()
      this.selectedCustomer = _.find(this.customers, { paygateId: this.detail.PaygateId })
      this.$v.$reset()
    },
    GetAgent: async function () {
      var agentResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetAgent/${this.detail.AgentId}`)
      console.log('agentResponse', agentResponse)
      this.AgentName = agentResponse.data.name
    },
    GetAssociation: async function () {
      // Get customer listings
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetAssociation/${this.associationid}`,
          {
            showerror: true,
            errormessage: 'Failed loading commission agent associations'
          })

        console.log('response get association', response)

        this.AssociationId = this.associationid

        this.detail.AgentId = response.data.agentId
        this.detail.CustomerName = response.data.customerName
        this.detail.PaygateId = response.data.paygateId
        this.detail.EffectiveDate = response.data.effectiveDate
        this.detail.EndDate = response.data.endDate
        this.detail.AssociationName = response.data.associationName
        this.detail.CreditNotePercentage = response.data.creditNotePercentage
        this.detail.TerminationFeePercentage = response.data.terminationFeePercentage
        this.detail.BanklineSTD1Or2Percentage = response.data.banklineSTD1Or2Percentage
        this.detail.BanklineSTD0Percentage = response.data.banklineSTD0Percentage
        this.detail.BanklineUrgentPercentage = response.data.banklineUrgentPercentage
        this.detail.OSUChargePercentage = response.data.osuChargePercentage
        this.detail.BACSFileSubmissionChargePercentage = response.data.bacsFileSubmissionChargePercentage
        this.detail.AdminChargePercentage = response.data.adminChargePercentage
        this.detail.BACSMigrationPercentage = response.data.bacsMigrationPercentage
        this.detail.CustomerSetupPercentage = response.data.customerSetupPercentage
        this.detail.FMServiceUserSetupPercentage = response.data.fmServiceUserSetupPercentage
        this.detail.PayerMigrationPercentage = response.data.payerMigrationPercentage
        this.detail.ReportsPercentage = response.data.reportsPercentage
        this.detail.ServiceUserModificationPercentage = response.data.serviceUserModificationPercentage
        this.detail.AdvanceNoticeLetterPercentage = response.data.advanceNoticeLetterPercentage
        this.detail.AdvanceNoticeEmailPercentage = response.data.advanceNoticeEmailPercentage
        this.detail.AdvanceNoticeSMSPercentage = response.data.advanceNoticeSMSPercentage
        this.detail.CollectionsPercentage = response.data.collectionsPercentage
        this.detail.ConfirmationLetterPercentage = response.data.confirmationLetterPercentage
        this.detail.FailedCollectionsPercentage = response.data.failedCollectionsPercentage
        this.detail.IndemnityClaimsPercentage = response.data.indemnityClaimsPercentage
        this.detail.PayerCancellationPercentage = response.data.payerCancellationPercentage
        this.detail.PayerReinstatePercentage = response.data.payerReinstatePercentage
        this.detail.PayerSetupPercentage = response.data.payerSetupPercentage
        this.detail.RefundsPercentage = response.data.refundsPercentage
        this.detail.ValidateBankAccountPercentage = response.data.validateBankAccountPercentage
        this.detail.ValidateIBANPercentage = response.data.validateIBANPercentage
        this.detail.CreditNoteFixedFee = response.data.creditNoteFixedFee
        this.detail.TerminationFeeFixedFee = response.data.terminationFeeFixedFee
        this.detail.BanklineSTD1Or2FixedFee = response.data.banklineSTD1Or2FixedFee
        this.detail.BanklineSTD0FixedFee = response.data.banklineSTD0FixedFee
        this.detail.BanklineUrgentFixedFee = response.data.banklineUrgentFixedFee
        this.detail.OSUChargeFixedFee = response.data.osuChargeFixedFee
        this.detail.BACSFileSubmissionChargeFixedFee = response.data.bacsFileSubmissionChargeFixedFee
        this.detail.AdminChargeFixedFee = response.data.adminChargeFixedFee
        this.detail.BACSMigrationFixedFee = response.data.bacsMigrationFixedFee
        this.detail.CustomerSetupFixedFee = response.data.customerSetupFixedFee
        this.detail.FMServiceUserSetupFixedFee = response.data.fmServiceUserSetupFixedFee
        this.detail.PayerMigrationFixedFee = response.data.payerMigrationFixedFee
        this.detail.ReportsFixedFee = response.data.reportsFixedFee
        this.detail.ServiceUserModificationFixedFee = response.data.serviceUserModificationFixedFee
        this.detail.AdvanceNoticeLetterFixedFee = response.data.advanceNoticeLetterFixedFee
        this.detail.AdvanceNoticeEmailFixedFee = response.data.advanceNoticeEmailFixedFee
        this.detail.AdvanceNoticeSMSFixedFee = response.data.advanceNoticeSMSFixedFee
        this.detail.CollectionsFixedFee = response.data.collectionsFixedFee
        this.detail.ConfirmationLetterFixedFee = response.data.confirmationLetterFixedFee
        this.detail.FailedCollectionsFixedFee = response.data.failedCollectionsFixedFee
        this.detail.IndemnityClaimsFixedFee = response.data.indemnityClaimsFixedFee
        this.detail.PayerCancellationFixedFee = response.data.payerCancellationFixedFee
        this.detail.PayerReinstateFixedFee = response.data.payerReinstateFixedFee
        this.detail.PayerSetupFixedFee = response.data.payerSetupFixedFee
        this.detail.RefundsFixedFee = response.data.refundsFixedFee
        this.detail.ValidateBankAccountFixedFee = response.data.validateBankAccountFixedFee
        this.detail.ValidateIBANFixedFee = response.data.validateIBANFixedFee
      } catch (e) {
        console.log('get association error', e)
      } finally {

      }
    },

    GetCustomerListings: async function () {
      // Get customer listings
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetCustomers`,
          {
            showerror: true,
            errormessage: 'Failed loading commission agent associations'
          })

        console.log('response', response)
        this.customers = response.data
      } catch { } finally {

      }
    },

    GetScreenControl: async function () {
      // Get customer listings
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CASCGetAssociationScreen/${this.detail.AgentId}/${this.detail.ContactId}`,
          {
            showerror: true,
            errormessage: 'Failed loading commission agent associations'
          })

        console.log('response', response)

        this.AgentName = response.data.agentName
        this.ContactName = response.data.contactName
      } catch { } finally {

      }
    },

    onUpdateModelDates () {
      // Get the dates from the date picker and get them into the model
      var showWarn = false
      this.detail.EffectiveDate = this.selectedDate
      if (this.EndDateEnable) {
        if (this.selectedDate > this.selectedEndDate) {
          console.log('here', moment(this.selectedDate).format('DD/MM/yyyy'))
          this.selectedEndDate = this.selectedDate
          showWarn = true
        }

        this.detail.EndDate = this.selectedEndDate
      } else {
        this.detail.EndDate = ''
      }

      if (showWarn) {
        this.endDateWarnVisible = true
        setTimeout(() => { this.endDateWarnVisible = false }, 5000)
      }
    },
    onReturnToCommissionEntry () {
      // Router push
      this.$router.push({
        name: 'CommissionAgentCommissionEdit',
        params: { commissionid: this.commissionid }
      })
    },
    onGoToAuditTrail () {
      this.$router.push({ name: 'CommissionAgentAuditTrailAgent', params: { id: this.detail.AgentId } })
    },
    onSaveDetailClick: async function () {
      var swalText = ''

      if (this.commissionEditEntry) {
        swalText = 'This will not change the current commission record'
      } else { swalText = 'Definitely save?' }

      console.log('onSaveDetailClick', this.detail)
      this.detail.PaygateId = this.selectedCustomer.paygateId
      // this.detail.AgentId = this.agentid
      this.detail.CustomerName = this.selectedCustomer.customerName
      this.detail.EffectiveDate = this.EffectiveDate
      this.detail.EndDate = this.selectedEndDate
      this.detail.AssociationId = this.associationid

      try {
        this.$nextTick(() => {
          this.$v.$reset()
        })
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAUpdateContactAssociation`, this.detail, { showload: true, showerror: true, errormessage: 'Agent Association failed to create' })
        console.log('response', response)

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
          this.$router.push({ name: 'CommissionAgentListAssociations', params: { agentid: this.detail.AgentId } })
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    onCanceDetailClick () {
      this.$router.push({ name: 'CommissionAgentListAssociations', params: { agentid: this.detail.AgentId } })
    },

    onShowProcessing () { },
    onHideProcessing () { },
    onShowAdhoc () { },
    onHideAdhoc () { },
    onShowSetupFees () { },
    onHideSetupFees () { },
    onShowPeriodicCharges () { },
    onHidePeriodicCharges () { },
    selectCustomer () { },

    OSUChargeCheck2 () {
      if (this.detail.OSUChargePercentage.length > 0 && this.detail.OSUChargeFixedFee.length > 0) {
        return false
      } else { return true }
    }

  },

  validations: {
    selectedCustomer: {},
    detail: {
      AssociationName: { required },
      CreditNotePercentage: { numeric },
      TerminationFeePercentage: { numeric },
      BanklineSTD1Or2Percentage: { numeric },
      BanklineSTD0Percentage: { numeric },
      BanklineUrgentPercentage: { numeric },

      OSUChargePercentage: { numeric },
      BACSFileSubmissionChargePercentage: { numeric },
      AdminChargePercentage: { numeric },

      BACSMigrationPercentage: { numeric },
      CustomerSetupPercentage: { numeric },
      FMServiceUserSetupPercentage: { numeric },
      PayerMigrationPercentage: { numeric },
      ReportsPercentage: { numeric },
      ServiceUserModificationPercentage: { numeric },
      AdvanceNoticeLetterPercentage: { numeric },
      AdvanceNoticeEmailPercentage: { numeric },
      AdvanceNoticeSMSPercentage: { numeric },
      CollectionsPercentage: { numeric },
      ConfirmationLetterPercentage: { numeric },
      FailedCollectionsPercentage: { numeric },
      IndemnityClaimsPercentage: { numeric },
      PayerCancellationPercentage: { numeric },
      PayerReinstatePercentage: { numeric },
      PayerSetupPercentage: { numeric },
      RefundsPercentage: { numeric },
      ValidateBankAccountPercentage: { numeric },
      ValidateIBANPercentage: { numeric },

      CreditNoteFixedFee: { numeric },
      TerminationFeeFixedFee: { numeric },
      BanklineSTD1Or2FixedFee: { numeric },
      BanklineSTD0FixedFee: { numeric },
      BanklineUrgentFixedFee: { numeric },
      OSUChargeFixedFee: { numeric },
      BACSFileSubmissionChargeFixedFee: { numeric },
      AdminChargeFixedFee: { numeric },
      BACSMigrationFixedFee: { numeric },
      CustomerSetupFixedFee: { numeric },
      FMServiceUserSetupFixedFee: { numeric },
      PayerMigrationFixedFee: { numeric },
      ReportsFixedFee: { numeric },
      ServiceUserModificationFixedFee: { numeric },

      AdvanceNoticeLetterFixedFee: { numeric },
      AdvanceNoticeEmailFixedFee: { numeric },
      AdvanceNoticeSMSFixedFee: { numeric },
      CollectionsFixedFee: { numeric },
      ConfirmationLetterFixedFee: { numeric },
      FailedCollectionsFixedFee: { numeric },
      IndemnityClaimsFixedFee: { numeric },
      PayerCancellationFixedFee: { numeric },
      PayerReinstateFixedFee: { numeric },
      PayerSetupFixedFee: { numeric },
      RefundsFixedFee: { numeric },
      ValidateBankAccountFixedFee: { numeric },
      ValidateIBANFixedFee: { numeric }
    }
  }

}
</script>
<style scoped>
td {
  cursor: pointer;
}

#firstPaymentDatePicker,
#endPaymentDatePicker,
.datepicker,
.datepicker:disabled {
  background-color: #ffffff !important;
}
.dark-mode #firstPaymentDatePicker,
.dark-mode #endPaymentDatePicker,
.dark-mode .datepicker,
.dark-mode .datepicker:disabled {
  background-color: transparent !important;
}
.dark-mode .vdp-datepicker,
.dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important;
}
.dark-mode .vdp-datepicker__calendar * .next,
.dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important;
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}

.fadein-enter {
  opacity: 0;
}
.fadein-enter-active {
  transition: opacity 0.6s;
}
.fadein-leave-active {
  transition: opacity 0.6s;
  opacity: 0;
}
</style>
