<template>
  <div class="form-group row" :class="{invalid: value.$error}">
    <label class="label-control col-md-3 required">Bank Account</label>
    <div class="col-md-5">
      <b-form-select v-model="value.$model" :options="bankAccounts" :disabled="disabled"></b-form-select>
      <validation-messages v-model="value" name="bank account"></validation-messages>
    </div>
    <div class="col-md-2" v-if="canViewBanks">
      <button class="btn btn-outline-secondary" type="button" @click="viewGroupBankDetails">View</button>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import roles from '@/Assets/Constants/roles'
import bacsCommon from '@/Lib/BacsCommon.js'

export default {
  props: ['value', 'bankAccounts', 'disabled'],
  computed: {
    canViewBanks () {
      return this.$store.getters.isInRoles([roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Qa, roles.Developer])
    }
  },
  methods: {
    async viewGroupBankDetails () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.value.$model}`, { showload: true, showerror: true, errormessage: 'Failed to get bank account' })
        var bankAccount = response.data
        this.$swal({
          title: 'Bank details',
          html: bacsCommon.viewGroupBankDetails(bankAccount, ''),
          type: 'info',
          animation: false
        })
      } catch { }
    }
  }
}
</script>
