<template>
  <form>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2><help-icon docPath="/paygate-collections/plans/plandesigner/" />Schedule Designer<favourite-icon></favourite-icon></h2>
      </div>
      <div>
        <div class="m-3">
          <div class="row form-group">
            <div class="col-md-2 required">
              Select a Group
            </div>
            <div class="col-md-4">
              <group-select
                v-model="$v.group.$model"
                :groups="customerGroups"
                @input="customerGroupsChanged"
                :clearable="false"
              ></group-select>
              <validation-messages name="Group" v-model="$v.group" />
              </div>
          </div>
          <b-row  class="planRow">
            <div class="tabs row">
              <div class="col-auto col-3 vertical-tab-container" :key="refreshIndex">
                <ul role="tablist" class="nav nav-tabs flex-column w-90 ml-2 vertical-tab-list">
                  <li role="presentation" class="nav-item" v-if="groupPlans === undefined || groupPlans === null || groupPlans.length === 0">
                    There are no schedule templates associated with this group.
                  </li>
                  <li role="presentation" class="nav-item" v-for="(paymentPlan, index) in groupPlans" :key="index">
                    <a role="tab" class="nav-link" :class="activeTabIndex === index ? 'active' : null" @click="updatePlanInput(index)">{{paymentPlan.name}}</a>
                  </li>
                  <li>
                    <button type="button" class="btn mt-4 btn-primary" @click="addNewPaymentPlan" :disabled="isLoading || !canEdit"><i class="fa fa-plus rpad"></i>Add New Template</button>
                  </li>
                </ul>
              </div>
              <div class="tab-content col">
                <payment-plan-input v-if="activePlanValid"
                  :plan="activePlan.plan"
                  :templateView="true"
                  ref="planInput" :insightFeedback="true"
                  @isinvalid="setValidity"
                  :sharedTemplate="group === '00000000-0000-0000-0000-000000000000'"
                                    :is-loading="planUpdating"
                                    @dirtied="dirtied"
                ></payment-plan-input>
              </div>
            </div>
          </b-row>
        </div>
      </div>
      <div class="idb-block-footer">
        <b-btn v-if="activePlanValid" class="float-right ml-1" variant="danger" :disabled="isLoading || !canEdit || activePlan.paymentPlanTemplateId === '00000000-0000-0000-0000-000000000000'" @click.stop="deletePlan">
          <i class="fa fa-trash" aria-hidden="true"></i> Delete
        </b-btn>
        <b-btn v-if="activePlanValid" variant="primary" @click.stop="savePlan" :disabled="!(activePlanValid && activePlan.plan.valid) || isLoading || !canEdit">
          <i class="fa fa-save" aria-hidden="true"></i> Save
        </b-btn>
      </div>
    </div>
  </form>
</template>
<script>
import axios from 'axios'
import Components from '@/Components/Collections'
import { required, String } from 'vuelidate/lib/validators'
import EventBus from '@/Lib/eventBus'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import {mapGetters} from "vuex";
import roles from "@/Assets/Constants/roles";
import rulesMixin from "@/Assets/Mixins/CollectionsRulesMixin";
export default {
  mixins: [loading, rulesMixin],
  components: {
    paymentPlanInput: Components.Payer.PaymentPlan
  },
  data () {
    return {
      newPlanReference: null,
      group: null,
      selectedPaymentPlan: {
        reference: '',
        recurrenceEndType: 0,
        recurrenceType: 0,
        ordinal: 1,
        firstAmount: 0,
        regularAmount: 0,
        totalPayments: 0,
        firstPaymentDate: new Date(),
        giftAid: false,
        variable: false,
        dayOfYear: 1,
        allowDateChoice: false,
        dateRangeAllowed: 2,
        step: 1,
        valid: false,
        contraNarrative: '',
        indirectSun: '',
        customerBankAccountId: '00000000-0000-0000-0000-000000000000',
        agreedDate: null,
        lodgedDate: null,
        terminationDate: null,
        ignoreWarnings: false,
      },
      paymentPlans: [],
      planName: null,
      activeTabIndex: 0,
      refreshIndex: 0,
      planUpdating: false,
      isDirtied: false,
      suppressDirtied : true
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    canEdit() {
      return this.$store.getters.isInRoles([roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard ])
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.rightsGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.rightsGroups).map(x => {
          let obj = {}
          obj.groupId = x.groupId
          obj.name = x.name
          obj.colour = x.colour
          return obj
        })
      }
      ret.unshift({
        'description': '',
        'groupId': '00000000-0000-0000-0000-000000000000',
        'groupType': 'UK DDMS',
        'name': 'Shared Templates',
        'paygateId': this.paygateId,
        'isItemActioned': false,
        'clonedName': null,
        'colour': null
      })
      return ret
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    defaultBankAccount () {
      var account = '00000000-0000-0000-0000-000000000000'
      if (this.$store.getters.groupBankAccounts.length > 0) {
        if (this.$store.getters.groupBankAccounts.filter(x => x.item2).length > 0) {
          account = this.$store.getters.groupBankAccounts.filter(x => x.item2)[0].item1
        } else {
          account = this.$store.getters.groupBankAccounts[0].item1
        }
      }
      return account
    },
    planTemplateTree () {
      return this.$store.getters.planTemplateTree
    },
    defaultPlan () {
      return this.$store.getters.defaultPlan
    },
    groupPlans () {
      this.refreshIndex
      var retVal = []
      if (this.$store.getters.planTemplateTree[this.group] !== null || this.$store.getters.planTemplateTree[this.group] !== undefined) {
        retVal = this.$store.getters.planTemplateTree[this.group]
        if (retVal !== null && retVal !== undefined && retVal.length > 0) {
          for (var i = 0; i < retVal.length; i++) {
            retVal[i].plan.payerReferencePattern = retVal[i].payerReferencePattern
            retVal[i].plan.planTemplateName = retVal[i].name
            retVal[i].plan.allowDateChoice = retVal[i].allowDateChoice
            retVal[i].plan.dateRangeAllowed = retVal[i].dateRangeAllowed
            retVal[i].plan.overrideGroupReference = retVal[i].overrideGroupReference
            retVal[i].plan.groupId = this.group
            retVal[i].plan.valid = true
            retVal[i].plan.onboardingUrl = retVal[i].onboardingUrl ?? ''
            retVal[i].plan.apiKey = retVal[i].apiKey ?? ''
            retVal[i].plan.showSchedule = retVal[i].showSchedule
            if (retVal[i].plan.customerBankAccountId === undefined || retVal[i].plan.customerBankAccountId === null || retVal[i].plan.customerBankAccountId === '00000000-0000-0000-0000-000000000000') {
              retVal[i].plan.customerBankAccountId = this.defaultBankAccount
            }
          }
        }
      }
      return retVal
    },
    activePlan () {
      var retVal = null
      if (this.groupPlans !== undefined && this.groupPlans !== null && this.groupPlans.length > 0) {
        retVal = this.groupPlans[this.activeTabIndex]
      }
      console.log(retVal)
      return retVal
    },
    activePlanValid () {
      return this.activePlan !== null && this.activePlan !== undefined && this.activePlan.plan !== null && this.activePlan.plan !== undefined
    }
  },
  validations: {
    planName: { required },
    newPlanReference: { required, String },
    group: { required }
  },
  async created (e) {
    this.$store.dispatch('getNonProcessingDates')
    this.$store.dispatch('populateAllCollectionsGroups')
    setTimeout(()=> {
      this.suppressDirtied = false
      this.isDirtied = false
    }, 1000)
  },
  async mounted (e) {
    this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
    this.group = '00000000-0000-0000-0000-000000000000'
    await this.$store.dispatch('getAvailableBankAccounts')
    await this.$store.dispatch('buildPlanTemplateTree')

    setTimeout(()=> {
      this.suppressDirtied = false
      this.isDirtied = false
    }, 1000)
  },
  methods: {
    dirtied () {
      if (!this.suppressDirtied) {
        this.isDirtied = true
      }
    },
    async getPlans () {
      this.suppressDirtied = true
      this.planUpdating = true
      var groupId = this.group === null ? '00000000-0000-0000-0000-000000000000' : this.group
      await this.$store.dispatch('getGroupConfigurationFromGroupId', this.group)
      await this.$store.dispatch('updateAvailablePlanTemplates', this.group)
      await this.$store.dispatch('getGroupBankAccounts', this.group)
      var resp = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/group/${groupId}`, { params: { 'paygateid': this.paygateId }, showload: true })
      for (var i = 0; i < resp.data.tree.length; i++) {
        var template = resp.data.tree[i]
        template.plan.payerReferencePattern = template.payerReferencePattern
        template.plan.planTemplateName = template.name
        template.plan.allowDateChoice = template.allowDateChoice
        template.plan.dateRangeAllowed = template.dateRangeAllowed
        template.plan.overrideGroupReference = template.overrideGroupReference
        template.plan.valid = false
        template.plan.onboardingUrl = template.onboardingUrl ?? ''
        template.plan.apiKey = template.apiKey ?? ''
        template.plan.showSchedule = template.showSchedule
        if (template.plan.customerBankAccountId === undefined || template.plan.customerBankAccountId === null || template.plan.customerBankAccountId === '00000000-0000-0000-0000-000000000000') {
          template.plan.customerBankAccountId = this.defaultBankAccount
        }
      }
      this.paymentPlans = resp.data.tree
      this.planUpdating = false
      this.isDirtied = false
      this.suppressDirtied = false
    },
    async addNewPaymentPlan (e) {
      this.planUpdating = true
      var resp = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/`, { groupId: this.group, paygateId: this.paygateId }, { params: { 'paygateid': this.paygateId }, showload: true })
      resp.data.plan.valid = false
      this.selectedPaymentPlan = resp.data
      this.$store.commit('addPlanToTemplateTree', { groupId: this.group, template: this.selectedPaymentPlan })
      this.activeTabIndex = 0
      this.refreshIndex++
      this.planUpdating = false
      this.isDirtied = true
    },
    setPlan () {
      for (var p = 0; p < this.$refs.planInput.length; p++) {
        this.$refs.planInput[p].reset()
      }
    },
    async deletePlan () {
      var e = this.activeTabIndex

      var result = await swal.fire({
        title: 'Are you sure?',
        text: 'Deleting a plan template will prevent the addition of schedules with that name, but existing schedules will remain in the system. Do you want to delete this plan?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        var resp = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/` + this.groupPlans[e].paymentPlanTemplateId, { params: { paygateid: this.paygateId }, showload: true })
        if (resp.data) {
          this.groupPlans.splice(e, 1)
          this.activeTabIndex = -1
          this.$toastr.s('Plan template deleted')
        } else {
          this.$toastr.e('Plan template deletion failed')
        }
      }

      await this.$store.dispatch('buildPlanTemplateTree')
    },
    async savePlan () {
      this.planUpdating = true
      var e = this.activeTabIndex
      let selectedPlan = this.groupPlans[e].plan
      let allowDateChoice = selectedPlan.allowDateChoice
      let selectedDateRange = parseInt(selectedPlan.dateRangeAllowed)
      this.groupPlans[e].templateJson = JSON.stringify(selectedPlan)
      this.groupPlans[e].name = selectedPlan.planTemplateName
      this.groupPlans[e].payerReferencePattern = selectedPlan.payerReferencePattern
      this.groupPlans[e].allowDateChoice = allowDateChoice
      this.groupPlans[e].dateRangeAllowed = selectedDateRange
      this.groupPlans[e].overrideGroupReference =selectedPlan.overrideGroupReference
      this.groupPlans[e].showSchedule =selectedPlan.showSchedule
      try {
        var resp = await axios.put(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/`, this.groupPlans[e], { params: { 'paygateid': this.paygateId }, showload: true })
        this.groupPlans[e] = resp.data
        this.groupPlans[e].plan.payerReferencePattern = resp.data.payerReferencePattern
        this.groupPlans[e].plan.overrideGroupReference = resp.data.overrideGroupReference
        this.groupPlans[e].plan.showSchedule= resp.data.showSchedule
        this.$toastr.s('Schedule template updated')
        await this.$store.dispatch('getGroupBankAccounts', this.group)
        await this.$store.dispatch('buildPlanTemplateTree')
        for (var i = 0; i < this.groupPlans.length; i++) {
          if (this.groupPlans[i].paymentPlanTemplateId === resp.data.plan.planTemplateId) {
            this.activeTabIndex = i
          }
        }
      } catch (e) {
        console.log(e)
        this.$toastr.e('Schedule template update failed')
      }
      this.planUpdating = false
      this.isDirtied = false
      setTimeout(()=> {
        this.suppressDirtied = false
        this.isDirtied = false
      }, 500)
    },
    async customerGroupsChanged (e) {
      this.selectedPaymentPlan = null
      this.activeTabIndex = 0
      // await this.getPlans()
    },
    async generateApiKey () {
      let url = process.env.VUE_APP_DDMS_API_URL + 'plantemplate/apikey/' + this.planTemplateId
      var result = await axios.post(url, {}, { showload: true, params: { paygateId: this.paygateId } })
      this.apiKey = result.data
    },
    setValidity (e) {
      console.log(e)
      for (var i = 0; i < this.groupPlans.length; i++) {
        if (this.groupPlans[i].paymentPlanTemplateId === e.plan.planTemplateId) {
          console.log(this.groupPlans[i])
          this.groupPlans[i].plan.valid = e.valid
          this.$forceUpdate()
        }
      }
    },
    updatePlanInput (index) {
      this.activeTabIndex = index
      this.$nextTick(() => {
        this.$refs.planInput.initialize()
        this.isDirtied = false
      })
    }
  },
  watch: {
    activeTabIndex () {
      if (this.$refs.planInput !== undefined && this.$refs.planInput !== null && this.$refs.planInput.length > 0) {
        this.$refs.planInput.reset()
        this.isDirtied = false
      }
    },
    selectedCustomer () {
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      this.group = '00000000-0000-0000-0000-000000000000'
      this.$store.dispatch('getAvailableBankAccounts')
      this.$store.dispatch('buildPlanTemplateTree')
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (this.isDirtied === true) {
      const result = await swal.fire({
        title: 'You have unsaved changes!',
        text: 'You will lose any changes to the current template.  Are you sure you want to navigate away?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonClass: 'btn btn-light',
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No',
        confirmButtonClass: 'btn btn-danger'
      })
      if (result.isConfirmed) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>
<style scoped>
.vertical-tab-list {
  border-bottom: solid 0px black !important;
  border-right: 0px solid #ddd;
}

.vertical-tab-container {
  border-bottom: solid 0px black !important;
  border-right: 1px solid #ddd;
  padding-right: 0px;
}

.vertical-tab-list .nav-item .active, .vertical-tab-list .nav-item .nav-link:hover {
  border: 1px solid #ddd;
  border-right: solid 1px transparent;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0rem;
  border-top-right-radius: 0rem;
  margin: 0
}

.planRow .tabs {
  width: 100%
}
</style>
