<template>
  <div>
    <!-- <loading v-bind:loading="loading"></loading> -->
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Bureau Customer Information
          <span class="pull-right">
            <a href="#" target="_blank">
              <i class="far fa-question-circle"></i>
            </a>
          </span>
          <favourite-icon></favourite-icon>
        </h2>
      </div>

      <div class="idb-block-content">
        <div class="alert alert-secondary" role="alert" v-if="status === 'Edit'">
          The previous details are shown in
          <span class="del">red</span>, the new details in
          <span class="ins">green</span>.
        </div>

        <b-tabs>
          <b-tab title="Bureau Customer Details" active>
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.customerReference">Unique Customer Reference</label>
              </b-col>
              <b-col sm="3">
                <b-form-input type="text" readonly v-model.trim="bureauCustomer.customerReference"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label class="required" for="bureauCustomer.name">Name</label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.bureauCustomer.name.$model"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-col offset-sm="3" class="pl-3" v-if="$v.bureauCustomer.name.$dirty">
              <label
                class="text-danger small"
                v-if="!$v.bureauCustomer.name.required"
              >A Bureau Customer Name is required</label>
            </b-col>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label class="required" for="bureauCustomer.serviceUserNumber">Service User Number</label>
              </b-col>
              <b-col sm="3">
                <b-form-input
                  type="text"
                  :maxlength="6"
                  v-model.trim="$v.bureauCustomer.serviceUserNumber.$model"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-col offset-sm="3" class="pl-3" v-if="$v.bureauCustomer.serviceUserNumber.$dirty">
              <label
                class="text-danger small"
                v-if="!$v.bureauCustomer.serviceUserNumber.required"
              >A Service User Number is required</label>
              <label
                class="text-danger small"
                v-if="!$v.bureauCustomer.serviceUserNumber.sunValidator"
              >The Service User Number is not valid</label>
            </b-col>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.defaultImportFilename">Default Import Filename</label>
              </b-col>
              <b-col sm="6">
                <b-form-input
                  type="text"
                  :maxlength="250"
                  v-model="bureauCustomer.defaultImportFilename"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.defaultImportSchema">Default Import Schema</label>
              </b-col>
              <b-col sm="4">
                <b-form-select v-model="bureauCustomer.defaultImportSchema" :options="schemas"></b-form-select>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="bureauCustomer.defaultImportSchema == 'Mapping'">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.defaultImportMapping">Default Import Mapping</label>
              </b-col>
              <b-col sm="4">
                <b-form-select v-model="bureauCustomer.defaultImportMapping" :options="mappings"></b-form-select>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.contraNarrative">Contra Narrative</label>
              </b-col>
              <b-col sm="4">
                <b-form-input type="text" :maxlength="255" v-model="bureauCustomer.contraNarrative"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.tags">Tags</label>
              </b-col>
              <b-col sm="6">
                <input-tag v-model="bureauCustomer.tags"></input-tag>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Bank Details">
            <!-- Bank Reference -->
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.bankName">Reference</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.bureauCustomer.bankReference.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <!-- Sort Code -->
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label class="required">Sort Code</label>
              </b-col>
              <b-col sm="2">
                <the-mask
                  type="text"
                  class="form-control"
                  v-model.trim="$v.bureauCustomer.sortCode.$model"
                  v-focus
                  :mask="['##-##-##']"
                  :guide="true"
                  ref="softCode"
                ></the-mask>
                <!-- Validation -->
                <span v-if="$v.bureauCustomer.sortCode.$dirty">
                  <small
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.bureauCustomer.sortCode.required"
                  >Sort Code is required</small>
                  <small
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.bureauCustomer.sortCode.minLength"
                  >
                    Sort Code must be at least
                    {{ $v.bureauCustomer.sortCode.$params.minLength.min }}
                    numbers
                  </small>
                  <small
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.bureauCustomer.sortCode.maxLength"
                  >
                    Sort Code must be less than
                    {{ $v.bureauCustomer.sortCode.$params.maxLength.max }}
                    numbers
                  </small>
                  <small
                    class="form-text text-warning small text-nowrap"
                    v-if="!sortCodeValid && $v.bureauCustomer.sortCode.required"
                  >{{ sortCodeErrorMessage }}</small>
                </span>
              </b-col>
              <span>
                <b-col v-if="sortCodeValid">
                  <i class="fas fa-check mt-2"></i>
                </b-col>
              </span>
            </b-row>

            <!-- Account Number -->
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label class="required" for="bureauCustomer.accountNumber">Account Number</label>
              </b-col>
              <b-col sm="2">
                <the-mask
                  type="text"
                  class="form-control"
                  v-model.trim="$v.bureauCustomer.accountNumber.$model"
                  :mask="['########']"
                  :guide="false"
                  ref="originatingAccountNumber"
                ></the-mask>
                <!-- Validation -->
                <span v-if="$v.bureauCustomer.accountNumber.$dirty">
                  <small
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.bureauCustomer.accountNumber.required"
                  >Account Number is required</small>
                  <small
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.bureauCustomer.accountNumber.minLength"
                  >
                    Account Number must be at least
                    {{ $v.bureauCustomer.accountNumber.$params.minLength.min }}
                    numbers
                  </small>
                  <small
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.bureauCustomer.accountNumber.maxLength"
                  >
                    Account Number must be less than
                    {{ $v.bureauCustomer.accountNumber.$params.maxLength.max }}
                    numbers
                  </small>
                  <small
                    class="form-text text-warning small text-nowrap"
                    v-if="
                      !accountNumberValid &&
                      $v.bureauCustomer.accountNumber.required
                    "
                  >{{ accountNumberErrorMessage }}</small>
                </span>
              </b-col>
              <span>
                <b-col v-if="accountNumberValid == true">
                  <i class="fas fa-check mt-2"></i>
                </b-col>
              </span>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.bankName">Bank Name</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.bureauCustomer.bankName.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.bankAddress1">Address</label>
              </b-col>
              <b-col sm="6">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressLine1"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="6" offset-sm="3">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressLine2"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="6" offset-sm="3">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressLine3"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="6" offset-sm="3">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressLine4"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.bankAddressLocality"></label>
              </b-col>
              <b-col sm="6">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressLocality"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.bankAddressTown">Town</label>
              </b-col>
              <b-col sm="5">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressTown"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.bankAddressCounty">County</label>
              </b-col>
              <b-col sm="5">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressCounty"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.bankAddressPostcode">Post Code</label>
              </b-col>
              <b-col sm="2">
                <b-form-input
                  type="text"
                  :maxlength="10"
                  v-model.trim="bureauCustomer.bankAddressPostcode"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Payment Limits">
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.paymentLimit">Payment Limit</label>
              </b-col>
              <b-col sm="2">
                <currency-input type="text" v-model="$v.bureauCustomer.paymentLimit.$model"></currency-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.submissionLimit">Submission Limit</label>
              </b-col>
              <b-col sm="2">
                <currency-input type="text" v-model="$v.bureauCustomer.submissionLimit.$model"></currency-input>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Contact Info">
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.contactFirstName">Forename</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.contactFirstName"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.contactSurname">Surname</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.contactSurname"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.address1">Address</label>
              </b-col>
              <b-col sm="6">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressLine1"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="6" offset-sm="3">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressLine2"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="6" offset-sm="3">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressLine3"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="6" offset-sm="3">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressLine4"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.addressLocality"></label>
              </b-col>
              <b-col sm="6">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressLocality"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.addressTown">Town</label>
              </b-col>
              <b-col sm="5">
                <b-form-input type="text" :maxlength="50" v-model.trim="bureauCustomer.addressTown"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.addressCounty">County</label>
              </b-col>
              <b-col sm="5">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressCounty"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.addressPostcode">Postcode</label>
              </b-col>
              <b-col sm="2">
                <b-form-input
                  type="text"
                  maxlength="10"
                  v-model.trim="bureauCustomer.addressPostcode"
                ></b-form-input>
              </b-col>
              <b-col sm="2">
                <button
                  class="btn btn-info"
                  :disabled="
                    this.bureauCustomer.addressPostcode == '' ||
                    this.bureauCustomer.addressPostcode == null
                  "
                  @click="getAddress()"
                >Lookup Address</button>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="this.addressOptions.length > 0 && !this.addressFound">
              <b-col sm="3" class="mt-2">
                <label for="addressOptions">Addresses</label>
              </b-col>
              <b-col sm="8">
                <b-form-select v-model="selectedAddress" :options="this.addressOptions" class="mb3"></b-form-select>
              </b-col>
            </b-row>

            <hr />

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.telephone">Telephone</label>
              </b-col>
              <b-col sm="3">
                <b-form-input type="text" v-model.trim="bureauCustomer.telephone"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.emailAddress">Email</label>
              </b-col>
              <b-col sm="4">
                <b-form-input type="text" v-model.trim="bureauCustomer.emailAddress"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.websiteUrl">Web Site</label>
              </b-col>
              <b-col sm="4">
                <b-form-input type="text" v-model.trim="bureauCustomer.websiteUrl"></b-form-input>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Notes">
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomer.notes">Notes</label>
              </b-col>
              <b-col sm="4">
                <b-form-textarea
                  type="text"
                  rows="6"
                  placeholder="Notes..."
                  v-model.trim="bureauCustomer.notes"
                ></b-form-textarea>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          :disabled="$v.bureauCustomer.$invalid"
          @click="onSaveClick()"
        >Save</button>
        <button class="btn btn-danger float-right" @click="onDeleteClick()">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import uuid from 'uuid/v4'
import swal from 'sweetalert2'
import { required, minLength, maxLength, helpers, maxValue } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import Utility from '@/Assets/Mixins/Utility'
import CurrencyInput from '@/Assets/Components/CurrencyInput.vue'
import colours from '@/Assets/Constants/colours'

const sunValidator = helpers.regex('alpha', /^\d{6}$/)

export default {
  components: {
    TheMask,
    CurrencyInput
  },
  data () {
    return {
      sortCodeValid: false,
      accountNumberValid: false,
      bureauCustomer: {
        bureauCustomerId: '',
        name: '',
        customerReference: '',
        serviceUserNumber: '',
        defaultImportFilename: '[CUSTOMERREF].csv',
        defaultImportSchema: 'Standard18',
        defaultImportMapping: '',
        contraNarrative: '',
        bankName: '',
        sortCode: '',
        accountNumber: '',
        bankAddressLine1: '',
        bankAddressLine2: '',
        bankAddressLine3: '',
        bankAddressLine4: '',
        bankAddressLocality: '',
        bankAddressTown: '',
        bankAddressCounty: '',
        bankAddressPostcode: '',
        paymentLimit: 0.00,
        submissionLimit: 0.00,
        contactFirstName: '',
        contactSurname: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressLine4: '',
        addressLocality: '',
        addressTown: '',
        addressCounty: '',
        addressPostcode: '',
        telephone: '',
        websiteUrl: '',
        emailAddress: '',
        tags: [],
        latitude: '',
        longitude: ''
      },
      addressOptions: [],
      addressBuildingNumber: '',
      selectedAddress: '',
      addressFound: true,
      action: { data: null },
      status: '',
      loading: true,
      newBureauCustomer: false,
      sortCodeErrorMessage: '',
      accountNumberErrorMessage: ''
    }
  },
  watch: {
    'bureauCustomer.sortCode': function () {
      this.validate()
    },
    'bureauCustomer.accountNumber': function () {
      this.validate()
    },
    selectedAddress: function () {
      this.populateAddress(this.selectedAddress)
    }
  },
  async created () {
    if (this.$route.params.id === undefined) {
      this.newBureauCustomer = true
      this.bureauCustomer.bureauCustomerId = uuid()
      this.bureauCustomer.paygateId = this.$store.state.common.paygateId
      this.addressFound = false
    } else {
      this.loadBureauCustomer(this.$route.params.id)
    }
    this.schemas = this.loadSchemas()
    this.mappings = await this.loadMappings('')
  },
  mixins: [Utility],
  methods: {
    async loadBureauCustomer (bureauCustomerId) {
      await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/' + bureauCustomerId, { showload: true })
        .then(response => {
          this.bureauCustomer = response.data
          if (this.bureauCustomer.addressPostcode === '' || this.bureauCustomer.addressPostcode === null) {
            this.addressFound = false
          }
        })
        .catch(error => console.error(error))
    },
    async onSaveClick () {
      if (this.bureauCustomer.defaultImportSchema !== 'Mapping') {
        this.bureauCustomer.defaultImportMapping = ''
      }
      if (this.newBureauCustomer) {
        await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer', this.bureauCustomer, { showload: true })
          .then(response => {
            this.newBureauCustomer = false
            this.bureauCustomer = response.data
            this.$toastr.s(`Bureau Customer ${this.bureauCustomer.name} created`, 'Bureau Customer')
          })
          .catch(error => {
            console.error(error)
            this.$toastr.e(`Failed to create ${this.bureauCustomer.name}`, 'Bureau Customer')
          })
      } else {
        console.log('Trying to PUT hello hello')
        try {
          var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomer/updatebureaucustomer', this.bureauCustomer, { showload: true })
          this.bureauCustomer = response.data
          console.log(response)
          this.$toastr.s(`Successfully updated ${this.bureauCustomer.name}`, 'Bureau Customer')
        } catch (error) {
          this.$toastr.e(`Failed to update ${this.bureauCustomer.name}`, 'Bureau Customer')
        }
      }
    },
    async onDeleteClick () {
      // Go check first and see if it's in a job.
      var deleteCheck = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/isBureauCustomerInJob/' + this.bureauCustomer.bureauCustomerId, { showload: true })
      var result
      if (deleteCheck.data.success === false) {
        var text = '<span id="swal2-content" class="swal2-content" style="display: block;">This bureau customer is still in the following jobs. Are you sure you want to delete this bureau customer? <br><ul>'
        var list = document.createElement('ul')
        for (var i = 0; i < deleteCheck.data.names.length; i++) {
          var listItem = document.createElement('li')
          listItem.innerHTML = deleteCheck.data.names[i]
          list.appendChild(listItem)
          text = text + '<div class="row justify-content-md-center"><li>' + deleteCheck.data.names[i] + '</li></div>'
        }
        text = text + '</ul></span>'
        // Get confirmation from the user that they really want to delete the customer
        result = await swal.fire({
          title: 'Delete Bureau Customer',
          text: 'This bureau customer is still in the following jobs. Are you sure you want to delete this bureau customer?',
          type: 'warning',
          html: text,
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } else {
        // Get confirmation from the user that they really want to delete the customer
        result = await swal({
          title: 'Delete Bureau Customer',
          text: 'Are you sure you want to delete this bureau customer',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      }

      if (result) {
        await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/' + this.bureauCustomer.bureauCustomerId, { showload: true })
          .then(response => {
            if (response.status === 204) {
              this.$toastr.s(`Bureau Customer ${this.bureauCustomer.name} deleted`, 'Bureau Customer')
              this.$router.back()
            }
          })
          .catch(error => console.error(error))
      }
    },
    async getAddress () {
      this.addressFound = false
      var data = { postCode: this.bureauCustomer.addressPostcode }
      await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'address', data, { showload: true })
        .then(response => {
          var result = JSON.parse(response.data)
          if (result.Message === 'Bad Request') {
            swal({
              title: 'Postcode not found',
              text: 'Sorry we could not locate an address using the information you provided,do you want to enter the address manually?',
              type: 'question',
              showCancelButton: true,
              cancelButtonText: 'No',
              showConfirmButton: true,
              confirmButtonText: 'Yes'
            })
              .then((result) => {
                this.bureauCustomer.addressPostcode = ''
                this.addressFound = true
              })
              .catch(swal.noop)
          } else {
            this.bureauCustomer.latitude = result.latitude
            this.bureauCustomer.longitude = result.longitude

            // Found a single address
            if (result.addresses.length === 1) {
              this.populateAddress(result.addresses[0])
            }

            // Found multiple addresses
            if (result.addresses.length > 1) {
              this.addressOptions = result.addresses
            }
          }
        })
        .catch(error => console.error(error))
    },
    populateAddress (data) {
      var address = data.split(',')
      this.bureauCustomer.addressLine1 = address[0]
      this.bureauCustomer.addressLine2 = address[1]
      this.bureauCustomer.addressLine3 = address[2]
      this.bureauCustomer.addressLine4 = address[3]
      this.bureauCustomer.addressLocality = address[4]
      this.bureauCustomer.addressTown = address[5]
      this.bureauCustomer.addressCounty = address[6]
      this.bureauCustomer.addressPostcode = this.bureauCustomer.addressPostcode.toUpperCase()
      this.addressFound = true
    },
    validate: _.debounce(async function () {
      const data = { sortCode: this.bureauCustomer.sortCode }

      if (this.bureauCustomer.accountNumber.length === 8) {
        data.accountNumber = this.bureauCustomer.accountNumber
      }

      try {
        const validateUrl = process.env.VUE_APP_VALIDATE_API_URL + 'BankAccount'
        const response = await axios.get(validateUrl, { params: data }, { showload: true })

        this.validationResult = response.data
        this.sortCodeErrorMessage = ''
        this.accountNumberErrorMessage = ''
        if (this.validationResult.valid) {
          console.info('Sort code and account number are valid')
          this.sortCodeValid = true
          this.accountNumberValid = true
        } else {
          if (this.validationResult.errorCode === '1502') {
            console.info('Sort code is valid')
            this.sortCodeValid = true
            this.accountNumberValid = false
            this.accountNumberErrorMessage = this.validationResult.errorText
          } else {
            console.info('Sort code and account number are invalid')
            this.sortCodeValid = false
            this.sortCodeErrorMessage = this.validationResult.errorText
            this.accountNumberValid = false
            this.accountNumberErrorMessage = this.validationResult.errorText
          }
        }

        if (this.validationResult.valid || this.validationResult.errorCode === '1502') {
          this.bureauCustomer.bankName = this.validationResult.institutionName
          this.bureauCustomer.bankAddressLine1 = this.validationResult.branchTitle
          this.bureauCustomer.bankAddressLine2 = this.validationResult.address1
          this.bureauCustomer.bankAddressLine3 = this.validationResult.address2
          this.bureauCustomer.bankAddressLine4 = this.validationResult.address3
          this.bureauCustomer.bankAddressLine5 = this.validationResult.address4
          this.bureauCustomer.bankAddressTown = this.validationResult.town + this.validationResult.city
          this.bureauCustomer.bankAddressCounty = this.validationResult.county
          this.bureauCustomer.bankAddressPostcode = this.validationResult.postcode
        }
      } catch (e) {
        console.log(e)
      }
    }, 800)
  },
  validations: {
    bureauCustomer: {
      serviceUserNumber: { minLength: 6, sunValidator, required },
      sortCode: { required, minLength: minLength(6), maxLength: maxLength(6) },
      accountNumber: { minLength: minLength(8), maxLength: maxLength(8), required },
      bankName: {},
      bankReference: {},
      name: { required },
      submissionLimit: { maxValue: maxValue(1000000) },
      paymentLimit: { maxValue: maxValue(1000000) }
    }
  }
}

</script>
