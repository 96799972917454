<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline File Details - Payment<span class="pull-right"><a :href="`${docUrl}/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-2 ">
              Filename
            </div>
            <div class="col-3">
              {{fileData.originalFilename}}
            </div>
            <div class="col-2 offset-1">
              Creation Date
            </div>
            <div class="col-3" v-if="loaded==true">
              {{reformatDate(fileData.creationDate)}}
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-2 ">
              File Type
            </div>
            <div class="col-3">
              <span v-if = "fileData.type == 'URG'">Standalone URG File (CHAPS)</span>
              <span v-else-if = "fileData.type == 'STD'">Standalone STD File</span>
              <span v-else-if = "fileData.type == 'BLK'">Daily Bulk File</span>
              <span v-else>{{fileData.type}}</span>
            </div>
            <div class="col-2 offset-1">
              Status
            </div>
            <div class="col-3">
              <b-badge pill v-if = "fileData.status == '11'" class="mr-1" variant="warning">Not Downloaded</b-badge>
              <b-badge pill v-else-if = "fileData.status == '12'" class="mr-1" variant="success">Downloaded</b-badge>
              <b-badge pill v-else-if = "fileData.status == '98'" class="mr-1" variant="warning">Sent to Bankline</b-badge>
              <b-badge pill v-else-if = "fileData.status == '99'" class="mr-1" variant="danger">Error</b-badge>
              <b-badge pill v-if = "fileData.archive == true" class="mr-1" variant="info">Sent to Bankline</b-badge>
              <div class="tinyText mt-2" v-if = "fileData.status == '12'">Downloaded: {{reformatDate(fileData.downloadDate)}}</div>
              <div class="tinyText mt-1" v-if = "fileData.archive == true">Sent to Bankline: {{reformatDate(fileData.archiveDate)}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>File Contents</h2>
        </div>
        <div class="idb-block-content">
          <div>
            <VuePerfectScrollbar class="scroll-area" :settings="settings">
              <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              :rows="rows"
              :columns="columns"
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              ref="table"
              :lineNumbers="true"
              styleClass="vgt-table striped bordered table-hover"
              @on-row-click="onRowClick"
              @on-cell-click="onCellClick">
            <template slot="table-row" slot-scope="props">
                <span v-if = "props.column.field == 'entryType'">
                  <b-badge pill v-if = "props.row.entryType == 'Credit'" variant="success">{{props.row.entryType}}</b-badge>
                  <b-badge pill v-else-if = "props.row.entryType == 'Debit'" variant="danger">{{props.row.entryType}}</b-badge>
                  <span v-else>{{props.row.entryType}}</span>
                </span>
                <span v-if = "props.column.field == 'status'">
                  <b-badge pill v-if = "props.row.status == '1'" variant="danger">Unprocessed</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '2'" variant="info">Processing</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '3'" variant="success">Matched</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '4'" variant="danger">Unmatched</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '5'" variant="dark">Unmatchable Type</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '6'" variant="danger">Unknown Type</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '7'" variant="warning">Ambiguous</b-badge>
                  <span v-else>{{props.row.entryType}}</span>
                </span>
                <span v-else-if = "props.column.field == 'debitAccountIdentifier'">
                  {{ props.formattedRow[props.column.field] }}
                  <div class="tinyText">Sortcode: {{ reformatSortcode(props.formattedRow[props.column.field])}}</div>
                  <div class="tinyText">Account Number: {{ reformatAccountNumber(props.formattedRow[props.column.field])}}</div>
                </span>
                <span v-else-if = "props.column.field == 'recordType'">
                <span v-if="props.formattedRow[props.column.field] === '01'">STD</span>
                <span v-else-if="props.formattedRow[props.column.field] === '02'">URG (CHAPS)</span>
                </span>
                <span v-else-if = "props.column.field == 'pa'">
                  {{ reformatCurrency(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'creationDate'">
                  {{ reformatDate(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'arrivalDate'">
                  {{ reformatDate2(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'accountWithBankIdentifier'">
                  {{ reformatSortcode2(props.formattedRow[props.column.field])}}
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
            </template>
            <div slot="table-actions">
              <button
                @click.prevent="clearTableFilters"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Clear filters'">
                <span class="fa-stack" style="font-size: 10px;">
                  <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                  <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                </span>
              </button>
              <b-button @click.prevent="load" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the table data.'" ><i class="fas fa-sync-alt pointer dimmedIcon"></i></b-button>
            </div>
              </vue-good-table>
            </VuePerfectScrollbar>
        </div>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div class="btn-group mr-2" role="group" >
                <button v-if="loaded==true" id="btnGroupDrop1" @click="btnDownloadFile" type="button" class="btn btn-primary">
                  <i class="fa fa-download mr-2"></i>Download Bankline File
                </button>
              </div>
              <div class="btn-group pull-right">
                <button v-if="loaded==true && (fileData.status =='12' || fileData.status =='99') && fileData.archive==false" id="btnDeleteFile" @click="btnDeleteFile" type="button" class="btn btn-danger">
                  <i class="fa fa-trash mr-2"></i>Delete File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
import { saveAs } from 'file-saver'
export default {
  name: 'banklineFile',
  mixins: [tableFilterMixin],
  components: {
    VuePerfectScrollbar,
    swal,
    saveAs
  },
  data () {
    return {
      loaded: false,
      settings: {
      },
      fileData: { archive: false },
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      columns: [
        {
          label: 'Id',
          field: '_id',
          hidden: true
        },
        {
          label: 'Debit Account Identifier',
          field: 'debitAccountIdentifier',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Beneficiary Sortcode',
          field: 'accountWithBankIdentifier',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Beneficiary Account Number',
          field: 'beneficiaryAccountNumber',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Beneficiary Name',
          field: 'beneficiaryNameAndAddressLineNumber1',
          filterOptions: {
            enabled: true
          },
          hidden: false
        },
        {
          label: 'Beneficiary Reference',
          field: 'beneficiaryReference',
          filterOptions: {
            enabled: true
          },
          hidden: false
        },
        {
          label: 'Payment Amount',
          field: 'pa',
          filterOptions: {
            enabled: true
          },
          hidden: false
        },
        {
          label: 'Record Type',
          field: 'recordType',
          filterOptions: {
            enabled: false
          },
          hidden: false
        },
        {
          label: 'Standalone',
          field: 'standalone',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Parent File',
          field: 'parentFileId',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Created',
          field: 'creationDate',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Arrival Date',
          field: 'arrivalDate',
          filterOptions: {
            enabled: false
          }
        }
      ],
      row_data: [],
      totalRecords: 0,
      paginationOptions: { enabled: true, perPage: 10, dropdownAllowAll: false, jumpFirstOrLast: true },
      serverParams: {
        sort: [{ field: 'creationDate', type: 'desc' }]
      }
    }
  },
  computed: {
    rows: function () {
      return this.row_data
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async load () {
      const postObj = {
        serverParams: this.serverParams,
        id: this.id
      }
      this.isTableLoading = true
      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}banklinepayments/${this.id}`, postObj)
        if (res) {
          this.loaded = true
          this.fileData = res.data.resObj
          this.row_data = res.data.resObj.data
          this.totalRecords = res.data.resObj.count
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline payment data.')
        } else {
          if (e.response.data && e.response.data.errorMessage) {
            this.$snapbar.e('Could not get Bankline payment data - Missing or deleted payment file')
          } else {
            this.$snapbar.e(`Could not get Bankline payment data - ${e.message}`)
          }
        }
        this.row_data = []
        this.totalRecords = 0
      } finally {
        this.isTableLoading = false
      }
    },
    reformatSortcode (dai) {
      if (dai.length !== 14) return
      return `${dai[0]}${dai[1]}-${dai[2]}${dai[3]}-${dai[4]}${dai[5]}`
    },
    reformatSortcode2 (dai) {
      if (dai.length !== 6) return
      return `${dai[0]}${dai[1]}-${dai[2]}${dai[3]}-${dai[4]}${dai[5]}`
    },
    reformatAccountNumber (dai) {
      if (dai.length !== 14) return
      return dai.substring(6, 14)
    },
    reformatCurrency (c) {
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      })
      return formatter.format(c)
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    reformatDate2 (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!
      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      return dd + '/' + mm + '/' + yyyy
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    async btnDeleteFile () {
      const result = await swal.fire({
        title: 'Are you sure you want to permanently delete this Bankline file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}banklinefile/${this.id}`, { showload: true })
          if (res) {
            this.$toastr.s(`The Bankline file '${this.fileData.originalFilename}' was successfully deleted.`)
            this.$router.push('/admin/bandr/bankline/banklineoutbound')
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this file.')
          } else {
            this.$snapbar.e(`Could not delete this file - ${e.message}`)
          }
        }
      }
    },
    async btnDownloadFile () {
      let res
      try {
        // Download the file from node - note the 'responseType: 'blob'' is required.
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineoutboundfiledownload/${this.id}`, { responseType: 'blob' })
        if (res) {
          try {
            // This saves the  downloaded blob as a file - should invoke normal browser file behaviour and might be different
            // from user to user.  For example, on my pc, downloaded CSV open stright into notepad++, for user users it might promopt a save-as dialog.
            saveAs.saveAs(res.data, this.fileData.originalFilename)
            this.load()
          } catch (e) {
            console.log(e)
            this.$snapbar.e('Error downloading Bankline file.  The file could not be saved.')
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to download Bankline files.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
      }
    },
    onRowClick (params) {
    },
    onCellClick (params) {
      if (params.column.field !== 'id') {
        this.$router.push(`/admin/bandr/bankline/banklinepaymentrow/${params.row._id}`)
      }
    }
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.load()
    }
  }
}
</script>
<style scoped>
  td {
    cursor: pointer;
  }
  .tinyText {
    font-size: x-small;
  }
</style>
