<template>
  <div>
    <form @submit.prevent="sendMessage(sendMessageType)">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2><help-icon docPath="/paygate-collections/messaging/bulkmessaging/" />Bulk Message Sender<favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div class="row form-group">
            <div class="col-md-2">
              Group
            </div>
            <div class="col-md-4">
              <group-select
                v-model="selectedGroup"
                :groups="customerGroups"
                @input="groupChange"
                :clearable="false">
              </group-select>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2">
              Message Type
            </div>
            <div class="col-md-4">
              <b-form-select
                :disabled="selectedGroup === '00000000-0000-0000-0000-000000000000' || allowedMessageTypes.length <= 1"
                :options="allowedMessageTypes" text-field="title" value-field="title" v-model="sendMessageType">
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <b-btn
            type="submit"
            variant="primary"
            :disabled="sendMessageLoading || allowedMessageTypes.length <= 1 || selectedGroup === null || isLoading || sendMessageType === null || sendMessageType === 'Select a Custom Template'"
          >
            <i class="fa fa-envelope mr-2"></i>Send
          </b-btn>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import VueSelect from 'vue-select'
import { required } from 'vuelidate/lib/validators'
import loading from '@/Assets/Mixins/LoadingMixin'
import EventBus from '@/Lib/eventBus'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import roles from "@/Assets/Constants/roles";
const messageTypes = [
  { name: 'Select A Message Type', value: null},
  { name: 'First Message', value: 'FirstMessage' },
  { name: 'Advance Notice', value: 'AdvanceNotice' },
  { name: 'Multiple Schedule Update', value: 'MultipleScheduleUpdate' },
  { name: 'Custom Message 1', value: 'CustomMessage1' },
  { name: 'Custom Message 2', value: 'CustomMessage2' },
  { name: 'Custom Message 3', value: 'CustomMessage3' },
  { name: 'Custom Message 4', value: 'CustomMessage4' },
  { name: 'Custom Message 5', value: 'CustomMessage5' },
  { name: 'Cancellation Notice', value: 'CancellationNotice' },
  { name: 'Email Verification', value: 'EmailVerification' },
  { name: 'Portal Message Sent', value: 'PortalMessageSent' },
  { name: 'Portal Email Verification', value: 'PortalEmailVerification' },
  { name: 'Refund Requested', value: 'RefundRequested' }
]
export default {
  mixins: [loading],
  name: 'BulkMessageSender',
  components: {
    VueSelect
  },
  data () {
    return {
      sendMessageLoading: false,
      sendMessageType: null,
      selectedGroups: [],
      selectedGroup: null,
      searchResults: [],
      messageTypeSelect: [
        { name: 'Select A Message Type', value: null},
        { name: 'First Message', value: 'FirstMessage' },
        { name: 'Advance Notice', value: 'AdvanceNotice' },
        { name: 'Multiple Schedule Update', value: 'MultipleScheduleUpdate' },
        { name: 'Custom Message 1', value: 'CustomMessage1' },
        { name: 'Custom Message 2', value: 'CustomMessage2' },
        { name: 'Custom Message 3', value: 'CustomMessage3' },
        { name: 'Custom Message 4', value: 'CustomMessage4' },
        { name: 'Custom Message 5', value: 'CustomMessage5' },
        { name: 'Cancellation Notice', value: 'CancellationNotice' },
        { name: 'Email Verification', value: 'EmailVerification' },
        { name: 'Portal Message Sent', value: 'PortalMessageSent' },
        { name: 'Portal Email Verification', value: 'PortalEmailVerification' },
        { name: 'Refund Requested', value: 'RefundRequested' }
      ],
      allowedMessageTypes: []
    }
  },
  async mounted () {
    this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
    this.selectedGroup = '00000000-0000-0000-0000-000000000000'
    var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
    if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
      this.selectedGroup = this.customerGroups.find(i => i.groupId === defaultGroupId)
      // Default group might be a FPS group which means it can't be the BACS default group and vice versa.
      if (this.selectedGroup !== undefined) {
        this.optOutGroup = defaultGroupId
      }
    }
    this.groupChange(this.optOutGroup)
  },
  methods: {
    async loadGroups (paygateId) {
      const paygateIdNotDefault = (paygateId && paygateId !== '00000000-0000-0000-0000-000000000000')
      if (paygateIdNotDefault) {
        await this.$store.dispatch('loadCustomerGroups', paygateId)
      }
      this.selectedGroup = '00000000-0000-0000-0000-000000000000'
    },
    async sendMessage (messageType) {
      const { data: { smsCount, letterCount, emailCount } } = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}messages/bulk/warningstats`, { groupIds: [ this.selectedGroup ] }, { showload: true, params: { paygateId: this.paygateId } })
      let text = 'This action will send '
      const messageTypeCount = [smsCount, letterCount, emailCount].reduce((prev, current) => {
        if (current > 0) {
          return prev + 1
        } else {
          return prev
        }
      }, 0)

      if (letterCount) {
        text += `${letterCount} letters`
      }

      if (emailCount) {
        if (messageTypeCount > 2) {
          text += ', '
        } else if (messageTypeCount === 2) {
          text += ' and '
        }
        text += `${emailCount} emails`
      }

      if (smsCount) {
        if (messageTypeCount > 1) {
          text += ' and '
        }
        text += `${smsCount} SMS messages`
      }
      text += '.'
      // let text = `This action will send ${letterCount} letters, ${emailCount} emails and ${smsCount} SMS messages.`

      await this.$swal({
        title: 'Are you sure?',
        text: text,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        showConfirmButton: true,
        confirmButtonText: 'Yes'
      })
      var msg = {}
      for (var i = 0; i < this.allowedMessageTypes.length; i++) {
        if (messageType === this.allowedMessageTypes[i].title) {
          msg = this.allowedMessageTypes[i]
        }
      }
      const msgBody = {
        payerMessageType: msg.messageType,
        groupIds: [this.selectedGroup],
        customTemplateId: msg.customMessageId
      }
      try {
        await axios.post(`${process.env.VUE_APP_DDMS_API_URL}messages/bulk`, msgBody, { showload: true, params: { paygateId: this.paygateId } })
        this.$toastr.s('Messages Queued')
      } catch (e) {
        this.$toastr.s('Attempt to queue messages failed.')
      }
    },
    // autoCompleteGroup: async function () {
    //   if (this.selectedGroups) {
    //     const params = { 'startsWith': this.selectedGroups }
    //     const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}autocomplete/groups`, { params: params, showload: true })
    //     this.searchResults = response.data.results || []
    //   } else {
    //     const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}autocomplete/groups`, { showload: true })
    //     this.searchResults = response.data.results || []
    //   }
    // },
    async groupChange () {
      await this.getMessageTypes()
    },
    async getMessageTypes () {
      if (this.selectedGroup !== null && this.selectedGroup !== '00000000-0000-0000-0000-000000000000') {
        const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}allowedmessages/${this.selectedGroup}`, { params: { paygateId: this.paygateId, includeSystem: false } })
        this.allowedMessageTypes = response.data
        this.allowedMessageTypes.unshift({ title: response.data.length === 0 ? 'No template available' : 'Select a Custom Template', value: null })
        this.sendMessageType = this.allowedMessageTypes[0].title
        if(!this.hasPayerPortal) {
          this.allowedMessageTypes = this.allowedMessageTypes.filter(function (obj) { return obj.messageType !== 9 && obj.messageType !== 10 })
        }
        if(!this.hasRefunds) {
          this.allowedMessageTypes = this.allowedMessageTypes.filter(function (obj) { return obj.messageType !== 11 && obj.messageType !== 17 })
        }
        this.allowedMessageTypes = this.allowedMessageTypes.filter(function (obj) { return obj.messageType !== 15 })
      } else {
        this.allowedMessageTypes = [{ title: 'Select a Custom Template', value: null }]
        this.sendMessageType = this.allowedMessageTypes[0].title
      }
    }
  },
  validations: {
    selectedGroup: { required }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    hasRefunds () {
      return this.$store.getters.hasLicence('LicenceCollectionsRefunds') && !this.$store.getters.isInRole(roles.SystemUser)
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.rightsGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.rightsGroups).map(x => {
          let obj = {}
          obj.groupId = x.groupId
          obj.name = x.name
          obj.colour = x.colour
          return obj
        })
      }
      if (ret.length > 1) {
        ret.unshift({
          'groupId': '00000000-0000-0000-0000-000000000000',
          'groupType': 'UK DDMS',
          'name': 'Please select a group',
          'paygateId': this.paygateId,
          'isItemActioned': false,
          'clonedName': null,
          'colour': null
        })
      }
      return ret
    },
    hasPayerPortal () {
      return this.$store.getters.hasLicence('LicenceCollectionsPayerPortal')
    }
  },
  watch: {
    selectedCustomer () {
      this.group = '00000000-0000-0000-0000-000000000000'
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      this.getMessageTypes()
    }
  }
}
</script>
