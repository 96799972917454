import axios from 'axios'

const state = {
  bureauCustomers: [],
  bureauCustomerSelectorSelection: [],
  bureauCustomerSelectorFilter: '',
  bureauCustomerSelectorReturnType: '',
  currentBureauCustomer: {
    bankReference: '',
    sortCode: '',
    accountNumber: ''
  },
  metaData: {
    tableLinks: {},
    totalPages: 0,
    totalItems: 0
  },
  fieldLabels: {}
}

const mutations = {
  setBureauCustomerSelectorSelection (state, bureauCustomers) {
    state.bureauCustomerSelectorSelection = bureauCustomers
  },
  setBureauCustomerSelectorFilter (state, filter) {
    state.bureauCustomerSelectorFilter = filter
  },
  setBureauCustomerSelectorReturnType (state, returnType) {
    state.bureauCustomerSelectorReturnType = returnType
  },
  setBureauCustomers (state, bureauCustomers) {
    state.bureauCustomers = bureauCustomers
  },
  setMetaData (state, meta) {
    state.metaData.tableLinks = meta.data.links
    state.metaData.totalPages = meta.data.meta.totalPages
    state.metaData.totalItems = meta.data.meta.totalItems
  },
  setCurrentBureauCustomer (state, response) {
    console.log('returning customer', response)
    if (response.deleted) {
      state.currentBureauCustomer = {}
    } else {
      state.currentBureauCustomer = response
    }
  },
  setBureauCustomerLabels (state, labels) {
    state.fieldLabels = labels
  },
  clearBureauCustomer (state) {
    state.currentBureauCustomer = {}
    console.log(state)
  },
  createBureauCustomer (state, bureauCustomer) {
    console.log('bureauCustomer', bureauCustomer)
    state.currentBureauCustomer = Object.assign({}, state.currentBureauCustomer, bureauCustomer)
    console.log('bureauCustomer state', state.currentBureauCustomer)
  }
}

const getters = {
  bureauCustomerSelectorSelection: (state) => {
    return state.bureauCustomerSelectorSelection
  },
  bureauCustomerSelectorFilter: (state) => {
    return state.bureauCustomerSelectorFilter
  },
  bureauCustomerSelectorReturnType: (state) => {
    return state.bureauCustomerSelectorReturnType
  },
  bureauCustomers: (state) => {
    return state.bureauCustomers
  },
  metaData: (state) => {
    return state.metaData
  },
  currentBureauCustomer: (state) => {
    return state.currentBureauCustomer
  },
  fieldLabels: (state) => {
    return state.fieldLabels
  }
}

const actions = {
  getBureauCustomerList: async function (state, s) {
    // ('BureauCustomer/GetBureauCustomerList/', { params: { query: queryObject } })
    console.log('serveraparms in getbclist', s)
    var param = JSON.stringify(s)
    console.log('para,', param)
    await axios
      .get('BureauCustomer/', {
        params: {
          page: s.page,
          perPage: s.perPage,
          columnFilters: s.columnFilters,
          sort: s.sort,
          searchKey: s.searchKey
        }
      })
      .then((response) => {
        state.commit('setBureauCustomers', response.data.data)
        state.commit('setMetaData', response)
      })
      .catch((error) => console.log('axios error', error))
  },
  getBureauCustomer: async function (state, id) {
    await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauCustomer/${id}`)
      .then((response) => {
        state.commit('setCurrentBureauCustomer', response.data.data[0])
      })
  },
  createBureauCustomer: async function (state, bureauCustomer) {
    console.log('in create bit', state, bureauCustomer)
    state.commit('clearBureauCustomer')
    state.commit('createBureauCustomer', bureauCustomer)
  },
  updateBureauCustomer: async function (state, bureauCustomer) {
    console.log('in the update')
    console.log('state', state)
    console.log('id', bureauCustomer.id)
    console.log('bureauCustomer', bureauCustomer)
    await axios.put(`${process.env.VUE_APP_BUREAU_API_URL}bureauCustomer/${bureauCustomer.id}`, bureauCustomer).then((response) => {
      state.commit('setCurrentBureauCustomer', response.data.data[0])
    })
  },

  createNewBureauCustomer: async function (state, bureauCustomer) {
    console.log('in create to database', bureauCustomer)
    await axios.post(`${process.env.VUE_APP_BUREAU_API_URL}bureauCustomer`, bureauCustomer).then((response) => {
      console.log('createresponse in store', response)
      state.commit('setCurrentBureauCustomer', response.data)
    })
  },

  deleteBureauCustomer: async function (state, internalId) {
    console.log('delete store', internalId)
    await axios.delete(`${process.env.VUE_APP_BUREAU_API_URL}bureauCustomer/${internalId}`).then((response) => {
      // state.commit('setCurrentBureauCustomer', response)
    })
  },

  getFieldLabels: async function (state) {
    // await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}BureauCustomer/GetBureauCustomerLabels`).then((labels) => {
    //   state.commit('setBureauCustomerLabels', labels.data)
    // })
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
