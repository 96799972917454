<template>
<div class="row">
  <div class="col-md-12">
    <div class="form-group">
      <label>{{label}}</label>
      <select class="form-control"
        v-model=value
        @input="$emit('input', $event.target.value)">
        <option v-for="option in options" :key="option.value" v-bind:value="option.value">
          {{option.text}}
        </option>
      </select>
    </div>
  </div>
</div>
</template>
<script>
import axios from 'axios'
// import Auth from '@/Assets/Components/Authentication/auth'
export default {
  name: 'BureauCustomer',
  props: [
    'multi',
    'name',
    'label',
    'value'
  ],
  data () {
    return {
      options: []
    }
  },
  created: async function () {
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    // let user = await Auth.getUser()
    // let paygateId = user.find(function (obj) { return obj.type === 'paygate_id' }).value
    if (!this.paygateId) {
      this.$toastr.e('Error getting list of available Bureau Customers. (001)', 'error')
      return
    }
    axios
      .get(`${process.env.VUE_APP_BUREAU_API_URL}bureaucustomer/customertitles`)
      .then(res => {
        console.log(res.data)
        for (const bc of res.data) {
          this.options.push({
            text: bc.text,
            value: bc.value
          })
        }
      })
      .catch(e => {
        console.log(e)
        this.$toastr.e('Error getting list of available Bureau Customers. (002)', e.message, 'error')
      })
  }
}
</script>
