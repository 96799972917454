<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Failed Collections Report</h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group mb-3">
          <div class="col-md-2">
            Date Filter Column:
          </div>
          <div class="col-md-4">
            <b-form-select
              class="form-control"
              :options="filterFields"
              v-model="selectedFilter"
              @input="filtersChanged()"
            />
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Earliest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="endPaymentDatePicker"
              v-model="start" :disabledDates="{ from: new Date(end) }"
              :format="formatDate" @input="filtersChanged()" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Latest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="startPaymentDatePicker"
              v-model="end"
              :format="formatDate" :disabledDates="{ to: new Date(start) }"
              @input="filtersChanged()" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <div class="vgt-selection-info-row clearfix" v-if="representRows.length > 0 && !isLoading && showSelectionInfo">
          {{selectionInfo}}
          <a href @click.prevent="clearSelections">Clear</a>
          <div class="vgt-selection-info-row__actions vgt-pull-right">
            <slot name="extra-select-info"></slot>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          class="scroll-table"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
          <span v-if="props.column.label === 'Represent'">
            <input v-if="props.row.canRepresent" type="checkbox" v-model="props.row.selected"  :disabled="!props.row.canRepresent"  @change="addToRepresent(props.row)" v-b-popover.hover.top.d500="'Represent collection.'" />
            <i  v-if="!props.row.canRepresent" class="fa fa-solid fa-ban" v-b-popover.hover.top.d500="returnText(props.row)"></i>
          </span>
            <span v-else-if="props.column.field === 'payerReference'">
              <router-link v-if="!props.row.deletedPayer" :to="`/collections/customer/${props.row.ukPayerId}/edit`">{{props.formattedRow[props.column.field]}}</router-link>
              <div v-b-popover.hover.top.d500="'This customer was deleted'" v-if="props.row.deletedPayer" >{{props.formattedRow[props.column.field]}}</div>
           </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
          </template>
          <template slot="table-column" slot-scope="props">
           <span v-if="props.column.label === 'Represent'">
              <input type="checkbox" v-model="representable" @change="addAllToRepresent" v-b-popover.hover.top.d500="'Select representable collections.'" />
           </span>
           <span v-else>
              {{props.column.label}}
           </span>
          </template>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">
        <b-btn id="addPayerButton" type="link" variant="primary" @click="rePresent" :disabled="representRows.length === 0">
          <i class="fa fa-plus mr-2"></i>Represent {{ representRows.length > 0 ? representRows.length : '' }} Selected Collections
        </b-btn>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import Datepicker from 'vuejs-datepicker'
  import papa from 'papaparse'
  import moment from 'moment'
  export default {
    mixins: [tableFilterMixin, loading],
    components: {
      Datepicker
    },
    data () {
      return {
        filterFields: [ 'Collection Date', 'Date Paid', 'Date of Failure Notification' ],
        selectedFilter: 'Collection Date',
        dateOptions: {
          format: 'DD/MM/YYYY HH:mm',
          useCurrent: false,
          stepping: 30,
          sideBySide: true,
        },
        end: null,
        start: null,
        rows: [],
        columns: [
          {
            label: 'Represent',
            field: 'checkbox',
            sortable: false,
            tdClass: 'text-center',
            thClass: 'text-center'
          },
          {
            label: 'paymentId',
            field: 'paymentId',
            hidden: true
          },
          {
            label: 'Customer Reference',
            field: 'payerReference'
          },
          {
            label: 'Schedule Reference',
            field: 'planReference'
          },
          {
            label: 'ukPayerId',
            field: 'ukPayerId',
            hidden: true
          },
          {
            label: 'Customer Name',
            field: 'payerName',
          },
          {
            label: 'Mobile Number',
            field: 'mobileNumber',
          },
          {
            label: 'Email Address',
            field: 'emailAddress',
          },
          {
            label: 'Collection Identifier',
            field: 'collectionIdentifier',
          },
          {
            label: 'Submission Reference',
            field: 'submissionReference',
          },
          {
            label: 'Collection Date',
            field: 'collectionDate',
            formatFn: this.formatDate
          },
          {
            label: 'Date Settled',
            field: 'datePaid',
            formatFn: this.formatDateWithNull
          },
          {
            label: 'Failure Date',
            field: 'failureDate',
            formatFn: this.formatDate
          },
          {
            label: 'Failure Reason',
            field: 'failureReason'
          },
          {
            label: 'Amount (£)',
            field: 'amount',
            formatFn: this.formatAmount,
            tdClass: 'text-right'
          }
        ],
        serverParams: {
          sort: [{ field: 'collectionDate', type: 'desc' }]
        },
        representRows: [],
        representable: false,
        showSelectionInfo: false
      }
    },
    mounted () {
      var savedParams = this.$store.getters.tableFilters(this.$route.path)
      if (savedParams) {
        // If there are, apply them!
        this.savedParamsToObjects(savedParams)
        this.serverParams = Object.assign({}, this.serverParams, savedParams)
      }
      //this.load()
    },
    methods: {
      async filtersChanged () {
        await this.$store.dispatch('setFailedCollectionFilterColumn', this.selectedFilter)
        await this.$store.dispatch('setFailedCollectionStartDate', this.start)
        await this.$store.dispatch('setFailedCollectionEndDate', this.end)
        await this.clearTableFilters()
      },
      load: async function () {
        try {
          if(this.$store.getters.collectionsFailedCollectionEndDate === null || this.$store.getters.collectionsFailedCollectionEndDate === undefined) {
            this.end = new Date()
            let tempStart = new Date()
            tempStart.setDate(this.end.getDate() - 1)
            tempStart.setHours(0,0,0,0)
            let tempEnd = new Date(this.end.setMonth(this.end.getMonth()+1));
            tempEnd.setHours(23,59,59,0)
            this.end = tempEnd
            this.$store.dispatch('setFailedCollectionEndDate', this.end)
          }
          else {
            this.end = this.$store.getters.collectionsFailedCollectionEndDate
          }
          if(this.$store.getters.collectionsFailedCollectionStartDate === null || this.$store.getters.collectionsFailedCollectionStartDate === undefined) {
            let tempStart = new Date()
            tempStart.setMonth(tempStart.getMonth() - 1)
            tempStart.setHours(0,0,0,0)
            this.start = tempStart
            this.$store.dispatch('setFailedCollectionStartDate', this.start)
          }
          else {
            this.start = this.$store.getters.collectionsFailedCollectionStartDate
          }
          console.log(this.start)
          console.log(this.end)
          this.selectedFilter = this.$store.getters.collectionsFailedCollectionFilterColumn
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/failedcollectionsreport`, { params: { ...this.buildGoodTableQuery(), startDate: this.start, endDate: this.end, filterBy: this.selectedFilter }, validateStatus: () => true, showLoad: true })
          // this.isLoading = true
          if (response.status === 200) {
            this.rows = response.data.data
            for (let i = 0; i < this.rows.length; i++) {
              this.rows[i].vgtDisabled = !this.rows[i].canRepresent
              this.rows[i].selected = this.rows[i].canRepresent && this.representRows.some(e=> e.paymentId === this.rows[i].paymentId)
            }
            this.totalRecords = response.data.count
          } else {
            if (response.status === 403) {
              this.$toastr.e('Not authorized to use this feature.')
              this.rows = []
              this.totalRecords = 0
            } else {
              this.$toastr.e('An error has occurred.')
            }
            this.isLoading = false
          }
        } catch (error) {
          this.$toastr.e(error)
        }
      },
      onRowChange (e) {
        // console.log(e)
        // this.$router.push({ path: `/collections/payer/${e.row.ukPayerId}/edit`, params: { openAt: 'messages'} })
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1 ).toFixed(2))
      },
      formatStatus (value) {
        let retVal = ''
        switch(value) {
          case 1:
            retVal = 'Created'
            break
          case 2:
            retVal = 'In Progress'
            break
          case 3:
            retVal = 'Resolved'
            break
          case 4:
            retVal = 'Rejected'
            break
          case 5:
            retVal = 'Action Required'
            break
          default:
            retVal = 'None'
            break
        }
        return retVal
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      formatDateWithNull (date) {
        if (date===null || date === '0001-01-01T00:00:00') {
          return 'N/A'
        } else {
          return this.formatDate(date)
        }
      },
      async exportTable () {
        var query = this.buildGoodTableQuery()
        var startDate = moment(this.end).format('DD-MM-YYYY')
        var endDate = moment(this.start).format('DD-MM-YYYY')
        query.perPage = this.totalRecords
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/failedcollectionsreport`, { params: {
          startDate: this.start, endDate: this.end,
          ...query, paygateId: this.paygateId, forExport: true, filterBy: this.selectedFilter }, showload: true })
        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, `failed-collections-report-${startDate}-to-${endDate}.csv`)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = `failed-collections-report-${startDate}-to-${endDate}.csv`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      codeToDescription (value) {
        let retval = ''
        switch (value) {
          case 1:
            retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
            break;
          case 2:
            retval = 'NO ADVANCE NOTICE WAS RECEIVED';
            break;
          case 3:
            retval = 'BANK CANCELS THE DIRECT DEBIT';
            break;
          case 4:
            retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
            break;
          case 5:
            retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
            break;
          case 6:
            retval = 'FRAUDULENT SIGNATURE';
            break;
          case 7:
            retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
            break;
          case 8:
            retval = 'SERVICE USER IS NOT RECOGNISED';
            break;
          default:
            retval = 'Invalid Code Supplied'
            break;
        }
        return retval
      },
      representRowSelected (e) {
        console.log(e.selectedRows)
        this.representRows = e.selectedRows.filter(a => a.canRepresent)
        console.log(this.rows)
        for (let i = 0; i < this.rows.length; i++){
          if (this.rows[i].vgtDisabled){
            // this.rows[i].vgtSelected = false
            this.$set(this.rows[i], 'vgtSelected', false);
          }
        }
      },
      returnText(row) {
        let retVal = 'Cannot Represent'
        if (row.outOfDateRange) {
          retVal = 'Date restrictions apply. Collection will be made 5 days after the Re-presentation is created, and may not be created for collections 25 days or older.'
        }
        if (row.alreadyRepresented) {
          retVal = 'This has already been represented'
        }
        if (row.notRepresentable) {
          retVal = 'Only failures caused by an ARUDD 0 can be represented'
        }
        return retVal;
      },
      async rePresent (e) {
        console.log(this.representRows)
        try {
          let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/represent`, this.representRows.map(x => x.paymentId))
          this.representable = false
          if (this.representRows.clear !== undefined) {
            this.representRows.clear()
          } else {
            this.representRows = []
          }
          this.$toastr.s('Collections represented')
          await this.load()
        } catch (e) {
          this.$toastr.e('Could not represent collections.')
        }
      },
      addToRepresent (row) {
        this.showSelectionInfo = false
        console.log(row)
        if (row.selected) {
          console.log('adding row')
          this.representRows.push(row)
          console.log(this.representRows)
        } else {
          console.log('removing row')
          let index = this.representRows.indexOf(row)
          this.representRows.splice(index, 1)
          this.representable = false
          console.log(this.representRows)
        }
        this.showSelectionInfo = true
      },
      async addAllToRepresent () {
        this.showSelectionInfo = false
        if(this.representable) {
          this.isLoading = true
          var query = this.buildGoodTableQuery()
          var startDate = moment(this.end).format('DD-MM-YYYY')
          var endDate = moment(this.start).format('DD-MM-YYYY')
          query.perPage = this.totalRecords
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/failedcollectionsreport`, {
            params: {
              startDate: this.start, endDate: this.end,
              ...query, paygateId: this.paygateId, canRepresentOnly: true, filterBy: this.selectedFilter
            }, showload: true
          })

          this.representRows =response.data.data
          if (response.data.data.length > 0) {
            await this.load()
            this.representRows = []
            this.isLoading = true
            this.representRows =response.data.data
            this.$toastr.s('Added')
          } else {
            this.$toastr.e('Nothing to represent')
            this.representRows =[]
            this.representable = false
          }
        } else {
          this.representRows = []
          await this.load()
          this.isLoading = true
          this.$toastr.s('Cleared')
        }
        this.isLoading = false
        this.showSelectionInfo = true
      },
      async clearSelections () {
        this.showSelectionInfo = false
        this.representable = false
        await this.addAllToRepresent()
        this.showSelectionInfo = true
      }
    },
    computed: {
      startDateOptions () {
        return { ...this.dateOptions, maxDate: this.end }
      },
      endDateOptions () {
        return { ...this.dateOptions, minDate: this.start }
      },
      selectionInfo () {
        return `${this.representRows.length} representable rows selected`
      },
    }
  }
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
