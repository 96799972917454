<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Anti-Fraud Lists
          <help-icon docPath="/administration/antifraudlists/" />
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="{
        enabled: true,
        perPage: 10,
      }"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          :searchOptions="{
        enabled: true
      }"
          @on-row-click="onRowClick"
          styleClass="vgt-table striped bordered"
        >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'description'">
            <span style="overflow-wrap: break-word; word-wrap: break-word; word-break: break-all;">{{props.row.description}}</span>
          </span>
          <span v-else>{{props.formattedRow[props.column.field]}}</span>
        </template>
          <div slot="table-actions">
            <button
              @click.prevent="create"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Create a new anti-fraud list'"
            >
              <i class="fa fa-plus dimmedIcon pointer"></i>
            </button>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" :disabled="!antiFraudLicenceSettings.enabled" @click="createNewAntiFraudList" type="button">
          <i class="fa fa-plus rpad"></i>Create Anti-Fraud List
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data: () => ({
    rows: [],
    columns: [
      {
        label: 'Name',
        field: 'name'
      },
      {
        label: 'Description',
        field: 'description'
      },
      {
        label: 'List Type',
        field: 'listType'
      }
    ],
    antiFraudLicenceSettings: {}
  }),
  watch: {
    selectedCustomer () { this.licenceCheck() }
  },
  async created () {
    this.licenceCheck()
    this.$store.dispatch('setAntiFraudList', undefined)
    this.$store.dispatch('setAntiFraudListItems', undefined)
  },
  methods: {
    onRowClick (params) {
      var antiFraudListId = params.row.id
      this.$router.push({ name: 'AntiFraudListEdit', params: { id: antiFraudListId } })
    },
    async loadAntiFraudLists () {
      var paygateId = this.selectedCustomer

      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList`, { params: { paygateid: paygateId } })
        this.rows = response.data.data
      } catch (e) {
        console.log(e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    async createNewAntiFraudList () {
      if (this.rows.length < this.antiFraudLicenceSettings.whitelistThreshold + this.antiFraudLicenceSettings.blacklistThreshold) {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/initAntiFraudList`)
        console.log('Init response: ' + JSON.stringify(response.data))
        var antiFraudListId = response.data.antiFraudListId
        console.log('New antiFraudListId: ' + antiFraudListId)
        this.$router.push({ name: 'AntiFraudListCreate', params: { id: antiFraudListId } })
      } else {
        this.$snapbar.e('You are not licensed to create any more Anti-Fraud lists.')
      }
    },

    async loadLicence () {
      var response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence')
      this.antiFraudLicenceSettings = response.data.antiFraud
      // console.log(JSON.stringify(this.antiFraudLicenceSettings))
      if (!this.antiFraudLicenceSettings.enabled) {
        this.$snapbar.e('You are not licensed to create Anti-Fraud lists.')
      }
    },

    async licenceCheck () {
      await this.loadLicence()
      if (this.antiFraudLicenceSettings.enabled) {
        this.loadAntiFraudLists()
      } else {
        this.rows = []
      }
    }
  }
}
</script>
