<template>
  <div>
    <form @submit.prevent="checkValidation() && saveLicence()" novalidate>
      <div class="row">
        <div class="col-md-12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>Resources</h2>
            </div>
            <div class="idb-block-content">
              <div v-if="areErrors" class="alert alert-danger" id="errors">
                <p>The licence cannot be saved due to these errors, please fix the problems in this list by either changing the related entities or amending the licence</p>
                <ul>
                  <li v-for="error in errors" :key="error.message">{{error.message}}</li>
                </ul>
              </div>
              <div class="col-md-10">
                <!-- TODO: This looks horid when shrunk to be responsive -->
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-3">Current Limit</div>
                    <div class="col-md-3">Used</div>
                    <div class="col-md-3">Available</div>
                  </div>
                  <div class="row top-buffer">
                    <div class="col-md-2">Users</div>
                    <div class="col-md-3" :class="{invalid: $v.licence.platform.users.$error}">
                      <input
                        class="form-control"
                        type="number"
                        v-model="$v.licence.platform.users.$model"
                      />
                      <!-- Validation -->
                      <validation-messages v-model="$v.licence.platform.users" name="limit"></validation-messages>
                    </div>
                    <div class="col-md-3">
                      <input class="form-control" readonly v-model="usersAmount" />
                    </div>
                    <div class="col-md-3">
                      <input class="form-control" readonly v-model="availableUsers" />
                    </div>
                  </div>
                  <div class="row top-buffer">
                    <div class="col-md-2">Groups</div>
                    <div class="col-md-3" :class="{invalid: $v.licence.platform.groups.$error}">
                      <input
                        class="form-control"
                        type="number"
                        v-model="$v.licence.platform.groups.$model"
                      />
                      <!-- Validation -->
                      <validation-messages v-model="$v.licence.platform.groups" name="limit"></validation-messages>
                    </div>
                    <div class="col-md-3">
                      <input class="form-control" readonly v-model="groupsAmount" />
                    </div>
                    <div class="col-md-3">
                      <input class="form-control" readonly v-model="availableGroups" />
                    </div>
                  </div>
                  <div class="row top-buffer">
                    <div class="col-md-2">Service Accounts</div>
                    <div
                      class="col-md-3"
                      :class="{invalid: $v.licence.platform.serviceAccounts.$error}"
                    >
                      <input
                        class="form-control"
                        type="number"
                        v-model="$v.licence.platform.serviceAccounts.$model"
                      />
                      <!-- Validation -->
                      <validation-messages
                        v-model="$v.licence.platform.serviceAccounts"
                        name="limit"
                      ></validation-messages>
                    </div>
                    <div class="col-md-3">
                      <input class="form-control" readonly v-model="serviceAccountAmount" />
                    </div>
                    <div class="col-md-3">
                      <input class="form-control" readonly v-model="availableServiceAccounts" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 col-lg-6">
          <!-- Payments -->
          <div v-show="false" class="idb-block">
            <div class="idb-block-title">
              <h2>Payments</h2>
            </div>
            <div class="idb-block-content">
              <!-- Bacs -->
              <div class="form-group row" :class="{invalid: $v.licence.bacs.value.$error}">
                <label class="col-form-label col-md-3">BACS</label>
                <div class="col-md-6">
                  <b-form-select v-model="$v.licence.bacs.value.$model" :options="paymentValues"></b-form-select>
                  <!-- Validation -->
                  <validation-messages v-model="$v.licence.bacs.value" name="bacs"></validation-messages>
                </div>
              </div>

              <!-- Allow Bank Grade -->
              <div
                class="form-group row"
                v-if="$v.licence.bacs.value.$model == 'Both' || $v.licence.bacs.value.$model == 'Direct'"
              >
                <label class="col-form-label col-md-3">Allow Bank Grade BACS</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.bacs.bankGrade.$model"
                  ></p-check>
                </div>
              </div>

              <!-- Allow Proxy Groups -->
              <div
                class="form-group row"
                v-if="$v.licence.bacs.value.$model == 'Both' || $v.licence.bacs.value.$model == 'Indirect'"
              >
                <label class="col-form-label col-md-3">Allow Multi-Block BACS</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.bacs.proxyGroup.$model"
                  ></p-check>
                </div>
              </div>

              <!-- Bacs Api -->
              <div
                class="form-group row"
                :class="{invalid: $v.licence.bacs.api.$error}"
                v-if="$v.licence.bacs.value.$model !== 'Off'"
              >
                <label class="col-form-label col-md-3">BACS API</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.bacs.api.$model"
                  ></p-check>
                </div>
              </div>

              <!-- FPS -->
              <div class="form-group row" :class="{invalid: $v.licence.fps.value.$error}">
                <label class="col-form-label col-md-3">Faster Payments Service (FPS)</label>
                <div class="col-md-6">
                  <b-form-select v-model="$v.licence.fps.value.$model" :options="paymentValues"></b-form-select>
                  <!-- Validation -->
                  <validation-messages v-model="$v.licence.fps.value" name="fps"></validation-messages>
                </div>
              </div>

              <!-- Allow Bank Grade; FPS bank grade subs will never pass pre-sub val so this will never be shown for now. Correct check is for 'Both' or 'Direct' -->
              <div
                class="form-group row"
                v-if="$v.licence.fps.value.$model == 'Foo' || $v.licence.fps.value.$model == 'Bar'"
              >
                <label class="col-form-label col-md-3">Allow Bank Grade FPS</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.fps.bankGrade.$model"
                  ></p-check>
                </div>
              </div>

              <!-- Allow Proxy Groups -->
              <div
                class="form-group row"
                v-if="$v.licence.fps.value.$model == 'Both' || $v.licence.fps.value.$model == 'Indirect'"
              >
                <label class="col-form-label col-md-3">Allow Multi-Block FPS</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.fps.proxyGroup.$model"
                  ></p-check>
                </div>
              </div>

              <!-- FPS Api -->
              <div
                class="form-group row"
                :class="{invalid: $v.licence.fps.api.$error}"
                v-if="$v.licence.fps.value.$model !== 'Off'"
              >
                <label class="col-form-label col-md-3">FPS API</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.fps.api.$model"
                  ></p-check>
                </div>
              </div>
            </div>
          </div>

          <!-- Bureau -->
          <div class="idb-block" v-if="false">
            <div class="idb-block-title">
              <h2>Bureau</h2>
            </div>
            <div class="idb-block-content">
              <!-- Enabled -->
              <div class="form-group row" :class="{invalid: $v.licence.bureau.value.$error}">
                <label class="col-form-label col-md-3">Enabled</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.bureau.value.$model"
                  ></p-check>
                  <!-- Validation -->
                  <validation-messages v-model="$v.licence.bureau.value" name="bureau"></validation-messages>
                </div>
              </div>

              <!-- Limit -->
              <div
                v-if="$v.licence.bureau.value.$model"
                class="form-group row"
                :class="{invalid: $v.licence.bureau.threshold.$error}"
              >
                <label class="col-form-label col-md-3">Limit</label>
                <div class="col-md-6">
                  <input
                    type="number"
                    class="form-control"
                    v-model="$v.licence.bureau.threshold.$model"
                  />
                  <small class="form-text text-muted">This is the amount of unique SUN's per month</small>
                  <!-- Validation -->
                  <validation-messages v-model="$v.licence.bureau.threshold" name="limit"></validation-messages>
                </div>
              </div>

              <!-- Bureau Api -->
              <div
                class="form-group row"
                :class="{invalid: $v.licence.bureau.api.$error}"
                v-if="$v.licence.bureau.value.$model"
              >
                <label class="col-form-label col-md-3">Bureau API</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.bureau.api.$model"
                  ></p-check>
                </div>
              </div>
            </div>
          </div>

          <!-- Other -->
          <div class="idb-block">
            <div class="idb-block-title">Other</div>
            <div class="idb-block-content">
              <!-- SMS Enabled -->
              <div class="form-group row" :class="{invalid: $v.licence.other.sms.$error}">
                <label class="col-form-label col-md-3">SMS Enabled</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.other.sms.$model"
                  ></p-check>
                  <!-- Validation -->
                  <validation-messages v-model="$v.licence.other.sms" name="sms"></validation-messages>
                </div>
              </div>

              <!-- SMS Limit -->
              <div
                v-if="$v.licence.other.sms.$model"
                class="form-group row"
                :class="{invalid: $v.licence.other.smsThreshold.$error}"
              >
                <label class="col-form-label col-md-3">SMS Limit</label>
                <div class="col-md-6">
                  <input
                    type="number"
                    class="form-control"
                    v-model="$v.licence.other.smsThreshold.$model"
                  />
                  <small class="form-text text-muted">This is the amount of SMS's per month</small>
                  <!-- Validation -->
                  <validation-messages v-model="$v.licence.other.smsThreshold" name="limit"></validation-messages>
                </div>
              </div>

              <!-- Email Limit -->
              <div
                class="form-group row"
                :class="{invalid: $v.licence.other.emailThreshold.$error}"
              >
                <label class="col-form-label col-md-3">Email Limit</label>
                <div class="col-md-6">
                  <input
                    type="number"
                    class="form-control"
                    v-model="$v.licence.other.emailThreshold.$model"
                  />
                  <small class="form-text text-muted">This is the amount of emails per month</small>
                  <!-- Validation -->
                  <validation-messages v-model="$v.licence.other.emailThreshold" name="limit"></validation-messages>
                </div>
              </div>

              <!-- Storage -->
              <div class="form-group row" :class="{invalid: $v.licence.other.storage.$error}">
                <label class="col-form-label col-md-3">Secure Cloud Storage</label>
                <div class="col-md-6">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      v-model="$v.licence.other.storage.$model"
                      aria-describedby="storage-addon"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text" id="storage-addon">GB</span>
                    </div>
                  </div>
                  <input
                    type="range"
                    class="custom-range"
                    v-model="$v.licence.other.storage.$model"
                    min="1"
                    max="10"
                    step="1"
                  />
                  <!-- Validation -->
                  <validation-messages v-model="$v.licence.other.storage" name="sms"></validation-messages>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-6">
          <!-- Validation -->
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>Validation</h2>
            </div>
            <div class="idb-block-content">
              <!-- Enabled -->
              <div class="form-group row" :class="{invalid: $v.licence.validate.value.$error}">
                <label class="col-form-label col-md-3">Enabled</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.validate.value.$model"
                  ></p-check>
                  <!-- Validation -->
                  <validation-messages v-model="$v.licence.validate.value" name="validate"></validation-messages>
                </div>
              </div>

              <div v-if="$v.licence.validate.value.$model">
                <!-- Modulus Check -->
                <div class="form-group row" :class="{invalid: $v.licence.validate.modCheck.$error}">
                  <label class="col-form-label col-md-3">UK Bank Account</label>
                  <div class="col-md-6">
                    <p-check
                      class="p-switch p-fill"
                      color="primary"
                      v-model="$v.licence.validate.modCheck.$model"
                    ></p-check>
                    <!-- Validation -->
                    <validation-messages
                      v-model="$v.licence.validate.modCheck"
                      name="modulus check"
                    ></validation-messages>
                  </div>
                </div>

                <!-- Iban -->
                <div class="form-group row" :class="{invalid: $v.licence.validate.iban.$error}">
                  <label class="col-form-label col-md-3">IBAN</label>
                  <div class="col-md-6">
                    <p-check
                      class="p-switch p-fill"
                      color="primary"
                      v-model="$v.licence.validate.iban.$model"
                    ></p-check>
                    <!-- Validation -->
                    <validation-messages v-model="$v.licence.validate.iban" name="iban"></validation-messages>
                  </div>
                </div>

                <!-- Threshold -->
                <div
                  class="form-group row"
                  :class="{invalid: $v.licence.validate.threshold.$error}"
                >
                  <label class="col-form-label col-md-3">Limit</label>
                  <div class="col-md-6">
                    <input
                      type="number"
                      class="form-control"
                      v-model="$v.licence.validate.threshold.$model"
                    />
                    <small
                      class="form-text text-muted"
                    >This is the amount of billable modchecks per month</small>
                    <!-- Validation -->
                    <validation-messages v-model="$v.licence.validate.threshold" name="limit"></validation-messages>
                  </div>
                </div>

                <!-- validate Api -->
                <div
                  class="form-group row"
                  :class="{invalid: $v.licence.validate.api.$error}"
                  v-if="$v.licence.validate.value.$model"
                >
                  <label class="col-form-label col-md-3">Validate API</label>
                  <div class="col-md-6">
                    <p-check
                      class="p-switch p-fill"
                      color="primary"
                      v-model="$v.licence.validate.api.$model"
                    ></p-check>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Agent -->
          <!-- <div class="idb-block">
            <div class="idb-block-title">
              <h2>Agent</h2>
            </div>
            <div class="idb-block-content">
              <div class="form-group row" :class="{invalid: $v.licence.agent.value.$error}">
                <label class="col-form-label col-md-3">Enabled</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.agent.value.$model"
                  ></p-check>
                  <validation-messages v-model="$v.licence.agent.value" name="enabled"></validation-messages>
                </div>
              </div>

              <div v-if="$v.licence.agent.value.$model">
                <div
                  class="form-group row"
                  :class="{invalid: $v.licence.agent.useOwnCertificate.$error}"
                >
                  <label class="col-form-label col-md-3">Use Own Certificate</label>
                  <div class="col-md-6">
                    <p-check
                      class="p-switch p-fill"
                      color="primary"
                      v-model="$v.licence.agent.useOwnCertificate.$model"
                    ></p-check>
                    <validation-messages
                      v-model="$v.licence.agent.useOwnCertificate"
                      name="use own certificate"
                    ></validation-messages>
                  </div>
                </div>

                <div
                  class="form-group row"
                  :class="{invalid: $v.licence.agent.allowFtpAddressChange.$error}"
                >
                  <label class="col-form-label col-md-3">Allow FTP Address Change</label>
                  <div class="col-md-6">
                    <p-check
                      class="p-switch p-fill"
                      color="primary"
                      v-model="$v.licence.agent.allowFtpAddressChange.$model"
                    ></p-check>
                    <validation-messages
                      v-model="$v.licence.agent.allowFtpAddressChange"
                      name="allow FTP address change"
                    ></validation-messages>
                  </div>
                </div>
              </div>
            </div>
          </div>-->

          <!-- Collections -->
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>Collections</h2>
            </div>
            <div class="idb-block-content">
              <!-- Enabled -->
              <div
                v-if="!$v.licence.collections.value.$model"
                class="form-group row"
                :class="{invalid: $v.licence.collections.value.$error}"
              >
                <label class="col-form-label col-md-3">Enabled</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.collections.value.$model"
                    disabled="disabled"
                  ></p-check>
                  <!-- Validation -->
                  <validation-messages v-model="$v.licence.collections.value" name="collections"></validation-messages>
                </div>
              </div>

              <!-- Onboarding -->
              <div
                class="form-group row"
                :class="{invalid: $v.licence.collections.onBoarding.$error}"
                v-if="$v.licence.collections.value.$model"
              >
                <label class="col-form-label col-md-3">Online Signup</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.collections.onBoarding.$model"
                  ></p-check>
                </div>
              </div>

              <!-- Payer Portal - knocked out in JFTP. -->
              <div
                class="form-group row"
                :class="{invalid: $v.licence.collections.payerPortal.$error}"
                v-if="$v.licence.collections.value.$model && false"
              >
                <label class="col-form-label col-md-3">Customer Portal</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.collections.payerPortal.$model"
                  ></p-check>
                </div>
              </div>

              <!-- Payer Threshold -->
              <div
                class="form-group row"
                :class="{invalid: $v.licence.collections.payerThreshold.$error}"
                v-if="$v.licence.collections.value.$model && false"
              >
                <label class="col-form-label col-md-3">Customer Limit</label>
                <div class="col-md-6">
                  <input
                    type="number"
                    class="form-control"
                    v-model="$v.licence.collections.payerThreshold.$model"
                  />
                  <!-- Validation -->
                  <validation-messages
                    v-model="$v.licence.collections.payerThreshold"
                    name="payer limit"
                  ></validation-messages>
                </div>
              </div>
              <!-- Collections Api -->
              <div
                class="form-group row"
                :class="{invalid: $v.licence.collections.api.$error}"
                v-if="$v.licence.collections.value.$model"
              >
                <label class="col-form-label col-md-3">Collections API</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.collections.api.$model"
                  ></p-check>
                </div>
              </div>
              <!-- Refunds -->
              <div
                class="form-group row"
                :class="{invalid: $v.licence.collections.refunds.$error}"
                v-if="$v.licence.collections.value.$model && false"
              >
                <label class="col-form-label col-md-3">Allow Refunds</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.collections.refunds.$model"
                  ></p-check>
                </div>
              </div>
            </div>
          </div>

          <!-- Anti-Fraud module -->
          <div v-show="false" class="idb-block">
            <div class="idb-block-title">
              <h2>Anti-Fraud Module</h2>
            </div>
            <div class="idb-block-content">
              <!-- Enabled -->
              <div class="form-group row" :class="{invalid: $v.licence.antiFraud.enabled.$error}">
                <label class="col-form-label col-md-3">Enabled</label>
                <div class="col-md-6">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.licence.antiFraud.enabled.$model"
                  ></p-check>
                  <!-- Validation -->
                  <validation-messages v-model="$v.licence.antiFraud.enabled" name="antiFraud"></validation-messages>
                </div>
              </div>

              <!-- Whitelist Limit -->
              <div
                v-if="$v.licence.antiFraud.enabled.$model"
                class="form-group row"
                :class="{invalid: $v.licence.antiFraud.whitelistThreshold.$error}"
              >
                <label class="col-form-label col-md-3">Whitelist Limit</label>
                <div class="col-md-6">
                  <input
                    type="number"
                    class="form-control"
                    v-model="$v.licence.antiFraud.whitelistThreshold.$model"
                  />
                  <small
                    class="form-text text-muted"
                  >This is the amount of whitelists you can have at one time</small>
                  <!-- Validation -->
                  <validation-messages
                    v-model="$v.licence.antiFraud.whitelistThreshold"
                    name="limit"
                  ></validation-messages>
                </div>
              </div>

              <!-- Blacklist Threshold -->
              <div
                v-if="$v.licence.antiFraud.enabled.$model"
                class="form-group row"
                :class="{invalid: $v.licence.antiFraud.blacklistThreshold.$error}"
              >
                <label class="col-form-label col-md-3">Blacklist Limit</label>
                <div class="col-md-6">
                  <input
                    type="number"
                    class="form-control"
                    v-model="$v.licence.antiFraud.blacklistThreshold.$model"
                  />
                  <small
                    class="form-text text-muted"
                  >This is the amount of blacklists you can have at one time</small>
                  <!-- Validation -->
                  <validation-messages
                    v-model="$v.licence.antiFraud.blacklistThreshold"
                    name="limit"
                  ></validation-messages>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-footer">
          <button
            class="btn"
            :class="needsChecking? 'btn-warning': 'btn-success'"
            type="submit"
            :disabled="(areErrors && !needsChecking) || isLoading"
          >{{needsChecking? 'Check conflicts':'Save'}}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, numeric, minValue, requiredIf } from 'vuelidate/lib/validators'
import { int32 } from '@/Assets/Validators'
import axios from 'axios'
import { mapGetters } from 'vuex'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  mixins: [loading],
  computed: {
    availableUsers () {
      return this.licence.platform.users - this.usersAmount
    },
    availableGroups () {
      return this.licence.platform.groups - this.groupsAmount
    },
    availableServiceAccounts () {
      return this.licence.platform.serviceAccounts - this.serviceAccountAmount
    },
    areErrors () {
      return this.errors.some(() => true)
    },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    '$v.$anyDirty': function (val) {
      if (val) {
        this.needsChecking = true
      }
    },
    async selectedCustomer () { await this.loadLicence() }
  },
  data () {
    return {
      needsChecking: true,
      usersAmount: 0,
      groupsAmount: 0,
      serviceAccountAmount: 0,
      licence: {
        platform: {
          users: 10,
          groups: 3,
          serviceAccounts: 0
        },
        bacs: {
          value: 'Direct',
          api: false,
          bankGrade: false,
          proxyGroup: false
        },
        fps: {
          value: 'Off',
          api: false,
          bankGrade: false,
          proxyGroup: false
        },
        collections: {
          value: true,
          api: false,
          onBoarding: false,
          payerPortal: false,
          payerThreshold: 10,
          refunds: false
        },
        bureau: {
          value: false,
          api: false,
          threshold: 100
        },
        validate: {
          value: true,
          modCheck: false,
          iban: false,
          cc: false,
          api: false,
          threshold: 20000
        },
        automation: {
          value: true,
          workflow: false,
          mode: 'Managed',
          frequency: 'Hourly',
          liveWorkflows: 5,
          api: false,
          folderManager: false,
          mappingDesigner: false,
          workflowDesigner: false
        },
        agent: {
          value: false,
          useOwnCertificate: false,
          allowFtpAddressChange: false
        },
        other: {
          sms: false,
          smsThreshold: 10000,
          emailThreshold: 10000,
          storage: 1
        },
        antiFraud: {
          enabled: false,
          whitelistThreshold: 0,
          blacklistThreshold: 0
        }
      },
      paymentValues: ['Off', 'Direct', 'Indirect', 'Both'],
      triggerFrequency: [
        { value: 'Hourly', text: 'Hourly' },
        { value: 'FifteenMinutes', text: '15 Minutes' },
        { value: 'FiveMinutes', text: '5 Minutes' },
        { value: 'EveryMinute', text: 'Every Minute' },
        { value: 'EverySecond', text: 'Every Second' }
      ],
      errors: []
    }
  },
  async created () {
    await this.loadLicence()
  },
  methods: {
    async saveLicence () {
      if (this.needsChecking) {
        try {
          var checkResponse = await axios.post(
            `${process.env.VUE_APP_PLATFORM_API_URL}Licence/Check`,
            this.licence,
            { showload: true, showerror: true, errormessage: 'Failed to check licence' }
          )
          if (checkResponse.data.error) {
            this.$snapbar.w('There are modules in paygate which are unlicensed')
            this.errors = checkResponse.data.errors
            var container = document.getElementById('base-container')
            container.scrollTop = 0
          } else {
            this.errors = []
            this.$snapbar.hide()
          }

          this.needsChecking = false
          this.$v.$reset()
        } catch { }
      } else {
        try {
          await axios.put(
            `${process.env.VUE_APP_PLATFORM_API_URL}Licence`,
            this.licence,
            { showload: true, showerror: true, errormessage: 'Failed to save licence' }
          )
          this.$toastr.s('Licence has been successfully saved!', 'Saved')
          await this.loadLicence()
        } catch { }
      }
    },
    async loadLicence () {
      this.$snapbar.hide()
      try {
        this.$Progress.start()

        const results = await Promise.allSettled([
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence`),
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/GroupCount`),
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/UserCount`),
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/ServiceAccountCount`)])

        const licenceResponse = results[0].value
        const groupResponse = results[1].value
        const userResponse = results[2].value
        const serviceAccountResponse = results[3].value

        this.licence = licenceResponse.data
        this.licence.collections.value = true
        this.errors = []
        this.groupsAmount = groupResponse.data
        this.usersAmount = userResponse.data
        this.serviceAccountAmount = serviceAccountResponse.data
      } catch {
        this.$Progress.fail()
      } finally {
        await this.$nextTick()
        this.$v.$reset()
        this.$Progress.finish()
      }
    }
  },
  validations: {
    licence: {
      platform: {
        users: { numeric, required, minValue: minValue(1), int32 },
        groups: { numeric, required, minValue: minValue(0), int32 },
        serviceAccounts: { numeric, required, minValue: minValue(0), int32 }
      },
      bacs: {
        value: { required },
        bankGrade: {},
        proxyGroup: {},
        api: {}
      },
      fps: {
        value: { required },
        bankGrade: {},
        proxyGroup: {},
        api: {}
      },
      collections: {
        value: {},
        api: {},
        onBoarding: {},
        payerPortal: {},
        payerThreshold: {
          numeric,
          required: requiredIf((model) => {
            return model.value
          }),
          int32
        },
        refunds: {}
      },
      bureau: {
        value: {},
        api: {},
        threshold: {
          numeric,
          required: requiredIf((model) => {
            return model.value
          }),
          int32
        }
      },
      validate: {
        value: {},
        modCheck: {},
        iban: {},
        cc: {},
        threshold: {
          numeric,
          required: requiredIf((model) => {
            return model.value
          }),
          int32
        },
        api: {}
      },
      automation: {
        value: {},
        workflow: {},
        mode: {
          required: requiredIf((model) => {
            return model.value
          })
        },
        frequency: {
          required: requiredIf((model) => {
            return model.workflow
          })
        },
        liveWorkflows: {
          required: requiredIf((model) => {
            return model.workflow
          }),
          numeric,
          minValue: minValue(0),
          int32
        },
        api: {},
        folderManager: {},
        mappingDesigner: {},
        workflowDesigner: {}
      },
      agent: {
        value: {},
        useOwnCertificate: {},
        allowFtpAddressChange: {}
      },
      other: {
        sms: {},
        smsThreshold: {
          numeric,
          required: requiredIf((model) => {
            return model.sms
          }),
          int32
        },
        emailThreshold: { numeric, required, int32 },
        storage: { required, numeric }
      },
      antiFraud: {
        enabled: {},
        whitelistThreshold: {
          numeric,
          required: requiredIf((model) => {
            return model.enabled
          }),
          int32
        },
        blacklistThreshold: {
          numeric,
          required: requiredIf((model) => {
            return model.enabled
          }),
          int32
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.top-buffer {
  margin-top: 20px;
}
</style>
