import axios from 'axios'
import groupTypesConst from '@/Assets/Constants/groupTypes'

// initial state
const state = {
  groups: null,
  rightsGroups: null,
  bacsGroup: {},
  groupBankAccount: {},
  selectedGroup: {},
  groupTypes: []
}

// getters
const getters = {
  customerGroups: (state) => {
    return state.groups
  },
  // FROM BACS
  groups: state => {
    return state.groups
  },
  rightsGroups: state => {
    return state.rightsGroups
  },
  bacsGroup: state => {
    return state.bacsGroup
  },
  groupBankAccount: state => {
    return state.groupBankAccount
  },
  selectedGroup: state => {
    return state.selectedGroup
  },
  availableGroupTypes: state => state.groupTypes
}

// actions
const actions = {
  async loadCustomerGroups ({ commit, rootState }, paygateId) {
    let res
    try {
      // eslint-disable-next-line
      res = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${paygateId}/2/`, { params: { 'rights': ['Create', 'Add', 'Edit', 'Delete'] } })
      console.log('GROUPS!', res.data)
      this.options = res.data
    } catch (e) {
      this.$snapbar.e('Error getting list of available groups. (002)')
    }
    commit('setCustomerGroups', res.data)
    return res.data.data
  },
  async getGroupsWithRights ({ commit }, payload) {
    const ukddmsEnumType = 2
    let url = `${process.env.VUE_APP_PLATFORM_API_URL}groups/${payload.paygateId}/${ukddmsEnumType}/true`
    let rights = payload.rights ? payload.rights : ['groupadministrator', 'create', 'add', 'edit', 'delete']
    let response = await axios.get(url, { params: { rights } })
    commit('setGroupsWithRights', response.data)
  },

  async getGroups ({ commit }, payload) {
    var url = process.env.VUE_APP_PLATFORM_API_URL + 'groups/' + payload.paygateId + '/' + payload.groupType // + '/' + payload.or
    if (payload.or !== undefined) {
      url += '/' + payload.or
    }
    var response = await axios.get(url, { params: { rights: payload.groupRole }, showload: true })
    commit('setGroups', response.data)
  },

  async getGroupTypes ({ commit }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/Types`)
      commit('setGroupTypes', response.data)
    } catch (e) {
      this.$toastr.e(e.response.data, e.response.statusText)
    }
  },
  getBacsGroup ({ commit }, payload) {
    var tmpGroups = this.getters.groups
    var selectedGroup = tmpGroups.find(i => i.groupId === payload.groupId)
    commit('setBacsGroup', selectedGroup)
  },

  async getSubBacsGroup ({ commit }, groupId) {
    var url = process.env.VUE_APP_PLATFORM_API_URL + 'groups/' + groupId
    var response = await axios.get(url)
    commit('setBacsGroup', response.data)
  },

  async getGroupBankAccount ({ commit }, payload) {
    var url = process.env.VUE_APP_PLATFORM_API_URL + 'bankaccounts/forGroup/' + payload.groupId
    var response = await axios.get(url)
    commit('setGroupBankAccount', response.data)
  },

  async setGroups ({ commit }, groups) {
    commit('setGroups', groups)
  }
}

// mutations
const mutations = {
  setCustomerGroups (state, groups) {
    state.groups = groups
  },
  setGroupsWithRights (state, groups) {
    state.rightsGroups = groups
  },
  // FROM BACS
  setGroups (state, groups) {
    state.groups = groups
  },

  setBacsGroup (state, bacsGroup) {
    state.bacsGroup = bacsGroup
  },

  setGroupBankAccount (state, groupBankAccount) {
    state.groupBankAccount = groupBankAccount
  },

  setSelectedGroup (state, selectedGroup) {
    state.selectedGroup = selectedGroup
  },
  setGroupTypes (state, groupTypes) {
    state.groupTypes = groupTypes.map(gt => groupTypesConst[gt])
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
