<template>
  <div>
    <div class="idb-block mt-2">
      <div class="idb-block-title">
        <h2>Manage Security Certificate Image</h2>
      </div>
      <div class="idb-block-content">
        <div v-if="currentImageFile.addedDate !== null">
          <p>
            <strong>Current image:</strong>
            {{currentImageFile.certificateImage}}
            <br />
            <img
              v-if="currentImageFile.certificateImage !== ''"
              :src="previewImageUrl"
              alt="Current Selected Logo Image"
            />
          </p>
          <p>
            <strong>Last Updated:</strong>
            {{formatDate(currentImageFile.addedDate)}}
          </p>
        </div>
        <b-container>
          <dropzoneLike
            v-if="!useDropzone"
            ref="serverImageFileUploader"
            @fileAdded="publicImageFileAdded"
            @fileRemoved="publicImageFileRemoved"
            :dropzoneOptions="dropzoneOptions"
            :dropzoneUrl="dropzoneImageUrl"
            :secureUrl="secureImageUrl"
            :groupId="group"
          ></dropzoneLike>
        </b-container>
        <vue-dropzone
          id="drop2"
          ref="imageFileUploader"
          :url="dropzoneImageUrl"
          :options="dropzoneImageOptions"
          @vdropzone-success="afterImageSuccess"
          @vdropzone-file-added="fileImageAdded"
          @vdropzone-removed-file="fileImageRemoved"
          @vdropzone-sending="sendingImageEvent"
          :use-custom-slot="true"
          :accepted-file-types="dropzoneImageOptions.acceptedFiles"
          :max-file-size-in-m-b="200"
          :useCustomDropzoneOptions="true"
          :use-custom-drop-zone-options="true"
          :useCustomSlot="true"
          v-if="useDropzone"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">{{dropzoneImageOptions.title}}</h3>
            <div class="subtitle">{{dropzoneImageOptions.subtitle}}</div>
          </div>
        </vue-dropzone>
      </div>
      <div class="idb-block-footer noprint-footer"></div>
    </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import dropzoneLike from '@/Components/Shared/DropzoneLike.vue'
import EventBus from '@/Lib/eventBus'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  components: {
    dropzoneLike
  },
  data () {
    return {
      updateStatusConnection: null,
      updateHubUrl: '',
      fileFormats: ['css'], // xml, json
      publicFiles: [],
      fileIds: [],
      fileFormat: 'css',
      bearerToken: '',
      group: null,
      dropzoneImageOptions: {
        maxFilesize: 5, // MB
        maxFiles: 4,
        acceptedFiles: '.png',
        dictDefaultMessage: '<i class="fa fa-cloud-upload fa-4x"></i><br/><br/><h3 class="dropzone-custom-title">Drop an image file here.</h3><div class="subtitle">Or click to select a file to upload.</div>',
        url: '',
        title: 'Drag and drop to upload image',
        subtitle: '...or click to select an image file from your computer'
      },
      currentImageFile: {
        certificateImage: '',
        addedDate: null,
        customerName: ''
      },
      useDropzone: true,
      fileGenerated: false,
      documentApproved: false
    }
  },
  computed: {
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = this.$store.getters.customerGroups
      }
      return ret
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    dropzoneImageUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/certificateimage`
    },
    secureImageUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/certificateimage/secure`
    },
    previewImageUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/certificateimage/preview`
    }
  },
  async created () {
    const paygateId = this.paygateId
    this.dropzoneImageOptions.url = this.dropzoneImageUrl + '?paygateId=' + paygateId
    this.dropzoneImageOptions.secureUrl = this.secureImageUrl + '?paygateId=' + paygateId
  },
  async mounted () {
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
      })
    var response = await axios.get(this.dropzoneImageUrl, { showload: true, params: { paygateId: this.paygateId } })
    if (response) {
      this.currentImageFile = response.data
    }
  },
  methods: {
    stopSocketListener () {
      this.updateStatusConnection.stop()
    },
    afterImageSuccess (file, response) {
      this.currentImageFile = response
      this.$toastr.s('Certificate Image Updated.')
    },
    fileImageAdded (file) {
      this.$refs.imageFileUploader.setOption('headers', { Authorization: this.bearerToken })
    },
    fileImageRemoved (file, error, xhr) {

    },
    sendingImageEvent (file, xhr, formData) {
      formData.append('paygateId', this.paygateId)
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var retVal = `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      console.log(retVal)
      return retVal
    }
  }
}
</script>
