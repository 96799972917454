<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          <help-icon docPath="/paygate-collections/portal/" />Portal Settings
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div class="idb-block-content">
        <b-form-row>
          <b-form-group
            :label-cols="5"
            class="col-sm-5"
            horizontal
            label="Group"
            label-class="required"
          >
            <group-select id="groupSelect" v-model="groupId" :groups="customerGroups"></group-select>
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group
            :label-cols="5"
            class="col-sm-5"
            label-class="required"
            horizontal
            label="Email Subject"
          >
            <b-form-input
              type="text"
              class="form-control"
              v-model="emailSubject"
              @input="delayTouch($v.emailSubject)"
              id="subjectInput"
            />
            <b-col v-if="$v.emailSubject.$dirty">
              <label
                id="subjectRequiredLabel"
                class="text-danger small"
                v-if="$v.emailSubject.$invalid"
              >Please ensure a subject is provided</label>
            </b-col>
          </b-form-group>
        </b-form-row>
        <b-form-group>
          <label>Message Body</label>
          <wysiwyg v-model="emailBody" @input="delayTouch($v.emailBody)"></wysiwyg>
          <b-col v-if="$v.emailBody.$dirty">
            <label
              id="emailBodyRequiredLabel"
              class="text-danger small"
              v-if="$v.emailBody.$invalid"
            >Ensure the Email body includes {####}.</label>
          </b-col>
        </b-form-group>
      </div>
      <div class="idb-block-footer">
        <b-button
          variant="primary"
          :disabled="$v.$invalid || isLoading"
          @click.prevent="sendBulk"
        >Send</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Wysiwyg from '@/Components/Collections/EmailWysiwyg.vue'
import { required } from 'vuelidate/lib/validators'
import swal from 'sweetalert2'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
const touchMap = new WeakMap()
export default {
  mixins: [loading],
  components: {
    Wysiwyg
  },
  data () {
    return {
      groupId: '00000000-0000-0000-0000-000000000000',
      emailBody: '',
      emailSubject: ''
    }
  },
  computed: {
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups)
      }
      ret.unshift({
        'description': '', 'groupId': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'name': 'All ', 'paygateId': this.paygateid, 'isItemActioned': false, 'clonedName': null, 'colour': null
      })
      return ret
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    }
  },
  methods: {
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
    },
    delayTouch ($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    async sendBulk () {
      var result = await this.confirm()
      if (result) {
        var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}portalmessaging/bulksend`, {
          paygateId: this.paygateId,
          groupId: this.groupId,
          messageSubject: this.emailSubject,
          messageBody: this.emailBody
        }, { showload: true, params: { paygateId: this.paygateId } })
      }
      if (response.data.success) {
        this.emailBody = ''
        this.emailSubject = ''
        this.groupId = '00000000-0000-0000-0000-000000000000'
        this.$v.$reset()
        this.$toastr.s('Message sent.')
      } else {
        this.$toastr.e('Message failed.')
      }
    },
    async confirm () {
      var result = null
      try {
        result = await swal({
          title: 'Confirm',
          text: `This message will be sent to all payers${this.groupId !== '00000000-0000-0000-0000-000000000000' ? ' in the group' : ''}.`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.primary,
          confirmButtonText: 'Proceed',
          cancelButtonText: 'Cancel'
        })
      } catch (err) {
        result = false
      }
      return result
    }
  },
  async mounted () {
    await this.loadGroups()
  },
  validations: {
    emailBody: { required },
    emailSubject: { required }
  }
}
</script>
