<template>
  <div id="viewFlaggedSubmissions">
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2><help-icon docPath="/paygate-collections/submissions/" />Scheduled AUDDIS Submission</h2>
          </div>
          <div class="idb-block-content">
            <b-row style="margin-bottom: 0.8em;">
              <b-col md="4"><strong>Group:</strong> {{ flaggedPayments.length > 0 ? flaggedPayments[0].groupName : '' }} </b-col>
              <b-col md="4"><strong>Next Processing Date:</strong> {{ formatDate(dates.proc) }} </b-col>
              <b-col md="4"><strong>Next Collection Date:</strong> {{ formatDate(dates.coll) }} </b-col>
            </b-row>
            <vue-good-table
              ref="table"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              :columns="columns"
              :rows="flaggedPayments"
              :lineNumbers="true"
              :totalRows="totalRecords"
              :search-options="{
                enabled: true
                }"
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              styleClass="vgt-table striped bordered">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'viewBtn'">
                  <span style="font-weight: bold; color: blue;">View</span>
                </span>
                <span v-else-if="props.column.field == 'viewPayerBtn'">
                  <router-link :to="{ name: 'editPayer', params: { ukPayerId: props.row.ukPayerId } }"> <!-- eslint-disable-line -->
                      <span>View Customer</span>
                    </router-link>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
              <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">
                    No Flagged Collections Found
                  </div>
                </div>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters" :disabled="isLoading"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'"
                  >
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span>
                  </button>
                  <b-button @click.prevent="load" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Refresh the data in the table'">
                    <i class="fa fa-sync pointer dimmedIcon"></i>
                  </b-button>
                  <b-button @click.prevent="printTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Print out the contents of the Logs table'">
                    <i class="fa fa-print pointer dimmedIcon"></i>
                  </b-button>
                  <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the Logs table'">
                    <i class="fa fa-share-square pointer dimmedIcon"></i>
                  </b-button>
                </div>
              </vue-good-table>
          </div>
          <div class="idb-block-footer mt-3">
            <b-btn type="link" variant="default" @click.prevent="back" :disabled="isLoading">
              Back
            </b-btn>
            <b-btn class="float-right" type="link" variant="primary" @click.prevent="process" :disabled="isLoading">
              Process Submission
            </b-btn>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// eslint-disable-next-line
import Vuelidate from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import axios from 'axios'
export default {
  mixins: [tableFilterMixin, loading],
  async created () {
    this.updateTotal = true
    var resp = await this.$store.dispatch('getScheduleProcessingDates')
    this.dates.proc = resp.proc
    this.dates.coll = resp.coll
  },
  props: {
    groupId: String
  },
  data () {
    return {
      columns: [
        {
          'field': 'flaggedPaymentsCount',
          'hidden': true
        },
        {
          label: 'First Name',
          field: 'firstName',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Surname',
          field: 'surname',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Reference',
          field: 'payerReference',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Transaction Code',
          field: 'txCode',
          width: '180px'
        },
        {
          label: 'Amount (£)',
          field: 'amount',
          formatFn: (value) => {
            return `${this.numberWithCommas((value / 1).toFixed(2))}`
          },
          tdClass: 'text-right'
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          formatFn: (val) => new Date(val).toLocaleDateString()
        },
        {
          label: 'Plan Reference',
          field: 'planReference'
        },
        {
          label: '',
          field: 'viewPayerBtn',
          sortable: false
        }
      ],
      serverParams: {
        sort: [{ field: 'surname', type: 'asc' }]
      },
      dates: { proc: new Date(), coll: new Date() }
    }
  },
  methods: {
    process () {
      this.$router.push({ name: 'ProcessCollection', params: { groupId: this.groupId, mode: 'auddis' }, showload: true })
    },
    back () {
      this.$router.push('/collections/submissions/')
    },
    getSubmissions () {
      this.$store.dispatch('loadSubmissions')
    },
    load: async function () {
      if (this.updateTotal) {
        document.querySelector('.footer__navigation__page-info').style.display = 'none'
      }
      await this.$store.dispatch('loadAuddisSubmission', { serverParams: this.buildGoodTableQuery(), groupId: this.groupId })
        .then(response => {
          this.submissions = this.$store.getters.submissions
          var meta = this.$store.getters.submissionMetaData
          this.totalRecords = meta.totalItems
        })
        .catch(error => {
          this.$toastr.e(error)
        })
        if (this.updateTotal) {
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/${this.groupId}/auddiscount`, { serverParams: this.buildGoodTableQuery(), params: { paygateId: this.paygateId } })
          this.totalRecords = response.data
          document.querySelector('.footer__navigation__page-info').style.display = 'inline-block'
        } else {
          document.querySelector('.footer__navigation__page-info').style.display = 'inline-block'
        }
        this.updateTotal = false
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onRowClick (params) {
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },

  },
  computed: {
    selectedPaygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    customerGroups () {
      return this.$store.state.groups.groups
    },
    flaggedPayments () {
      return this.$store.state.submissions.flaggedPayments
    },
    totalPages () {
      return this.$store.getters.submissionMetaData.totalPages
    },
    totalItems () {
      return this.$store.getters.submissionMetaData.totalItems
    }
  },
  validations: {
    group: { required }
  }
}
</script>
