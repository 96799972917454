<template>
  <b-modal v-model="value" centered size="reconciliationModal" @hide="close" no-close-on-backdrop>
    <div slot="modal-header" class="w-100">{{ reportTitle }}</div>
    <div class="col-md-12">
      <span v-html="processingLog"></span>
    </div>
    <div slot="modal-footer" class="w-100">
      <b-row>
        <b-col cols="2">
          <b-btn size="sm" class="float-left" variant="primary" @click="close">Close</b-btn>
        </b-col>
        <b-col cols="10">
          <b-btn size="sm" class="float-right" variant="outline-primary" @click="downloadLog">Download Log</b-btn>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'viewProcessingLog',

  props: {
    value: Boolean,
    processingLog: String,
    reportTitle: String
  },

  methods: {
    close () {
      this.$emit('close')
    },

    downloadLog () {
      let log = this.processingLog.replace(/<br\/>/g, '')
      const blob = new Blob([log], { type: 'text/plain' })
      const downloadUrl = window.URL.createObjectURL(blob)
      let filename = `${this.reportTitle} Processing Log.txt`
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // Works in IE and Edge.
        window.navigator.msSaveBlob(blob, filename)
      } else {
        // Uses HTML5 file attribute. Works in Chrome, Firefox and Edge.
        var a = document.createElement('a')
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      }
    }
  }
}
</script>
<style lang="scss">
.modal .modal-reconciliationModal {
  max-width: 95%;
}
</style>
