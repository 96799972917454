<template>
  <div>
  <div class="row form-group mb-3">
    <div class="col-md-2 required">
      Earliest Date
    </div>
    <div class="col-md-4">
      <div class="right-inner-addon">
        <datepicker id="endPaymentDatePicker"
                    v-model="start"
                    :format="formatDate" @input="filtersChanged()" input-class="form-control datepicker" :disabledDates="{ from: new Date(end) }"
        /><i class="fa fa-calendar form-control-feedback"></i>
      </div>
    </div>
  </div>
  <div class="row form-group mb-3">
    <div class="col-md-2 required">
      Latest Date
    </div>
    <div class="col-md-4">
      <div class="right-inner-addon">
        <datepicker id="startPaymentDatePicker"
                    v-model="end"
                    :format="formatDate" :disabledDates="{ to: new Date(start), from: new Date() }"
                    @input="filtersChanged()" input-class="form-control datepicker"
        /><i class="fa fa-calendar form-control-feedback"></i>
      </div>
    </div>
  </div>
  <vue-good-table mode="remote"
                  ref="table"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  @on-row-click="onRowClick"
                  @on-cell-click="onCellClick"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="true"
                  :totalRows="totalRecords"
                  :search-options="{
                    enabled: false
                    }"
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :isLoading.sync="isTableLoading"
                  styleClass="vgt-table striped bordered" class="scroll-table">
    <template slot="loadingContent">
      <h1>Loading...</h1>
    </template>
    <div slot="emptystate">
      <div class="vgt-center-align vgt-text-disabled">
        No Custom Templates Available
      </div>
    </div>
    <div slot="table-actions">
      <button
        @click.prevent="clearTableFilters"
        class="btn btn-link"
        v-b-popover.hover.top.d500="'Clear filters'"
      >
                        <span class="fa-stack" style="font-size: 10px;">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span>
      </button>
      <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
        <i class="fa fa-share-square pointer dimmedIcon"></i>
      </b-button>
      <b-button @click.prevent="load" class="" :disabled="isLoading" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'">
        <i class="fa fa-sync pointer dimmedIcon"></i>
      </b-button>
    </div>
    <template v-b-tooltip="'ToolTip!'" slot="table-row" slot-scope="props">
      <span v-if="props.column.field === 'buttons'">
        <b-button
          @click="download(props.row.payerMessageId, props.row.reference)"
          variant="outline-secondary" class="btn-block"
        >
          <i class="fa fa-save mr-2"></i>View Output
        </b-button>
        <b-button :disabled="!props.row.canRecall && !props.row.recalled" v-if="props.row.canRecall && !props.row.recalled"
          @click="recall(props.row.payerMessageId)"
          variant="outline-danger" class="btn-block"
        >
          <i class="fa fa-bin mr-2"></i>Recall
        </b-button>
      </span>
      <span v-else-if="props.column.field === 'messageStatus'">
        <b-badge
          pill
          :variant="getVariant(props.row.messageStatus)"
        >{{props.formattedRow[props.column.field]}}</b-badge>
      </span>
      <span v-else-if="props.column.field === 'recalled'">
        <i v-if="props.row.recalled" class="fas fa-check mr-2"></i>
        <i v-if="!props.row.recalled" class="fas fa-times mr-2"></i>
      </span>
      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
    </template>
  </vue-good-table>
  </div>
</template>

<script>
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import axios from 'axios'
import _ from 'lodash'
import {mapGetters} from 'vuex'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import roles from "@/Assets/Constants/roles";
import Datepicker from 'vuejs-datepicker'
import moment from "moment/moment";
import papa from "papaparse";
export default {
  components: {
    Datepicker
  },
  async mounted () {
    this.load()
  },
  props: {
    endpoint: String
  },
  mixins: [tableFilterMixin, loading],
  name: "EmailTable",
  data () {
    return {
      start: null,
      end: null,
      totalRecords: 0,
      rows: [],
      columns: [
        {label: 'UkPayerId',
          field: 'ukPayerId', hidden: true},
        {label: 'Contact Name',
          field: 'contactName'},
        {label: 'URN',
          field: 'urn'},
        {
          field: 'messageStatus',
          label: 'DDM Status',
          tdClass: 'text-center',
          formatFn: (x) => {
            switch (x) {
              case 0:
                return 'Error'
              case 1:
                return 'Sent'
              case 2:
                return 'Queued'
              case 3:
                return 'Bounced'
              case 4:
                return 'Recalled'
            }
          }
        },
        {label: 'Submitted',
          field: 'submitted',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }},
        {label: 'Recipient',
          field: 'recipient'},
        {label: 'Post Code',
          field: 'postCode'},
        {label: 'Postage',
          field: 'postage'},
        {label: 'Ink',
          field: 'ink'},
        {label: 'Printed',
          field: 'printed'},
        {label: 'Cost',
          field: 'cost', tdClass: 'text-right'},
        {label: 'Sender',
          field: 'sender'},
        {label: 'Status',
          field: 'status'},
        {label: 'Reference',
          field: 'reference'},
        { label: 'Recalled', field: 'recalled', tdClass: 'text-center' },
        {label: 'Api Endpoint',
          field: 'endpoint' },
        {
          field: 'messageType',
          label: 'Message Type',
          formatFn: (x) => {
            switch (x) {
              case 20:
                return 'Pre-Collection Notification'
              case 19:
                return 'Confirmation Letter'
              case 18:
                return 'Notice of Change (Migration)'
              case 17:
                return 'Refund Cancelled'
              case 16:
                return 'Notice of Change'
              case 15:
                return 'Customer Transferred'
              case 14:
                return 'Customer Closed'
              case 13:
                return 'Custom'
              case 12:
                return 'Cancellation Notice'
              case 11:
                return 'Refund Requested'
              case 10:
                return 'Portal Email Verification'
              case 9:
                return 'Portal Message Sent'
              case 8:
                return 'Email Verification Message'
              case 7:
                return 'Custom Message 5'
              case 6:
                return 'Custom Message 4'
              case 5:
                return 'Custom Message 3'
              case 4:
                return 'Custom Message 2'
              case 3:
                return 'Custom Message 1'
              case 2:
                return 'Multiple Schedule Update'
              case 1:
                return 'First Collections'
              case 0:
                return 'Advance Notice'
              default:
                return 'Unknown'
            }
          }
        },
        {label: 'Internal Message Id',
          field: 'payerMessageId' },
        {
          label: '',
          field: 'buttons',
          tdClass: 'text-center',
          sortable: false
        }
      ],
      serverParams: {
        sort: [{ field: 'fileName', type: 'asc' }],
      }
    }
  },
  methods: {
    async filtersChanged () {
      await this.clearTableFilters()
    },
    getVariant (status) {
      var variant = ''
      switch (status) {
        case 4:
        case 0:
          variant = 'danger'
          break
        case 1:
          variant = 'success'
          break
        default:
          variant = 'primary'
          break
      }
      return variant
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    onRowClick (event) {
      // forces the table to do pointer cursor on row etc
    },
    onCellClick (event) {
      if (event.column.field !== 'buttons' && event.column.field !== 'lastActivity') {
        this.$store.dispatch('blankPayer')
        this.$router.push({ path: `/collections/customer/${(event.row.ukPayerId)}/edit`, params: { openAt: 'messagequeue'}, query: { openAt: 'messagequeue'} })
      }
    },
    base64ImageToBlob (str) {
      // extract content type and base64 payload from original string
      var imageContent = atob(str)

      // create an ArrayBuffer and a view (as unsigned 8-bit)
      var buffer = new ArrayBuffer(imageContent.length)
      var view = new Uint8Array(buffer)

      // fill the view, using the decoded base64
      for(var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }

      // convert ArrayBuffer to Blob
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessing' });
      return blob;
    },
    load: _.debounce(async function () {
      this.isTableLoading = true
      try {
        if (this.start === null || this.end === null) {
          this.end = new Date()
          let tempStart = new Date()
          tempStart.setDate(this.end.getDate() - 7)
          this.start = tempStart
        }
        var query = this.buildGoodTableQuery()
        query.startDate = this.start
        query.endDate = this.end
        const { data } = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}collectionsadministration/postworksdata`, { params:  { ...query }, validateStatus: () => true })
        this.totalRecords = data.count
        this.rows = data.data
      } catch (e) {
        console.log(e)
        this.$toastr.e('Unable to get file list', 'Error')
      }
      this.isTableLoading = false
    }, 300),
    async deleteTemplate (row) {
      const { templateId, name } = row
      var result = await swal.fire({
        title: `Delete '${name}'?`,
        text: 'You will not be able to recover the template. Do you want to delete this template?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        try {
          await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/${templateId}`, { params: { paygateId: this.paygateId } })
          this.load()
          this.$toastr.s(`Template '${name}' has been deleted.`, 'Template Deleted')
        } catch (e) {
          this.$toastr.e('Unable to delete the template.', 'Error Occurred')
        }
      }
    },
    async recall(messageId) {
      var result = await swal.fire({
        title: `Recall Message`,
        text: 'This message will be removed from the outgoing PostWorks queue',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        try {
          await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}collectionsadministration/recall/${messageId}`, { params: { paygateId: this.paygateId } })
          await this.load()
          this.$toastr.s(`Message recalled`, 'Message recalled')
        } catch (e) {
          this.$toastr.e('Unable to recall the message.', 'Error Occurred')
        }
      }
    },
    async download (messageId, filename) {
      // forces the table to do pointer cursor on row etc
      const { data } = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}collectionsadministration/postworksdata/${messageId}`)
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(this.base64ImageToBlob(data))
      a.download = filename
      // Append anchor to body.
      document.body.appendChild(a)
      a.click();
      // Remove anchor from body
      document.body.removeChild(a)
    },
    async exportTable () {
      var query = this.buildGoodTableQuery()
      var minDate = moment(this.end).format('DD-MM-YYYY')
      var maxDate = moment(this.start).format('DD-MM-YYYY')
      query.perPage = this.totalRecords
      query.forExport = true
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}collectionsadministration/postworksdata`, { params: {
          startDate: this.start, endDate: this.end,
          ...query, paygateId: this.paygateId, filterBy: this.selectedFilter }, showload: true })
      var csvString = papa.unparse(response.data)
      let fileName = `postworks-send-log-${maxDate}-to-${minDate}.csv`
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    canEdit() {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.DevOps, roles.Developer, roles.Qa ])
    }
  },
  watch: {
    selectedCustomer () {
      this.load()
    }
  }
}
</script>

<style>
.deleted * {
  color: #cccccc;
}
.suppressed * {
  color: #cccccc;
}
.alert td.amountField {
  color: red !important;
  font-weight: bold;
}
#firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
