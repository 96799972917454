<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Challenge For Indemnity Advice: {{ challenge.advice !== undefined ? challenge.advice.payingBankReference : ''}}</h2>
      </div>
      <div class="idb-block-content">
        <b-form-row>
          <b-form-group :label-cols="2"  class="col-sm-4" horizontal label="Status">
            <b-form-select :disabled="!canChallenge || !canProgress" v-model="challenge.challengeStatus" :options="challengeStatae" required value-field="value" text-field="text"/>
            <small v-if="needsLabel" class="text-danger">This cannot be advanced until {{needsLabelDate}}</small>
          </b-form-group>
          </b-form-row>
        <b-form-row>
          <b-form-group :label-cols="2"  class="col-sm-4" horizontal label="Contact">
            <b-input-group>
              <b-form-select :disabled="!canChallenge" v-model="challenge.bankingContactId" :options="contacts" required value-field="bankingContactId" text-field="displayName"/>
              <template #append>
                <b-button variant="success" :disabled="!canChallenge || !isFinance" @click.prevent="openAddContactModal">Add Contact</b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </b-form-row>
          <b-form-row v-if="false">
            <b-form-group :label-cols="5" class="col-sm-6" horizontal label="Is Resolved">
              <p-check
                id="businessCheck"
                class="p-switch p-fill"
                color="primary"
                v-model="challenge.isResolved"
                :disabled="!canChallenge"
              ></p-check>
            </b-form-group>
          </b-form-row>
          <b-row v-if="false">
            <b-col>Rejection Reason</b-col>
          </b-row>
          <b-row v-if="false" class="mb-5">
            <b-col>
              <div ref="challengeEditor"></div>
            </b-col>
          </b-row>
        </div>
      <div class="idb-block-footer" v-if="canChallenge">
        <b-button @click.prevent="updateChallenge" variant="primary">Save</b-button>
      </div>
    </div>
    <div class="idb-block" v-if="challenge.basis !== '' && challenge.basis !== null && challenge.basis">
      <div class="idb-block-title">
        <h2>Challenge Reason</h2>
      </div>
      <div class="idb-block-content">
        {{challenge.basis}}
      </div>
    </div>
    <div v-if="challenge.bankingContactId !== null" class="idb-block">
      <div class="idb-block-title">
        <h2>Banking Contact Details</h2>
      </div>
      <div class="idb-block-content">
        <div class="vgt-wrap">
          <table class="vgt-table striped ia-table border-0">
            <tbody>
            <tr><td>Name</td><td>{{contactData !== null ? contactData.name : ''}}</td></tr>
            <tr><td>Job Title</td><td>{{contactData !== null ? contactData.jobTitle : ''}}</td></tr>
            <tr><td>Institution</td><td>{{contactData !== null ? contactData.institutionName : ''}}</td></tr>
            <tr><td>Branch Name</td><td>{{contactData !== null ? contactData.branchName : ''}}</td></tr>
            <tr><td>Branch Title</td><td>{{contactData !== null ? contactData.branchTitle : ''}}</td></tr>
            <tr><td>Title</td><td>{{contactData !== null ? contactData.title : ''}}</td></tr>
            <tr><td>Address</td><td>{{contactData !== null ? contactData.address1 : ''}}</td></tr>
            <tr v-if="contactData !== null && contactData.address2 && contactData.address2 !== null && contactData.address2.length > 0"><td>Address 2</td><td>{{contactData.address2}}</td></tr>
            <tr v-if="contactData !== null && contactData.address3 && contactData.address3 !== null && contactData.address3.length > 0"><td>Address 3</td><td>{{contactData.address3}}</td></tr>
            <tr v-if="contactData !== null && contactData.address4 && contactData.address4 !== null && contactData.address4.length > 0"><td>Address 4</td><td>{{contactData.address4}}</td></tr>
            <tr><td>Town</td><td>{{contactData !== null ? contactData.town : ''}}</td></tr>
            <tr><td>County</td><td>{{contactData !== null ? contactData.county : ''}}</td></tr>
            <tr><td>Post Code</td><td>{{contactData !== null ? contactData.postcode : ''}}</td></tr>
            <tr><td>Email</td><td>{{contactData !== null ? contactData.email : ''}}</td></tr>
            <tr><td>Phone</td><td>{{contactData !== null ? contactData.phone : ''}}</td></tr>
            <tr><td>Mobile</td><td>{{contactData !== null ? contactData.mobile : ''}}</td></tr>
            <tr><td>Contact Type</td><td>{{contactData !== null ? formatContactType(contactData.contactType) : ''}}</td></tr>
            <tr><td>Notes</td><td>{{contactData !== null ? contactData.notes : ''}}</td></tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Advice</h2>
      </div>
      <div class="idb-block-content">
        <indemnity-advice :advice="challenge.advice" />
        </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Notes</h2>
      </div>
      <div class="idb-block-content">
        <notes v-if="challenge.challengeId !== undefined" :challengeId="challenge.challengeId" />
      </div>
    </div>
    <banking-contact-modal
          ref="bankingContactModal"
          :type="contactModalType"
          :contacts="contacts"
          @edit="editContact"
        ></banking-contact-modal>
  </div>
</template>
<script>
import axios from 'axios'
import notes from '@/Components/Collections/Indemnities/ChallengeNotes'
import indemnityAdvice from '@/Components/Collections/Indemnities/IndemnityAdvice'
import Quill from 'quill'
import BankingContactModal from './Modals/BankingContactModal'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import roles from "@/Assets/Constants/roles";
import {Diff} from "diff";
export default {
  components: { notes, indemnityAdvice, BankingContactModal },
  props: {
    challengeId: String
  },
  data () {
    return {
      editor: null,
      challengeStatae: [
        { value: 0, text: 'None'},
        { value: 1, text: 'Requested'},
        { value: 2, text: 'Actioned'},
        { value: 3, text: 'More Info'},
        { value: 4, text: 'Successful'},
        { value: 5, text: 'Rejected'}
      ],
      challenge:{

      },
      contacts: [{ displayName: 'No Contact Specfied', bankingContactId: null }],
      contactModalType: 'Create',
    }
  },
  async mounted () {
    // this.editor = new Quill(this.$refs.challengeEditor, {
    //   modules: {
    //     toolbar: {
    //       container: [
    //         [{ header: [1, 2, 3, 4, false] }, 'tablecontainer'],
    //         ['bold', 'italic', 'underline', 'strike'],
    //         [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    //         [{ 'indent': '-1' }, { 'indent': '+1' }],
    //         ['link', 'image'],
    //         [{ 'color': [] }],
    //         [{ 'font': [] }],
    //         [{ 'align': [] }],
    //         ['clean']
    //       ],
    //       table: true,
    //       handlers: {}
    //     },
    //     history: {
    //       delay: 2000,
    //       maxStack: 500,
    //       userOnly: true
    //     }
    //   },
    //   theme: 'snow'
    // })
    await this.loadContacts()
    await this.load()
  },
  methods: {
    async openAddContactModal () {
      this.contactModalType = 'Create'
      this.$bvModal.show('bankingContactModal')
    },
    async editContact (contact) {
      console.log('edit contact fired')
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}bankingcontact/${contact.bankingContactId}`, contact)
      response.data.displayName = `${response.data.name} - ${response.data.institutionName} - ${response.data.branchName}`
      this.contacts.push(response.data)
      let first = this.contacts[0]
      this.contacts.shift()
      this.contacts = this.contacts.sort(function (a,b) {
        return a.displayName.localeCompare(b.displayName)
      })
      this.contacts.unshift(first)
      this.contact.selectedContactId = response.data.bankingContactId
      this.challenge.bankingContactId = response.data.bankingContactId
    },
    async load () {
      try {
        let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}indemnity/challenge/${this.challengeId}`)
        this.challenge = { ...response.data }
        // this.editor.root.innerHTML = this.challenge.rejectionReason
      } catch (err) {
        console.log(err)
        this.$toastr.e('Could not load challenge.')
      }
    },
    async loadContacts () {
      try {
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}bankingcontact/summary`)
          if (response) {
            this.contacts = this.contacts.concat(response.data)
          }
        } catch (error) {
          this.$toastr.e(error)
      }
    },
    async updateChallenge () {
      try {
        // this.challenge.rejectionReason = this.editor.root.innerHTML
        await axios.put(`${process.env.VUE_APP_DDMS_API_URL}indemnity/challenge/${this.challengeId}`, this.challenge)
        this.$toastr.s('Challenge Successfully Updated')
      } catch (err) {
        this.$toastr.e('Could not update the challenge.')
      }
    },
    formatContactType(type) {
      let retVal = ''
      switch (type) {
        case 0:
          retVal = 'General'
          break
        case 1:
          retVal = 'Technical'
          break
        case 2:
          retVal = 'Contractual'
          break
        case 3:
          retVal = 'Billing'
          break
      }
      return retVal
    },
    formatDate (date) {
      if (date !== null && date !== undefined) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      }
      return ''
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
  },
  computed: {
    contactData() {
      if (this.contacts.length > 0) {
        for (let i = 0; i < this.contacts.length; i++) {
          if(this.contacts[i].bankingContactId === this.challenge.bankingContactId) {
            return this.contacts[i]
          }
        }
        return null
      }
    },
    canChallenge() {
      return this.$store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations])
    },
    isFinance () {
      return this.$store.getters.isInRoles([roles.Finance])
    },
    canProgress() {
      let canProgress = false
      if(this.challenge !== null && this.challenge.advice !== null && this.challenge.advice !== undefined && this.challenge.advice.productionDate !== null) {
        var nowDate = new Date()
        var diffDays = nowDate.getDate() - new Date(this.challenge.advice.productionDate).getDate()
        let reasonCode7 = this.challenge.advice.reasonCode === 7
        let dateDiff = diffDays < 14
        console.log(' Days = ' + diffDays)
        console.log(diffDays)
        canProgress = !(reasonCode7 && dateDiff)
      }
      return canProgress
    },
    needsLabel () {
      let needsLabel = false
      if(this.challenge !== null && this.challenge.advice !== null && this.challenge.advice !== undefined && this.challenge.advice.productionDate !== null) {
        var nowDate = new Date()
        const diffTime = Math.abs(nowDate - new Date(this.challenge.advice.productionDate));
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let reasonCode7 = this.challenge.advice.reasonCode === 7
        let dateDiff = diffDays < 14
        console.log(' Days = ' + diffDays)
        console.log(diffDays)
        needsLabel = (reasonCode7 && dateDiff)
      }
      return needsLabel
    },
    needsLabelDate () {
      let needsLabelDate = ''
      if(this.challenge !== null && this.challenge.advice !== null && this.challenge.advice !== undefined && this.challenge.advice.productionDate !== null) {
        var diffDays = new Date(this.challenge.advice.productionDate)
        var newDate = diffDays.setDate(diffDays.getDate() + 14)
        needsLabelDate = this.formatDate(newDate)
      }
      return needsLabelDate
    }
  }
}
</script>
<style type="text/css">
.ia-table, .ia-table thead th td, .ia-table tr td {
  border-style: none;
}
.ia-table thead th, .ia-table thead th td {
  background: transparent;
  padding: 0;
}
</style>
