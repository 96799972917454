<template>
  <div v-show="actionLoaded" id="submissionWrapper">
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Submission Details</h2>
      </div>
      <viewSubmission></viewSubmission>
      <div class="idb-block-footer">
        <button v-if="showBackToSearch" 
          type="button"
          class="btn btn-primary"
          v-on:click="backToSearchResults"
        >Back to Search Results</button>
        <button v-if="showBackToAction" 
          type="button"
          class="btn btn-primary"
          v-on:click="backToAction"
        >Back to Action</button>
        <button v-show="submissionStatus === 'complete' || submissionStatus === 'rejected'"
          type="button"
          class="btn btn-outline-primary pull-right"
          v-on:click="showSubmissionSummary"
        >BACS Submission Summary</button>
      </div>
    </div>
  </div>
</template>

<script>
import ViewSubmission from '@/Views/Bacs/Common/ViewSubmission.vue'
import guids from '@/Assets/Constants/paygateId'

export default {
  components: {
    viewSubmission: ViewSubmission
  },
  computed: {
    actionLoaded () { return this.$store.getters.parentDataLoaded }
  },
  data () {
    return {
      returnPage: '',
      submissionId: '',
      antiFraudListId: '',
      submissionStatus: '',
      showBackToSearch: false,
      showBackToAction: false,
      actionId: ''
    }
  },

  methods: {
    backToSearchResults () {
      var returnUrl = '/payments/search/' + this.returnPage + '?useSearchParams=true'
      if (this.antiFraudListId !== undefined) {
        returnUrl += '&antiFraudListId=' + this.antiFraudListId
      }
      this.$router.push(returnUrl)
    },

    backToAction () {
      var returnUrl = `/payments/bacsActions/actionWrapper?actionId=${this.actionId}&actionedObjectId=${this.submissionId}&actionSubClass=InputReportError`
      this.$router.push(returnUrl)
    },

    showSubmissionSummary () {
      const routeData = this.$router.resolve({ path: '/payments/common/viewSubmissionSummary', query: { submissionId: this.submissionId } })
      window.open(routeData.href, '_blank')
    }
  },

  created () {
    this.returnPage = this.$route.query.returnPage
    if (this.returnPage.includes('search')) {
      this.showBackToSearch = true
      this.antiFraudListId = this.$route.query.antiFraudListId
      this.submissionStatus = this.$route.query.submissionStatus
      this.submissionId = this.$store.getters.submissionId
      this.$store.dispatch('setActionId', '00000000-0000-0000-0000-000000000000')
    } else if (this.returnPage.includes('inputReport')) {
      this.showBackToAction = true
      this.actionId = this.$route.query.actionId
      this.submissionId = this.$route.query.submissionId
    } else {
      this.submissionId = this.$route.query.submissionId
      this.$store.dispatch('setSubmissionId', this.submissionId)
      this.$store.dispatch('setCustomerPaygateId', guids.paygateId)
    }
  }
}
</script>
