<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>Cancel Submissions<favourite-icon></favourite-icon></h2>
    </div>
    <div class="idb-block-content">
      <div class="row form-group">
        <div class="col-md-2 required">
          Select a Group
        </div>
        <div class="col-md-4">
          <group-select
            v-model="group"
            :groups="customerGroups"
            @input="groupChanged"
            :clearable="false"
          ></group-select>
          <p v-if="allGroups"><small>Customers can only be created once a group is selected.</small></p>
        </div>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="records"
        mode="remote"
                styleClass="vgt-table striped bordered"
          ref="table"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :search-options="{
                    enabled: true
                    }"
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                :isLoading.sync="isTableLoading"
                @on-row-click="onRowClick" @on-cell-click="handleClick"
      >
        <div slot="emptystate">
          <span style="text-align: center;">No entries have been found matching the criteria.</span>
        </div>
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'cancelSub'">
              <b-button variant="danger" @click="cancelSubmission(props.row.submissionId, props.row.fileNumber)"> <!-- eslint-disable-line -->
                <span><i class="fas fa-times mr-2"></i>Cancel</span>
              </b-button>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>

        </template>
        <div slot="table-actions">
          <button
            @click.prevent="clearTableFilters"
            class="btn btn-link"
            v-b-popover.hover.top.d500="'Clear filters'"
          >
            <span class="fa-stack" style="font-size: 10px;">
              <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
              <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
            </span>
          </button>
          <b-button
            @click.prevent="load" :disabled="isLoading"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Refresh the data in the table'"
          >
            <i class="fa fa-sync pointer dimmedIcon"></i>
          </b-button>
          <b-button
            @click.prevent="exportTable"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Export the contents of the table'"
          >
            <i class="fas fa-file-export pointer dimmedIcon"></i>
          </b-button>
        </div>
      </vue-good-table>
    </div>
    <div class="idb-block-footer">
    </div>
  </div>
</template>
<script>
import EventBus from '@/Lib/eventBus'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import papa from 'papaparse'
import moment from 'moment'
import swal from 'sweetalert2'

export default {
  props: {
    prepopulatedGroup: String,
    prepopulatedSearchTerm: String,
    prepopulatedStatus: String
  },
  mixins: [ tableFilterMixin, loading ],
  computed: {
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups)
      }
      ret.unshift({
        'description': '', 'groupId': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'name': 'All Groups', 'paygateId': this.paygateId, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      return ret
    },
    allGroups () {
      return !this.group || this.group === undefined || this.group === null || this.group === '00000000-0000-0000-0000-000000000000'
    }
  },
  data () {
    return {
      records: [],
      columns: [
        {
          label: 'Created Date',
          field: 'createdDate',
          tdClass: 'text-left',
          thClass: 'text-left',
          formatFn: this.formatDate,
          width: '200px'
        },
        {
          label: 'Group',
          field: 'groupName',
        },
        {
          label: 'Title',
          field: 'reference'
        },
        {
          label: 'SUN',
          field: 'serviceUserNumber'
        },
        {
          label: 'Status',
          field: 'status',
          formatFn: this.formatStatus
        },
        {
          label: 'File Number',
          field: 'fileNumber'
        },
        {
          label: 'Collections',
          field: 'numberOfPayments'
        },
        {
          field: 'submissionId',
          hidden: true
        },
        {
          label: '',
          field: 'cancelSub',
          sortable: false,
          width: '100px'
        }
      ],
      serverParams: {
        sort: [{ field: 'createdDate', type: 'desc' }]
      },
      group: '00000000-0000-0000-0000-000000000000'
    }
  },
  async mounted () {
    const onPaygateChange = (paygateId) => {
      const paygateIdNotDefault = (paygateId && paygateId !== '00000000-0000-0000-0000-000000000000')
      if (paygateIdNotDefault) {
        this.$store.dispatch('loadCustomerGroups', paygateId)
      }
    }
    EventBus.$on('paygateIdChange', onPaygateChange)
    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })
    if (this.$store.getters.customerGroups === null) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
      if (this.$store.getters.customerGroups !== null) {
        const initiallySelectedGroup = this.prepopulatedGroup ? this.prepopulatedGroup : '00000000-0000-0000-0000-000000000000'
        this.group = initiallySelectedGroup
        const table = this.$refs['table']
        const searchTerm = this.prepopulatedSearchTerm ? this.prepopulatedSearchTerm : ''
        table.globalSearchTerm = searchTerm
        table.$emit('on-search', {
          searchTerm: searchTerm
        })
      }
    } else {
      this.group = '00000000-0000-0000-0000-000000000000'
    }
    await this.load()
  },
  methods: {
    groupChanged () {
      this.updateParams({ page: 1 })
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      let day = date.getDate()
      let monthIndex = date.getMonth() + 1
      let year = date.getFullYear()
      let hours = date.getHours()
      let minutes = date.getMinutes()
      let seconds = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year} ${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`
    },
    pad (num, size) {
      let s = '00' + num
      return s.substr(s.length - size)
    },
    getSeverityClass (value) {
      let retVal = ''
      switch (value.toUpperCase()) {
        case 'ERROR':
          retVal = 'danger'
          break
        case 'WARN':
          retVal = 'warning'
          break
        case 'DEBUG':
          retVal = 'secondary'
          break
        default:
          retVal = 'info'
          break
      }
      return retVal
    },
    formatStatus (status) {
      let retVal = ''
      switch (status) {
        case 0:
          retVal = 'None'
          break
        case 1:
          retVal = 'Created'
          break
        case 2:
          retVal = 'Signed'
          break
        case 3:
          retVal = 'Approved'
          break
        case 4:
          retVal = 'Final Approval'
          break
        case 5:
          retVal = 'Submitted'
          break
        case 6:
          retVal = 'Rejected'
          break
        case 7:
          retVal = 'Error'
          break
        case 8:
          retVal = 'Cancelled'
          break
        case 9:
          retVal = 'Completed'
          break
        case 10:
          retVal = 'Committed'
          break
        case 11:
          retVal = 'Downloaded'
          break
        case 16:
          retVal = 'Due'
          break
        case 17:
          retVal = 'Successful'
          break
        case 18:
          retVal = 'Unsubmitted'
          break
        case 19:
          retVal = 'Cancelled'
          break
        default:
          break
      }
      return retVal
    },
    async load () {
      this.isTableLoading = true
      let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/pastsubmissions/${this.group}`, { headers: { }, params: { ...this.buildGoodTableQuery(), paygateId: this.paygateId }, showload: true })
      this.records = response.data.rows
      this.totalRecords = response.data.totalRows
      this.isTableLoading = false
    },
    exportTable () {
      var csvString = papa.unparse(records)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'past-submissions-log.csv')
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = 'past-submissions-log.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    async cancelSubmission (id, fileNumber) {
      var canCancel = await this.remindThemToCancel()
      if (canCancel) {
        this.isTableLoading = true
        let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/manualcancel/${id}/${fileNumber}`, {}, { showload: true, paygateId: this.paygateId })
        if(response.data.success) {
          await this.load()
          await this.askAboutMessaging(id)
        } else {
          this.$toastr.e('Failed to cancel submission.')
        }
        this.isTableLoading = false
      }
    },
    onRowClick (params) {

    },
    handleClick (params) {
      if (params.column.field !== 'cancelSub') {
        this.$router.push(`/collections/cancelsubmission/${params.row.submissionId}/${params.row.fileNumber}`)
      }
    },
    async remindThemToCancel () {
      let result = await swal.fire({
        title: 'Warning',
        text: 'This process only cancels the submission within Paygate. It will not cancel the actual BACS submission. Please ensure that the submission has been cancelled with your bank before proceeding.',
        icon: 'warning',
        input: 'checkbox',
        inputPlaceholder: 'I understand *',
        inputLabel: 'I understand *',
        animation: false,
        showCancelButton: true,
        cancelButtonClass: 'btn btn-light',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue',
        confirmButtonClass: 'btn btn-danger',
        inputClass: 'required',
        inputValidator: (result) => {
          return !result && 'You need to confirm that you understand that the submission is only cancelled within paygate'
        }
      })
      return result.isConfirmed
    },
    async askAboutMessaging (id) {
      let result = await swal.fire({
        title: 'Submission Cancelled',
        text: 'Would you like to send a message to customers regarding the cancellation?',
        icon: 'success',
        animation: false,
        showCancelButton: true,
        cancelButtonClass: 'btn btn-light',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonClass: 'btn btn-danger',
        input: 'select',
        inputOptions: {
          'CustomMessage1': 'Custom Message 1',
          'CustomMessage2': 'Custom Message 2',
          'CustomMessage3': 'Custom Message 3',
          'CustomMessage4': 'Custom Message 4',
          'CustomMessage5': 'Custom Message 5'
        },
        inputValidator: function (value) {
          return new Promise(function (resolve, reject) {
            if (value !== '') {
              resolve();
            } else {
              reject('You need to select a Message Type');
            }
          })
        }
      })
      if(result.isConfirmed){
        let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/sendcancellationmessage`, { templateName: result.value, submissionId: id }, { showload: true, params: { paygateId: this.paygateId } })
        if (response.data.success) {
          this.$toastr.s('Messages successfully queued.')
        } else {
          this.$toastr.e('An error occurred sending the cancellation message.')
        }
      }
    }
  }
}
</script>
<style>
.swal2-icon.swal2-info, .swal2-icon.swal2-warning {
    font-size: inherit !important;
    line-height: 80px;
    text-align: center;
}
.swal2-icon .swal2-icon-content {
    font-size: 60px !important;
}
</style>
