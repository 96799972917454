export default {
  bac: 'BAC',
  bgc: 'BGC',
  bln: 'BLN',
  chg: 'CHG',
  chq: 'CHQ',
  collection: 'Collection',
  dd: 'D/D',
  ebp: 'EBP',
  ibp: 'IBP',
  int: 'INT',
  itl: 'ITL',
  collectionAdjustment: 'Manual Collection Adjustment',
  settlementAdjustment: 'Manual Settlement Adjustment',
  pos: 'POS',
  so: 'S/O',
  refundOrIndemnityClaim: 'Refund or Indemnity Claim',
  resetBalance: 'Reset Balance',
  settlement: 'Settlement',
  ud: 'U/D'
}
