<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Bureau Customers
          <span class="pull-right">
            <a href="#" target="_blank">
              <i class="far fa-question-circle"></i>
            </a>
          </span>
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button
              @click.prevent="load"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="printTable"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
            >
              <i class="fa fa-print pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="exportTable"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
            >
              <i class="fa fa-file-export pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  mixins: [tableFilterMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Name'
          }
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber',
          filterOptions: {
            enabled: true,
            placeholder: 'Service User Number'
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'name', type: 'desc' }]
      }
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  methods: {
    onRowClick (params) {
      var id = params.row.bureauCustomerId
      this.$router.push({ name: 'BureauCustomerDetails', params: { id: id } })
    },
    load: _.debounce(async function () {
      try {
        this.isTableLoading = true
        var params = { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer }
        console.log('Params', params)
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauCustomer`,
          {
            params,
            showerror: true,
            errormessage: 'Failed loading bureau customers'
          })

        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    }, 500)
  }
}

</script>
