<template>
  <div>
    <h3>Workflow<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/other/workflow/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <div class="form-group">
      <label>Workflow</label>
      <br>
      <select class="form-control"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        v-model="selectedNode.props.s1.value">
        <option v-for="option in workflowOptions" :key="option.value" :value="option.value">
        {{option.text}}
      </option>
    </select>
    </div>
    <br/>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import axios from 'axios'
import { maxLength } from 'vuelidate/lib/validators'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            maxLength: maxLength(128)
          }
        },
        s2: {
          value: {
            maxLength: maxLength(128)
          }
        }
      }
    }
  },
  mixins: [loading],
  data () {
    return {
      workflowOptions: [],
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        console.log('invalid')
        this.$v.$touch()
        return
      }
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    }
  },
  created: async function () {
    this.$snapbar.hide()
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    let workflowResponse
    try {
      workflowResponse = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowtitles`, { showload: true })
      if (workflowResponse) {
        for (const workflow of workflowResponse.data.workflows) {
          this.workflowOptions.push({
            text: workflow.title,
            value: workflow._id
          })
        }
      }
    } catch (e) {
      console.log(e)
      this.$snapbar.e('Error getting list of available workflows')
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
.invalid {
  border-color: red;
  border-width: 2px;
}

  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }

</style>
