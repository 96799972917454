<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>Add Commission Item</h2>
    </div>
    <div class="idb-block-content">
      <div class="row form-group">
        <label class="col-form-label col-md-3">Agents</label>
        <div class="col-md-6">
          <vue-select
            v-model="$v.selectedAgent.$model"
            :options="agents"
            label="name"
            :closeOnSelect="true"
            @input="selectAgent()"
            :disabled="FromStatementMode"
          ></vue-select>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3"></div>
        <div class="col-md-8 col-sm-12">
          <h6 v-show="AgentWarning" class="text-warning">{{AgentWarningText}}</h6>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-form-label col-md-3">Customers</label>
        <div class="col-md-6">
          <vue-select
            v-model="$v.selectedCustomer.$model"
            :options="customers"
            label="customerName"
            :closeOnSelect="true"
            @input="selectCustomer()"
          ></vue-select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label col-md-3">Amount</label>
        <div class="col-md-6">
          <CurrencyInput v-model="$v.manualAmount.$model" currency="GBP" class="form-control"></CurrencyInput>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-form-label col-md-3">Notes</label>
        <div class="col-md-6">
          <textarea
            class="form-control"
            rows="3"
            v-model.trim="$v.CommissionNotes.$model"
            placeholder="Please add a note here"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="idb-block-footer">
      <button class="btn btn-primary" :disabled="disableSave" @click="onSaveCommissionClick">Save</button>

      <button class="btn btn-danger pull-right" @click="onCancelAddSubmissionClick" type="button">
        <i class="glyphicon ti-trash rpad"></i>Cancel
      </button>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import swal from 'sweetalert2'
import { CurrencyDirective } from 'vue-currency-input'
import colours from '@/Assets/Constants/colours'
import NewCommissionEligibility from '@/Assets/Constants/commissionAgentNewCommissionEligibility'

import {
  required,
  maxLength,
  numeric,
  decimal,
  minValue,
  maxValue,
  between
} from 'vuelidate/lib/validators'
import VueSelect from 'vue-select'
import _ from 'lodash'
export default {
  mixins: [],
  components: { VueSelect },
  directives: {
    currency: CurrencyDirective
  },

  props: {
    statementid: String
  },

  data () {
    return {
      FromStatementMode: false,
      disableSave: true,
      AgentWarning: false,
      AgentWarningText: '',
      eligibility: NewCommissionEligibility.NoStatement,
      manualAmount: 0,
      agents: [],
      selectedAgent: {},
      customers: [],
      selectedCustomer: {},
      Statement: {},
      CommissionNotes: ''
    }
  },

  watch: {
    selectedAgent: function () {
      this.runValidate()
    },

    selectedCustomer: function () {
      this.runValidate()
    },
    manualAmount: function () {
      this.runValidate()
    },
    CommissionNotes: function () {
      this.runValidate()
    }
  },

  mounted: async function () {

  },
  created: async function () {
    await this.load()
  },
  methods: {

    load: async function () {
      await this.GetAgentList()
      await this.GetCustomerListings()

      console.log(this.statementid)
      if (this.statementid === null || this.statementid === '' || this.statementid === undefined) {
        console.log('statementid is bad', this.statementid)
      } else {
        console.log('statementid is good', this.statementid)
        this.FromStatementMode = true
        await this.GetStatement()
      }

      this.$v.$reset()
    },

    GetStatement: async function () {
      console.log('GetStatement', this.FromStatementMode)
      //      if (!this.FromStatementMode) { return }
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetSingleStatement/${this.statementid}`,
          {
            showerror: true,
            errormessage: 'Failed loading statement data'
          })

        console.log('response', response)
        this.Statement = response.data
        console.log('this.agents', this.agents)
        console.log('this.Statement.agentid', this.Statement.agentid)

        this.selectedAgent = _.find(this.agents, function (o) {
          console.log('found')
          return o
        })
        console.log('this.selectedAgent', this.selectedAgent)
      } catch { } finally {

      }
    },

    GetAgentList: async function () {
      // Get customer listings
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetAgentsList`,
          {
            showerror: true,
            errormessage: 'Failed loading commission agents'
          })

        console.log('response', response)
        this.agents = response.data
      } catch { } finally {

      }
    },
    GetCustomerListings: async function () {
      // Get customer listings
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetCustomers`,
          {
            showerror: true,
            errormessage: 'Failed loading commission agent customers'
          })

        console.log('response', response)
        this.customers = response.data
      } catch { } finally {

      }
    },
    selectAgent: async function () {
      if (!this.$v.selectedAgent.$invalid) {
        try {
          var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetAddCommissionCheckInStatement/${this.selectedAgent.id}`,
            {
              showerror: true,
              errormessage: 'Failed loading commission agents'
            })

          console.log('response', response.data, NewCommissionEligibility.NoStatement)
          this.eligibility = response.data

          if (this.eligibility === NewCommissionEligibility.NoStatement) {
            this.AgentWarning = false
            this.AgentWarningText = ''
          } else if (this.eligibility === NewCommissionEligibility.InStatement) {
            this.AgentWarning = true
            this.AgentWarningText = 'The agent already has a statement for this month. This will add this commission item to this statement.'
          } else if (this.eligibility === NewCommissionEligibility.StatementSent) {
            this.AgentWarning = true
            this.AgentWarningText = 'The agent statement has already been sent for this month. You will have to either roll the statement back or generate a new statement.'
          } else if (this.eligibility === NewCommissionEligibility.StatementPaid) {
            this.AgentWarning = true
            this.AgentWarningText = 'The agent statement has already been paid for this month. You will have to generate a new statement.'
          }
        } catch { } finally {

        }
      }
    },
    selectCustomer () { },

    onCancelAddSubmissionClick () {
      if (this.FromStatementMode) {
        // go back to statement
        this.$router.push({
          name: 'CommissionAgentListCommissionForStatement',
          params: { statementid: this.statementid }
        })
      } else {
        // go back to commission screen
        this.$router.push({
          name: 'CommissionAgentListCommissions'
        })
      }
    },

    onSaveCommissionClick: async function () {
      console.log('text', this.eligibility, this.eligibility === NewCommissionEligibility.NoStatement)

      var swalText = ''
      if (this.eligibility === NewCommissionEligibility.NoStatement) {
        swalText = 'This action will create a manual commission record for <strong>' +
          this.selectedAgent.name +
          '</strong> for <strong>' +
          this.selectedCustomer.customerName +
          '</strong> in the amount of <strong>£' +
          this.manualAmount +
          '</strong>.'
      } else if (this.eligibility === NewCommissionEligibility.InStatement) {
        swalText = 'This agent has a current month statement. This action will cause this statement to be recalculated.'
      } else if (this.eligibility === NewCommissionEligibility.StatementSent) {
        swalText = 'The agent has a statement this month already set to sent. You can add the commission but you will either have to roll back the existing statement or wait to generate a new statement.'
      } else if (this.eligibility === NewCommissionEligibility.StatementPaid) {
        swalText = 'The agent has a statement this month already set to paid. You generate a new statement to process further.'
      }
      console.log('swalText', swalText)
      try {
        var swalResult = await swal.fire({
          title: 'Add New Commission Item',
          html: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }
      if (!swalResult.isConfirmed) {
        console.log('declined')
      } else {
        console.log('accepted', this.selectedAgent.id)
        try {
          var payload = {
            CommissionAmount: this.manualAmount,
            AgentId: this.selectedAgent.id,
            StatementId: this.statementid,
            FromStatementMode: this.FromStatementMode,
            PaygateId: this.selectedCustomer.paygateId,
            CommissionNotes: this.CommissionNotes
          }
          console.log('payload', payload)

          var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAAddCommissionRecord`, payload, { showload: true, showerror: true, errormessage: 'Failed to add commission record' })

          console.log('add response', response)
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
            // Go to the edit commission record for what we just added

            this.$router.push({
              name: 'CommissionAgentCommissionEdit',
              params: { commissionid: response.data.id }
            })
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        }
      }
    },
    runValidate: function () {
      if (!this.$v.selectedAgent.$invalid &&
        !this.$v.selectedCustomer.$invalid &&
        !this.$v.manualAmount.$invalid &&
        !this.$v.CommissionNotes.$invalid
      ) {
        this.disableSave = false
      } else {
        this.disableSave = true
      }
    }

  },
  validations: {

    selectedAgent: { required },
    selectedCustomer: { required },
    CommissionNotes: { required },
    manualAmount: {
      required,
      decimal
    }
  }
}

</script>
