<template>
  <form @submit.prevent>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>{{status}} SUN</h2>
      </div>

      <div class="idb-block-content">
        <!-- Alias -->
        <div class="form-group row" :class="{ invalid: $v.sun.alias.$error }">
          <label class="col-form-label col-md-3 required">Alias</label>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              v-model.trim="$v.sun.alias.$model"
              :readonly="!canEdit"
            />
            <!-- Validation -->
            <validation-messages v-model="$v.sun.alias"></validation-messages>
          </div>
        </div>

        <!-- Number -->
        <div class="form-group row" :class="{ invalid: $v.sun.number.$error }">
          <label class="col-form-label col-md-3 required">Number</label>
          <div class="col-md-6">
            <input
              v-focus
              type="text"
              class="form-control"
              v-model.trim="$v.sun.number.$model"
              :readonly="!canEdit"
            />
            <!-- Validation -->
            <validation-messages v-model="$v.sun.number">
              <small
                class="form-text small"
                v-if="$v.sun.number.sunValidator != undefined && !$v.sun.number.sunValidator"
              >Incorrect sun format</small>
            </validation-messages>
            <small
              class="form-text text-muted"
            >The format for a Service User Number is 6 numbers or a B followed by 5 numbers.</small>
          </div>
        </div>

        <!-- Service User Name -->
        <div class="form-group row" :class="{invalid: $v.sun.serviceUserName.$error}">
          <label class="col-form-label col-md-3 required">Service User Name</label>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              v-model="$v.sun.serviceUserName.$model"
              :readonly="!canEdit"
            />
            <!-- Validation -->
            <validation-messages v-model="$v.sun.serviceUserName" name="service user name"></validation-messages>
          </div>
        </div>

        <!-- Type -->
        <div class="form-group row" :class="{ invalid: $v.sun.type.$error }">
          <label class="col-form-label col-md-3 required">Type</label>
          <div class="col-md-6">
            <b-form-select
              v-model="$v.sun.type.$model"
              :options="sunTypes"
              title="Type"
              @input="changeType"
              :disabled="!canEdit"
            ></b-form-select>
            <!-- Validation -->
            <validation-messages v-model="$v.sun.type"></validation-messages>
          </div>
        </div>

        <!-- Level -->
        <div class="form-group row" :class="{ invalid: $v.sun.level.$error }">
          <label class="col-form-label col-md-3 required">Level</label>
          <div class="col-md-6">
            <b-form-select
              v-model="$v.sun.level.$model"
              :options="sunLevels"
              title="Levels"
              @input="$v.$reset()"
              :disabled="!canEdit"
            ></b-form-select>
            <!-- Validation -->
            <validation-messages v-model="$v.sun.level"></validation-messages>
          </div>
        </div>

        <!-- Bank Account -->
        <div class="form-group row" :class="{invalid: $v.sun.bankAccountId.$error}">
          <label class="label-control col-md-3 required">Bank Account</label>
          <div class="col-md-5">
            <b-form-select
              v-if="status === 'Create'"
              v-model="$v.sun.bankAccountId.$model"
              :options="bankAccounts"
              :readonly="!canEdit"
              :disabled="bankAccounts.length === 0"
            ></b-form-select>
            <input v-else type="text" class="form-control" disabled v-model="sun.bankAccountName" />
            <!-- Validation -->
            <validation-messages v-model="$v.sun.bankAccountId" name="Bank Account"></validation-messages>
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-outline-secondary"
              type="button"
              :disabled="sun.bankAccountId === null"
              @click="viewGroupBankDetails"
            >View</button>
          </div>
        </div>

        <!-- Bank Statement Text -->
        <div class="form-group row" :class="{invalid: $v.sun.bankStatementText.$error}">
          <label class="label-control col-md-3 required">Bank Statement Text</label>
          <div class="col-md-6">
            <input
              id="name"
              type="text"
              class="form-control"
              v-model="$v.sun.bankStatementText.$model"
              :readonly="!canEdit"
            />

            <!-- Validation -->
            <validation-messages v-model="$v.sun.bankStatementText" name="bank statement text"></validation-messages>
          </div>
        </div>

        <!-- Direct Debits Allowed -->
        <div class="form-group row" :class="{ invalid: $v.sun.sendNoticeOfChange.$error }">
          <label class="col-form-label col-md-3 required">Send Notice Of Change</label>
          <div class="col-md-6">
            <p-check
              class="p-switch p-fill"
              color="primary"
              v-model="$v.sun.sendNoticeOfChange.$model"
              :disabled="!canEdit"
            ></p-check>
            <!-- Validation -->
            <validation-messages v-model="$v.sun.sendNoticeOfChange"></validation-messages>
          </div>
        </div>

        <!-- Direct Debits Allowed -->
        <div class="form-group row" :class="{ invalid: $v.sun.directDebitsAllowed.$error }">
          <label class="col-form-label col-md-3 required">Direct Debits Allowed</label>
          <div class="col-md-6">
            <p-check
              class="p-switch p-fill"
              color="primary"
              v-model="$v.sun.directDebitsAllowed.$model"
              :disabled="!canEdit"
            ></p-check>
            <!-- Validation -->
            <validation-messages v-model="$v.sun.directDebitsAllowed"></validation-messages>
          </div>
        </div>

        <!-- Direct Credits Allowed -->
        <div class="form-group row" :class="{ invalid: $v.sun.directCreditsAllowed.$error }">
          <label class="col-form-label col-md-3 required">Direct Credits Allowed</label>
          <div class="col-md-6">
            <p-check
              class="p-switch p-fill"
              color="primary"
              v-model="$v.sun.directCreditsAllowed.$model"
              :disabled="!canEdit"
            ></p-check>
            <!-- Validation -->
            <validation-messages v-model="$v.sun.directCreditsAllowed"></validation-messages>
          </div>
        </div>

        <!-- Advance Notice Period (In Days) -->
        <div class="form-group row" :class="{ invalid: $v.sun.advanceNoticeInDays.$error }">
          <label class="col-form-label col-md-3 required">Advance Notice Period (In Days)</label>
          <div class="col-md-3">
            <input
              type="number"
              class="form-control"
              v-model.trim="$v.sun.advanceNoticeInDays.$model"
              :readonly="!canEdit"
            />
            <!-- Validation -->
            <validation-messages v-model="$v.sun.advanceNoticeInDays"></validation-messages>
          </div>
        </div>

        <linked-customers-table
          v-if="sun.type !== sunTypesObject.bureauSun && status == 'Edit'"
          :customers="linkedCustomers"
        ></linked-customers-table>
      </div>

      <div class="idb-block-footer">
        <button
          v-if="canEdit"
          class="btn btn-primary"
          :disabled=" isLoading"
          type="submit"
          @click="checkValidation() && saveSun()"
        >{{ status === "Edit" ? "Save" : status }}</button>
        <button class="btn btn-outline-danger pull-right ml-3" type="button" @click="back">Cancel</button>

        <button
          v-if="status === 'Edit' && canEdit"
          class="btn btn-danger pull-right"
          @click="deleteSun"
          :disabled="isLoading"
          type="button"
        >
          <i class="glyphicon ti-trash rpad"></i>Delete SUN
        </button>
      </div>
    </div>
  </form>
</template>

<script>
// Third Party
import swal from 'sweetalert2'
import axios from 'axios'
import { mapGetters } from 'vuex'
import _ from 'lodash'

// Mixins
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

// Constants
import roles from '@/Assets/Constants/roles'
import colours from '@/Assets/Constants/colours'
import { types, levels } from '@/Assets/Constants/sunTypes'

// Components
import LinkedCustomersTable from '@/Components/Shared/LinkedCustomersTable'

// Other
import bacsCommon from '@/Lib/BacsCommon.js'

// Validations
import { bacsField } from '@/Assets/Validators'
import { required, helpers, maxLength } from 'vuelidate/lib/validators'

const sunValidator = helpers.regex('alpha', /^B\d{5}$|^\d{6}$/)

export default {
  mixins: [DataLeaveMixin, loading],
  components: {
    LinkedCustomersTable
  },
  props: ['status', 'id'],
  computed: {
    sunTypes () {
      return Object.values(types)
    },
    sunLevels () {
      if (this.sun.type === types.singleFMSun || this.sun.type === types.sharedFMSun) {
        var tempLevels = JSON.parse(JSON.stringify(levels))

        delete tempLevels.paper

        return Object.values(tempLevels)
      }

      return Object.values(levels)
    },
    canEdit () {
      return this.$store.getters.isInRoles([roles.Finance, roles.Implementations])
    },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () { this.$router.push({ name: 'Suns' }) }
  },
  data () {
    return {
      sun: {
        id: null,
        number: null,
        alias: null,
        type: null,
        level: 'Paperless',
        directDebitsAllowed: true,
        directCreditsAllowed: false,
        advanceNoticeInDays: 10,
        serviceUserName: '',
        bankAccountId: null,
        sendNoticeOfChange: true,
        bankStatementText: null
      },
      linkedToCustomers: false,
      linkedCustomers: [],
      sunTypesObject: types,

      bankAccounts: [],
      sunLoaded: false
    }
  },
  async mounted () {
    if (this.status === 'Edit') {
      await this.loadSun()
      await this.loadBankAccounts()
      this.sunLoaded = true
    } else {
      this.sunLoaded = true
    }
  },
  methods: {
    async saveSun () {
      try {
        if (this.status === 'Create') {
          await axios.post(
            `${process.env.VUE_APP_PLATFORM_API_URL}Sun`,
            this.sun,
            { showload: true, showerror: true, errormessage: 'SUN failed to save' }
          )
          this.$v.$reset()

          this.$toastr.s('New SUN created', 'Created')
          this.$router.push({ name: 'Suns' })
        } else {
          await axios.put(
            `${process.env.VUE_APP_PLATFORM_API_URL}Sun`,
            this.sun,
            { showload: true, showerror: true, errormessage: 'SUN failed to save' }
          )

          this.$toastr.s('SUN updated', 'Updated')

          await this.loadSun()
        }
        this.$v.$reset()
      } catch { }
    },
    async deleteSun () {
      try {
        if (this.linkedToCustomers) {
          this.$snapbar.w('Cannot delete this SUN because it is linked to some Clients.')
          return
        }

        // Get confirmation from the user that they really want to delete the sun
        var swalResult

        if (this.sun.type === types.bureauSun) {
          swalResult = await swal.fire({
            title: 'Delete SUN',
            icon: 'warning',
            html: `<div>This will permanently delete this SUN</div> 
          <div>Deleting a BUN may have adverse affects on the system</div>
          <div class="mb-1"><strong>Are you REALLY sure you want to do this?</strong></div>
          <div>Please type <code>${this.sun.alias}</code> to confirm </div>`,
            input: 'text',
            inputValidator: result => new Promise((resolve, reject) => {
              result === this.sun.alias ? resolve() : resolve(`You need to write ${this.sun.alias} to confirm`)
            }),
            showCancelButton: true,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'Delete SUN',
            cancelButtonText: 'Cancel'
          })
        } else {
          swalResult = await swal.fire({
            title: 'Delete SUN',
            text: 'Are you sure you want to delete this SUN?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          })
        }
      } catch (error) {
        // Do nothing as this will be if they clicked cancel
        return
      }

      if (!swalResult.isConfirmed) {
        return
      }

      try {
        await axios.delete(
          `${process.env.VUE_APP_PLATFORM_API_URL}sun/${this.sun.id}`,
          { showload: true, showerror: true, errormessage: 'SUN failed to delete' }
        )
        this.$toastr.s('SUN deleted', 'Deleted')
        this.$router.push({ name: 'Suns' })
        this.$v.$reset()
      } catch { }
    },
    async loadSun () {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}sun/${this.id}/withcustomernames`,
          {
            params: { paygateId: this.$store.state.common.paygateId },
            showload: true,
            showerror: true,
            errormessage: 'SUNfailed to load'
          }
        )
        this.sun = response.data

        if ((this.sun.assignedCustomerNames !== undefined && this.sun.assignedCustomerNames.length > 0)) {
          this.linkedToCustomers = true
          this.linkedCustomers = this.sun.assignedCustomerNames
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      } finally {
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    },
    async changeType () {
      if (!this.sunLoaded) {
        return
      }
      this.sun.bankAccountId = null
      this.bankAccounts = []
      this.$v.$reset()
      console.log('type changes')

      this.loadBankAccounts()
    },
    async loadBankAccounts () {
      try {
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Sun/BankAccountDropdown`, { type: this.sun.type }, { showerror: true, errormessage: 'Failed to get bank accounts' })

        this.bankAccounts = response.data.map(bankAccount => {
          return { value: bankAccount.bankAccountId, text: bankAccount.reference }
        })
      } catch { }
    },
    async viewGroupBankDetails () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.sun.bankAccountId}`, { showload: true, showerror: true, errormessage: 'Failed to get bank account' })
        var bankAccount = response.data
        this.$swal({
          title: 'Bank details',
          html: bacsCommon.viewGroupBankDetails(bankAccount, ''),
          type: 'info',
          animation: false
        })
      } catch { }
    }

  },
  validations () {
    return {
      sun: {
        number: { required, sunValidator },
        alias: { required },
        type: { required },
        level: { required },
        directDebitsAllowed: {},
        directCreditsAllowed: {},
        advanceNoticeInDays: { required },
        serviceUserName: { required, maxLength: maxLength(50) },
        bankAccountId: {
          required
        },
        sendNoticeOfChange: {},
        bankStatementText: { required, bacsField, maxLength: maxLength(18) }
      }
    }
  }
}
</script>
