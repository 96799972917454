<template>
  <div>
    <div class="d-flex justify-content-between mb-20">
      <h5 class="m-0">Collections Value</h5>
    </div>
    <div style="position:relative; height: 350px !important">
      <line-chart :chart-data="chartData" :options="chartOptions" :styles="{ height: '100%' }"></line-chart>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import LineChart from '@/Assets/Components/Charts/LineChart'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import roles from '@/Assets/Constants/roles'
import {mapGetters} from "vuex";

export default {
  components: {
    LineChart,
    VuePerfectScrollbar
  },
  name: 'CollectionsValueWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  watch: {
    'widget.options': {
      async handler () {
        await this.load()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    txDateRange () {
      return this.widget.options.find(o => o.label === 'Date Range').value
    },
    numberOfDays () {
      return this.widget.options.find(o => o.label === 'Number of Days').value
    },
    selectedWidgetCustomer () {
      return this.widget.options.find(o => o.label === 'Client').value !== undefined ? this.widget.options.find(o => o.label === 'Client').value : this.selectedCustomer
    },
    unsubmitted () {
      return this.widget.options.find(o => o.label === 'Unsubmitted').value
    },
    submitted () {
      return this.widget.options.find(o => o.label === 'Submitted').value
    },
    due () {
      return this.widget.options.find(o => o.label === 'Due').value
    },
    failed () {
      return this.widget.options.find(o => o.label === 'Failed').value
    },
    indemnity () {
      return this.widget.options.find(o => o.label === 'Indemnity Claim').value
    },
    cancelled () {
      return this.widget.options.find(o => o.label === 'Cancelled').value
    },
    successful () {
      return this.widget.options.find(o => o.label === 'Successful').value
    },
    isSystemUser () { return this.$store.getters.isInRole(roles.SystemUser) || this.$store.getters.isInRole(roles.Finance) }
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Collections Value'
            }
          }],
          xAxes: [{
            ticks: {
              callback: function (value, index, values) {
                return value.substring(0, 5)
              }
            },
            gridLines: {
              display: false
            },
            categoryPercentage: 0.5,
            barPercentage: 0.8
          }]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var index = tooltipItem.index;
              let amount = dataset.data[index].toFixed(2).toString()
              var pattern = /(-?\d+)(\d{3})/
              while (pattern.test(amount)) {
                amount = amount.replace(pattern, '$1,$2')
              }
              return data.datasets[tooltipItem.datasetIndex].label + ': £' + amount;
            },
            title: function (chart, data) {
              // console.log('title value: ' + value)
              // return data.labels[chart[0].index].substring(3, 13)
              return null
            }
          }
        },
        onHover: (e, item) => {
          if (item.length !== 0) {
            e.target.style.cursor = 'pointer'
          } else {
            e.target.style.cursor = 'default'
          }
        }
      },
      chartData: {},
      settings: {
        maxScrollbarLength: 60
      },
      submissionCountData: {},
      chartTitle: ''
    }
  },

  methods: {
    formatAmount: (value) => {
      return `${this.numberWithCommas((value / 1).toFixed(2))}`
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    async load () {
      if (this.selectedWidgetCustomer === null) {
        this.$toastr.e('Please select a client')
        return
      }

      if (this.numberOfDays === '') {
        this.widget.options.find(o => o.label === 'Number of Days').value = 0
      }

      if (this.numberOfDays < 0 || this.numberOfDays > 90) {
        this.$toastr.e('Number of Days must be between 0 and 90')
        return
      }

      var queryParams = `?txDateRange=${this.txDateRange}&numberOfDays=${this.numberOfDays}&selectedCustomerId=${this.selectedWidgetCustomer}`
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}widget/getcollectionsvalue${queryParams}`)
      this.submissionCountData = response.data
      if (this.submissionCountData === null || this.submissionCountData.length === 0) {
        return
      }

      this.chartTitle = this.submissionCountData[0].chartTitle
      var labels = []
      var data = []
      this.dates = []

      let unsub = []
      let unsublabel = []
      let subbed = []
      let sublabel = []
      let fails = []
      let failslabel = []
      let due = []
      let dueable = []
      let indem = []
      let indemlabel = []
      let success = []
      let successlabel = []
      let cancel = []
      let cancellabel = []

      var datasets = []
      for (var i = 0; i < this.submissionCountData.length; i++) {
        labels.push(this.submissionCountData[i].dateAsString)
        unsub.push(this.submissionCountData[i].notProcessedValue)
        unsublabel.push(this.submissionCountData[i].notProcessedValue)
        subbed.push(this.submissionCountData[i].collectedValue)
        sublabel.push(this.submissionCountData[i].collectedValue)
        fails.push(this.submissionCountData[i].failureValue)
        failslabel.push(this.submissionCountData[i].failureValue)
        due.push(this.submissionCountData[i].pendingValue)
        dueable.push(this.submissionCountData[i].pendingValue)
        indem.push(this.submissionCountData[i].indemnityValue)
        indemlabel.push(this.submissionCountData[i].indemnityValue)
        success.push(this.submissionCountData[i].successValue)
        successlabel.push(this.submissionCountData[i].successValue)
        cancel.push(this.submissionCountData[i].cancelledValue)
        cancellabel .push(this.submissionCountData[i].cancelledValue)
      }
      if (this.unsubmitted) {
        datasets.push({
          data: unsub,
          label: 'Unsubmitted', // 'Debit Transactions',
          backgroundColor: '#20c997',
          fill: false,
          borderColor: '#20c997',
          labels: unsublabel,
          lineTension: 0 // Shows straight lines between the points; remove this option to show curves.
        })
      }
      if(this.submitted){
        datasets.push({
          data: subbed,
          label: 'Submitted', // 'Debit Transactions',
          backgroundColor: '#1c9000',
          fill: false,
          borderColor: '#1c9000',
          labels: sublabel,
          lineTension: 0 // Shows straight lines between the points; remove this option to show curves.
        })
      }
      if (this.failed){
        datasets.push({
          data: fails,
          label: 'Failed', // 'Debit Transactions',
          backgroundColor: '#d32500',
          fill: false,
          borderColor: '#d32500',
          labels: failslabel,
          lineTension: 0 // Shows straight lines between the points; remove this option to show curves.
        })
      }
      if( this.due){
        datasets.push({
          data: due,
          label: 'Due', // 'Debit Transactions',
          backgroundColor: '#0dcaf0',
          fill: false,
          borderColor: '#0dcaf0',
          labels: dueable,
          lineTension: 0 // Shows straight lines between the points; remove this option to show curves.
        })
      }
      if(this.indemnity){
        datasets.push({
          data: indem,
          label: 'Indemnity Claim', // 'Debit Transactions',
          backgroundColor: '#ffc107',
          fill: false,
          borderColor: '#ffc107',
          lineTension: 0, // Shows straight lines between the points; remove this option to show curves.
          labels: indemlabel
        })
      }
      if(this.cancelled){
        datasets.push({
          data: cancel,
          label: 'Cancelled', // 'Debit Transactions',
          backgroundColor: '#FFCCFF',
          fill: false,
          borderColor: '#FFCCFF',
          lineTension: 0, // Shows straight lines between the points; remove this option to show curves.
          labels: cancellabel
        })
      }
      if(this.successful){
        datasets.push({
          data: success,
          label: 'Successful', // 'Debit Transactions',
          backgroundColor: '#CCFFCC',
          fill: false,
          borderColor: '#CCFFCC',
          lineTension: 0, // Shows straight lines between the points; remove this option to show curves.
          labels: successlabel
        })
      }


      this.chartData = {
        labels: labels,
        datasets: datasets
      }
    },
    formatValue (value) {
      try {
        return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } catch (e) {
        return 0
      }
    },

    formatCount (count) {
      try {
        return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '$&,')
      } catch (e) {
        return 0
      }
    }
  },

  async mounted () {
    await this.load()
  }
}
</script>
