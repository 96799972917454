<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Billing Report</h2>
      </div>

      <div class="idb-block-content">
        <form>
          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Billing ID</label>
            <div class="col-sm-10">
              <input type="text" readonly class="form-control-plaintext" v-model="reportDetails.id" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Client</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                v-model="reportDetails.clientName"
              />
            </div>
          </div>

          <div class="form-group row" v-if="reportDetails.clientDebitedName">
            <label class="col-sm-2 text-right col-form-label">Debited Client</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                v-model="reportDetails.clientDebitedName"
              />
            </div>
          </div>

          <div class="form-group row" v-if="reportDetails.billingMethod === 'Direct Debit'">
            <label class="col-sm-2 text-right col-form-label">Customer</label>
            <div class="col-sm-10">
              <a href="#" @click="gotoPayer">View Customer</a>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Creation Date</label>
            <div class="col-sm-10">
              <span
                class="form-control-plaintext"
              >{{reportDetails.creationDate | formatDate('DD/MM/YYYY')}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Status</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                v-model="reportDetails.status"
              />
            </div>
          </div>

          <div
            v-if="reportDetails.status === 'Failed' ||reportDetails.status === 'Netdown Created' "
          >
            <div class="form-group row" v-if="reportDetails.reason">
              <label class="col-sm-2 text-right col-form-label">Reason</label>
              <div class="col-sm-10">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  v-model="reportDetails.reason"
                />
              </div>
            </div>
          </div>

          <div class="form-group row" v-if="reportDetails.paidDate">
            <label class="col-sm-2 text-right col-form-label">Settled Date</label>
            <div class="col-sm-10">
              <span
                class="form-control-plaintext"
              >{{reportDetails.paidDate | formatDate('DD/MM/YYYY')}}</span>
            </div>
          </div>

          <div class="form-group row" v-if="reportDetails.paidByName">
            <label class="col-sm-2 text-right col-form-label">Settled By</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                v-model="reportDetails.paidByName"
              />
            </div>
          </div>

          <div class="form-group row" v-if="reportDetails.otherPaymentMethod">
            <label class="col-sm-2 text-right col-form-label">Settled with Payment Method</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                v-model="reportDetails.otherPaymentMethod"
              />
            </div>
          </div>

          <div class="form-group row" v-if="reportDetails.status == 'Netdown Created'">
            <label class="col-sm-2 text-right col-form-label">Netdown Created</label>
            <div class="col-sm-10">
              <tick class="form-control-plaintext" v-model="netdownCreated" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Billing Payment Details</label>

            <div class="col-md-6">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Service Charges</td>
                    <td
                      :class="moneyClass(reportDetails.serviceCharges)"
                    >{{moneyFormat(reportDetails.serviceCharges)}}</td>
                  </tr>

                  <tr>
                    <td>VAT</td>
                    <td
                      :class="moneyClass(reportDetails.vatAmount)"
                    >{{moneyFormat(reportDetails.vatAmount)}}</td>
                  </tr>

                  <tr>
                    <td>Total Payment Amount</td>
                    <td
                      :class="moneyClass(reportDetails.amount)"
                    >{{moneyFormat(reportDetails.amount)}}</td>
                  </tr>
                </tbody>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Billing Details</h2>
      </div>

      <div class="idb-block-content">
        <div class="row">
          <div class="col-md-12">
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              :rows="rows"
              :columns="columns"
              :lineNumbers="true"
              mode="remote"
              :totalRows="totalRecords"
              styleClass="vgt-table striped bordered"
              ref="table"
              @on-row-click="gotoDetails"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
            >
              <template slot="table-row" slot-scope="props">
                <span
                  v-if="props.column.field !== 'name'"
                  :class="tdClass(props.row, props.column.field)"
                >{{props.formattedRow[props.column.field]}}</span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="$router.go(-1)">Back</button>
        <button
          class="btn btn-success ml-2"
          v-if="this.reportDetails.status === 'Failed' && this.reportDetails.billingMethod === 'Direct Debit'"
          @click="resubmit"
        >Resubmit</button>
        <button
          class="btn btn-success ml-2"
          v-if="canMarkAsSettled"
          @click="openMarkAsPaidModal"
        >Mark As Settled</button>
      </div>
    </div>
    <b-modal
      id="otherPaymentMethod"
      ref="otherPaymentMethod"
      variant="primary"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
      title="Specify Other Payment Method"
      ok-variant="success"
      cancel-variant="danger"
      cancel-title="Cancel"
      ok-title="Specify Other Payment Method"
    >
      <div class="form-group" :class="{ invalid: $v.otherPaymentMethod.$error }">
        <label class="required">Payment Method</label>
        <input type="text" class="form-control" v-model="$v.otherPaymentMethod.$model" />
        <validation-messages v-model="$v.otherPaymentMethod" name="payment method"></validation-messages>
      </div>

      <template slot="modal-footer">
        <button type="button" class="btn btn-secondary" @click="hideModal">Cancel</button>
        <button
          type="button"
          class="btn btn-success"
          @click="checkValidation() && markAsPaid()"
        >Submit</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'

// Validators
import { requiredIf } from 'vuelidate/lib/validators'

// Mixins
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  name: 'BillingReportDetails',
  props: ['id'],
  mixins: [settlementMixin, tableFilterMixin],
  computed: {
    netdownCreated () {
      return !!this.reportDetails.netdownId
    },
    canMarkAsSettled () {
      return (this.reportDetails.status === 'Awaiting Payment' && this.reportDetails.billingMethod === 'Other') || (this.reportDetails.status === 'Failed' && this.reportDetails.billingMethod === 'Direct Debit')
    }
  },
  data () {
    return {
      reportDetails: {
        amount: 0,
        serviceCharges: 0,
        vatAmount: 0
      },
      columns: [
        {
          label: 'Type',
          field: 'type'
        },
        {
          label: 'Customer Reference',
          field: 'customerReference',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Amount (£)',
          field: 'charge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'VAT (£)',
          field: 'vatAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Service Charges (£)',
          field: 'serviceCharge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        }
      ],
      rows: [],
      serverParams: {
        sort: [{ field: 'processingDate', type: 'desc' }]
      },
      otherPaymentMethod: null
    }
  },
  methods: {
    async gotoDetails (params) {
      var billingUsageId = params.row.id
      await this.$store.dispatch('setPaygateId', this.reportDetails.paygateId)
      window.open(this.$router.resolve({ name: 'ChargeDetail', params: { id: billingUsageId, showBackButton: false } }).href, '_blank')
    },
    async loadReport () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Billing/Report/${this.id}`, {
        showload: true,
        showerror: true,
        errormessage: 'Billing details failed to load'
      })

      this.reportDetails = response.data
    },
    async load () {
      try {
        this.isTableLoading = true

        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Billing/Report/Table`, {
          params: {
            ...this.buildGoodTableQuery(),
            billingId: this.id
          },
          showerror: true,
          errormessage: 'Billing report details failed to load'
        })

        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch {

      } finally {
        this.isTableLoading = false
      }
    },
    openMarkAsPaidModal () {
      this.$refs.otherPaymentMethod.show()
    },
    hideModal () {
      this.otherPaymentMethod = null
      this.$v.$reset()
      this.$refs.otherPaymentMethod.hide()
    },
    async markAsPaid () {
      this.$refs.otherPaymentMethod.hide()
      try {
        await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}Billing/MarkAsPaid/${this.id}`, null, {
          params: { otherPaymentMethod: this.otherPaymentMethod },
          showload: true,
          showerror: true,
          errormessage: 'Billing failed to mark as Settled'
        })

        this.$toastr.s(
          'Billing marked as Settled',
          'Settled'
        )
        await this.loadReport()
      } catch { }
    },
    gotoPayer () {
      window.open(this.$router.resolve({ name: 'editPayer', params: { ukPayerId: this.reportDetails.ukPayerId } }).href, '_blank')
    },
    async resubmit () {
      try {
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Billing/Resubmit/${this.id}`, {}, {
          showload: true,
          showerror: true,
          errormessage: 'Billing Items failed to re-submit'
        })

        this.$toastr.s(
          'Billing Items Re-Submitted',
          'Submitted'
        )
        await this.loadReport()
      } catch { }
    }
  },
  async created () {
    await this.loadReport()
    await this.load()
  },
  validations () {
    return {
      otherPaymentMethod: {
        required: requiredIf(() => {
          return this.reportDetails.status === 'Awaiting Payment' && this.reportDetails.billingMethod === 'Other'
        })
      }
    }
  }
}
</script>

<style>
</style>
