<template>
  <div>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2><span class="pull-right"><a :href="`${docUrl}/automation/paygate-designer/designersettings/`" target="_blank"><i class="far fa-question-circle"></i></a></span>Workflow/Mapping Designer Settings<favourite-icon></favourite-icon></h2>
          </div>
          <form>
            <div class="idb-block-content">
              <!-- <div class="row">
                <div class="col-md-1">
                </div>
                <div class="col-md-4">
                  <p-check name="check" class="p-switch" color="primary " v-model="config.magneticLinks"><span class=" ml-2">Enable Magnetic Links</span></p-check>
                </div>
                <div class="col-md-7">
                  Links snap to the closest available connector.
                </div>
              </div> -->
              <br/>
              <div class="row">
                <div class="col-md-1">
                </div>
                <div class="col-md-4">
                  <p-check name="check" class="p-switch" color="primary " v-model="config.autosave"><span class=" ml-2">Enable Auto Save</span></p-check>
                </div>
                <div class="col-md-7">
                  The Mapping or Workflow is automatically saved in the background
                </div>
              </div>
            </div>
            <div class="idb-block-footer">
              <div class="row" >
                <div class="col-md-12 col-sm-3">
                  <b-button @click.prevent="btnSave" :disabled="isLoading" variant="primary"><i class="fa fa-save mr-2"></i>Save Settings</b-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  data: () => ({
    paygateId: '',
    config: {},
    docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
  }),
  methods: {
    async btnSave () {
      try {
        await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}designersettings`, this.config, { showload: true })
        this.$toastr.s('The settings were successfully updated.')
      } catch (e) {
        this.$snapbar.e(`Error saving duplication configuration - ${e.message}`)
      }
    }
  },
  created: async function () {
    this.$snapbar.hide()
    let res
    try {
      res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}designersettings`, { showload: true })
      console.log(res)
      if (res && res.data) {
        this.config = res.data
      } else {
        this.$snapbar.e('Error retrieving Designer Settings')
      }
    } catch (e) {
      this.$snapbar.e(`Error retrieving Designer Settings - ${e.message}`)
    }
  }
}
</script>

<style scoped>
  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
</style>
