<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Invoice Analysis
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="form-row">
              <div class="form-group col-md-2" :class="{ invalid: $v.rangeOn.$error }">
                <label for="to" class="required">Range Based On</label>
                <select class="form-control" v-model="$v.rangeOn.$model" @change="loaded = false">
                  <option selected value="ChargeDate">Charge Date</option>
                  <option value="InvoiceDate">Invoice Date</option>
                </select>
                <validation-messages v-model="$v.rangeOn" name="range based on"></validation-messages>
              </div>
              <div
                class="form-group col-md-2"
                :class="{ invalid: $v.type.$error || $v.chargeType.$error }"
              >
                <label for="to" class="required">Analysis Type</label>
                <select class="form-control" v-model="$v.type.$model" @change="loaded = false">
                  <option value="BillingType">Billing Type</option>
                  <option value="ChargeType">Charge Type</option>
                  <option value="RecurringVNonRecurring">Recurring vs Non-Recurring</option>
                </select>
                <validation-messages v-model="$v.type" name="analysis Type"></validation-messages>

                <b-form-select
                  class="mt-2"
                  v-model="$v.chargeType.$model"
                  :options="billingTypesAsDropdown"
                  title="Type"
                  @input="$v.$reset()"
                  @change="loaded = false"
                  v-if="type==='ChargeType'"
                ></b-form-select>
                <validation-messages v-model="$v.chargeType" name="charge type"></validation-messages>
              </div>

              <div
                class="form-group col-md-2"
                :class="{ invalid: $v.associationId.$error }"
                v-show="!sunId && !clientId"
                v-if="false"
              >
                <label for="to">Association</label>
                <select
                  class="form-control"
                  v-model="$v.associationId.$model"
                  @change="changeToNull('associationId')"
                >
                  <option></option>
                  <option
                    v-for="association in associations"
                    :key="association.id"
                    :value="association.id"
                  >{{association.label}}</option>
                </select>
                <validation-messages v-model="$v.associationId" name="association"></validation-messages>
              </div>
              <div
                class="form-group col-md-2"
                :class="{ invalid: $v.clientId.$error }"
                v-show="!associationId && !sunId"
              >
                <label for="to">Client</label>
                <select
                  class="form-control"
                  v-model="$v.clientId.$model"
                  @change="changeToNull('clientId')"
                >
                  <option></option>
                  <option
                    v-for="customer in customers"
                    :key="customer.paygateId"
                    :value="customer.paygateId"
                  >{{customer.name}}</option>
                </select>
                <validation-messages v-model="$v.clientId" name="client"></validation-messages>
              </div>
              <div
                class="form-group col-md-2"
                :class="{ invalid: $v.sunId.$error }"
                v-show="!associationId && !clientId"
              >
                <label for="to">Sun</label>
                <select
                  class="form-control"
                  v-model="$v.sunId.$model"
                  @change="changeToNull('sunId')"
                >
                  <option></option>
                  <option v-for="sun in suns" :key="sun.id" :value="sun.id">{{sun.label}}</option>
                </select>
                <validation-messages v-model="$v.sunId" name="sun"></validation-messages>
              </div>
            </div>
            <div class="form-row">
              <div
                class="form-group col-md-2"
                :class="{ invalid: $v.dates.fromFrom.$error || $v.dates.fromTo.$error }"
              >
                <label for="from" class="required">From Range</label>
                <vuejsDatepicker
                  name="from"
                  id="from"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="$v.dates.fromFrom.$model"
                  :disabledDates="{from:dates.fromTo}"
                  @change="loaded = false"
                ></vuejsDatepicker>
                <validation-messages v-model="$v.dates.fromFrom" name="from range"></validation-messages>
                <vuejsDatepicker
                  name="from"
                  id="from"
                  format="dd/MM/yyyy"
                  class="mt-2"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="$v.dates.fromTo.$model"
                  :disabledDates="{to:dates.fromFrom}"
                  @change="loaded = false"
                ></vuejsDatepicker>
                <validation-messages v-model="$v.dates.fromTo" name="from range"></validation-messages>
              </div>
              <div
                class="form-group col-md-2"
                :class="{ invalid: $v.dates.toFrom.$error || $v.dates.toTo.$error }"
              >
                <label for="to" class="required">To Range</label>
                <vuejsDatepicker
                  name="to"
                  id="to"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="$v.dates.toFrom.$model"
                  :disabledDates="{from:dates.toTo}"
                  @change="loaded = false"
                ></vuejsDatepicker>
                <validation-messages v-model="$v.dates.toFrom" name="to range"></validation-messages>
                <vuejsDatepicker
                  name="to"
                  id="to"
                  format="dd/MM/yyyy"
                  class="mt-2"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="$v.dates.toTo.$model"
                  :disabledDates="{to:dates.toFrom}"
                  @change="loaded = false"
                ></vuejsDatepicker>
                <validation-messages v-model="$v.dates.toTo" name="to range"></validation-messages>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click="checkValidation() && load()"
              :disabled="isLoading"
            >Load Analysis</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loaded">
      <div class="row">
        <div class="col-md-12">
          <div class="idb-block">
            <div class="idb-block-content">
              <div class="row">
                <div class="col-md-12">
                  <vue-good-table
                    :rows="rows"
                    :columns="columns"
                    :pagination-options="{
                      enabled:true,
                      jumpFirstOrLast:true,
                    }"
                    :sort-options="sortOptions"
                    styleClass="vgt-table striped bordered"
                    class="scroll-table"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span
                        v-if="props.column.field !== 'accountId' && props.column.field !== 'clientName'&& props.column.field !== 'sun' && !props.column.field.endsWith('Percentage')"
                        :class="tdClass(props.row, props.column.field)"
                      >{{props.formattedRow[props.column.field]}}</span>
                      <span
                        v-else-if="props.column.field.endsWith('Percentage')"
                        :class="tdClass(props.row, props.column.field + 'Amount')"
                      >{{props.formattedRow[props.column.field]}}</span>
                      <span v-else>{{props.formattedRow[props.column.field]}}</span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
            <div class="idb-block-footer">
              <button type="button" class="btn btn-success" @click="downloadExcel">Export as Excel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import moment from 'moment'
import { saveAs } from 'file-saver'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'
import invoicingMixin from '@/Components/Platform/Customer/Invoicing/invoicingMixin'

// Constants
import { billingTypesAsDropdown } from '@/Assets/Constants/billing'

// Validations
import {
  required,
  requiredIf
} from 'vuelidate/lib/validators'

export default {
  name: 'InvoiceAnalysis',
  mixins: [loading, invoicingMixin],
  computed: {
    billingTypesAsDropdown () {
      return billingTypesAsDropdown
    },
    requestData () {
      return {
        ...this.dates,
        rangeOn: this.rangeOn,
        type: this.type,
        chargeType: this.chargeType,
        clientId: this.clientId,
        associationId: this.associationId,
        sunId: this.sunId

      }
    },
    columns () {
      switch (this.type) {
        case 'BillingType':
          return [
            {
              label: 'Account Id',
              field: 'accountId',
              sortable: true
            },
            {
              label: 'Client Name',
              field: 'clientName',
              sortable: true
            },
            {
              label: 'SUN',
              field: 'sun',
              sortable: true
            },
            {
              label: 'Processing Charges From',
              field: 'processingChargesFrom',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Processing Charges To',
              field: 'processingChargesTo',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'

            },
            {
              label: 'Processing Charges Difference',
              field: 'processingDifference',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Processing Charges Difference Percentage',
              field: 'processingDifferencePercentage',
              tdClass: 'text-right'
            },
            {
              label: 'Ad-Hoc Charges From',
              field: 'adHocChargesFrom',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Ad-Hoc Charges To',
              field: 'adHocChargesTo',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'

            },
            {
              label: 'Ad-Hoc Charges Difference',
              field: 'adHocDifference',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Ad-Hoc Charges Difference Percentage',
              field: 'adHocDifferencePercentage',
              tdClass: 'text-right'
            },

            {
              label: 'PS Charges From',
              field: 'psChargesFrom',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'PS Charges To',
              field: 'psChargesTo',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'

            },
            {
              label: 'PS Charges Difference',
              field: 'psDifference',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'PS Charges Difference Percentage',
              field: 'psDifferencePercentage',
              tdClass: 'text-right'
            },

            {
              label: 'Periodic Charges From',
              field: 'periodicChargesFrom',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Periodic Charges To',
              field: 'periodicChargesTo',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'

            },
            {
              label: 'Periodic Charges Difference',
              field: 'periodicDifference',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Periodic Charges Difference Percentage',
              field: 'periodicDifferencePercentage',
              tdClass: 'text-right'
            },

            {
              label: 'Miscellaneous Charges From',
              field: 'miscellaneousChargesFrom',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Miscellaneous Charges To',
              field: 'miscellaneousChargesTo',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'

            },
            {
              label: 'Miscellaneous Charges Difference',
              field: 'miscellaneousDifference',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Miscellaneous Charges Difference Percentage',
              field: 'miscellaneousDifferencePercentage',
              tdClass: 'text-right'
            },

            {
              label: 'Total Charges From',
              field: 'totalChargesFrom',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Total Charges To',
              field: 'totalChargesTo',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'

            },
            {
              label: 'Total Charges Difference',
              field: 'totalDifference',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Total Charges Difference Percentage',
              field: 'totalDifferencePercentage',
              tdClass: 'text-right'
            }

          ]
        case 'ChargeType':
          return [
            {
              label: 'Account Id',
              field: 'accountId',
              sortable: true
            },
            {
              label: 'Client Name',
              field: 'clientName',
              sortable: true
            },
            {
              label: 'SUN',
              field: 'sun',
              sortable: true
            },

            {
              label: 'Count From',
              field: 'countFrom',
              tdClass: 'text-right'
            },
            {
              label: 'Value From',
              field: 'valueFrom',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Charges From',
              field: 'chargesFrom',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },

            {
              label: 'Count To',
              field: 'countTo',
              tdClass: 'text-right'
            },
            {
              label: 'Value To',
              field: 'valueTo',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Charges To',
              field: 'chargesTo',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },

            {
              label: 'Count Difference',
              field: 'countDifference',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Value Difference',
              field: 'valueDifference',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Charges Difference',
              field: 'chargesDifference',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },

            {
              label: 'Count Difference Percentage',
              field: 'countPercentage',
              tdClass: 'text-right'
            },
            {
              label: 'Value Difference Percentage',
              field: 'valuePercentage',
              tdClass: 'text-right'
            },
            {
              label: 'Charges Difference Percentage',
              field: 'chargesPercentage',
              tdClass: 'text-right'
            }

          ]
        case 'RecurringVNonRecurring':
          return [
            {
              label: 'Account Id',
              field: 'accountId',
              sortable: true
            },
            {
              label: 'Client Name',
              field: 'clientName',
              sortable: true
            },
            {
              label: 'SUN',
              field: 'sun',
              sortable: true
            },
            {
              label: 'Recurring Charges From',
              field: 'recurringChargesFrom',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Recurring Charges To',
              field: 'recurringChargesTo',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Recurring Charges Difference',
              field: 'recurringChargesDifference',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Recurring Charges Percentage',
              field: 'recurringChargesPercentage',
              tdClass: 'text-right'
            },

            {
              label: 'Non-Recurring Charges From',
              field: 'nonRecurringChargesFrom',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Non-Recurring Charges To',
              field: 'nonRecurringChargesTo',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Non-Recurring Charges Difference',
              field: 'nonRecurringChargesDifference',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Non-Recurring Charges Percentage',
              field: 'nonRecurringChargesPercentage',
              tdClass: 'text-right'
            },
            {
              label: 'Total Charges From',
              field: 'totalChargesFrom',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Total Charges To',
              field: 'totalChargesTo',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Total Charges Difference',
              field: 'totalChargesDifference',
              formatFn: this.moneyFormat,
              tdClass: 'text-right'
            },
            {
              label: 'Total Charges Percentage',
              field: 'totalChargesPercentage',
              tdClass: 'text-right'
            }
          ]
      }

      return []
    }
  },
  data () {
    return {
      dates: {
        fromFrom: null,
        fromTo: null,
        toFrom: null,
        toTo: null
      },
      rangeOn: 'ChargeDate',
      type: 'BillingType',
      chargeType: null,
      associationId: null,
      sunId: null,
      clientId: null,
      rows: [],
      loaded: false,
      shouldLoad: false,
      suns: [],
      associations: [],
      customers: [],
      sortOptions: { enabled: true, initialSortBy: { field: 'clientName', type: 'asc' } }

    }
  },
  methods: {
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/Analysis`,
          {
            params: {
              ...this.requestData
            },
            showload: true,
            showerror: true,
            errormessage: 'Invoice Analysis Failed to load'
          })

        switch (this.type) {
          case 'BillingType':
            this.rows = response.data.billingReport
            break
          case 'ChargeType':
            this.rows = response.data.chargeReport
            break
          case 'RecurringVNonRecurring':
            this.rows = response.data.recurringVNonRecurring
            break
        }

        this.loaded = true
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    async loadSuns () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}sun/Dropdown`, { showerror: true, errormessage: 'Failed to get SUNs' })
        this.suns = response.data
      } catch { }
    },
    async loadAssociations () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CustomerAssociations/Dropdown`, { showerror: true, errormessage: 'Failed to get Customer Associations' })
        this.associations = response.data
      } catch { }
    },
    async loadCustomers () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Customers`, { showerror: true, errormessage: 'Failed to get Customers' })
        this.customers = response.data
      } catch { }
    },
    changeToNull (property) {
      if (this[property] === '') {
        this[property] = null
        this.$v.associationId.$reset()
        this.$v.sunId.$reset()
        this.$v.clientId.$reset()
      }
      this.loaded = false
    },
    async downloadExcel () {
      try {
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/AnalysisDownload`,
          {
            ...this.requestData
          },
          {

            responseType: 'blob',
            showload: true,
            showerror: true,
            errormessage: 'Invoice Analysis Failed to download'
          })

        const file = new Blob([response.data])

        saveAs(file, `InvoiceAnalysis-${new Date().toISOString()}-${this.type}.xlsx`)
      } catch (e) {
        this.$toastr.e('Something went wrong', 'Error')
      } finally {
      }
    }

  },
  async created () {
    var thisMonth = moment().utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0, date: 1 }).add(1, 'month')
    this.dates.fromFrom = moment(thisMonth).subtract(3, 'month').toDate()
    this.dates.fromTo = moment(thisMonth).subtract(2, 'month').subtract(1, 'day').toDate()

    this.dates.toFrom = moment(thisMonth).subtract(2, 'month').toDate()
    this.dates.toTo = moment(thisMonth).subtract(1, 'month').subtract(1, 'day').toDate()

    this.loadSuns()
    this.loadAssociations()
    this.loadCustomers()
  },
  validations () {
    return {
      dates: {
        fromFrom: { required },
        fromTo: { required },
        toFrom: { required },
        toTo: { required }
      },
      rangeOn: { required },
      type: { required },
      chargeType: {
        required: requiredIf((model) => {
          return model.type === 'ChargeType'
        })
      },
      associationId: {
      },
      sunId: {
      },
      clientId: {
      }
    }
  }
}
</script>

<style>
</style>
