<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2><a href="javascript:void(0);" v-on:click="clickBack()" id="app">
  Log Entry
</a> / Event<span class="pull-right"><a :href="`${docUrl}/automation/logging/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h2>
        </div>
        <div class="idb-block-content">
          <div>
            <div v-if="workflowEvent.data">
              <h4>Event Data</h4>
              <div class="row">
                <div class="col-md-2"><span class="ml-3"></span>Title</div>
                <div class="col-md-4">{{workflowEvent.title}}</div>
                <div class="col-md-2">Started</div>
                <div class="col-md-4"> {{ reformatDate(workflowEvent.createdAt)}}</div>
              </div>
              <div class="row">
                <div class="col-md-2"><span class="ml-3"></span>Status</div>
                <div class="col-md-4">
                  <b-badge pill v-if = "workflowEvent.currentStatus == 'Failed'" variant="danger">{{workflowEvent.currentStatus}}</b-badge>
                  <b-badge pill v-else-if = "workflowEvent.currentStatus == 'Complete'" variant="success">{{workflowEvent.currentStatus}}</b-badge>
                  <b-badge pill v-else-if = "workflowEvent.currentStatus == 'Completed with warnings'" variant="warning">{{workflowEvent.currentStatus}}</b-badge>
                  <b-badge pill v-else-if = "workflowEvent.currentStatus == 'Processing'" variant="primary">{{workflowEvent.currentStatus}}</b-badge>
                  <b-badge pill v-else-if = "workflowEvent.currentStatus == 'Paused'" variant="warning">{{workflowEvent.currentStatus}}</b-badge>
                  <!-- <span v-else>{{props.row.currentStatus}}</span> -->
                </div>
                <div class="col-md-2">Last Updated</div>
                <div class="col-md-4"> {{ reformatDate(workflowEvent.updatedAt)}}</div>
              </div>
              <br/>
              <hr>
              <br>
              <h4>Node Configuration</h4>
              <div v-for="(item, index) in this.workflowEvent.data.props" :key="index" class="row">
                <div class="col-md-4"><span class="ml-3"></span>{{item.title}}</div>
                <div class="col-md-6" v-if="item.element && !item.element.startsWith('pw')" >{{item.value}}</div>
                <div class="col-md-6" v-else>********</div>
                <!-- <div class="col-md-9">{{item.element.startsWith('pw')}}</div> -->
              </div>
              <br/>
              <hr>
              <br/>
            </div>
            <h4>Log Messages</h4>
            <div>The individual log messages that this event created</div>
            <br>
            <div>
              <vue-good-table
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                :isLoading.sync="isTableLoading"
                ref="table"
                :columns="columns"
                :rows="rows"
                :lineNumbers="true"
                :search-options="{ enabled: true }"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                styleClass="vgt-table striped bordered table-hover"
                >
                <template slot="table-row" slot-scope="props">
                  <span v-if = "props.column.field == 'datetime'">
                    {{ reformatDate(props.formattedRow[props.column.field])}}
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
                <div slot="table-actions">
                <button
                  @click.prevent="clearTableFilters"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Clear filters'">
                  <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                  </span>
                </button>
                  <b-button @click.prevent="load" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'" ><i class="fas fa-sync-alt pointer dimmedIcon"></i></b-button>
                </div>
              </vue-good-table>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row" >
            <div class="col-md-12 col-sm-3">
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  name: 'workflow-event',
  mixins: [tableFilterMixin, loading],
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      eid: this.$route.params.eid,
      nodeId: undefined,
      workflowEvent: {
        data: {}
      },
      columns: [
        {
          label: 'Id',
          field: '_id',
          hidden: true
        },
        {
          label: 'Date - Time',
          field: 'datetime',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Event Message',
          field: 'message',
          filterOptions: {
            enabled: false
          }
        }
      ],
      row_data: [],
      serverParams: {
        sort: [{ field: 'datetime', type: 'asc' }]
      }
    }
  },
  computed: {
    rows: function () {
      return this.row_data
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    clickBack () {
      this.$router.push(`/automation/workflow/workflowtelemetry/${this.eid}`)
    },
    async load () {
      this.$snapbar.hide()
      let res
      this.isTableLoading = true
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowEvent/${this.id}`)
        if (res) {
          this.workflowEvent = res.data.workflowEvent
          if (!this.workflowEvent) {
            this.$snapbar.w('Invalid workflow event id')
            this.row_data = []
            return
          }
          this.nodeId = this.workflowEvent.nodeId
          let msgRes
          try {
            msgRes = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowMessage/${this.workflowEvent.nodeId}`)
            if (msgRes) {
              this.row_data = msgRes.data.workflowMessage
            }
          } catch (e) {
            this.$snapbar.e(`Error retrieving workflow event data from PayGate. - ${e.message}`)
            this.row_data = []
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view telemetry data.')
        } else {
          this.$snapbar.e(`Could not get telemetry - ${e.message}`)
        }
        this.row_data = []
      }
    },
    viewTelemetry () {
      this.$router.push(`/automation/workflow/workflowtelemetry/${this.id}`)
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      const ms = this.pad(myDate.getMilliseconds(), 3, '0')
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s + '.' + ms
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    pad (n, width, z) {
      z = z || '0'
      n += ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    },
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
    }
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      await this.load()
    }
  },
  created: async function () {
    console.log('id: ', this.id)
    console.log('eid: ', this.eid)
  }
}
</script>

<style>
  .item-content {
    border-style: solid;
    border-width: 1px;
    border-color: #dddddd;
    padding: 6px;
  }
</style>
