<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Collection Schedule Report</h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group mb-3">
          <div class="col-md-2">
            Date Filter Column:
          </div>
          <div class="col-md-4">
            <b-form-select
              class="form-control"
              :options="filterFields"
              v-model="selectedFilter"
              @input="filtersChanged()"
            />
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Earliest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="endPaymentDatePicker"
              v-model="start"
                          :disabledDates="{ from: new Date(end) }"
              :format="formatDate" @input="filtersChanged()" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Latest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="startPaymentDatePicker"
              v-model="end"
              :format="formatDate" :disabledDates="{ to: new Date(start) }"
              @input="filtersChanged()" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
          class="scroll-table"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
            <p-check
              id="sentCheck"
              class="p-switch p-fill"
              color="primary"
              v-model="nearingCompletion"
              @change="load"
            >Only show schedules due to end soon</p-check>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'recurrenceType'">
              <template v-if="props.row.paymentType === 0 || props.row.paymentType === 4">N/A</template>
              <template v-else>{{props.formattedRow[props.column.field]}}</template>
            </span>
            <span v-else-if="props.column.field === 'amount'">
              <template v-if="props.row.paymentType === 0">N/A</template>
              <template v-else>{{props.formattedRow[props.column.field]}}</template>
            </span>
            <span v-else-if="props.column.field === 'nextPaymentDate'">
              <template v-if="props.row.paymentType === 0">N/A</template>
              <template v-else>{{props.formattedRow[props.column.field]}}</template>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
          <template slot="table-column" slot-scope="props">
            <span class="d-block">
              {{props.column.label}}
           </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import Datepicker from 'vuejs-datepicker'
  import papa from 'papaparse'
  import moment from 'moment'
  import {mapGetters} from "vuex";

  export default {
    mixins: [tableFilterMixin, loading],
    components: {
      Datepicker
    },
    data () {
      return {
        filterFields: [ 'First Collection Date', 'Next Collection Date', 'Creation Date', 'End Date', 'Regular Collection Start Date' ],
        selectedFilter: 'Next Collection Date',
        dateOptions: {
          format: 'DD/MM/YYYY HH:mm',
          useCurrent: false,
          stepping: 30,
          sideBySide: true,
        },
        nearingCompletion: false,
        end: null,
        start: null,
        rows: [],
        columns: [
          {
            label: 'paymentPlanId',
            field: 'paymentPlanId',
            hidden: true
          },
          {
            label: 'ukPayerId',
            field: 'ukPayerId',
            hidden: true
          },
          {
            label: 'Schedule Reference',
            field: 'planReference'
          },
          {
            label: 'Schedule Template Name',
            field: 'scheduleTemplateName'
          },
          {
            label: 'Schedule Type',
            field: 'paymentType',
            formatFn: this.recurrenceEndTypeText
          },
          {
            label: 'Schedule Status',
            field: 'status',
            formatFn: this.planStatusText
          },
          {
            label: 'Customer Reference',
            field: 'payerReference'
          },
          {
            label: 'Customer Name',
            field: 'customerName'
          },
          {
            label: 'Customer Status',
            field: 'payerStatus',
            formatFn: this.payerStatusText
          },
          {
            label: 'Frequency',
            field: 'recurrenceType',
            formatFn: this.recurrenceTypeText
          },
          {
            label: 'Ordinal',
            field: 'ordinal',
            hidden: true
          },
          {
            label: 'First Collection Date',
            field: 'firstPaymentDate',
            formatFn: this.formatDateWithNull
          },
          {
            label: 'First Collection Amount (£)',
            field: 'firstCollectionAmount',
            formatFn: this.formatAmount,
          },
          {
            label: 'Regular Collection Start Date',
            field: 'regularStartDate',
            formatFn: this.formatDateWithNull
          },
          {
            label: 'Regular Collection Amount (£)',
            field: 'regularCollectionAmount',
            formatFn: this.formatAmount,
          },
          {
            label: 'Next Collection Date',
            field: 'nextPaymentDate',
            formatFn: this.formatDateWithNull
          },
          {
            label: 'Next Collection Amount (£)',
            field: 'amount',
            formatFn: this.formatAmount,
            tdClass: 'text-right'
          },
          {
            label: 'Creation Date',
            field: 'agreedDate',
            formatFn: this.formatDateWithNull
          },
          {
            label: 'End Date',
            field: 'untilDate',
            formatFn: this.formatDateWithNull
          }
        ],
        serverParams: {
          sort: [{ field: 'nextPaymentDate', type: 'desc' }]
        }
      }
    },
    mounted () {
      this.end = new Date()
      let tempStart = new Date()
      tempStart.setDate(this.end.getDate() - 1)
      this.start = tempStart
      let tempEnd = new Date(this.end.setMonth(this.end.getMonth()+1));
      this.end = tempEnd
    },
    methods: {
      async filtersChanged () {
        await this.clearTableFilters()
      },
      load: async function () {
        try {
          if (this.end === null) {
            this.end = new Date()
            let tempStart = new Date()
            tempStart.setDate(this.end.getDate() - 1)
            tempStart.setHours(0,0,0,0)
            let tempEnd = new Date(this.end.setMonth(this.end.getMonth()+1));
            tempEnd.setHours(23,59,59,0)
            this.end = tempEnd
          }
          if (this.start === null) {
            let tempStart = new Date()
            tempStart.setDate(this.end.getDate() - 1)
            tempStart.setHours(0,0,0,0)
            this.start = tempStart
          }
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/collectionschedulereport`, { params: { ...this.buildGoodTableQuery(), startDate: this.start, endDate: this.end, nearingCompletion: this.nearingCompletion, filterBy: this.selectedFilter }, validateStatus: () => true, showLoad: true })
          if (response.status === 200) {
            this.rows = response.data.data
            this.totalRecords = response.data.count
          } else {
            if (response.status === 403) {
              this.$toastr.e('Not authorized to use this feature.')
              this.rows = []
              this.totalRecords = 0
            } else {
              this.$toastr.e('An error has occurred.')
            }
          }
        } catch (error) {
          this.$toastr.e(error)
        }
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
      formatStatus (value) {
        let retVal = ''
        switch(value) {
          case 1:
            retVal = 'Created'
            break
          case 2:
            retVal = 'In Progress'
            break
          case 3:
            retVal = 'Resolved'
            break
          case 4:
            retVal = 'Rejected'
            break
          case 5:
            retVal = 'Action Required'
            break
          default:
            retVal = 'None'
            break
        }
        return retVal
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      formatDateWithNull (date) {
        if (date===null || date === '0001-01-01T00:00:00') {
          return 'N/A'
        } else {
          return this.formatDate(date)
        }
      },
      async exportTable () {
        var query = this.buildGoodTableQuery()
        var startDate = moment(this.start).format('DD-MM-YYYY')
        var endDate = moment(this.end).format('DD-MM-YYYY')
        query.perPage = this.totalRecords
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/collectionschedulereport`, { params: {
          startDate: this.start, endDate: this.end,
          ...query, paygateId: this.paygateId, nearingCompletion: this.nearingCompletion, forExport: true, filterBy: this.selectedFilter }, showload: true })
        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, `collections-schedule-report-${startDate}-to-${endDate}.csv`)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = `collections-schedule-report-${startDate}-to-${endDate}.csv`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      codeToDescription (value) {
        let retval = ''
        switch (value) {
          case 1:
            retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
            break;
          case 2:
            retval = 'NO ADVANCE NOTICE WAS RECEIVED';
            break;
          case 3:
            retval = 'BANK CANCELS THE DIRECT DEBIT';
            break;
          case 4:
            retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
            break;
          case 5:
            retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
            break;
          case 6:
            retval = 'FRAUDULENT SIGNATURE';
            break;
          case 7:
            retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
            break;
          case 8:
            retval = 'SERVICE USER IS NOT RECOGNISED';
            break;
          default:
            retval = 'Invalid Code Supplied'
            break;
        }
        return retval
      },
      recurrenceEndTypeText (val) {
        var outText = ''
        switch (val) {
          case 0:
            outText = 'One Off'
            break
          case 1:
            outText = 'Until Date'
            break
          case 2:
            outText = 'Until Number of Collections'
            break;
          case 3:
            outText = 'Ongoing'
            break
          case 4:
            outText = 'Ad Hoc'
            break
        }
        return outText
      },
      planStatusText (val) {
        var outText = ''
        switch (val) {
          case 0:
            outText = 'Closed'
            break
          case 1:
            outText = 'Alert'
            break
          case 2:
            outText = 'Suspended'
            break
          case 3:
            outText = 'Operational'
            break
        }
        return outText
      },
      recurrenceTypeText (val) {
        var outText = ''
        switch (val) {
          case 0:
            outText = 'Weekly'
            break
          case 1:
            outText = 'Fortnightly'
            break
          case 2:
            outText = 'Four Weekly'
            break
          case 3:
            outText = 'Monthly'
            break
          case 4:
            outText = 'Two Monthly'
            break
          case 5:
            outText = 'Quarterly'
            break
          case 6:
            outText = 'Six Monthly'
            break
          case 7:
            outText = 'Yearly'
            break
        }
        return outText
      },
      payerStatusText (x) {
        switch (x) {
          case -1:
            return 'Any'
          case 0:
            return 'Operational'
          case 1:
            return 'Suspended'
          case 2:
            return 'Alert'
          case 3:
            return 'Closed'
          case 4:
            return 'Incomplete'
          case 5:
            return 'Awaiting Activation'
          case 6:
            return 'Plan Suspended'
          case 7:
            return 'Deleted'
          case 8:
            return 'Lodgement Pending'
          default:
            return 'Unknown'
        }
      }
    },
    computed: {
      ...mapGetters(['selectedCustomer']),
      startDateOptions () {
        return { ...this.dateOptions, maxDate: this.end }
      },
      endDateOptions () {
        return { ...this.dateOptions, minDate: this.start }
      },
    },
    watch: {
      selectedCustomer () {
        this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
        this.load()
      }
    }
  }
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
