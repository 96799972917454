<template>
  <div>
    <b-row class="mb-5" v-if="canChallenge">
      <b-col>
        <div ref="notesEditor"></div>
      </b-col>
    </b-row>
    <b-row class="mb-5" v-if="canChallenge">
      <b-col>
        <b-button variant="outline-primary" @click="addNote" :disabled="isEmpty">Add Note</b-button>
      </b-col>
    </b-row>
    <div v-if="rows.length === 0" class="card mb-4">No notes available.</div>
    <div v-for="(row, index) in rows" :key="index" class="card mb-4">
      <div class="card-header">
        <div class="media flex-wrap align-items-center">
          <avatar
            width="30"
            height="30"
            v-b-popover.hover.top.d500="row.userName"
            class="d-block ui-w-40 rounded-circle"
            :user-id="row.userId"
          />
          <div class="media-body ml-3">
            {{row.userName}}
            <div class="text-muted small">{{ formatDate(row.creationDate) }}</div>
          </div>
        </div>
        <div class="card-body" v-html="row.note"></div>
      </div>
    </div>
  </div>
</template>
<script>
// import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { mapGetters } from 'vuex'
import roles from '@/Assets/Constants/roles'
export default {
  props: {
    challengeId: String
  },
  data () {
    return {
      platformApi: process.env.VUE_APP_PLATFORM_API_URL,
      bearerToken: null,
      editor: null,
      rows: [],
      columns: [
        { label: 'User Name', field: 'userName' },
        { label: 'Message', field: 'note', html: true },
        { label: 'Date', field: 'creationDate' }
      ]
    }
  },
  async mounted () {
    // auth.getAccessToken()
    //   .then(token => {
    //     this.bearerToken = 'Bearer ' + token
    //   })
    axios.get(`${process.env.VUE_APP_DDMS_API_URL}indemnity/challenge/${this.challengeId}/notes`).then((response) => { this.rows = response.data }).catch((err) => {
      console.log(err)
      this.$toastr.e('An error occurred loading the indemnity advice notes.')
    })
    if (this.canChallenge) {
      this.editor = new Quill(this.$refs.notesEditor, {
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, false] }, 'tablecontainer'],
              ['bold', 'italic', 'underline', 'strike'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ indent: '-1' }, { indent: '+1' }],
              ['link', 'image'],
              [{ color: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ['clean']
            ],
            table: true,
            handlers: {}
          },
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true
          }
        },
        theme: 'snow'
      })
    }
  },
  methods: {
    async addNote (e) {
      axios.post(`${process.env.VUE_APP_DDMS_API_URL}indemnity/challenge/${this.challengeId}/notes`, { body: this.editor.root.innerHTML }, { params: { paygateid: this.$store.getters.selectedCustomer } })
        .then(async (response) => {
          this.$toastr.s('A note has been added to the challenge record')
          axios.get(`${process.env.VUE_APP_DDMS_API_URL}indemnity/challenge/${this.challengeId}/notes`).then((response) => {
            this.rows = response.data
            this.editor.root.innerHTML = ''
          })
            .catch((err) => {
              console.log(err)
              this.$toastr.e('An error occurred loading the customer notes.')
            })
        })
        .catch((err) => {
          console.log(err)
          this.$toastr.e('An error occurred adding the note.')
        })
    },
    formatDate (datestr) {
      const date = new Date(datestr)
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var h = date.getHours()
      var m = date.getMinutes()
      var s = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year} ${this.pad(h, 2)}:${this.pad(m, 2)}:${this.pad(s, 2)}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    }
  },
  computed: {
    ...mapGetters(['getAccessToken']),
    isEmpty () {
      return this.editor && this.editor.getText().trim().length == 0
    },
    canChallenge () {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.Finance])
    }
  }
}
</script>
