<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        <help-icon docPath="/administration/customer/details/" />Client Emails
      </h2>
    </div>

    <div class="idb-block-content">
      <vue-good-table
        mode="remote"
        :search-options="{
                    enabled: true
                    }"
        :sort-options="{
                enabled: true
              }"
        :pagination-options="{
                    enabled: true,
                    dropdownAllowAll: false,
                    jumpFirstOrLast: true
                  }"
        :isLoading.sync="isTableLoading"
        :totalRows="totalRecords"
        :columns="columns"
        :rows="emailAddresses"
        @on-row-click="onRowClick"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        styleClass="vgt-table striped bordered"
        ref="table"
      >
        <div slot="table-actions">
          <table-actions
            :print-enabled="false"
            :export-enabled="true"
            :reload-table="load"
            :clear-table-filters="clearTableFilters"
            :data-url="dataUrl"
            :export-data="tableServerData"
            :export-name="exportName"
          ></table-actions>
        </div>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'verified'">
            <i class="fa fa-fw" :class="props.row.verified ? 'fa-check' : 'fa-times'"></i>
          </span>
          <span v-else-if="props.column.field == 'buttons'">
            <b-btn
              :disabled="props.row['verified']"
              variant="primary"
              @click.stop="sendVerification(props.row['id'])"
            >Retry Verification</b-btn>&nbsp;
            <b-btn variant="danger" @click.stop="deleteEmail(props.row['id'])">
              <i class="fa fa-trash-alt"></i>
            </b-btn>
          </span>
          <span v-else>{{props.formattedRow[props.column.field]}}</span>
        </template>
      </vue-good-table>
    </div>
    <div class="idb-block-footer">
      <button
        class="btn btn-primary"
        @click.prevent="create"
        :disabled="disabledDueToConnectedMerchant"
      >Add Email</button>
    </div>
    <b-modal
      id="emailAddressModal"
      ref="emailAddressModal"
      title="Email Address"
      size="lg"
      hide-header-close
      cancel-variant="secondary-outline"
      lazy
    >
      <b-row class="mt-2">
        <b-col sm="4" class="mt-2">
          <label class="required" for="emailAddressInput">Email Address</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            id="emailAddressInput"
            type="email"
            v-model.trim="selectedEmailAddress.emailAddress"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col sm="4" class="mt-2">
          <label for="fromNameInput">Email Sender Name</label>
        </b-col>
        <b-col sm="7">
          <b-form-input id="fromNameInput" type="text" v-model.trim="selectedEmailAddress.fromName"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>A verification email will be sent automatically when 'OK' is pressed.</b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

// Third Party
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'

// Validators
import { mapGetters } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'

// Mixins
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import ConnectedMerchantReadOnlyMixin from '@/Assets/Mixins/ConnectedMerchantReadOnlyMixin'

export default {
  mixins: [ConnectedMerchantReadOnlyMixin, tableFilterMixin],
  computed: {
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}emails`
    },
    tableServerData () {
      const query = this.buildGoodTableQuery()
      const paygateId = this.$store.state.common.paygateId
      if (paygateId) {
        query.paygateId = paygateId
        query.includeUnverified = true
      }
      return query
    },
    exportName () {
      return `${moment(this.fromDate).format('DD/MM/YYYY')}-${moment(this.toDate).format('DD/MM/YYYY')}-client-emails-export`
    },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () {
      this.load()
    }
  },
  async mounted () {
    const handleModalHide = async (bvEvent, modalId) => {
      const target = bvEvent.target.id
      switch (target) {
        case 'emailAddressModal':
          if (bvEvent.trigger === 'ok') {
            const isNew = this.selectedEmailAddress.id === null
            if (isNew) {
              const emailAddress = await this.insertEmailAddressFromModal()
              this.emailAddresses.splice(0, 0, emailAddress)
            } else {
              const emailAddress = await this.updateEmailAddressFromModal()
              for (var i = 0; i < this.emailAddresses.length; i++) {
                if (this.emailAddresses[i].id === emailAddress.id) {
                  this.emailAddresses[i].emailAddress = emailAddress.emailAddress
                  this.emailAddresses[i].fromName = emailAddress.fromName
                  this.emailAddresses[i].verified = emailAddress.verified
                }
              }
            }
          }
          break
        default:
          break
      }
    }

    this.$root.$on('bv::modal::hide', handleModalHide)
    this.$once('hook:beforeDestroy', () => {
      this.$root.$off('bv::modal::hide', handleModalHide)
    })
    this.load()
  },
  data () {
    return {
      selectedEmailAddress: {
        emailAddress: '',
        id: null,
        fromName: '',
        verified: false
      },
      emailAddresses: [],
      totalRecords: 0,
      serverParams: {
        sort: [{
          field: 'emailAddress',
          type: 'ASC'
        }]
      },
      columns: [
        {
          hidden: true,
          field: 'id'
        },
        {
          label: 'Email Address',
          field: 'emailAddress'
        },
        {
          label: 'Sender Name',
          field: 'fromName'
        },
        {
          label: 'Verified',
          field: 'verified'
        },
        {
          label: '',
          field: 'buttons',
          sortable: false
        }]
    }
  },
  methods: {
    async create () {
      this.selectedEmailAddress = {
        emailAddress: '',
        id: null,
        fromName: '',
        verified: false
      }
      this.$refs.emailAddressModal.show()
    },
    async insertEmailAddressFromModal () {
      const url = process.env.VUE_APP_PLATFORM_API_URL + 'emails'

      try {
        var response = await axios.post(url, this.selectedEmailAddress)
        this.$toastr.s('Email Address added', 'Success')
        return response.data
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to add email address', 'An error occurred')
        return null
      }
    },
    async updateEmailAddressFromModal () {
      const url = `${process.env.VUE_APP_PLATFORM_API_URL}emails/${this.selectedEmailAddress.id}`
      try {
        var response = await axios.put(url, this.selectedEmailAddress)
        this.$toastr.s('Email address updated', 'Success')
        return response.data
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to update email address', 'An error occurred')
        return null
      }
    },
    sendVerification: _.debounce(async function (id) {
      const url = `${process.env.VUE_APP_PLATFORM_API_URL}emails/${id}/sendverification`
      try {
        var response = await axios.post(url)
        let verified = false
        for (var i = 0; i < this.emailAddresses.length; i++) {
          if (this.emailAddresses[i].id === response.data.id) {
            this.emailAddresses[i].verified = response.data.verified
            verified = response.data.verified
          }
        }
        if (!verified) {
          this.$toastr.s('A verification email has been sent.', 'Success')
        } else {
          this.$toastr.s('Verification has occurred.', 'Success')
        }
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to send verification email', 'An error occurred')
      }
    }, 300),
    deleteEmail: _.debounce(async function (id) {
      const url = `${process.env.VUE_APP_PLATFORM_API_URL}emails/${id}`
      try {
        var response = await axios.delete(url)
        console.log(response.data.id)
        this.emailAddresses = this.emailAddresses.filter(x => x.id !== response.data.id)
        this.$toastr.s('Email has been deleted', 'Success')
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to delete email address', 'An error occurred')
      }
    }, 300),
    load: _.debounce(async function () {
      try {
        this.isTableLoading = true

        const response = await axios.get(this.dataUrl, { params: this.tableServerData })
        this.emailAddresses = response.data.data
        this.totalRows = response.data.totalItems
      } finally {
        this.isTableLoading = false
      }
    }, 300),
    onRowClick (event) {
      this.selectedEmailAddress = Object.assign({}, event.row)
      this.$refs.emailAddressModal.show()
    }
  },
  validations: {
    customer: {
      paygateId: { required, isGuid },
      name: { required, maxLen: maxLength(50) }
    }
  }
}

function isGuid (value) {
  var regex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$')
  var result = regex.test(value)
  return result
}

</script>
