<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        <help-icon docPath="/paygate-collections/messaging/messagetemplates/" />Default Email Designer
      </h2>
    </div>
    <div id="emailEditor" class="idb-block-content" v-if="!previewOpen">
      <b-row>
        <b-col cols="4" md="4">
          <b-form-group>
            <label for="emailTemplateName">
              <strong>Template Name</strong>
            </label>
            <b-form-input
              class
              id="emailTemplateName"
              v-model="templateName"
              :disabled="true"
              type="text"
              autocomplete="off"
              :state="templateNameStateValid && !alreadyExists"
              aria-describedby="emailTemplateName emailTemplateNameFeedback"
              placeholder="Email Template Name"
              maxlength="50" @input="checkTemplateNameExists"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!templateNameStateValid" id="emailTemplateNameFeedback">
              The template name may only be up to 50 characters of the following types:
              <ul>
                <li>Upper-case letters A-Z</li>
                <li>Lower-case letters a-z</li>
                <li>Numbers 0-9</li>
                <li>Spaces, Underscores "_", or dashes "-"</li>
              </ul>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="alreadyExists" id="emailTemplateNameFeedback2">
              There is an Email template by that name already in the system.
            </b-form-invalid-feedback>
            <b-form-text id="smsTemplateNameHelp">The name of the template, as it will be appear to users.</b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col cols="8">
          <b-form-group>
            <label for="emailSubject">Subject</label>
            <b-form-input
              id="emailSubject"
              v-model.trim="messageTitle"
              type="text"
              autocomplete="off"
              :state="emailTitleStateValid"
              aria-describedby="emailSubjectHelp emailSubjectFeedback"
              placeholder="Enter the email subject"
              maxlength="80"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!emailTitleStateValid" id="emailTitleFeedback">
              The Subject must only have:
              <ul>
                <li>Upper-case letters A-Z</li>
                <li>Lower-case letters a-z</li>
                <li>Numbers 0-9</li>
                <li>Spaces, underscores "_" or dashes "-"</li>
                <li>Square Brackets [] for data population.</li>
              </ul>
            </b-form-invalid-feedback>
            <b-form-text id="emailTitleHelp">The subject seen by the email recipient.</b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group>
        <label>Message Body</label>
        <wysiwyg v-model="messageBody" :upload-url="uploadUrl"></wysiwyg>
      </b-form-group>
    </div>
    <b-modal id="preview-modal" size="lg" :title="'Subject: ' + preview.title" :ok-only="true" ok-title="Close">
      <!-- <h4 v-html="preview.title"></h4> -->
      <div v-html="preview.body"></div>
    </b-modal>
    <!-- <div id="emailPreview" class="idb-block-content" v-if="previewOpen">
      <h4 v-html="preview.title"></h4>
      <div v-html="preview.body"></div>
    </div>-->

    <div class="idb-block-footer">
      <b-btn @click.prevent="saveContent" :disabled="isLoading" variant="primary">Save</b-btn>
      <b-btn variant="info" :disabled="isLoading" class="ml-2" @click.prevent="togglePreview">Preview</b-btn>
    </div>
  </div>
</template>
<script>
import Wysiwyg from '@/Components/Collections/EmailWysiwyg.vue'
import EventBus from '@/Lib/eventBus'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import roles from "@/Assets/Constants/roles";
const hasNonAlphaNumOrSpaces = (str) => {
  if (!str) {
    return null
  }
  const nonAlphaNumsOrSpace = /[^A-Za-z0-9 -_+()]/
  return !nonAlphaNumsOrSpace.test(str)
}

export default {
  mixins: [loading],
  props: {
    type: { type: String, default: "-1" }
  },
  data () {
    return {
      previewButtonLabel: 'Preview',
      messageTitle: '',
      messageBody: '',
      loading: !!this.templateid,
      uploadUrl: process.env.VUE_APP_DDMS_API + '/designer/userimages',
      preview: { title: '', body: '' },
      previewOpen: false,
      requiresApproval: false,
      bankApproved: false,
      alreadyExists: false,
      deprecatedId: null,
      lastModified: null
    }
  },
  components: {
    Wysiwyg
  },
  computed: {
    templateName () {
        switch (parseInt(this.type)) {
          case 20:
            return 'Pre-Collection Notification'
          case 19:
            return 'Confirmation Letter'
          case 18:
            return 'Notice of Change (Migration)'
          case 17:
            return 'Refund Cancelled'
          case 16:
            return 'Notice of Change'
          case 15:
            return 'Customer Transferred'
          case 14:
            return 'Customer Closed'
          case 13:
            return 'Custom'
          case 12:
            return 'Cancellation Notice'
          case 11:
            return 'Refund Requested'
          case 10:
            return 'Portal Email Verification'
          case 9:
            return 'Portal Message Sent'
          case 8:
            return 'Email Verification Message'
          case 7:
            return 'Custom Message 5'
          case 6:
            return 'Custom Message 4'
          case 5:
            return 'Custom Message 3'
          case 4:
            return 'Custom Message 2'
          case 3:
            return 'Custom Message 1'
          case 2:
            return 'Multiple Schedule Update'
          case 1:
            return 'First Collections'
          case 0:
            return 'Advance Notice'
          default:
            return 'Unknown'
        }
    },
    templateNameStateValid () {
      return true
    },
    previewDisabled () {
      const messageBodyValid = !!this.messageBody
      return !messageBodyValid || !this.emailTitleStateValid
    },
    emailTitleStateValid () {
      return true
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    canEdit() {
      return this.$store.getters.isInRoles([roles.LZAdmin, roles.Implementations])
    }
  },
  async mounted () {
    this.loadTemplateInfo()
  },
  methods: {
    formatDate (date) {
      try {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      } catch (e) {
        return ''
      }
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    async loadTemplateInfo (templateId) {
      let url = `${process.env.VUE_APP_DDMS_API_URL}defaultmessagedesigner/email/${this.type}`
      const response = await axios.get(url, { showload: true })
      this.messageTitle = response.data.title
      this.messageBody = response.data.messageBody
      this.lastModified = response.data.lastModified
    },
    async saveContent () {
        await this.trySave()
    },
    async trySave () {
      try {
        const body = {
          messageBody: this.messageBody,
          title: this.messageTitle,
          channelType: 0,
          messageType: this.type
        }
        await axios.put(`${process.env.VUE_APP_DDMS_API_URL}defaultmessagedesigner/email/${this.type}`, body, { showload: true, params: { paygateId: this.paygateId } })
        this.$toastr.s('Update successful')
      } catch (e) {
        this.$toastr.e('Unable to save changes to the email template.', 'An Error Occurred')
      }
    },
    async tryCreate (paygateId) {
      try {
        const body = {
          name: this.templateName,
          messageBody: this.messageBody,
          messageTitle: this.messageTitle,
          requireBankApproval: this.requiresApproval
        }
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/email`, body, { showload: true, params: { paygateId: this.paygateId } })
        history.pushState({}, 'paygate Collections', window.location + '/' + response.data.templateId)
        if (this.requiresApproval) {
          this.$toastr.s('Template updated successfully, and may be used once approved.', 'Template Created')
        } else {
          this.$toastr.s('Template updated successfully.', 'Template Created')
        }
        this.$router.push('/collections/message/templates')
      } catch (e) {
        this.$toastr.e('Unable to save changes to the email template.', 'An Error Occurred')
      }
    },
    clearTemplate () {
      this.templateName = ''
      this.messageBody = ''
      this.messageTitle = ''
      this.requiresApproval = false
      this.templateId = null
    },
    async viewPreview () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/email/preview`, {
        'bodyTemplate': this.messageBody,
        'titleTemplate': this.messageTitle
      }, { showload: true, params: { paygateId: this.paygateId } })
      this.preview = response.data
    },
    async togglePreview () {
      this.preview = { bodyTemplate: 'Loading...', titleTemplate: '' }
      await this.viewPreview()
      this.$bvModal.show('preview-modal')
    },
    async checkTemplateNameExists () {
      let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/checkforduplicates`, { channel: 0, templateName: this.templateName }, { showload: true, params: { paygateId: this.paygateId } })
      this.alreadyExists = response.data
    }
  }
}
</script>

<style>
#emailTitleInput {
  min-width: 40em;
  margin-bottom: 2.5em;
}

#preview-modal {
  z-index: 1090 !important;
}

#preview-modal___BV_modal_outer_ {
  z-index: auto !important;
}
</style>
