<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Look Ahead Detail - {{dateString}}</h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group mb-3" v-if="false">
          <div class="col-md-2 required">
            Earliest Collection Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="endPaymentDatePicker"
              v-model="start"
              :format="formatDate" @input="load()" input-class="form-control datepicker" :disabledDates="{ from: new Date(end) }"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <div class="row form-group mb-3" v-if="false">
          <div class="col-md-2 required">
            Latest Collection Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="startPaymentDatePicker"
              v-model="end"
              :format="formatDate" :disabledDates="{ to: new Date(start) }"
              @input="load()" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import Datepicker from 'vuejs-datepicker'
  import papa from 'papaparse'
  import moment from 'moment'
  import {String} from "vuelidate/lib/validators";

  export default {
    mixins: [tableFilterMixin, loading],
    props: {
      groupId: String,
      year: Number,
      month: Number
    },
    components: {
      Datepicker
    },
    data () {
      return {
        dateOptions: {
          format: 'DD/MM/YYYY HH:mm',
          useCurrent: false,
          stepping: 30,
          sideBySide: true,
        },
        end: null,
        start: null,
        rows: [],
        columns: [
          {
            label: 'Customer Reference',
            field: 'payerReference'
          },
          {
            label: 'Customer Name',
            field:  'accountHoldersName'
          },
          {
            label: 'Schedule Reference',
            field: 'planReference'
          },
          {
            label: 'Group',
            field: 'groupName'
          },
          {
            label: 'Collection Date',
            field: 'collectionDate',
            formatFn: this.formatDate
          },
          {
            label: 'Amount',
            field: 'amount',
            formatFn: this.formatAmount
          }
        ],
        serverParams: {
          sort: [{ field: 'collectionDate', type: 'desc' }],
        }
      }
    },
    mounted () {
      this.end = new Date()
      let tempStart = new Date()
      tempStart.setDate(this.end.getDate() - 7)
      this.start = tempStart
      //this.load()
    },
    methods: {
      load: async function () {
        this.isTableLoading = true
        try {
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}reporting/lookaheaddrilldown/${this.groupId}/${this.year}/${this.month}`, { params: { ...this.buildGoodTableQuery() } })
          if (response) {
            this.rows = response.data.data
            this.totalRecords = response.data.count
          }
        } catch (error) {
          this.$toastr.e(error)
        }
        this.isTableLoading = false
      },
      onRowChange (e) {
        console.log(e)
        this.$router.push({ path: `/collections/challenges/${e.row.challengeId}` })
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
      formatStatus (value) {
        let retVal = ''
        switch(value) {
          case 1:
            retVal = 'Created'
            break
          case 2:
            retVal = 'In Progress'
            break
          case 3:
            retVal = 'Resolved'
            break
          case 4:
            retVal = 'Rejected'
            break
          case 5:
            retVal = 'Action Required'
            break
          default:
            retVal = 'None'
            break
        }
        return retVal
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      formatDateWithNull (date) {
        if (date===null || date === '0001-01-01T00:00:00') {
          return 'N/A'
        } else {
          return this.formatDate(date)
        }
      },
      async exportTable () {
        var query = this.buildGoodTableQuery()
        var startDate = moment(this.end).format('DD-MM-YYYY')
        var endDate = moment(this.start).format('DD-MM-YYYY')
        query.perPage = this.totalRecords
        query.forExport = true
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}reporting/lookaheaddrilldown/${this.groupId}/${this.year}/${this.month}`, { params: {
          ...query, paygateId: this.paygateId }, showload: true })
        var csvString = papa.unparse(response.data)
        let groupName = this.groupId === '00000000-0000-0000-0000-000000000000' ? 'all-customers' : response.data[0].groupName
        var blob = new Blob([csvString])
        var filename = `look-ahead-report-${groupName}-${this.pad(this.month,2)}-${this.year}.csv`
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = filename
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      goToSubmission(e) {
        this.$router.push({ name: 'Submission History Item', params: { submissionId: e.row.submissionId } })
      },
      goToSchedule(e) {
        this.$router.push({ name: 'PayerSchedule', params: { id: e.row.ukPayerId, planId: e.row.paymentPlanId } })
      },
      codeToDescription (value) {
        let retval = ''
        switch (value) {
          case 1:
            retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
            break;
          case 2:
            retval = 'NO ADVANCE NOTICE WAS RECEIVED';
            break;
          case 3:
            retval = 'BANK CANCELS THE DIRECT DEBIT';
            break;
          case 4:
            retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
            break;
          case 5:
            retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
            break;
          case 6:
            retval = 'FRAUDULENT SIGNATURE';
            break;
          case 7:
            retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
            break;
          case 8:
            retval = 'SERVICE USER IS NOT RECOGNISED';
            break;
          default:
            retval = 'Invalid Code Supplied'
            break;
        }
        return retval
      }
    },
    computed: {
      startDateOptions () {
        return { ...this.dateOptions, maxDate: this.end }
      },
      endDateOptions () {
        return { ...this.dateOptions, minDate: this.start }
      },
      dateString () {
        let monthStr = ''
          switch (parseInt(this.month)) {
            case 1:
              monthStr = 'January'
              break
            case 2:
              monthStr = 'February'
              break
            case 3:
              monthStr = 'March'
              break
            case 4:
              monthStr = 'April'
              break
            case 5:
              monthStr = 'May'
              break
            case 6:
              monthStr = 'June'
              break
            case 7:
              monthStr = 'July'
              break
            case 8:
              monthStr = 'August'
              break
            case 9:
              monthStr = 'September'
              break
            case 10:
              monthStr = 'October'
              break
            case 11:
              monthStr = 'November'
              break
            case 12:
              monthStr = 'December'
              break
            default:
              monthStr = this.month
              break
          }
          return `${monthStr} ${this.year}`
      }
    }
  }
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
