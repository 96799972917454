<template>
  <span class="pull-right mr-2" v-if="this.docPath">
    <a href="#" @click="click">
      <i class="text-primary far fa-user question d-none"></i>
    </a>
  </span>
</template>

<script>

export default {
  name: 'HelpIcon',
  props: ['docPath'],
  methods: {
    click () {
      window.open(`${process.env.VUE_APP_DOCUMENTATION_ROOT_URL}${this.docPath}`, '_blank')
    }
  }
}
</script>
