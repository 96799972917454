// HTML5 Canvas based Workflow Designer (Blueroom)
// PayGate 3.1.0 (Chorrol)
// Author: Gary Vry

import { vueInstance } from '@/main.js'
import createjs from 'createjs-cmd'
import axios from 'axios'
import _ from 'lodash'
import { getTheme } from './themes.js'
import { createWorkflowNodeProperties } from './nodeDefs'
// import { formatRelativeWithOptions } from 'date-fns/fp'

const base64 = require('base-64')
const utf8 = require('utf8')

// let magneticLine = true
const megneticRadius = 7500
let magneticInputRect
let magneticShape

let canvas
let stage

let selectedObjects = []
let dragMode = false
let dragDeltaX = 0
let dragDeltaY = 0
let itemOffsets = []
let rubberBandRect
let rubberBandShape
let rubberBandOriginX
let rubberBandOriginY
let rubberBandMode = false
let lineDrawingMode = false
let lineOriginX
let lineOriginY
const storedMouseCoords = { x: 0, y: 0 }

// When drawing  a line, this is the destination connector object
let inputConnectorTarget

// When drawing  a line, this is the origin connector object
let outputConnectorOrigin

let dragNodeMode = false
let drawingLine
let drawingLineShape

// The undo / redo stack
let stack = []
let stackPointer = -1
const maxStackLength = 64 // Number of undo steps

// Flag to detect if we need to detect if a workflow is in progress
// eslint-disable-next-line
let canvasIsDirty = false

let originalUnpluggedNode

let theme
const gridSize = 16
const snapToGrid = false
const showGrid = false

// Node Config
const nodeConfig = []
const nodeRadius = 8

const nodeOpacity = 1.0
const connectorSize = 10
const framerate = 60

// Shape Cache - may imprved performance (?)
const enabelShapeCaching = true

let popupTimer
let popupGroup
let popupRect
let popupShape
let popupOriginX
let popupOriginY

// Shadow
const showShadows = true
const showLineShadows = false
const shadowDepthX = 4
const shadowDepthY = 8
const shadowBlur = 12
// initCanvas()
const connectorsToBack = true

const ie11 = !!window.MSInputMethodContext && !!document.documentMode

const detectIE = () => {
  // Detect IE and Edge
  const ua = window.navigator.userAgent

  const msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  // Is the host browser IE
  const trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  // Is the host browser Edge
  const edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  }
  // other browser
  return false
}

const detectIe10OrBelow = () => {
  if (navigator.userAgent.indexOf('MSIE') >= 0) {
    return true
  } else {
    return false
  }
}

if (detectIE() === true) {
  console.log('IE Detected')
  // .. IE Shims here
}

// console.log('ie11: ' + ie11)
// if (ie11) {
//   window.onkeydown = function (e) {
//     console.log('ie11')
//     console.log(e)
//   }
// }

if (detectIe10OrBelow()) {
  console.error('IE10 or below detected.')
  window.location.replace('./incompatible.html')
}

export const initCanvas = () => {
  const setTheme = vueInstance.$store.getters.getClaim('theme').value
  if (setTheme) {
    theme = getTheme('dark')
  } else {
    theme = getTheme('light')
  }

  canvas = document.getElementById('image-canvas')
  canvas.objectType = 'Canvas'
  // Prevent Right-Click context menu
  canvas.oncontextmenu = e => e.preventDefault()

  stage = new createjs.Stage(canvas)
  stage.enableMouseOver(20)
  stage.mouseMoveOutside = true
  stage.width = 200

  stage.on('stagemouseup', function (evt) {
    if (evt.nativeEvent.button === 0) {
      if (rubberBandMode) {
        unSelectAll()
        const adjustedX = evt.stageX - dragDeltaX
        const adjustedY = evt.stageY - dragDeltaY
        // Select everything inside the band
        rubberBandMode = false
        stage.removeChild(rubberBandShape)
        selectEnclosedShapes(rubberBandOriginX, rubberBandOriginY, adjustedX, adjustedY)
      }
    }
    dragMode = false
    rubberBandMode = false
    document.body.style.cursor = 'default'
  })

  stage.on('stagemousemove', evt => {
    if (rubberBandMode) {
      dragMode = false
      stage.removeChild(rubberBandShape)
      rubberBandRect = new createjs.Graphics()
      const adjustedX = evt.stageX - dragDeltaX
      const adjustedY = evt.stageY - dragDeltaY
      rubberBandRect.beginFill('#44a7ff').drawRect(rubberBandOriginX, rubberBandOriginY, adjustedX - rubberBandOriginX, adjustedY - rubberBandOriginY)
      rubberBandShape = new createjs.Shape(rubberBandRect)
      rubberBandShape.objectType = 'rubberBand'
      rubberBandShape.alpha = 0.3
      stage.addChild(rubberBandShape)
    }
    if (dragMode) {
      document.body.style.cursor = 'move'
      rubberBandMode = false
      dragDeltaX = stage.mouseX - storedMouseCoords.x
      dragDeltaY = stage.mouseY - storedMouseCoords.y
      stage.x = dragDeltaX
      stage.y = dragDeltaY
    }
  })

  stage.on('stagemousedown', evt => {
    // Mouse Click for context menu
    // Value of evt.nativeEvent.button
    // 0 LH button
    // 1 middle button
    // 2 RH button

    if (evt.nativeEvent.button === 0) {
      const shape = getObjectUnderMouse(false)
      if (shape === null) {
        const adjustedX = evt.stageX - dragDeltaX
        const adjustedY = evt.stageY - dragDeltaY
        rubberBandMode = true
        rubberBandOriginX = adjustedX
        rubberBandOriginY = adjustedY
        stage.removeChild(rubberBandShape)
        rubberBandRect = new createjs.Graphics()
        rubberBandRect.beginFill('#44a7ff').drawRect(rubberBandOriginX, rubberBandOriginY, 1, 1)
        rubberBandShape = new createjs.Shape(rubberBandRect)
        rubberBandShape.objectType = 'rubberBand'
        rubberBandShape.alpha = 0.3
        stage.addChild(rubberBandShape)
      }
      dragMode = false
    }
    if (evt.nativeEvent.button === 2) {
      // Store the co-ordinates of the mouse pointer
      // will be used to paste any new nodes
      storedMouseCoords.x = stage.mouseX - dragDeltaX
      storedMouseCoords.y = stage.mouseY - dragDeltaY
      dragMode = true
      rubberBandMode = false
    }
  })
  // console.log('ie11: ' + ie11)
  // Keypress Event
  if (ie11) {
    // FIXME:  not working - IE11 keyboard events not firing
    console.log('IE11 Specific key handling')
    window.onkeydown = function (e) {
      console.log('key')
    }
  } else {
    // console.log('Non-IE11 key handling')
    window.onkeydown = keyPressed
  }

  // stage = new createjs.Stage(canvas)

  canvas.style.backgroundColor = theme.canvasColour
  renderGrid(1)

  drawingLine = new createjs.Graphics().beginStroke(theme.connectorLineColour).moveTo(0, 0).bezierCurveTo(0, 0, 0, 0, 0, 0)
  drawingLineShape = new createjs.Shape(drawingLine)
  stage.addChild(drawingLineShape)
  stage.update()

  // Framerate ticker
  createjs.Ticker.addEventListener('tick', tick)
  createjs.Ticker.framerate = framerate
  initWorkflow()
}

export function initWorkflow () {
  showStackStats()
  addToStack()
}

export function keyPressed (evt) {
  // General canvas keydown event
  if (evt) {
    if (evt.code === 'Delete') {
      removeSelected()
    }
    if (evt.code === 'Escape') {
      unSelectAll()
    }
    if (evt.code === 'KeyA' && evt.ctrlKey) {
      selectAll()
      // Prevent browser from selecting non-canvas stuff
      return false
    }
    if (evt.code === 'KeyX' && evt.ctrlKey) {
      cutClipboard()
      return false
    }
    if (evt.code === 'KeyC' && evt.ctrlKey) {
      copyClipboard()
      return false
    }
    if (evt.code === 'KeyV' && evt.ctrlKey) {
      pasteClipBoard()
      return false
    }
    if (evt.code === 'KeyZ' && evt.ctrlKey) {
      undo()
      return false
    }
    if (evt.code === 'KeyY' && evt.ctrlKey) {
      redo()
      return false
    }
  }
}

export const destroyClipboard = () => {
  // Remove the keyboard events that designer uses for clipboard - otherwise ctrl-c, etc
  // won't work on any other page during the session.
  window.onkeydown = null
}

export const moveToOrigin = () => {
  // MOves the diagram viewport back to 0,0
  stage.x = 0
  stage.y = 0
  dragDeltaX = 0
  dragDeltaY = 0
  stage.update()
}

export const clearCanvas = () => {
  // Clears the current canvas of all children
  addToStack()
  nodeConfig.length = 0
  stage.removeChild(magneticShape)
  // let fadeTime = 250
  for (let i = stage.numChildren - 1; i >= 0; i--) {
    const child = stage.getChildAt(i)
    stage.removeChild(child)
  }
  addToStack()
  vueInstance.$store.commit('setIsWorkflowDirty', true)
  renderGrid(1)
}

export const createNode = (x, y, nodeType, nodeId, workflow, nodeConf, extra) => {
  // This is not a brand new node, it is being recreated from say a saved workflow.
  // This is important because if it is being recreated then a lot of id's need to be reused and not recreated,

  vueInstance.$store.commit('setIsWorkflowDirty', true)
  let newNode = false
  if (nodeId === undefined) {
    nodeId = createGuid()
    newNode = true
  }

  const nodeDef = createWorkflowNodeProperties(nodeType)
  let props = {}

  // if (nodeType === 'fileSplitter') {
  // rules are a special case in that the number of outputs is dynamic.
  // the number of outputs is encoded in n2 in the rules node object.
  // When a rules is loaded as part of a workflow, extra is not passed into this function
  // and will be undefined - this is by design and just means we need to used the encoded n2 value.
  // When a rules is first created in the designer, extra is set and that value is used.
  // if (extra === undefined) {
  //   extra = nodeConf.NodeCfg.nodeConfig.n2
  // }
  // The rule node has a user definable number of outputs
  // console.log('rule!!' + extra)
  //   nodeDef.nodeConfig.n2 = extra
  // }

  if (nodeConf !== undefined) {
    // Existing node - existing props
    props = nodeConf.NodeCfg.props
  } else {
    // New node - blank props
    props = nodeDef.props
    // Sometimes, extra information is passed in via the vue part ofthe designer.
    if (nodeType === 'mapping' || nodeType === 'workflow') {
      // extra is the id of the mapping
      props.s1.value = extra
    }
  }

  canvasIsDirty = true

  if (newNode === true) {
  }

  if (snapToGrid) {
    x = allignToGrid(x)
    y = allignToGrid(y)
  }

  nodeDef.id = nodeId

  if (props) {
    // Attach any props the node already has - say when it is loaded in
    nodeDef.props = props
  }

  nodeConfig.push(nodeDef)

  // Dimensions of the node
  const width = 115
  const headerHeight = 24
  const footerHeight = 20

  // Calculate the height of the node
  const maxSections = Math.max(nodeDef.nodeConfig.n1, nodeDef.nodeConfig.n2)
  const height = (30 * (1 + maxSections)) + 8 + footerHeight

  // Group
  const group = new createjs.Container()
  stage.addChild(group)
  group.objectType = 'nodeGroup'
  group.nodeId = nodeId
  group.nodecfg = nodeDef
  vueInstance.$store.commit('setSelectedNode', nodeDef)

  // Main Rect
  const mainRect = new createjs.Graphics()
  mainRect.beginFill(theme.nodeColour)
  mainRect.drawRoundRect(x, y, width, height, nodeRadius)
  const mainRectShape = new createjs.Shape(mainRect)
  mainRectShape.objectType = 'nodeBody'
  mainRectShape.id = nodeId
  mainRectShape.alpha = nodeOpacity
  group.addChild(mainRectShape)

  // Shadow
  if (showShadows) mainRectShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepthX, shadowDepthY, shadowBlur)

  // Header
  const headerRect = new createjs.Graphics()
  headerRect.beginFill(nodeDef.nodeConfig.headerBackColour)
  headerRect.drawRoundRectComplex(x, y, width, headerHeight, nodeRadius, nodeRadius, 0, 0)
  const headerRectShape = new createjs.Shape(headerRect)
  group.addChild(headerRectShape)
  headerRectShape.cursor = 'move'
  headerRectShape.objectType = 'nodeHeader'
  headerRectShape.id = nodeId
  group.headerBackColour = nodeDef.nodeConfig.headerBackColour

  // Click is like mouseup - it triggers when you let go of LMB
  headerRectShape.on('click', evt => {
    clearTimeout(popupTimer)
    stage.removeChild(popupGroup)
    if (evt.nativeEvent.ctrlKey) {
      // Is not already selected.  If so we want to remove it.
      let isSelected = false
      for (const g of selectedObjects) {
        if (g.nodeId === group.nodeId) isSelected = true
      }
      if (!isSelected) {
        addNodeToSelected(group, nodeDef.nodeConfig.headerBackColour)
      } else {
        const i = selectedObjects.map(function (e) { return e.nodeId }).indexOf(group.nodeId)
        selectedObjects.splice(i, 1)
      }
    } else {
      if (selectedObjects.length < 2) {
        selectedObjects = []
      }
      addNodeToSelected(group, nodeDef.nodeConfig.headerBackColour)
    }
  })

  // Drag Bar
  headerRectShape.on('mousedown', evt => {
    if (evt.nativeEvent.ctrlKey) {
    } else {
      if (selectedObjects.length < 2) {
        selectedObjects = []
      }
    }

    dragMode = false
    rubberBandMode = false
    const adjustedX = evt.stageX - dragDeltaX
    const adjustedY = evt.stageY - dragDeltaY
    group.offset = { x: group.x - adjustedX, y: group.y - adjustedY }
    bringToFront(group)

    // Add offsets
    itemOffsets = []
    for (const g of selectedObjects) {
      if (g.type === 'node') {
        const o = getObjectWithId(g.nodeId)
        const p = o.parent
        itemOffsets.push(
          {
            id: g.nodeId,
            item: g,
            itemParent: p,
            offset: new createjs.Point(p.x - group.x, p.y - group.y)
          }
        )
      }
    }
  })

  headerRectShape.on('pressmove', evt => {
    // Is the node already selected?
    // A some nodes are selected and a user press moves
    // an unselected node then everything should be de-selected
    clearTimeout(popupTimer)
    stage.removeChild(popupGroup)
    let isSelected = false
    for (const g of selectedObjects) {
      if (g.nodeId === group.nodeId) isSelected = true
    }
    if (!isSelected) selectedObjects = []

    const adjustedX = evt.stageX - dragDeltaX
    const adjustedY = evt.stageY - dragDeltaY
    group.x = adjustedX + group.offset.x
    group.y = adjustedY + group.offset.y

    dragNodeMode = true
    addNodeToSelected(group, nodeDef.nodeConfig.headerBackColour)

    for (const g of selectedObjects) {
      if (g.type === 'node') {
        if (g.nodeId !== group.nodeId) {
          const o = getObjectWithId(g.nodeId)
          if (o && o.parent) {
            const p = o.parent
            for (const offset of itemOffsets) {
              if (g.nodeId === offset.id) {
                p.x = group.x + offset.offset.x
                p.y = group.y + offset.offset.y
              }
            }
          }
        }
      }
    }
  })

  headerRectShape.on('pressup', evt => {
    clearTimeout(popupTimer)
    stage.removeChild(popupGroup)
    vueInstance.$store.commit('setSelectedNode', nodeDef)
    vueInstance.$store.commit('setIsWorkflowDirty', true)
    vueInstance.$store.commit('setSaveWorkflow', true)
    const adjustedX = evt.stageX - dragDeltaX
    const adjustedY = evt.stageY - dragDeltaY

    if (connectorsToBack) sendAllConnectorsToBack()

    dragNodeMode = false
    if (snapToGrid) {
      const actualX = adjustedX + group.offset.x
      const xDif = actualX % gridSize
      let xDelta = 0
      if (xDif !== 0) {
        if (xDif > (gridSize / 2)) {
          xDelta = Math.abs(xDif - gridSize)
        } else {
          xDelta = xDif * -1
        }
      }

      // snap y - Find the y distance from the nearest snap point: yDelta
      const actualY = adjustedY + group.offset.y
      const yDif = actualY % gridSize
      let yDelta = 0
      if (yDif !== 0) {
        if (yDif > (gridSize / 2)) {
          yDelta = Math.abs(yDif - gridSize)
        } else {
          yDelta = yDif * -1
        }
      }

      // Calculate the transform needed between the node's current position and the nearest snap point.
      const dx = actualX + xDelta
      const dy = actualY + yDelta

      createjs.Tween.get(group).to({ x: dx, y: dy }, 300, createjs.Ease.exponentialOut)
        .call(function () {
          repositionAllLineConnectors()
          drawSelectionBoxes()
          addToStack()
        }
        )
    } else {
      addToStack()
    }
  })

  headerRectShape.on('mouseover', evt => {
    createjs.Tween.get(evt.target.parent).to({ alpha: 0.85 }, 150, createjs.Ease.exponentialOut)
    popupTimer = setTimeout(() => {
      popupGroup = new createjs.Container()
      stage.addChild(popupGroup)
      popupGroup.objectType = 'popupGroup'

      const popupWidth = 400
      const popupHeight = 32 + (Object.keys(nodeDef.props).length * 32) + 16
      const adjustedX = evt.stageX - dragDeltaX
      const adjustedY = evt.stageY - dragDeltaY
      popupOriginX = adjustedX
      popupOriginY = adjustedY
      if (popupOriginX + popupWidth > canvas.width) popupOriginX -= popupWidth

      stage.removeChild(popupShape)
      popupRect = new createjs.Graphics()
      popupRect.beginFill(theme.popupBackColour).drawRect(popupOriginX, popupOriginY, popupWidth, popupHeight)
      popupShape = new createjs.Shape(popupRect)
      popupShape.objectType = 'popup'
      popupShape.alpha = 0.95
      popupGroup.addChild(popupShape)
      if (showShadows) popupShape.shadow = new createjs.Shadow(theme.popupShadowColour, 0, 0, 14)

      // Header
      const popupHdrRect = new createjs.Graphics()
      popupHdrRect.beginFill(nodeDef.nodeConfig.headerBackColour).drawRect(popupOriginX, popupOriginY, popupWidth, 24)
      const popupHdrRectShape = new createjs.Shape(popupHdrRect)
      popupHdrRectShape.objectType = 'popupHdr'
      popupHdrRectShape.alpha = 0.8
      popupGroup.addChild(popupHdrRectShape)

      const popupHdrText = new createjs.Text(nodeDef.title, '12px Arial', theme.popupHeaderForeColour)
      popupHdrText.textAlign = 'center'
      popupHdrText.x = popupOriginX + (popupWidth / 2)
      popupHdrText.y = popupOriginY + 6
      popupHdrText.objectType = 'popupHdrText'
      popupGroup.addChild(popupHdrText)

      // Values
      const props = nodeDef.props
      let propPosY = 40
      Object.keys(props).forEach(p => {
        const itemTitle = props[p].title
        const propTitleText = new createjs.Text(truncateText(itemTitle, 20), '12px Arial', theme.popupForeColour)
        propTitleText.textAlign = 'left'
        propTitleText.x = popupOriginX + 16
        propTitleText.y = popupOriginY + propPosY
        popupGroup.addChild(propTitleText)

        let itemValue = props[p].value

        if (itemTitle === 'Selected Group' || itemTitle === 'Group') {
          const yp1 = propPosY
          propPosY += 32
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}/Groups/${itemValue}`)
            .then(res => {
              if (res && res.data && res.data.name) {
                itemValue = res.data.name
                const propValueText = new createjs.Text(truncateText(itemValue), '12px Arial', theme.popupForeValueColour)
                propValueText.textAlign = 'left'
                propValueText.x = popupOriginX + popupWidth / 2.5
                propValueText.y = popupOriginY + yp1
                popupGroup.addChild(propValueText)
              }
            })
        } else if (itemTitle === 'Selected User' || itemTitle === 'User') {
          const yp1 = propPosY
          propPosY += 32
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}/Users/${itemValue}`)
            .then(res => {
              if (res && res.data && res.data.name) {
                itemValue = `${res.data.email} (${res.data.name})`
                const propValueText = new createjs.Text(truncateText(itemValue), '12px Arial', theme.popupForeValueColour)
                propValueText.textAlign = 'left'
                propValueText.x = popupOriginX + popupWidth / 2.5
                propValueText.y = popupOriginY + yp1
                popupGroup.addChild(propValueText)
              }
            })
        } else if (itemTitle === 'Mapping') {
          const yp2 = propPosY
          propPosY += 32
          axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/${itemValue}`)
            .then(res => {
              if (res && res.data && res.data.mapping.title) {
                itemValue = res.data.mapping.title
                const propValueText = new createjs.Text(truncateText(itemValue), '12px Arial', theme.popupForeValueColour)
                propValueText.textAlign = 'left'
                propValueText.x = popupOriginX + popupWidth / 2.5
                propValueText.y = popupOriginY + yp2
                popupGroup.addChild(propValueText)
              }
            })
        } else if (itemTitle === 'Workflow') {
          const yp2 = propPosY
          propPosY += 32
          axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflow/${itemValue}`)
            .then(res => {
              if (res && res.data && res.data.workflow.title) {
                itemValue = res.data.workflow.title
                const propValueText = new createjs.Text(truncateText(itemValue), '12px Arial', theme.popupForeValueColour)
                propValueText.textAlign = 'left'
                propValueText.x = popupOriginX + popupWidth / 2.5
                propValueText.y = popupOriginY + yp2
                popupGroup.addChild(propValueText)
              }
            })
        } else {
          if (itemValue === true) itemValue = 'Yes'
          if (itemValue === false) itemValue = 'No'
          const propValueText = new createjs.Text(truncateText(itemValue), '12px Arial', theme.popupForeValueColour)
          propValueText.textAlign = 'left'
          propValueText.x = popupOriginX + popupWidth / 2.5
          propValueText.y = popupOriginY + propPosY
          popupGroup.addChild(propValueText)
          propPosY += 32
        }
      })
    }, 500)
  })
  headerRectShape.on('mouseout', evt => {
    createjs.Tween.get(evt.target.parent).to({ alpha: 1 }, 300, createjs.Ease.exponentialOut)
    clearTimeout(popupTimer)
    stage.removeChild(popupGroup)
  })

  // Header Text
  const text = new createjs.Text(nodeDef.title, '12px Arial', theme.headerLabelColour)
  text.textAlign = 'center'
  text.x = x + (width / 2)
  text.y = y + 4
  text.objectType = 'headerText'
  group.addChild(text)

  // Footer
  const footerRect = new createjs.Graphics()
  footerRect.beginFill(theme.nodeFooterBackColour)
  footerRect.drawRoundRectComplex(x, y + height - footerHeight, width, headerHeight, 0, 0, nodeRadius, nodeRadius)
  const footerRectShape = new createjs.Shape(footerRect)
  footerRectShape.objectType = 'NodeFooter'
  group.addChild(footerRectShape)

  // ID Icon
  const idIcon = new createjs.Text(nodeDef.nodeConfig.icon, '900 10px \'Font Awesome 5 Free\'', theme.headerLabelColour)
  idIcon.textAlign = 'left'
  idIcon.x = x + 6
  idIcon.y = y + 5
  idIcon.objectType = 'idIcon'
  group.addChild(idIcon)

  const cfgIcon = new createjs.Text('\uf013', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
  cfgIcon.textAlign = 'right'
  cfgIcon.x = x + width - 6
  cfgIcon.y = y + height - 15
  cfgIcon.objectType = 'cfgIcon'
  group.addChild(cfgIcon)

  const cfgIconRect = new createjs.Graphics()
  cfgIconRect.beginFill('#ffffff')
  cfgIconRect.drawRect(x + width - 17, y + height - 15, 13, 13)
  const cfgIconRectShape = new createjs.Shape(cfgIconRect)
  cfgIconRectShape.alpha = 0.01
  group.addChild(cfgIconRectShape)
  cfgIconRectShape.cursor = 'pointer'
  cfgIconRectShape.objectType = 'cfgIconRectShape'
  cfgIconRectShape.on('mousedown', () => {
    dragMode = false
    rubberBandMode = false
    // Send the selected node to the vuex state: selectedNode
    vueInstance.$store.commit('setSelectedNode', nodeDef)
  })
  cfgIconRectShape.on('pressup', () => {
    // console.log('cfgIconRectShape')
    // Remove the clipboard keyboard event handlers
    destroyClipboard()
    // Set a flag in vuex that will cause the vue client to open the editor popup.
    vueInstance.$store.commit('setPopupState', true)
  })

  if (nodeType === 'mapping' || nodeType === 'workflow') {
    const editIcon = new createjs.Text('\uf044', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
    editIcon.textAlign = 'right'
    editIcon.x = x + 21
    editIcon.y = y + height - 15
    editIcon.objectType = 'editIcon'
    group.addChild(editIcon)

    const editIconRect = new createjs.Graphics()
    editIconRect.beginFill('#ffffff')
    editIconRect.drawRect(x + 6, y + height - 15, 13, 13)
    const editIconRectShape = new createjs.Shape(editIconRect)
    editIconRectShape.alpha = 0.01
    group.addChild(editIconRectShape)
    editIconRectShape.cursor = 'pointer'
    editIconRectShape.objectType = 'editIconRectShape'

    // nodeConf is undefined for a brand new (unsaved) mapping/workflow node
    // but 'extra' contains the id we need
    let url = ''
    if (nodeConf) {
      url = `/automation/${nodeType}/designer/${nodeConf.NodeCfg.props.s1.value}`
    } else {
      url = `/automation/${nodeType}/designer/${extra}`
    }

    editIconRectShape.on('pressup', function (evt) {
      // Two ways to route
      // 1) Open the new sub diagram in the same tag - preserve SPA
      // vueInstance.$router.push(url)

      // 2) Open the sub document in a new tab - break SPA - May be blocked by popup bloockers.
      const routeData = vueInstance.$router.resolve(url)
      window.open(routeData.href, '_blank')
    })
  }

  const helpIcon = new createjs.Text('\uf059', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
  helpIcon.textAlign = 'right'
  helpIcon.x = x + width - 24
  helpIcon.y = y + height - 15
  helpIcon.objectType = 'helpIcon'
  group.addChild(helpIcon)

  const helpIconRect = new createjs.Graphics()
  helpIconRect.beginFill('#ffffff')
  helpIconRect.drawRect(x + width - 37, y + height - 15, 13, 13)
  const helpIconRectShape = new createjs.Shape(helpIconRect)
  helpIconRectShape.alpha = 0.01
  group.addChild(helpIconRectShape)
  helpIconRectShape.cursor = 'help'
  helpIconRectShape.objectType = 'helpIconRectShape'
  helpIconRectShape.on('mousedown', function (evt) {
    dragMode = false
    const url = process.env.VUE_APP_DOCUMENTATION_ROOT_URL.concat(nodeDef.nodeConfig.htmlLink)
    window.open(url, '_blank')
  })

  // Draw the input connectors
  let ypos = y + 45
  let p = 0
  for (let yp = 0; yp < nodeDef.nodeConfig.n1; yp++) {
    // Connectors
    const ipcnt = new createjs.Graphics()
    ipcnt.beginStroke(createjs.Graphics.getRGB(0, 0, 0))
    ipcnt.setStrokeStyle(1)
    ipcnt.beginFill('#eeeeee')

    ipcnt.drawRect(x, ypos, connectorSize, connectorSize)
    const ipcntShape = new createjs.Shape(ipcnt)

    ipcntShape.cursor = 'crosshair'
    group.addChild(ipcntShape)

    ipcntShape.on('mouseover', function (evt) {
      inputConnectorTarget = ipcntShape
    })

    ipcntShape.on('mouseout', function (evt) {
      inputConnectorTarget = null
    })

    ipcntShape.on('pressmove', function (evt) {
      if (lineDrawingMode) {
        const adjustedX = evt.stageX - dragDeltaX
        const adjustedY = evt.stageY - dragDeltaY
        stage.removeChild(drawingLineShape)
        stage.removeChild(magneticShape)
        const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
        // Create a new line.  A new line is created wvery mouse move
        drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
        drawingLineShape = new createjs.Shape(drawingLine)
        bringToFront(drawingLineShape)
        if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepthX, shadowDepthY, shadowBlur)
        getClosestInput(evt)
        stage.addChild(drawingLineShape)
      }
    })

    ipcntShape.on('mousedown', evt => {
      // Get a list of lines connected to this connector
      const links = []
      let op, ip
      let pickedLine
      const adjustedX = evt.stageX - dragDeltaX
      const adjustedY = evt.stageY - dragDeltaY

      for (let i = stage.numChildren - 1; i >= 0; i--) {
        const child = stage.getChildAt(i)
        if (child.objectType === 'lineConnector') {
          if (child.data.TargetParent) {
            if (child.data.TargetParent.id === evt.target.id) {
              pickedLine = child
              op = child.data.OriginParent
              ip = child.data.TargetParent
              links.push(child.data.Id)
            }
          }
        }
      }

      // Handle differently depending on number of
      // links connected to a connection point
      if (links.length === 0) {
      } else if (links.length === 1) {
        // Set up the drawing line
        outputConnectorOrigin = op
        originalUnpluggedNode = ip
        lineOriginX = getObjectX(op)
        lineOriginY = getObjectY(op)
        lineDrawingMode = true

        // Draw a line to stop a visual glitch
        const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
        drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
        drawingLineShape = new createjs.Shape(drawingLine)
        bringToFront(drawingLineShape)
        if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepthX, shadowDepthY, shadowBlur)
        stage.addChild(drawingLineShape)

        // Remove old line(s)
        removeShadowLine(pickedLine.data.Id)
        stage.removeChild(pickedLine)
      } else {
        // more than one link.  Need to determine which link
        // to remove

        // Selected link has priority.
        // Is one of the links the selected link
        // console.log('More than 1 Links')

        // Are there selected lines?
        const selectedLines = selectedObjects.filter((obj) => obj.type === 'line').length
        if (selectedLines === 1) {
          for (const item of selectedObjects) {
            if (_.includes(links, item.nodeId)) {
              for (let i = stage.numChildren - 1; i >= 0; i--) {
                const child = stage.getChildAt(i)
                if (child.objectType === 'lineConnector') {
                  if (child.data.Id === item.nodeId) {
                    pickedLine = child
                    outputConnectorOrigin = child.data.OriginParent
                    originalUnpluggedNode = child.data.TargetParent
                    lineOriginX = getObjectX(outputConnectorOrigin)
                    lineOriginY = getObjectY(outputConnectorOrigin)
                    lineDrawingMode = true

                    // Draw a line to stop a visual glitch
                    const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
                    drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
                    drawingLineShape = new createjs.Shape(drawingLine)
                    bringToFront(drawingLineShape)
                    if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepthX, shadowDepthY, shadowBlur)
                    stage.addChild(drawingLineShape)

                    // Remove old line(s)
                    removeShadowLine(pickedLine.data.Id)
                    stage.removeChild(pickedLine)
                  }
                }
              }
            } else {
              unselectAllLineConnectors()
              // Just pick the first connector in the array
              for (let i = stage.numChildren - 1; i >= 0; i--) {
                const child = stage.getChildAt(i)
                if (child.objectType === 'lineConnector') {
                  if (child.data.Id === _.head(links)) {
                    pickedLine = child
                    outputConnectorOrigin = child.data.OriginParent
                    originalUnpluggedNode = child.data.TargetParent
                    lineOriginX = getObjectX(outputConnectorOrigin)
                    lineOriginY = getObjectY(outputConnectorOrigin)
                    lineDrawingMode = true

                    // Draw a line to stop a visual glitch
                    const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
                    drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
                    drawingLineShape = new createjs.Shape(drawingLine)
                    bringToFront(drawingLineShape)
                    if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepthX, shadowDepthY, shadowBlur)
                    stage.addChild(drawingLineShape)

                    // Remove old line(s)
                    removeShadowLine(pickedLine.data.Id)
                    stage.removeChild(pickedLine)
                  }
                }
              }
            }
          }
        } else if (selectedLines === 0) {
          for (let i = stage.numChildren - 1; i >= 0; i--) {
            const child = stage.getChildAt(i)
            if (child.objectType === 'lineConnector') {
              if (child.data.Id === _.head(links)) {
                pickedLine = child
                outputConnectorOrigin = child.data.OriginParent
                originalUnpluggedNode = child.data.TargetParent
                lineOriginX = getObjectX(outputConnectorOrigin)
                lineOriginY = getObjectY(outputConnectorOrigin)
                lineDrawingMode = true

                // Draw a line to stop a visual glitch
                const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
                drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
                drawingLineShape = new createjs.Shape(drawingLine)
                bringToFront(drawingLineShape)
                if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepthX, shadowDepthY, shadowBlur)
                stage.addChild(drawingLineShape)

                // Remove old line(s)
                removeShadowLine(pickedLine.data.Id)
                stage.removeChild(pickedLine)
              }
            }
          }
        }
      }
    })

    ipcntShape.on('pressup', evt => {
      vueInstance.$store.commit('setSaveWorkflow', true)
      if (lineDrawingMode) {
        lineDrawingMode = false
        stage.removeChild(drawingLineShape)
        stage.removeChild(magneticShape)

        // Find Closest input connector
        const closestInput = getClosestInput(evt)
        if (closestInput !== undefined) {
          inputConnectorTarget = getObjectWithId(closestInput.id)
          drawLineConnector(outputConnectorOrigin, inputConnectorTarget, false)
        } else {
          drawLineConnector(outputConnectorOrigin, originalUnpluggedNode, false)
        }
        inputConnectorTarget = undefined
        addToStack()
      } else {
        lineDrawingMode = false
      }
    })

    ipcntShape.objectType = 'nodeInput'

    if (newNode) {
      ipcntShape.id = createGuid()
    } else {
      for (let ii = 0; ii < workflow.inputs.length; ii++) {
        if (workflow.inputs[ii].Row === p && workflow.inputs[ii].ParentId === nodeId) {
          ipcntShape.id = workflow.inputs[ii].Id
        }
      }
    }

    ipcntShape.parentId = nodeId
    ipcntShape.row = yp

    // Label
    const ipcntText = new createjs.Text(nodeDef.nodeConfig.lLabels[p++], '12px Arial', theme.bodyLabelColour)
    ipcntText.textAlign = 'left'
    ipcntText.x = x + connectorSize + 4
    ipcntText.y = ypos - 2
    ipcntText.objectType = 'label'
    group.addChild(ipcntText)
    ypos += 30
  }

  // Draw the output connectors
  p = 0
  ypos = y + 45
  for (let yp = 0; yp < nodeDef.nodeConfig.n2; yp++) {
    // Connectors
    const opcnt = new createjs.Graphics()
    opcnt.beginStroke(createjs.Graphics.getRGB(0, 0, 0))
    opcnt.setStrokeStyle(1)
    opcnt.beginFill('#eeeeee')
    opcnt.drawRect(x + width - connectorSize, ypos, connectorSize, connectorSize)
    const opcntShape = new createjs.Shape(opcnt)
    opcntShape.cursor = 'crosshair'
    group.addChild(opcntShape)

    opcntShape.on('mousedown', function (evt) {
      // Store the co-ordinates of where the mouse down happened. This is the origin of the new line.
      outputConnectorOrigin = evt.target
      lineOriginX = getObjectX(outputConnectorOrigin)
      lineOriginY = getObjectY(outputConnectorOrigin)
      lineDrawingMode = true
    })

    // Metadata
    opcntShape.objectType = 'nodeOutput'

    if (newNode) {
      opcntShape.id = createGuid()
    } else {
      for (let ii = 0; ii < workflow.outputs.length; ii++) {
        if (workflow.outputs[ii].Row === p && workflow.outputs[ii].ParentId === nodeId) {
          opcntShape.id = workflow.outputs[ii].Id
        }
      }
    }

    opcntShape.parentId = nodeId
    opcntShape.row = yp
    opcntShape.on('pressmove', evt => {
      // Remove the old line
      stage.removeChild(drawingLineShape)
      stage.removeChild(magneticShape)
      // Bezier Control Point
      const adjustedX = evt.stageX - dragDeltaX
      const adjustedY = evt.stageY - dragDeltaY
      const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
      // Create a new line.  A new line is created wvery mouse move
      drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
      drawingLineShape = new createjs.Shape(drawingLine)
      bringToFront(drawingLineShape)
      if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepthX, shadowDepthY, shadowBlur)
      getClosestInput(evt)
      stage.addChild(drawingLineShape)
    })

    opcntShape.on('pressup', evt => {
      if (lineDrawingMode) {
        lineDrawingMode = false
        stage.removeChild(drawingLineShape)
        stage.removeChild(magneticShape)
        if (inputConnectorTarget) {
          // Don't allow linking to own node.
          if (outputConnectorOrigin.parentId !== inputConnectorTarget.parentId) {
            // Ok to create the line
            drawLineConnector(outputConnectorOrigin, inputConnectorTarget, false)
          } else {
          }
          inputConnectorTarget = undefined
        } else {
          // Find Closest input connector
          const closestInput = getClosestInput(evt)
          if (closestInput !== undefined) {
            inputConnectorTarget = getObjectWithId(closestInput.id)
            // Don't allow linking to own node.
            if (outputConnectorOrigin.parentId !== inputConnectorTarget.parentId) {
              drawLineConnector(outputConnectorOrigin, inputConnectorTarget, false)
            }
          } else {
            lineDrawingMode = false
          }
          inputConnectorTarget = undefined
        }
        vueInstance.$store.commit('setSaveWorkflow', true)
        addToStack()
      } else {
        lineDrawingMode = false
      }
    })

    // Label
    const opcntText = new createjs.Text(nodeDef.nodeConfig.rLabels[p++], '12px Arial', theme.bodyLabelColour)
    opcntText.textAlign = 'right'
    opcntText.x = x + width - connectorSize - 4
    opcntText.y = ypos - 2
    opcnt.objectType = 'label'
    group.addChild(opcntText)
    ypos += 30
  }

  if (enabelShapeCaching) {
    group.cache(x - 8, y - 8, width + 32, height + 32)
  }
  stage.update()
  vueInstance.$store.commit('setSaveWorkflow', true)
}

const getClosestInput = evt => {
  const points = []
  const adjustedX = evt.stageX - dragDeltaX
  const adjustedY = evt.stageY - dragDeltaY
  const origin = { x: adjustedX, y: adjustedY }
  for (let i = stage.numChildren - 1; i >= 0; i--) {
    const child = stage.getChildAt(i)
    if (child.objectType === 'nodeGroup') {
      for (let j = child.numChildren - 1; j >= 0; j--) {
        const subChild = child.getChildAt(j)
        if (subChild.objectType === 'nodeInput') {
          points.push({
            id: subChild.id,
            x: getObjectX(subChild),
            y: getObjectY(subChild)
          })
        }
      }
    }
  }

  points.forEach((item, index) => {
    const dist = pointDistance(item, origin)
    item.d = dist
  })

  let lowest = Number.POSITIVE_INFINITY
  let closestInput
  points.forEach((item, index) => {
    if (item.d < lowest && item.d < megneticRadius) {
      lowest = item.d
      closestInput = item
    }
  })

  // let closestInput =
  if (closestInput) {
    highlightInput(closestInput.id)
    return closestInput
  }
}

const highlightInput = id => {
  stage.removeChild(magneticShape)
  const input = getObjectWithId(id)
  magneticInputRect = new createjs.Graphics()

  magneticInputRect.beginFill('#44ff44')
  magneticInputRect.drawRect(getObjectX(input) - connectorSize / 2, getObjectY(input) - connectorSize / 2, connectorSize, connectorSize)
  magneticShape = new createjs.Shape(magneticInputRect)
  magneticShape.objectType = 'magneticInput'
  magneticShape.alpha = 0.3
  stage.addChild(magneticShape)
}

// Measures the distance between two points.
// Sort of pythag but without the root which is not nessessary
const pointDistance = (point, origin) => {
  return Math.pow(point.x - origin.x, 2) + Math.pow(point.y - origin.y, 2)
}

const tick = event => {
  // this set makes it so the stage only re-renders when an event handler indicates a change has happened.
  if (dragNodeMode) {
    // A node is being dragged - we need to update the line connectors
    // Method 1 - Redraw all lines
    repositionAllLineConnectors()
  }
  stage.update(event)
}

const renderGrid = gridType => {
  if (!showGrid) {
    return
  }

  // Render the background grid
  if (gridType === 1) {
    const majorGridSize = gridSize * 4
    for (let i = 0; i < canvas.width; i += gridSize) {
      const line = new createjs.Shape()
      stage.addChild(line)

      if (i % majorGridSize === 0) {
        line.graphics.setStrokeStyle(2).beginStroke(theme.majorGridColour)
      } else {
        line.graphics.setStrokeStyle(2).beginStroke(theme.minorGridColour)
      }

      line.graphics.moveTo(i, 0)
      line.graphics.lineTo(i, canvas.height)
      line.graphics.endStroke()
    }
    for (let i = 0; i < canvas.height; i += gridSize) {
      const line = new createjs.Shape()
      stage.addChild(line)

      if (i % majorGridSize === 0) {
        line.graphics.setStrokeStyle(2).beginStroke(theme.majorGridColour)
      } else {
        line.graphics.setStrokeStyle(2).beginStroke(theme.minorGridColour)
      }

      line.graphics.moveTo(0, i)
      line.graphics.lineTo(canvas.width, i)
      line.graphics.endStroke()
    }
  }
}

const drawLineConnector = (origin, target, selected) => {
  canvasIsDirty = true
  vueInstance.$store.commit('setIsWorkflowDirty', true)
  // Origin co-ords
  const ox = getObjectX(origin) + connectorSize / 2
  const oy = getObjectY(origin)

  // Target co-ords
  const tx = getObjectX(target) - connectorSize / 2
  const ty = getObjectY(target)

  // Control Point
  const cpx = ox + (tx - ox) / 2
  const thickness = 2
  let colour = theme.connectorLineColour

  if (selected) {
    colour = theme.selectedConnectorLineColour
  }

  // Draw Line
  const id = createGuid()

  const lineShape = new createjs.Shape()
  const cmd = lineShape.graphics.beginStroke(colour).command
  lineShape.graphics.setStrokeStyle(thickness).moveTo(ox, oy).bezierCurveTo(cpx, oy, cpx, ty, tx, ty)
  lineShape.objectType = 'lineConnector'
  let shadowLineShape
  if (!lineDrawingMode) {
    stage.removeChild(magneticShape)
    shadowLineShape = new createjs.Shape()
    shadowLineShape.graphics.setStrokeStyle(connectorSize)
      .beginStroke('rgba(255, 0, 0, .01)')
      .moveTo(ox, oy)
      .bezierCurveTo(cpx, oy, cpx, ty, tx, ty)
    shadowLineShape.objectType = 'shadowlineConnector'

    shadowLineShape.data = {
      Id: id,
      OriginParent: origin,
      TargetParent: target
    }
    shadowLineShape.on('mousedown', evt => {
      if (evt.nativeEvent.ctrlKey) {
        // CTRL Click
        cmd.style = theme.selectedConnectorLineColour
        addLineToSelected(evt.target.data.Id, origin, target)
      } else {
        unSelectAll()
        cmd.style = theme.selectedConnectorLineColour
        addLineToSelected(evt.target.data.Id, origin, target)
      }
    })
    shadowLineShape.on('mouseover', evt => {
      createjs.Tween.get(lineShape).to({ alpha: 0.65 }, 150, createjs.Ease.exponentialOut)
    })
    shadowLineShape.on('mouseout', evt => {
      createjs.Tween.get(lineShape).to({ alpha: 1 }, 300, createjs.Ease.exponentialOut)
    })
  }
  if (showLineShadows) lineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepthX, shadowDepthY, shadowBlur / 2)
  lineShape.data = {
    Id: id,
    OriginParent: origin,
    TargetParent: target
  }
  if (!lineDrawingMode) { }

  stage.addChild(shadowLineShape)
  stage.addChild(lineShape)
}

const getWorkflowB64 = () => {
  const workflowObj = buildWorkflowModel()
  const json = JSON.stringify(workflowObj)

  // TODO: LZ String
  // var b64Compressed = LZString.compressToBase64(json)
  // return b64Compressed
  return json
}

export const buildWorkflowModel = () => {
  // Create the workflow model
  const workflowObj = {
    diagramType: 'Workflow',
    paygateId: '',
    version: '3.1.0',
    generator: 'blueroom',
    creationDate: toDdMmYyyy()
  }

  const nodes = []
  const connectors = []
  const inputs = []
  const outputs = []

  for (let i = stage.numChildren - 1; i >= 0; i--) {
    const child = stage.getChildAt(i)

    if (child.objectType === 'nodeGroup') {
      const node = {
        Id: child.nodeId,
        X: getNodeX(child),
        Y: getNodeY(child),
        NodeCfg: child.nodecfg
      }
      nodes.push(node)

      for (let j = child.numChildren - 1; j >= 0; j--) {
        const subChild = child.getChildAt(j)

        if (subChild.objectType === 'nodeInput') {
          const input = {
            Id: subChild.id,
            ParentId: subChild.parentId,
            Row: subChild.row
          }
          inputs.push(input)
        }

        if (subChild.objectType === 'nodeOutput') {
          const output = {
            Id: subChild.id,
            ParentId: subChild.parentId,
            Row: subChild.row
          }
          outputs.push(output)
        }
      }
    }

    if (child.objectType === 'lineConnector') {
      try {
        const connector = {
          Id: child.data.Id,
          OriginId: child.data.OriginParent.id,
          TargetId: child.data.TargetParent.id
        }
        connectors.push(connector)
      } catch (ex) { }
    }
  }

  workflowObj.nodes = nodes
  workflowObj.connectors = connectors
  workflowObj.inputs = inputs
  workflowObj.outputs = outputs
  return workflowObj
}

export const loadWorkflow = async workflow => {
  //  TODO: SWAL Removed
  if (!workflow) {
    console.log('compressedBase64 is falsy')
    return
  }

  addToStack()
  // Clear undo stack
  stack.length = 0
  stackPointer = -1

  retrieveWorkflow(workflow)
  if (connectorsToBack) sendAllConnectorsToBack()
  addToStack()
  canvasIsDirty = false
  vueInstance.$store.commit('setIsWorkflowDirty', false)
}

const retrieveWorkflow = json => {
  clearCanvas()

  // If json is not an object, turn it into one
  let wfObj
  if (typeof (json) !== 'object') {
    wfObj = JSON.parse(json)
  } else {
    wfObj = json
  }

  // Rebuild Nodes
  for (let i = wfObj.nodes.length - 1; i >= 0; i--) {
    createNode(
      wfObj.nodes[i].X,
      wfObj.nodes[i].Y,
      wfObj.nodes[i].NodeCfg.name,
      wfObj.nodes[i].Id,
      wfObj,
      wfObj.nodes[i])
  }

  // Rebuild Line Connectors
  for (let i = wfObj.connectors.length - 1; i >= 0; i--) {
    const origin = getObjectWithId(wfObj.connectors[i].OriginId)
    const target = getObjectWithId(wfObj.connectors[i].TargetId)
    drawLineConnector(origin, target, false)
  }
}

export const undo = () => {
  stackPointer--
  if (stackPointer < 0) {
    stackPointer++
    return
  }
  const newCanvas = stack[stackPointer]
  retrieveWorkflow(newCanvas)
  vueInstance.$store.commit('setIsWorkflowDirty', true)
}

export const redo = () => {
  stackPointer++
  if (stackPointer >= stack.length) {
    stackPointer--
    return
  }
  const newCanvas = stack[stackPointer]
  retrieveWorkflow(newCanvas)
  vueInstance.$store.commit('setIsWorkflowDirty', true)
}

const addToStack = () => {
  // Keep the undo stack from growing too large
  if (stack.length > maxStackLength) {
    console.warn('Max reached')
    stack.shift()
    stackPointer--
  }

  if (stack.length > 0) {
    if (stack.length !== stackPointer + 1) {
      const cutAmount = stack.length - stackPointer - 1
      stack = _.dropRight(stack, cutAmount)
    }
  }

  stackPointer++
  const b64Compressed = getWorkflowB64()
  stack[stackPointer] = b64Compressed

  // validateWorkflow()
}

const validateWorkflow = () => {
  // Validate the workflow - horrible clunky way to call the method.  Events/watchers maybe?.  Pfff, it's fine.
  try {
    vueInstance.$children[0].$refs.routerView.$children[2].validateWorkflow()
  } catch (e) {
    console.log('Error calling validateWorkflow()')
  }
}

const showStackStats = text => {
  if (text === undefined) {
    text = ''
  } else {
    text += ': '
  }
}

const createGuid = () => {
  //  Guid Helper.  Creates a GUID in same format as c#
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // TODO: Investigate
    /* eslint-disable one-var */
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

const bringToFront = obj => {
  // move an object in the cavs to the front of the z-order
  stage.setChildIndex(obj, stage.numChildren - 1)
}

const toDdMmYyyy = () => {
  let now = new Date()
  let dd = now.getDate()
  let mm = now.getMonth() + 1
  const yyyy = now.getFullYear()

  if (dd < 10) {
    dd = '0' + dd
  }

  if (mm < 10) {
    mm = '0' + mm
  }

  const hours = now.getHours()
  const mins = now.getMinutes()
  const secs = now.getSeconds()

  now = dd + '/' + mm + '/' + yyyy + ' ' + hours + ':' + mins + ':' + secs
  return now
}

const getNodeX = node => {
  // Helper - returns the x co-ordinate of a node.
  let x
  try {
    for (let i = node.numChildren - 1; i >= 0; i--) {
      const child = node.getChildAt(i)
      if (child.objectType === 'nodeBody') {
        x = getObjectX(child)
      }
    }
  } catch (ex) { }
  return x
}

const getNodeY = node => {
  // Helper - returns the y co-ordinate of a node.
  let y
  try {
    for (let i = node.numChildren - 1; i >= 0; i--) {
      const child = node.getChildAt(i)
      if (child.objectType === 'nodeBody') {
        y = getObjectY(child)
      }
    }
  } catch (ex) { }
  return y
}

const getObjectX = obj => {
  // Helper - returns the x co-ordinate of the centre point of a shape.
  let x
  try {
    x = (obj.graphics.command.x + obj.parent.x) + connectorSize / 2
  } catch (ex) { }
  return x
}

const getObjectY = obj => {
  // Helper - returns the y co-ordinate of the centre point of a shape.
  let y
  try {
    y = (obj.graphics.command.y + obj.parent.y) + connectorSize / 2
  } catch (ex) { }
  return y
}

const getObjectW = obj => {
  // Helper - returns the width of the shape.
  let w
  try {
    w = (obj.graphics.command.w)
  } catch (ex) { }
  return w
}

const getObjectH = obj => {
  // Helper - returns the height of the shape.
  let h
  try {
    h = (obj.graphics.command.h)
  } catch (ex) { }
  return h
}

const getObjectWithId = id => {
  // Helper - returns the object matching an ID
  // Use it for find the connection point matching an id
  for (let i = stage.numChildren - 1; i >= 0; i--) {
    const child = stage.getChildAt(i)
    if (child.objectType === 'nodeGroup') {
      for (let j = child.numChildren - 1; j >= 0; j--) {
        const subChild = child.getChildAt(j)
        if (subChild.objectType === 'nodeInput') {
          if (subChild.id === id) {
            return subChild
          }
        }
        if (subChild.objectType === 'nodeOutput') {
          if (subChild.id === id) {
            return subChild
          }
        }
        if (subChild.objectType === 'nodeBody') {
          if (subChild.id === id) {
            return subChild
          }
        }
      }
    }
  }
}

const unselectAllLineConnectors = () => {
  for (let i = stage.numChildren - 1; i >= 0; i--) {
    const child = stage.getChildAt(i)
    if (child.objectType === 'lineConnector') {
      child.graphics._stroke.style = theme.connectorLineColour
    }
  }
}

const removeShadowLine = id => {
  // Removes the shadow line with a particular id from the stage.
  // Used when dragging nodes.
  for (let i = stage.numChildren - 1; i >= 0; i--) {
    const child = stage.getChildAt(i)
    if (child.objectType === 'shadowlineConnector') {
      if (child.data.Id === id) {
        stage.removeChild(child)
      }
    }
  }
}

const getObjectUnderMouse = useStored => {
  let shape
  if (useStored) {
    shape = stage.getObjectUnderPoint(storedMouseCoords.x, storedMouseCoords.y)
  } else {
    shape = stage.getObjectUnderPoint(stage.mouseX, stage.mouseY)
  }

  if (shape == null) return null

  if (shape.objectType) {
    if (shape.objectType === 'nodeGroup') {
      return { id: shape.nodeId, type: shape.objectType }
    } else if (shape.objectType === 'lineConnector') {
      return { id: shape.data.Id, type: shape.objectType }
    } else if (shape.objectType === 'shadowlineConnector') {
      return { id: shape.data.Id, type: shape.objectType }
    } else {
      // It might be part of a node - we need to find its parent
      const parent = shape.parent
      if (parent.objectType === 'nodeGroup') {
        return { id: parent.nodeId, type: parent.objectType }
      }
    }
  } else {
    return { id: null, type: null }
  }
}

const repositionAllLineConnectors = () => {
  // While dragging a node the line connectors also need to be repositioned
  for (let i = stage.numChildren - 1; i >= 0; i--) {
    const child = stage.getChildAt(i)
    if (child.objectType === 'lineConnector') {
      // create a new line and remove the old one
      drawLineConnector(child.data.OriginParent, child.data.TargetParent, false)
      removeShadowLine(child.data.Id)
      stage.removeChild(child)
    }
  }
}

const allignToGrid = num => {
  const dif = num % gridSize
  let delta = 0
  if (dif !== 0) {
    if (dif > (gridSize / 2)) {
      delta = Math.abs(dif - gridSize)
    } else {
      delta = dif * -1
    }
  }
  return num + delta
}

export const removeSelected = () => {
  // Remove the selected 'item' from the stage.  This is usually the node or line that the user has selected
  for (let i = stage.numChildren - 1; i >= 0; i--) {
    const child = stage.getChildAt(i)
    if (child) {
      if (child.objectType === 'nodeGroup') {
        if (selectedObjects.some(e => e.nodeId === child.nodeId)) {
          stage.removeChild(child)
          removeConnectedLine(child.nodeId)
          drawSelectionBoxes()
        }
      }
      if (child.objectType === 'lineConnector') {
        if (selectedObjects.some(e => e.nodeId === child.data.Id)) {
          removeShadowLine(child.data.Id)
          stage.removeChild(child)
        }
      }
    }
  }
  selectedObjects = []
  addToStack()
  vueInstance.$store.commit('setIsWorkflowDirty', true)
}

const removeConnectedLine = (parentId) => {
  // Removes all line connectors that are connected to the node with the parentId
  for (let i = stage.numChildren - 1; i >= 0; i--) {
    const child = stage.getChildAt(i)
    if (child.objectType === 'lineConnector') {
      if (child.data.OriginParent.parentId === parentId) {
        removeShadowLine(child.data.Id)
        stage.removeChild(child)
      }
      if (child.data.TargetParent.parentId === parentId) {
        removeShadowLine(child.data.Id)
        stage.removeChild(child)
      }
    }
  }
}

const addLineToSelected = (id, origin, target) => {
  const item = {
    type: 'line',
    nodeId: id,
    originId: origin.id,
    targetId: target.id
  }
  // Add the selected line to the array - only if it's not already in there
  if ((selectedObjects.filter(e => e.originId === origin.id).length === 0) && (selectedObjects.filter(e => e.targetId === target.id).length === 0)) {
    selectedObjects.push(item)
    vueInstance.$store.commit('setSelectedObjects', selectedObjects)
  }
}

const addNodeToSelected = (node) => {
  const item = {
    type: 'node',
    nodeId: node.nodeId,
    headerBackColour: node.headerBackColour
  }
  // Add the selected item to the array - only if it's not already in there
  if (selectedObjects.filter(e => e.nodeId === node.nodeId).length === 0) {
    // console.log('Added')
    selectedObjects.push(item)
    vueInstance.$store.commit('setSelectedObjects', selectedObjects)
  }
  drawSelectionBoxes()
}

export const copyClipboard = () => {
  const so = vueInstance.$store.state.workflow.selectedObjects
  const bundle = []
  for (const o of so) {
    for (let i = stage.numChildren - 1; i >= 0; i--) {
      const child = stage.getChildAt(i)
      if (child.nodeId === o.nodeId) {
        if (o.type === 'node') {
          const newId = createGuid()
          const node = {
            Id: newId,
            X: getNodeX(child) + 32,
            Y: getNodeY(child) + 32,
            NodeCfg: child.nodecfg
          }
          node.NodeCfg.id = newId
          bundle.push(node)
        }
      }
    }
  }

  const clipboardContent = {
    generator: 'blueroom',
    version: '1.0.0',
    bundle
  }

  try {
    const bytes = utf8.encode(JSON.stringify(clipboardContent))
    const encoded = base64.encode(bytes)
    window.localStorage.setItem('pgwfcb', encoded)
  } catch (e) {
    console.log(`Copy Error: ${e.message}`)
  }

  return clipboardContent
}

export const cutClipboard = () => {
  copyClipboard()
  removeSelected()
}

export const pasteClipBoard = () => {
  addToStack()
  // Get the clipboard contents from localstorage
  try {
    const encodedClipboard = window.localStorage.getItem('pgwfcb')
    const bytes = base64.decode(encodedClipboard)
    const cb = utf8.decode(bytes)
    const cbData = JSON.parse(cb)

    if (cbData) {
      if (cbData.bundle) {
        for (let i = cbData.bundle.length - 1; i >= 0; i--) {
          createNode(cbData.bundle[i].X, cbData.bundle[i].Y, cbData.bundle[i].NodeCfg.name, undefined, cbData, cbData.bundle[i])
        }
      }
    }
  } catch (e) {
    console.log(`Paste Error: ${e.message}`)
  }
  addToStack()
  vueInstance.$store.commit('setIsWorkflowDirty', true)
}

const drawSelectionBoxes = () => {
  // Daws the coloured boxes around selected nodes
  const offset = connectorSize / 2
  for (let i = stage.numChildren - 1; i >= 0; i--) {
    const child = stage.getChildAt(i)
    if (child.objectType === 'selectedNode') {
      stage.removeChild(child)
    }
  }
  for (const box of selectedObjects) {
    const item = getObjectWithId(box.nodeId)
    const nx = getObjectX(item) - offset
    const ny = getObjectY(item) - offset
    const nh = getObjectH(item) + offset
    const nw = getObjectW(item)
    const selectedRect = new createjs.Graphics()
    selectedRect.setStrokeStyle(3)
    selectedRect.beginStroke(box.headerBackColour)
    selectedRect.drawRoundRect(nx, ny, nw, nh, nodeRadius)
    const selectedShape = new createjs.Shape(selectedRect)
    selectedShape.objectType = 'selectedNode'
    stage.addChild(selectedShape)
  }
}

const unSelectAll = () => {
  // Unselects all lines and nodes
  unselectAllLineConnectors()
  selectedObjects = []
  drawSelectionBoxes()
  vueInstance.$store.commit('setSelectedObjects', [])
}

const selectAll = () => {
  unselectAllLineConnectors()
  for (let i = 0; i < stage.numChildren; i++) {
    const child = stage.getChildAt(i)
    if (child.objectType === 'lineConnector') {
      addLineToSelected(child.data.Id, child.data.OriginParent, child.data.TargetParent)
      child.graphics._stroke.style = theme.selectedConnectorLineColour
    }

    if (child.objectType === 'nodeGroup') {
      for (let j = child.numChildren - 1; j >= 0; j--) {
        const subChild = child.getChildAt(j)
        if (subChild.objectType === 'nodeBody') {
          // selectedObjects.push(child)
          addNodeToSelected(child)
        }
      }
    }
  }
}

const sendAllConnectorsToBack = () => {
  for (let i = stage.numChildren - 1; i >= 0; i--) {
    const child = stage.getChildAt(i)
    if (child.objectType === 'lineConnector') {
      stage.setChildIndex(child, 0)
    }
    if (child.objectType === 'shadowlineConnector') {
      stage.setChildIndex(child, 0)
    }
  }
}

const selectEnclosedShapes = (x, y, dx, dy) => {
  // Returns an array of shapes that are completely enclosed within the args
  selectedObjects = []

  // Not very readable but these next two lines swap start and end points
  // if the user starts drawing the rubberband from say the bottom right corner.
  if (dx < x) dx = [x, x = dx][0]
  if (dy < y) dy = [y, y = dy][0]

  for (let i = 0; i < stage.numChildren; i++) {
    const child = stage.getChildAt(i)
    if (child.objectType === 'lineConnector') {
      const lox = getObjectX(child.data.OriginParent)
      const loy = getObjectY(child.data.OriginParent)
      const ltx = getObjectX(child.data.TargetParent)
      const lty = getObjectY(child.data.TargetParent)

      // Is the line totally enclosed within the rubberband?
      // It's actually just basic geometry
      if (((lox > x) && (lox < dx)) && ((ltx > x) && (ltx < dx)) && ((loy > y) && (loy < dy)) && ((lty > y) && (lty < dy))) {
        child.graphics._stroke.style = theme.selectedConnectorLineColour
        addLineToSelected(child.data.Id, child.data.OriginParent, child.data.TargetParent)
      }
    }
    if (child.objectType === 'nodeGroup') {
      for (let j = child.numChildren - 1; j >= 0; j--) {
        const subChild = child.getChildAt(j)
        if (subChild.objectType === 'nodeBody') {
          const scx = getObjectX(subChild)
          const scy = getObjectY(subChild)
          const scw = getObjectW(subChild)
          const sch = getObjectH(subChild)
          if ((scx >= x) && (scy >= y) && ((scx + scw) <= dx) && ((scy + sch) <= dy)) {
            addNodeToSelected(child)
          }
        }
      }
    }
  }
}

const truncateText = (text, length = 32) => {
  if (text) {
    if (text.length > length) {
      text = text.substring(0, length)
      text = text.concat('...')
    }
    return text
  }
}
