<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <div>
                <component
                  v-for="(field, index) in schema"
                  :key="index"
                  :is="field.fieldType"
                  :value="formData[field.name]"
                  @input="updateForm(field.name, $event)"
                  v-bind="field">
                </component>
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <b-button variant="danger"
                @click="$emit('cancel')"
                title="Edit Mapping">
                <span class="fa fa-times" aria-hidden="true"></span>
                Cancel
              </b-button>
              <b-button variant="success"
                @click.prevent="btnSave"
                title="Edit Mapping">
                <span class="fa fa-save" aria-hidden="true"></span>
                Save
              </b-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import * as diagram from './Engine/mappingDesigner.js'
import Label from './FormComponents/Label'
import NumberInput from './FormComponents/NumberInput'
import SelectList from './FormComponents/SelectList'
import BankAccount from './FormComponents/BankAccount'
import GroupDetails from './FormComponents/GroupDetails'
import BureauCustomer from './FormComponents/BureauCustomer'
import TextInput from './FormComponents/TextInput'
import Checkbox from './FormComponents/Checkbox'
import RadioButton from './FormComponents/RadioButton'
import TextArea from './FormComponents/TextArea'
import PasswordInput from './FormComponents/PasswordInput'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
  name: 'FormGenerator',
  components: {
    Label,
    NumberInput,
    SelectList,
    BankAccount,
    GroupDetails,
    BureauCustomer,
    TextInput,
    Checkbox,
    RadioButton,
    TextArea,
    PasswordInput,
    VuePerfectScrollbar
  },
  data () {
    return {
      settings: {
        // maxScrollbarLength: 100
      },
      formData: {
        s1: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.s1),
        s2: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.s2),
        s3: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.s3),
        s4: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.s4),
        s5: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.s5),
        s6: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.s6),
        s7: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.s7),
        s8: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.s8),
        s9: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.s9),
        b1: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.b1),
        b2: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.b2),
        b3: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.b3),
        b4: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.b4),
        b5: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.b5),
        b6: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.b6),
        b7: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.b7),
        b8: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.b8),
        b9: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.b9),
        b10: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.b10),
        b11: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.b11),
        b12: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.b12),
        int1: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.int1),
        int2: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.int2),
        int3: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.int3),
        int4: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.int4),
        pw1: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.pw1),
        pw2: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.pw2),
        guid1: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.guid1),
        guid2: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.guid2),
        guid3: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.guid3),
        guid4: this.setNodeProp(this.$store.state.mapping.selectedMappingNode.props.guid4)
      },
      schema: []
    }
  },
  methods: {
    btnSave () {
      this.$store.commit('setSelectedMappingNodeProps', this.formData)
      this.$emit('close')
    },
    setNodeProp (prop) {
      if (prop) {
        return prop.value
      }
    },
    updateForm (fieldName, value) {
      this.$set(this.formData, fieldName, value)
      this.$emit('input', this.formData)
    },
    scrollHandle (evt) {
    }
  },
  beforeUpdate () {
  },
  mounted () {
    this.schema = this.$store.state.mapping.selectedMappingNode.ui.schema
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 70%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.required {
  color: red;
  font-variant-position: super;
  padding-left: 4px;
}
  .invalid {
    border-color: red;
    border-width: 2px;
  }
  label.required {
    /* $gray-700:  */
    color: #878787;
  }
  label.required::after {
    content: " *";
    color: red;
  }

</style>
