var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"arucsReportError"}},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('div',{staticClass:"idb-block-title"},[_c('h2',[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])]),_c('div',{staticClass:"idb-block-content"},[(_vm.action.id !== undefined)?_c('div',{attrs:{"id":"actionDetails"}},[_c('div',{staticClass:"row form-group"},[_vm._m(0),_c('div',{staticClass:"col-md-10"},[_vm._v(" "+_vm._s(this.action.title)+" "),_vm._m(1)])]),_c('div',{staticClass:"row form-group"},[_vm._m(2),_c('div',{staticClass:"col-md-10"},[_vm._v(_vm._s(this.action.message))])]),(this.action.data.details.length > 0)?_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-md-2"}),_c('div',{staticClass:"col-md-10"},_vm._l((this.action.data.details),function(detail){return _c('div',{key:detail.messageText,staticStyle:{"padding-bottom":"15px"},attrs:{"value":detail.messageText}},[_vm._v(" "+_vm._s(detail.messageText)+" ")])}),0)]):_vm._e()]):_vm._e(),_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-md-12"},[_c('vue-good-table',{ref:"bacsPaymentErrors",attrs:{"columns":_vm.columns,"rows":_vm.paymentErrors,"lineNumbers":false,"totalRows":_vm.totalRecords,"pagination-options":{
                enabled: true,
                perPage: _vm.rowsPerPage,
                dropdownAllowAll: false,
                setCurrentPage: _vm.currentPage
              },"sort-options":{
                enabled: true
              },"styleClass":"vgt-table striped bordered"},on:{"on-row-click":_vm.onRowClick,"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'submissionStatus')?_c('span',[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.statusToolTip(props.row.submissionStatus)),expression:"statusToolTip(props.row.submissionStatus)",modifiers:{"hover":true,"top":true}}],class:_vm.getStatusClass(props.row.submissionStatus),style:(_vm.getStatusStyle(props.row.submissionStatus))})]):(props.column.field === 'payerHyperlink')?_c('span',[(props.row.itemId !== '00000000-0000-0000-0000-000000000000')?_c('span',{staticClass:"viewLink"},[_vm._v("View Customer")]):_vm._e()]):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}])})],1)])]),_c('div',{staticClass:"idb-block-footer"},[_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-md-12"},[_c('button',{staticClass:"btn btn-outline-primary ml-3",attrs:{"type":"button"},on:{"click":_vm.viewReport}},[_vm._v(_vm._s(_vm.reportButtonText))]),_c('button',{staticClass:"btn btn-danger pull-right",attrs:{"id":"delete","type":"button"},on:{"click":_vm.deleteAction}},[_vm._v("Delete Action")])])])])])]),_c('div',[_c('viewReportModal',{attrs:{"reportData":_vm.reportContent,"reportFormat":_vm.selectedReportFormat,"reportTitle":_vm.modalTitle},on:{"close":_vm.closeViewReport},model:{value:(_vm.isViewReportModalVisible),callback:function ($$v) {_vm.isViewReportModalVisible=$$v},expression:"isViewReportModalVisible"}})],1),_c('div',[_c('bacsPaymentErrorModal',{attrs:{"bacsPaymentError":_vm.selectedBacsPaymentError},on:{"close":_vm.closePaymentModal},model:{value:(_vm.isBacsPaymentErrorModalVisible),callback:function ($$v) {_vm.isBacsPaymentErrorModalVisible=$$v},expression:"isBacsPaymentErrorModalVisible"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2"},[_c('strong',[_vm._v("Title")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"pull-right"},[_c('a',{attrs:{"href":"#","target":"_blank"}},[_c('i',{staticClass:"far fa-question-circle"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2"},[_c('strong',[_vm._v("Message")])])
}]

export { render, staticRenderFns }