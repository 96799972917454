<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>Commission Statements Administration</h2>
    </div>

    <div class="idb-block-content">
      <!-- Date Picker-->
      <div class="row form-group">
        <div class="col-md-2 col-sm-6">
          <transition name="fadein">
            <vuejsDatepicker
              name="selectMonth"
              id="selectMonth"
              format="MMM/yyyy"
              input-class="form-control bg-white"
              :bootstrap-styling="true"
              minimumView="month"
              maximumView="year"
              initial-view="month"
              :use-utc="true"
              :disabled-dates="{
                    from: new Date()
                    }"
              :calendar-button="true"
              calendar-button-icon="fa fa-calendar"
              v-model="selectedDate"
              @input="GetStatementData()"
              :highlighted="highlighted"
            ></vuejsDatepicker>
          </transition>
        </div>
      </div>

      <!-- Buttons -->
      <div class="row form-group">
        <div class="col-md-2">
          <button
            class="btn btn-primary"
            @click="onGenerateStatementData"
            type="button"
          >Refresh Commission Statements</button>
        </div>
        <div class="col-md-1"></div>
      </div>

      <!-- Tablist -->

      <div role="tablist" v-if="!showWarn">
        <b-card no-body v-for="statement in statements" v-bind:key="statement.id">
          <b-card-header header-tag="header" class="p-2" role="tab">
            <b-row
              v-b-toggle="'statement-' + statement.id + statement.agentid"
              class="payment-header pt-0"
            >
              <b-col label cols="1">
                <strong>Ref:</strong>
                {{ statement.statementserialid }}
              </b-col>

              <b-col label cols="3">
                <strong>Agent:</strong>
                {{ statement.agentname }}
              </b-col>

              <b-col label cols="2">
                <strong>Amount:</strong>
                £{{ statement.statementtotal }}
              </b-col>

              <b-col label cols="2" v-if="statement.commissionstatementstatus === 'Paid'">
                <strong>Invoice:</strong>
                {{ statement.invoiceNumber }}
              </b-col>

              <b-col label cols="2" v-if="statement.commissionstatementstatus === 'Sent'">
                <button class="btn btn-primary" @click="onSetStatementToPaid(statement)">Set To Paid</button>
              </b-col>
              <b-col label cols="2" v-if="statement.commissionstatementstatus === 'Generated'"></b-col>
              <b-col label cols="2">
                <button
                  v-if="statement.commissionstatementstatus === 'Generated'"
                  class="btn btn-primary"
                  @click="onSendStatementEmail(statement)"
                >Email Statement</button>

                <button
                  v-else
                  class="btn btn-secondary"
                  @click="onSendStatementEmail(statement)"
                >Resend Statement</button>
              </b-col>
              <b-col label cols="2">
                <button
                  class="btn btn-primary"
                  @click="onRollbackStatement(statement)"
                >Roll Back Statement</button>
              </b-col>

              <b-col v-if="statement.overPercentageWarning" cols="6">
                <h6
                  class="text-warning"
                >This statement includes commissions with associations totalling over 100%!</h6>
              </b-col>
            </b-row>
          </b-card-header>

          <b-collapse
            :id="'statement-' + statement.id+ statement.agentid"
            :ref="'statement-' + statement.id+ statement.agentid"
            accordion="my-accordion"
            @show="onShowStatement(statement)"
            @hide="onHideStatement()"
            role="tabpanel"
          >
            <div class="row form-group">
              <div class="col-sm-2">
                <label>Customer</label>
              </div>

              <div class="col-sm-2">
                <label>Payments</label>
              </div>
              <div class="col-sm-2">
                <label>Clawbacks</label>
              </div>

              <div class="col-sm-2">
                <label>Commission Items</label>
              </div>
              <div class="col-sm-2">
                <label>Commission Amount</label>
              </div>
              <!-- <div class="col-sm-2">
                <label>View Commission Items</label>
              </div>-->

              <div class="col-sm-2">
                <button class="btn btn-default btn-sm" @click="onViewCommissionItems(statement.id)">
                  <i class="fa fa-cog"></i> View Commission Items
                </button>
              </div>
            </div>

            <div
              v-for="commission in statement.commissionaggregate"
              v-bind:key="commission.customername"
              class="payment-header pt-0"
            >
              <div class="row form-group">
                <div class="col-sm-2">
                  <label>{{commission.customername}}</label>
                </div>
                <div class="col-sm-2">
                  <label>£{{commission.payments}}</label>
                </div>

                <div v-if="commission.clawbacks === 0" class="col-sm-2">
                  <label>£0</label>
                </div>

                <div v-else class="col-sm-2">
                  <label class="text-danger">-£{{commission.clawbacks * -1}}</label>
                </div>

                <div class="col-sm-2">
                  <label>{{commission.itemstotal}}</label>
                </div>

                <div v-if="commission.commissiontotal < 0" class="col-sm-2">
                  <label class="text-danger">
                    <b>-£{{commission.commissiontotal * -1}}</b>
                  </label>
                </div>
                <div v-else class="col-sm-2">
                  <label>
                    <b>£{{commission.commissiontotal}}</b>
                  </label>
                </div>

                <div class="col-sm-2">
                  <h6
                    v-show="commission.manualCreatedExists && !commission.overrideExists"
                    class="text-warning"
                  >Contains manually created commission items</h6>
                  <h6
                    v-show="!commission.manualCreatedExists && commission.overrideExists"
                    class="text-warning"
                  >Contains overridden commission items</h6>
                  <h6
                    v-show="commission.manualCreatedExists && commission.overrideExists"
                    class="text-warning"
                  >Contains manually created and overridden commission items</h6>
                </div>
              </div>
            </div>
          </b-collapse>
        </b-card>
      </div>
    </div>

    <div>
      <StatementPaidDialog
        :currentStatementId="currentStatementId"
        v-on:submit="manageStatementPaidDialogClosed"
      ></StatementPaidDialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

import StatementPaidDialog from './StatementPaidDialog'

export default {
  props: {
    statementid: String
  },
  components: { StatementPaidDialog },
  data () {
    return {
      statements: [],
      GoToStatement: false,
      Statement: {},
      // Date Picker
      selectedDate: new Date(),
      highlightDates: [],
      highlighted: {
        dates: []
      },
      // Page Control
      showWarn: false,
      currentStatementId: ''

    }
  },

  async created () {
    await this.load()
  },
  methods: {
    async load () {
      console.log(this.statementid)
      if (this.statementid === null || this.statementid === '' || this.statementid === undefined) {
        console.log('statementid is bad', this.statementid)
      } else {
        console.log('statementid is good', this.statementid)
        this.GoToStatement = true
        await this.SetStatementData()
      }

      await this.GetStatementData()
    },

    async SetStatementData () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetSingleStatement/${this.statementid}`,
          {
            showerror: true,
            errormessage: 'Failed loading statement data'
          })

        this.Statement = response.data

        var dateVal = new Date()
        dateVal.setMonth(response.data.statementmonth - 1)
        dateVal.setYear(response.data.statementyear)
        this.selectedDate = dateVal

        // console.log('this.$root.$emit', this.$root.$emit('bv::toggle::b-collapse', '1241241'))
        // this.$emit('bv::toggle::b-collapse', collapseId)
        // this.$root.$emit('bv::toggle::collapse', 'statement-' + response.data.id + response.data.agentid)
      } catch { } finally {

      }
    },

    GetStatementData: async function () {
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetStatementsData`,
          {
            params: { SelectedDate: this.selectedDate, paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get Commission Receivable Statement data'
          })

        this.statements = response.data

        var DateHighlightResponse = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetStatementsDateHighlights`,
          {
            params: { SelectedDate: this.selectedDate, paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get Commission Receivable Statement data'
          })
        console.log('DateHighlightResponse', DateHighlightResponse)

        for (let i = 0; i < DateHighlightResponse.data.length; i++) {
          console.log(i)
          var dateVal = new Date()
          dateVal.setMonth(DateHighlightResponse.data[i].statementMonth - 1)
          dateVal.setYear(DateHighlightResponse.data[i].statementYear)
          console.log(dateVal)
          this.highlighted.dates.push(dateVal)
          this.highlightDates.push(dateVal)
        }
        if (this.GoToStatement) {
          var collapseId = 'statement-' + this.statementid + this.Statement.agentid
          this.$refs[collapseId][0].toggle()
        }
      } catch (e) {
        console.log('e', e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    onGenerateStatementData: async function () {
      // Generate Statement Data
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CACreateCommissionStatements`,
          {
            params: { selectedDate: this.selectedDate, paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to generate Commission Receivable Statement data'
          })

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
      await this.GetStatementData()
    },
    onShowStatement () { },
    onHideStatement () { },

    onViewCommissionItems (statementid) {
      this.$router.push({
        name: 'CommissionAgentListCommissionForStatement',
        params: { statementid: statementid }
      })
    },

    onSendStatementEmail: async function (statement) {
      console.log('statement', statement)
      var swalText = ''
      var toAddresses = []
      var ccAddresses = []
      if (statement.emailaddresses.length === 0) {
        swalText = 'There are no email recipients to email this statement to. Please check the company configuration.'
      } else {
        toAddresses = _.filter(statement.emailaddresses, function (o) { return !o.emailcc })
        ccAddresses = _.filter(statement.emailaddresses, function (o) { return o.emailcc })

        if (toAddresses.length === 0) {
          swalText = 'There are no email recipients listed in the TO section. Please check the company configuration'
        } else {
          swalText = 'This action will email this statement to the following email addresses: <br /> <br /> <u>To Addresses:</u>'
          for (let t = 0; t < toAddresses.length; t++) {
            swalText = swalText + '<li>' + toAddresses[t].email + '</li>'
          }
          if (ccAddresses.length > 0) {
            swalText = swalText + '<br /><br /> <u> CC Addresses:</u>'
            for (let c = 0; c < ccAddresses.length; c++) {
              swalText = swalText + '<li>' + ccAddresses[c].email + '</li>'
            }
          }
        }
        if (statement.overPercentageWarning) {
          swalText = swalText + '<br /> <h6 class = "text-warning">This statement contains commission items involved with more than one association and whose total percentage commission exceeds 100% </h6>'
        }
      }
      try {
        var swalResult = await swal.fire({
          title: 'Email this statement',
          html: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }
      if (!swalResult.isConfirmed) { return }
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGenerateEmailStatement/${statement.id}`,
          {
            params: { paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get Commission Receivable Statement data'
          })

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
      await this.GetStatementData()
    },

    onSetStatementToPaid: async function (statement) {
      console.log('statement', statement)
      var swalText = 'Would you like to set statement with <strong>Ref ' + statement.statementserialid +
        '</strong> for Agent <strong>' + statement.agentname + '</strong> for <strong>£' + statement.statementtotal + ' </strong> to Paid?'
      if (statement.overPercentageWarning) {
        swalText = swalText + '<br /> <h6 class = "text-warning">This statement contains commission items involved with more than one association and whose total percentage commission exceeds 100% </h6>'
      }
      try {
        var swalResult = await swal.fire({
          title: 'Email this statement',
          html: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }
      if (!swalResult.isConfirmed) { return }
      this.currentStatementId = statement.id
      this.$bvModal.show('statement-paid-dialog')
    },
    manageStatementPaidDialogClosed: async function () {
      await this.GetStatementData()
    },
    HOST_onCancelStatementPaid () {
      console.log('cancelled invoice entry')
      this.$toastr.w('Cancelled setting the statement to paid')
    },

    HOST_onContinueToSetStatementToPaid: async function (statement) {
      try {
        console.log('setting to paid')
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CASetStatementToPaid/${statement.id}`,
          {
            params: { paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to set Commission Statement to Paid'
          })

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
      await this.GetStatementData()
    },

    onRollbackStatement: async function (statement) {
      console.log('statement', statement)
      var swalText = 'Would you like to roll back statement with <strong>Ref ' +
        statement.statementserialid +
        '</strong> for Agent <strong>' +
        statement.agentname +
        '</strong> for <strong>£' +
        statement.statementtotal + ' </strong>? <br/> This will:' +
        '<li>Unwind all associated commission records for this statement</li>' +
        '<li>Cause this version of the statement to be permanently rolled back and no longer payable</li>' +
        '<li>Mean you will have to regenerate a new statement in order to pay any commission items associated with it</li>' +
        '<br/>You should only do this if you are sure as it will immediately mean all commission items will revert to <strong>New</strong> status and it cannot be reversed.'

      try {
        var swalResult = await swal.fire({
          title: 'Rollback all unpaid statements?',
          html: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }
      if (!swalResult.isConfirmed) { return }

      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CARollbackStatement/${statement.id}`,
          {
            params: { paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to rollback selected statement'
          })

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
      await this.GetStatementData()
    }

  }
}

</script>
