<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline File Details - Account Balance Summary<span class="pull-right"><a :href="`${docUrl}/automation/logging/`" target="_blank"><i class="far fa-question-circle"></i></a></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-1 ">
              Filename
            </div>
            <div class="col-3">
              {{fileData.originalFilename}}
            </div>
            <div class="col-1 offset-1">
              Upload Date
            </div>
            <div class="col-3">
              {{reformatDate(fileData.creationDate)}}
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-1 ">
              Origin
            </div>
            <div class="col-3">
              <b-badge pill v-if = "fileData.description == 'upload'" variant="success">Uploaded from Bankline</b-badge>
              <b-badge pill v-else-if = "fileData.description == 'payment'" variant="info">Payment</b-badge>
            </div>
            <div class="col-1 offset-1">
              Status
            </div>
            <div class="col-3">
              <b-badge pill v-if = "fileData.status == '2'" variant="secondary">Unprocessed</b-badge>
              <b-badge pill v-else-if = "fileData.status == '3'" variant="success">Processed</b-badge>
            </div>
          </div>
        </div>
      </div>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>File Contents</h2>
        </div>
        <div class="idb-block-content">
          <div>
            <VuePerfectScrollbar class="scroll-area" :settings="settings">
              <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              :rows="rows"
              :columns="columns"
              compactMode
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              ref="table"
              :lineNumbers="true"
              styleClass="vgt-table striped bordered table-hover"
              @on-row-click="onRowClick"
              @on-cell-click="onCellClick">
            <template slot="table-row" slot-scope="props">
                <span v-if = "props.column.field == 'entryType'">
                  <b-badge pill v-if = "props.row.entryType == 'Credit'" variant="success">{{props.row.entryType}}</b-badge>
                  <b-badge pill v-else-if = "props.row.entryType == 'Debit'" variant="danger">{{props.row.entryType}}</b-badge>
                  <span v-else>{{props.row.entryType}}</span>
                </span>
                <span v-else-if = "props.column.field == 'data.SortCode'">
                  {{reformatSortcode2(props.row.data.SortCode)}}
                </span>
                <span v-else-if = "props.column.field == 'status'">
                  <b-badge pill v-if = "props.row.status == '1'" variant="secondary">Unprocessed</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '2'" variant="info">Processing</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '3'" variant="success">Processed</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '4'" variant="danger">Unmatched</b-badge>
                  <span v-else>{{props.row.entryType}}</span>
                </span>
                <span v-else-if = "props.column.field == 'createdAt'">
                  {{ reformatDate(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'updatedAt'">
                  {{ reformatDate(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'data.LastNightsLedger'">
                  {{ reformatCurrency(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'data.TodaysLedger'">
                  {{ reformatCurrency(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'data.LastNightsCleared'">
                  {{ reformatCurrency(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'data.TodaysCleared'">
                  {{ reformatCurrency(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'data.StartOfDayLedger'">
                  {{ reformatCurrency(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'data.StartOfDayCleared'">
                  {{ reformatCurrency(props.formattedRow[props.column.field])}}
                </span>
                <!-- <span v-else-if = "props.column.field == 'title'">
                  <b-button v-if="props.formattedRow.type =='Workflow'" @click.prevent="viewWorkflow(props)" class="pointer" variant="link" >{{props.formattedRow[props.column.field]}}</b-button>
                  <b-button v-else-if="props.formattedRow.type =='Mapping'" @click.prevent="viewMapping(props)" class="pointer" variant="link" >{{props.formattedRow[props.column.field]}}</b-button>
                </span> -->
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
            </template>
            <div slot="table-actions">
              <button
                @click.prevent="clearTableFilters"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Clear filters'">
                <span class="fa-stack" style="font-size: 10px;">
                  <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                  <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                </span>
              </button>
              <b-button @click.prevent="load" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the table data.'" ><i class="fas fa-sync-alt pointer dimmedIcon"></i></b-button>
            </div>
              </vue-good-table>
            </VuePerfectScrollbar>
        </div>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div class="btn-group mr-2" role="group" v-if = "fileData.status == '2'">
                <button id="btnGroupDrop1" @click="btnReconcileBanklineFile" type="button" class="btn btn-primary">
                  <i class="fa fa-play mr-2"></i>Reconcile Bankline File
                </button>
              </div>
              <div class="btn-group mr-2" role="group">
                <button id="btnGroupDrop1" @click="btnUploadBanklineFile" type="button" class="btn btn-primary">
                  <i class="fa fa-upload mr-2"></i>Upload Bankline File
                </button>
              </div>
              <div class="btn-group mr-2" role="group">
                <button id="btnGroupDrop1" @click="btnViewAllBalances" type="button" class="btn btn-outline-primary">
                  <i class="fa fa-list mr-2"></i>View Bank Balances
                </button>
              </div>
              <div class="btn-group pull-right">
                <button id="btnDeleteFile" @click="btnDeleteFile" type="button" class="btn btn-danger">
                  <i class="fa fa-trash mr-2"></i>Delete File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
export default {
  name: 'WorkflowLogs',
  mixins: [tableFilterMixin],
  components: {
    VuePerfectScrollbar,
    swal
  },
  data () {
    return {
      settings: {
      },
      fileData: {},
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'Row',
          field: 'row',
          hidden: false
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Sort Code',
          field: 'data.SortCode',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account Number',
          field: 'data.AccountNumber',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account Alias',
          field: 'data.AccountAlias',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Account Short Name',
          field: 'data.AccountShortName',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Currency',
          field: 'data.Currency',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Account Type',
          field: 'data.AccountType',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Date',
          field: 'data.Date',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Last Night\'s Ledger',
          field: 'data.LastNightsLedger',
          type: 'decimal',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Today\'s Ledger',
          field: 'data.TodaysLedger',
          type: 'decimal',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Last Night\'s Cleared',
          field: 'data.LastNightsCleared',
          type: 'decimal',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Today\'s Cleared',
          field: 'data.TodaysCleared',
          type: 'decimal',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Start of Day Ledger',
          field: 'data.StartOfDayLedger',
          type: 'decimal',
          filterOptions: {
            enabled: true
          },
          hidden: false
        },
        {
          label: 'Start of Day Cleared',
          field: 'data.StartOfDayCleared',
          type: 'decimal',
          filterOptions: {
            enabled: true
          }
        }
      ],
      row_data: [],
      totalRecords: 0,
      paginationOptions: { enabled: true, perPage: 10, dropdownAllowAll: false, jumpFirstOrLast: true },
      serverParams: {
        sort: [{ field: 'row', type: 'asc' }]
      }
    }
  },
  computed: {
    rows: function () {
      return this.row_data
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async load () {
      const postObj = {
        serverParams: this.serverParams,
        id: this.id
      }
      this.isTableLoading = true
      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineabsfile/${this.id}`, postObj)
        if (res) {
          this.fileData = res.data.resObj
          this.row_data = res.data.resObj.data
          this.totalRecords = res.data.resObj.count
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
        this.row_data = []
        this.totalRecords = 0
      } finally {
        this.isTableLoading = false
      }
    },
    async btnViewAllBalances () {
      this.$router.push('/admin/bandr/balances')
    },
    async btnUploadBanklineFile () {
      this.$router.push('/admin/bandr/bankline/uploadfile')
    },
    reformatSortcode2 (dai) {
      if (!dai) return
      return dai
      // try {
      //   if (dai.length !== 6) return
      //   return `${dai[0]}${dai[1]}-${dai[2]}${dai[3]}-${dai[4]}${dai[5]}`
      // } catch {

      //   return 'Error'
      // }
    },
    reformatCurrency (c) {
      if (!c) return ''
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      })
      return formatter.format(c)
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    async btnDeleteFile () {
      const result = await swal.fire({
        title: 'Are you sure you want to permanently delete this Bankline file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}banklinefile/${this.id}`, { showload: true })
          if (res) {
            this.$toastr.s(`The Bankline file '${this.fileData.originalFilename}' was successfully deleted.`)
            this.$router.push('/admin/bandr/bankline/banklineinbound')
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this file.')
          } else {
            this.$snapbar.e(`Could not delete this file - ${e.message}`)
          }
        }
      }
    },
    async btnReconcileBanklineFile () {
      console.log('btnReconcileBanklineFile')
      const postObj = {
        id: this.id
      }
      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}reconcileBlFile`, postObj)
        if (res) {

        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to reconcile Bankline file data.')
        } else {
          this.$snapbar.e(`Could not reconcile file data - ${e.message}`)
        }
      }
    },
    onRowClick (params) {
    },
    onCellClick (params) {
      if (params.column.field !== 'id') {
        this.$router.push(`/admin/bandr/bankline/banklineabsrow/${params.row.id}`)
      }
    }
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.load()
    }
  }
}
</script>
<style scoped>
  td {
    cursor: pointer;
  }
</style>
