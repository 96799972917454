<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <h4 class="card-count">
              {{itemCountLabel}}
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">{{ chartText }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <pie-chart :chart-data="chartData" :options="chartOptions"></pie-chart>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PieChart from '@/Assets/Components/Charts/PieChart'
import interpolateColors from '@/Assets/Helpers/ColourHelper'

export default {
  components: {
    PieChart
  },
  name: 'BacsFpsWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  watch: {
    'widget.options': {
      async handler () {
        await this.load()
      },
      deep: true
    }
  },
  computed: {
    numberOfDays () {
      return this.widget.options.find(o => o.label === 'Number of Days').value
    },
    selectDataBy () {
      return this.widget.options.find(o => o.label === 'Select Data By').value
    },
    selectedGroupId () {
      var selectedGroupId = this.widget.options.find(o => o.label === 'Group').value
      return selectedGroupId !== null ? selectedGroupId : '00000000-0000-0000-0000-000000000000'
    }
  },
  data () {
    return {
      chartOptions: {
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            right: 4
          }
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              var prefix = data.labels[tooltipItem.index].substring(0, 1)
              var label = ''
              if (prefix === '£') {
                label = data.labels[tooltipItem.index].substring(1) + ': £' + parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
              } else {
                label = data.labels[tooltipItem.index] + ': ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '$&,')
              }
              return label
            }
          }
        }
      },
      itemCount: 0,
      itemCountLabel: '',
      chartData: {},
      chartText: '',
      colours: {}
    }
  },

  methods: {
    async load () {
      this.itemCount = 0
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/dashboard/getSummaryCount?groupId=${this.selectedGroupId}&numberOfDays=${this.numberOfDays}&selectDataBy=${this.selectDataBy}`)
      var labels = []
      var data = []

      var labelId = ''
      if (this.selectDataBy === 'TxAmount') {
        labelId = '£'
      }
      for (var i = 0; i < response.data.length; i++) {
        labels.push(labelId + response.data[i].label)
        data.push(response.data[i].count)
        this.itemCount += response.data[i].count
      }

      if (this.numberOfDays > 1) {
        this.chartText = 'In the last ' + this.numberOfDays + ' days'
      } else {
        this.chartText = 'In the last day'
      }

      if (this.selectedGroupId === '00000000-0000-0000-0000-000000000000') {
        this.chartText += ' for all groups'
      } else {
        var groupResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/${this.selectedGroupId}`)
        this.chartText += ' for the \'' + groupResponse.data.name + '\' group'
      }

      if (this.selectDataBy === 'Network') {
        this.colours = interpolateColors('rgb(249,237,105)', 'rgb(106,44,112)', labels.length + 1)
      } else if (this.selectDataBy === 'TxCount') {
        this.colours = interpolateColors('rgb(204,255,153)', 'rgb(102,153,0)', labels.length + 1)
      } else if (this.selectDataBy === 'TxAmount') {
        this.colours = interpolateColors('rgb(102,204,255)', 'rgb(0,102,255)', labels.length + 1)
      }

      if (this.selectDataBy === 'Network') {
        this.itemCountLabel = this.formatCount(this.itemCount) + (this.itemCount === 1 ? ' submission' : ' submissions')
      } else if (this.selectDataBy === 'TxCount') {
        this.itemCountLabel = this.formatCount(this.itemCount) + (this.itemCount === 1 ? ' item' : ' items')
      } else if (this.selectDataBy === 'TxAmount') {
        this.itemCountLabel = 'Value £ ' + this.formatValue(this.itemCount)
      }

      this.chartData = {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: this.colours
        }]
      }
    },

    // Piechart won't load the BacsMixin which contains these helpers so re-add them here.
    formatValue (value) {
      try {
        return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } catch (e) {
        return 0
      }
    },

    formatCount (count) {
      try {
        return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '$&,')
      } catch (e) {
        return 0
      }
    }
  },

  async mounted () {
    await this.load()
  }
}
</script>

<style>
</style>
