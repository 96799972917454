<template>
  <div class="container-fluid">
    <div class="row">
      <div class="alert alert-info" v-if="description">{{description}}</div>
    </div>
    <div v-if="allowedBand">
      <div class="form-group row">
        <label class="col-form-label col-md-2">Charge Set Type</label>
        <div class="col-md-10">
          <p-radio
            class="p-default p-round"
            value="SteppedRate"
            v-model="selectedChargeSet.type.$model"
            v-if="allowedRate"
            :disabled="readOnly"
          >Stepped Rate</p-radio>
          <p-radio
            class="p-default p-round"
            value="FixedRate"
            v-model="selectedChargeSet.type.$model"
            v-if="allowedRate"
            :disabled="readOnly"
          >Fixed Rate</p-radio>
          <p-radio
            class="p-default p-round"
            value="SteppedFixFee"
            v-model="selectedChargeSet.type.$model"
            :disabled="readOnly"
          >Stepped Fix Fee</p-radio>
          <p-radio
            class="p-default p-round"
            value="FixedFee"
            v-model="selectedChargeSet.type.$model"
            :disabled="readOnly"
          >Fixed Fee</p-radio>
        </div>
      </div>
      <div
        class="row"
        v-if="selectedChargeSet.type.$model == 'SteppedRate' || selectedChargeSet.type.$model == 'FixedRate'"
      >
        <div class="col-md-6" :class="{invalid: selectedChargeSet.minimum.$error}">
          <label class="form-label required">Minimum Fee {{valueHeader}}</label>
          <div class="input-group">
            <span class="input-group-text">£</span>
            <two-decimal-places-input
              class="form-control text-right"
              v-model.trim.number="selectedChargeSet.minimum.$model"
              :readonly="readOnly"
            />
          </div>
          <validation-messages v-model="selectedChargeSet.minimum" name="minimum"></validation-messages>
        </div>
        <div class="col-md-6" :class="{invalid: selectedChargeSet.maximum.$error}">
          <label class="form-label required">Maximum Fee {{valueHeader}}</label>
          <div class="input-group">
            <span class="input-group-text">£</span>
            <two-decimal-places-input
              class="form-control text-right"
              v-model.trim.number="selectedChargeSet.maximum.$model"
              :readonly="readOnly"
            />
          </div>
          <validation-messages v-model="selectedChargeSet.maximum" name="maximum"></validation-messages>
        </div>
      </div>
      <div class="row mt-15">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>
                <small>(greater than or equal to)</small>
                <br />
                {{typeHeader}}
              </th>
              <th>{{valueSymbol}} {{valueHeader}}</th>
              <th>
                <button
                  type="button"
                  class="btn btn-success"
                  @click="addBand"
                  v-if="!readOnly"
                >Add Charge Band</button>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(range,$index) in selectedChargeSet.ranges.$each.$iter" :key="$index">
              <td :class="{invalid: range.amount.$error}">
                <input
                  type="number"
                  step="1"
                  min="0"
                  class="form-control text-right"
                  v-model.trim.number="range.amount.$model"
                  :disabled="$index == 0"
                  :readonly="readOnly"
                />
                <validation-messages v-model="range.amount" :name="`${typeHeader} amount`">
                  <small
                    v-if="!range.amount.valid"
                    class="form-text small validation-messages"
                  >You cannot have duplicate {{typeHeader}} amounts</small>
                </validation-messages>
              </td>
              <td :class="{invalid: range.rate.$error}">
                <div class="input-group">
                  <span class="input-group-text">{{valueSymbol}}</span>
                  <input
                    v-if="selectedChargeSet.type.$model == 'SteppedRate' || selectedChargeSet.type.$model == 'FixedRate'"
                    type="number"
                    min="0"
                    step="0.01"
                    class="form-control text-right"
                    v-model.trim.number="range.rate.$model"
                    :readonly="readOnly"
                  />
                  <two-decimal-places-input
                    v-else
                    class="form-control text-right"
                    v-model.trim.number="range.rate.$model"
                    :readonly="readOnly"
                  />
                </div>
                <validation-messages v-model="range.rate" :name="valueHeader"></validation-messages>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="removeBand($index)"
                  v-if="$index != 0 && !readOnly"
                >Remove Charge Band</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <div class="form-group row" :class="{ invalid: selectedChargeSet.value.$error }">
        <label class="label-control col-md-3">{{valueHeader}}</label>
        <div class="col-md-5">
          <div class="input-group">
            <span class="input-group-text">£</span>
            <two-decimal-places-input
              class="form-control text-right"
              v-model.number="selectedChargeSet.value.$model"
              :readonly="readOnly"
            />
          </div>
          <validation-messages v-model="selectedChargeSet.value" :name="typeHeader"></validation-messages>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import TwoDecimalPlacesInput from '@/Assets/Components/TwoDecimalPlacesInput'

// Constants
import { billingProperties } from '@/Assets/Constants/billing'

export default {
  name: 'ChargeSets',
  components: {
    TwoDecimalPlacesInput
  },
  computed: {
    description () {
      switch (this.selectedChargeSetName) {
        case billingProperties.AdvanceNoticeLetter:
          return 'Charge made for sending an Advance Notice to a Customer via letter'
        case billingProperties.AdvanceNoticeEmail:
          return 'Charge made for sending an Advance Notice to a Customer via email'
        case billingProperties.PreCollectionNotification:
          return 'Charge made for a Pre-Collection Notification'
        case billingProperties.Collections:
          return 'Charge made for a Collection Request'
        case billingProperties.ConfirmationLetter:
          return 'Charge made for sending a Confirmation Letter to a Customer'
        case billingProperties.ConfirmationEmail:
          return 'Charge made for sending a Confirmation Email to a Customer'
        case billingProperties.FailedCollections:
          return 'Charge made for a Collection Failure'
        case billingProperties.IndemnityClaims:
          return 'Charge made for an Indemnity Claim'
        case billingProperties.PayerCancellation:
          return 'Charge made for suspending a Customer'
        case billingProperties.PayerReinstate:
          return 'Charge made for reinstating a Customer'
        case billingProperties.PayerSetup:
          return 'Charge made for a Customer Setup'
        case billingProperties.Refunds:
          return 'Charge made for a Refund'
        case billingProperties.ValidateBankAccount:
          return 'Charge made for a Validation on a bank account'
        case billingProperties.ValidateIBAN:
          return 'Charge made for a Validation on an IBAN'
        case billingProperties.BanklineSTD1Or2:
          return 'Charge made for a Bankline STD 1 or 2 day payment'
        case billingProperties.BanklineSTD0:
          return 'Charge made for a Bankline STD 0 day payment'
        case billingProperties.BanklineUrgent:
          return 'Charge made for a Bankline URG Payment'
        default:
          return ''
      }
    },
    typeHeader () {
      switch (this.selectedChargeSetName) {
        case billingProperties.Collections:
          return 'Collections'
        default:
          return ''
      }
    },
    valueHeader () {
      switch (this.selectedChargeSetName) {
        case billingProperties.AdvanceNoticeLetter:
        case billingProperties.AdvanceNoticeEmail:
          return 'Per Advance Notice'
        case billingProperties.PreCollectionNotification:
          return 'Per Pre-Collection Notification'
        case billingProperties.Collections:
          return 'Per Collection'
        case billingProperties.ConfirmationLetter:
          return 'Per Confirmation Letter'
        case billingProperties.ConfirmationEmail:
          return 'Per Confirmation Email'
        case billingProperties.FailedCollections:
          return 'Per Failed Collection'
        case billingProperties.IndemnityClaims:
          return 'Per Indemnity Claim'
        case billingProperties.PayerCancellation:
          return 'Per Customer Cancellation'
        case billingProperties.PayerReinstate:
          return 'Per Customer Reinstatement'
        case billingProperties.PayerSetup:
          return 'Per Customer Setup'
        case billingProperties.Refunds:
          return 'Per Refund'
        case billingProperties.ValidateBankAccount:
          return 'Per Bank account Validated'
        case billingProperties.ValidateIBAN:
          return 'Per IBAN Validated'
        case billingProperties.BanklineSTD1Or2:
          return 'Bankline STD 1 or 2 day payments'
        case billingProperties.BanklineSTD0:
          return 'Bankline STD 0 day payments'
        case billingProperties.BanklineUrgent:
          return 'Bankline URG Payments'

        default:
          return ''
      }
    },
    valueSymbol () {
      switch (this.selectedChargeSet.type.$model) {
        case 'SteppedRate':
        case 'FixedRate':
          return '%'
        case 'SteppedFixFee':
        case 'FixedFee':
          return '£'
        default:
          return ''
      }
    },
    allowedRate () {
      switch (this.selectedChargeSetName) {
        case billingProperties.Collections:
          return true
        default:
          return false
      }
    },
    allowedBand () {
      switch (this.selectedChargeSetName) {
        case billingProperties.Collections:
          return true
        default:
          return false
      }
    }
  },
  props: {
    selectedChargeSetName: {
      type: String,
      required: true
    },
    selectedChargeSet: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    addBand () {
      this.selectedChargeSet.ranges.$model.push({ amount: 0, rate: 0 })
      this.$nextTick(() => {
        this.selectedChargeSet.ranges.$each[this.selectedChargeSet.ranges.$model.length - 1].$reset()
      })
    },
    removeBand (index) {
      this.selectedChargeSet.ranges.$model.splice(index, 1)
    }
  }

}
</script>

<style lang="scss" scoped>
</style>
