<template>
<div class="row" >
  <div class="col-md-12">
    <div class="input">
      <div class="form-group">
        <label>{{label}}</label><span class="required" v-if="this.isRequired">*</span>
        <input class="form-control input-sm" type="password"
          :name="name"
          :value="value"
          :class="{invalid: $v.value.$error}"
          @input="$emit('input',$event.target.value); $v.value.$touch() "
          :placeholder="placeholder">
        <p class="validationText" v-if="!$v.value.required ">Required Field</p>
        <p class="validationText" v-if="!$v.value.maxLength ">Max Length is {{this.maxLength}}</p>
        <p class="validationText" v-if="!$v.value.minLength ">Min Length is {{this.minLength}}</p>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
export default {
  name: 'TextInput',
  props: [
    'placeholder',
    'label',
    'name',
    'value',
    'maxLength',
    'minLength',
    'isRequired'
  ],
  validations () {
    const o = {}
    o.value = {}
    // Required
    if (this.isRequired) {
      o.value.required = required
    } else {
      o.value.required = false
    }

    // Max length
    if (this.maxLength) {
      o.value.maxLength = maxLength(this.maxLength)
    } else {
      o.value.maxLength = false
    }

    // Min length
    if (this.minLength) {
      o.value.minLength = minLength(this.minLength)
    } else {
      o.value.minLength = false
    }
    return o
  }
}
</script>

<style>
</style>
