import Store from '@/Store/index'
import roles from '@/Assets/Constants/roles'
import customerTypes from '@/Assets/Constants/customerTypes'
import licence from '@/Assets/Constants/licence'
import customerStatuses from '@/Assets/Constants/customerStatuses'

export default [
  {
    menu_title: 'Client',
    menu_icon: 'ti-agenda',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.Limited, roles.ReadOnly, roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.Qa, roles.DevOps, roles.Developer, roles.Implementations])
    },
    child_routes: [
      {
        path: '/admin/clients/create',
        menu_title: 'Create',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations])
        }
      },
      {
        path: '/admin/clients/details',
        menu_title: 'Details',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.Limited, roles.ReadOnly, roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.Qa, roles.DevOps, roles.Developer, roles.Implementations])
        }
      },
      {
        path: '/admin/clients/incomplete',
        menu_title: 'Incomplete',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations])
        }
      },
      {
        path: '/collections/messages/optout',
        menu_title: 'Opt Out',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Qa, roles.Implementations, roles.Developer])
        }
      },
      {
        path: '/admin/charge/configuration',
        menu_title: 'Charge Configuration',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations])
        }
      },
      {
        path: '/admin/charge/charges',
        menu_title: 'Charges',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Implementations])
        }
      },
      {
        path: '/admin/charge/historic',
        menu_title: 'Historic Charge Configuration',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations])
        }
      },
      {
        path: '/admin/charge/chargeconfigurationgroups',
        menu_title: 'Charge Configuration Groups',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations])
        }
      },
      {
        path: '/admin/client/children',
        menu_title: 'Child Clients',
        showMethod: async () => {
          return Store.getters.vueAppVersion >= 110 && Store.getters.isInRole(roles.LZAdmin) && Store.getters.selectedCustomerObject.type !== customerTypes.general
        }
      },
      {
        path: '/admin/client/associations',
        menu_title: 'Client Associations',
        showMethod: async () => {
          return Store.getters.vueAppVersion >= 110 && Store.getters.isInRoles([roles.LZAdmin, roles.Finance])
        }
      },
      {
        path: '/admin/clients/emails',
        menu_title: 'Emails',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.ClientAdmin, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations])
        }
      },
      {
        path: '/admin/clients/licence',
        menu_title: 'Licence',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations])
        }
      },
      {
        path: '/admin/client/certificates',
        menu_title: 'Certificates',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.DevOps, roles.Implementations])
        }
      },
      {
        path: '/admin/clients/dashboard',
        menu_title: 'Dashboard',
        showMethod: async () => {
          return Store.getters.isInRole(roles.ClientAdmin)
        }
      },
      {
        path: '/admin/clients/auditlogs',
        menu_title: 'Audit Log',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations])
        }
      },
      // {
      //   path: '/admin/clients/agent',
      //   menu_title: 'Agent Details',
      //   showMethod: async () => {
      //     return Store.getters.isInRole(roles.AgentConfigure)
      //   }
      // },
      {
        path: '/admin/clients/reportsubscriptions',
        menu_title: 'Report Subscriptions',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.Implementations])
        }
      },
      {
        path: '/admin/clients/partnumbers',
        menu_title: 'Part Numbers',
        showMethod: async () => {
          // return Store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations])
          return false
        }
      },
      {
        path: '/admin/clients/customerSearch',
        menu_title: 'Search',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.Implementations])
        }
      }
    ]
  },
  {
    menu_title: 'Billing',
    menu_icon: 'ti-money',
    showMethod: async () => {
      return Store.getters.isInRole(
        roles.Finance
      )
    },
    child_routes: [
      {
        path: '/admin/billing/process',
        menu_title: 'Process Billing',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance)
        }
      },
      {
        path: '/admin/billing/report',
        menu_title: 'Billing Report',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance)
        }
      }

    ]
  },
  {
    menu_title: 'Settlements',
    menu_icon: 'ti-marker',
    showMethod: async () => {
      return Store.getters.isInRoles(
        [roles.Finance, roles.Standard, roles.ClientAdmin]
      )
    },
    child_routes: [
      {
        path: '/admin/settlements/potential',
        menu_title: 'Potential Settlements',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance)
        }
      },
      {
        path: '/admin/settlements/processed',
        menu_title: 'Processed Settlements',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance)
        }
      },

      {
        path: '/admin/settlements/report',
        menu_title: 'Settlement Report',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.Finance, roles.Standard, roles.ClientAdmin])
        }
      }
    ]
  },
  {
    menu_title: 'Invoicing',
    menu_icon: 'ti-envelope',
    showMethod: async () => {
      return Store.getters.isInRoles(
        [roles.Finance, roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Qa, roles.Developer, roles.Implementations]
      )
    },
    child_routes: [
      {
        path: '/admin/invoicing/single',
        menu_title: 'Create Single Client Invoice',
        name: 'Invoice',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance)
        }
      },
      {
        path: '/admin/invoicing/multiple',
        menu_title: 'Create Multi-Client Invoices',
        name: 'Invoices',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance)
        }
      },
      {
        path: '/admin/invoicing/list',
        menu_title: 'List Invoices',
        name: 'ListInvoices',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.Finance, roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Qa, roles.Developer, roles.Implementations])
        }
      },
      {
        path: '/admin/invoicing/analysis',
        menu_title: 'Invoice Analysis',
        name: 'InvoiceAnalysis',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance)
        }
      }
    ]
  },
  {
    menu_title: 'Reconciliation',
    menu_icon: 'ti-direction-alt',
    name: 'AdminBAndR',
    showMethod: async () => {
      return Store.getters.isInRole(roles.Finance)
    },
    child_routes: [
      {
        path: '/admin/bandr/banklinefilelist',
        menu_title: 'Bankline File List',
        name: 'BanklineFileList',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance)
        }
      },
      {
        path: '/admin/bandr/bankledgerbalances',
        menu_title: 'Bank Ledger Balances',
        name: 'BankLedgerBalances',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance) && parseInt(process.env.VUE_APP_VERSION) >= 110
        }
      },
      {
        path: '/admin/bandr/indemnityclaims',
        menu_title: 'Indemnity Claims',
        name: 'IndemnityClaims',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance) && parseInt(process.env.VUE_APP_VERSION) >= 110
        }
      },
      {
        path: '/admin/bandr/ledgertransactions',
        menu_title: 'Ledger Transactions',
        name: 'LedgerTransactions',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance) && parseInt(process.env.VUE_APP_VERSION) >= 110
        }
      },
      {
        path: '/admin/bandr/clientbalances',
        menu_title: 'Client Balances',
        name: 'ClientBalances',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance) && parseInt(process.env.VUE_APP_VERSION) >= 110
        }
      },
      {
        path: '/admin/bandr/ledgerclientliability',
        menu_title: 'Ledger Client Liability',
        name: 'LedgerClientLiability',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance) && parseInt(process.env.VUE_APP_VERSION) >= 110
        }
      },
      {
        path: '/admin/bandr/nominalCodeTotals',
        menu_title: 'Nominal Code Totals',
        name: 'NominalCodeTotals',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance) && parseInt(process.env.VUE_APP_VERSION) >= 110
        }
      },
      {
        path: '/admin/bandr/nominalcodes',
        menu_title: 'Manage Nominal Codes',
        name: 'AdminBAndRNominalCodes',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance) && parseInt(process.env.VUE_APP_VERSION) >= 110
        }
      }
    ]
  },
  {
    menu_title: 'Users',
    menu_icon: 'ti-user',
    path: '/admin/users/list',
    showMethod: async () => {
      if (Store.getters.selectedCustomer !== process.env.VUE_APP_LANDZ_ID) {
        return Store.getters.isInRoles([roles.Limited, roles.ReadOnly, roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations])
      } else {
        return Store.getters.isInRoles([roles.DevOps, roles.Qa, roles.Developer])
      }
    }
  },
  {
    menu_title: 'Service Accounts',
    menu_icon: 'ti-desktop',
    path: '/admin/serviceaccounts/list',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.Standard, roles.ClientAdmin, roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations])
    }
  },
  {
    menu_title: 'Groups',
    path: '/admin/groups/list',
    menu_icon: 'ti-layers-alt',
    showMethod: async () => {
      return Store.getters.selectedCustomerObject.status !== customerStatuses.incomplete && Store.getters.isInRoles([roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations])
    }
  },
  {
    menu_title: 'Bank Accounts',
    menu_icon: 'ti-package',
    path: '/admin/bankaccounts/list',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations])
    }
  },
  {
    menu_title: 'Service User Numbers',
    menu_icon: 'ti-shortcode',
    path: '/admin/suns/list',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations])
    }
  },

  // {
  //   menu_title: 'Commission Agents',
  //   menu_icon: 'ti-marker-alt',
  //   name: 'Commission Agents',
  //   showMethod: async () => {
  //     return Store.getters.isInRole(roles.Finance)
  //   },

  //   child_routes: [
  //     {
  //       path: '/admin/commissionagents/agents',
  //       menu_title: 'Agents',
  //       name: 'ListAgents',
  //       showMethod: async () => {
  //         return Store.getters.isInRole(roles.Finance)
  //       }
  //     }, {
  //       path: '/admin/commissionagents/commissions',
  //       menu_title: 'Commissions',
  //       name: 'ListCommissions',
  //       showMethod: async () => {
  //         return Store.getters.isInRole(roles.Finance)
  //       }
  //     },
  //     {
  //       path: '/admin/commissionagents/statements',
  //       menu_title: 'Statements',
  //       name: 'ListStatements',
  //       showMethod: async () => {
  //         return Store.getters.isInRole(roles.Finance)
  //       }
  //     },
  //     {
  //       path: '/admin/commissionagents/statementaudittrail',
  //       menu_title: 'Statement Audit Trail',
  //       name: 'ListStatementAuditTrail',
  //       showMethod: async () => {
  //         return Store.getters.isInRole(roles.Finance)
  //       }
  //     },
  //     {
  //       path: '/admin/commissionagents/reports/paymentdetailreport',
  //       menu_title: 'Payment Detail Report',
  //       name: 'PaymentDetailReport',
  //       showMethod: async () => {
  //         return Store.getters.isInRole(roles.Finance)
  //       }
  //     },
  //     {
  //       path: '/admin/commissionagents/reports/commissioncomparisonreport',
  //       menu_title: 'Commission Comparison Report',
  //       name: 'CommissionComparisonReport',
  //       showMethod: async () => {
  //         return Store.getters.isInRole(roles.Finance)
  //       }
  //     }
  //   ]
  // },

  {
    menu_title: 'Reports',
    menu_icon: 'ti-receipt',
    name: 'AdminReports',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.ClientAdmin, roles.LZAdmin, roles.Finance, roles.Implementations])
    },
    child_routes: [
      {
        menu_title: 'Indemnity Claims',
        menu_icon: 'ti-direction-alt',
        path: '/collections/indemnity',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations])
        }
      },
      {
        path: '/admin/reports/liability',
        menu_title: 'Liability Report',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance)
        }
      },
      {
        path: '/admin/reports/holding',
        menu_title: 'Holding Report',
        showMethod: async () => {
          return Store.getters.vueAppVersion >= 110 && Store.getters.isInRole(roles.Finance)
        }
      },
      {
        path: '/admin/reports/actionsreport',
        menu_title: 'Actions Report',
        name: 'AdminReportsActionReport',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.ClientAdmin, roles.LZAdmin, roles.Implementations])
        }
      },
      {
        path: '/admin/reports/monthlyfees',
        menu_title: 'Monthly Fees Report',
        name: 'MonthlyFeesReport',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance)
        }
      },
      {
        path: '/admin/reports/psadhoc',
        menu_title: 'PS and Ad-Hoc Fees Report',
        name: 'PsAdHocFeesReport',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance)
        }
      },
      {
        path: '/collections/reporting/expectedcollections',
        menu_title: 'Expected Collections',
        name: 'ExpectedCollections',
        showMethod: async () => {
          return Store.getters.isInRole(roles.Finance)
        }
      }
    ]
  },
  {
    menu_title: 'Security Policy',
    menu_icon: 'ti-lock',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer])
    },
    child_routes: [
      {
        path: '/admin/securitypolicy/useraccounts',
        menu_title: 'User Accounts',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer])
        }
      },
      {
        path: '/admin/securitypolicy/bankaccounts',
        menu_title: 'Bank Accounts',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer]) && Store.getters.selectedCustomer === process.env.VUE_APP_LANDZ_ID
        }
      },
      {
        path: '/admin/securitypolicy/login',
        menu_title: 'Login Policy',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer])
        }
      },
      {
        path: '/admin/securitypolicy/passwordcomplexity',
        menu_title: 'Password Complexity',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer])
        }
      },
      {
        path: '/admin/securitypolicy/incorrectpassword',
        menu_title: 'Incorrect Password',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer])
        }
      },
      {
        path: '/admin/securitypolicy/inactivitytimeout',
        menu_title: 'Inactivity Timeout',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer])
        }
      },
      {
        path: '/admin/securitypolicy/timerestrictions',
        menu_title: 'Time Restrictions',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer])
        }
      },
      {
        path: '/admin/securitypolicy/ipaddressrestrictions',
        menu_title: 'IP Address Restrictions',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer])
        }
      },
      {
        path: '/admin/securitypolicy/paymentgroups',
        menu_title: 'Payment Groups',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer])
        }
      }
    ]
  },
  {
    menu_title: 'GDPR',
    menu_icon: 'ti-server',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.ClientAdmin, roles.LZAdmin, roles.Implementations])
    },
    child_routes: [
      {
        path: '/admin/GDPR/search',
        menu_title: 'Search Personal Data',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.ClientAdmin, roles.LZAdmin, roles.Implementations])
        }
      },
      {
        path: '/admin/GDPR/deleteGroupData',
        menu_title: 'Delete Group Data',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations]) && false
        }
      }
    ]
  },
  {
    menu_title: 'Collections',
    menu_icon: 'ti-cloud-down',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Qa, roles.CustomerAdministrator, roles.DevOps, roles.Implementations])
    },
    child_routes: [
      {
        path: '/admin/customers/onlinesignup/urls/',
        menu_title: 'Online Signup URLs',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations]) && Store.getters.hasLicence(licence.collectionsOnBoarding) && parseInt(process.env.VUE_APP_VERSION) >= 102 && Store.getters.selectedCustomer !== process.env.VUE_APP_LANDZ_ID
        }
      },
      {
        path: '/admin/customers/onlinesignup',
        menu_title: 'Online Signup Allowed Hosting URLs',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations]) && Store.getters.hasLicence(licence.collectionsOnBoarding) && parseInt(process.env.VUE_APP_VERSION) >= 102 && false && Store.getters.selectedCustomer !== process.env.VUE_APP_LANDZ_ID
        }
      },
      {
        path: '/collections/onlinesignup/styles/',
        menu_title: 'Manage Online Signup Stylesheet',
        menu_icon: 'ti-link',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations]) && Store.getters.hasLicence(licence.collectionsOnBoarding) && parseInt(process.env.VUE_APP_VERSION) >= 102 && Store.getters.selectedCustomer !== process.env.VUE_APP_LANDZ_ID
        }
      },
      {
        menu_title: 'Email Bounces',
        menu_icon: 'ti-email',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations])
        },
        path: '/admin/email/bounces',
        counterData: 'bouncedEmailsCount'
      },
      {
        path: '/collections/portal/configuration/',
        menu_title: 'Portal Configuration',
        menu_icon: 'ti-comments',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations]) && Store.getters.hasLicence(licence.collectionsPayerPortal) && false
        }
      },
      {
        path: '/admin/system/emailmonitoring',
        menu_title: 'Email Monitoring',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations])
        }
      },
      {
        path: '/admin/system/onlinesignupapiusage',
        menu_title: 'Online Signup API Usage',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations]) && Store.getters.hasLicence(licence.collectionsOnBoarding) && parseInt(process.env.VUE_APP_VERSION) >= 110 && false
        }
      },
      {
        path: '/admin/system/onlinesignupcertificateimage',
        menu_title: 'Online Signup Certificate Image',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations]) && Store.getters.hasLicence(licence.collectionsOnBoarding) && parseInt(process.env.VUE_APP_VERSION) >= 102 && Store.getters.selectedCustomer === process.env.VUE_APP_LANDZ_ID
        }
      },
      {
        path: '/admin/collections/templateapproval',
        menu_title: 'Template Approval',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations])
        }
      },
      {
        path: '/admin/system/cancelschedules',
        menu_title: 'Cancel Schedules or Customers',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations])
        }
      },
      {
        path: '/admin/system/suspendschedules',
        menu_title: 'Suspend Customers',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations])
        }
      },
      {
        path: '/admin/collections/movecustomers',
        menu_title: 'Move Customers',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations]) && Store.getters.selectedCustomerObject.paygateId !== process.env.VUE_APP_LANDZ_ID
        }
      },
      {
        path: '/admin/system/customermigrationreport',
        menu_title: 'Customer Migration Report',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.CustomerAdministrator, roles.Implementations])
        }
      },
      {
        path: '/collections/defaultmessagedesigner',
        menu_title: 'Edit Default Templates',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations]) && Store.getters.selectedCustomerObject.paygateId === process.env.VUE_APP_LANDZ_ID
        }
      },
      {
        path: '/collections/submissioncontrol',
        menu_title: 'Submission Control',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.Finance]) && Store.getters.selectedCustomerObject.paygateId === process.env.VUE_APP_LANDZ_ID
        }
      },
      {
        path: '/collections/tools/reportgeneration',
        menu_title: 'Inject BACS Reports',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.Qa])
        }
      },
      {
        path: '/collections/postworksouttray',
        menu_title: 'Postworks Out Tray',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations, roles.DevOps, roles.Developer]) && Store.getters.selectedCustomerObject.paygateId === process.env.VUE_APP_LANDZ_ID
        }
      },
      {
        path: '/collections/messagingadministration',
        menu_title: 'Messaging Administration',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.DevOps]) && Store.getters.selectedCustomerObject.paygateId === process.env.VUE_APP_LANDZ_ID
        }
      }
    ]
  },
  {
    menu_title: 'System',
    menu_icon: 'ti-panel',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.LZAdmin, roles.Marketing, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations]) && Store.getters.selectedCustomerObject.paygateId === process.env.VUE_APP_LANDZ_ID
    },
    child_routes: [
      {
        path: '/admin/system/reccuringchargesettings',
        menu_title: 'Recurring Charges Settings',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.Finance, roles.LZAdmin, roles.Implementations])
        }
      },
      {
        path: '/admin/system/securelimits',
        menu_title: 'Secure Limits',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.Finance, roles.Implementations])
        }
      },
      {
        path: '/admin/system/maintenancewindows',
        menu_title: 'Maintenance Windows',
        showMethod: async () => {
          return Store.getters.isInRole(roles.DevOps)
        }
      },
      {
        path: '/admin/system/stories',
        menu_title: 'News Stories',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.Marketing, roles.DevOps])
        }
      },
      {
        path: '/admin/bandr/bankline/banklineconfig',
        menu_title: 'Bankline Configuration',
        name: 'AdminBAndRConfig',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.DevOps, roles.Finance])
        }
      },
      {
        path: '/admin/system/listhsm',
        menu_title: 'Manage HSM',
        showMethod: async () => {
          return Store.getters.isInRole(roles.DevOps)
        }
      },
      {
        path: '/admin/system/logging/view',
        menu_title: 'Log Viewer',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.DevOps, roles.Qa, roles.Developer, roles.LZAdmin, roles.Implementations])
        }
      },
      {
        path: '/admin/system/eventlog/eventlog',
        menu_title: 'Event Log',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.DevOps, roles.Qa, roles.Developer, roles.Implementations])
        }
      },
      {
        path: '/admin/customerthreshold/emails',
        menu_title: 'Customer Threshold Emails',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.LZAdmin, roles.Implementations]) && false
        }
      },
      {
        path: '/admin/system/logging',
        menu_title: 'Logging Settings',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.DevOps, roles.Developer])
        }
      },
      {
        path: '/payments/bacsAdmin/bacsConfig',
        menu_title: 'BACS Config',
        showMethod: async () => {
          return Store.getters.isInRole(roles.DevOps)
        }
      },
      {
        path: '/admin/system/ImportEISCD',
        menu_title: 'EISCD Import',
        showMethod: async () => {
          return Store.getters.isInRole(roles.DevOps)
        }
      },
      {
        path: '/admin/system/ImportWeightTable',
        menu_title: 'Weight Table Import',
        showMethod: async () => {
          return Store.getters.isInRole(roles.DevOps)
        }
      },
      {
        path: '/admin/system/vat',
        menu_title: 'VAT',
        name: 'ListVat',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.Finance, roles.LZAdmin, roles.Implementations])
        }
      },
      {
        path: '/admin/system/appsettings',
        menu_title: 'App Settings',
        showMethod: async () => {
          return Store.getters.isInRole(roles.DevOps)
        }
      },
      {
        path: '/payments/bacsreports/reportProcessorStatus',
        menu_title: 'Report Processor Status',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.DevOps])
        }
      },
      {
        path: '/admin/system/createtestdata',
        menu_title: 'Create Test Data',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.Developer, roles.Qa]) && process.env.VUE_APP_VERSION_MODE === 'Development'
        }
      }
    ]
  }
]
