<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          <help-icon docPath="/paygate-collections/messaging/assigntemplates/" />Assign Group Templates
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group">
          <div class="col-md-2 required">Select a Group</div>
          <div class="col-md-4">
            <group-select
              v-model="assignTemplatesGroup"
              :groups="customerGroups"
              @input="groupChange"
              :clearable="false"
            ></group-select>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <b-dropdown variant="primary">
          <!-- no-caret -->
          <span slot="button-content">
            <i class="fa fa-plus rpad"></i>Create Template
          </span>
          <b-dropdown-item @click="goToTemplate('EmailDesigner')">
            <i class="fa fa-at rpad" title="Email"></i>Email
          </b-dropdown-item>
          <b-dropdown-item @click="goToTemplate('LetterDesigner')" v-if="lettersAllowed">
            <i class="fa fa-envelope rpad" title="Letter"></i>Letter
          </b-dropdown-item>
          <b-dropdown-item @click="goToTemplate('SmsDesigner')" v-if="allowSms">
            <i class="fa fa-mobile rpad" title="SMS"></i>SMS
          </b-dropdown-item>
        </b-dropdown>
        <b-button class="ml-2" variant="secondary" @click.prevent="removeAction" v-if="actionId && actionId !== null">Clear Action</b-button>
      </div>
    </div>
    <div class="idb-block" v-if="assignTemplatesGroup !== null && assignTemplatesGroup !== '00000000-0000-0000-0000-000000000000'">
      <div class="idb-block-title">
        <h2>Standard Messages</h2>
      </div>
      <div class="idb-block-content" v-if="!disableInteraction">
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.advanceNotice"
              title="Advance Notice"
              subtitle="Sent to notify the customer of a change to a single upcoming Collection."
              :template-options="approvedTemplates"
              :message-type="0"></assignment>
          </b-col>
        </b-row>
        <b-row v-if="false">
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.cancellationNotice"
              title="Cancellation Notice"
              :template-options="templates"
              subtitle="Sent to the customer on cancellation of a schedule."
              :message-type="12"></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.confirmationLetter"
              subtitle="Sent to the customer confirming their enrolment in a Direct Debit scheme."
              title="Confirmation Letter"
              :template-options="approvedTemplates"
              :message-type="19"></assignment>
          </b-col>
        </b-row>
        <b-row v-if="hasOnboarding">
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.emailVerification"
              title="Email Verification"
              subtitle="Sent to the customer when email verification is required."
              :template-options="templates"
              :message-type="8"></assignment>
          </b-col>
        </b-row>
        <b-row v-if="false">
          <b-col><!-- this is apparently redundant in JFTP -->
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.firstPayment"
              title="First Collection"
              subtitle="Sent to the customer prior to the first Collection of a plan."
              :template-options="approvedTemplates"
              :message-type="1"></assignment>
          </b-col>
        </b-row>

        <b-row v-if="false">
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.multipleScheduleUpdate"
              title="Multiple Schedule Update"
              subtitle="Sent to notify the customer of changes to multiple future Collections."
              :template-options="templates"
              :message-type="2"
            ></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.notificationOfChange"
              title="Notification Of Change"
              :template-options="approvedTemplates"
              subtitle="Sent to the customer to notify changes to their Service User."
              :message-type="16"></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.migrationNotificationOfChange"
              title="Notification Of Change (Migration)"
              subtitle="Sent to the customer to notify changes to their Service User upon migration."
              :template-options="approvedTemplates"
              :message-type="18"></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.payerClosed"
              title="Customer Closed"
              subtitle="Sent when a customer status is set to 'Closed'."
              :template-options="templates"
              :message-type="14"></assignment>
          </b-col>
        </b-row>
        <b-row v-if="false">
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.payerTransferred"
              title="Customer Transferred"
              subtitle="Sent to the customer upon migration to another customer."
              :template-options="templates"
              :message-type="15"></assignment>
          </b-col>
        </b-row>
        <b-row v-if="hasRefunds">
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.refundCancelled"
              title="Refund Cancelled"
              subtitle="Sent to the customer when a planned refund is cancelled."
              :template-options="templates"
              :message-type="17"></assignment>
          </b-col>
        </b-row>
        <b-row v-if="hasRefunds">
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.refundRequested"
              title="Refund Requested"
              subtitle="Sent to the customer when a refund is requested."
              :template-options="templates"
              :message-type="10"></assignment>
          </b-col>
        </b-row>
        <b-row v-if="allowSms">
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.preCollectionNotification"
              title="Pre Collection Notification"
              subtitle="SMS Sent to customer prior to direct debit collection"
              :template-options="templates"
              :message-type="20"></assignment>
          </b-col>
        </b-row>
<!--      </div>-->
<!--      <div class="idb-block-footer">-->
<!--        <b-btn variant="primary" :disabled="isLoading" @click.prevent="saveChanges">Save</b-btn>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="idb-block" v-if="assignTemplatesGroup !== null && assignTemplatesGroup !== '00000000-0000-0000-0000-000000000000'">-->
<!--      <div class="idb-block-title">-->
<!--        <h2>Advanced Messaging</h2>-->
<!--      </div>-->
<!--      <div class="idb-block-content">-->
        <b-row v-if="false">
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.portalMessageSent"
              title="Customer Portal Message Notification"
              subtitle="Sent to the customer when a message is recieved through the customer portal."
              :template-options="templates"
              :message-type="9"></assignment>
          </b-col>
        </b-row>
      </div>
      <div v-if="disableInteraction">
        <Loading></Loading>
      </div>
      <div class="idb-block-footer">
        <b-btn variant="primary" :disabled="isLoading" @click.prevent="saveChanges">Save Standard Messaging</b-btn>
      </div>
    </div>

    <div class="idb-block" v-if="assignTemplatesGroup !== null && assignTemplatesGroup !== '00000000-0000-0000-0000-000000000000'">
      <div class="idb-block-title">
        <h2>Custom Messages</h2>
      </div>
      <div class="idb-block-content" v-if="!disableInteraction">
        <b-row><b-col cols="1"><b-button @click="addCustomType" variant="primary"><i class="fa fa-plus mr-2"></i>Add</b-button></b-col><b-col>Adds another message type that can be used for this group.</b-col></b-row>
        <b-row v-for="(temp, i) in customTemplates" :key="i">
          <b-col>
            <assignment
              v-model="customTemplates[i]"
              :title="temp.title"
              :template-options="templates" :allowDefaults="false"
              :custom="true" :disabled="disableInteraction"
              @deleteClicked="deleteMessageType"></assignment>
          </b-col>
        </b-row>
      </div>
      <div v-if="disableInteraction">
        <Loading></Loading>
      </div>
      <div class="idb-block-footer">
        <b-btn variant="primary" :disabled="isLoading || disableInteraction || !allCustomDefined" @click.prevent="saveCustomTemplates">Save Custom Messaging</b-btn>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '@/Lib/eventBus'
import TemplateAssignment from '@/Components/Collections/TemplateAssignment.vue'
import axios from 'axios'
import _ from 'lodash'
import Loading from '@/Assets/Components/Loading/Loading'
import loading from '@/Assets/Mixins/LoadingMixin'
import {mapGetters} from "vuex";
import roles from "@/Assets/Constants/roles";
export default {
  mixins: [loading],
  data () {
    return {
      actionId: null,
      disableInteraction: true,
      group: null,
      loaded: false,
      templates: { emails: [], letters: [], sms: [] },
      approvedTemplates: { emails: [], letters: [], sms: [] },
      customTemplates: [],
      messageTemplates: {
        firstPayment: {
          smsEnabled: false,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        advanceNotice: {
          smsEnabled: false,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        multipleScheduleUpdate: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        customMessage1: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        customMessage2: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        customMessage3: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        customMessage4: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        customMessage5: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        emailVerification: {
          smsEnabled: false,
          emailEnabled: true,
          letterEnabled: false,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        portalMessageSent: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: false,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        refundRequested: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        cancellationNotice: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        payerClosed: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        payerTransferred: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        notificationOfChange: {
          smsEnabled: false,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        migrationNotificationOfChange: {
          smsEnabled: false,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        refundCancelled: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        confirmationLetter: {
          smsEnabled: false,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        preCollectionNotification: {
          smsEnabled: true,
          emailEnabled: false,
          letterEnabled: false,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        }
      }
    }
  },
  components: {
    assignment: TemplateAssignment,
    Loading
  },
  async mounted () {
    this.$store.dispatch('getMessageChannels')
    this.actionId = this.$route.query.actionId
    await this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
    await Promise.all([
      this.loadTemplates(this.paygateId)
    ])
    // var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
    // if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
    //   this.selectedGroup = this.customerGroups.find(i => i.groupId === defaultGroupId)
    //   // Default group might be a FPS group which means it can't be the BACS default group and vice versa.
    //   if (this.selectedGroup !== undefined) {
    //     this.assignTemplatesGroup = defaultGroupId
    //   }
    // }
    // this.groupChange(this.assignTemplatesGroup)
    if (this.assignTemplatesGroup !== undefined && this.assignTemplatesGroup !== null) {
      await this.groupChange(this.assignTemplatesGroup)
    }
  },
  methods: {
    async saveChanges () {
      const paygateId = this.paygateId
      const groupId = this.assignTemplatesGroup
      const messageTemplates = this.messageTemplates
      let url = `${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates?groupId=${groupId}`
      if (paygateId) {
        url += `&paygateId=${paygateId}`
      }
      try {
        await axios.post(url, messageTemplates, { showload: true, params: { paygateId: this.paygateId } })
        this.$toastr.s('Message template changes saved.', 'Success')
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to save changes', 'An error occurred')
      }
    },
    async saveCustomTemplates () {
      var valueArr = this.customTemplates.map(function(item){ return item.title });
      var isDuplicate = valueArr.some(function(item, idx){
        return valueArr.indexOf(item) != idx
      });
      if (isDuplicate) {
        this.$toastr.e('Duplicate custom type names found, please ensure the message types are unique')
      } else {
        const groupId = this.assignTemplatesGroup
        const messageTemplates = this.messageTemplates
        let url = `${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/custom/${groupId}`
        try {
          await axios.put(url, this.customTemplates, {showload: true, params: {paygateId: this.paygateId}})
          this.$toastr.s('Custom Messages saved.', 'Success')
        } catch (e) {
          console.error(e)
          this.$toastr.e('Unable to save custom messages', 'An error occurred')
        }
      }
    },
    async loadTemplates (paygateId) {
      this.disableInteraction = true
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/list`, { showload: true, params: { paygateId: this.paygateId } })
      this.templates = response.data.standard
      this.approvedTemplates = response.data.approved
      this.disableInteraction = false
    },
    async loadCustomMessages () {
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/custom/${this.assignTemplatesGroup}`, { showload: true, params: { paygateId: this.paygateId } })
      this.customTemplates = response.data
    },
    async addCustomType () {
      var count = this.customTemplates.length
      const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/custom/${this.assignTemplatesGroup}`, {
          title: 'Custom Message' + (count > 0 ? ' ' + (count +1) : ''),
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: '00000000-0000-0000-0000-000000000000',
          emailTemplate: '00000000-0000-0000-0000-000000000000',
          letterTemplate: '00000000-0000-0000-0000-000000000000'
        }, { params: { paygateId: this.paygateId } })
        this.customTemplates.push(response.data)
    },
    async groupChange (group) {
      if (group !== null) {
        this.disableInteraction = true
        const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates?&groupId=${group}`, { showload: true, params: { paygateId: this.paygateId } })
        //this.messageTemplates = { ...this.messageTemplates, ...response.data }
        if (Object.keys(response.data).length === 0) {
          this.messageTemplates = {
            firstPayment: {
              smsEnabled: false,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            advanceNotice: {
              smsEnabled: false,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            multipleScheduleUpdate: {
              smsEnabled: true,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            customMessage1: {
              smsEnabled: true,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            customMessage2: {
              smsEnabled: true,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            customMessage3: {
              smsEnabled: true,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            customMessage4: {
              smsEnabled: true,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            customMessage5: {
              smsEnabled: true,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            emailVerification: {
              smsEnabled: false,
              emailEnabled: true,
              letterEnabled: false,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            portalMessageSent: {
              smsEnabled: true,
              emailEnabled: true,
              letterEnabled: false,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            refundRequested: {
              smsEnabled: true,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            cancellationNotice: {
              smsEnabled: true,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            payerClosed: {
              smsEnabled: true,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            payerTransferred: {
              smsEnabled: true,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            notificationOfChange: {
              smsEnabled: false,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            migrationNotificationOfChange: {
              smsEnabled: false,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            refundCancelled: {
              smsEnabled: true,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            confirmationLetter: {
              smsEnabled: false,
              emailEnabled: true,
              letterEnabled: true,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            },
            preCollectionNotification: {
              smsEnabled: true,
              emailEnabled: false,
              letterEnabled: false,
              smsTemplate: null,
              emailTemplate: null,
              letterTemplate: null
            }
          }
        }
        for(var property in response.data) {
          if(this.messageTemplates[property] !== undefined) {
            this.messageTemplates[property].smsTemplate = response.data[property].smsTemplate
            this.messageTemplates[property].emailTemplate = response.data[property].emailTemplate
            this.messageTemplates[property].letterTemplate = response.data[property].letterTemplate
          }
        }
        this.messageTemplates.firstPayment.smsEnabled = false
        this.messageTemplates.advanceNotice.smsEnabled = false
        this.messageTemplates.confirmationLetter.smsEnabled = false
        this.messageTemplates.emailVerification.smsEnabled = false
        this.messageTemplates.emailVerification.letterEnabled = false
        await this.loadCustomMessages()
        this.loaded = true
        this.disableInteraction = false
      }
    },
    goToTemplate (route) {
      this.$router.push({ name: route })
    },
    removeAction () {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}messages/clearaction/${this.actionId}`, { params: { paygateId: this.paygateId } }).then(
      (response) => {
        this.$toastr.s('Action cleared')
        this.actionId = null
      }
      )
    },
    async deleteMessageType (templateId) {
      const response = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/custom/${this.assignTemplatesGroup}/${templateId}`, { showload: true, params: { paygateId: this.paygateId } })
      if (response.status === 200) {
        await this.loadCustomMessages()
        this.$toastr.s('Message type deleted.', 'Success')
      } else {
        this.$toastr.e('Message type could not be deleted.', 'Failure')
      }
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    lettersAllowed (){
      let availMessageChannels = this.$store.getters.messageChannels
      return availMessageChannels.includes(1)
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.rightsGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.rightsGroups).map(x => {
          let obj = {}
          obj.groupId = x.groupId
          obj.name = x.name
          obj.colour = x.colour
          return obj
        })
      }
      /*if(ret.length > 1){
        ret.unshift({
          'description': '', 'groupId': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'name': 'All Customers', 'paygateId': this.paygateId, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      }*/
      return ret
    },
    assignTemplatesGroup: {
      get () {
        return this.$store.getters.assignTemplatesGroup || null
      },
      set (value) {
        this.$store.commit('setAssignTemplatesGroup', value)
      }
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    hasRefunds () {
      return this.$store.getters.hasLicence('LicenceCollectionsRefunds') && !this.$store.getters.isInRole(roles.SystemUser)
    },
    hasOnboarding () {
      return this.$store.getters.hasLicence('LicenceCollectionsOnBoarding') && this.$store.getters.isInRole(roles.SystemUser)
    },
    allowSms () {
      return parseInt(process.env.VUE_APP_VERSION) >=  130
    },
    allCustomDefined () {
      var emailEmptyCount = this.customTemplates.filter((temp) => temp.emailTemplate === '00000000-0000-0000-0000-000000000000').length
      var smsEmptyCount = this.allowSms ? this.customTemplates.filter((temp) => temp.smsTemplate === '00000000-0000-0000-0000-000000000000').length : 0
      var letterEmptyCount = this.lettersAllowed ? this.customTemplates.filter((temp) => temp.letterTemplate === '00000000-0000-0000-0000-000000000000').length : 0
      return emailEmptyCount === 0 && smsEmptyCount === 0 && letterEmptyCount === 0
    }
  },
  watch: {
    selectedCustomer () {
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      this.loadTemplates(this.paygateId)
    }
  }
}
</script>
<style scoped>
.newTemplateLinks p {
  float: left;
}

#customMessagesTitle {
  margin-top: 1em;
}

.messageTitle {
  margin-top: 1em;
}
</style>
