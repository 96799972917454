<template>
  <div id="presubValResults">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Pre-Submission Validation Results
              <span class="pull-right">
                <a :href="helpUrl" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div v-if="this.totalRecords > 0" class="col-md-12">
                <vue-good-table
                  mode="remote"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-per-page-change="onPerPageChange"
                  @on-column-filter="onColumnFilter"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="false"
                  :totalRows="totalRecords"
                  :pagination-options="{
                    enabled: true,
                    perPage: 10,
                    dropdownAllowAll: false
                  }"
                  :sort-options="{
                    enabled: false
                  }"
                  styleClass="vgt-table striped bordered">
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'messageSeverity'">
                      <span v-bind:class="getSeverityClass(props.row.messageSeverity)" class="badge badge-pill pull-left">{{props.row.messageSeverity}}</span>
                    </span>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                  <div slot="table-actions">
                    <b-button
                      @click.prevent="createPresubPdfReport"
                      variant="link"
                      title="Create Message Report"
                      v-b-popover.hover.top.d500="'Shows a summary of the validation messages'"
                    >
                      <i class="fa fa-file-pdf pointer dimmedIcon"></i>
                    </b-button>
                    <b-button
                      @click.prevent="createPresubCsvReport"
                      variant="link"
                      title="Create Message CSV File"
                      v-b-popover.hover.top.d500="'Export validation messages to Excel'"
                    >
                      <i class="fa fa-file-excel pointer dimmedIcon"></i>
                    </b-button>
                  </div>
                </vue-good-table>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button id="returnToSummary" type="button" class="btn btn-primary" v-on:click="returnToSummary">Back to Result Summary</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

export default {
  mixins: [
    bacsMixin
  ],
  data () {
    return {
      submissionData: {},
      columns: [
        {
          label: 'File No.',
          field: 'fileNumber',
          type: 'number'
        },
        {
          label: 'Record No.',
          field: 'recordNumber',
          type: 'number'
        },
        {
          label: 'Severity',
          field: 'messageSeverity'
        },
        {
          label: 'Title',
          field: 'title'
        },
        {
          label: 'Description',
          field: 'description'
        },
        {
          label: 'Area',
          field: 'presubValArea',
          type: 'number'
        }
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        submissionId: '',
        showPresubValMessages: this.showPresubValMessages,
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '' // 'asc' or 'desc'
        },

        page: 1, // what page I want to show
        perPage: 10 // how many items I'm showing per page
      },
      presubValResult: '',
      saveStarted: false,
      max: 100,
      progress: 0,
      showPresubValResult: true,
      getTaskProgressUrl: '',
      endTaskUrl: '',
      groupBankAccount: {},
      downloadUrl: '',
      importFileResponse: {},
      bacsGroup: {},
      helpUrl: ''
    }
  },

  methods: {
    getSeverityClass (severity) {
      if (severity === 'Fix') {
        return 'badge-danger'
      } else if (severity === 'Duplicate') {
        return 'badge-warning'
      } else {
        return 'badge-' + severity.toLowerCase()
      }
    },

    getButtonClass () {
      if (this.presubValResult === 'Warnings') {
        return 'btn btn-light'
      } else {
        return 'btn btn-primary'
      }
    },

    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange (params) {
      this.rows.length = 0
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange (params) {
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type
        }
      })
      this.loadItems()
    },

    onColumnFilter (params) {
      this.updateParams(params)
      this.loadItems()
    },

    loadItems () {
      this.serverParams.submissionId = this.submissionData.submissionId

      var json = JSON.stringify({
        submissionId: this.submissionData.submissionId,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        showPresubValMessages: false,
        fileNumber: this.fileNumber,
        sortColumn: this.serverParams.sort.field,
        sortDirection: this.serverParams.sort.type,
        resetStart: false
      })

      return axios({
        method: 'POST',
        url: this.downloadUrl,
        data: json
      })
        .then(response => {
          this.rows = response.data.data
          this.totalRecords = response.data.count

          if (this.showPresubValResult === true) {
            this.showPresubValResult = false
          }
        })
    },

    async createPresubPdfReport () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPresubValidationReport?submissionId=' + this.submissionData.submissionId + '&submissionReference=' + this.submissionData.reference
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    },
    async createPresubCsvReport () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPresubValidationCsvFile?submissionId=' + this.submissionData.submissionId + '&submissionReference=' + this.submissionData.reference
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    },
    returnToSummary () {
      this.$router.push('/payments/bacspayments/preSubValidationResult?retrieveResult=false')
    }
  },

  async beforeRouteLeave (to, from, next) {
    if (to.path.endsWith('preSubValidationResult')) {
      next()
    } else {
      var result = await swal.fire({
        title: 'Navigation',
        text: 'Your submission details will be lost. Do you want to navigate away from this page?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        next()
      } else {
        next(false)
      }
    }
  },

  mounted () {
    this.submissionData = this.$store.getters.submissionData
    this.groupBankAccount = this.$store.getters.groupBankAccount
    this.importFileResponse = this.$store.getters.importFileResponse
    this.bacsGroup = this.$store.getters.bacsGroup
    this.getTaskProgressUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/GetTaskProgress?submissionId=' + this.submissionData.submissionId
    this.endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndSaveTask?submissionId=' + this.submissionData.submissionId
    this.downloadUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getPresubValidationMessages'

    if (this.submissionData.contraNarrative === undefined) {
      this.submissionData.contraNarrative = ''
    }

    if (this.submissionData.submissionType === 'BACS') {
      if (this.submissionData.filename !== undefined && this.submissionData.filename !== null && this.submissionData.filename !== 'N/A') {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/bacs-payments/createbacssubmissionfromfile/'
      } else {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/bacs-payments/createbacssubmissionusingeditor/'
      }
    } else {
      if (this.submissionData.filename !== undefined && this.submissionData.filename !== null && this.submissionData.filename !== 'N/A') {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/faster-payments/createfpssubmissionfromfile/'
      } else {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/faster-payments/createfpssubmissionusingeditor/'
      }
    }

    this.loadItems()
  }
}
</script>
