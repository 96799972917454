<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Settlement Report
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="from" class="required">From</label>
                <vuejsDatepicker
                  name="from"
                  id="from"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :use-utc="false"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="dates.from"
                  :disabledDates="{from:dates.to}"
                  @input="load"
                ></vuejsDatepicker>
              </div>
              <div class="form-group col-md-4">
                <label for="from" class="required">To</label>
                <vuejsDatepicker
                  name="from"
                  id="from"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :use-utc="false"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="dates.to"
                  :disabledDates="{to:dates.from}"
                  @input="load"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-4" v-if="isFinance">
                <label for="from">SUNs</label>
                <vue-select
                  v-model="selectedSuns"
                  :options="suns"
                  :closeOnSelect="true"
                  label="alias"
                  :reduce="sun => sun.id"
                  :clearable="false"
                  :selectable="option => !option.hasOwnProperty('group')"
                  :multiple="true"
                  @input="load"
                >
                  <template #option="{ group, alias }">
                    <div v-if="group" class="group">{{ group }}</div>
                    {{ alias }}
                  </template>
                </vue-select>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12">
                <button
                  class="btn btn-outline-primary pull-right"
                  @click="clearSearchCriteria"
                >Clear Search Criteria</button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <vue-good-table
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :isLoading.sync="isTableLoading"
                  :rows="rows"
                  :columns="columns"
                  :lineNumbers="true"
                  mode="remote"
                  :totalRows="totalRecords"
                  @on-row-click="onRowClick"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  styleClass="vgt-table striped bordered"
                  ref="table"
                  class="scroll-table"
                >
                  <template slot="table-actions">
                    <table-actions
                      :data-url="dataUrl"
                      :export-data="exportData"
                      :print-enabled="false"
                      :table-amount="totalRecords"
                      :export-name="exportName"
                      :reload-table="load"
                      :table-columns="columns"
                      :clear-table-filters="clearTableFilters"
                    ></table-actions>
                  </template>
                  <template slot="table-row" slot-scope="props">
                    <span
                      v-if="props.column.tdClass === 'text-right'"
                      :class="tdClass(props.row, props.column.field)"
                    >{{props.formattedRow[props.column.field]}}</span>
                    <span
                      v-else-if="props.column.field === 'status'"
                      class="badge badge-pill"
                      :class="getBadgeClass(props.row.status)"
                    >{{props.formattedRow[props.column.field]}}</span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'

// Constants
import { settlementType, settlementStatus, paymentMethod } from '@/Assets/Constants/settlement'
import roles from '@/Assets/Constants/roles'

// Components
import VueSelect from 'vue-select'

export default {
  mixins: [loading, tableFilterMixin, settlementMixin],
  name: 'SettlementReport',
  components: {
    VueSelect
  },
  computed: {
    detailLoadUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}Settlement/Details`
    },
    suns () {
      var suns = this.sunsOrdered

      suns = suns.sort((a, b) => {
        var typeA = a.directCreditsAllowed
        var typeB = b.directCreditsAllowed
        return (typeA > typeB) ? -1 : (typeA < typeB) ? 1 : 0
      })
      var result = []
      suns.forEach((c, i) => {
        if (i === 0) {
          result.push({ group: c.directCreditsAllowed ? 'Direct Credits Allowed' : 'Direct Credits Not-Allowed', alias: '' })
        }

        if (i > 0) {
          var previousC = suns[i - 1]
          if (c.directCreditsAllowed !== previousC.directCreditsAllowed) {
            result.push({ group: c.directCreditsAllowed ? 'Direct Credits Allowed' : 'Direct Credits Not-Allowed', alias: '' })
          }
        }

        result.push(c)
      })

      return result
    },
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}Settlement/Report`
    },
    exportName () {
      return `${moment(this.dates.from).format('DD/MM/YYYY')}-${moment(this.dates.to).format('DD/MM/YYYY')}-settlement-report-export`
    },
    exportData () {
      return {
        fromDate: this.dates.from,
        toDate: this.dates.to,
        sunIds: this.selectedSuns
      }
    },
    isFinance () {
      return this.$store.getters.isInRole(roles.Finance)
    },
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Settlement Reference',
          field: 'reference',
          sortable: false,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Client Name',
          field: 'clientName',
          filterOptions: {
            enabled: true
          },
          hidden: !this.$store.getters.isInRole(roles.Finance)
        },
        {
          label: 'Settlement Type',
          field: 'type',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Any Type',
            filterDropdownItems: Object.values(settlementType)
          }
        },
        {
          label: 'Settlement Payment Method',
          field: 'paymentMethod',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Any Payment Method',
            filterDropdownItems: Object.values(paymentMethod)
          }
        },
        {
          label: 'Created Date',
          field: 'creationDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Settlement Date',
          field: 'settlementDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Collection Amount (£)',
          field: 'collectionAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Failed Collection Amount (£)',
          field: 'failedCollectionAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Indemnity Claim Amount (£)',
          field: 'indemnityClaimAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Netdown Amount (£)',
          field: 'netdownAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        // {
        //   label: 'Refund Amount (£)',
        //   field: 'refundAmount',
        //   formatFn: this.moneyFormat,
        //   tdClass: 'text-right'
        // },
        {
          label: 'Service Charges (£)',
          field: 'serviceCharges',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          hidden: true
        },
        {
          label: 'VAT (£)',
          field: 'vatAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number',
          hidden: true
        },
        {
          label: 'Client Settlement Payment (£)',
          field: 'clientSettlementPayment',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Any Status',
            filterDropdownItems: Object.values(settlementStatus)
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'creationDate', type: 'desc' }]
      },
      paginationOptions: {
        dropdownAllowAll: false,
        mode: 'pages'
      },
      detailParams: null,
      selectedSettlement: null,
      dates: {
        from: null,
        to: null
      },
      selectedSuns: [],
      sunsOrdered: []
    }
  },
  methods: {
    async load () {
      // Load stuff in
      try {
        // Make sure valid data before sending
        if (!this.exportData.fromDate && !this.exportData.toDate) {
          return
        }

        this.$snapbar.hide()
        this.isTableLoading = true
        var response = await axios.get(this.dataUrl, {
          params: {
            ...this.buildGoodTableQuery(
              this.exportData
            ),
            ...this.exportData
          },
          showload: true,
          showerror: true,
          errormessage: 'Settlements failed to load'
        })

        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    onRowClick (params) {
      var settlementId = params.row.id
      this.$router.push({ name: 'SettlementReportDetails', params: { id: settlementId } })
    },
    async loadSuns () {
      if (!this.isFinance) {
        return
      }

      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/Suns`, {
          showload: true,
          showerror: true,
          errormessage: 'SUNs failed to load'
        })

        this.sunsOrdered = response.data
      } catch { }
    },
    getBadgeClass (status) {
      switch (status) {
        case settlementStatus.AwaitingSubmission:
        case settlementStatus.Submitted:
          return 'badge-info'
        case settlementStatus.Paid:
          return 'badge-success'

        case settlementStatus.Failed:
          return 'badge-danger'
      }
      return 'badge-secondary'
    },
    clearSearchCriteria () {
      var thisMonth = moment().utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0, date: 1 }).add(1, 'month')
      this.dates.to = moment(thisMonth).subtract(1, 'day').toDate()
      this.dates.from = moment(thisMonth).subtract(2, 'month').toDate()
      this.selectedSuns = []
      this.load()
    }

  },
  async created () {
    if (this.serverParams.otherData && this.serverParams.otherData.toDate && this.serverParams.otherData.fromDate) {
      this.dates.to = moment(this.serverParams.otherData.toDate).toDate()
      this.dates.from = moment(this.serverParams.otherData.fromDate).toDate()
      this.selectedSuns = this.serverParams.otherData.sunIds
    } else {
      var thisMonth = moment().utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0, date: 1 }).add(1, 'month')
      this.dates.to = moment(thisMonth).subtract(1, 'day').toDate()
      this.dates.from = moment(thisMonth).subtract(2, 'month').toDate()
    }
    await this.load()
    await this.loadSuns()
  },
  watch: {
    selectedCustomer () { this.load() }
  }
}

</script>

<style lang="scss" scoped>
</style>
