<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>{{invoiceDetails.type}} Details</h2>
      </div>

      <div class="idb-block-content">
        <form>
          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Client</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                v-model="invoiceDetails.clientName"
              />
            </div>
          </div>

          <div class="form-group row" v-if="invoiceDetails.invoicedClientId">
            <label class="col-sm-2 text-right col-form-label">Client Invoiced</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                v-model="invoiceDetails.invoicedClientName"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Document Id</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                v-model="invoiceDetails.documentId"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Type</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                v-model="invoiceDetails.type"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">{{invoiceDetails.type}} Date</label>
            <div class="col-sm-10">
              <span
                class="form-control-plaintext"
              >{{invoiceDetails.date | formatDate('DD/MM/YYYY')}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">From Date</label>
            <div class="col-sm-10">
              <span
                class="form-control-plaintext"
              >{{invoiceDetails.fromDate | formatDate('DD/MM/YYYY')}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">To Date</label>
            <div class="col-sm-10">
              <span
                class="form-control-plaintext"
              >{{invoiceDetails.toDate | formatDate('DD/MM/YYYY')}}</span>
            </div>
          </div>
          <div v-if="invoiceDetails.emailInvoice ">
            <div class="form-group row">
              <label class="col-sm-2 text-right col-form-label">Sent</label>
              <div class="col-sm-10">
                <tick :value="invoiceDetails.sent"></tick>
              </div>
            </div>

            <div class="form-group row" v-if="invoiceDetails.sent">
              <label class="col-sm-2 text-right col-form-label">Sent Date</label>
              <div class="col-sm-10">
                <span
                  class="form-control-plaintext"
                >{{invoiceDetails.sentDate | formatDate('DD/MM/YYYY')}}</span>
              </div>
            </div>
          </div>
          <div v-if="invoiceDetails.voided">
            <div class="form-group row">
              <label class="col-sm-2 text-right col-form-label">Voided</label>
              <div class="col-sm-10">
                <span class="badge badge-danger badge-pill">voided</span>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Total</label>
            <div class="col-sm-10">
              <span
                class="form-control-plaintext"
                :class="moneyClass(invoiceDetails.total, true)"
              >{{moneyFormat(invoiceDetails.total)}}</span>
            </div>
          </div>
          <div class="form-group row" v-if="isFinance && invoiceDetails.comment">
            <label class="col-sm-2 text-right col-form-label">Comment</label>
            <div class="col-sm-10">
              <span class="form-control-plaintext">{{invoiceDetails.comment}}</span>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>{{invoiceDetails.type}} Charges</h2>
      </div>

      <div class="idb-block-content">
        <div class="row">
          <div class="col-md-12">
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              :rows="rows"
              :columns="columns"
              :lineNumbers="true"
              mode="remote"
              :totalRows="totalRecords"
              styleClass="vgt-table striped bordered"
              ref="table"
              @on-row-click="gotoDetails"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
            >
              <template slot="table-row" slot-scope="props">
                <span
                  v-if="props.column.field !== 'name'"
                  :class="tdClass(props.row, props.column.field)"
                >{{props.formattedRow[props.column.field]}}</span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="$router.go(-1)">Back</button>
        <button
          type="button"
          class="btn btn-info ml-3"
          v-if="isFinance && !invoiceDetails.voided"
          @click.prevent="createOppositeFile()"
        >
          <i class="glyphicon ti-notepad"></i>
          Create {{oppositeLabel}}
        </button>

        <button
          type="button"
          class="btn btn-info ml-3"
          v-if="invoiceDetails.emailInvoice && isFinance"
          @click.prevent="sendInvoice()"
        >
          <i class="glyphicon ti-email"></i>
          Email {{label}}
        </button>
        <button
          type="button"
          class="btn btn-info ml-3"
          @click.prevent="recreateFile()"
          v-if="(!invoiceDetails.sentDate && invoiceDate > today) && isFinance && !invoiceDetails.voided"
        >
          <i class="glyphicon ti-reload"></i>
          Re-Create {{label}}
        </button>
        <button
          type="button"
          class="btn btn-danger ml-3"
          @click.prevent="voidInvoice()"
          v-if="(!invoiceDetails.sentDate && invoiceDate > today) && isFinance && !invoiceDetails.voided"
        >
          <i class="glyphicon ti-close"></i>
          Void {{label}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import moment from 'moment'

// Mixins
import invoicingMixin from '@/Components/Platform/Customer/Invoicing/invoicingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

// Constants
import roles from '@/Assets/Constants/roles'

export default {
  name: 'InvoiceDetails',
  props: ['id'],
  mixins: [invoicingMixin, tableFilterMixin],
  computed: {
    isFinance () {
      return this.$store.getters.isInRole(roles.Finance)
    },
    label () {
      return this.invoiceDetails.creditNote ? 'Credit Note' : 'Invoice'
    },
    oppositeLabel () {
      return this.invoiceDetails.creditNote ? 'Invoice' : 'Credit Note'
    },
    invoiceDate () {
      return moment(this.invoiceDetails.date).startOf('day').toDate()
    },
    today () {
      return moment().startOf('day').toDate()
    }
  },
  data () {
    return {
      invoiceDetails: {
        documentId: null,
        clientName: null,
        pdf: null,
        excel: null,
        sent: false,
        type: 'Invoice',
        creditNote: false,
        total: 0,
        date: null,
        fromDate: null,
        toDate: null,
        sentDate: null,
        comment: null,
        id: null,
        void: false
      },
      columns: [
        {
          label: 'Type',
          field: 'type'
        },
        {
          label: 'Customer Reference',
          field: 'customerName',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
        },
        {
          label: 'Amount (£)',
          field: 'charge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },

        {
          label: 'Service Charges (£)',
          field: 'serviceCharge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'VAT (£)',
          field: 'vatAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        }
      ],
      rows: [],
      serverParams: {
        sort: [{ field: 'processingDate', type: 'desc' }]
      }
    }
  },
  methods: {
    async gotoDetails (params) {
      var billingUsageId = params.row.id
      await this.$store.dispatch('setPaygateId', this.invoiceDetails.paygateId)
      window.open(this.$router.resolve({ name: 'ChargeDetail', params: { id: billingUsageId, showBackButton: false } }).href, '_blank')
    },
    async loadDetails () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/${this.id}`, {
        showload: true,
        showerror: true,
        errormessage: 'Details failed to load'
      })

      this.invoiceDetails = response.data
    },
    async load () {
      try {
        this.isTableLoading = true

        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/Charges`, {
          params: {
            ...this.buildGoodTableQuery(),
            invoiceId: this.id
          },
          showerror: true,
          errormessage: `${this.label} charges failed to load`
        })

        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch {

      } finally {
        this.isTableLoading = false
      }
    },
    async createOppositeFile () {
      this.$router.push({ name: 'CreateSingleInvoice', query: { oppositeFile: 'true', invoiceId: this.invoiceDetails.id, creditNote: `${!this.invoiceDetails.creditNote}` } })
    },
    async recreateFile () {
      this.$router.push({ name: 'CreateSingleInvoice', query: { recreate: 'true', invoiceId: this.invoiceDetails.id, clientId: this.invoiceDetails.paygateId } })
    },
    async sendInvoice () {
      try {
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/SendInvoice`,
          {
            invoiceId: this.invoiceDetails.id
          },
          {
            showload: true,
            showerror: true,
            errormessage: `${this.label} failed to send`
          })

        this.loadDetails()
        this.$toastr.s(
          'Invoice Sent',
          'Sent'
        )
      } catch (e) {
        this.$toastr.e('Something went wrong', 'Error')
      } finally {
      }
    },
    async voidInvoice () {
      try {
        await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/Void`,
          {
            invoiceId: this.invoiceDetails.id
          },
          {
            showload: true,
            showerror: true,
            errormessage: `${this.label} failed to void`
          })

        this.loadDetails()
        this.$toastr.s(
          `${this.label} Voided`,
          'Void'
        )
      } catch (e) {
        this.$toastr.e('Something went wrong', 'Error')
      } finally {
      }
    }
  },
  async created () {
    await this.loadDetails()
    await this.load()
  }
}
</script>

<style>
</style>
