<template>
  <div>
    <div id="synchronisationReportDetails">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2><help-icon docPath="/paygate-collections/reconciliation/" />Synchronisation Report Details</h2>
            </div>
            <div class="idb-block-content">
              <span
                v-if="reports.length === 0"
              >Synchronisation was successful, but no reports were available to synchronise.</span>
              <b-tabs v-if="reports.length > 0">
                <b-tab
                  v-for="report in reports"
                  :key="removeDupeString(report.filename)"
                  :title="removeDupeString(report.filename) + ' ('+getReportType(report.reporttype)+')'"
                >
                  <vue-good-table
                    ref="reportList"
                    :columns="columns"
                    :rows="report.messages"
                    @on-row-click="viewReport"
                    :pagination-options="{
                      enabled: true,
                      perPage: 10,
                      dropdownAllowAll: false
                    }"
                    styleClass="vgt-table striped bordered"
                  ></vue-good-table>
                </b-tab>
              </b-tabs>
            </div>
            <div class="idb-block-footer mt-2">
              <b-button
                variant="primary"
                @click.prevent="removeAction" :disabled="isLoading"
                v-if="actionId && actionId !== null"
              >Clear Action</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  data () {
    return {
      reports: [],
      columns: [
        {
          label: 'Customer Reference',
          field: 'ref'
        },
        {
          label: 'Transaction Code',
          field: 'transactionCode'
        },
        {
          label: 'Return Code',
          field: 'returnCode'
        },
        {
          label: 'Description',
          field: 'description'
        }
      ],
      actionId: null
    }
  },
  props: {
    syncId: String
  },
  created () {
  },
  async mounted () {
    try {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}reconciliation/` + this.syncId, { showload: true, params: { paygateId: this.paygateId } })
      console.log(response)
      this.processData(response)
    } catch (e) {
      console.log(e)
    }
    this.actionId = this.$route.query.actionId
  },
  computed: {
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    }
  },
  methods: {
    addMessageRow (rowString) {
      var rowArray = rowString.split(',')
      let rowObj = {}
      if (rowArray.length === 4) {
        rowObj = {
          ref: rowArray[0].substring(rowArray[0].indexOf(':') + 2),
          transactionCode: rowArray[1].substring(rowArray[1].indexOf(':') + 2),
          returnCode: rowArray[2].substring(rowArray[2].indexOf(':') + 2),
          description: rowArray[3].substring(rowArray[3].indexOf(':') + 2)
        }
      } else {
        rowObj = {
          ref: '',
          transactionCode: '',
          returnCode: '',
          description: rowArray[0]
        }
      }
      return rowObj
    },
    processData (response) {
      for (var i = 0; i < response.data.length; i++) {
        response.data[i].messages = JSON.parse(response.data[i].messages)
        for (var a = 0; a < response.data[i].messages.length; a++) {
          response.data[i].messages[a] = this.addMessageRow(response.data[i].messages[a])
        }
      }
      this.reports = response.data
    },
    getReportType (type) {
      let retVal = ''
      switch (type) {
        case 1:
          retVal = 'ARUDD'
          break
        case 4:
          retVal = 'ADDACS'
          break
        case 5:
        case 6:
        case 7:
          retVal = 'AUDDIS'
          break
        case 8:
          retVal = 'DDIC'
          break
        default:
          retVal = 'Unknown'
          break
      }
      return retVal
    },
    removeDupeString (fileName) {
      if (fileName.includes('-du')) {
        var fileNameArray = fileName.split('-')
        fileName = fileNameArray[0] + '.xml'
      }
      return fileName
    },
    removeAction () {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}reconciliation/` + this.syncId + '/clearaction', { params: { 'paygateid': this.paygateId, actionId: this.actionId }, showload: true }).then(
        (response) => {
          this.$toastr.s('Action cleared')
          this.actionId = null
          this.$router.back()
        }
      )
    }
  }
}
</script>
