<template>
  <form @submit.prevent="checkValidation() && saveChargeSetGroup()">
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>{{status}} Charge Configurations Group</h2>
      </div>
      <div class="idb-block-content">
        <b-tabs ref="tabs">
          <b-tab title="Charge Configurations Group Details">
            <!-- Name -->
            <div class="form-group row" :class="{invalid: $v.chargeSetGroup.name.$error}">
              <label class="label-control col-md-3 required">Name</label>
              <div class="col-md-6">
                <input type="text" class="form-control" v-model="$v.chargeSetGroup.name.$model" />
                <validation-messages v-model="$v.chargeSetGroup.name"></validation-messages>
              </div>
            </div>

            <!-- Customers -->
            <div class="form-group row" :class="{invalid: $v.chargeSetGroup.customers.$error}">
              <label class="col-form-label col-md-3">Clients</label>
              <div class="col-md-6">
                <vue-select
                  v-model="selectedCustomerToAdd"
                  :options="customers"
                  :disabled="customersLoading"
                  append-to-body
                ></vue-select>
                <validation-messages v-model="$v.chargeSetGroup.customers">
                  <small
                    class="form-text small"
                    v-if="$v.chargeSetGroup.customers.oneCustomer != undefined && !$v.chargeSetGroup.customers.oneCustomer"
                  >Please add a Client</small>
                  <small
                    class="form-text small"
                    v-if="$v.chargeSetGroup.customers.minCustomers != undefined && !$v.chargeSetGroup.customers.minCustomers"
                  >Please add at least 2 Clients</small>
                </validation-messages>
              </div>
              <div class="col-md-1">
                <button
                  class="btn btn-success"
                  type="button"
                  @click="addToGroup"
                  :disabled="selectedCustomerToAdd == null || customersLoading"
                >Add To Group</button>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <vue-good-table
                  :columns="columns"
                  :rows="$v.chargeSetGroup.customers.$model"
                  :totalRows="$v.chargeSetGroup.customers.length "
                  :pagination-options="{
                    enabled: true,
                    perPage: 10,
                    dropdownAllowAll: false,
                            jumpFirstOrLast: true
                  }"
                  :search-options="{
                    enabled: true
                  }"
                  styleClass="vgt-table striped bordered"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'actions'">
                      <button
                        class="btn btn-danger"
                        type="button"
                        @click.prevent="removeFromGroup(props.row,$event)"
                      >
                        <a class="fa fa-times"></a>
                      </button>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </b-tab>
          <b-tab title="Charge Configurations Group Values">
            <charge-configuration-controls
              :billing="$v.chargeSetGroup.chargeConfiguration"
              :errors="errors"
            ></charge-configuration-controls>
          </b-tab>
        </b-tabs>
      </div>
      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          :disabled=" isLoading"
          type="submit"
        >{{ status === "Edit" ? "Save" : status }}</button>

        <button class="btn btn-outline-danger pull-right ml-3" type="button" @click="back">Cancel</button>
        <button
          v-if="status === 'Edit'"
          class="btn btn-danger pull-right"
          @click="deleteChargeSetGroup"
          type="button"
        >
          <i class="glyphicon ti-trash rpad"></i>Delete Charge Configurations Group
        </button>
      </div>
    </div>
  </form>
</template>

<script>
// Third Party
import _ from 'lodash'
import axios from 'axios'
import swal from 'sweetalert2'

// Mixins
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

// Components
import VueSelect from 'vue-select'
import ChargeConfigurationControls from '@/Components/Platform/Customer/Charges/Configuration/ChargeConfigurationControls'

// Constants
import colours from '@/Assets/Constants/colours'

// Validations
import { required, minLength, requiredIf, helpers, minValue, integer } from 'vuelidate/lib/validators'

export default {
  name: 'ChargeSetGroupEdit',
  props: ['status', 'id'],
  mixins: [DataLeaveMixin, loading],
  components: {
    VueSelect,
    ChargeConfigurationControls
  },
  computed: {
    customers () {
      return this.loadedCustomers
    }
  },
  data () {
    return {
      chargeSetGroup: {
        name: '',
        id: null,
        customers: [],
        chargeConfiguration: {
          // Periodic
          adminCharge: { value: 0 },
          bacsFileSubmissionCharge: { value: 0 },
          bacsFileSubmissionIncludedFiles: { value: 2 },
          osuCharge: { value: 0 },

          // Processing
          advanceNoticeLetter: { value: 0 },
          advanceNoticeEmail: { value: 0 },
          preCollectionNotification: { value: 0 },
          collections: {
            minimum: 0,
            maximum: 1,
            ranges: [{ amount: 0, rate: 0 }],
            type: 'SteppedRate'
          },
          confirmationLetter: { value: 0 },
          confirmationEmail: { value: 0 },
          failedCollections: { value: 0 },
          indemnityClaims: { value: 0 },
          payerCancellation: { value: 0 },
          payerReinstate: { value: 0 },
          payerSetup: { value: 0 },
          validateBankAccount: { value: 0 },
          validateIBAN: { value: 0 },
          banklineSTD1Or2: { value: 0 },
          banklineSTD0: { value: 0 },
          banklineUrgent: { value: 0 },
          ignoreProcessingChargeOnFailure: { value: true },

          // Other
          inChargeSetGroup: true,
          chargeSetGroupId: null
        }
      },
      selectedCustomerToAdd: null,
      loadedCustomers: [],
      customersLoading: true,
      columns: [
        {
          label: 'Name',
          field: 'label'
        },
        {
          label: '',
          field: 'actions'
        }
      ],
      errors: []
    }
  },
  methods: {
    async load () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ChargeSetGroup/${this.id}`)

        this.chargeSetGroup = response.data

        this.chargeSetGroup.customers.forEach(customer => {
          const foundIndex = this.loadedCustomers.findIndex(lc => lc.id === customer.id)
          if (foundIndex !== -1) { this.loadedCustomers.splice(foundIndex, 1) }
        })
      } catch { }
    },
    async loadCustomers () {
      try {
        this.customersLoading = true
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ChargeSetGroup/Customers`,
          {
            showload: true,
            showerror: true,
            errormessage: 'Failed loading Clients'
          })

        this.loadedCustomers = response.data
      } catch { } finally { this.customersLoading = false }
    },
    async saveChargeSetGroup () {
      try {
        var data = { ...this.chargeSetGroup }
        data.customers = data.customers.map(c => c.id)

        if (this.status === 'Create') {
          await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}ChargeSetGroup`, data, { showload: true, showerror: true, errormessage: 'Charge Configuration Group failed to save' })

          this.$toastr.s('New Charge Configuration Group created', 'Created')

          this.$v.$reset()
          this.$router.push({ name: 'ListChargeConfigurationGroups' })
        } else {
          await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}ChargeSetGroup`, data, { showload: true, showerror: true, errormessage: 'Charge Configuration Group failed to save' })

          this.$toastr.s('New Charge Configuration Group updated', 'Updated')

          this.$v.$reset()
        }
      } catch (e) {
        if (e.response && e.response.status === 422) {
          this.errors = e.response.data
          this.$toastr.e('There are errors on the page, please see the top for more information', 'Validation Error')
        }
      }
    },
    async deleteChargeSetGroup () {
      try {
        var swalResult = await swal.fire({
          title: 'Delete Charge Configuration Group',
          text: 'Are you sure you want to delete this Charge Configuration group?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch (error) {
        // Do nothing as this will be if they clicked cancel
        return
      }

      if (!swalResult.isConfirmed) {
        return
      }

      try {
        await axios.delete(
          `${process.env.VUE_APP_PLATFORM_API_URL}ChargeSetGroup/${this.chargeSetGroup.id}`,
          { showload: true, showerror: true, errormessage: 'Charge Configuration Group failed to delete' }
        )

        this.$toastr.s('Charge Configuration Group deleted', 'Deleted')
        this.$v.$reset()

        this.$router.push({ name: 'ListChargeConfigurationGroups' })
      } catch { }
    },
    async addToGroup () {
      if (!this.chargeSetGroup.customers.some(c => this.selectedCustomerToAdd.id === c.id)) {
        this.chargeSetGroup.customers.push(this.selectedCustomerToAdd)
        var index = this.loadedCustomers.indexOf(this.selectedCustomerToAdd)
        this.loadedCustomers.splice(index, 1)
        this.selectedCustomerToAdd = null
      }
    },
    async removeFromGroup (row, event) {
      event.stopPropagation()
      this.loadedCustomers.push({ label: row.label, id: row.id })
      this.chargeSetGroup.customers.splice(row.originalIndex, 1)

      if (this.chargeSetGroup.customers.length === 0) {
        await this.loadCustomers()
      }
    }
  },
  async mounted () {
    if (this.status === 'Edit') {
      await this.load()
    }
    await this.loadCustomers()
  },
  validations () {
    return {
      chargeSetGroup: {
        name: { required },
        customers: { oneCustomer: required, minCustomers: minLength(2) },
        chargeConfiguration: {
          // Periodic
          adminCharge: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          bacsFileSubmissionCharge: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          bacsFileSubmissionIncludedFiles: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          osuCharge: {
            value: {
              required,
              minValue: minValue(0)
            }
          },

          // Processing
          advanceNoticeLetter: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          advanceNoticeEmail: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          preCollectionNotification: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          collections: {
            minimum: {
              required: requiredIf((model) => {
                return model.type !== 'SteppedFixFee'
              }),
              minValue: helpers.withParams({ type: 'minValue', min: 0 }, (value, model) => {
                return model.type !== 'SteppedFixFee' ? value >= 0 : true
              }),
              maxValue: helpers.withParams({ type: 'maxValue', max: this.chargeSetGroup.chargeConfiguration.collections.maximum }, (value, model) => {
                return model.type !== 'SteppedFixFee' ? value <= this.chargeSetGroup.chargeConfiguration.collections.maximum : true
              })
            },
            maximum: {
              required: requiredIf((model) => {
                return model.type !== 'SteppedFixFee'
              }),
              minValue: helpers.withParams({ type: 'minValue', min: this.chargeSetGroup.chargeConfiguration.collections.minimum }, (value, model) => {
                return model.type !== 'SteppedFixFee' ? value >= this.chargeSetGroup.chargeConfiguration.collections.minimum : true
              })
            },
            ranges: {
              $each: {
                amount: {
                  required,
                  minValue: minValue(0),
                  integer,
                  valid: (value) => {
                    var count = _.sumBy(this.chargeSetGroup.chargeConfiguration.collections.ranges,
                      v => +(v.amount === value))
                    return count === 1
                  }
                },
                rate: {
                  required,
                  minValue: minValue(0),
                  maxValue: helpers.withParams({ type: 'maxValue', max: 100 }, (value) => {
                    return (this.chargeSetGroup.chargeConfiguration.collections.type !== 'SteppedFixFee' && this.chargeSetGroup.chargeConfiguration.collections.type !== 'FixedFee') ? value <= 100 : true
                  })
                }
              }
            },
            type: {}
          },
          confirmationLetter: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          confirmationEmail: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          failedCollections: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          indemnityClaims: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          payerCancellation: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          payerReinstate: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          payerSetup: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          validateBankAccount: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          validateIBAN: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          banklineSTD1Or2: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          banklineSTD0: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          banklineUrgent: {
            value: {
              required,
              minValue: minValue(0)
            }
          },
          ignoreProcessingChargeOnFailure: {
            value: {}
          }
        }
      }
    }
  }
}
</script>

<style>
</style>
