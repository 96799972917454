<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Indemnity Claims Report</h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group mb-3">
          <div class="col-md-2">
            Date Filter Column:
          </div>
          <div class="col-md-4">
            <b-form-select
              class="form-control"
              :options="filterFields"
              v-model="selectedFilter"
              @input="filtersChanged()"
            />
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Earliest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="endPaymentDatePicker"
              v-model="start"
              :format="formatDate" @input="filtersChanged()" input-class="form-control datepicker" :disabledDates="{ from: new Date(end) }"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Latest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="startPaymentDatePicker"
              v-model="end"
              :format="formatDate" :disabledDates="{ to: new Date(start), from: new Date() }"
              @input="filtersChanged()" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          class="scroll-table"
          :columns="columns"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'claimChallenge'" v-b-popover.hover.top.d500="props.row.claimChallenge === 'Challenge' ? 'Request a Challenge for this indemnity claim' : props.row.claimChallenge">
                    <b-button
                      class="w-100"

                      :variant="props.row.claimChallenge === 'Challenge' ? 'success' : 'secondary'"
                      :disabled="props.row.claimChallenge !== 'Challenge' || !canChallenge"
                      @click="challenge(props.row.adviceCollectionId, props.row.reasonCode, props.row.productionDate)"
                    >{{props.row.claimChallenge}}</b-button>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import Datepicker from 'vuejs-datepicker'
  import papa from 'papaparse'
  import moment from 'moment'
  import colours from "@/Assets/Constants/colours"
  import swal from 'sweetalert2'
  import {mapGetters} from "vuex";
  import roles from "@/Assets/Constants/roles";
  import Store from "@/Store";

  export default {
    mixins: [tableFilterMixin, loading],
    components: {
      Datepicker
    },
    data () {
      return {
        filterFields: [ 'Collection Date', 'Date Paid', 'Date of Indemnity Claim Notification' ],
        selectedFilter: 'Date of Indemnity Claim Notification',
        dateOptions: {
          format: 'DD/MM/YYYY HH:mm',
          useCurrent: false,
          stepping: 30,
          sideBySide: true,
        },
        end: null,
        start: null,
        rows: [],
        columns: [
          {
            label: 'adviceCollectionId',
            field: 'adviceCollectionId',
            hidden: true
          },
          {
            label: 'DDIC Reference',
            field: 'payingBankReference'
          },
          {
            label: 'Customer Reference',
            field: 'payerReference'
          },
          {
            label: 'Customer Name',
            field: 'payerName'
          },
          {
            label: 'Schedule Reference',
            field: 'serviceUserReference'
          },
          {
            label: 'ukPayerId',
            field: 'ukPayerId',
            hidden: true
          },
          {
            label: 'paymentPlanId',
            field: 'paymentPlanId',
            hidden: true
          },
          {
            label: 'Collection Date',
            field: 'collectionDate',
            formatFn: this.formatDate
          },
          {
            label: 'Amount',
            field: 'collectionAmount',
            formatFn: this.formatAmount
          },
          {
            label: 'DDIC Date',
            field: 'productionDate',
            formatFn: this.formatDate
          },
          {
            label: 'Date Paid',
            field: 'datePaid',
            formatFn: this.formatDateWithNull
          },
          {
            label: 'Reason Code',
            field: 'reasonCode'
          },
          {
            label: 'Description',
            field: 'description'
          },
          {
            label: 'challengeId',
            field: 'challengeId',
            hidden: true
          },
          {
            label: 'Claim Status',
            field: 'claimStatus',
            formatFn: this.formatClaimStatus
          },
          {
            label: 'Indemnity Claim Challenge',
            field: 'claimChallenge'
          },
          {
            label: 'Challenge Status',
            field: 'challengeStatus',
            formatFn: this.formatChallengeStatus
          },
          {
            label: 'submissionId',
            field: 'submissionId',
            hidden: true
          },
          {
            label: 'payerReference',
            field: 'payerReference',
            hidden: true
          },
          {
            label: 'planReference',
            field: 'planReference',
            hidden: true
          }
        ],
        serverParams: {
          sort: [{ field: 'productionDate', type: 'desc' }],
        }
      }
    },
    mounted () {
      var savedParams = this.$store.getters.tableFilters(this.$route.path)

      if (savedParams) {
        // If there are, apply them!
        this.savedParamsToObjects(savedParams)
        this.serverParams = Object.assign({}, this.serverParams, savedParams)
      } else {
        if (this.isLnZUser) {
          this.selectedFilter = 'Date of Indemnity Claim Notification'
        } else {
          this.selectedFilter = 'Collection Date'
        }
      }

      this.end = new Date()
      let tempStart = new Date()
      tempStart.setDate(this.end.getDate() - 7)
      this.start = tempStart
    },
    methods: {
      async filtersChanged () {
        await this.clearTableFilters()
      },
      load: async function () {
        try {
          if (this.start === null || this.end === null) {
            this.end = new Date()
            let tempStart = new Date()
            tempStart.setDate(this.end.getDate() - 7)
            this.start = tempStart
          }
          var query = this.buildGoodTableQuery()
          query.startDate = this.start
          query.endDate = this.end
          query.filterBy = this.selectedFilter
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}indemnityreport/claims`, { params: query, validateStatus: () => true })
          if (response.status === 200) {
            this.rows = response.data.data
            this.totalRecords = response.data.count
          } else {
            if (response.status === 403) {
              this.$toastr.e('Not authorized to use this feature.')
              this.rows = []
              this.totalRecords = 0
            } else {
              this.$toastr.e('An error has occurred.')
            }
          }
        } catch (error) {
          this.$toastr.e(error)
        }
      },
      onRowChange (e) {
        console.log(e)
        this.$router.push({ path: `/collections/customer/${e.row.ukPayerId}/edit`, params: { openAt: 'messagequeue'}, query: { openAt: 'messagequeue'} })
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
      formatChallengeStatus (value) {
        let retVal = ''
        switch (value) {
          case 1:
            retVal = 'Requested'
            break
          case 2:
            retVal = 'Actioned'
            break
          case 3:
            retVal = 'More Info'
            break
          case 4:
            retVal = 'Successful'
            break
          case 5:
            retVal = 'Rejected'
            break
          default:
            retVal = 'None'
            break
        }
        return retVal
      },
      formatClaimStatus (value) {
        let retVal = ''
        switch (value) {
          case 1:
            retVal = 'Challenged'
            break
          case 2:
            retVal = 'Recouped'
            break
          case 3:
            retVal = 'Completed'
            break
          case 4:
            retVal = 'Cancelled'
            break
          case 5:
            retVal = 'Cancelled Completed'
            break
          default:
            retVal = 'Received'
            break
        }
        return retVal
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      formatDateWithNull (date) {
        if (date===null || date === '0001-01-01T00:00:00') {
          return 'N/A'
        } else {
          return this.formatDate(date)
        }
      },
      async exportTable () {
        var query = this.buildGoodTableQuery()
        var minDate = moment(this.end).format('DD-MM-YYYY')
        var maxDate = moment(this.start).format('DD-MM-YYYY')
        query.perPage = this.totalRecords
        query.forExport = true
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}indemnityreport/claims`, { params: {
            startDate: this.start, endDate: this.end,
          ...query, paygateId: this.paygateId, filterBy: this.selectedFilter }, showload: true })
        var csvString = papa.unparse(response.data)
        let fileName = `indemnity-claims-report-${maxDate}-to-${minDate}.csv`
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      codeToDescription (value) {
        let retval = ''
        switch (value) {
          case 1:
            retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
            break;
          case 2:
            retval = 'NO ADVANCE NOTICE WAS RECEIVED';
            break;
          case 3:
            retval = 'BANK CANCELS THE DIRECT DEBIT';
            break;
          case 4:
            retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
            break;
          case 5:
            retval = 'CUSTOMERDISPUTES HAVING GIVEN AUTHORITY';
            break;
          case 6:
            retval = 'FRAUDULENT SIGNATURE';
            break;
          case 7:
            retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
            break;
          case 8:
            retval = 'SERVICE USER IS NOT RECOGNISED';
            break;
          default:
            retval = 'Invalid Code Supplied'
            break;
        }
        return retval
      },
      async challenge(rowId, reasonCode, productionDate) {
        if (productionDate.getTime === undefined || productionDate === null) {
          productionDate = new Date(productionDate)
        }

        var nowDate = new Date();
        var diffDays = nowDate.getDate() - productionDate.getDate();
        if (reasonCode !== 7 && diffDays > 9) {
          let basis = await swal.fire({
            title: 'Challenge Window Elapsed',
            icon: 'warning',
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',
            confirmButtonColor: colours.success,
          });
        } else {
          let basis = await swal.fire({
            title: 'Create Challenge for this Indemnity Claim?',
            icon: 'warning',
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            confirmButtonColor: colours.success,
            html:'<input id="swal-input2" class="swal2-input" placeholder="Provide reason for Challenge" required/>',
            preConfirm: () => {
              if (document.getElementById('swal-input2').value !== '') {
                return document.getElementById('swal-input2').value
              } else {
                swal.showValidationMessage('Please provide a reason for the challenge.')
              }
            }
          });
          if(basis.value !== '' && basis.isConfirmed) {
            try {
              let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}indemnity/reportchallenge`, {
                adviceCollectionId: rowId,
                basis: basis.value
              })
              await this.load()
              this.$toastr.s('Challenge created')
            } catch (e) {
              this.$toastr.e('Could not create Challenge')
            }
          }
        }
      }

    },
    computed: {
      ...mapGetters(['selectedCustomer']),
      startDateOptions () {
        return { ...this.dateOptions, maxDate: this.end }
      },
      endDateOptions () {
        return { ...this.dateOptions, minDate: this.start }
      },
      canChallenge() {
        return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.Finance])
      },
      isLnZUser () {
        return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.Finance, roles.DevOps, roles.Developer, roles.Qa])
      }
    },
    watch: {
      selectedCustomer () {
        this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
        this.load()
      }
    }
  }
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
