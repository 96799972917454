<template>
  <div>
    <form @submit.prevent="checkValidation() && save()">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Edit App Settings</h2>
        </div>
        <div class="idb-block-content">
          <div class="form-group row" :class="{ invalid: $v.value.$error }">
            <div class="col-md-12">
              <textarea
                v-model="$v.value.$model"
                rows="20"
                class="form-control"
                autocomplete="false"
              ></textarea>
              <!-- Validation -->
              <validation-messages v-model="$v.value" name="app settings">
                <small
                  class="form-text small"
                  v-if="
                $v.value.valid != undefined &&
                !$v.value.valid
              "
                >Must be valid JSON</small>
              </validation-messages>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <button class="btn btn-primary" :disabled="isLoading" type="submit">Save</button>
          <button
            :disabled="isLoading"
            class="btn btn-outline-danger pull-right"
            type="button"
            @click="back"
          >Cancel</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  name: 'AppSettings',
  mixins: [DataLeaveMixin, loading],
  data () {
    return {
      value: ''
    }
  },
  methods: {
    async load () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}AppSettings`, { showload: true, showerror: true, errormessage: 'App Settings failed to load' })
      this.value = JSON.stringify(response.data, null, 2)
    },
    async save () {
      await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}AppSettings`, this.value, { showload: true, showerror: true, errormessage: 'App Settings failed to save' })

      this.$toastr.s('App Settings updated', 'Saved')

      this.$v.$reset()
    }
  },
  created () {
    this.load()
  },
  validations: {
    value: {
      required,
      valid: (value) => {
        try {
          JSON.parse(value)
        } catch {
          return false
        }
        return true
      }
    }
  }
}
</script>

<style>
</style>
