<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Statement Details - Ref: {{ AuditTrail.statementserialid }}</h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group">
          <div class="col-lg-4">
            <div class="col-sm-10">
              <b>Commission Agent:</b>
              <b>{{ AuditTrail.agentName }}</b>
            </div>
            <div class="col-sm-10">
              <b>Total Amount:</b>
              £{{ AuditTrail.statementtotal }}
            </div>
            <div class="col-sm-10">
              <b>Status:</b>
              {{ AuditTrail.commissionStatementStatus }}
            </div>
          </div>
          <div class="col-lg-7">
            <div class="col-md-12">
              <b>Timeline</b>
            </div>
            <div class="row col-md-12" v-for="event in AuditTrailEvents" v-bind:key="event.id">
              <div class="col-sm-2">{{event.createdDate | FormatDate}}:</div>
              <div class="col-sm-8">{{event.rubric}}</div>

              <div
                class="col-sm-2"
                v-if="event.eventtype === 'EmailSent' || event.eventtype === 'EmailReSent'"
              >
                <button class="btn btn-default btn-sm" @click="onGetStatementEmail(event)">
                  <i class="fa fa-envelope"></i> View Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="idb-block-content">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="from">Customer</label>
            <vue-select
              v-model="tableCustomersSelect"
              :options="tableDataCustomers"
              label="text"
              :closeOnSelect="true"
              @input="updateTable()"
            ></vue-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              :rows="rows"
              :columns="columns"
              :lineNumbers="true"
              mode="remote"
              :totalRows="totalRecords"
              @on-row-click="onRowClick"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              :search-options="{
            enabled: true
          }"
              styleClass="vgt-table striped bordered"
              ref="table"
            >
              <div slot="table-actions">
                <button
                  @click.prevent="clearTableFilters"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Clear filters'"
                >
                  <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                  </span>
                </button>
                <button
                  @click.prevent="exportData"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Export Data'"
                >
                  <i class="fa fa-share-square pointer dimmedIcon"></i>
                </button>
                <button
                  @click.prevent="onCreateAssociation"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Create a new contact association'"
                >
                  <i class="fa fa-plus dimmedIcon pointer"></i>
                </button>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import StatementStatusType from '@/Assets/Constants/commissionAgentStatementStatusType'
import AuditTrailEventType from '@/Assets/Constants/commissionAgentAuditTrailEventTypes'
import commissionAgentCommissionItemStatus from '../../../../Assets/Constants/commissionAgentCommissionItemStatus'
import VueSelect from 'vue-select'

export default {
  props: {
    id: String
  },
  components: { VueSelect },
  mixins: [loading, tableFilterMixin, bacsMixin],

  filters: {
    FormatDate: function (dateVal) {
      if (!dateVal) { return '' } else {
        return moment(String(dateVal)).format('DD/MM/YYYY')
      }
    }

  },

  data () {
    return {
      AuditTrail: {},
      AuditTrailEvents: [],
      statementid: '',
      rows: [],
      columns: [
        {
          label: 'AssociationId',
          field: 'associationId',
          hidden: true
        },
        {
          label: 'AgentId',
          field: 'agentId',
          hidden: true
        },
        {
          label: 'BillingUsageId',
          field: 'billingUsageId',
          hidden: true
        },
        {
          label: 'Agent',
          field: 'agentName',
          filterOptions: {
          }
        },
        {
          label: 'Customer',
          field: 'customerName',
          filterOptions: {

          }
        },
        {
          label: 'Service Charge Date',
          field: 'serviceChargeCreationDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: true

        },
        {
          label: 'Service Charge (£)',
          field: 'serviceCharge',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },

        {
          label: 'Commission (£)',
          field: 'commissionAmount',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Commission Type',
          field: 'fixedFeePercentage',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Overridden',
          field: 'overridden',
          sortable: true,
          formatFn: this.GetOverriddenField

        },
        {
          label: 'Manual Creation',
          field: 'manualCreated',
          sortable: true,
          formatFn: this.GetManualCreationField
        },
        {
          label: 'Status',
          field: 'commissionItemStatus',
          sortable: true,
          formatFn: this.GetCommissionItemStatusField,
          filterOptions: {
            enabled: true,
            placeholder: 'All',
            filterDropdownItems: Object.values(commissionAgentCommissionItemStatus)
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'serviceCharge', type: 'asc' }]
      },
      tableDataCustomers: [],
      tableAgentsSelect: [],
      tableCustomersSelect: [],
      tableStatusSelect: []
    }
  },
  async created () {
    console.log('audit trail statement loaded')
    await this.load()
  },
  methods: {
    async load () {
      await this.GetAuditTrailData()
      await this.GetAuditTrailEventsData()
      await this.getTableOptions()
      await this.updateTable()
    },
    GetAuditTrailData: async function () {
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetAuditTrailData/${this.id}`,
          {
            params: { paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get Commission Ledger Statement data'
          })
        console.log('Audit Response', response)

        this.AuditTrail = response.data
        this.statementid = response.data.statementid
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    GetAuditTrailEventsData: async function () {
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetAuditTrailEventsData/${this.id}`,
          {
            params: { paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get Commission Ledger Statement data'
          })
        console.log('Events Response', response)

        this.AuditTrailEvents = response.data
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    // loadTable: async function () {
    //   try {
    //     this.isTableLoading = true
    //     console.log('here')
    //     console.log(this.buildGoodTableQuery())
    //     var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionsTableForAudit/${this.id}`,
    //       {
    //         params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
    //         showerror: true,
    //         errormessage: 'Failed to get table data'
    //       })

    //     console.log('response', response)
    //     this.rows = response.data.data
    //     this.totalRecords = response.data.meta.totalItems
    //   } catch { } finally {
    //     this.isTableLoading = false
    //   }
    // },

    async getTableOptions () {
      console.log('Getting Data')
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetStatementCommissionTableOptionData/${this.statementid}`,
          {
            params: { paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data for listing commissions'
          })

        console.log('table data response', response)

        this.tableDataCustomers = []
        for (var j = 0; j < response.data.customerTableData.length; j++) {
          this.tableDataCustomers.push({ value: response.data.customerTableData[j].paygateId, text: response.data.customerTableData[j].customerName })
        }
      } catch (e) {
        console.log('e', e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    updateTable: async function () {
      console.log('this.tableStatusSelect', this.tableStatusSelect)
      if (this.tableStatusSelect.length > 0) {
        var commissionItemStatusArr = []
        this.serverParams.columnFilters.commissionItemStatus = []
        for (var i = 0; i < this.tableStatusSelect.length; i++) {
          commissionItemStatusArr.push(this.tableStatusSelect[i].value)
        }
        this.serverParams.columnFilters.commissionItemStatus = commissionItemStatusArr
      }

      // Deleting here in case carried over from the show commission page
      delete this.serverParams.columnFilters.agentSelect

      delete this.serverParams.columnFilters.customerSelect
      if (this.tableCustomersSelect !== null && this.tableCustomersSelect !== undefined) {
        this.serverParams.columnFilters.customerSelect = this.tableCustomersSelect.value
      }

      this.serverParams.columnFilters.statementid = this.statementid

      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionsTable`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data'
          })
        console.log('table response', response)
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch (e) {
        console.log('update table failed', e)

        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    GetManualCreationField (field) {
      if (field) {
        return 'yes'
      } else { return '' }
    },
    GetOverriddenField (field) {
      if (field) {
        return 'yes'
      } else { return '' }
    },

    GetCommissionItemStatusField (field) {
      if (field === commissionAgentCommissionItemStatus.New) { return 'New' } else if (field === commissionAgentCommissionItemStatus.InStatement) { return 'In Statement' } else if (field === commissionAgentCommissionItemStatus.StatementSent) { return 'Statement Emailed' } else if (field === commissionAgentCommissionItemStatus.StatementPaid) { return 'Paid' } else if (field === commissionAgentCommissionItemStatus.AgentDeactivated) { return 'Not Payable' } else if (field === commissionAgentCommissionItemStatus.Deleted) { return 'Deleted' } else if (field === commissionAgentCommissionItemStatus.Rollback) { return 'Rollback' }
    },
    onRowClick (params) {
      console.log('params', params)
    },
    onGetStatementEmail (event) {
      var id = event.id
      this.$router.push({ name: 'CommissionAgentAuditTrailEmail', params: { id: id } })
    }
  }
}
</script>
