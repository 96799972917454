<template>
  <div id="findSubmissions">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <!-- Header -->
          <div class="idb-block-title">
            <h2>
              Search Submissions
              <span class="pull-right">
                <a :href="helpUrl" target="_blank">
                  <i class="far fa-question-circle d-none"></i>
                </a>
              </span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <!-- Main content -->
          <div class="idb-block-content">
            <div class="row">
              <div class="col-md-2">
                <strong>From Date</strong>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <strong>To Date</strong>
              </div>
              <div class="col-md-1"></div>
              <div v-if="this.isSystemUser || this.isConnectedMerchantAdmin || this.isMasterCustomerAdmin" class="col-md-2">
                <strong>Clients</strong>
              </div>
              <div class="col-md-4">
                <strong>Select a Group</strong>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdFrom"
                  name="createdFrom"
                  id="createdFrom"
                  v-model="createdFrom"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  @focusin.native="fromDateOnfocus"
                  :monday-first="true"
                  :disabledDates="disabledDates"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdTo"
                  name="createdTo"
                  id="createdTo"
                  v-model="createdTo"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  @focusin.native="toDateOnfocus"
                  :monday-first="true"
                  :disabledDates="disabledDates"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-1"></div>
              <div v-if="this.isSystemUser || this.isConnectedMerchantAdmin || this.isMasterCustomerAdmin" class="col-md-2">
                <b-select v-model.trim="selectedCustomerType" :options="customerTypes" @change="test()" />
              </div>
              <div v-if="this.isSystemUser" class="col-md-4">
                <group-select
                  v-model="selectedGroupId"
                  :groups="processingGroups"
                  @input="selectGroup()"
                  :clearable="true"
                  label="label"
                ></group-select>
              </div>
            </div>
            <div v-if="invalidDateRange === true" class="row form-group">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <span class="mandatory">The "From Date" cannot be after the "To Date"</span>
              </div>
            </div>
            <div v-if="this.showMaxRowsWarning" class="row form-group">
              <div class="col-md-12">
                <b-card bg-variant="warning" text-variant="white" class="text-center">
                  <b-card-text>Only the first {{ maxRows }} rows have been returned.</b-card-text>
                </b-card>
              </div>
            </div>
          </div>
          <!-- Footer -->
          <div class="idb-block-footer">
            <div class="row form-group">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  :disabled="this.canSearch === false"
                  v-on:click="searchSubmissions"
                >Search</button>
              </div>
              <div class="col-md-10">
                <button
                  type="button"
                  class="btn btn-outline-primary pull-right"
                  v-on:click="clearSearchParams()"
                >Clear Search Criteria</button>
              </div>
            </div>
            <div v-show="searchComplete === true" class="row form-group">
              <div class="col-md-12">
                <vue-good-table
                  ref="submissions"
                  @on-row-click="onRowClick"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPerPageChange"
                  @on-cell-click="onCellClick"
                  @on-sort-change="onSortChange"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="false"
                  :totalRows="totalRecords"
                  :pagination-options="{
                    enabled: true,
                    perPage: rowsPerPage,
                    dropdownAllowAll: false,
                    setCurrentPage: currentPage,
                    jumpFirstOrLast: true
                  }"
                  :sort-options="{
                    enabled: true,
                    initialSortBy: this.sortCriteria
                  }"
                  styleClass="vgt-table striped bordered"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'submissionStatus'">
                      <span
                        v-b-popover.hover.top="statusToolTip(props.row.submissionStatus)"
                        v-bind:class="getStatusClass(props.row.submissionStatus)"
                        v-bind:style="getStatusStyle(props.row.submissionStatus)"
                      ></span>
                    </span>
                    <span v-else-if="props.column.field === 'submissionSummaryReport'">
                      <span v-bind:class="getSummaryReportClass(props.row.submissionStatus)"></span>
                    </span>
                    <span v-else-if="props.column.field === 'submissionInputReport'">
                      <span v-bind:class="getInputReportClass(props.row.reportId)"></span>
                    </span>
                    <span v-else-if="props.column.field === 'submissionSummaryPdf'">
                      <span v-bind:class="getPdfClass(props.row.submissionStatus)"></span>
                    </span>
                    <span v-else-if="props.column.field === 'submissionSummaryXml'">
                      <span v-bind:class="getXmlClass(props.row.submissionStatus)"></span>
                    </span>
                    <span v-else-if="props.column.field === 'antiFraudList'">
                      <span v-bind:class="getAntiFraudClass()"></span>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                  <div slot="table-actions">
                    <b-button
                      @click.prevent="createSubmissionCsvFile"
                      class
                      variant="link"
                      v-b-popover.hover.top.d500="'Export search results to CSV'"
                    >
                      <i class="fas fa-file-export pointer dimmedIcon"></i>
                    </b-button>
                  </div>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { numeric } from 'vuelidate/lib/validators'
import bacsMixin from '@/Lib/BacsMixin.js'
import roles from '@/Assets/Constants/roles'
import pgConstants from '@/Assets/Constants/paygateId.js'
import { mapGetters } from 'vuex'
import VueSelect from 'vue-select'

export default {
  name: 'searchSubmissions',
  components: {
    VueSelect
  },
  mixins: [
    bacsMixin
  ],
  computed: {
    ...mapGetters(['selectedCustomer']),
    isSystemUser () { return this.$store.getters.isInRole(roles.LZAdmin) || this.$store.getters.isInRole(roles.Finance) || this.$store.getters.isInRole(roles.Implementations) },
    showMaxRowsWarning () { return this.totalRecords === this.maxRows },
    isConnectedMerchantAdmin () { return this.$store.getters.isInRole(roles.ConnectedMerchantAdmin) },
    isMasterCustomerAdmin () { return this.$store.getters.isInRole(roles.MasterCustomerAdmin) }
  },

  data () {
    return {
      canSearch: false,
      maxRows: 1000,
      paygateId: '',
      userId: '',
      paymentEngineTypes: {},
      selectedPaymentEngineType: 0,
      paygateTypes: {},
      selectedPaygateType: -1,
      createdFrom: '',
      createdTo: '',
      selectedGroupId: '',
      selectedGroup: {},
      selectedUserId: '00000000-0000-0000-0000-000000000000',
      reference: '',
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'PaygateId',
          field: 'paygateId',
          hidden: true
        },
        {
          label: 'Client',
          field: 'customerName',
          hidden: true
        },
        {
          label: 'FileNo',
          field: 'fileNumber',
          hidden: true
        },
        {
          label: 'UserId',
          field: 'userId',
          hidden: true
        },
        {
          label: 'Reference',
          field: 'reference'
        },
        {
          label: 'Created By',
          field: 'userName'
        },
        {
          label: 'GroupId',
          field: 'groupId',
          hidden: true
        },
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: 'AccountId',
          field: 'accountId',
          hidden: true
        },
        {
          label: 'Bank',
          field: 'bankReference',
          hidden: true
        },
        {
          label: 'Type',
          field: 'paymentEngineType',
          formatFn: this.setUpperCase,
          hidden: true
        },
        {
          label: 'Amount',
          field: 'totalAmount',
          type: 'number',
          hidden: false,
          formatFn: this.formatAmount
        },
        {
          label: 'Cur',
          field: 'currency',
          hidden: true
        },
        {
          label: 'Created On',
          field: 'createdOn',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Report ID',
          field: 'reportId',
          hidden: true
        },
        {
          label: 'Status',
          field: 'submissionStatus',
          sortable: false
        },
        {
          label: 'Summary Report',
          field: 'submissionSummaryReport',
          sortable: false
        },
        {
          label: 'Input Report',
          field: 'submissionInputReport',
          hidden: true,
          sortable: false
        },
        {
          label: 'PDF',
          field: 'submissionSummaryPdf',
          sortable: false
        },
        {
          label: 'XML',
          field: 'submissionSummaryXml',
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      searchComplete: false,
      disabledDates: {},
      invalidDateRange: false,
      selectedSubmissionId: '',
      subReference: '',
      currentPage: 1,
      rowsPerPage: 10,
      customerTypes: {},
      selectedCustomerType: 1,
      helpUrl: '',
      submissionStatus: '',
      defaultFromDate: '',
      defaultToDate: '',
      customerColumnIndex: 2,
      summaryReportColumnIndex: 17,
      inputReportColumnIndex: 18,
      pdfColumnIndex: 19,
      xmlColumnIndex: 20,
      authResponse: {},
      isLoggedIn: false,
      retrievalUrl: '',
      modalTitle: '',
      isViewReportModalVisible: false,
      reportContent: '',
      networkType: 'BacstelIp',
      sortCriteria: {},
      processingGroups: []
    }
  },

  methods: {
    async searchSubmissions () {
      if (this.checkDates() === false || this.$v.$invalid === true) {
        return
      }

      this.searchComplete = false
      this.rows = []
      this.initSortCriteria()

      var fromDate = this.createdFrom
      var toDate = this.createdTo

      if (fromDate === '') {
        fromDate = '01/01/0001'
      }

      if (toDate === '') {
        toDate = '01/01/0001'
      }

      var groupId = '00000000-0000-0000-0000-000000000000'
      if (this.selectedGroupId !== undefined && this.selectedGroupId !== null && this.selectedGroupId !== '') {
        groupId = this.selectedGroupId
      }

      var submissionSearchParams = JSON.stringify({
        createdFrom: fromDate,
        createdTo: toDate,
        paymentEngineType: this.selectedPaymentEngineType,
        maxRows: this.maxRows,
        paygateId: this.paygateId,
        customerType: this.selectedCustomerType,
        submissionStatus: this.submissionStatus,
        selectedGroupId: groupId
      })

      this.$store.dispatch('setSearchParams', submissionSearchParams)

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/search/submissions/',
        data: submissionSearchParams,
        showload: true
      })

      if (response.data !== null) {
        this.totalRecords = response.data.length
        this.rows = response.data
        this.currentPage = 1
      }

      if (this.isSystemUser || this.isConnectedMerchantAdmin || this.isMasterCustomerAdmin) {
        this.columns[this.customerColumnIndex].hidden = false
      }

      this.toggleSummaryReportColumn()
      this.searchComplete = true
    },

    formatAmount (value) {
      return this.formatValue(value)
    },

    clearSearchParams () {
      this.selectedGroupId = ''
      this.selectedUserId = '00000000-0000-0000-0000-000000000000'
      this.createdFrom = ''
      this.createdTo = ''
      this.reference = ''
      this.maxRows = 1000
      this.selectedPaymentEngineType = 0
      this.rows = []
      this.searchComplete = false
      this.totalRecords = 0
      this.submissionStatus = ''
      this.selectedCustomerType = 1
      this.initSearchDates()
    },

    async initComboData () {
      this.clearSearchParams()

      this.userId = this.$store.getters.getClaim('sub').value
      if (this.isSystemUser) {
        this.paygateId = this.selectedCustomer
      } else {
        this.paygateId = this.$store.getters.getClaim('paygate_id').value
      }
    },

    initSearchDates () {
      // Dates in Javascript and/or combined with the date picker are nuts.
      // Dateformat dd/MM/yyyy returned by server has the day and month swapped over by JS/datepicker.
      // Hence the fudge of using the MM returned as the day and the dd returned as the month!
      var mm = this.defaultFromDate.substring(0, 2)
      var dd = this.defaultFromDate.substring(3, 5)
      var yyyy = this.defaultFromDate.substring(6, 10)
      this.createdFrom = dd + '/' + mm + '/' + yyyy

      mm = this.defaultToDate.substring(0, 2)
      dd = this.defaultToDate.substring(3, 5)
      yyyy = this.defaultToDate.substring(6, 10)
      this.createdTo = dd + '/' + mm + '/' + yyyy
    },

    async getEnums () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/search/getDropdownValues`)
      this.customerTypes = response.data.customerTypes
      this.processingGroups = response.data.processingGroups
      this.defaultFromDate = response.data.defaultFromDate
      this.defaultToDate = response.data.defaultToDate
    },

    async selectGroup () {
    },

    onRowClick (params) {
      var subId = params.row.id
      this.$store.dispatch('setActionId', undefined)
      this.$store.dispatch('setSubmissionId', subId)
      this.$store.dispatch('setFileNumber', 1)
      this.$store.dispatch('setSearchResults', this.rows)
    },

    async onCellClick (params) {
      this.$store.dispatch('setSubmissionId', params.row.id)
      this.$store.dispatch('setFileNumber', 1)
      this.$store.dispatch('setSearchResults', this.rows)

      if (params.column.field === 'submissionSummaryReport') {
        if (this.showSummaryReportIcon(params.row.submissionStatus)) {
          const routeData = this.$router.resolve({ path: '/payments/common/viewSubmissionSummary', query: { submissionId: params.row.id, fileNumber: params.row.fileNumber, paygateId: params.row.paygateId } })
          window.open(routeData.href, '_blank')
        }
      } else if (params.column.field === 'submissionInputReport') {
        if (params.row.reportId !== null) {
          const routeData = this.$router.resolve({ path: '/payments/common/viewInputReport', query: { submissionId: params.row.id, fileNumber: params.row.fileNumber } })
          window.open(routeData.href, '_blank')
        }
      } else if (params.column.field === 'submissionSummaryXml') {
        if (this.showSummaryReportIcon(params.row.submissionStatus)) {
          this.downloadXml(params.row.id, params.row.fileNumber)
        }
      } else if (params.column.field === 'submissionSummaryPdf') {
        if (this.showSummaryReportIcon(params.row.submissionStatus)) {
          this.downloadPdf(params.row.id, params.row.fileNumber)
        }
      } else if (params.column.field === 'antiFraudList') {
        this.returnToAntiFraudList(params.row.id)
      } else {
        this.$store.dispatch('setPayments', [])
        this.$store.dispatch('setCustomerPaygateId', params.row.paygateId)
        const subUrl = `/payments/search/submissionWrapper?returnPage=searchSubmissions&submissionId=${params.row.id}&fileNumber=${params.row.fileNumber}`
        this.$router.push(subUrl)
      }
    },

    checkDates () {
      var datesOk = true
      if (this.createdFrom !== '' && this.createdTo !== '') {
        var fromDate = new Date(this.createdFrom)
        var toDate = new Date(this.createdTo)

        if (fromDate > toDate) {
          this.invalidDateRange = true
          datesOk = false
        } else {
          this.invalidDateRange = false
        }
      }

      return datesOk
    },

    checkSearchCriteria () {
      var criteriaEntered = true

      if ((this.createdFrom === '' || this.createdFrom === '01/01/0001') &&
        (this.createdTo === '' || this.createdTo === '01/01/0001') &&
        (this.selectedGroupId === '' || this.selectedGroupId === '00000000-0000-0000-0000-000000000000') &&
        (this.selectedUserId === '' || this.selectedUserId === '00000000-0000-0000-0000-000000000000') &&
        this.reference === '' &&
        this.selectedPaymentEngineType === 0) {
        criteriaEntered = false
        // this.$toastr.w('Please enter search criteria')
        this.$swal({
          title: 'Submission Search',
          text: 'Please enter some search criteria. E.g. A From Date and a Group or a PaymentType which isn\'t "All".',
          type: 'warning',
          animation: false
        })
      }

      return criteriaEntered
    },

    onSortChange (params) {
      this.sortCriteria = {
        field: params[0].field,
        type: params[0].type
      }
      
      this.$store.dispatch('setSortCriteria', this.sortCriteria)
    },

    initSortCriteria () {
      this.sortCriteria = {
        field: 'createdOn',
        type: 'desc'
      }
    },

    getStatusClass (submissionStatus) {
      var iconClass = ''
      switch (submissionStatus) {
        case 'Created':
          iconClass = 'fa fa-plus'
          break
        case 'Signed':
          iconClass = 'fa fa-file-signature'
          break
        case 'Approved':
          iconClass = 'fa fa-thumbs-up'
          break
        case 'complete':
          iconClass = 'fa fa-check-circle'
          break
        case 'rejected':
          iconClass = 'fa fa-times-circle'
          break
        case 'Cancelled':
          iconClass = 'fa fa-trash-alt'
          break
        case 'warning':
          iconClass = 'fa fa-exclamation-circle'
          break
        case 'CreditDue':
          iconClass = 'fa fa-pause'
          break
        case 'CreditSuccessful':
          iconClass = 'fa fa-credit-card'
          break
        case 'CreditFailed':
          iconClass = 'fa fa-exclamation-circle'
          break
      }
      return iconClass
    },

    getSummaryReportClass (submissionStatus) {
      var iconClass = ''
      if (this.showSummaryReportIcon(submissionStatus)) {
        iconClass = 'fa fa-file-alt'
      }
      return iconClass
    },

    getInputReportClass (reportId) {
      var iconClass = ''
      if (reportId !== null) {
        iconClass = 'fa fa-file-alt'
      }
      return iconClass
    },

    getXmlClass (submissionStatus) {
      var iconClass = ''
      if (this.showSummaryReportIcon(submissionStatus)) {
        iconClass = 'fa fa-file-code'
      }
      return iconClass
    },

    getPdfClass (submissionStatus) {
      var iconClass = ''
      if (this.showSummaryReportIcon(submissionStatus)) {
        iconClass = 'fa fa-file-pdf'
      }
      return iconClass
    },

    getAntiFraudClass () {
      return 'fa fa-database'
    },

    getStatusStyle (submissionStatus) {
      var colour = ''
      switch (submissionStatus) {
        case 'complete':
        case 'CreditSuccessful':
          colour = 'color: green;'
          break
        case 'rejected':
        case 'CreditFailed':
          colour = 'color: red;'
          break
        case 'warning':
          colour = 'color: #DD6B55;'
          break
        case 'Signed':
          colour = 'color: #606266;'
          break
      }
      return colour
    },

    statusToolTip (submissionStatus) {
      var toolTip = ''
      switch (submissionStatus) {
        case 'complete':
          toolTip = 'Complete'
          break
        case 'rejected':
          toolTip = 'Rejected'
          break
        case 'warning':
          toolTip = 'Warning'
          break
        case 'CreditSuccessful':
          toolTip = 'Credit Successful'
          break
        case 'CreditFailed':
          toolTip = 'Credit Failed'
          break
        case 'CreditDue':
          toolTip = 'Credit Due'
          break
        default:
          toolTip = submissionStatus
          break
      }
      return toolTip
    },

    onPageChange (params) {
      this.currentPage = params.currentPage
      this.$store.dispatch('setCurrentSearchResultsPage', this.currentPage)
    },

    onPerPageChange (params) {
      this.rowsPerPage = params.currentPerPage
      this.$store.dispatch('setSearchRowsPerPage', this.rowsPerPage)
      this.searchSubmissions()
    },

    async downloadXml (submissionId, fileNumber) {
      var url = `${process.env.VUE_APP_BACS_API_URL}bacs/submission/getSubmissionSummaryXml?submissionId=${submissionId}&fileNumber=${fileNumber}`
      var response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob' // important
      })

      if (response.headers['content-disposition'] !== undefined) {
        this.saveFile(response)
      }
    },

    async downloadPdf (submissionId, fileNumber) {
      var url = `${process.env.VUE_APP_BACS_API_URL}bacs/report/downloadSubmissionSummaryPdf?submissionId=${submissionId}&selectedPaygateId=${this.paygateId}&fileNumber=${fileNumber}`
      var response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob' // important
      })

      if (response.headers['content-disposition'] !== undefined) {
        this.saveFile(response)
      }
    },

    setUpperCase (value) {
      return value.toUpperCase()
    },

    submissionSent (submissionStatus) {
      return submissionStatus === 'complete' || submissionStatus === 'rejected' || submissionStatus === 'warning' ||
             submissionStatus === 'CreditDue' || submissionStatus === 'CreditSuccessful' || submissionStatus === 'CreditFailed'
    },

    showSummaryReportIcon (submissionStatus) {
      var showIcon = this.isSystemUser

      if (this.isSystemUser) {
        showIcon = this.submissionSent(submissionStatus) && this.paygateId === pgConstants.paygateId && this.selectedCustomerType === 1
      }

      return showIcon
    },

    toggleSummaryReportColumn () {
      var hideSummaryCol = this.paygateId !== pgConstants.paygateId || this.selectedCustomerType !== 1
      this.columns[this.summaryReportColumnIndex].hidden = hideSummaryCol
      this.columns[this.pdfColumnIndex].hidden = hideSummaryCol
      this.columns[this.xmlColumnIndex].hidden = hideSummaryCol
    },

    test () {
      this.searchComplete = false
    },

    async createSubmissionCsvFile () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createSubmissionSearchResultsCsvFile'
        let request = JSON.stringify({
          searchResults: this.rows,
          sortBy: this.sortCriteria.field,
          sortOrder: this.sortCriteria.type
        })

        var response = await axios({
          url: url,
          method: 'POST',
          data: request,
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    }
  },

  watch: {
    selectedCustomer () {
      this.initComboData()
      this.initSearchDates()
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (this.isLoggedIn) {
      await this.vocalinkLogoff()
      this.isLoggedIn = false
    }
    next()
  },
  created () {
    this.disabledDates.from = new Date()
  },

  async mounted () {
    document.getElementById('createdFrom').style.backgroundColor = 'white'
    document.getElementById('createdTo').style.backgroundColor = 'white'

    this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/search/searchsubmissions/'
    await this.initComboData()
    await this.getEnums()

    var useSearchParams = this.$route.query.useSearchParams
    if (useSearchParams === undefined) {
      this.$store.dispatch('setSearchParams', null)
      this.$store.dispatch('setCurrentSearchResultsPage', 1)
      this.initSearchDates()
    } else {
      try {
        var searchParams = JSON.parse(this.$store.getters.searchParams)
        this.selectedPaymentEngineType = searchParams.paymentEngineType
        this.createdFrom = searchParams.createdFrom !== '01/01/0001' ? searchParams.createdFrom : ''
        this.createdTo = searchParams.createdTo !== '01/01/0001' ? searchParams.createdTo : ''
        this.reference = searchParams.reference
        this.maxRows = searchParams.maxRows
        if (searchParams.selectedGroupId !== '00000000-0000-0000-0000-000000000000') {
          this.selectedGroupId = searchParams.selectedGroupId
        }
        if (searchParams.selectedUserId !== '00000000-0000-0000-0000-000000000000') {
          this.selectedUserId = searchParams.selectedUserId
        }
        this.selectedPaygateType = searchParams.paygateType
        this.selectedCustomerType = searchParams.customerType

        if (this.isSystemUser) {
          this.columns[this.customerColumnIndex].hidden = false
        }
        if (useSearchParams === 'true') {
          this.rows = this.$store.getters.searchResults
          this.sortCriteria = this.$store.getters.sortCriteria
          console.log('Mounted sortCriteria: ' + JSON.stringify(this.sortCriteria))
          this.totalRecords = this.rows.length
          this.searchComplete = true
          this.currentPage = this.$store.getters.currentSearchResultsPage
          this.rowsPerPage = this.$store.getters.searchRowsPerPage
          this.toggleSummaryReportColumn()
        } else if (useSearchParams === 'auto') {
          this.submissionStatus = 'complete'
          await this.searchSubmissions()
        }
      } catch (ex) {
        // Exception thrown if user returns to search results and decides, in a way only QA can, to refresh the browser.
        const query = Object.assign({}, this.$route.query)
        delete query.useSearchParams
        this.$router.replace({ query })
        this.$store.dispatch('setSearchParams', null)
        this.$store.dispatch('setCurrentSearchResultsPage', 1)
        this.initSearchDates()
      }
    }
    this.canSearch = true
  },

  validations: {
    maxRows: { numeric }
  }
}
</script>
