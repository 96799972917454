import { render, staticRenderFns } from "./LetterTemplates.vue?vue&type=template&id=be8e4e42&scoped=true&"
import script from "./LetterTemplates.vue?vue&type=script&lang=js&"
export * from "./LetterTemplates.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be8e4e42",
  null
  
)

export default component.exports