<template>
  <div>
    <div id="manageSchedule" class="pb-5">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <form @submit.prevent="checkValidation() && importData()">
              <div class="idb-block-title">
                <h2><help-icon docPath="/paygate-collections/import-export/importpayer/" />Import Customers<favourite-icon></favourite-icon></h2>
              </div>
              <div class="idb-block-content">
                <div class="row form-group">
                  <div class="col-md-3 required">
                    Select a Group
                  </div>
                  <div class="col-md-4">
                    <group-select
                      v-model="$v.group.$model"
                      :groups="customerGroups"
                      @input="customerGroupsChanged"
                      :clearable="false"
                      :allowFilter="false"
                    ></group-select>
                    <validation-messages name="Group" v-model="$v.group" />
                  </div>
                </div>
                <b-form-group
                  :label-cols="3"
                  horizontal
                  label="Tick if customers are new and require a mandate setting up with the bank (0N)"
                  v-if="isSystemAdmin"
                >
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="newPayers"
                    @change="checkNewMandate()"
                    :disabled="!groupSelected"
                  ></p-check>
                </b-form-group>
                <b-form-group
                  :label-cols="3"
                  horizontal
                  label="Tick if customers require migrating to AUDDIS (0S)"
                  v-if="isSystemAdmin"
                >
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="migration"
                    @change="checkMigration()"
                    :disabled="!groupSelected"
                  ></p-check>
                </b-form-group>

                <div class="row form-group">
                  <div class="col-md-3 required">
                      Activation Date
                  </div>
                  <div class="col-md-4">
                  <div class="right-inner-addon">
                    <datepicker id="startPaymentDatePicker"
                    v-model="activationDate"
                    :format="formatActiviationDate"
                    :disabledDates="disabledDates"
                    input-class="form-control datepicker"
                    :disabled="!groupSelected"
                    /><i class="fa fa-calendar form-control-feedback"></i>
                  </div>
                  </div>
                </div>

                <b-form-group
                  :label-cols="3"
                  horizontal
                  label="Send Notification Of Change" v-if="isSystemAdmin"
                >
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="sendNotificationOfChange"
                    :disabled="!groupSelected"
                  ></p-check>
                </b-form-group>
                <div class="row form-group" v-if="isSystemAdmin && sendNotificationOfChange">
                  <div class="col-md-3 required">Previous Service User Name</div>
                  <div class="col-md-4">
                    <input
                      type="text" class="form-control"
                      v-model="oldServiceUserName"
                      :disabled="!groupSelected"></input>
                    </div>
                  </div>
                <b-form-group v-if="false"
                  :label-cols="3"
                  horizontal
                  label="Update customer if duplicate reference found (excludes Bank Details)"
                >
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="updateIfDuplicate"
                    :disabled="!groupSelected"
                  ></p-check>
                </b-form-group>
                <b-form-group
                  :label-cols="3"
                  horizontal
                  v-if="hasPayerPortal"
                  label="Tick if customers should have a Customer Portal User created upon import"
                >
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="createPayerPortalUser"
                    :disabled="!groupSelected"
                  ></p-check>
                </b-form-group>
                <b-container v-if="groupSelected">
                  <dropzoneLike ref="serverFileUploader"
                    @fileAdded="publicFileAdded"
                    @fileRemoved="publicFileRemoved"
                    :groupId="group"
                    :dropzoneOptions="dropzoneOptions"
                    :dropzoneUrl="dropzoneUrl"
                    :secureUrl="secureUrl">
                    </dropzoneLike>
                </b-container>
              </div>
              <div class="idb-block-footer">
                <b-button type="submit" variant="primary" :disabled="!importEnabled || isLoading || !canUpload">Import</b-button>
                <span class="pull-right"><p-check
                  class="p-switch p-fill"
                  color="primary"
                  v-model="showHelp"
                ></p-check> Show Help</span>
              </div>
            </form>
          </div>
          <div class="idb-block mb-20" v-if="showHelp">
            <div class="idb-block-title">
              <h2>File Format Definition</h2>
            </div>
            <div class="idb-block-content">
              <b-form-group :label-cols="3"
                            horizontal
                            label="Hide Optional Schedule Fields"
              >
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  v-model="hideScheduleFields"
                ></p-check>
              </b-form-group>
              <table class="table">
              <tr>
                <th>Field Name</th>
                <th>Type</th>
                <th>Example</th>
                <th>Required</th>
              </tr>
              <tr>
                <td>Sort Code</td>
                <td>Numbers (6)</td>
                <td>123456</td>
                <td><i class="fa fa-check"></i></td>
              </tr>
              <tr>
                <td>Account Number</td>
                <td>Numbers (8)</td>
                <td>12345678</td>
                <td><i class="fa fa-check"></i></td>
              </tr>
              <tr>
                <td>Account Holders Name</td>
                <td>Text (18)</td>
                <td>John Smith</td>
                <td><i class="fa fa-check"></i></td>
              </tr>
              <tr>
                <td>Status</td>
                <td>Number from a defined list of values.</td>
                <td>0 = Operational<br/>
                  1 = Suspended<br/>
                  2 = Alert<br/>
                  3 = Closed</td>
                <td><i class="fa fa-check"></i></td>
              </tr>
              <tr>
                <td>Status Reason</td>
                <td>Number from a defined list of values</td>
                <td>0</td>
                <td><i class="fa fa-times"></i></td>
              </tr>
              <tr>
                <td>Title</td>
                <td>Number from a defined list of values.</td>
                <td>0 = Mr<br/>
                  1 = Mrs<br/>
                  2 = Mss<br/>
                  3 = Ms<br/>
                  4 = Mx<br/>
                  5 = Dr<br/>
                  6 = Other</td>
                <td><i class="fa fa-check"></i></td>
              </tr>
              <tr>
                <td>First Name</td>
                <td>Text (30)</td>
                <td>John</td>
                <td><i class="fa fa-check"></i></td>
              </tr>
              <tr>
                <td>Surname</td>
                <td>Text (30)</td>
                <td>Smith</td>
                <td><i class="fa fa-check"></i></td>
              </tr>
              <tr>
                <td>Reference</td>
                <td>Text (18)</td>
                <td>ABCD1234AB</td>
                <td><i class="fa fa-check"></i></td>
              </tr>
              <tr>
                <td>Address 1</td>
                <td>Text (40)</td>
                <td>1 High Street</td>
                <td><i class="fa fa-check"></i></td>
              </tr>
              <tr>
                <td>Address 2</td>
                <td>Text (40)</td>
                <td></td>
                <td><i class="fa fa-times"></i></td>
              </tr>
              <tr>
                <td>Address 3</td>
                <td>Text (40)</td>
                <td></td>
                <td><i class="fa fa-times"></i></td>
              </tr>
              <tr>
                <td>Address 4</td>
                <td>Text (40)</td>
                <td></td>
                <td><i class="fa fa-times"></i></td>
              </tr>
              <tr>
                <td>Postcode</td>
                <td>Text (30)</td>
                <td>AB1 4BC</td>
                <td><i class="fa fa-check"></i></td>
              </tr>
              <tr>
                <td>Email</td>
                <td>Text</td>
                <td>email.address@domain.com</td>
                <td><i class="fa fa-check"></i></td>
              </tr>
              <tr>
                <td>Tel 1</td>
                <td>Text (30)</td>
                <td>01234567891</td>
                <td><i class="fa fa-times"></i></td>
              </tr>
              <tr>
                <td>Tel 2</td>
                <td>Text (30)</td>
                <td>01234567892</td>
                <td><i class="fa fa-times"></i></td>
              </tr>
              <tr>
                <td>Mobile</td>
                <td>Text (30)</td>
                <td>07234567891</td>
                <td><i class="fa fa-times"></i></td>
              </tr>
              <tr>
                <td>Business Name</td>
                <td>Text (30)</td>
                <td>Smith Cheeses</td>
                <td><i class="fa fa-times"></i></td>
              </tr>
              <template v-if="!hideScheduleFields">
                <tr>
                  <td>First Amount</td>
                  <td>Decimal</td>
                  <td>123.45</td>
                  <td><i class="fa fa-times"></i></td>
                </tr>
                <tr>
                  <td>Last Amount</td>
                  <td>Decimal</td>
                  <td>123.45</td>
                  <td><i class="fa fa-times"></i></td>
                </tr>
                <tr>
                  <td>Regular Amount</td>
                  <td>Decimal</td>
                  <td>123.45</td>
                  <td><i class="fa fa-times"></i></td>
                </tr>
                <tr>
                  <td>Gift Aid</td>
                  <td>Text from a defined list of values</td>
                  <td>TRUE or FALSE</td>
                  <td><i class="fa fa-times"></i></td>
                </tr>
                <tr>
                  <td>First Collection Date</td>
                  <td>Date (dd/MM/yyyy)</td>
                  <td>09/09/2022</td>
                  <td><i class="fa fa-times"></i></td>
                </tr>
                <tr>
                  <td>Ordinal</td>
                  <td>Number</td>
                  <td>3</td>
                  <td><i class="fa fa-times"></i></td>
                </tr>
                <tr>
                  <td>Frequency</td>
                  <td>Text from defined list of values.</td>
                  <td>Weekly, FourWeekly, Monthly, TwoMonthly, Quarterly, SixMonthly, Fortnightly, Yearly</td>
                  <td><i class="fa fa-times"></i></td>
                </tr>
                <tr>
                  <td>End Type</td>
                  <td>Text from defined list of values.</td>
                  <td>OneOff, UntilDate, UntilNumberOfCollections, Ongoing, AdHoc</td>
                  <td><i class="fa fa-times"></i></td>
                </tr>
                <tr>
                  <td>Final Date</td>
                  <td>Date (dd/MM/yyyy)</td>
                  <td>14/04/2023</td>
                  <td><i class="fa fa-times"></i></td>
                </tr>
                <tr>
                  <td>Number Of Collections</td>
                  <td>Number</td>
                  <td>0</td>
                  <td><i class="fa fa-times"></i></td>
                </tr>
                <tr>
                  <td>Schedule Name</td>
                  <td>Text</td>
                  <td>Cheese Order</td>
                  <td><i class="fa fa-times"></i></td>
                </tr>
              </template>
              </table>
              <p>The numbers in brackets indicate the minimum and maximum number of characters in a given field.</p>
              <p>If a field is marked as optional, an empty field can be submitted.</p>
              <p>The first line of the file with column headers is optional.</p>
              <p>
                If the schedule fields are included the following fields are mandatory:
              </p>
              <ul>
                <li>Regular Amount</li>
                <li>Gift Aid</li>
                <li>First Collection Date</li>
                <li>Ordinal</li>
                <li>Frequency</li>
                <li>End Type</li>
              </ul>
              <p>Ad Hoc schedules do not require First Amounts, Regular Amounts, Last Amounts, Ordinal, First Collection Dates or Frequency.</p>
              <p>You cannot mix customers with schedules and customers without in one file.</p>
            </div>
          </div>
          <div class="idb-block mb-20" v-if="showHelp">
            <div class="idb-block-title">
              <h2>Examples</h2>
            </div>
            <div class="idb-block-content">
              <b-form-group :label-cols="3"
                            horizontal
                            label="Show table format"
              >
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  v-model="showTableFormat"
                ></p-check>
              </b-form-group>
              <b-form-group :label-cols="3"
                            horizontal
                            label="Hide Schedule Fields"
              >
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  v-model="hideScheduleFields"
                ></p-check>
              </b-form-group>
              <p v-if="!showTableFormat && hideScheduleFields">Sort Code,Account Number,Account Holders Name,Status,Status Reason,Title,First Name,Surname,Reference,Address 1,Address 2,Address 3,Address 4,Post Code,Email,Tel 1,Tel 2,Mobile,BusinessName<br/>
                123456,12345678,John Smith,0,0,0,John,Smith,ABCD1234AB,1 High Street,,,,AB1 4BC,email.address@domain.com,01234567891,01234567892,07234567891,Smiths Cheeses</p>
              <p v-if="!showTableFormat && !hideScheduleFields">Sort Code,Account Number,Account Holders Name,Status,Status Reason,Title,First Name,Surname,Reference,Address 1,Address 2,Address 3,Address 4,Post Code,Email,Tel 1,Tel 2,Mobile,BusinessName,First Amount,Last Amount,Regular Amount,Gift Aid,First Collection Date,Ordinal,Recurrence Type,Recurrence End Type,Until Date,Total Collections,Schedule Name<br/>
                123456,12345678,John Smith,0,0,0,John,Smith,ABCD1234AB,1 High Street,,,,AB1 4BC,email.address@domain.com,01234567891,01234567892,07234567891,Smiths Cheeses,123.45,123.45,123.45,FALSE,09/09/2022,1,Fortnightly,Ongoing,01/01/1900,0,Cheese Order</p>
              <div class="overflow-auto">
                <table class="table" v-if="showTableFormat">
                  <tr>
                    <th>sortcode</th>
                    <th>accountnumber</th>
                    <th>accountholdersname</th>
                    <th>status</th>
                    <th>statusreason</th>
                    <th>title</th>
                    <th>firstname</th>
                    <th>surname</th>
                    <th>reference</th>
                    <th>address1</th>
                    <th>address2</th>
                    <th>address3</th>
                    <th>address4</th>
                    <th>postcode</th>
                    <th>email</th>
                    <th>tel1</th>
                    <th>tel2</th>
                    <th>mobile</th>
                    <th>businessname</th>
                    <template v-if="!hideScheduleFields">
                      <td>firstamount</td>
                      <td>lastamount</td>
                      <td>regularamount</td>
                      <td>giftaid</td>
                      <td>firstcollectiondate</td>
                      <td>ordinal</td>
                      <td>recurrencetype</td>
                      <td>recurrenceendtype</td>
                      <td>untildate</td>
                      <td>totalcollections</td>
                      <td>schedulename</td>
                    </template>
                  </tr>
                  <tr>
                    <td>123456</td>
                    <td>12345678</td>
                    <td>John Smith</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>John</td>
                    <td>Smith</td>
                    <td>8888X01</td>
                    <td>1 High Street</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>AB1 4BC</td>
                    <td>email.address@domain.com</td>
                    <td>01234567891</td>
                    <td>01234567892</td>
                    <td>07234567891</td>
                    <td>Smith Cheeses</td>
                    <template v-if="!hideScheduleFields">
                      <td>123.45</td>
                      <td>123.45</td>
                      <td>123.45</td>
                      <td>FALSE</td>
                      <td>01/02/2023</td>
                      <td>1</td>
                      <td>Fortnightly</td>
                      <td>Ongoing</td>
                      <td>01/01/1900</td>
                      <td>34</td>
                      <td>Cheese Order</td>
                    </template>
                  </tr>
                </table>
              </div>
            </div>
            <div class="idb-block-footer">
              <b-button variant="primary" @click="downloadExample">Download Example File</b-button>
            </div>
          </div>
          <div class="idb-block mb-20">
            <div class="idb-block-title">
              <h2>File Upload Status</h2>
            </div>
            <div class="idb-block-content">
              <vue-good-table
                mode="remote"
                ref="table"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                @on-row-click="onRowClick"
                @on-cell-click="onCellClick"
                :columns="columns"
                :rows="uploads"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :search-options="{
                    enabled: false
                    }"
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                :isLoading.sync="isTableLoading"
                styleClass="vgt-table striped bordered"
                class="scroll-table"
              >
                <template slot="loadingContent">
                  <h1>Loading...</h1>
                </template>
                <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">No Uploads Available</div>
                </div>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'"
                  >
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span>
                  </button>
                  <b-button
                    @click.prevent="load" :disabled="isLoading"
                    class
                    variant="link"
                    v-b-popover.hover.top.d500="'Refresh the data in the table'"
                  >
                    <i class="fa fa-sync pointer dimmedIcon"></i>
                  </b-button>
                  <b-button v-if="false"
                    @click.prevent="deleteRecords"
                    class
                    variant="link"
                    v-b-popover.hover.top.d500="'Clear incomplete uploads'"
                  >
                    <i class="fa fa-times pointer dimmedIcon"></i>
                  </b-button>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'uploadStatus'">
                    <div class="fileNameAligner">
                      <b-badge
                        :variant="getSeverityClass(props.row)"
                      >{{ convertStatus(props.row) }}</b-badge>
                    </div>
                  </span>
                  <span v-else-if="props.column.label === 'Processed'">
                    <div class="uploadPercentageContainer">
                      <div
                        class="uploadPercentage"
                        :class="buildPercentageBarClass(percentageCompleted(props.row))"
                        :style="{ width: percentageCompleted(props.row) +'%' }"
                      >&nbsp;</div>
                      <div
                        class="uploadPercentageLabel"
                      >{{ props.row.processedRows +'/'+props.row.totalRows }}</div>
                    </div>
                  </span>
                  <span v-else-if="props.column.label === 'Validated'">
                    <div class="uploadPercentageContainer">
                      <div
                        class="uploadPercentage"
                        :class="buildPercentageBarClass(validationCompleted(props.row))"
                        :style="{ width: validationCompleted(props.row) +'%' }"
                      >&nbsp;</div>
                      <div class="uploadPercentageLabel">{{ validationCompleted(props.row) }}%</div>
                    </div>
                  </span>
                  <span
                    v-else-if="props.column.field === 'processedRows' || props.column.field === 'invalidRows' || props.column.field === 'totalRows'"
                  >
                    <div class="numericAligner">{{props.formattedRow[props.column.field]}}</div>
                  </span>
                  <span v-else-if="props.column.label === ''">
                    <b-button
                      v-b-popover.hover.top.d500="'Remove upload from table'"
                      variant="outline-danger"
                      @click="clearUpload(props.row.uploadedFileId)"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i> Clear
                    </b-button>
                  </span>
                  <span v-else-if="props.column.field === 'uploadDate'">
                    <div class="fileNameAligner">{{formatDateTime(props.row.uploadDate)}}</div>
                  </span>
                  <span v-else>
                    <div class="fileNameAligner">{{props.formattedRow[props.column.field]}}</div>
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { required } from 'vuelidate/lib/validators'
import ValidationMessages from '@/Assets/Components/Validation/ValidationMessages.vue'
import EventBus from '@/Lib/eventBus'
import moment from 'moment'
import dropzoneLike from '@/Components/Shared/DropzoneLike.vue'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import Datepicker from 'vuejs-datepicker'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import roles from '@/Assets/Constants/roles'
import rulesMixin from '@/Assets/Mixins/CollectionsRulesMixin'
import Store from '@/Store'
export default {
  mixins: [tableFilterMixin, loading, rulesMixin],
  created () {
    window.addEventListener('beforeunload', this.stopSocketListener)
    this.uploadHubUrl = process.env.VUE_APP_DDMS_API_URL + 'hubs/uploads'
    if (this.paygateId) {
      this.dropzoneUrl += '?paygateId=' + this.paygateId
      this.uploadHubUrl += '?paygateId=' + this.paygateId
    }
    this.dropzoneOptions.url = this.dropzoneUrl
    this.dropzoneOptions.secureUrl = this.dropzoneUrl

    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        // this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
        this.uploadStatusConnection = new HubConnectionBuilder().withUrl(this.uploadHubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.uploadStatusConnection.serverTimeoutInMilliseconds = 3600000
        this.uploadStatusConnection.start()
        this.uploadStatusConnection.on('RefreshUploads', data => {
          this.load()
        })
        this.uploadStatusConnection.on('UploadComplete', (data) => {
          console.log(data)
          this.$toastr.Add({
            name: 'uploadComplete',
            msg: data.file.originalFileName + '. successfully uploaded',
            preventDuplicates: true,
            type: 'success'
          })
        })
      })
  },
  data () {
    return {
      dropzoneUrl: `${process.env.VUE_APP_DDMS_API_URL}import`,
      uploadStatusConnection: null,
      uploadHubUrl: '',
      fileFormats: ['csv'], // xml, json
      dataTypes: ['Payer'],
      dropzoneOptions: {
        maxFilesize: 250, // MB
        maxFiles: 4,
        acceptedFiles: '.csv',
        dictDefaultMessage: '<i class="fa fa-cloud-upload fa-4x"></i><br/><br/><h3 class="dropzone-custom-title">Drop a delimited file such as a CSV file here.</h3><div class="subtitle">Or click to select a file to upload.</div>',
        url: '',
        title: 'Drag and drop to upload customers',
        subtitle: '...or click to select a file from your computer',
        previewTemplate: this.previewTemplate()
      },
      dataType: 'Payer',
      newPayers: true,
      migration: false,
      createPayerPortalUser: false,
      group: '00000000-0000-0000-0000-000000000000',
      fileFormat: 'csv',
      bearerToken: '',
      fileIds: [],
      uploads: [],
      columns: [
        {
          label: 'File Name',
          field: 'originalFileName'
        },
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: 'Status',
          field: 'uploadStatus',
          sortable: false
        },
        {
          label: 'Upload Date',
          field: 'uploadDate',
          formatFn: this.formatDateTime
        },
        {
          label: 'Activation Date',
          field: 'activationDate',
          formatFn: this.formatDate
        },
        {
          label: 'Validated',
          field: this.validationCompleted,
          sortable: false
        },
        {
          label: 'Invalid Rows',
          field: 'invalidRows',
          sortable: false
        },
        {
          label: 'Processed',
          field: this.percentageCompleted,
          sortable: false
        },
        {
          label: '',
          field: () => { }
        }
      ],
      serverParams: {
        sort: [{ field: 'uploadDate', type: 'desc' }]
      },
      publicFiles: [],
      fileIdResponseMap: [],
      updateIfDuplicate: false,
      activationDate: new Date(),
      sendNotificationOfChange: false,
      showTableFormat: false,
      showHelp: false,
      hideScheduleFields: false,
      oldServiceUserName: ''
    }
  },
  components: {
    ValidationMessages,
    dropzoneLike,
    Datepicker
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    disabledDates () {
      const maxDate = new Date(8640000000000000)
      let minDate = new Date()
      minDate.setDate(minDate.getDate() - 1)
      if (this.migration) {
        minDate = this.minDate
      }
      return {
        days: [0, 6],
        daysOfMonth: [29, 30, 31],
        dates: this.$store.getters.nonProcessingDates,
        to: minDate,
        from: maxDate
      }
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.rightsGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.rightsGroups).map(x => {
          const obj = {}
          obj.groupId = x.groupId
          obj.name = x.name
          obj.colour = x.colour
          return obj
        })
      }
      if (ret.length > 1) {
        ret.unshift({ description: '', groupId: '00000000-0000-0000-0000-000000000000', groupType: 'UK DDMS', name: 'Please Select', paygateId: this.paygateId, isItemActioned: false, clonedName: null, colour: null })
      }
      return ret
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    importEnabled () {
      return this.fileIds.length > 0
    },
    secureUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}import/${this.group}`
    },
    hasPayerPortal () {
      return this.$store.getters.hasLicence('LicenceCollectionsPayerPortal')
    },
    canUpload () {
      return this.groupSelected && this.fileIds.length > 0
    },
    isSystemAdmin () {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations])
    },
    groupSelected () {
      return this.group !== null && this.group !== '00000000-0000-0000-0000-000000000000'
    }
  },
  async mounted () {
    this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
    var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
    if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
      this.selectedGroup = this.customerGroups.find(i => i.groupId === defaultGroupId)
      // Default group might be a FPS group which means it can't be the BACS default group and vice versa.
      if (this.selectedGroup !== undefined) {
        this.group = defaultGroupId
      }
    }
  },
  methods: {
    buildPercentageBarClass (percentDone) {
      var retVal = ''
      switch (percentDone) {
        case 0:
          retVal = 'NoPadding'
          break
        case 100:
          retVal = 'Completed'
          break
        default:
          retVal = 'Uploading'
          break
      }
      return retVal
    },
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
      if (this.$store.getters.customerGroups !== null && this.$store.getters.customerGroups.length > 0) {
        this.group = this.$store.getters.customerGroups[0].groupId
      }
    },
    percentageCompleted (row) {
      var percentage = 0
      if (row.totalRows > 0) {
        percentage = (row.processedRows / row.totalRows) * 100
        if (percentage > 100) {
          percentage = 100
        }
      }
      return Math.ceil(percentage)
    },
    validationCompleted (row) {
      var percentage = 0
      if (row.totalRows > 0) {
        percentage = ((row.validRows + row.invalidRows) / row.totalRows) * 100
        if (percentage > 100) {
          percentage = 100
        }
      }
      return Math.ceil(percentage)
    },
    stopSocketListener () {
      this.uploadStatusConnection.stop()
    },
    load () {
      axios.get(`${process.env.VUE_APP_DDMS_API_URL}import/`, { headers: { 'x-cust-meta': this.paygateId }, params: { ...this.buildGoodTableQuery(), paygateId: this.paygateId }, showload: true }).then((response) => {
        this.uploads = response.data.data
        // state.metaData.tableLinks = response.data.links
        // this.serverParams.totalPages = response.data.meta.totalPages
        this.totalRecords = response.data.count
      })
    },
    importData () {
      // method
      console.log('IMPORT DATA CLICKED')
      console.log(this.createPayerPortalUser)
      axios.post(`${process.env.VUE_APP_DDMS_API_URL}import/process`, {
        fileIds: this.fileIds,
        groupId: this.group,
        paygateId: this.paygateId,
        mandateRequired: this.newPayers !== null ? this.newPayers : false,
        migrationRequred: this.migration !== null ? this.migration : false,
        payerPortalUserRequired: !!this.createPayerPortalUser,
        fileFormat: 'csv',
        importType: 'Payer',
        updateIfDuplicate: this.updateIfDuplicate,
        activationDate: this.activationDate,
        sendNotificationOfChange: this.sendNotificationOfChange,
        oldServiceUserName: this.oldServiceUserName
      }).then((response) => {
        this.$toastr.s('Uploads queued for processing, this may take some time.')
        this.$refs.serverFileUploader.removeAllFiles()
        this.fileIds = []
      }, { params: { paygateid: this.paygateId }, showload: true }).then(() => { this.load() })
      this.$refs.serverFileUploader.removeAllFiles()
    },
    afterSuccess (file, response) {
      this.fileIds.push(response)
    },
    fileAdded (file) {
      this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
    },
    fileRemoved (file, error, xhr) {

    },
    sendingEvent (file, xhr, formData) {
      formData.append('paygateId', this.paygateId)
      formData.append('groupId', this.group)
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onRowClick (e) {
      // todo
    },
    onCellClick (e) {
      if (e.column.label !== '') {
        this.$router.push({ path: '/collections/data/import/' + e.row.uploadedFileId })
      }
    },
    convertStatus (row) {
      var statusInt = row.uploadStatus
      var statusStr = ''
      switch (statusInt) {
        case 0:
          statusStr = 'Queued'
          break
        case 1:
          statusStr = 'Uploading'
          break
        case 2:
          statusStr = 'Complete'
          break
        case 3:
          statusStr = 'Error'
          break
        case 4:
          statusStr = 'Error'
          break
        case 5:
          statusStr = 'Pending'
          break
        case 6:
          statusStr = 'Processing'
          break
        case 7:
          statusStr = 'Preparing'
          break
        case 8:
          statusStr = 'Validating'
          break
        case 9:
          statusStr = 'Duplicate References'
          break
        case 10:
          statusStr = 'Deleted'
          break
        case 11:
          statusStr = 'Finalizing'
          break
        case 12 :
          statusStr = 'Cancelled'
          break
        case 13 :
          statusStr = 'Uploaded'
          break
        case 14 :
          statusStr = 'Processed'
          break
      }
      return statusStr
    },
    getSeverityClass (row) {
      var statusInt = row.uploadStatus
      var buttonClass = ''
      switch (statusInt) {
        case 2: // Staging
        case 13:
        case 14:
          buttonClass = 'success'
          break
        case 4: // Error
        case 12:
          buttonClass = 'danger'
          break
        case 3: // Warning
        case 9:
          buttonClass = 'danger'
          break
        default:
          buttonClass = 'primary'
          break
      }
      return buttonClass
    },
    checkMigration () {
      this.newPayers = false
      if (this.activationDate < this.minDate) {
        this.activationDate = this.minDate
      }
    },
    checkNewMandate () {
      this.migration = false
    },
    deleteRecords () {
      this.serverParams.paygateid = this.paygateId
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}import/deleteinvalid`, { params: this.serverParams, headers: { 'x-cust-meta': this.paygateId }, showload: true }).then((response) => {
        this.uploads = response.data.data
        // state.metaData.tableLinks = response.data.links
        // this.serverParams.totalPages = response.data.meta.totalPages
        this.totalRecords = response.data.meta.totalItems
      })
    },
    clearUpload (id) {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}import/` + id + '/', { headers: { 'x-cust-meta': this.paygateId }, params: { paygateid: this.paygateId }, showload: true }).then((response) => {
        this.$toastr.s('Record deleted, along with all staged data and uploaded files.')
        this.load()
      })
    },
    formatDate (datestr) {
      const date = new Date(datestr)
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var h = date.getHours()
      var m = date.getMinutes()
      var s = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    formatDateTime (datestr) {
      const date = new Date(datestr)
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var h = date.getHours()
      var m = date.getMinutes()
      var s = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year} ${this.pad(h, 2)}:${this.pad(m, 2)}:${this.pad(s, 2)}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatActiviationDate (dateString) {
      return moment(dateString).format('DD/MM/YYYY')
    },
    async customerGroupsChanged () {
      if (this.fileIds.length > 0) {
        this.importEnabled = false
        await axios.put(`${process.env.VUE_APP_DDMS_API_URL}import/updategroups`, { fileIds: this.fileIds, groupId: this.group }, { params: { paygateid: this.paygateId }, showload: true })
        this.$toastr.s('Updated pending upload groups')
        this.importEnabled = true
      }
    },
    async publicFileAdded (addedFile) {
      console.log(addedFile)
      let response = null
      switch (addedFile.type) {
        case 'Secure Cloud Folder':
          response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}import/public/`, { groupId: this.group, path: addedFile.path, fileName: addedFile.fileName }, { showload: true, params: { paygateId: this.paygateId } })
          if (response) {
            addedFile.id = response.data
            this.publicFiles.push(addedFile)
            this.fileIds.push(response.data)
            console.log('file id added')
          }
          break
        case 'Browser Dialog':
          this.fileIds.push(addedFile.uploadResponse)
          this.fileIdResponseMap.push({ id: addedFile.uploadResponse, fileId: addedFile.upload.uuid })
          break
        case 'Restricted File Browser':
          response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}import/lastuploaded/${this.group}/?fileName=${addedFile.fileName}`, { showload: true, params: { paygateId: this.paygateId } })
          this.fileIds.push(response.data)
          break
      }
      console.log('file added')
    },
    async publicFileRemoved (removedFile) {
      let removalIndex = 0
      for (var i = 0; i < this.publicFiles.length; i++) {
        if (removedFile.fileName === this.publicFiles[i].fileName) {
          removalIndex = i
          break
        }
      }
      let idToReplace = ''
      for (var l = 0; l < this.fileIdResponseMap.length; l++) {
        if (removedFile.upload.uuid === this.fileIdResponseMap[l].fileId) {
          idToReplace = this.fileIdResponseMap[l].id
        }
      }
      for (var f = 0; f < this.fileIds.length; f++) {
        if (this.publicFiles[removalIndex] !== undefined && this.publicFiles[removalIndex] !== null && this.fileIds[f] === this.publicFiles[removalIndex].id) {
          this.fileIds.splice(f, 1)
          break
        }
        if (this.fileIds[f] === idToReplace) {
          this.fileIds.splice(f, 1)
          break
        }
      }
      if (this.publicFiles.length > 0) {
        this.publicFiles.splice(removalIndex, 1)
      }
    },
    downloadExample () {
      var csvString =
        this.hideScheduleFields
          ? 'sortcode,accountnumber,accountholdersname,status,statusreason,title,firstname,surname,reference,address1,address2,address3,address4,postcode,email,tel1,tel2,mobile,businessname\r\n123456,12345678,John Smith,0,0,0,John,Smith,ABCD1234AB,1 High Street,,,,AB1 4BC,email.address@domain.com,01234567891,01234567892,07234567891,Smiths Cheeses'
          : 'sortcode,accountnumber,accountholdersname,status,statusreason,title,firstname,surname,reference,address1,address2,address3,address4,postcode,email,tel1,tel2,mobile,businessname,firstamount,lastamount,regularamount,giftaid,firstcollectiondate,ordinal,recurrencetype,recurrenceendtype,untildate,totalcollections,schedulename\r\n123456,12345678,John Smith,0,0,0,John,Smith,ABCD1234AB,1 High Street,,,,AB1 4BC,email.address@domain.com,01234567891,01234567892,07234567891,Smiths Cheeses,123.45,123.45,123.45,FALSE,09/09/2022,1,Fortnightly,Ongoing,01/01/1900,0,Cheese Order'
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'example-customer.csv')
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = 'example-customer.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    previewTemplate () {
      return `<div class="dz-preview dz-file-preview">
                  <div class="dz-image">
                    <img data-dz-thumbnail="">
                  </div>
                  <div class="dz-details">
                    <div class="dz-size">
                      <span data-dz-size=""></span>
                    </div>
                    <div class="dz-filename">
                      <span data-dz-name="">
                      </span>
                    </div>
                  </div>
                  <div class="dz-progress">
                    <span class="dz-upload" data-dz-uploadprogress=""></span>
                  </div>
                  <div class="dz-error-message">
                    <span data-dz-errormessage=""></span>
                  </div>
                  <div class="dz-success-mark">
                  </div>
                  <div class="dz-error-mark"></div>
                  <a class="dropzone-remove-button-letters" data-dz-remove="">X</a>
                </div>`
    }
  },
  async beforeRouteLeave (to, from, next) {
    await this.clearTableFilters()
    this.uploadStatusConnection.stop()
    next()
  },
  validations: {
    group: { required },
    fileFormat: { required },
    dataType: { required }
  },
  watch: {
    selectedCustomer () {
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      if (this.$store.getters.selectedCustomer === process.env.VUE_APP_LANDZ_ID) {
        this.$router.push('/')
      }
      this.load()
    }
  }
}
</script>
<style>
.uploadPercentageContainer {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: 10px;
}
.uploadPercentage {
  position: absolute;
  display: block;
  width: 0%;
  z-index: 0;
  padding: 10px 0px 10px 0px;
}
.uploadPercentageLabel {
  position: relative;
  display: block;
  width: 100%;
  z-index: 1;
  text-align: center;
  /* padding: 10px 39px 10px 38px; */
}
.numericAligner {
  text-align: center;
  padding: 10px 39px 10px 38px;
}
.fileNameAligner {
  padding: 10px 0px 10px 0px;
}
.NoPadding {
  padding: 0;
}
.messageContainer {
  padding: 10px 39px 10px 38px;
  width: 100%;
  display: block;
  text-align: center;
}
.Error,
.DuplicateReferences,
.Deleted {
  background-color: rgb(243, 102, 102);
  color: white;
}
.Warning {
  background-color: rgb(241, 196, 112);
}
.Pending {
  background-color: rgb(241, 196, 112);
}
.Uploading,
.Validating {
  background-color: lightskyblue;
}
.Complete {
  background-color: #86da9a;
}
.Uploading {
  background-color: lightskyblue;
}
.Completed {
  background-color: #86da9a;
}
.right-inner-addon {
    position: relative;
  }
  .right-inner-addon  input {
    padding-left: 8px;
  }
  .right-inner-addon i {
    position: absolute;
    top: 11px;
    right: 15px;
  }
.dropzone-remove-button-letters {
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  color: #ffffff !important;
}
.dropzone-remove-button-letters:hover {
  cursor: pointer !important;
}
</style>
