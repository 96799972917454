<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>BACS Direct Debit Processing Report</h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Earliest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="endPaymentDatePicker"
              v-model="start" :disabledDates="{ from: new Date(end) }"
              :format="formatDate" @input="filtersChanged()" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Latest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="startPaymentDatePicker"
              v-model="end"
              :format="formatDate"  :disabledDates="{ to: new Date(start) }"
              @input="filtersChanged()" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-row-click="onRowClick"
          @on-search="onSearch"
          @on-cell-click="viewReportModal"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'viewReportXml'">
              <span class="grid-link">XML</span>
            </span>
            <span v-else-if="props.column.field === 'viewReportHtml'">
              <span class="grid-link">HTML</span>
            </span>
            <span v-else-if="props.column.field === 'viewReportCsv'">
              <span class="grid-link">CSV</span>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
    <div>
      <viewReportModal
        v-model="isModalVisible"
        :reportData="reportContent"
        :reportFormat="selectedReportFormat"
        @close="closeViewReport"
      ></viewReportModal>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import Datepicker from 'vuejs-datepicker'
  import papa from 'papaparse'
  import moment from 'moment'
  import EventBus from '@/Lib/eventBus'
  import ViewReportModal from '@/Views/Collections/Reconciliation/ViewReportModal.vue'
  import { mapGetters } from 'vuex'
  export default {
    mixins: [tableFilterMixin, loading],
    components: {
      Datepicker,
      viewReportModal: ViewReportModal
    },
    data () {
      return {
        dateOptions: {
          format: 'DD/MM/YYYY HH:mm',
          useCurrent: false,
          stepping: 30,
          sideBySide: true,
        },
        end: null,
        start: null,
        selectedReportFormat: 0,
        reportContent: '',
        isModalVisible: false,
        networkType: 'BacstelIp',
        rows: [],
        columns: [
          {
            label: 'Report Id',
            field: 'reportId'
          },
          {
            label: 'Type',
            field: 'reportName'
          },
          {
            label: 'Report Date',
            field: 'reportDate',
            formatFn: this.formatDateWithNull
          },
          {
            label: 'Processed Date',
            field: 'processingDate',
            formatFn: this.formatDateWithNull
          },
          {
            label: 'Processed?',
            field: this.formatYesNo
          },
          {
            label: 'Failure Reason',
            field: 'failureReason'
          },
          {
            label: 'SUN',
            field: 'sun'
          },
          {
            label: 'Customer',
            field: 'customerName'
          },
          {
            label: '',
            field: 'viewReportXml'
          },
          {
            label: '',
            field: 'viewReportHtml'
          }
        ],
        serverParams: {
          sort: [{ field: 'reportDate', type: 'desc' }]
        },
      }
    },
    mounted () {
      const onPaygateChange = (paygateId) => {
        const paygateIdNotDefault = (paygateId && paygateId !== '00000000-0000-0000-0000-000000000000')
        if (paygateIdNotDefault) {
          this.$store.dispatch('loadCustomerGroups', paygateId)
          this.load()
        }
      }
      EventBus.$on('paygateIdChange', onPaygateChange)
      this.$once('hook:beforeDestroy', () => {
        EventBus.$off('paygateIdChange', onPaygateChange)
      })

      if(this.$store.getters.collectionsBacsReportEndDate === null || this.$store.getters.collectionsBacsReportEndDate === undefined) {
        this.end = new Date()
        let tempStart = new Date()
        tempStart.setDate(this.end.getDate() - 1)
        tempStart.setHours(0,0,0,0)
        let tempEnd = new Date(this.end.setMonth(this.end.getMonth()+1));
        tempEnd.setHours(23,59,59,0)
        this.end = tempEnd
        this.$store.dispatch('setBacsReportEndDate', this.end)
      }
      else {
        this.end = this.$store.getters.collectionsBacsReportEndDate
      }

      if(this.$store.getters.collectionsBacsReportStartDate === null || this.$store.getters.collectionsBacsReportStartDate === undefined) {
        let tempStart = new Date()
        tempStart.setDate(this.end.getDate() - 1)
        tempStart.setHours(0,0,0,0)
        this.start = tempStart
        this.$store.dispatch('setBacsReportStartDate', this.start)
      }
      else {
        this.start = this.$store.getters.collectionsBacsReportStartDate
      }

      var savedParams = this.$store.getters.tableFilters(this.$route.path)

      if (savedParams) {
        // If there are, apply them!
        this.savedParamsToObjects(savedParams)
        this.serverParams = Object.assign({}, this.serverParams, savedParams)
      }
      //this.load()
    },
    methods: {
      async filtersChanged () {
        await this.$store.dispatch('setBacsReportStartDate', this.start)
        await this.$store.dispatch('setBacsReportEndDate', this.end)
        await this.clearTableFilters()
      },
      resultToText (val) {
        let retVal = ''
        switch (val) {
          case 0:
            retVal = 'Success'
            break
          case 1:
            retVal = 'Failure'
            break
        }
        return retVal
      },
      formatYesNo (val) {
        return val ? 'Yes' : 'No'
      },
      load: async function () {
        try {
          if (this.end === null) {
            this.end = new Date()
            let tempStart = new Date()
            tempStart.setDate(this.end.getDate() - 1)
            tempStart.setHours(0,0,0,0)
            let tempEnd = new Date(this.end.setMonth(this.end.getMonth()+1));
            tempEnd.setHours(23,59,59,0)
            this.end = tempEnd
          }
          if (this.start === null) {
            let tempStart = new Date()
            tempStart.setDate(this.end.getDate() - 1)
            tempStart.setHours(0,0,0,0)
            this.start = tempStart
          }
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/bacsprocessingreport`, { params: { ...this.buildGoodTableQuery(), startDate: this.start, endDate: this.end }, validateStatus: () => true })
          if (response.status === 200) {
            this.rows = response.data.data
            this.totalRecords = response.data.count
          } else {
            if (response.status === 403) {
              this.$toastr.e('Not authorized to use this feature.')
              this.rows = []
              this.totalRecords = 0
            } else {
              this.$toastr.e('An error has occurred.')
            }
          }
        } catch (error) {
          this.$toastr.e(error)
        }
      },
      async getXml (reportId, row) {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/bacsprocessingreport/${reportId}/xml`, { params: {
           paygateId: this.paygateId }, showload: true })
        var blob = new Blob([response.data])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, `${row.reportName}.xml`)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = `${row.reportName}.xml`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      async getHtml (reportId) {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/bacsprocessingreport/${reportId}/html`, { params: {
           paygateId: this.paygateId }, showload: true })
        this.reportContent = response.data
        this.isModalVisible = true
      },
      async viewReport (row) {

      },
      async onRowChange (e) {
        this.$router.push({ path: `/collections/reports/bacsprocessing/`+e.row.reportId, params: {} })
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
      formatStatus (value) {
        let retVal = ''
        switch(value) {
          case 1:
            retVal = 'Created'
            break
          case 2:
            retVal = 'In Progress'
            break
          case 3:
            retVal = 'Resolved'
            break
          case 4:
            retVal = 'Rejected'
            break
          case 5:
            retVal = 'Action Required'
            break
          default:
            retVal = 'None'
            break
        }
        return retVal
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      formatDateWithNull (date) {
        if (date===null || date === '0001-01-01T00:00:00') {
          return 'N/A'
        } else {
          return this.formatDate(date)
        }
      },
      async exportTable () {
        var query = this.buildGoodTableQuery()
        var startDate = moment(this.start).format('DD-MM-YYYY')
        var endDate = moment(this.end).format('DD-MM-YYYY')
        query.perPage = this.totalRecords
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/bacsprocessingreport`, { params: {
          startDate: this.start, endDate: this.end,
          ...query, paygateId: this.paygateId, forExport: true }, showload: true })
        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, `bacs-processing-report-${startDate}-to-${endDate}.csv`)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = `bacs-processing-report-${startDate}-to-${endDate}.csv`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      onRowClick (event) {
        // forces the table to do pointer cursor on row etc
      },
      codeToDescription (value) {
        let retval = ''
        switch (value) {
          case 1:
            retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
            break;
          case 2:
            retval = 'NO ADVANCE NOTICE WAS RECEIVED';
            break;
          case 3:
            retval = 'BANK CANCELS THE DIRECT DEBIT';
            break;
          case 4:
            retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
            break;
          case 5:
            retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
            break;
          case 6:
            retval = 'FRAUDULENT SIGNATURE';
            break;
          case 7:
            retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
            break;
          case 8:
            retval = 'SERVICE USER IS NOT RECOGNISED';
            break;
          default:
            retval = 'Invalid Code Supplied'
            break;
        }
        return retval
      },
      recurrenceEndTypeText (val) {
        var outText = ''
        switch (val) {
          case 0:
            outText = 'One Off'
            break
          case 1:
            outText = 'Until Date'
            break
          case 2:
            outText = 'Until Number of Collections'
            break;
          case 3:
            outText = 'Ongoing'
            break
          case 4:
            outText = 'Ad Hoc'
            break
        }
        return outText
      },
      planStatusText (val) {
        var outText = ''
        switch (val) {
          case 0:
            outText = 'Closed'
            break
          case 1:
            outText = 'Alert'
            break
          case 2:
            outText = 'Suspended'
            break
          case 3:
            outText = 'Operational'
            break
        }
        return outText
      },
      recurrenceTypeText (val) {
        var outText = ''
        switch (val) {
          case 0:
            outText = 'Weekly'
            break
          case 1:
            outText = 'Monthly'
            break
          case 2:
            outText = 'Yearly'
            break
        }
        return outText
      },
      async viewReportModal (params) {
        console.log('params: ' + JSON.stringify(params))
        var cellClicked = params.column.field
        console.log('cellClicked: ' + cellClicked)

        if (cellClicked === 'viewReportHtml') {
          await this.getHtml(params.row.reportId)
        } else if(cellClicked === 'viewReportXml') {
          await this.getXml(params.row.reportId, params.row)
        } else {
          this.$router.push({ path: `/collections/reports/bacsprocessing/`+params.row.reportId, params: {} })
        }
      },
      closeViewReport () {
        console.log('closeViewReport')
        this.isModalVisible = false
      },
    },
    computed: {
      ...mapGetters(['selectedCustomer']),
      startDateOptions () {
        return { ...this.dateOptions, maxDate: this.end }
      },
      endDateOptions () {
        return { ...this.dateOptions, minDate: this.start }
      },
    },
    watch: {
      selectedCustomer () {
        this.load()
      }
    }
  }
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
