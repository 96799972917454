<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Bank Accounts
          <help-icon docPath="/administration/bankaccounts/" />
          <favourite-icon />
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <div slot="table-actions">
            <table-actions
              :print-enabled="false"
              :export-enabled="false"
              :create-enabled="true"
              :create="create"
              :clear-table-filters="clearTableFilters"
              create-tooltip="Create a new bank account"
              :reload-table="load"
            ></table-actions>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer" v-if="!readOnly">
        <button
          class="btn btn-primary"
          @click="create"
          :disabled="disabledDueToConnectedMerchant"
          type="button"
        >
          <i class="fa fa-plus rpad"></i>Create Bank Account
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'

// Mixins
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import ConnectedMerchantReadOnlyMixin from '@/Assets/Mixins/ConnectedMerchantReadOnlyMixin'

// Constants
import roles from '@/Assets/Constants/roles'
import accountTypes from '@/Assets/Constants/bankAccountTypes'

export default {
  mixins: [tableFilterMixin, ConnectedMerchantReadOnlyMixin],
  computed: {
    readOnly () {
      return !this.$store.getters.isInRole(roles.Finance)
    },
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Reference',
          field: 'reference',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Bank Name',
          field: 'bankName',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Sortcode',
          field: 'sortCode',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account Number',
          field: 'accountNumber',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account Type',
          field: 'accountType',
          filterOptions: {
            enabled: true,
            placeholder: 'All Types',
            filterDropdownItems: Object.values(accountTypes)
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'reference', type: 'asc' }]
      }
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  methods: {
    onRowClick (params) {
      var bankAccountId = params.row.bankAccountId
      this.$router.push({ name: 'BankAccountEdit', params: { id: bankAccountId } })
    },
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}bankAccounts`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading bank account'
          })
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    create () {
      this.$router.push({ name: 'BankAccountCreate' })
    }
  }
}

</script>
