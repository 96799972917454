<template>
  <div>
    <h3>Audit log Message<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/messaging/smsmessage/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <div class="textarea">
      <div class="form-group">
        <label class="required">Message</label>
        <textarea class="form-control"
          rows="4"
          cols="40"
          :class="{invalid: $v.selectedNode.props.s1.value.$error}"
          @input="$v.selectedNode.props.s1.value.$touch()"
          v-model="selectedNode.props.s1.value">
        </textarea>
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty ">The message field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.maxLength">Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
      </div>
    </div>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger" class="btnPad" @click.prevent="btnCancel">
        Cancel
      </b-button>
      <b-button  class="" variant="success" @click.prevent="btnSave">
        OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength } from 'vuelidate/lib/validators'
export default {
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(4096)
          }
        }
      }
    }
  },
  data () {
    return {
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
