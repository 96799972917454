<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline Account Balances<span class="pull-right"></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-1 ">
              Filename
            </div>
            <div class="col-3">
              {{fileData.filename}}
            </div>
            <div class="col-1 offset-1">
              Upload Date
            </div>
            <div class="col-3">
              {{fileData.displayImportDate}}
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-1 ">
              Origin
            </div>
            <div class="col-3">
              <b-badge pill variant="success">Uploaded from Bankline</b-badge>
            </div>
            <div class="col-1 offset-1">
              Status
            </div>
            <div class="col-2">
              <b-badge pill v-if = "fileData.fileStatus === 'Unprocessed'" variant="secondary">{{fileData.fileStatus}}</b-badge>
              <b-badge pill v-else-if = "fileData.status == '2'" variant="info">Processing</b-badge>
              <b-badge pill v-else-if = "fileData.fileStatus == 'Processed'" variant="success">Processed</b-badge>
              <b-badge pill v-else-if = "fileData.status == '4'" variant="warning">Unmatched</b-badge>
              <b-badge pill v-else-if = "fileData.status == '98'" variant="warning">Archived</b-badge>
              <b-badge pill v-else-if = "fileData.status == '99'" variant="danger">Error</b-badge>
            </div>
            <div v-if="this.fileHasBeenProcessed" class="col-2">
              Processed Date
            </div>
            <div v-if="this.fileHasBeenProcessed" class="col-2">
              {{fileData.displayProcessedDate}}
            </div>
          </div>
        </div>
      </div>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>File Contents</h2>
        </div>
        <div class="idb-block-content">
          <div>
            <VuePerfectScrollbar class="scroll-area" :settings="settings">
              <vue-good-table
                :isLoading.sync="isTableLoading"
                mode="remote"
                ref="table"
                :columns="columns"
                :rows="rows"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :totalRows="totalRecords"
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                styleClass="vgt-table striped bordered table-hover"
                :lineNumbers="false">
                <div slot="table-actions">
                  <table-actions
                    :data-url="dataUrl"
                    :export-data="tableServerData"
                    :print-enabled="false"
                    :table-amount="totalRecords"
                    :export-name="exportFilename"
                    :reload-table="load"
                    :table-columns="columns"
                    :clear-table-filters="clearTableFilters"
                  >
                  </table-actions>
                </div>
                </vue-good-table>
            </VuePerfectScrollbar>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div class="btn-group mr-2" role="group" v-if="fileData.fileStatus === 'Unprocessed'">
                <button id="btnGroupDrop1" @click="saveBankLedgerBalances" type="button" class="btn btn-primary">
                  <i class="fa fa-play mr-2"></i>Save Bank Ledger Balances
                </button>
              </div>
              <div class="btn-group mr-2" role="group">
                <button id="btnGroupDrop1" @click="btnUploadBanklineFile" type="button" class="btn btn-primary">
                  <i class="fa fa-upload mr-2"></i>Upload Bankline File
                </button>
              </div>
              <div class="btn-group mr-2" role="group">
                <button id="btnGroupDrop1" @click="btnViewAllBalances" type="button" class="btn btn-outline-primary">
                  <i class="fa fa-list mr-2"></i>View Bank Balances
                </button>
              </div>
              <div v-if="fileData.fileStatus !== 'Processed'" class="btn-group pull-right">
                <button id="btnDeleteFile" @click="btnDeleteFile" type="button" class="btn btn-danger">
                  <i class="fa fa-trash mr-2"></i>Delete File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
export default {
  mixins: [tableFilterMixin, loading],
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      settings: {
      },
      fileData: {},
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      rows: [],
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'Sort Code',
          field: 'sortCode',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account Number',
          field: 'accountNumber',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account Short Name',
          field: 'accountShortName',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'TX Date',
          field: 'transactionDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Last Night\'s Ledger',
          field: 'lastNightLedgerBalance',
          type: 'decimal',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Today\'s Ledger',
          field: 'todayLedgerBalance',
          type: 'decimal',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Last Night\'s Cleared',
          field: 'lastNightClearedBalance',
          type: 'decimal',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Today\'s Cleared',
          field: 'todayClearedBalance',
          type: 'decimal',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Start of Day Ledger',
          field: 'startDayLedgerBalance',
          type: 'decimal',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Start of Day Cleared',
          field: 'startDayClearedBalance',
          type: 'decimal',
          filterOptions: {
            enabled: false
          }
        }
      ],
      totalRecords: 0,
      fileDeleted: false
    }
  },
  computed: {
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}reconciliation/banklineAccountBalances?fileId=${this.id}`
    },
    tableServerData () {
      return this.buildGoodTableQuery()
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    },
    exportFilename () {
      let filename = ''
      if (this.fileData.filename !== undefined && this.fileData.filename !== null) {
        const filenameLength = this.fileData.filename.length
        // Don't want to add the file extension to export filename otherwise exported filename will end with ".csv.csv"!
        filename = filenameLength > 4 ? this.fileData.filename.substring(0, filenameLength - 4) : this.fileData.filename
      }
      return 'BankLineBalances ' + filename
    },
    fileHasBeenProcessed () {
      return this.fileData.fileStatus === 'Processed' || this.fileData.fileStatus === 'Processed With Warnings' 
    }
  },
  methods: {
    async loadFileDetails () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}reconciliation/banklineFile?fileId=${this.id}`, { showload: true })
        this.fileData = response.data
        var importFileResponse = this.$store.getters.importFileResponse
        if (importFileResponse !== undefined && importFileResponse !== null && importFileResponse.errorMessage !== undefined && importFileResponse.errorMessage.length > 0) {
          this.$snapbar.w(importFileResponse.errorMessage)
        }
        this.$store.dispatch('setImportFileResponse', null)
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
      }
    },

    async load () {
      try {
        this.isTableLoading = true
        const response = await axios.get(this.dataUrl,
          {
            params: { ...this.tableServerData },
            showerror: true,
            errormessage: 'Bankline balance search failed'
          })

        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
        this.rows = []
        this.totalRecords = 0
      } finally {
        this.isTableLoading = false
      }
    },
    getStatusVariant (fileStatus) {
      var variant = ''
      switch (fileStatus) {
        case 'Unprocessed':
          variant = 'secondary'
          break
        case 'Processed':
          variant = 'success'
          break
      }
      return variant
    },
    async btnViewAllBalances () {
      this.$router.push('/admin/bandr/bankledgerbalances')
    },
    async btnUploadBanklineFile () {
      this.$router.push('/admin/bandr/bankline/uploadfile')
    },
    async btnDeleteFile () {
      const result = await swal.fire({
        title: 'Are you sure you want to permanently delete this Bankline file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}reconciliation/deleteBanklineFile/${this.id}`, { showload: true })
          if (res) {
            this.fileDeleted = true
            this.$toastr.s('The Bankline file was successfully deleted.')
            this.$router.push('/admin/bandr/banklinefilelist')
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this file.')
          } else {
            this.$snapbar.e(`Could not delete this file - ${e.message}`)
          }
        }
      }
    },
    
    async saveBankLedgerBalances () {
      try {
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}ledger/saveBankLedgerBalances?fileId=${this.fileData.fileId}`, { showload: true })
        if (response.data.success) {
          console.log('response: ' + JSON.stringify(response.data))
          if (response.data.errorMessage === undefined || response.data.errorMessage.length == 0) {
            this.$toastr.s('Bank account balances have been updated successfully.')
          } else {
            this.$snapbar.w(response.data.errorMessage)
          }

          await this.loadFileDetails()
          await this.load()
        } else {
          this.$snapbar.e(response.data.errorMessage)
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to save bank account balances.')
        } else {
          this.$snapbar.e(`Could not save bank account balances - ${e.message}`)
        }
      }
    }
  },

  async beforeRouteLeave (to, from, next) {
    if (this.fileDeleted) {
      next()
    }
    else if (this.fileData.fileStatus !== undefined && this.fileData.fileStatus !== null && this.fileData.fileStatus === 'Unprocessed') {
      var result = await swal.fire({
        title: 'Navigation',
        text: 'This Bankline balance file has not been processed yet. Do you want to navigate away from this page?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        next()
      }
    } else {
      next()
    }
  },
  async mounted () {
    await this.loadFileDetails()
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.load()
    }
  }
}
</script>
<style scoped>
  td {
    cursor: pointer;
  }
</style>
