<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          <help-icon docPath="/paygate-collections/portal/" />Portal Messages
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div>
        <div class="m-3">
          <b-form-row>
            <b-form-group :label-cols="2" class="col-sm-4" horizontal label="Group">
              <group-select
                id="groupSelect"
                v-model="groupId"
                :groups="customerGroups"
                @input="customerGroupsChanged"
              ></group-select>
            </b-form-group>
          </b-form-row>
        </div>
      </div>
      <div class="idb-block-footer"></div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          <span class="pull-right">
            <p-check
              id="giftAidCheck"
              class="p-switch p-fill"
              color="primary"
              v-model="unreadOnly"
              @change="unreadOnly != unreadOnly"
            >Show only unread</p-check>
          </span>Messages
        </h2>
      </div>
      <div>
        <div class="container-fluid">
          <inbox :messages="messages" @input="getPayerMessages" :unreadOnly="unreadOnly" />
        </div>
      </div>
      <div class="idb-block-footer"></div>
    </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import _ from 'lodash'
import axios from 'axios'
import Components from '@/Components/Collections'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  components: {
    inbox: Components.Messaging.Inbox
  },
  data () {
    return {
      messages: [],
      groupId: '00000000-0000-0000-0000-000000000000',
      unreadOnly: false,
      portalMessagingConnection: null
    }
  },
  computed: {
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups)
      }
      ret.unshift({
        description: '', groupId: '00000000-0000-0000-0000-000000000000', groupType: 'UK DDMS', name: 'All ', paygateId: this.paygateid, isItemActioned: false, clonedName: null, colour: null
      })
      return ret
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    }
  },
  methods: {
    async customerGroupsChanged () {
      var groupUrl = `${process.env.VUE_APP_DDMS_API_URL}portalmessaging`
      if (this.groupId !== '00000000-0000-0000-0000-000000000000') {
        groupUrl += `/group/${this.groupId}`
      }
      const response = await axios.get(groupUrl, { showload: true, params: { paygateId: this.paygateId } })
      if (response.data) {
        this.messages = response.data
        this.currentMessageView = this.messages
      } else {
        this.$toastr.e('Could not retrieve messages.')
      }
    },
    loadGroups (paygateId) {
      this.$store.dispatch('loadCustomerGroups', this.paygateId)
    },
    stopSocketListener () {
      this.portalMessagingConnection.stop()
    }
  },
  async mounted () {
    var hubUrl = process.env.VUE_APP_DDMS_API_URL + 'hubs/messaging?paygateid=' + this.paygateId
    this.loadGroups(this.paygateId)
    await this.customerGroupsChanged()
    window.addEventListener('beforeunload', this.stopSocketListener)
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        // this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
        this.portalMessagingConnection = new HubConnectionBuilder().withUrl(hubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.portalMessagingConnection.start()
        this.portalMessagingConnection.on('messagesupdated', data => {
          this.customerGroupsChanged()
        })
      })
  }
}
</script>
