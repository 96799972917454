<template>
  <div>
    <form @submit.prevent="checkValidation() && save()" novalidate>
      <div class="row" v-if="selectedCustomer == lAndZId && !billing.inChargeSetGroup">
        <div class="col-md-12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>Default Charge Configuration</h2>
            </div>
            <div class="idb-block-content">
              <div
                class="alert alert-info"
              >This will set the default for new Clients, it will only affect Clients created after this has changed</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="isParentConnectedMerchant">
        <div class="col-md-12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>Charge Configuration</h2>
            </div>
            <div class="idb-block-content">
              <div
                class="alert alert-info"
              >This will set the charge configuration for all Clients under this connected merchant</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="billing.inChargeSetGroup">
        <div class="col-md-12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>Charge Configuration</h2>
            </div>
            <div class="idb-block-content">
              <div class="alert alert-info">
                This Client's Charge Configuration is being managed in a charge configuration group, please click
                <router-link
                  :to="{ name: 'ChargeConfigurationGroupEdit', params: { id: this.billing.chargeSetGroupId } }"
                >here</router-link>&nbsp;to view it
              </div>
            </div>
          </div>
        </div>
      </div>

      <charge-configuration-controls
        :read-only="billing.inChargeSetGroup"
        :billing="$v.billing"
        :errors="errors"
      ></charge-configuration-controls>

      <!-- Buttons -->
      <div class="row" v-if="!billing.inChargeSetGroup">
        <div class="col-md-12">
          <div class="idb-block">
            <div class="idb-block-footer">
              <button class="btn btn-primary" type="submit" :disabled="isLoading">Save</button>
              <button
                class="btn btn-primary ml-3"
                type="button"
                @click="saveForAllChildren"
                v-if="hasChildren && selectedCustomerObject.type !== customerTypes.connectedMerchant"
                :disabled="isLoading"
              >Save and Apply to Children</button>
              <button
                class="btn btn-outline-danger pull-right ml-3"
                type="button"
                @click="back"
              >Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Third Party
import _ from 'lodash'
import axios from 'axios'
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'

// Mixins
import loading from '@/Assets/Mixins/LoadingMixin'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'

// Components
import ChargeConfigurationControls from '@/Components/Platform/Customer/Charges/Configuration/ChargeConfigurationControls'

// Constants
import colours from '@/Assets/Constants/colours'
import customerTypes from '@/Assets/Constants/customerTypes'

// Validations
import { requiredIf, required, helpers, minValue, integer } from 'vuelidate/lib/validators'

export default {
  name: 'BillingManagement',
  mixins: [loading, DataLeaveMixin],
  computed: {
    lAndZId () {
      return process.env.VUE_APP_LANDZ_ID
    },
    customerTypes () {
      return customerTypes
    },

    ...mapGetters(['selectedCustomer', 'hasChildren', 'selectedCustomerObject', 'isParentConnectedMerchant'])
  },
  components: {
    ChargeConfigurationControls
  },
  data () {
    return {
      errors: [],

      billing: {
        // Periodic
        adminCharge: { value: 0 },
        bacsFileSubmissionCharge: { value: 0 },
        bacsFileSubmissionIncludedFiles: { value: 2 },
        osuCharge: { value: 0 },

        // Processing
        advanceNoticeLetter: { value: 0 },
        advanceNoticeEmail: { value: 0 },
        preCollectionNotification: { value: 0 },
        collections: {
          minimum: 0,
          maximum: 1,
          ranges: [{ amount: 0, rate: 0 }],
          type: 'SteppedRate'
        },
        confirmationLetter: { value: 0 },
        confirmationEmail: { value: 0 },
        failedCollections: { value: 0 },
        indemnityClaims: { value: 0 },
        payerCancellation: { value: 0 },
        payerReinstate: { value: 0 },
        payerSetup: { value: 0 },
        validateBankAccount: { value: 0 },
        validateIBAN: { value: 0 },
        banklineSTD1Or2: { value: 0 },
        banklineSTD0: { value: 0 },
        banklineUrgent: { value: 0 },
        ignoreProcessingChargeOnFailure: { value: true },

        // Other
        inChargeSetGroup: false,
        chargeSetGroupId: null
      }
    }
  },
  methods: {

    async load () {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}Charge/Current/${this.selectedCustomer}`,
          {
            showload: true,
            showerror: true,
            errormessage: 'Billing failed to load'
          }
        )
        this.billing = response.data
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      } finally {
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    },
    async save () {
      this.errors = []
      try {
        await axios.post(
          `${process.env.VUE_APP_PLATFORM_API_URL}Charge/${this.selectedCustomer}`, this.billing,
          { showload: true, showerror: true, errormessage: 'Billing failed to save' })
        this.$v.$reset()
        this.$toastr.s(
          'Billing Saved',
          'Saved'
        )
        this.load()
      } catch (e) {
        if (e.response && e.response.status === 422) {
          this.errors = e.response.data
          this.$toastr.e('There are errors on the page, please see the top for more information', 'Validation Error')
        }
      }
    },
    async saveForAllChildren () {
      this.errors = []
      try {
        // Get confirmation from the user that they really want to apply to all child customers
        var swalResult = await swal.fire({
          title: 'Save and Apply to Children',
          text: 'Are you sure you want to save and apply this to all child Clients? This will overwrite any custom changes any children have',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.warning,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch (error) {
        // Do nothing as this will be if they clicked cancel
        return
      }

      if (!swalResult.isConfirmed) {
        return
      }

      try {
        await axios.post(
          `${process.env.VUE_APP_PLATFORM_API_URL}Charge/${this.selectedCustomer}/Children`, this.billing,
          { showload: true, showerror: true, errormessage: 'Billing failed to save' })
        this.$v.$reset()
        this.$toastr.s(
          'Billing Saved',
          'Saved'
        )
        this.load()
      } catch (e) {
        if (e.response && e.response.status === 422) {
          this.errors = e.response.data
          this.$toastr.e('There are errors on the page, please see the top for more information', 'Validation Error')
        }
      }
    }
  },
  async mounted () {
    await this.load()
  },
  watch: {
    selectedCustomerObject () { this.load() }
  },
  validations () {
    return {
      billing: {
        // Periodic
        adminCharge: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        bacsFileSubmissionCharge: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        bacsFileSubmissionIncludedFiles: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        osuCharge: {
          value: {
            required,
            minValue: minValue(0)
          }
        },

        // Processing
        advanceNoticeLetter: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        advanceNoticeEmail: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        preCollectionNotification: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        collections: {
          minimum: {
            required: requiredIf((model) => {
              return model.type !== 'SteppedFixFee'
            }),
            minValue: helpers.withParams({ type: 'minValue', min: 0 }, (value, model) => {
              return model.type !== 'SteppedFixFee' ? value >= 0 : true
            }),
            maxValue: helpers.withParams({ type: 'maxValue', max: this.billing.collections.maximum }, (value, model) => {
              return model.type !== 'SteppedFixFee' ? value <= this.billing.collections.maximum : true
            })
          },
          maximum: {
            required: requiredIf((model) => {
              return model.type !== 'SteppedFixFee'
            }),
            minValue: helpers.withParams({ type: 'minValue', min: this.billing.collections.minimum }, (value, model) => {
              return model.type !== 'SteppedFixFee' ? value >= this.billing.collections.minimum : true
            })
          },
          ranges: {
            $each: {
              amount: {
                required,
                minValue: minValue(0),
                integer,
                valid: (value) => {
                  var count = _.sumBy(this.billing.collections.ranges,
                    v => +(v.amount === value))
                  return count === 1
                }
              },
              rate: {
                required,
                minValue: minValue(0),
                maxValue: helpers.withParams({ type: 'maxValue', max: 100 }, (value) => {
                  return (this.billing.collections.type !== 'SteppedFixFee' && this.billing.collections.type !== 'FixedFee') ? value <= 100 : true
                })
              }
            }
          },
          type: {}
        },
        confirmationLetter: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        confirmationEmail: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        failedCollections: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        indemnityClaims: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        payerCancellation: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        payerReinstate: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        payerSetup: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        validateBankAccount: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        validateIBAN: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        banklineSTD1Or2: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        banklineSTD0: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        banklineUrgent: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
        ignoreProcessingChargeOnFailure: {
          value: {}
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
