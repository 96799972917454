
<template>
  <div>
    <div class="row py-10" v-if="type == 'Bank Account'">
      <div class="col-sm-3">
        <span
          class="square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
        >{{hit.data.reference|substring(0,2)}}</span>
      </div>
      <div class="col-sm-9">
        <h6>
          <a class="search-title">{{hit.data.reference}}</a>
        </h6>
        <p class="mb-0 font-sm search-description">{{hit.data.description}}</p>
      </div>
    </div>
    <div class="row py-10" v-else-if="type == 'User'">
      <div class="col-sm-3">
        <span
          class="square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
        >{{hit.data.name|substring(0,2)}}</span>
      </div>
      <div class="col-sm-9">
        <h6>
          <a class="search-title">{{hit.data.name}}</a>
        </h6>
        <p class="mb-0 font-sm search-description">{{hit.data.email}}</p>
      </div>
    </div>
    <div class="row py-10" v-else-if="type == 'Mappings'">
      <div class="col-sm-3">
        <span
          class="square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
        >{{hit.data.title|substring(0,2)}}</span>
      </div>
      <div class="col-sm-9">
        <h6>
          <a class="search-title">{{hit.data.title}}</a>
        </h6>
        <p class="mb-0 font-sm search-description">{{hit.data.email}}</p>
      </div>
    </div>

    <div class="row py-10" v-else-if="type == 'Workflows'">
      <div class="col-sm-3">
        <span
          class="square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
        >{{hit.data.title|substring(0,2)}}</span>
      </div>
      <div class="col-sm-9">
        <h6>
          <a class="search-title">{{hit.data.title}}</a>
        </h6>
        <p class="mb-0 font-sm search-description">{{hit.data.email}}</p>
      </div>
    </div>
    <div class="row py-10" v-else-if="type == 'Triggers'">
      <div class="col-sm-3">
        <span
          class="square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
        >{{hit.data.title|substring(0,2)}}</span>
      </div>
      <div class="col-sm-9">
        <h6>
          <a class="search-title">{{hit.data.title}}</a>
        </h6>
        <p class="mb-0 font-sm search-description">{{hit.data.triggerType}}</p>
      </div>
    </div>
    <div class="row py-10" v-else-if="type == 'Importers'">
      <div class="col-sm-3">
        <span
          class="square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
        >{{hit.data.title|substring(0,2)}}</span>
      </div>
      <div class="col-sm-9">
        <h6>
          <a class="search-title">{{hit.data.title}}</a>
        </h6>
        <p class="mb-0 font-sm search-description">{{hit.data.importerType}}</p>
      </div>
    </div>
    <div class="row py-10" v-else-if="type == 'Payments'">
      <div class="col-sm-3">
        <span
          class="square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
        >{{hit.data.userReference|substring(0,2)}}</span>
      </div>
      <div class="col-sm-9">
        <h6>
          <a class="search-title">{{hit.data.userReference}}</a>
        </h6>
        <p
          class="mb-0 font-sm search-description"
        >{{hit.data.paymentEngineType}} - {{hit.data.thirdPartyAccountName}} - {{hit.data.thirdPartySortCode}} - {{hit.data.thirdPartyAccountNumber}}</p>
      </div>
    </div>
    <div class="row py-10" v-else-if="type == 'Customer'">
      <div class="col-sm-3">
        <span
          class="square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
        >{{hit.data.referene|substring(0,2)}}</span>
      </div>
      <div class="col-sm-9">
        <h6>
          <a class="search-title">{{hit.data.reference}}</a>
        </h6>
        <p class="mb-0 font-sm search-description">{{hit.data.firstName}} - {{hit.data.surname}}</p>
      </div>
    </div>
    <div class="row py-10" v-else>
      <div class="col-sm-3">
        <span
          class="square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
        >{{hit.data.name|substring(0,2)}}</span>
      </div>
      <div class="col-sm-9">
        <h6>
          <a class="search-title">{{hit.data.name}}</a>
        </h6>
        <p class="mb-0 font-sm search-description">{{hit.data.description}}</p>
      </div>
    </div>
  </div>
</template>

<script>
// import searchRouter from '@/Assets/Components/Search/searchRouter'

export default {
  name: 'SearchTypes',
  props: {
    hit: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.search-description {
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: normal;
  max-height: 43px;
  display: inline-block;
  overflow: hidden;
}

.search-title {
  white-space: normal;
}
</style>
