<template>

  <div style="height:100%">

    <div class="d-flex justify-content-between mb-20">
      <h5 class="m-0">Pending Bureau Jobs</h5>
    </div>

    <hr>

    <VuePerfectScrollbar class="scroll-area"
                         :settings="settings">

      <div class="col-md-12">
        <b-row v-if="rows.length > 0">
          <b-col cols="4"><strong>Reference</strong></b-col>
          <b-col cols="4"><strong>Processing Date</strong></b-col>
          <b-col cols="2"><strong>Files</strong></b-col>
          <b-col cols="2"><strong>Status</strong></b-col>
        </b-row>
        <b-row v-for="row in rows" v-bind:key="row.bureauSubmissionId">
            <b-col cols="4"><a href="" @click.prevent="onViewPaymentsClick(row)">{{ row.submissionReference }}</a></b-col>
            <b-col cols="4">{{ row.processingDate | formatDate("Do MMMM YYYY") }}</b-col>
            <b-col cols="2">{{ row.numberOfFiles }}</b-col>
            <b-col cols="2">{{ row.status }}</b-col>
        </b-row>
      </div>

      <div class="col-md-12">
        <span v-if="rows.length == 0">
          You do not currently have any pending bureau jobs
        </span>
      </div>

    </VuePerfectScrollbar>

  </div>

</template>

<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { setInterval } from 'timers'

export default {
  name: 'BureauPendingJobsWidget',
  components: {
    VuePerfectScrollbar
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  computed: {
    paygateId () { return this.$store.state.common.paygateId }
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60
      },
      intervalBit: null,
      rows: []
    }
  },
  async created () {
    this.load()
    this.intervalBit = setInterval(this.load, 30000)
  },
  beforeRouteLeave () {
    clearInterval(this.intervalBit)
  },
  methods: {
    async load () {
      try {
        let response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getPendingSubmissions')
        this.rows = response.data
      } catch (error) {
        console.error(error)
      }
    },
    onViewPaymentsClick (submission) {
      this.$store.dispatch('setSubmission', submission)
      this.$router.push({ name: 'PaymentDetails' })
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-area {
  height: calc(100% - 25px);
  min-height: unset;
}

.mouseHovering {
  color: yellow
}
</style>
