<template>
  <div>
    <h3>Sign Submission<span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <!-- <div class="form-group">
      <label>Group</label>
      <br>
      <group-select v-model="selectedNode.props.s1.value" :groups="options" :class="{invalid: $v.selectedNode.props.s1.value.$error}"></group-select>
    <p class="validationText" v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty ">A valid PayGate group must be selected!</p>
    </div> -->
    <br/>
    <br/>
    <!-- <div class="form-group"> -->
      <!-- <p-check name="check" class="p-switch" color="primary" v-model="selectedNode.props.b1.value">Overwrite Existing File</p-check> -->
    <!-- </div> -->
    <div class="spacer150"></div>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
export default {
  validations: {
    selectedNode: {
      props: {
        // s1: {
        //   value: {
        //     required
        //   }
        // }
      }
    }
  },
  data () {
    return {
      // options: [],
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    }
  },
  created: async function () {
    // this.paygateId = this.$store.getters.getClaim('paygate_id').value
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }

  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
</style>
