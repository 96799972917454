<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>{{ ComputedEmailTitle }}</h2>
    </div>

    <div class="idb-block-content">
      <div class="row form-group">
        <div class="col-lg-4">
          <div class="col-sm-10">
            <b>To List:</b>
          </div>
          <div class="col-sm-10" v-for="ToAddress in AuditTrailEmail.toList" v-bind:key="ToAddress">
            <div class="col-sm-10">{{ToAddress}}:</div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="col-sm-10">
            <b>Cc List:</b>
          </div>
          <div class="col-sm-10" v-for="CcAddress in AuditTrailEmail.ccList" v-bind:key="CcAddress">
            <div class="col-sm-10">{{CcAddress}}:</div>
          </div>
        </div>

        <div class="col-lg-4">
          Resend Options
          <div class="col-sm-10">
            <button class="btn btn-default btn-sm" @click="onResendToListedRecipients()">
              <i class="fa fa-envelope"></i> Re-Send This Exact Email To Listed Recipients
            </button>
          </div>
          <hr />
          <div class="col-sm-10">
            <label class="col-form-label">Add Other Email Addresses</label>
            <input
              class="form-control input-sm"
              placeholder="You can add multiple addresses using commas"
              id="inputsm"
              type="email"
              v-model="AddedEmailField"
            />
            <button class="btn btn-default btn-sm" @click="onResendToEnteredRecipients()">
              <i class="fa fa-envelope"></i> Re-Send This Email to entered recipients
            </button>

            <p-check
              class="p-switch p-fill"
              color="secondary"
              v-model="IncludeOriginalEmails"
            >Include Original Email Recipients</p-check>
          </div>
        </div>
      </div>
      <hr />
      <div class="row form-group">
        <div class="col-lg-1"></div>
        <div class="col-lg-8">
          <div v-html="AuditTrailEmail.emailField"></div>
        </div>
        <div class="col-lg-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

export default {
  props: ['id'],
  computed: {
    ComputedSentDate () {
      return moment(String(this.AuditTrailEmail.dateSent)).format('DD/MM/YYYY')
    },
    ComputedEmailTitle () {
      if (this.AuditTrailEmail.resendLedgerEmailId === '00000000-0000-0000-0000-000000000000') {
        return 'Email sent on ' + this.ComputedSentDate
      } else { return 'Email resent on ' + this.ComputedSentDate + ' originally sent ' + moment(String(this.AuditTrailEmail.resendOrigEmailDate)).format('DD/MM/YYYY') }
    }
  },
  data () {
    return {
      AuditTrailEmail: {},
      statementLedgerDateId: '',
      AddedEmailField: '',
      IncludeOriginalEmails: false
    }
  },

  async created () {
    await this.load()
    this.statementLedgerDateId = this.id
  },
  methods: {
    async load () {
      await this.getAuditTrailEmail()
    },
    getAuditTrailEmail: async function () {
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetAuditTrailEmail/${this.id}`,
          {
            params: { audittraileventid: this.id, paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get Audit Trail Email data'
          })
        console.log('response', response)

        this.AuditTrailEmail = response.data
      } catch (e) {
        //  this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    ValidateEmail (mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
      }

      return (false)
    },
    CompileEmailAddresses: async function () {
      var swalText = ''
      var enteredAddresses = []
      var toAddresses = []
      var ccAddresses = []
      var badAddresses = []

      console.log('this.AuditTrailEmail.toList', this.AuditTrailEmail.toList)
      if (this.IncludeOriginalEmails) {
        console.log('Include Original Emails')
        for (let t = 0; t < this.AuditTrailEmail.toList.length; t++) {
          var addressString = this.AuditTrailEmail.toList[t].trim()
          var check = this.ValidateEmail(addressString)
          if (check) {
            toAddresses.push(addressString)
          } else { badAddresses.push(addressString) }
        }
      }

      console.log('toAddresses', toAddresses)

      enteredAddresses = this.AddedEmailField.split(',')
      console.log('enteredAddresses', enteredAddresses)

      for (let e = 0; e < enteredAddresses.length; e++) {
        var enteredAddressString = enteredAddresses[e].trim()
        var checkEnteredString = this.ValidateEmail(enteredAddressString)
        if (checkEnteredString) {
          toAddresses.push(enteredAddressString)
        } else { badAddresses.push(enteredAddressString) }
      }

      if (this.AuditTrailEmail.ccList !== null && this.AuditTrailEmail.ccList !== undefined) {
        for (let c = 0; c < this.AuditTrailEmail.ccList; c++) {
          var ccAddressString = this.AuditTrailEmail.ccList[c].trim()
          var checkCcString = this.ValidateEmail(ccAddressString)
          if (checkCcString) {
            ccAddresses.push(ccAddressString)
          } else { badAddresses.push(ccAddressString) }
        }
      }
      console.log('toAddresses', toAddresses)
      var SendEmailOk = true
      // Check Addresses for bad entries
      if (toAddresses.length === 0 && ccAddresses.length === 0) {
        swalText = 'There are no email recipients to email this statement to. Please check the company configuration.'
        SendEmailOk = false
      } else if (badAddresses.length > 0) {
        swalText = 'The following entered values are not valid addresses. Would you like to omit these entries or cancel re-sending this email?'

        for (let b = 0; b < badAddresses.length; b++) {
          swalText = swalText + '<li>' + badAddresses[b] + '</li>'
        }
        try {
          var swalResult = await swal.fire({
            title: 'Problem with email addresses',
            html: swalText,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'Omit',
            cancelButtonText: 'Cancel'
          })
        } catch {
          return
        }
        if (!swalResult.isConfirmed) {
          SendEmailOk = false
        }
      }

      var data = { SendEmailOk: SendEmailOk, ToAddresses: toAddresses, CcAddresses: ccAddresses }
      return data
    },
    onResendToEnteredRecipients: async function () {
      // var swalText = ''
      // var enteredAddresses = []
      // var toAddresses = []
      // var ccAddresses = []

      // console.log('this.AuditTrailEmail.toList', this.AuditTrailEmail.toList)
      // if (this.IncludeOriginalEmails) {
      //   console.log('Include Original Emails')
      //   for (let t = 0; t < this.AuditTrailEmail.toList.length; t++) {
      //     toAddresses.push(this.AuditTrailEmail.toList[t])
      //   }
      // }

      // console.log('toAddresses', toAddresses)

      // enteredAddresses = this.AddedEmailField.split(',')
      // console.log('enteredAddresses', enteredAddresses)

      // for (let e = 0; e < enteredAddresses.length; e++) {
      //   toAddresses.push(enteredAddresses[e])
      // }

      // if (this.AuditTrailEmail.ccList !== null && this.AuditTrailEmail.ccList !== undefined) {
      //   for (let c = 0; c < this.AuditTrailEmail.ccList; c++) {
      //     ccAddresses.push(this.AuditTrailEmail.ccList[c])
      //   }
      // }
      // console.log('toAddresses', toAddresses)

      var compiledEmailAddresses = await this.CompileEmailAddresses()
      var SendEmailOk = compiledEmailAddresses.SendEmailOk
      if (!SendEmailOk) { return }
      var toAddresses = compiledEmailAddresses.ToAddresses
      var ccAddresses = compiledEmailAddresses.CcAddresses
      var swalText = ''
      console.log('toAddresses in main method ', toAddresses)
      console.log('ccAddresses in main method ', ccAddresses)

      if (toAddresses.length === 0 && ccAddresses.length === 0) {
        // Bad addresses and sending was cancelled
        return
      } else {
        if (toAddresses.length === 0) {
          swalText = 'There are no email recipients listed in the TO section. Please check the company configuration'
        } else {
          swalText = 'This action will email this statement to the following email addresses: <br /> <br /> <u>To Addresses:</u>'
          for (let a = 0; a < toAddresses.length; a++) {
            swalText = swalText + '<li>' + toAddresses[a] + '</li>'
          }
          if (ccAddresses > 0) {
            swalText = swalText + '<br /><br /> <u> CC Addresses:</u>'
            for (let b = 0; b < this.AuditTrailEmail.ccList.length; b++) {
              swalText = swalText + '<li>' + ccAddresses[b] + '</li>'
            }
          }
        }
      }
      var data = { ToAddresses: toAddresses, CcAddresses: ccAddresses }

      console.log('data', data)
      try {
        var swalResult = await swal.fire({
          title: 'Email this statement',
          html: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }

      if (!swalResult.isConfirmed) { return }

      try {
        var response = await axios.post(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/ResendToListedRecipients/${this.id}`,
          {

            AuditTrailEmailLogId: this.AuditTrailEmail.id,
            ToAddresses: toAddresses,
            CcAddresses: ccAddresses,
            paygateid: this.selectedCustomer,
            showerror: true,
            errormessage: 'Failed to resend Commission Statement Email'
          })
        console.log('response', response)

        this.AuditTrailEmail = response.data

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }

      await this.getAuditTrailEmail()
    },

    onResendToListedRecipients: async function () {
      var swalText = ''

      if (this.AuditTrailEmail.toList.length === 0 && this.AuditTrailEmail.ccList.length === 0) {
        swalText = 'There are no email recipients to email this statement to. Please check the company configuration.'
      } else {
        if (this.AuditTrailEmail.toList.length === 0) {
          swalText = 'There are no email recipients listed in the TO section. Please check the company configuration'
        } else {
          swalText = 'This action will email this statement to the following email addresses: <br /> <br /> <u>To Addresses:</u>'
          for (let t = 0; t < this.AuditTrailEmail.toList.length; t++) {
            swalText = swalText + '<li>' + this.AuditTrailEmail.toList[t].email + '</li>'
          }
          if (this.AuditTrailEmail.ccList.length > 0) {
            swalText = swalText + '<br /><br /> <u> CC Addresses:</u>'
            for (let c = 0; c < this.AuditTrailEmail.ccList.length; c++) {
              swalText = swalText + '<li>' + this.AuditTrailEmail.ccList[c].email + '</li>'
            }
          }
        }
      }

      console.log('the id', this.id)
      try {
        var swalResult = await swal.fire({
          title: 'Re-send this email?',
          text: 'This action will re-send this email to all entries on the TO and CC lists',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }
      if (!swalResult.isConfirmed) { return }
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/ResendToListedRecipients`,
          {
            params: { AuditTrailEventId: this.id, paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to resend Statement Email'
          })
        console.log('response', response)

        this.AuditTrailEmail = response.data
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
      await this.getAuditTrailEmail()
    }

  }
}

</script>
