<template>
  <div id="manageSchedule">
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
            <div class="idb-block-title">
              <h2><help-icon docPath="/paygate-collections/import-export/exportdata/" />Export Data<favourite-icon></favourite-icon></h2>
            </div>
            <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2 required">
                Select a Group
              </div>
              <div class="col-md-4">
                <group-select
                  v-model="$v.group.$model"
                  :groups="customerGroups"
                  :clearable="false"  @input="setColumns"
                ></group-select>
                <validation-messages name="Group" v-model="$v.group" />
              </div>
            </div>
            <div class="row form-group" v-if="false">
              <div class="col-md-2 required">
                Exported file format
              </div>
              <div class="col-md-4">
                <b-form-select :options="fileFormats" v-model="$v.fileFormat.$model" />
                <validation-messages name="File Format" v-model="$v.fileFormat" />
              </div>
            </div>
              <div class="row form-group">
                <div class="col-md-2 required">
                  Type of data to be exported
                </div>
                <div class="col-md-4">
                  <b-form-select :options="dataTypes" v-model="$v.dataType.$model" @change="setColumns" />
                  <validation-messages name="Data Type" v-model="$v.dataType" />
                </div>
              </div>
              <b-form-group
                  :label-cols="2"
                  horizontal
                  label="Show data to be exported"
                  class="mb-2"
                >
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="showAdvanced" @change="handleAdvanced"
                  ></p-check>
                </b-form-group>
              <vue-good-table v-if="showAdvanced"
                mode="remote"
                ref="table"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :rows="filteredRows"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :search-options="{
                    enabled: true
                    }"
                :paginationOptions="paginationOptions"
                :sort-options="computedSortOptions"
                :isLoading.sync="isTableLoading"
                styleClass="vgt-table striped bordered"
                :columns="columns"
                              class="scroll-table"
              >
                <template slot="loadingContent">
                  <h1>Loading...</h1>
                </template>
                <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">No data available</div>
                </div>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'"
                  >
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span>
                  </button>
                  <b-button
                    @click.prevent="load" :disabled="isLoading"
                    class
                    variant="link"
                    v-b-popover.hover.top.d500="'Refresh the data in the table'"
                  >
                    <i class="fa fa-sync pointer dimmedIcon"></i>
                  </b-button>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'status'">
                    <b-badge pill
                             :variant="getBadgeClass(props.row.status)"
                    >{{props.formattedRow[props.column.field]}}</b-badge>
                  </span>
                  <span v-else-if="props.column.field === 'buttons'">
                    <b-button v-if="props.row.plans.length === 1" @click="goToSchedule(props.row.ukPayerId, props.row.plans[0].planId)" variant="outline-secondary"><i class="fa fa-calendar mr-2"></i>View Schedule</b-button>
                    <b-dropdown v-if="props.row.plans.length > 1" variant="outline-secondary"> <!-- no-caret -->
                      <span slot="button-content"><i class="fa fa-calendar mr-2"></i>View Schedule</span>
                      <b-dropdown-item v-for="(plan, index) in props.row.plans" :key="index" @click="goToSchedule(props.row.ukPayerId, plan.planId)">{{plan.planName}}<i
                        v-if="plan.planStatus === 2"
                        v-b-tooltip.hover
                        title="Suspended"
                        class="fa fa-exclamation-circle text-danger"
                      ></i></b-dropdown-item>
                    </b-dropdown>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div class="idb-block-footer">
              <b-button type="submit" variant="primary" @click="exportData" :disabled="group === null || !groupSelected || fileFormat === null">Export</b-button><small v-if="!groupSelected" class="ml-2 text-danger">Ensure a Group is selected</small>
            </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import ValidationMessages from '@/Assets/Components/Validation/ValidationMessages.vue'
import FileSaver from 'file-saver'
import EventBus from '@/Lib/eventBus'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import { mapGetters } from 'vuex'
import _ from "lodash";

const statusToText = (x) => {
  switch (x) {
    case -1:
      return 'Any'
    case 0:
      return 'Operational'
    case 1:
      return 'Suspended'
    case 2:
      return 'Alert'
    case 3:
      return 'Closed'
    case 4:
      return 'Incomplete'
    case 5:
      return 'Awaiting Activation'
    case 6:
      return 'Plan Suspended'
    default:
      return 'Unknown'
  }
}

const statusToBadgeVariant = (x) => {
  switch (x) {
    case 3:
      return 'secondary'
    case 2:
    case 6:
      return 'warning'
    case 1:
      return 'danger'
    case 0:
      return 'success'
    case 4:
    case 5:
      return 'info'
    default:
      return 'dark'
  }
}

export default {
  mixins: [tableFilterMixin, loading],
  data () {
    return {
      group: '00000000-0000-0000-0000-000000000000',
      fileFormat: 'csv',
      dataType: 'Customers',
      fileFormats: ['csv', 'xml', 'json'],
      dataTypes: ['Customers', 'Schedules', 'Collections'],
      showAdvanced: false,
      filteredRows: [],
      firstInPayers: true,
      firstInPlans: true,
      firstInCollections: true,
      columns: [],
      serverParams: {
        sort: [{ field: 'reference', type: 'asc' }],
      }
    }
  },
  components: { ValidationMessages },
  computed: {
    ...mapGetters(['selectedCustomer']),
    customerGroups () {
      var ret = []
      if (this.$store.getters.rightsGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.rightsGroups).map(x => {
          let obj = {}
          obj.groupId = x.groupId
          obj.name = x.name
          obj.colour = x.colour
          return obj
        })
      }
      if(ret.length > 1){
        ret.unshift({
          'description': '', 'groupId': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'name': 'Please Select', 'paygateId': this.paygateId, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      }
      return ret
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    computedSortOptions () {
      return {
        enabled: true,
        initialSortBy: this.dataType === 'Customers' ? { field: 'reference', type: 'asc'} : {field: 'payerCoreReference', type: 'asc'}
      }
    },
    groupSelected () {
      return this.group !== '00000000-0000-0000-0000-000000000000'
    }
  },
  mounted () {
    this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })

    var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
    if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
      this.selectedGroup = this.customerGroups.find(i => i.groupId === defaultGroupId)
      // Default group might be a FPS group which means it can't be the BACS default group and vice versa.
      if (this.selectedGroup !== undefined) {
        this.group = defaultGroupId
      }
    }
    if (this.group !== null) {
      this.setColumns()
    }
  },
  methods: {
    setColumns () {
      this.firstInPlans = true
      this.firstInPayers = true
      if(this.$refs.table !== undefined && this.$refs.table !== null) {
        this.clearTableFilters()
      }
      switch (this.dataType) {
        case 'Customers':
          this.serverParams.sort[0].field = 'reference'
          this.serverParams.sort[0].type = 'asc'
          this.columns = [
            {
              label: 'id',
              field: 'ukPayerId',
              hidden: true
            },
            {
              label: 'Reference',
              field: 'reference'
            },
            {
              label: 'Group',
              field: 'groupName'
            },
            {
              label: 'Customer Status',
              field: 'status',
              formatFn: statusToText,
              tdClass: 'text-center'
            },
            {
              label: 'Name',
              field: 'displayName'
            },
            {
              label: 'Email',
              field: 'email'
            },
            {
              label: 'Mobile Number',
              field: 'mobile'
            }
          ]
          break
        case 'Schedules':
          this.serverParams.sort[0].field = 'payerCoreReference'
          this.serverParams.sort[0].type = 'asc'
          this.columns = [
            {
              label: 'Reference',
              field: 'payerCoreReference'
            },
            {
              label: 'Schedule Reference',
              field: 'scheduleReferenceOverride'
            },
            {
              label: 'Group',
              field: 'groupName'
            },
            {
              label: 'Frequency',
              field: 'frequency',
              formatFn: this.formatFrequency
            },
            {
              label: 'Regular Amount',
              field: 'regularAmount',
              formatFn: (value) => {
                return `${this.numberWithCommas((value / 1).toFixed(2))}`
              },
            },
            {
              label: 'First Collection Date',
              field: 'firstCollectionDate',
              formatFn: (value) => {
                const retVal = this.formatDate(new Date(value))
                return retVal
              }
            },
          ]
          break
        case 'Collections':
          this.serverParams.sort[0].field = 'payerCoreReference'
          this.serverParams.sort[0].type = 'asc'
          this.columns = [
            {
              label: 'Reference',
              field: 'planReference'
            },
            {
              label: 'Amount',
              field: 'amount',
              formatFn: (value) => {
                return `${this.numberWithCommas((value / 1).toFixed(2))}`
              },
            },
            {
              label: 'Collection Date',
              field: 'collectionDate',
              formatFn: (value) => {
                const retVal = this.formatDate(new Date(value))
                return retVal
              }
            },
          ]
          break
      }
    },
    load () {
      var query = this.buildGoodTableQuery()
      if (this.group != null) {
        switch (this.dataType) {
          case 'Customers':
            if(this.firstInPayers) {
              // this.serverParams.sort = [ { field: 'reference', type:'asc' } ]
              // query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
              this.firstInPayers = false
            }
            axios.get(`${process.env.VUE_APP_DDMS_API_URL}payerexport/${this.group}/filtered`, {
              params: query,
              paygateId: this.paygateId
            }).then((response) => {
              this.filteredRows = response.data.data
              this.totalRecords = response.data.count
            })
            break;
          case 'Schedules':
            if(this.firstInPlans) {
              // this.serverParams.sort = [ { field: 'payerCoreReference', type:'asc' } ]
              // query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
              this.firstInPlans = false
            }
            axios.get(`${process.env.VUE_APP_DDMS_API_URL}planexport/${this.group}/filtered`, {
              params: query,
              paygateId: this.paygateId
            }).then((response) => {
              this.filteredRows = response.data.data
              this.totalRecords = response.data.count
            })
            break;
          case 'Collections':
            if(this.firstInCollections) {
              // this.serverParams.sort = [ { field: 'payerCoreReference', type:'asc' } ]
              // query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
              this.firstInPlans = false
            }
            axios.get(`${process.env.VUE_APP_DDMS_API_URL}collectionexport/${this.group}/filtered`, {
              params: query,
              paygateId: this.paygateId
            }).then((response) => {
              this.filteredRows = response.data.data
              this.totalRecords = response.data.count
            })
            break;
        }
      }
    },
    handleAdvanced () {
      this.$nextTick(() => {
        this.clearTableFilters()
        if (this.showAdvanced) {
          this.load()
        }
      })
    },
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
      // const groups = this.$store.getters.customerGroups
      // const paygateIdNotDefault = (paygateId && paygateId !== '00000000-0000-0000-0000-000000000000')
      // const groupsNull = (groups === undefined || groups === null)
      // if (paygateIdNotDefault && groupsNull) {
      //   this.$store.dispatch('loadCustomerGroups', paygateId)
      // }
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    amountFormatter (value) {
      return value.toFixed(2)
    },
    exportData () {
      var query = this.buildGoodTableQuery()
      query.perPage = this.totalRecords
      query.page = 1
      switch (this.dataType) {
        case 'Customers':
          axios.get(`${process.env.VUE_APP_DDMS_API_URL}payerexport/${this.group}`, { params: this.showAdvanced ? query : null, paygateId: this.paygateId }).then((response) => {
            let blob = new Blob([response.data.$values ? JSON.stringify(response.data.$values) : response.data], { type: response.headers.contentType })
            let url = window.URL.createObjectURL(blob)
            FileSaver.saveAs(blob, `${this.dataType}-${new Date().toISOString()}.${this.fileFormat}`)
            window.URL.revokeObjectURL(url)
          })
          break;
        case 'Schedules':
          axios.get(`${process.env.VUE_APP_DDMS_API_URL}planexport/${this.group}`, { params: this.showAdvanced ? query : null, paygateId: this.paygateId }).then((response) => {
            let blob = new Blob([response.data.$values ? JSON.stringify(response.data.$values) : response.data], { type: response.headers.contentType })
            let url = window.URL.createObjectURL(blob)
            FileSaver.saveAs(blob, `${this.dataType}-${new Date().toISOString()}.${this.fileFormat}`)
            window.URL.revokeObjectURL(url)
          })
          break;
        case 'Collections':
          axios.get(`${process.env.VUE_APP_DDMS_API_URL}collectionexport/${this.group}`, { params: this.showAdvanced ? query : null, paygateId: this.paygateId }).then((response) => {
            let blob = new Blob([response.data.$values ? JSON.stringify(response.data.$values) : response.data], { type: response.headers.contentType })
            let url = window.URL.createObjectURL(blob)
            FileSaver.saveAs(blob, `${this.dataType}-${new Date().toISOString()}.${this.fileFormat}`)
            window.URL.revokeObjectURL(url)
          })
          break;
      }
    },
    goToSchedule (payerId, planId) {
      this.$router.push({ name: 'PayerSchedule', params: { id: payerId, planId: planId } })
    },
    getBadgeClass (value) {
      return statusToBadgeVariant(value)
    },
    formatFrequency(value){
      let freq = ''
      switch (value) {
        case 0:
          freq = 'Weekly'
          break
        case 1:
          freq = 'Fortnightly'
          break
        case 2:
          freq = 'Four Weekly'
          break
        case 3:
          freq = 'Monthly'
          break
        case 4:
          freq = 'Two Monthly'
          break
        case 5:
          freq = 'Quarterly'
          break
        case 6:
          freq = 'Six Monthly'
          break
        case 7:
          freq = 'Yearly'
          break
      }
      return freq
    }
  },
  validations: {
    group: { required },
    /*fileFormat: { required },*/
    dataType: { required }
  },
  watch: {
    selectedCustomer () {
      this.group = '00000000-0000-0000-0000-000000000000'
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      this.load()
    }
  }
}
</script>
