<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Privacy Policy</h2>
      </div>
      <div class="idb-block-content">
        <p>London &amp; Zurich Limited as part of the Jonas Family of Companies (“Jonas,” “we,” “us,” ”our”) are committed to protecting your privacy. There are various ways that you might interact with Jonas, and the information you provide when doing so allows us to improve our services.</p>
        <p>This website, our related websites and any mobile site or mobile application that link to this Privacy Policy) (collectively, the “Site”, “Sites”) are owned and operated by London &amp; Zurich Limited with its principal place of business at Headley Technology Park, Middle Lane, Wythall, Birmingham, Warwickshire, B38 0DS</p>
        <br/>
        <h4>Our privacy policy explains:</h4>
        <ul>
          <li>What information we collect, and why we collect it;</li>
          <li>How we use that information;</li>
          <li>How we protect that information;</li>
          <li>How you can control your information, including accessing, updating and deleting what we store; and</li>
          <li>How we share information collected.</li>
        </ul>
        <p>This Privacy Policy applies to London &amp; Zurich Limited and its subsidiaries and affiliates and covers our processing activities as a data controller.</p>
<p>We do not sell our services to children and the Site is not intended for or directed at children under the age of 13 years. As such, our Sites are designed for adult user interaction. We do not intentionally collect personally identifiable information from children under the age of 13.</p>
<p>We are not required to appoint a formal data protection officer under data protection laws. However, we have appointed a Data Protection Contact who you can reach out to about any queries you may have in relation to this Privacy Policy. If you are located in the EEA, you can contact our representative(s) in the EEA about any data protection queries, using the contact details below.</p>
<p>Jonas adheres to Canadian and international statutes and regulations which govern the protection of personal information. In Canada Jonas adheres to the Personal Information and Protection of Electronic Documents Act (“PIPEDA”). In the United States, Jonas adheres to statutes of general application and statutes of specific application such as the Health Insurance Portability and Accountability Act (“HIPPA”) where applicable In Australia, Jonas complies with the Privacy Act, 1988, a federal statute as well as with State and Territory laws pertaining to protection of personal information, including the Health Records Act 2001(VIC) where applicable. In New Zealand, Jonas follows the Privacy Act, 1993. In Europe, Jonas adheres to i) until 25 May 2018, EU Directive 95/46/EC, as transposed into domestic legislation of each Member State; ii) on and from 25 May 2018 the General Data Protection Regulation (EU) 2016/679 (“GDPR”); iii) as and when enacted in the United Kingdom, the Data Protection Act [2018], supplementing and amending the GDPR; iv) EU Directive 2002/58/EC on privacy and electronic communications, as transposed into domestic legislation of each Member State; and v) any applicable decisions, guidelines, guidance notes and codes of practice issued from time to time by courts, supervisory authorities and other applicable government authorities; in each case together with all laws implementing, replacing or supplementing the same.</p>
<br/>
<h4>Contacting Us</h4>
<p>If you have any questions about our Privacy Policy or your information, or to exercise any of your rights as described in this Privacy Policy or under data protection laws, you can contact us as follows:</p>
<p>In the EEA</p>
<p>By post: London &amp; Zurich Limited, Headley Technology Park, Middle Lane, Wythall, Birmingham, Warwickshire, B38 0DS</p>
<p>By telephone: +44 (0)1462 418117</p>
<p>By email: <a href="mailto:Info@landz.co.uk">Info@landz.co.uk</a> </p>
<br/>
<h4>Topics</h4>
<ul>
<li><a href="#informationWeCollect">Information we collect</a></li>
<li><a href="#howWeUseInformation">How We Use Information</a></li>
<li><a href="#marketing">Marketing</a></li>
<li><a href="#optingout">Opting Out</a></li>
<li><a href="#changeofpurpose">Change of purpose</a></li>
<li><a href="#dataprocessor">London &amp; Zurich Limited as data processor</a></li>
<li><a href="#disclosure">Disclosure of your personal data to third parties</a></li>
<li><a href="#internationaltransfers">International transfers</a></li>
<li><a href="#security">Security of your personal data</a></li>
<li><a href="#dataretention">Data retention: How long we keep your personal data</a></li>
<li><a href="#yourrights">Your rights</a></li>
<li><a href="#object">Your right to object</a></li>
<li><a href="#excercising">Exercising your rights</a></li>
<li><a href="#useofcookies">Use of Cookies, IP Addresses and Aggregate Information</a></li>
<li><a href="#socialmedia">Social Media and Online Engagement</a></li>
<li><a href="#complaints">Complaints</a></li>
<li><a href="#changes">Changes to this Policy</a></li>
</ul>
<br/>
<h4 id="informationWeCollect">Information we collect</h4>
<br/>
<h5>Information you give us </h5>
<p>We may collect or record basic personal information which you voluntarily provide through completing forms on our Site, through electronic mail you send to us, or through other means of communication between you and us. The categories of personal information you provide may include:</p>
<ul>
<li>first and last name</li>
<li>job title and company name</li>
<li>email address</li>
<li>phone number</li>
<li>mailing address</li>
<li>password to register with us</li>
<li>your personal or professional interests</li>
<li>any other identifier that permits us to make contact with you.</li>
</ul>
<p>We do not generally seek to collect sensitive personal information (e.g. social security or other governmental ID numbers, credit card details and account numbers, racial or ethnic origin, political opinions, religious or philosophical beliefs, trade-union membership; health or sex life, sexual orientation; genetic or biometric information) through our Site and if we do we will ask for your explicit consent to our proposed use of that information at the time of collection. This information will be collected, stored, accessed and processed in a secure manner.</p>

<br/>
<h5>Information we collect from you</h5>
<p>We collect, store and use information about your visits to the Sites and about your computer, tablet, mobile or other device through which you access the Sites. This includes the following information:</p>
<ul>
  <li>general non-personal information pertaining to users of our sites technical information, including the Internet protocol (IP) address, source domain names, specific web pages, length of time spent, and pages accessed, browser type, internet service provider, device identifier, your login information,  time zone setting, browser plug-in types and versions, operating system and platform, and geographical location</li>
  <li>information about your visits and use of the Site, including the full Uniform Resource Locators (URL), clickstream to, through and from our Site, pages you viewed and searched for, page response times, length of visits to certain pages, referral source/exit pages, page interaction information (such as scrolling, clicks and mouse-overs), and website navigation and search terms used.]</li>
  <li>information about individual contacts for our customers (“Business Contact Information”) in the ordinary course of our business for managing and maintaining customer relationships. In particular, we may obtain the following types of Business Contact Information: name, address, invoice information including bank account information, and order information.</li>
</ul>

<br/>
<h5>Collection and Use of Employee Personal Information</h5>
<p>We also collect personal information from our employees and from job applicants (human resource data) in connection with administration of our human resources programs and functions. These programs and functions include, but are not limited to: job applications and hiring programs, compensation and benefit programs, performance appraisals, training, access to our facilities and computer networks, employee profiles, employee directories, human resource recordkeeping, and other employment related purposes. It is our policy to keep all past and present employee information private from disclosure to third parties. There are certain business related exceptions and they are:</p>
<ul>
  <li>To comply with municipal, regional, provincial or federal agency requests</li>
  <li>Inquiries from third parties with a signed authorization from the employee to release the information, except in situations where limited verbal verifications are acceptable (see below)</li>
  <li>Third parties with which we have contractual agreements to assist in administration of company sponsored benefits.</li>
</ul>
<p>Prospective employers, government agencies, financial institutions, and residential property managers routinely contact us requesting information on a former or current employee’s work history and salary. All such requests of this type shall be referred to and completed on a confidential basis by the human resources department or payroll department. For written verification of employment requests, information will be provided on the form only when it is accompanied by an employee’s signed authorization to release information. The form will be returned directly to the requesting party and filed as part of the human resources or payroll department’s confidential records.</p>
<br/>
<h4 id="howWeUseInformation">How We Use Information</h4>
<br/>
<p>As a data controller, we will only use your personal information if we have a legal basis for doing so. The purpose for which we use and process your information and the legal basis on which we carry out each type of processing is explained in the table below.</p>
<p>Note that we may process your personal data for more than one lawful ground if the data is used for several purposes. Please Contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
<br/>
<table class="table">
  <thead>
    <tr>
      <th scope="col">Purposes for which we will process the information</th>
      <th scope="col">Type of data</th>
      <th scope="col">Legal Basis for the processing</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>To provide you with information and materials that you request from us.</td>
      <td>(a) Identity
(b) Contact</td>
      <td>@It is in our legitimate interests to respond to your queries and provide any information and materials requested in order to generate and develop business. To ensure we offer an efficient service, we consider this use to be proportionate and will not be prejudicial or detrimental to you.</td>
    </tr>
    <tr>
      <td>To personalise our services and products and the Sites to you.</td>
      <td>(a) Identity
(b) Contact

(c) Technical</td>
      <td>
        <p>It is in our legitimate interests to improve the Site in order to enhance your experience on our Site, to facilitate system administration and better our services. We consider this use to be proportionate and will not be prejudicial or detrimental to you.</p>
        <p>If you do not wish to receive tailored or personalised information from us based on personal information you have provided us, please contact us as provided in “Contacting Us” above.</p>
      </td>
    </tr>
    <tr>
      <td>To update you on services and products and benefits we offer.</td>
      <td>(a) Identity
(b) Contact

(c) Profile

(d) Usage

(e) Marketing and Communications

(f) Technical</td>
      <td>
        <p>It is in our legitimate interests to market our services and products. We consider this use to be proportionate and will not be prejudicial or detrimental to you.</p>
        <p>If you would prefer not to receive any direct marketing communications from us, you can opt-out at any time by contacting us as providing in “Contacting Us” above.</p>
        <p>For direct marketing sent by email to new contacts (i.e. individuals who we have not previously engaged with), we need your consent to send you unsolicited direct marketing.</p>
      </td>
    </tr>
        <tr>
      <td>To send you information regarding changes to our policies, other terms and conditions and other administrative information.</td>
      <td>(a) Identity
(b) Contact

(c) Technical

(d) Usage

(e)         Profile</td>
      <td>It is in our legitimate interests to ensure that any changes to our policies and other terms are communicated to you. We consider this use to be necessary for our legitimate interests and will not be prejudicial or detrimental to you.</td>
    </tr>
    <tr>
      <td>
        <p>To administer our Sites including troubleshooting, data analysis, testing, research, statistical and survey purposes;</p>
        <p>To improve our Sites to ensure that consent is presented in the most effective manner for you and your computer, mobile device or other item of hardware through which you access the Sites; and</p>
        <p>To keep our Sites safe and secure.</p>
      </td>
      <td>(a) Identity
(b) Contact

(c) Technical

(d) Usage

(e)         Profile</td>
      <td>For all these categories, it is in our legitimate interests to continually monitor and improve our services and your experience of the Sites and to ensure network security. We consider this use to be necessary for our legitimate interests and will not be prejudicial or detrimental to you.</td>
    </tr>
        <tr>
      <td>To measure or understand the effectiveness of any marketing we provide to you and others, and to deliver relevant marketing to you.</td>
      <td>(a) Identity
(b) Contact

(c) Technical

(d) Usage

(e)         Profile</td>
      <td>It is in our legitimate interests to continually improve our offering and to develop our business. We consider this use to be necessary in order to effectively generate business and will not be prejudicial or detrimental to you.</td>
    </tr>
        <tr>
      <td>To enforce the terms and conditions and any contracts entered into with you.</td>
      <td></td>
      <td>It is in our legitimate interests to enforce our terms and conditions of service. We consider this use to be necessary for our legitimate interests and proportionate.</td>
    </tr>

  </tbody>
</table>
<br/>
<p>If you do not wish to provide us with your personal data and processing such data is necessary for the performance of a contract with you and to fulfil our contractual obligations to you, we may not be able to perform our obligations under the contract between us.</p>
<p>Where you provide consent, you can withdraw your consent at any time and free of charge, but without affecting the lawfulness of processing based on consent before its withdrawal. You can update your details or change your privacy preferences by contacting us as provided in “Contacting us” above.</p>
<p>Where we rely on legitimate interests as a lawful basis, we will carry out a balancing test to ensure that your interests, rights and freedoms do not override our legitimate interests. If you want further information on the balancing test we have carried out, you can request this from us by Contacting us.</p>

<br/><br/>
<h4 id="marketing">Marketing</h4>
<p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.</p>
<p>Third-party marketing  </p>
<p>We do not share personal data with any company outside the Jonas group for marketing purposes.</p>

<br/><br/>
<h4 id="optingOut">Opting out</h4>
<p>You can ask us to stop sending you marketing messages at any time following the opt-out links on any marketing message sent to you or by Contacting us at any time at <a href="mailto:Info@landz.co.uk">Info@landz.co.uk</a>.</p>

<br/><br/>
<h4 id="changeofpurpose">Change of purpose</h4>
<p>We will only use your personal information for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your personal information for an unrelated purpose, we will notify you in a timely manner and we will explain the legal basis which allows us to do so.</p>

<br/><br/>
<h4 id="dataprocessor">London &amp; Zurich Limited as data processor</h4>
<p>In certain cases, we also operate as a data processor and we collect and process personal information on behalf of our business customers in the provision of our services and products. In these circumstances, London & Zurich Limited is acting as a data processor and our business customers remain the data controller in respect of personal information they provide to us.</p>
<p>Our business customers remain the data [information] controllers with respect to any customer information that they provide to us for our provision of services. To the extent that we are acting as data processor, we therefore act in accordance with the instructions of such customers regarding the collection, processing, storage, deletion and transfer of customer information, as well as other matters such as the provision of access to and rectification of customer. We will only use such personal information for the purposes of providing the services and products for which our business customers have engaged us.</p>
<p>Our business customers are responsible for ensuring that these individuals’ privacy is respected, including communicating to the individuals in their own privacy policies who their personal information is being shared with and processed by.</p>
<p>As a data processor, we may share personal information where instructed by our business customer. Where authorized by the business customer, we may also share personal information with third party service providers who work for us and who are subject to security and confidentiality obligations.</p>
<p>Where London &amp; Zurich Limited is acting as a data processor, we will refer any request from an individual for access to personal information which we hold about them to our customer. We will not respond directly to the request.</p>
<p>We will retain personal information which we process on behalf of our customers for as long as needed to provide services and products to our customers and in accordance with any agreement in place with our customers.</p>

<br/><br/>
<h4 id="disclosure">Disclosure Of Your Personal Data To Third Parties</h4>
<p>We will not sell, rent, lease or otherwise share your personal information other than as outlined in this Privacy Policy or without obtaining your consent beforehand.</p>
<h5>Internal third parties</h5>
<p>We may share your personal information with our group companies, affiliates, subsidiaries or contractors as necessary to carry out the purposes for which the information was supplied or collected (i.e. to provide the services and products you have requested from us).</p>
<h5>External third parties</h5>
<p>Personal information will also be shared with our third party service providers and business partners who assist with the running of the Sites and our services and products including [hosting providers, email service providers, insert others]. Our third party service providers and business partners are subject to security and confidentiality obligations and are only permitted to process your personal information for specified purposes and in accordance with our instructions.</p>
<p>We may also post links to third party websites as a service to you. These third party websites are operated by companies that are outside of our control, and your activities at those third party websites will be governed by the policies and practices of those third parties. We encourage you to review the privacy policies of these third parties before disclosing any information, as we are not responsible for the privacy policies of those websites.</p>
<p>In addition, we may disclose information about you when we believe, in good faith, that such use or disclosure is reasonably necessary to:</p>
<ul>
  <li>comply with law
  <li>enforce or apply the terms of any of our user agreements</li>
  <li>protect our rights, property or safety,, or the rights, property or safety of our users, or others</li>
  <li>in the event that we become involved in a business divestiture, change of control, sale, merger, or acquisition of all or a part of our business, in which case we may disclose your personal information to the prospective seller or buyer of such business or assets</li>
  <li>if all or substantially all of our assets are acquired by a third party, in which case personal information held by it about its customers will be one of the transferred assets</li>
  <li>if we are under a duty to disclose or share your personal information in order to comply with any legal or regulatory obligation</li>
  <li>if necessary to protect the vital interests of a person; and</li>
  <li>to enforce or apply our terms and conditions or to establish, exercise or defend the rights of London &amp; Zurich Limited, our staff, customers or others.</li>
</ul>
<br/><br/>
<h4 id="international">International Transfers</h4>
<h5>If you are based in the EU or EEA</h5>
<p>We share your personal data within the Jonas Group. This will involve transferring your data outside the European Economic Area (EEA) to Canada the USA, Australia and New Zealand. Canada and New Zealand have been deemed by the EU as having an adequate level of protection for personal data.</p>
<p>Many of our external third party service providers and business partners are based outside the European Economic Area (EEA) so their processing of your personal data will involve a transfer of data outside the EEA.</p>
<p>Whenever we transfer your personal data outside the EEA to the countries identified above which have not been deemed by the EU to have an adequate level of protection for personal data, and specifically to the US, we ensure a similar degree of protection is afforded to it by using standard data protection clauses approved by the European Commission (as permitted under Article 46(2)(c)) that are designed to help safeguard your privacy rights.</p>
<p>You can contact us as provided in “Contacting us” above if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA.</p>

<br/><br/>
<h4 id="security">Security of Your Personal Data</h4>
<p>The security of your personal information is important to us. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it.</p>
<p>We use appropriate measures to safeguard personally identifiable information, which measures are appropriate to the type of information maintained, and follows applicable laws regarding the safeguarding of any such information under our control. In addition, in some areas of our Sites, we may use encryption technology to enhance information privacy and help prevent loss, misuse, or alteration of the information under our control. We also employ industry-standard measures and processes for detecting and responding to inappropriate attempts to breach our systems.
No method of transmission over the Internet, or method of electronic storage, can be 100% secure. Therefore, we cannot guarantee the absolute security of your information. The Internet by its nature is a public forum, and we encourage you to use caution when disclosing information online. Often, you are in the best situation to protect yourself online. You are responsible for protecting your username and password from third party access, and for selecting passwords that are secure.</p>
<p>If you have any questions about security on our Site, you can contact us as provided in “Contacting us” above.</p>

<br/><br/>
<h4 id="dataretention">Data Retention: How Long We Keep Your Personal Data</h4>
<p>When you contact us, we may keep a record of your communication to help solve any issues that you might be facing. Your information may be retained for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirement.</p>
<p>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
<p>Details of retention periods for different aspects of your personal data are available in our retention policy which you can request from us by contacting us.</p>

<br/><br/>
<h4 id="yourrights">Your rights</h4>
<p>Subject to certain limitations, you have rights under data protection laws in relation to your personal data. These rights include the rights to:</p>
<ul>
<li><strong>Request access to your personal data</strong> – (commonly known as a “data subject access request”). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it. Note that we may refuse to comply with a request for access if the request is manifestly unfounded or excessive, or repetitive in nature.</li>
<li><strong>Request correction of your personal data</strong> – this enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us. Note that we may refuse to comply with a request for correction if the request is manifestly unfounded or excessive, or repetitive in nature.</li>
<li><strong>Request erasure of your personal data</strong> – this enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note that we may refuse a request for erasure, for example, where the processing is necessary to comply with a legal obligation or necessary for the establishment, exercise or defence of legal claims.</li>
<li><strong>Request restriction of processing your personal data</strong> – this enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data’s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it. Note that we may refuse to comply with a request for restriction if the request is manifestly unfounded or excessive, or repetitive in nature.</li>
<li><strong>Request transfer of your personal data</strong> – we will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies where your personal data is processed by us with your consent or for the performance of a contract and when processing is carried out by automated means.</li>
<li><strong>Right to withdraw consent</strong> – you can withdraw your consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent.</li>
</ul>

<br/><br/>
<h4 id="object">Your Right To Object</h4>
<br/>
<h5>Direct marketing</h5>
<p>You have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>
<p>Where we process your information based on our legitimate interests.</p>
<p>You also have the right to object, on grounds relating to your particular situation, at any time to processing of your personal information which is based on our legitimate interests. Where you object on this ground, we shall no longer process your personal information unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms or for the establishment, exercise or defence of legal claims.</p>

<br/><br/>
<h4 id="exercising">Exercising Your Rights</h4>
<p>If you wish to exercise any of the rights set out above, including withdrawing consent, please contact us giving us specific details regarding which right you choose to exercise.</p>
<h5>No fee usually required</h5>
<p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>
<h5>What we may need from you  </h5>
<p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
<h5>Time limit to respond</h5>
<p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
<p>Please direct any questions about your information to the Data Protection Contact identified above.</p>

<br/><br/>
<h4 id="useofcookies">Use of Cookies, IP Addresses and Aggregate Information</h4>
<h5>Cookies</h5>
<p>In order to improve the Sites, we may use small files commonly known as “cookies”. Cookies are a technology that can be used to help personalize your use of a website.  A cookie is a small amount of data which often includes a unique identifier that is sent to your computer or mobile phone (your “device”) from the Sites and is stored on your device’s browser or hard drive. The cookies we use on the Sites won’t collect personally identifiable information about you and we won’t disclose information stored in cookies that we place on your device to third parties.</p>
<p>To enable us to assess the effectiveness and usefulness of this Site, and to give you the best user experience, we collect and store information such as pages viewed by you, your domain names and similar information. Our Site makes use of anonymous cookies for the purposes of:</p>
<ul>
  <li>Completion and support of Site activity</li>
  <li>Site and system administration</li>
  <li>Research and development and</li>
  <li>Anonymous user analysis, user profiling, and decision-making.</li>
</ul>
<p>By continuing to browse the Sites, you are agreeing to our use of cookies.</p>
<p>If you don’t want us to use cookies when you use the Sites, you can set your browser to notify you when you receive a cookie, giving you the chance to decide whether to accept it or decline at any time. However, if you block cookies some of the features on the Sites may not function as a result.</p>
<p>You can find more information about how to do manage cookies for all the commonly used internet browsers by visiting www.allaboutcookies.org. This website will also explain how you can delete cookies which are already stored on your device.</p>

<br/>
<h5>We currently set the following cookies:</h5>
<table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Cookie Name</th>
      <th scope="col">Description</th>
      <th scope="col">Duration</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>XSRF-TOKEN</td>
      <td>Prevent Cross-Site Request Forgery (XSRF/CSRF) attacks</td>
      <td>Session</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>loginstatus</td>
      <td>Tracks Login Status</td>
      <td>Session</td>
    </tr>
    <!-- <tr>
      <th scope="row">3</th>
      <td>TBD</td>
      <td>TBD</td>
      <td>TBD</td>
    </tr> -->
  </tbody>
</table>

<br/>
<h5>Cookies from third parties</h5>
<p>We currently use the following third party cookies:</p>
<table class="table">
  <thead>
    <tr>
      <th scope="col">Third Party Cookies</th>
      <th scope="col">Cookie Name(s)</th>
      <th scope="col">Purpose</th>
      <th scope="col">Link</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Hubspot</td>
      <td>Help Assistant</td>
      <td><a href="https://legal.hubspot.com/privacy-policy">Hubspot Privacy Policy</a></td>
    </tr>
  </tbody>
</table>

<p>We only use “Google Analytics” on the Sites. This cookie provides us with a visitor count and an understanding of how visitors move around and use the Sites. We can then use this information to improve navigability and the Sites generally.  The cookies we use on the Sites won’t collect personally identifiable information about you and we won’t disclose information stored in cookies that we place on your device to third parties.</p>
<p>The Sites use Google Analytics, a web analytics service provided by Google Inc. (<strong>Google</strong>). Google Analytics uses “cookies”, which are text files placed on your computer, to help the Sites analyse how users use the Sites. The information generated by the cookie about your use of the Sites (including your IP address) will be transmitted to and stored by Google on servers in the United States.  Google will use this information for the purpose of evaluating your use of the Sites, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google.  You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of the Sites. By using the Sites, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</P>
<h5>IP Addresses and Aggregate Information</h5>
<p>An Internet Protocol (“IP”) address is associated with your computer’s connection to the internet. We may use your IP address to help diagnose problems with our server, to administer the Site and to maintain contact with you as you navigate through the Site. Your computer’s IP address also may be used to provide you with information based upon your navigation through the Site.</p>
<p>Aggregate information is used to measure the visitors’ interest in, and use of, various areas of the Site and the various programs that we administer. We will rely upon aggregate information, which is information that does not identify you, such as statistical and navigational information. With this aggregate information, we may undertake statistical and other summary analyses of the visitors’ behaviors and characteristics. Although we may share this aggregate information with third parties, none of this information will allow anyone to identify you, or to determine anything else personal about you.</p>
<h5>Links</h5>
<p>The Sites may, from time to time, contain links to and from the websites of our business partners, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal information to these websites.</p>

<br/><br/>
<h4 id="socialmedia">Social Media and Online Engagement</h4>
<p>We occasionally use a variety of new technologies and social media options to communicate and interact with customers, potential customers, employees and potential employees. These sites and applications include popular social networking and media sites, open source software communities and more. To better engage the public in ongoing dialog, certain of our businesses use certain third-party platforms including, but not limited to, Facebook, Twitter and LinkedIn. Third-Party Websites and Applications (TPWA) are Web-based technologies that are not exclusively operated or controlled by us. When interacting on those websites, you may reveal certain personal information to us or to third parties. Other than when used by our employees for the purpose of responding to a specific message or request, we will not use, share, or retain your personal information.</p>
<ul>
  <li>The Facebook privacy policy is available at:<a href="http://www.facebook.com/policy.php" target="blank">http://www.facebook.com/policy.php</a></li>
  <li>The Twitter privacy policy is available at:<a href="http://twitter.com/privacy" target="blank"> http://twitter.com/privacy</a></li>
  <li>The LinkedIn privacy policy is available at:<a href="http://www.linkedin.com/static?key=privacy_policy" target="blank"> http://www.linkedin.com/static?key=privacy_policy</a></li>
</ul>

<br/><br/>
<h4 id="complaints">Complaints</h4>
<p>For the UK: You have the right to make a complaint at any time with a supervisory authority, in particular in the EU (or EEA) state where you work, normally live or where any alleged infringement of data protection laws occurred. The supervisory authority in the UK is the Information Commissioner who may be contacted at <a href="https://ico.org.uk/concerns/" target="blank">https://ico.org.uk/concerns/</a> or telephone: <a href="tel:03031231113">0303 123 1113</a>.</p>

<br/><br/>
<h4 id="policy">Changes to this Policy</h4>
<p>We regularly review our compliance with our privacy policy. We also adhere to several self-regulatory frameworks in addition to complying with applicable law.</p>
<p>We may change this privacy policy from time to time. If this privacy policy changes, the revised privacy policy will be posted at the “Privacy Policy” link on the Site’s home page. In the event that the change is significant or material, we will notify you of such a change by revising the link on the home page to read “Newly Revised Privacy Policy.” Please check the privacy policy frequently. Your continued use of the Site constitutes acceptance of such changes in the privacy policy, except where further steps are required by applicable law. This privacy policy was last updated on the date set out at the end of the policy.</p>
<br/><br/>
<p>LAST UPDATED: 25 June 2021</p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {

  }
}
</script>

<style scoped>
/* h4 {
  color: #053691;
} */
</style>
