import Store from '@/Store/index'
import roles from '@/Assets/Constants/roles'

export default [
  {
    menu_title: 'Reports',
    menu_icon: 'ti-receipt',
    showMethod: async () => {
      return Store.getters.isInRole(roles.Finance)
    },
    child_routes: [{
      path: '/payments/bacsreports/viewDownloadedReports',
      menu_title: 'View Downloaded Reports'
    }]
  },
  {
    menu_title: 'Bankline',
    menu_icon: 'ti-wallet',
    showMethod: async () => {
      return Store.getters.isInRole(roles.Finance)
    },
    child_routes: [
      {
        path: '/admin/bandr/bankline/banklinepayment',
        menu_title: 'Bankline Standard Payment'
      },
      {
        path: '/admin/bandr/bankline/banklinechapspayment',
        menu_title: 'Bankline CHAPS Payment'
      },
      {
        path: '/admin/bandr/bankline/banklineoutbound',
        menu_title: 'Bankline Outbound Files'
      }
    ]
  },
  {
    menu_title: 'Unlock Submissions',
    menu_icon: 'ti-unlock',
    path: '/payments/bacsAdmin/unlockSubmissions',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.LZAdmin, roles.Qa, roles.Developer, roles.DevOps, roles.Implementations])
    }
  }
]
