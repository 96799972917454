<template>
  <img :src="avatar" />
</template>

<script>
// Third Party
import axios from 'axios'

// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'Avatar',
  props: {
    userId: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['getAvatarCache', 'getBaseAvatarUrl', 'getUserId'])
  },
  data () {
    return {
      avatar: null
    }
  },
  mounted () {
    this.getAvatar()
  },
  watch: {
    async getAvatarCache () {
      if (!this.userId) {
        await this.getAvatar()
      }
    }
  },
  methods: {
    async getAvatar () {
      var url
      if (this.userId) {
        url = `${this.getBaseAvatarUrl}${this.userId}`
      } else {
        url = `${this.getBaseAvatarUrl}${this.getUserId}${this.getAvatarCache}`
      }

      var response = await axios.get(url)
      let appender = Math.random()
      this.avatar = `data:image/jpg;base64,${response.data}`
    }
  }
}
</script>

<style>
</style>
