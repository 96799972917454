<template>
    <div>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            <help-icon docPath="/paygate-collections/messaging/assigntemplates/" />Messaging Opt Out
            <favourite-icon></favourite-icon>
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="row form-group" v-if="false">
            <div class="col-md-2 required">Select a Group</div>
            <div class="col-md-4">
              <group-select
                v-model="optOutGroup"
                :groups="customerGroups"
                @input="groupChange"
                :clearable="false"
              ></group-select>
            </div>
          </div>
          <table v-if="optOutEntries.length > 0" class="table table-striped">
            <thead>
              <th scope="col">Message Type</th>
              <th scope="col">Opted Out?</th>
            </thead>
            <tbody>
              <tr v-for="(entry, i) in optOutEntries" :key="i">
                <td>{{entry.title}}</td>
                <td><p-check
                  class="p-switch p-fill"
                  color="primary"
                  v-model="entry.optOut"
                ></p-check></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="optOutEntries.length >0" class="idb-block-footer">
         <b-button
            variant="primary"
            @click.prevent="save"
          ><i class="fa fa-save mr-2"></i>Save
          </b-button>
        </div>
      </div>
    </div>
</template>
<script>
  import axios from 'axios'
  import EventBus from '@/Lib/eventBus'
  import {mapGetters} from "vuex";
  import _ from "lodash";
  import roles from "@/Assets/Constants/roles";
  export default {
    data () {
      return {
        optOutGroup: '00000000-0000-0000-0000-000000000000',
        optOutEntries: [],
        loaded: true
      }
    },
    methods: {
      formatTitle (val) {
        return val.replace('Payer','Customer').replace('Payment','Collection')
      },
      async loadGroups (paygateId) {
        const paygateIdNotDefault = (paygateId && paygateId !== '00000000-0000-0000-0000-000000000000')
        if (paygateIdNotDefault) {
          await this.$store.dispatch('loadCustomerGroups', paygateId)
        }
        this.group = '00000000-0000-0000-0000-000000000000'
      },
      async groupChange (group) {
        if (group !== null && group !== '00000000-0000-0000-0000-000000000000') {
          const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}optout/${group}`, { showload: true, params: { paygateId: this.paygateId } })
          this.optOutEntries = response.data
          this.filterTypes()
          for(var i = 0; i < this.optOutEntries.length; i++) {
            this.optOutEntries[i].title = this.formatTitle(this.optOutEntries[i].title);
          }
          this.optOutEntries.sort((a,b) => (a.title > b.title) ? 1 : -1)
          this.loaded = true
        } else {
          this.optOutEntries = []
        }
      },
      async load () {
          const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}optout/`, { showload: true, params: { paygateId: this.paygateId } })
          this.optOutEntries = response.data
          this.filterTypes()
          for(var i = 0; i < this.optOutEntries.length; i++) {
            this.optOutEntries[i].title = this.formatTitle(this.optOutEntries[i].title);
          }
          this.optOutEntries.sort((a,b) => (a.title > b.title) ? 1 : -1)
          this.loaded = true
      },
      async save () {
        try {
          var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}optout/`, this.optOutEntries, { params: { paygateId: this.paygateId } })
          this.optOutEntries = response.data
          this.filterTypes()
          for(var i = 0; i < this.optOutEntries.length; i++) {
            this.optOutEntries[i].title = this.formatTitle(this.optOutEntries[i].title);
          }
          this.optOutEntries.sort((a,b) => (a.title > b.title) ? 1 : -1)
          this.$toastr.s('Saved')
        } catch (e) {
          this.$toastr.e('Failed to save opt out choices.')
        }
      },
      filterTypes () {
        // As some message types aren't availble or suitable in JFTP, do this.
        let messageTypesFilter = [
          1,  // First Payment
          2,  // Multiple Schedule Update
          8,  // Email Verification
          12, // Cancellation Notice
          15, // Payer Transferred
          //19  // Confirmation Letter
        ]

        if(!this.hasPayerPortal) {
          messageTypesFilter.push(9) // Portal Message Sent
          messageTypesFilter.push(10) // Portal Email Verification
        }
        if(!this.hasRefunds) {
          messageTypesFilter.push(11) // Refund Requested
          messageTypesFilter.push(17) // Refund Cancelled
        }
        if(!this.allowSms) {
          messageTypesFilter.push(20) // pre collection notification
        }
        this.optOutEntries = this.optOutEntries.filter(function (obj) { return !messageTypesFilter.includes(obj.messageType) })
      }

    },
    computed: {
      ...mapGetters(['selectedCustomer']),
      hasRefunds () {
        return this.$store.getters.hasLicence('LicenceCollectionsRefunds') && !this.$store.getters.isInRole(roles.SystemUser)
      },
      paygateId () {
        return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
      },
      customerGroups () {
        var ret = []
        if (this.$store.getters.rightsGroups !== null) {
          ret = _.cloneDeep(this.$store.getters.rightsGroups).map(x => {
            let obj = {}
            obj.groupId = x.groupId
            obj.name = x.name
            obj.colour = x.colour
            return obj
          })
        }
        return ret
      },
      groupSelected () {
        return this.optOutGroup !== '00000000-0000-0000-0000-000000000000'
      },
      hasPayerPortal () {
        return this.$store.getters.hasLicence('LicenceCollectionsPayerPortal')
      },
      allowSms () {
        return parseInt(process.env.VUE_APP_VERSION) >=  130
      }
    },
    async mounted () {
      await this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      await this.load()
    },
    watch: {
      selectedCustomer () {
        this.load()
      }
    }
  }
</script>
