<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Edit Default Templates</h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'email'">
                <router-link :to="{ name: 'DefaultEmailDesigner', params: { type: props.row.messagetype } }" v-if="props.row.messagetype !== 20">
                  <!-- eslint-disable-line -->
                  <span>Edit</span>
                </router-link>
                <span v-else>-</span>
              </span>
            <span v-else-if="props.column.field == 'letter'">
                <router-link :to="{ name: 'DefaultLetterDesigner', params: { type: props.row.messagetype } }" v-if="props.row.messagetype !== 20">
                  <!-- eslint-disable-line -->
                  <span>Edit</span>
                </router-link>
              <span v-else>-</span>
              </span>
            <span v-else-if="props.column.field === 'sms'">
                <router-link :to="{ name: 'DefaultSmsDesigner', params: { type: props.row.messagetype } }" v-if="props.row.messagetype !== 0 && props.row.messagetype !== 19 && props.row.messagetype !== 18">
                  <!-- eslint-disable-line -->
                  <span>Edit</span>
                </router-link>
                <span v-else>-</span>
              </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
          <div slot="table-actions">

            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import Datepicker from 'vuejs-datepicker'
import papa from 'papaparse'
import moment from 'moment'
import {mapGetters} from "vuex";

export default {
  mixins: [tableFilterMixin, loading],
  components: {
    Datepicker
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Type',
          field: 'messagetype',
          formatFn: (x) => {
            switch (x) {
              case 20:
                return 'Pre-Collection Notification'
              case 19:
                return 'Confirmation Letter'
              case 18:
                return 'Notice of Change (Migration)'
              case 17:
                return 'Refund Cancelled'
              case 16:
                return 'Notice of Change'
              case 15:
                return 'Customer Transferred'
              case 14:
                return 'Customer Closed'
              case 13:
                return 'Custom'
              case 12:
                return 'Cancellation Notice'
              case 11:
                return 'Refund Requested'
              case 10:
                return 'Portal Email Verification'
              case 9:
                return 'Portal Message Sent'
              case 8:
                return 'Email Verification Message'
              case 7:
                return 'Custom Message 5'
              case 6:
                return 'Custom Message 4'
              case 5:
                return 'Custom Message 3'
              case 4:
                return 'Custom Message 2'
              case 3:
                return 'Custom Message 1'
              case 2:
                return 'Multiple Schedule Update'
              case 1:
                return 'First Collections'
              case 0:
                return 'Advance Notice'
              default:
                return 'Unknown'
            }
          }
        },
        {
          label: 'Last Modified',
          field: 'lastModified'
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Letter',
          field: 'letter'
        },
        {
          label: 'Sms',
          field: 'sms',
          hidden: true
        }
      ],
      serverParams: {
        sort: [{ field: 'templateName', type: 'desc' }]
      }
    }
  },
  mounted () {
    this.end = new Date()
    let tempStart = new Date()
    tempStart.setDate(this.end.getDate() - 1)
    this.start = tempStart
    let tempEnd = new Date(this.end.setMonth(this.end.getMonth()+1));
    this.end = tempEnd
    this.load()
  },
  methods: {
    async filtersChanged () {
      await this.clearTableFilters()
    },
    load: async function () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}defaultmessages`, { params: { ...this.buildGoodTableQuery() }, validateStatus: () => true })
        if (response.status === 200) {
          this.rows = response.data.data
          this.totalRecords = response.data.count
        } else {
          if (response.status === 403) {
            this.$toastr.e('Not authorized to use this feature.')
            this.rows = []
            this.totalRecords = 0
          } else {
            this.$toastr.e('An error has occurred.')
          }
        }
      } catch (error) {
        this.$toastr.e(error)
      }
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    formatAmount (value) {
      return '£' + this.numberWithCommas((value / 1).toFixed(2))
    },
    formatStatus (value) {
      let retVal = ''
      switch(value) {
        case 1:
          retVal = 'Created'
          break
        case 2:
          retVal = 'In Progress'
          break
        case 3:
          retVal = 'Resolved'
          break
        case 4:
          retVal = 'Rejected'
          break
        case 5:
          retVal = 'Action Required'
          break
        default:
          retVal = 'None'
          break
      }
      return retVal
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    formatDateWithNull (date) {
      if (date===null || date === '0001-01-01T00:00:00') {
        return 'N/A'
      } else {
        return this.formatDate(date)
      }
    },
    async exportTable () {
      var query = this.buildGoodTableQuery()
      var startDate = moment(this.start).format('DD-MM-YYYY')
      var endDate = moment(this.end).format('DD-MM-YYYY')
      query.perPage = this.totalRecords
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}defaultmessages`, { params: {
          startDate: this.start, endDate: this.end,
          ...query, paygateId: this.paygateId, nearingCompletion: this.nearingCompletion, forExport: true, filterBy: this.selectedFilter }, showload: true })
      var csvString = papa.unparse(response.data)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, `collections-schedule-report-${startDate}-to-${endDate}.csv`)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = `collections-schedule-report-${startDate}-to-${endDate}.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    codeToDescription (value) {
      let retval = ''
      switch (value) {
        case 1:
          retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
          break;
        case 2:
          retval = 'NO ADVANCE NOTICE WAS RECEIVED';
          break;
        case 3:
          retval = 'BANK CANCELS THE DIRECT DEBIT';
          break;
        case 4:
          retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
          break;
        case 5:
          retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
          break;
        case 6:
          retval = 'FRAUDULENT SIGNATURE';
          break;
        case 7:
          retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
          break;
        case 8:
          retval = 'SERVICE USER IS NOT RECOGNISED';
          break;
        default:
          retval = 'Invalid Code Supplied'
          break;
      }
      return retval
    },
    recurrenceEndTypeText (val) {
      var outText = ''
      switch (val) {
        case 0:
          outText = 'One Off'
          break
        case 1:
          outText = 'Until Date'
          break
        case 2:
          outText = 'Until Number of Collections'
          break;
        case 3:
          outText = 'Ongoing'
          break
        case 4:
          outText = 'Ad Hoc'
          break
      }
      return outText
    },
    planStatusText (val) {
      var outText = ''
      switch (val) {
        case 0:
          outText = 'Closed'
          break
        case 1:
          outText = 'Alert'
          break
        case 2:
          outText = 'Suspended'
          break
        case 3:
          outText = 'Operational'
          break
      }
      return outText
    },
    recurrenceTypeText (val) {
      var outText = ''
      switch (val) {
        case 0:
          outText = 'Weekly'
          break
        case 1:
          outText = 'Fortnightly'
          break
        case 2:
          outText = 'Four Weekly'
          break
        case 3:
          outText = 'Monthly'
          break
        case 4:
          outText = 'Two Monthly'
          break
        case 5:
          outText = 'Quarterly'
          break
        case 6:
          outText = 'Six Monthly'
          break
        case 7:
          outText = 'Yearly'
          break
      }
      return outText
    },
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    startDateOptions () {
      return { ...this.dateOptions, maxDate: this.end }
    },
    endDateOptions () {
      return { ...this.dateOptions, minDate: this.start }
    },
    smsAllowed () {
      return parseInt(process.env.VUE_APP_VERSION) >=  130
    }
  },
  watch: {
    selectedCustomer () {
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      /*this.load()*/
    }
  }
}
</script>
<style>
.deleted * {
  color: #cccccc;
}
.suppressed * {
  color: #cccccc;
}
.alert td.amountField {
  color: red !important;
  font-weight: bold;
}
#firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
