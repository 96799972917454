<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        Log Viewer
        <favourite-icon></favourite-icon>
      </h2>
    </div>
    <div class="idb-block-content">
      <div class="row form-group">
        <div class="col-md-3">
          <p-check
            class="p-switch p-fill"
            color="primary"
            v-model="allCustomers"
            @change="selectedModuleChanged"
          >Include all customers</p-check>
        </div>
        <div class="col-md-3">
          <p-check
            class="p-switch p-fill"
            color="primary"
            v-model="autoRefresh"
            @change="toggleTimer"
          >Auto Refresh (30 seconds)</p-check>
        </div>
        <div class="col-md-3">
          <p-check
            class="p-switch p-fill"
            color="primary"
            v-model="showAdvanced"
            data-toggle="collapse"
            data-target="#advancedCollapse"
          >Advanced</p-check>
        </div>
        <div class="container-fluid mt-2 collapse" id="advancedCollapse">
          <div class="card card-body">
            <div class="row form-group">
              <div class="col-md-2 required">Select a Module</div>
              <div class="col-md-3">
                <vue-select
                  v-model="selectedModule"
                  :options="sections.map(x=>x.label)"
                  multiple
                  :select-size="4"
                  @input="selectedModuleChanged"
                />
              </div>
              <div class="col-md-3">
                <b-button @click="setModule(true)" variant="outline-secondary">
                  <i class="fas fa-check"></i> Select All
                </b-button>
                <b-button @click="setModule(false)" variant="outline-danger" class="ml-2">
                  <i class="fas fa-times"></i> Clear
                </b-button>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2 required">Log Level</div>
              <div class="col-md-3">
                <vue-select
                  v-model="logLevel"
                  :options="logLevels.map(x=>x.label)"
                  multiple
                  :select-size="4"
                  @input="selectedModuleChanged"
                />
              </div>
              <div class="col-md-3">
                <b-button @click="setLevels(true)" variant="outline-secondary">
                  <i class="fas fa-check"></i> Select All
                </b-button>
                <b-button @click="setLevels(false)" variant="outline-danger" class="ml-2">
                  <i class="fas fa-times"></i> Clear
                </b-button>
              </div>
            </div>
            <p class="card-text">Date Filtering Options</p>
            <div class="form-group row">
              <div class="col-md-2">
                <p-check class="p-switch p-fill" color="primary" v-model="useStartDate">Start Date</p-check>
              </div>
              <!-- Start DateTime -->
              <div class="col-md-3" v-if="useStartDate">
                <div class="input-group date">
                  <date-picker
                    v-model="start"
                    :config="startDateOptions"
                    :wrap="true"
                    @input="selectedModuleChanged"
                    :disabled="!useStartDate"
                  ></date-picker>
                  <div class="input-group-addon">
                    <span class="far fa-calendar"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-2">
                <p-check class="p-switch p-fill" color="primary" v-model="useEndDate">End Date</p-check>
              </div>
              <!-- End DateTime -->
              <div class="col-md-3" v-if="useEndDate">
                <div class="input-group date">
                  <date-picker
                    v-model="end"
                    :disabled="!useEndDate"
                    :config="endDateOptions"
                    :wrap="true"
                    @input="selectedModuleChanged"
                  ></date-picker>
                  <div class="input-group-addon">
                    <span class="far fa-calendar"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="output"
        mode="remote"
        styleClass="vgt-table striped bordered"
        ref="table"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        :lineNumbers="true"
        :totalRows="totalRecords"
        :search-options="{
                    enabled: true
                    }"
        :paginationOptions="paginationOptions"
        :sort-options="sortOptions"
        :isLoading.sync="isTableLoading"
      >
        <div slot="emptystate">
          <span style="text-align: center;">No entries have been found matching the criteria.</span>
        </div>
        <div slot="table-actions">
          <button
            @click.prevent="clearTableFilters"
            class="btn btn-link"
            v-b-popover.hover.top.d500="'Clear filters'"
          >
            <span class="fa-stack" style="font-size: 10px;">
              <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
              <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
            </span>
          </button>
          <b-button
            @click.prevent="load"
            :disabled="isLoading"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Refresh the data in the table'"
          >
            <i class="fa fa-sync pointer dimmedIcon"></i>
          </b-button>
          <b-button
            @click.prevent="exportTable"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Export the contents of the table (limited to 500 rows)'"
          >
            <i class="fas fa-file-export pointer dimmedIcon"></i>
          </b-button>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'level'">
            <b-badge
              pill
              :variant="getSeverityClass(props.row.level)"
            >{{props.formattedRow[props.column.field]}}</b-badge>
          </span>
          <span v-else-if="props.column.field == 'message'">
            <div v-html="props.formattedRow[props.column.field]"></div>
          </span>
          <span v-else>{{props.formattedRow[props.column.field]}}</span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import datePicker from '@/Assets/Components/DateTimePicker/DatePicker'
import papa from 'papaparse'
import VueSelect from 'vue-select'
import moment from 'moment'
export default {
  mixins: [tableFilterMixin, loading],
  components: {
    datePicker, VueSelect
  },
  mounted () {
    this.end = new Date()
    const tempStart = new Date()
    tempStart.setDate(this.end.getDate() - 1)
    this.start = tempStart
    if (this.autoRefresh) {
      this.logInterval = setInterval(() => {
        this.load()
      }, 30000)
    }
  },
  beforeDestroy () {
    if (this.logInterval && this.logInterval !== null) {
      clearInterval(this.logInterval)
      this.logInterval = null
    }
  },
  data () {
    return {
      sections: [
        { value: 'Agent', label: 'Agent' },
        { value: 'BACS', label: 'BACS' },
        { value: 'Bureau', label: 'BACS Bureau' },
        { value: 'Collections', label: 'Collections' },
        { value: 'Identity Server', label: 'Identity Server' },
        { value: 'Platform', label: 'Platform' },
        { value: 'Validate', label: 'Validate' },
        { value: 'Workflow', label: 'Workflow' }
      ],
      selectedModule: ['Agent', 'BACS', 'BACS Bureau', 'Collections', 'Identity Server', 'Platform', 'Validate', 'Workflow'],
      output: [],
      logging: true,
      logInterval: null,
      logLevels: [
        // { value: 'All', text: 'All' },
        { value: 'CRITICAL', label: 'Critical' },
        { value: 'ERROR', label: 'Error' },
        { value: 'WARN', label: 'Warning' },
        { value: 'INFO', label: 'Information' },
        { value: 'DEBUG', label: 'Debug' },
        { value: 'TRACE', label: 'Trace' }
      ],
      logLevel: ['Critical', 'Error', 'Warning'],
      start: null,
      end: null,
      columns: [
        {
          label: 'Module',
          field: 'logger',
          tdClass: 'text-center',
          thClass: 'text-center',
          width: '100px'
        },
        {
          label: 'Time',
          field: 'timestamp',
          tdClass: 'text-left',
          thClass: 'text-left',
          formatFn: this.formatDate,
          width: '200px'
        },
        {
          label: 'Level',
          field: 'level',
          tdClass: 'text-center',
          thClass: 'text-center',
          width: '100px'
        },
        {
          label: 'Detail',
          field: 'message',
          formatFn: this.formatMsg
        }
      ],
      serverParams: {
        sort: [{ field: 'timestamp', type: 'desc' }]
      },
      paginationOptions: {
        perPageDropdown: [10, 20, 30, 40, 50],
        dropdownAllowAll: false,
        enabled: true,
        perPage: 10,
        setCurrentPage: 1
      },
      dateOptions: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        stepping: 30,
        sideBySide: true
      },
      allCustomers: false,
      autoRefresh: false,
      useStartDate: false,
      useEndDate: false,
      showAdvanced: false
    }
  },
  computed: {
    paygateId () {
      return this.$store.getters.selectedCustomer
    },
    startDateOptions () {
      var maxDate = this.useEndDate ? this.end : moment().endOf('day')
      return { ...this.dateOptions, maxDate }
    },
    endDateOptions () {
      var minDate = this.useStartDate ? this.start : false
      return { ...this.dateOptions, minDate, maxDate: moment().endOf('day') }
    },
    ...mapGetters(['selectedCustomer'])
  },
  methods: {
    formatMsg (message) {
      return message.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      const day = date.getDate()
      const monthIndex = date.getMonth() + 1
      const year = date.getFullYear()
      const hours = date.getHours()
      const minutes = date.getMinutes()
      const seconds = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year} ${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`
    },
    pad (num, size) {
      const s = '00' + num
      return s.substr(s.length - size)
    },
    getSeverityClass (value) {
      let retVal = ''
      switch (value.toUpperCase()) {
        case 'ERROR':
          retVal = 'danger'
          break
        case 'WARN':
          retVal = 'warning'
          break
        case 'DEBUG':
          retVal = 'secondary'
          break
        default:
          retVal = 'info'
          break
      }
      return retVal
    },
    async selectedModuleChanged () {
      this.$refs.table.reset()
      this.updateParams({ page: 1 })
    },
    getModuleValues () {
      const output = []
      for (let m = 0; m < this.selectedModule.length; m++) {
        for (let i = 0; i < this.sections.length; i++) {
          if (this.sections[i].label === this.selectedModule[m]) {
            output.push(this.sections[i].value)
          }
        }
      }
      return output
    },
    getLevelValues () {
      const output = []
      for (let m = 0; m < this.logLevel.length; m++) {
        for (let i = 0; i < this.logLevels.length; i++) {
          if (this.logLevels[i].label === this.logLevel[m]) {
            output.push(this.logLevels[i].value)
          }
        }
      }
      return output
    },
    load: _.debounce(async function () {
      console.log(this.buildGoodTableQuery())
      console.log('loading log')
      this.isTableLoading = true
      // let response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Logging/${this.selectedModule}/log`, { headers: { 'x-cust-meta': this.$store.state.common.paygateId }, params: { ...this.buildGoodTableQuery(), start: this.start, end: this.end, level: this.logLevel.join('|'), paygateid: this.paygateId, allCustomers: this.allCustomers }, showload: true })
      const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Logging/log`, { headers: { 'x-cust-meta': this.$store.getters.selectedCustomer }, params: { ...this.buildGoodTableQuery(), start: this.useStartDate ? this.start : null, end: this.useEndDate ? this.end : null, level: this.getLevelValues().join('|'), paygateid: this.paygateId, allCustomers: this.allCustomers, section: this.getModuleValues().join('|') }, showload: true })
      this.output = response.data.data
      this.totalRecords = response.data.totalRecords
      this.isTableLoading = false
    }, 300),
    async exportTable () {
      // let response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Logging/${this.selectedModule}/log`, { headers: { 'x-cust-meta': this.$store.state.common.paygateId }, params: { ...query, start: this.start, end: this.end, level: this.logLevel.join('|'), paygateid: this.paygateId, allCustomers: this.allCustomers }, showload: true })
      const query = this.buildGoodTableQuery()
      query.perPage = 500
      query.page = 1
      const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Logging/log`, { headers: { 'x-cust-meta': this.$store.getters.selectedCustomer }, params: { ...query, start: this.useStartDate ? this.start : null, end: this.useEndDate ? this.end : null, level: this.getLevelValues().join('|'), paygateid: this.paygateId, allCustomers: this.allCustomers, section: this.getModuleValues().join('|') }, showload: true })
      const exportArr = []
      for (let i = 0; i < response.data.data.length; i++) {
        var v = response.data.data[i]
        exportArr.push({ module: v.logger, time: v.timestamp, level: v.level, detail: v.message.replace(/[\n\r]+/g, '').trim() })
      }
      var csvString = papa.unparse(exportArr)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, this.selectedModule + '-log.csv')
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = this.selectedModule + '-log.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    toggleTimer () {
      if (this.autoRefresh) {
        if (!this.logInterval || this.logInterval === null) {
          this.logInterval = setInterval(() => {
            this.load()
          }, 30000)
        }
      } else {
        if (this.logInterval && this.logInterval !== null) {
          clearInterval(this.logInterval)
          this.logInterval = null
        }
      }
    },
    setLevels (selectAll) {
      this.logLevel = selectAll ? ['Critical', 'Error', 'Warning', 'Information', 'Debug', 'Trace'] : []
      this.selectedModuleChanged()
    },
    setModule (selectAll) {
      this.selectedModule = selectAll ? ['Agent', 'BACS', 'BACS Bureau', 'Collections', 'Identity Server', 'Platform', 'Validate', 'Workflow'] : []
      this.selectedModuleChanged()
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  }
}
</script>
