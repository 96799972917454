<template>
  <div class="pb-2 mb-4">
    <div class="idb-block mb-4">
      <div class="idb-block-title">
        <h2>
          <help-icon docPath="/paygate-collections/payers/editpayer/" />Edit Customer
        </h2>
      </div>
      <div>
        <b-card
          v-if="status === 5 && emailVerified && canActivatePayer"
          bg-variant="warning"
          text-variant="white"
          style="margin-bottom: 0.8em; margin-right: 0.8em"
        >
          <i class="fa fa-exclamation-triangle rpad"></i> Activation Required
          <b-btn variant="outline-light" @click="activateOnboarded">Activate</b-btn>
        </b-card>
        <b-card
          v-if="status === 5 && !emailVerified && canActivatePayer"
          bg-variant="warning"
          text-variant="white"
          style="margin-bottom: 0.8em; margin-right: 0.8em"
        >
          <i class="fa fa-exclamation-triangle rpad"></i> Email Verification Required - Cannot activate customer without Email Verification.
        </b-card>
        <b-tabs v-model="currentTab" nav-wrapper-class="no-print-tabs">
          <b-tab :active="!openAt" title="Main">
            <template slot="title">
              Main
              <i
                v-if="!statusValidated"
                class="missing-fields fa fa-exclamation-triangle"
                aria-hidden="true"
                title="Status Invalid"
              ></i>
            </template>
            <template v-if="loadingPayer">
              <loadingControl />
            </template>
            <template v-else>
              <b-form-row>
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  label-class="required"
                  horizontal
                  label="Group"
                  label-for="groupInput"
                >
                  <b-form-input id="groupInput" v-model="groupName" type="text" disabled />
                </b-form-group>
              </b-form-row>
              <b-form-row>
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  label-class="required"
                  horizontal
                  label="Customer Reference" label-for="payerReferenceInput"
                >
                  <b-form-input
                    id="payerReferenceInput"
                    type="text"
                    class="form-control"
                    required
                    v-model.trim="$v.payerReference.$model"
                    :disabled="true"
                  />
                </b-form-group>
              </b-form-row>
              <b-form-row>
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  label-class="required"
                  horizontal
                  label="Customer Status"
                  label-for="payerStatus"
                >
                  <b-form-select
                    id="payerStatus"
                    v-model.number="status"
                    :options="statae"
                    text-field="name"
                    value-field="value"
                    @change="removeExistingStatus"
                    :disabled="!editable || (status === 5 && !emailVerified) || status === 8 || !canEdit || clientIsInactive"
                  />
                </b-form-group>
              </b-form-row>
              <b-form-row v-if="false">
                <b-form-group :label-cols="5" class="col-sm-6" horizontal label-for="merchantNameInput" label="Client Name">
                  <b-form-input
                    type="text"
                    class="form-control"
                    v-model="merchantName"
                    :disabled="true"
                    id="merchantNameInput"
                  />
                </b-form-group>
              </b-form-row>
              <b-form-row v-if="connectedMerchantName !== merchantName">
                <b-form-group :label-cols="5" class="col-sm-6" horizontal label-for="parentCoustomerInput" label="Parent Client">
                  <b-form-input
                    type="text"
                    class="form-control"
                    v-model="connectedMerchantName"
                    :disabled="true"
                    id="parentCoustomerInput"
                  />
                </b-form-group>
              </b-form-row>
              <b-form-row v-if="status === 1 || status === 3">
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  label-class="required"
                  horizontal
                  :label="status === 1 ? 'Suspension Reason' : 'Cancellation Reason'"
                  label-for="suspensionReasonInput"
                >
                  <b-form-input
                    v-model="suspensionReason"
                    type="text"
                    id="suspensionReasonInput"
                    class="form-control"
                    :disabled="!editable || !canEdit || clientIsInactive"
                  />
                  <b-col v-if="reasonNeeded">
                    <label v-if="status === 1"
                           id="suspensionReasonPositiveLabel"
                           class="text-danger small"
                    >Please supply a suspension reason.</label>
                    <label v-if="status === 3"
                           id="suspensionReasonPositiveLabel"
                           class="text-danger small"
                    >Please supply a cancellation reason.</label>
                  </b-col>
                </b-form-group>
              </b-form-row>
              <b-form-row>
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  label-class="required"
                  horizontal
                  label="Message Channel"
                  label-for="messageChannelInput"
                >
                  <b-form-select
                    id="messageChannelInput"
                    v-model="messageChannel"
                    :options="allowedMessageChannels"
                    text-field="name"
                    value-field="value"
                    :disabled="!editable || !canEdit"
                    @change="showPreview ? getPreview() : null"
                  />
                  <small v-if="messageChannel === 2">
                    Initial confirmation messages will be sent by
                    <strong>{{ groupConfig ? (groupConfig.initialMessageFallbackType === 0 ? 'email' : 'letter') : 'unknown' }}</strong> to adhere to scheme rules.
                  </small>
                </b-form-group>
              </b-form-row>
              <b-form-row>
                <b-form-group :label-cols="5" class="col-sm-6" horizontal label-for="sourceInput" label="Source">
                  <b-form-input :value="payerSource" id="sourceInput" type="text" disabled />
                </b-form-group>
              </b-form-row>
              <b-form-row>
                <b-form-group :label-cols="5" class="col-sm-6" horizontal label-for="activationInput" label="Activation Date">
                  <b-form-input :value="formatDate(activationDate)" id="activationInput" type="text" disabled />
                </b-form-group>
              </b-form-row>
              <b-form-row v-if="auddis0nSent">
                <b-form-group :label-cols="5" class="col-sm-6" horizontal label-for="lodgedDateInput" label="Lodged Date">
                  <b-form-input :value="lodgedDate" type="text" id="lodgedDateInput" disabled />
                </b-form-group>
              </b-form-row>
              <b-form-row>
                <b-form-group :label-cols="5" class="col-sm-6" horizontal label-for="businessCheck" label="Customer is a Business">
                  <p-check
                    id="businessCheck"
                    class="p-switch p-fill"
                    color="primary"
                    v-model="isBusiness"
                    :disabled="!editable || !canEdit"

                  ></p-check>
                </b-form-group>
              </b-form-row>
              <b-form-row v-if="hasPayerPortal">
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  horizontal
                  :label="(payerPortalId === undefined || payerPortalId === null) ? 'Create Portal Account' : 'Resend Password'"
                >
                  <b-button
                    v-if="payerPortalId === null"
                    :disabled="isLoading"
                    variant="secondary"
                    @click="createPortalAccount"
                  >Create</b-button>
                  <b-button
                    v-if="payerPortalId !== null"
                    :disabled="isLoading"
                    variant="secondary"
                    @click="resendPortalCode"
                  >Resend Code</b-button>
                </b-form-group>
              </b-form-row>
            </template>
          </b-tab>
          <b-tab :active="openAt === 'payerDetails'">
            <template slot="title">
              Customer Details
              <i
                v-if="(payerStagingDetails !== undefined && !payerStagingDetails.payerDetailsValidated) || !payerDetailsValid"
                class="missing-fields fa fa-exclamation-triangle"
                aria-hidden="true"
                :title="detailsValidationMessage"
              ></i>
            </template>
            <payer-details-input @can-continue="detailsValid" :ukPayerId="ukPayerId" ref="detailsInput"></payer-details-input>
          </b-tab>
          <b-tab :active="openAt === 'paymentPlans' || openAt === 'schedules'" @click="getPayerAdvNoticeDetails">
            <template slot="title">
              Schedules
              <i
                v-if="(payerStagingDetails !== undefined && !payerStagingDetails.paymentPlansValidated) || !currentPlanValid"
                class="missing-fields fa fa-exclamation-triangle"
                aria-hidden="true"
                :title="plansValidationMessage"
              ></i>
            </template>
            <b-row class="planRow">
              <b-tabs
                vertical
                nav-wrapper-class="col-3 vertical-tab-container"
                nav-class="w-100 vertical-tab-list"
                v-model="selectedPlanIndex"
              >
                <template v-slot:tabs-end>
                  <b-nav-item v-if="!planLimitHit && canEdit" href="#">
                    <b-form-select :disabled="!canEdit"
                      class="form-control w-100"
                      @keyup.enter="addNewPaymentPlan"
                      @change="addNewPaymentPlan"
                      v-model.trim="$v.newPlanReference.$model"
                      type="text"
                      placeholder="New schedule name..."
                      :options="planNames"
                      text-field="name"
                      value-field="name"
                    >
                      <!-- This slot appears above the options from 'options' prop -->
                      <template slot="first">
                        <option :value="null" disabled>Add a Schedule</option>
                      </template>
                    </b-form-select>
                  </b-nav-item>
                  <b-nav-item :to="{ path:`/collections/reporting/schedules/${ukPayerId}` }">
                    <router-link
                      :to="{ path:`/collections/reporting/schedules/${ukPayerId}` }"
                    >Summary Report</router-link>
                  </b-nav-item>
                  <b-nav-item :to="{ path:`/collections/customerschedule/${ukPayerId}` }">
                    <router-link
                      :to="{ path:`/collections/customerschedule/${ukPayerId}` }"
                    >Merged Schedule Report</router-link>
                  </b-nav-item>
                </template>
                <b-tab
                  v-for="(paymentPlan, index) in paymentPlans"
                  :key="index"
                  :title="paymentPlan.planTemplateName"
                  >
                  <payment-plan-input
                    :plan="paymentPlan"
                    v-if="paymentPlan !== null && paymentPlan !== undefined"
                    :ukPayerId="ukPayerId"
                    :planClosed="isClosed || selectedPlanClosed"
                    @planDeleted="deletePlan(index)"
                    :showDeleteButton="canEdit"
                    :deleteDisabled="paymentPlans.length === 1"
                    :canCreateOwnReference="canSetPlanReference"
                    :hasDdi="auddis0nSent"
                    :isIncomplete="status === 4"
                  ></payment-plan-input>
                </b-tab>
                <template slot="empty">There are no schedules associated with this customer.</template>
              </b-tabs>
            </b-row>
          </b-tab>
          <b-tab :active="openAt === 'bankDetails'" v-if="canViewBanking">
            <template slot="title">
              Bank Details
              <i
                v-if="payerStagingDetails !== undefined && !payerStagingDetails.bankAccountValidated"
                class="missing-fields fa fa-exclamation-triangle"
                aria-hidden="true"
                :title="bankingValidationMessage"
              ></i>
              <i
                v-if="!isLoading && !validModulus"
                class="missing-fields fa fa-exclamation-triangle"
                aria-hidden="true"
                title="Bank details failed modulus check"
              ></i>
            </template>
            <bank-account-details-input :showIntro="false"></bank-account-details-input>
          </b-tab>
          <b-tab title="Alerts" v-if="canViewAlerts" :active="openAt === 'alerts'" @click="reloadAlerts">
            <payer-alerts ref="payerAlerts" :canLoad="!loadingPayer" :reference="$v.payerReference.$model" :ukPayerId="ukPayerId"></payer-alerts>
          </b-tab>
          <b-tab title="Customer History" @click="reloadHistory" :active="openAt === 'payerHistory'" >
            <payer-history ref="payerHistory" :canLoad="!loadingPayer" :ukPayerId="ukPayerId"></payer-history>
          </b-tab>
          <b-tab title="Notes" @click="reloadNotes" :active="openAt === 'notes'">
            <payer-notes ref="payerNotes" :canLoad="!loadingPayer" :ukPayerId="ukPayerId"></payer-notes>
          </b-tab>
          <b-tab title="Send Template Message" :active="openAt === 'messages'" v-if="canSendMessages" >
            <b-row class="mb-2">
              <b-col md="3">Send Message to Customer</b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="3">
                <b-form-select v-model="sendMessagePlan" :options="paymentPlanSelectOptions"
                               @change="getPreview()">
                  <template slot="first">
                    <option :value="null" disabled>Please select a schedule</option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col>
                <b-form-select
                  v-model="sendMessageType"
                  :options="sendMessagePlan && sendMessagePlan === '00000000-0000-0000-0000-000000000000' ? coreReferenceMessageTypesSelect : allowedMessageTypesSelect"
                  value-field="value"
                  text-field="title"
                  @change="getPreview()" :disabled="sendMessagePlan === null"
                >
                  <template slot="first">
                    <option :value="null" v-if="(sendMessagePlan && sendMessagePlan === '00000000-0000-0000-0000-000000000000' ? coreReferenceMessageTypesSelect : allowedMessageTypesSelect).length > 0" disabled>Please select a message type</option>
                    <option :value="null" v-if="(sendMessagePlan && sendMessagePlan === '00000000-0000-0000-0000-000000000000' ? coreReferenceMessageTypesSelect : allowedMessageTypesSelect).length === 0" disabled>No message types available</option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col>
                <b-btn
                  variant="primary"
                  :disabled="sendMessageLoading || (sendMessageType === null || sendMessagePlan === null || isLoading) || !canSend"
                  @click.prevent="sendMessage(sendMessageType)"
                >
                  <i class="fa fa-envelope rpad"></i>Send
                </b-btn>
              </b-col>
              <b-col>
                <p-check
                  id="previewCheck"
                  class="p-switch p-fill"
                  color="primary"
                  v-model="showPreview"
                  @change="getPreview()"
                >Show Preview</p-check>
              </b-col>
              <b-col v-if="isLzAdminRole">
                <p-check
                  id="previewCheck"
                  class="p-switch p-fill"
                  color="primary"
                  v-model="forceMessage"
                >Force Send</p-check>
                <br/><small>Allow Confirmation Letters and Customer Closed messages to be sent more than once.</small>
              </b-col>
            </b-row>
            <b-form-row v-if="sendMessageType === 16 || sendMessageType === 18">
              <b-form-group :label-cols="5" class="col-sm-6" horizontal label-class="required" label-for="parentCoustomerInput" label="Old Service User Name">
                <b-form-input
                  type="text"
                  class="form-control"
                  v-model="oldName"
                  id="parentCoustomerInput"
                />
              </b-form-group>
            </b-form-row>
            <b-form-row v-if="sendMessageType === 16 || sendMessageType === 18">
              <b-form-group :label-cols="5" class="col-sm-6" label-class="required" horizontal label-for="parentCoustomerInput" label="New Service User Name">
                <b-form-input
                  type="text"
                  class="form-control"
                  v-model="newName"
                  id="parentCoustomerInput"
                />
              </b-form-group>
            </b-form-row>
            <b-row v-if="sendMessagePlan !== null && showPreview" class="mt-2">
              <b-col>
                <strong>Message Preview</strong>
              </b-col>
            </b-row>
            <b-row v-if="sendMessagePlan !== null && showPreview">
              <b-col>
                <div class="container" v-if="messageChannel === 1">
                  <b-row>
                    <b-col style="width: 105% !important">
                      <div style="padding-top: 110%; position: relative;">
                        <iframe
                          frameborder="0"
                          ref="previewFrame"
                          height="100%"
                          width="100%"
                          style="position: absolute; top: 0; left: 0;"
                        ></iframe>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div id="emailPreview" v-if="messageChannel === 0">
                  <div>Subject:</div>
                  <div class="mb-4" v-html="previewTitle"></div>
                  <div v-html="previewBody"></div>
                </div>
                <div class="inline-flex" v-if="messageChannel === 2">
                  <label class="speech-bubble-label">
                    <strong v-html="previewTitle"></strong>
                  </label>
                  <div class="speech-bubble">
                    <div class="smsPreviewText" v-if="!previewLoading" v-html="previewBody"></div>
                    <div
                      class="smsPreviewTextLoading"
                      v-if="previewLoading"
                      v-html="previewTextPreview"
                    ></div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            title="Message Queue"
            :active="openAt === 'messagequeue'"
            @click="getPayerMessageQueue" v-if="canViewMessages"
          >
            <b-form-row v-if="paymentPlans.length > 1">
              <b-form-group
                :label-cols="5"
                class="col-sm-6"
                label-class="required"
                horizontal
                label="Schedule Messages"
              >
                <b-form-select
                  v-model="queuePlan"
                  :options="paymentPlanSelectOptions"
                  @input="changeMessageQueuePlan"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">-- All Schedules --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-form-row>
            <messageQueue ref="messageQueue"
              v-bind="{ payerId: ukPayerId, planId: paymentPlans.length > 1 ? paymentPlans[0].paymentPlanId : queuePlan}" :canLoad="canLoadQueue"
            />
          </b-tab>
          <b-tab title="Direct Messages" :active="openAt === 'portalmessages'" v-if="hasPayerPortal && false">
            <compose :plans="paymentPlans" :ukPayerId="ukPayerId" @input="getPayerMessages" />
            <inbox
              :messages="portalMessages"
              :ukPayerId="ukPayerId"
              :payerView="true"
              @input="getPayerMessages"
            />
          </b-tab>
          <b-tab
            title="Indemnities"
            :active="openAt === 'indemnities'"
            @click="getPayerIndemnityAdvices"
          >
            <b-tabs
              vertical
              nav-wrapper-class="col-3 vertical-tab-container"
              nav-class="w-100 vertical-tab-list indemnityTabs"
            >
              <b-tab
                v-for="(advice, index) in indemnityAdvices"
                :key="index"
                :active="advice.adviceId === adviceId"
                :title="advice.payingBankReference"
              >
                <template #title>
                  {{advice.payingBankReference}}<i v-if="advice.isMatchedToPayer" v-b-popover.hover.top.d500="'Matched To Customer'" class="fas fa-check-circle ml-2 text-success"></i><i v-if="!advice.isMatchedToPayer" v-b-popover.hover.top.d500="'Not Matched To Customer'" class="fas fa-times-circle ml-2 text-danger"></i>
                </template>
                <indemnityAdvice :payer-view="true" :advice="advice" :showType="true" :showCustGroup="false" />
              </b-tab>
              <template slot="empty">There are no indemnities associated with this customer.</template>
            </b-tabs>
          </b-tab>
          <b-tab title="DDI" v-if="false" :active="openAt === 'scheme'">
            <b-form-row>
              <b-form-group
                :label-cols="5"
                class="col-sm-6"
                label-class="required"
                horizontal
                label="Schedule"
              >
                <b-form-select
                  class="form-control w-100"
                  v-model="auddisPlan"
                  type="text"
                  :options="paymentPlans"
                  text-field="reference"
                  value-field="paymentPlanId"
                >
                  <!-- This slot appears above the options from 'options' prop -->
                  <template slot="first">
                    <option :value="null" disabled>Please select a schedule</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group
                :label-cols="5"
                class="col-sm-6"
                label-class="required"
                horizontal
                label="Code"
              >
                <b-form-select
                  class="form-control w-100"
                  v-model="auddisCode"
                  type="text"
                  :options="auddisCodes"
                  text-field="text"
                  value-field="value"
                >
                  <!-- This slot appears above the options from 'options' prop -->
                  <template slot="first">
                    <option :value="null" disabled>Please select a code</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group :label-cols="5" class="col-sm-6" horizontal>
                <b-button
                  @click="queueAudddis"
                  :disabled="auddisCode === null || auddisPlan === null"
                >
                  <i class="fa fa-list mr-2"></i>Queue AUDDIS Instruction
                </b-button>
              </b-form-group>
            </b-form-row>
          </b-tab>
        </b-tabs>
      </div>
      <div class="idb-block-footer noprint-footer">
        <b-button
          variant="primary"
          @click.prevent="savePayer"
          :disabled="$v.$invalid || !editable || isLoading || reasonNeeded || !currentPlanValid || !payerDetailsValid || !canEdit || !validModulus"
        >
          <i class="fa fa-save mr-2"></i>Save Customer
        </b-button>&nbsp;
        <b-button
          class="pull-right"
          variant="danger"
          :disabled="isLoading || !canDelete"
          @click.prevent="requestPayerDeletion"
          v-if="!clientCustomer && canDelete"
        >
          <i class="fa fa-trash mr-2"></i>Delete Customer
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import axios from 'axios'
import Components from '@/Components/Collections'
import { required, String } from 'vuelidate/lib/validators'
import EventBus from '@/Lib/eventBus'
import PrettyCheck from 'pretty-checkbox-vue/check'
import swal from 'sweetalert2'
import loadingControl from '@/Assets/Components/Loading/Loading.vue'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import roles from '@/Assets/Constants/roles'
import rulesMixin from '@/Assets/Mixins/CollectionsRulesMixin'
import {mapGetters} from "vuex";
const positive = (val) => {
  return parseInt(val) > 0
}
export default {
  mixins: [loading, rulesMixin],
  props: {
    retPath: String,
    ukPayerId: String,
    payerDetailsInitiallyVisible: Boolean,
    bankAccountInitiallyVisible: Boolean,
    paymentPlansInitiallyVisible: Boolean,
    indemnityInitiallyVisible: Boolean,
    adviceId: String
  },
  data () {
    return {
      currentTab: 0,
      openAt: '',
      payerDetailsValid: true,
      queuePlan: null,
      sendMessagePlan: null,
      sendMessageType: null,
      sendMessageLoading: false,
      messageTypeSelect: [
        { name: 'First Message', value: 'FirstMessage' },
        { name: 'Advance Notice', value: 'AdvanceNotice' },
        { name: 'Multiple Schedule Update', value: 'MultipleScheduleUpdate' },
        { name: 'Custom Message 1', value: 'CustomMessage1' },
        { name: 'Custom Message 2', value: 'CustomMessage2' },
        { name: 'Custom Message 3', value: 'CustomMessage3' },
        { name: 'Custom Message 4', value: 'CustomMessage4' },
        { name: 'Custom Message 5', value: 'CustomMessage5' },
        { name: 'Cancellation Notice', value: 'CancellationNotice' },
        { name: 'Email Verification', value: 'EmailVerification' },
        { name: 'Portal Message Sent', value: 'PortalMessageSent' },
        { name: 'Portal Email Verification', value: 'PortalEmailVerification' },
        { name: 'Refund Requested', value: 'RefundRequested' }
      ],
      messageChannels: [
        { name: 'Letter', value: 1 },
        { name: 'Email', value: 0 },
        { name: 'SMS', value: 2 }
      ],
      suspensionReasons: [
        { name: 'None', value: 0 },
        { name: 'Unknown', value: 1 },
        { name: 'Reason 1', value: 2 },
        { name: 'Reason 2', value: 3 },
        { name: 'Reason 3', value: 4 },
        { name: 'Reason 4', value: 5 },
        { name: 'Reason 5', value: 6 }
      ],
      // moreInfoData: null,
      payerDetailsVisible: true,
      paymentPlansVisible: true,
      bankAccountVisible: true,
      payerDetails: {},
      newPlanReference: null,
      selectedPaymentPlan: null,
      bankAccountDetails: {},
      groupValid: false,
      loadingPayer: false,
      showFade: true,
      planNames: [],
      outOfPlanNames: false,
      selectedPlanClosed: false,
      groupConfig: null,
      showPreview: false,
      forceMessage: false,
      messageResponse: null,
      previewBody: '',
      previewTitle: '',
      bearerToken: null,
      portalMessages: [],
      replyId: '',
      portalMessagingConnection: null,
      statusValidated: true,
      allowedMessageTypes: [],
      allowedMessageTypesSelect: [],
      coreReferenceMessageTypes: [],
      coreReferenceMessageTypesSelect: [],
      auddisPlan: null,
      auddisCode: null,
      auddisCodes: [{ text: '0N', value: '0N' }, { text: '0S', value: '0S' }],
      indemnityAdvices: [],
      currentEndPoint: 'jftppayers',
      groupName: 'GROUP NAME',
      selectedPlanIndex: 0,
      canLoadQueue: false,
      suppressChange: false,
      isDirty: false,
      loadedPayer: {},
      oldName: '',
      newName: '',
      canBeDeleted: false
    }
  },
  components: {
    payerDetailsInput: Components.Payer.PayerDetails,
    paymentPlanInput: Components.Payer.PaymentPlan,
    bankAccountDetailsInput: Components.Payer.BankAccountDetails,
    payerNotes: Components.Payer.PayerNotes,
    payerAlerts: Components.Payer.PayerAlerts,
    payerHistory: Components.Payer.PayerHistory,
    pCheck: PrettyCheck,
    inbox: Components.Messaging.Inbox,
    compose: Components.Messaging.Compose,
    messageQueue: Components.Messaging.MessageQueue,
    indemnityAdvice: Components.Indemnities.IndemnityAdvice,
    loadingControl: loadingControl
  },
  methods: {
    formatDate (date) {
      if (date !== null && date !== undefined) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      }
      return ''
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    detailsValid (fromControl) {
      this.payerDetailsValid = fromControl.value
    },
    reloadAlerts () {
      if(this.$refs.payerAlerts !== undefined) {
        this.$refs.payerAlerts.reloadTable()
      }
    },
    reloadHistory () {
      if(this.$refs.payerHistory !== undefined) {
        this.$refs.payerHistory.load()
      }
    },
    reloadNotes () {
      if (this.$refs.payerNotes !== undefined) {
        this.$refs.payerNotes.load()
      }
    },
    getPayerMessageQueue () {
      this.canLoadQueue = true
      if(this.$refs.messageQueue !== undefined) {
        this.$refs.messageQueue.load()
      }
    },
    formatTitle (val) {
      return val.replace('Payer','Customer').replace('Payment','Collection')
    },
    async load () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}${this.currentEndPoint}/${this.ukPayerId}`)
        if (response.data.payer.status === 7) {
          this.$router.push('/collections/customer/manage')
          this.$toastr.e('Could not load deleted customer.')
        }
        this.groupConfig = response.data.config
        this.canBeDeleted = response.data.canDelete
        this.$store.commit('setGroupConfig', this.groupConfig)
        this.$store.commit('setPayerFromUkPayer', response.data.payer)
        this.$store.commit('setConnectedMerchant', response.data.connectedMerchantName)
        this.$store.commit('setMerchant', response.data.merchantName)
        this.$store.commit('setPlanNames', response.data.planNames)
        this.planNames = this.checkPlanNames(response.data.planTemplates)
        this.$store.commit('setAvailableTemplates', response.data.planTemplates)
        var messageTypes = response.data.optOuts
        for(var i = 0; i < messageTypes.length; i++) {
          messageTypes[i].title = this.formatTitle(messageTypes[i].title);
        }
        messageTypes = messageTypes.sort(function (a,b) {
          return a.title.localeCompare(b.title)
        })

        let coreRefTypesFilter = [
          13, // Custom
          14, // Payer Closed
          16, // Notification Of Change
          18, // Notification of Change Migration
          19  // Confirmation Letter
        ]

        if (this.isClosed) {
          coreRefTypesFilter = [
            14 // Payer Closed
          ]
        }

        // As some message types aren't availble or suitable in JFTP, do this.
        let messageTypesFilter = [
          1,  // First Payment
          2,  // Multiple Schedule Update
          8,  // Email Verification
          12, // Cancellation Notice
          13, // Custom
          15, // Payer Transferred
          14, // Payer Closed (CORE)
          16, // Notification Of Change (CORE)
          18, // Notification of Change Migration (CORE)
          19  // Confirmation Letter (should be in the core box)
        ]


        if (this.isClosed) {
          messageTypesFilter.push(0)
          messageTypesFilter.push(20)
        }

        if(!this.hasPayerPortal) {
          messageTypesFilter.push(9) // Portal Message Sent
          messageTypesFilter.push(10) // Portal Email Verification
        }
        if(!this.hasRefunds) {
          messageTypesFilter.push(11) // Refund Requested
          messageTypesFilter.push(17) // Refund Cancelled
        }

        if(!this.allowSms) {
          messageTypesFilter.push(20) // Pre Collection Notification
        }
        let coreTypes = _.cloneDeep(messageTypes)
        messageTypes = messageTypes.filter(function (obj) { return !messageTypesFilter.includes(obj.messageType) })
        coreTypes = coreTypes.filter(function (obj) { return coreRefTypesFilter.includes(obj.messageType) })

        this.allowedMessageTypes = _.cloneDeep(messageTypes)
        this.coreReferenceMessageTypes = _.cloneDeep(coreTypes)

        this.allowedMessageTypesSelect = messageTypes.map(function (msgType) {
          return { title: msgType.title, value: msgType.customMessageId === '00000000-0000-0000-0000-000000000000' ? msgType.messageType : msgType.customMessageId }
        })
        this.coreReferenceMessageTypesSelect = coreTypes.map(function (msgType) {
          return { title: msgType.title, value: msgType.customMessageId === '00000000-0000-0000-0000-000000000000' ? msgType.messageType : msgType.customMessageId }
        })
        this.groupName = response.data.groupName
        if (this.paymentPlans.length > 0) {
          this.selectedPlanIndex = this.paymentPlans.findIndex(x=> x.planTemplateName === this.selectedSchedule)
          this.setSelectedPlan(this.selectedPlanIndex >= 0 ? this.selectedPlanIndex : 0)
        } else {
          this.$store.commit('setPlanValidationMessage', 'No Schedules Specified')
        }
        if (this.openAt === 'indemnities') {
          this.getPayerIndemnityAdvices()
        }
      } catch (e) {
        this.$toastr.e('Could not load customer.')
      }
      this.loadedPayer = _.cloneDeep(this.$store.state.payer.currentPayer)
      this.loadingPayer = false
    },
    sourceToText (val) {
      let retVal = 'Unknown'
      switch (val) {
        case 0:
          retVal = 'Customer Wizard'
          break
        case 1:
          retVal = 'File Import'
          break
        case 2:
          retVal = 'Public Api'
          break
        case 3:
          retVal = 'Online Signup Form'
          break
        case 5:
          retVal = 'Migration'
          break
      }
      return retVal
    },
    validatedTabTitle (title, stagingSection) {
      if (stagingSection) {
        return title
      } else {
        return `${title} `
      }
    },
    sendMessage: _.throttle(async function (messageType) {
      var payerMessageType = messageType
      if (this.sendMessagePlan === '00000000-0000-0000-0000-000000000000') {
        for (var i = 0; i < this.coreReferenceMessageTypes.length; i++) {
          if (messageType === this.coreReferenceMessageTypes[i].messageType || messageType === this.coreReferenceMessageTypes[i].customMessageId) {
            payerMessageType = this.coreReferenceMessageTypes[i]
          }
        }
      } else {
        for (var i = 0; i < this.allowedMessageTypes.length; i++) {
          if (messageType === this.allowedMessageTypes[i].messageType || messageType === this.allowedMessageTypes[i].customMessageId) {
            payerMessageType = this.allowedMessageTypes[i]
          }
        }
      }
      var result = await swal.fire({
        title: 'Send Message to Customer?',
        text: 'A message will be added to the message queue to be sent to the customer.',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'No',
        showConfirmButton: true,
        confirmButtonText: 'Yes'
      })
      if (result.isConfirmed) {
        const url = `${process.env.VUE_APP_DDMS_API_URL}payer/message`
        const body = {
          paymentPlanId: this.sendMessagePlan,
          payerId: this.$store.state.payer.currentPayer.ukPayerId,
          payerMessageType: payerMessageType.customMessageId === '00000000-0000-0000-0000-000000000000' ? messageType :13 ,
          customTemplateId: payerMessageType.customMessageId,
          forceThrough: this.forceMessage,
          oldName: this.oldName,
          newName: this.newName
        }
        try {
          this.sendMessageLoading = true
          var response = await axios.post(url, body, { showload: true, params: { paygateId: this.paygateId } })
          if (response.data.alreadySent) {
            var text = messageType === 19 ? 'Confirmation Letter' : 'Customer Closed message'
            this.$toastr.e(`Message could not be queued as only a single ${text} can be sent to each Customer`, `${text} already Sent`)
          }
          else if (response.data.optedOut) {
            this.$toastr.e('The Customer is opted out from this message type.', 'Could not Queue')
          }
          else {
            if (response.data === '00000000-0000-0000-0000-000000000000') {
              this.$toastr.e('Failed to queue message', 'Failed')
            } else {
              EventBus.$emit('refreshHistory')
              this.$toastr.s('Message added to queue.', 'Success')
            }
          }
        } catch (e) {
          console.error(e)
          this.$toastr.e('Failed to add message to queue.', 'An error occurred.')
        } finally {
          this.sendMessageLoading = false
        }
      }
    }, 1500),
    async requestPayerDeletion () {
      var response = await swal.fire({
        title: 'Delete Customer?',
        text: 'The customer will be deleted and unable to be recovered.',
        type: 'warning',
        icon: 'warning',
        animation: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (response.isConfirmed) {
        try {
          await this.$store.dispatch('deletePayer', this.ukPayerId)
          this.$toastr.s('Customer Deleted')
          this.loadedPayer = _.cloneDeep(this.$store.state.payer.currentPayer)
          this.$router.push('/collections/customer/manage')
        } catch {
          this.$toastr.e('Could not delete the customer.')
        }
      }
    },
    deletePlan (index) {
      this.$swal({
        title: 'Delete Schedule',
        text: 'The schedule will be deleted once the Customer is saved, and will not be recoverable.',
        type: 'warning',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
        .then((confirmDelete) => {
          if (confirmDelete) {
            var ref = this.paymentPlans[this.paymentPlans.indexOf(this.selectedPaymentPlan)].reference
            this.planNames.push(ref)
            if (this.paymentPlans.indexOf(this.selectedPaymentPlan) === index) {
              this.$delete(this.paymentPlans, index)
              this.setSelectedPlan(0)
            } else {
              this.$delete(this.paymentPlans, index)
            }
            this.planNames = this.checkPlanNames(this.planNames)
          }
        })
    },
    setSelectedPlan (index) {
      if (this.paymentPlans.length > 0) {
        this.selectedPaymentPlan = this.paymentPlans[index]
        this.selectedPlanClosed = this.selectedPaymentPlan.planStatus !== undefined && this.selectedPaymentPlan.planStatus !== null && this.selectedPaymentPlan.planStatus === 0
      } else {
        this.selectedPaymentPlan = null
        this.selectedPlanClosed = false
      }
    },
    selectPlan (paymentPlan) {
      this.selectedPaymentPlan = paymentPlan
      this.selectedPlanClosed = paymentPlan.planStatus && paymentPlan.planStatus === 0
    },
    addNewPaymentPlan () {
      if (!this.$v.newPlanReference.$invalid) {
        var selectedPlan = null
        let plan
        for (let i = 0; i < this.planNames.length; i++) {
          plan = this.planNames[i]
          var clone = [ ...this.paymentPlans ]
          var thisTemplateCount = clone.filter((obj) => obj.planTemplateName.startsWith(plan.name)).length
          let date = this.messageChannel === 1 ? (this.$store.getters.collectionsMinDateLetter) : (this.$store.getters.collectionsMinDate)
          if (date < this.activationDate){
            date = this.$store.state.payer.currentPayer.activationDate
          }
          let now = new Date()
          if(date.getDate() === now.getDate()) {
            date.setDate(date.getDate() + 1)
            while (this.isNpd(date)) {
              date.setDate(date.getDate() + 1)
            }
          }
          let untilDate = this.groupConfig.defaultPayerMessageType === 1 ? (this.auddis0nSent ? this.$store.getters.collectionsMinUntilLetter : this.$store.getters.collectionsMinUntilLetterNoDdi) : (this.auddis0nSent ? this.$store.getters.collectionsMinUntil : this.$store.getters.collectionsMinUntilNoDdi)

          var name = `${this.newPlanReference}${(thisTemplateCount > 0 ? ` (${thisTemplateCount+1})` : '')}`
          if (plan && plan.name === this.newPlanReference) {
            this.historicPlanCount = this.historicPlanCount + 1
            selectedPlan = _.clone(this.planNames[i].plan)
            selectedPlan.step = selectedPlan.step === null ? 0 : selectedPlan.step
            selectedPlan.reference = this.allowMultiplePlans && this.payerReference.length <= 14 ? `${this.payerReference}-${(this.historicPlanCount).toString().padStart(3, '0')}` : this.payerReference
            for(let p = 0; p <  this.paymentPlans.length; p++) {
              if ( this.paymentPlans[p].reference === selectedPlan.reference) {
                this.historicPlanCount = this.historicPlanCount + 1
                selectedPlan.reference = this.allowMultiplePlans && this.payerReference.length <= 14 ? `${this.payerReference}-${(this.historicPlanCount).toString().padStart(3, '0')}` : this.payerReference
                if (this.allowMultiplePlans && this.payerReference.length <= 14) {
                  p = 0
                }
                if (this.paymentPlans[p].planTemplateName === name) {
                  name = `${this.newPlanReference}${(thisTemplateCount > 0 ? ` (${thisTemplateCount+2})` : '')}`
                  p = 0
                }
              }
            }
            selectedPlan.firstPaymentDate = date
            if (selectedPlan.friendlyRecurrenceType < 2) {
              selectedPlan.ordinal = date.getDay()
            }

            selectedPlan.futurePlanChanges = this.planNames[i].futurePlanChanges
            selectedPlan.planTemplateName = name
            selectedPlan.planStatus = 3
            selectedPlan.suspensionReason = ''
            selectedPlan.untilDate = untilDate
            selectedPlan.groupId = this.groupId
          }
        }
        this.paymentPlans.push(selectedPlan)
        let index = this.paymentPlans.findIndex(x=>x.reference === selectedPlan.reference)
        this.setSelectedPlan(index)
        this.planNames = this.checkPlanNames(this.planNames)
        this.newPlanReference = null
        setTimeout(() => {
          this.selectedPlanIndex = index
        }, 1000)
      }
    },
    async savePayer () {
      this.$v.$touch()
      try {
        if (this.$v.$invalid) {

        } else {
          if (this.isClosed) {
            const willClose = await this.$swal({
              title: 'Close Customer?',
              text: 'This is irreversible. The customer will no longer be editable.',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: colours.danger,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No'
            })
            if (!willClose) {
              return
            }
          }
          let canPutLive = false
          if (this.status === 4 && this.paymentPlans.length > 0) {
            canPutLive = true
          }
          var response = await this.$store.dispatch('savePayer', { group: this.group })
          if (response.ensureUnique) {
            this.$toastr.e('Customer not saved, ensure collections are unique (no possibility of amount/date duplication).')
          }
          else if (response.secureLimitsExceeded) {
            let message = []
            if(response.maxAmountPerCollectionExceeded) {
              message.push('Max Amount Per Collection')
            }
            if(response.payerCollectionCountPerMonthExceeded) {
              message.push('Payer Collection Count Per Month')
            }
            if(response.maxAmountPerMonthExceeded) {
              message.push('Max Amount Per Month')
            }
            if(response.maxCollectionCountPerMonthExceeded) {
              message.push('Collection Count Per Month')
            }
            if(response.clientLimitsExceeded) {
              message.push('Collection Count or Amount for Client')
            }
            this.$toastr.e(`Secure Limits Exceeded: ${ message.join(', ').trim() }. Customer has not been updated. Please contact L & Z.`)
          } else {
            this.$toastr.s('Customer saved successfully.', 'Success')
            this.$store.dispatch('updateCurrentCollectionsAlertCount')
            if (canPutLive) {
              const makeOperational = await swal.fire({
                title: 'Set Customer to Operational',
                text: this.auddis0nSent ? 'This customer can be made operational' : 'This customer can be made operational, but will appear as "Lodgement Pending" until the Direct Debit 0N Instruction has been lodged with BACS.',
                type: 'warning',
                icon: 'warning',
                showCancelButton: false,
                showDenyButton: true,
                confirmButtonColor: colours.success,
                denyButtonText: 'Leave Incomplete',
                denyButtonColor: colours.warning,
                confirmButtonText: 'Set as Operational',
              })
              if (makeOperational.isConfirmed) {
                this.status = 0
                response = await this.$store.dispatch('savePayer', { group: this.group })
                if (response.ensureUnique) {
                  this.$toastr.e('Customer not saved, ensure collections are unique (no possibility of amount/date duplication).')
                }
                else if (response.secureLimitsExceeded) {
                  let message = []
                  if(response.maxAmountPerCollectionExceeded) {
                    message.push('Max Amount Per Collection')
                  }
                  if(response.payerCollectionCountPerMonthExceeded) {
                    message.push('Payer Collection Count Per Month')
                  }
                  if(response.maxAmountPerMonthExceeded) {
                    message.push('Max Amount Per Month')
                  }
                  if(response.maxCollectionCountPerMonthExceeded) {
                    message.push('Collection Count Per Month')
                  }
                  if(response.clientLimitsExceeded) {
                    message.push('Collection Count or Amount for Client')
                  }
                  this.$toastr.e(`Secure Limits Exceeded: ${ message.join(', ').trim() }. Customer has not been updated. Please contact L & Z.`)
                } else {
                  this.$toastr.s('Customer set to operational.', 'Success')
                  this.$store.dispatch('updateCurrentCollectionsAlertCount')
                }
              }
              // put this in for people who may trigger the "ARE YOU SURE" dialog with their lightning fast reflexes.
              this.loadedPayer = {}
              await this.load()

            }
            else {
              this.loadedPayer = {}
              await this.load()
            }
            this.statusValidated = true
          }

        }
      } catch (e) {
        if (e.response.status === 409) {
          this.$toastr.e(e.response.data, 'Customer not saved')
        } else if (e.response.status === 400) {
          const { bankAccountValidated, payerDetailsValidated, paymentPlansValidated, payerStatusValidated } = e.response.data
          let message = 'Validation failed. Check '
          if (!payerDetailsValidated) {
            this.payerStagingDetails.payerDetailsValidated = false
            message += "'Customer Details', "
          }
          if (!paymentPlansValidated) {
            this.payerStagingDetails.paymentPlansValidated = false
            message += "'Collection Schedules', "
          }
          if (!bankAccountValidated) {
            this.payerStagingDetails.bankAccountValidated = false
            message += "'Bank Details', "
          }
          if (!payerStatusValidated && this.status === 1) {
            this.statusValidated = false
            message += "'Suspension Reason', "
          }
          message = message.slice(0, message.length - 2)
          message += '.'
          this.$toastr.e(message, 'Customer not saved')
        } else {
          console.error(e)
          this.$toastr.e('Unable to save customer.', 'Error')
        }
      }
      this.$v.$reset()
      this.groupValid = false
      this.$store.dispatch('updateCurrentCollectionsAlertCount')
    },
    dirtyGroup () {
      this.$v.group.$touch()
      this.groupValid = !this.$v.group.$invalid
    },
    async fetchPayerDetails () {
      this.loadingPayer = true
      await this.$store.dispatch('setCurrentJftpPayerWithId', { id: this.ukPayerId })
      // const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/custom/${this.groupId}`, { showload: true })
      this.$nextTick(function () {
        this.loadingPayer = false
        setTimeout(function () {
          this.showFade = false
        }.bind(this), 1600)
      })
    },
    checkPlanNames (input) {
      var plans = this.$store.state.payer.currentPayer.paymentPlans
      var usableNames = input
      // for (var i = 0; i < plans.length; plans++) {
      //   if (usableNames.find(val => val.name === plans[i].planTemplateName)) {
      //     usableNames = usableNames.filter(item => item.name !== plans[i].planTemplateName)
      //   }
      // }
      // this.outOfPlanNames = usableNames.length === 0
      return usableNames
    },
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
      const groups = this.$store.getters.customerGroups
      const paygateIdNotDefault = (paygateId && paygateId !== '00000000-0000-0000-0000-000000000000')
      const groupsNull = (groups === undefined || groups === null)
      if (paygateIdNotDefault && groupsNull) {
        var payload = { paygateId: paygateId, groupRole: ['groupAdministrator', 'create', 'edit', 'add'] }
        this.$store.dispatch('getGroupsWithRights', payload).then(response => {
          this.groups = this.$store.getters.rightsGroups
          if (this.groups.length === 0) {
            this.$toastr.w('You don\'t have permission to view any customer groups.')
          }
        })
      }
      if (this.$store.getters.customerGroups !== null && this.$store.getters.customerGroups.length > 0) {
        this.group = this.$store.getters.customerGroups[0].groupid
      }
    },
    async getPreview () {
      if (this.sendMessageType !== null && this.sendMessagePlan !== null) {
        setTimeout(async () => {
          if (this.messageChannel === 1) {
            const iframe = this.$refs.previewFrame
            if (iframe) {
              iframe.src = this.previewUrl
            }
          } else {
            var previewResponse = await axios.get(this.sendMessagePlan === '00000000-0000-0000-0000-000000000000' ? `${process.env.VUE_APP_DDMS_API_URL}messages/payerpreviewcore/${this.ukPayerId}/${this.messageChannel}/${this.sendMessageType}` : `${process.env.VUE_APP_DDMS_API_URL}messages/payerpreview/${this.sendMessagePlan}/${this.messageChannel}/${this.sendMessageType}`, {
              showload: true,
              params: {paygateId: this.paygateId}
            })
            this.previewBody = previewResponse.data[0]
            this.previewTitle = previewResponse.data.length > 1 ? previewResponse.data[1] : 'Sender'
          }
        }, 1000)
      }
    },
    async activateOnboarded () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payer/activate/${this.ukPayerId}`, {}, { showload: true, params: { paygateId: this.paygateId } })
      if (response.data.ensureUnique) {
        this.$toastr.e('Customer not saved, ensure collections are unique (no possibility of amount/date duplication).')
      }
      else if (response.data.secureLimitsExceeded) {
        let message = []
        if (response.data.maxAmountPerCollectionExceeded) {
          message.push('Max Amount Per Collection')
        }
        if (response.data.payerCollectionCountPerMonthExceeded) {
          message.push('Payer Collection Count Per Month')
        }
        if (response.data.maxAmountPerMonthExceeded) {
          message.push('Max Amount Per Month')
        }
        if (response.data.maxCollectionCountPerMonthExceeded) {
          message.push('Collection Count Per Month')
        }
        if(response.data.clientLimitsEcxeeded) {
          message.push('Collection Count or Amount for Client')
        }
        this.$toastr.e(`Secure Limits Exceeded: ${message.join(', ').trim()}. Customer has not been updated. Please contact L & Z.`)
      }
      else {
        await this.load()
        this.$toastr.s('Customer has been activated')
      }
    },
    async createPortalAccount () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payer/portal/${this.ukPayerId}`, {}, { showload: true, params: { paygateId: this.paygateId } })
      if (response) {
        await this.load()
      }
    },
    async resendPortalCode () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payer/portal/${this.ukPayerId}/resend`, {}, { showload: true, params: { paygateId: this.paygateId } })
      if (response.data) {
        this.$toastr.s('Code sent.')
      }
    },
    async getPayerMessages () {
      if (this.hasPayerPortal && parseInt(process.env.VUE_APP_VERSION) >= 120) {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}portalmessaging/payer/${this.ukPayerId}`, { showload: true, params: { paygateId: this.paygateId } })
        if (response.data) {
          this.portalMessages = response.data
        }
      }
    },
    stopSocketListener () {
      this.portalMessagingConnection.stop()
    },
    async loadLicence () {
      try {
        const licenceRequest = axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence`, { showload: true, params: { paygateId: this.paygateId } })
        var response = await licenceRequest
        this.license = response.data
      } catch (err) {
        alert(err)
      }
    },
    changeMessageQueuePlan () {
      EventBus.$emit('changeMessageQueuePlan', this.queuePlan)
    },
    async getMessageTypes () {
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}optout/${this.groupId}/allowed`, { params: { paygateId: this.paygateId } })
      this.allowedMessageTypes = response.data
    },
    async queueAudddis () {
      try {
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}pushauddis/${this.ukPayerId}/${this.auddisPlan}`, { code: this.auddisCode }, { params: { paygateId: this.paygateId } })
        this.$toastr.s('Queued AUDDIS message successfully.')
      } catch (e) {
        this.$toastr.e('Failed to queue AUDDIS instruction.')
      }
    },
    async getPayerIndemnityAdvices () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}indemnity/payer/${this.ukPayerId}`, { params: { paygateId: this.paygateId } })
      if (response.data) {
        this.indemnityAdvices = response.data
        this.indemnityAdvices.sort((a, b) => a.payingBankReference.localeCompare(b.payingBankReference))
      }
    },
    removeExistingStatus () {
      this.suspensionReason = ''
    },
    async getPayerAdvNoticeDetails () {
      await this.$store.dispatch('getAdvancedNoticeSettingsForPayer', { ukPayerId: this.ukPayerId })
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    canSend() {
      return ((this.sendMessageType === 16 || this.sendMessageType === 18) && this.newName !== '' && this.oldName !== '') || (this.sendMessageType !== 16 && this.sendMessageType !== 18)
    },
    isClosed () {
      return this.status === 3
    },
    clientCustomer () {
      return this.selectedCustomer === '0a4703c6-d43f-4b12-90fa-eb6d172f3703'
    },
    statae () {
      let statae = [
        { name: 'Operational', value: 0 }
      ]
       if (this.status === 4) {
         statae.push({ name: this.status === 4 && !this.auddis0nSent ? 'Lodgement Pending' : 'Incomplete', value: 4 })
       } else {
         statae.push({ name: 'Suspended', value: 1 })
         statae.push({ name: 'Alert', value: 2 })
         statae.push({ name: 'Closed', value: 3 })
       }
      if (this.status === 5) {
        statae.push({ name: 'Awaiting Activation', value: 5 })
      }
      if (this.status === 7) {
        statae.push({ name: 'Deleted', value: 7 })
      }
      if (this.status === 8) {
        statae.push({ name: 'Lodgement Pending', value: 8  })
      }
      return statae
    },
    paymentPlanSelectOptions () {
      const selectList = this.paymentPlans.map(plan => ({
        value: plan.paymentPlanId,
        text: plan.planTemplateName
      }))

      selectList.sort(function (a,b) {
        return a.text.localeCompare(b.text)
      })

      selectList.unshift({value: '00000000-0000-0000-0000-000000000000', text: 'Customer Reference'})

      if (selectList.length === 0) {
        selectList.push({ value: null, text: 'No schedules found' })
      }
      return selectList
    },
    canActivate () {
      const stagingDetails = this.$store.state.payer.currentPayer.payerStaging
      return (stagingDetails.bankAccountValidated &&
        stagingDetails.paymentPlansValidated &&
        stagingDetails.payerDetailsValidated &&
        !stagingDetails.awaitingActivation)
    },
    returnPath () {
      return `/customer/${this.retPath}`
    },
    customerGroups () {
      return this.$store.getters.customerGroups
    },
    currentPlanValid () {
      return this.$store.state.payer.currentPayer.planValid
    },
    valid () {
      return this.$store.state.payer.formValid
    },
    groupId: {
      get () {
        return this.$store.state.payer.currentPayer.groupId
      },
      set (value) {
        this.$store.commit('updateGroup', value)
      }
    },
    messageChannel: {
      get () {
        return this.$store.state.payer.currentPayer.payerMessageType
      },
      set (value) {
        this.$store.state.payer.currentPayer.payerMessageType = value
      }
    },
    payerReference: {
      get () {
        return this.$store.state.payer.currentPayer.reference
      },
      set (value) {
        this.$store.commit('updatePayerReference', value)
      }
    },
    auddis0nSent: {
      get () {
        return this.$store.state.payer.currentPayer.auddis0nSent
      }
    },
    lodgedDate: {
      get () {
        return this.formatDate(this.$store.state.payer.currentPayer.lodgedDate)
      }
    },
    paymentPlans: {
      get () {
        let planOpts = this.$store.state.payer.currentPayer.paymentPlans.sort(function (a,b) {
          return a.planTemplateName.localeCompare(b.planTemplateName)
        })
        return planOpts
      },
      set (value) {
        this.$store.state.payer.currentPayer.paymentPlans = value
      }
    },
    payerStagingDetails: {
      get () {
        return this.$store.state.payer.currentPayer.payerStaging
      },
      set (value) {
        this.$store.state.payer.currentPayer.payerStaging = value
      }
    },
    status: {
      get () {
        return this.$store.state.payer.currentPayer.status
      },
      set (value) {
        this.$store.state.payer.currentPayer.status = value
      }
    },
    suspensionReason: {
      get () {
        return this.$store.state.payer.currentPayer.suspensionReason
      },
      set (value) {
        this.$store.state.payer.currentPayer.suspensionReason = value
      }
    },
    isSuspended: {
      get () {
        return this.$store.state.payer.currentPayer.status === 1
      }
    },
    payerPortalId: {
      get () {
        return this.$store.state.payer.currentPayer.payerPortalId
      },
      set (value) {
        this.$store.state.payer.currentPayer.payerPortalId = value
      }
    },
    accessedPortal: {
      get () {
        return this.$store.state.payer.currentPayer.accessedPortal
      },
      set (value) {
        this.$store.state.payer.currentPayer.accessedPortal = value
      }
    },
    editable: {
      get () {
        return this.$store.state.payer.currentPayer.editable
      }
    },
    emailVerified: {
      get () {
        return this.$store.state.payer.currentPayer.emailVerified
      }
    },
    historicPlanCount: {
      get () {
        return this.$store.state.payer.currentPayer.historicPlanCount
      },
      set (value) {
        this.$store.state.payer.currentPayer.historicPlanCount = value
      }
    },
    isBusiness: {
      get () {
        return this.$store.state.payer.currentPayer.isBusiness
      },
      set (value) {
        this.$store.state.payer.currentPayer.isBusiness = value
      }
    },
    isPaperless: {
      get () {
        return this.$store.state.payer.currentPayer.isPaperless
      },
      set (value) {
        this.$store.state.payer.currentPayer.isPaperless = value
      }
    },
    detailsValidationMessage: {
      get () {
        return this.$store.state.payer.detailsValidationMessage
      }
    },
    bankingValidationMessage: {
      get () {
        return this.$store.state.payer.bankingValidationMessage
      }
    },
    plansValidationMessage: {
      get () {
        return this.$store.state.payer.plansValidationMessage
      }
    },
    merchantName: {
      get () {
        return this.$store.state.payer.currentPayer.merchantName
      },
      set (value) {
        this.$store.state.payer.currentPayer.merchantName = value
      }
    },
    connectedMerchantName: {
      get () {
        return this.$store.state.payer.currentPayer.connectedMerchantName
      },
      set (value) {
        this.$store.state.payer.currentPayer.connectedMerchantName = value
      }
    },
    payerSource: {
      get () {
        return this.sourceToText(this.$store.state.payer.currentPayer.payerSource)
      },
      set (value) {
        this.$store.state.payer.currentPayer.payerSource = value
      }
    },
    activationDate: {
      get () {
        return this.$store.state.payer.currentPayer.activationDate
      },
      set (value) {
        this.$store.state.payer.currentPayer.activationDate = value
      }
    },
    bankingValidated: {
      get () {
        return this.$store.state.payer.currentPayer.bankAccountDetails.validated
      }
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    allowMultiplePlans () {
      if (!this.groupConfig) {
        return false
      }
      return this.groupConfig.allowMultiplePlans
    },
    canSetPlanReference () {
      if (!this.groupConfig) {
        return false
      }
      return this.groupConfig.canSetPlanReference === true
    },
    planLimitHit () {
      return (!this.allowMultiplePlans && this.paymentPlans.length === 1) || (this.allowMultiplePlans && this.outOfPlanNames)
    },
    validModulus () {
      if (!this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult) {
        return true
      } else if (!this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult) {
        return true
      }
      return (this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult.valid && this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult.supportsDdi) || (this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult !== null && this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult !== undefined && this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult.errorText !== null &&  (this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult.errorText.startsWith('The supplied sort code could not be found') || this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult.errorText.startsWith('No bank details could be found')))
    },
    // groupName () {
    //   return this.groupConfig ? this.groupConfig.group.name : ''
    // },
    previewUrl () {
      if (this.sendMessagePlan) {
        const pdfUrl = encodeURI(this.sendMessagePlan === '00000000-0000-0000-0000-000000000000' ? `${process.env.VUE_APP_DDMS_API_URL}messages/payerpreviewcore/letter/${this.ukPayerId}/${this.sendMessageType}` : `${process.env.VUE_APP_DDMS_API_URL}messages/payerpreview/letter/${this.sendMessagePlan}/${this.sendMessageType}`)
        var previewUrl = `${process.env.VUE_APP_PDFVIEWER_URL}?file=${pdfUrl}&token=${this.bearerToken}`
        const paygateId = this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
        if (paygateId) {
          previewUrl += `&paygateId=${paygateId}`
        }
        return previewUrl
      } else {
        return process.env.VUE_APP_PDFVIEWER_URL
      }
    },
    hasPayerPortal () {
      return this.$store.getters.hasLicence('LicenceCollectionsPayerPortal')
    },
    canDelete () {
      return this.status >= 3 && this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin]) && this.canBeDeleted
    },
    isLzAdminRole () {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations])
    },
    canViewBanking () {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.ReadOnly, roles.Finance, roles.Qa, roles.Developer])
    },
    canViewAlerts () {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Finance])
    },
    reasonNeeded () {
      return (this.isSuspended || this.isClosed) && this.suspensionReason === ''
    },
    isFinance () {
      return this.$store.getters.isInRoles([roles.Finance])
    },
    advancedNoticeWithMedia () {
      return this.getAdvancedNoticeWithMedia(this.groupConfig.advanceNoticeInDays, this.groupConfig.defaultPayerMessageType)
    },
    canEdit() {
      return this.$store.getters.isInRoles([ roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard ])
    },
    canViewMessages() {
      return this.$store.getters.isInRoles([ roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance, roles.Developer, roles.Qa ])
    },
    canSendMessages() {
      return this.$store.getters.isInRoles([ roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin ])
    },
    canActivatePayer() {
      return this.$store.getters.isInRoles([ roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard ])
    },
    allowedMessageChannels () {
      let availMessageChannels = this.$store.getters.messageChannels
      if (parseInt(process.env.VUE_APP_VERSION) <  130){
        let smsIndex = availMessageChannels.indexOf(2)
        if (smsIndex !== -1) {
          availMessageChannels.splice(smsIndex, 1);
        }
      }
      let allowed = []
      for(var i = 0; i < this.messageChannels.length; i++){
        if (availMessageChannels.includes(this.messageChannels[i].value)){
          allowed.push(this.messageChannels[i])
        }
      }
      return allowed
    },
    hasRefunds () {
      return this.$store.getters.hasLicence('LicenceCollectionsRefunds') && !this.$store.getters.isInRole(roles.SystemUser)
    },
    allowSms () {
      return parseInt(process.env.VUE_APP_VERSION) >= 130
    },
    clientIsInactive () {
      return this.$store.getters.selectedCustomerObject.status === 'Incomplete' || this.$store.getters.selectedCustomerObject.status === 'Inactive'
    },
  },
  async mounted () {
    this.loadingPayer = true
    this.openAt = this.$route.query.openAt
    this.selectedSchedule = this.$route.query.selectedSchedule
    if (this.$store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations, roles.DevOps, roles.Developer, roles.Qa])) {
      this.suppressChange = true
      var pgResponse = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}helpers/getpgid/${this.ukPayerId}`)
      await this.$store.dispatch('setPaygateId', pgResponse.data)
      this.suppressChange = false
    }
    this.loadingPayer = true
    await this.load()
    this.$store.dispatch('getNonProcessingDates')
    this.$store.dispatch('getMessageChannels')
    this.$store.dispatch('getAdvancedNoticeSettingsForPayer', { ukPayerId: this.ukPayerId })
    this.$store.dispatch('getSecureLimits')
    EventBus.$emit('changeMessageQueuePlan', null)
    if (this.$store.getters.fromMessagePage || this.openAt === 'messagequeue') {
      this.openAt = 'messagequeue'
      this.getPayerMessageQueue()
      this.$store.commit('setOnMessagePage', false)
    }
    if (this.openAt === 'payerHistory') {
      this.reloadHistory()
    }
    if (this.openAt === 'alerts') {
      this.reloadAlerts()
    }
    if(this.openAt === 'notes') {
      this.reloadNotes()
    }
    if (this.openAt === 'indemnities') {
      this.getPayerIndemnityAdvices()
    }


  },
  async created () {
    // If there are no initially visible sections, keep the defaults (all visible). Else set them here:
    if (this.payerDetailsInitiallyVisible || this.bankAccountInitiallyVisible || this.paymentPlansInitiallyVisible) {
      this.payerDetailsVisible = this.payerDetailsInitiallyVisible
      this.bankAccountVisible = this.bankAccountInitiallyVisible
      this.paymentPlansVisible = this.paymentPlansInitiallyVisible
    }
  },
  validations: {
    groupId: { required },
    payerReference: {
      required
    },
    newPlanReference: {}
  },
  watch: {
    selectedPlanIndex(val) {
      this.setSelectedPlan(val)
    },
    $v: {
      handler (val) {
      },
      deep: true
    },
    currentPlanValid: {
      handler (val) {
      },
      deep: true
    },
    selectedCustomer () {
      if (!this.suppressChange) {
        this.$router.push({path: '/collections/customer/manage'})
      }
    },
    currentTab (v) {
      let openAt = 'Main'
      switch (v) {
        case 1: openAt = 'payerDetails';
        break;
        case 2: openAt = 'schedules';
        break;
        case 3: openAt = 'bankDetails';
        break;
        case 4: openAt = 'alerts';
        break;
        case 5: openAt = 'payerHistory';
        break;
        case 6: openAt = 'notes';
        break;
        case 7: openAt = 'messages';
        break;
        case 8: openAt = 'messagequeue';
        break;
        case 9: openAt = 'portalmessages';
        break;
        case 10: openAt = 'scheme';
        break;
        case 11: openAt = 'indemnities';
        break;
      }
      history.replaceState({
        listingFormActive: v
      }, null, `${window.location.pathname}?openAt=${openAt}${(this.selectedSchedule !== null && this.selectedSchedule !== undefined && this.selectedSchedule !== '' ? '&selectedSchedule='+this.selectedSchedule :'')}`)
    }
  },
  async beforeRouteLeave (to, from, next) {
    let original = _.cloneDeep(this.loadedPayer)
    let current = _.cloneDeep(this.$store.state.payer.currentPayer)
    let changed = _.difference(original, current)

    let originalStr = JSON.stringify(original)
    let currentStr = JSON.stringify(current)
    let changed2 = originalStr !== '{}' && originalStr !== currentStr // the first argument is to catch situations where the original may not have been loaded correctly.
    if (changed.length > 0 || changed2 === true) {
      const result = await swal.fire({
        title: 'You have unsaved changes!',
        text: 'You will lose any changes to the current template.  Are you sure you want to navigate away?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonClass: 'btn btn-light',
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No',
        confirmButtonClass: 'btn btn-danger'
      })
      if (result.isConfirmed) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>
<style>
.focused-item {
  animation-name: yellowfade;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
}

.missing-fields {
  color: red;
}
.warning-fields {
  color: orange;
}
.planRow .tabs {
  width: 100%;
}

.vertical-tab-list {
  border-bottom: solid 0px black !important;
  border-right: 0px solid #ddd;
}

.vertical-tab-container {
  border-bottom: solid 0px black !important;
  border-right: 1px solid #ddd;
  padding-right: 0px;
}

.vertical-tab-list .nav-item .active,
.vertical-tab-list .nav-item .nav-link:hover {
  border: 1px solid #ddd;
  border-right: solid 1px transparent;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0rem;
  border-top-right-radius: 0rem;
  margin: 0;
}

@media print {
  .no-print-tabs,
  .noprint-footer,
  .vgt-global-search__input,
  .vgt-global-search__actions,
  .idb-block-title {
    display: none;
  }
  th {
    border: solid 1px black;
  }
  .vgt-inner-wrap,
  .vgt-global-search {
    border: solid 1px transparent;
  }
}

#smsMessageBody {
  min-height: 7rem;
}

.inline-flex {
  display: flex;
}

.smsPreviewText {
  margin-left: 2rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  margin-right: 2rem;
}

.smsPreviewTextLoading {
  margin-left: 2rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  color: transparent;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.speech-bubble-label {
  margin-top: 0.5rem;
  margin-right: 2rem;
  min-width: 5rem;
}

.speech-bubble {
  position: relative;
  background: #eeee;
  border-radius: 0.4em;
  color: black;
  width: 20rem;
  min-height: 7rem;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 1.7rem;
  width: 0;
  height: 0;
  border: 1.25em solid transparent;
  border-right-color: #eee;
  border-left: 0;
  border-top: 0;
  margin-top: -0.625em;
  margin-left: -1em;
}

.dark-mode * .speech-bubble {
  background: #303030 ;
}

.dark-mode * .speech-bubble:after {
  border-right-color: #303030;
}

.indemnityTabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  max-width: 500px;
  max-height: 500px;
  overflow: auto;
  min-height: 500px;
}

.dark-mode * .bg-warning * .btn-outline-light:hover {
  color: #000000 !important;
}
</style>
