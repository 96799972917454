<template>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <div class="idb-block">
      <!-- Header -->
      <div class="idb-block-title">
        <h2><help-icon docPath="/paygate-collections/payers/managepayer/" />View Customer History Item: {{ title }}</h2>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">
        View an individual customer's history item.
        <b-row class="mt-3">
          <b-col md="4">Name: <router-link
              :to="'/collections/customer/'+id+'/edit'"
            >{{name}}</router-link></b-col>
          <b-col md="4">
            Customer Reference :
            <router-link
              :to="'/collections/customer/'+id+'/edit'"
            >{{reference}}</router-link>
          </b-col>
          <b-col md="4">&nbsp;</b-col>
        </b-row>
        <b-row>
          <b-col md="4">Message Type: {{ getTextFromMessageType(messageType) }}</b-col>
          <b-col md="4" v-if="reasonCode && reasonCode !== ''">Reason Code : {{ reasonCode ? reasonCode : ''}}</b-col>
          <b-col md="4">Generation Date : {{ formatDate(generationDate) }}</b-col>
          <b-col md="4" v-if="!reasonCode || reasonCode === ''">&nbsp;</b-col>
          <b-col md="4">Source: {{ isSystemUser ? userName : sourceToText(source) }}</b-col>
        </b-row>
      </div>
    </div>
    <div class="idb-block" v-if="body !== null">
      <!-- Header  -->
      <div class="idb-block-title">
        <h2>Description</h2>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">{{ body }}</div>
    </div>
    <div class="idb-block" v-if="suspensionReason > 0">
      <!-- Header  -->
      <div class="idb-block-title">
        <h2>Suspension Reason</h2>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">{{ getSuspensionReason(suspensionReason) }}</div>
    </div>
    <div class="idb-block" v-if="circumstances !== null && circumstances !== ''">
      <!-- Header -->
      <div class="idb-block-title">
        <h2>Circumstances</h2>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">{{ circumstances }}</div>
    </div>
    <div class="idb-block" v-if="instructions !== null && instructions !== ''">
      <!-- Header -->
      <div class="idb-block-title">
        <h2>Instructions</h2>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">{{ instructions }}</div>
    </div>
    <div class="idb-block" v-if="paygateAction !== null && paygateAction !== ''">
      <!-- Header -->
      <div class="idb-block-title">
        <h2>Action</h2>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">{{ paygateAction }}</div>
    </div>
    <div class="idb-block" v-if="allowRepresentation">
      <!-- Header -->
      <div class="idb-block-title">
        <h2>Re-Presentation</h2>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">
        You can re-present this failed collection up to 30 days after the original processing day.
        <br />
        <span>The original processing date was: {{ formatDate(originalProcessingDate) }}</span><br/>
        <span v-if="canRepresent && !represented && activeStatus">To re-present the collection, click the button below.</span>
        <span v-if="canRepresent && !represented && !activeStatus">This collection cannot be represented as the Customer is {{statusText}}.</span>
        <span v-else-if="canRepresent && represented">This collection has already been represented.</span>
        <span v-else>This collection cannot be represented as it is outside the 30 day window.</span>
        <br />
        <br />
        <b-btn variant="warning" v-if="canViewAlerts" @click="representCollection" :disabled="isLoading || !canRepresent || represented || !activeStatus">Re-Present Collection</b-btn>
      </div>
    </div>
    <div class="idb-block" v-if="allowSendNew0N">
      <!-- Header -->
      <div class="idb-block-title">
        <h2>Send new 0N Direct Debit Instruction</h2>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">
        A new 0N instruction is required with BACS. Ensure the Customer Details are correct and send a new instruction using the button below.
        <br />
        <br />
        <b-btn variant="warning" @click="queue0N" :disabled="isLoading || !canRepresent">Queue new instruction</b-btn>
      </div>
    </div>
    <div class="idb-block" v-if="showMessageData">
      <!-- Header -->
      <div class="idb-block-title">
        <h2>Outgoing Message Details</h2>
      </div>
      <div class="idb-block-content">
        <b-row class="mt-2">
          <b-col cols="2">
            <strong>Subject</strong>
          </b-col>
          <b-col v-html="messageData.title">
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="2">
            <strong>Body</strong>
          </b-col>
          <b-col v-html="messageData.body">
          </b-col>
        </b-row>

      </div>
    </div>
    <div class="idb-block"  v-if="showMessageData">
      <div class="idb-block-title">
        <h2>Outgoing Message Details</h2>
      </div>
      <div class="idb-block-content">
        <b-row class="mt-2">
          <b-col>
            <p>Save a copy of the letter using the button below:</p>
            <b-button @click="saveFile" variant="outline-primary"><i class="far fa-file-pdf mr-2"></i>Save Letter As PDF</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="idb-block" v-if="this.alertMessageId !== null && this.alertMessageId !== '' && canViewAlerts">
      <div class="idb-block-footer">
        <b-button variant="outline-danger" @click.prevent="deleteAlert" :disabled="isLoading">Clear Alert</b-button>
        <b-button class="ml-1" v-if="messageType===13 && !planInfo.ignoreWarnings" variant="outline-warning" @click.prevent="setToIgnore" :disabled="isLoading">Ignore future warnings</b-button>
        <b-button class="pull-right" variant="outline-primary" v-if="canRequestRefund && hasRefunds && canRefund" :disabled="isLoading || refundRequested || !refundable" @click.prevent="initiateRefund">Refund</b-button>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Notes</h2>
      </div>
      <div class="idb-block-content">
        <payer-history-notes :ukPayerId="id" :historyId="historyId"></payer-history-notes>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import loading from '@/Assets/Mixins/LoadingMixin'
import swal from 'sweetalert2'
import roles from '@/Assets/Constants/roles'
import colours from '@/Assets/Constants/colours'
import Components from '@/Components/Collections'
const statusToText = (x) => {
  switch (x) {
    case -1:
      return 'Any'
    case 0:
      return 'Operational'
    case 1:
      return 'Suspended'
    case 2:
      return 'Alert'
    case 3:
      return 'Closed'
    case 4:
      return 'Incomplete'
    case 5:
      return 'Awaiting Activation'
    case 6:
      return 'Plan Suspended'
    case 7:
      return 'Deleted'
    case 8:
      return 'Lodgement Pending'
    default:
      return 'Unknown'
  }
}
export default {
  components: {
    payerHistoryNotes: Components.Payer.PayerHistoryNotes,
  },
  mixins: [loading],
  data () {
    return {
      alertMessageId: null,
      name: null,
      reference: null,
      messageType: null,
      reasonCode: null,
      generationDate: null,
      originalProcessingDate: null,
      title: null,
      body: null,
      paygateAction: null,
      instructions: null,
      circumstances: null,
      statusReason: null,
      ukpayerid: null,
      allowRepresentationStatae: [1], //, 10, 11, 12, 13, 14,
      allowNew0NStatae: [46],
      metaData: null,
      refundRequested: false,
      paymentComposite: '',
      refundable: false,
      suspensionReason: '',
      messageData: null,
      messageIsLetter: true,
      payerMessageId: null,
      source: null,
      userName: null,
      canRepresent: false,
      represented: false,
      payerStatus: 0
    }
  },
  props: {
    id: String,
    historyId: String
  },
  computed: {
    showMessageData () {
      return this.messageData !== undefined && this.messageData !== null && this.payerMessageId !== null && this.payerMessageId !== '00000000-0000-0000-0000-000000000000'
    },
    allowRepresentation () {
      let allow = false
      allow = this.messageType === 7 && this.reasonCode === '0'
      return allow
    },
    allowSendNew0N () {
      let allow = false
      for (var s = 0; s < this.allowNew0NStatae.length; s++) {
        if (this.allowNew0NStatae[s] === this.statusReason) {
          allow = true
        }
      }
      return allow && this.alertMessageId !== undefined && this.alertMessageId !== null
    },
    activeStatus () {
      let activeStatus = [0,2]
      return activeStatus.includes(this.payerStatus)
    },
    statusText () {
      return statusToText(this.payerStatus)
    },
    planInfo () {
      var plan = { planId: null, ignoreWarnings: false }
      if (this.metaData !== null) {
        plan = { ...plan, ...JSON.parse(this.metaData) }
      }
      return plan
    },
    canRequestRefund () {
      return this.paymentComposite !== ''
    },
    hasRefunds () {
      return this.$store.getters.hasLicence('LicenceCollectionsRefunds') && !this.$store.getters.isInRole(roles.SystemUser)
    },
    canRefund () {
      return this.$store.getters.isInRoles([roles.LZAdmin, roles.Implementations, roles.ClientAdmin]) && this.v11
    },
    v11 () {
      return parseInt(process.env.VUE_APP_VERSION) >= 110
    },
    isSystemUser () {
      return this.$store.getters.isInRole(roles.SystemUser)
    },
    canViewAlerts () {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Finance])
    }
  },
  async mounted () {
    try {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.id}/history/${this.historyId}`, { showload: true, params: { paygateId: this.paygateId } })
      if (response) {
        var history = response.data.history
        var envelope = response.data.envelope
        this.reasonCode = history.reasonCode
        this.title = history.title
        this.name = history.payer.payerDetails.firstName + ' ' + history.payer.payerDetails.surname
        this.reference = history.payer.reference
        this.statusReason = history.payer.statusReason
        this.messageType = history.payerHistoryMessageType
        this.generationDate = history.generationDate
        this.body = history.body
        this.refundRequested = history.refundRequested
        this.paymentComposite = history.paymentComposite
        this.originalProcessingDate = history.originalProcessingDate
        this.suspensionReason = history.suspensionReason
        this.messageIsLetter = history.messageIsLetter
        this.payerMessageId = history.payerMessageId
        this.alertMessageId = history.alertMessageId
        this.source = history.source
        this.userName = history.userName
        this.represented = history.represented
        this.payerStatus = history.payer.status
        if(this.originalProcessingDate !== undefined && this.originalProcessingDate !== null) {
          let date = this.originalProcessingDate
          if(date.getTime == null) {
            date = new Date(date)
          }
          let now = new Date()
          let diff = now.getTime() - date.getTime()
          let dayDiff = diff / (1000 * 3600 * 24)
          this.canRepresent = dayDiff < 30
        }



        let msg = null
        try {
          msg = JSON.parse(history.messageData)
        } catch (e) {
          console.log(e)
        }
        if (msg !== null && msg !== undefined) {
          this.messageData = { title: msg.Title !== undefined ? msg.Title : msg.title, body: msg.Body !== undefined ? msg.Body : msg.body }
        }
        else {
          this.messageData = null
        }
        if (envelope !== null && envelope !== undefined) {
          this.circumstances = envelope.circumstances
          this.instructions = envelope.instructions
          this.paygateAction = envelope.payGateAction
        }
        this.alertMessageId = history.alertMessageId
        this.metaData = history.metaData
        if (this.hasRefunds && this.canRefund) {
          await this.checkIfRefundable()
        }
      }
    } catch(err) {
      console.log(err)
      this.$toastr.e('An error occurred retrieving the history item.')
    }
  },
  methods: {
    sourceToText (sourceCode) {
      let retVal = ''
      switch (sourceCode) {
        case 0:
          retVal = 'System'
          break
        case 1:
          retVal = 'User'
          break
        case 2:
          retVal = 'Api'
          break
      }
      return retVal
    },
    async queue0N () {
      try {
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}pushauddis/${this.id}/00000000-0000-0000-0000-000000000000`, { code: '0N', alertId: this.alertMessageId }, { params: { paygateId: this.paygateId } })
        this.alertMessageId = null
        this.$toastr.s('Queued AUDDIS message successfully.')
      } catch (e) {
        this.$toastr.e('Failed to queue AUDDIS instruction.')
      }
    },
    async representCollection (e) {
      axios.post(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.id}/history/${this.historyId}/represent`, {}, { showload: true, params: { paygateId: this.paygateId } })
        .then((response) => {
          if (response.data.message) {
            var exceeded = response.data.maxAmountPerCollectionExceeded ||
              response.data.maxAmountPerMonthExceeded ||
              response.data.payerCollectionCountPerMonthExceeded ||
              response.data.maxCollectionCountPerMonthExceeded
            if (exceeded) {
              let message = 'Secure Limits Exceeded: '
              let detailsArray = []
              if(response.data.maxAmountPerCollectionExceeded) {
                detailsArray.push('maximum amount per Collection')
              }
              if(response.data.maxAmountPerMonthExceeded){
                detailsArray.push('maximum Collection amount per month')
              }
              if(response.data.payerCollectionCountPerMonthExceeded){
                detailsArray.push('maximum Collection count per month')
              }
              if(response.data.maxCollectionCountPerMonthExceeded){
                detailsArray.push('maximum Collection count per Customer per month')
              }
              message += detailsArray.join(', ')
              this.$toastr.e(message)
            } else {
              this.$toastr.e(response.data.message)
            }
          }
          else if (response.data.alreadyRepresented) {
            this.$toastr.e('This collection has already been re presented')
            this.canRepresent = false
          }
          else {
            this.$toastr.s('Collection Re Presented')
            this.canRepresent = false
            this.represented = true
            this.statusReason = -1
          }
        })
        .catch((err) => {
          console.log(err)
          this.$toastr.e('An error occurred Re Presenting the Collection.')
        })
    },
    getSuspensionReason (reasonInt) {
      let retVal = ''
      switch (reasonInt) {
        case 0:
          retVal = 'They were not suspended.'
          break
        case 1:
          retVal = 'We do not know why they were suspended.'
          break
      }
      return retVal
    },
    getTextFromMessageType (typeCode) {
      let retVal = ''
      switch (typeCode) {
        case 0:
          retVal = 'AUDDIS'
          break
        case 1 :
          retVal ='First Collection'
          break
        case 2 :
          retVal ='Regular Collection'
          break
        case 3 :
          retVal ='Last Collection'
          break
        case 4 :
          retVal ='Re-Presentation Collection'
          break
        case 5 :
          retVal ='Account'
          break
        case 6 :
          retVal ='Collection Error'
          break
        case 7 :
          retVal ='ARUDD'
          break
        case 8 :
          retVal ='ADDACS'
          break
        case 9 :
          retVal ='AWACS'
          break
        case 10 :
          retVal ='DDIC'
          break
        case 11 :
          retVal ='Message Sent'
          break
        case 12 :
          retVal ='Scheduled Regular Amount Change'
          break
        case 13 :
          retVal ='Ad-Hoc Customer Expiry'
          break
        case 14 :
          retVal ='Portal Message Sent'
          break
        case 15 :
          retVal ='Portal Message Receieved'
          break
        case 16 :
          retVal ='Refund Requested'
          break
        case 17 :
          retVal ='Status Changed'
          break
        case 18 :
          retVal ='Schedule Status Changed'
          break
        case 19 :
          retVal ='Collection Added'
          break
        case 20 :
          retVal ='Schedule Added'
          break
        case 21 :
          retVal ='New Indemnity Advice'
          break
        case 22 :
          retVal ='New Indemnity Cancellation'
          break
        case 23 :
          retVal ='Customer Updated'
          break
        case 24 :
          retVal ='Collection Not Processed'
          break
        case 25 :
          retVal ='Refund Cancelled'
          break
        case 26 :
          retVal ='Schedule Changed'
          break
        case 27 :
          retVal ='Schedule Removed'
          break
        case 28 :
          retVal = 'Collection Removed'
          break
      }
      return retVal
    },
    async deleteAlert (e) {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}alerts/${this.alertMessageId}`, { showload: true, params: { paygateId: this.paygateId } }).then(async () => {
        axios.get(`${process.env.VUE_APP_DDMS_API_URL}alerts/`, { showload: true, params: { paygateId: this.paygateId } }).then((response) => {
          this.$toastr.s('Alert deleted')
          this.alertMessageId = null
          this.$store.dispatch('updateCurrentCollectionsAlertCount')
          //this.$router.push('/collections/alerts')
          this.$router.go(-1)
        })
      }).catch((e) => { this.$toastr.e('Could not delete the alert') })
      this.$store.dispatch('updateCurrentCollectionsAlertCount')
    },
    async setToIgnore (e) {
      await axios.post(`${process.env.VUE_APP_DDMS_API_URL}plan/${this.planInfo.planId}/ignore/${this.historyId}`, {}, { showload: true, params: { paygateId: this.paygateId } })
      this.metaData = `{ "planId": "${this.planInfo.planId}", "ignoreWarnings": true }`
    },
    formatDate (date) {
      if (date === null) {
        return 'N/A'
      }
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    async checkIfRefundable () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}amendment/refund/check/${this.historyId}`, { params: { paygateId: this.paygateId } })
      if (response) {
        this.refundable = response.data
      }
    },
    async saveFile () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}messages/letter/${this.payerMessageId}`, { params: { paygateId: this.paygateId } })
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(this.base64ImageToBlob(response.data))
      a.download = `${this.getTextFromMessageType(this.messageType)} - ${this.formatDate(this.generationDate)}.pdf`
      // Append anchor to body.
      document.body.appendChild(a)
      a.click();
      // Remove anchor from body
      document.body.removeChild(a)
    },
    base64ImageToBlob (str) {
      // extract content type and base64 payload from original string
      var imageContent = atob(str)

      // create an ArrayBuffer and a view (as unsigned 8-bit)
      var buffer = new ArrayBuffer(imageContent.length)
      var view = new Uint8Array(buffer)

      // fill the view, using the decoded base64
      for(var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }

      // convert ArrayBuffer to Blob
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessing' });
      return blob;
    },
    async initiateRefund () {
      var result = null
      try {
        result = await swal.fire({
          title: 'Refund Collection',
          text: `Are you sure you want this Collection to be refunded?`,
          type: 'warning',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.success,
          confirmButtonText: 'Create Refund',
          cancelButtonText: 'Cancel',
          width: '40%'
        })
      } catch (err) {
        console.log(err)
        result = false
      }
      if (result.isConfirmed) {
        try {
          var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}amendment/refund`, { paymentComposite: this.paymentComposite, payerHistoryId: this.historyId }, { showload: true, params: { paygateId: this.paygateId } })
          if (response && response.data) {
            if (response.data.errorMessages.length == 0) {
              this.$toastr.s('Refund requested.')
              this.refundRequested = true
            }
            else {
              this.refundRequested = false
              this.$toastr.e(response.data.errorMessages[0])
            }
          }
        } catch  {
          this.$toastr.e('Could not initate refund process.')
        }
      }
    }
  }
}
</script>
