<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2><help-icon docPath="/paygate-collections/plans/plansuppression/" />Schedule Suppression<favourite-icon></favourite-icon></h2>
    </div>
    <div>
      <div class="m-3">
        <div class="row form-group">
          <div class="col-md-2 required">
            Select a Group
          </div>
          <div class="col-md-4">
            <group-select
              v-model="group"
              :groups="customerGroups"
              @input="getPlans"
              :clearable="false"
            ></group-select>
            </div>
        </div>
        <b-row  class="planRow">
            <b-tabs vertical nav-wrapper-class="col-3 vertical-tab-container" nav-class="w-90 vertical-tab-list ml-2" v-model="activeTabIndex">
              <template slot="empty">
                    There are no templates associated with this group.
              </template>
              <b-tab v-for="(paymentPlan, index) in paymentPlans" :key="index" @click="planSelected(paymentPlan, index)" :title="paymentPlan.name">
                <vue-good-table
                :columns="columns"
                :rows="paymentPlan.planSuppressions"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :rowStyleClass="rowStyler"
                :search-options="{
                enabled: false
                }"
                :pagination-options="{
                enabled: false
              }"
                @on-cell-click="onCellClick"
                styleClass="vgt-table striped bordered"
                ref="futurePlanTable"
              >
                <template slot="loadingContent">
                  <h1>Loading...</h1>
                </template>
                <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">No Suppressions available</div>
                </div>
                <div slot="table-actions">
                  <b-button
                    @click.prevent="addSuppression"
                    :disabled="!canEdit"
                    class
                    variant="link"
                    v-b-popover.hover.top.d500="'Add Suppression'"
                  >
                    <i class="fa fa-plus pointer dimmedIcon"></i>
                  </b-button>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="editRowIndex == props.index">
                    <span v-if="props.column.field === 'startDate'">
                      <datepicker v-model="props.row.startDate" format="dd/MM/yyyy" :disabledDates="{ from: new Date(props.row.endDate), to: new Date() }" />
                    </span>
                    <span v-if="props.column.field === 'endDate'">
                      <datepicker v-model="props.row.endDate" format="dd/MM/yyyy" :disabledDates="{ to: new Date(props.row.startDate) }" />
                    </span>
                    <span v-if="props.column.label === ''">
                      <b-button variant="primary" @click.self.stop.prevent="saveSupp(props)">
                        <i class="fa fa-check mr-1"></i>Confirm
                      </b-button>
                      <b-button variant="outline-warning" @click.self.stop.prevent="cancelSupp(props)">
                        <i class="fa fa-times mr-1"></i>Cancel
                      </b-button>
                      <b-button variant="outline-danger" @click.self.stop.prevent="deleteSupp(props)">
                        <i class="fa fa-times mr-1"></i>Delete
                      </b-button>
                    </span>
                  </span>
                  <span v-else>
                    <span v-if="props.column.label == ''">
                      <span :id="'locked'+props.index" v-if="props.row.isLocked">
                        <i class="fa fa-lock"></i>
                      </span>
                      <span :id="'unlocked'+props.index" v-if="!props.row.isLocked">
                        <i class="fa fa-lock-open"></i>
                      </span>
                      <!-- Tooltip title specified via prop title -->
                      <b-tooltip :target="props.row.isLocked ? 'locked'+props.index : 'unlocked'+props.index" :title="props.row.isLocked ? 'Cannot be edited, as suppresion has already been applied' : 'Can be edited'"></b-tooltip>
                    </span>
                    <span>{{props.formattedRow[props.column.field]}}</span>
                  </span>
                </template>
              </vue-good-table>
                <b-btn id="addSuppressionButton" :disabled="!canEdit" class="mt-4" type="link" variant="primary" @click="addSuppression">
                  <i class="fa fa-plus mr-2"></i>Add Suppression
                </b-btn>
              </b-tab>
            </b-tabs>
        </b-row>
      </div>
    </div>
    <div class="idb-block-footer">
      <b-button v-if="selectedPaymentPlan !== null" variant="primary" :disabled="isLoading || !canEdit" @click="savePlanTemplate"><i class="fa fa-save rpad" aria-hidden="true"></i>Save</b-button>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import EventBus from '@/Lib/eventBus'
import Datepicker from 'vuejs-datepicker'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'
import {mapGetters} from "vuex";
import roles from "@/Assets/Constants/roles";
export default {
  mixins: [loading],
  components: {
    Datepicker
  },
  data () {
    return {
      group: '00000000-0000-0000-0000-000000000000',
      suppressions: [],
      columns: [
        {
          label: 'Start Date',
          field: 'startDate',
          formatFn: this.formatDate
        },
        {
          label: 'End Date',
          field: 'endDate',
          formatFn: this.formatDate
        },
        {
          label: '',
          field: 'control'
        }
      ],
      dateOptions: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        stepping: 30,
        sideBySide: true,
      },
      paymentPlans: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: 'startDate', type: 'asc' }],
        page: 1,
        perPage: 10,
        searchKey: ''
      },
      editRowIndex: -1,
      selectedPaymentPlan: {
        reference: '',
        recurrenceEndType: 0,
        recurrenceType: 0,
        ordinal: 1,
        firstAmount: 0,
        regularAmount: 0,
        totalPayments: 0,
        firstPaymentDate: new Date(),
        giftAid: false,
        variable: false,
        dayOfYear: 1 },
      selectedPlanTemplateId: null,
      selectedPlan: null,
      activeTabIndex: 0
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    canEdit() {
      return this.$store.getters.isInRoles([roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard ])
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.rightsGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.rightsGroups).map(x => {
          let obj = {}
          obj.groupId = x.groupId
          obj.name = x.name
          obj.colour = x.colour
          return obj
        })
      }
      ret.unshift({
        'description': '',
        'groupId': '00000000-0000-0000-0000-000000000000',
        'groupType': 'UK DDMS',
        'name': 'Shared Templates',
        'paygateId': this.paygateId,
        'isItemActioned': false,
        'clonedName': null,
        'colour': null
      })
      return ret
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    }
  },
  async created (e) {
    await this.$store.dispatch('populateAllCollectionsGroups')
    this.$store.dispatch('getNonProcessingDates')
  },
  async mounted (e) {
    this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
    this.group = '00000000-0000-0000-0000-000000000000'
    this.getPlans()
  },
  methods: {
    loadGroups (paygateId) {
      this.$store.dispatch('loadCustomerGroups', this.paygateId)
    },
    async customerGroupsChanged (e) {
      this.selectedPaymentPlan = null
      this.selectedPlan = null
      await this.getPlans()
    },
    async getPlans () {
      var groupId = this.group === null ? '00000000-0000-0000-0000-000000000000' : this.group
      var resp = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/group/${groupId}`, { params: { 'paygateid': this.paygateId }, showload: true })
      this.paymentPlans = resp.data
      if (this.paymentPlans.length > 0) {
        this.planSelected(this.paymentPlans[0])
      }
    },
    planSelected (plan, index) {
      this.suppressions = []
      this.selectedPlan = plan
      this.selectedPaymentPlan = plan
      this.selectedPlanTemplateId = plan.paymentPlanTemplateId
      if (plan.templateJson !== 'undefined') {
        if(typeof plan.templateJson !== 'object') {
          this.selectedPlan = JSON.parse(plan.templateJson)
        } else {
          this.selectedPlan = plan.templateJson
        }
      }
      if (!this.selectedPaymentPlan.planSuppressions) {
        this.selectedPaymentPlan.planSuppressions = []
      }
      this.suppressions = _.clone(this.selectedPaymentPlan.planSuppressions)
      console.log(this.suppressions)
      var now = new Date()
      now.setHours(0,0,0,0)
      for(var i=0; i < this.suppressions.length; i++){
        this.suppressions[i].isLocked = new Date(this.suppressions[i].startDate) < now || new Date(this.suppressions[i].endDate) < now
      }
      this.activeTabIndex = index
    },
    addSuppression () {
      var newSuppression = {
        ukDdmsPlanSuppressionId: '00000000-0000-0000-0000-000000000000',
        planTemplateId: this.selectedPlanTemplateId,
        startDate: new Date(),
        endDate: new Date(),
        isLocked: false
      }
      this.selectedPaymentPlan.planSuppressions.push(newSuppression)
      this.editRowIndex = this.selectedPaymentPlan.planSuppressions.length - 1
    },
    reloadItems: async function () {

    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onPageChange (params) {

    },
    onSortChange (params) {

    },
    onColumnFilter (params) {
      console.log('onColumnFilter not yet implemented')
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    onPerPageChange (params) { },
    onRowClick (params) {
    },
    onCellClick (params) {
      if (this.editRowIndex !== params.rowIndex) {
        if (!this.isLocked(params.row)) {
          this.oldVersion = _.clone(params.row)
          this.editRowIndex = params.rowIndex
        }
      }
    },
    onSearch (params) {

    },
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    rowStyler (row) {
      let now = new Date()
      return row.status === 10 && (row !== this.selectedRow) ? 'deleted' : row.startDate < now || row.endDate < now ? '' : 'clickableRow'
    },
    saveSupp (e) {
      var now = new Date()
      this.selectedPaymentPlan.planSuppressions[e.index] = e.row
      this.selectedPaymentPlan.planSuppressions[e.index].isLocked = e.row.startDate < now || e.row.endDate < now
      // this.selectedPaymentPlan.planSuppressions[e.index] = this.suppressions[e.index]
      this.editRowIndex = -1
      this.oldVersion = null
    },
    cancelSupp (e) {
      console.log(this.oldVersion)
      this.selectedPaymentPlan.planSuppressions.splice(this.editRowIndex, 1)
      if (this.oldVersion !== undefined && this.oldVersion !== null) {
        this.selectedPaymentPlan.planSuppressions.splice(this.editRowIndex, 0, this.oldVersion)
      }
      this.editRowIndex = -1
    },
    deleteSupp (e) {
      this.selectedPaymentPlan.planSuppressions.splice(e.index, 1)
      this.editRowIndex = -1
      this.oldVersion = null
    },
    async savePlanTemplate (e) {
      try {
        var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}plansuppression/${this.selectedPlanTemplateId}`, this.selectedPaymentPlan.planSuppressions, { showload: true, params: { paygateId: this.paygateId } })
        if (response) {
          console.log(response)
          this.$toastr.s('Saved')
        }
      } catch (ex) {
        console.log(ex)
        this.$toastr.e('An exception occurred saving the future changes.')
      }
    },
    isLocked (row) {
      var now = new Date()
      console.log(row.startDate)
      console.log(row.endDate)
      return row.startDate < now || row.endDate < now
    }
  },
  watch: {
    selectedCustomer () {
      this.group = '00000000-0000-0000-0000-000000000000'
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      this.load()
    }
  }
}
</script>
<style>
.vertical-tab-list {
  border-bottom: solid 0px black !important;
  border-right: 0px solid #ddd;
}

.vertical-tab-container {
  border-bottom: solid 0px black !important;
  border-right: 1px solid #ddd;
  padding-right: 0px;
}

.vertical-tab-list .nav-item .active, .vertical-tab-list .nav-item .nav-link:hover {
  border: 1px solid #ddd;
  border-right: solid 1px transparent;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0rem;
  border-top-right-radius: 0rem;
  margin: 0
}

.planRow .tabs {
  width: 100%
}

.clickableRow {
  cursor: pointer;
}

</style>
