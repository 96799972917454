<template>
  <div id="audits">
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Charges</h2>
      </div>
      <div class="idb-block-content">
        <div class="row mb-1">
          <div class="col-md-2">
            <div class="form-group">
              <label class="form-label">From Date</label>
              <div class="input-group date">
                <date-picker
                  v-model="fromDate"
                  :config="fromDateOptions"
                  @dp-change="load"
                  :wrap="true"
                ></date-picker>
                <div class="input-group-addon">
                  <span class="far fa-calendar"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="form-label">To Date</label>
              <div class="input-group date">
                <date-picker
                  v-model="toDate"
                  :config="toDateOptions"
                  @dp-change="load"
                  :wrap="true"
                ></date-picker>
                <div class="input-group-addon">
                  <span class="far fa-calendar"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="form-label">
                <br />
              </label>
              <button
                style="display:block"
                class="btn btn-info"
                type="button"
                @click="resetDates"
              >Reset Dates</button>
            </div>
          </div>
        </div>
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <template slot="table-row" slot-scope="props">
            <span
              v-if="props.column.field === 'serviceCharge' || props.column.field === 'vatAmount'"
            >
              <span v-if="props.row.rate === undefined">
                <span
                  v-if="props.row.isManual && props.column.field === 'serviceCharge'"
                  :class="tdClass(props.row, props.column.field)"
                >{{props.formattedRow[props.column.field]}}</span>
                <span v-else>TBD</span>
              </span>
              <span
                v-else
                :class="tdClass(props.row, props.column.field)"
              >{{props.formattedRow[props.column.field]}}</span>
            </span>
            <span v-else-if="props.column.field == 'invoiced'">
              <tick v-model="props.row.invoiced"></tick>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
          <div slot="table-actions">
            <table-actions
              :data-url="dataUrl"
              :export-data="tableServerData"
              :print-enabled="false"
              :table-amount="totalRecords"
              :export-name="exportName"
              :reload-table="load"
              :table-columns="columns"
              :clear-table-filters="clearTableFiltersLocal"
            ></table-actions>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer" v-if="canCreate">
        <button class="btn btn-primary" @click="create" type="button">
          <i class="fa fa-plus rpad"></i>Create Charge
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'

// Components
import datePicker from '@/Assets/Components/DateTimePicker/DatePicker'

// Mixins
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'

// Constants
import { billingEnum as billing } from '@/Assets/Constants/billing'
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [tableFilterMixin, settlementMixin],
  components: {
    datePicker
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}Charge/List/`
    },
    tableServerData () {
      return this.buildGoodTableQuery()
    },
    fromDateOptions () {
      return {
        ...this.dateOptions,
        maxDate: this.toDate
      }
    },
    toDateOptions () {
      return {
        ...this.dateOptions,
        minDate: this.fromDate
      }
    },
    exportName () {
      return `${moment(this.fromDate).format('DD/MM/YYYY')}-${moment(this.toDate).format('DD/MM/YYYY')}-charges-export`
    },
    canCreate () {
      return this.$store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations])
    }
  },
  data () {
    return {
      fromDate: moment().subtract(1, 'months').millisecond(0).second(0).minute(0).hour(0),
      toDate: moment().millisecond(999).second(59).minute(59).hour(23),
      dateOptions: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        sideBySide: true
      },
      rows: [],
      columns: [
        {
          label: 'Type',
          field: 'type',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter by type',
            filterDropdownItems: Object.values(billing)
          }
        },
        {
          label: 'User',
          field: 'user',
          sortable: true
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy HH:mm'
        },
        {
          field: 'charge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number',
          hidden: true
        },
        {
          label: 'Settlement Status',
          field: 'settlementStatus'
        },
        {
          label: 'Billing Status',
          field: 'billingStatus'
        },
        {
          label: 'Invoiced',
          field: 'invoiced'
        },
        {
          label: 'VAT (£)',
          field: 'vatAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number',
          sortable: true
        },
        {
          label: 'Service Charge (£)',
          field: 'serviceCharge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number',
          sortable: true
        }
      ],
      serverParams: {
        sort: [{ field: 'createddate', type: 'desc' }]
      },
      paginationOptions: {
        dropdownAllowAll: false,
        mode: 'pages'
      }
    }
  },
  watch: {
    selectedCustomer () {
      this.$refs.table.currentPage = 1
      this.$refs.table.$refs.paginationBottom.currentPage = 1
      this.serverParams.page = 1
      this.$refs.table.reset()
      this.load()
    }
  },
  methods: {
    async load () {
      try {
        this.isTableLoading = true

        var response = await axios.get(this.dataUrl, {
          params: {
            ...this.tableServerData
          },
          showerror: true,
          errormessage: 'Failed loading billing usage'
        })
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    onRowClick (params) {
      var id = params.row.id
      this.$router.push({ name: 'ChargeDetail', params: { id, showBackButton: 'true' } })
    },

    buildGoodTableQuery () {
      const query = { ...this.serverParams.columnFilters }
      query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }
      query.fromDate = this.fromDate.toISOString()
      query.toDate = moment(this.toDate).hour(23).minute(59).second(59).millisecond(999).toISOString()

      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
      return query
    },
    resetDates () {
      // Reset dates
      this.fromDate = moment().subtract(1, 'months').second(0).minute(0).hour(0).toDate()
      this.toDate = moment().second(59).minute(59).hour(23).toDate()
    },
    create () {
      this.$router.push({ name: 'ChargeCreate' })
    },
    clearTableFiltersLocal () {
      this.clearTableFilters() && this.resetDates()
    }
  }
}

</script>
<style lang="scss">
</style>
