import axios from 'axios'
import moment from 'moment'
const state = {
  groupConfig: {},
  allGroups: null,
  minDate: null,
  minDateLetter: null,
  minDateNoDdi: null,
  minLetterNoDdi: null,
  minUntil: null,
  minUntilLetter: null,
  minUntilNoDdi: null,
  minUntilLetterNoDdi: null,
  secureLimits: {
    id: '',
    name: 'not set',
    maxAmountPerCollection: 0,
    maxAmountPerMonth: 0,
    maxCollectionCountPerMonth: 0,
    maxCollectionCountPerPayer: 0,
    maxRefundAmount: 0,
    maxRefundAmountPerMonth: 0
  }
}

const mutations = {
  setGroupConfig (state, groupConfig) {
    state.groupConfig = groupConfig
  },
  setAllGroups (state, groups) {
    state.allGroups = groups
  },
  setMinDates (state, data) {
    state.minDate = new Date(data.min.split('T')[0])
    state.minDateLetter = new Date(data.minLetter.split('T')[0])
    state.minDateNoDdi = new Date(data.minNoDdi.split('T')[0])
    state.minLetterNoDdi = new Date(data.minLetterNoDdi.split('T')[0])
    state.minUntil = new Date(data.minUntil)
    state.minUntilLetter = new Date(data.minUntilLetter)
    state.minUntilNoDdi = new Date(data.minUntilNoDdi)
    state.minUntilLetterNoDdi = new Date(data.minUntilLetterNoDdi)
  },
  setSecureLimits(state, data) {
    state.secureLimits.id = data.id
    state.secureLimits.name = data.name
    state.secureLimits.maxAmountPerCollection = isNaN(data.maxAmountPerCollection) ? 0 : data.maxAmountPerCollection
      state.secureLimits.maxAmountPerMonth = isNaN(data.maxAmountPerMonth) ? 0 : data.maxAmountPerMonth
      state.secureLimits.maxCollectionCountPerMonth = isNaN(data.maxCollectionCountPerMonth) ? 0 : data.maxCollectionCountPerMonth
      state.secureLimits.maxCollectionCountPerPayer = isNaN(data.maxCollectionCountPerPayer) ? 0 : data.maxCollectionCountPerPayer
      state.secureLimits.maxRefundAmount = isNaN(data.maxRefundAmount) ? 0 : data.maxRefundAmount
      state.secureLimits.maxRefundAmountPerMonth = isNaN(data.maxRefundAmountPerMonth) ? 0 : data.maxRefundAmountPerMonth
  }
}

const getters = {
  collectionsGroupConfig: (state) => {
    return state.groupConfig
  },
  collectionsAllGroups: (state) => {
    return state.allGroups
  },
  collectionsMinDate: (state) => {
    return state.minDate
  },
  collectionsMinDateLetter: (state) => {
    return state.minDateLetter
  },
  collectionsMinDateNoDdi: (state) => {
    return state.minDateNoDdi
  },
  collectionsMinDateLetterNoDdi: (state) => {
    return state.minLetterNoDdi
  },
  collectionsMinUntil: (state) => {
    return state.minUntil
  },
  collectionsMinUntilLetter: (state) => {
    return state.minUntilLetter
  },
  collectionsMinUntilNoDdi: (state) => {
    return state.minUntilNoDdi
  },
  collectionsMinUntilLetterNoDdi: (state) => {
    return state.minUntilLetterNoDdi
  },
  collectionsSecureLimitsLookup: (state) => {
    return state.secureLimits
  }
}

const actions = {
  getGroupConfigurationFromGroupId: async ({ commit, rootState }, request) => {
    var result = null
    try {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}config/${request.id}`)
      result = response.data
      commit('setGroupConfig', response.data)
    } catch (err) {
      result = { error: true, exception: err }
    }
    return result
  },
  getGroupConfigurationFromPayerId: async ({ commit, rootState }, request) => {
    var result = null
    try {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}config/payer/${request.id}`)
      result = response.data
      commit('setGroupConfig', response.data)
    } catch (err) {
      result = { error: true, exception: err }
    }
    return result
  },
  populateAllCollectionsGroups: async ({ commit }, request) => {
    var result = null
    try {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}config`, { showLoad: true })
      result = response.data
      commit('setAllGroups', response.data)
    } catch (err) {
      result = { error: true, exception: err }
    }
    return result
  },
  getAdvancedNoticeSettings: async ({ commit, rootState }, request) => {
    let result = null
    let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}config/mindates`, { params: { paygateId: rootState.common.paygateId }, showLoad: true })
    commit('setMinDates', response.data)
    return response.data
  },
  getAdvancedNoticeSettingsForPayer: async ({ commit, rootState }, request) => {
    let result = null
    let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}config/mindates/${request.ukPayerId}`, { params: { paygateId: rootState.common.paygateId }, showLoad: true })
    commit('setMinDates', response.data)
    return response.data
  },
  getSecureLimits: async ({ commit, rootState }, request) => {
    let result = null
    let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}securelimit`, { params: { paygateId: rootState.common.paygateId }, showLoad: true })
    commit('setSecureLimits', response.data)
    return response.data
  }
}
export default { actions, state, mutations, getters }
