<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Submission History</h2>
      </div>
      <div class="idb-block-content">
        <div class="vgt-wrap">
          <table class="vgt-table striped ia-table border-0">
            <tbody>
              <tr><td>Instruction Count</td><td>{{instructionCt}}</td></tr>
              <tr><td>Debit Value</td><td>{{formatAmount(debitVal)}}</td></tr>
              <tr v-if="hasRefunds"><td>Credit Value</td><td>{{formatAmount(creditVal)}}</td></tr>
              <tr><td>Submission Date</td><td>{{formatDate(createdDt)}}</td></tr>
              <tr><td>Collection Date</td><td>{{formatDate(collectionDt)}}</td></tr>
              <tr><td>Payment Date</td><td>{{formatDate(paymentDt)}}</td></tr>
              <tr><td>Settlement Date</td><td>{{formatDate(settlementDt)}}</td></tr>
              <tr><td>Indemnity Count</td><td>{{indemnityCt}}</td></tr>
              <tr><td>Indemnity Value</td><td>{{formatAmount(indemnityVal)}}</td></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Submission History Data</h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          @on-row-click="onRowChange"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'hasIndemnity'">
                <i v-if="props.row.hasIndemnity" class="fa fa-check text-success"></i>
                <i v-if="!props.row.hasIndemnity" class="fa fa-minus"></i>
            </span>
            <span v-else-if="props.column.field === 'isBeingChallenged'">
                <i v-if="props.row.isBeingChallenged" class="fa fa-check  text-success"></i>
                <i v-if="!props.row.isBeingChallenged" class="fa fa-minus"></i>
            </span>
            <span v-else-if="props.column.field === 'hasCancelledIndemnity'">
                <i v-if="props.row.hasCancelledIndemnity" class="fa fa-check text-success"></i>
                <i v-if="!props.row.hasCancelledIndemnity" class="fa fa-minus"></i>
            </span>
            <span v-else>
                {{props.formattedRow[props.column.field]}}
            </span>
            </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import papa from 'papaparse'
  import {mapGetters} from "vuex";
  export default {
    mixins: [tableFilterMixin, loading],
    props: { submissionId: String },
    computed: {
      ...mapGetters(['selectedCustomer']),
      hasRefunds () {
        return this.$store.getters.hasLicence('LicenceCollectionsRefunds') && this.creditSun
      },
      instructionCt () {
        return this.submission && this.submission.instructionCount !== null ? this.submission.instructionCount : 0
      },
      debitVal () {
        return this.submission && this.submission.debitValue !== null ? this.submission.debitValue : 0
      },
      creditVal () {
        return this.submission && this.submission.creditValue !== null ? this.submission.creditValue : 0
      },
      indemnityCt () {
        return this.submission && this.submission.indemnityCount !== null ? this.submission.indemnityCount: 0
      },
      indemnityVal () {
        return this.submission && this.submission.indemnityValue !== null ? this.submission.indemnityValue : 0
      },
      processingDt () {
        return this.submission && this.submission.processingDate !== null ? this.submission.processingDate: ''
      },
      collectionDt () {
        return this.submission && this.submission.collectionDate !== null ? this.submission.collectionDate: ''
      },
      settlementDt () {
        return this.submission && this.submission.settlementDate !== null ? this.submission.settlementDate: ''
      },
      createdDt() {
        return this.submission && this.submission.createdDate !== null ? this.submission.createdDate: ''
      },
      paymentDt () {
        return this.submission && this.submission.paymentDate !== null ? this.submission.paymentDate: ''
      }
    },
    data () {
      return {
        submission: {
            instructionCount: 0,
            debitValue: 0,
            creditValue: 0,
            processingDate: '-',
            collectionDate: '-',
            settlementDate: '-' ,
            createdDate: '-' ,
            paymentDate: '-' ,
            indemnityCount: 0,
            indemnityValue: 0
        },
        rows: [],
        columns: [
          {
            label: 'id',
            field: 'submissionId',
            hidden: true
          },
          {
            label: 'Reference',
            field: 'reference'
          },
          {
            label: 'Account Holder Name',
            field: 'accountHoldersName'
          },
          {
            label: 'Amount',
            field: 'amount',
            formatFn: this.formatAmount
          },
          {
            label: 'Transaction Code',
            field: 'transactionCode'
          },
          {
            label: 'Has Indemnity',
            field: 'hasIndemnity',
            tdClass: 'text-center'
          },
          {
            label: 'Has Challenge',
            field: 'isBeingChallenged',
            tdClass: 'text-center'
          },
          {
            label: 'Challenge Succeeded',
            field: 'hasCancelledIndemnity',
            tdClass: 'text-center'
          }
        ],
        serverParams: {
          sort: [{ field: 'reference', type: 'asc' }]
        },
        creditSun: true
      }
    },
    mounted () {
      this.load()
    },
    methods: {
      load: async function () {
        try {
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}indemnitysubmissions/${this.submissionId}`, { params: { ...this.buildGoodTableQuery(), paygateId: this.paygateId } })
          if (response) {
            this.submission = response.data.submissionDetails
            this.rows = response.data.data
            this.totalRecords = response.data.count
            this.creditSun = response.data.canCredit
          }
        } catch (error) {
          this.$toastr.e(error)
        }
      },
      onRowChange (e) {
        console.log(e)
        this.$router.push({ name: 'PayerSchedule', params: { id: e.row.ukPayerId, planId: e.row.paymentPlanId } })
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
      formatStatus (value) {
        let retVal = ''
        switch(value) {
          case 1:
            retVal = 'Created'
            break
          case 2:
            retVal = 'In Progress'
            break
          case 3:
            retVal = 'Resolved'
            break
          case 4:
            retVal = 'Rejected'
            break
          case 5:
            retVal = 'Action Required'
            break
          default:
            retVal = 'None'
            break
        }
        return retVal
      },
      formatDate (date) {
        if(date === '-') {
          return 'N/A'
        }
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      async exportTable () {
        var query = this.buildGoodTableQuery()
        query.perPage = this.totalRecords
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}indemnitysubmissions/${this.submissionId}`, { params: { ...query, paygateId: this.paygateId, forExport: true } })
        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, `historical-submission-${this.submissionId}.csv`)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = `historical-submission-${this.submissionId}.csv`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
    },
    watch: {
      selectedCustomer () {
        this.group = '00000000-0000-0000-0000-000000000000'
        this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
        this.load()
      }
    }
  }
</script>
<style type="text/css">
.ia-table, .ia-table thead th td, .ia-table tr td {
  border-style: none;
}
.ia-table thead th, .ia-table thead th td {
  background: transparent;
  padding: 0;
}
</style>
