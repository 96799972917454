<template>
  <form @submit.prevent>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>{{status}} Secure Limits</h2>
      </div>

      <div class="idb-block-content">
        <!-- Name -->
        <div class="form-group row" :class="{ invalid: $v.secureLimits.name.$error }">
          <label class="col-form-label col-md-3 required">Name</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model.trim="$v.secureLimits.name.$model" />
            <!-- Validation -->
            <validation-messages v-model="$v.secureLimits.name"></validation-messages>
          </div>
        </div>

        <!-- Max Amount Per Collection -->
        <div
          class="form-group row"
          :class="{invalid: $v.secureLimits.maxAmountPerCollection.$error}"
        >
          <label class="label-control col-md-3 required">Max Amount Per Collection</label>
          <div class="col-md-3">
            <input
              type="numeric"
              class="form-control"
              min="0"
              step="1"
              v-model.trim="$v.secureLimits.maxAmountPerCollection.$model"
            />

            <!-- Validation -->
            <validation-messages
              v-model="$v.secureLimits.maxAmountPerCollection"
              name="max amount per collection"
            ></validation-messages>
          </div>
        </div>

        <!-- Max Amount Per Month -->
        <div class="form-group row" :class="{invalid: $v.secureLimits.maxAmountPerMonth.$error}">
          <label class="label-control col-md-3 required">Max Amount Per Month</label>
          <div class="col-md-3">
            <input
              type="numeric"
              class="form-control"
              min="0"
              step="1"
              v-model.trim="$v.secureLimits.maxAmountPerMonth.$model"
            />

            <!-- Validation -->
            <validation-messages
              v-model="$v.secureLimits.maxAmountPerMonth"
              name="max Amount Per Month"
            ></validation-messages>
          </div>
        </div>

        <!-- Max Collection Count Per Month -->
        <div
          class="form-group row"
          :class="{invalid: $v.secureLimits.maxCollectionCountPerMonth.$error}"
        >
          <label class="label-control col-md-3 required">Max Collection Count Per Month</label>
          <div class="col-md-3">
            <input
              type="numeric"
              class="form-control"
              min="0"
              step="1"
              v-model.trim="$v.secureLimits.maxCollectionCountPerMonth.$model"
            />

            <!-- Validation -->
            <validation-messages
              v-model="$v.secureLimits.maxCollectionCountPerMonth"
              name="max Collection Count Per Month"
            ></validation-messages>
          </div>
        </div>

        <!-- Max Collection Count Per Customer -->
        <div
          class="form-group row"
          :class="{invalid: $v.secureLimits.maxCollectionCountPerPayer.$error}"
        >
          <label class="label-control col-md-3 required">Max Collection Count Per Customer</label>
          <div class="col-md-3">
            <input
              type="numeric"
              class="form-control"
              min="0"
              step="1"
              v-model.trim="$v.secureLimits.maxCollectionCountPerPayer.$model"
            />

            <!-- Validation -->
            <validation-messages
              v-model="$v.secureLimits.maxCollectionCountPerPayer"
              name="max Collection Count Per Payer"
            ></validation-messages>
          </div>
        </div>

        <!-- Max Refund Amount -->
        <!-- <div class="form-group row" :class="{invalid: $v.secureLimits.maxRefundAmount.$error}">
          <label class="label-control col-md-3 required">Max Refund Amount</label>
          <div class="col-md-3">
            <input
              type="numeric"
              class="form-control"
              min="0"
              step="1"
              v-model.trim="$v.secureLimits.maxRefundAmount.$model"
            />

            <validation-messages v-model="$v.secureLimits.maxRefundAmount" name="Max Refund Amount"></validation-messages>
          </div>
        </div>-->

        <!-- Max Refund Amount Per Month -->
        <!-- <div
          class="form-group row"
          :class="{invalid: $v.secureLimits.maxRefundAmountPerMonth.$error}"
        >
          <label class="label-control col-md-3 required">Max Refund Amount Per Month</label>
          <div class="col-md-3">
            <input
              type="numeric"
              class="form-control"
              min="0"
              step="1"
              v-model.trim="$v.secureLimits.maxRefundAmountPerMonth.$model"
            />

            <validation-messages
              v-model="$v.secureLimits.maxRefundAmountPerMonth"
              name="Max Refund Amount Per Month"
            ></validation-messages>
          </div>
        </div>-->

        <secure-limits-linked-customers-table :customers="linkedCustomers"></secure-limits-linked-customers-table>
      </div>
      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          :disabled=" isLoading"
          type="submit"
          @click="checkValidation() && saveSecureLimits()"
        >{{ status === "Edit" ? "Save" : status }}</button>

        <button class="btn btn-outline-danger pull-right ml-3" type="button" @click="back">Cancel</button>
        <button class="btn btn-danger pull-right ml-3" type="button" @click="deleteSecurLimits">
          <i class="glyphicon ti-trash rpad"></i>Delete Secure Limits
        </button>
      </div>
    </div>
  </form>
</template>

<script>
// Components
import SecureLimitsLinkedCustomersTable from '@/Components/Shared/SecureLimitsLinkedCustomersTable'

// Constants
import colours from '@/Assets/Constants/colours'

// Third Party
import axios from 'axios'
import { required, decimal, integer } from 'vuelidate/lib/validators'
import swal from 'sweetalert2'

// Mixins
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  components: { SecureLimitsLinkedCustomersTable },
  mixins: [DataLeaveMixin, loading],
  props: ['status', 'id'],
  data () {
    return {
      secureLimits: {
        name: '',
        maxAmountPerCollection: null,
        maxAmountPerMonth: null,
        maxCollectionCountPerMonth: null,
        maxCollectionCountPerPayer: null,
        maxRefundAmount: 0,
        maxRefundAmountPerMonth: 0
      },
      linkedCustomers: [],
      currentMaxAmountPerCollection: null,
      currentMaxAmountPerMonth: null,
      currentMaxCollectionCountPerMonth: null,
      currentMaxCollectionCountPerPayer: null
    }
  },
  async mounted () {
    if (this.status === 'Edit') {
      await this.loadSecureLimits()
    }
  },
  methods: {
    async saveSecureLimits () {
      try {
        if (this.status === 'Create') {
          await axios.post(
            `${process.env.VUE_APP_PLATFORM_API_URL}securelimits`,
            this.secureLimits,
            { showload: true, showerror: true, errormessage: 'Secure limits failed to save' }
          )
          this.$v.$reset()

          this.$toastr.s('New Secure limits created', 'Created')
          this.$router.push({ name: 'ListSecureLimits' })
        } else {
          if (this.secureLimits.maxAmountPerCollection > this.currentMaxAmountPerCollection ||
            this.secureLimits.maxAmountPerMonth > this.currentMaxAmountPerMonth ||
            this.secureLimits.maxCollectionCountPerPayer > this.currentMaxCollectionCountPerPayer ||
            this.secureLimits.maxCollectionCountPerMonth > this.currentMaxCollectionCountPerMonth
          ) {
            const result = await swal.fire(
              {
                icon: 'warning',
                type: 'warning',
                title: 'Limits Exceeded',
                text: 'Some linked clients already exceed the specified limits, they will be unable to create new customers or edit existing customers.',
                showCancelButton: true,
                confirmButtonText: 'Continue anyway',
                cancelButtonText: 'Cancel',
                confirmButtonColor: colours.danger
              }
            )
            if (result.isConfirmed) {
              await this.update()
            }
          } else {
            await this.update()
          }
        }
      } catch { }
    },
    async update () {
      await axios.put(
        `${process.env.VUE_APP_PLATFORM_API_URL}securelimits`,
        this.secureLimits,
        { showload: true, showerror: true, errormessage: 'Secure limits failed to save' }
      )
      this.$toastr.s('Secure limits updated', 'Updated')
      await this.loadSecureLimits()
      this.$v.$reset()
    },
    async loadSecureLimits () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}securelimits/${this.id}`, { showload: true, showerror: true, errormessage: 'Failed to load secure limits' })
        this.secureLimits = response.data
        var linkedCustomersResponse = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}securelimit/customers/${this.id}`, { showload: true, showerror: true, errormessage: 'Failed to load secure limits' })
        this.linkedCustomers = linkedCustomersResponse.data.data

        if (this.linkedCustomers.length > 0) {
          // Get the highest current values.
          this.currentMaxAmountPerCollection = this.linkedCustomers.reduce((prev, current) => { return prev.maxAmountPerCollection > current.maxAmountPerCollection ? prev : current }).maxAmountPerCollection
          this.currentMaxAmountPerMonth = this.linkedCustomers.reduce((prev, current) => { return prev.maxAmountPerMonth > current.maxAmountPerMonth ? prev : current }).maxAmountPerMonth
          this.currentMaxCollectionCountPerMonth = this.linkedCustomers.reduce((prev, current) => { return prev.maxCollectionCountPerMonth > current.maxCollectionCountPerMonth ? prev : current }).maxCollectionCountPerMonth
          this.currentMaxCollectionCountPerPayer = this.linkedCustomers.reduce((prev, current) => { return prev.maxCollectionCountPerPayer > current.maxCollectionCountPerPayer ? prev : current }).maxCollectionCountPerPayer
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      } finally {
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    },
    async deleteSecurLimits () {
      try {
        await axios.delete(
          `${process.env.VUE_APP_PLATFORM_API_URL}securelimits/${this.id}`,
          { showload: true, showerror: true, errormessage: 'Secure limits failed to delete' }
        )
        this.$v.$reset()

        this.$toastr.s('Secure limits deleted', 'Deleted')
        this.$router.push({ name: 'ListSecureLimits' })
      } catch {
      }
    }

  },
  validations: {
    secureLimits: {
      name: { required },
      maxAmountPerCollection: { required, numeric: decimal },
      maxAmountPerMonth: { required, numeric: decimal },
      maxCollectionCountPerMonth: { required, integer },
      maxCollectionCountPerPayer: { required, integer }
      // maxRefundAmount: { required,  numeric },
      // maxRefundAmountPerMonth: { required,  numeric }
    }
  }
}
</script>
