<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Agent audit trail for
          <strong>{{Agent.name}}</strong>
        </h2>
      </div>

      <div class="idb-block-content">
        <div class="row">
          <div class="col-md-12">
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              :rows="rows"
              :columns="columns"
              :lineNumbers="true"
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              :search-options="{
                enabled: true
              }"
              styleClass="vgt-table striped bordered"
              ref="table"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'mapping'">
                  <b-row>
                    <b-col sm="12">
                      <model-list-select
                        :id="'mappingSelector-' + props.row.id"
                        :list="mappings"
                        option-value="value"
                        option-text="text"
                        v-model="props.row.mapping"
                        placeholder="Mapping"
                        :isDisabled="props.row.status !== 'Uploaded'"
                        @input="updateFileMapping(props.row)"
                      ></model-list-select>
                    </b-col>
                  </b-row>
                </span>
                <span v-else-if="props.column.field == 'actions'">
                  <b-button
                    v-if="props.row.eventType == 'ContactCreated'"
                    @click.stop="onGoToContact(props.row.contactId)"
                  >Go To Contact</b-button>

                  <b-button
                    v-else-if="props.row.eventType == 'ContactModified'"
                    @click.stop="onGoToContact(props.row.contactId)"
                  >Go To Contact</b-button>

                  <b-button
                    v-else-if="props.row.eventType == 'ContactAssociationCreated'"
                    @click.stop="onGoToAssociation(props.row.associationId)"
                  >Go To Association</b-button>
                  <b-button
                    v-else-if="props.row.eventType == 'ContactAssociationEdited'"
                    @click.stop="onGoToAssociation(props.row.associationId)"
                  >Go To Association</b-button>

                  <b-button
                    v-else-if="props.row.eventType == 'ManualCommissionCreated'"
                    @click.stop="onGoToCommissionEdit(props.row.manualCommissionId)"
                  >Go To Commission</b-button>
                  <b-button
                    v-else-if="props.row.eventType == 'StatementCreated'"
                    @click.stop="onGoToStatement(props.row.statementId)"
                  >Go To Commission</b-button>
                </span>
              </template>

              <div slot="table-actions">
                <button
                  @click.prevent="clearTableFilters"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Clear filters'"
                >
                  <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                  </span>
                </button>
                <button
                  @click.prevent="exportData"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Export Data'"
                >
                  <i class="fa fa-share-square pointer dimmedIcon"></i>
                </button>
                <button
                  @click.prevent="onCreateAssociation"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Create a new contact association'"
                >
                  <i class="fa fa-plus dimmedIcon pointer"></i>
                </button>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="onGoBackToAgent" type="button">
          <i class="fa fa-plus rpad"></i>Back To Agent
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import StatementStatusType from '@/Assets/Constants/commissionAgentStatementStatusType'
import AuditTrailEventType from '@/Assets/Constants/commissionAgentAuditTrailEventTypes'
import commissionAgentCommissionItemStatus from '../../../../Assets/Constants/commissionAgentCommissionItemStatus'
import commissionAgentAgentAuditTrailEvent from '@/Assets/Constants/CommissionAgentAgentAuditTrailEvent'

import VueSelect from 'vue-select'

export default {
  props: {
    id: String
  },
  components: { VueSelect },
  mixins: [loading, tableFilterMixin, bacsMixin],

  data () {
    return {
      Agent: {},
      rows: [],
      columns: [
        {
          label: 'id',
          field: 'id',
          hidden: true
        },
        {
          label: 'Serial',
          field: 'serialId'
        },
        {
          label: 'Event Type',
          field: 'eventType',
          formatFn: this.GetEventType,
          sortable: false
        },
        {
          label: 'Rubric',
          field: 'rubric'
        },

        {
          label: 'Date',
          field: 'createdDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: true
        },
        {
          field: 'actions',
          sortable: false
        }

        // {
        //   label: 'Related',
        //   field: 'commissionStatementStatus',
        //   type: 'StatementTypes',
        //    sortable: false
        // }
      ],
      serverParams: {
        sort: [{ field: 'serialid', type: 'desc' }]
      }

    }
  },
  async created () {
    console.log('audit trail agent loaded')
  },

  methods: {

    async load () {
      await this.getAgentAuditTrailData()
      await this.updateTable()
    },

    getAgentAuditTrailData: async function () {
      console.log('Getting Data')
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetAgent/${this.id}`,
          {
            params: { paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data for listing commissions'
          })
        this.Agent = response.data
        console.log('table data response', response)
      } catch (e) {
        console.log('e', e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    updateTable: async function () {
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetAgentAuditTrailTable/${this.id}`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data'
          })
        console.log('table response', response)

        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    onGoBackToAgent () {
      this.$router.push({ name: 'CommissionAgentAdministration', params: { id: this.id } })
    },
    GetEventType (field) {
      if (field === commissionAgentAgentAuditTrailEvent.AgentCreated) { return 'Agent Created' } else if (field === commissionAgentAgentAuditTrailEvent.AgentModified) { return 'Agent Modified' } else if (field === commissionAgentAgentAuditTrailEvent.AgentDeactivated) { return 'Agent Deactivated' } else if (field === commissionAgentAgentAuditTrailEvent.AgentReactivated) { return 'Agent Reactivated' } else if (field === commissionAgentAgentAuditTrailEvent.ContactCreated) { return 'Contact Created' } else if (field === commissionAgentAgentAuditTrailEvent.ContactModified) { return 'Contact Modified' } else if (field === commissionAgentAgentAuditTrailEvent.ContactAssociationCreated) { return 'Association Created' } else if (field === commissionAgentAgentAuditTrailEvent.ContactAssociationEdited) { return 'Association Edited' } else if (field === commissionAgentAgentAuditTrailEvent.ManualCommissionCreated) { return 'Commission Manually Created' } else if (field === commissionAgentAgentAuditTrailEvent.StatementCreated) { return 'Statement Created' }
    },

    // Table Buttons
    onGoToContact (contactId) {
      // Go To Contact
      console.log('Go To Contact', contactId)

      this.$router.push({
        name: 'CommissionAgentContactAdministrationFromAuditTrail',
        params: { agentid: this.id, contactid: contactId }
      })
    },
    onGoToAssociation (associationId) {
      // Go To Contact
      console.log('Go To associationId', associationId)

      this.$router.push({
        name: 'CommissionAgentViewAssociationFromAuditTrail',
        params: { associationid: associationId }
      })
    },
    onGoToCommissionEdit (manualCommissionId) {
      console.log('Go To manualCommissionId', manualCommissionId)
    },
    onGoToStatement (statementId) {
      console.log('Go To statementId', statementId)
    }

  }
}
</script>
