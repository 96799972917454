<template>
  <b-modal id="ledgerTxModal" v-model="isVisible" @hide="hide" @shown="setFocus" centered size="lg" no-close-on-backdrop>
    <div slot="modal-header" class="w-100">{{ modalTitle }}</div>
    <div v-if="isNewTx">
      <div class="row form-group">
        <div class="col-md-4 required">Applied Date</div>
        <div class="col-md-3" :class="{invalid: $v.createdDate.$error}">
          <vuejsDatepicker
            ref="createdDate"
            name="createdDate"
            id="createdDate"
            v-model="createdDate"
            format="dd/MM/yyyy"
            input-class="form-control"
            :bootstrap-styling="true"
            :disabledDates="disabledDates"
            v-on:selected="createdDateChanged"
            :monday-first="true"
          ></vuejsDatepicker>
        </div>
        <span v-if="$v.createdDate.$dirty">
          <small
            v-if="!$v.createdDate.required"
            class="form-text text-danger small text-nowrap"
          >Applied Date is required</small>
        </span>
      </div>

      <div class="row form-group">
        <div class="col-md-4 required">Amount (£)</div>
        <div class="col-md-3" :class="{invalid: $v.amount.$error}">
          <input
            type="number"
            class="form-control"
            v-model.trim.lazy="$v.amount.$model"
            @change="onChangeAmount($v.amount)"
            @keypress="onKeypressAmount($event)"
            @keyup="onKeyupAmount($event)"
            @blur="onBlur($v.amount)"
          />
        </div>
        <span v-if="$v.amount.$dirty">
          <small
            v-if="!$v.amount.required"
            class="form-text text-danger small text-nowrap"
          >Amount is required</small>
          <small
            v-if="!$v.amount.decimal"
            class="form-text text-danger small text-nowrap"
          >Amount must be a monetory value</small>
        </span>
      </div>

      <div class="row form-group">
        <div class="col-md-4 required">Ledger Transaction Type</div>
        <div class="col-md-5" :class="{invalid: $v.selectedTxType.$error}">
          <b-select v-model.trim="$v.selectedTxType.$model" :options="ledgerTxTypes" />
        </div>
        <span v-if="$v.selectedTxType.$dirty">
          <small
            v-if="!$v.selectedTxType.required"
            class="form-text text-danger small text-nowrap"
          >Transaction Type is required</small>
        </span>
      </div>
    </div>
    <div v-else>
      <div class="row form-group">
        <div class="col-md-4 required">Account Type</div>
        <div class="col-md-6">
          <input
            type="text"
            class="form-control"
            v-model.trim.lazy="ledgerTx.clientOrBankTx"
            :disabled="true"
          />
        </div>        
      </div>

      <div class="row form-group">
        <div class="col-md-4 required">Nominal Code Type</div>
        <div class="col-md-6">
          <vue-select
            v-model="selectedNominalCodeOption"
            :options="nominalCodeOptions"
            :closeOnSelect="true"
            :clearable="false"
            label="text"
            @option:selected="selectNominalCodeOption()">
          </vue-select>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-4">Select Client</div>
        <div class="col-md-6">
          <vue-select
            v-model="selectedClient"
            :options="generalClients"
            :closeOnSelect="true"
            :clearable="false"
            label="name"
            :disabled="disableClientSelect"
            @option:selected="selectClient()">
          </vue-select>
        </div>
      </div>

      <div v-if="showBankDropDown" class="row form-group">
        <div class="col-md-4 required">Select Bank Account</div>
        <div class="col-md-6">
          <vue-select
            v-model="selectedBankAccount"
            :options="bankAccounts"
            :closeOnSelect="true"
            :clearable="false"
            label="reference"
            @option:selected="selectBankAccount()">
          </vue-select>
        </div>
        <div class="col-md-1">
          <button class="btn btn-outline-secondary" type="button" @click="viewBankDetails" :disabled="selectedBankAccountId === null">View</button>
        </div>
      </div>

      <div v-if="showNominalCodeDropDown" class="row form-group">
        <div class="col-md-4 required">Select Nominal Code</div>
        <div class="col-md-6">
          <vue-select
            v-model="selectedNominalCode"
            :options="defaultNominalCodes"
            :closeOnSelect="true"
            :clearable="false"
            label="dropdownContent"
            @option:selected="selectNominalCode()">
          </vue-select>
        </div>
      </div>

      <div v-if="showBankDropDown" class="row form-group">
        <div class="col-md-4 required">Bank Nominal Code</div>
        <div class="col-md-6" :class="{invalid: $v.nominalCode.$error}">
          <input
            type="text"
            class="form-control"
            v-model.trim.lazy="$v.nominalCode.$model"
            :disabled="!needNewBankNominalCode"
          />
        </div>
        <span v-if="$v.nominalCode.$dirty">
          <small
            v-if="!$v.nominalCode.required"
            class="form-text text-danger small text-nowrap"
          >Bank nominal code is required</small>
        </span>
      </div>

      <div v-if="showBankDropDown" class="row form-group">
        <div class="col-md-4 required">XRefID</div>
        <div class="col-md-6" :class="{invalid: $v.xrefId.$error}">
          <input
            type="text"
            class="form-control"
            v-model.trim.lazy="$v.xrefId.$model"
          />
        </div>
        <span v-if="$v.xrefId.$dirty">
          <small
            v-if="!$v.xrefId.required"
            class="form-text text-danger small text-nowrap"
          >XrefID is required</small>
          <small
            v-if="!$v.xrefId.integer"
            class="form-text text-danger small text-nowrap"
          >XrefID must be a integer value</small>
        </span>
      </div>

      <div class="row form-group">
        <div class="col-md-4 required">Reconciliation Status</div>
        <div class="col-md-6">
          <vue-select
            v-model="selectedReconciliationStatus"
            :options="reconciliationStatuses"
            :closeOnSelect="true"
            :clearable="false"
            label="text"
            @option:selected="selectReconciliationStatus()">
          </vue-select>
        </div>
      </div>

      <div v-if="showUpdateAllCheckbox" class="row form-group">
        <div class="col-md-4">Reconcile Matching Xref IDs</div>
        <div class="col-md-4">
          <p-check
            class="p-switch p-fill"
            color="primary"
            v-model="updateAll"
          ></p-check>
        </div>
      </div>
    </div>

    <div slot="modal-footer" class="w-100">
      <b-row>
        <b-col cols="2">
          <b-btn
            size="sm"
            class="float-left"
            variant="primary"
            @click="saveTransaction"
          >Save Transaction</b-btn>
        </b-col>
        <b-col cols="10">
          <b-btn
            size="sm"
            class="float-right"
            variant="danger"
            @click="cancel"
          >Cancel</b-btn>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { required, requiredIf, decimal, integer } from 'vuelidate/lib/validators'
import axios from 'axios'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import bacsCommon from '@/Lib/BacsCommon.js'
import VueSelect from 'vue-select'

export default {
  props: {
    isVisible: Boolean,
    isNewTx: Boolean,
    paygateId: {},
    bankAccountId: {},
    sunId: {},
    disabledDates: {},
    ledgerTx: {},
    bankAccounts: Array,
    defaultNominalCodes: Array,
    generalClients: Array
  },
  components: {
    VueSelect
  },
  computed: {
    modalTitle () { return this.isNewTx ? "Create Ledger Transaction" : "Edit Ledger Transaction" },
    showBankDropDown () { return this.selectedNominalCodeOption.value === '1' },
    showNominalCodeDropDown () { return this.selectedNominalCodeOption.value === '0' },
    // Only relevant for bank account TXs.
    nominalCodeType () { return this.ledgerTx.clientOrBankTx === 'Bank' ? 1 : 2 },
    showUpdateAllCheckbox () { return this.selectedReconciliationStatus.value === '2' },
    // The client should always be L&Z for the "Bank" side "BAC", "EBP" and "D/D" TX types so don't allow user to select a different client.
    disableClientSelect () { return this.ledgerTx.isLandZBankTx }
  },
  data () {
    return {
      createdDate: new Date(),
      ledgerTxTypes: [
        { value: '97', text: 'Manual Collection Adjustment' },
        { value: '98', text: 'Manual Settlement Adjustment' },
        { value: '99', text: 'Reset Balance' }
      ],
      selectedTxType: '',
      decimalPointEntered: false,
      amount: '',
      selectedBankAccountId: null,
      selectedBankAccount: {},
      reconciliationStatuses: [
        { value: '0', text: 'Unprocessed' },
        { value: '1', text: 'Unreconciled' },
        { value: '2', text: 'Reconciled' },
        { value: '3', text: 'Duplicate Match' },
        { value: '4', text: 'Processed' }
      ],
      selectedReconciliationStatus: '',
      updatedLedgerTx: {},
      selectedNominalCode: {},
      nominalCode: '',
      needNewBankNominalCode: false,
      nominalCodeTypeDesc: '',
      nominalCodeOptions: [
        { value: '0', text: 'Default' },
        { value: '1', text: 'Bank Account' } // "Bank" or "Client"
      ],
      selectedNominalCodeOption: {},
      xrefId: 0,
      selectedClient: {},
      updateAll: false
    }
  },

  methods: {
    hide () {
      this.$emit('input', false)
    },

    async setFocus () {
      if (this.isNewTx) {
        var dateElement = document.getElementById('createdDate')
        dateElement.style.backgroundColor = 'white'
        this.decimalPointEntered = false
        this.selectedTxType = ''
        this.createdDate = new Date()
        this.amount = ''
      } else {
        this.updateAll = false
        this.selectedReconciliationStatus = ''
        // console.log('modal TX clients: ' + JSON.stringify(this.generalClients))
        // console.log('banks: ' + JSON.stringify(this.bankAccounts))
        this.selectedClient = this.generalClients.find(i => i.paygateId === this.ledgerTx.paygateId)
        // console.log('ledgerTx: ' + JSON.stringify(this.ledgerTx))
        this.selectedBankAccount = this.bankAccounts.find(i => i.bankAccountId === this.ledgerTx.bankAccountId)
        // console.log('bank: ' + JSON.stringify(this.selectedBankAccount))
        this.selectedNominalCode = this.defaultNominalCodes.find(i => i.code === this.ledgerTx.nominalCode)
        // console.log('NCO: ' + JSON.stringify(this.selectedNominalCode))
        if (this.selectedNominalCode !== undefined) {
          // Ledger TX has a default nominal code.
          this.selectedNominalCodeOption = this.nominalCodeOptions.find(i => i.value === '0')
        } else {
          // Ledger TX has a bank nominal code.
          this.selectedNominalCodeOption = this.nominalCodeOptions.find(i => i.value === '1')
        }
        // console.log('NC type: ' + JSON.stringify(this.selectedNominalCodeOption))

        if (this.selectedBankAccount !== undefined) {
          this.selectedBankAccountId = this.selectedBankAccount.bankAccountId
          await this.selectBankAccount()
        }
        // Default values so poxy Vuelidate is easier to setup.
        this.amount = 0
        this.selectedTxType = this.ledgerTx.ledgerTxType
        this.selectedReconciliationStatus = this.reconciliationStatuses.find(i => i.text === this.ledgerTx.reconciliationStatus)
        // console.log('SET recstatus ' + JSON.stringify(this.selectedReconciliationStatus))
        this.updatedLedgerTx = Object.assign({}, this.ledgerTx)
        this.nominalCode = this.ledgerTx.nominalCode
        this.xrefId = this.ledgerTx.xrefId
        // console.log('ledger tx nc: ' + this.ledgerTx.nominalCode)
        // console.log('nominalCode: ' + this.nominalCode)
      }
      this.$v.$reset()
    },

    onChangeAmount (element) {
      if (this.amount === '') {
        this.amount = 0
      }
      if (this.amount !== '') {
        this.amount = parseFloat(this.amount).toFixed(2)
      }
    },

    onBlur (element) {
      element.$touch()
    },

    onKeypressAmount (e) {
      const tmpAmount = e.target.value + ''
      // Allow another DP to be entered if user has deleted the one that was there.
      if (!tmpAmount.includes('.')) {
        this.decimalPointEntered = false
      }
      if (e.charCode >= 48 && e.charCode <= 57) {
        // Only allow up to 2 digits after the DP.
        return true
      } else if (e.charCode === 46) {
        if (!this.decimalPointEntered) {
          this.decimalPointEntered = true
        } else {
          e.preventDefault()
        }
        return true
      } else {
        e.preventDefault()
      }
    },

    onKeyupAmount (e) {
      // Only allow up to 2 digits after the DP.
      const i = e.target.value.indexOf('.')
      if (i > -1) {
        const mantissa = e.target.value.substring(i)
        const num = e.target.value.substring(0, e.target.value.length - mantissa.length)
        if (mantissa.length > 3) {
          e.target.value = num + mantissa.substring(0, mantissa.length - 1)
        }
      }
    },

    createdDateChanged () {
    },

    cancel () {
      this.selectedTxType = ''
      this.$emit('close')
    },

    async saveTransaction () {
      // console.log('saveTransaction')
      this.$v.$touch()

      if (this.$v.$invalid) {
        console.log('form invalid')
        return false
      }

      if (this.isNewTx) {
        await this.saveNewTransaction()
      } else {
        await this.updateTransaction()
      }
    },

    async saveNewTransaction () {
      // console.log('saveNewTransaction')
      var ledgerTxType = parseInt(this.selectedTxType)
      var okToSave = true
      // Get confirmation if resetting the ledger balance.
      if (ledgerTxType === 99) {
        okToSave = await this.getConfirmation()
      }

      if (okToSave) {
        var ledgerTxColn = []
        var credit = 0
        var debit = 0
        if (ledgerTxType === 97) {
          debit = this.amount
        } else if (ledgerTxType === 98) {
          credit = this.amount
        }
        var newLedgerTx = {
          paygateId: this.paygateId,
          bankAccountId: this.bankAccountId,
          sunId: this.sunId,
          credit: credit,
          debit: debit,
          ledgerTxType: ledgerTxType,
          transactionDate: this.createdDate,
          reconciliationstatus: 4 // Processed
        }
        ledgerTxColn.push(newLedgerTx)

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_PLATFORM_API_URL + 'ledger/',
          data: JSON.stringify(ledgerTxColn),
          showload: true
        })
        if (response.data.status === 'Created') {
          this.$toastr.s('Ledger transaction saved')
        } else {
          this.$snapbar.e(response.data.errorText)
        }
        this.selectedTxType = ''
        this.$emit('savedLedgerTransaction')
      }
    },

    async getConfirmation () {
      var result = await swal.fire({
        title: 'Are you sure you want to reset the ledger balance?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: colours.danger
      })

      return result.isConfirmed
    },

    async updateTransaction() {
      if (this.selectedBankAccount !== undefined) {
        this.updatedLedgerTx.bankAccountId = this.selectedBankAccount.bankAccountId
        this.updatedLedgerTx.reference = this.selectedBankAccount.reference
        // this.updatedLedgerTx.clientOrBankTx = 'Bank'
      }
      // console.log('UPDATE recstatus ' + JSON.stringify(this.selectedReconciliationStatus))
      this.updatedLedgerTx.reconciliationStatus = this.selectedReconciliationStatus.text
      this.updatedLedgerTx.nominalCode = this.nominalCode
      this.updatedLedgerTx.xrefId = this.xrefId
      if (this.selectedClient !== undefined) {
        this.updatedLedgerTx.paygateId = this.selectedClient.paygateId
      }

      if (this.selectedReconciliationStatus.value === '2') {
        // Only allow this option when reconciling a TX.
        this.updatedLedgerTx.updateAllLinkedXrefIds = this.updateAll
      } else {
        this.updatedLedgerTx.updateAllLinkedXrefIds = false
      }
      
      let canUpdateTx = true
      // Check whether the bank nominal code should be created if applicable i.e. if original value is 9999.
      // Do this first and only update the nominal code if the bank nominal code has been created.
      if (this.needNewBankNominalCode) {
        canUpdateTx = await this.saveBankNominalCode()
      }

      if (canUpdateTx) {
        try {
          var response = await axios({
            method: 'PUT',
            url: process.env.VUE_APP_PLATFORM_API_URL + 'ledger/',
            data: JSON.stringify(this.updatedLedgerTx),
            showload: true
          })

          if (response.data !== null && response.data.status !== 'Failed') {
            this.$emit('savedLedgerTransaction')
          } else {
            this.$snapbar.e(response.data.errorText)
          }
        } catch (e) {
          console.log(e)
        }
      }
    },

    async saveBankNominalCode() {
      let saveSuccess = false
      const bankNominalCode = {
        code: this.updatedLedgerTx.nominalCode,
        description: `Bank Account \'${this.ledgerTx.clientOrBankTx}\' side nominal code`,
        nominalCodeType: this.nominalCodeType,
        bankaccountId: this.selectedBankAccountId
      }

      try {
        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_PLATFORM_API_URL + 'nominalCode/',
          data: JSON.stringify(bankNominalCode),
          showload: true
        })

        if (response.data.status === 'Created') {
          saveSuccess = true
        } else {
          this.$snapbar.e(response.data.errorText)
        }
      } catch (e) {
        console.log(e)
        this.$snapbar.e('Failed to save new nominal code.')
      }

      return saveSuccess
    },

    async viewBankDetails () {
      try {
        this.selectedBankAccount.currency = 'GBP'
        await swal.fire({
          title: 'Bank details',
          html: bacsCommon.viewGroupBankDetails(this.selectedBankAccount, ''),
          animation: false
        })
      } catch { }
    },
    
    async selectBankAccount () {
      // console.log('selectedbank: ' + JSON.stringify(this.selectedBankAccount))
      this.selectedBankAccountId = this.selectedBankAccount.bankAccountId
      // Get bank nominal code.
      // console.log('nominalCodeType: ' + this.nominalCodeType)
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}nominalCode/bankNominalCode?bankAccountId=${this.selectedBankAccountId}&nominalCodeType=${this.nominalCodeType}`, { showload: true })
      // console.log('Bank NC: ' + JSON.stringify(response.data))
      // Finance user needs to create a bank nominal code equal to the nominal code entered for this ledger TX Selected if bank doesn't have a nominal code set.
      this.needNewBankNominalCode = response.data === null;
      if (response.data !== null) {
        this.nominalCode = response.data.code
      } else {
        this.nominalCode = this.ledgerTx.nominalCode
        this.$snapbar.w(`The selected bank does not have a \'${this.ledgerTx.clientOrBankTx}\' nominal code. Enter a nominal code or select a different bank account.`)
      }
    },

    async selectClient () {
      // Get customer SUN:
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customers/${this.selectedClient.paygateId}`, { showload: true, showerror: true, errormessage: 'Customer failed to load' })
      this.updatedLedgerTx.sunId = response.data.sunId
      this.selectedBankAccount = this.bankAccounts.find(i => i.bankAccountId === response.data.bankAccountId)
      await this.selectBankAccount()
    },

    selectReconciliationStatus () {
    },

    async selectNominalCodeOption () {
      await this.selectBankAccount()
    },

    selectNominalCode() {
      this.nominalCode = this.selectedNominalCode.code
      console.log('NC: ' + this.nominalCode)
    }
  },

  validations: {
    createdDate: { 
      required: requiredIf(function () {
          return !this.isNewTx
        })
      },
    amount: {
      required,
      decimal
    },
    selectedTxType: { required },
    nominalCode: {
      required: requiredIf(function () {
        return !this.isNewTx
      })
    },
    xrefId: {
      required,
      integer
    }
  }
}
</script>
