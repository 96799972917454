<template>
  <form @submit.prevent="!$v.$invalid && saveCertificate()">
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Add Client Certificate
          <help-icon docPath="/administration/customer/certificates/addcertificate/" />
        </h2>
      </div>
      <div class="idb-block-content">
        <div class="vue-dropzone dropzone dz-clickable dropbox" v-if="!uploaded">
          <input
            type="file"
            accept="application/pkix-cert"
            v-on:change="handleFileUpload()"
            id="file"
            ref="file"
            class="input-file"
            :disabled="isLoading"
          />
          <div class="dz-default dz-message">
            <span>Drop a file here or click to select a file to upload.</span>
          </div>
        </div>

        <div v-if="uploaded">
          <!-- friendlyName -->
          <div class="form-group row" :class="{invalid: $v.certificate.friendlyName.$error}">
            <label class="col-form-label col-md-3 required">Friendly Name</label>
            <div class="col-md-6">
              <input class="form-control" v-focus v-model.trim="$v.certificate.friendlyName.$model" />
              <span v-if="$v.certificate.friendlyName.$error" class="validation-messages">
                <small
                  class="form-text small"
                  v-if="!$v.certificate.friendlyName.required"
                >Please enter a friendly name for this certificate</small>
              </span>
            </div>
          </div>

          <!-- distinguishedName -->
          <div class="form-group row">
            <label class="col-form-label col-md-3">Distinguished Name</label>
            <div class="col-md-9">
              <input
                class="form-control"
                v-model.trim="certificate.distinguishedName"
                disabled
                readonly
              />
            </div>
          </div>

          <!-- issuer -->
          <div class="form-group row">
            <label class="col-form-label col-md-3">Issuer</label>
            <div class="col-md-9">
              <input class="form-control" v-model.trim="certificate.issuer" disabled readonly />
            </div>
          </div>

          <!-- SerialNumber -->
          <div class="form-group row">
            <label class="col-form-label col-md-3">Serial Number</label>
            <div class="col-md-9">
              <input class="form-control" v-model.trim="certificate.serialNumber" disabled readonly />
            </div>
          </div>

          <!-- Thumbprint -->
          <div class="form-group row">
            <label class="col-form-label col-md-3">Thumbprint</label>
            <div class="col-md-9">
              <input class="form-control" v-model.trim="certificate.thumbprint" disabled readonly />
            </div>
          </div>

          <!-- ValidFrom -->
          <div class="form-group row">
            <label class="col-form-label col-md-3">Valid From</label>
            <div class="col-md-9">
              <input class="form-control" v-model.trim="certificate.validFrom" disabled readonly />
            </div>
          </div>

          <!-- ValidTo -->
          <div class="form-group row">
            <label class="col-form-label col-md-3">Valid To</label>
            <div class="col-md-9">
              <input class="form-control" v-model.trim="certificate.validTo" disabled readonly />
            </div>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          type="submit"
          :disabled="$v.$invalid || isLoading"
        >Save Certificate</button>
        <button class="btn btn-danger pull-right" type="button" @click="cancel">Cancel</button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import loading from '@/Assets/Mixins/LoadingMixin'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  mixins: [loading],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () { this.$router.push({ name: 'CustomerCertificates' }) }
  },
  data () {
    return {
      file: null,
      uploaded: false,
      certificate: {
        friendlyName: null
      }
    }
  },
  methods: {
    async handleFileUpload () {
      this.file = this.$refs.file.files[0]

      const formData = new FormData()
      formData.append('file', this.file)

      try {
        var certificateResponse = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate/Upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            showload: true,
            showerror: true,
            errormessage: 'Certificate failed to upload'
          }
        )
        this.certificate = certificateResponse.data

        // Test if valid?
        var isAfterNow = moment(this.certificate.validTo).isAfter()

        if (!isAfterNow) {
          this.$snapbar.w('The certificate is out of date, please upload a valid certificate')
          this.$refs.file.value = ''
          return
        }

        this.uploaded = true
      } catch { }
    },
    async saveCertificate () {
      try {
        this.certificate.paygateId = this.$store.state.common.paygateId
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate`, this.certificate, {
          showload: true,
          showerror: true,
          errormessage: 'Certificate failed to save'
        })
        this.$toastr.s('Client Certificate Created', 'Created')
        this.$router.back()
      } catch { }
    },
    cancel () {
      this.$router.back()
    }
  },
  validations: {
    certificate: {
      friendlyName: { required }
    }
  }
}

</script>
<style lang="scss" scoped>
.dropbox {
  position: relative;
}
.input-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  min-height: 150px;
  left: 0;
  top: 0;
  &:disabled {
    cursor: not-allowed;
  }
}
</style>
