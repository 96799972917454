<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        Select payment files for FPS submission: {{submissionReference}}
        <help-icon></help-icon>
        <favourite-icon></favourite-icon>
      </h2>
    </div>

    <div class="idb-block-content">
      <b-row>
        <b-col sm="12">
          <dropzone
            id="my-dropzone"
            ref="fileUploader"
            :options="dropzoneOptions"
            @vdropzone-success="afterSuccess"
            @vdropzone-file-added="fileAdded"
            @vdropzone-removed-file="fileRemoved"
            @vdropzone-sending="sendingEvent"
            :use-custom-slot="true"
            :max-file-size-in-m-b="200"
            :useCustomSlot="true"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">Drag and drop to add files to the FPS Submission</h3>
              <div class="subtitle">...or click to select a file from your computer</div>
            </div>
          </dropzone>
        </b-col>
      </b-row>
    </div>

    <div class="idb-block-content">
      <vue-good-table
        ref="submissionFilesTable"
        :paginationOptions="{
                        enabled: true,
                        perPage: 10,
                      }"
        :rows="submissionDetails"
        :columns="columns"
        :lineNumbers="true"
        :sort-options="{
                        enabled: true,
                        initialSortBy: {field: 'paymentFilename', type: 'asc'}
                      }"
        styleClass="vgt-table striped bordered"
      >
        <template slot="loadingContent">
          <h1>Loading...</h1>
        </template>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'bureauCustomerName'">
            <b-row>
              <b-col sm="11">
                <model-list-select
                  :id="props.row.bureauCustomerId"
                  :list="BureauCustomersDisplay"
                  v-model="props.row.bureauCustomerId"
                  label="name"
                  option-value="bureauCustomerId"
                  option-text="name"
                  placeholder="Select Customer"
                  :isDisabled="props.row.status === 'Imported'"
                  @input="updateBureauCustomer(props.row)"
                ></model-list-select>
              </b-col>
              <a
                class="btn btn-sm primary mt-1 reset-customer"
                :hidden="props.row.status != 'Uploaded' || props.row.schema == 'Standard18'"
                :disabled="isLoading"
                @click="onResetSelectedCustomer(props.row)"
              >
                <i class="fa fa-times"></i>
              </a>
            </b-row>
          </span>
          <span v-else-if="props.column.field == 'schema'">
            <b-row>
              <b-col sm="12">
                <model-list-select
                  :id="'schemaSelector-'+props.row.bureauSubmissionDetailId"
                  :list="schemas"
                  option-value="value"
                  option-text="text"
                  v-model="props.row.importSchema"
                  :isDisabled="props.row.status != 'Uploaded'"
                  placeholder="Schema"
                  @input="updateSubmissionDetail(props.row)"
                ></model-list-select>
              </b-col>
            </b-row>
          </span>
          <span v-else-if="props.column.field == 'mapping'">
            <b-row>
              <b-col sm="12">
                <model-list-select
                  :id="'mappingSelector-'+props.row.bureauSubmissionDetailId"
                  :list="mappings"
                  option-value="value"
                  option-text="text"
                  v-model="props.row.mapping"
                  placeholder="Mapping"
                  :isDisabled="props.row.status != 'Uploaded' || props.row.importSchema != 'Mapping' "
                  @input="updateSubmissionDetail(props.row)"
                ></model-list-select>
              </b-col>
            </b-row>
          </span>
          <span v-else-if="props.column.field == 'delete'">
            <a
              class="btn btn-sm primary mt-1"
              @click.stop="onDeleteFileClick(props.row)"
              :disabled="isLoading"
            >
              <i class="fa fa-trash"></i>
            </a>
          </span>
          <span v-else>
            <b-row>
              <b-col sm="12" class="mt-2">{{ props.formattedRow[props.column.field] }}</b-col>
            </b-row>
          </span>
        </template>

        <div slot="table-actions">
          <b-button
            @click.prevent="load"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Refresh the data in the table'"
          >
            <i class="fa fa-sync pointer dimmedIcon"></i>
          </b-button>
          <b-button
            @click.prevent="printTable"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
          >
            <i class="fa fa-print pointer dimmedIcon"></i>
          </b-button>
          <b-button
            @click.prevent="exportTable"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
          >
            <i class="fa fa-file-export pointer dimmedIcon"></i>
          </b-button>
        </div>
      </vue-good-table>
    </div>

    <div class="idb-block-footer">
      <button class="btn btn-primary mr-2" @click="ImportAdditionalFiles">Import Additional Files</button>
      <button class="btn btn-primary ml-2" @click="onViewPaymentsClick()">View Payments</button>
    </div>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import axios from 'axios'
import auth from '@/Assets/Components/Authentication/auth.js'
import Dropzone from 'vue2-dropzone'
import swal from 'sweetalert2'
import _ from 'lodash'
// import SubmissionDetails from '@/Components/Bureau/BureauJob/JobSubmissionDetailsComponent.vue'
import Utility from '@/Assets/Mixins/Utility'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import colours from '@/Assets/Constants/colours'
var GUID_EMPTY = '00000000-0000-0000-0000-000000000000'

export default {
  components: {
    Dropzone,
    ModelListSelect
  },
  mixins: [Utility, loading, tableFilterMixin, bacsMixin],
  props: {
    id: String
  },
  data () {
    return {
      submissionDetails: [], // Current files in submission
      SelectedBureauCustomerId: '',
      BureauCustomers: [],
      BureauCustomersDisplay: [],
      BUREAUGROUPTYPE: '1',

      submissionReference: '',
      contraNarrative: '',
      groupId: '',
      mappings: [],
      // dropzone
      dropzoneOptions: {
        url: process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/uploadmanualfpspaymentfile'
      },
      // table
      columns: [
        {
          label: 'Filename',
          field: 'paymentFilename',
          sortable: true
        },
        {
          label: 'Customer',
          field: 'bureauCustomerName',
          sortable: true
        },
        {
          label: 'Schema',
          field: 'schema',
          sortable: false
        },
        {
          label: 'Mapping',
          field: 'mapping',
          sortable: false
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          field: 'delete',
          sortable: false
        }
      ],

      objectItem: {}

    }
  },
  mounted () {
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
      })
  },
  async created () {
    await this.load()
  },

  methods: {
    load: _.debounce(async function () {
      console.log('loading manual fps submission addfiles')
      // this.paygateId = this.$store.state.common.paygateId
      // await this.loadGroups()
      await this.loadBureauCustomers()
      await this.loadMappingsOnLoad()
      await this.loadManualSubmission()
      await this.loadManualSubmissionDetails()
    }, 800),

    // Loader Methods
    async loadManualSubmission () {
      // Go and get the submission from the API
      console.log('getting the submission')
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getmanualfpsbuildersubmission/' + this.id)
        console.log('loadSubmission response', response)
        this.groupId = response.data.groupId
        this.submissionReference = response.data.submissionReference
        this.contraNarrative = response.data.contraNarrative
      } catch (e) {
        console.log('exception ', e)
      }
    },

    async loadManualSubmissionDetails () {
      // Go and get any files/blocks loaded into the submission so far
      console.log('loading submissiondetails')
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getmanualfpsbuildersubmissiondetails/' + this.id, { showload: true })
        console.log('loadSubmissionDetails response', response)

        this.submissionDetails = response.data.data
      } catch (error) {
        console.error('Error in UploadFiles.load')
        console.error(error)
      }
    },
    loadMappingsOnLoad: async function () {
      console.log('load mappings')
      this.mappings = await this.loadMappings('')
      console.info('Mappings:', this.mappings)
      this.schemas = this.loadSchemas()
      console.info('Schemas:', this.schemas)

      return true
    },
    loadBureauCustomers: async function () {
      console.log('load bureau customers')

      try {
        // Load customers in job
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getbureaucustomersforsubmission/', { showload: true })
        this.BureauCustomers = response.data

        var index = 0
        for (index = 0; index < this.BureauCustomers.length; ++index) {
          var current = {
            bureauCustomerId: this.BureauCustomers[index].bureauCustomerId,
            name: this.BureauCustomers[index].name + ' (' + this.BureauCustomers[index].serviceUserNumber + ')',
            disabled: false
          }
          this.BureauCustomersDisplay.push(current)
        }
      } catch (error) {
        console.error('Error in FPS Additional Files')
        console.error(error)
      }
      return true
    },

    // Form Edit Methods

    async onDeleteFileClick (row) {
      const swalResult = await swal.fire({
        title: 'Delete Payment File',
        text: 'Are you sure you want to delete the file: ' + row.paymentFilename + ', this will delete the file from PayGate',
        icon: 'question',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        try {
          const data = {
            submissionId: row.bureauSubmissionId,
            fileNumber: row.fromFileNumber,
            itemId: row.bureauSubmissionDetailId
          }

          const response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/deleteBlock', data, { showload: true })
          console.log(response)

          // Remove from grid
          this.submissionDetails = this.submissionDetails.filter(function (submissionRow) {
            return submissionRow.bureauSubmissionDetailId !== row.bureauSubmissionDetailId
          })
        } catch (error) {
          console.error('Error in UploadFiles.onDeleteFileClick.DeleteSubmissionDetail')
          console.error(error)
        }
        this.$toastr.s('Removed file ' + row.paymentFilename, 'Bureau Submission')
      }
    },

    async onResetSelectedCustomer (submissionDetail) {
      submissionDetail.bureauCustomerId = GUID_EMPTY
      submissionDetail.importSchema = ''
      submissionDetail.mapping = ''
      this.updateSubmissionDetail(submissionDetail)
    },

    async updateBureauCustomer (row) {
      console.log('updateBureauCustomer', row)
      if (row.importSchema === '' ||
        row.importSchema === 'None' ||
        row.importSchema === undefined ||
        row.mapping === '' ||
        row.mapping === undefined
      ) {
        // Go and get this bureau customer's default import
        try {
          var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getbureaucustomerdefaultimportschema/' + row.bureauCustomerId)
          console.log('get default bc data', response)
          row.importSchema = response.data.importSchema
          if (row.importSchema === 'Mapping') {
            row.mapping = response.data.mapping
          }
        } catch (e) {
          console.log('exception getting default import', e)
        }
      }
      await this.updateSubmissionDetail(row)
    },

    // Submssion Management Methods
    async updateSubmissionDetail (row) {
      console.log('updateSubmissionDetail row', row)
      try {
        if (row.importSchema !== 'Mapping') {
          row.mapping = ''
        }

        var response = await axios.put(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/updatemanualbacssubmissiondetail', row, { showload: true })
        // this.$toastr.s(`Updated ${submissionDetail.paymentFilename}`, 'Update Bureau Submission')
        console.log(response)

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (error) {
        this.$toastr.e(`Failed to update ${row.paymentFilename}`, 'Update FPS Bureau Submission Additional Files')
      }
    },

    async ImportAdditionalFiles () {
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/importadditionalfiles/' + this.id, { showload: true })

        console.log('bureau submission result', response)
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
          var bureauSubmissionId = response.data.id
          console.log('bureau submission bureauSubmissionId', bureauSubmissionId)
          this.$router.push({ name: 'UploadAdditionalFilesBacsImport', params: { id: this.id, startProcess: true } })
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (error) {
        this.$toastr.e(`Manual FPS Bureau Submission ${this.id} failed ${error}`, 'Manual FPS Bureau Submission')
      }
    },
    onViewPaymentsClick () {
      this.$router.push({ name: 'PaymentDetailsFPS', params: { id: this.id } })
    },
    // Dropzone Methods
    async afterSuccess (file, response) {
      await this.loadManualSubmissionDetails()
      // var submissionDetail = response
      // this.autoMatch(submissionDetail)
      // this.submissionDetails.push(submissionDetail)
      file.previewElement.outerHTML = ''
    },
    async fileAdded (file) {
      this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
    },
    fileRemoved (file, error, xhr) {
      console.log(file.name)
    },
    sendingEvent (file, xhr, formData) {
      formData.append('bureauSubmissionId', this.id)
      // formData.append('paygateId', this.paygateId)
    },
    dropZoneError (file, error, request) {
      this.$toastr.e('File upload ' + file.name + ' failed', 'Bureau Submission')
      console.error('Error in UploadFiles.DropZoneError')
      console.error(error)
    }
  }
}

</script>
