export default {
  methods: {
    tdClass (row, field) {
      return this.moneyClass(row[field])
    },
    moneyClass (amount, noRight) {
      let right = 'text-right'
      if (noRight) { right = '' }
      if (amount < 0) {
        return `text-danger ${right}`
      }
      return right
    },
    moneyFormat (value) {
      if (value < 0) {
        return `(${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).substring(1)})`
      } else {
        return `${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      }
    }
  }
}
