<template>
  <div style="height:100%">
    <div class="d-flex justify-content-between">
      <h4>Latest Indemnity Reports</h4>
    </div>
    <vue-perfect-scrollbar class="scroll-area" :settings="settings">

      <b-tabs pills card vertical nav-wrapper-class="w25" v-if="reports.length > 0">
        <b-tab  v-for="(report, i) in reports" :key="i" class="pt-0" :title="formatDate(report.productionDate)" active>
            <table class="table">
            <thead>
              <th>&nbsp;</th>
              <th>Count</th>
              <th class="amountCell">Value</th>
            </thead>
            <tbody>
              <tr><td>New Advice</td><td>{{report.matchedNewAdviceCount}}</td><td class="amountCell">{{formatAmount(report.matchedNewAdviceValue)}}</td></tr>
              <tr><td>Cancellations</td><td>{{report.matchedCancellationCount}}</td><td class="amountCell">{{formatAmount(report.matchedCancellationValue)}}</td></tr>
              <tr><td>Unmatched Advice</td><td>{{report.unmatchedNewAdviceCount}}</td><td class="amountCell">{{formatAmount(report.unmatchedNewAdviceValue)}}</td></tr>
              <tr><td>Unmatched Cancellations</td><td>{{report.unmatchedCancellationCount}}</td><td class="amountCell">{{formatAmount(report.unmatchedCancellationValue)}}</td></tr>
            </tbody>
            </table>
            <b-button variant="primary" @click.prevent="goToReport(report)">View report</b-button>
        </b-tab>
      </b-tabs>
       <div v-if="reports.length === 0">No indemnity reports have been processed.</div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import axios from 'axios'

export default {
  name: 'IndemnityWidget',
  components: {
    VuePerfectScrollbar
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    amountToGet () {
      return this.widget.options.find(o => o.label === 'Amount of Indemnity Reports to show').value
    },
    ...mapGetters(['getAccessToken'])
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      collectionsApi: process.env.VUE_APP_DDMS_API_URL,
      settings: {
        maxScrollbarLength: 60
      },
      reports: [],
      errorState: false
    }
  },
  watch: {
    widget () {
      this.load()
    }
  },
  methods: {
    goToReport(report) {
        this.$router.push({ path: `/collections/indemnity/${report.indemnityReportId}` })
    },
    async load () {
        try {
            let result = await axios.get(`${this.collectionsApi}indemnitywidget/reports/${this.amountToGet ? this.amountToGet : 10}`, { params: { paygateId: this.selectedCustomer } })
            this.reports = result.data
        } catch (e) {
            console.log(e)
        }
    },
    pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      truncateText (value)     {
          return value.substr(0, 10) + '...'
      }
  },
  async mounted () {
      await this.load()
  }
}
</script>
<style scoped>
  .amountCell {
    text-align: right;
  }

  .scroll-area {
    margin-top: 20px;
    height: calc(100% - 40px);
    min-height: unset;
    border-top: solid 1px #eeeeee;
  }
</style>
