<template>
  <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12" class="mb-4">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>
                <router-link
                  v-if="false"
                  class="pull-right ml-2 text-danger"
                  to="/collections/payer/manage"
                >
                  <i class="fas fa-times mr-2"></i>
                </router-link>
                <help-icon docPath="/paygate-collections/payers/payercreation/" />Create Customer Wizard
                <span class="text-muted" v-if="stepsTaken || $store.getters.hasPayerId">: {{accountHolderName}} ({{reference}})</span>
              </h2>
            </div>
            <horizontal-stepper
              ref="stepper"
              :steps="payerSteps"
              @completed-step="stepCompleted"
              @stepper-finished="checkCompleted"
              @cancel="cancelProcess"
              :reset="resetSteps"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
// import Bank from './Wizard/Bank'
// import Details from './Wizard/Details'
import Bank from '@/Components/Collections/Payer/BankAccountDetails'
import Details from './Wizard/Details'
import Address from './Wizard/Address'
import Plan from './Wizard/Plan'
import swal from 'sweetalert2'
import HorizontalStepper from '@/Components/Collections/Payer/Wizard'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import {mapGetters} from 'vuex';
export default {
  mixins: [loading],
  components: {
    HorizontalStepper
  },
  props: {
    groupid: String
  },
  data () {
    return {
      complete: false,
      payerSteps: [
        {
          icon: 'fas fa-user',
          name: 'personalDetails',
          title: 'Personal Details',
          component: Details,
          completed: false,
          getter: 'detailsDone'
        },
        {
          icon: 'fas fa-home',
          name: 'address',
          title: 'Address',
          component: Address,
          completed: false,
          getter: 'addressDone'
        },
        {
          icon: 'fas fa-university',
          name: 'bankDetails',
          title: 'Bank Details',
          component: Bank,
          completed: false,
          getter: 'bankingDone'
        },
        {
          icon: 'fas fa-calendar',
          name: 'planDetails',
          title: 'Collection Schedule',
          component: Plan,
          completed: false,
          getter: 'planDone'
        }
      ],
      stepsTaken: false,
      canNavigate: false,
      highestStepDone: 0,
      resetSteps: false,
      secureLimitsExceeded: false
    }
  },
  methods: {
    async stepCompleted (payload) {
      if (this.resetSteps !== true) {
        if (this.highestStepDone <= payload.index) {
          this.highestStepDone = payload.index + 1
        }
        this.payerSteps.forEach(async (step) => {
          if (step.name === payload.name) {
            step.completed = this.$store.getters[step.getter]
          }
        })
        if (payload.hasOwnProperty('index')) {
          if (!this.$store.getters.wizardPayerCreated) {
            await this.$store.dispatch  ('createNewWizardPayer', {groupId: this.groupid})
            this.$store.commit('setPayerCreated')
          } else {
            await this.$store.dispatch('savePayer')
          }
        }
      }
      this.resetSteps = false
    },
    async createPayer () {
      this.$store.commit('setGroupId', this.groupid)
      this.$store.commit('setPayerStatus', 0)
      let response = await this.$store.dispatch('updatePayer', { groupid: this.groupid, paygateId: this.paygateId })
      if (!response.secureLimitsExceeded) {
        this.$toastr.s(`Customer: ${this.$store.state.payer.reference} created`)
        await this.$store.dispatch('setBlankPayer', this.groupid)
        this.$store.state.payer.currentPayer.payerMessageType = this.$store.getters.collectionsGroupConfig.defaultPayerMessageType
        await this.$store.commit('updatePayerReference', '')
        await this.$store.commit('setPayerId', null)
        await this.$store.commit('setNewRefRequired', true)
        this.$store.commit('resetWizard')
        this.payerSteps.forEach((step) => {
          step.completed = false
        })
        this.highestStepDone = 0
        this.stepsTaken = false
        this.canNavigate = true
        if (!this.$store.state.payer.addAnother) {
          this.detailsValid = true
          this.$router.push({path: '/collections/customer/manage'})
        } else {
          this.resetSteps = true
        }
      } else {
        let message = []
        if(response.maxAmountPerCollectionExceeded) {
          message.push('Max Amount Per Collection')
        }
        if(response.payerCollectionCountPerMonthExceeded) {
          message.push('Customer Collection Count Per Month')
        }
        if(response.maxAmountPerMonthExceeded) {
          message.push('Max Amount Per Month')
        }
        if(response.maxCollectionCountPerMonthExceeded) {
          message.push('Collection Count Per Month')
        }
        if(response.clientLimitsExceeded) {
          message.push('Max Amount Per Client Per Month')
        }
        this.$store.commit('setPayerStatus', 4)
        this.$refs.stepper.canContinue = true
        this.$toastr.e(`Secure Limits Exceeded: ${ message.join(', ').trim() }. Customer will remain in an incomplete state. Please contact L & Z.`)
        this.canNavigate = false
        this.stepsTaken = true
        this.highestStepDone = 2
        this.$store.commit('setSecureLimitsExceeded', true)
      }
    },
    async leaving () {
      var result = null
      try {
        if (!this.$store.getters.secureLimitsExceeded) {
          result = await swal.fire({
            title: 'Navigation',
            text: `Leaving this page will leave the customer in an incomplete state. This customer ('${this.$store.state.payer.currentPayer.reference}') can be accessed from the manage customers page. This customer may also be abandoned, in which case it may not be completed later.`,
            type: 'warning',
            icon: 'warning',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: colours.success,
            confirmButtonText: 'Save and Leave Wizard',
            denyButtonText: 'Abandon and Leave Wizard',
            denyButtonColor: colours.warning,
            cancelButtonText: 'Cancel',
            width: '40%'
          })
        } else {
          result = await swal.fire({
            title: 'Navigation',
            text: `Leaving this page will leave the customer in an incomplete state. As the Secure Limits for this Client would be exceeded using the selected Schedule, this Customer cannot be made operational. This Customer ('${this.$store.state.payer.currentPayer.reference}') can be accessed from the Manage Customers page. This Customer may also be abandoned, in which case it may not be completed later.`,
            type: 'warning',
            icon: 'warning',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: colours.success,
            confirmButtonText: 'Save and Leave Wizard',
            denyButtonText: 'Abandon and Leave Wizard',
            denyButtonColor: colours.warning,
            cancelButtonText: 'Cancel',
            width: '40%'
          })
        }
      } catch (err) {
        console.log(err)
        result = false
      }
      return result
    },
    async incomplete () {
      var result = null
      try {
        if (this.$store.getters.secureLimitsExceeded) {
          result = await swal.fire({
            icon: 'warning',
            title: 'Navigation',
            text: `This Customer ('${this.$store.state.payer.currentPayer.reference}') will remain in an incomplete state, as the Secure Limits for this Client would be exceeded using the selected Schedule. This Customer may be completed through the Manage Customers page.`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: colours.success,
            confirmButtonText: 'Save',
            cancelButtonText: 'Cancel'
          })
        } else {
          result = await swal.fire({
            icon: 'warning',
            title: 'Navigation',
            text: `This Customer ('${this.$store.state.payer.currentPayer.reference}') is in an incomplete state, you may complete this wizard, or it can be completed from within the manage customers page.`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: colours.success,
            confirmButtonText: 'Save',
            cancelButtonText: 'Cancel'
          })
        }
      } catch (err) {
        result = false
      }
      return result
    },
    async checkCompleted (e) {
      if (this.$store.getters.wizardComplete) {
        await this.createPayer()
      } else {
        this.$router.push({ path: '/collections/customer/manage' })
      }
    },
    navigateToStep (e) {
      var selectedStep = 0
      var selectedParent = null
      for (let i = 0; i < e.path.length; i++) {
        if (e.path[i].getAttribute('step-index') !== null) {
          selectedStep = parseInt(e.path[i].getAttribute('step-index'))
          selectedParent = e.path[i]
          break
        }
      }
      var wizardNodes = this.$refs.stepper.$el.querySelectorAll('.step')
      for (let i = 0; i < wizardNodes.length; i++) {
        wizardNodes[i].setAttribute('class', 'step deactivated')
      }
      if (this.$store.state.payer.currentPayer.ukPayerId !== null) {
        this.$refs.stepper.activateStep(selectedStep, selectedStep < this.$refs.stepper.currentStep)
        if (selectedParent !== null) {
          selectedParent.setAttribute('class', 'step active')
        }
      }
    },
    async abandonPayer () {
      await this.$store.dispatch('abandonPayer', this.$store.state.payer.currentPayer.ukPayerId)
    },
    async cancelProcess () {
        this.$router.push('/collections/customer/manage')
    }
  },
  // async created () {
  //   await this.$store.dispatch('populateAllCollectionsGroups')
  // },
  async mounted () {
    await this.$store.dispatch('getNonProcessingDates')
    await this.$store.dispatch('setBlankPayer', this.groupid)
    await this.$store.dispatch('getWizardSettings', this.groupid)
    await this.$store.dispatch('getGroupBankAccounts', this.groupid)
    this.$store.commit('resetWizard')
    this.$store.state.payer.currentPayer.payerMessageType = this.$store.getters.collectionsGroupConfig.defaultPayerMessageType
    this.$store.state.payer.currentPayer.activationDate = new Date()
    var wizardNodes = this.$refs.stepper.$el.querySelectorAll('.step')
    // var step = 0
    for (var i = 0; i < wizardNodes.length; i++) {
      wizardNodes[i].setAttribute('step-index', i)
      wizardNodes[i].addEventListener('click', this.navigateToStep)
    }
  },
  async beforeRouteLeave (to, from, next) {
    try {
      if (this.$store.getters.detailsDone) {
        if (!this.$store.getters.wizardPayerCreated && this.$store.getters.detailsDone) {
          await this.$store.dispatch('createNewWizardPayer', { groupId: this.groupid })
          await this.$store.commit('setPayerCreated')
        }
        const result = await this.leaving()
        if (result) {
          if (result.isConfirmed) {
            next()
          } else if (result.isDenied) {
            await this.abandonPayer()
            next()
          }
        } else {
          next(false)
        }
      } else {
        if (this.$store.getters.wizardPayerCreated) {
          await this.$store.dispatch('deletePayer', this.$store.state.payer.currentPayer.ukPayerId)
        }
        next()
      }
    } catch (e) {
      console.log(e)
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    reference () {
      return this.$store.state.payer.currentPayer.reference
    },
    accountHolderName () {
      return this.$store.state.payer.currentPayer.bankAccountDetails.accountHoldersName
    },
    detailsValid: {
      get () {
        return this.$store.state.payer.createCompleted.detailsCompleted
      },
      set (value){
        this.$store.state.payer.createCompleted.detailsCompleted = value
      }
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    customerExeeded () {
      return this.$store.getters.getThresholdExceeded
    }
  },
  watch: {
    selectedCustomer () {
      this.$router.push({path: '/collections/customer/manage'})
    },
    customerExeeded (val) {
      if (val === true) {
        this.$toastr.w('Customer threshold exceeded for this Client.')
      }
    }
  }
}
</script>
