<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Maintenance Windows</h2>
      </div>
      <div class="idb-block-content">
        <div class="form-group row">
          <label class="col-form-label col-md-2">Include Historic Events</label>
          <div class="col-md-5">
            <p-check
              class="p-switch p-fill"
              color="primary"
              v-model="serverParams.historic"
              @change="onHistoricChange"
            ></p-check>
          </div>
        </div>

        <vue-good-table
          :isLoading.sync="isTableLoading"
          :paginationOptions="paginationOptions"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :sort-options="sortOptions"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'enabled'">
              <tick v-model="props.row.enabled"></tick>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
          <div slot="table-actions">
            <button
              @click.prevent="create"
              class="btn btn-link"
              title="New Maintenance Window"
              v-b-popover.hover.top.d500="'Create Maintenance Window'"
            >
              <i class="fa fa-plus dimmedIcon pointer"></i>
            </button>
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="create" type="button">
          <i class="fa fa-plus rpad"></i>Create Maintenance Window
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [tableFilterMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      isTableLoading: false,
      rows: [],
      columns: [
        {
          label: 'Reason',
          field: 'reason'
        },
        {
          label: 'Type',
          field: 'type'
        },
        {
          label: 'Start',
          field: 'start',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'do MMMM yyyy HH:mm'
        },
        {
          label: 'End',
          field: 'end',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'do MMMM yyyy HH:mm'
        },
        {
          label: 'Publish Date',
          field: 'publishDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'do MMMM yyyy HH:mm',
          firstSortType: 'desc'
        },
        {
          label: 'Enabled',
          field: 'enabled',
          sortFn: (x, y) => x === y ? 0 : x ? -1 : 1
        }
      ],
      serverParams: {
        sort: [{ field: 'publishDate', type: 'desc' }],
        historic: false
      }
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  async created () {
    await this.load()
  },
  methods: {
    onRowClick (params) {
      var maintenanceWindowId = params.row.id
      this.$router.push({ name: 'MaintenanceWindowEdit', params: { id: maintenanceWindowId } })
    },
    async load () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}MaintenanceWindow/List`, {
          params: { includeHistoric: this.serverParams.historic, includeDisabled: true },
          showerror: true,
          errormessage: 'Maintenance Windows failed to load'
        })
        this.rows = response.data
      } catch { }
    },
    create () {
      this.$router.push({ name: 'MaintenanceWindowCreate' })
    },
    async onHistoricChange (value) {
      this.updateParams({
        historic: value
      })
      await this.load()
    },
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
    },
    clearTableFilters () {
      this.doneParams = false
      // Clear column filters, doubt there will be any initial ones but just in case apply them after
      this.$refs.table.reset()
      // Update params on good table
      this.savedParamsToObjects(this.defaultParams)

      this.doneParams = true
      // Update params that are actually sent to the server
      this.$nextTick(() => {
        this.updateParams(this.defaultParams)
        this.load()
      })
    }
  }
}

</script>
