<template>
  <form @submit.prevent>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          {{status}} Bank Account
          <help-icon :docPath="docPath" />
        </h2>
      </div>

      <div class="idb-block-content">
        <div
          class="alert alert-warning"
          v-if="bankAccount.isItemActioned"
        >This bank account has a pending action against it and cannot be edited</div>
        <!-- Reference -->
        <div class="form-group row" :class="{ invalid: $v.bankAccount.reference.$error }">
          <label class="col-form-label col-md-3 required">Reference</label>
          <div class="col-md-6">
            <input
              v-focus
              type="text"
              class="form-control"
              v-model.trim="$v.bankAccount.reference.$model"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            />
            <!-- Validation -->
            <validation-messages v-model="$v.bankAccount.reference" name="reference"></validation-messages>
          </div>
        </div>
        <!-- Description -->
        <div class="form-group row" :class="{ invalid: $v.bankAccount.description.$error }">
          <label class="col-form-label col-md-3">Description</label>
          <div class="col-md-6">
            <textarea
              class="form-control"
              rows="3"
              v-model.trim="$v.bankAccount.description.$model"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            ></textarea>
            <!-- Validation -->
            <validation-messages v-model="$v.bankAccount.description"></validation-messages>
          </div>
        </div>
        <!-- Sort Code -->
        <div class="form-group row" :class="{ invalid: $v.bankAccount.sortCode.$error }">
          <label class="label-control col-md-3 required">Sort Code</label>
          <div class="col-md-3">
            <the-mask
              type="text"
              class="form-control"
              v-model="$v.bankAccount.sortCode.$model"
              :mask="['##-##-##']"
              :guide="true"
              ref="sortCode"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            />
            <!-- Validation -->
            <validation-messages v-model="$v.bankAccount.sortCode" name="sort code"></validation-messages>
          </div>
          <div v-if="bankAccountValidation != null">
            <div
              v-if="bankAccountValidation.valid || bankAccountValidation.errorCode === '1502'"
              class="col-md-1"
            >
              <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
            </div>
            <div v-else-if="bankAccountValidation.errorCode !== '1505'" class="col-md-1">
              <span class="text-warning small text-nowrap">{{bankAccountValidation.errorText}}</span>
            </div>
          </div>
        </div>
        <!-- Account Number -->
        <div class="form-group row" :class="{ invalid: $v.bankAccount.accountNumber.$error }">
          <label class="label-control col-md-3 required">Account Number</label>
          <div class="col-md-3">
            <the-mask
              type="text"
              class="form-control"
              v-model="$v.bankAccount.accountNumber.$model"
              :mask="['########']"
              :guide="false"
              ref="accountNumber"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            />
            <validation-messages v-model="$v.bankAccount.accountNumber" name="account number"></validation-messages>
          </div>
          <div v-if="bankAccountValidation != null">
            <div v-if="bankAccountValidation.valid" class="col-md-1">
              <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
            </div>
            <div
              v-else-if="bankAccountValidation.errorCode === '1502' || bankAccountValidation.errorCode === '1505'"
              class="col-md-1"
            >
              <span class="text-warning small text-nowrap">{{bankAccountValidation.errorText}}</span>
            </div>
          </div>
        </div>

        <!-- Account type -->
        <div class="form-group row" :class="{ invalid: $v.bankAccount.accountType.$error }">
          <label class="col-form-label col-md-3 required">Account Type</label>
          <div class="col-md-6">
            <b-form-select
              v-model="$v.bankAccount.accountType.$model"
              :options="accountTypes"
              title="Type"
              @input="$v.$reset()"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            ></b-form-select>
            <!-- Validation -->
            <validation-messages v-model="$v.bankAccount.accountType"></validation-messages>
          </div>
        </div>

        <!-- Currency -->
        <div class="form-group row">
          <label class="label-control col-md-3">Currency</label>
          <div class="col-md-3">
            <div class="form-control-plaintext">
              <span style="margin-right:5px;">{{$v.bankAccount.currency.$model}}</span>
            </div>
          </div>
        </div>
        <!-- Intermediate Bank Account Id -->
        <div class="form-group row">
          <label class="label-control col-md-3">Intermediate Bank Id</label>
          <div class="col-md-3">
            <input
              type="text"
              class="form-control"
              v-model.trim="$v.bankAccount.intermediateBankId.$model"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-3">Linked Clients</label>
          <div class="col-md-9">
            <vue-good-table
              ref="customerContacts"
              :columns="customerColumns"
              :rows="linkedCustomers"
              :totalRows="linkedCustomers.length "
              :pagination-options="{
                          enabled: true,
                          perPage: 10,
                          dropdownAllowAll: false,
                          jumpFirstOrLast: true
                        }"
              styleClass="vgt-table striped bordered"
            ></vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <!-- Balance -->
    <div class="idb-block" v-if="status !== 'Create' && this.canManageBanks && vueAppVersion >= 110">
      <div class="idb-block-title">
        <h2>Balance</h2>
        <span>Last Report Date: {{this.formatDate(bankBalance.transactionDate)}}</span>
      </div>
      <div class="idb-block-content">
        <div class="row">
          <div class="col-md-2 offset-3">
            <p class="font-weight-bold">Last Night's Ledger</p>
          </div>
          <div class="col-md-1">
            <p class="text-right">£{{formatValue(bankBalance.lastNightLedgerBalance)}}</p>
          </div>
          <div class="col-md-2 offset-1">
            <p class="font-weight-bold">Last Night's Cleared</p>
          </div>
          <div class="col-md-1">
            <p class="text-right">£{{formatValue(bankBalance.lastNightClearedBalance)}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 offset-3">
            <p class="font-weight-bold">Start of Day Ledger</p>
          </div>
          <div class="col-md-1">
            <p class="text-right">£{{formatValue(bankBalance.startDayLedgerBalance)}}</p>
          </div>
          <div class="col-md-2 offset-1">
            <p class="font-weight-bold">Start of Day Cleared</p>
          </div>
          <div class="col-md-1">
            <p class="text-right">£{{formatValue(bankBalance.startDayClearedBalance)}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 offset-3">
            <p class="font-weight-bold">Today's Ledger</p>
          </div>
          <div class="col-md-1">
            <p class="text-right">£{{formatValue(bankBalance.todayLedgerBalance)}}</p>
          </div>
          <div class="col-md-2 offset-1">
            <p class="font-weight-bold">Today's Cleared</p>
          </div>
          <div class="col-md-1">
            <p class="text-right">£{{formatValue(bankBalance.todayClearedBalance)}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Bank Name and Address</h2>
      </div>
      <div class="idb-block-content">
        <!-- Bank Name -->
        <div class="form-group row">
          <label class="col-form-label col-md-3">Bank Name</label>
          <div class="col-md-6">
            <input
              class="form-control"
              v-model.trim="bankAccount.bankName"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            />
          </div>
        </div>
        <!-- Address -->
        <div class="form-group row">
          <label class="col-form-label col-md-3">Address</label>
          <div class="col-md-6">
            <input
              class="form-control"
              v-model.trim="bankAccount.address1"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            />
            <input
              class="form-control"
              v-model.trim="bankAccount.address2"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            />
            <input
              class="form-control"
              v-model.trim="bankAccount.address3"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            />
            <input
              class="form-control"
              v-model.trim="bankAccount.address4"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            />
            <input
              class="form-control"
              v-model.trim="bankAccount.address5"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            />
          </div>
        </div>
        <!-- Postcode -->
        <div class="form-group row">
          <label class="col-form-label col-md-3">Post Code</label>
          <div class="col-md-6">
            <input
              class="form-control"
              v-model.trim="bankAccount.postcode"
              :disabled="bankAccount.isItemActioned || disabledDueToConnectedMerchant"
              :readonly="!canManageBanks"
            />
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          :disabled="bankAccount.isItemActioned || isLoading || disabledDueToConnectedMerchant"
          @click="checkValidation() && saveBankAccount()"
          v-if="canManageBanks"
        >{{ status === "Edit" ? "Save" : status }}</button>
        <button class="btn btn-outline-danger pull-right ml-3" type="button" @click="back">Cancel</button>

        <button
          v-if="canManageBanks && status === 'Edit'"
          class="btn btn-danger pull-right"
          @click="deleteBankAccount"
          :disabled="bankAccount.isItemActioned || isLoading || disabledDueToConnectedMerchant"
          type="button"
        >
          <i class="glyphicon ti-trash rpad"></i>Delete Bank Account
        </button>
      </div>
    </div>
  </form>
</template>

<script>

// Third Party
import axios from 'axios'
import _ from 'lodash'
import { TheMask } from 'vue-the-mask'
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import moment from 'moment'

// Mixins
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import ConnectedMerchantReadOnlyMixin from '@/Assets/Mixins/ConnectedMerchantReadOnlyMixin'
import bacsMixin from '@/Lib/BacsMixin.js'

// Constants
import roles from '@/Assets/Constants/roles'
import colours from '@/Assets/Constants/colours'
import accountTypes from '@/Assets/Constants/bankAccountTypes'

// Validations
import {
  required,
  maxLength,
  numeric
} from 'vuelidate/lib/validators'
import { exactLength } from '@/Assets/Validators'

export default {
  mixins: [DataLeaveMixin, loading, ConnectedMerchantReadOnlyMixin, bacsMixin],
  props: ['status', 'id'],
  computed: {
    accountTypes () {
      return Object.values(accountTypes)
    },
    canManageBanks () {
      return this.$store.getters.isInRole(roles.Finance)
    },
    docPath () {
      switch (this.status) {
        case 'Create':
          return '/administration/bankaccounts/createbankaccount/'
        case 'Edit':
          return '/administration/bankaccounts/editbankaccount/'
        default:
          return null
      }
    },
    ...mapGetters(['selectedCustomer', 'vueAppVersion'])
  },
  watch: {
    'bankAccount.sortCode': function () {
      this.digitsOrDashes()
    },
    'bankAccount.accountNumber': function () {
      this.digitsOrDashes()
    },
    selectedCustomer () { this.$router.push({ name: 'BankAccounts' }) }
  },
  components: {
    TheMask
  },
  data () {
    return {
      currencies: ['GBP', 'USD', 'EUR'],
      bankAccount: {
        reference: '',
        description: null,
        accountType: 'Internal',
        sortCode: '',
        accountNumber: '',
        bankIdentifier: null,
        bic: null,
        iban: null,
        currency: 'GBP',
        bankName: null,
        address1: null,
        address2: null,
        address3: null,
        address4: null,
        address5: null,
        country: 'GB',
        postcode: null
      },
      bankBalance: {
        lastNightsLedger: 0,
        lastNightsCleared: 0,
        startOfDayLedger: 0,
        startOfDayCleared: 0,
        todaysLedger: 0,
        todaysCleared: 0,
        reportDate: undefined

      },
      bankAccountValidation: null,
      linkedToCustomers: false,
      linkedCustomers: [],
      customerColumns: [{
        label: 'Name',
        field: 'name'
      },
      {
        label: 'Client ID',
        field: 'accountId'
      },
      {
        label: 'Current Balance (£)',
        field: 'currentBalance',
        type: 'number',
        formatFn: this.formatAmount
      }]
    }
  },
  async mounted () {
    if (this.status === 'Edit') {
      await this.loadBankAccount()
      await this.loadBankAccountCustomers()
      await this.loadBankBalance()
    }
  },
  methods: {
    async saveBankAccount () {
      try {
        if (this.status === 'Create') {
          this.bankAccount.paygateId = this.$store.state.common.paygateId
          const response = await axios.post(
            `${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts`,
            this.bankAccount,
            { showload: true, showerror: true, errormessage: 'Bank Account failed to save' }
          )
          this.$v.$reset()

          this.$v.$reset()
          if (response.data.status === 'Action') {
            this.$toastr.s(
              'New bank account needs to be approved',
              'Needs Approval'
            )
            this.$router.push({ name: 'BankAccounts' })
          } else {
            this.$toastr.s('New bank account created', 'Created')
            this.$router.push({ name: 'BankAccounts' })
          }
        } else {
          const response = await axios.put(
            `${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts`,
            this.bankAccount,
            { showload: true, showerror: true, errormessage: 'Bank Account failed to save' }
          )
          if (response.data.status === 'Action') {
            this.$toastr.s(
              'Bank account update needs to be approved',
              'Needs Approval'
            )
          } else {
            this.$toastr.s('Bank account updated', 'Updated')
          }
          await this.loadBankAccount()
        }
        this.$v.$reset()
      } catch { }
    },
    async deleteBankAccount () {
      try {
        if (this.linkedToCustomers) {
          this.$snapbar.w('Cannot delete this bank account because it is linked to one or more SUNs')
          return
        }

        // Get confirmation from the user that they really want to delete the customer
        var swalResult = await swal.fire({
          title: 'Delete Bank Account',
          text: 'Are you sure you want to delete this bank account?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch (error) {
        // Do nothing as this will be if they clicked cancel
        return
      }

      if (!swalResult.isConfirmed) {
        return
      }

      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.bankAccount.bankAccountId}`,
          { showload: true, showerror: true, errormessage: 'Bank Account failed to delete' }
        )
        if (response.data.status === 'Action') {
          this.$toastr.s(
            'Bank account deletion needs to be approved',
            'Needs Approval'
          )
          await this.loadBankAccount()
        } else {
          this.$toastr.s('Bank Account deleted', 'Deleted')
          this.$router.push({ name: 'BankAccounts' })
        }
        this.$v.$reset()
      } catch { }
    },
    async loadBankAccount () {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.id}`,
          {
            params: { paygateId: this.$store.state.common.paygateId },
            showload: true,
            showerror: true,
            errormessage: 'Bank Account failed to load'
          }
        )
        this.bankAccount = response.data
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      } finally {
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    },
    async loadBankAccountCustomers () {
      try {
        let url = ''
        if (this.vueAppVersion >= 110) {
          url = `${process.env.VUE_APP_PLATFORM_API_URL}ledger/getCustomerBankLedgerBalances?bankAccountId=${this.id}`
        } else {
          url = `${process.env.VUE_APP_PLATFORM_API_URL}ledger/getBankCustomers?bankAccountId=${this.id}`
        }        
        const response = await axios.get(
          url,
          { params: { paygateId: this.$store.state.common.paygateId }, showload: true, showerror: true, errormessage: 'Failed to get bank account clients' }
        )
        const bankAccountCustomers = response.data

        if ((bankAccountCustomers !== undefined && bankAccountCustomers.length > 0)) {
          this.linkedToCustomers = true
          this.linkedCustomers = bankAccountCustomers
        }
      } catch (e) {
        console.log(e)
        // this.$toastr.e(e.response.data, e.response.statusText)
      } finally {
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    },
    async loadBankBalance () {
      try {
        if (this.canManageBanks && this.vueAppVersion >= 110) {
          const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ledger/getBankLedgerBalance?bankAccountId=${this.id}`)
          if (response && response.data) {
            this.bankBalance = response.data
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    },
    digitsOrDashes: function () {
      if (!this.$v.bankAccount.sortCode.$invalid && !this.$v.bankAccount.sortCode.$invalid) {
        this.validate()
      } else {
        this.bankAccount.result = null
      }
    },
    formatAmount (value) {
      try {
        return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } catch (e) {
        return 0
      }
    },
    formatDate: function (dateVal) {
      return moment(dateVal).format('DD/MM/YYYY')
    },
    validate: _.debounce(async function () {
      const data = { sortCode: this.bankAccount.sortCode, accountNumber: this.bankAccount.accountNumber }

      try {
        const response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/ValidateBankAccount`, data, { showerror: true, errormessage: 'Failed to get validate bank account' })

        this.bankAccountValidation = response.data

        if (this.bankAccountValidation.valid) {
          this.bankAccount.bankName = this.bankAccount.bankName ? this.bankAccount.bankName : this.bankAccountValidation.institutionName
          this.bankAccount.postcode = this.bankAccount.postcode ? this.bankAccount.postcode : this.bankAccountValidation.postcode
          this.bankAccount.address1 = this.bankAccount.address1 ? this.bankAccount.address1 : this.bankAccountValidation.address1
          this.bankAccount.address2 = this.bankAccount.address2 ? this.bankAccount.address2 : this.bankAccountValidation.address2
          this.bankAccount.address3 = this.bankAccount.address3 ? this.bankAccount.address3 : this.bankAccountValidation.address3
          this.bankAccount.address4 = this.bankAccount.address4 ? this.bankAccount.address4 : this.bankAccountValidation.address4
        }
      } catch { }
    }, 800)
  },
  validations: {
    bankAccount: {
      reference: { required, maxLength: maxLength(50) },
      description: { maxLength: maxLength(200) },
      sortCode: {
        required,
        numeric,
        exactLength: exactLength(6)
      },
      accountNumber: {
        required,
        numeric,
        exactLength: exactLength(8)
      },
      accountType: {},
      currency: {},
      intermediateBankId: {}
    }
  }
}
</script>
