<template>
<div class="row" >
  <div class="col-md-12">
    <div class="input">
      <div class="form-group">
        <label>{{label}}</label><span class="required" v-if="this.isRequired"></span>
        <input class="form-control input-sm" type="text"
          :name="name"
          :value="value"
          :class="{invalid: $v.value.$error}"
          @input="$emit('input',$event.target.value); $v.value.$touch() "
          :placeholder="placeholder">
        <p class="validationText" v-if="!$v.value.email && $v.value.$dirty">Use a proper email address!</p>
        <p class="validationText" v-if="!$v.value.url && $v.value.$dirty">Invalid URL</p>
        <p class="validationText" v-if="!$v.value.required && $v.value.$dirty">Required Field</p>
        <p class="validationText" v-if="!$v.value.maxLength  && $v.value.$dirty">Max Length is {{this.maxLength}}</p>
        <p class="validationText" v-if="!$v.value.minLength && $v.value.$dirty ">Min Length is {{this.minLength}}</p>
        <p class="validationText" v-if="!$v.value.alpha && $v.value.$dirty ">Alpha only!</p>
        <p class="validationText" v-if="!$v.value.alphaNum && $v.value.$dirty ">AlphaNum only!</p>
        <p class="validationText" v-if="!$v.value.numeric && $v.value.$dirty ">Numeric only!</p>
        <p class="validationText" v-if="!$v.value.ipAddress && $v.value.$dirty">Must be a valid ip address!</p>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { required, url, maxLength, minLength, alpha, alphaNum, numeric, ipAddress } from 'vuelidate/lib/validators'
import { email } from '@/Assets/Validators'

export default {
  name: 'TextInput',
  props: [
    'placeholder',
    'label',
    'name',
    'value',
    'validationType',
    'maxLength',
    'minLength',
    'isRequired'
  ],
  validations () {
    const o = {}
    o.value = {}

    // Required
    if (this.isRequired) {
      o.value.required = required
    } else {
      o.value.required = false
    }

    // Email
    if (this.validationType === 'email') {
      o.value.email = email
    } else {
      o.value.email = false
    }

    // URL
    if (this.validationType === 'url') {
      o.value.url = url
    } else {
      o.value.url = false
    }

    // IP Address
    if (this.validationType === 'ipAddress') {
      o.value.ipAddress = ipAddress
    } else {
      o.value.ipAddress = false
    }

    // Max length
    if (this.maxLength) {
      o.value.maxLength = maxLength(this.maxLength)
    } else {
      o.value.maxLength = false
    }

    // Min length
    if (this.minLength) {
      o.value.minLength = minLength(this.minLength)
    } else {
      o.value.minLength = false
    }

    // Alpha
    if (this.validationType === 'alpha') {
      console.log('alpha')
      o.value.alpha = alpha
    } else {
      o.value.alpha = false
    }

    // Alpha Numeric
    if (this.validationType === 'alphaNum') {
      o.value.alphaNum = alphaNum
    } else {
      o.value.alphaNum = false
    }

    // Numeric
    if (this.validationType === 'numeric') {
      o.value.numeric = numeric
    } else {
      o.value.numeric = false
    }
    return o
  },
  mounted () {
    console.log('mounted')
    console.log('value: ' + this.value)
    this.$v.$touch()
  }
}
</script>
<style>
</style>
