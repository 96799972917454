<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Holding Report
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>Clients</label>

                <multi-select
                  v-model="selectedCustomers"
                  :values="customers"
                  :options="customerOptions"
                ></multi-select>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click="load()"
              :disabled="isLoading"
            >Load Holding Amounts</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loaded">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Holding Amounts</h2>
          </div>
          <div class="idb-block-content">
            <div class="row">
              <div class="col-md-12">
                <vue-good-table
                  :rows="holdings"
                  :columns="columns"
                  :pagination-options="{
                  enabled:true,
                    jumpFirstOrLast:true
                }"
                  :sort-options="{
                  initialSortBy: {field: 'difference', type: 'desc'}}"
                  styleClass="vgt-table striped bordered"
                ></vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'

// Components
import MultiSelect from '@/Assets/Components/MultiSelect'

export default {
  name: 'HoldingReport',
  mixins: [loading, settlementMixin],
  components: {
    MultiSelect
  },
  computed: {
    ...mapGetters(['customersOrdered'])
  },
  data () {
    return {
      loaded: false,
      holdings: [],
      selectedCustomers: [],
      customers: [],
      customerOptions: {
        search: true,
        selectAll: true,
        texts: {
          placeholder: 'Select Clients',
          search: 'Search Clients'
        }
      },

      columns: [
        {
          label: 'Client Name',
          field: 'name',
          sortable: true
        },
        {
          label: 'Set Holding Amount (£)',
          field: 'setHoldingAmount',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Current Holding Amount (£)',
          field: 'currentHoldingAmount',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Difference (£)',
          field: 'difference',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        }
      ]
    }
  },
  mounted () {
    this.customers = this.customersOrdered.map(co => ({ value: co.paygateId, name: co.name }))
  },
  methods: {
    async load () {
      var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Holding`, {
        customers: this.selectedCustomers
      }, {
        showload: true,
        showerror: true,
        errormessage: 'Holding Report failed to load'
      })

      this.holdings = response.data
      this.loaded = true
    }
  }
}
</script>

<style>
</style>
