<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Liability Report
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>Bank Accounts</label>

                <multi-select
                  v-model="selectedBankAccounts"
                  :values="bankAccounts"
                  :options="bankAccountOptions"
                ></multi-select>
              </div>
              <div class="form-group col-md-4">
                <label>SUNs</label>

                <multi-select v-model="selectedSuns" :values="suns" :options="sunOptions"></multi-select>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click="load()"
              :disabled="isLoading"
            >Generate Liabilities</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loaded">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Generated Liabilities</h2>
          </div>
          <div class="idb-block-content">
            <div class="row">
              <div class="col-md-12">
                <vue-good-table
                  :rows="liability.liabilities"
                  :columns="columns"
                  :pagination-options="{
                  enabled:true,
                    jumpFirstOrLast:true
                }"
                  :sort-options="sortOptions"
                  styleClass="vgt-table striped bordered"
                >
                  <template slot="table-row" slot-scope="props">
                    <span
                      v-if="props.column.tdClass === 'text-right'"
                      :class="tdClass(props.row, props.column.field)"
                    >{{props.formattedRow[props.column.field]}}</span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                  <div slot="table-actions-bottom" class="text-right">
                    <span
                      :class="moneyClass(liability.totalLiability)"
                      class="mr-20"
                    >Total Liability: £{{moneyFormat(liability.totalLiability)}}</span>
                  </div>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'

// Components
import MultiSelect from '@/Assets/Components/MultiSelect'

export default {
  name: 'CustomerLiabilityReport',
  mixins: [loading, settlementMixin],
  computed: {
    nonZeroLiabilities () {
      return this.liability.liabilities.filter(l => l.amount !== 0)
    }
  },
  components: {
    MultiSelect
  },
  data () {
    return {
      loaded: false,
      bankAccounts: [],
      selectedBankAccounts: [],
      bankAccountOptions: {
        search: true,
        selectAll: true,
        texts: {
          placeholder: 'Select Bank Accounts',
          search: 'Search Bank Accounts'
        }
      },
      suns: [],
      selectedSuns: [],
      sunOptions: {
        search: true,
        selectAll: true,
        texts: {
          placeholder: 'Select SUNs',
          search: 'Search SUNs'
        }
      },
      liability: {
        totalLiability: 0,
        liabilities: []
      },
      columns: [
        {
          label: 'Client Name',
          field: 'name',
          sortable: true
        },
        {
          label: 'Liability (£)',
          field: 'amount',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        }
      ],
      colours: [],
      sortOptions: { enabled: true, initialSortBy: { field: 'name', type: 'asc' } }
    }
  },
  mounted () {
    this.loadSuns()
    this.loadBankAccounts()
  },
  methods: {
    async loadSuns () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Sun/Dropdown`, { showerror: true, errormessage: 'Failed to get SUNs' })
        this.suns = response.data.map(s => ({ value: s.id, name: s.label }))
      } catch { }
    },
    async loadBankAccounts () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/Dropdown`, { showerror: true, errormessage: 'Failed to get bank accounts' })

        this.bankAccounts = response.data.map(b => ({ value: b.bankAccountId, name: b.reference })
        )
      } catch { }
    },
    async load () {
      var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Liability`, {
        bankAccounts: this.selectedBankAccounts,
        suns: this.selectedSuns
      }, {
        showload: true,
        showerror: true,
        errormessage: 'Liabilities failed to load'
      })

      this.liability = response.data
      this.loaded = true
    }
  }
}
</script>

<style>
</style>
