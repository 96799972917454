<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline Files<span class="pull-right"></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div>
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              :rows="rows"
              :columns="columns"
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              ref="table"
              :lineNumbers="false"
              styleClass="vgt-table striped bordered table-hover"
              @on-row-click="onRowClick"
              >
              <template slot="table-row" slot-scope="props">
                <span v-if = "props.column.field == 'filename'">
                  <div>{{props.row.filename}}</div>
                  <div class="tinyText mt-2">Items in file: {{props.row.txCount}}</div>
                </span>
                <span v-else-if = "props.column.field === 'fileStatus'">
                  <b-badge pill v-bind:variant="getStatusVariant(props.row.fileStatus)">{{ props.row.fileStatus }}</b-badge>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
            </template>
              <div slot="table-actions">
                <table-actions
                  :data-url="dataUrl"
                  :export-data="tableServerData"
                  :print-enabled="false"
                  :table-amount="totalRecords"
                  :export-name="exportFilename"
                  :reload-table="load"
                  :table-columns="columns"
                  :clear-table-filters="clearTableFilters"
                >
                  <b-button @click.prevent="btnUploadBanklineFile" class="" variant="link" v-b-popover.hover.top.d500="'Upload a Bankline File.'" ><i class="fas fa-upload pointer dimmedIcon"></i></b-button>

                </table-actions>
              </div>
            </vue-good-table>
          </div>
          <br/>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div class="btn-group" role="group">
                <button id="btnGroupDrop1" @click="btnUploadBanklineFile" type="button" class="btn btn-primary">
                  <i class="fa fa-upload mr-2"></i>Upload a Bankline File
                </button>
              </div>
              <div v-if="vueAppVersion >= 110" class="btn-group ml-2" role="group">
                <button id="btnGroupDrop1" @click="btnViewAllBalances" type="button" class="btn btn-outline-primary">
                  <i class="fa fa-list mr-2"></i>View Bank Balances
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
export default {
  name: 'banklineoutbound',
  mixins: [tableFilterMixin],
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      columns: [
        {
          label: 'Id',
          field: 'fileId',
          hidden: true
        },
        {
          label: 'Filename',
          field: 'filename',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Uploaded',
          field: 'importDate',
          hidden: false,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy HH:mm:ss',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'File Type',
          field: 'banklineFileType',
          filterOptions: {
            enabled: true,
            placeholder: 'All Types',
            filterDropdownItems: [
              { value: '0', text: 'Account Statement' },
              { value: '1', text: 'Account Balance' }
            ]
          }
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Items in file',
          field: 'numItems',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Status',
          field: 'fileStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'All Status',
            filterDropdownItems: [
              { value: '0', text: 'Unprocessed' },
              { value: '1', text: 'Processed' },
              { value: '2', text: 'Processed With Warnings' },
              { value: '5', text: 'Processed With Errors' },
              { value: '3', text: 'Duplicate'}
            ]
          }
        }
      ],
      rows: [],
      totalRecords: 0
    }
  },
  computed: {
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}reconciliation/banklineFiles`
    },
    tableServerData () {
      return this.buildGoodTableQuery()
    },
    exportFilename () {
      const date = new Date()
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()
      let currentDate = `${day}-${month}-${year}`;      
      return 'BankLineFileList ' + currentDate
    },
    ...mapGetters(['vueAppVersion'])
  },
  methods: {
    async load () {
      try {
        var importFileResponse = this.$store.getters.importFileResponse
        if (importFileResponse !== undefined && importFileResponse !== null && importFileResponse.errorMessage !== undefined && importFileResponse.errorMessage.length > 0) {
          this.$snapbar.w(importFileResponse.errorMessage)
        }
        this.$store.dispatch('setImportFileResponse', null)
      } catch (e) { 
        console.log('Error: ' + e.response)
      }

      try {
        this.isTableLoading = true
        const response = await axios.get(this.dataUrl,
          {
            params: { ...this.tableServerData },
            showerror: true,
            errormessage: 'Bankline files search failed'
          })

        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch (e) { 
        console.log('Error: ' + e.response)
      } finally {
        this.isTableLoading = false
        this.$store.dispatch('setImportFileResponse', null)
      }
    },

    getStatusVariant (fileStatus) {
      var variant = ''
      switch (fileStatus) {
        case 'Unprocessed':
          variant = 'secondary'
          break
        case 'Processed':
          variant = 'success'
          break
        case 'Processed With Warnings':
          variant = 'warning'
          break
        case 'Duplicate':
        case 'Processed With Errors':
          variant = 'danger'
          break
      }
      return variant
    },
    async btnViewAllBalances () {
      this.$router.push('/admin/bandr/bankledgerbalances')
    },
    async btnUploadBanklineFile () {
      this.$router.push('/admin/bandr/bankline/uploadfile')
    },
    onRowClick (params) {
      if (params.row.banklineFileType === 'Account Statement') {
        this.$router.push(`/admin/bandr/banklinetransactions/${params.row.fileId}`)
      } else {
        this.$router.push(`/admin/bandr/banklineaccountbalances/${params.row.fileId}`)
      }
    }
  },
  async mounted () {
    //await this.load()
  }
}
</script>
<style scoped>
  td {
    cursor: pointer;
  }
  .tinyText {
    font-size: x-small;
  }
</style>
