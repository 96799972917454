<template>
  <div>
    <div class="idb-block" v-if="showForJftp">
      <div
        class="idb-block-title"
        v-b-toggle.paymentDetailsCollapse
        :class="{invalid: details.paymentDetails.$error}"
      >
        Payment Details
        <i class="fa fa-chevron-down"></i>
        <i class="fa fa-chevron-up"></i>
      </div>
      <b-collapse id="paymentDetailsCollapse">
        <div class="idb-block-content">
          <!-- Service User Number -->
          <div class="form-group row" v-if="showForJftp">
            <label class="col-form-label col-md-3 required">Service User Number</label>
            <div class="col-md-5">
              <div class="form-control-plaintext">
                <span style="margin-right:5px;">{{details.serviceUserNumber.$model}}</span>
              </div>
            </div>
          </div>
          <!-- Bacs Submission type-->
          <div class="form-group row" v-if="showForJftp">
            <label class="label-control col-md-3 required">BACS Submission Type:</label>
            <div class="col-md-5">
              <b-form-select :options="bacsSubmissionTypes" v-model="details.submissionType.$model"></b-form-select>
            </div>
          </div>
          <!-- Import Schema -->
          <div class="form-group row" v-if="false">
            <label class="label-control col-md-3">Import Schema</label>
            <div class="col-md-5">
              <b-form-select v-model="details.importSchema.$model" :options="importSchemas"></b-form-select>
            </div>
          </div>

          <!-- Mapping -->
          <div class="form-group row" v-if="details.importSchema.$model === 'Mapping'">
            <label class="label-control col-md-3">Mapping</label>
            <div class="col-md-5">
              <b-form-select v-model="details.mappingId.$model" :options="mappings"></b-form-select>
            </div>
          </div>

          <!-- Bank Account-->
          <bank-account-view :bankAccount="details.bankAccount" v-if="showForJftp"></bank-account-view>
          <!-- Payment Signing Method -->
          <div
            class="form-group row"
            v-if="showForJftp"
            :class="{invalid: details.tokenType.$error}"
          >
            <label class="label-control col-md-3 required">Payment Signing Method</label>
            <div class="col-md-5">
              <p-radio
                class="p-default p-round"
                value="Hsm"
                v-model="details.tokenType.$model"
                :disabled="disabled"
                v-if="!isIndirect && hasHsm"
              >HSM</p-radio>
              <p-radio
                class="p-default p-round"
                value="SmartCard"
                v-model="details.tokenType.$model"
                :disabled="disabled"
              >Smart Card</p-radio>
              <p-radio
                class="p-default p-round"
                value="UsbToken"
                v-model="details.tokenType.$model"
                :disabled="disabled"
                v-if="isIndirect"
              >USB Token</p-radio>
              <p-radio
                class="p-default p-round"
                value="SecurityCode"
                v-model="details.tokenType.$model"
                :disabled="disabled"
                v-if="isIndirect"
              >Security Code</p-radio>
              <p-radio
                class="p-default p-round"
                value="None"
                v-model="details.tokenType.$model"
                :disabled="disabled"
                v-if="isIndirect"
              >None</p-radio>
              <validation-messages v-model="details.tokenType" name="payment signing method"></validation-messages>
            </div>
          </div>
          <div class="form-group row" v-if="isIndirect">
            <label class="label-control col-md-3">Proxy Group</label>
            <div class="col-md-3">
              <p-check class="p-switch p-fill" color="primary" v-model="details.proxyGroup.$model"></p-check>
            </div>
          </div>
          <div
            class="form-group row"
            :class="{invalid: details.groupEmail.$error}"
            v-if="isIndirect"
          >
            <label class="label-control col-md-3 required">Group Email Address</label>
            <div class="col-md-5">
              <input type="email" class="form-control" v-model.trim="details.groupEmail.$model" />
              <validation-messages v-model="details.groupEmail" name="email"></validation-messages>
            </div>
          </div>
          <!-- HSM Certificate -->
          <div
            class="form-group row"
            :class="{invalid: details.certificateId.$error, warn: !selectedHSMCert.valid}"
            v-if="details.tokenType.$model == 'Hsm' && !isIndirect && showForJftp"
          >
            <label class="label-control col-md-3 required">HSM Certificate</label>
            <div class="col-md-5">
              <b-form-select v-model="details.certificateId.$model" :options="hsmCertificates"></b-form-select>
              <validation-messages v-model="details.certificateId" name="HSM certificates"></validation-messages>
              <span class="validation-messages" v-if="!selectedHSMCert.valid">
                <small class="form-text small">Certificate is expired</small>
              </span>
            </div>
          </div>

          <!-- HSM PIN required -->
          <div v-if="details.tokenType.$model == 'Hsm' && showForJftp" class="form-group row">
            <label class="col-form-label col-md-3">HSM PIN Required</label>
            <div class="col-md-3">
              <p-check
                class="p-switch p-fill"
                color="primary"
                :disabled="disabled"
                v-model="details.hsmPinRequired.$model"
              ></p-check>
            </div>
          </div>
          <template v-if="showAdvanced">
            <!-- Individual Payment Limit -->
            <div class="form-group row" :class="{invalid: details.paymentLimit.$error}">
              <label class="col-form-label col-md-3">Individual Collection Limit</label>
              <div class="col-md-3">
                <input
                  type="number"
                  class="form-control"
                  v-model.trim="details.paymentLimit.$model"
                />
                <!-- Validation -->
                <validation-messages v-model="details.paymentLimit" name="Individual payment limit">
                  <small
                    class="form-text small"
                    v-if="details.paymentLimit.wholepounds != undefined && !details.paymentLimit.wholepounds"
                  >individual item limit can only be in whole pounds</small>
                </validation-messages>
                <small class="form-text text-muted">Set to 0 for no limit</small>
              </div>
            </div>

            <!-- Submission  Limit -->
            <div class="form-group row" :class="{invalid: details.submissionLimit.$error}">
              <label class="col-form-label col-md-3">Submission Collection Limit</label>
              <div class="col-md-3">
                <input
                  type="number"
                  class="form-control"
                  v-model.trim="details.submissionLimit.$model"
                />
                <!-- Validation -->
                <validation-messages
                  v-model="details.submissionLimit"
                  name="Submission payment limit"
                >
                  <small
                    class="form-text small"
                    v-if="details.paymentLimit.wholepounds != undefined && !details.paymentLimit.wholepounds"
                  >submission item limit can only be in whole pounds</small>
                </validation-messages>
                <small class="form-text text-muted">Set to 0 for no limit</small>
              </div>
            </div>
            <!-- Is indirect submitter group -->
            <div class="form-group row" v-if="showForJftp">
              <label class="col-form-label col-md-3">Is Indirect Submitter Group</label>
              <div class="col-md-3">
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  :disabled="disabled"
                  v-model="details.isIndirectSubmitterGroup.$model"
                ></p-check>
              </div>
            </div>
            <!-- Allow File Level Signing -->
            <div class="form-group row" v-if="showForJftp">
              <label class="col-form-label col-md-3">Allow File Level Signing</label>
              <div class="col-md-3">
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  :disabled="disabled"
                  v-model="details.allowFileLevelSigning.$model"
                ></p-check>
              </div>
            </div>

            <!-- Segregate Group Roles -->
            <div class="form-group row" v-if="showForJftp">
              <label class="col-form-label col-md-3">Segregate Group Roles</label>
              <div class="col-md-3">
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  :disabled="disabled"
                  v-model="details.segregateGroupRoles.$model"
                ></p-check>
              </div>
            </div>

            <!-- Delete Payment Data -->
            <div class="form-group row" v-if="showForJftp">
              <label class="col-form-label col-md-3">Delete Payment Data</label>
              <div class="col-md-3">
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  :disabled="disabled"
                  v-model="details.deletePaymentData.$model"
                ></p-check>
              </div>
            </div>
          </template>
        </div>
      </b-collapse>
    </div>
    <div class="idb-block">
      <div
        class="idb-block-title"
        v-b-toggle.submissionDetailsCollapse
        :class="{invalid: details.submissionDetails.$error}"
      >
        Submission Details
        <i class="fa fa-chevron-down"></i>
        <i class="fa fa-chevron-up"></i>
      </div>
      <b-collapse id="submissionDetailsCollapse">
        <!-- Submission Window Type -->
        <div class="idb-block-content">
          <div class="form-group row" v-if="false">
            <label class="col-form-label col-md-3">Submission Window Type</label>
            <div class="col-md-3">
              <b-form-select
                :options="submissionWindowTypes"
                v-model="details.submissionWindowType.$model"
              />
            </div>
          </div>

          <!-- Window Size -->
          <div class="form-group row" v-if="false">
            <!--details.submissionWindowType.$model == 2 -->
            <label class="col-form-label col-md-3">Window Size</label>
            <div class="col-md-3">
              <input
                type="number"
                class="form-control"
                v-model.trim="details.multidayWindowSize.$model"
              />
            </div>
          </div>

          <!-- Default Contra Narrative -->
          <div class="form-group row">
            <label class="col-form-label col-md-3">Default Contra Narrative</label>
            <div class="col-md-3">
              <input
                type="text"
                class="form-control"
                maxlength="18"
                v-model.trim="details.defaultContraNarrative.$model"
              />
            </div>
          </div>

          <!-- AUDDIS Group -->
          <div class="form-group row">
            <label class="col-form-label col-md-3">AUDDIS Group</label>
            <div class="col-md-3">
              <p-check class="p-switch p-fill" color="primary" v-model="details.isAuddis.$model"></p-check>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="idb-block" v-if="showAdvanced && false">
      <!-- All at customer level etc. -->
      <div
        class="idb-block-title"
        v-b-toggle.serviceUserDetailsCollapse
        :class="{invalid: details.serviceUserDetails.$error}"
      >
        Service User Details
        <i class="fa fa-chevron-down"></i>
        <i class="fa fa-chevron-up"></i>
      </div>
      <b-collapse id="serviceUserDetailsCollapse">
        <div class="idb-block-content" :key="componentKey">
          <!-- Service User Name -->
          <div
            class="form-group row"
            v-if="false"
            :class="{invalid: details.serviceUserName.$error}"
          >
            <label class="col-form-label col-md-3 required">Service User Name</label>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                :value="details.serviceUserName.$model"
                @input="inputServiceUserName($event.target.value)"
              />
              <!-- Validation -->
              <validation-messages v-model="details.serviceUserName" name="service user name">
                <small
                  class="form-text small"
                  v-if="
                details.serviceUserName.isUnique != undefined &&
                !details.serviceUserName.isUnique
              "
                >Please ensure service user name is unique</small>
              </validation-messages>
            </div>
          </div>
          <template v-if="showAdvanced">
            <!-- this is now at customer level -->
            <!-- FM Name -->
            <div class="form-group row">
              <label class="label-control col-md-3">Service User FM Name</label>
              <div class="col-md-5">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="details.serviceUserFMName.$model"
                />
              </div>
            </div>

            <!-- Address 1 -->
            <div class="form-group row">
              <label class="label-control col-md-3">Service User Short Name</label>
              <div class="col-md-5">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="details.serviceUserShortName.$model"
                />
              </div>
            </div>

            <!-- Address 1 -->
            <div class="form-group row">
              <label class="label-control col-md-3">Service User Trading Name</label>
              <div class="col-md-5">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="details.serviceUserTradingName.$model"
                />
              </div>
            </div>

            <!-- Address 1 -->
            <div class="form-group row">
              <label class="label-control col-md-3">Address 1</label>
              <div class="col-md-5">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="details.serviceUserAddress1.$model"
                />
              </div>
            </div>

            <!-- Address 2 -->
            <div class="form-group row">
              <label class="label-control col-md-3">Address 2</label>
              <div class="col-md-5">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="details.serviceUserAddress2.$model"
                />
              </div>
            </div>

            <!-- Address 3 -->
            <div class="form-group row">
              <label class="label-control col-md-3">Address 3</label>
              <div class="col-md-5">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="details.serviceUserAddress3.$model"
                />
              </div>
            </div>

            <!-- Address 4 -->
            <div class="form-group row">
              <label class="label-control col-md-3">Address 4</label>
              <div class="col-md-5">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="details.serviceUserAddress4.$model"
                />
              </div>
            </div>

            <!-- Postcode -->
            <div class="form-group row">
              <label class="label-control col-md-3">Postcode</label>
              <div class="col-md-4">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="details.serviceUserPostcode.$model"
                />
              </div>
            </div>

            <!-- Service User Telephone -->
            <div class="form-group row">
              <label class="label-control col-md-3">Service User Telephone</label>
              <div class="col-md-5">
                <input
                  type="tel"
                  class="form-control"
                  v-model.trim="details.serviceUserTelephone.$model"
                />
              </div>
            </div>

            <!-- Service User Fax -->
            <div class="form-group row">
              <label class="label-control col-md-3">Service User Fax</label>
              <div class="col-md-5">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="details.serviceUserFax.$model"
                />
              </div>
            </div>
            <!-- Service User Fax -->
            <div class="form-group row">
              <label class="label-control col-md-3">Service User Website</label>
              <div class="col-md-5">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="details.serviceUserWebsite.$model"
                />
              </div>
            </div>

            <!-- Email Address -->
            <div class="form-group row" :class="{invalid: details.serviceUserEmail.$error}">
              <label class="label-control col-md-3">Email Address</label>
              <div class="col-md-5">
                <input
                  type="email"
                  class="form-control"
                  v-model.trim="details.serviceUserEmail.$model"
                />
                <validation-messages v-model="details.serviceUserEmail" name="email"></validation-messages>
              </div>
            </div>
          </template>
        </div>
      </b-collapse>
    </div>
    <div class="idb-block" v-if="showAdvanced && false">
      <!-- again, this is now at customer level -->
      <div
        class="idb-block-title"
        v-b-toggle.serviceUserContactNamesCollapse
        :class="{invalid: details.serviceUserContactNames.$error}"
      >
        Service User Contact Names
        <i class="fa fa-chevron-down"></i>
        <i class="fa fa-chevron-up"></i>
      </div>
      <b-collapse id="serviceUserContactNamesCollapse">
        <div class="idb-block-content">
          <!-- Service User Contact Name 1 -->
          <div class="form-group row">
            <label class="label-control col-md-3">Service User Contact Name 1</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="details.serviceUserContactName1.$model"
              />
            </div>
          </div>

          <!-- Service User Contact Name 2 -->
          <div class="form-group row">
            <label class="label-control col-md-3">Service User Contact Name 2</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="details.serviceUserContactName2.$model"
              />
            </div>
          </div>

          <!-- Service User Contact Name 3 -->
          <div class="form-group row">
            <label class="label-control col-md-3">Service User Contact Name 3</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="details.serviceUserContactName3.$model"
              />
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="idb-block" v-if="showAdvanced">
      <div
        class="idb-block-title"
        v-b-toggle.customFieldsCollapse
        :class="{invalid: details.customFields.$error}"
      >
        Custom Fields
        <i class="fa fa-chevron-down"></i>
        <i class="fa fa-chevron-up"></i>
      </div>
      <b-collapse id="customFieldsCollapse">
        <div class="idb-block-content">
          <!-- Custom Field 1 -->
          <div class="form-group row">
            <label class="label-control col-md-3">Custom Field 1</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="details.serviceUserCustomField1.$model"
              />
            </div>
          </div>

          <!-- Custom Field 2 -->
          <div class="form-group row">
            <label class="label-control col-md-3">Custom Field 2</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="details.serviceUserCustomField2.$model"
              />
            </div>
          </div>

          <!-- Custom Field 3 -->
          <div class="form-group row">
            <label class="label-control col-md-3">Custom Field 3</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="details.serviceUserCustomField3.$model"
              />
            </div>
          </div>

          <!-- Custom Field 4 -->
          <div class="form-group row">
            <label class="label-control col-md-3">Custom Field 4</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="details.serviceUserCustomField4.$model"
              />
            </div>
          </div>

          <!-- Custom Field 5 -->
          <div class="form-group row">
            <label class="label-control col-md-3">Custom Field 5</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="details.serviceUserCustomField5.$model"
              />
            </div>
          </div>

          <!-- Custom Field 6 -->
          <div class="form-group row">
            <label class="label-control col-md-3">Custom Field 6</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="details.serviceUserCustomField6.$model"
              />
            </div>
          </div>

          <!-- Custom Field 7 -->
          <div class="form-group row">
            <label class="label-control col-md-3">Custom Field 7</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="details.serviceUserCustomField7.$model"
              />
            </div>
          </div>

          <!-- Custom Field 8 -->
          <div class="form-group row">
            <label class="label-control col-md-3">Custom Field 8</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                v-model.trim="details.serviceUserCustomField8.$model"
              />
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="idb-block" v-if="showAdvanced">
      <div
        class="idb-block-title"
        v-b-toggle.payerMessagingCollapse
        :class="{invalid: details.payerMessaging.$error}"
      >
        Customer Messaging
        <i class="fa fa-chevron-down"></i>
        <i class="fa fa-chevron-up"></i>
      </div>
      <b-collapse id="payerMessagingCollapse">
        <div class="idb-block-content">
          <!-- Default Payer Message Type -->
          <div class="form-group row">
            <label class="label-control col-md-3">Default Customer Message Type</label>
            <div class="col-md-3">
              <b-form-select
                :options="allowedMessageChannels"
                v-model="details.defaultPayerMessageType.$model"
              />
              <small
                v-if="details.defaultPayerMessageType.$model === 2"
              >Initial confirmation messages will not be sent by SMS to adhere to scheme rules, please select an alternative for confirmation messages.</small>
            </div>
          </div>

          <!-- Initial Message Fallback Type -->
          <div class="form-group row">
            <label class="label-control col-md-3">Initial Message Fallback Type</label>
            <div class="col-md-3">
              <b-form-select
                :options="allowedFallbackMessageChannels"
                v-model="details.initialMessageFallbackType.$model"
              />
            </div>
          </div>

          <!-- Letter File Export Type -->
          <div class="form-group row" v-if="lettersAllowed">
            <label class="label-control col-md-3">Letter File Export Type</label>
            <div class="col-md-3">
              <div class="side-icon">
                <b-form-select
                  :options="letterFileExportTypes"
                  v-model="details.docxExportType.$model"
                  :plain="true"
                />
                <i id="docExportIcon" class="fa fa-info-circle"></i>
                <b-tooltip target="docExportIcon" placement="right">
                  Single - Letters for individual customers are created as separate Word (.docx) files.
                  Merged - Letters for individual customers are created in a single Word (.docx) file separated by a page break.
                </b-tooltip>
              </div>
            </div>
          </div>

          <!-- Email BCC Address List -->
          <div class="form-group row" :class="{invalid: details.emailBccList.$error}">
            <label class="label-control col-md-3">Email BCC Address List</label>
            <div class="col-md-6">
              <div class="side-icon">
                <textarea rows="3" class="form-control" v-model.trim="details.emailBccList.$model"></textarea>
                <i id="emailBccIcon" class="fa fa-info-circle"></i>
                <b-tooltip target="emailBccIcon" placement="right">
                  All customer email messages will be 'bcc' sent to the recipients listed below.
                  Semicolon (;) separated list of email addresses
                </b-tooltip>
              </div>
              <validation-messages v-model="details.emailBccList" name="BCC List">
                <small
                  class="form-text small"
                  v-if="details.emailBccList.bccValidator != undefined && !details.emailBccList.bccValidator"
                >Invalid email addresses found, please use semicolon (;) to separate values.</small>
              </validation-messages>
            </div>
          </div>

          <!-- Letter File Export Folder -->
          <div class="form-group row" v-if="lettersAllowed">
            <label class="label-control col-md-3">Letter File Export Folder</label>
            <div class="col-md-6">
              <div class="side-icon">
                <input type="text" class="form-control" v-model="details.letterOutputFolder.$model" />
                <i id="letterOutputFolderIcon" class="fa fa-info-circle" style="float:left"></i>
                <b-tooltip
                  target="letterOutputFolderIcon"
                  placement="right"
                >The folder that PayGate will use to create generated customer letters.</b-tooltip>
              </div>
            </div>
          </div>

          <!-- Email Address -->
          <div class="form-group row">
            <label class="label-control col-md-3">Email Address</label>
            <div class="col-md-6">
              <div class="side-icon">
                <b-form-select
                  :options="emailAddresses"
                  value-field="id"
                  text-field="emailAddress"
                  v-model="details.domainEmailId.$model"
                />
                <i id="domainEmailIcon" class="fa fa-info-circle"></i>
                <b-tooltip
                  target="domainEmailIcon"
                  placement="right"
                >Please note: emails that cannot be successfully delivered will cause changes to associated Customer statuses – please refer to the DDMS help here for more information</b-tooltip>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="idb-block" v-if="showAdvanced">
      <div class="idb-block-title" v-b-toggle.orphanSubmissionCollapse>
        Unprocessed Submission Settings
        <i class="fa fa-chevron-down"></i>
        <i class="fa fa-chevron-up"></i>
      </div>
      <b-collapse id="orphanSubmissionCollapse">
        <div class="idb-block-content">
          <div class="form-group row">
            <label class="label-control col-md-3">Allow warnings on missed submissions</label>
            <div class="col-md-3">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="details.allowOrphanWarnings.$model"
              ></p-check>
            </div>
          </div>
          <div class="form-group row" v-if="details.allowOrphanWarnings.$model">
            <label class="label-control col-md-3">Late submission notification recipients</label>
            <div class="col-md-4">
              <ul
                class="list-group"
                v-draggable="{value: details.orphanReportingEmails.$model }"
                @end="onDragEnd"
              >
                <li
                  class="list-group-item"
                  v-for="(email,index) in details.orphanReportingEmails.$model"
                  :key="email.orphanReportingEmailId"
                >
                  {{index+1}}: {{email.emailAddress}}
                  <div class="pull-right">
                    <b-btn @click="delItem(email)" variant="danger">
                      <i class="fa fa-trash"></i>
                    </b-btn>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group row" v-if="details.allowOrphanWarnings.$model">
            <label class="label-control col-md-3">Add Email</label>
            <div class="col-md-3">
              <div class="side-icon">
                <input type="text" class="form-control" v-model="newEmail" />
              </div>
            </div>
            <div class="col-md-3">
              <b-button variant="primary" @click="addEmail">
                <i class="fa fa-plus rpad"></i>Add
              </b-button>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="idb-block" v-if="showAdvanced">
      <div class="idb-block-title" v-b-toggle.planSettingsCollapse>
        Schedule Settings
        <i class="fa fa-chevron-down"></i>
        <i class="fa fa-chevron-up"></i>
      </div>
      <b-collapse id="planSettingsCollapse">
        <div class="idb-block-content">
          <div class="form-group row">
            <label class="label-control col-md-3">Default Schedule Template</label>
            <div class="col-md-3">
              <b-form-select
                :options="defaultPlanTemplates"
                v-model="details.defaultPlanTemplateId.$model"
                value-field="paymentPlanTemplateId"
                text-field="name"
              >
                <template slot="first">
                  <option :value="null" disabled>-- Please select an option --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="label-control col-md-3">Schedule Purpose</label>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="details.planPurpose.$model" />
              <small>A label for the plan payments, e.g. "monthly utility".</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="label-control col-md-3">Auto Generate References</label>
            <div class="col-md-3">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="details.autoGenerateReferences.$model"
              ></p-check>
            </div>
          </div>
          <payer-ref-control
            rowClass="form-group row"
            v-model="details.payerReferencePattern.$model"
            @invalidReference="setRefInvalid"
          />
          <div class="form-group row">
            <label class="label-control col-md-3">Allow Multiple Schedules</label>
            <div class="col-md-3">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="details.allowMultiplePlans.$model"
              ></p-check>
            </div>
          </div>
          <div class="form-group row">
            <label class="label-control col-md-3">Dormancy Period (in Months)</label>
            <div class="col-md-3">
              <input
                type="number"
                class="form-control"
                v-model.trim="details.dormancyPeriodMonths.$model"
              />
              <validation-messages v-model="details.dormancyPeriodMonths" name="dormancy period"></validation-messages>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="idb-block" v-if="showAdvanced">
      <div class="idb-block-title" v-b-toggle.suppressionCollapse>
        Suppression
        <i class="fa fa-chevron-down"></i>
        <i class="fa fa-chevron-up"></i>
      </div>
      <b-collapse id="suppressionCollapse">
        <div class="idb-block-content">
          <div class="form-group row">
            <label class="label-control col-md-3">Enable Schedule Suppression</label>
            <div class="col-md-6">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="details.suppressionEnabled.$model"
              ></p-check>
            </div>
          </div>

          <div v-if="details.suppressionEnabled.$model">
            <div v-for="(suppressionWindow, index) in suppressionWindows" :key="index">
              <div class="form-group row">
                <label class="label-control col-md-3">Suppression Start Day</label>
                <div class="col-md-3">
                  <input
                    type="number"
                    class="form-control"
                    v-model.trim="details.suppressionDayStart.$model"
                  />
                  <validation-messages
                    v-model="details.suppressionDayStart"
                    name="suppression start day"
                  ></validation-messages>
                </div>
              </div>

              <div class="form-group row">
                <label class="label-control col-md-3">Suppression Start Month</label>
                <div class="col-md-3">
                  <input
                    type="number"
                    class="form-control"
                    v-model.trim="details.suppressionMonthStart.$model"
                  />
                  <validation-messages
                    v-model="details.suppressionMonthStart"
                    name="suppression start month"
                  ></validation-messages>
                </div>
              </div>

              <div class="form-group row">
                <label class="label-control col-md-3">Suppression End Day</label>
                <div class="col-md-3">
                  <input
                    type="number"
                    class="form-control"
                    v-model.trim="details.suppressionDayEnd.$model"
                  />
                  <validation-messages
                    v-model="details.suppressionDayEnd"
                    name="suppression end day"
                  ></validation-messages>
                </div>
              </div>

              <div class="form-group row">
                <label class="label-control col-md-3">Suppression End Month</label>
                <div class="col-md-3">
                  <input
                    type="number"
                    class="form-control"
                    v-model.trim="details.suppressionMonthEnd.$model"
                  />
                  <validation-messages
                    v-model="details.suppressionMonthEnd"
                    name="suppression end month"
                  ></validation-messages>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="idb-block" v-if="showAdvanced && hasRefunds && this.vueAppVersion >= 110">
      <div class="idb-block-title" v-b-toggle.refundCollapse>
        Refunds
        <i class="fa fa-chevron-down"></i>
        <i class="fa fa-chevron-up"></i>
      </div>
      <b-collapse id="refundCollapse">
        <div class="idb-block-content">
          <!-- Default Contra Narrative -->
          <div class="form-group row">
            <label class="col-form-label col-md-3">Refund Window Start</label>
            <div class="col-md-3">
              <input
                type="number"
                class="form-control"
                :min="0"
                :max="12"
                v-model.number="details.refundWindowStart.$model"
              />
              <small>Number of months in the past.</small>
            </div>
          </div>
          <!-- Default Contra Narrative -->
          <div class="form-group row">
            <label class="col-form-label col-md-3">Refund Window End</label>
            <div class="col-md-3">
              <input
                type="number"
                class="form-control"
                :min="0"
                :max="12"
                v-model.number="details.refundWindowEnd.$model"
              />
              <small>Number of months in the past.</small>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="idb-block" v-if="showAdvanced">
      <div
        class="idb-block-title"
        v-b-toggle.advancedCollapse
        :class="{invalid: details.advanced.$error}"
      >
        Advanced
        <i class="fa fa-chevron-down"></i>
        <i class="fa fa-chevron-up"></i>
      </div>
      <b-collapse id="advancedCollapse">
        <div class="idb-block-content">
          <div class="form-group row">
            <label class="label-control col-md-3">Update Scheduled Payments On Error</label>
            <div class="col-md-6">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="details.updateSchedPaymentOnError.$model"
              ></p-check>
            </div>
          </div>
          <div class="form-group row" v-if="showForJftp">
            <label class="label-control col-md-3">Group Workflow Type</label>
            <div class="col-md-3">
              <b-form-select
                :options="groupWorkflowTypes"
                v-model="details.groupWorkflowType.$model"
              />
            </div>
          </div>

          <div class="form-group row" v-if="corvidUser && hideInVersion">
            <label class="label-control col-md-3">Api Active</label>
            <div class="col-md-3">
              <p-check class="p-switch p-fill" color="primary" v-model="details.apiActive.$model"></p-check>
            </div>
          </div>

          <div class="form-group row" v-if="details.apiActive.$model">
            <label for="description" class="label-control col-md-3">Group API Key</label>
            <div class="col-md-3">
              <div class="input-group">
                <input type="text" readonly class="form-control" v-model="apiKey" />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary ml-3"
                    type="button"
                    @click="copyKey"
                  >Copy Key</button>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row" v-if="false">
            <label class="label-control col-md-3">Use Default Variable Amount File Mapping</label>
            <div class="col-md-3">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="details.defaultVariableAmountMapping.$model"
              ></p-check>
            </div>
          </div>

          <div class="form-group row" v-if="false">
            <label class="label-control col-md-3">Variable Amount File Mapping</label>
            <div class="col-md-3">
              <b-form-select
                :options="variableAmountMappings"
                v-model="details.variableAmountMappingId.$model"
                value-field="_id"
                text-field="title"
                :disabled="details.defaultVariableAmountMapping.$model"
              >
                <template slot="first">
                  <option :value="null" disabled>-- Please select an option --</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="label-control col-md-3">Suppress 0C Messages</label>
            <div class="col-md-3">
              <p-check class="p-switch p-fill" color="primary" v-model="details.suppress0C.$model"></p-check>
            </div>
          </div>

          <div class="form-group row" v-if="corvidUser && false">
            <label class="col-form-label col-md-3">Customer Threshold</label>
            <div class="col-md-3">
              <input
                type="number"
                class="form-control"
                :disabled="!corvidUser"
                v-model="details.payerThreshold.$model"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="label-control col-md-3">Auto Cancel Submission on Withdrawal Report</label>
            <div class="col-md-6">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="details.autoCancelFromWithdrawal.$model"
              ></p-check>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <b-button
      variant="outline-secondary"
      @click="showAdvancedAction"
      type="button"
    >{{ showAdvanced ? 'Hide' : 'Show' }} Advanced Options</b-button>
  </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import PayerRefControl from '@/Components/Collections/Payer/PayerReference'
import bankAccountView from '@/Components/Platform/Group/EditGroupTypeDetails/EditingComponents/BankAccountView'
import roles from '@/Assets/Constants/roles'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  props: ['details', 'disabled', 'status'],
  components: {
    PayerRefControl,
    bankAccountView
  },
  data () {
    return {
      showForJftp: false,
      submissionWindowTypes: [{ value: 1, text: 'Single Day' }, { value: 0, text: 'Multi Day' }],
      groupWorkflowTypes: [{ value: 0, text: 'Fully Integrated' }, { value: 1, text: 'Not Integrated' }],
      letterFileExportTypes: [{ value: 0, text: 'Single' }, { value: 1, text: 'Merged' }],
      defaultPayerMessageTypes: [{ value: 0, text: 'Email' }, { value: 1, text: 'Letter' }, { value: 2, text: 'SMS' }],
      initialMessageFallbackTypes: [{ value: 0, text: 'Email' }, { value: 1, text: 'Letter' }],
      variableAmountMappings: [],
      defaultPlanTemplates: [],
      emailAddresses: [],
      suppressionWindows: [
        {
          id: '',
          suppressionMonthStart: this.details.suppressionMonthStart,
          suppressionDayStart: this.details.suppressionDayStart,
          suppressionMonthEnd: this.details.suppressionMonthEnd,
          suppressionDayEnd: this.details.suppressionDayEnd
        }
      ],
      newEmail: null,
      hsmCertificates: [],
      importSchemas: [
        { value: 'None', text: 'None' },
        { value: 'Legacy', text: 'Legacy' },
        { value: 'Standard18', text: 'Standard 18' },
        { value: 'Mapping', text: 'Mapping' }
      ],
      mappings: [],
      showAdvanced: false,
      bacsSubmissionTypes: [
        { value: 0, text: 'Direct' },
        { value: 1, text: 'Bureau' },
        { value: 2, text: 'Indirect' }
      ],
      componentKey: 0
    }
  },
  async mounted () {
    this.$store.dispatch('getMessageChannels')
    if (this.details.payerReferencePattern.$model === null) {
      this.details.payerReferencePattern.$model = '[Firstname]{3}|[Surname]{3}|[0-9A-Z]{2}|[Sequence]{2}'
    }
  },
  async created () {
    if (!this.hasHsm) {
      this.details.tokenType.$model = 'SmartCard'
    }
    try {
      this.$Progress.start()
      var results = await Promise.allSettled([
        axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence', { showerror: true, errormessage: 'Failed to get licence' }),
        // axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mappingTitles/bacs`, { showerror: true, errormessage: 'Failed to get bacs mappings' }),
        axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate`, { params: { paygateid: this.$store.state.common.paygateId }, showerror: true, errormessage: 'Failed to get customer certificates' }),
        axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}emails?includeUnverified=false`, { showerror: true, errormessage: 'Failed to get emails' }),
        // axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mappingtitles`, { showerror: true, errormessage: 'Failed to get mappings' }),
        axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/${this.$route.params.groupId === undefined ? '00000000-0000-0000-0000-000000000000' : this.$route.params.groupId}`, { showerror: true, errormessage: 'Failed to get plan templates' })
      ])

      try {
        if (results[0].status === 'fulfilled') {
          // payer threshold Lincene
          if (this.details.payerThreshold.$model === '' || this.details.payerThreshold.$model === undefined) {
            this.details.payerThreshold.$model = results[0].value.data.collections.payerThreshold
          }
        }
      } catch { }

      // try {
      //   if (results[1].status === 'fulfilled') {
      //     // Bacs Mappings
      //     this.mappings = results[1].value.data.mappings.map(mapping => {
      //       return { value: mapping._id, text: mapping.title }
      //     })
      //   }
      // } catch { }
      try {
        if (results[1].status === 'fulfilled') {
          // Customer Certificates
          this.hsmCertificates = results[1].value.data.map(certificate => {
            var validTo = moment(certificate.validTo)

            var valid = true
            if (moment().isAfter(validTo)) {
              valid = false
            }

            return { value: certificate.certificateId, text: certificate.friendlyName, valid }
          })
          if (this.status === 'Create') {
            this.hsmCertificates = this.hsmCertificates.filter(c => c.valid)
          }
        }
      } catch { }
      try {
        if (results[2].status === 'fulfilled') {
          // Emails
          results[2].value.data.data.unshift({ emailAddress: 'System Default', id: null })
          const emailData = results[2].value.data.data.map(resData => ({ emailAddress: resData.emailAddress, id: resData.id }))
          this.emailAddresses = emailData
        }
      } catch { }
      // try {
      //   if (results[3].status === 'fulfilled') {
      //     // Mappings
      //     this.variableAmountMappings = results[4].value.data.mappings
      //   }
      // } catch { }
      try {
        if (results[3].status === 'fulfilled') {
          // Plan templates
          this.defaultPlanTemplates = results[3].value.data.planTemplates
        }
      } catch { }
    } catch {
      this.$Progress.fail()
    } finally {
      this.$Progress.finish()
    }
  },
  methods: {
    // ensures that the ddms config group has the correct
    suppressionChanged () {
      this.details.suppressionDayStart = this.suppressionWindows[0].suppressionDayStart
      this.details.suppressionDayEnd = this.suppressionWindows[0].suppressionDayEnd
      this.details.suppressionMonthStart = this.suppressionWindows[0].suppressionMonthStart
      this.details.suppressionMonthEnd = this.suppressionWindows[0].suppressionMonthEnd
    },
    addEmail () {
      this.details.orphanReportingEmails.$model.push({ emailAddress: this.newEmail, heirarchyIndex: this.details.orphanReportingEmails.$model.length, groupId: this.$route.params.groupId, orphanedReportingEmailId: '00000000-0000-0000-0000-000000000000' })
    },
    delItem (element) {
      var index = this.details.orphanReportingEmails.$model.indexOf(element)
      this.details.orphanReportingEmails.$model.splice(index, 1)
      this.reindex()
    },
    moveUp (movedItem) {
      this.move(this.details.orphanReportingEmails.$model, movedItem, -1)
    },
    moveDown (movedItem) {
      this.move(this.details.orphanReportingEmails.$model, movedItem, 1)
    },
    move (array, element, delta) {
      var index = array.indexOf(element)
      var newIndex = index + delta
      if (newIndex < 0 || newIndex === array.length) return // Already at the top or bottom.
      var indexes = [index, newIndex].sort() // Sort the indixes
      array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]) // Replace from lowest index, two elements, reverting the order
      this.reindex()
    },
    reindex () {
      for (var i = 0; i < this.details.orphanReportingEmails.$model.length; i++) {
        this.details.orphanReportingEmails.$model[i].heirarchyIndex = i
      }
    },
    onDragEnd (data) {
      console.log(this.details.orphanReportingEmails.$model)
      this.reindex()
      console.log(this.details.orphanReportingEmails.$model)
    },
    copyKey () {
      navigator.clipboard.writeText(this.apiKey)
      this.$toastr.s('The API has been copied', 'Key Copied')
    },
    inputServiceUserName: _.debounce(function (value) {
      this.details.serviceUserName.$model = value
    }, 400),
    showAdvancedAction () {
      this.showAdvanced = !this.showAdvanced

      this.$nextTick(() => {
        this.details.payerReferencePattern.$reset()
      })
    },
    setRefInvalid: _.debounce(function (value) {
      this.details.referenceInvalid = value
      if (this.details.referenceInvalid.$model) {
        console.log(this.details.referenceInvalid.$model)
        this.details.referenceInvalid.$model = value
      }
      this.$emit('setInvalidRef', value)
      // this.$emit('triggerTouch')
    }, 400)
  },
  computed: {
    ...mapGetters(['selectedCustomer', 'vueAppVersion']),
    monthSelect () {
      var months = []
      for (var i = 1; i <= 12; i++) {
        months.push({ text: moment(i, 'MM').format('MMMM'), value: i })
      }
      return months
    },
    showRefBox () {
      return this.details.autoGenerateReferences.$model
    },
    apiKey () {
      return btoa(this.$route.params.groupId)
    },
    hasHsm () {
      return this.$store.getters.hasLicence('LicenceHSM')
    },
    hasBureau () {
      return this.$store.getters.hasLicence('LicenceBureau')
    },
    needsBureauSun () {
      return this.details.submissionType.$model === 1
    },
    isIndirect () {
      return this.details.submissionType.$model === 2
    },
    hasRefunds () {
      return this.$store.getters.hasLicence('LicenceCollectionsRefunds')
    },
    selectedHSMCert () {
      var cert = this.hsmCertificates.find(c => c.value === this.details.certificateId.$model)

      if (cert == null) {
        return { valid: true }
      }

      return cert
    },
    corvidUser () {
      return this.$store.getters.isInRole(roles.LZAdmin)
    },
    hideInVersion () {
      return parseInt(process.env.VUE_APP_VERSION) >= 110
    },
    allowedMessageChannels () {
      const availMessageChannels = this.$store.getters.messageChannels
      const allowed = []
      if (parseInt(process.env.VUE_APP_VERSION) < 110) {
        const smsIndex = availMessageChannels.indexOf(2)
        if (smsIndex !== -1) {
          availMessageChannels.splice(smsIndex, 1)
        }
      }
      for (var i = 0; i < this.defaultPayerMessageTypes.length; i++) {
        if (availMessageChannels.includes(this.defaultPayerMessageTypes[i].value)) {
          allowed.push(this.defaultPayerMessageTypes[i])
        }
      }
      return allowed
    },
    allowedFallbackMessageChannels () {
      const availMessageChannels = this.$store.getters.messageChannels
      const allowed = []
      for (var i = 0; i < this.initialMessageFallbackTypes.length; i++) {
        if (availMessageChannels.includes(this.initialMessageFallbackTypes[i].value)) {
          allowed.push(this.initialMessageFallbackTypes[i])
        }
      }
      return allowed
    },
    lettersAllowed () {
      return false
      // const availMessageChannels = this.$store.getters.messageChannels
      // return availMessageChannels.includes(1)
    }
  },
  watch: {
    selectedCustomer () {
      if (!this.suppressChange) {
        this.$store.dispatch('getMessageChannels')
        this.$router.push({ path: '/admin/groups/list' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.fa-chevron-up,
.fa-chevron-down {
  float: right;
}

.dark-mode {
  .idb-block-title:hover {
    background-color: #353535;
  }
}
.idb-block-title:hover {
  background-color: #eeeeee;
}
.collapsed > .fa-chevron-up,
:not(.collapsed) > .fa-chevron-down {
  display: none;
}
.fa-info-circle {
  margin-left: 5px;
}
.side-icon {
  display: flex;
  i {
    height: 15px;
  }
}
.currentDefault {
  color: #004085;
  background-color: #b8daff;
}
</style>
