<template>
  <div>
    <b-modal
      id="invoiceSubmit"
      ref="invoiceSubmit"
      variant="primary"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
      :title="'Submit ' + label"
      ok-variant="success"
      cancel-variant="danger"
      cancel-title="Cancel"
      :ok-title="'Submit ' + label"
    >
      <div class="form-group" :class="{ invalid: $v.invoiceDate.$error }">
        <label class="required">{{label}} date</label>
        <vuejsDatepicker
          name="invoiceDate"
          id="invoiceDate"
          format="dd/MM/yyyy"
          input-class="form-control bg-white"
          :bootstrap-styling="true"
          minimumView="day"
          maximumView="year"
          initial-view="day"
          :use-utc="true"
          :disabled-dates="{
                    to: yesterday
                    }"
          :calendar-button="true"
          calendar-button-icon="fa fa-calendar"
          v-model="$v.invoiceDate.$model"
        ></vuejsDatepicker>
        <validation-messages v-model="$v.invoiceDate" name="invoice date"></validation-messages>
      </div>
      <div class="form-group">
        <label>Comment</label>
        <textarea class="form-control" rows="2" v-model.trim="$v.invoiceComment.$model"></textarea>
      </div>

      <template slot="modal-footer">
        <button
          type="button"
          :disabled="isLoading"
          class="btn btn-secondary"
          @click="hideModal"
        >Cancel</button>
        <button
          type="button"
          :disabled="isLoading"
          class="btn btn-success"
          @click="checkValidation() && submit()"
        >Submit {{label}}</button>
      </template>
    </b-modal>
  </div>
</template>
<script>
// Third Party
import moment from 'moment'

// Validators
import { required } from 'vuelidate/lib/validators'

// Mixins
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  name: 'invoiceSubmitModal',
  props: {
    creditNote: { type: Boolean, default: false }
  },
  mixins: [loading],
  computed: {
    label () {
      return this.creditNote ? 'Credit Note' : 'Invoice'
    },
    yesterday () {
      return moment().subtract(1, 'day').toDate()
    }
  },
  data () {
    return {
      invoiceDate: null,
      invoiceComment: null
    }
  },
  methods: {
    submit () {
      this.$emit('submit', this.invoiceDate, this.invoiceComment)
    },
    hideModal () {
      this.$refs.invoiceSubmit.hide()
    }
  },
  validations () {
    return {
      invoiceDate: { required },
      invoiceComment: {}
    }
  }
}
</script>
