<template>
  <div :class="!templateView ? 'mb-4 pb-4' : ''">
    <b-row v-if="!templateView && !createMode" class="pb-2 mb-2">
      <b-col cols="6">
          <router-link
            v-if="plan.paymentPlanId !== '00000000-0000-0000-0000-000000000000' && !isIncomplete"
            :to="{ name: 'PayerSchedule', params: { id: ukPayerId, planId: plan.paymentPlanId } }"
          >
            <!-- eslint-disable-line -->
            <b-button variant="outline-secondary">
              <i class="fa fa-calendar rpad"></i>View Schedule
            </b-button>
          </router-link>
      </b-col>
    </b-row>
    <b-form-row>
      <b-form-group
        :label-cols="labelCols"
        :class="columnClass"
        label-class="required"
        horizontal
        label="Template Name"
      >
        <b-form-input
          id="planTemplateNameInput"
          class="form-control"
          v-model="plan.planTemplateName"
          @input="dirtyTouch($v.plan.planTemplateName)"
          :disabled="!editable || !templateView || !canEdit"
          :formatter="formatPlanName"
        />
        <b-col v-if="$v.plan.planTemplateName.$dirty">
          <label
            id="planTemplateNameRequiredLabel"
            class="text-danger small"
            v-if="!$v.plan.planTemplateName.required"
          >Template Name required</label>
          <label
            id="planTemplateNameExistsLabel"
            class="text-danger small"
            v-if="!$v.plan.planTemplateName.nameNotInUse"
          >Template Name already exists</label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="!templateView">
      <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Schedule Reference">
        <b-form-input :disabled="true"
          id="planReferenceInput"
          class="form-control"
          v-model="plan.reference"
          @change="checkReference($v.plan.reference)"

        /><!-- :disabled="!isNew || !canCreateOwnReference" -->
        <b-col v-if="existingReference">
          <label id="payerReferenceExistsLabel" class="text-warning small">
            A customer already exists with this reference for this SUN.
          </label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="!templateView">
      <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Status">
        <b-form-select
          id="planStatusSelect"
          v-model.number="plan.planStatus"
          :options="statae"
          text-field="name"
          @change="dirtyTouch($v.plan.planStatus)"
          value-field="value"
          :disabled="!editable || planClosed || createMode || !canEdit"
        />
        <small v-if="!planClosed && plan.planStatus === 0 && plan.anyPending" class="text-danger">There is a recently created collection for this schedule that has already been processed.</small>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="!templateView && (plan.planStatus === 2 || plan.planStatus === 0)">
      <b-form-group :label-cols="labelCols" :class="columnClass" label-class="required" horizontal :label="plan.planStatus === 2 ?  'Suspension Reason' : 'Cancellation Reason'">
        <b-form-input
          v-model="plan.suspensionReason"
          type="text"
          class="form-control"
          @change="dirtyTouch($v.plan.suspensionReason)"
          :disabled="!editable || planClosed || createMode || !canEdit"
        />
        <!-- Validation -->
        <b-col v-if="$v.plan.suspensionReason.$dirty">
          <label
            id="suspensionReasonPositiveLabel"
            class="text-danger small"
            v-if="!$v.plan.suspensionReason.required && (plan.planStatus === 2)"
          >Please supply a suspension reason.</label>
          <label
            id="suspensionReasonPositiveLabel"
            class="text-danger small"
            v-if="!$v.plan.suspensionReason.required && plan.planStatus === 0"
          >Please supply a cancellation reason.</label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-form-row  v-if="!templateView && (plan.agreedDate || !isNew)">
      <b-form-group
        :label-cols="labelCols"
        :class="columnClass"
        horizontal
        label="Agreement Date"
      >
        <div class="right-inner-addon">
          <datepicker
            v-model="plan.agreedDate"
            :format="formatDateTime"
            :disabled="!isNew || !canEdit"
            input-class="form-control datepicker"
          />
          <i class="fa fa-calendar form-control-feedback"></i>
        </div>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="!templateView && plan.lodgedDate && plan.lodgedDate !== null">
      <b-form-group
        :label-cols="labelCols"
        :class="columnClass"
        horizontal
        label="Lodged Date"
      >
        <div class="right-inner-addon">
          <datepicker
            v-model="plan.lodgedDate"
            :format="formatDate"
            :disabled="true"
            input-class="form-control datepicker"
          />
          <i class="fa fa-calendar form-control-feedback"></i>
        </div>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="!templateView && plan.terminationDate && plan.terminationDate !== null">
      <b-form-group
        :label-cols="labelCols"
        :class="columnClass"
        horizontal
        label="Termination Date"
      >
        <div class="right-inner-addon">
          <datepicker
            v-model="plan.terminationDate"
            :format="formatDateTime"
            :disabled="true"
            input-class="form-control datepicker"
          />
          <i class="fa fa-calendar form-control-feedback"></i>
        </div>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="hasBureau && !sharedTemplate && isBureauOrIndirect">
      <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Bank Account">
        <b-form-select
          id="bankAccountSelect"
          v-model="plan.customerBankAccountId"
          :options="bankAccounts"
          text-field="text"
          value-field="value"
          :disabled="!editable || !isNew"
        />
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="hasBureau && !sharedTemplate && isBureauOrIndirect">
      <b-form-group
        :label-cols="labelCols"
        :class="columnClass"
        horizontal
        label="Contra Narrative"
      >
        <b-form-input
          id="contraNarrativeInput"
          class="form-control"
          v-model="plan.contraNarrative"
          :disabled="!editable || !canEdit"
        />
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="hasBureau && !sharedTemplate && isBureauOrIndirect">
      <b-form-group
        :label-cols="labelCols"
        :class="columnClass"
        horizontal
        label-class="required"
        label="Indirect Service User Number"
      >
        <b-form-input
          id="planStatusSelect"
          v-model="plan.indirectSun"
          :disabled="!editable || !isNew || !canEdit"
        />
        <!-- Validation -->
        <b-col v-if="$v.plan.indirectSun.$dirty">
          <label
            id="indirectSunRequiredLabel"
            class="text-danger small"
            v-if="!$v.plan.indirectSun.required"
          >Indirect SUN required.</label>
          <label
            id="indirectSunInvalidLabel"
            class="text-danger small"
            v-if="!$v.plan.indirectSun.sunValidator"
          >Indirect SUN is in invalid format</label>
        </b-col>
        <small
          class="form-text text-muted"
        >The format for a Service User Number is 6 numbers or a B followed by 5 numbers.</small>
      </b-form-group>
    </b-form-row>
    <b-form-row>
      <b-form-group
        :label-cols="labelCols"
        :class="columnClass"
        horizontal
        label="Direct Debit Type"
      >
        <b-form-select
          id="recurrenceEndTypeSelect"
          v-model.number="plan.recurrenceEndType"
          :options="paymentType"
          text-field="name"
          value-field="ordinal"
          @change="dirtyTouch($v.plan.recurrenceEndType)"
          :disabled="!editable || !canEdit"
        />
      </b-form-group>
    </b-form-row>
    <section v-if="!isAdHoc">
      <b-form-row v-if="!isOneOff">
        <transition name="fade">
          <b-form-group
            v-if="plan.recurrenceEndType===2"
            :label-cols="labelCols"
            :class="columnClass"
            horizontal
            label="Number Of Collections"
        label-class="required"
          >
            <b-form-input
              id="totalPaymentsInput"
              class="col-sm-4"
              type="number"
              v-model.number="plan.totalPayments"
              placeholder="1"
              text-field="name"
              @change="dirtyTouch($v.plan.totalPayments)"
              value-field="value"
              :disabled="!editable || !canEdit"
              :min="1"
            />
            <b-col v-if="$v.plan.totalPayments.$dirty">
              <label
                id="totalPaymentsPositiveLabel"
                class="text-danger small"
                v-if="!$v.plan.totalPayments.required || !$v.plan.totalPayments.positive"
              >Value must be a positive number.</label>
            </b-col>
          </b-form-group>
        </transition>
      </b-form-row>
      <b-form-row v-if="!isOneOff">
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Frequency">
          <b-form-select
            id="friendlyRecurrenceTypeSelect"
            v-model.number="plan.friendlyRecurrenceType"
            :options="friendlyRecurrenceType"
            text-field="name"
            @change="friendlyRecurrenceTypeChanged"
            value-field="ordinal"
            :disabled="!editable || !isNew || !canEdit"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row  v-if="!isOneOff && false">
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Frequency">
          <b-form-select
            id="recurrenceTypeSelect"
            v-model.number="plan.recurrenceType"
            :options="recurrenceType"
            text-field="name"
            @change="recurrenceTypeChanged"
            value-field="ordinal"
            :disabled="!editable || !isNew || !canEdit"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="!isOneOff && plan.recurrenceType===1">
        <b-form-group
          :label-cols="labelCols"
          :class="columnClass"
          horizontal
          label="Day of the Month"
        >
          <b-form-input
            id="ordinalSelect"
            :number="true"
            type="number"
            v-model.number="plan.ordinal"
            :min="ordinalMin"
            :max="ordinalMax"
            @change="ordinalChanged"
            :disabled="!editable || !canEdit"
          />
          <b-col v-if="$v.plan.ordinal.$dirty">
            <label
              id="ordinalRequiredLabel"
              class="text-danger small"
              v-if="!$v.plan.ordinal.numeric"
            >Value must be a number </label>
            <label
              id="ordinalRangeLabel"
              class="text-danger small"
              v-if="!$v.plan.ordinal.withinRange"
            >Value must be between {{ordinalMin}} and {{ordinalMax}} </label>
          </b-col>
          <div class="col" v-if="showAdvice">
            <label class="small" :class="adivceLabelClass">
              {{adviceMessage}}
              <a
                href="#"
                @click.prevent="heatmapVisible = !heatmapVisible"
              >{{ heatmapVisible ? 'Show less info.' : 'Show more info.'}}</a>
            </label>
          </div>
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="!isOneOff && plan.recurrenceType===2">
        <b-form-group
          :label-cols="labelCols"
          :class="columnClass"
          horizontal
          label="Day of the Year"
        >
          <div class="right-inner-addon">
            <datepicker
              id="dayOfYearDayPicker"
              v-model="dayOfYear"
              :format="formatDate"
              @input="dirtyTouchYear()"
              :disabled="!editable || !canEdit"
              :disabledDates="yearDisabled"
              input-class="form-control datepicker"
            />
            <i class="fa fa-calendar form-control-feedback"></i>
          </div>
          <div class="col" v-if="showAdvice">
            <label class="small" :class="adivceLabelClass">
              {{adviceMessage}}
              <a
                href="#"
                @click.prevent="heatmapVisible = !heatmapVisible"
              >{{ heatmapVisible ? 'Show less info.' : 'Show more info.'}}</a>
            </label>
          </div>
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="!isOneOff">
        <b-form-group
          v-if="plan.recurrenceType===0"
          :label-cols="labelCols"
          :class="columnClass"
          horizontal
          label="Day of the Week"
        >
          <b-form-select
            id="weekdaySelect"
            v-model.number="plan.ordinal"
            :options="weekdays"
            text-field="name"
            @change="ordinalChangedWeekday"
            value-field="value"
            :disabled="(!editable || (plan.friendlyRecurrenceType < 2 && !recTypeChanged)) && !templateView || !canEdit"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="!isOneOff && !stepDisabled && false">
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Step">
          <b-form-input
            :number="true"
            id="stepSelect"
            v-model.number="plan.step"
            :min="1"
            :max="maxStep"
            :disabled="stepDisabled || !editable || !canEdit"
            @change="dirtyTouchStep($v.plan.step)"
            type="number"
          />
          <b-col v-if="$v.plan.step.$invalid">
            <label
              id="stepRequiredLabel"
              class="text-danger small mr-2"
              v-if="!$v.plan.step.required"
            >Step required</label>
            <label
              id="stepPositiveLabel"
              class="text-danger small mr-2"
              v-if="!$v.plan.step.positive"
            >Value must be positive</label>
            <label
              id="stepRangeLabel"
              class="text-danger small mr-2"
              v-if="!$v.plan.step.withinRange"
            >Value must be between 1 and {{maxStep}} </label>
            <label
              id="stepNumericLabel"
              class="text-danger small mr-2"
              v-if="!$v.plan.step.numeric"
            >Value must be a number</label>

          </b-col>
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="!templateView">
        <b-form-group
          :label-cols="labelCols"
          :class="columnClass"
          horizontal
          :label="isOneOff ? 'Collection Date' : 'First Collection Date'"
        >
          <div class="right-inner-addon">
            <datepicker
              id="firstPaymentDatePicker"
              v-model="plan.firstPaymentDate"
              :disabled="!editable || !isNew || templateView || !canEdit"
              :disabledDates="disabledDates"
              :format="formatDate"
              input-class="form-control datepicker"
              @input="dirtyTouchFpd($v.plan.firstPaymentDate)"
            />
            <i class="fa fa-calendar form-control-feedback"></i>
          </div>
          <small v-if="templateView">The first Collection date is set when the plan is added to a customer.</small>
          <b-col v-if="isNew && $v.plan.firstPaymentDate.$invalid && !templateView">
            <label
              id="firstPaymentGreaterLabel"
              class="text-danger small"
              v-if="!$v.plan.firstPaymentDate.newerThanNow"
            >The first Collection date must be greater than today</label>
          </b-col>
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="!templateView && plan.recurrenceEndType === 1">
        <b-form-group
          :label-cols="labelCols"
          :class="columnClass"
          horizontal
          label="Final Collection Date"
        label-class="required"
        >
          <div class="right-inner-addon">
            <datepicker
              id="endPaymentDatePicker"
              v-model="plan.untilDate"
              :disabledDates="finalPaymentsDisabledDates"
              :format="formatDate"
              @input="dirtyTouch($v.plan.untilDate)"
              input-class="form-control datepicker"
              :disabled="!editable || !canEdit"
              :key="minDatesKey"
            />
            <i class="fa fa-calendar form-control-feedback"></i>
          </div>
          <b-col v-if="!templateView && $v.plan.untilDate.$invalid">
            <label
              id="untilRequired"
              class="text-danger small"
              v-if="!$v.plan.untilDate.required"
            >A date must be greater than today. </label>
            <label
              id="untilIsDate"
              class="text-danger small"
              v-if="!$v.plan.untilDate.Date"
            >A date must be provided. </label>
            <label
              id="untilMin"
              class="text-danger small"
              v-if="!$v.plan.untilDate.minValue"
            >A date must be greater than the first Collection date, plus three days to accommodate for the BACS cycle. </label>
          </b-col>
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="!isOneOff && false">
        <b-form-group
          :label-cols="labelCols"
          :class="columnClass"
          horizontal
          label="Variable Amount"
        >
          <p-check
            id="variableCheck"
            class="p-switch p-fill"
            color="primary"
            v-model="plan.variable"
            :disabled="!editable || !canEdit"
          ></p-check>
        </b-form-group>
      </b-form-row>
      <transition name="fade">
        <b-form-row v-if="!isOneOff && !plan.variable">
          <b-form-group
            :label-cols="labelCols"
            :class="columnClass"
            horizontal
            label="First Collection Amount"
          >
            <currency-input
              id="firstAmountInput"
              class="form-control"
              required
              @input="dirtyTouchCurrency($v.plan.firstAmount)"
              v-model="firstAmount"
              :disabled="!editable || !canEdit"
              currency="GBP"
              locale="en"
            />
            <b-col v-if="$v.plan.firstAmount.$dirty">
              <label
                id="firstAmountNumberLabel"
                class="text-danger small"
                v-if="!$v.plan.firstAmount.decimal"
              >Value must be a number </label>
              <label
                id="firstAmountPositiveLabel"
                class="text-danger small"
                v-if="!$v.plan.firstAmount.zeroOrPositive"
              >Value must be positive </label>
              <label
                id="firstAmountMaxLabel"
                class="text-danger small"
                v-if="!$v.plan.firstAmount.maxValue"
              >Value must be less than {{formatCurrency(secureLimits.maxAmountPerCollection)}}</label>
            </b-col>
          </b-form-group>
        </b-form-row>
      </transition>
      <transition name="fade">
        <b-form-row v-if="!plan.variable">
          <b-form-group
            :label-cols="labelCols"
            :class="columnClass"
            horizontal
            :label="isOneOff ? 'Amount' : 'Regular Collection Amount'"
          >
            <currency-input
              id="regularAmountInput"
              class="form-control"
              :label-class="plan.variable ? '' : 'required'"
              @input="dirtyTouchCurrency($v.plan.regularAmount)"
              v-model="regularAmount"
              :disabled="!editable || !canEdit"
              currency="GBP"
              locale="en"
            />
            <b-col v-if="$v.plan.regularAmount.$dirty">
              <label
                id="regularAmountRequiredLabel"
                class="text-danger small"
                v-if="!$v.plan.regularAmount.required || !$v.plan.regularAmount.positive"
              >{{ isOneOff ? '' : 'Regular Collection ' }}Amount required. Value must be numeric and positive.</label>
            </b-col>
            <b-col v-if="$v.plan.regularAmount.$dirty">
              <label
                id="regularAmountNumberLabel"
                class="text-danger small"
                v-if="!$v.plan.regularAmount.decimal"
              >Value must be a number</label>
            </b-col>
            <b-col v-if="$v.plan.regularAmount.$dirty">
              <label
                id="regularAmountMaxNumberLabel"
                class="text-danger small"
                v-if="!$v.plan.regularAmount.maxValue"
              >Value must be less than {{formatCurrency(secureLimits.maxAmountPerCollection)}}</label>
            </b-col>
          </b-form-group>
        </b-form-row>
      </transition>
      <transition name="fade">
        <b-form-row v-if="!isOneOff && !plan.variable && plan.recurrenceEndType !== 3 && plan.recurrenceEndType !== 4">
          <b-form-group
            :label-cols="labelCols"
            :class="columnClass"
            horizontal
            label="Final Collection Amount"
          >
            <currency-input
              id="lastAmountInput"
              class="form-control"
              @input="dirtyTouchCurrency($v.plan.lastAmount)"
              v-model="lastAmount"
              :disabled="!editable || !canEdit"
              currency="GBP"
              locale="en"
            />
            <b-col v-if="$v.plan.lastAmount.$dirty">
              <label
                id="lastAmountPositiveLabel"
                class="text-danger small"
                v-if="!$v.plan.lastAmount.zeroOrPositive"
              >Value must be positive</label>
            </b-col>
            <b-col v-if="$v.plan.lastAmount.$dirty">
              <label
                id="lastAmountNumberLabel"
                class="text-danger small"
                v-if="!$v.plan.lastAmount.decimal"
              >Value must be a number</label>
            </b-col>
            <b-col v-if="$v.plan.lastAmount.$dirty">
              <label
                id="lastAmountNumberLabel"
                class="text-danger small"
                v-if="!$v.plan.lastAmount.maxValue"
              >Value must be less than {{formatCurrency(secureLimits.maxAmountPerCollection)}}</label>
            </b-col>
          </b-form-group>
        </b-form-row>
      </transition>
    </section>
      <b-form-row :class="plan.variable && !templateView ? 'mb-4 pb-4' : ''" v-if="canDoGiftAid">
        <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Gift Aid">
          <p-check
            id="giftAidCheck"
            class="p-switch p-fill"
            color="primary"
            v-model="plan.giftAid"
            :disabled="!editable || !canEdit"
          ></p-check>
        </b-form-group>
      </b-form-row>

      <b-form-row class="mt-2 mb-2" v-if="!isOneOff && !isAdHoc && !templateView && !plan.variable && !createMode">
        <strong>Planned Schedule Changes</strong>
      </b-form-row>
      <div v-if="!isOneOff && !isAdHoc && !templateView && !plan.variable && !createMode">
        <vue-good-table
          :columns="columns"
          :rows="currentFpc"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :rowStyleClass="rowStyler"
          :search-options="{
                    enabled: false
                    }"
          :pagination-options="{
                    enabled: false
                  }"
          @on-row-click="canEdit ? onRowClick : null"
          @on-cell-click="canEdit ? onCellClick : null"
          styleClass="vgt-table striped bordered"
          ref="futurePlanTable"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No Scheduled Changes Available</div>
          </div>
          <div slot="table-actions">
            <b-button
              @click.prevent="addPayment"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Add Planned Change'" :disabled="!canEdit && editRowIndex !== -1 && editSubIndex !== -1"
            >
              <i class="fa fa-plus pointer dimmedIcon"></i>
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
          <span v-if="props.row.subs && props.row.subs.length > 0">
            <div
              v-for="(sub,index) in props.row.subs"
              :key="index"
              @click="switchToSubEdit(props.index, index, props.column.label)"
              :class="index > 0 ? 'subRow' : ''"
            >
              <span v-if="props.column.label=='Trigger'">
                <span
                  v-if="!isEditState(props.index, index) && index === 0"
                >{{ sub.triggerType === 0 ? formatFpcDate(sub.startDate) : sub.paymentsMade }}</span>
                <span v-if="!isEditState(props.index, index) && index !== 0">&nbsp;</span>
                <div  v-if="sub.triggerType === 0 && isEditState(props.index, index)" class="right-inner-addon">
                  <datepicker  v-if="sub.triggerType === 0 && isEditState(props.index, index)" v-model="sub.startDate" :disabledDates="disabledDates" input-class="form-control datepicker" />
                  <i class="fa fa-calendar form-control-feedback"></i>
                </div>
                <input type="number" oninput="validity.valid||(value='1');" min="1" @keydown="($event.key === '-' || $event.key === '.') ? $event.preventDefault() : null"  v-if="sub.triggerType === 1 && isEditState(props.index, index)" v-model="sub.paymentsMade" />
              </span>
              <span v-if="props.column.field == 'changeAmount'">
                <span
                  v-if="!isEditState(props.index, index)"
                >{{props.row.subs.length > 1 ? (index + 1) + ': ' : ''}}{{formatValue(sub)}}</span>
                <two-decimal-places-input class="form-control"
                       v-if="isEditState(props.index, index) && sub.changeType === 2"
                       v-model.number="sub.changeAmount"
                                          :class="sub.changeAmount === 0 ? 'is-invalid' : ''"
                />
                <currency-input
                  id="firstAmountInput"
                  class="form-control"
                  required
                  v-model="sub.changeAmount"
                  v-if="isEditState(props.index, index) && sub.changeType !== 2"
                  currency="GBP"
                  locale="en"
                  :options="currencyOptions"
                  @keydown="$event.key === '-' && sub.changeType === 0 ? $event.preventDefault() : null"
                  :class="sub.changeAmount === 0 ? 'is-invalid' : ''"
                />
                <small v-if="sub.changeAmount === 0" class="invalid-feedback text-danger">
                  Please enter a{{ sub.changeType === 0 ? ' positive ' : ' '}}value.
                </small>
              </span>
              <span v-else-if="props.column.field == 'changeType'">
                <span v-if="!isEditState(props.index, index)">{{ formatChangeType(sub)}}</span>
                <b-form-select
                  v-if="isEditState(props.index, index)"
                  :options="changeTypes"
                  text-field="name"
                  value-field="value"
                  v-model="sub.changeType"
                ></b-form-select>
              </span>
              <span v-else-if="props.column.field == 'triggerType'">
                <span v-if="!isEditState(props.index, index)">{{ formatTriggerType(sub.triggerType)}}</span>
                <b-form-select
                  v-if="isEditState(props.index, index)"
                  :options="triggerTypes"
                  text-field="name"
                  value-field="value"
                  v-model="sub.triggerType"
                ></b-form-select>
              </span>
              <span v-else-if="props.column.label == ''">
                <input type="hidden" v-model="sub.orderIndex" />
                <span
                  v-if="props.row.subs.length > 1 && !isEditState(props.index, index) && !sub.applied && sub.planId === plan.paymentPlanId"
                  @click="moveUp(props.row.subs, index, props.index)"
                  :class="!canMove(props.row.subs, index, -1) ? 'disabled-order' : null"
                >
                  <i class="fa fa-arrow-up mr-2" title="Move Up"></i>
                </span>
                <span
                  v-if="props.row.subs.length > 1 && !isEditState(props.index, index) && !sub.applied && sub.planId === plan.paymentPlanId"
                  @click="moveDown(props.row.subs, index, props.index)"
                  :class="!canMove(props.row.subs, index, 1) ? 'disabled-order' : null"
                >
                  <i class="fa fa-arrow-down mr-2" title="Move Down"></i>
                </span>
                <i class="fa fa-lock ml-2" v-if="(sub.applied && index === 0) || sub.planId !== plan.paymentPlanId" title="Locked"></i>
                <span v-if="isEditState(props.index, index)">
                  <b-button variant="primary" @click="saveFpc(sub)" title="Confirm Changes" :disabled="sub.changeAmount === 0">
                    <i class="fa fa-check mr-1"></i> Confirm
                  </b-button>
                  <b-button
                            variant="warning"
                            @click="cancelFpc(sub)"
                            title="Cancel Changes"
                  >
                    <i class="fa fa-times mr-1"></i> Cancel
                  </b-button>
                  <b-button
                    variant="danger"
                    @click="deleteFpc(sub)"
                    title="Delete"
                    :disabled="props.row.applied"
                  >
                    <i class="fa fa-trash mr-1"></i> Delete
                  </b-button>
                </span>
              </span>
              <span v-else></span>
            </div>
          </span>
          </template>
        </vue-good-table>
      </div>
    <b-form-row class="mt-2 mb-2" v-if="!isOneOff && !isAdHoc && !templateView && !plan.variable && !createMode">
      <strong>Suppressions</strong>
    </b-form-row>
    <div v-if="!isOneOff && !isAdHoc && !templateView && !plan.variable && !createMode">
      <vue-good-table
        :columns="suppressionColumns"
        :rows="plan.suppressions"
        :lineNumbers="true"
        :totalRows="totalRecords"
        :rowStyleClass="rowStyler"
        :search-options="{
                  enabled: false
                  }"
        :pagination-options="{
                  enabled: false
                }"
        @on-cell-click="onCellClick"
        styleClass="vgt-table striped bordered"
        ref="futurePlanTable"
      >
        <template slot="loadingContent">
          <h1>Loading...</h1>
        </template>
        <div slot="emptystate">
          <div class="vgt-center-align vgt-text-disabled">No Suppressions available</div>
        </div>
      </vue-good-table>
    </div>
    <b-form-row v-if="templateView && hasOnboarding && !isShared">
      <b-form-group :label-cols="labelCols" :class="columnClass" horizontal label="Use Schedule specific Reference pattern">
        <p-check
          id="giftAidCheck"
          class="p-switch p-fill"
          color="primary"
          v-model="plan.overrideGroupReference"
          :disabled="!editable || !canEdit"
        ></p-check>
        <br/><small>This overrides the pattern specified at Group level.</small>
      </b-form-group>
    </b-form-row>
    <payer-ref-control v-if="templateView && hasOnboarding && plan.overrideGroupReference && !isShared" v-model="plan.payerReferencePattern" />
    <b-form-row v-if="templateView && hasOnboarding && !newTemplate && !isShared">
      <b-form-group
        :label-cols="labelCols"
        :class="columnClass"
        horizontal
        label="Generate API Key"
      >
        <b-input-group>
          <b-form-input
            id="apiKeyInput"
            type="text"
            class="form-control"
            v-model="plan.apiKey"
            :disabled="true"
            :key="apiIndex"
          />
          <template v-slot:append>
            <b-button variant="secondary" @click.prevent="generateApiKey">Get</b-button>
            <b-button variant="info" @click.prevent="copyApiKey">Copy</b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="templateView && hasOnboarding && !newTemplate && !isUntilDate && !isAdHoc && !isShared">
      <b-form-group
        :label-cols="labelCols"
        :class="columnClass"
        horizontal
        label="Generate Online Signup URL"
      >
        <b-input-group>
          <b-form-input
            id="onboardingUrlInput"
            type="text"
            class="form-control"
            v-model="plan.onboardingUrl"
            :disabled="true"
            :key="urlIndex"
          />
          <template v-slot:append>
            <b-button variant="secondary" @click.prevent="generateOnboardingUrl">Get</b-button>
            <b-button variant="info" @click.prevent="copyUrl">Copy</b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="!isOneOff && templateView && hasOnboarding && !isUntilDate && !isShared && false">
      <b-form-group
        :label-cols="labelCols"
        :class="columnClass"
        horizontal
        label="Allow Date Choice"
      >
        <p-check
            id="allowDateChoiceCheck"
            class="p-switch p-fill"
            color="primary"
            v-model="$v.plan.allowDateChoice.$model"
            :disabled="!editable"
          ></p-check>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="!isOneOff && templateView && allowDateChoice && hasOnboarding && !isUntilDate && false">
      <b-form-group
        :label-cols="labelCols"
        :class="columnClass"
        horizontal
        label="Date Range Allowed"
      >
        <input type="number" :min="0" v-model="$v.plan.dateRangeAllowed.$model" />
        <b-col v-if="$v.plan.dateRangeAllowed.$dirty">
          <label
            id="dateRangeAllowedPositiveLabel"
            class="text-danger small"
            v-if="!$v.plan.dateRangeAllowed.zeroOrPositive"
          >Value must be a positive number</label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-form-row v-if="!isOneOff && !isAdHoc && templateView && hasOnboarding && !isUntilDate && false">
      <b-form-group
        :label-cols="labelCols"
        :class="columnClass"
        horizontal
        label="Show Schedule on Online Signup Form?"
      >
        <p-check
          id="showScheduleCheck"
          class="p-switch p-fill"
          color="primary"
          v-model="$v.plan.showSchedule.$model"
          :disabled="!editable"
        ></p-check>
      </b-form-group>
    </b-form-row>
    <b-form-row
      v-if="!templateView && !createMode && plan.paymentPlanId !== '00000000-0000-0000-0000-000000000000'"
    >
      <b-col>
        <router-link
          :to="{ name: 'PayerSchedule', params: { id: ukPayerId, planId: plan.paymentPlanId } }"
        >
          <!-- eslint-disable-line -->
          <b-button variant="outline-secondary" class="mt-3">
            <i class="fa fa-calendar rpad"></i>View Schedule
          </b-button>
        </router-link>
        <div class="mt-3 pull-right" v-b-popover.hover.top="plan.planStatus !== 0 ? 'The Schedule must be closed prior to deletion.' : (deleteDisabled ? 'Customer requires at least one Schedule' : 'Deletes the Schedule from the system.')">
          <b-button
            v-if="showDeleteButton"
            @click="deletePlan"
            variant="danger"
            :disabled="plan.planStatus !== 0 || deleteDisabled"
          >
            <i class="fa fa-trash rpad"></i>Delete Schedule
          </b-button>
        </div>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import { decimal, required, numeric, helpers, sameAs } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker'
import PrettyCheck from 'pretty-checkbox-vue/check'
import Enum from '@/Enums/Collections'
import PayerRefControl from '@/Components/Collections/Payer/PayerReference'
import _ from 'lodash'
import axios from 'axios'
import rulesMixin from '@/Assets/Mixins/CollectionsRulesMixin'
import Moment from 'moment'
import {firstBy} from 'thenby'
import roles from '@/Assets/Constants/roles'
import TwoDecimalPlacesInput from '@/Assets/Components/TwoDecimalPlacesInput'
import Store from "@/Store";

const positive = (val) => {
  return parseFloat(val) > 0 && val.toString() !== 'e'
}
const lessThan28 = (val) => {
  return parseInt(val) <= 28
}
const zeroOrPositive = (val) => {
  return parseFloat(val) >= 0 && val.toString() !== 'e'
}
const newerThanNow = (val) => {
  return Date.parse(val) > new Date()
}
const newerThanFirst = (val) => {
  return Date.parse(val) > Date.parse(this.firstPaymentDate)
}
const sunValidator = helpers.regex('alpha', /^B\d{5}$|^\d{6}$/)
const nameNotInUse = function (val) {
  try {
    if (this.isLoading) {
      return true
    }
    return !(this.templatesFlat.filter(x => x.name.toUpperCase() === this.plan.planTemplateName.toUpperCase() && x.paymentPlanTemplateId !== this.plan.planTemplateId).length > 0)
  } catch (e) {
    return true
  }
}
export default {
  mixins: [rulesMixin],
  data () {
    return {
      currentFpc: [],
      firstPaymentDate: new Date(),
      futureChangeDate: new Date(),
      newAmount: 0,
      recurrenceType: Enum.Payer.RecurrenceType.enumValues,
      isValid: false,
      weekdays: [
        { name: 'Monday', value: 1 },
        { name: 'Tuesday', value: 2 },
        { name: 'Wednesday', value: 3 },
        { name: 'Thursday', value: 4 },
        { name: 'Friday', value: 5 }
      ],
      dayOfYear: new Date(),
      statae: [
        { name: 'Closed', value: 0 },
        // { name: 'Alert', value: 1 },
        { name: 'Suspended', value: 2 },
        { name: 'Operational', value: 3 }
      ],
      editRowIndex: -1,
      editSubIndex: -1,
      newRowIndex: -1,
      columns: [
        {
          label: 'Change Type',
          field: 'changeType',
          sortable: false
        },
        {
          label: 'Amount',
          field: 'changeAmount',
          sortable: false
        },
        {
          label: 'Trigger Type',
          field: 'triggerType',
          formatFn: (value) => {
            return value === 0 ? 'After Date' : 'After X Collections Made'
          },
          sortable: false
        },
        {
          label: 'Trigger',
          field: 'planId',
          sortable: false
        },
        {
          label: 'Applied',
          field: 'applied',
          sortable: false
        },
        {
          label: '',
          field: 'control',
          sortable: false
        }
      ],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: 'surname',
          type: 'ASC'
        },
        page: 1,
        perPage: 10,
        searchKey: ''
      },
      selectedRow: null,
      changeTypes: [
        { name: 'Replace Old Amount', value: 0 },
        { name: 'Numeric Change', value: 1 },
        { name: 'Percentage Change', value: 2 }
      ],
      triggerTypes: [{ name: 'After Date', value: 0 }, { name: 'After X Collections', value: 1 }],
      adviceMessage: '',
      showAdvice: false,
      showHeatMap: false,
      heatmapData: [],
      adivceLabelClass: '',
      heatmapVisible: false,
      existingReference: false,
      suspensionReasons: [
        { name: 'None', value: 0 },
        { name: 'Unknown', value: 1 },
        { name: 'Reason 1', value: 2 },
        { name: 'Reason 2', value: 3 },
        { name: 'Reason 3', value: 4 },
        { name: 'Reason 4', value: 5 },
        { name: 'Reason 5', value: 6 },
      ],
      currencyOptions: {
        currency: 'GBP',
        currencyDisplay: 'symbol',
        valueRange: {
          min: 0
        },
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        useGrouping: false,
        accountingSign: false
      },
      canDoGiftAid: false,
      recTypeChanged: false,
      apiIndex: 0,
      urlIndex: 0,
      untilDateKey: 0,
      minDatesKey: 0,
      suppressionColumns: [
        {
          label: 'Start Date',
          field: 'startDate',
          formatFn: this.formatDate
        },
        {
          label: 'End Date',
          field: 'endDate',
          formatFn: this.formatDate
        }
      ],
    }
  },
  props: {
    open: Boolean,
    plan: {},
    ukPayerId: String,
    templateView: Boolean,
    createMode: Boolean,
    planClosed: Boolean,
    columnClass: { type: String, default: 'col-sm-6' },
    labelCols: { type: Number, default: 4 },
    showDeleteButton: { type: Boolean, default: false },
    insightFeedback: { type: Boolean, default: false },
    limitFirstPaymentDate: { type: Boolean, default: false },
    sharedTemplate: { type: Boolean, default: false },
    canCreateOwnReference: { type: Boolean, default: false },
    hasDdi: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    deleteDisabled: { type: Boolean, default: false },
    isIncomplete: { type: Boolean, default: false }
  },
  async mounted () {
    // if (this.$store.getters.collectionsAllGroups === undefined || this.$store.getters.collectionsAllGroups === null) {
    //   await this.$store.dispatch('populateAllCollectionsGroups')
    // }
    if (this.$store.getters.planTemplateTree === undefined || this.$store.getters.planTemplateTree.length === 0) {
      await this.$store.dispatch('updateAvailablePlanTemplates', this.plan !== undefined ? this.plan.groupId : '00000000-0000-0000-0000-000000000000')
    }
    await this.initialize()
    // if (this.plan.customerBankAccountId === undefined || this.plan.customerBankAccountId === null || this.plan.customerBankAccountId === '00000000-0000-0000-0000-000000000000') {
    //   this.plan.customerBankAccountId = this.defaultBankAccount
    // }
    // if (this.$store.getters.availableBankAccounts === undefined || this.$store.getters.availableBankAccounts === null || this.$store.getters.availableBankAccounts.length === 0) {
    //   await this.$store.dispatch('getAvailableBankAccounts')
    // }
    this.buildValidationMessage()
    this.$v.$touch()
    this.currentFpc = _.clone(this.plan.futurePlanChanges)
    this.sortData()
  },
  methods: {
    copyUrl (){
      navigator.clipboard.writeText(this.plan.onboardingUrl)
      this.$toastr.s('The URL has been copied', 'URL copied to the clipboard')
    },
    copyApiKey (){
      navigator.clipboard.writeText(this.plan.apiKey)
      this.$toastr.s('The API Key has been copied', 'API Key copied to the clipboard')
    },
    checkReference: _.debounce(async function (referenceValidator) {
      if (!referenceValidator.$invalid) {
        const reference = referenceValidator.$model
        let url = `${process.env.VUE_APP_DDMS_API_URL}payerreference/${this.groupId}/dupecheck?reference=${reference}`
        const response = await axios.get(url, { showload: true })
        this.existingReference = response.data
        let isValid = !this.$v.plan.$invalid
        this.$store.dispatch('setCurrentPayerPlan', isValid && !response.data)
      }
    }, 300),
    deletePlannedChange (index) {
      this.plan.futurePlanChanges.splice(index, 1)
      this.$forceUpdate()
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    formatDateTime (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var hour = date.getHours()
      var minute = date.getMinutes()
      var second = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year} ${this.pad(hour, 2)}:${this.pad(minute, 2)}:${this.pad(second, 2)}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatCurrency (value) {
      var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      })
      return formatter.format(value)
    },
    dirtyTouchStep (callingModel) {
      callingModel.$touch()
      let isValid = !this.$v.plan.$invalid
      if (!isValid && !this.isNew) {
        this.$v.plan.step.$model = 1
      }
      this.$store.dispatch('setCurrentPayerPlan', isValid)
    },
    formatPlanName (e) {
      return String(e).substring(0, 40)
    },
    dirtyTouch (callingModel) {
      callingModel.$touch()
      let isValid = !this.$v.plan.$invalid
      this.$store.dispatch('setCurrentPayerPlan', isValid)
    },
    dirtyTouchFpd (callingModel) {
      if (this.plan.friendlyRecurrenceType < 2) {
        this.plan.ordinal  = callingModel.$model.getDay()
      }
      this.untilDateKey++
      this.dirtyTouch(callingModel)
    },
    dirtyTouchCurrency (callingModel) {
      callingModel.$touch()
      let isValid = !this.$v.plan.$invalid
      this.$store.dispatch('setCurrentPayerPlan', isValid)
    },
    async dirtyTouchYear () {
      var start = new Date(this.dayOfYear.getFullYear(), 0, 0)
      this.yearlyDay = this.dayOfYear.getDate()
      this.yearlyMonth = this.dayOfYear.getMonth() + 1
      var diff = this.dayOfYear - start
      var oneDay = 1000 * 60 * 60 * 24
      var day = Math.ceil(diff / oneDay)
      if (this.leapYear(this.dayOfYear.getFullYear()) && this.dayOfYear.getMonth() >= 3){
        day = day - 1
      }
      this.plan.ordinal = day
      this.$v.plan.ordinal.$touch()
      let isValid = !this.$v.plan.$invalid
      this.$store.dispatch('setCurrentPayerPlan', isValid)
    },
    navigateToSchedule () {
      this.$router.push({ name: 'PayerSchedule', params: { id: this.ukPayerId, planId: this.plan.paymentPlanId } })
    },
    reloadItems: async function () {

    },
    leapYear(year)
    {
      return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onPageChange (params) {
    },
    onSortChange (params) {
    },
    onColumnFilter (params) {
      console.log('onColumnFilter not yet implemented')
    },
    onPerPageChange (params) {
    },
    onRowClick (params) {
    },
    onCellClick (params) {
      if (!this.fpcRowLocked(params.row)) {
        if (params.column.field !== 'control') {
          this.oldVersion = _.clone(params.row)
          this.editRowIndex = params.rowIndex
        }
      }
    },
    onSearch (params) {
    },
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    fpcRowLocked (row) {
      return (row.planId === '00000000-0000-0000-0000-000000000000') || row.applied
    },
    formatChangeType (sub) {
      let value = sub.changeType
      if (!isNaN(value)) {
        return this.changeTypes[value].name
      } else {
        let retval = ''
        switch(value) {
          case 0:
            retval = 'NewValue'
            break
          case 1:
            retval = 'NumericModifier'
            break
          case 2:
            retval = 'PercentageModifier'
            break
        }
        return retval
      }
    },
    formatValue (row) {
      var val = ''
      switch (row.changeType) {
        case 0:
        case 'NewValue':
          val = this.formatCurrency(row.changeAmount)
          break
        case 1:
        case 'NumericModifier':
          val = row.changeAmount > 0 ? '+' + this.formatCurrency(row.changeAmount) : '-' + this.formatCurrency(row.changeAmount * -1)
          break
        case 2:
        case 'PercentageModifier':
          val = (parseFloat(row.changeAmount) > 0 ? '+' : '') + parseFloat(row.changeAmount).toFixed(2) + '%'
          break
      }
      return val
    },
    deletePlan () {
      this.$emit('planDeleted')
    },
    buildValidationMessage () {
      var fields = []
      if (this.$store.state.payer.currentPayer.paymentPlans.length === 0) {
        fields.push('No Schedule Specified')
      }
      if (this.$v.reference) {
        fields.push('Schedule Reference')
      }
      if (this.$v.planTemplateName) {
        fields.push('Schedule Template Name')
      }
      if (this.$v.recurrenceEndType) {
        fields.push('Recurrence End Type')
      }
      if (this.$v.recurrenceType) {
        fields.push('Recurrence Type')
      }
      if (this.$v.firstPaymentDate) {
        fields.push('First Collection Date')
      }
      if (this.$v.firstAmount) {
        fields.push('First Amount')
      }
      if (this.$v.regularAmount) {
        fields.push('Regular Amount')
      }
      if (this.$v.lastAmount) {
        fields.push('Last Amount')
      }
      if (this.$v.untilDate) {
        fields.push('Until Date')
      }
      if (this.$v.ordinal) {
        fields.push('Ordinal')
      }
      if (this.$v.step) {
        fields.push('Step')
      }
      if (this.$v.totalPayments) {
        fields.push('Total Collections')
      }
      var message = fields.length > 0 ? `Invalid Plan Details: ${fields.join(', ')}` : ''
      this.$store.commit('setPlanValidationMessage', message)
    },
    async generateApiKey () {
      let url = process.env.VUE_APP_DDMS_API_URL + 'plantemplate/apikey/' + this.plan.planTemplateId
      var result = await axios.post(url, {})
      this.plan.apiKey = result.data
      navigator.clipboard.writeText(this.plan.apiKey)
      this.apiIndex ++
      this.$toastr.s('The API Key has been created', 'API Key generated and copied to the clipboard.')
    },
    async generateOnboardingUrl () {
      let url = process.env.VUE_APP_DDMS_API_URL + 'plantemplate/onboardingurl/' + this.plan.planTemplateId
      var result = await axios.post(url, {}, { params: { paygateid: this.paygateId } })
      this.plan.onboardingUrl = result.data.onboardingUrl
      navigator.clipboard.writeText(this.plan.onboardingUrl)
      if (result.data.approvalDocumentNeeded) {
        this.$toastr.s('The Onboarding URL has been created, it needs to be approved by the sponsoring bank before it is used.', 'Onboarding URL generated and copied to the clipboard.')
      } else {
        this.$toastr.s('The Onboarding URL has been created', 'Onboarding URL generated and copied to the clipboard.')
      }
      this.urlIndex ++
    },
    reset () {
      this.$v.$reset()
    },
    async ordinalChanged () {
      this.dirtyTouch(this.$v.plan.ordinal)
    },
    async ordinalChangedWeekday () {
      this.dirtyTouch(this.$v.plan.ordinal)
    },
    ordinalSuffix (i) {
      var j = i % 10
      var k = i % 100
      if (j === 1 && k !== 11) {
        return i + 'st'
      }
      if (j === 2 && k !== 12) {
        return i + 'nd'
      }
      if (j === 3 && k !== 13) {
        return i + 'rd'
      }
      return i + 'th'
    },
    async recurrenceTypeChanged () {
      switch (this.plan.recurrenceType) {
        case 0:
          await this.ordinalChangedWeekday()
          break
        case 1:
          await this.ordinalChanged()
          break
        case 2:
          await this.dirtyTouchYear()
          break
      }
      this.plan.step = 1
      this.dirtyTouch(this.$v.plan.recurrenceType)
    },
    async friendlyRecurrenceTypeChanged () {
      this.recTypeChanged = true
      switch (this.plan.friendlyRecurrenceType) {
        case 0:
          this.plan.recurrenceType = 0
          this.plan.step = 1
          if (this.plan.ordinal > 5 && !this.isNew) {
            this.plan.ordinal = 1
          }
          await this.ordinalChangedWeekday()
          break
        case 1:
          this.plan.recurrenceType = 0
          this.plan.step = 2
          if (this.plan.ordinal > 5 && !this.isNew) {
            this.plan.ordinal = 1
          }
          await this.ordinalChangedWeekday()
          break
        case 2:
          this.plan.recurrenceType = 0
          this.plan.step = 4
          if (this.plan.ordinal > 5 && !this.isNew) {
            this.plan.ordinal = 1
          }
          await this.ordinalChangedWeekday()
          break
        case 3:
          this.plan.recurrenceType = 1
          this.plan.step = 1
          await this.ordinalChanged()
          break
        case 4:
          this.plan.recurrenceType = 1
          this.plan.step = 2
          await this.ordinalChanged()
          break
        case 5:
          this.plan.recurrenceType = 1
          this.plan.step = 3
          await this.ordinalChanged()
          break
        case 6:
          this.plan.recurrenceType = 1
          this.plan.step = 6
          await this.ordinalChanged()
          break
        case 7:
          this.plan.recurrenceType = 2
          this.plan.step = 1
          await this.dirtyTouchYear()
          break
      }
      this.dirtyTouch(this.$v.plan.recurrenceType)
      this.dirtyTouch(this.$v.plan.friendlyRecurrenceType)
    },
    formatFpcDate (value) {
      return Moment(value).format('DD/MM/YYYY')
    },
    async initialize () {
      var date = new Date()
      date.setHours(0, 0, 0, 0)
      if (this.plan.ordinal) {
        date.setMonth(0)
        date.setDate(0)
        var timeOfFirst = date.getTime() // this is the time in milliseconds of 1/1/YYYY
        var dayMilli = 1000 * 60 * 60 * 24
        var dayCount = this.plan.ordinal
        if (this.leapYear(date.getFullYear() + 1) && date.getMonth() >= 3) {
          dayCount = dayCount+1
        }
        var dayNumMilli = dayCount * dayMilli
        date.setTime(timeOfFirst + dayNumMilli)
      }
      this.dayOfYear = date
      if (this.dayOfYear < new Date()) {
        console.log(this.yearlyMonth)
        console.log(this.yearlyDay)
        var year = this.dayOfYear.getFullYear();
        var month = this.yearlyMonth - 1; // remember that JAVASCRIPT USES A 0 INDEX FOR DATES. HAHAHAHA.
        var day = this.yearlyDay;
        /*if (this.leapYear(this.dayOfYear.getFullYear() + 1) && this.dayOfYear.getMonth() >= 3){
          day = day + 1
        }*/
        this.dayOfYear = new Date(year + 1, month, day);
      }
      let canDoResp = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/candogiftaid`)
      if (canDoResp) {
        this.canDoGiftAid = canDoResp.data
      }
    },
    //FPC STUFF
    moveUp (itemArray, itemIndex, rowIndex) {
      var toIndex = itemIndex - 1 >= 0 ? itemIndex - 1 : 0
      var element = itemArray[itemIndex]
      if (this.canMove(itemArray, itemIndex, -1)) {
        itemArray.splice(itemIndex, 1)
        itemArray.splice(toIndex, 0, element)
      }
      this.currentFpc[rowIndex].subs = itemArray
    },
    moveDown (itemArray, itemIndex, rowIndex) {
      var toIndex = itemIndex + 1 < itemArray.length - 1 ? itemIndex + 1 : itemArray.length
      var element = itemArray[itemIndex]
      if (this.canMove(itemArray, itemIndex, 1)) {
        itemArray.splice(itemIndex, 1)
        itemArray.splice(toIndex, 0, element)
      }
      this.currentFpc[rowIndex].subs = itemArray
    },
    canMove (itemArray, index, delta) {
      var canGo = false
      var checkItem = itemArray[index + delta]
      if (checkItem && !itemArray[index].applied) {
        canGo = true
      }
      return canGo
    },
    switchToSubEdit (rowIndex, subIndex, label) {
      let nothingSelected = this.editSubIndex === -1 && this.editRowIndex === -1
      let notControlColumn = label !== ''
      let notApplied = !this.currentFpc[rowIndex].subs[subIndex].applied
      let isNotTemplate = this.currentFpc[rowIndex].subs[subIndex].planId === this.plan.paymentPlanId
      if (nothingSelected && notControlColumn && notApplied && isNotTemplate) {
        this.editRowIndex = rowIndex
        this.editSubIndex = subIndex
        this.oldVersion = _.clone(this.currentFpc[rowIndex].subs[subIndex])
      }
    },
    isEditState (rowIndex, subIndex) {
      return this.editRowIndex === rowIndex && this.editSubIndex === subIndex
    },
    rowStyler (row) {
      return this.currentFpc.filter(x => new Moment(x.startDate).format('YYYYMMDD') === new Moment(row.startDate).format('YYYYMMDD')).length > 1 ? 'grouped ' : ''
    },
    addPayment () {
      var settings = this.currentGroupSettings ? this.currentGroupSettings :  this.$store.getters.collectionsAllGroups[this.plan.groupId]
      let messageType = settings.defaultPayerMessageType
      if(this.$store.state.payer.currentPayer && !this.isNew) {
        messageType = this.$store.state.payer.currentPayer.payerMessageType
      }
      //let minDate = messageType === 1 ? _.clone(this.$store.getters.collectionsMinDateLetter) : _.clone(this.$store.getters.collectionsMinDate)
      let minDate = messageType === 1 ? _.clone(this.$store.getters.collectionsMinDateLetter) : _.clone(this.$store.getters.collectionsMinDate)
      var now = new Date()
      now.setHours(24, 0, 0, 0)
      var item = {
        ukddmsFuturePlanChangeId: '00000000-0000-0000-0000-000000000000',
        planTemplateId: '00000000-0000-0000-0000-000000000000',
        changeAmount: 0,
        startDate: minDate,
        applied: false,
        appliedDate: '',
        planId: this.plan.paymentPlanId,
        changeType: 'NewValue',
        triggerType: 0,
        orderIndex: this.plan.futurePlanChanges.length - 1
      }
      var newPlannedChange = { startDate: item.startDate, applied: item.applied, appliedDate: item.appliedDate, subs: [item] }
      this.currentFpc.push(newPlannedChange)
      this.editRowIndex = this.currentFpc.length - 1
      this.editSubIndex = 0
      this.newRowIndex = this.editRowIndex
    },
    saveFpc (e) {
      e.startDate = Moment(e.startDate).toDate().setHours(1, 0, 0, 0)
      e.startDate = Moment(e.startDate).toDate().toISOString()
      this.currentFpc[this.editRowIndex].subs[this.editSubIndex] = e

      this.currentFpc = this.currentFpc.sort(firstBy((a, b) => { return a.triggerType === 0 ? new Moment(a.startDate).format('YYYYMMDD') - new Moment(b.startDate).format('YYYYMMDD') : a.paymentsMade - b.paymentsMade  }).thenBy('orderIndex'))
      var flatFpc = this.flattenFpc()
      this.plan.futurePlanChanges = _.clone(flatFpc)
      this.currentFpc = flatFpc
      this.sortData()
      this.editRowIndex = -1
      this.editSubIndex = -1
      // this.getPlans()
    },
    flattenFpc () {
      var flatFpc = []
      for (var i = 0; i < this.currentFpc.length; i++) {
        var fpc = this.currentFpc[i]
        for (var s = 0; s < fpc.subs.length; s++) {
          var sub = fpc.subs[s]
          sub.orderIndex = s
          flatFpc.push(sub)
        }
      }
      return flatFpc
    },
    cancelFpc (e) {
      if (this.oldVersion) {
        e = this.oldVersion
        this.currentFpc[this.editRowIndex].subs[this.editSubIndex] = _.clone(this.oldVersion)
      } else {
        this.currentFpc[this.editRowIndex].subs.splice(this.editSubIndex, 1)
      }
      var flatFpc = this.flattenFpc()
      this.plan.futurePlanChanges = _.clone(flatFpc)
      this.currentFpc = flatFpc
      this.sortData()
      this.editRowIndex = -1
      this.editSubIndex = -1
    },
    deleteFpc (e) {
      this.currentFpc[this.editRowIndex].subs.splice(this.editSubIndex, 1)
      if (this.currentFpc[this.editRowIndex].subs.length === 0) {
        this.currentFpc.splice(this.editRowIndex, 1)
      }
      var flatFpc = this.flattenFpc()
      this.plan.futurePlanChanges = _.clone(flatFpc)
      this.currentFpc = flatFpc
      this.sortData()
      this.editRowIndex = -1
      this.editSubIndex = -1
      this.oldVersion = null
    },
    sortData () {
      this.currentFpc.sort(firstBy((a, b) => { return a.triggerType === 0 ? new Moment(a.startDate).format('YYYYMMDD') - new Moment(b.startDate).format('YYYYMMDD') : a.paymentsMade - b.paymentsMade  }).thenBy('orderIndex'))
      var grouped = _.groupBy(this.currentFpc, (x) => { return x.triggerType === 0 ? new Moment(x.startDate).format('YYYYMMDD') : x.paymentsMade })
      var groupedFpc = []
      for (var prop in grouped) {
        var item = grouped[prop]
        groupedFpc.push({ startDate: item[0].startDate, applied: item[0].applied, appliedDate: item[0].appliedDate, subs: item })
      }
      this.currentFpc = groupedFpc
    },
    formatTriggerType (value) {
      return value === 0 ? 'After Date' : 'After X Collections Made'
    }
  },
  components: {
    Datepicker,
    pCheck: PrettyCheck,
    PayerRefControl,
    TwoDecimalPlacesInput
  },
  computed: {
    newTemplate () {
      return this.plan.planTemplateId === '00000000-0000-0000-0000-000000000000'
    },
    canEdit() {
      return this.$store.getters.isInRoles([roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard ])
    },
    firstAmount: {
      get () {
        return this.plan.firstAmount ? this.plan.firstAmount : 0
      },
      set (value) {
        this.plan.firstAmount = value
      }
    },
    regularAmount: {
      get () {
        return this.plan.regularAmount ? this.plan.regularAmount : 0
      },
      set (value) {
        this.plan.regularAmount = value
      }
    },
    lastAmount: {
      get () {
        return this.plan.lastAmount ? this.plan.lastAmount : 0
      },
      set (value) {
        this.plan.lastAmount = value
      }
    },
    suspensionReason: {
      get () {
        return this.plan.suspensionReason
      },
      set (value) {
        this.plan.suspensionReason = value
      }
    },
    allowDateChoice: {
      get () {
        return this.plan.allowDateChoice
      },
      set (value) {
        this.plan.allowDateChoice = value
      }
    },
    overrideGroupReference: {
      get () {
        return this.plan.overrideGroupReference
      },
      set (value) {
        this.plan.overrideGroupReference = value
      }
    },
    dateRangeAllowed: {
      get () {
        return this.plan.dateRangeAllowed
      },
      set (value) {
        this.plan.dateRangeAllowed = value
      }
    },
    yearlyDay: {
      get () {
        return this.plan.yearlyDay
      },
      set (value) {
        this.plan.yearlyDay = value
      }
    },
    yearlyMonth: {
      get () {
        return this.plan.yearlyMonth
      },
      set (value) {
        this.plan.yearlyMonth = value
      }
    },
    isSuspended () {
      return this.plan.planStatus === 2
    },
    isClosed () {
      return this.plan.planStatus === 0
    },
    groupId () {
      return this.$store.state.payer.currentPayer.groupId
    },
    editable () {
      return this.$store.state.payer.currentPayer.editable
    },
    ordinalOpts () {
      var range
      switch (this.plan.recurrenceType) {
        case Enum.Payer.RecurrenceType.Weekly.ordinal:
          range = 5
          break
        case Enum.Payer.RecurrenceType.Yearly.ordinal:
          range = 365
          break
        case Enum.Payer.RecurrenceType.Monthly.ordinal:
        default:
          range = 28
          break
      }
      var retVal = []
      for (var i = 1; i <= range; i++) {
        retVal.push(i)
      }
      return retVal
    },
    ordinalMin () {
      return this.ordinalOpts[0]
    },
    ordinalMax () {
      return this.ordinalOpts[this.ordinalOpts.length-1]
    },
    stepDisabled () {
      return this.plan.recurrenceType === Enum.Payer.RecurrenceType.Yearly.ordinal
    },
    paymentType () {
      let output = []
      for (let i = 0; i < Enum.Payer.RecurrenceEndType.enumValues.length; i++) {
        let words = Enum.Payer.RecurrenceEndType.enumValues[i].name.match(/[A-Z][a-z]+/g)
        output.push({ name: words.join(' ').replace('Payment','Collection'), ordinal: Enum.Payer.RecurrenceEndType.enumValues[i].ordinal })
      }
      return output
    },
    friendlyRecurrenceType () {
      let output = []
      for (let i = 0; i < Enum.Payer.FriendlyRecurrenceType.enumValues.length; i++) {
        let words = Enum.Payer.FriendlyRecurrenceType.enumValues[i].name.match(/[A-Z][a-z]+/g)
        output.push({ name: words.join(' '), ordinal: Enum.Payer.FriendlyRecurrenceType.enumValues[i].ordinal })
      }
      return output
    },
    yearDisabled () {
      let now = new Date()
      let minDate = new Date()
      let maxDate = new Date(now.setMonth(now.getMonth() + 12))
      return {
        to: minDate,
        from: maxDate
      }
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    disabledDates () {
      let maxDate = new Date(8640000000000000)
      if (this.limitFirstPaymentDate) {
        var now = new Date()
        maxDate = new Date(now.setMonth(now.getMonth() + 6))
      }
      var settings = this.currentGroupSettings ? this.currentGroupSettings :  this.$store.getters.collectionsAllGroups[this.plan.groupId]
      let messageType = settings.defaultPayerMessageType
      if(this.$store.state.payer.currentPayer && !this.isNew) {
        messageType = this.$store.state.payer.currentPayer.payerMessageType
      }
      let minDate = messageType === 1 ? _.clone(this.$store.getters.collectionsMinDateLetter) : _.clone(this.$store.getters.collectionsMinDate)
      minDate = new Date(minDate.setMinutes(minDate.getMinutes()-1))
      if (minDate < this.activationDate){
        minDate = this.activationDate
      }
      return {
        days: [0, 6],
        //daysOfMonth: [29, 30, 31],
        dates: (this.plan.friendlyRecurrenceType <2 ? [] : this.$store.getters.nonProcessingDates),
        to: minDate,
        from: maxDate
      }
    },
    finalPaymentsDisabledDates () {
      let refValue = this.untilDateKey
      let maxDate = new Date(8640000000000000)
      let minDate =  new Date(this.minUntilDate)
      minDate.setDate(minDate.getDate()+1)
      this.minDatesKey++
      return {
        days: [0, 6],
        //daysOfMonth: [29, 30, 31],
        dates: this.$store.getters.nonProcessingDates,
        to: minDate,
        from: maxDate
      }
    },
    // minDate: function () {
    //   var settings = this.currentGroupSettings ? this.currentGroupSettings : this.$store.getters.collectionsAllGroups[this.plan.groupId]
    //   let messageType = settings.defaultPayerMessageType
    //   if(this.$store.state.payer.currentPayer && !this.isNew) {
    //     messageType = this.$store.state.payer.currentPayer.payerMessageType
    //   }
    //   return messageType === 1 ? (this.hasDdi ? this.$store.getters.collectionsMinDateLetter : this.$store.getters.collectionsMinDateLetterNoDdi) : (this.hasDdi ? this.$store.getters.collectionsMinDate : this.$store.getters.collectionsMinDateNoDdi)
    // },
    isAdHoc () {
      return this.plan.recurrenceEndType === Enum.Payer.RecurrenceEndType.AdHoc.ordinal
    },
    isOneOff () {
      return this.plan.recurrenceEndType === Enum.Payer.RecurrenceEndType.OneOff.ordinal
    },
    isUntilDate () {
      return this.plan.recurrenceEndType === Enum.Payer.RecurrenceEndType.UntilDate.ordinal
    },
    advancedNoticeWithMedia () {
      if (!this.isShared && this.$store.getters.collectionsAllGroups !== null) {
        var settings = this.currentGroupSettings ? this.currentGroupSettings :  this.$store.getters.collectionsAllGroups[this.plan.groupId]
        return this.getAdvancedNoticeWithMedia(settings.advanceNoticeInDays, settings.defaultPayerMessageType)
      }
    },
    isNew () {
      return this.plan.paymentPlanId === '00000000-0000-0000-0000-000000000000'
    },
    steps () {
      var retVal = []
      switch (this.plan.recurrenceType) {
        case 0: // weekly
          retVal = []
          for (var i = 1; i <= 56; i++) {
            retVal.push(i)
          }
          break
        case 1: // monthly
          retVal = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
          break
        case 2: // annually
          retVal = [1]
          break
      }
      return retVal
    },
    maxStep () {
      let retVal = 1
      switch (this.plan.recurrenceType) {
        case 0: // weekly
          retVal = 56
          break
        case 1: // monthly
          retVal = 13
          break
      }
      return retVal
    },
    numPaymentsRequired () {
      return this.plan.recurrenceEndType === 2
    },
    untilDateRequired () {
      return this.plan.recurrenceEndType === 1
    },
    templates () {
      return this.$store.getters.planTemplateTree !== undefined && this.$store.getters.planTemplateTree.length !== 0 ? this.$store.getters.planTemplateTree[this.plan.groupId] : []
    },
    templatesFlat () {
      return this.$store.getters.planTemplatesFlat !== undefined && this.$store.getters.planTemplatesFlat.length !== 0 ? this.$store.getters.planTemplatesFlat : []
    },
    planNameExists () {
      //return this.templateView ? false : this.templates.filter(x => x.name === this.plan.planTemplateName && x.paymentPlanTemplateId !== this.plan.planTemplateId).length > 0
      return this.templateView ? this.templatesFlat.filter(x => x.name.toUpperCase() === this.plan.planTemplateName.toUpperCase() && x.paymentPlanTemplateId !== this.plan.planTemplateId).length > 0 : false
    },
    hasBureau () {
      return this.$store.getters.hasLicence('LicenceBureau')
    },
    bankAccounts () {
      return this.$store.getters.availableBankAccounts
    },
    isShared () {
      return this.plan.groupId === undefined || this.plan.groupId === '00000000-0000-0000-0000-000000000000'
    },
    defaultBankAccount () {
      var account = null
      if (this.isShared) {
        account = this.bankAccounts[0].id
      } else {
        var settings = this.currentGroupSettings && this.currentGroupSettings !== null  ? this.currentGroupSettings : this.$store.getters.collectionsAllGroups[this.plan.groupId]
        account = settings.bacnkAccountId
      }
      return account
    },
    isBureauOrIndirect () {
      var retVal = false
      if (this.$store.getters.collectionsAllGroups !== undefined && this.$store.getters.collectionsAllGroups !== null) {
        var settings = this.$store.getters.collectionsAllGroups[this.plan.groupId]
        retVal = this.isShared ? false : (settings.submissionType !== 0)
      } else if (this.currentGroupSettings && this.currentGroupSettings !== null) {
        retVal = this.isShared ? false : this.currentGroupSettings.submissionType !== 0
      }
      return retVal
    },
    currentGroupSettings () {
      return this.$store.getters.collectionsGroupConfig
    },
    fpc () {
      return this.plan.futurePlanChanges
    },
    minUntilDate () {
      let refValue = this.untilDateKey
      let minDate = new Date(this.plan.firstPaymentDate)
      for (let i = 0; i < 3; i++) {
        minDate = minDate.setDate(minDate.getDate()+1)
        minDate = this.nudgeDateIfWeekend(new Date(minDate))
      }
      return minDate
    },
    activationDate () {
      return (this.$store.state.payer.currentPayer !== undefined && this.$store.state.payer.currentPayer !== null) ?  this.$store.state.payer.currentPayer.activationDate : new Date()
    },
    hasOnboarding () {
      return this.$store.getters.hasLicence('LicenceCollectionsOnBoarding') && this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations]) && parseInt(process.env.VUE_APP_VERSION) >= 102 && this.$store.getters.selectedCustomer !== process.env.VUE_APP_LANDZ_ID
    }

  },
  watch: {
    $v: {
      handler (val) {
        if (this.$v.$anyDirty) {
          this.$emit('dirtied')
        }
        this.buildValidationMessage()
        this.$emit('isinvalid', { valid: !this.$v.$anyError, plan: this.plan })
      },
      deep: true
    },
    fpc () {
      this.currentFpc = _.clone(this.plan.futurePlanChanges)
      this.sortData()
    }
  },
  validations () {
    if (this.plan.variable) {
      return {
        plan: {
          reference: this.templateView ? { } : { required },
          planTemplateName: { required, nameNotInUse },
          recurrenceEndType: { zeroOrPositive },
          recurrenceType: { numeric },
          friendlyRecurrenceType: { numeric },
          firstPaymentDate: this.templateView ? {} : { Date,  minValue(val) { return this.isNew ? new Date(val) > new Date() : true }  },
          firstAmount: { decimal, zeroOrPositive, maxValue(val) { return val <= this.secureLimits.maxAmountPerCollection || this.secureLimits.maxAmountPerCollection === 0 } },
          regularAmount: { decimal, zeroOrPositive, maxValue(val) { return val <= this.secureLimits.maxAmountPerCollection || this.secureLimits.maxAmountPerCollection === 0 } },
          lastAmount: { decimal, zeroOrPositive, maxValue(val) { return val <= this.secureLimits.maxAmountPerCollection || this.secureLimits.maxAmountPerCollection === 0 } },
          ordinal: { numeric, positive, withinRange (val) { return parseInt(val) >= this.ordinalMin && parseInt(val) <= this.ordinalMax  } },
          step: this.isOneOff ? {} : { numeric, required, withinRange (val) { return parseInt(val) >= 1 && parseInt(val) <= this.maxStep  } },
          giftAid: {},
          variable: {},
          planStatus: { required },
          totalPayments: this.numPaymentsRequired ? { required, numeric, positive } : {},
          indirectSun: {},
          untilDate: this.untilDateRequired && !this.templateView ? { required, Date, minValue(val) { return new Date(val) > this.minUntilDate } } : {},
          suspensionReason:  (this.isSuspended || this.isClosed) && !this.templateView  ? { required } : { },
          agreedDate : {},
          lodgedDate: {},
          terminationDate: {},
          allowDateChoice: {},
          dateRangeAllowed: this.templateView && this.allowDateChoice ? { numeric, zeroOrPositive } : {},
          overrideGroupReference: {},
          showSchedule: {},
          onboardingUrl: {},
          apiKey: {}
        },
        existingReference: this.templateView ? { } : { sameAs: sameAs(() => false) }
      }
    } else {
      return {
        plan: {
          reference: this.templateView ? { } : { required },
          planTemplateName: { required, nameNotInUse },
          recurrenceEndType: { zeroOrPositive },
          recurrenceType: { numeric },
          friendlyRecurrenceType: { numeric },
          firstPaymentDate: this.templateView || this.isAdHoc ? {} : { Date, minValue(val) { return this.isNew ? new Date(val) > new Date() : true } },
          firstAmount: this.isAdHoc ? {} : { decimal, zeroOrPositive, maxValue(val) { return val <= this.secureLimits.maxAmountPerCollection || this.secureLimits.maxAmountPerCollection === 0 } },
          regularAmount: this.isAdHoc ? {} : { required, decimal, positive, maxValue(val) { return val <= this.secureLimits.maxAmountPerCollection || this.secureLimits.maxAmountPerCollection === 0 } },
          lastAmount: this.isAdHoc ? {} : { decimal, zeroOrPositive, maxValue(val) { return val <= this.secureLimits.maxAmountPerCollection || this.secureLimits.maxAmountPerCollection === 0 } },
          ordinal: this.isAdHoc ? {} : { numeric, required, withinRange (val) { return parseInt(val) >= this.ordinalMin && parseInt(val) <= this.ordinalMax  } },
          step: this.isAdHoc || this.isOneOff ? {} : { numeric, required, positive, withinRange (val) { return parseInt(val) >= 1 && parseInt(val) <= this.maxStep  } },
          giftAid: {},
          variable: {},
          planStatus: { required },
          totalPayments: this.numPaymentsRequired ? { required, numeric, positive } : {},
          indirectSun: {},
          untilDate: this.untilDateRequired && !this.templateView ? { required, Date, minValue(val) { return new Date(val) > this.minUntilDate } } : {},
          suspensionReason: (this.isSuspended || this.isClosed) && !this.templateView ? { required } : { },
          agreedDate : {},
          lodgedDate: {},
          terminationDate: {},
          allowDateChoice: {},
          dateRangeAllowed: this.templateView && this.allowDateChoice ? { numeric, zeroOrPositive } : {},
          overrideGroupReference: {},
          showSchedule: {},
          onboardingUrl: {},
          apiKey: {}
        },
        existingReference: this.templateView ? { } : { sameAs: sameAs(() => false) }
      }
    }
  }
}
</script>
<style>
#firstPaymentDatePicker,
#endPaymentDatePicker,
.datepicker,
.datepicker:disabled {
  background-color: #ffffff !important;
}
.dark-mode #firstPaymentDatePicker,
.dark-mode #endPaymentDatePicker,
.dark-mode .datepicker,
.dark-mode .datepicker:disabled {
  background-color: transparent !important;
}
.dark-mode .vdp-datepicker,
.dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important;
}
.dark-mode .vdp-datepicker__calendar * .next,
.dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important;
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
