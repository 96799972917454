<template>
  <form @submit.prevent="search()">
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Search
          <help-icon :docPath="'/common/search/'" />
        </h2>
      </div>

      <div class="idb-block-content">
        <div class="accordion" id="searchFilters">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#searchFilter"
                >Search Filters</button>
              </h5>
            </div>

            <div id="searchFilter" class="collapse show" data-parent="#searchFilters">
              <div class="card-body">
                <!-- Reference -->
                <div class="form-group">
                  <label class="col-form-label col-md-3 required">Search Term</label>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      focus
                      v-model.trim="searchTerm"
                      @input="delayedSearch()"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label col-md-3 required">Search Type</label>
                  <div class="col-md-6">
                    <b-form-select
                      v-model="searchFilter.typeFilter"
                      @change="search()"
                      :options="typeFilters"
                    >
                      <template slot="first">
                        <option value>All</option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vue-good-table
          :paginationOptions="{
        enabled: true,
        perPage: 10,
      }"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          @on-row-click="onRowClick"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'relevance'">
              <span>
                <div class="progress">
                  <div
                    class="progress-bar"
                    :class="{'bg-success':props.row.relevance >= 70,
                 'bg-info': props.row.relevance < 70 && props.row.relevance >= 50,
                 'bg-warning': props.row.relevance < 50 && props.row.relevance >= 20,
                'bg-danger': props.row.relevance < 20   }"
                    :style="{width:props.row.relevance + '%'}"
                  >{{Math.round(props.row.relevance)}}</div>
                </div>
              </span>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
          <div slot="emptystate">Nothing was found, please change the search criteria</div>
        </vue-good-table>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import { returnRouteObject } from '@/Assets/Components/Search/searchRouter'
import _ from 'lodash'

export default {
  computed: {
    searchTerm: {
      get () {
        return this.$store.state.search.searchTerm
      },
      set (value) {
        this.$store.commit('setSearchTerm', value)
      }
    }
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Type',
          field: 'typeName'
        },
        {
          label: 'Title',
          field: 'title'
        },
        {
          label: 'Description',
          field: 'description'
        },
        {
          label: 'Relevance',
          field: 'relevance'
        }
      ],
      loading: false,
      typeFilters: [],
      searchFilter: {
        typeFilter: ''
      }
    }
  },
  watch: {
    searchTerm () { this.delayedSearch() }
  },
  async created () {
    if (this.searchTerm !== '') {
      await this.search()
    }
    await this.loadFilters()
  },
  methods: {
    onRowClick ({ row }) {
      this.$router.push(returnRouteObject(row.typeName, row.data, this.$store))
    },
    async search () {
      try {
        this.loading = true
        if (this.searchTerm === '') {
          this.rows = []
          return
        }

        try {
          this.$gtag.event('search', {
            event_category: 'engagement',
            event_label: 'search_term',
            value: 1
          })
        } catch {
          // Do Nothing
        }

        var apiresult = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}search/Advanced`, this.searchFilter, {
          params: {
            searchTerm: this.searchTerm
          },
          showload: true,
          showerror: true,
          errormessage: 'Search failed'
        })
        let data = apiresult.data

        if (data.length > 0) {
          data = _.sortBy(data, function (hit) {
            return -hit.score
          })
          const highestScore = data[0].score
          data = _.each(data, function (d) {
            switch (d.typeName) {
              case 'User':
                d.title = d.data.name
                d.description = d.data.email
                break

              case 'BACS Group':
              case 'BACS Bureau Group':
              case 'Collections Group':
              case 'BACS Online Group':
              case 'FPS Online Group':
              case 'FPS Group':
                d.title = d.data.name
                d.description = d.data.description
                break
              case 'Bank Account':
                d.title = d.data.reference
                d.description = d.data.description
                break
              case 'Mappings':
              case 'Workflows':
                d.title = d.data.title
                break
              case 'Triggers':
                d.title = d.data.title
                d.description = d.data.triggerType
                break
              case 'Importers':
                d.title = d.data.title
                d.description = d.data.importerType
                break
              case 'Payments':
                d.title = d.data.userReference
                d.description = `${d.data.paymentEngineType} - ${d.data.thirdPartyAccountName} - ${d.data.thirdPartySortCode} - ${d.data.thirdPartyAccountNumber}`
                break
              case 'Customer':
                d.title = d.data.reference
                d.description = `${d.data.firstName} ${d.data.surname}`
                break
            }
            d.relevance = (d.score / highestScore) * 100
          })
          this.rows = data
        } else {
          this.rows = []
        }
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    delayedSearch:
      _.debounce(async function () {
        await this.search()
      }, 500),
    async loadFilters () {
      var filters = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}search/Advanced/Types`)
      this.typeFilters = filters.data
    }
  }
}

</script>
