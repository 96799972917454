<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          <help-icon docPath="/paygate-collections/hosted-forms/managestyles/" />Manage Stylesheet
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div class="idb-block-content">
        <b-row>
          <b-col
            v-if="currentFile.stylesheet !== undefined  && currentFile.stylesheet !== null && currentFile.stylesheet !== ''"
          >
            <p>
              <strong>Approved stylesheet:</strong>
              {{currentFile.stylesheet}}
            </p>
            <p>
              <strong>Last Updated:</strong>
              {{(currentFile !== null && currentFile.addedDate !== null ? formatDate(currentFile.addedDate) : '')}}
            </p>
          </b-col>
          <b-col
            v-if="currentFile.unapprovedStylesheet !== undefined && currentFile.unapprovedStylesheet !== null && currentFile.unapprovedStylesheet !== ''"
          >
            <p>
              <strong>Unapproved stylesheet:</strong>
              {{currentFile.unapprovedStylesheet}}
              <br />
              <small>A preview of this stylesheet can be seen in the Approval Document once it has rendered.</small>
            </p>
          </b-col>
        </b-row>
        <vue-dropzone
          id="drop1"
          ref="fileUploader"
          :url="dropzoneUrl"
          :options="dropzoneOptions"
          @vdropzone-success="afterSuccess"
          @vdropzone-file-added="fileAdded"
          @vdropzone-removed-file="fileRemoved"
          @vdropzone-sending="sendingEvent"
          :use-custom-slot="true"
          :accepted-file-types="dropzoneOptions.acceptedFiles"
          :max-file-size-in-m-b="200"
          :useCustomDropzoneOptions="true"
          :use-custom-drop-zone-options="true"
          :useCustomSlot="true"
          v-if="useDropzone"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">{{dropzoneOptions.title}}</h3>
            <div class="subtitle">{{dropzoneOptions.subtitle}}</div>
          </div>
        </vue-dropzone>
      </div>
      <div v-if="currentFile.addedDate !== null" class="idb-block-footer noprint-footer">
        <b-button variant="danger" @click="clearStylesheet">Remove Stylesheet</b-button>
      </div>
    </div>
    <div class="idb-block mt-2">
      <div class="idb-block-title">
        <h2>Manage Logo Image</h2>
      </div>
      <div class="idb-block-content">
        <b-row>
          <b-col
            v-if="currentImageFile.logoImage !== undefined && currentImageFile.logoImage !== null && currentImageFile.logoImage !== ''"
          >
            <p>
              <strong>Approved Image:</strong>
              {{currentImageFile.logoImage}}
              <br />
              <img
                v-if="currentFile.logoImage !== ''"
                :src="previewImageUrl"
                alt="Current Selected Logo Image"
              />
            </p>
            <p>
              <strong>Last Updated:</strong>
              {{(currentImageFile !== null && currentImageFile.addedDate !== null ? formatDate(currentImageFile.addedDate) : '' )}}
            </p>
          </b-col>
          <b-col
            v-if="currentImageFile.unapprovedLogoImage !== undefined && currentImageFile.unapprovedLogoImage !== null && currentImageFile.unapprovedLogoImage !== ''"
          >
            <div>
              <p>
                <strong>Unapproved Image:</strong>
                {{currentImageFile.unapprovedLogoImage}}
                <br />
                <img
                  v-if="currentFile.logoImage !== ''"
                  :src="unapprovedImageUrl"
                  alt="Current Selected Logo Image"
                />
              </p>
            </div>
          </b-col>
        </b-row>
        <vue-dropzone
          id="drop2"
          ref="imageFileUploader"
          :url="dropzoneImageUrl"
          :options="dropzoneImageOptions"
          @vdropzone-success="afterImageSuccess"
          @vdropzone-file-added="fileImageAdded"
          @vdropzone-removed-file="fileImageRemoved"
          @vdropzone-sending="sendingImageEvent"
          :use-custom-slot="true"
          :accepted-file-types="dropzoneImageOptions.acceptedFiles"
          :max-file-size-in-m-b="200"
          :useCustomDropzoneOptions="true"
          :use-custom-drop-zone-options="true"
          :useCustomSlot="true"
          v-if="useDropzone"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">{{dropzoneImageOptions.title}}</h3>
            <div class="subtitle">{{dropzoneImageOptions.subtitle}}</div>
          </div>
        </vue-dropzone>
      </div>
      <div v-if="currentImageFile.addedDate !== null" class="idb-block-footer noprint-footer">
        <b-button variant="danger" @click="clearImage">Remove Image</b-button>
      </div>
    </div>
    <div
      v-if="approvalFile !== null && approvalFile.documentFailed && !isLoading"
      class="idb-block"
    >
      <div class="idb-block-title">
        <h2>Bank Approval Document</h2>
      </div>
      <div class="idb-block-content">
        <p>The document failed to generate. Please upload a new stylesheet and try again. If this continues, please contact an administrator.</p>
        <p>Online Signup will not be usable until the document has been approved.</p>
      </div>
    </div>
    <div
      v-if="approvalFile !== null && !approvalFile.documentFailed && approvalFile.approvalDocumentFileName === '' && !isLoading"
      class="idb-block"
    >
      <div class="idb-block-title">
        <h2>Bank Approval Document</h2>
      </div>
      <div class="idb-block-content">
        <p>Document is being generated. This may take a while, you may navigate away from this page.</p>
        <p>OSU will not be usable until the document has been approved.</p>
      </div>
    </div>
    <div
      v-if="approvalFile !== null && !approvalFile.documentFailed && approvalFile.approvalDocumentFileName !== '' && !isLoading"
      class="idb-block"
    >
      <div class="idb-block-title">
        <h2>Bank Approval Document</h2>
      </div>
      <div class="idb-block-content" v-if="!approvalFile.documentApproved">
        <p>The document below can be sent to the sponsoring bank provider for approval.</p>
        <p>Online Signup will not be usable until the document has been approved.</p>
      </div>
      <div class="idb-block-content" v-if="approvalFile.documentApproved">
        <p>
          The document below has been approved by the bank
          <span
            v-if="approvalFile.approvedDocument !== null && approvalFile.approvedDocument !== ''"
          >({{formatDate(approvalFile.approvalDate)}})</span>.
        </p>
      </div>
      <div class="idb-block-footer">
        <b-button
          variant="primary"
          class="pull-right mr-2"
          @click="approve"
          :disabled="approvalFile.documentApproved"
        >Mark As Approved</b-button>
        <b-button variant="success" class="mr-2" v-if="!approvalFile.documentApproved" @click="saveDocument">
          <i class="fas fa-file mr-2"></i>Download Approval Document
        </b-button>
        <b-button variant="warning" class="mr-2" @click="recreateDocument">
          <i class="fas fa-file mr-2"></i>Regenerate Document
        </b-button>
        <b-button
          variant="success"
          v-if="approvalFile.approvedDocument !== null && approvalFile.approvedDocument !== ''"
          @click="saveApprovedDocument"
        >
          <i class="fas fa-file mr-2"></i>Currently Approved Document
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import dropzoneLike from '@/Components/Shared/DropzoneLike.vue'
import EventBus from '@/Lib/eventBus'
import loading from '@/Assets/Mixins/LoadingMixin'
import { mapGetters } from 'vuex'
export default {
  mixins: [loading],
  components: {
    dropzoneLike
  },
  data () {
    return {
      intImageKey: 0,
      updateStatusConnection: null,
      updateHubUrl: '',
      fileFormats: ['css'], // xml, json
      publicFiles: [],
      fileIds: [],
      fileFormat: 'css',
      bearerToken: '',
      group: null,
      dropzoneOptions: {
        maxFilesize: 5, // MB
        maxFiles: 4,
        acceptedFiles: '.css',
        dictDefaultMessage: '<i class="fa fa-cloud-upload fa-4x"></i><br/><br/><h3 class="dropzone-custom-title">Drop a CSS file here.</h3><div class="subtitle">Or click to select a file to upload.</div>',
        url: '',
        title: 'Drag and drop to upload stylesheet',
        subtitle: '...or click to select a file from your computer',
        previewTemplate: this.previewTemplate()
      },
      dropzoneImageOptions: {
        maxFilesize: 5, // MB
        maxFiles: 4,
        acceptedFiles: '.png,.jpg,.jpeg',
        dictDefaultMessage: '<i class="fa fa-cloud-upload fa-4x"></i><br/><br/><h3 class="dropzone-custom-title">Drop an image file here.</h3><div class="subtitle">Or click to select a file to upload.</div>',
        url: '',
        title: 'Drag and drop to upload image',
        subtitle: '...or click to select an image file from your computer',
        previewTemplate: this.previewTemplate()
      },
      currentFile: {
        onboardingStylesheetId: '',
        fileName: '',
        unapprovedFileName: '',
        addedDate: null,
        unapprovedStylesheetBytes: null
      },
      currentImageFile: {
        onboardingLogoImageId: '',
        logoImage: '',
        unapprovedLogoImage: '',
        addedDate: null,
        customerName: ''
      },
      useDropzone: true,
      fileGenerated: false,
      documentApproved: false,
      documentFileName: '',
      fileFailed: false,
      approvalFile: {
        approvedDocument: null
      }
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = this.$store.getters.customerGroups
      }
      return ret
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    customerName () {
      return this.$store.getters.selectedCustomerObject.name
    },
    importEnabled () {
      return this.fileIds.length > 0
    },
    dropzoneUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet`
    },
    secureUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/secure`
    },
    dropzoneImageUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/image`
    },
    secureImageUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/image/secure`
    },
    previewImageUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/image/${this.customerName}?paygateid=${this.paygateId}&key=${this.intImageKey}`
    },
    unapprovedImageUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/unapprovedimage/${this.customerName}?paygateid=${this.paygateId}&key=${this.intImageKey}`
    }
  },
  async created () {
    this.updateHubUrl = process.env.VUE_APP_DDMS_API_URL + 'hubs/onboardingdocument'
    window.addEventListener('beforeunload', this.stopSocketListener)
    const paygateId = this.paygateId
    this.updateHubUrl += '?paygateId=' + this.paygateId
    this.dropzoneOptions.url = this.dropzoneUrl + '?paygateId=' + paygateId
    this.dropzoneOptions.secureUrl = this.secureUrl + '?paygateId=' + paygateId
    this.dropzoneImageOptions.url = this.dropzoneImageUrl + '?paygateId=' + paygateId
    this.dropzoneImageOptions.secureUrl = this.secureImageUrl + '?paygateId=' + paygateId
  },
  async mounted () {
    if (this.$store.getters.selectedCustomer === process.env.VUE_APP_LANDZ_ID) {
      this.$router.push('/')
    }
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        // this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
        this.updateStatusConnection = new HubConnectionBuilder().withUrl(this.updateHubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.updateStatusConnection.serverTimeoutInMilliseconds = 3600000
        this.updateStatusConnection.start()
        this.updateStatusConnection.on('DocumentReady', async (data) => {
          this.$toastr.Add({
            name: 'uploadComplete',
            msg: 'Approval Document successfully uploaded.',
            preventDuplicates: true,
            type: 'success'
          })
          this.fileGenerated = true
          this.fileFailed = false
          await this.load()
        })
        this.updateStatusConnection.on('DocumentFailed', async (data) => {
          this.$toastr.Add({
            name: 'uploadfailed',
            msg: 'Approval Document failed to generate.',
            preventDuplicates: true,
            type: 'danger'
          })
          this.fileGenerated = true
          this.fileFailed = true
          await this.load()
        })
      })
    await this.load()
  },
  methods: {
    stopSocketListener () {
      this.updateStatusConnection.stop()
    },
    async clearStylesheet () {
      try {
        await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet`, { params: { paygateId: this.paygateId } })
        this.$toastr.s('Stylesheet deleted')
        await this.load()
        this.$refs.fileUploader.removeAllFiles()
        this.$refs.imageFileUploader.removeAllFiles()
        this.intImageKey++
      } catch (e) {
        this.$toastr.e('Could not delete stylesheet')
      }
    },
    async recreateDocument () {
      try {
        await axios.post(`${process.env.VUE_APP_DDMS_API_URL}onboardingurl/recreatedocument`, {}, { showload: true, params: { paygateId: this.paygateId } })
        this.$toastr.s('Generating document')
        await this.load()
      } catch (e) {
        this.$toastr.e('Failed to trigger document generation')
      }
    },
    async clearImage () {
      try {
        await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/image`)
        this.$toastr.s('Image deleted')
        await this.load()
        this.$refs.fileUploader.removeAllFiles()
        this.$refs.imageFileUploader.removeAllFiles()
        this.intImageKey++
      } catch (e) {
        this.$toastr.e('Could not delete image')
      }
    },
    async load () {
      var response = await axios.get(this.dropzoneUrl, { showload: true, params: { paygateId: this.paygateId } })
      console.log(response)
      if (response) {
        if (response.data === '') {
          response.data = { addedDate: null, approvalDocumentFileName: '', documentApproved: false, unapprovedFileName: '' }
        }
        this.currentFile = response.data
        this.fileGenerated = response.data.approvalDocumentFileName !== ''
        this.documentFileName = response.data.approvalDocumentFileName
        this.documentApproved = response.data.documentApproved
      } else {
        this.currentFile = { addedDate: null, approvalDocumentFileName: '', documentApproved: false, unapprovedFileName: '' }
        this.fileGenerated = ''
        this.documentFileName = ''
        this.documentApproved = ''
      }
      response = await axios.get(this.dropzoneImageUrl, { showload: true, params: { paygateId: this.paygateId } })
      console.log(response)
      if (response) {
        if (response.data === '') {
          response.data = { addedDate: null, approvalDocumentFileName: '', documentApproved: false, unapprovedLogoImage: null, logoImage: null }
        }
        this.currentImageFile = response.data
      } else {
        this.currentImageFile = { addedDate: null, approvalDocumentFileName: '', documentApproved: false, unapprovedLogoImage: null, logoImage: null }
      }
      this.$refs.fileUploader.removeAllFiles()
      this.$refs.imageFileUploader.removeAllFiles()
      this.intImageKey++
      await this.loadApprovalFile()
    },
    async loadApprovalFile () {
      var approvalResponse = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}onboardingurl/isapproved`, { showload: true, params: { paygateId: this.paygateId } })
      if (approvalResponse) {
        this.approvalFile = approvalResponse.data
      } else {
        this.$toastr.e('Could not load Approval Document information')
      }
    },
    async afterSuccess (file, response) {
      this.currentFile = response
      this.fileGenerated = false
      this.fileFailed = false
      await this.load()
    },
    fileAdded (file) {
      this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
      this.fileGenerated = false
      this.documentApproved = false
      this.fileFailed = false
    },
    fileRemoved (file, error, xhr) {

    },
    sendingEvent (file, xhr, formData) {
      formData.append('paygateId', this.paygateId)
    },
    async afterImageSuccess (file, response) {
      this.currentFile = response
      this.fileGenerated = false
      this.fileFailed = false
      await this.load()
    },
    fileImageAdded (file) {
      this.$refs.imageFileUploader.setOption('headers', { Authorization: this.bearerToken })
      this.fileGenerated = false
      this.documentApproved = false
      this.fileFailed = false
    },
    fileImageRemoved (file, error, xhr) {

    },
    sendingImageEvent (file, xhr, formData) {
      formData.append('paygateId', this.paygateId)
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      let retVal = ''
      if (date !== undefined) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        retVal = `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      }
      return retVal
    },
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
      if (this.$store.getters.customerGroups !== null && this.$store.getters.customerGroups.length > 0) {
        this.group = this.$store.getters.customerGroups[0].groupId
      }
    },
    async saveApprovedDocument () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/approveddocument`, { showload: true, params: { paygateId: this.paygateId } })
      var blob = this.base64ImageToBlob(response.data)
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, `${this.$store.getters.selectedCustomerObject.name}-approveddocument.docx`)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
        a.download = `${this.$store.getters.selectedCustomerObject.name}-approveddocument.docx`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    async saveDocument () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/approvaldocument`, { showload: true, params: { paygateId: this.paygateId } })
      var blob = this.base64ImageToBlob(response.data)
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, `${this.currentImageFile.customerName}-approvaldocument.docx`)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
        a.download = `${this.currentImageFile.customerName}-approvaldocument.docx`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    base64ImageToBlob (str) {
      // extract content type and base64 payload from original string
      var imageContent = atob(str)

      // create an ArrayBuffer and a view (as unsigned 8-bit)
      var buffer = new ArrayBuffer(imageContent.length)
      var view = new Uint8Array(buffer)

      // fill the view, using the decoded base64
      for (var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }

      // convert ArrayBuffer to Blob
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessing' })
      return blob
    },
    async approve () {
      try {
        this.currentFile.unapprovedStylesheet = null
        this.currentFile.stylesheet = null
        this.currentImageFile.logoImage = null
        this.currentImageFile.unapprovedLogoImage = null
        var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/approvaldocument`, { approved: true }, { showload: true, params: { paygateId: this.paygateId } })
        this.$toastr.s('Document Approved.')
        this.documentApproved = true
        this.intImageKey++
        await this.load()
        this.currentImageFile.unapprovedLogoImage = null
        this.currentImageFile.unapprovedStylesheet = null
      } catch (e) {
        this.$toastr.e('Failed to approve document.')
      }
    },
    previewTemplate () {
      return `<div class="dz-preview dz-file-preview">
                  <div class="dz-image">
                    <img data-dz-thumbnail="">
                  </div>
                  <div class="dz-details">
                    <div class="dz-size">
                      <span data-dz-size=""></span>
                    </div>
                    <div class="dz-filename">
                      <span data-dz-name="">
                      </span>
                    </div>
                  </div>
                  <div class="dz-progress">
                    <span class="dz-upload" data-dz-uploadprogress=""></span>
                  </div>
                  <div class="dz-error-message">
                    <span data-dz-errormessage=""></span>
                  </div>
                  <div class="dz-success-mark">
                  </div>
                  <div class="dz-error-mark"></div>
                  <a class="dropzone-remove-button-letters" data-dz-remove="">X</a>
                </div>`
    }
  },
  watch: {
    async selectedCustomer () {
      if (this.$store.getters.selectedCustomer === process.env.VUE_APP_LANDZ_ID) {
        this.$router.push('/')
      }
      const paygateId = this.paygateId
      this.updateHubUrl += '?paygateId=' + this.paygateId
      this.dropzoneOptions.url = this.dropzoneUrl + '?paygateId=' + paygateId
      this.dropzoneOptions.secureUrl = this.secureUrl + '?paygateId=' + paygateId
      this.dropzoneImageOptions.url = this.dropzoneImageUrl + '?paygateId=' + paygateId
      this.dropzoneImageOptions.secureUrl = this.secureImageUrl + '?paygateId=' + paygateId
      this.intImageKey++
      this.currentFile.unapprovedStylesheet = null
      this.currentFile.stylesheet = null
      this.currentImageFile.logoImage = null
      this.currentImageFile.unapprovedLogoImage = null
      await this.load()
    }
  }
}
</script>
<style type="text/css" scoped>
.dropzone-remove-button-letters {
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  color: #ffffff !important;
}
.dropzone-remove-button-letters:hover {
  cursor: pointer !important;
}
</style>
