var codes = {
  AF2200: 'AF2200',
  AF2201: 'AF2201',
  AF2202: 'AF2202',
  AF9999: 'AF9999',
  B1000: 'B1000',
  B1002: 'B1002',
  B1011: 'B1011',
  B1012: 'B1012',
  B1013: 'B1013',
  B9999: 'B9999',
  BACS1001: 'BACS1001',
  BACS1002: 'BACS1002',
  BACS1003: 'BACS1003',
  BACS1004: 'BACS1004',
  BACS1005: 'BACS1005',
  BACS1006: 'BACS1006',
  BACS1007: 'BACS1007',
  BACS1008: 'BACS1008',
  BACS1009: 'BACS1009',
  BACS1010: 'BACS1010',
  BACS1011: 'BACS1011',
  BACS1012: 'BACS1012',
  BACS1013: 'BACS1013',
  BACS1014: 'BACS1014',
  BACS9999: 'BACS9999',
  BL1003: 'BL1003',
  BL1004: 'BL1004',
  BL1005: 'BL1005',
  BL2001: 'BL2001',
  C1000: 'C1000',
  C1001: 'C1001',
  C1002: 'C1002',
  C1003: 'C1003',
  C1004: 'C1004',
  C1008: 'C1008',
  C1009: 'C1009',
  C1010: 'C1010',
  C1011: 'C1011',
  C1012: 'C1012',
  C1015: 'C1015',
  C1016: 'C1016',
  C1020: 'C1020',
  C1021: 'C1021',
  C1022: 'C1022',
  C9999: 'C9999',
  CR1000: 'CR1000',
  CR1001: 'CR1001',
  G2100: 'G2100',
  G2101: 'G2101',
  G2102: 'G2102',
  G2104: 'G2104',
  G2105: 'G2105',
  G2106: 'G2106',
  G2107: 'G2107',
  G2114: 'G2114',
  G2115: 'G2115',
  G2116: 'G2116',
  G9999: 'G9999',
  GDPR1000: 'GDPR1000',
  GDPR1001: 'GDPR1001',
  GDPR1002: 'GDPR1002',
  GDPR9999: 'GDPR9999',
  I1000: 'I1000',
  I1001: 'I1001',
  I1002: 'I1002',
  I1003: 'I1003',
  I1004: 'I1004',
  L1000: 'L1000',
  L1001: 'L1001',
  L1002: 'L1002',
  L1003: 'L1003',
  L9999: 'L9999',
  PP2010: 'PP2010',
  PP2020: 'PP2020',
  PP2030: 'PP2030',
  PP2040: 'PP2040',
  REC1000: 'REC1000',
  REC1001: 'REC1001',
  S2001: 'S2001',
  S2002: 'S2002',
  S3000: 'S3000',
  SA1000: 'SA1000',
  SA1001: 'SA1001',
  SA1002: 'SA1002',
  SA1003: 'SA1003',
  SA1004: 'SA1004',
  SA1005: 'SA1005',
  SL1000: 'SL1000',
  SL1001: 'SL1001',
  SL2000: 'SL2000',
  SL2001: 'SL2001',
  ST1000: 'ST1000',
  ST1001: 'ST1001',
  ST9999: 'ST9999',
  U2000: 'U2000',
  U2003: 'U2003',
  U2004: 'U2004',
  U2005: 'U2005',
  U2006: 'U2006',
  U2017: 'U2017',
  U2018: 'U2018',
  U2019: 'U2019',
  U2021: 'U2021',
  U9999: 'U9999',
  WF1000: 'WF1000',
  WF1002: 'WF1002',
  WF1010: 'WF1010',
  WF9999: 'WF9999'
}

var types = {
  AntiFraud: 'Anti-Fraud',
  BACS: 'BACS',
  BankAccount: 'Bank Account',
  Bankline: 'Bankline',
  Billing: 'Billing',
  Client: 'Client',
  Charge: 'Charge',
  GDPR: 'GDPR',
  Group: 'Group',
  Invoicing: 'Invoicing',
  Licence: 'Licence',
  PaymentProcessing: 'Payment Processing',
  Reconciliation: 'Reconciliation',
  SecurityToken: 'Security Token',
  ServiceAccount: 'Service Account',
  Settlement: 'Settlement',
  System: 'System',
  User: 'User',
  Workflow: 'Workflow'

}

var severities = {
  Low: 'Low',
  Medium: 'Medium',
  High: 'High'
}

export {
  codes,
  types,
  severities
}
