<template>
  <b-modal v-model="isVisible" @hide="hide" @shown="setFocus" centered size="lg" no-close-on-backdrop>
    <div slot="modal-header" class="w-100">Edit Nominal Code</div>
    <div class="row form-group">
      <div class="col-md-4 required">Code</div>
      <div class="col-md-4" :class="{invalid: $v.code.$error}">
        <input
          type="text"
          class="form-control"
          ref="defaultElement"
          maxlength="8"
          v-model.trim.lazy="$v.code.$model"
        />
      </div>
      <span v-if="$v.code.$dirty">
        <small
          v-if="!$v.code.required"
          class="form-text text-danger small text-nowrap"
        >Code is required</small>
      </span>
    </div>

    <div class="row form-group">
      <div class="col-md-4 required">Description</div>
      <div class="col-md-4" :class="{invalid: $v.description.$error}">
        <textarea
          id="description"
          class="form-control"
          rows="4"
          cols="40"
          v-model.trim.lazy="$v.description.$model"
          >
        </textarea>
      </div>
      <span v-if="$v.description.$dirty">
        <small
          v-if="!$v.description.required"
          class="form-text text-danger small text-nowrap"
        >Description is required</small>
      </span>
    </div>

    <div class="row form-group">
      <div class="col-md-4 required">Nominal Code Type</div>
      <div class="col-md-5" :class="{invalid: $v.selectedCodeType.$error}">
        <b-select v-model.trim="$v.selectedCodeType.$model" :options="nominalCodeTypes" @change="nominalCodeChanged()" />
      </div>
      <span v-if="$v.selectedCodeType.$dirty">
        <small
          v-if="!$v.selectedCodeType.required"
          class="form-text text-danger small text-nowrap"
        >Nominal Code Type is required</small>
      </span>
    </div>

    <div v-if="selectedCodeType !== '' && selectedCodeType !== 'Default'" class="row form-group">
      <div class="col-md-4 required">Bank Account</div>
      <div class="col-md-5" :class="{invalid: $v.selectedBankAccount.$error}">
        <vue-select
          v-model="$v.selectedBankAccount.$model"
          :options="bankAccounts"
          :closeOnSelect="true"
          :clearable="false"
          label="reference"
          @option:selected="selectBankAccount()">
        </vue-select>
      </div>
      <div class="col-md-1">
        <button class="btn btn-outline-secondary" type="button" @click="viewBankDetails">View</button>
      </div>
      <span v-if="$v.selectedBankAccount.bankAccountId.$dirty">
        <small
          v-if="!$v.selectedBankAccount.bankAccountId.required"
          class="form-text text-danger small text-nowrap"
        >Bank Account is required</small>
      </span>
    </div>

    <div slot="modal-footer" class="w-100">
      <b-row>
        <b-col cols="2">
          <b-btn
            size="sm"
            class="float-left"
            variant="primary"
            @click="saveNominalCode"
          >Save Nominal Code</b-btn>
        </b-col>
        <b-col cols="2">
          <b-btn
            size="sm"
            class="float-right"
            variant="btn btn-outline-danger"
            @click="cancel"
          >Cancel</b-btn>
        </b-col>
        <b-col cols="8">
          <b-btn
            size="sm"
            class="float-right"
            variant="danger"
            @click="deleteNominalCode"
            v-show="!newNominalCode"
          ><i class="fa fa-trash mr-2"></i>Delete Nominal Code</b-btn>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import axios from 'axios'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import VueSelect from 'vue-select'
import bacsCommon from '@/Lib/BacsCommon.js'

export default {
  props: {
    isVisible: Boolean,
    nominalCode: Object,
    newNominalCode: Boolean,
    bankAccounts: Array
  },
  components: {
    VueSelect
  },
  data () {
    return {
      nominalCodeTypes: [
        { value: 'Default', text: 'Default' },
        { value: 'Bank Account - Bank', text: 'Bank Account - Bank' },
        { value: 'Bank Account - Client', text: 'Bank Account - Client' }
      ],
      selectedCodeType: '',
      code: '',
      description: '',
      originalCode: '',
      selectedBankAccountId: null,
      selectedBankAccount: {}
    }
  },

  methods: {
    hide () {
      this.$emit('input', false)
    },

    setFocus () {
      this.code = this.nominalCode.code

      if (this.newNominalCode) {
        this.code = ''
        this.description = ''
        this.selectedCodeType = ''
        this.selectedBankAccountId = null
        this.selectedBankAccount = {}
      } else {
        this.originalCode = this.nominalCode.code
        this.code = this.nominalCode.code
        this.description = this.nominalCode.description
        this.selectedCodeType = this.nominalCode.nominalCodeType
        if (this.nominalCode.bankAccountId !== null && this.nominalCode.bankAccountId !== undefined) {
          this.selectedBankAccount = this.bankAccounts.find(i => i.bankAccountId === this.nominalCode.bankAccountId)
          this.selectedBankAccountId = this.selectedBankAccount.bankAccountId
        }
      }

      this.$v.$reset()
      this.$refs.defaultElement.focus()
    },

    onBlur (element) {
      element.$touch()
    },

    nominalCodeChanged () {
      if (this.selectedCodeType === 'Default') {
        this.selectedBankAccountId = null
      }
    },

    selectBankAccount () {
      this.selectedBankAccountId = this.selectedBankAccount.bankAccountId
    },

    cancel () {
      this.selectedCodeType = ''
      this.$emit('close')
    },

    async saveNominalCode () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return false
      }

      var bankRef = ''
      if (this.selectedBankAccount !== null) {
        bankRef = this.selectedBankAccount.reference
      }
      const nominalCode = {
        code: this.code,
        description: this.description,
        nominalCodeType: this.selectedCodeType,
        bankaccountId: this.selectedBankAccountId,
        reference: bankRef
      }

      const method = this.newNominalCode ? 'POST' : 'PUT'
      let url = `${process.env.VUE_APP_PLATFORM_API_URL}nominalCode`
      if (method === 'PUT') {
        url = `${process.env.VUE_APP_PLATFORM_API_URL}nominalCode?originalCode=${this.originalCode}`
      }

      console.log('method: ' + method)
      console.log('url: ' + url)
      var response = await axios({
        method: method,
        url: url,
        data: JSON.stringify(nominalCode),
        showload: true
      })
      if (response.data.status !== 'Failed') {
        this.$toastr.s('Nominal code saved')
        this.selectedCodeType = ''
        this.$emit('savedNominalCode')
      } else {
        this.$snapbar.e(response.data.errorText)
      }
    },

    async deleteNominalCode () {
      var result = await swal.fire({
        title: 'Are you sure you want to delete this nominal code?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: colours.danger
      })
      if (result.isConfirmed) {
        var response = await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}nominalCode?code=${this.code}`)
        if (response.data.status === 'Deleted') {
          this.$toastr.s('Nominal code deleted')
          this.selectedCodeType = ''
          this.$emit('savedNominalCode')
        } else {
          this.$snapbar.e(response.data.errorText)
        }
      }
    },

    async viewBankDetails () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.selectedBankAccountId}`, { showload: true, showerror: true, errormessage: 'Failed to get bank account' })
        var bankAccount = response.data
        this.$swal({
          title: 'Bank details',
          html: bacsCommon.viewGroupBankDetails(bankAccount, ''),
          type: 'info',
          animation: false
        })
      } catch { }
    }
  },

  validations: {
    code: { required },
    description: { required },
    selectedCodeType: { required },
    selectedBankAccount: {
      bankAccountId: {
        required: requiredIf(function () {
          return this.selectedCodeType !== 'Default'
        })
      }
    }
  }
}
</script>
