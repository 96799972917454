<template>
  <div id="nonProcessingDays">
    <!-- <loading v-bind:loading="loading"></loading> -->
    <div v-show="nonProcessingDaysLoaded">
      <div class="row form-group">
        <div class="col-md-12">
          <vue-good-table
            @on-row-click="onRowClick"
            :columns="columns"
            :rows="nonProcessingDays"
            :pagination-options="{
              enabled: true,
              perPage: 10,
              dropdownAllowAll: false
            }"
            styleClass="vgt-table striped bordered"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'northernIrelandOnly'">
                <span v-if="props.row.northernIrelandOnly === true">
                  <p-check class="p-switch p-fill" color="primary" checked :disabled="true"></p-check>
                </span>
                <span v-else>
                  <p-check class="p-switch p-fill" color="primary" :disabled="true"></p-check>
                </span>
              </span>
              <span v-else>{{props.formattedRow[props.column.field]}}</span>
            </template>
            <div slot="table-actions">
              <b-button
                @click.prevent="createNonProcessingDay"
                class
                variant="link"
                title="Create Non-Processing Day"
                v-b-popover.hover.top.d500="'Create a new non-processing day'"
              >
                <i class="fa fa-plus pointer dimmedIcon"></i>
              </b-button>
            </div>
          </vue-good-table>
        </div>
      </div>
      <editNonProcessingDay
        v-model="isModalVisible"
        :nonProcessingDay="currentNonProcessingDay"
        :newNonProcessingDay="newNonProcessingDay"
        @close="editCancelled"
        @savedNonProcessingDay="savedNonProcessingDay"
      ></editNonProcessingDay>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import EditNonProcessingDay from '@/Views/Bacs/BacsAdmin/EditNonProcessingDay.vue'

export default {
  components: {
    editNonProcessingDay: EditNonProcessingDay
  },

  data () {
    return {
      nonProcessingDays: [],
      nonProcessingDaysLoaded: false,
      columns: [
        {
          label: 'Non-Processing Date',
          field: 'date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy',
          thClass: 'text-left',
          tdClass: 'text-left'
        },
        {
          label: 'Reason',
          field: 'reason'
        }
        // {
        //   label: 'Northern Ireland Only',
        //   field: 'northernIrelandOnly',
        //   type: 'boolean',
        //   html: true,
        //   thClass: 'text-left',
        //   tdClass: 'text-left'
        // }
      ],
      loading: false,
      currentNonProcessingDay: {},
      isModalVisible: false,
      newNonProcessingDay: false
    }
  },

  methods: {
    async getBacsNonProcessingDays () {
      this.nonProcessingDaysLoaded = false
      this.loading = true
      this.nonProcessingDays = []
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/admin/getBacsNonProcessingDays`)
      this.nonProcessingDays = response.data
      this.nonProcessingDaysLoaded = true
      this.loading = false
    },

    onRowClick (params) {
      // console.log('Row details: ' + JSON.stringify(params))
      this.currentNonProcessingDay = params.row
      this.isModalVisible = true
    },

    createNonProcessingDay () {
      this.newNonProcessingDay = true
      this.currentNonProcessingDay = {}
      this.currentNonProcessingDay.date = ''
      this.currentNonProcessingDay.reason = ''
      this.currentNonProcessingDay.northernIrelandOnly = false
      this.isModalVisible = true
    },

    editCancelled () {
      this.isModalVisible = false
      this.newNonProcessingDay = false
    },

    savedNonProcessingDay () {
      this.isModalVisible = false
      this.newNonProcessingDay = false
      this.getBacsNonProcessingDays()
    }
  },

  async mounted () {
    await this.getBacsNonProcessingDays()
  }
}
</script>
