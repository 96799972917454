<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline Configuration<span class="pull-right"><a :href="`${docUrl}/automation/logging/`" target="_blank"><i class="far fa-question-circle d-none"></i></a></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <b-form-row :class="{invalid: $v.banklineEngineConfig.maximumStdIndividualAmount.$error}">
            <div class="col-md-4 col-sm-12">
              <label class="required" v-b-popover.hover.top.d500="'The maximum amount, in UK pounds, of any individual STD payment in the file.'" >Maximum Individual STD Amount</label>
            </div>
            <div class="form-group col-md-8 col-sm-12">
              <b-form-input
                id="maximumIndividualAmount"
                class="col-sm-2"
                type="number"
                v-model.number="banklineEngineConfig.maximumStdIndividualAmount"
                text-field="name"
                value-field="value"
                :min="1"
                :max="10000000"
                step="1"
                onchange="this.value = parseInt(this.value);"
                @input="$v.banklineEngineConfig.maximumStdIndividualAmount.$touch()"
              />
              <p class="validationText" v-if="!$v.banklineEngineConfig.maximumStdIndividualAmount.required && $v.banklineEngineConfig.maximumStdIndividualAmount.$dirty ">The field is required!</p>
              <p class="validationText" v-if="!$v.banklineEngineConfig.maximumStdIndividualAmount.minValue">Minimum value is {{ $v.banklineEngineConfig.maximumStdIndividualAmount.$params.minValue.min }}!</p>
              <p class="validationText" v-if="!$v.banklineEngineConfig.maximumStdIndividualAmount.maxValue">Maximum value is {{ $v.banklineEngineConfig.maximumStdIndividualAmount.$params.maxValue.max }}!</p>
            </div>
          </b-form-row>
          <b-form-row :class="{invalid: $v.banklineEngineConfig.maximumUrgIndividualAmount.$error}">
            <div class="col-md-4 col-sm-12">
              <label class="required" v-b-popover.hover.top.d500="'The maximum amount, in UK pounds, of any individual URG (CHAPS) payment in the file.'" >Maximum Individual URG (CHAPS) Amount</label>
            </div>
            <div class="form-group col-md-8 col-sm-12">
              <b-form-input
                id="maximumIndividualAmount"
                class="col-sm-2"
                type="number"
                v-model.number="banklineEngineConfig.maximumUrgIndividualAmount"
                text-field="name"
                value-field="value"
                :min="1"
                :max="10000000"
                step="1"
                onchange="this.value = parseInt(this.value);"
                @input="$v.banklineEngineConfig.maximumUrgIndividualAmount.$touch()"
              />
              <p class="validationText" v-if="!$v.banklineEngineConfig.maximumUrgIndividualAmount.required && $v.banklineEngineConfig.maximumUrgIndividualAmount.$dirty ">The field is required!</p>
              <p class="validationText" v-if="!$v.banklineEngineConfig.maximumUrgIndividualAmount.minValue">Minimum value is {{ $v.banklineEngineConfig.maximumUrgIndividualAmount.$params.minValue.min }}!</p>
              <p class="validationText" v-if="!$v.banklineEngineConfig.maximumUrgIndividualAmount.maxValue">Maximum value is {{ $v.banklineEngineConfig.maximumUrgIndividualAmount.$params.maxValue.max }}!</p>
            </div>
          </b-form-row>
          <b-form-row :class="{invalid: $v.banklineEngineConfig.maximumItemsPerFile.$error}">
            <div class="col-md-4 col-sm-12">
              <label class="required" v-b-popover.hover.top.d500="'The maximum number of individual transactions per file.'" >Maximum Items Per File (STD and URG Payment files)</label>
            </div>
            <div class="form-group col-md-8 col-sm-12">
              <b-form-input
                id="maximumItemsPerFile"
                class="col-sm-2"
                type="number"
                v-model.number="banklineEngineConfig.maximumItemsPerFile"
                text-field="name"
                value-field="value"
                :min="1"
                :max="100000"
                step="1"
                onchange="this.value = parseInt(this.value);"
                @input="$v.banklineEngineConfig.maximumItemsPerFile.$touch()"
              />
              <p class="validationText" v-if="!$v.banklineEngineConfig.maximumItemsPerFile.required && $v.banklineEngineConfig.maximumItemsPerFile.$dirty ">The field is required!</p>
              <p class="validationText" v-if="!$v.banklineEngineConfig.maximumItemsPerFile.minValue">Minimum value is {{ $v.banklineEngineConfig.maximumItemsPerFile.$params.minValue.min }}!</p>
              <p class="validationText" v-if="!$v.banklineEngineConfig.maximumItemsPerFile.maxValue">Maximum value is {{ $v.banklineEngineConfig.maximumItemsPerFile.$params.maxValue.max }}!</p>
            </div>
          </b-form-row>
          <b-form-row :class="{invalid: $v.banklineEngineConfig.maximumItemsPerFile.$error}">
            <div class="col-md-4 col-sm-12">
              <label>Prevent uploading duplicate inbound bankline files</label>
            </div>
            <div class="form-group col-md-8 col-sm-12">
              <b-form-checkbox v-model="banklineEngineConfig.preventDupes">
              </b-form-checkbox>
            </div>
          </b-form-row>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div class="btn-group" role="group">
                <button id="btnGroupDrop1" @click="btnSaveConfig" type="button" class="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
export default {
  name: 'banklineconfig',
  data () {
    return {
      banklineEngineConfig: {},
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  validations: {
    banklineEngineConfig: {
      maximumStdIndividualAmount: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(10000000)
      },
      maximumUrgIndividualAmount: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(10000000)
      },
      maximumItemsPerFile: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(10000)
      }
    }
  },
  methods: {
    async load () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineconfig`)
        if (res) {
          console.log(res)
          this.banklineEngineConfig = res.data.responseObj.banklineEngineConfig
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline config data.')
        } else {
          this.$snapbar.e(`Could not get bankline configuration - ${e.message}`)
        }
      }
    },
    async btnSaveConfig () {
      this.$snapbar.hide()
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$toastr.e('There are validation errors on the form', 'Validation')
        return
      }
      try {
        await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineconfig`, this.banklineEngineConfig)
        // this.$snapbar.s('The Bankline configuration has been saved.')
        this.$toastr.s('The Bankline configuration has been saved', 'Success')
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to save Bankline config data.')
        } else {
          this.$snapbar.e(`Could not get bankline configuration - ${e.message}`)
        }
      }
    }
  },
  mounted () {
    this.load()
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.load()
    }
  }
}
</script>
<style scoped>
  input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
          -webkit-appearance: none;
      }
      input[type="number"]{
          -moz-appearance: textfield;
      }
</style>
