<template>
  <form @submit.prevent>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>{{status}} VAT</h2>
      </div>

      <div class="idb-block-content">
        <!-- Name -->
        <div class="form-group row" :class="{ invalid: $v.vat.name.$error }">
          <label class="col-form-label col-md-3 required">Name</label>
          <div class="col-md-6">
            <input v-model="$v.vat.name.$model" class="form-control" type="text" />
            <!-- Validation -->
            <validation-messages v-model="$v.vat.name" name="name"></validation-messages>
          </div>
        </div>

        <!-- Rate -->
        <div class="form-group row" :class="{ invalid: $v.vat.rate.$error }">
          <label class="col-form-label col-md-3 required">Rate</label>
          <div class="col-md-2">
            <div class="input-group">
              <two-decimal-places-input class="form-control" v-model.number="$v.vat.rate.$model" />
              <div class="input-group-append">
                <span class="input-group-text" id="percent-addon">%</span>
              </div>
            </div>

            <!-- Validation -->
            <validation-messages v-model="$v.vat.rate" name="rate"></validation-messages>
          </div>
        </div>

        <!-- Active From -->
        <div
          class="form-group row"
          :class="{invalid: $v.vat.activeFrom.$error}"
          v-if="status != 'Create'"
        >
          <label class="label-control col-md-3 required">Active From</label>
          <div class="col-md-3">
            <vuejsDatepicker
              name="to"
              id="to"
              format="dd/MM/yyyy"
              input-class="form-control bg-white"
              :bootstrap-styling="true"
              minimumView="day"
              maximumView="year"
              initial-view="day"
              :use-utc="true"
              :disabled-dates="{
                    to: new Date()
                    }"
              :calendar-button="true"
              calendar-button-icon="fa fa-calendar"
              v-model="$v.vat.activeFrom.$model"
            ></vuejsDatepicker>

            <!-- Validation -->
            <validation-messages v-model="$v.vat.activeFrom" name="active from date"></validation-messages>
          </div>
        </div>

        <div>
          <div class="row">
            <div class="col-md-12">
              <vue-good-table
                :columns="columns"
                :rows="vat.rates"
                :totalRows="vat.rates.length"
                :pagination-options="{
                    enabled: true,
                    perPage: 10,
                    dropdownAllowAll: false,
                            jumpFirstOrLast: true
                  }"
                :search-options="{
                    enabled: true
                  }"
                styleClass="vgt-table striped bordered"
              ></vue-good-table>
            </div>
          </div>
        </div>
      </div>

      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          :disabled=" isLoading"
          type="submit"
          @click="checkValidation() && saveVat()"
        >{{ status === "Edit" ? "Save" : status }}</button>
        <button class="btn btn-outline-danger pull-right ml-3" type="button" @click="back">Cancel</button>
      </div>
    </div>
  </form>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'

// Components
import TwoDecimalPlacesInput from '@/Assets/Components/TwoDecimalPlacesInput'

// Validators
import { required, maxValue, minValue, requiredIf } from 'vuelidate/lib/validators'

// Mixins
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  mixins: [DataLeaveMixin, loading],
  props: ['status', 'id'],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () { this.$router.push({ name: 'ListVat' }) }
  },
  components: {
    TwoDecimalPlacesInput
  },
  data () {
    return {
      vat: {
        name: null,
        rate: 0,
        activeFrom: new Date(),
        rates: []
      },
      dateOptions: {
        format: 'DD/MM/YYYY',
        sideBySide: true,
        minDate: new Date()

      },
      columns: [
        {
          label: 'Rate',
          field: 'rate'
        },
        {
          label: 'Active From',
          field: 'activeFrom',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        }]
    }
  },
  async mounted () {
    if (this.status === 'Edit') {
      await this.loadVat()
    }
  },
  methods: {
    async saveVat () {
      try {
        if (this.status === 'Create') {
          await axios.post(
            `${process.env.VUE_APP_PLATFORM_API_URL}Vat`,
            this.vat,
            { showload: true, showerror: true, errormessage: 'VAT failed to save' }
          )
          this.$v.$reset()

          this.$toastr.s('New VAT created', 'Created')
          this.$router.push({ name: 'ListVat' })
        } else {
          await axios.put(
            `${process.env.VUE_APP_PLATFORM_API_URL}Vat`,
            this.vat,
            { showload: true, showerror: true, errormessage: 'VAT failed to save' }
          )

          this.$toastr.s('VAT updated', 'Updated')

          await this.loadVat()
        }
      } catch { } finally {
        this.$nextTick(() => this.$v.$reset())
      }
    },
    async loadVat () {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}Vat/${this.id}`,
          { showload: true, showerror: true, errormessage: 'Failed to get VAT' }
        )

        this.vat.id = response.data.id
        this.vat.name = response.data.name
        this.vat.rate = response.data.currentRate
        this.vat.rates = response.data.rates
        var minDate = new Date()
        minDate.setDate(minDate.getDate() + 1)
        this.vat.activeFrom = minDate
        this.dateOptions.minDate = minDate

        this.$nextTick(() => this.$v.$reset())
      } catch { }
    }

  },
  validations () {
    return {
      vat: {
        name: { required },
        rate: { required, maxValue: maxValue(100), minValue: minValue(0) },
        activeFrom: {
          required: requiredIf(() => {
            return this.status !== 'Create'
          })
        }
      }
    }
  }
}
</script>
