<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2><help-icon docPath="/paygate-collections/messaging/lettersout/" />Postworks Out Tray<favourite-icon></favourite-icon></h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group">
          <div class="col-md-12">
            <postworks-output />
          </div>
        </div>
      </div>
      <div class="idb-block-footer mt-3">
        <b-button class="pull-right" variant="danger" @click="recallAll">Recall All Available Messages</b-button>
      </div>
    </div>
  <div class="idb-block" v-if="false">
    <div class="idb-block-title">
      <h2><help-icon docPath="/paygate-collections/messaging/lettersout/" />Output folder</h2>
    </div>
    <div class="idb-block-content">
      <div class="row">
        <div class="col-md-12">These are files ready to be sent to postworks.</div>
      </div>
      <div class="row form-group">
        <div class="col-md-12">
          <outbox endpoint="letteroutput" />
        </div>
      </div>
    </div>
    <div class="idb-block-footer mt-3">
      <b-button :disabled="pressed" variant="primary" @click="sendToPostworks">Trigger Sending Process</b-button>
    </div>
  </div>
    <div class="idb-block mt-3">
      <div class="idb-block-title">
        <h2>Upload Folder</h2>
      </div>
      <div class="idb-block-content">
        <div class="row">
          <div class="col-md-12">These are what are sent to postworks. This should be empty, unless the process is in the middle of sending.</div>
        </div>
        <div class="row form-group">
          <div class="col-md-12">
            <outbox endpoint="letterupload" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import axios from 'axios'
import papa from 'papaparse'
import Outbox from '@/Views/Collections/Messaging/TrayTable/Outbox.vue'
import PostworksOutput from '@/Views/Collections/Messaging/TrayTable/PostworksOutput.vue'
import swal from "sweetalert2";
import colours from "@/Assets/Constants/colours";
export default {
  mixins: [tableFilterMixin, loading],
  components: { Outbox, PostworksOutput },
  data () {
    return {
      pressed: false
    }
  },
  methods: {
    async sendToPostworks () {
      try {
        let data = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}postworks/trigger`)
        this.$toastr.s('Triggered')
      } catch (er) {
        this.$toastr.e('Could not trigger process')
      }
    },
    async recallAll() {
      var result = await swal.fire({
        title: `Recall All Messages`,
        text: 'All messages less than 30 minutes old will be recalled.',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        try {
          await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}collectionsadministration/recallavailable`, { params: { paygateId: this.paygateId } })
          await this.load()
          this.$toastr.s(`Messages recalled`, 'Messages recalled')
        } catch (e) {
          this.$toastr.e('Unable to recall the messages.', 'Error Occurred')
        }
      }
    },
  }
}
</script>
