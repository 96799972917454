<template>
  <div>
    <b-modal
      id="feedback"
      ref="feedback"
      variant="primary"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
      title="Give Feedback"
      size="lg"
    >
      <div class="row">
        <div class="col-md-12 text-center">
          <p>We would like your feedback to improve our website</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>How would you rate your feedback?</label>
          </div>
        </div>
      </div>
      <!-- This is the stars, can be replaced with whatever really -->
      <div>
        <div class="row" :class="{ invalid: $v.score.$error }">
          <div class="col text-center">
            <p>1 (Negative)</p>
            <i
              class="fa-star star"
              :class="$v.score.$model > 0?'fas':'far'"
              @click="$v.score.$model = 1"
            ></i>
          </div>
          <div class="col text-center">
            <p class="content">&#160;</p>
            <i
              class="fa-star star"
              :class="$v.score.$model > 1?'fas':'far'"
              @click="$v.score.$model = 2"
            ></i>
          </div>
          <div class="col text-center">
            <p>3 (Neutral)</p>
            <i
              class="fa-star star"
              :class="$v.score.$model > 2?'fas':'far'"
              @click="$v.score.$model = 3"
            ></i>
          </div>
          <div class="col text-center">
            <p class="content"></p>
            <i
              class="fa-star star"
              :class="$v.score.$model > 3?'fas':'far'"
              @click="$v.score.$model = 4"
            ></i>
          </div>
          <div class="col text-center">
            <p>5 (Positive)</p>
            <i
              class="fa-star star"
              :class="$v.score.$model > 4?'fas':'far'"
              @click="$v.score.$model = 5"
            ></i>
          </div>
        </div>
        <div class="row" :class="{ invalid: $v.score.$error }">
          <div class="col text-center">
            <span v-if="$v.score.$error" class="validation-messages">
              <small class="form-text small" v-if="!$v.score.required">Please pick a score</small>
            </span>
          </div>
        </div>
      </div>
      <br />
      <!-- Other form bits -->
      <div v-show="$v.score.$model > 0">
        <div class="form-group">
          <label class="col-form-label">{{message}}</label>

          <textarea class="form-control" rows="3" v-model.trim="comments"></textarea>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-3">Submit as Anonymous</label>
          <div class="col-md-1">
            <p-check class="p-switch p-fill" color="primary" v-model="anon"></p-check>
          </div>
          <div class="col-md-7">
            <small
              class="form-text small"
            >When this is checked, we won't record any idetifying details and your feedback will remain 100% anonymous</small>
          </div>
        </div>
      </div>
      <template slot="modal-footer">
        <button
          type="button"
          class="btn btn-success mr-auto"
          @click="checkValidation('You have to enter a score to submit feedback') && submitFeedback()"
        >Submit Feedback</button>
        <button type="button" class="btn btn-primary" @click="hideModal">Cancel</button>
      </template>
    </b-modal>
  </div>
</template>
<script>

import axios from 'axios'
import {
  between
} from 'vuelidate/lib/validators'

export default {
  name: 'feedbackModal',
  data () {
    return {
      score: 0,
      comments: '',
      anon: false
    }
  },
  computed: {
    message () {
      if (this.score < 3) {
        return 'We\'re sorry to hear that, can you tell us what we could improve on?'
      }
      if (this.score > 3) {
        return 'We\'re pleased, can you tell us what you like?'
      }
      return 'Can you tell us what you do/don\'t like?'
    }
  },
  methods: {
    async submitFeedback () {
      try {
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Feedback`, {
          score: this.score,
          comments: this.comments,
          anonymous: this.anon,
          page: this.$router.currentRoute.path
        })
        this.$toastr.s('We value your feedback, thank you!', 'Thanks for your feedback')
        this.hideModal()
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    hideModal () {
      this.$refs.feedback.hide()
      this.score = 0
      this.comments = ''
      this.$v.$reset()
      this.anon = false
    }
  },
  validations () {
    return {
      score: {
        required: between(1, 5)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.star {
  color: #ffbf00;
}

// This physically makes me sick, but the tempalte compiler is stripping &nbsp;
.content {
  &::before {
    content: "\00a0";
  }
}
</style>
