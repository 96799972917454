import groupTypes from '@/Assets/Constants/groupTypes'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

function commonTest () {
  alert('Hello World!')
}

function viewGroupBankDetails (groupBankAccount, groupName) {
  if (groupBankAccount.bankName === undefined || groupBankAccount.bankName === null) {
    groupBankAccount.bankName = ''
  }
  var html = '<table border="0" width="90%" style="margin-left: auto; margin-right: auto;">'
  html += '<tr><td class="viewBankDetailsTitle">Sort Code:</td><td class="viewBankDetailsValue">' + groupBankAccount.sortCode + '</td></tr>'
  html += '<tr><td class="viewBankDetailsTitle">Account Number:</td><td class="viewBankDetailsValue">' + groupBankAccount.accountNumber + '</td></tr>'
  html += '<tr><td class="viewBankDetailsTitle">Bank Account Name:</td><td class="viewBankDetailsValue">' + groupBankAccount.bankName + '</td></tr>'
  html += '<tr><td class="viewBankDetailsTitle">Our Bank Reference:</td><td class="viewBankDetailsValue">' + groupBankAccount.reference + '</td></tr>'
  html += '<tr><td class="viewBankDetailsTitle">Default Currency:</td><td class="viewBankDetailsValue">' + groupBankAccount.currency + '</td></tr>'
  html += '</table>'
  return html
}

function getBrowser () {
  var browser = ''
  // console.log('User Agent: ' + navigator.userAgent)
  if (navigator.userAgent.indexOf('Edge') !== -1) {
    browser = 'Edge'
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    browser = 'Chrome'
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    browser = 'Firefox'
  } else if ((navigator.userAgent.indexOf('MSIE') !== -1) || (!!document.documentMode === true)) { // IF IE > 10
    browser = 'IE'
  }

  return browser
}

function browserWarning (obj, device) {
  obj.$toastr.w('Signing with a ' + device + ' only works in Internet Explorer')
}
function smartcardBrowserWarning (obj) {
  obj.$toastr.w('Signing with a smartcard only works in Internet Explorer')
}
function usbTokenBrowserWarning (obj) {
  obj.$toastr.w('A USB token can only be used with Internet Explorer')
}

function formatVocalinkLoginChallenge (loginChallenge) {
  return '<div style="text-align: left;">' + loginChallenge + '</div>'
}

function vocalinkCertificateDetails (vocalinkCert) {
  var html = '<table border="0">'
  html += '<tr><td class="alertKey">Issuer</td></tr>'
  html += '<tr><td class="alertValueLeft alertValuePadding">' + vocalinkCert.issuer + '</td></tr>'
  html += '<tr><td class="alertKey">Subject</td></tr>'
  html += '<tr><td class="alertValueLeft alertValuePadding">' + vocalinkCert.subject + '</td></tr>'
  html += '<tr><td class="alertKey">Name</td></tr>'
  html += '<tr><td class="alertValueLeft alertValuePadding">' + vocalinkCert.name + '</td></tr>'
  html += '<tr><td class="alertKey">Start Date</td></tr>'
  html += '<tr><td class="alertValueLeft alertValuePadding">' + vocalinkCert.effectiveDateString + '</td></tr>'
  html += '<tr><td class="alertKey">Expiry Date</td></tr>'
  html += '<tr><td class="alertValueLeft alertValuePadding">' + vocalinkCert.expirationDateString + '</td></tr>'
  html += '</table>'
  return html
}

async function cancelSubmissionDialog () {
  // var cancellationReason = ''
  var cancel = false

  var result = await swal.fire({
    title: 'Are you sure you want to cancel this submission?',
    input: 'text',
    inputPlaceholder: 'Optional cancellation reason',
    icon: 'warning',
    allowOutsideClick: false,
    showCancelButton: true,
    cancelButtonText: 'No',
    confirmButtonText: 'Yes',
    confirmButtonColor: colours.danger
  })

  var cancelReponse = {}

  if (result.isConfirmed) {
    cancel = true
    // eslint-disable-next-line
    cancelReponse['cancel'] = cancel
    // eslint-disable-next-line
    cancelReponse['cancellationReason'] = result.value
  }
  return cancelReponse
}

async function confirmCancellation (json, axios, toastr, router) {
  var response = await axios({
    method: 'POST',
    url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/cancelsubmission',
    data: json,
    showload: true
  })

  if (response.data.length === 0) {
    toastr.s('Submission cancelled')
  } else {
    toastr.e(response.data)
  }
  router.push('/actions')
}

function getPaygateType (groupType) {
  var paygateType = ''
  if (groupType === groupTypes.bacs || groupType === groupTypes.fps || groupType === groupTypes.collections) {
    paygateType = 'Enterprise'
  } else if (groupType === groupTypes.bureau) {
    paygateType = 'Bureau'
  } else if (groupType === groupTypes.bacsOnline || groupType === groupTypes.fpsOnline) {
    paygateType = 'Online'
  }
  return paygateType
}

function pluginLoaded () {
  var loaded = false
  try {
    var plugin = document.PayGatePlugin
    var pluginVersion = plugin.GetVersion()
    if (pluginVersion !== undefined) {
      loaded = true
    }
  } catch (ex) {
  }

  return loaded
}

// Each value of plugin component version refers to a different part of the plugin.
// From left to right the component is:
// 0 USB submission commit (Paygate Online only)
// 1 USB admin features
// 2 Smartcard signing
// 3 Restricted file browser
function isLatestPlugin (component, expectedVersion) {
  var newPluginRequired = false
  try {
    var plugin = document.PayGatePlugin
    var pluginVersion = plugin.GetVersion()
    if (pluginVersion !== undefined) {
      var componentVersions = pluginVersion.split('.')
      var version = componentVersions[component]
      if (version !== expectedVersion) {
        newPluginRequired = true
      }
    }
  } catch (ex) {
  }

  return newPluginRequired
}

function isLatestNonActiveXPlugin (pluginVersion, component, expectedVersion) {
  console.log('isLatestNonActiveXPlugin: pluginVersion: ' + pluginVersion + ', component: ' + component + ', expectedVersion: ' + expectedVersion)
  var newPluginRequired = false
  try {
    if (pluginVersion !== undefined) {
      var componentVersions = pluginVersion.split('.')
      var version = componentVersions[component]
      if (version !== expectedVersion) {
        newPluginRequired = true
      }
    }
  } catch (ex) {
  }

  return newPluginRequired
}

async function downloadPayGatePluginMsi (axios) {
  var browser = getBrowser()
  // console.log('Browser (common): ' + browser)

  var response = await axios({
    url: 'bacs/submission/downloadPlugin?browser=' + browser,
    method: 'GET',
    responseType: 'blob'
  })

  const blob = new Blob([response.data])
  const downloadUrl = window.URL.createObjectURL(blob)
  let filename = ''
  const disposition = response.headers['content-disposition']
  // console.log('disposition: ' + disposition)
  // console.log('headers: ' + JSON.stringify(response.headers))

  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // Works in IE and Edge.
    window.navigator.msSaveBlob(blob, filename)
  } else {
    // Uses HTML5 file attribute. Works in Chrome, Firefox and Edge.
    var a = document.createElement('a')
    // safari doesn't support this yet
    if (typeof a.download === 'undefined') {
      window.location.href = downloadUrl
    } else {
      a.href = downloadUrl
      a.download = filename
      document.body.appendChild(a)
      a.click()
    }
  }
}

async function showVocalinkChallengeNoPin (challenge) {
  await swal.fire({
    title: 'Sign the following Login Challenge',
    html: formatVocalinkLoginChallenge(challenge),
    icon: 'info',
    allowOutsideClick: false,
    confirmButtonText: 'Sign'
  })
}

async function showVocalinkChallengePin (challenge) {
  var customerPin = ''

  var result = await swal.fire({
    title: 'Login Challenge',
    html: '<div style="text-align: left;">Enter your HSM PIN to sign the following login challenge:</div><br />' + formatVocalinkLoginChallenge(challenge),
    icon: 'info',
    allowOutsideClick: false,
    input: 'password',
    inputPlaceholder: 'HSM PIN',
    confirmButtonText: 'Sign'
  })

  if (result.isConfirmed) {
    customerPin = result.value
  }

  return customerPin
}

export default {
  commonTest,
  viewGroupBankDetails,
  getBrowser,
  formatVocalinkLoginChallenge,
  vocalinkCertificateDetails,
  cancelSubmissionDialog,
  confirmCancellation,
  getPaygateType,
  smartcardBrowserWarning,
  usbTokenBrowserWarning,
  browserWarning,
  pluginLoaded,
  isLatestPlugin,
  isLatestNonActiveXPlugin,
  downloadPayGatePluginMsi,
  showVocalinkChallengeNoPin,
  showVocalinkChallengePin
}
