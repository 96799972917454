var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{ref:"bureauCustomerTable",attrs:{"mode":"remote","paginationOptions":{
      enabled: true,
      perPage: 10,
    },"rows":_vm.rows,"columns":_vm.columns,"totalRows":_vm.totalRecords,"searchOptions":{
      enabled: true,
    },"selectOptions":{
      enabled: true,
      selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
    },"row-style-class":_vm.rowStyleClassFn},on:{"on-search":_vm.onSearch,"on-row-click":_vm.onRowClick,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange}}),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-btn',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.onAddSelected}},[_vm._v("Add Selected")]),_c('b-btn',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.onAddUnallocated}},[_vm._v(_vm._s(_vm.addUnallocatedLabel))]),_c('b-btn',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.onCancel}},[_vm._v("Cancel")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }