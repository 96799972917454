<template>
  <div style="height:90%">
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0">Pending Overdue Submissons</h4>
    </div>
    <b-row v-if="group !== null && oneSquare">
      <b-col cols="6">Group</b-col>
      <b-col>{{groupName}}</b-col>
    </b-row>
    <b-row v-if="oneSquare">
      <b-col cols="6">Count</b-col>
      <b-col>{{new Intl.NumberFormat('en-GB', {}).format(submissionCount)}}</b-col>
    </b-row>
    <b-row v-if="oneSquare">
      <b-col cols="6">Value</b-col>
      <b-col>{{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(submissionValue)}}</b-col>
    </b-row>
    <b-row v-if="!oneSquare" class="text-center">
      <b-col v-if="group !== null && !oneSquare">
        <h2>
          <a @click="goToSub(true,false)">{{groupName}}</a>
        </h2>
      </b-col>
      <b-col>
        <div class="pos-relative">
          <h2 :class="ddiStyle">
            <a @click="goToSub(false,false)">{{numberFormatter(submissionCount)}}</a>
          </h2>
        </div>
      </b-col>
      <b-col>
        <div class="pos-relative">
          <h2 :class="ddiStyle">
            <a @click="goToSub(false,false)">£{{numberFormatter(submissionValue)}}</a>
          </h2>
        </div>
      </b-col>
    </b-row>
    <!-- actual values row -->
    <b-row v-if="!oneSquare" class="text-center">
      <b-col v-if="group !== null && !oneSquare">
        <span class="d-block">&nbsp;</span>
      </b-col>
      <b-col>
        <span class="d-block">{{new Intl.NumberFormat('en-GB', {}).format(submissionCount)}}</span>
      </b-col>
      <b-col>
        <span
          class="d-block"
        >{{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(submissionValue)}}</span>
      </b-col>
    </b-row>
    <b-row v-if="!oneSquare" class="text-center">
      <b-col v-if="group !== null && !oneSquare">
        <span class="pb-10 d-block">Group</span>
      </b-col>
      <b-col>
        <span class="d-block">Submission Count</span>
      </b-col>
      <b-col>
        <span class="d-block">Submission Value</span>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from 'axios'
import {mapGetters} from "vuex";
export default {
  name: 'OrphanedSubmissionsWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      maxEscalationLevel: 0,
      submissionCount: 0,
      submissionValue: 0,
      groupName: ''
    }
  },
  methods: {
    groupChanged (e) {
      this.populate()
    },
    async populate () {
      let url = this.group !== null ? `${process.env.VUE_APP_DDMS_API_URL}widget/orphanedsubmissions/${this.group}` : `${process.env.VUE_APP_DDMS_API_URL}widget/orphanedsubmissions`
      var response = await axios.get(url, { params: { paygateId: this.selectedCustomer } })
      if (response && response.data) {
        this.submissionCount = response.data.submissionCount
        this.submissionValue = response.data.submissionValue
        this.maxEscalationLevel = response.data.level
        this.groupName = response.data.groupName
      }
    },
    numberFormatter (num) {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
      }
      return num
    },
    goToSub (allData, auddis) {
      let url = `/collections/submissions/`
      if (this.group !== null && !allData) {
        if (auddis) {
          url = `/collections/submissions/${this.group}/auddis`
        } else {
          url = `/collections/submissions/${this.group}/collection`
        }
      }
      this.$router.push(url)
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    group () {
      return this.widget.options.find(o => o.label === 'GroupId').value
    },
    oneSquare () {
      return this.widget.h === 1 && this.widget.w === 1
    },
    ddiStyle () {
      let retVal = ''
      switch (this.maxEscalationLevel) {
        case 0:
          retVal = 'bigNumber text-success'
          break
        case 1:
          retVal = 'bigNumber text-warning'
          break
        default:
          retVal = 'bigNumber text-danger'
          break
      }
      return retVal
    }
  },
  async mounted () {
    await this.populate()
  }
}
</script>
<style scoped>
.bigNumber {
  font-size: 3rem !important;
  min-width: 80px;
  /*min-height: 80px;*/
  display: inline-block;
}
.orphanFine {
  color: #60bd68;
}
.orphanWarning {
  color: #fbeb00;
}
.orphanDanger {
  color: #f15854;
}
</style>
