<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Assigned USB Tokens
          <help-icon docPath="/administration/usbtokens/" />
          <favourite-icon />
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button
                class="btn btn-danger"
                @click="unassign(props.row.user.userName, props.row.userId)"
              >
                <i class="fa fa-user-slash rpad"></i> Unassign
              </button>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

export default {
  mixins: [tableFilterMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'User Name',
          field: 'user.userName',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Valid From',
          field: 'certificateValidFrom',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
        },
        {
          label: 'Valid To',
          field: 'certificateValidTo',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
        },
        {
          label: 'Token Supplier',
          field: 'tokenSupplier'
        },
        {
          label: 'Token Serial Number',
          field: 'tokenSerialNumber'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false
        }
      ],
      serverParams: {
        sort: [{ field: 'user.userName', type: 'asc' }]
      }
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  methods: {
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}usbtoken`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading USB tokens'
          })
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } finally {
        this.isTableLoading = false
      }
    },
    async unassign (username, userId) {
      try {
        try {
          // Get confirmation from the user that they really want to delete the customer
          var swalResult = await swal.fire({
            title: 'Unassign USB Token',
            text: `Are you sure you want to unassign this token from ${username}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          })

          if (!swalResult.isConfirmed) {
            return
          }
        } catch (error) {
          // Do nothing as this will be if they clicked cancel
          return
        }

        await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}usbtoken/${userId}`,
          { showload: true, showerror: true, errormessage: 'USB token failed to unassign' })
        this.load()
        this.$toastr.s('USB Token was unassigned successfully', 'Success')
      } catch { }
    }
  }
}

</script>
