<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Collections Overview Report</h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group mb-3">
          <div class="col-md-6">
            <div class="row form-group mb-3">
              <div class="col-md-4 required">
                Earliest Date
              </div>
              <div class="col-md-8">
                <div class="right-inner-addon">
                  <datepicker id="endPaymentDatePicker"
                  v-model="start" :disabledDates="{ from: new Date(end) }"
                  :format="formatDate" @input="filtersChanged()" input-class="form-control datepicker"
                  /><i class="fa fa-calendar form-control-feedback"></i>
                </div>
              </div>
            </div>
            <div class="row form-group mb-3">
              <div class="col-md-4 required">
                Latest Date
              </div>
              <div class="col-md-8">
                <div class="right-inner-addon">
                  <datepicker id="startPaymentDatePicker"
                  v-model="end"
                  :format="formatDate" :disabledDates="{ to: new Date(start) }"
                  @input="filtersChanged()" input-class="form-control datepicker"
                  /><i class="fa fa-calendar form-control-feedback"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 vgt-wrap ">
            <table class="vgt-table striped ia-table border-0">
              <thead>
                <tr><th colspan="2">Totals</th></tr>
              </thead>
              <tbody>
              <tr><td>Collected</td><td>{{formatAmount(totalCollected)}}</td></tr>
              <tr><td>Due</td><td>{{formatAmount(totalDue)}}</td></tr>
              <tr><td>Failures</td><td>{{formatAmount(totalFailures)}}</td></tr>
              <tr><td>Indemnities</td><td>{{formatAmount(totalIndemnities)}}</td></tr>
              <tr><td>Not Processed</td><td>{{formatAmount(totalNotProcessed)}}</td></tr>
              </tbody>
            </table>
          </div>
        </div>

        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import Datepicker from 'vuejs-datepicker'
  import papa from 'papaparse'
  import moment from 'moment'
  import {mapGetters} from "vuex";

  export default {
    mixins: [tableFilterMixin, loading],
    components: {
      Datepicker
    },
    data () {
      return {
        dateOptions: {
          format: 'DD/MM/YYYY HH:mm',
          useCurrent: false,
          stepping: 30,
          sideBySide: true,
        },
        end: null,
        start: null,
        totalCollected: 0,
        totalDue: 0,
        totalFailures: 0,
        totalIndemnities: 0,
        totalNotProcessed: 0,
        rows: [],
        columns: [
          {
            label: 'paygateId',
            field: 'paygateId',
            hidden: true
          },
          {
            label: 'Client Name',
            field: 'customerName'
          },
          {
            label: 'Collected (£)',
            field: 'collected',
            formatFn: this.formatAmount
          },
          {
            label: 'Due (£)',
            field: 'due',
            formatFn: this.formatAmount
          },
          {
            label: 'Failures (£)',
            field: 'failures',
            formatFn: this.formatAmount
          },
          {
            label: 'Indemnities (£)',
            field: 'indemnities',
            formatFn: this.formatAmount
          },
          {
            label: 'Not Processed (£)',
            field: 'notProcessed',
            formatFn: this.formatAmount
          },
        ],
        serverParams: {
          sort: [{ field: 'customerName', type: 'asc' }]
        }
      }
    },
    mounted () {
      this.end = new Date()
      let tempStart = new Date()
      tempStart.setDate(this.end.getDate() - 7)
      tempStart.setHours(0,0,0,0)
      this.start = tempStart
      this.load()
    },
    methods: {
      async filtersChanged () {
        await this.clearTableFilters()
      },
      load: async function () {
        try {
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/collectionsoverviewreport`, { params: { ...this.buildGoodTableQuery(), startDate: this.start, endDate: this.end }, validateStatus: () => true, showLoad: true })
          if (response.status === 200) {
            this.rows = response.data.table.data
            this.totalRecords = response.data.table.count
            this.totalCollected = response.data.totalCollected
            this.totalDue = response.data.totalDue
            this.totalFailures = response.data.totalFailures
            this.totalIndemnities = response.data.totalIndemnities
            this.totalNotProcessed = response.data.totalNotProcessed
          } else {
            if (response.status === 403) {
              this.$toastr.e('Not authorized to use this feature.')
              this.rows = []
              this.totalRecords = 0
            } else {
              this.$toastr.e('An error has occurred.')
            }
          }
        } catch (error) {
          this.$toastr.e(error)
        }
      },
      onRowChange (e) {
        // console.log(e)
        // this.$router.push({ path: `/collections/payer/${e.row.ukPayerId}/edit`, params: { openAt: 'messages'} })
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
      formatStatus (value) {
        let retVal = ''
        switch(value) {
          case 1:
            retVal = 'Created'
            break
          case 2:
            retVal = 'In Progress'
            break
          case 3:
            retVal = 'Resolved'
            break
          case 4:
            retVal = 'Rejected'
            break
          case 5:
            retVal = 'Action Required'
            break
          default:
            retVal = 'None'
            break
        }
        return retVal
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      formatDateWithNull (date) {
        if (date===null || date === '0001-01-01T00:00:00') {
          return 'N/A'
        } else {
          return this.formatDate(date)
        }
      },
      async exportTable () {
        var query = this.buildGoodTableQuery()
        var minDate = moment(this.start).format('DD-MM-YYYY')
        var maxDate = moment(this.end).format('DD-MM-YYYY')
        query.perPage = this.totalRecords
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/collectionsoverviewreport`, { params: {
          ...query, paygateId: this.paygateId, startDate: this.start, endDate: this.end, forExport: true }, showload: true })
        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, `overview-report-${minDate}-to-${maxDate}.csv`)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = `overview-report-${minDate}-to-${maxDate}.csv`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      codeToDescription (value) {
        let retval = ''
        switch (value) {
          case 1:
            retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
            break;
          case 2:
            retval = 'NO ADVANCE NOTICE WAS RECEIVED';
            break;
          case 3:
            retval = 'BANK CANCELS THE DIRECT DEBIT';
            break;
          case 4:
            retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
            break;
          case 5:
            retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
            break;
          case 6:
            retval = 'FRAUDULENT SIGNATURE';
            break;
          case 7:
            retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
            break;
          case 8:
            retval = 'SERVICE USER IS NOT RECOGNISED';
            break;
          default:
            retval = 'Invalid Code Supplied'
            break;
        }
        return retval
      },
    },
    computed: {
      ...mapGetters(['selectedCustomer']),
      startDateOptions () {
        return { ...this.dateOptions, maxDate: this.end }
      },
      endDateOptions () {
        return { ...this.dateOptions, minDate: this.start }
      },
    },
    watch: {
      selectedCustomer () {
        this.load()
      }
    }
  }
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
 .amountCell {
   text-align: right;
 }
.matchedCell {
  text-align: center;
}
.ia-table, .ia-table thead th td, .ia-table tr td {
  border-style: none;
}
.ia-table thead th, .ia-table thead th td {
  background: transparent;
  padding: 0;
}

</style>
