import Store from '@/Store/index'
import roles from '@/Assets/Constants/roles'

export default [
  {
    menu_title: 'Search Submissions',
    menu_icon: 'ti-control-play',
    path: '/payments/search/searchSubmissions',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations ])
    }
  },
  {
    menu_title: 'Search Payments',
    menu_icon: 'ti-control-forward',
    path: '/payments/search/searchPayments',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations ])
    }
  },
  {
    menu_title: 'Search Customers',
    menu_icon: 'ti-user',
    path: '/collections/search/searchcustomers',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations, roles.DevOps, roles.Developer, roles.Qa ])
    }
  }
]
