import { render, staticRenderFns } from "./BACSGroupConfig.vue?vue&type=template&id=31aa0076&scoped=true&"
import script from "./BACSGroupConfig.vue?vue&type=script&lang=js&"
export * from "./BACSGroupConfig.vue?vue&type=script&lang=js&"
import style0 from "./BACSGroupConfig.vue?vue&type=style&index=0&id=31aa0076&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31aa0076",
  null
  
)

export default component.exports