<template>
  <div>
    <publicFileBrowser
      v-if="showModalFileBrowser"
      @close="closeBrowser"
      @cancel="showModalFileBrowser = false"
      :defaultFolder="defaultFolder"
      :filesOnly="filesOnly"
    >
      <h3 slot="header">File Browser</h3>
    </publicFileBrowser>
    <div
      class="vue-dropzone dropzone dz-clickable"
      @click="launchBrowser"
      v-if="useServerFileBrowser || useRestrictedFileBrowser"
    >
      <div v-if="selectedFiles.length === 0" class="dz-message">
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">Click to select file</h3>
          <div class="subtitle">Select an uploaded file from the folder</div>
        </div>
      </div>
      <div
        class="dz-preview dz-file-preview dz-processing dz-error dz-complete"
        v-for="(file, index) in selectedFiles"
        :key="index"
      >
        <div class="dz-image">
          <img data-dz-thumbnail />
        </div>
        <div class="dz-details">
          <div class="dz-size"></div>
          <div class="dz-filename">{{ file.fileName }}</div>
        </div>
        <a
          class="dz-remove"
          v-if="showDeleteButtons"
          @click.stop="removeFile(index)"
          data-dz-remove
        >Remove file</a>
      </div>
    </div>
    <vue-dropzone
      id="drop1"
      ref="fileUploader"
      :url="dropzoneUrl"
      :options="dropzoneOptions"
      @vdropzone-success="afterSuccess"
      @vdropzone-file-added="fileAdded"
      @vdropzone-removed-file="fileRemoved"
      @vdropzone-sending="sendingEvent"
      :use-custom-slot="true"
      :accepted-file-types="dropzoneOptions.acceptedFiles"
      :max-file-size-in-m-b="200"
      :useCustomDropzoneOptions="true"
      :use-custom-drop-zone-options="true"
      :useCustomSlot="true"
      v-if="useDropzone"
    >
      <div class="dropzone-custom-content">
        <h3 class="dropzone-custom-title">{{dropzoneOptions.title}}</h3>
        <div class="subtitle">{{dropzoneOptions.subtitle}}</div>
      </div>
    </vue-dropzone>
    <plugin></plugin>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import publicFileBrowser from '@/Components/Shared/PublicFileBrowser.vue'
import Plugin from '@/Assets/Components/UsbTokens/Plugin'
import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
import axios from 'axios'
export default {
  data () {
    return {
      selectedFiles: [],
      showModalFileBrowser: false,
      rights: null,
      bearerToken: null,
      expectedVersion: '1'
    }
  },
  props: {
    showDeleteButtons: {
      type: Boolean,
      default: true
    },
    groupId: {
      type: String,
      default: '00000000-0000-0000-0000-000000000000'
    },
    dropzoneOptions: {
      type: Object
    },
    dropzoneUrl: { type: String },
    secureUrl: { type: String },
    filesOnly: { type: Boolean, default: true }
  },
  components: {
    publicFileBrowser,
    plugin: Plugin
  },
  methods: {
    launchBrowser () {
      if (this.useServerFileBrowser) {
        this.showModalFileBrowser = true
      }
      if (this.useRestrictedFileBrowser) {
        this.showRestrictedFileBrowser()
      }
    },
    closeBrowser (e) {
      const path = e
      const fileName = path.split('/')[path.split('/').length - 1]
      const fileObj = { type: this.rights.uploadType, path, fileName }
      this.selectedFiles.push(fileObj)
      console.log(this.selectedFiles)
      this.showModalFileBrowser = false
      this.$emit('fileAdded', fileObj)
    },
    removeAllFiles () {
      this.selectedFiles = []
      this.$refs.fileUploader.removeAllFiles()
    },
    removeFile (index) {
      var deletedFile = this.selectedFiles[index]
      this.selectedFiles.splice(index, 1)
      this.$emit('fileRemoved', deletedFile)
    },
    async loadRights () {
      const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${this.groupId}`)
      var group = response.data.groupUserRights
      this.rights = group.find(x => x.userId === this.userId)
      if(this.rights === undefined || this.rights === null) {
        this.rights = {
          uploadType: 'Browser Dialog'
        }
      }
    },
    fileAdded (file) {
      this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
    },
    fileRemoved (file, error, xhr) {
      this.$emit('fileRemoved', file)
    },
    sendingEvent (file, xhr, formData) {
      formData.append('paygateId', this.paygateId)
      formData.append('groupId', this.groupId)
    },
    afterSuccess (file, response) {
      response = { uploadResponse: response, type: this.rights.uploadType, ...file }
      this.$emit('fileAdded', response)
    },
    showRestrictedFileBrowser () {
      const browser = this.getBrowser()
      if (browser === 'IE') {
        this.expectedVersion = '2' // Active X plugin for IE.
      } else {
        this.expectedVersion = '1' // Native messaging plugin for Chrome and Firefox
      }
      if (browser === 'Firefox') {
        this.showRestrictedFileBrowserFirefox()
      } else if (browser === 'Chrome') {
        this.showRestrictedFileBrowserChome()
      } else if (browser === 'IE') {
        this.showRestrictedFileBrowserIE()
      }
    },
    showRestrictedFileBrowserChome () {
      console.log(this.secureUrl)
      // console.log('showRestrictedFileBrowserChrome')
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'UploadFromRestrictedFolder', StartDirectory: this.rights.uploadPath !== null ? this.rights.uploadPath : '', DirectoryLocked: this.rights.directoryLocked, BearerToken: this.bearerToken, Url: this.secureUrl }, (response) => {
          // console.log('Chrome plugin response: ' + response.response)
          var pluginResponse = common.parsePluginResponse(response.response)
          if (pluginResponse.SelectedFileName !== null) {
            var fileBrowserResponse = { submissionId: pluginResponse.SubmissionId, filename: pluginResponse.SelectedFileName }
            this.addFile(fileBrowserResponse)
          } else if (pluginResponse.ErrorMessage !== null) {
            this.$toastr.e(pluginResponse.ErrorMessage)
          }
        })
        resolve()
      })
    },
    showRestrictedFileBrowserFirefox () {
      this.$store.dispatch('setWebExtPluginResponse', null)
      return new Promise((resolve, reject) => {
        document.addEventListener('getPluginResponseWebPage', (response) => {
          var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
          if (pluginResponse.SelectedFileName !== null) {
            var fileBrowserResponse = { submissionId: pluginResponse.SubmissionId, filename: pluginResponse.SelectedFileName }
            this.addFile(fileBrowserResponse)
            resolve()
          } else {
            reject(new Error(pluginResponse.ErrorMessage))
          }
        }, { once: true })

        var pluginEvent = new CustomEvent('clientCallPlugin', { detail: { PluginTask: 'UploadFromRestrictedFolder', StartDirectory: this.createUser.uploadPath, DirectoryLocked: this.createUser.directoryLocked, BearerToken: this.bearerToken, Url: this.secureUrl } })
        document.dispatchEvent(pluginEvent)
      })
    },
    showRestrictedFileBrowserIE () {
      // console.log('showRestrictedFileBrowserIE')
      this.plugin = document.PayGatePlugin
      var result = this.plugin.UploadFromRestrictedFolder(this.rights.uploadPath, this.rights.directoryLocked, this.bearerToken, this.secureUrl, 1000)
      if (result.indexOf('Error') === -1) {
        var i = result.indexOf('_')
        var submissionId = result.substring(0, i)
        var filename = result.substring(i + 1)
        var response = { submissionId: submissionId, filename: filename }
        // this.afterSuccess(null, response)
        this.addFile(response)
      } else {
        this.$toastr.e(result)
      }
    },
    getBrowser () {
      var browser = ''
      if (navigator.userAgent.indexOf('Edge') !== -1) {
        browser = 'Edge'
      } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
        browser = 'Chrome'
      } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
        browser = 'Firefox'
      } else if ((navigator.userAgent.indexOf('MSIE') !== -1) || (!!document.documentMode === true)) { // IF IE > 10
        browser = 'IE'
      }
      return browser
    },
    addFile (file) {
      const fileObj = { type: this.rights.uploadType, path: '', fileName: file.filename, id: file.submissionId }
      this.selectedFiles.push(fileObj)
      this.$emit('fileAdded', fileObj)
    }
  },
  async mounted () {
    this.loadRights()
    if (this.$refs.fileUploader) {
      this.$refs.fileUploader.removeAllFiles()
    }
    auth.getAccessToken().then(token => { this.bearerToken = 'Bearer ' + token })
  },
  watch: {
    async groupId () {
      await this.loadRights()
      if (this.useRestrictedFileBrowser) {
        var extLoaded = this.$route.query.pluginAccessible
        if (extLoaded === 'true') {
          if (this.browser === 'Firefox') {
            document.addEventListener('getExtensionResponseWebPage', this.initExtensionEvent)
            document.addEventListener('getPluginResponseWebPage', this.initPluginEvent)
          }
          this.pluginCheckComplete = true
          this.selectedGroup = this.$store.getters.selectedGroup
          if (this.selectedGroup !== null && this.selectedGroup !== undefined && this.selectedGroup.groupId !== undefined) {
            this.selectGroup()
          }
        }
        await this.$store.dispatch('checkPlugin')
      }
    }
  },
  computed: {
    userId () {
      return this.$store.getters.getClaim('sub').value
    },
    useRestrictedFileBrowser () {
      if (this.rights) {
        return this.rights.uploadType === 'Restricted File Browser'
      }
      return false
    },
    useServerFileBrowser () {
      if (this.rights) {
        return this.rights.uploadType === 'Secure Cloud Folder'
      }
      return false
    },
    useDropzone () {
      if (this.rights) {
        return this.rights.uploadType === 'Browser Dialog'
      }
      return true
    },
    defaultFolder () {
      if (this.rights) {
        return this.rights.uploadPath
      }
      return ''
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    }
  }
}
</script>
<style scoped>
a.dz-remove:hover,
a.dz-remove:active,
a.dz-remove:visited {
  color: #ffffff;
}
</style>
