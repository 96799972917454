<template>
    <div class="template-container">
        <div  v-if="value.title === undefined"><strong v-b-popover.hover.top="subtitle">{{ title }}</strong>
        </div>
        <b-row v-else class="mt-2">
          <b-col sm="2" class="mt-2" cols="2">
            <label class="font-weight-bold">Title</label>
          </b-col>
          <b-col cols="7" xl="6" class="mt-2">
            <b-input-group>
              <b-form-input  v-model="value.title" />
            </b-input-group>
          </b-col>
        </b-row>

      <b-row class="mt-2" v-if="value.emailEnabled">
        <b-col sm="2" class="mt-2" cols="2">
          <label>Email</label>
        </b-col>
        <b-col cols="7" xl="6" class="mt-2">
          <b-input-group>
            <b-form-select :disabled="disabled" v-model="value.emailTemplate" :options="allowDefaults ? templateOptions.emails : templateOptions.emails.filter(x=>x.value !== null)" />

          </b-input-group><small class="text-danger" v-if="needsEmail">Template is required</small>
        </b-col>
        <b-col v-if="value.emailTemplate !== null && value.emailTemplate !== undefined">
          &nbsp;<router-link :to="{ name: 'SpecificEmailTemplate', params: { templateid : value.emailTemplate } }" class="btn btn-primary mt-2 buttonWidthFix" v-if="value.emailTemplate">Edit</router-link>
          <b-btn class="mt-2" variant="primary" disabled v-else>Edit</b-btn>
        </b-col>
        <b-col v-else-if="allowDefaults">
          <router-link :to="`/collections/defaultmessage/0/${messageType}`" class="btn btn-primary mt-2 buttonWidthFix">View</router-link>
        </b-col>
      </b-row>
         <b-row class="mt-2" v-if="value.letterEnabled && lettersAllowed">
          <b-col sm="2" class="mt-2" cols="2">
            <label>Letter</label>
          </b-col>
          <b-col cols="7" xl="6" class="mt-2">
            <b-input-group>
              <b-form-select :disabled="disabled" v-model="value.letterTemplate" :options="allowDefaults ? templateOptions.letters : templateOptions.letters.filter(x=>x.value !== null)" />

              </b-input-group><small class="text-danger" v-if="needsLetter">Template is required</small>
          </b-col>
          <b-col v-if="value.letterTemplate !== null && value.letterTemplate !== undefined">
             &nbsp;<router-link :to="{ name: 'SpecificLetterTemplate', params: { templateid : value.letterTemplate } }" class="btn btn-primary mt-2 buttonWidthFix" v-if="value.letterTemplate">Edit</router-link>
             <b-btn class="mt-2" variant="primary" disabled v-else>Edit</b-btn>
          </b-col>
          <b-col v-else-if="allowDefaults">
            <router-link :to="`/collections/defaultmessage/1/${messageType}`" class="btn btn-primary mt-2 buttonWidthFix">View</router-link>
          </b-col>
        </b-row>

         <b-row class="mt-2" v-if="value.smsEnabled && smsAllowed">
          <b-col class="mt-2" cols="2">
            <label>SMS</label>
          </b-col>
          <b-col cols="7" xl="6" class="mt-2">
            <b-input-group>
              <b-form-select :disabled="disabled" v-model="value.smsTemplate" :options="allowDefaults ? templateOptions.sms : templateOptions.sms.filter(x=>x.value !== null)" />

            </b-input-group><small class="text-danger" v-if="needsSms">Template is required</small>
          </b-col>
          <b-col v-if="value.smsTemplate !== null && value.smsTemplate !== undefined">
            &nbsp;<router-link :to="{ name: 'SpecificSmsTemplate', params: { templateid : value.smsTemplate } }" class="btn btn-primary mt-2 buttonWidthFix" v-if="value.smsTemplate">Edit</router-link>
          <b-btn class="mt-2" variant="primary" disabled v-else>Edit</b-btn>
          </b-col>
          <b-col v-else-if="allowDefaults">
            <router-link :to="`/collections/defaultmessage/2/${messageType}`" class="btn btn-primary mt-2 buttonWidthFix">View</router-link>
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="value.title !== undefined">
          <b-col>
              <b-btn class="mt-2" variant="danger" @click="deleteMessageType"><i class="fa fa-trash mr-2"></i>Delete</b-btn>
          </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default () {
        return {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: '00000000-0000-0000-0000-000000000000',
          emailTemplate: '00000000-0000-0000-0000-000000000000',
          letterTemplate: '00000000-0000-0000-0000-000000000000',
          custom: false,
          title: 'Default Title'
        }
      }
    },
    disabled: { type: Boolean, default: true },
    title: String,
    subtitle: String,
    templateOptions: Object,
    messageType: { type: Number, default: 0 },
    allowDefaults:  { type: Boolean, default: true }
  },
  methods: {
    previewSms () {
      this.$swal('SMS Template Preview', 'Load thumbnail here.')
    },
    previewEmail () {
      this.$swal('Email Template Preview', 'Load thumbnail here.')
    },
    previewLetter () {
      this.$swal('Letter Template Preview', 'Load thumbnail here.')
    },
    deleteMessageType () {
      this.$emit('deleteClicked', this.value.customGroupTemplateId)
    }
  },
  computed: {
    lettersAllowed (){
      let availMessageChannels = this.$store.getters.messageChannels
      return availMessageChannels.includes(1)
    },
    smsAllowed () {
      return parseInt(process.env.VUE_APP_VERSION) >= 120
    },
    needsSms () {
      return this.value.smsTemplate === '00000000-0000-0000-0000-000000000000'
    },
    needsLetter () {
      return this.value.letterTemplate === '00000000-0000-0000-0000-000000000000'
    },
    needsEmail () {
      return this.value.emailTemplate === '00000000-0000-0000-0000-000000000000'
    }
  }
}
</script>

<style>
  .template-container {
    padding: 1em 0.4em 0.4em 0.4em;
    margin: 1em;
  }
  .buttonWidthFix {
    width: 3.8rem;
  }
</style>
