<template>
  <div>
    <fileBrowser v-if="showModalFileBrowser" @close="showModalFileBrowser = false; closePopup()"></fileBrowser>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2 v-if="isNew">
              <span class="pull-right">
                <a
                  :href="`${docUrl}/automation/paygate-mapping/importers/iso20022importer/`"
                  target="_blank"
                >
                  <i class="far fa-question-circle"></i>
                </a>
              </span> Create an ISO20022 importer
            </h2>
            <h2 v-else>
              <span class="pull-right">
                <a
                  :href="`${docUrl}/automation/paygate-mapping/importers/iso20022importer/`"
                  target="_blank"
                >
                  <i class="far fa-question-circle"></i>
                </a>
              </span> Edit an ISO20022 importer
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row">
              <div class="col-md-2 col-sm-12">
                <label
                  class="required"
                  v-b-popover.hover.top.d500="'The title of the importer.'"
                >Title</label>
              </div>
              <div class="form-group col-md-10 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  :class="{invalid: $v.importer.title.$error}"
                  @input="$v.importer.title.$touch()"
                  v-model="importer.title"
                />
                <p
                  class="validationText"
                  v-if="!$v.importer.title.required && $v.importer.title.$dirty "
                >The title field is required!</p>
                <p
                  class="validationText"
                  v-if="!$v.importer.title.maxLength"
                >Maximum length is {{ $v.importer.title.$params.maxLength.max }} characters!</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 col-sm-12">
                <label
                  v-b-popover.hover.top.d500="'An optional text field for you to write a discription of the importer.' "
                >Description</label>
              </div>
              <div class="form-group col-md-10 col-sm-12">
                <textarea
                  class="form-control"
                  rows="4"
                  cols="60"
                  @input="$emit('textarea',$event.target.value)"
                  @blur="$v.importer.description.$touch()"
                  v-model="importer.description"
                ></textarea>
                <p
                  class="validationText"
                  v-if="!$v.importer.description.maxLength"
                >Maximum length is {{ $v.importer.description.$params.maxLength.max }} characters!</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 col-sm-12">
                <label
                  class
                  v-b-popover.hover.top.d500="'The file path that PayGate will use when this mapping is run to locate the file.'"
                >File Location</label>
              </div>
              <div class="col-md-10 col-sm-12 mb-3">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :class="{invalid: $v.importer.filePath.$error}"
                    @input="$v.importer.filePath.$touch()"
                    v-model="importer.filePath"
                  />
                  <div class="input-group-append">
                    <b-button @click.prevent="btnFileBrowser" variant="outline-secondary">...</b-button>
                  </div>
                </div>
                <!-- <p class="validationText" v-if="!$v.importer.filePath.required && $v.importer.filePath.$dirty ">The folder path is required!</p> -->
                <p
                  class="validationText"
                  v-if="!$v.importer.filePath.maxLength"
                >Maximum length is {{ $v.importer.filePath.$params.maxLength.max }} characters!</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 col-sm-12">
                <label
                  class="required"
                  v-b-popover.hover.top.d500="'Sets the data type used for auto-mapping.'"
                >Data Type</label>
              </div>
              <div class="form-group col-md-4 col-sm-12">
                <select v-model="selectedDataType" class="form-control">
                  <option
                    v-for="option in dataTypeMode"
                    :key="option.id"
                    :value="option.value"
                  >{{ option.text }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="idb-block">
          <div class="idb-block-title" v-if="!isNew">
            <div class="row">
              <span class="col-md-12 col-sm-12">
                <strong>Mappings using this importer</strong>
                <span
                  v-if="boundMappings.length > 0"
                  class="badge badge-pill badge-primary ml-2"
                >{{ boundMappings.length }}</span>
                <b-button
                  @click.prevent="getBoundMappings"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Refresh the list of bound mappings.'"
                >
                  <i class="fas fa-sync-alt pointer"></i>
                </b-button>
              </span>
            </div>
          </div>
          <div class="idb-block-content">
            <div v-if="boundMappings.length > 0">
              <div class="row" v-for="m in boundMappings" :key="m.index" :value="m.value">
                <div class="col-md-12 ml-5">
                  {{m.title}} (
                  <router-link
                    tag="a"
                    :to="'/automation/mapping/mappingdetail/' + m._id"
                    class="small"
                  >Edit</router-link>)
                </div>
              </div>
            </div>
            <div v-else>
              <span v-if="!isNew">None</span>
            </div>
            <br />
          </div>
          <div class="idb-block-footer">
            <div class="row">
              <div class="col-md-12 col-sm-3">
                <b-button
                  :disabled="isLoading"
                  class="mr-3"
                  v-if="isNew"
                  @click.prevent="createImporter"
                  variant="primary"
                  v-b-popover.hover.top.d500="'Creates and saves a new importer.'"
                >Create</b-button>
                <b-button
                  :disabled="isLoading"
                  class="mr-3"
                  v-else
                  @click.prevent="updateImporter"
                  variant="primary"
                  v-b-popover.hover.top.d500="'Update the current importer saving any changes.'"
                >
                  <i class="fa fa-edit mr-2"></i>Save
                </b-button>
                <b-button
                  :disabled="isLoading"
                  class="mr-3"
                  v-if="!isNew"
                  @click.prevent="cloneImporter"
                  variant="outline-primary"
                  v-b-popover.hover.top.d500="'Create a clone of this importer'"
                >Clone</b-button>
                <b-button
                  :disabled="isLoading"
                  v-if="!isNew && boundMappings.length === 0"
                  @click.prevent="deleteImporter"
                  variant="danger"
                  class="pull-right"
                  v-b-popover.hover.top.d500="'Permanently delete this importer.'"
                >
                  <i class="fa fa-trash mr-2"></i>Delete Importer
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import { required, maxLength, minValue, maxValue, integer } from 'vuelidate/lib/validators'
import vSelect from 'vue-select'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser'
import colours from '@/Assets/Constants/colours'
import loading from '@/Assets/Mixins/LoadingMixin'
import swal from 'sweetalert2'
export default {
  components: {
    'v-select': vSelect,
    fileBrowser,
    swal
  },
  mixins: [loading],
  validations: {
    importer: {
      title: {
        required,
        maxLength: maxLength(48)
      },
      description: {
        maxLength: maxLength(1024)
      },
      filePath: {
        maxLength: maxLength(512)
      }
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      boundMappings: [],
      showModalFileBrowser: false,
      isNew: true,
      id: this.$route.params.id,
      importer: {
        parserParams: {
          ignoreFirstChars: '0',
          ignoreLastChars: '0'
        },
        configuration: []
      },
      authToken: '',
      selectedImporterType: 'iso20022',
      selectedDataType: 'bacsPayments',
      dataTypeMode: [
        { text: 'BACS/FPS Payments (pain.001.01A.07)', value: 'bacsPayments', id: 0 }
        // { text: 'Bureau Customers', value: 'bacsBureauCustomers, id: 1' },
        // { text: 'UK DDMS Payers', value: 'ukDdmsPayers', id: 2 },
        // { text: 'UK DDMS Variable Update', value: 'ukDdmsVar', id: 3 }
      ]
    }
  },
  methods: {
    async getBoundMappings () {
      this.boundMappings = []
      try {
        const response = await axios
          .get(`${process.env.VUE_APP_WORKFLOW_API_URL}boundMappings/${this.id}`, { showload: true })
        this.boundMappings = response.data.mappings
      } catch (e) {
        console.log(e)
      }
    },
    truncate (text) {
      if (text.length < 20) {
        return text
      } else {
        return `${text.substring(0, 19)}...`
      }
    },
    async createImporter () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.importer.dataType = this.selectedDataType

      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}importer`, this.importer, { showload: true })
        if (res) {
          this.$toastr.s(`The importer '${this.importer.title}' was successfully created.`)
          this.$router.push('/automation/mapping/import/importers')
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.errorMessage) {
          this.$snapbar.e(`Error creating importer.  The importer was not created. ${e.response.data.errorMessage}`)
        } else {
          this.$snapbar.e('Error creating importer.  The importer was not created.')
        }
      }
    },
    getDataTypeText (selected) {
      const item = this.dataTypeMode.find(x => x.value === selected)
      return item.text
    },
    async updateImporter () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        console.log('$v Invalid')
        console.log(this.$v)
        return
      }
      this.importer.dataType = this.selectedDataType

      let res
      try {
        res = await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}importer/${this.importer._id}`, this.importer, { showload: true })
        if (res) {
          this.$toastr.s(`The importer '${this.importer.title}' was successfully updated.`)
          this.$router.push('/automation/mapping/import/importers')
        }
      } catch (e) {
        this.$snapbar.e(`Update error (MP0017i) - ${e.message}`)
      }
    },
    async cloneImporter () {
      // TODO: Ensure form validate before proceeding
      // Other you can get a mongoose validation error that is returned as a 500
      const result = await swal.fire({
        title: 'Clone Importer',
        text: 'Create an exact copy of the current importer.',
        type: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No',
        input: 'text',
        inputValue: `${this.importer.title} (clone)`
      })
      if (result.isConfirmed) {
        if (result.value) {
          if (!this.importer.configuration) {
            this.importer.configuration = {}
          }
          const cloneImporter = {
            title: result.value,
            description: this.importer.description,
            dataType: this.importer.dataType,
            filePath: this.importer.filePath,
            importerType: this.importer.importerType,
            paygateId: this.importer.paygateId,
            configuration: this.importer.configuration
          }

          let res
          try {
            res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}importer`, cloneImporter, { showload: true })
            if (res) {
              this.$toastr.s(`The importer '${cloneImporter}' was successfully cloned.`)
              this.$router.push('/automation/mapping/import/importers')
            }
          } catch (e) {
            if (e.response && e.response.data && e.response.data.errorMessage) {
              this.$snapbar.e(`Error cloning importer.  The importer was not cloned. ${e.response.data.errorMessage}`)
            } else {
              this.$snapbar.e('Error cloning importer.  The importer was not cloned.')
            }
          }
        }
      }
    },
    async deleteImporter () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const result = await swal.fire({
        title: 'Are you sure you want to delete this importer?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}importer/${this.id}`, { showload: true })
          if (res) {
            this.$toastr.s(`The importer '${this.importer.title}' was successfully deleted.`)
            this.$router.push('/automation/mapping/import/importers')
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this Importer.')
          } else {
            this.$snapbar.e(`Could not delete the importer - ${e.message}`)
          }
        }
      }
    },
    btnFileBrowser () {
      // TODO: File Browser
      this.$store.commit('setModalPopupString', this.importer.filePath)
      this.showModalFileBrowser = true
    },
    closePopup () {
      this.importer.filePath = this.$store.state.mapping.modalPopupString
    },
    btnRemoveSubstitutionRule (index) {
      this.substitutionRules.splice(index, 1)
    },
    btnAddSubstitutionRule () {
      const newRule = {
        enabled: true,
        from: '',
        to: ''
      }
      this.substitutionRules.push(newRule)
    },
    defaultImporter () {
      return {
        title: '',
        description: '',
        filePath: '\\',
        importerType: 'iso20022',
        dataType: this.selectedDataType,
        paygateId: this.paygateId,
        configuration: []
      }
    },
    async loadImporter () {
      this.$snapbar.hide()
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}importer/${this.id}`, { showload: true })
        if (res) {
          if (res.data.importer) {
            this.importer = res.data.importer
            // Default to avoid breaking changes

            this.selectedDataType = this.importer.dataType

            this.getBoundMappings()
            console.log(res.data)
          } else {
            this.importer = this.defaultImporter()
            this.$snapbar.w('Unknown or Invalid importer')
          }
        }
      } catch (e) {
        this.importer = this.defaultImporter()
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view this Importer.')
        } else {
          this.$snapbar.e(`Could not get display importers - ${e.message}`)
        }
      }
    }
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      this.loadImporter()
    }
  },
  created: async function () {
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    this.importer = this.defaultImporter()

    if (!this.id) {
      this.isNew = true
    } else {
      this.isNew = false
      // get importer from db
      this.loadImporter()
    }
  }
}
</script>

<style scoped>
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: ns-resize;
}

.fadein-enter {
  opacity: 0;
}

.fadein-enter-active {
  transition: opacity 0.6s;
}

.fadein-leave-active {
  transition: opacity 0.6s;
  opacity: 0;
}

.invalid {
  border-color: red;
  border-width: 2px;
}
label.required {
  /* $gray-700:  */
  color: #878787;
}
label.required::after {
  content: " *";
  color: red;
}
.top-buffer {
  margin-top: 20px;
}

.subtitle {
  color: #314b5f;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:visited {
  background-color: #c3164f !important;
}
.fadein-enter {
  opacity: 0;
}

.fadein-enter-active {
  transition: opacity 0.6s;
}

.fadein-leave-active {
  transition: opacity 0.6s;
  opacity: 0;
}
</style>
