<template>
  <div id="importedPayments">
    <div v-if="this.fileBlocks > 1" class="row form-group">
      <div class="col-md-2">
        <b>Select File</b>
      </div>
      <div class="col-md-6">
        <vue-select
          v-model="selected"
          :options="fileDetails"
          :closeOnSelect="true"
          :clearable="false"
          label="serviceUserNumber"
          @option:selected="fileDropdownSelected()">
        </vue-select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-12">
        <vue-good-table
          ref="payments"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
          @on-column-filter="onColumnFilter"
          @on-row-mouseenter="onRowMouseover"
          @on-row-mouseleave="onRowMouseleave"
          :columns="columns"
          :rows="rows"
          :totalRows="totalRecords"
          :paginationOptions="paginationOptions"
          styleClass="vgt-table striped bordered"
          :row-style-class="rowStyleClassFn"
        >
          <div slot="table-actions">
            <b-button
              @click.prevent="createPaymentCsvFile"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Export payments to CSV'"
            >
              <i class="fas fa-file-export pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="refreshGrid"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'">
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import VueSelect from 'vue-select'

export default {
  name: 'submissionPayments',
  components: {
    VueSelect
  },
  mixins: [
    bacsMixin
  ],
  props: ['selectedFileNumber', 'selectedRecordNumber'],
  computed: {
    subParamsRoutePath () {
      // const routePath = `${this.$route.path}_payments_${this.submissionId}`
      // console.log('routePath: ' + routePath)
      return `${this.$route.path}_payments_${this.submissionId}`
    }
  },
  data () {
    return {
      submissionId: '',
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'FNo.',
          field: 'fileNumber',
          type: 'number',
          hidden: true
        },
        {
          label: 'RNo.',
          field: 'recordNumber',
          type: 'number',
          hidden: true
        },
        {
          label: 'Client',
          field: 'customerName',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Third Party Name',
          field: 'thirdPartyAccountName',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          },
          tdClass: 'subPaymentsPagepointer'
        },
        {
          label: 'Sort Code',
          field: 'thirdPartySortCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          },
          tdClass: 'subPaymentsPagepointer'
        },
        {
          label: 'Account Number',
          field: 'thirdPartyAccountNumber',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          },
          tdClass: 'subPaymentsPagepointer'
        },
        {
          label: 'User Reference',
          field: 'userReference',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          },
          tdClass: 'subPaymentsPagepointer'
        },
        {
          label: 'Transaction Code',
          field: 'transactionCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          },
          tdClass: 'subPaymentsPagepointer'
        },
        {
          label: 'Amount (£)',
          field: 'amountText',
          thClass: 'text-right',
          tdClass: 'text-right subPaymentsPagepointer',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Processing Date',
          field: 'displayProcessingDate',
          hidden: true
        },
        {
          label: 'RTI',
          field: 'freeFormat',
          hidden: true
        },
        {
          label: 'Message',
          field: 'message',
          hidden: true
        }
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        submissionId: '',
        showPresubValMessages: this.showPresubValMessages,
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '' // 'asc' or 'desc'
        },
        fileNumber: 1,
        page: 1, // what page I want to show
        perPage: 10 // how many items I'm showing per page
      },
      defaultParams: {},
      editAction: false,
      fileNumber: 1,
      recordNumber: 0,
      fileBlocks: 0,
      fileDetails: {},
      paginationOptions: {
        enabled: true,
        perPage: 10,
        setCurrentPage: 1,
        dropdownAllowAll: false,
        jumpFirstOrLast: true
      },
      selected: {}, 
      paymentsLoaded: false,
      restartSearch: true
    }
  },
  methods: {
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
      // console.log('updateParams: ' + JSON.stringify(this.serverParams))
      // this.loadItems()
    },

    onPageChange (params) {
      // console.log('onPageChange')
      this.restartSearch = false
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange (params) {
      // console.log('onPerPageChange')
      this.restartSearch = true
      // This method is called when page mounted so only want to load items AFTER payments have been loaded and items per page changed by the user.
      if (this.paymentsLoaded === true) {
        this.rows.length = 0
        this.updateParams({ perPage: params.currentPerPage })
        this.loadItems()
      }
    },

    onSortChange (params) {
      // console.log('onSortChange')
      this.restartSearch = true
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type
        }
      })
      this.loadItems()
    },

    onColumnFilter (params) {
      // console.log('onColumnFilter')
      // this.appliedFilter = true
      this.restartSearch = true
      this.updateParams(params)
      this.loadItems()
    },

    onRowMouseover (params) {
    },

    onRowMouseleave (params) {
    },

    rowStyleClassFn (row) {
      if (this.recordNumber > 0 && row.recordNumber === this.recordNumber) {
        return 'hightlightedRow'
      }
    },

    async refreshGrid () {
      // console.log('refreshGrid')
      await this.resetTable()
      await this.loadItems()
    },

    async resetTable () {
      // console.log('resetTable')
      this.$refs.payments.currentPerPage = this.defaultParams.perPage
      this.$refs.payments.reset()

      this.paginationOptions.perPage = this.defaultParams.perPage
      this.$refs.payments.$refs.paginationBottom.perPage = this.defaultParams.perPage
      this.$refs.payments.$refs.paginationBottom.handlePerPage()

      // Set page
      this.paginationOptions.setCurrentPage = this.defaultParams.page

      this.savedParamsToObjects(this.defaultParams)
      this.$nextTick(() => {
        this.updateParams(this.defaultParams)
      })
      this.restartSearch = true
    },

    async savedParamsToObjects (savedParams) {
      // Update the sort options, might not need to do this as the changes _should_ call the event handlers
      this.sortOptions = { ...this.sortOptions, initialSortBy: savedParams.sort }

      // Get the field names from the column filters
      const columnnFilterKeys = Object.keys(savedParams.columnFilters)
      for (var i = 0; i < columnnFilterKeys.length; i++) {
        const filterKey = columnnFilterKeys[i]
        // Find the column in the list
        const column = this.columns.find(c => c.field === filterKey)
        // Apply the filter
        column.filterOptions.filterValue = savedParams.columnFilters[filterKey]
      }
      // Set per page
      this.paginationOptions.perPage = savedParams.perPage
      if (this.$refs.payments) {
        this.$refs.payments.$refs.paginationBottom.perPage = savedParams.perPage
        this.$refs.payments.$refs.paginationBottom.handlePerPage()
      }
      // Set page
      this.paginationOptions.setCurrentPage = savedParams.page
      // console.log('currentpage: ' + this.paginationOptions.setCurrentPage)
    },

    async loadItems () {
      // console.log('loadItems restartSearch: ' + this.restartSearch)
      this.paymentsLoaded = true

      var json = JSON.stringify({
        submissionId: this.submissionId,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        showPresubValMessages: this.showPresubValMessages,
        fileNumber: this.fileNumber,
        sortColumn: this.serverParams.sort.field,
        sortDirection: this.serverParams.sort.type,
        resetStart: this.restartSearch,
        columnFilters: this.serverParams.columnFilters,
        editAction: this.editAction,
        paymentsWithPresubValIssues: this.paymentsWithPresubValIssues
      })

      var response = await axios.post(`${process.env.VUE_APP_BACS_API_URL}bacs/payment/get/`, json, { showload: true })
      this.rows = response.data.payments
      if (this.rows.length > 0) {
        if (response.data.filtersSet === 0) {
          this.totalRecords = this.fileDetails[this.fileNumber - 1].totalRecordCount
        } else {
          this.totalRecords = response.data.filteredRecordCount
        }
      }
      this.serverParams.fileNumber = this.fileNumber
      this.$store.commit('updateTable', { name: this.subParamsRoutePath, filters: this.serverParams })
    },

    async getFileDetails () {
      // console.log('getFileDetails')
      if (this.submissionId !== undefined) {
        var url = `${process.env.VUE_APP_BACS_API_URL}bacs/submission/getBacsIntelligibleSummaries?submissionId=${this.submissionId}`
        var response = await axios.get(url, { showload: true })
        this.fileDetails = response.data
        this.fileBlocks = this.fileDetails.length
      }
    },

    async setFileDetails () {
      // console.log('setFileDetails')
      if (this.fileBlocks > 0) {
        this.selected = this.fileDetails.find(i => i.fileNumber === this.fileNumber)
        this.selectFileDetail()
        // if (this.recordNumber > 0) {
        //   this.selected = this.fileDetails.find(i => i.fileNumber === this.fileNumber)
        //   this.selectFileDetail()
        // } else {
        //   this.selected = this.fileDetails[0]
        //   this.selectFileDetail()
        // }
      }
    },

    async fileDropdownSelected () {
      // console.log('fileDropdownSelected')
      await this.resetTable()
      this.restartSearch = true
      await this.selectFileDetail()
    },

    async selectFileDetail () {
      // console.log('selectFileDetail')
      // this.recordNumber = 0
      this.serviceUserNumber = this.selected.serviceUserNumber
      this.fileNumber = this.selected.fileNumber
      this.selectedFile = this.selected.displayName
      // console.log('serverParams.page: ' + this.serverParams.page)
      let initialPageNumber = this.serverParams.page

      if (this.restartSearch) {
        this.serverParams.page = 1
        this.serverParams.columnFilters = {}
        initialPageNumber = 1
      }

      // Navigate to correct page when user selects a payment via a presub val message.
      if (this.recordNumber > 0) {
        initialPageNumber = Math.floor(this.recordNumber / this.serverParams.perPage)
        if (this.recordNumber % this.serverParams.perPage > 0) {
          initialPageNumber++
        }

        this.serverParams.page = initialPageNumber
        this.restartSearch = false
      }

      // Using "setCurrentPage" didn't believe that the variable initialPageNumber was a number so fudge the issue by multiplying it by 1.
      // This is flaky as fuck. One minute the current page is set correctly, the next it isn't.
      // Only seems to work if dropdownAllowAll = true which doesn't make sense.
      // const initPage = this.serverParams.page * 1
      // this.paginationOptions = { enabled: true, perPage: 10, setCurrentPage: this.serverParams.page, dropdownAllowAll: false, jumpFirstOrLast: true }
      this.paginationOptions = { enabled: true, perPage: 10, setCurrentPage: this.serverParams.page, dropdownAllowAll: true, jumpFirstOrLast: true }
      await this.$nextTick()
      this.paginationOptions = { enabled: true, perPage: 10, setCurrentPage: this.serverParams.page, dropdownAllowAll: false, jumpFirstOrLast: true }
      await this.loadItems()

      // Without this, any previous filter values aren't shown in the filter fields.
      this.$set(this.columns[0], 'filterValue', 'blah')
    },

    async createPaymentCsvFile () {
      try {
        var json = JSON.stringify({
          submissionId: this.submissionId,
          fileNumber: this.fileNumber,
          sortColumn: this.serverParams.sort.field,
          sortDirection: this.serverParams.sort.type,
          columnFilters: this.serverParams.columnFilters
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPaymentCsvFile/',
          data: json,
          showload: true
        })

        this.saveFile(response)
      } catch (ex) {
        console.log('error: ' + ex)
        console.log(JSON.stringify(ex))
      }
    }
  },

  async created () {
    this.submissionId = this.$store.getters.submissionId
    await this.getFileDetails()
    this.defaultParams = JSON.parse(JSON.stringify(this.serverParams))
    // console.log('payments fileNumber: ' + this.selectedFileNumber)
    // console.log('payments recordNumber: ' + this.selectedRecordNumber)
    // If user has just clicked the "Submission Payments" tab then check whether there are any saved server params.
    if (this.selectedRecordNumber == 0) {
      // Check if there are any stored filters in there.
      var savedParams = this.$store.getters.tableFilters(this.subParamsRoutePath)
      // console.log('savedparams: ' + JSON.stringify(savedParams))
      if (savedParams) {
        this.restartSearch = false
        // If there are, apply them!
        await this.savedParamsToObjects(savedParams)
        this.fileNumber = savedParams.fileNumber
        this.updateParams(savedParams)
      }

      await this.setFileDetails()
    } else {
      // User has clicked on a specific pre-sub val message so navigate to the correct file and page containing the related payment.
      this.fileNumber = this.selectedFileNumber
      this.recordNumber = this.selectedRecordNumber
      // console.log('User selected presub val msg, rec no: ' + this.recordNumber)
      await this.setFileDetails()
    }
  }
}
</script>
<style lang="scss">
.hightlightedRow {
  background-color: lightskyblue !important;
}
.subPaymentsPagepointer {
  cursor: default !important;
}
</style>
