<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Cancel Schedules or Customers</h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'paygateId'">
              <b-dropdown variant="danger">
                <!-- no-caret -->
                <span slot="button-content">
                  <i class="fa fa-trash mr-2"></i>Cancel
                </span>
                <b-dropdown-item  @click="cancelPlans(props.row.paygateId)">
                  Cancel all Schedules
                </b-dropdown-item>
                <b-dropdown-item  @click="closePlans(props.row.paygateId)">
                  Close all Customers and Schedules
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <span v-else-if="props.column.field === 'enabled'">
             <i class="fas fa-check" v-if="props.row.enabled"></i>
             <i class="fas fa-times" v-if="!props.row.enabled"></i>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">

      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import Datepicker from 'vuejs-datepicker'
  import papa from 'papaparse'
  import PrettyCheck from 'pretty-checkbox-vue/check'
  import moment from 'moment'
  import swal from 'sweetalert2'
  import colours from '@/Assets/Constants/colours'
  export default {
    props: {
        ukPayerId: String
    },
    mixins: [tableFilterMixin, loading],
    components: {
      Datepicker,
      pCheck: PrettyCheck
    },
    data () {
      return {
        dateOptions: {
          format: 'DD/MM/YYYY HH:mm',
          useCurrent: false,
          stepping: 30,
          sideBySide: true,
        },
        end: null,
        start: null,
        useDateFilter: false,
        rows: [],
        columns: [
          {
            label: 'Client Name',
            field: 'customerName'
          },
          {
            label: 'Enabled',
            field: 'enabled'  ,
            tdClass: 'text-center'
          },
          {
            label: 'Active Customers',
            field: 'payerCount',
            tdClass: 'text-right'
          },
          {
            label: 'Active Schedules',
            field: 'planCount',
            tdClass: 'text-right'
          },
          {
            label: '',
            field: 'paygateId',
            tdClass: 'text-center'
          }
        ],
        serverParams: {
          sort: [{ field: 'customerName', type: 'asc' }],
        },
      }
    },
    mounted () {
      this.end = new Date()
      let tempStart = new Date()
      tempStart.setDate(this.end.getDate() - 7)
      this.start = tempStart
      this.load()
    },
    methods: {
      load: async function () {
        try {
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}bulkcancellation`, { params: { ...this.buildGoodTableQuery() }, showLoad: true })
          if (response) {
            this.rows = response.data.data
            this.totalRecords = response.data.count
          }
        } catch (error) {
          this.$toastr.e(error)
        }
      },
      onRowChange (e) {
        console.log(e)
        //this.$router.push({ path: `/collections/challenges/${e.row.challengeId}` })
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
      formatStatus (value) {
        let retVal = ''
        switch(value) {
          case 1:
            retVal = 'Created'
            break
          case 2:
            retVal = 'In Progress'
            break
          case 3:
            retVal = 'Resolved'
            break
          case 4:
            retVal = 'Rejected'
            break
          case 5:
            retVal = 'Action Required'
            break
          default:
            retVal = 'None'
            break
        }
        return retVal
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      formatDateWithNull (date) {
        if (date===null || date === '0001-01-01T00:00:00') {
          return 'N/A'
        } else {
          return this.formatDate(date)
        }
      },
      async exportTable () {
        var query = this.buildGoodTableQuery()
        var reportDate = moment(new Date()).format('DD-MM-YYYY')
        query.perPage = this.totalRecords
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}bulkcancellation`, { params: {
          ...query }, showload: true })
        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        let fileName = `cancel-schedule-report-${reportDate}.csv`
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      codeToDescription (value) {
        let retval = ''
        switch (value) {
          case 1:
            retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
            break;
          case 2:
            retval = 'NO ADVANCE NOTICE WAS RECEIVED';
            break;
          case 3:
            retval = 'BANK CANCELS THE DIRECT DEBIT';
            break;
          case 4:
            retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
            break;
          case 5:
            retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
            break;
          case 6:
            retval = 'FRAUDULENT SIGNATURE';
            break;
          case 7:
            retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
            break;
          case 8:
            retval = 'SERVICE USER IS NOT RECOGNISED';
            break;
          default:
            retval = 'Invalid Code Supplied'
            break;
        }
        return retval
      },
      getBadgeClass (value) {
        return statusToBadgeVariant(value)
      },
      recurrenceTypeFormat (value) {
        let retval = ''
        switch (value) {
          case 0:
            retval = 'Weekly';
            break;
          case 1:
            retval = 'Monthly';
            break;
          case 2:
            retval = 'Yearly';
            break;
        }
        return retval
      },
      recurrenceEndTypeFormat (value) {
        let retval = ''
        switch (value) {
          case 0:
            retval = 'One Off';
            break;
          case 1:
            retval = 'Until Date';
            break;
          case 2:
            retval = 'Set Collection Count';
            break;
          case 3:
            retval = 'Ongoing';
            break;
          case 4:
            retval = 'Ad Hoc';
            break;
        }
        return retval
      },
      formatRemaining (value) {
        if (value === -1){
          return 'N/A'
        }
        return value
      },
      async cancelPlans (paygateId) {
        var result = null
        try {
          result = await swal.fire({
            icon: 'warning',
            title: 'Navigation',
            text: `This will cancel all Schedules for this Client. It will NOT close Customers or send 0C instructions.`,
            type: 'warning',
            input: 'text',
            inputPlaceholder: 'Cancellation Reason',
            showCancelButton: true,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel'
          })
        } catch (err) {
          result = false
        }
        if (result.isConfirmed) {
          var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}bulkcancellation/${paygateId}/cancelschedules`, { cancellationReason: result.value }, { showload: true })
          if(response) {
            this.$toastr.s('Schedules for the selected Client have been cancelled.')
            this.load()
          }
        } else {
          console.log('NO')
        }

      },
      async closePlans (paygateId) {
        var result = null
        try {
          result = await swal.fire({
            icon: 'warning',
            title: 'Navigation',
            text: `This will close all Customers and cancel all Schedules for this Client. AUDDIS 0C instructions will be generated for each 0N instruction lodged.`,
            type: 'warning',
            input: 'text',
            inputPlaceholder: 'Closure Reason',
            showCancelButton: true,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel'
          })
        } catch (err) {
          result = false
        }
        if (result.isConfirmed) {
          var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}bulkcancellation/${paygateId}/closeschedules`, { cancellationReason: result.value }, { showload: true })
          if(response) {
            this.$toastr.s('Customers closed for the selected Client, and all Schedules belonging to those Customers have been cancelled.')
            this.load()
          }
        } else {
          console.log('NO')
        }

      }
    },
    computed: {
      startDateOptions () {
        return { ...this.dateOptions, maxDate: this.end }
      },
      endDateOptions () {
        return { ...this.dateOptions, minDate: this.start }
      },
    }
  }
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
