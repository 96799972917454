<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>Cancel Submission Detail - {{submissionTitle}} - {{submissionDate}}<favourite-icon></favourite-icon></h2>
    </div>
    <div class="idb-block-content">
    <p><strong>Service User Number:</strong> {{submissionSun}}</p>
    <p><strong>File Number:</strong> {{fileNumber}}</p>
      <vue-good-table
        :columns="columns"
        :rows="records"
        mode="remote"
                styleClass="vgt-table striped bordered"
          ref="table"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :search-options="{
                    enabled: true
                    }"
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                :isLoading.sync="isTableLoading"

      >
        <div slot="emptystate">
          <span style="text-align: center;">No entries have been found matching the criteria.</span>
        </div>
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'viewPayerButton'">
              <router-link :to="{ name: 'editPayer', params: { ukPayerId: props.row.ukPayerId } }"> <!-- eslint-disable-line -->
                <span>View Customer</span>
              </router-link>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>

        </template>
        <div slot="table-actions">
          <button
            @click.prevent="clearTableFilters"
            class="btn btn-link"
            v-b-popover.hover.top.d500="'Clear filters'"
          >
            <span class="fa-stack" style="font-size: 10px;">
              <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
              <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
            </span>
          </button>
          <b-button
            @click.prevent="load" :disabled="isLoading"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Refresh the data in the table'"
          >
            <i class="fa fa-sync pointer dimmedIcon"></i>
          </b-button>
          <b-button
            @click.prevent="exportTable"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Export the contents of the table'"
          >
            <i class="fas fa-file-export pointer dimmedIcon"></i>
          </b-button>
        </div>
      </vue-good-table>
    </div>
    <div class="idb-block-footer">
      <b-button class="mr-2" variant="primary" @click.prevent="removeAction" :disabled="isLoading" v-if="actionId && actionId !== null">Clear Action</b-button>
      <b-button @click="cancelSubmission" variant="danger"><i class="fas fa-times mr-2"></i>Cancel Submission</b-button>
    </div>
  </div>
</template>
<script>
import EventBus from '@/Lib/eventBus'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import papa from 'papaparse'
import moment from 'moment'
import swal from 'sweetalert2'
export default {
  props: {
    prepopulatedGroup: String,
    prepopulatedSearchTerm: String,
    prepopulatedStatus: String,
    submissionId: String,
    fileNumber: String,
  },
  mixins: [ tableFilterMixin, loading ],
  computed: {
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups)
      }
      ret.unshift({
        'description': '', 'groupId': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'name': 'All Groups', 'paygateId': this.paygateId, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      return ret
    },
    allGroups () {
      return !this.group || this.group === undefined || this.group === null || this.group === '00000000-0000-0000-0000-000000000000'
    }
  },
  data () {
    return {
      actionId: null,
      records: [],
      columns: [
        {
          label: 'Customer Reference',
          field: 'payerReference',
          formatFn: this.formatReference
        },
        {
          label: 'Status',
          field: 'status',
          formatFn: this.formatStatus
        },
        {
          label: 'Amount (£)',
          field: 'amount',
          formatFn: (value) => {
            return `${this.numberWithCommas((value / 1).toFixed(2))}`
          },
        },
        {
          label: 'Transaction Code',
          field: 'transactionCode'
        },
        {
          field: 'ukddmsPendingPaymentId',
          hidden: true
        },
        {
          field: 'paymentComposite',
          hidden: true
        },
        {
          field: 'ukPayerId',
          hidden: true
        },
        {
          label: '',
          field: 'viewPayerButton',
          sortable: false,
          width: '150px'
        }
      ],
      serverParams: {
        sort: [{ field: 'payerReference', type: 'asc' }]
      },
      submissionTitle: '',
      submissionDate: '',
      submissionSun: ''
    }
  },
  async mounted () {
    this.actionId = this.$route.query.actionId
    await this.load()
  },
  methods: {
    groupChanged () {
      this.updateParams({ page: 1 })
    },
    formatReference (val) {
      if (val === undefined || val === null) {
        val = 'Deleted Customer'
      }
      return val
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      let day = date.getDate()
      let monthIndex = date.getMonth() + 1
      let year = date.getFullYear()
      let hours = date.getHours()
      let minutes = date.getMinutes()
      let seconds = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year} ${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`
    },
    pad (num, size) {
      let s = '00' + num
      return s.substr(s.length - size)
    },
    async cancelSubmission () {
      var canCancel = await this.remindThemToCancel()
      if (canCancel) {
        let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/manualcancel/${this.submissionId}/${this.fileNumber}`, {}, { showload: true, params: { paygateId: this.paygateId } })
        if(response.data.success) {
          await this.askAboutMessaging(this.submissionId)
          this.$router.push('/collections/pastsubmissions')
        } else {
          this.$toastr.e('Failed to cancel submission.')
        }
      }
    },
    getSeverityClass (value) {
      let retVal = ''
      switch (value.toUpperCase()) {
        case 'ERROR':
          retVal = 'danger'
          break
        case 'WARN':
          retVal = 'warning'
          break
        case 'DEBUG':
          retVal = 'secondary'
          break
        default:
          retVal = 'info'
          break
      }
      return retVal
    },
    formatStatus (status) {
      var outText = ''
      switch (status) {
        case 9:
          outText = 'Pending First Submission'
          break
        case 8:
          outText = 'Pending Regular Submission'
          break
        case 7:
          outText = 'Pending Re Presentation'
          break
        case 6:
          outText = 'Pending Final Submission'
          break
        case 5:
          outText = 'Being Processed'
          break
        case 4:
          outText = 'Submitted'
          break
        case 3:
          outText = 'Failed'
          break
        case 2:
          outText = 'Error'
          break
        case 1:
          outText = 'Suspended'
          break
        case 0:
          outText = 'Closed'
          break
        case 10:
          outText = 'Deleted'
          break
        case 11:
          outText = '<i class="fas fa-share mr-2"></i>Refunded'
          break
        case 16:
          outText = 'Due'
          break
        case 17:
          outText = 'Successful'
          break
        case 18:
          outText = 'Unsubmitted'
          break
        case 19:
          outText = 'Cancelled'
          break
        default:
          outText = 'Value not set yet'
          break
      }
      return outText
    },
    async load () {
      this.isTableLoading = true
      let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/pastsubmission/${this.submissionId}/${this.fileNumber}`, { headers: { }, params: { ...this.buildGoodTableQuery(), paygateId: this.paygateId }, showload: true })
      this.records = response.data.rows
      this.totalRecords = response.data.totalRows
      this.submissionTitle = response.data.submissionTitle
      this.submissionSun = response.data.serviceUserNumber
      this.submissionDate = this.formatDate(response.data.creationDate)
      this.isTableLoading = false
    },
    exportTable () {
      var csvString = papa.unparse(records)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'past-submissions-log.csv')
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = 'past-submissions-log.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    async remindThemToCancel () {
      let result = await swal.fire({
        title: 'Warning',
        text: 'This process only cancels the submission within Paygate. It will not cancel the actual BACS submission. Please ensure that the submission has been cancelled with your bank before proceeding.',
        icon: 'warning',
        input: 'checkbox',
        inputPlaceholder: 'I understand *',
        inputLabel: 'I understand *',
        animation: false,
        showCancelButton: true,
        cancelButtonClass: 'btn btn-light',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue',
        confirmButtonClass: 'btn btn-danger',
        inputClass: 'required',
        inputValidator: (result) => {
          return !result && 'You need to confirm that you understand that the submission is only cancelled within paygate'
        }
      })
      return result.isConfirmed
    },
    async askAboutMessaging (id) {
      let result = await swal.fire({
        title: 'Submission Cancelled',
        text: 'Would you like to send a message to customers regarding the cancellation?',
        icon: 'success',
        animation: false,
        showCancelButton: true,
        cancelButtonClass: 'btn btn-light',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonClass: 'btn btn-danger',
        input: 'select',
        inputOptions: {
          'CustomMessage1': 'Custom Message 1',
          'CustomMessage2': 'Custom Message 2',
          'CustomMessage3': 'Custom Message 3',
          'CustomMessage4': 'Custom Message 4',
          'CustomMessage5': 'Custom Message 5'
        },
        inputValidator: function (value) {
          return new Promise(function (resolve, reject) {
            if (value !== '') {
              resolve();
            } else {
              reject('You need to select a Message Type');
            }
          })
        }
      })
      if(result.isConfirmed){
        let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/sendcancellationmessage`, { templateName: result.value, submissionId: id }, { showload: true, params: { paygateId: this.paygateId } })
        if (response.data.success) {
          this.$toastr.s('Messages successfully queued.')
        } else {
          this.$toastr.e('An error occurred sending the cancellation message.')
        }
      }
    }
  }
}
</script>
<style>
.swal2-icon.swal2-info, .swal2-icon.swal2-warning {
    font-size: inherit !important;
    line-height: 80px;
    text-align: center;
}
.swal2-icon .swal2-icon-content {
    font-size: 60px !important;
}
</style>
