<template>
  <div>
    <!-- Agent Detail -->
    <div class="idb-block">
      <div class="idb-block-title">
        <h3>Edit Agent</h3>
        <h4 v-if="!active" class="text-warning">This agent is currently deactivated</h4>
      </div>
      <div class="idb-block-content">
        <div class="form-group row" :class="{ invalid: $v.name.$error }">
          <label class="col-form-label col-md-3 required">Name</label>
          <div class="col-md-6">
            <input v-focus type="text" class="form-control" v-model.trim="$v.name.$model" />
            <!-- Validation -->
            <validation-messages v-model="$v.name" :an="true" name="agent name"></validation-messages>
          </div>
        </div>

        <div class="form-group row">
          <label class="label-control col-md-3">Postcode</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="postcode" />
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-info"
              @click="getAddress"
              type="button"
              :disabled="postcode === '' || postcode  === null || postcode=== undefined"
            >Lookup Address</button>
          </div>
        </div>

        <transition name="fade">
          <div
            class="form-group row"
            v-if="foundAddresses.length > 0 && !this.addressLookupInProgress"
          >
            <label class="label-control col-md-3">Select an Address</label>
            <div class="col-md-6">
              <select
                class="form-control"
                aria-label="Please select an address"
                v-model="selectedAddress"
                @change="setAddress"
              >
                <option selected>Please select an address</option>
                <option
                  v-for="foundAddress in foundAddresses"
                  :key="foundAddress.addressLine1"
                  :value="foundAddress"
                >{{foundAddress.addressLine1}}, {{foundAddress.addressTown}}, {{foundAddress.addressCounty}}</option>
              </select>
            </div>
          </div>
        </transition>

        <div class="form-group row">
          <label class="label-control col-md-3">Address 1</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="address1" />
          </div>
        </div>

        <div class="form-group row">
          <label class="label-control col-md-3">Address 2</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="address2" />
          </div>
        </div>

        <div class="form-group row">
          <label class="label-control col-md-3">Address 3</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="address3" />
          </div>
        </div>

        <div class="form-group row">
          <label class="label-control col-md-3">Address 4</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="address4" />
          </div>
        </div>

        <div class="form-group row">
          <label class="label-control col-md-3">Address 5</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="address5" />
          </div>
        </div>

        <div class="form-group row">
          <label class="label-control col-md-3">Email Addresses</label>
          <div v-if="agentEmailsDisplayWarn" class="col-md-6">
            <h6
              class="text-warning"
            >This agent's contacts have no configured email addresses. You will not be able to email any statements with this current configuration.</h6>
          </div>

          <div v-else class="col-md-6">
            <div v-for="contact in agentEmails" v-bind:key="contact.contactid">
              <div class="row">
                <div class="col-md-4">
                  <b>{{contact.name}}</b>
                </div>
                <div class="col-md-4">{{contact.email}}</div>
                <div v-if="contact.emailcc" class="col-md-2">
                  <i>CC Email</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          type="submit"
          id="submit-btn"
          :disabled="disableSave"
          @click="saveAgent()"
        >Save</button>
        <button
          v-if="active"
          class="btn btn-danger pull-right"
          @click="onDeactivateAgentClick"
          type="button"
        >
          <i class="glyphicon ti-trash rpad"></i>Deactivate Agent
        </button>
        <button
          v-else
          class="btn btn-danger pull-right"
          @click="onReactivateAgentClick"
          type="button"
        >
          <i class="glyphicon ti-trash rpad"></i>Reactivate Agent
        </button>

        <button
          class="btn btn-secondary pull-right"
          id="submit-btn"
          @click="onGoToAuditTrail()"
        >Audit Trail</button>

        <button
          class="btn btn-secondary pull-right"
          id="submit-btn"
          :disabled="disableSave"
          @click="showAssociations()"
        >Associations</button>
      </div>
    </div>
    <!-- Contact Table -->
    <div class="idb-block">
      <div class="idb-block-title">
        <h3>Contacts</h3>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :search-options="{
            enabled: true
          }"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <button
              @click.prevent="exportData"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Export Data'"
            >
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </button>
            <button
              @click.prevent="onCreateContact"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Create a new contact for this agent'"
            >
              <i class="fa fa-plus dimmedIcon pointer"></i>
            </button>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="onCreateContact">Create Contact</button>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import axios from 'axios'
import {
  required,
  maxLength
} from 'vuelidate/lib/validators'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  props: {
    Id: String
  },
  mixins: [tableFilterMixin],
  watch: {
    name: function () {
      this.runValidate()
    }
  },
  data () {
    return {
      // Agent Details
      name: '',
      address1: null,
      address2: null,
      address3: null,
      address4: null,
      address5: null,
      postcode: null,
      active: true,
      agentEmails: [],
      agentEmailsDisplayWarn: false,
      // Postcode Lookup
      addressLookupInProgress: false,
      foundAddresses: [],
      selectedAddress: null,

      // Page Control
      disableSave: true,

      // Table Data

      rows: [],
      columns: [
        {
          label: 'contactid',
          field: 'id',
          hidden: true
        },
        {
          label: 'Contact Name',
          field: 'name',
          filterOptions: {
            enabled: false
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'name', type: 'asc' }]
      }

    }
  },
  async created () {
    console.log('agent associations loaded')
    await this.load()
  },

  methods: {

    async load () {
      var agentResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetAgent/${this.Id}`)
      console.log('agentResponse', agentResponse)
      this.name = agentResponse.data.name
      this.address1 = agentResponse.data.address1
      this.address2 = agentResponse.data.address2
      this.address3 = agentResponse.data.address3
      this.address4 = agentResponse.data.address4
      this.address5 = agentResponse.data.address5
      this.postcode = agentResponse.data.postcode
      this.active = agentResponse.data.active
      this.agentEmails = agentResponse.data.agentEmails

      if (this.agentEmails.length === 0) {
        this.agentEmailsDisplayWarn = true
      } else {
        this.agentEmailsDisplayWarn = false
      }

      await this.loadTable()
    },

    onReactivateAgentClick: async function () {
      console.log('this.Id', this.Id)
      var swalText = 'This will <strong>reactivate</strong> this agent and they will be able to generate commissions. If commissions were generated while the agent was deactivated, these will not be added retrospectively. Please use the Commissions Administration panel to decide what to do with these commissions.'
      try {
        var swalResult = await swal.fire({
          title: 'Recativate this agent',
          html: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }
      if (!swalResult.isConfirmed) {
        console.log('declined to reactivate')
      } else {
        try {
          this.$nextTick(() => {
            this.$v.$reset()
          })
          var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAReactivateAgent/${this.Id}`,
            {
              showerror: true,
              errormessage: 'Failed to Reactivate Agent'
            })

          console.log('response', response)
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$snapbar.e(response.data.toastMessage)
          }
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        }
        await this.load()
      }
    },

    onDeactivateAgentClick: async function () {
      var swalText = 'This will <strong>deactivate</strong> this agent and they will no longer be able to generate commissions. <br/>Any associations currently active will remain present but ignored by the commissions system (for newly generated commissions only).'
      try {
        var swalResult = await swal.fire({
          title: 'Decativate this agent',
          html: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }
      if (!swalResult.isConfirmed) {
        console.log('declined to deactivate')
      } else {
        try {
          this.$nextTick(() => {
            this.$v.$reset()
          })
          var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CADeactivateAgent/${this.Id}`,
            {
              showerror: true,
              errormessage: 'Failed to Deactivate Agent'
            })
          console.log('response', response)
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$snapbar.e(response.data.toastMessage)
          }
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        }
        await this.load()
      }
    },

    loadTable: async function () {
      try {
        this.isTableLoading = true
        console.log(this.buildGoodTableQuery())
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetContacts/${this.Id}`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading commission agent contacts'
          })

        console.log('response', response)
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    onRowClick (params) {
      var contactid = params.row.id
      console.log('rowclick id', contactid)
      this.$router.push({
        name: 'CommissionAgentContactAdministration',
        params: { agentid: this.Id, contactid: contactid }
      })

      // :agentid/:contactid',
    },

    exportData: async function () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetContactsCSV/${this.Id}`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data'
          })

        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Contacts.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {
        console.log(e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    onGoToAuditTrail () {
      this.$router.push({ name: 'CommissionAgentAuditTrailAgent', params: { id: this.Id } })
    },

    onCreateContact () {
      this.$router.push({ name: 'CommissionAgentCreateContact', params: { id: this.Id } })
    },

    showAssociations () {
      this.$router.push({ name: 'CommissionAgentListAssociations', params: { agentid: this.Id } })
    },

    async saveAgent () {
      var payload = {
        id: this.Id,
        name: this.name,
        address1: this.address1,
        address2: this.address2,
        address3: this.address3,
        address4: this.address4,
        address5: this.address5,
        postcode: this.postcode
      }
      try {
        this.$nextTick(() => {
          this.$v.$reset()
        })
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAUpdateAgent`, payload, { showload: true, showerror: true, errormessage: 'Failed to Create New Agent' })
        console.log('response', response)
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
          var recordId = response.data.id

          this.$router.push({ name: 'CommissionAgentAdministration', params: { id: recordId } })
        } else {
          this.$snapbar.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    async getAddress () {
      this.foundAddresses = []
      this.addressLookupInProgress = true
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}addresslookup`, {
          params: { postcode: this.postcode }
        })
        console.log(response)
        if (response.data.addresses.length === 0) {
          this.$toastr.w('Postcode Not Found', 'Sorry, we could not find that address')
        } else if (response.data.addresses.length === 1) {
          this.foundAddresses = response.data.addresses[0]
        } else {
          this.foundAddresses = response.data.addresses
        }
      } catch (e) {
        this.$toastr.w('Postcode Not Found', 'Sorry, we could not find that address')
      } finally {
        this.addressLookupInProgress = false
      }
    },
    setAddress () {
      if (this.selectedAddress != null) {
        this.address1 = this.selectedAddress.addressLine1
        this.address2 = this.selectedAddress.addressLine2
        this.address3 = this.selectedAddress.addressLine3
        this.address4 = this.selectedAddress.addressTown
        this.address5 = this.selectedAddress.addressCounty
      }
    },
    runValidate: function () {
      if (!this.$v.name.$invalid) {
        this.disableSave = false
      } else {
        this.disableSave = true
      }
    }

  },

  validations: {
    name: { required, maxLength: maxLength(150) }

  }
}
</script>
