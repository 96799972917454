<template>
  <b-modal v-model="value" @hide="hide" @shown="setFocus" centered size="lg" no-close-on-backdrop>
    <div slot="modal-header" class="w-100">Non-Processing Day (Use English Bank Holidays ONLY)</div>
    <div class="row form-group">
      <div class="col-md-4 required">Non-Processing Date</div>
      <div class="col-md-3">
        <vuejsDatepicker
          ref="paymentDate"
          name="nonProcessingDate"
          id="nonProcessingDate"
          v-model="nonProcessingDate"
          format="dd/MM/yyyy"
          input-class="form-control"
          :bootstrap-styling="true"
          v-on:selected="nonProcessingDateChanged"
          @focusin.native="dateOnfocus"
          :monday-first="true"
        ></vuejsDatepicker>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"></div>
      <div class="col-md-8">
        <span v-if="$v.nonProcessingDate.$dirty">
          <small
            v-if="!$v.nonProcessingDate.required"
            class="form-text text-danger small text-nowrap"
          >Non-processing date is required</small>
        </span>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-4 required">Reason</div>
      <div class="col-md-8" :class="{invalid: $v.reason.$error}">
        <input v-model.trim.lazy="$v.reason.$model" class="form-control" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"></div>
      <div class="col-md-8">
        <span v-if="$v.reason.$dirty">
          <small
            v-if="!$v.reason.required"
            class="form-text text-danger small text-nowrap"
          >Non-processing day reason is required</small>
        </span>
      </div>
    </div>
    <div class="row form-group d-none">
      <div class="col-md-4">Northern Ireland Only</div>
      <div class="col-md-2">
        <p-check class="p-switch p-fill" color="primary" v-model="northernIrelandOnly"></p-check>
      </div>
    </div>
    <div slot="modal-footer" class="w-100">

          <b-btn
            size="sm"
            class="float-left"
            variant="primary"
            @click="saveNonProcessingDay"
            :disabled="disableButtons"
          >Save Non-Processing Day</b-btn>
          <b-btn
            size="sm"
            class="float-right ml-2"
            variant="danger"
            @click="deleteNonProcessingDay"
            :disabled="disableButtons"
            v-if="!newNonProcessingDay"
          >Delete Non-Processing Day</b-btn>
          <b-btn size="sm" class="float-right" variant="outline-danger" @click="cancel" :disabled="disableButtons">Cancel</b-btn>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import bacsMixin from '@/Lib/BacsMixin.js'

export default {
  name: 'editNonProcessingDay',

  mixins: [
    bacsMixin
  ],
  props: {
    value: Boolean,
    newNonProcessingDay: Boolean,
    nonProcessingDay: {}
  },

  data () {
    return {
      disableButtons: false,
      nonProcessingDate: '',
      reason: '',
      northernIrelandOnly: false
    }
  },

  methods: {
    hide () {
      this.$emit('input', false)
    },

    cancel () {
      this.$emit('close')
    },

    setFocus () {
      this.nonProcessingDate = this.nonProcessingDay.date
      this.reason = this.nonProcessingDay.reason
      this.northernIrelandOnly = this.nonProcessingDay.northernIrelandOnly
      this.disableButtons = false

      var dateElement = document.getElementById('nonProcessingDate')
      dateElement.style.backgroundColor = 'white'
      this.$v.$reset()
    },

    nonProcessingDateChanged (val) {
      this.nonProcessingDate = val
    },

    async saveNonProcessingDay () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }

      this.disableButtons = true

      var json = JSON.stringify({
        id: this.nonProcessingDay.id,
        date: this.nonProcessingDate,
        reason: this.reason,
        northernIrelandOnly: this.northernIrelandOnly
      })

      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/admin/updateBacsNonProcessingDay'
      var method = 'PUT'
      if (this.newNonProcessingDay === true) {
        url = process.env.VUE_APP_BACS_API_URL + 'bacs/admin/createBacsNonProcessingDay'
        method = 'POST'
      }
      var response = await axios({
        method: method,
        url: url,
        data: json
      })

      if (response.data.status === 'Updated' || response.data.status === 'Created') {
        this.$toastr.s(response.data.toastMessage)
        this.$emit('savedNonProcessingDay')
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
    },

    deleteNonProcessingDay () {
      const vm = this
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure want to delete this non-processing day?',
        type: 'warning',
        animation: false,
        showCancelButton: true,
        cancelButtonClass: 'btn btn-light',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete',
        confirmButtonClass: 'btn btn-danger'
      }).then(function () {
        vm.disableButtons = true

        var json = JSON.stringify(vm.nonProcessingDay.id)

        return axios({
          method: 'DELETE',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/admin/deleteBacsNonProcessingDay',
          data: json
        })
          .then(response => {
            if (response.data.status === 'Deleted') {
              vm.$toastr.s('Deleted non-processing day')
              vm.$emit('savedNonProcessingDay')
            } else {
              vm.$toastr.e(response.data.toastMessage)
              vm.disableButtons = false
            }
          })
      }, function (dismiss) {
        if (dismiss === 'cancel') {
          // do nothing.
        }
      })
    }
  },

  validations () {
    return {
      nonProcessingDate: { required },
      reason: { required }
    }
  }
}
</script>
