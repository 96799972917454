<template>
  <div>
    <!-- List Type -->
    <div class="form-group row">
      <label class="label-control col-md-3">List Type for Group</label>
      <div class="col-md-9">
        <p-radio
          class="p-default p-round"
          value="Black List"
          v-model="groupListType"
          @change="changedListType"
          :disabled="disabled"
        >Black List</p-radio>
        <p-radio
          class="p-default p-round"
          value="White List"
          v-model="groupListType"
          @change="changedListType"
          :disabled="disabled"
        >White List</p-radio>
      </div>
    </div>
    <!-- Pre-sub val message severity -->
    <div class="form-group row">
      <label class="label-control col-md-3">Pre-sub Message Severity</label>
      <div class="col-md-9">
        <p-radio
          class="p-default p-round"
          value="Fix"
          v-model="messageSeverity"
          @change="changedSeverity"
          :disabled="disabled"
        >Fix</p-radio>
        <p-radio
          class="p-default p-round"
          value="Warning"
          v-model="messageSeverity"
          @change="changedSeverity"
          :disabled="disabled"
        >Warning</p-radio>
      </div>
    </div>
    <div v-show="groupListType !== ''" class="form-group row">
      <b-list-group class="col-5">
        <b-list-group-item variant="secondary">Available {{ listTypeDisplayName }}</b-list-group-item>
        <draggable
          :list="availableLists"
          class="dragArea"
          style="min-height=50"
          @change="changed"
          group="lists"
          :disabled="disabled"
        >
          <b-list-group-item
            v-for="(list, index) in availableLists"
            class="pointer"
            :key="list.id"
            @dblclick="toggle(availableLists, allocatedLists, index)"
          >{{ list.name }}</b-list-group-item>
        </draggable>
      </b-list-group>
      <div class="col-1 text-center"></div>
      <b-list-group class="col-5">
        <b-list-group-item variant="secondary">Allocated {{ listTypeDisplayName }}</b-list-group-item>
        <draggable
          :list="allocatedLists"
          class="dragArea"
          style="min-height=50"
          @change="changed"
          group="lists"
          :disabled="disabled"
        >
          <b-list-group-item
            v-for="(list, index) in allocatedLists"
            class="pointer"
            :key="list.id"
            @dblclick="toggle(allocatedLists, availableLists, index)"
          >{{ list.name }}</b-list-group-item>
        </draggable>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import Utility from '@/Assets/Mixins/Utility'

export default {
  mixins: [Utility],
  props: ['group', 'disabled', 'groupId'],
  components: {
    draggable
  },
  computed: {
    allocatedLists: {
      get () {
        return this.groupLists
      }
    },
    availableLists: {
      get () {
        return this.listsForSelectedType.filter(r => !this.allocatedLists.some(ar => ar.name === r.name))
      }
    }
  },
  data: () => ({
    groupListType: '',
    messageSeverity: 'Info',
    listTypeDisplayName: '',
    allLists: [],
    groupLists: [],
    listsForSelectedType: [],
    paygateId: '',
    cachedGroupLists: [],
    cachedGroupAntifraudLists: []
  }),
  async mounted () {
    this.paygateId = this.$store.state.common.paygateId

    try {
      const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList`, { params: { paygateid: this.paygateId } })
      this.allLists = response.data.data
      if (this.group.antiFraudGroupLists.length > 0) {
        const firstList = this.allLists.find(i => i.id === this.group.antiFraudGroupLists[0].antiFraudListId)
        this.groupListType = firstList.listType
        this.listTypeDisplayName = this.groupListType + 's'
        this.messageSeverity = this.group.antiFraudGroupLists[0].presubValMessageSeverity
        this.listsForSelectedType = this.allLists.filter(i => i.listType === this.groupListType && i.isItemActioned === false)

        for (let j = 0; j < this.group.antiFraudGroupLists.length; j++) {
          this.groupLists.push(this.allLists.find(i => i.id === this.group.antiFraudGroupLists[j].antiFraudListId))
        }
      }
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    changed () {
      this.group.antiFraudGroupLists = []
      for (var j = 0; j < this.allocatedLists.length; j++) {
        var afgl = {
          id: this.generateUUID(),
          groupId: this.groupId,
          antiFraudListId: this.allocatedLists[j].id,
          paygateId: this.paygateId,
          presubValMessageSeverity: this.messageSeverity
        }
        this.group.antiFraudGroupLists.push(afgl)
      }
    },
    // User double clicked item to move
    toggle (source, target, index) {
      if (this.disabled) {
        return
      }
      target.push(source[index])
      source.splice(index, 1)
      this.changed()
    },
    changedSeverity () {
      this.group.antiFraudGroupLists.forEach((groupList) => {
        groupList.presubValMessageSeverity = this.messageSeverity
      })
    },
    changedListType () {
      this.listsForSelectedType = this.allLists.filter(i => i.listType === this.groupListType)

      // Preserve the currently selected black or white lists in case the user has accidently clicked on the List Type radio button and lost the current settings.
      if (this.cachedGroupLists.length > 0) {
        var tempLists = []
        var tempGroupAntifraudLists = []
        for (let i = 0; i < this.cachedGroupLists.length; i++) {
          tempLists.push(this.cachedGroupLists[i])
          tempGroupAntifraudLists.push(this.cachedGroupAntifraudLists[i])
        }

        this.cachedGroupLists = []
        this.cachedGroupAntifraudLists = []
        for (let i = 0; i < this.groupLists.length; i++) {
          this.cachedGroupLists.push(this.groupLists[i])
          this.cachedGroupAntifraudLists.push(this.group.antiFraudGroupLists[i])
        }

        this.groupLists = []
        this.group.antiFraudGroupLists = []
        for (let i = 0; i < tempLists.length; i++) {
          this.groupLists.push(tempLists[i])
          this.group.antiFraudGroupLists.push(tempGroupAntifraudLists[i])
        }
      } else {
        this.cachedGroupLists = []
        this.cachedGroupAntifraudLists = []
        for (let i = 0; i < this.groupLists.length; i++) {
          this.cachedGroupLists.push(this.groupLists[i])
          this.cachedGroupAntifraudLists.push(this.group.antiFraudGroupLists[i])
        }
        this.groupLists = []
      }

      if (this.messageSeverity === 'Info') {
        this.messageSeverity = 'Fix'
      }
      this.listTypeDisplayName = this.groupListType + 's'
    }
  }
}
</script>

<style lang="scss" scoped>
.dragArea {
  min-height: 200px;
}

.pointer:hover {
  cursor: pointer;
}

.disabled {
  .pointer:hover {
    cursor: not-allowed;
  }
}
</style>
