<template>
  <div id="clientLiability">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Ledger Client Liability
              <span class="pull-right">
              </span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Totals Until End Of</strong>
              </div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdFrom"
                  name="createdFrom"
                  id="createdFrom"
                  v-model="createdFrom"
                  format="MMM yyyy"
                  minimumView="month"
                  maximumView="year"
                  initial-view="month"
                  :bootstrap-styling="true"
                  :monday-first="true"
                  v-on:input="checkDates"
                  :disabledDates="disabledDates"
                ></vuejsDatepicker>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <div class="row form-group">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="load"
                  :disabled="disableSearch"
                >Search</button>
              </div>
              <div class="col-md-10">
                <button
                  type="button"
                  class="btn btn-outline-primary pull-right"
                  v-on:click="clearSearchParams()"
                >Clear Search Criteria</button>
              </div>
            </div>
            <div v-show="searchComplete === true" class="row form-group">
              <div class="col-md-12">
                <vue-good-table
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :isLoading.sync="isTableLoading"
                  ref="table"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="false"
                  mode="remote"
                  :totalRows="totalRecords"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  styleClass="vgt-table striped bordered"
                >
                  <div slot="table-actions">
                    <table-actions
                      :data-url="dataUrl"
                      :export-data="tableServerData"
                      :print-enabled="false"
                      :table-amount="totalRecords"
                      :export-name="exportFilename"
                      :reload-table="load"
                      :table-columns="columns"
                      :clear-table-filters="clearTableFilters"
                    >
                    </table-actions>
                  </div>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'
import bacsMixin from '@/Lib/BacsMixin.js'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [
    bacsMixin,
    tableFilterMixin
  ],
  computed: {
    ...mapGetters(['selectedCustomer']),
    isFinanceUser () { return this.$store.getters.isInRole(roles.Finance) },
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}ledger/ledgerClientLiability`
    },
    tableServerData () {
      return this.buildGoodTableQuery()
    },
    exportFilename () {
      return 'ClientLiability ' + this.defaultFromDate
    }
  },
  data () {
    return {
      searchComplete: false,
      createdFrom: '',
      totalRecords: 0,
      disabledDates: {},
      invalidDateRange: false,
      defaultFromDate: '',
      columns: [
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Client ID',
          field: 'accountId',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Bank Account ID',
          field: 'bankAccountId',
          hidden: true
        },
        {
          label: 'Bank Account',
          field: 'reference',
          hidden: false
        },
        {
          label: 'Direct Debit (£)',
          field: 'totalDebit',
          type: 'decimal',
          formatFn: this.formatAmount,
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Failed Direct Debit (£)',
          field: 'totalCredit',
          type: 'decimal',
          formatFn: this.formatAmount,
          hidden: false,
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Grand Total (£)',
          field: 'grandTotal',
          type: 'decimal',
          formatFn: this.formatAmount,
          filterOptions: {
            enabled: false
          }
        }
      ],
      rows: [],
      isModalVisible: false,
      bankAccountId: '',
      sunId: '',
      ledgerBalance: { currentBalance: 0.00 },
      datesInit: false,
      selectedCustomerType: 0,
      isNewTx: false,
      selectedLedgerTx: {},
      bankAccounts: [],
      nominalCodes: [],
      disableSearch: true,
      bankAccountColumn: 3
    }
  },

  methods: {
    async load () {
      if (!this.checkDates() || !this.datesInit) {
        return
      }

      try {
        this.isTableLoading = true
        const response = await axios.get(this.dataUrl,
          {
            params: { ...this.tableServerData },
            showerror: true,
            errormessage: 'Ledger Client Totals search failed',
            showload: true
          })

        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
        this.searchComplete = true
      } catch { 
        this.$snapbar.e('Ledger Client Totals search failed')
      } finally {
        this.isTableLoading = false
      }
    },

    // Override mixin version of buildGoodTableQuery since adding extra date params.
    buildGoodTableQuery () {
      const query = { ...this.serverParams.columnFilters }
      Object.keys(query).forEach((key) => { 
        // Remove wildcard option from 'reference' and 'claimstatus' filters so they are queried as "=" rather than "ilike".
        if (key !== 'reference') {
          query[key] = `*${query[key]}*` 
        }
      })
      query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }

      query.liabilityDate = moment(this.createdFrom).format('YYYY-MM-DD')

      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
      return query
    },

    async clearSearchParams () {
      this.defaultFromDate = ''
      await this.initSearchDates()
      this.totalRecords = 0
      this.rows = []
      this.searchComplete = false
    },

    async initSearchDates () {
      if (this.defaultFromDate === '') {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ledger/initDates`)
        this.defaultFromDate = response.data.defaultFromDate
      }
      this.createdFrom = new Date(this.$moment(this.defaultFromDate, 'DD/MM/YYYY'))
      // NB. Don't want to disable any future dates since the TX date could be at least a couple of days in the future for BACS payments; depends on weekends bank holidays etc.
      this.datesInit = true
    },

    checkDates () {
      var datesOk = true
      if (this.createdFrom !== '') {
        this.defaultFromDate = moment(this.createdFrom).format('DD-MM-YYYY')
      }
      return datesOk
    },

    formatAmount (value) {
      return this.formatValue(value)
    },

    async loadBankAccounts () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}bankAccounts/DropdownOfType?bankAccountType=0`, { showload: true })
      this.bankAccounts = response.data
      // console.log('banks: ' + JSON.stringify(this.bankAccounts))
      let bankAccountsDropdown = []
      this.bankAccounts.forEach(bankAccount => {
        bankAccountsDropdown.push({ value: bankAccount.bankAccountId, text: bankAccount.reference })
      });
      // console.log('banks: ' + JSON.stringify(bankAccountsDropdown))
      this.$set(this.columns[this.bankAccountColumn], 'filterOptions', { enabled: true, placeholder: 'All Banks', filterDropdownItems: bankAccountsDropdown })
    }
  },
  watch: {
    async selectedCustomer () {
      this.clearSearchParams()
      this.selectedCustomerType = 1
    }
  },

  async mounted () {
    document.getElementById('createdFrom').style.backgroundColor = 'white'
    await this.initSearchDates()
    await this.loadBankAccounts()
    this.$nextTick(() => {
      this.disabledDates.from = new Date()
      this.disableSearch = false
    })
  }
}
</script>
<style lang="scss">
.pagepointer {
  cursor: default !important;
}

.ledgerTxWordwrap {
  overflow-wrap: break-word; word-wrap: break-word; word-break: break-all !important;
}

</style>
