<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          <help-icon docPath="/paygate-collections/portal/" />Verification Settings
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div class="idb-block-content">
        <!-- Email Address -->
        <b-form-row>
          <b-form-group
            :label-cols="5"
            class="col-sm-5"
            label-class="required"
            horizontal
            label="Email Address"
          >
            <b-form-select
              :options="emailAddresses"
              value-field="id"
              text-field="emailAddress"
              v-model="emailAddress"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group
            :label-cols="5"
            class="col-sm-5"
            label-class="required"
            horizontal
            label="Email Subject"
          >
            <b-form-input
              type="text"
              class="form-control"
              v-model="emailSubject"
              @input="delayTouch($v.emailSubject)"
              id="subjectInput"
            />
            <b-col v-if="$v.emailSubject.$dirty">
              <label
                id="subjectRequiredLabel"
                class="text-danger small"
                v-if="$v.emailSubject.$invalid"
              >Please ensure a subject is provided</label>
            </b-col>
          </b-form-group>
        </b-form-row>
        <b-form-group>
          <label>Email Message Body</label>
          <wysiwyg v-model="emailBody" @input="delayTouch($v.emailBody)"></wysiwyg>
          <b-col v-if="$v.emailBody.$dirty">
            <label
              id="emailBodyRequiredLabel"
              class="text-danger small"
              v-if="$v.emailBody.$invalid"
            >Ensure the Email body includes {####}.</label>
          </b-col>
        </b-form-group>
        <b-form-row v-if="false">
          <b-form-group
            :label-cols="5"
            class="col-sm-5"
            label-class="required"
            horizontal
            label="SMS Message"
          >
            <b-form-input
              type="text"
              class="form-control"
              v-model="smsBody"
              @input="delayTouch($v.smsBody)"
              id="smsInput"
            />
            <b-col v-if="$v.smsBody.$dirty">
              <label
                id="smsBodyRequiredLabel"
                class="text-danger small"
                v-if="$v.smsBody.$invalid"
              >Ensure the SMS body includes {####}.</label>
            </b-col>
          </b-form-group>
        </b-form-row>
      </div>
      <div class="idb-block-footer">
        <b-button
          variant="primary"
          :disabled="$v.$invalid || isLoading"
          @click.prevent="saveConfiguration"
        >Save</b-button>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Portal URL</h2>
      </div>
      <div class="idb-block-content">
        <p>These are the domains in which the portal site can be displayed within a non-paygate environment.</p>
        <vue-good-table
          mode="remote"
          :search-options="{
                      enabled: true
                      }"
          :sort-options="{
                  enabled: false,
                  initialSortBy: { field: 'url', type: 'desc' }
                }"
          :pagination-options="{
                      enabled: true,
                      dropdownAllowAll: false,
                    }"
          :totalRows="totalRecords"
          :columns="columns"
          :rows="urls"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
        >
          <div slot="table-actions">
            <b-button
              @click.prevent="loadUrls"
              class
              variant="link"
              title="Refresh Table"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-redo pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="printTable"
              class
              variant="link"
              title="Print Table"
              v-b-popover.hover.top.d500="'Print out the contents of the Allowed URLs table'"
            >
              <i class="fa fa-print pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="exportTable"
              class
              variant="link"
              title="Export Table"
              v-b-popover.hover.top.d500="'Export the contents of the Emails table'"
            >
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'verified'">
              <i class="fa fa-fw" :class="props.row.verified ? 'fa-check' : 'fa-times'"></i>
            </span>
            <span v-else-if="props.column.field == 'buttons'">
              <b-btn variant="danger" @click.stop="deleteUrl(props.row['portalallowedurlid'])">
                <i class="fa fa-trash-alt"></i> Remove
              </b-btn>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
        </vue-good-table>
      </div>
      <div class="idb-block-footer noprint-footer">
        <button class="btn btn-primary" :disabled="isLoading" @click.prevent="create">Add Url</button>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>API Access Key</h2>
      </div>
      <div class="idb-block-content">
        <b-form-row>
          <b-form-group
            :label-cols="labelCols"
            :class="columnClass"
            horizontal
            label="Generate API Key"
          >
            <b-input-group>
              <b-form-input
                id="apiKeyInput"
                type="text"
                class="form-control"
                v-model.number="apiKey"
                :disabled="true"
              />
              <template v-slot:append>
                <b-button
                  variant="secondary"
                  :disabled="isLoading"
                  @click.prevent="generateApiKey"
                >Get</b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </b-form-row>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Manage Portal Stylesheet</h2>
      </div>
      <div class="idb-block-content">
        <div
          v-if="currentFile.portalStyleId !== '00000000-0000-0000-0000-000000000000' && currentFile.addedDate !== '0001-01-01T00:00:00'"
        >
          <p>
            <strong>Current stylesheet:</strong>
            {{currentFile.stylesheet}}
          </p>
          <p>
            <strong>Last Updated:</strong>
            {{formatDate(currentFile.addedDate)}}
          </p>
        </div>
        <b-container>
          <dropzoneLike
            v-if="!useDropzone"
            ref="serverFileUploader"
            @fileAdded="publicFileAdded"
            @fileRemoved="publicFileRemoved"
            :dropzoneOptions="dropzoneOptions"
            :dropzoneUrl="dropzoneUrl"
            :secureUrl="secureUrl"
            :groupId="group"
          ></dropzoneLike>
        </b-container>
        <vue-dropzone
          id="drop1"
          ref="fileUploader"
          :url="dropzoneUrl"
          :options="dropzoneOptions"
          @vdropzone-success="afterSuccess"
          @vdropzone-file-added="fileAdded"
          @vdropzone-removed-file="fileRemoved"
          @vdropzone-sending="sendingEvent"
          :use-custom-slot="true"
          :accepted-file-types="dropzoneOptions.acceptedFiles"
          :max-file-size-in-m-b="200"
          :useCustomDropzoneOptions="true"
          :use-custom-drop-zone-options="true"
          :useCustomSlot="true"
          v-if="useDropzone"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">{{dropzoneOptions.title}}</h3>
            <div class="subtitle">{{dropzoneOptions.subtitle}}</div>
          </div>
        </vue-dropzone>
      </div>
      <div class="idb-block-footer noprint-footer"></div>
    </div>
    <b-modal
      id="urlModal"
      ref="urlModal"
      title="Allowed URL"
      size="lg"
      hide-header-close
      cancel-variant="secondary-outline"
      lazy
    >
      <b-row class="mt-2">
        <b-col sm="4" class="mt-2">
          <label class="required" for="urlInputInput">Allowed URL</label>
        </b-col>
        <b-col sm="7">
          <b-form-input id="urlInput" type="text" v-model.trim="selectedUrl.url"></b-form-input>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import dropzoneLike from '@/Components/Shared/DropzoneLike.vue'
import EventBus from '@/Lib/eventBus'
import _ from 'lodash'
import Wysiwyg from '@/Components/Collections/EmailWysiwyg.vue'
import { required } from 'vuelidate/lib/validators'
import loading from '@/Assets/Mixins/LoadingMixin'
const bodyRegex = /[\p{L}\p{M}\p{S}\p{N}\p{P}\s*]*\{####\}[\p{L}\p{M}\p{S}\p{N}\p{P}\s*]*/
const bodyValidator = (value) => value ? bodyRegex.test(value) : true
const smsRegex = /^.*\{####\}.*$/
const smsValidator = (value) => value ? smsRegex.test(value) : true
const touchMap = new WeakMap()
export default {
  mixins: [loading],
  components: {
    dropzoneLike,
    Wysiwyg
  },
  data () {
    return {
      fileFormats: ['css'], // xml, json
      publicFiles: [],
      fileIds: [],
      fileFormat: 'css',
      bearerToken: '',
      group: null,
      dropzoneOptions: {
        maxFilesize: 5, // MB
        maxFiles: 4,
        acceptedFiles: '.css',
        dictDefaultMessage: '<i class="fa fa-cloud-upload fa-4x"></i><br/><br/><h3 class="dropzone-custom-title">Drop a CSS file here.</h3><div class="subtitle">Or click to select a file to upload.</div>',
        url: '',
        title: 'Drag and drop to upload stylesheet',
        subtitle: '...or click to select a file from your computer'
      },
      currentFile: {
        portalStyleId: '',
        fileName: '',
        addedDate: null
      },
      useDropzone: true,
      selectedUrl: {
        url: '',
        portalAllowedUrlId: null
      },
      urls: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: 'url', type: 'asc' }],
        page: 1,
        perPage: 10,
        searchKey: ''
      },
      columns: [
        {
          hidden: true,
          field: 'portalAllowedUrlId'
        },
        {
          label: 'URL',
          field: 'url'
        },
        {
          label: '',
          field: 'buttons',
          sortable: false
        }],
      emailAddresses: [],
      emailAddress: '00000000-0000-0000-0000-000000000000',
      emailBody: 'Your verification code is {####}.',
      emailSubject: 'Verify',
      smsBody: 'Your verification code is {####}.',
      apiKey: '',
      labelCols: ''
    }
  },
  computed: {
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = this.$store.getters.customerGroups
      }
      return ret
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    importEnabled () {
      return this.fileIds.length > 0
    },
    dropzoneUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}portalstylesheet?paygateId=${this.paygateId}`
    },
    secureUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}portalstylesheet/secure`
    }
  },
  async created () {
    this.dropzoneOptions.url = this.dropzoneUrl
    this.dropzoneOptions.secureUrl = this.secureUrl
  },
  async mounted () {
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
      })
    try {
      var response = await axios.get(this.dropzoneUrl, {params: {paygateId: this.paygateId}})
      if (response) {
        this.currentFile = response.data
      }
    } catch (e) {
      console.log(e)
    }
    await this.loadGroups(this.paygateId)

    const onPaygateChange = (paygateId) => {
      this.loadGroups(paygateId)
    }

    EventBus.$on('paygateIdChange', onPaygateChange)

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })
    const handleModalHide = async (bvEvent, modalId) => {
      const target = bvEvent.target.id
      switch (target) {
        case 'urlModal':
          if (bvEvent.trigger === 'ok') {
            let isNew = this.selectedUrl.onboardingAllowedUrlId === null
            if (isNew) {
              const newUrl = await this.insertUrlFromModal()
              this.urls.splice(0, 0, newUrl)
            } else {
              const updateUrl = await this.updateUrlFromModal()
              for (var i = 0; i < this.urls.length; i++) {
                if (this.urls[i].onboardingAllowedUrlId === updateUrl.onboardingAllowedUrlId) {
                  this.urls[i].url = updateUrl.url
                }
              }
            }
          }
          break
        default:
          break
      }
    }
    this.$root.$on('bv::modal::hide', handleModalHide)
    this.$once('hook:beforeDestroy', () => {
      this.$root.$off('bv::modal::hide', handleModalHide)
    })
    this.loadUrls()
    try {
      const emailResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}emails?includeUnverified=false`, { params: { paygateId: this.paygateId } })
      let emailData = emailResponse.data.data.map(resData => ({ emailAddress: resData.emailAddress, id: resData.id }))
      emailData.unshift({ id: '00000000-0000-0000-0000-000000000000', emailAddress: 'Default Email Address' })
      this.emailAddresses = emailData
    } catch {
      this.$toastr.e('Could not load available email addresses')
    }
    await this.loadConfiguration()
  },
  methods: {
    afterSuccess (file, response) {
      this.currentFile = response
    },
    fileAdded (file) {
      this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
    },
    fileRemoved (file, error, xhr) {

    },
    sendingEvent (file, xhr, formData) {
      formData.append('paygateId', this.paygateId)
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      if (date === undefined || date === null){
        return new Date()
      }
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var retVal = `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      return retVal
    },
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
      if (this.$store.getters.customerGroups !== null && this.$store.getters.customerGroups.length > 0) {
        this.group = this.$store.getters.customerGroups[0].groupId
      }
    },
    async create () {
      this.selectedUrl = {
        url: '',
        portalAllowedUrlId: null
      }
      this.$refs['urlModal'].show()
    },
    async insertUrlFromModal () {
      let url = process.env.VUE_APP_DDMS_API_URL + 'portalurl'
      const paygateId = this.paygateId
      this.selectedUrl.portalAllowedUrlId = '00000000-0000-0000-0000-000000000000'
      if (paygateId) {
        url += `?paygateId=${paygateId}`
      }
      try {
        var response = await axios.post(url, this.selectedUrl, { showload: true, params: { paygateId: this.paygateId } })
        this.$toastr.s('Portal Url added', 'Success')
        return response.data
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to add portal Url', 'An error occurred')
        return null
      }
    },
    async updateUrlFromModal () {
      let url = process.env.VUE_APP_DDMS_API_URL + 'portalurl/' + this.selectedUrl.portalAllowedUrlId
      const paygateId = this.paygateId
      if (paygateId) {
        url += `?paygateId=${paygateId}`
      }
      try {
        var response = await axios.put(url, this.selectedUrl, { showload: true, params: { paygateId: this.paygateId } })
        this.$toastr.s('Portal Url updated', 'Success')
        return response.data
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to update Portal Url', 'An error occurred')
        return null
      }
    },
    deleteUrl: _.debounce(async function (id) {
      let url = process.env.VUE_APP_DDMS_API_URL + 'portalurl/' + id
      const paygateId = this.paygateId
      if (paygateId) {
        url += `?paygateId=${paygateId}`
      }
      try {
        var response = await axios.delete(url, { showload: true, params: { paygateId: this.paygateId } })
        console.log(response.data.id)
        this.urls = this.urls.filter(x => x.id !== response.data.id)
        this.$toastr.s('Portal Url has been deleted', 'Success')
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to delete Portal Url', 'An error occurred')
      }
    }, 300),
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    exportTable () {
      console.error('exportTable not implemented yet')
    },
    printTable () {
      console.log('print table')
    },
    getQuery () {
      var query = {}
      query.sort = this.serverParams.sort.field + ':' + this.serverParams.sort.type
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }
      return query
    },
    loadUrls: _.debounce(async function () {
      let getUrlsUrl = process.env.VUE_APP_DDMS_API_URL + 'portalurl'
      const query = this.getQuery()
      const paygateId = this.paygateId
      if (paygateId) {
        query.paygateId = paygateId
      }
      const response = await axios.get(getUrlsUrl, { params: query, showload: true })
      this.urls = response.data.item1
      this.totalRows = response.data.item2
    }, 300),
    onRowClick (event) {
      this.selectedUrl = Object.assign({}, event.row)
      this.$refs['urlModal'].show()
    },
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadUrls()
    },
    onSortChange (params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      })
      this.loadUrls()
    },
    onColumnFilter (params) {
      this.updateParams(params)
      this.loadUrls()
    },
    onPerPageChange (params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadUrls()
    },
    onSearch (params) {
      this.serverParams.searchKey = params.searchTerm
      this.loadUrls()
    },
    async loadConfiguration () {
      var response = await axios.get(process.env.VUE_APP_DDMS_API_URL + 'portalconfig', { showload: true, params: { paygateId: this.paygateId } })
      console.log(response)
      if (response.data) {
        this.emailAddress = response.data.domainEmailId
      } else {
        this.emailAddress = '00000000-0000-0000-0000-000000000000'
      }
    },
    async saveConfiguration () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        var response = await axios.post(process.env.VUE_APP_DDMS_API_URL + 'portalconfig', {
          paygateId: this.paygateId,
          domainEmailId: this.emailAddress,
          emailMessage: 'Your verification code is {####}.',
          emailMessageSubject: 'Verify',
          smsMessage: 'Your verification code is {####}.'
        }, { showload: true, params: { paygateId: this.paygateId } })
        if (response.data) {
          this.$toastr.s('Settings updated')
        } else {
          this.$toastr.e('Failed to update settings')
        }
      } else {
        this.$toastr.e('There are errors on the form, please correct and try again.')
      }
    },
    delayTouch ($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    async generateApiKey () {
      let url = process.env.VUE_APP_DDMS_API_URL + 'portalconfig/apikey/'
      var result = await axios.post(url, {}, { showload: true, params: { paygateId: this.paygateId } })
      this.apiKey = result.data
      navigator.clipboard.writeText(this.apiKey)
      this.$toastr.s('The API Key has been copied', 'API Key Copied')
    }
  },
  validations: {
    emailBody: { required, bodyValidator },
    emailSubject: { required },
    smsBody: { required, smsValidator }
  }
}
</script>
<style scoped>
</style>
