<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Upload Bankline File<span class="pull-right"><a :href="`${docUrl}/automation/logging/`" target="_blank"><i class="far fa-question-circle"></i></a></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div class="row" v-if="showDropZone" >
            <div class="form-group col-md-12 col-sm-12">
              <vue-dropzone
                :useCustomDropzoneOptions="true"
                ref="myVueDropzone"
                id="dropzone"
                @vdropzone-file-added="vfileAdded"
                @vdropzone-complete="vcomplete"
                @vdropzone-success="uploadSuccess"
                @vdropzone-error="verror"
                :options="dropzoneOptions">
              </vue-dropzone>
            </div>
          </div>
          <div v-if="isProcessing" class="row mb-2" v-for="file in processingFiles" :key="file.name" >
            <div class="col-1">
              <div class="spinner-border" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
            <div class="col-10">Processing file: {{file.name}}. Please wait. </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import vue2Dropzone from 'vue2-dropzone'
import Auth from '@/Assets/Components/Authentication/auth'
export default {
  name: 'UploadBanklineFile',
  components: {
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      authToken: '',
      showDropZone: true,
      processingFiles: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_PLATFORM_API_URL}reconciliation/uploadFile`,
        maxFilesize: 30, // MB
        maxFiles: 1,
        addRemoveLinks: true,
        thumbnailWidth: 64, // px
        thumbnailHeight: 64,
        acceptedFiles: '.csv',
        dictDefaultMessage: '<i class="fas fa-cloud-upload-alt fa-4x"></i><br/><br/><h3 class="text-primary">Drop a Bankline file in CSV format here.</h3><div class="subtitle">Or click to select a file to upload.</div>'
      },
      isProcessing: false
    }
  },
  methods: {
    vcomplete (file) {
    },

    vfileAdded (file) {
    },

    async uploadSuccess (file, response) {
      this.isProcessing = true
      this.processingFiles.push(file)
      var fileDataProcessed = false
      try {
        // For some reason, a + sign is stripped from the query string so encode it with it's ASCII equivalent. Only QA could come up with a filename containing a + sign!
        const encodedFilename = response.filename.replace('+', '%2B')
        var saveResponse = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}reconciliation/importBanklineFile?filename=${encodedFilename}`)
        fileDataProcessed = saveResponse.data.success
        if (saveResponse.data.success) {
          this.$toastr.s('Successfully uploaded Bankline transaction file')
        } else {
          this.isProcessing = false
          this.processingFiles = []
          this.$snapbar.e(saveResponse.data.errorMessage)
        }
      } catch (e) {
        this.isProcessing = false
        this.processingFiles = []
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to upload bankline files.')
        } else if (e.response && e.response.status === 400) {
          this.$snapbar.e(e.response.data.responseObj.errorMessage)
          console.log(e.response)
        } else {
          this.$snapbar.e(`Error uploading the bankline file(s) - ${e.message}`)
        }
      }
      if (fileDataProcessed) {
        if (saveResponse.data.errorMessage !== undefined && saveResponse.data.errorMessage.length > 0) {
          this.$store.dispatch('setImportFileResponse', saveResponse.data)
        }
        // redirectId is set so redirect to either TX or balance page.
        if (saveResponse.data.redirectId !== undefined && saveResponse.data.redirectId !== null) {
          this.$router.push(`/admin/bandr/${saveResponse.data.redirectId}/${saveResponse.data.messageId}`)
        } else {
          this.$router.push('/admin/bandr/banklinefilelist')
        }
      }
    },

    verror (file, response) {
      console.error('Dropzone:  verror')
      this.isProcessing = false
      this.$snapbar.e('File read error (MP0013-DZ). Ensure a .csv file has been selected for upload.')
    },

    async getAuthToken () {
      const token = await Auth.getAccessToken()
      if (token) {
        if (this.$refs.myVueDropzone) {
          this.$refs.myVueDropzone.setOption('headers', { Authorization: 'Bearer ' + token })
        }
      }
    }
  },
  created: async function () {
    this.getAuthToken()
  }
}
</script>
<style scoped>
</style>
