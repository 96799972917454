<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Potential Settlements
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="form-row">
              <div class="form-group col-md-2" :class="{ invalid: $v.date.$error }">
                <label for="to" class="required">To</label>
                <vuejsDatepicker
                  name="to"
                  id="to"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :use-utc="true"
                  :disabled-dates="{
                    from: new Date()
                    }"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="$v.date.$model"
                ></vuejsDatepicker>
                <validation-messages v-model="$v.date" name="from"></validation-messages>
              </div>

              <div class="form-group col-md-4">
                <label>Bank Accounts</label>

                <multi-select
                  v-model="selectedBankAccounts"
                  :values="bankAccounts"
                  :options="bankAccountOptions"
                  @change="loadCustomers"
                ></multi-select>
              </div>
              <div class="form-group col-md-4" :class="{ invalid: $v.selectedChildren.$error }">
                <label>
                  Clients
                  <span class="text-danger mr-2">*</span>
                </label>

                <multi-select
                  v-model="$v.selectedChildren.$model"
                  :values="customers"
                  :options="customerOptions"
                ></multi-select>
                <validation-messages v-model="$v.selectedChildren" name="Client"></validation-messages>
              </div>
              <div class="form-group col-md-2">
                <label class="form-label">On Hold</label>
                <div class="col">
                  <p-check
                    class="p-switch p-fill"
                    color="primary"
                    v-model="onHold"
                    @change="onHoldChanged"
                  ></p-check>
                </div>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click="checkValidation() && load()"
              :disabled="isLoading"
            >Generate Potential Settlements</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loaded">
      <div class="row">
        <div class="col-md-12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>Generated Settlements</h2>
            </div>
            <div class="idb-block-content">
              <div class="row">
                <div class="col-md-12">
                  <vue-good-table
                    :rows="settlements"
                    :columns="columns"
                    :pagination-options="{
                      enabled:true,
                      jumpFirstOrLast:true,
                    }"
                    :sort-options="sortOptions"
                    styleClass="vgt-table striped bordered"
                    :select-options="{ enabled: !onHold, selectionText: 'Settlements selected', selectAllByPage:false }"
                    @on-selected-rows-change="handleSelectedRowChangeLocal"
                    ref="settlementTable"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span
                        v-if="props.column.field !== 'name' && props.column.field !== 'actions'"
                        :class="tdClass(props.row, props.column.field)"
                      >{{props.formattedRow[props.column.field]}}</span>
                      <span v-else-if="props.column.field === 'actions'">
                        <button
                          class="btn btn-link"
                          type="button"
                          @click.prevent="details(props.row,$event)"
                        >Details</button>
                      </span>
                      <span v-else>{{props.formattedRow[props.column.field]}}</span>
                    </template>
                    <div slot="selected-row-actions" class="mr-15">
                      <span
                        :class="moneyClass(totalToClient)"
                        class="mr-20"
                      >Amount to Clients: £{{moneyFormat(totalToClient)}}</span>
                      <span
                        :class="moneyClass(totalSelected)"
                        class="mr-20"
                      >Selected Collection: £{{moneyFormat(totalSelected)}}</span>
                      <span>
                        Selected Difference:
                        <span
                          :class="moneyClass(totalDifference)"
                        >£{{moneyFormat(totalDifference)}}</span>
                      </span>
                    </div>
                  </vue-good-table>
                </div>
              </div>
              <div class="row"></div>
            </div>
            <div class="idb-block-footer" v-if="!onHold">
              <button
                type="submit"
                class="btn btn-success"
                @click="submit()"
                :disabled="isLoading || disableSubmission"
              >Submit Potential Settlements</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <settlement-details
      :params="detailParams"
      :loadUrl="detailLoadUrl"
      :name="name"
      :model="detailRequestData"
    ></settlement-details>
  </div>
</template>

<script>
// Third Party
import moment from 'moment'
import axios from 'axios'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'

// Validations
import {
  required
} from 'vuelidate/lib/validators'

// Components
import SettlementDetails from '@/Components/Platform/Customer/Settlements/Details/SettlementDetails'
import MultiSelect from '@/Assets/Components/MultiSelect'

export default {
  mixins: [loading, settlementMixin],
  components: {
    SettlementDetails,
    MultiSelect
  },
  name: 'Settlement',
  computed: {
    detailLoadUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}Settlement/GenerateDetails`
    },
    requestData () {
      return {
        date: this.date,
        customers: this.$v.selectedChildren.$model,
        bankAccounts: this.selectedBankAccounts,
        onHold: this.onHold
      }
    },
    detailRequestData () {
      return {
        date: this.date
      }
    }
  },
  data () {
    return {
      date: null,
      settlements: [],
      loaded: false,
      columns: [
        {
          label: 'Client Name',
          field: 'clientName',
          sortable: true
        },
        {
          label: 'Settlement Type',
          field: 'type',
          sortable: true,
          sortFn: this.settlementTypeSort
        },
        {
          label: 'Collection Amount (£)',
          field: 'collectionAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },
        {
          label: 'Failed Collection Amount (£)',
          field: 'failedCollectionAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },
        {
          label: 'Indemnity Claim Amount (£)',
          field: 'indemnityClaimAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },
        {
          label: 'Netdown Amount (£)',
          field: 'netdownAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },
        // {
        //   label: 'Refund Amount (£)',
        //   field: 'refundAmount',
        //   formatFn: this.moneyFormat,
        //   tdClass: 'text-right',
        //   type: 'number'
        // },
        {
          label: 'Connected Merchant Charges (£)',
          field: 'connectedMerchantCharges',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number',
          hidden: true
        },
        {
          label: 'Service Charges (£)',
          field: 'serviceCharges',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number',
          hidden: true
        },
        {
          label: 'VAT (£)',
          field: 'vatAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number',
          hidden: true
        },
        {
          label: 'Client Settlement Payment (£)',
          field: 'clientSettlementPayment',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },
        {
          label: '',
          field: 'actions',
          sortable: false
        }
      ],
      detailParams: null,
      disableSubmission: true,
      name: null,
      selectedChildren: [],
      customers: [],
      bankAccounts: [],
      selectedBankAccounts: [],
      connectedMerchantSettlement: false,
      customerOptions: {
        search: true,
        selectAll: true,
        texts: {
          placeholder: 'Select Clients',
          search: 'Search Clients'
        }
      },
      bankAccountOptions: {
        search: true,
        selectAll: true,
        texts: {
          placeholder: 'Select Bank Accounts',
          search: 'Search Bank Accounts'
        }
      },
      sortOptions: { enabled: true, initialSortBy: { field: 'clientName', type: 'asc' } },
      onHold: false
    }
  },
  methods: {
    async load () {
      try {
        this.$snapbar.hide()
        this.loaded = false
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/Generate`,
          this.requestData,
          {
            showload: true,
            showerror: true,
            errormessage: 'Settlements failed to generate'
          })

        this.settlements = response.data.map(d => {
          if (d.clientSettlementPayment < 0) {
            d.vgtDisabled = true
          }
          return d
        })

        this.loaded = true
      } catch { }
    },
    async submit () {
      try {
        var selectedRecords = this.$refs.settlementTable.selectedRows

        if (selectedRecords.length === 0) {
          this.$toastr.w('No settlements selected')
          return
        }

        var records = selectedRecords.sort((a, b) => a.paygateId.localeCompare(b.paygateId)).map(sr => `${sr.paygateId}${sr.clientSettlementPayment.toFixed(2)}`)
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/SubmitPotential`, { ...this.requestData, composite: records.join(''), customers: selectedRecords.map(r => r.paygateId) }, {
          showload: true,
          showerror: true,
          errormessage: 'Settlements failed to submit'
        })

        this.settlements = []

        this.handleSelectedRowChange({ selectedRows: [] })

        this.loaded = false

        this.$toastr.s(
          'Settlements Submitted',
          'Submitted'
        )
      } catch { }
    },
    details (row, event) {
      event.stopPropagation()
      this.detailParams = {
        paygateid: row.paygateId
      }
      this.name = row.clientName
      this.$bvModal.show('settlementModal')
    },
    async loadCustomers () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/GetCustomers`, {
          params: { selectedBankAccounts: this.selectedBankAccounts, onHold: this.onHold },
          showerror: true,
          errormessage: 'Clients Failed To Load'
        })

        this.customers = response.data.map((v) => { return { value: v.id, name: v.label } })
        this.selectedChildren.splice(0, this.selectedChildren.length)
        this.$v.selectedChildren.$reset()
      } catch { }
    },
    async loadBankAccounts () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/BankAccounts`, {
          params: { selectedChildren: this.selectedChildren },
          showerror: true,
          errormessage: 'Failed to get bank accounts'
        })

        this.bankAccounts = response.data.map((v) => { return { value: v.id, name: v.label } })
      } catch { }
    },
    onHoldChanged () {
      this.settlements.splice(0, this.settlements.length)
      this.loaded = false
      this.loadCustomers()
    },
    handleSelectedRowChangeLocal (selectedRows) {
      this.handleSelectedRowChange(selectedRows, true)
    }
  },
  async created () {
    var date = moment().utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    this.date = moment(date).toDate()

    this.loadCustomers()
    this.loadBankAccounts()
  },
  validations: {
    date: { required },
    selectedChildren: {
      select: required
    }
  }
}

</script>

<style lang="scss">
</style>
