<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline Account Statement<span class="pull-right"></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-1 ">
              Filename
            </div>
            <div class="col-3">
              {{fileData.filename}}
            </div>
            <div class="col-1 offset-1">
              Upload Date
            </div>
            <div class="col-2">
              {{fileData.displayImportDate}}
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-1 ">
              Origin
            </div>
            <div class="col-3">
              <b-badge pill variant="success">Uploaded from Bankline</b-badge>
            </div>
            <div class="col-1 offset-1">
              Status
            </div>
            <div class="col-2">
              <b-badge pill v-if="fileData.fileStatus === 'Unprocessed'" variant="secondary">Unprocessed</b-badge>
              <b-badge pill v-else-if="fileData.fileStatus === 'Processing'" variant="info">Processing</b-badge>
              <b-badge pill v-else-if="fileData.fileStatus === 'Processed'" variant="success">Processed</b-badge>
              <b-badge pill v-else-if="fileData.fileStatus === 'Processed With Warnings'" variant="warning">Processed With Warnings</b-badge>
              <b-badge pill v-else-if="fileData.fileStatus === 'Processed With Errors'" variant="danger">Processed With Errors</b-badge>
              <b-badge pill v-else-if="fileData.fileStatus === 'Duplicate'" variant="danger">Duplicate</b-badge>
              <b-badge pill v-else-if="fileData.fileStatus === 'Archived'" variant="warning">Archived</b-badge>
            </div>
            <div v-if="this.fileHasBeenProcessed" class="col-2">
              Processed Date
            </div>
            <div v-if="this.fileHasBeenProcessed" class="col-2">
              {{fileData.displayProcessedDate}}
            </div>
          </div>
        </div>
      </div>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>File Contents</h2>
        </div>
        <div class="idb-block-content">
          <div>
            <VuePerfectScrollbar class="scroll-area" :settings="settings">
              <vue-good-table
              :isLoading.sync="isTableLoading"
              mode="remote"
              ref="table"
              :columns="columns"
              :rows="rows"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              :totalRows="totalRecords"
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              styleClass="vgt-table striped bordered table-hover"
              :lineNumbers="false">
            <template slot="table-row" slot-scope="props">
                <span v-if = "props.column.field === 'reconciliationStatus'">
                  <b-badge pill v-bind:variant="getStatusVariant(props.row.reconciliationStatus)">{{ props.row.reconciliationStatus }}</b-badge>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
            </template>
              <div slot="table-actions">
                <table-actions
                  :data-url="dataUrl"
                  :export-data="tableServerData"
                  :print-enabled="false"
                  :table-amount="totalRecords"
                  :export-name="exportFilename"
                  :reload-table="load"
                  :table-columns="columns"
                  :clear-table-filters="clearTableFilters"
                >
                </table-actions>
              </div>
              </vue-good-table>
            </VuePerfectScrollbar>
          </div>
        </div>
        <div v-if="this.fileDetailsLoaded" class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div v-if="!this.fileHasBeenProcessed && vueAppVersion >= 110" class="btn-group mr-2" role="group">
                <button id="btnGroupDrop1" @click="reconcileBanklineFile" type="button" class="btn btn-primary" :disabled="isReconciliationRunning">
                  <i class="fa fa-play mr-2"></i>Reconcile Bankline File
                </button>
              </div>
              <div class="btn-group mr-2" role="group">
                <button id="btnGroupDrop1" @click="uploadBanklineFile" type="button" class="btn btn-primary" :disabled="isReconciliationRunning">
                  <i class="fa fa-upload mr-2"></i>Upload Bankline File
                </button>
              </div>
              <div v-if="vueAppVersion >= 110" class="btn-group mr-2" role="group">
                <button id="btnGroupDrop1" @click="viewAllBalances" type="button" class="btn btn-outline-primary" :disabled="isReconciliationRunning">
                  <i class="fa fa-list mr-2"></i>View Bank Balances
                </button>
              </div>
              <div v-if="this.fileHasBeenProcessed && vueAppVersion >= 110" class="btn-group mr-2">
                <button id="btnGroupDrop1" @click="viewProcessingLog" type="button" class="btn btn-outline-primary" :disabled="isReconciliationRunning">
                  <i class="fa fa-play mr-2"></i>View Processing Log
                </button>
              </div>
              <div v-if="!this.fileHasBeenProcessed" class="btn-group pull-right">
                <button id="btnDeleteFile" @click="deleteFile" type="button" class="btn btn-danger" :disabled="isReconciliationRunning">
                  <i class="fa fa-trash mr-2"></i>Delete File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
    <div>
      <viewProcessingLog
        v-model="isModalVisible"
        :processingLog="fileData.processingLog"
        :reportTitle="fileData.filename"
        @close="closeModal"
      ></viewProcessingLog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
import ledgerTransactionTypes from '@/Assets/Constants/ledgerTransactionTypes'
import ViewProcessingLog from '@/Views/JFTP/Reconcilliation/Bankline/ViewProcessingLog.vue'

export default {
  mixins: [tableFilterMixin, loading],
  components: {
    VuePerfectScrollbar,
    viewProcessingLog: ViewProcessingLog
  },
  data () {
    return {
      settings: {
      },
      fileData: {},
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      rows: [],
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'Status',
          field: 'reconciliationStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'All Status',
            filterDropdownItems: [
              { value: '0', text: 'Unprocessed' },
              { value: '1', text: 'Unreconciled' },
              { value: '2', text: 'Reconciled' },
              { value: '3', text: 'Duplicate Match' },
              { value: '4', text: 'Processed' }
            ]
          }
        },
        {
          label: 'Sort Code',
          field: 'sortCode',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account Number',
          field: 'accountNumber',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account Short Name',
          field: 'accountShortName',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Bank Name',
          field: 'bankName',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'TX Date',
          field: 'transactionDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Nar #1',
          field: 'narrative1',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Nar #2',
          field: 'narrative2',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Nar #3',
          field: 'narrative3',
          hidden: false,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Narrative #4',
          field: 'narrative4',
          hidden: true,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Narrative #5',
          field: 'narrative5',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Message Type',
          field: 'ledgerTxType',
          filterOptions: {
            enabled: true,
            placeholder: 'All Types',
            filterDropdownItems: Object.values(ledgerTransactionTypes)
          }
        },
        {
          label: 'Debit',
          field: 'debit',
          type: 'decimal',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Credit',
          field: 'credit',
          type: 'decimal',
          filterOptions: {
            enabled: true
          }
        }
      ],
      totalRecords: 0,
      isReconciliationRunning: false,
      isModalVisible: false,
      processingLog: '',
      filename: '',
      fileDetailsLoaded: false,
      fileDeleted: false
    }
  },
  computed: {
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}reconciliation/banklineTransactions?fileId=${this.id}`
    },
    tableServerData () {
      return this.buildGoodTableQuery()
    },
    exportFilename () {
      let filename = ''
      if (this.fileData.filename !== undefined && this.fileData.filename !== null) {
        const filenameLength = this.fileData.filename.length
        // Don't want to add the file extension to export filename otherwise exported filename will end with ".csv.csv"!
        filename = filenameLength > 4 ? this.fileData.filename.substring(0, filenameLength - 4) : this.fileData.filename
      }
      return 'BankLineTransactions ' + filename
    },
    ...mapGetters(['vueAppVersion']),
    fileHasBeenProcessed () {
      return this.fileData.fileStatus.startsWith('Processed')
      //return this.fileData.fileStatus === 'Processed' || this.fileData.fileStatus === 'Processed With Warnings' 
    }
  },
  methods: {
    async loadFileDetails () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}reconciliation/banklineFile?fileId=${this.id}`, { showload: true })
        this.fileData = response.data
        var importFileResponse = this.$store.getters.importFileResponse
        if (importFileResponse !== undefined && importFileResponse !== null && importFileResponse.errorMessage !== undefined && importFileResponse.errorMessage.length > 0) {
          this.$snapbar.w(importFileResponse.errorMessage)
        }
        this.$store.dispatch('setImportFileResponse', null)
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
      }
    },

    async load () {
      try {
        this.isTableLoading = true
        const response = await axios.get(this.dataUrl,
          {
            params: { ...this.tableServerData },
            showerror: true,
            errormessage: 'Bankline transactions search failed'
          })

        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
        this.rows = []
        this.totalRecords = 0
      } finally {
        this.isTableLoading = false
      }
    },

    getStatusVariant (fileStatus) {
      var variant = ''
      switch (fileStatus) {
        case 'Unprocessed':
          variant = 'secondary'
          break
        case 'Unreconciled':
          variant = 'danger'
          break
        case 'Reconciled':
        case 'Processed':
          variant = 'success'
          break
        case 'DuplicateMatch':
          variant = 'warning'
          break
      }
      return variant
    },

    async viewAllBalances () {
      this.$router.push('/admin/bandr/bankledgerbalances')
    },

    async uploadBanklineFile () {
      this.$router.push('/admin/bandr/bankline/uploadfile')
    },

    async deleteFile () {
      const result = await swal.fire({
        title: 'Delete Bankline file',
        text: 'Are you sure you want to permanently delete this Bankline file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}reconciliation/deleteBanklineFile/${this.id}`, { showload: true })
          if (res) {
            this.fileDeleted = true
            this.$toastr.s('The Bankline file was successfully deleted.')
            this.$router.push('/admin/bandr/banklinefilelist')
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this file.')
          } else {
            this.$snapbar.e(`Could not delete this file - ${e.message}`)
          }
        }
      }
    },

    async reconcileBanklineFile () {
      if (this.fileData.fileStatus === 'Duplicate') {
        const result = await swal.fire({
          title: 'Duplicate Bankline file',
          text: 'A Bankline file with the same transactions has already been processed. Are you sure you want to process this duplicate Bankline file?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })

        if (!result.isConfirmed) {
          return
        }
      }

      try {
        this.isReconciliationRunning = true
        this.fileData.fileStatus = 'Processing'
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}reconciliation/reconcileTransactions?fileId=${this.fileData.fileId}`, { showload: true })
        if (response.data.success) {
          if (response.data.responseMessages.length === 0) {
            this.$snapbar.s('Reconciliation process has completed successfully. Please see Processing Log for details.')
          } else {
            this.$snapbar.w(response.data.responseMessages[0].messageText)
          }
          await this.loadFileDetails()
          await this.load()
        } else {
          this.$snapbar.e(response.data.errorMessage)
          this.isReconciliationRunning = false
          this.fileData.fileStatus = 'Unprocessed'
        }
        this.isReconciliationRunning = false
      } catch (e) {
        this.isReconciliationRunning = false
        this.fileData.fileStatus = 'Unprocessed'
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to reconcile Bankline file data.')
        } else {
          this.$snapbar.e(`Could not reconcile file data - ${e.message}`)
        }
      }
    },

    viewProcessingLog () {
      this.isModalVisible = true
    },

    closeModal () {
      this.isModalVisible = false
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (this.fileDeleted) {
      next()
    }
    else if (this.fileData.fileStatus !== undefined && this.fileData.fileStatus !== null && this.fileData.fileStatus === 'Unprocessed') {
      var result = await swal.fire({
        title: 'Navigation',
        text: 'This Bankline statement file has not been processed yet. Do you want to navigate away from this page?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        next()
      }
    } else {
      next()
    }
  },

  async mounted () {
    await this.loadFileDetails()
    await this.load()
    this.fileDetailsLoaded = true
  }
}
</script>
<style scoped>
  td {
    cursor: pointer;
  }
</style>
