<template>
  <div id="viewSubmissionSummary">
    <div style="width: 98%; margin:0 auto;">
      <p v-html="submissionSummary"></p>
    </div>
    <div class="idb-block-footer">
      <div class="row form-group">
        <div class="col-md-2">
          <button type="button" class="btn btn-primary" v-on:click="downloadXml">Download XML</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import { mapGetters } from 'vuex'
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [
    bacsMixin
  ],
  computed: {
    ...mapGetters(['selectedCustomer']),
    isSystemUser () { return this.$store.getters.isInRole(roles.LZAdmin) || this.$store.getters.isInRole(roles.Finance) || this.$store.getters.isInRole(roles.Implementations) }
  },
  data () {
    return {
      submissionSummary: '',
      submissionId: '',
      paygateId: '',
      fileNumber: 0
    }
  },

  methods: {
    async getSubmissionSummary () {
      var url = `${process.env.VUE_APP_BACS_API_URL}bacs/submission/getSubmissionSummary?submissionId=${this.submissionId}&selectedPaygateId=${this.paygateId}&fileNumber=${this.fileNumber}`
      var response = await axios.get(url)
      this.submissionSummary = response.data
    },

    async downloadXml () {
      var url = `${process.env.VUE_APP_BACS_API_URL}bacs/submission/getSubmissionSummaryXml?submissionId=${this.submissionId}&fileNumber=${this.fileNumber}`
      var response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
        showload: true
      })

      this.saveFile(response)
    }
  },

  async mounted () {
    this.submissionId = this.$route.query.submissionId
    this.fileNumber = this.$route.query.fileNumber
    this.paygateId = this.$route.query.paygateId
    await this.getSubmissionSummary()
  }
}
</script>
