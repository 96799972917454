<template>
  <div>
    <b-modal
      id="reverseSettlementModal"
      ref="reverseSettlementModal"
      variant="primary"
      centered
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      title="Reverse Settlement"
      size="xl"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-danger">Are you sure you want to reverse this settlement?</div>
        </div>
      </div>
      <div class="row" v-if="settlement">
        <div class="col-md-12">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Client name</th>
                <th scope="col">Created Date</th>
                <th scope="col">Collection Amount (£)</th>
                <th scope="col">Failed Collection Amount (£)</th>
                <th scope="col">Indemnity Claim Amount (£)</th>
                <th scope="col">Netdown Amount (£)</th>
                <!-- <th scope="col">Refund Amount (£)</th> -->
                <!-- <th scope="col">Service Charges (£)</th>
                <th scope="col">VAT (£)</th>-->
                <th scope="col">Client Settlement Payment (£)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{settlement.clientName}}</td>
                <td>{{settlement.creationDate | formatDate('DD/MM/yyyy HH:mm:ss')}}</td>
                <td
                  :class="tdClass(settlement, 'collectionAmount')"
                >{{moneyFormat(settlement.collectionAmount)}}</td>
                <td
                  :class="tdClass(settlement, 'failedCollectionAmount')"
                >{{moneyFormat(settlement.failedCollectionAmount)}}</td>
                <td
                  :class="tdClass(settlement, 'indemnityClaimAmount')"
                >{{moneyFormat(settlement.indemnityClaimAmount)}}</td>
                <td
                  :class="tdClass(settlement, 'netdownAmount')"
                >{{moneyFormat(settlement.netdownAmount)}}</td>
                <!-- <td
                  :class="tdClass(settlement, 'refundAmount')"
                >{{moneyFormat(settlement.refundAmount)}}</td>-->

                <!-- <td
                  :class="tdClass(settlement, 'serviceCharges')"
                >{{moneyFormat(settlement.serviceCharges)}}</td>
                <td :class="tdClass(settlement, 'vatAmount')">{{moneyFormat(settlement.vatAmount)}}</td>-->
                <td
                  :class="tdClass(settlement, 'clientSettlementPayment')"
                >{{moneyFormat(settlement.clientSettlementPayment)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <template slot="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mr-10"
          @click="hideModal"
          :disabled="isLoading"
        >Cancel</button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="isLoading"
          @click="reverseSettlement"
        >Reverse Settlement</button>
      </template>
    </b-modal>
  </div>
</template>
<script>
// Third Party
import axios from 'axios'

// Mixins
import loading from '@/Assets/Mixins/LoadingMixin'
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'

export default {
  name: 'ReverseSettlementDetails',
  props: {
    settlement: {
      type: Object
    }
  },
  mixins: [loading, settlementMixin],
  data () {
    return {
      settlementDetails: [],
      loading: false
    }
  },
  methods: {
    hideModal () {
      this.$refs.reverseSettlementModal.hide()
    },
    async reverseSettlement () {
      await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/Processed/Reverse`,
        {
          settlementId: this.settlement.id
        },
        {
          showload: true,
          showerror: true,
          errormessage: 'Settlement failed to reverse',
          params: {

          }
        })
      this.$toastr.s(
        'Settlement Reversed',
        'Reversed'
      )
      this.$emit('reversed')
      this.hideModal()
    }
  }
}
</script>
