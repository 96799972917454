<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Billing Report
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="from" class="required">From</label>
                <vuejsDatepicker
                  name="from"
                  id="from"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :use-utc="true"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="dates.from"
                  :disabledDates="{from:dates.to}"
                  @input="load"
                ></vuejsDatepicker>
              </div>
              <div class="form-group col-md-4">
                <label for="from" class="required">To</label>
                <vuejsDatepicker
                  name="from"
                  id="from"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :use-utc="true"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="dates.to"
                  :disabledDates="{to:dates.from}"
                  @input="load"
                ></vuejsDatepicker>
              </div>
              <div class="form-group col-md-4">
                <label>Bank Accounts</label>
                <multi-select
                  v-model="selectedBankAccounts"
                  :values="bankAccounts"
                  :options="bankAccountOptions"
                  @change="load"
                ></multi-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <vue-good-table
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :isLoading.sync="isTableLoading"
                  :rows="rows"
                  :columns="columns"
                  :lineNumbers="true"
                  mode="remote"
                  :totalRows="totalRecords"
                  @on-row-click="onRowClick"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  styleClass="vgt-table striped bordered"
                  ref="table"
                >
                  <template slot="table-actions">
                    <table-actions
                      :data-url="dataUrl"
                      :print-enabled="false"
                      :table-amount="totalRecords"
                      :export-name="exportName"
                      :reload-table="load"
                      :table-columns="columns"
                      :export-data="exportData"
                      :clear-table-filters="clearTableFilters"
                    ></table-actions>
                  </template>
                  <template slot="table-row" slot-scope="props">
                    <span
                      v-if="props.column.tdClass === 'text-right'"
                      :class="tdClass(props.row, props.column.field)"
                    >{{props.formattedRow[props.column.field]}}</span>
                    <span
                      v-else-if="props.column.field === 'status'"
                      class="badge badge-pill"
                      :class="getBadgeClass(props.row.status)"
                    >{{props.formattedRow[props.column.field]}}</span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import moment from 'moment'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'

// Constants
import { billingStatus } from '@/Assets/Constants/billing'

// Components
import MultiSelect from '@/Assets/Components/MultiSelect'

export default {
  mixins: [loading, tableFilterMixin, settlementMixin],
  name: 'BillingReport',
  components: {
    MultiSelect
  },
  computed: {
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}Billing/Report`
    },
    exportName () {
      return `${moment(this.fromDate).format('DD/MM/YYYY')}-${moment(this.toDate).format('DD/MM/YYYY')}-billing-report-export`
    },
    detailLoadUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}Settlement/Details`
    },
    exportData () {
      return {
        fromDate: this.dates.from,
        toDate: this.dates.to,
        selectedBankAccounts: this.selectedBankAccounts
      }
    }
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Client Name',
          field: 'clientName',
          sortable: true,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Created Date',
          field: 'creationDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Gross Settlement Method',
          field: 'billingMethod',
          sortable: true
        },
        {
          label: 'Service Charges (£)',
          field: 'serviceCharges',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },
        {
          label: 'VAT (£)',
          field: 'vatAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },
        {
          label: 'Amount (£)',
          field: 'amount',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number'
        },

        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Any Status',
            filterDropdownItems: Object.values(billingStatus)
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'creationDate', type: 'desc' }]
      },
      sortOptions: { enabled: true, initialSortBy: { field: 'creationDate', type: 'desc' } },
      paginationOptions: {
        dropdownAllowAll: false,
        mode: 'pages'
      },
      detailParams: null,
      dates: {
        from: null,
        to: null
      },
      bankAccounts: [],
      selectedBankAccounts: [],
      bankAccountOptions: {
        search: true,
        selectAll: true,
        texts: {
          placeholder: 'Select Bank Accounts',
          search: 'Search Bank Accounts'
        }
      }
    }
  },
  methods: {
    async load () {
      // Load stuff in
      try {
        if (!this.exportData.fromDate && !this.exportData.toDate) {
          return
        }

        this.$snapbar.hide()

        var response = await axios.get(this.dataUrl, {
          params: {
            ...this.buildGoodTableQuery(
              this.exportData
            ),
            fromDate: this.dates.from,
            toDate: this.dates.to,
            bankAccounts: this.selectedBankAccounts
          },
          showload: true,
          showerror: true,
          errormessage: 'Billings failed to load'
        })

        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch { }
    },
    onRowClick (params) {
      var billingId = params.row.id
      this.$router.push({ name: 'BillingReportDetails', params: { id: billingId } })
    },
    async loadBankAccounts () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/BankAccounts`, {
          params: { selectedChildren: this.selectedChildren },
          showerror: true,
          errormessage: 'Failed to get bank accounts'
        })

        this.bankAccounts = response.data.map((v) => { return { value: v.id, name: v.label } })
      } catch { }
    },
    getBadgeClass (status) {
      switch (status) {
        case billingStatus.Submitted:
        case billingStatus.AwaitingPayment:
          return 'badge-info'
        case billingStatus.Settled:
          return 'badge-success'

        case billingStatus.Failed:
          return 'badge-danger'

        case billingStatus.NetdownCreated:
          return 'badge-warning'
      }
      return 'badge-secondary'
    }

  },
  async created () {
    if (this.serverParams.otherData && this.serverParams.otherData.toDate && this.serverParams.otherData.fromDate) {
      this.dates.to = moment(this.serverParams.otherData.toDate).toDate()
      this.dates.from = moment(this.serverParams.otherData.fromDate).toDate()
      this.selectedBankAccounts = this.serverParams.otherData.selectedBankAccounts
    } else {
      var thisMonth = moment().utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0, date: 1 }).add(1, 'month')
      this.dates.to = moment(thisMonth).subtract(1, 'day').toDate()
      this.dates.from = moment(thisMonth).subtract(1, 'month').toDate()
    }

    await this.load()
    await this.loadBankAccounts()
  }
}

</script>

<style lang="scss" scoped>
</style>
