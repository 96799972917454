<template>
  <div class="pb-2 mb-4">
    <div class="idb-block mb-4">
      <div class="idb-block-title">
        Messaging Administration
      </div>
      <div class="idb-block-content">
        <!-- Allow Letters -->
        <div class="form-group row">
          <label class="label-control col-md-3">Test Mode</label>
          <div class="col-md-2">
            <p-check
              class="p-switch p-fill"
              color="primary"
              v-model="testMode"
              :disabled="disabled"
            ></p-check>
          </div>
        </div>
        <!-- Allow Letters -->
        <div class="form-group row">
          <label class="label-control col-md-3">Purge all collections data on change?</label>
          <div class="col-md-2">
            <p-check
              class="p-switch p-fill"
              color="primary"
              v-model="nukeData"
              :disabled="disabled"
            ></p-check>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <b-btn
          id="addPayerButton"
          type="link"
          variant="primary"
          @click="update" :disabled="disabled"
          class="pull-right">
          Update
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import colours from "@/Assets/Constants/colours";
import axios from "axios";
import Ca from "vuejs-datepicker/dist/locale/translations/ca";

export default {
  name: "MessagingAdministration",
  async mounted () {
    await this.load()
  },
  data () {
    return {
      testMode: true,
      nukeData: false,
      disabled: true
    }
  },
  computed: {

  },
  methods: {
    async load () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}collectionsadministration/lettermode`, { showload: true })
        this.testMode = response.data
        this.disabled = false
      } catch (e) {
        this.$toastr.e('Could not load existing test mode settings')
      }
    },
    async update () {
      var result = null
      try {
        result = await swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: this.nukeData ?
            `This will ${(this.testMode ? 'enable' : 'disable')} test mode, ${(this.testMode ? 'preventing' : 'allowing')} actual letters to be sent via Postworks. All existing Collections data, i.e. Customers, Schedules, Alerts, Collections, Alerts, Customer Histories and Messages will be purged from the system. Type the phrase "This is deliberate" to continue.`
            : `This will ${(this.testMode ? 'enable' : 'disable')} test mode, ${(this.testMode ? 'preventing' : 'allowing')} actual letters to be sent via Postworks. All existing Groups, Clients and Customers will be placed into Email-only mode, and any pending outgoing messages will not be sent. Type the phrase "This is deliberate" to continue.`,
          type: 'warning',
          input: 'text',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel'
        })
      } catch (err) {
        result = false
        this.$toastr.e('An error occurred')
      }
      if (result.isConfirmed && result.value === 'This is deliberate') {
        try {
          let response = await axios.put(`${process.env.VUE_APP_DDMS_API_URL}collectionsadministration/lettermode`, { testMode: this.testMode, nukeData: this.nukeData }, { showload: true })
          this.$toastr.s(`Test mode settings updated${(this.nukeData ? ', customer data cleared and letters disabled for all clients and groups' : ' and letters disabled for all clients and groups')}`)
        } catch (e) {
          this.$toastr.e('An error occurred, could not alter test mode settings')
        }
      } else {
        if(result.isConfirmed) {
          this.$toastr.e('Please type "this is deliberate" to indicate that you are not doing this by accident')
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
