<template>
  <div>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2><span class="pull-right"><a :href="`${docUrl}/automation/configuration/duplicationsettings/`" target="_blank"><i class="far fa-question-circle"></i></a></span>Workflow: Duplication Settings<favourite-icon></favourite-icon></h2>
          </div>
          <form>
            <div class="idb-block-content">
              <div class="row">
                <div class="col-md-1">
                </div>
                <div class="col-md-4">
                  Enable File Duplication Checking
                </div>
                <div class="col-md-2">
                  <p-check name="check" class="p-switch" color="primary " v-model="config.dupeCheckingEnabled"></p-check>
                </div>
                <div class="col-md-5">
                  <transition name="fadein">
                    <div v-if="!config.dupeCheckingEnabled">
                    <i class="fa fa-exclamation-triangle mr-2 text-warning"></i>Warning, file duplication checking is currently disabled.
                  </div>
                  </transition>
                </div>
              </div>
              <br/><br/>
              <transition name="fadein">
              <div v-if="config.dupeCheckingEnabled">
              <div class="row" >
                <div class="col-md-1">
                </div>
                <div class="col-md-3">
                  Duplication criteria
                </div>
              </div>
              <br/>
              <div class="row" >
                <div class="col-md-2">
                </div>
                <div class="col-md-4">
                  Duplicate Filename
                </div>
                <div class="col-md-3">
                  <p-check name="check" @change ="dupeFilenameClick" class="p-switch" color="primary " v-model="config.dupeFilenameEnabled"></p-check>
                </div>
              </div>
              <br/>
              <div class="row" >
                <div class="col-md-2">
                </div>
                <div class="col-md-4">
                  Duplicate File content
                </div>
                <div class="col-md-3">
                  <p-check name="check" class="p-switch" @change ="dupeFileHashClick" color="primary " v-model="config.dupeFileHashEnabled"></p-check>
                </div>
              </div>
              <br/>
              <div class="row" >
                <div class="col-md-2">
                </div>
                <div class="col-md-4">
                  Ignore older duplicate files
                </div>
                <div class="col-md-2">
                  <p-check name="check" class="p-switch" color="primary " v-model="config.dupeBlockAgeEnabled"></p-check>
                </div>
              </div>
              <br/>
              <div class="row" v-if="config.dupeBlockAgeEnabled">
                <div class="col-md-3">
                </div>
                <div class="col-md-3">
                  Age (in Days)
                </div>
                <div class="col-md-2">
                  <input
                    @change="tbChanged"
                    @paste.prevent
                    autocomplete="off"
                    type="number"
                    min="1"
                    max="1000"
                    step="1"
                    class="form-control"
                    v-model="config.dupeBlockAge">
                </div>
              </div>
                </div>
              </transition>
            </div>
            <div class="idb-block-footer">
              <div class="row" >
                <div class="col-md-12 col-sm-3">
                  <b-button @click.prevent="btnSave" :disabled="isLoading" v-if="!commsError" variant="primary" v-b-popover.hover.top.d500="'Save the current duplication configuration'"><i class="fa fa-save mr-2"></i>Save</b-button>
                  <b-button @click.prevent="deleteDupes" :disabled="isLoading" v-if="!commsError" variant="danger" class="pull-right" v-b-popover.hover.top.d500="'Permanently delete all duplication entries.'"><i class="fa fa-undo mr-2"></i>Reset Duplication Tracking</b-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
export default {
  mixins: [loading],
  data: () => ({
    paygateId: '',
    config: {},
    commsError: false,
    docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
  }),
  methods: {
    tbChanged () {
      console.log(this.config.dupeBlockAge)
      var isnum = /^\d+$/.test(this.config.dupeBlockAge)
      if (!isnum) this.config.dupeBlockAge = 1
    },
    async btnSave () {
      // TODO: Some vuelidate
      this.tbChanged()
      try {
        await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowconfig`, this.config, { showload: true })
        this.$toastr.s('The settings were successfully updated.')
      } catch (e) {
        this.$snapbar.e(`Error saving duplication configuration - ${e.message}`)
      }
    },
    dupeFilenameClick () {
      if (!this.config.dupeFileHashEnabled) {
        this.config.dupeFileHashEnabled = true
      }
    },
    dupeFileHashClick () {
      if (!this.config.dupeFilenameEnabled) {
        this.config.dupeFilenameEnabled = true
      }
    },
    deleteDupes () {
      this.$swal({
        title: 'Are you sure you want to reset duplication tracking?',
        text: 'All previously stored dupplication entries will be removed!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
        .then(async result => {
          if (result) {
            try {
              await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}duplication`, { showload: true })
              this.$toastr.s('Duplication tracking has been reset.')
            } catch (e) {
              this.$snapbar.e(`Error reseting duplication tracking - ${e.message}`)
            }
          }
        })
        .catch(this.$swal.noop)
    }
  },
  created: async function () {
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    this.$snapbar.hide()
    let res
    try {
      res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowconfig`, { showload: true })
      if (res && res.data && res.data.config) {
        this.config = res.data.config
      } else {
        this.$snapbar.e('Error retrieving duplication configuration')
      }
    } catch (e) {
      if (e.response && e.response.status === 403) {
        this.$snapbar.e('You are not authorised to view duplication settings.')
      } else {
        this.$snapbar.e(`Could not return duplication settings - ${e.message}`)
      }
    }
  }
}
</script>

<style scoped>
  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
</style>
