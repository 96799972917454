<template>
  <div>
    <div class="vgt-selection-info-row clearfix" v-if="anySelectedRows">
      {{selectionInfo}}
      <a href @click.prevent="clearSelections">Clear</a>
      <div class="vgt-selection-info-row__actions vgt-pull-right">
        <slot name="extra-select-info"></slot>
      </div>
    </div>
    <vue-good-table
      :paginationOptions="paginationOptions"
      :sort-options="sortOptions"
      :isLoading="isLoading"
      :rows="actualRows"
      :columns="columns"
      :lineNumbers="true"
      mode="remote"
      :totalRows="totalRows"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-search="onSearch"
      @on-select-all="onSelectAll"
      @on-selected-rows-change="onSelectedRowsChange"
      styleClass="vgt-table striped bordered"
      ref="table"
      :select-options="{ enabled: true, disableSelectInfo: true}"
    >
      <template slot="selected-row-actions">
        <div>{{selectionInfo}}</div>
      </template>
      <template slot="table-actions">
        <slot name="table-actions"></slot>
      </template>
      <template slot="table-row" slot-scope="props">
        <slot
          name="table-row"
          :column="props.column"
          :formattedRow="props.formattedRow"
          :row="props.row"
        ></slot>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CheckboxTable',
  props: {
    value: {
      type: Array,
      required: true
    },
    paginationOptions: {
      type: Object
    },
    sortOptions: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    rows: {
      type: Array
    },
    columns: {
      type: Array
    },
    mode: {
      type: String,
      default: 'remote'
    },
    totalRows: {
      type: Number
    },
    selectionIdentifier: {
      type: String,
      default: 'id'
    },
    selectAllMethod: {
      type: Function
    }
  },
  computed: {
    selectionInfo () {
      return `${this.selectedRows.length} rows selected`
    },
    anySelectedRows () {
      return this.selectedRows.some(s => true)
    },
    actualRows () {
      this.rows.forEach(r => { r.vgtSelected = false })
      this.rows.filter(r => this.selectedRows.some(s => r[this.selectionIdentifier] === s[this.selectionIdentifier])).forEach(r => { r.vgtSelected = true })
      return this.rows
    }
  },
  data () {
    return {
      selectedRows: [],
      page: 1,
      shouldSelect: true
    }
  },
  methods: {
    onPageChange (params) {
      this.page = params.currentPage
      this.shouldSelect = false
      this.$emit('on-page-change', params)
    },
    onSortChange (params) {
      this.$emit('on-sort-change', params)
    },
    onColumnFilter (params) {
      this.$emit('on-column-filter', params)
    },
    onPerPageChange (params) {
      this.$emit('on-per-page-change', params)
    },
    onSearch (params) {
      this.$emit('on-search', params)
    },
    async onSelectAll (params) {
      this.shouldSelect = false
      if (params.selected) {
        this.selectedRows = await this.selectAllMethod()
      } else {
        this.selectedRows = []
      }
      this.shouldSelect = true
      this.$emit('input', this.selectedRows)
    },
    onSelectedRowsChange (params) {
      if (this.shouldSelect) {
        this.selectedRows = _.differenceBy(this.selectedRows, this.rows, 'id')
        this.selectedRows = this.selectedRows.concat(params.selectedRows)
      }
      this.shouldSelect = true
      this.$emit('input', this.selectedRows)
    },
    clearSelections () {
      this.selectedRows = []
      this.$refs.table.unselectAllInternal(true)
      this.$emit('input', this.selectedRows)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
