var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"intelligibleSummary"}},[_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-md-12"},[_c('vue-good-table',{ref:"summaries",attrs:{"columns":_vm.columns,"rows":_vm.customerSubmissions,"pagination-options":{
          enabled: true,
          perPage: 10,
          dropdownAllowAll: false,
          jumpFirstOrLast: true
        },"styleClass":"vgt-table striped bordered"},on:{"on-row-click":_vm.onRowClick,"on-column-filter":_vm.afterFilter},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'creditRecordCount')?_c('span',[_vm._v(" "+_vm._s(_vm.formatCount(props.row.creditRecordCount))+" ")]):(props.column.field === 'creditValue')?_c('span',[_vm._v(" "+_vm._s(_vm.formatValue(props.row.creditValue))+" ")]):(props.column.field === 'debitRecordCount')?_c('span',[_vm._v(" "+_vm._s(_vm.formatCount(props.row.debitRecordCount))+" ")]):(props.column.field === 'debitValue')?_c('span',[_vm._v(" "+_vm._s(_vm.formatValue(props.row.debitValue))+" ")]):(props.column.field === 'ddiRecordCount')?_c('span',[_vm._v(" "+_vm._s(_vm.formatCount(props.row.ddiRecordCount))+" ")]):_vm._e()]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.d500",value:('Clear filters'),expression:"'Clear filters'",modifiers:{"hover":true,"top":true,"d500":true}}],staticClass:"btn btn-link",on:{"click":function($event){$event.preventDefault();return _vm.clearTableFilters.apply(null, arguments)}}},[_c('span',{staticClass:"fa-stack",staticStyle:{"font-size":"10px"}},[_c('i',{staticClass:"fa fa-filter fa-stack-1x dimmedIcon"}),_c('i',{staticClass:"fa fa-ban fa-stack-2x dimmedIcon"})])])])])],1)]),(_vm.showTotal)?_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-md-12"},[_c('vue-good-table',{ref:"total",attrs:{"columns":_vm.totalColumns,"rows":_vm.summaryTotals,"sort-options":{
          enabled: false
        },"styleClass":"vgt-table striped bordered"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'creditRecordCount')?_c('span',[_vm._v(" "+_vm._s(_vm.formatCount(props.row.creditRecordCount))+" ")]):(props.column.field === 'creditValue')?_c('span',[_vm._v(" "+_vm._s(_vm.formatValue(props.row.creditValue))+" ")]):(props.column.field === 'debitRecordCount')?_c('span',[_vm._v(" "+_vm._s(_vm.formatCount(props.row.debitRecordCount))+" ")]):(props.column.field === 'debitValue')?_c('span',[_vm._v(" "+_vm._s(_vm.formatValue(props.row.debitValue))+" ")]):(props.column.field === 'ddiRecordCount')?_c('span',[_vm._v(" "+_vm._s(_vm.formatCount(props.row.ddiRecordCount))+" ")]):_vm._e()]}}],null,false,2254460173)})],1)]):_vm._e(),_c('br'),(_vm.actionId !== undefined)?_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-md-2"},[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button","disabled":_vm.isLoading},on:{"click":_vm.downloadReport}},[_c('span',{staticClass:"far fa-arrow-alt-circle-down",staticStyle:{"padding-right":"5px"}}),_vm._v("Download Submission Report ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }