import Vue from 'vue'

Vue.filter('currency', function (amt) {
  if (!amt) return ''
  var amountAsNumber = Number(amt)
  if (amountAsNumber < 0) {
    return `-£${amountAsNumber.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).substring(1)}`
  }
  return `£${amountAsNumber.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
})
