<template>
  <form @submit.prevent="checkValidation() && save()" novalidate>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Part Numbers
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content" v-if="defaultCustomer">
            <div
              class="alert alert-info"
            >This will set the default part numbers, if a part number isn't overridden in a Client configuration</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Processing Charges</h2>
          </div>
          <div class="idb-block-content">
            <div class="row">
              <!-- Advance Notice - Letters -->
              <div
                class="form-group col"
                :class="{ invalid: $v.billing.advanceNoticeLetter.$error }"
              >
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >Advance Notice - Letters</label>

                <input class="form-control" v-model.number="$v.billing.advanceNoticeLetter.$model" />
                <validation-messages
                  v-model="$v.billing.advanceNoticeLetter"
                  name="Advance Notice - Letters"
                ></validation-messages>
                <small>Charge made for sending an Advance Notice to a Payer via letter</small>
              </div>

              <!-- Advance Notice - Emails -->
              <div
                class="form-group col"
                :class="{ invalid: $v.billing.advanceNoticeEmail.$error }"
              >
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >Advance Notice - Emails</label>

                <input class="form-control" v-model.number="$v.billing.advanceNoticeEmail.$model" />
                <validation-messages
                  v-model="$v.billing.advanceNoticeEmail"
                  name="Advance Notice - Emails"
                ></validation-messages>
                <small>Charge made for sending an Advance Notice to a Payer via email</small>
              </div>

              <!-- Collections -->
              <div class="form-group col" :class="{ invalid: $v.billing.collections.$error }">
                <label class="label-control" :class="{'required':defaultCustomer}">Collections</label>

                <input class="form-control" v-model.number="$v.billing.collections.$model" />
                <validation-messages v-model="$v.billing.collections" name="Collections"></validation-messages>
                <small>Charge made for sending an Advance Notice to a Payer vis SMS</small>
              </div>

              <!-- Confirmation Letters -->
              <div
                class="form-group col"
                :class="{ invalid: $v.billing.confirmationLetter.$error }"
              >
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >Confirmation Letters</label>

                <input class="form-control" v-model.number="$v.billing.confirmationLetter.$model" />
                <validation-messages
                  v-model="$v.billing.confirmationLetter"
                  name="Confirmation Letters"
                ></validation-messages>
                <small>Charge made for sending a Confirmation Letter to a Payer, could be a post or email</small>
              </div>
            </div>

            <div class="row">
              <!-- Failed Collections -->
              <div class="form-group col" :class="{ invalid: $v.billing.failedCollections.$error }">
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >Failed Collections</label>

                <input class="form-control" v-model.number="$v.billing.failedCollections.$model" />
                <validation-messages
                  v-model="$v.billing.failedCollections"
                  name="Failed Collections"
                ></validation-messages>
                <small>Charge made for a Collection Failure</small>
              </div>

              <!-- Indemnity Claims -->
              <div class="form-group col" :class="{ invalid: $v.billing.indemnityClaims.$error }">
                <label class="label-control" :class="{'required':defaultCustomer}">Indemnity Claims</label>

                <input class="form-control" v-model.number="$v.billing.indemnityClaims.$model" />
                <validation-messages v-model="$v.billing.indemnityClaims" name="Indemnity Claims"></validation-messages>
                <small>Charge made for an Indemnity Claim</small>
              </div>

              <!-- Payer Cancellations -->
              <div class="form-group col" :class="{ invalid: $v.billing.payerCancellation.$error }">
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >Customer Cancellations</label>

                <input class="form-control" v-model.number="$v.billing.payerCancellation.$model" />
                <validation-messages
                  v-model="$v.billing.payerCancellation"
                  name="Payer Cancellations"
                ></validation-messages>
                <small>Charge made for suspending a Customer</small>
              </div>
              <!-- Payer Reinstatements -->
              <div class="form-group col" :class="{ invalid: $v.billing.payerReinstate.$error }">
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >Customer Reinstatements</label>

                <input class="form-control" v-model.number="$v.billing.payerReinstate.$model" />
                <validation-messages
                  v-model="$v.billing.payerReinstate"
                  name="Payer Reinstatements"
                ></validation-messages>
                <small>Charge made for reinstating a Customer</small>
              </div>
            </div>

            <div class="row">
              <!-- Payer Setups -->
              <div class="form-group col" :class="{ invalid: $v.billing.payerSetup.$error }">
                <label class="label-control" :class="{'required':defaultCustomer}">Customer Setups</label>

                <input class="form-control" v-model.number="$v.billing.payerSetup.$model" />
                <validation-messages v-model="$v.billing.payerSetup" name="Payer Setups"></validation-messages>
                <small>Charge made for a Payer Setup</small>
              </div>

              <!-- Refunds -->
              <!-- <div class="form-group col" :class="{ invalid: $v.billing.refunds.$error }">
                <label class="label-control" :class="{'required':defaultCustomer}">Refunds</label>

                <input class="form-control" v-model.number="$v.billing.refunds.$model" />
                <validation-messages v-model="$v.billing.refunds" name="Refunds"></validation-messages>
                <small>Charge made for a Refund</small>
              </div>-->

              <!-- Bank Account Validations -->
              <div
                class="form-group col"
                :class="{ invalid: $v.billing.validateBankAccount.$error }"
              >
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >Bank Account Validations</label>

                <input class="form-control" v-model.number="$v.billing.validateBankAccount.$model" />
                <validation-messages
                  v-model="$v.billing.validateBankAccount"
                  name="Bank Account Validations"
                ></validation-messages>
                <small>Charge made for a Validation on a bank account</small>
              </div>
              <div class="form-group col" :class="{ invalid: $v.billing.validateIBAN.$error }">
                <label class="label-control" :class="{'required':defaultCustomer}">IBAN Validations</label>

                <input class="form-control" v-model.number="$v.billing.validateIBAN.$model" />
                <validation-messages v-model="$v.billing.validateIBAN" name="IBAN Validations"></validation-messages>
                <small>Charge made for reinstating a Customer</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Ad-Hoc Charges</h2>
          </div>
          <div class="idb-block-content">
            <div class="row">
              <!-- Credit Note -->
              <div class="form-group col" :class="{ invalid: $v.billing.creditNote.$error }">
                <label class="label-control" :class="{'required':defaultCustomer}">Credit Note</label>

                <input class="form-control" v-model.number="$v.billing.creditNote.$model" />
                <validation-messages v-model="$v.billing.creditNote" name="Credit Note"></validation-messages>
                <small>A credit value added to a Client's account. Can be used for Advance Payments</small>
              </div>

              <!-- Termination Fee -->
              <div class="form-group col" :class="{ invalid: $v.billing.terminationFee.$error }">
                <label class="label-control" :class="{'required':defaultCustomer}">Termination Fee</label>

                <input class="form-control" v-model.number="$v.billing.terminationFee.$model" />
                <validation-messages v-model="$v.billing.terminationFee" name="Termination Fee"></validation-messages>
                <small>Termination charge</small>
              </div>

              <!-- Bankline STD 1 or 2 day payment -->
              <div class="form-group col" :class="{ invalid: $v.billing.banklineSTD1Or2.$error }">
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >Bankline STD 1 or 2 day payment</label>

                <input class="form-control" v-model.number="$v.billing.banklineSTD1Or2.$model" />
                <validation-messages
                  v-model="$v.billing.banklineSTD1Or2"
                  name="Bankline STD 1 or 2 day payment"
                ></validation-messages>
                <small>Bankline STD 1 or 2 day payment</small>
              </div>

              <!-- Bankline STD 0 day payment -->
              <div class="form-group col" :class="{ invalid: $v.billing.banklineSTD0.$error }">
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >Bankline STD 0 day payment</label>

                <input class="form-control" v-model.number="$v.billing.banklineSTD0.$model" />
                <validation-messages
                  v-model="$v.billing.banklineSTD0"
                  name="Bankline STD 1 or 2 day payment"
                ></validation-messages>
                <small>Bankline STD 0 day payment</small>
              </div>
            </div>
            <div class="row">
              <!-- Bankline URG Payment -->
              <div class="form-group col-3" :class="{ invalid: $v.billing.banklineUrgent.$error }">
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >Bankline URG Payment</label>

                <input class="form-control" v-model.number="$v.billing.banklineUrgent.$model" />
                <validation-messages
                  v-model="$v.billing.banklineUrgent"
                  name="Bankline URG Payment"
                ></validation-messages>
                <small>Bankline URG Payment</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Periodic Charges</h2>
          </div>
          <div class="idb-block-content">
            <div class="row">
              <!-- Admin charge -->
              <div class="form-group col-3" :class="{ invalid: $v.billing.adminCharge.$error }">
                <label class="label-control" :class="{'required':defaultCustomer}">Admin charge</label>

                <input class="form-control" v-model.number="$v.billing.adminCharge.$model" />
                <validation-messages v-model="$v.billing.adminCharge" name="Admin charge"></validation-messages>
                <small>Monthly Admin Charge</small>
              </div>

              <!-- BACS File Submission Charge -->
              <div
                class="form-group col-3"
                :class="{ invalid: $v.billing.bacsFileSubmissionCharge.$error }"
              >
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >BACS File Submission Charge</label>

                <input
                  class="form-control"
                  v-model.number="$v.billing.bacsFileSubmissionCharge.$model"
                />
                <validation-messages
                  v-model="$v.billing.bacsFileSubmissionCharge"
                  name="BACS File Submission Charge"
                ></validation-messages>
                <small>Charge per BACS File Submission, beyond the included number in the contract per month</small>
              </div>

              <!-- OSU charge -->
              <div class="form-group col-3" :class="{ invalid: $v.billing.osuCharge.$error }">
                <label class="label-control" :class="{'required':defaultCustomer}">OSU charge</label>

                <input class="form-control" v-model.number="$v.billing.osuCharge.$model" />
                <validation-messages v-model="$v.billing.osuCharge" name="OSU charge"></validation-messages>
                <small>Monthly OSU Charge</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>PS Charges</h2>
          </div>
          <div class="idb-block-content">
            <div class="row">
              <!-- BACS Migration -->
              <div class="form-group col" :class="{ invalid: $v.billing.bacsMigration.$error }">
                <label class="label-control" :class="{'required':defaultCustomer}">BACS Migration</label>

                <input class="form-control" v-model.number="$v.billing.bacsMigration.$model" />
                <validation-messages v-model="$v.billing.bacsMigration" name="BACS Migration"></validation-messages>
                <small>Charge made for a BACS Migration</small>
              </div>

              <!-- Customer Setup -->
              <div class="form-group col" :class="{ invalid: $v.billing.customerSetup.$error }">
                <label class="label-control" :class="{'required':defaultCustomer}">Customer Setup</label>

                <input class="form-control" v-model.number="$v.billing.customerSetup.$model" />
                <validation-messages v-model="$v.billing.customerSetup" name="Customer Setup"></validation-messages>
                <small>Charge made for setting up a Customer in the system</small>
              </div>

              <!-- FM Service User Setup -->
              <div
                class="form-group col"
                :class="{ invalid: $v.billing.fmServiceUserSetup.$error }"
              >
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >FM Service User Setup</label>

                <input class="form-control" v-model.number="$v.billing.fmServiceUserSetup.$model" />
                <validation-messages
                  v-model="$v.billing.fmServiceUserSetup"
                  name="FM Service User Setup"
                ></validation-messages>
                <small>Charge made for a FM BACS Service User application</small>
              </div>

              <!-- Payer Migration -->
              <div class="form-group col" :class="{ invalid: $v.billing.payerMigration.$error }">
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >Customer Migration</label>

                <input class="form-control" v-model.number="$v.billing.payerMigration.$model" />
                <validation-messages v-model="$v.billing.payerMigration" name="Payer Migration"></validation-messages>
                <small>Charge made for migrating Customer data internally</small>
              </div>
            </div>
            <div class="row">
              <!-- Reports -->
              <div class="form-group col-3" :class="{ invalid: $v.billing.reports.$error }">
                <label class="label-control" :class="{'required':defaultCustomer}">Reports</label>

                <input class="form-control" v-model.number="$v.billing.reports.$model" />
                <validation-messages v-model="$v.billing.reports" name="Reports"></validation-messages>
                <small>A charge for bespoke reports setup for an entity</small>
              </div>

              <!-- Service User Modification -->
              <div
                class="form-group col-3"
                :class="{ invalid: $v.billing.serviceUserModification.$error }"
              >
                <label
                  class="label-control"
                  :class="{'required':defaultCustomer}"
                >Service User Modification</label>

                <input
                  class="form-control"
                  v-model.number="$v.billing.serviceUserModification.$model"
                />
                <validation-messages
                  v-model="$v.billing.serviceUserModification"
                  name="Service User Modification"
                ></validation-messages>
                <small>Charge made for a modification to a Service User</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-footer">
            <button class="btn btn-primary" type="submit" :disabled="isLoading">Save</button>
            <button
              class="btn btn-outline-danger pull-right ml-3"
              type="button"
              @click="back"
            >Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'

// Mixins
import loading from '@/Assets/Mixins/LoadingMixin'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'

// Validations
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'PartNumbers',
  mixins: [loading, DataLeaveMixin],
  computed: {
    lAndZId () {
      return process.env.VUE_APP_LANDZ_ID
    },
    defaultCustomer () {
      return this.selectedCustomer === this.lAndZId
    },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  data () {
    return {
      billing: {
        // Ad-Hoc
        creditNote: '',
        terminationFee: '',
        banklineSTD1Or2: '',
        banklineSTD0: '',
        banklineUrgent: '',
        // Periodic
        adminCharge: '',
        bacsFileSubmissionCharge: '',
        osuCharge: '',

        // Processing
        advanceNoticeLetter: '',
        advanceNoticeEmail: '',

        collections: '',
        confirmationLetter: '',
        failedCollections: '',
        indemnityClaims: '',
        payerCancellation: '',
        payerReinstate: '',
        payerSetup: '',
        refunds: '',
        validateBankAccount: '',
        validateIBAN: '',

        // PS
        bacsMigration: '',
        customerSetup: '',
        fmServiceUserSetup: '',
        payerMigration: '',
        reports: '',
        serviceUserModification: ''
      }
    }
  },
  methods: {
    async save () {
      await axios.patch(`${process.env.VUE_APP_PLATFORM_API_URL}PartNumbers`, this.billing, {
        showload: true,
        showerror: true,
        errormessage: 'Part numbers failed to save'
      })

      this.$v.$reset()
      this.$toastr.s(
        'Part numbers Saved',
        'Saved'
      )
    },
    async load () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}PartNumbers`, {
        showload: true,
        showerror: true,
        errormessage: 'Part numbers failed to load'
      })

      this.billing = response.data
    }
  },
  async mounted () {
    await this.load()
  },
  validations () {
    return {
      billing: {
        // Ad-Hoc
        creditNote: { required: requiredIf(() => this.defaultCustomer) },
        terminationFee: { required: requiredIf(() => this.defaultCustomer) },
        banklineSTD1Or2: { required: requiredIf(() => this.defaultCustomer) },
        banklineSTD0: { required: requiredIf(() => this.defaultCustomer) },
        banklineUrgent: { required: requiredIf(() => this.defaultCustomer) },
        // Periodic
        adminCharge: { required: requiredIf(() => this.defaultCustomer) },
        bacsFileSubmissionCharge: { required: requiredIf(() => this.defaultCustomer) },
        osuCharge: { required: requiredIf(() => this.defaultCustomer) },

        // Processing
        advanceNoticeLetter: { required: requiredIf(() => this.defaultCustomer) },
        advanceNoticeEmail: { required: requiredIf(() => this.defaultCustomer) },
        collections: { required: requiredIf(() => this.defaultCustomer) },
        confirmationLetter: { required: requiredIf(() => this.defaultCustomer) },
        failedCollections: { required: requiredIf(() => this.defaultCustomer) },
        indemnityClaims: { required: requiredIf(() => this.defaultCustomer) },
        payerCancellation: { required: requiredIf(() => this.defaultCustomer) },
        payerReinstate: { required: requiredIf(() => this.defaultCustomer) },
        payerSetup: { required: requiredIf(() => this.defaultCustomer) },
        refunds: { required: requiredIf(() => this.defaultCustomer) },
        validateBankAccount: { required: requiredIf(() => this.defaultCustomer) },
        validateIBAN: { required: requiredIf(() => this.defaultCustomer) },

        // PS
        bacsMigration: { required: requiredIf(() => this.defaultCustomer) },
        customerSetup: { required: requiredIf(() => this.defaultCustomer) },
        fmServiceUserSetup: { required: requiredIf(() => this.defaultCustomer) },
        payerMigration: { required: requiredIf(() => this.defaultCustomer) },
        reports: { required: requiredIf(() => this.defaultCustomer) },
        serviceUserModification: { required: requiredIf(() => this.defaultCustomer) }
      }
    }
  }
}
</script>

<style>
</style>
