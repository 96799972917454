<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Expected Collections<favourite-icon></favourite-icon></h2>
      </div>
      <div class="idb-block-content">
        <div class="form-row">
          <div class="form-group col-md-2" :class="{ invalid: $v.date.$error }">
            <label for="to" class="required">Collection Date</label>
            <vuejsDatepicker
              name="to"
              id="to"
              format="dd/MM/yyyy"
              input-class="form-control bg-white"
              :bootstrap-styling="true"
              minimumView="day"
              maximumView="year"
              initial-view="day"
              :use-utc="true"
              :disabled-dates="dateOptions"
              :calendar-button="true"
              calendar-button-icon="fa fa-calendar"
              v-model="$v.date.$model"
            ></vuejsDatepicker>
            <validation-messages v-model="$v.date" name="from"></validation-messages>
          </div>

          <div class="form-group col-md-4">
            <label class="required">Bank Accounts</label>
            <multi-select
              v-model="selectedBankAccounts"
              :values="bankAccounts"
              :options="bankAccountOptions"
            ></multi-select>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <b-button @click="getData" variant="primary" :disabled="selectedBankAccounts.length === 0">Get Data</b-button>
      </div>
    </div>
    <div class="idb-block" v-if="searchAttempted">
      <div class="idb-block-title">
        <h2>Results</h2>
      </div>
      <div class="idb-block-content">
        <div class="form-group col-md-4">
          <label>Bank Accounts</label>
          <b-form-select
            :options="filteredBankAccounts"
            v-model="singleBankAccount"
            value-field="value"
            text-field="name"
            @change="load"
          >
            <template slot="first">
              <option :value="null">All matching above criteria</option>
            </template>
          </b-form-select>
          <!-- <multi-select ref="filterableAccounts"
            v-model="refinedBankAccounts"
            :values="filteredBankAccounts"
            :options="bankAccountOptions"
          ></multi-select> -->
        </div>
        <div class="scroll-table vgt-wrap ">
          <div class="vgt-inner-wrap">
            <!-- HEAD -->
            <div class="vgt-global-search vgt-clearfix">
              <div class="vgt-global-search__input vgt-pull-left">
                <form role="search">
                  <label>
                    <span aria-hidden="true" class="input__icon">
                      <div class="magnifying-glass"></div>
                    </span>
                    <span class="sr-only">Search</span>
                  </label>
                  <input type="text" placeholder="Search Table" v-model="searchValue" @keyup="updateSearch" class="vgt-input vgt-pull-left"></form>
              </div>
              <div class="vgt-global-search__actions vgt-pull-right">
                <div>
                  <button class="btn btn-link" @click="clearExpTableFilters">
                    <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i></span>
                  </button>
                  <button type="button" class="btn btn-link" @click="exportTable"><i class="fa fa-share-square pointer dimmedIcon"></i></button>
                  <button type="button" class="btn btn-link" @click="load"><i class="fa fa-sync pointer dimmedIcon"></i></button>
                </div>
              </div>
            </div>
            <!-- END OF HEAD -->
            <div class="vgt-responsive">
              <table class="vgt-table striped bordered ">
              <thead>
              <tr>
                <th scope="col" class="line-numbers"></th>
                <th class="sortable" @click="sortThatOut('accountid')" :class="(sortCol === 'accountid' ? `sorting ${(sortDir === 'asc' ? 'sorting-asc' : 'sorting-desc' )}` : '')">
                  <span>ID</span>
                  <button>
                    <span class="sr-only" v-if="sortDir === 'asc'">
                    Sort table by Client Id in descending order
                    </span>
                    <span class="sr-only" v-else>
                    Sort table by Client Id in ascending order
                    </span>
                  </button>
                </th>
                <th class="sortable" @click="sortThatOut('name')" :class="(sortCol === 'name' ? `sorting ${(sortDir === 'asc' ? 'sorting-asc' : 'sorting-desc' )}` : '')">
                  <span>Client</span>
                  <button>
                    <span class="sr-only" v-if="sortDir === 'asc'">
                    Sort table by Client Name in descending order
                    </span>
                    <span class="sr-only" v-else>
                    Sort table by Client Name in ascending order
                    </span>
                  </button>
                </th>
                <th class="sortable text-right"  @click="sortThatOut('amount')" :class="(sortCol === 'amount' ? `sorting ${(sortDir === 'asc' ? 'sorting-asc' : 'sorting-desc' )}` : '')">
                  <span>Amount</span>
                  <button>
                    <span class="sr-only" v-if="sortDir === 'asc'">
                    Sort table by Amount in descending order
                    </span>
                    <span class="sr-only" v-else>
                    Sort table by Amount in ascending order
                    </span>
                  </button>
                </th>
              </tr>
              </thead>
              <tbody v-if="rows.length > 0" v-for="(ba, i) in rows" :key="ba.reference+'-'+i">
                <tr class="table-info expected-report-group">
                  <td colspan="3">{{ba.reference}}</td>
                  <td colspan="1" class="text-right"><span  class="text-end">{{formatAmount(ba.amount)}}</span></td>
                </tr>
                <template v-for="(s) in ba.suns">
                  <tr class="table-secondary expected-report-group">
                    <td colspan="3">{{s.serviceUserNumber}} ({{s.sunName}})</td>
                    <td colspan="1" class="text-right"><span  class="text-end">{{formatAmount(s.amount)}}</span></td>
                  </tr>
                  <tr v-for="(item, a) in s.collections" :key="ba.reference+'-'+item.name+'-'+i+'-'+a">
                    <td class="line-numbers">{{a+1}}</td>
                    <td>{{item.accountId}}</td>
                    <td>{{item.name}}</td>
                    <td class="text-right"><span  class="text-end">{{formatAmount(item.amount)}}</span></td>
                  </tr>
                </template>
              </tbody>
              <tbody v-if="rows === undefined || rows === null || rows.length === 0">
                <tr><td colspan="4">
                  <div><div class="vgt-center-align vgt-text-disabled">No Data Available</div></div>
                </td></tr>
              </tbody>
            </table>
            </div>
            <!-- START OF FOOTER -->
            <div class="vgt-wrap__footer vgt-clearfix">
              <div class="footer__row-count vgt-pull-left" v-if="false">
                <form>
                  <label class="footer__row-count__label">Rows per page:</label>
                  <select autocomplete="off" name="perPageSelect" aria-controls="vgt-table" class="footer__row-count__select">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <!---->
                  </select>
                </form>
              </div>
              <div class="footer__navigation vgt-pull-right" v-if="false">
                <div data-v-347cbcfa="" class="footer__navigation__page-info">
                  <div data-v-347cbcfa="">1 - 1 of 1</div>
                </div>
                <button type="button" aria-controls="vgt-table" class="footer__navigation__page-btn disabled">
                  <span aria-hidden="true" class="chevron left"></span>
                  <span>First</span></button>
                <button type="button" aria-controls="vgt-table" class="footer__navigation__page-btn disabled">
                  <span aria-hidden="true" class="chevron left"></span>
                  <span>Previous</span>
                </button>
                <button type="button" aria-controls="vgt-table" class="footer__navigation__page-btn disabled">
                  <span>Next</span>
                  <span aria-hidden="true" class="chevron right"></span>
                </button>
                <button type="button" aria-controls="vgt-table" class="footer__navigation__page-btn disabled">
                  <span>Last</span>
                  <span aria-hidden="true" class="chevron right"></span>
                </button>
              </div>
            </div>
            <!-- END OF FOOTER -->
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import moment from 'moment'
import axios from 'axios'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'

// Validations
import {
  required
} from 'vuelidate/lib/validators'

// Components
import MultiSelect from '@/Assets/Components/MultiSelect'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import {ref} from "vuelidate/lib/validators/common";
import papa from "papaparse";

export default {
  mixins: [loading, tableFilterMixin],
  components: {
    MultiSelect
  },
  name: 'ExpectedCollection',
  computed: {
    dateOptions () {
      let tempStart = new Date()
      tempStart.setDate(tempStart.getDate())
      return { from: tempStart }
    }
  },
  data () {
    return {
      date: null,
      bankAccounts: [],
      singleBankAccount: null,
      selectedBankAccounts: [],
      refinedBankAccounts: [],
      filteredBankAccounts: [],
      selectedChildren: [],
      searchValue: '',
      bankAccountOptions: {
        search: true,
        selectAll: true,
        texts: {
          placeholder: 'Select Bank Accounts',
          search: 'Search Bank Accounts'
        }
      },
      columns: [
        {
          label: 'paygateId',
          field: 'paygateId',
          hidden: true
        },
        {
          label: 'ID',
          field: 'accountId'
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Amount',
          field: 'amount'
        }
      ],
      rows: [],
      searchAttempted: false,
      sortCol: 'accountid',
      sortDir: 'asc',
      filterSetup: true
    }
  },
  watch: {
    async refinedBankAccounts() {
      if (!this.filterSetup) {
        await this.load()
      }
    }
  },
  methods: {
    async clearExpTableFilters () {
      this.sortCol = 'accountid'
      this.sortDir = 'asc'
      this.searchValue = ''
      await this.load()
    },
    async sortThatOut (col) {
      if (col === this.sortCol) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc'
      }
      else {
        this.sortCol = col
        this.sortDir = 'asc'
      }
      await this.load()
    },
    async loadBankAccounts () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/BankAccounts`, {
          params: { selectedChildren: this.selectedChildren },
          showerror: true,
          errormessage: 'Failed to get bank accounts'
        })

        this.bankAccounts = response.data.map((v) => { return { value: v.id, name: v.label } })
      } catch { }
    },
    async updateSearch () {
      await this.load()
    },
    async getData () {
      this.filterSetup = true
      this.searchAttempted = true
      this.$nextTick(async ()=> {
        this.filteredBankAccounts.length = 0
        this.filteredBankAccounts = [ ...this.bankAccounts.filter(x=> this.selectedBankAccounts.includes(x.value)) ]
        this.filterSetup = false
      })
      await this.load()
    },
    load: _.debounce(async function () {
      if (this.searchAttempted) {
        var query = this.buildGoodTableQuery()
        query.searchFilter = this.searchValue
        query.sort = `${this.sortCol}:${this.sortDir},`
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/expectedcollections`, {
          params: { ...query, collectionDate: this.date, bankAccounts: this.singleBankAccount === null ? this.selectedBankAccounts : [ this.singleBankAccount ]},
          showerror: true,
          errormessage: 'Failed to get data'
        })
        this.rows = response.data.data.bankAccounts
        this.totalRecords = response.data.count
      }
    }, 300),
    async exportTable () {
      var startDate = moment(this.date).format('DD-MM-YYYY')
      var query = this.buildGoodTableQuery()
      query.searchFilter = this.searchValue
      query.sort = `${this.sortCol}:${this.sortDir},`
      query.forExport = true
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/expectedcollections`, {
        params: { ...query, collectionDate: this.date, bankAccounts: this.refinedBankAccounts},
        showerror: true,
        errormessage: 'Failed to get data'
      })
      var csvString = papa.unparse(response.data)
      let fileName = `expected-collections-${startDate}.csv`
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    groupBy (xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    formatAmount (value) {
      return '£' + this.numberWithCommas((value / 1).toFixed(2))
    },
  },
  async created () {
    await this.loadBankAccounts()
  },
  async mounted () {
    this.date = new Date()
  },
  validations: {
    date: { required }
  }
}

</script>

<style lang="css" scoped>
.dark-mode * .expected-report-group td, .dark-mode * .expected-report-group td .text-end {
  color: #000000 !important
}
</style>
