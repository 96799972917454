<template>
  <div id="payments">
    <div class="row form-group">
      <div class="col-md-12">
        <vue-good-table ref="payments"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
          @on-column-filter="onColumnFilter"
          :columns="columns"
          :rows="rows"
          :totalRows="totalRecords"
          :paginationOptions="paginationOptions"
          styleClass="vgt-table striped bordered">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'displayProcessingDate'">
                {{ formatDate(props.row.displayProcessingDate) }}
              </span>
              <span v-else>{{props.formattedRow[props.column.field]}}</span>
            </template>
            <div slot="table-actions">
              <b-button
                @click.prevent="createPaymentReport"
                class
                variant="link"
                v-b-popover.hover.top.d500="'Shows a summary of the payments'"
              >
                <i class="fa fa-file-pdf pointer dimmedIcon"></i>
              </b-button>
              <b-button
                @click.prevent="createPaymentCsvFile"
                class
                variant="link"
                v-b-popover.hover.top.d500="'Export payment details to CSV'"
              >
                <i class="fas fa-file-export pointer dimmedIcon"></i>
              </b-button>
              <b-button
                @click.prevent="refreshGrid"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Clear filters'">
                <span class="fa-stack" style="font-size: 10px;">
                  <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                  <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                </span>
              </b-button>
            </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import moment from 'moment'

export default {
  mixins: [
    bacsMixin
  ],
  data () {
    return {
      submissionId: '',
      fileNumber: 1,
      intelligibleSummaries: {},
      customerSubmissions: [],
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'No.',
          field: 'recordNumber',
          type: 'number',
          hidden: true
        },
        {
          label: 'Client',
          field: 'customerName',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Third Party Name',
          field: 'thirdPartyAccountName',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Sort Code',
          field: 'thirdPartySortCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Account Number',
          field: 'thirdPartyAccountNumber',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Orig Acc Name',
          field: 'originatorAccountName',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'User Ref',
          field: 'userReference',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'TX Code',
          field: 'transactionCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Amount (£)',
          field: 'amountText',
          thClass: 'text-right',
          tdClass: 'text-right',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Processing Date',
          field: 'displayProcessingDate',
          hidden: true
        },
        {
          label: 'RTI',
          field: 'freeFormat',
          hidden: true
        }
      ],
      rows: [],
      totalRecords: 0,
      initTotalRecordCount: 0,
      serverParams: {
        submissionId: '',
        showPresubValMessages: this.showPresubValMessages,
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '' // 'asc' or 'desc'
        },

        page: 1, // what page I want to show
        perPage: 10 // how many items I'm showing per page
      },
      paginationOptions: {
        enabled: true,
        perPage: 10,
        setCurrentPage: 1,
        dropdownAllowAll: false,
        jumpFirstOrLast: true
      },
      defaultParams: {},
      procDateColumn: 10,
      rtiColumn: 11,
      customerPaygateId: ''
    }
  },

  methods: {
    formatDate: function (dateVal) {
      return moment(dateVal).format('DD/MM/YYYY')
    },

    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems(false)
    },

    onPerPageChange (params) {
      this.rows.length = 0
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems(true)
    },

    onSortChange (params) {
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type
        }
      })
      this.loadItems(true)
    },

    onColumnFilter (params) {
      this.appliedFilter = true
      this.updateParams(params)
      this.totalRecords = this.initTotalRecordCount
      this.loadItems(true)
    },

    async refreshGrid () {
      this.$refs.payments.currentPerPage = this.defaultParams.perPage
      this.$refs.payments.reset()

      this.paginationOptions.perPage = this.defaultParams.perPage
      this.$refs.payments.$refs.paginationBottom.perPage = this.defaultParams.perPage
      this.$refs.payments.$refs.paginationBottom.handlePerPage()

      // Set page
      this.paginationOptions.setCurrentPage = this.defaultParams.page

      this.updateParams(this.defaultParams)
      this.totalRecords = this.initTotalRecordCount
      await this.loadItems(true)
    },

    async getPaymentCount () {
      var url = `${process.env.VUE_APP_BACS_API_URL}bacs/payment/getBacsPaymentCount?submissionId=${this.submissionId}&fileNumber=${this.fileNumber}&customerPaygateId=${this.customerPaygateId}`
      var response = await axios.get(url, { showload: true })
      this.totalRecords = response.data
      this.initTotalRecordCount = this.totalRecords
    },

    async loadItems (resetStart) {
      var json = JSON.stringify({
        submissionId: this.submissionId,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        showPresubValMessages: this.showPresubValMessages,
        fileNumber: this.fileNumber,
        sortColumn: this.serverParams.sort.field,
        sortDirection: this.serverParams.sort.type,
        resetStart: resetStart,
        columnFilters: this.serverParams.columnFilters,
        paygateId: this.customerPaygateId
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/payment/get/',
        data: json,
        showload: true
      })

      this.rows = response.data.payments
      if (this.rows.length > 0) {
        // this.$store.dispatch('setPayments', response.data)
        this.$store.dispatch('setServerParams', this.serverParams)
        this.setupTable(response.data)
      }
    },

    setupTable (response) {
      this.totalRecords = response.filtersSet === 0 ? this.initTotalRecordCount : response.filteredRecordCount

      // If user has navigated forward more pages than the filtered result set, no filtered results will be shown.
      // So need to go back to selected filtered page if possible otherwise just go back to page 1.
      // Need to test a scenario that has a filtered result set of > 10 records.
      if (response.filtersSet > 0 && response.filteredRecordCount < this.serverParams.perPage) {
        this.serverParams.page = 1
      }

      if (this.rows[0].processingDate.length === 0) {
        this.columns[this.procDateColumn].hidden = true
      } else {
        this.columns[this.procDateColumn].hidden = false
        this.isMultiDate = true
      }
      if (this.rows[0].freeFormat === '0000') {
        this.columns[this.rtiColumn].hidden = true
      } else {
        this.columns[this.rtiColumn].hidden = false
      }
    },

    async createPaymentReport () {
      try {
        var json = JSON.stringify({
          submissionId: this.submissionId,
          fileNumber: this.fileNumber,
          sortColumn: this.serverParams.sort.field,
          sortDirection: this.serverParams.sort.type,
          columnFilters: this.serverParams.columnFilters,
          paygateId: this.customerPaygateId
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPaymentReport/',
          data: json,
          responseType: 'blob', // important
          showload: true
        })

        this.saveFile(response)
      } catch (ex) {
        console.log('error: ' + ex)
        console.log(JSON.stringify(ex))
      }
    },

    async createPaymentCsvFile () {
      try {
        var json = JSON.stringify({
          submissionId: this.submissionId,
          fileNumber: this.fileNumber,
          sortColumn: this.serverParams.sort.field,
          sortDirection: this.serverParams.sort.type,
          columnFilters: this.serverParams.columnFilters,
          paygateId: this.customerPaygateId
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPaymentCsvFile/',
          data: json,
          showload: true
        })

        this.saveFile(response)
      } catch (ex) {
        console.log('error: ' + ex)
        console.log(JSON.stringify(ex))
      }
    }
  },

  async mounted () {
    this.submissionId = this.$store.getters.submissionId

    this.fileNumber = this.$store.getters.fileNumber
    this.intelligibleSummaries = this.$store.getters.intelligibleSummaries
    this.customerSubmissions = this.$store.getters.customerSubmissions
    var viewSubmissionData = this.$store.getters.viewSubmissionData
    this.customerPaygateId = this.$store.getters.customerPaygateId

    this.defaultParams = JSON.parse(JSON.stringify(this.serverParams))

    if (viewSubmissionData.paymentsLoaded === false) {
      await this.getPaymentCount()
      await this.loadItems(true)
      viewSubmissionData.paymentsLoaded = true
      this.$store.dispatch('setViewSubmissionData', viewSubmissionData)
    } else {
      this.serverParams = this.$store.getters.serverParams
      await this.getPaymentCount()
      await this.loadItems(false)
    }
  }
}
</script>
