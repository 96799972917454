<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Customer Schedules - Summary View</h2>
      </div>
      <div class="idb-block-content">
      <div class="row form-group mb-3">
        <div class="col-md-3">
          <div class="form-group">
            <p-check
              id="previewCheck"
              class="p-switch p-fill"
              color="primary"
              v-model="useDateFilter"  @change="load()"
            >Filter By Collection Date</p-check>
          </div>
        </div>
      </div>
        <div class="row form-group mb-3" v-if="useDateFilter">
          <div class="col-md-2 required">
            Earliest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="endPaymentDatePicker"
              v-model="start"
              :format="formatDate" @input="load()" input-class="form-control datepicker" :disabledDates="disabledRangeDates"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <div class="row form-group mb-3" v-if="useDateFilter">
          <div class="col-md-2 required">
            Latest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="startPaymentDatePicker"
              v-model="end"
              :format="formatDate" :disabledDates="disabledRangeDatesEnd"
              @input="load()" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
          class="scroll-table"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'planStatus'">
              <b-badge pill
                :variant="getBadgeClass(props.row.planStatus)"
              >{{props.formattedRow[props.column.field]}}</b-badge>
            </span>
            <span v-else-if="props.column.field === 'paymentsRemaining'">
              {{props.formattedRow[props.column.field]}}<i
                           v-if="props.row.paymentsRemaining >= 0 && props.row.paymentsRemaining < 2"
                            v-b-tooltip.hover
                            title="Schedule reaching completion."
                            class="fa fa-exclamation-triangle ml-3 text-danger"
                          ></i>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import Datepicker from 'vuejs-datepicker'
  import papa from 'papaparse'
  import PrettyCheck from 'pretty-checkbox-vue/check'
  import moment from 'moment'
  import {mapGetters} from "vuex"
  const statusToText = (x) => {
    switch (x) {
      case 0:
        return 'Closed'
      case 1:
        return 'Alert'
      case 2:
        return 'Suspended'
      case 3:
        return 'Operational'
    }
  }

  const statusToBadgeVariant = (x) => {
    switch (x) {
      case 3:
        return 'success'
      case 2:
        return 'danger'
      case 1:
        return 'warning'
      case 0:
        return 'dark'
    }
  }
  export default {
    props: {
        ukPayerId: String
    },
    mixins: [tableFilterMixin, loading],
    components: {
      Datepicker,
      pCheck: PrettyCheck
    },
    data () {
      return {
        dateOptions: {
          format: 'DD/MM/YYYY HH:mm',
          useCurrent: false,
          stepping: 30,
          sideBySide: true,
        },
        end: null,
        start: null,
        useDateFilter: false,
        payerReference: '',
        rows: [],
        columns: [
          {
            label: 'paymentPlanId',
            field: 'paymentPlanId',
            hidden: true
          },
          {
            label: 'Reference',
            field: 'reference'
          },
          {
            label: 'Schedule Template Name',
            field: 'planTemplateName'
          },
          {
            label: 'Status',
            field: 'planStatus',
            formatFn: statusToText,
          },
          {
            label: 'Next Collection',
            field: 'nextPaymentDate',
            formatFn: this.formatDateWithNull
          },
          {
            label: 'First Collection Date',
            field: 'firstPaymentDate',
            formatFn: this.formatDate
          },
          {
            label: 'First Amount',
            field: 'firstAmount',
            formatFn: this.formatAmount,
            tdClass: 'text-right'
          },
          {
            label: 'Regular Amount',
            field: 'regularAmount',
            formatFn: this.formatAmount,
            tdClass: 'text-right'
          },
          {
            label: 'Last Amount',
            field: 'lastAmount',
            formatFn: this.formatAmount,
            tdClass: 'text-right'
          },
          {
            label: 'Ordinal',
            field: 'ordinal',
            hidden: true
          },
          {
            label: 'Step',
            field: 'step',
            hidden: true
          },
          {
            label: 'Type',
            field: 'recurrenceType',
            formatFn: this.recurrenceTypeFormat
          },
          {
            label: 'End Type',
            field: 'recurrenceEndType',
            formatFn: this.recurrenceEndTypeFormat
          },
          {
            label: 'Until Date',
            field: 'untilDate',
            formatFn: this.formatDateWithNull,
            hidden: true
          },
          {
            label: 'Total Collections',
            field: 'totalPayments',
            hidden: true
          },
          {
            label: 'Collections Made',
            field: 'paymentsMade',
            hidden: true
          },
          {
            label: 'Variable',
            field: 'variable',
            hidden: true
          },
          {
            label: 'Auddis 0N Sent',
            field: 'auddis0nSent',
            hidden: true
          },
          {
            label: 'Most Recent Collection',
            field: 'mostRecentPayment',
            formatFn: this.formatDateWithNull,
            hidden: true
          },
          {
            label: 'Collections Remaining',
            field: 'paymentsRemaining',
            formatFn: this.formatRemaining
          }
        ],
        serverParams: {
          sort: [{ field: 'reference', type: 'asc' }],
        },
      }
    },
    mounted () {
      this.end = new Date()
      let tempEnd = new Date()
      tempEnd.setDate(this.end.getDate() + 28)
      let tempStart = new Date()
      tempStart.setDate(this.end.getDate() - 28)
      this.start = tempStart
      this.end = tempEnd
      this.load()
    },
    methods: {
      load: async function () {
        try {
          var payerResponse = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftppayers/${this.ukPayerId}`)
          this.payerReference = payerResponse.data.payer.reference
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payerreport/${this.ukPayerId}`, { params: { ...this.buildGoodTableQuery(), startDate: new Date(this.start), endDate: new Date(this.end), useDateFiltering: this.useDateFilter } })
          if (response) {
            this.rows = response.data.data
            this.totalRecords = response.data.count
          }
        } catch (error) {
          this.$toastr.e(error)
        }
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
      formatStatus (value) {
        let retVal = ''
        switch(value) {
          case 1:
            retVal = 'Created'
            break
          case 2:
            retVal = 'In Progress'
            break
          case 3:
            retVal = 'Resolved'
            break
          case 4:
            retVal = 'Rejected'
            break
          case 5:
            retVal = 'Action Required'
            break
          default:
            retVal = 'None'
            break
        }
        return retVal
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      formatDateWithNull (date) {
        if (date===null || date === '0001-01-01T00:00:00') {
          return 'N/A'
        } else {
          return this.formatDate(date)
        }
      },
      async exportTable () {
        var fileName = 'schedule-report-' + this.payerReference

        var query = this.buildGoodTableQuery()
        var minDate = moment(this.end).format('DD-MM-YYYY')
        var maxDate = moment(this.start).format('DD-MM-YYYY')
        if(this.useDateFilter) {
          fileName = fileName + '-' + maxDate + '-'+minDate
        }
        fileName = fileName + '.csv'
        query.perPage = this.totalRecords
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payerreport/${this.ukPayerId}`, { params: {
          startDate: new Date(this.start),
            endDate: new Date(this.end),
          useDateFiltering: this.useDateFilter,
          ...query, paygateId: this.paygateId, exportView: true }, showload: true })
        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      codeToDescription (value) {
        let retval = ''
        switch (value) {
          case 1:
            retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
            break;
          case 2:
            retval = 'NO ADVANCE NOTICE WAS RECEIVED';
            break;
          case 3:
            retval = 'BANK CANCELS THE DIRECT DEBIT';
            break;
          case 4:
            retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
            break;
          case 5:
            retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
            break;
          case 6:
            retval = 'FRAUDULENT SIGNATURE';
            break;
          case 7:
            retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
            break;
          case 8:
            retval = 'SERVICE USER IS NOT RECOGNISED';
            break;
          default:
            retval = 'Invalid Code Supplied'
            break;
        }
        return retval
      },
      getBadgeClass (value) {
        return statusToBadgeVariant(value)
      },
      recurrenceTypeFormat (value) {
        let retval = ''
        switch (value) {
          case 0:
            retval = 'Weekly';
            break;
          case 1:
            retval = 'Monthly';
            break;
          case 2:
            retval = 'Yearly';
            break;
        }
        return retval
      },
      recurrenceEndTypeFormat (value) {
        let retval = ''
        switch (value) {
          case 0:
            retval = 'One Off';
            break;
          case 1:
            retval = 'Until Date';
            break;
          case 2:
            retval = 'Until Number Of Collections';
            break;
          case 3:
            retval = 'Ongoing';
            break;
          case 4:
            retval = 'Ad Hoc';
            break;
        }
        return retval
      },
      formatRemaining (value) {
        if (value === -1){
          return 'N/A'
        }
        return value
      }
    },
    computed: {
      ...mapGetters(['selectedCustomer']),
      startDateOptions () {
        return { ...this.dateOptions, maxDate: this.end }
      },
      endDateOptions () {
        return { ...this.dateOptions, minDate: this.start }
      },
      disabledRangeDates: function () {
        const maxDate = new Date(this.end)
        const minDate = new Date('0001-01-01T00:00:00Z')
        return {
          to: minDate,
          from: maxDate
        }
      },
      disabledRangeDatesEnd: function () {
        const maxDate = new Date(8640000000000000)
        const minDate = new Date(this.start)
        return {
          to: minDate,
          from: maxDate
        }
      }
    },
    watch: {
      selectedCustomer () {
        this.$router.push({path: '/collections/customer/manage'})
      },
    }
  }
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
