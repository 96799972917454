<template>
  <div>
    <!-- Customer Details -->
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Agent Details
          <help-icon docPath="/administration/customer/details/" />
        </h2>
      </div>

      <!-- Agent Details If they have not set it up -->
      <div class="idb-block-content" v-if="!agentSetup && loaded">
        <div
          class="alert alert-info"
        >Agent has currently not been initialised, please press the button below to start this process (This is usually a one time setup)</div>
        <button class="btn btn-primary" @click="setupAgent">Setup Agent</button>
      </div>

      <!-- Agent Details if they have it setup -->
      <div class="idb-block-content" v-if="agentSetup">
        <!-- SFTP Key Download -->
        <div class="form-group row">
          <label class="label-control col-md-3">
            Agent Initialisation Token
            <br />
            <small
              class="text-warning"
              v-if="isSystemAdmin"
            >System Administrators can't download Customer Agent Initialisation Tokens.</small>
          </label>

          <div class="col-md-6">
            <div class="input-group">
              <input
                :type="pinRevealed? 'text': 'password'"
                readonly
                class="form-control"
                v-model="sftpKeyPin"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  @click.prevent="showKey"
                  :disabled="isSystemAdmin"
                >{{ pinRevealed ? 'Hide' : 'Reveal' }}</button>
                <button
                  class="btn"
                  :class="pinRevealed ? 'btn-outline-primary' : ' btn-outline-secondary'"
                  type="button"
                  :disabled="!pinRevealed || isSystemAdmin"
                  @click="copyPin"
                >Copy Token</button>
              </div>
            </div>
          </div>
        </div>
        <!-- IP Whitelist Version 2 -->
        <div class="form-group row" v-if="!whitelistDisabled">
          <label class="label-control col-md-3">Enforce IP Address Restrictions</label>
          <div class="col-md-6">
            <p-check
              class="p-switch p-fill"
              color="primary"
              v-model="$v.policy.enforceIpRestrictions.$model"
            ></p-check>
          </div>
        </div>
      </div>
      <div class="idb-block-footer" v-if="agentSetup">
        <button v-if="!whitelistDisabled" class="btn btn-primary" type="submit" @click="saveWhitelist">Save Changes</button>
        <button
          class="btn btn-outline-warning ml-2"
          type="submit"
          @click="regenerateToken"
        >Regenerate Token</button>
      </div>
    </div>
    <div class="idb-block" v-if="agentSetup && policy.enforceIpRestrictions">
      <div class="idb-block-title">
        <h2>Allowed IP Addresses</h2>
      </div>
      <div class="idb-block-content">
        <transition name="fade">
          <div class="row">
            <div
              class="col-md-10 offset-md-1 pl-5"
              :class="{invalid: !$v.policy.ipAddressItem.atLeastOne}"
            >
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Active</th>
                    <th scope="col">IP Address (V4 format) with or without a CIDR</th>
                    <th scope="col">
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="addNewAddress"
                      >Add New Address</button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ipAddress,index) in $v.policy.ipAddressItem.$each.$iter" :key="index">
                    <td scope="row">
                      <p-check v-model="ipAddress.active.$model" :disabled="true"></p-check>
                    </td>
                    <td :class="{invalid: ipAddress.ipText.$error}">
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="ipAddress.ipText.$model"
                      />
                      <validation-messages v-model="ipAddress.ipText"></validation-messages>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-danger"
                        @click="removeAddress(index)"
                      >Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <validation-messages v-model="$v.policy.ipAddressItem">
                <template slot="override">
                  <small
                    class="form-text small"
                    v-if="!$v.policy.ipAddressItem.atLeastOne"
                  >Please enter at least one IP address restriction</small>
                </template>
              </validation-messages>
              <small>Allowed IP Address restrictions require manual changes to be made, only addresses with "Active" checked are in use.</small>
            </div>
          </div>
        </transition>
      </div>
      <div class="idb-block-footer">
        <button
          type="button"
          class="btn btn-outline-success ml-2"
          @click="addMyIpAddress"
        >Add My IP Address</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import roles from '@/Assets/Constants/roles'
import _ from 'lodash'
export default {
  name: 'AgentDetails',
  computed: {
    isSystemAdmin () {
      return this.$store.getters.isInRole(roles.SystemUser)
    },
    ...mapGetters(['selectedCustomer'])

  },
  watch: {
    selectedCustomer () { this.load() }
  },
  data () {
    return {
      loaded: false,
      agentSetup: false,
      sftpKeyPin: '',
      pinRevealed: false,
      sftpIpWhitelist: '',
      restrictionTypes: ['Allow'],
      policy: {
        enforceIpRestrictions: false,
        ipAddressItem: []
      },
      whitelistDisabled: true
    }
  },
  async mounted () {
    await this.load()
  },
  methods: {
    async load () {
      this.loaded = false
      // Have they been setup before?
      try {
        // If we get a response from this that isn't a 404 they have agent setup!
        const response = await axios.get(`${process.env.VUE_APP_AGENT_API_URL}sftpkey`, { showload: true })
        this.agentSetup = true
        await this.loadWhitelist()
        if (!this.isSystemAdmin) {
          this.sftpKeyPin = response.data.pin
        }
        this.agentSetup = true
      } catch (e) {
        if (e.response.status === 404) {
          // They have not setup agent!
          this.agentSetup = false
        } else {
          this.$snapbar.e('Unable to get Initialisation key', 'Error')
        }
      } finally {
        this.loaded = true
      }
    },
    async setupAgent () {
      try {
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Agent`, null,
          { showload: true, showerror: true, errormessage: 'Agent failed to setup' })
        await this.load()
      } catch { }
    },
    async loadWhitelist () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Customers/Whitelist`,
          { showload: true, showerror: true, errormessage: 'Failed to get SFTP IP Whitelist' })
        this.policy = response.data
        // this.$v.policy.ipAddressItem.$model = blah.ipAddressItem
        // this.$v.policy.enforceIpRestrictions.$model = blah.enforceIpRestrictions
      } catch { }
    },
    async saveWhitelist () {
      try {
        await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}Customers/Whitelist`, { whitelist: JSON.stringify(this.policy) },
          { showload: true, showerror: true, errormessage: 'Failed to save SFTP IP Whitelist' })
        this.$toastr.s('SFTP Whitelist updated.')
      } catch { }
    },
    copyPin () {
      if (this.sftpKeyPin) {
        this.copyTextToClipboard(this.sftpKeyPin)
      }
    },
    async showKey () {
      if (this.pinRevealed) {
        this.pinRevealed = false
      } else {
        this.pinRevealed = true
      }
    },
    fallbackCopyTextToClipboard (text) {
      var textArea = document.createElement('textarea')
      textArea.value = text

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand('copy')       
        this.$toastr.s('The Initialisation Token has been copied', 'Initialisation Token Copied')
      } catch (err) {
        this.$toastr.e('Unable to Copy Initialisation Token', 'Initialisation Token Failed to Copy')
      }

      document.body.removeChild(textArea)
    },
    copyTextToClipboard (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      let toastr = this.$toastr
      navigator.clipboard.writeText(text).then(function() {
        toastr.s('The Initialisation Token has been copied', 'Initialisation Token Copied')
      }, function(err) {
        toastr.e('Unable to Copy Initialisation Token', 'Initialisation Token to Copy')
      })
    },
    async regenerateToken () {
      await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Agent/regenerate`, null,
        { showload: true, showerror: true, errormessage: 'Agent failed to regenerate token.' })
      await this.setupAgent()
      this.$toastr.s('Initialisation Token has been regenerated.')
    },
    addNewAddress () {
      this.$v.policy.ipAddressItem.$model.push({
        ipText: '',
        restrictionType: 'Allow',
        active: false
      })
    },
    removeAddress (index) {
      this.$v.policy.ipAddressItem.$model.splice(index, 1)
      this.$v.policy.ipAddressItem.$touch()
    },
    async addMyIpAddress () {
      const response = await axios.get(`${process.env.VUE_APP_AGENT_API_URL}ipaddress`, { showload: true })
      this.$v.policy.ipAddressItem.$model.push({
        ipText: response.data.ipAddress,
        restrictionType: 'Allow',
        active: false
      })
    }
  },
  validations () {
    return {
      policy: {
        enforceIpRestrictions: {},
        ipAddressItem: {
          atLeastOne: (value, model) => {
            var result = true
            if (model.enforceIpRestrictions) {
              result = _.some(value)
            }

            return result
          },
          $each: {
            ipText: {
              required,
              ipAddress: (value) => /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/igm.test(value)
            },
            restrictionType: {},
            active: {}
          }
        }
      }
    }
  }
}
</script>
