<template>
  <div>
    <transition name="fade">
      <div class="snap-container" v-if="shown">
        <div class="snap-box" :class="type">
          <span class="snap-wrapper" :class="{expand:notify}">
            <span class="snap-logo"></span>
            <span class="snap-message" v-if="!html">{{message}}</span>
            <span class="snap-message" v-else v-html="message"></span>
          </span>
          <span class="snap-close" @click="hide">
            <span class="fas fa-times"></span>
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SnapBar',
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      message: '',
      type: '',
      shown: false,
      timer: null,
      notify: false,
      notifyTimer: null,
      html: false,
      autoClose: this.processOption('autoClose', false),
      timeout: this.processOption('timeout', 5000)
    }
  },
  methods: {
    show ({ message, type, autoClose, timeout, html }) {
      if (type === undefined) {
        type = 'info'
      }

      if (this.shown === true && this.message === message) {
        if (this.notifyTimer === null) {
          this.notify = true

          this.notifyTimer = setTimeout(() => {
            this.notify = false
            clearTimeout(this.notifyTimer)
            this.notifyTimer = null
          }, 500)
        }
      }

      this.shown = true
      this.message = message
      this.type = type
      this.html = html

      if (this.timer != null) {
        clearTimeout(this.timer)
      }
      if (this.processSetting(autoClose, 'autoClose')) {
        this.timer = setTimeout(() => {
          this.hide()
        }, this.processSetting(timeout, 'timeout'))
      }
    },
    hide () {
      this.shown = false
      this.type = ''
      this.message = ''
      this.html = false
    },
    i (message, options = {}) {
      this.show({ message, type: 'info', ...options })
    },
    s (message, options = {}) {
      this.show({ message, type: 'success', ...options })
    },
    e (message, options = {}) {
      this.show({ message, type: 'error', ...options })
    },
    w (message, options = {}) {
      this.show({ message, type: 'warning', ...options })
    },
    info (message, options = {}) {
      this.i(message, options)
    },
    success (message, options = {}) {
      this.s(message, options)
    },
    error (message, options = {}) {
      this.e(message, options)
    },
    warning (message, options = {}) {
      this.w(message, options)
    },
    processOption (optionValue, defaultValue) {
      if (!this.options) {
        return defaultValue
      }
      return typeof this.options[optionValue] !== 'undefined'
        ? this.options[optionValue]
        : defaultValue
    },
    processSetting (value, namedValue) {
      if (value === undefined || value === null) {
        return this[namedValue]
      }
      return value
    }
  },
  mounted () {
    var pushState = history.pushState
    var hideMethod = this.hide
    history.pushState = function () {
      pushState.call(history, ...arguments)
      hideMethod()
    }

    window.onpopstate = (event) => {
      this.hide()
      event.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
$black: #000000;
$grey: #999999;
$light-grey: #cccccc;
$white: #ffffff;
$near-black: #030303;
$green: #51a351;
$red: #bd362f;
$blue: #2f96b4;
$orange: #f89406;
$default-container-opacity: 0.8;

.snap-container {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  max-height: 40px;
  color: $white;
  overflow-y: hidden;
  transition: all 1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.snap-box {
  text-align: center;
  background-color: $near-black;
  padding: 3px;
  opacity: 0.9;
  min-height: 0;

  &:hover {
    opacity: 1;
  }
  .snap-close {
    right: 25px;
    position: absolute;
    vertical-align: middle;
    cursor: pointer;
  }

  .snap-wrapper {
    display: inline-block;
    &.expand {
      animation-name: shake;
      animation-duration: 0.5s;
    }
  }

  .snap-logo {
    &::before {
      font-family: "Font Awesome 5 Free";
      display: inline-block;
      padding-right: 10px;
      vertical-align: middle;
      font-weight: 900;
      color: $white;
    }
  }

  &.info {
    background-color: $blue;
    .snap-logo {
      &::before {
        content: "\f05a";
      }
    }
  }
  &.success {
    background-color: $green;
    .snap-logo {
      &::before {
        content: "\f00c";
      }
    }
  }
  &.error {
    background-color: $red;
    .snap-logo {
      &::before {
        content: "\f071";
      }
    }
  }
  &.warning {
    background-color: $orange;
    .snap-logo {
      &::before {
        content: "\f06a";
      }
    }
  }
}

.fade-enter,
.fade-leave-to {
  max-height: 0;
}

@keyframes shake {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-5%);
  }
  30% {
    transform: translateX(5%);
  }
  45% {
    transform: translateX(-3%);
  }
  60% {
    transform: translateX(1%);
  }
  75% {
    transform: translateX(-1%);
  }
  100% {
    transform: translateX(0%);
  }
}
</style>
