<template>
  <div id="indemnityClaims">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Indemnity Claims
              <span class="pull-right">
              </span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2">
                <strong>From Transaction Date</strong>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <strong>To Transaction Date</strong>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdFrom"
                  name="createdFrom"
                  id="createdFrom"
                  v-model="createdFrom"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  :monday-first="true"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdTo"
                  name="createdTo"
                  id="createdTo"
                  v-model="createdTo"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  :monday-first="true"
                ></vuejsDatepicker>
              </div>
            </div>
            <div v-if="invalidDateRange === true" class="row form-group">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <span class="mandatory">The "From Date" cannot be after the "To Date"</span>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <div class="row form-group">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="load"
                  :disabled="disableSearch"
                >Search</button>
              </div>
              <div class="col-md-10">
                <button
                  type="button"
                  class="btn btn-outline-primary pull-right"
                  v-on:click="clearSearchParams()"
                >Clear Search Criteria</button>
              </div>
            </div>
            <div v-show="searchComplete === true" class="row form-group">
              <div class="col-md-12">
                <vue-good-table
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :isLoading.sync="isTableLoading"
                  ref="table"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="false"
                  mode="remote"
                  :totalRows="totalRecords"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  styleClass="vgt-table striped bordered ledgerTxTable"
                >
                <template slot="table-row" slot-scope="props">
                  <span v-if = "props.column.field === 'reconciliationStatus'">
                    <span v-b-popover.hover.top="statusToolTip(props.row.unreconciledReason)">
                      <b-badge pill v-bind:variant="getStatusVariant(props.row.reconciliationStatus)">{{ props.row.reconciliationStatus }}</b-badge>
                    </span>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
                  <div slot="table-actions">
                    <table-actions
                      :data-url="dataUrl"
                      :export-data="tableServerData"
                      :print-enabled="false"
                      :table-amount="totalRecords"
                      :export-name="exportFilename"
                      :reload-table="load"
                      :table-columns="columns"
                      :clear-table-filters="clearTableFilters"
                    >
                    </table-actions>
                  </div>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <editLedgerTransaction
      :isVisible="isModalVisible"
      :isNewTx="isNewTx"
      :paygateId="this.selectedCustomer"
      :bankAccountId="this.bankAccountId"
      :sunId="this.sunId"
      :disabledDates="disabledDates"
      :ledgerTx="selectedLedgerTx"
      :bankAccounts="bankAccounts"
      :nominalCodes="nominalCodes"
      @close="editCancelled"
      @savedLedgerTransaction="savedLedgerTransaction">
    </editLedgerTransaction>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'
import bacsMixin from '@/Lib/BacsMixin.js'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import EditLedgerTransaction from '@/Views/JFTP/Reconcilliation/Ledger/EditLedgerTransaction'
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [
    bacsMixin,
    tableFilterMixin
  ],
  components: {
    editLedgerTransaction: EditLedgerTransaction
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    isFinanceUser () { return this.$store.getters.isInRole(roles.Finance) },
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}ledger/indemnityClaimTransactions`
    },
    tableServerData () {
      return this.buildGoodTableQuery()
    },
    exportFilename () {
      return 'IndemnityClaims ' + this.defaultFromDate + ' to ' + this.defaultToDate
    }    
  },
  data () {
    return {
      searchComplete: false,
      createdFrom: '',
      createdTo: '',
      totalRecords: 0,
      disabledDates: {},
      invalidDateRange: false,
      defaultFromDate: '',
      defaultToDate: '',
      columns: [
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Client ID',
          field: 'accountId',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Bank Account ID',
          field: 'bankAccountId',
          hidden: true
        },
        {
          label: 'Bank Account',
          field: 'reference',
          hidden: false
        },
        {
          label: 'Service User Name',
          field: 'bankStatementText',
          tdClass: 'ledgerTxWordwrap',
          hidden: true,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Created By',
          field: 'userName',
          tdClass: 'ledgerTxWordwrap',
          hidden: true,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Narrative 1',
          field: 'narrative1',
          tdClass: 'ledgerTxWordwrap',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Narrative 2',
          field: 'xref',
          tdClass: 'ledgerTxWordwrap',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Claim Status',
          field: 'claimStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'All Status',
            filterDropdownItems: [
              { value: 'Received', text: 'Received' },
              { value: 'Recouped', text: 'Recouped' },
              { value: 'Cancelled', text: 'Cancelled' },
              { value: 'CancelledComplete', text: 'CancelledComplete' }
            ]
          }
        },
        {
          label: 'TX Date',
          field: 'transactionDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account',
          field: 'clientOrBankTx',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Nom Code',
          field: 'nominalCode',
          hidden: false,
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Ledger TX Type',
          field: 'ledgerTxType',
          hidden: true
        },
        {
          label: 'XRefID',
          field: 'xrefId',
          tdClass: 'ledgerTxWordwrap',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Debit (£)',
          field: 'debit',
          type: 'decimal',
          formatFn: this.formatAmount,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Credit (£)',
          field: 'credit',
          type: 'decimal',
          formatFn: this.formatAmount,
          hidden: false,
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Rec Status',
          field: 'reconciliationStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'All Status',
            filterDropdownItems: [
              { value: '1', text: 'Unreconciled' },
              { value: '2', text: 'Reconciled' }
            ]
          }
        }
      ],
      rows: [],
      isModalVisible: false,
      bankAccountId: '',
      sunId: '',
      ledgerBalance: { currentBalance: 0.00 },
      datesInit: false,
      customerTypes: [],
      selectedCustomerType: 0,
      isNewTx: false,
      selectedLedgerTx: {},
      bankAccounts: [],
      nominalCodes: [],
      disableSearch: true,
      bankAccountColumn: 4
    }
  },

  methods: {
    async load () {
      if (!this.checkDates() || !this.datesInit) {
        return
      }

      try {
        this.isTableLoading = true
        const response = await axios.get(this.dataUrl,
          {
            params: { ...this.tableServerData },
            showerror: true,
            errormessage: 'Ledger transactions search failed',
            showload: true
          })

        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
        this.searchComplete = true
      } catch { } finally {
        this.isTableLoading = false
      }
    },

    // Override mixin version of buildGoodTableQuery since adding extra date params.
    buildGoodTableQuery () {
      const query = { ...this.serverParams.columnFilters }
      Object.keys(query).forEach((key) => { 
        // Remove wildcard option from 'reference' and 'claimstatus' filters so they are queried as "=" rather than "ilike".
        if (key !== 'reference' && key !== 'claimStatus') {
          query[key] = `*${query[key]}*` 
        }
      })
      query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }

      query.fromDate = moment(this.createdFrom).format('YYYY-MM-DD')
      query.toDate = moment(this.createdTo).format('YYYY-MM-DD')

      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
      return query
    },

    async clearSearchParams () {
      await this.initSearchDates()
      this.totalRecords = 0
      this.rows = []
      this.searchComplete = false
    },

    checkDates () {
      var datesOk = true
      if (this.createdFrom !== '' && this.createdTo !== '') {
        var fromDate = new Date(this.createdFrom)
        var toDate = new Date(this.createdTo)

        if (fromDate > toDate) {
          this.invalidDateRange = true
          datesOk = false
        } else {
          this.invalidDateRange = false
        }

        this.defaultFromDate = moment(this.createdFrom).format('DD-MM-YYYY')
        this.defaultToDate = moment(this.createdTo).format('DD-MM-YYYY')
      }

      return datesOk
    },

    async initSearchDates () {
      if (this.defaultFromDate === '' || this.defaultToDate === '') {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ledger/initDates?mode=bacsprocessingdate`)
        this.defaultFromDate = response.data.defaultFromDate
        this.defaultToDate = response.data.defaultToDate
      }
      this.createdFrom = new Date(this.$moment(this.defaultFromDate, 'DD/MM/YYYY'))
      this.createdTo = new Date(this.$moment(this.defaultToDate, 'DD/MM/YYYY'))
      // NB. Don't want to disable any future dates since the TX date could be at least a couple of days in the future for BACS payments; depends on weekends bank holidays etc.
      this.datesInit = true
    },

    formatAmount (value) {
      return this.formatValue(value)
    },

    createLedgerTransaction () {
      this.isNewTx = true
      this.isModalVisible = true
    },

    editTransaction (params) {
      this.selectedLedgerTx = params.row
      this.isNewTx = false
      this.isModalVisible = true
    },

    editCancelled () {
      this.isModalVisible = false
    },

    async savedLedgerTransaction () {
      this.isModalVisible = false
      if (this.isNewTx) {
        await this.loadLedgerBalance()
        await this.load()
      }
    },

    async getEnums () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/search/getDropdownValues`)
      this.customerTypes = response.data.customerTypes
    },

    changeCustomerType () {
      this.searchComplete = false
    },

    async loadBankAccounts () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}bankAccounts/DropdownOfType?bankAccountType=0`, { showload: true })
      this.bankAccounts = response.data
      // console.log('banks: ' + JSON.stringify(this.bankAccounts))
      let bankAccountsDropdown = []
      this.bankAccounts.forEach(bankAccount => {
        bankAccountsDropdown.push({ value: bankAccount.bankAccountId, text: bankAccount.reference })
      });
      // console.log('banks: ' + JSON.stringify(bankAccountsDropdown))
      this.$set(this.columns[this.bankAccountColumn], 'filterOptions', { enabled: true, placeholder: 'All Banks', filterDropdownItems: bankAccountsDropdown })
    },

    getStatusVariant (reconciliationStatus) {
      var variant = ''
      switch (reconciliationStatus) {
        case 'Unprocessed':
          variant = 'secondary'
          break
        case 'Processed':
          variant = 'info'
          break
        case 'Unreconciled':
          variant = 'secondary'
          break
        case 'Reconciled':
          variant = 'success'
          break
        case 'DuplicateMatch':
          variant = 'warning'
          break
      }
      return variant
    },

    showViewBanklineFileOption (props) { 
      return props.row.bankLineFileId !== null && props.row.bankLineFileId !== undefined && props.row.clientOrBankTx !== 'Client' 
    },

    onRowClick (params) {
      var ledgerTxId = params.row.id
      // console.log('ledgerTxId: ' + ledgerTxId + ', nom code: ' + params.row.nominalCode + ', rec status: ' + params.row.reconciliationStatus)
      if (params.row.nominalCode === '9999' && params.row.reconciliationStatus === 'Unreconciled') {
        // console.log('Show modal... bank account ID: ' + params.row.bankAccountId)
        this.editTransaction(params)
      }
    },

    viewBanklineFile(params) {
      console.log('viewBanklineFile')
      // const subUrl = `/admin/bandr/banklinetransactions/${params.row.bankLineFileId}`
      // this.$router.push(subUrl)
      if (params.row.bankLineFileId !== null && params.row.bankLineFileId !== undefined) {
        const routeData = this.$router.resolve({ path: `/admin/bandr/banklinetransactions/${params.row.bankLineFileId}`, query: { } })
        window.open(routeData.href, '_blank')
      }
    },

    statusToolTip (unreconciledReason) {
      return unreconciledReason
    }
  },
  watch: {
    async selectedCustomer () {
      this.clearSearchParams()
      // await this.loadCustomer()
      // await this.loadLedgerBalance()
      this.selectedCustomerType = 1
    }
  },

  async mounted () {
    document.getElementById('createdFrom').style.backgroundColor = 'white'
    document.getElementById('createdTo').style.backgroundColor = 'white'
    await this.getEnums()
    await this.initSearchDates()
    // await this.loadCustomer()
    // await this.loadLedgerBalance()
    await this.loadBankAccounts()
    // await this.loadNominalCodes()
    this.$nextTick(() => {
      this.disabledDates.from = new Date()
      this.disableSearch = false
    })
  }
}
</script>
<style lang="scss">
.pagepointer {
  cursor: default !important;
}

.ledgerTxWordwrap {
  overflow-wrap: break-word; word-wrap: break-word; word-break: break-all !important;
}

.ledgerTxTable {
  display: block;
  overflow-x: auto;
}
</style>
