<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline Outbound Files<span class="pull-right"><a :href="`${docUrl}/bankline/files/`" target="_blank"><i class="far fa-question-circle d-none"></i></a></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <vue-good-table
            :paginationOptions="paginationOptions"
            :sort-options="sortOptions"
            :isLoading.sync="isTableLoading"
            :rows="row_data"
            :columns="columns"
            mode="remote"
            :totalRows="totalRecords"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
            ref="table"
            :lineNumbers="true"
            styleClass="vgt-table striped bordered table-hover"
            @on-row-click="onOutRowClick">
            <template slot="table-row" slot-scope="props">
              <span v-if = "props.column.field == 'originalFilename'">
                <div>{{props.row.originalFilename}}</div>
                <div class="tinyText mt-2">Items in file: {{props.row.numItems}}</div>
              </span>
              <span v-else-if = "props.column.field == 'type'">
                <span v-if = "props.row.type == 'BLK'" >Daily Bulk File</span>
                <span v-else-if = "props.row.type == 'STD'">Standalone STD File</span>
                <span v-else-if = "props.row.type == 'URG'">Standalone URG File (CHAPS)</span>
                <span v-else>{{props.row.type}}</span>
              </span>
              <span v-else-if = "props.column.field == 'buttons'">
                <div v-if="props.row.status == '11'">
                  <b-button @click.prevent="btnOutDownloadFile(props)" class="" variant="outline-primary">Download</b-button>
                </div>
                <div v-else>
                  <b-dropdown right @click.prevent="btnOutDownloadFile(props)" variant="outline-primary" split text="Download" class="m-2">
                    <b-dropdown-item v-if="props.row.status != '11'" @click.prevent="btnOutArchiveFile(props, true)"><i class="glyphicon ti-share mr-1"></i>Mark as Sent to Bankline</b-dropdown-item>
                    <b-dropdown-item v-if="props.row.status != '11' && props.row.archive==false" active @click.prevent="btnOutDeleteFile(props.row.id, props)"><i class="glyphicon ti-trash mr-1"></i>Delete File</b-dropdown-item>
                  </b-dropdown>
                </div>
              </span>
              <span v-else-if = "props.column.field == 'description'">
                <b-badge pill v-if = "props.row.status == 'outbound'" variant="success">Generated by JFTP</b-badge>
                <span v-else>{{props.row.status}}</span>
                <div class="tinyText mt-1" v-if = "props.row.status == '12'">Downloaded: {{reformatDate(props.row.downloadDate)}}</div>
              </span>
              <span v-else-if = "props.column.field == 'status'">
                <b-badge pill v-if = "props.row.status == '11'" variant="warning">Not Downloaded</b-badge>
                <b-badge pill v-else-if = "props.row.status == '12'" variant="success">Downloaded</b-badge>
                <b-badge pill v-else-if = "props.row.status == '99'" variant="danger">Error</b-badge>
                <span v-else>{{props.row.status}}</span>
                <b-badge pill v-if = "props.row.archive == true" variant="info" class="ml-2">Sent to Bankline</b-badge>
                <div class="tinyText mt-2" v-if = "props.row.status == '12'">Downloaded: {{reformatDate(props.row.downloadDate)}}</div>
                <div class="tinyText mt-1" v-if = "props.row.archive == true">Sent to Bankline: {{reformatDate(props.row.archiveDate)}}</div>
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
            <div slot="table-actions">
              <button
                @click.prevent="clearTableFilters"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Clear filters'">
                <span class="fa-stack" style="font-size: 10px;">
                  <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                  <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                </span>
              </button>
              <b-button @click.prevent="load" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the table data.'" ><i class="fas fa-sync-alt pointer dimmedIcon"></i></b-button>
            </div>
          </vue-good-table>
          <br/>
          <b-form-checkbox v-model="showArchived" >
            Show files that have been sent to Bankline
          </b-form-checkbox>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div class="btn-group ml-2" role="group">
                <button id="btnGroupDrop1" @click="btnAdhocBanklinePayment" type="button" class="btn btn-primary">
                  <i class="fas fa-money-bill-wave-alt mr-2"></i>Ad-hoc Bankline Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
import { saveAs } from 'file-saver'
export default {
  name: 'banklineoutbound',
  mixins: [tableFilterMixin],
  components: {
    swal,
    saveAs
  },
  data () {
    return {
      btnOutLatch: false,
      showArchived: true,
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'File Details',
          field: 'originalFilename',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Created',
          field: 'creationDate',
          hidden: false,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSX',
          dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
        },
        {
          label: 'File Type',
          field: 'type',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: false
          },
          hidden: true
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: false
          }
        },
        {
          field: 'buttons',
          sortable: false,
          filterOptions: { enabled: false },
          tdClass: 'text-center',
          thClass: 'text-center',
          hidden: false
        }
      ],
      serverParams: {
        sort: [{ field: 'creationDate', type: 'desc' }]
      },
      row_data: [],
      totalRecords: 0,
      paginationOptions: { enabled: true, perPage: 10, dropdownAllowAll: false }
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async load () {
      this.loadData()
    },
    async loadData () {
      const postObj = {
        serverParams: this.serverParams,
        description: 'outbound',
        id: this.id,
        archive: this.showArchived
      }
      this.isTableLoading = true
      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineoutboundfiles`, postObj)
        if (res) {
          this.row_data = res.data.resObj.data
          this.totalRecords = res.data.resObj.count
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
        this.row_data = []
        this.totalRecords = 0
      } finally {
        this.isTableLoading = false
      }
    },
    async btnAdhocBanklinePayment () {
      this.$router.push('/admin/bandr/bankline/banklinepayment')
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    onOutRowClick (params) {
      if (!this.btnOutLatch) {
        this.$router.push(`/admin/bandr/bankline/banklinepaymentfile/${params.row.id}`)
      }
    },
    async btnCreateTestFile () {

    },
    async btnOutDownloadFile (item) {
      this.btnOutLatch = true
      let res
      try {
        // Download the file from node - note the 'responseType: 'blob'' is required.
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineoutboundfiledownload/${item.row.id}`, { responseType: 'blob' })
        if (res) {
          try {
            // This saves the  downloaded blob as a file - should invoke normal browser file behaviour and might be different
            // from user to user.  For example, on my pc, downloaded CSV open stright into notepad++, for user users it might promopt a save-as dialog.
            await saveAs.saveAs(res.data, item.row.originalFilename)
            await this.loadData()
            this.btnOutLatch = false

            // Force the UI to update - JFTP-3797
            if (item.row.status === 11) {
              item.row.status = 12
            }
          } catch (e) {
            console.log(e)
            this.$snapbar.e('Error downloading Bankline file.  The file could not be saved.')
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to download Bankline files.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
      }
    },
    async isTodaysFile (creationDate) {
      // Checks if creationDate is today
      const cd = new Date(creationDate)
      const today = new Date()
      const isToday = cd.getDate() === today.getDate() &&
        cd.getMonth() === today.getMonth() &&
        cd.getFullYear() === today.getFullYear()
      return isToday
    },
    async btnOutArchiveFile (prop, archive) {
      this.btnOutLatch = true
      // Don't allow a file created today to be archived
      // if (this.isTodaysFile(prop.row.creationDate) === true) {
      //   this.$snapbar.e('You cannot archive a file that was created today.')
      //   this.btnOutLatch = false
      //   return
      // }
      if (prop.row.status === 11) {
        this.$snapbar.e('You cannot set the status to "Sent to Bankline" because the file has not yet been downloaded.')
        return
      }
      const result = await swal.fire({
        title: 'Are you sure you want to change the status of this Bankline file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        const body = {
          _id: prop.row._id,
          archive
        }
        let res
        try {
          res = await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}archivebanklinefile`, body, { showload: true })
          if (res) {
            this.$toastr.s('The Bankline file was status was successfully changed.')
            this.load()
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to change the status of this file.')
          } else {
            console.log(e)
            this.$snapbar.e(`Could not change the status of this file - ${e.message}`)
          }
        }
      }
      this.btnOutLatch = false
    },
    async btnOutDeleteFile (id, prop) {
      this.btnOutLatch = true
      if (prop.row.status === 11) {
        this.$snapbar.e('You cannot delete a file that has not been downloaded.')
        return
      }

      const result = await swal.fire({
        title: 'Are you sure you want to permanently delete this Bankline file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}banklinefile/${id}`, { showload: true })
          if (res) {
            this.$toastr.s('The Bankline file was successfully deleted.')
            this.load()
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this file.')
          } else {
            console.log(e)
            this.$snapbar.e(`Could not delete this file - ${e.message}`)
          }
        }
      }
      this.btnOutLatch = false
    }
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.load()
    },
    showArchived: function (val) {
      localStorage.showArchived = this.showArchived
      this.loadData()
    }
  },
  created () {
    // Retrieve the archive checkbox state.
    if (localStorage.showArchived === undefined) {
      localStorage.showArchived = this.showArchived
    }
    this.showArchived = localStorage.showArchived
  }
}
</script>
<style scoped>
  td {
    cursor: pointer;
  }
  .tinyText {
    font-size: x-small;
  }
</style>
