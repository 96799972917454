<template>
  <b-modal
    id="manage-payment-modal"
    ref="managePaymentModal"
    title="Payment Details for FPS Submission"
    size="xl"
    hide-header-close
    @ok="SaveBlockDetail"
    @shown="load"
  >
    <div slot="modal-ok">Save</div>
    <div>
      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label class="required" for="thirdPartyAccountName">Name</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            type="text"
            v-model.trim="$v.thirdPartyAccountName.$model"
            :readonly="readonly"
            :disabled="disabledFields"
          ></b-form-input>
        </b-col>
        <b-col offset-sm="3" class="pl-3" v-if="$v.thirdPartyAccountName.$dirty">
          <label
            class="form-text text-danger small text-nowrap"
            v-if="!$v.thirdPartyAccountName.required"
          >Name is required</label>
          <label
            class="form-text text-danger small text-nowrap"
            v-if="!$v.thirdPartyAccountName.maxLength"
          >Name must be less than {{$v.thirdPartyAccountName.$params.maxLength.max}} characters</label>
        </b-col>
      </b-row>

      <!-- Sort Code -->
      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label class="required" for="thirdPartySortCode">Sort Code</label>
        </b-col>
        <b-col sm="2">
          <the-mask
            type="text"
            class="form-control"
            v-model.trim="$v.thirdPartySortCode.$model"
            v-focus
            :mask="['##-##-##']"
            :guide="true"
            ref="thirdPartySortCode"
            :readonly="readonly"
            :disabled="disabledFields"
          ></the-mask>
          <!-- Validation -->
          <span v-if="$v.thirdPartySortCode.$dirty">
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.thirdPartySortCode.required"
            >Sort Code is required</small>
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.thirdPartySortCode.minLength"
            >Sort Code must be at least {{$v.thirdPartySortCode.$params.minLength.min}} numbers</small>
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.thirdPartySortCode.maxLength"
            >Sort Code must be less than {{$v.thirdPartySortCode.$params.maxLength.max}} numbers</small>
            <small
              class="form-text text-warning small text-nowrap"
              v-if="!sortCodeValid && $v.thirdPartySortCode.required"
            >{{ sortCodeErrorMessage }}</small>
          </span>
        </b-col>
        <span>
          <b-col v-if="sortCodeValid">
            <i class="fas fa-check mt-2"></i>
          </b-col>
        </span>
      </b-row>

      <!-- Account Number -->
      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label class="required" for="thirdPartyAccountNumber">Account Number</label>
        </b-col>
        <b-col sm="2">
          <the-mask
            type="text"
            class="form-control"
            v-model.trim="$v.thirdPartyAccountNumber.$model"
            :mask="['########']"
            :guide="false"
            ref="thirdPartyAccountNumber"
            :readonly="readonly"
            :disabled="disabledFields"
          ></the-mask>
          <!-- Validation -->
          <span v-if="$v.thirdPartyAccountNumber.$dirty">
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.thirdPartyAccountNumber.required"
            >Account Number is required</small>
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.thirdPartyAccountNumber.minLength"
            >Account Number must be at least {{$v.thirdPartyAccountNumber.$params.minLength.min}} numbers</small>
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.thirdPartyAccountNumber.maxLength"
            >Account Number must be less than {{$v.thirdPartyAccountNumber.$params.maxLength.max}} numbers</small>
            <small
              class="form-text text-warning small text-nowrap"
              v-if="!accountNumberValid && $v.thirdPartyAccountNumber.required"
            >{{ accountNumberErrorMessage }}</small>
          </span>
        </b-col>
        <span>
          <b-col v-if="accountNumberValid==true">
            <i class="fas fa-check mt-2"></i>
          </b-col>
        </span>
      </b-row>

      <!-- User Ref -->
      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label for="userReference">User Reference</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            type="text"
            v-model.trim="$v.userReference.$model"
            :readonly="readonly"
            :disabled="disabledFields"
          ></b-form-input>
          <!-- Validation -->
          <span v-if="$v.userReference.$dirty">
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.userReference.maxLength"
            >User reference must be less than {{$v.userReference.$params.maxLength.max}} characters</small>
          </span>
        </b-col>
      </b-row>

      <!-- Transaction Code -->
      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label class="required" for="transactionCode">Transaction Code</label>
        </b-col>
        <b-col sm="2">
          <b-form-select
            :id="'transactionCodeSelector'"
            v-model="transactionCode"
            :options="transactionCodes"
            value-field="code"
            text-field="code"
            v-if="!readonly"
            :disabled="disabledFields"
            @input="transactionCodeChanged()"
          ></b-form-select>
          <b-form-input
            type="text"
            v-model.trim="$v.transactionCode.$model"
            v-if="readonly"
            :readonly="readonly"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-sm="3">
          <span class="form-text small">{{ transactionCodeDescription }}</span>
        </b-col>
      </b-row>

      <!-- Amount -->
      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label class="required" for="amount">Amount</label>
        </b-col>
        <b-col sm="3">
          <currency-input
            type="text"
            v-model="$v.amount.$model"
            :readonly="readonly"
            :disabled="disabledFields"
          ></currency-input>
        </b-col>
        <b-col offset-sm="3" class="pl-3" v-if="$v.amount.$dirty">
          <label class="text-danger small" v-if="!$v.amount.required">Amount is required</label>
          <label class="text-danger small" v-if="!$v.amount.maxValue">Maximum payment is £20,000,000</label>
        </b-col>
        <b-col offset-sm="3" class="pl-3" v-if="!$v.amount.amountZeroValidForTransactionCode">
          <label class="text-danger small">Amount must be £0.00 for this transaction code</label>
        </b-col>
        <b-col offset-sm="3" class="pl-3" v-if="!$v.amount.amountNonZeroValidForTransactionCode">
          <label
            class="text-danger small"
          >Amount must be greater than £0.00 for this transaction code</label>
        </b-col>
      </b-row>

      <!-- Validation Messages -->
      <span v-if="validationMessages.length > 0">
        <b-table
          class="mt-2"
          borderless
          small
          caption-top
          :striped="true"
          :items="validationMessages"
          :fields="validationFields"
          :per-page="10"
        >
          <template v-slot:cell(messageSeverity)="data">
            <span v-bind:class="data.value" class="badge badge-pill table-pill">{{ data.value }}</span>
          </template>
        </b-table>
      </span>
    </div>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { required, maxLength, minLength, maxValue } from 'vuelidate/lib/validators'
import CurrencyInput from '@/Assets/Components/CurrencyInput.vue'
import { TheMask } from 'vue-the-mask'
import Datepicker from 'vuejs-datepicker'
import Utility from '@/Assets/Mixins/Utility'
import bacsMixin from '@/Lib/BacsMixin.js'

export function amountZeroValidForTransactionCode (amount) {
  switch (this.transactionCode) {
    case '0N':
    case '0C':
    case '0S':
      return (amount === 0)
    default:
      return true
  }
}

export function amountNonZeroValidForTransactionCode (amount) {
  switch (this.transactionCode) {
    case '99':
    case '17':
    case '01':
    case '18':
    case '19':
    case 'Z4':
    case 'Z5':
      return (amount > 0)
    default:
      return true
  }
}

export default {
  mixins: [Utility, bacsMixin],
  components: {
    CurrencyInput,
    TheMask,
    Datepicker
  },
  props: {
    // readonly: Boolean,
    disabledFields: Boolean,
    itemId: String, // block referrer
    submissionId: String, // submission referrer
    bureauJobId: String, // this bureau job referrer
    pkid: String, // this payment referrer
    isNew: Boolean // New payment or modify existing
  },

  async created () {
    this.disabledDates = await this.setUpBACSDatePicker()
    if (this.isNew) {
      var nextDateResponse = await this.getNextPaymentDate()
      console.log('next date response', nextDateResponse)
      this.paymentDate = nextDateResponse
    }
  },
  data () {
    return {
      thirdPartyAccountName: '',
      thirdPartySortCode: '',
      thirdPartyAccountNumber: '',
      userReference: '',
      amount: 0.00,
      validationResult: {},
      valid: false,
      sortCodeValid: false,
      accountNumberValid: false,
      sortCodeErrorMessage: '',
      accountNumberErrorMessage: '',
      disabledDates: {},
      readonly: false,
      // if (this.submission.paymentNetworkType === 'BacstelIp') {
      // } else {
      //   this.txCodes = ['99', 'Z4', 'Z5']
      // }
      transactionCode: '99',
      transactionCodeDescription: 'standard bank giro (i.e. credit)',
      transactionCodes: [
        { code: '99', description: 'Standard bank giro (i.e. credit)' },
        { code: '17', description: 'Standard bank debit' },
        { code: '01', description: 'First payment. This has to be used the first time a direct debit is collected by the customer' },
        { code: '18', description: 'Re-presentation. If the direct debit failed to go through one time, it can be sent again with this code' },
        { code: '19', description: 'Final payment. The last in a series of direct debit collections. This will also close the mandate' },
        { code: '0N', description: 'Set-up a new instruction with the payer’s Bank/Building Society branch' },
        { code: '0C', description: 'Cancel an existing AUDDIS instruction' },
        { code: '0S', description: 'Convert an existing manually set-up DDI to an AUDDIS direct debit' },
        { code: 'Z4', description: 'Interest payment' },
        { code: 'Z5', description: 'Dividend payment' }
      ],
      validationMessages: [],
      validationFields: [
        {
          key: 'title',
          label: 'Title'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'messageSeverity',
          label: 'Severity'
        }
      ]
    }
  },
  watch: {
    thirdPartySortCode: function () {
      this.validate()
    },
    thirdPartyAccountNumber: function () {
      this.validate()
    }
  },
  methods: {
    load: async function () {
      console.log('is new payment modal', this.isNew)
      console.log('props disabledFields', this.disabledFields)
      console.log('props itemId', this.itemId)
      console.log('props submissionId', this.submissionId)
      console.log('props bureauJobId', this.bureauJobId)
      console.log('props pkid', this.pkid)
      console.log('props isNew', this.isNew)

      if (this.isNew) {
        this.thirdPartyAccountName = ''
        this.thirdPartySortCode = ''
        this.thirdPartyAccountNumber = ''
        this.userReference = ''
        this.amount = ''
        this.processingDate = ''
      } else {
        var params = {
          submissionId: this.submissionId,
          itemId: this.itemId,
          bureauJobId: this.bureauJobId,
          pkid: this.pkid
        }
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/GetCurrentSubmissionPayment', params, { showload: true })
        console.log('response from get current payment', response)

        this.thirdPartyAccountName = response.data.thirdPartyAccountName
        this.thirdPartySortCode = response.data.thirdPartySortCode
        this.thirdPartyAccountNumber = response.data.thirdPartyAccountNumber
        this.userReference = response.data.userReference
        this.amount = response.data.amount
      }
    },
    SaveBlockDetail: async function () {
      if (this.isNew) {
        var paramsNew = {
          submissionId: this.submissionId,
          itemId: this.itemId,
          bureauJobId: this.bureauJobId,
          thirdPartyAccountName: this.thirdPartyAccountName,
          thirdPartySortCode: this.thirdPartySortCode,
          thirdPartyAccountNumber: this.thirdPartyAccountNumber,
          userReference: this.userReference,
          amount: this.amount,
          processingDate: '',
          transactionCode: this.transactionCode
        }
        var responseNew = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/SaveNewSubmissionPayment', paramsNew, { showload: true })
        console.log('response from save current payment', responseNew)
      } else {
        var params = {
          submissionId: this.submissionId,
          itemId: this.itemId,
          bureauJobId: this.bureauJobId,
          pkid: this.pkid,
          thirdPartyAccountName: this.thirdPartyAccountName,
          thirdPartySortCode: this.thirdPartySortCode,
          thirdPartyAccountNumber: this.thirdPartyAccountNumber,
          userReference: this.userReference,
          amount: this.amount,
          processingDate: '',
          transactionCode: this.transactionCode
        }
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/SaveCurrentSubmissionPayment', params, { showload: true })
        console.log('response from save current payment', response)
      }
      this.$emit('submit')
    },
    validate: _.debounce(async function () {
      const data = { sortCode: this.thirdPartySortCode }

      if (this.thirdPartyAccountNumber.length === 8) {
        data.accountNumber = this.thirdPartyAccountNumber
      }

      try {
        const validateUrl = process.env.VUE_APP_VALIDATE_API_URL + 'BankAccount'
        const response = await axios.get(validateUrl, { params: data }, { showload: true })

        this.validationResult = response.data
        console.log(this.validationResult)

        this.sortCodeErrorMessage = ''
        this.accountNumberErrorMessage = ''
        if (this.validationResult.valid) {
          console.info('Sort code and account number are valid')
          this.sortCodeValid = true
          this.accountNumberValid = true
        } else {
          if (this.validationResult.errorCode === '1502') {
            console.info('Sort code is 222 valid')
            this.sortCodeValid = true
            this.accountNumberValid = false
            this.accountNumberErrorMessage = this.validationResult.errorText
          } else {
            console.info('Sort code and account number are invalid')
            this.sortCodeValid = false
            this.sortCodeErrorMessage = this.validationResult.errorText
            this.accountNumberValid = false
            this.accountNumberErrorMessage = this.validationResult.errorText
          }
        }

        if (this.validationResult.valid || this.validationResult.errorCode === '1502') {
          // this.getSubBranch()
        }
      } catch (e) {
        console.log(e)
      }
    }, 800),
    transactionCodeChanged () {
      const index = this.transactionCodes.findIndex(x => x.code === this.transactionCode)
      this.transactionCodeDescription = this.transactionCodes[index].description
    }
  },
  validations: {
    thirdPartyAccountName: { maxLength: maxLength(50), required },
    thirdPartySortCode: { required, minLength: minLength(6), maxLength: maxLength(6) },
    thirdPartyAccountNumber: { minLength: minLength(8), maxLength: maxLength(8), required },
    userReference: { maxLength: maxLength(18) },
    transactionCode: { minLength: minLength(2), maxLength: maxLength(2), required },
    amount: { maxValue: maxValue(20000000), required, amountZeroValidForTransactionCode, amountNonZeroValidForTransactionCode }
  }
}
</script>
