<template>
<div>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        <span class="mr-2" v-if="elAdvancedFilter.showRange == 'today'">Today's Events</span>
        <span class="mr-2" v-else-if="elAdvancedFilter.showRange == 'range'">Events between {{reformatDate2(elAdvancedFilter.fromDate)}} and {{reformatDate2(elAdvancedFilter.toDate)}}</span>
        <span class="mr-2" v-else>All Events</span>
        <span v-if="isLoading" class="spinner-border spinner-border-sm ml-1" role="status"></span>
        <favourite-icon></favourite-icon>
      </h2>
    </div>
    <div class="idb-block-content">
      <div class="progress">
        <div class="progress-bar bg-secondary" role="progressbar" :style=getCountdown() aria-valuemax="100"></div>
      </div>
      <br/>
      <div class="idb-block">
        <div
          class="idb-block-title titleHover"
          v-b-toggle.advancedFilterCollapse>
          Advanced Filter
          <i class="fa fa-chevron-down"></i>
          <i class="fa fa-chevron-up"></i>
        </div>
        <b-collapse v-model="elAdvancedFilter.visible" id="advancedFilterCollapse">
          <div class="idb-block-content">
            <div v-if="elAdvancedFilter.show">
              <div class="row">
                <div class="col-md-2">Page Size</div>
                <div class="col-md-1">
                  <b-form-select v-model="elAdvancedFilter.perPage" @change="changedFilter()">
                    <option v-for="option in pageSizePptions" v-bind:value="option.value" :key="option.index" >
                      {{ option.text }}
                    </option>
                  </b-form-select>
                </div>
                <div class="col-md-9">
                  <button id="btnGroupDrop1"  v-b-popover.hover.top.d500="'Resets the filter back to default.'" @click="clearFilter" type="button" class="btn btn-outline-secondary float-right">X</button>
                </div>
              </div>
              <br/>
              <div class="row">
                <div class="col-md-2 form-group">
                  <p-radio value="all" color="primary" v-model="elAdvancedFilter.showRange" @change="changedFilter()">Show All</p-radio>
                </div>
                <div class="col-md-2 form-group">
                  <p-radio value="today" color="primary" v-model="elAdvancedFilter.showRange" @change="changedFilter()">Show Today</p-radio>
                </div>
                <div class="col-md-1 form-group">
                  <p-radio value="range" color="primary" v-model="elAdvancedFilter.showRange" @change="changedFilter()">Show Range</p-radio>
                </div>
                <transition name="fadein">
                  <div class="col-md-1 col-sm-12" v-if="elAdvancedFilter.showRange === 'range'">
                    <span class="float-right">From:</span>
                  </div>
                </transition>
                <transition name="fadein">
                  <div class="col-md-2 col-sm-12" v-if="elAdvancedFilter.showRange === 'range'">
                    <div class="input-group">
                      <div class="right-inner-addon">
                        <datepicker
                          id="fromDatePicker"
                          format="dd/MM/yyyy"
                          :bootstrap-styling="bootstrapStyling"
                          :typeable="typeable"
                          :disabled-dates="disabledDatesFrom"
                          v-model="elAdvancedFilter.fromDate"
                          @input="changedFilter()"
                        />
                        <i class="fa fa-calendar form-control-feedback"></i>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="fadein">
                  <div class="col-md-1 col-sm-12" v-if="elAdvancedFilter.showRange === 'range'">
                    <span class="float-right">To:</span>
                  </div>
                </transition>
                <transition name="fadein">
                  <div class="col-md-2 col-sm-12" v-if="elAdvancedFilter.showRange === 'range'">
                    <div class="input-group">
                      <div class="right-inner-addon">
                        <datepicker
                          id="toDatePicker"
                          format="dd/MM/yyyy"
                          :bootstrap-styling="bootstrapStyling"
                          :typeable="typeable"
                          :disabled-dates="disabledDatesTo"
                          v-model="elAdvancedFilter.toDate"
                          @input="changedFilter()"
                        />
                        <i class="fa fa-calendar form-control-feedback"></i>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <div class="idb-block-footer">
      <div class="row">
        <div class="col-12">
          <span class="btn-group mr-2" role="group">
            <button id="btnGroupDrop1" @click="btnRefresh" type="button" class="btn btn-primary">
              <i class="fa fa-reload mr-2"></i>Refresh
            </button>
            <button v-if="isDevOps" id="btnGroupDrop1" @click="btnNavToConfig" type="button" class="btn btn-outline-primary ml-2">
              <i class="fa fa-reload mr-2"></i>Config
            </button>
          </span>
          <span class="float-right">Page {{elAdvancedFilter.page}}. Showing {{ showPerPage()}} of {{this.totalRecords}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="idb-block" v-for="row in row_data" :key="row.index">
    <div class="idb-block-title">
      <h2>
        <span v-if="row.eventType === 'Info'" class="badge badge-pill badge-info mr-1">Info</span>
        <span v-else-if="row.eventType === 'Warning' && row.level === 'High'" class="badge badge-pill badge-danger mr-1">Critical Warning</span>
        <span v-else-if="row.eventType === 'Warning' && row.level !== 'High'" class="badge badge-pill badge-warning mr-1">Warning</span>
        <span v-else-if="row.eventType === 'Error'" class="badge badge-pill badge-danger mr-1">Error</span>
        <span v-else class="badge badge-pill badge-info mr-1">Message</span>
        {{row.title}} <span class="ml-3 mr-3">{{reformatDate(row.creationDate)}}</span>
      </h2>
    </div>
    <div class="idb-block-content">
      <div v-html="row.message"></div>
    </div>
    <!-- <div class="idb-block-footer" v-if="row.contextId && row.area !== '1'">
        <button
          v-if="row.contextId && row.area !== '1'"
          @click="btnLink(row)" type="button"
          class="btn btn-link float-right">
          <i class="fas fa-link mr-2"></i>More Information
        </button>
    </div> -->
  </div>
</div>
</template>

<script>
import axios from 'axios'
import Datepicker from 'vuejs-datepicker'
import roles from '@/Assets/Constants/roles'
export default {
  components: {
    Datepicker
  },

  data () {
    return {
      row_data: [],
      totalRecords: 0,
      typeable: false,
      bootstrapStyling: true,
      serverParams: {
        sort: [{ field: 'expirationDate', type: 'desc' }]
      },
      pageSizePptions: [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '999', value: 999 }
      ],
      options: [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '999', value: 999 }
      ],
      disabledDatesFrom: {},
      disabledDatesTo: {},
      progressBarValue: 100,
      timerValue: 0,
      refreshPeriod: 30000,
      timerInterval: 1000,
      isLoading: false,
      elAdvancedFilter: {
        show: true,
        dateFilter: false,
        fromDate: new Date(),
        toDate: new Date(),
        visible: true,
        age: new Date(),
        perPage: 20,
        page: 1,
        showRange: 'today'
      }
    }
  },
  computed: {
    paygateId () {
      return this.$store.getters.selectedCustomer
    },
    isDevOps () { 
      return this.$store.getters.isInRole(roles.DevOps) 
    }
  },
  methods: {
    async load () {
      this.isLoading = true
      const postObj = {
        serverParams: this.serverParams,
        elAdvancedFilter: this.elAdvancedFilter
      }
      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}events`, postObj)
        if (res) {
          // console.log(res)
          this.row_data = res.data.data
          this.totalRecords = res.data.count
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view events data.')
        } else {
          console.log(e)
          this.$snapbar.e(`Could not get the events data - ${e.message}`)
        }
        this.row_data = []
        this.totalRecords = 0
      } finally {
        this.isLoading = false
      }
    },
    async changedFilter () {
      // Ensure from date is not after to date
      const fdate = new Date(this.elAdvancedFilter.fromDate)
      const tdate = new Date(this.elAdvancedFilter.toDate)
      if (fdate > tdate) {
        console.log('fdate > tdate')
        this.elAdvancedFilter.fromDate = tdate
      }
      localStorage.elAdvancedFilter = JSON.stringify(this.elAdvancedFilter)
      await this.load()
    },
    async btnRefresh () {
      this.timerValue = this.refreshPeriod
      this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
      this.load()
    },
    getImageSrc (originator) {
      return '/img/sharedimages/events/icons/' + originator + '.png'
    },
    getCountdown () {
      return `width: ${this.progressBarValue}%`
    },
    showPerPage () {
      if (this.elAdvancedFilter.perPage > this.totalRecords) {
        return this.totalRecords
      } else {
        return this.elAdvancedFilter.perPage
      }
    },
    lessThanOneHourAgo (date) {
      const hour = 1000 * 60 * 60
      const anHourAgo = Date.now() - hour
      if (date < anHourAgo) {
        this.clearFilter()
      }
    },
    clearFilter () {
      this.elAdvancedFilter = {
        show: true,
        dateFilter: false,
        fromDate: new Date(),
        toDate: new Date(),
        visible: true,
        age: new Date(),
        perPage: 20,
        page: 1,
        showRange: 'today'
      }
      this.elAdvancedFilter.fromDate = new Date()
      this.elAdvancedFilter.fromDate.setDate(-30)
      this.elAdvancedFilter.fromDate.setHours(0, 0, 0, 0)
      this.elAdvancedFilter.toDate = new Date()
      this.elAdvancedFilter.toDate.setHours(23, 59, 59, 999)
      this.changedFilter()
    },
    reformatJustDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      return dd + '/' + mm + '/' + yyyy
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    reformatDate2 (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      return dd + '/' + mm + '/' + yyyy
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    // async btnLink (row) {
    //   console.log(row)

    //   switch (row.area) {
    //     case '2':
    //       this.$router.push(`/payments/common/viewSubmission?submissionId=${row.contextId}`)
    //       break
    //     case '3':
    //       this.$router.push('/payments/bacsreports/viewDownloadedReports')
    //       break
    //     default:
    //       this.$toastr.e('Unknown or unavailable link.')
    //   }
    // },
    btnNavToConfig () {
      this.$router.push('/admin/system/eventlog/eventlogconfig')
    }
  },
  watch: {
    // selectedCustomer () { this.load() },
    'elAdvancedFilter.visible': function (val) {
      this.changedFilter()
    }
  },
  async mounted () {
    if (localStorage.elAdvancedFilter) {
      this.elAdvancedFilter = JSON.parse(localStorage.elAdvancedFilter)
      this.lessThanOneHourAgo()
      // if the age of the filter older than 1 hour?
    } else {
      this.elAdvancedFilter.fromDate = new Date()
      this.elAdvancedFilter.fromDate.setDate(-30)
      this.elAdvancedFilter.fromDate.setHours(0, 0, 0, 0)
      this.elAdvancedFilter.toDate = new Date()
      this.elAdvancedFilter.toDate.setHours(23, 59, 59, 999)
      localStorage.elAdvancedFilter = JSON.stringify(this.elAdvancedFilter)
    }

    const today = new Date()
    const oneMonthAgo = new Date()
    oneMonthAgo.setDate(-30)
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    console.log('today', today)
    console.log('oneMonthAgo', oneMonthAgo)
    console.log('tomorrow', tomorrow)

    this.disabledDatesFrom = {
      to: oneMonthAgo,
      from: tomorrow
    }
    this.disabledDatesTo = {
      to: oneMonthAgo,
      from: tomorrow
    }

    await this.load()
    // The countdown time
    this.timerValue = this.refreshPeriod
    this.timer = setInterval(() => {
      this.timerValue -= this.timerInterval
      this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
      if (this.progressBarValue <= 0) {
        // The settimeout is used to sync the UI
        setTimeout(() => {
          this.timerValue = this.refreshPeriod
          this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
          this.load()
        }, 1000)
      }
    }, this.timerInterval)
    this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
  },
  destroyed () {
    clearTimeout(this.timer)
  }
}
</script>

<style scoped>
  /* Nice thin progressbar */
  .progress {
    height: 4px;
    line-height: 4px;
  }
  .progress-bar {
    height: 4px;
    line-height: 4px;
  }
  .fadein-enter {
    opacity: 0;
  }
  .fadein-enter-active {
    transition: opacity 0.6s;
  }
  .fadein-leave-active {
    transition: opacity 0.6s;
    opacity: 0;
  }
  /* Accordian */
  .fa-chevron-up,
  .fa-chevron-down {
    float: right;
  }
  .dark-mode {
    .titleHover:hover {
      background-color: #353535;
    }
  }
  .titleHover:hover {
    background-color: #eeeeee;
  }
  .collapsed > .fa-chevron-up,
  :not(.collapsed) > .fa-chevron-down {
    display: none;
  }
  .fa-info-circle {
    margin-left: 5px;
  }
  .side-icon {
    display: flex;
    i {
      height: 15px;
    }
  }
  .currentDefault {
    color: #004085;
    background-color: #b8daff;
  }
  .subblock {
    width: 90%;
    align-content : center;
  }
  /* DatePicker */
  .right-inner-addon {
    position: relative;
  }
  .right-inner-addon input {
    padding-left: 1px;
  }
  .right-inner-addon i {
    position: absolute;
    top: 6px;
    right: 15px;
  }
  .textMuted {
    font-size: small;
    color: #bbbbbb;
  }
</style>
