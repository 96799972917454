var types = {
  singleFMSun: 'Single FM SUN',
  sharedFMSun: 'Shared FM SUN',
  thirdPartySun: '3rd Party SUN',
  bureauSun: 'Bureau SUN'
}

var levels = {
  paper: 'Paper',
  aUDDIS: 'AUDDIS',
  paperless: 'Paperless'
}

export {
  types,
  levels
}
