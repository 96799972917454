<template>
    <div class="idb-block">
        <div class="idb-block-title">
          <h2><help-icon docPath="/paygate-collections/payers/editpayer/" />Customer "{{reference}}" Due To Expire Due to Inactivity</h2>
        </div>
        <div class="idb-block-content">
            <p>The Customer below is due to be closed due to inactivity.</p>
            <p>Reference: {{reference}}</p>
            <p>Status: {{status}}</p>
            <p>Most Recent Collection: {{mostRecentPayment}}</p>
            <p v-if="ukPayerId !== null"><router-link :to="{ name: 'editPayer', params: { ukPayerId: ukPayerId } }" style="text-decoration: underline"> <!-- eslint-disable-line -->
              <span>View Customer</span>
            </router-link></p>
        </div>
        <div class="idb-block-footer noprint-footer">
            <b-button variant="primary" @click.prevent="removeAction" :disabled="isLoading" v-if="actionId && actionId !== null">Clear Action</b-button>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
const statusToText = (x) => {
  switch (x) {
    case -1:
      return 'Any'
    case 0:
      return 'Operational'
    case 1:
      return 'Suspended'
    case 2:
      return 'Alert'
    case 3:
      return 'Closed'
    case 4:
      return 'Incomplete'
    case 5:
      return 'Awaiting Activation'
    case 6:
      return 'Plan Suspended'
    case 7:
      return 'Deleted'
    case 8:
      return 'Lodgement Pending'
    default:
      return 'Unknown'
  }
}
export default {
  props: { ukPayerId: String },
  data () {
    return {
      actionId: null,
      reference: '',
      planId: '',
      status: '',
      mostRecentPayment: null,
      ukPayerId: null
    }
  },
  async mounted () {
    this.actionId = this.$route.query.actionId
    var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payeractions/finalwarning/${this.ukPayerId}`, { params: { paygateId: this.paygateId } })
    this.reference = response.data.reference
    this.status = statusToText(response.data.status)
    this.mostRecentPayment = response.data.mostRecentPayment
    this.ukPayerId = response.data.ukPayerId
  },
  methods: {
    async removeAction () {
      var response = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}payeractions/finalwarning/${this.actionId}`, { params: { paygateId: this.paygateId } })
      if (response) {
        this.$toastr.s('Action Cleared')
        this.$router.push('/actions')
      }
    }
  }
}
</script>
