<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <div>
                <div class="form-group">
                  <label class="required">Title</label>
                  <input type="text" class="form-control"
                        v-model="mappingMetaData.title"
                        :class="{invalid: $v.mappingMetaData.title.$error}"
                        @input="$v.mappingMetaData.title.$touch()">
                      <p class="validationText" v-if="!$v.mappingMetaData.title.maxLength && $v.mappingMetaData.title.$dirty ">The title is too long. The maximum length is 64 characters.</p>
                      <p class="validationText" v-if="!$v.mappingMetaData.title.required && $v.mappingMetaData.title.$dirty ">A mapping title is required.</p>
                  </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Description</label>
                  <textarea class="form-control" rows="4" cols="60"
                  v-model="mappingMetaData.description"
                  :class="{invalid: $v.mappingMetaData.description.$error}"
                  @input="$v.mappingMetaData.description.$touch()"></textarea>
                  <p class="validationText" v-if="!$v.mappingMetaData.description.maxLength && $v.mappingMetaData.description.$dirty ">The description is too long. The maximum length is 128 characters.</p>
                </div>
                <!-- <br/>
                <div class="form-group">
                  <label for="exampleInputEmail1">Tags</label>
                  <input-tag v-model="mappingMetaData.tags" :limit="9" :allowDuplicates="false"></input-tag>
                </div> -->
                <br/>
                <div class="form-row">
                  <div class="col-md-4">
                    <label for="exampleInputEmail1">Author</label>
                    <input type="text" :disabled="true" class="form-control"
                      v-model="mappingMetaData.author"
                      :class="{invalid: $v.mappingMetaData.author.$error}"
                      @input="$v.mappingMetaData.author.$touch()">
                    <p class="validationText" v-if="!$v.mappingMetaData.author.maxLength && $v.mappingMetaData.author.$dirty ">The author is too long. The maximum length is 128 characters.</p>
                  </div>
                  <div class="col-md-2">
                  </div>
                  <div class="col-md-6">
                    <label for="exampleInputEmail1">Type</label><br>
                    <select v-model="mappingMetaData.type" class="custom-select custom-select-lg">
                      <option v-for="option in typeOptions" :key="option.index" v-bind:value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <br/>
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <b-button variant="danger"
                @click="btnCancel"
                title="Cancel">
                <span class="fa fa-times" aria-hidden="true"></span>
                Cancel
              </b-button>
              <b-button variant="success"
                @click.prevent="btnSave"
                title="Save Metadata">
                <span class="fa fa-save" aria-hidden="true"></span>
                Save
              </b-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
export default {
  name: 'popup',
  validations: {
    mappingMetaData: {
      description: {
        maxLength: maxLength(128)
      },
      author: {
        maxLength: maxLength(128)
      },
      title: {
        required,
        maxLength: maxLength(64)
      }
    }
  },
  data () {
    return {
      originalMetaData: {},
      mappingMetaData: {
        title: '',
        description: '',
        author: '',
        dateCreated: '',
        type: 'bacs',
        numExecution: 0,
        lastExecuted: '',
      },
      typeOptions: [
        { text: 'BACS & FPS', value: 'bacs' },
        // { text: 'Faster Payments', value: 'fps' },
        { text: 'UK BACS Bureau', value: 'ukBureau' },
        { text: 'Bureau Customer Importer', value: 'bureauCustomerImporter' },
        { text: 'UK Collections', value: 'ukddms' },
        { text: 'UK Collections Payers', value: 'ukddmsPayers' },
        { text: 'UK Collections Variable Update', value: 'ukddmsVar' },
        // { text: 'SEPA', value: 'sepa' },
        { text: 'Other', value: 'other' }
      ]
    }
  },
  methods: {
    btnSave () {
      if (this.$v.$invalid) {
        console.log('invalid')
        this.$v.$touch()
        return
      }
      this.$store.commit('setMappingMetaData', this.mappingMetaData)
      this.$emit('close')
    },
    btnCancel () {
      const initialState = JSON.parse(this.originalMetaData)
      console.log('initialState')
      console.log(initialState)
      this.$store.commit('setMappingMetaData', initialState)
      this.$emit('cancel')
    },
    updateForm (fieldName, value) {
      // console.log('updateForm')
      // console.log('fieldName: ' + fieldName)
      // console.log('updateForm: ' + value)
      // this.$set(this.formData, fieldName, value)
      // this.$emit('input', this.formData)
      // console.dir(this.formData)
    },
    scrollHandle (evt) {
      // console.log(evt)
    }
  },
  beforeUpdate () {
    // console.log('beforeUpdate')
    // console.log(this.schema)
  },
  mounted () {
    this.mappingMetaData = this.$store.state.mapping.mappingMetaData
    this.originalMetaData = JSON.stringify(this.mappingMetaData)
    console.log()
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  /* transition: opacity .3s ease; */
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 70%;
  margin: 0px auto;
  padding: 20px 30px;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-height: 90vh;
  overflow-y: auto;
  background-color: rgba(250, 250, 250)
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
/* .modal-enter {
  opacity: 0;
} */

/* .modal-leave-active {
  opacity: 0;
} */

/* .modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */

.required {
  color: red;
  font-variant-position: super;
  padding-left: 4px;
}
  .invalid {
    border-color: red;
    border-width: 2px;
  }
  label.required {
    /* $gray-700:  */
    color: #878787;
  }
  label.required::after {
    content: " *";
    color: red;
  }

.vue-input-tag-wrapper .input-tag {
  background-color: #dedada;
  border: #dedada;
  color: #585858;
}
.vue-input-tag-wrapper .input-tag .remove {
  color: #444444;
}
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
