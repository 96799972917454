<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2><help-icon docPath="/paygate-collections/messaging/messagetemplates/" />SMS Designer</h2>
    </div>
    <div class="idb-block-content">
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group>
            <label for="smsTemplateName">
              <strong>Template Name</strong>
            </label>
            <b-form-input
              class
              id="smsTemplateName"
              v-model="templateName"
              type="text"
              autocomplete="off"
              :disabled="loading"
              :state="templateNameStateValid && !alreadyExists"
              aria-describedby="smsTemplateNameHelp smsTemplateNameFeedback"
              placeholder="SMS Template Name"
              maxlength="50" @input="checkTemplateNameExists"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!templateNameStateValid" id="smsTemplateNameFeedback">
              The template name may only have characters of the following types:
              <ul>
                <li>Upper-case letters A-Z</li>
                <li>Lower-case letters a-z</li>
                <li>Numbers 0-9</li>
                <li>Spaces, underscores "_" or dashes "-"</li>
              </ul>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="alreadyExists" id="smsTemplateNameFeedback2">
              There is an SMS template by that name already in the system.
            </b-form-invalid-feedback>
            <b-form-text id="smsTemplateNameHelp">The name of the template, as it will be appear to users.</b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="false">
        <b-col>
          <b-form-group>
            <p-check id="templateRequiresApprovalCheck"
                  class="p-switch p-fill"
                  color="primary"
                  v-model="requiresApproval"
                ></p-check>
            <label for="templateRequiresApprovalCheck">
              <strong>This template will be used for Advance Notice/First Collection/Notification Of Change/Confirmation Letter</strong>
            </label>
            <b-form-text>If a previously approved template is updated, it must undergo the approval process again. The most recent approved version will be used for messaging.</b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col cols="12" md="4" id="smsEditor">
          <b-form-group>
            <label for="smsSenderName">Sender Name</label>
            <b-form-input
              class
              id="smsSenderName"
              v-model="senderName"
              type="text"
              autocomplete="off"
              :state="senderNameStateValid"
              aria-describedby="smsSenderNameHelp smsSenderNameFeedback"
              placeholder="LandZ"
              maxlength="11"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!senderNameStateValid" id="smsSenderNameFeedback">
              The sender name must be between 3 and 11 characters in length, and of the following types:
              <ul>
                <li>Upper-case letters A-Z</li>
                <li>Lower-case letters a-z</li>
                <li>Numbers 0-9</li>
                <li>At least one of these characters must be a letter.</li>
              </ul>
            </b-form-invalid-feedback>
            <b-form-text
              id="smsSenderNameHelp"
            >The name seen by the SMS recipient (max 11 characters).</b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="5">
          <b-form-group>
            <label for="smsMessageBody">Message</label>
            <b-form-textarea
              id="smsMessageBody"
              v-model="messageBody"
              @input="previewLoading = true; previewText = messageBody; updatePreview()"
              maxlength="160"
              placeholder="SMS message body"
            ></b-form-textarea>
            <b-form-text
              id="smsMessageBodyHelp"
            >The message seen by the SMS recipient (max 160 characters).</b-form-text>
          </b-form-group>
        </b-col>
        <b-col offset-md="1" md="5" cols="12" id="smsPreview">
          <div>Preview</div>
          <div class="inline-flex">
            <label class="speech-bubble-label">
              <strong>{{ senderNamePreview }}</strong>
            </label>
            <div class="speech-bubble">
              <div class="smsPreviewText" v-if="!previewLoading" v-html="previewTextPreview"></div>
              <div class="smsPreviewTextLoading" v-if="previewLoading" v-html="previewTextPreview"></div>
            </div>
          </div>
          <b-form-text class="mt-2">Approximate length: {{previewTextPreview.length}} characters.</b-form-text>
        </b-col>
      </b-row>
    </div>
    <div class="idb-block-footer">
      <b-btn variant="primary" @click.prevent="saveClick" :disabled="isLoading || !senderNameStateValid || senderName === '' || alreadyExists || !canEdit">Save</b-btn>&nbsp;
      <b-btn variant="outline-secondary" :disabled="isLoading || !canEdit" @click.prevent="clearTemplate">New Template</b-btn>
      <!--b-btn :disabled="previewDisabled" @click.prevent="togglePreview" variant="info">{{previewButtonLabel}}</b-btn-->
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import EventBus from '@/Lib/eventBus'
import loading from '@/Assets/Mixins/LoadingMixin'
import roles from "@/Assets/Constants/roles";

export default {
  mixins: [loading],
  props: {
    templateid: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      senderName: '',
      previewLoading: false,
      previewButtonLabel: 'Preview',
      messageBody: '',
      previewText: '',
      templateName: '',
      previewOpen: false,
      loading: !!this.templateid,
      requiresApproval: false,
      bankApproved: false,
      alreadyExists: false
    }
  },
  computed: {
    canEdit() {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin ])
    },
    senderNamePreview () {
      return this.senderNameStateValid ? this.senderName : 'LandZ'
    },
    previewTextPreview () {
      return this.formatMessage(this.previewText || 'SMS message body')
    },
    previewDisabled () {
      const messageBodyValid = !!this.messageBody
      return !messageBodyValid || !this.senderNameStateValid
    },
    templateNameStateValid () {
      const str = this.templateName
      if (!str) {
        return null
      }
      const hasNonTemplateChar = /^[\w-_\s]*$/g
      return hasNonTemplateChar.test(str)
    },
    senderNameStateValid () {
      const str = this.senderName
      if (!str) {
        return null
      }
      const senderNameChars = /^[A-Za-z0-9]{3,}$/
      const minCharacters = this.senderName.match(/[A-Za-z]/g)
      return senderNameChars.test(str) && (minCharacters !== null && minCharacters.length > 0)
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    }
  },
  async mounted () {
    this.$store.dispatch('setLastOpened', 'Sms')
    const onPaygateChange = async (paygateId) => {
      if (this.templateid) {
        this.loading = true
        try {
          await this.loadTemplateInfo(this.templateid)
          this.loading = false
        } catch (e) {
          this.$toastr.e('An error occurred loading the SMS template information.', 'Error')
          this.$router.push({ name: 'SmsDesigner' })
        }
      }
    }

    EventBus.$on('paygateIdChange', onPaygateChange)

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })

    onPaygateChange()
  },
  methods: {
    saveClick () {
      const paygateId = this.paygateId
      if (this.templateid) {
        this.trySave(this.templateid, paygateId)
      } else {
        this.tryCreate(paygateId)
      }
    },
    async trySave (templateId, paygateId) {
      try {
        const body = {
          name: this.templateName,
          messageBody: this.messageBody,
          senderName: this.senderName,
          requireBankApproval: this.requiresApproval
        }
        const response = await axios.put(`${process.env.VUE_APP_DDMS_API_URL}designer/sms/${templateId}`, body, { showload: true, params: { paygateId: this.paygateId } })
        history.pushState({}, 'Paygate DDMS', window.location + '/' + response.data.templateId)
        this.$toastr.s(`'${this.templateName}' saved successfully.`, `SMS Template Saved`)
        this.$router.push('/collections/message/templates')
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to save changes to the SMS template.', 'An Error Occurred')
      }
    },
    async tryCreate (paygateId) {
      try {
        const body = {
          name: this.templateName,
          messageBody: this.messageBody,
          senderName: this.senderName,
          requireBankApproval: this.requiresApproval
        }
        await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/sms`, body, { showload: true, params: { paygateId: this.paygateId } })
        if (this.requiresApproval) {
          this.$toastr.s('Template updated successfully, and may be used once approved.', 'Template Updated')
        } else {
          this.$toastr.s('Template updated successfully.', 'Template Updated')
        }
        this.$router.push('/collections/message/templates')
      } catch (e) {
        this.$toastr.e('Unable to save changes to the SMS template.', 'An Error Occurred')
      }
    },
    async loadTemplateInfo (templateId) {
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/sms/${this.templateid}`, { showload: true, params: { paygateId: this.paygateId } })
      this.templateName = response.data.name
      this.senderName = response.data.senderName
      this.messageBody = response.data.messageBody
      this.previewText = this.messageBody
      this.requiresApproval = response.data.requireBankApproval
      this.bankApproved = response.data.bankApproved
      this.updatePreview()
    },
    updatePreview: _.debounce(async function updatePreviewText () {
      const messageBody = this.messageBody
      this.previewLoading = true
      this.previewText = messageBody
      try {
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/preview/text`, { messageBody }, { showload: true, params: { paygateId: this.paygateId } })
        this.previewText = response.data.messageBody
      } finally {
        this.previewLoading = false
      }
    }, 500),
    saveContent () {
      console.log(this.messageBody)
    },
    async viewPreview () {
      this.previewText = this.messageBody
    },
    async togglePreview () {
      if (!this.previewOpen) {
        await this.viewPreview()
        this.previewButtonLabel = 'Edit'
      } else {
        this.previewButtonLabel = 'Preview'
      }
      this.previewOpen = !this.previewOpen
    },
    clearTemplate () {
      this.messageBody = ''
      this.senderName = ''
      this.templateName = ''
      this.templateId = null
      this.updatePreview()
    },
    formatMessage (body) {
      return body.replace(/\n/gi, '<br/>')
    },
    async checkTemplateNameExists () {
      let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/checkforduplicates`, { channel: 2, templateName: this.templateName }, { showload: true, params: { paygateId: this.paygateId } })
      this.alreadyExists = response.data
    }
  }
}
</script>
<style scoped>
.smsPreviewLoader {
  width: 40%;
  margin: auto;
}

#smsMessageBody {
  min-height: 7rem;
}

.inline-flex {
  display: flex;
}

.smsPreviewText {
  margin-left: 2rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  margin-right: 2rem;
  word-wrap: break-word;
}

.smsPreviewTextLoading {
  margin-left: 2rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  color: transparent;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  word-wrap: break-word;
}

.speech-bubble-label {
  margin-top: 0.5rem;
  margin-right: 2rem;
  min-width: 5rem;
}

.speech-bubble {
  position: relative;
  background: #eee;
  border-radius: 0.4em;
  color: black;
  width: 20rem;
  min-height: 7rem;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 1.7rem;
  width: 0;
  height: 0;
  border: 1.25em solid transparent;
  border-right-color: #eee;
  border-left: 0;
  border-top: 0;
  margin-top: -0.625em;
  margin-left: -1em;
}

.dark-mode * .speech-bubble {
  background: #303030;
}

.dark-mode * .speech-bubble:after {
  border-right-color: #303030;
}
</style>
