<template>
  <div>
    <Teleport to="#modalTarget" :disabled="teleported">
      <div class="fitBox">
        <div ref="editor"></div>
        <textarea v-show="!sourceHidden" class="wysiwyg-source-view" ref="sourceView" v-model="content"></textarea>
      </div>
    </Teleport>
    <b-button class="mt-2" @click="teleportthis">Switch to Fullscreen</b-button>
    <b-modal
      id="emaildesignermodal"
      ref="emaildesignermodal"
      variant="primary"
      no-close-on-backdrop
      no-close-on-esc
      centered
      scrollable
      ok-only
      title="Message Body"
      ok-title="Minimise"
      size="xl"
      @ok="teleportthis"
      @close="teleportthis"
      dialog-class="modal-fullscreen1"

    ><div id="modalTarget"></div>
    </b-modal>
  </div>
</template>
<script>
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import Teleport from 'vue2-teleport'
export default {
  components: {
    Teleport,
  },
  props: {
    value: {
      type: String,
      default: null
    },
    uploadUrl: {
      type: String,
      default: ''
    },
    suppressLoad: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      content: '',
      editor: null,
      bearerToken: '',
      sourceHidden: true,
      teleported: true
    }
  },
  mounted () {
    if (!this.suppressLoad) {
      this.loadQuill()
    }
  },
  watch: {
    value (newVal, oldVal) {
      if (this.editor) {
        if (newVal && newVal !== this.content) {
          this.content = newVal
          this.editor.pasteHTML(newVal)
        } else if (!newVal) {
          this.editor.setText('')
        }
      }
    }
  },
  methods: {
    teleportthis  () {
      this.$bvModal.show('emaildesignermodal')
      this.teleported = !this.teleported
    },
    toggleHtmlVisibility () {
      this.sourceHidden = !this.sourceHidden
    },
    loadQuill () {
      const sourceView = this.$refs['sourceView']
      const toggleVisiblity = this.toggleHtmlVisibility.bind(this)
      this.editor = new Quill(this.$refs.editor, {
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              ['link', 'image'],
              [{ 'color': [] }],
              [{ 'font': [] }],
              [{ 'align': [] }],
              ['clean'],
              ['editSource']
            ],
            table: true,
            handlers: {
              editSource () {
                if (sourceView.style.display === '') {
                  const html = sourceView.value
                  if (html) {
                    this.quill.pasteHTML(html)
                  }
                }

                toggleVisiblity()
                // sourceView.style.display = sourceView.style.display === 'none' ? '' : 'none'
              }
            }
          },
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true
          }
        },
        theme: 'snow'
      })

      const htmlEditor = this.editor.addContainer('l-htmlEdit')
      htmlEditor.appendChild(this.$refs['sourceView'])

      if (this.value) {
        this.editor.pasteHTML(this.value)
      }

      this.editor.on('text-change', (delta, oldDelta, source) => {
        let html = this.$refs.editor.children[0].innerHTML
        const quill = this.editor
        const text = quill.getText()

        if (html === '<p><br></p>') {
          html = ''
        }
        sourceView.value = html
        this.content = html
        this.$emit('input', this.content)
        this.$emit('change', { html, text, quill })
      })
      this.$emit('ready', this.editor)
    }
  }
}
</script>

<style>
.wysiwyg-source-view {
  width: 100%;
  margin: 0px;
  box-sizing: border-box;
  font-size: 15px;
  outline: none;
  padding: 20px;
  line-height: 24px;
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  position: absolute;
  top: 0;
  bottom: 0;
  border: none;
  resize: none;
}

.ql-editSource:after {
  content: "[html]";
}

button.ql-editSource {
  width: 100% !important;
}

.ql-container {
  min-height: 15em;
  height: 15em;
}

.modal-fullscreen1 .modal {
  padding: 0 !important;
}
.modal-fullscreen1 .modal-dialog {
  max-width: 100%;
  height: 100%;
  margin: 0;
}
.modal-fullscreen1 .modal-content {
  border: 0;
  border-radius: 0;
  min-height: 100%;
  height: auto;
}

#modalTarget {
  height: 100%;
  display: block;
}

.fitBox {
  height: 100%;
}
.modal-fullscreen1 .modal-content .ql-editor, .modal-fullscreen1 .modal-content .ql-container{
  height: 90%
}
</style>
