<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline Inbound Files<span class="pull-right"><a :href="`${docUrl}/bankline/files/`" target="_blank"><i class="far fa-question-circle"></i></a></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div>
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              :rows="row_data"
              :columns="columns"
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              ref="table"
              :lineNumbers="true"
              styleClass="vgt-table striped bordered table-hover"
              @on-row-click="onInRowClick"
              >
              <template slot="table-row" slot-scope="props">
                <span v-if = "props.column.field == 'originalFilename'">
                  <div>{{props.row.originalFilename}}</div>
                  <div class="tinyText mt-2">Items in file: {{props.row.numItems}}</div>
                </span>
                <span v-else-if = "props.column.field == 'type'">
                  <span v-if = "props.row.type == 'AST'">Account Statement</span>
                  <span v-else-if = "props.row.type == 'ABS'">Account Balance Summary</span>
                  <span v-else>{{props.row.type}}</span>
                </span>
                <span v-else-if = "props.column.field == 'description'">
                  <b-badge pill v-if = "props.row.description == 'upload'" variant="success">Uploaded from Bankline</b-badge>
                  <b-badge pill v-else-if = "props.row.description == 'payment'" variant="info">Payment File</b-badge>
                  <span v-else>{{props.row.description}}</span>
                </span>
                <span v-else-if = "props.column.field == 'buttons'">
                <b-dropdown right variant="outline-primary" split text="View" class="m-2">
                  <b-dropdown-item v-if="props.row.archive == false" @click.prevent="btnInArchiveFile(props, true)"><i class="glyphicon ti-archive mr-1"></i>Archive File</b-dropdown-item>
                  <b-dropdown-item v-else @click.prevent="btnInArchiveFile(props, false)"><i class="glyphicon ti-archive mr-1"></i>Unarchive File</b-dropdown-item>
                  <b-dropdown-item v-if="props.row.status != '11'" active @click.prevent="btnInDeleteFile(props.row.id)"><i class="glyphicon ti-trash mr-1"></i>Delete File</b-dropdown-item>
                </b-dropdown>
                </span>
                <span v-else-if = "props.column.field == 'status'">
                  <b-badge pill v-if = "props.row.status == '1'" variant="secondary">Unprocessed</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '2'" variant="info">Processing</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '3'" variant="success">Processed</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '4'" variant="warning">Unmtched</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '98'" variant="primary">Archived</b-badge>
                  <b-badge pill v-else-if = "props.row.status == '99'" variant="danger">Error</b-badge>
                  <b-badge pill v-if = "props.row.archive == true" variant="info" class="ml-2">Archived</b-badge>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
            </template>
            <div slot="table-actions">
              <b-button @click.prevent="btnUploadBanklineFile" class="" variant="link" v-b-popover.hover.top.d500="'Upload a Bankline File.'" ><i class="fas fa-upload pointer dimmedIcon"></i></b-button>
              <button
                @click.prevent="clearTableFilters"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Clear filters'">
                <span class="fa-stack" style="font-size: 10px;">
                  <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                  <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                </span>
              </button>
              <b-button @click.prevent="load" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the table data.'" ><i class="fas fa-sync-alt pointer dimmedIcon"></i></b-button>
            </div>
          </vue-good-table>
          </div>
          <br/>
          <b-form-checkbox v-model="showArchived" >
            Show archived files
          </b-form-checkbox>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div class="btn-group" role="group">
                <button id="btnGroupDrop1" @click="btnUploadBanklineFile" type="button" class="btn btn-primary">
                  <i class="fa fa-upload mr-2"></i>Upload a Bankline File
                </button>
              </div>
              <div class="btn-group ml-2" role="group">
                <button id="btnGroupDrop1" @click="btnViewAllBalances" type="button" class="btn btn-outline-primary">
                  <i class="fa fa-list mr-2"></i>View Bank Balances
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
import { saveAs } from 'file-saver'
export default {
  name: 'banklineoutbound',
  mixins: [tableFilterMixin],
  components: {
    swal,
    saveAs
  },
  data () {
    return {
      btnInLatch: false,
      showArchived: true,
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'Filename',
          field: 'originalFilename',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Uploaded',
          field: 'creationDate',
          hidden: false,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSX',
          dateOutputFormat: 'dd/MM/yyyy HH:mm:ss',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'File Type',
          field: 'type',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Items in file',
          field: 'numItems',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: false
          }
        },
        {
          field: 'buttons',
          sortable: false,
          filterOptions: { enabled: false },
          tdClass: 'text-center',
          thClass: 'text-center',
          hidden: false
        }
      ],
      row_data: [],
      totalRecords: 0,
      paginationOptions: { enabled: true, perPage: 10, dropdownAllowAll: false, jumpFirstOrLast: true },
      serverParams: {
        sort: [{ field: 'creationDate', type: 'desc' }]
      }
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async load () {
      this.loadData()
    },
    async loadData () {
      const postObj = {
        serverParams: this.serverParams,
        description: 'upload',
        id: this.id,
        archive: this.showArchived
      }
      this.isTableLoading = true
      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineinboundfiles`, postObj)
        if (res) {
          this.row_data = res.data.resObj.data
          this.totalRecords = res.data.resObj.count
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
        this.row_data = []
        this.totalRecords = 0
      } finally {
        this.isTableLoading = false
      }
    },
    async btnViewAllBalances () {
      this.$router.push('/admin/bandr/balances')
    },
    async btnUploadBanklineFile () {
      this.$router.push('/admin/bandr/bankline/uploadfile')
    },
    async btnAdhocBanklinePayment () {
      this.$router.push('/admin/bandr/bankline/banklinepayment')
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    onInRowClick (params) {
      if (!this.btnInLatch) {
        // console.log(params.row.type)
        this.$router.push(`/admin/bandr/bankline/bankline${params.row.type.toLowerCase()}file/${params.row.id}`)
      }
    },
    onOutRowClick (params) {
      if (!this.btnOutLatch) {
        this.$router.push(`/admin/bandr/bankline/banklinepaymentfile/${params.row.id}`)
      }
    },
    async btnCreateTestFile () {

    },
    async btnInDownloadFile (id) {
      this.btnInLatch = true
    },
    async isTodaysFile (creationDate) {
      // Checks if creationDate is today
      const cd = new Date(creationDate)
      const today = new Date()
      const isToday = cd.getDate() === today.getDate() &&
        cd.getMonth() === today.getMonth() &&
        cd.getFullYear() === today.getFullYear()
      return isToday
    },
    async btnInArchiveFile (prop, archive) {
      this.btnInLatch = true
      // Only all 'processed' files to be archived
      if (prop.row.status !== 3) {
        this.$snapbar.e('You cannot archive a file that has not been processed.')
        return
      }
      console.log(archive)
      let msg = 'Are you sure you want to archive this Bankline file?'
      if (!archive) msg = 'Are you sure you want to unarchive this Bankline file?'

      const result = await swal.fire({
        title: msg,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        const body = {
          _id: prop.row._id,
          archive
        }
        let res
        try {
          res = await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}archivebanklinefile`, body, { showload: true })
          if (res) {
            this.$toastr.s('The Bankline file was status was successfully changed.')
            this.load()
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to archive this file.')
          } else {
            console.log(e)
            this.$snapbar.e(`Could not archive this file - ${e.message}`)
          }
        }
      }
      this.btnInLatch = false
    },
    async btnInDeleteFile (id) {
      this.btnInLatch = true
      const result = await swal.fire({
        title: 'Are you sure you want to permanently delete this Bankline file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}banklinefile/${id}`, { showload: true })
          if (res) {
            this.$toastr.s('The Bankline file was successfully deleted.')
            this.load()
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this file.')
          } else {
            console.log(e)
            this.$snapbar.e(`Could not delete this file - ${e.message}`)
          }
        }
      }
      this.btnInLatch = false
    }
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.load()
    },
    showArchived: function (val) {
      localStorage.showArchived = this.showArchived
      this.loadData()
    }
  },
  created () {
    // Retrieve the archive checkbox state.
    if (localStorage.showArchived === undefined) {
      localStorage.showArchived = this.showArchived
    }
    this.showArchived = localStorage.showArchived
  }
}
</script>
<style scoped>
  td {
    cursor: pointer;
  }
  .tinyText {
    font-size: x-small;
  }
</style>
