<template>
  <loading class="main" v-bind:loading="true"></loading>
</template>
<script>
import Auth from '@/Assets/Components/Authentication/auth'

export default {
  name: 'authCallbackSilent',
  data: function () {
    return {
      loading: true
    }
  },
  async created () {
    var response = await Auth.manager.signinSilentCallback()
    console.log('------------------------------------------------')
    console.log('Silent:', response)
    console.log('------------------------------------------------')
  }
}
</script>
