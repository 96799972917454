<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Importers <span class="pull-right"><a :href="`${docUrl}/automation/paygate-mapping/importers/`" target="_blank"><i class="far fa-question-circle"></i></a></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div class="">
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              ref="table"
              :columns="columns"
              :rows="rows"
              :lineNumbers="true"
              :search-options="{ enabled: true }"
              @on-row-click="onRowClick"
              @on-cell-click="onCellClick"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              styleClass="vgt-table striped bordered table-hover"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if = "props.column.field == 'enabled'">
                  <span  v-if="props.row.enabled == true" style="font-weight: bold; color: darkgreen;"><i class="fa fa-check"></i></span>
                  <span  v-else style="font-weight: bold; color: darkred;"><i class="fa fa-times"></i></span>
                </span>
                <span v-else-if = "props.column.field == 'description'">
                  {{formatDescription(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'importerType'">
                  <span  v-if="props.row.importerType == 'csv'"><i class="fas fa-file-csv mr-2 faIconGray"></i>CSV / Delimited</span>
                  <span  v-else-if="props.row.importerType == 'fixedlength'"><i class="fas fa-file-alt faIconGray mr-2 "></i>Fixed Length</span>
                  <span  v-else>Unknown</span>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
              <div slot="table-actions">
                <button
                  @click.prevent="clearTableFilters"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Clear filters'">
                  <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                  </span>
                </button>
                <b-button @click.prevent="load" :disabled="isLoading" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'" ><i class="fas fa-sync-alt pointer dimmedIcon pointer"></i></b-button>
                <span slot="table-actions" class="dropdown " v-b-popover.hover.top.d500="'New Importer'">
                  <a class="btn btn btn-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-plus pointer dimmedIcon pointer"></i>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" @click.prevent="newCsvImporter" href="#"><i class="fas fa-file-csv faIconGray"></i> CSV / Delimited</a>
                    <a class="dropdown-item" @click.prevent="newFixedImporter" href="#"><i class="fas fa-file-alt faIconGray"></i> Fixed Length</a>
                    <!-- <a class="dropdown-item" @click.prevent="newIso20022Importer" href="#"><i class="fas fa-file-code faIconGray"></i> ISO 20022</a> -->
                  </div>
                </span>
              </div>
            </vue-good-table>
          </div>
        </div>
        <div class="idb-block-footer">
          <div>
            <div class="btn-group" appendTo="body" role="group">
              <button id="btnGroupDrop1" :disabled="isLoading" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                New Importer
              </button>
              <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <a class="dropdown-item" @click.prevent="newCsvImporter" href="#"><i class="fas fa-file-csv faIconGray"></i> CSV / Delimited</a>
                <a class="dropdown-item" @click.prevent="newFixedImporter" href="#"><i class="fas fa-file-alt faIconGray"></i> Fixed Length</a>
                <!-- <a class="dropdown-item" @click.prevent="newIso20022Importer" href="#"><i class="fas fa-file-code faIconGray"></i> ISO 20022</a> -->
              </div>
            </div>
            <br/>
            <br/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  name: 'my-component',
  mixins: [tableFilterMixin, loading],
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      columns: [
        {
          label: 'Id',
          field: '_id',
          hidden: true
        },
        {
          label: 'Title',
          field: 'title',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Type',
          field: 'importerType',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: '',
          field: 'buttons',
          hidden: true
        }
      ],
      row_data: [],
      serverParams: {
        sort: [{ field: 'title', type: 'asc' }]
      }
    }
  },
  computed: {
    rows: function () {
      return this.row_data
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    formatDescription (txt) {
      if (txt.length > 64) {
        return txt.substring(0, 61) + '...'
      } else {
        return txt
      }
    },
    newCsvImporter () {
      this.$router.push('/automation/mapping/import/csvImporter')
    },
    newFixedImporter () {
      this.$router.push('/automation/mapping/import/fixedLengthImporter')
    },
    newIso20022Importer () {
      this.$router.push('/automation/mapping/import/iso20022Importer')
    },
    onCellClick (params) {
      if (params.column.field !== 'buttons') {
        this.$router.push(`/automation/mapping/import/${params.row.importerType}Importer/${params.row._id}`)
      }
    },
    onRowClick (params) {
    },
    async load () {
      this.$snapbar.hide()
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}importers`, { showload: true })
        if (res && res.data && res.data.importers) {
          this.row_data = res.data.importers
        } else {
          this.$snapbar.e('Could not get a list of importers')
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Importers.')
        } else {
          this.$snapbar.e(`Could not get a list of Importers - ${e.message}`)
        }
      }
    },
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
    }
  },
  created: async function () {
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      await this.load()
    }
  }
}
</script>

<style scoped>
  td {
    cursor: pointer;
  }
</style>
