import axios from 'axios'

// initial state
const state = {
  twoFactorMethods: [],
  approvalMethods: ['Via Action Item'],
  mfaOptions: [],
  mfaEnabled: false
}
const getters = {
  twoFactorMethods: state => state.twoFactorMethods,
  approvalMethods: state => state.approvalMethods,
  mfaOptions: state => state.mfaOptions,
  mfaEnabled: state => state.mfaEnabled
}

// mutations
const mutations = {
  updateTwoFactorOptions (state, mfaSettings) {
    state.mfaEnabled = mfaSettings.value
    state.approvalMethods = ['Via Action Item']
    state.mfaOptions = []

    if (mfaSettings.value) {
      if (mfaSettings.authenticator) {
        state.approvalMethods.push('Authenticator')
        state.mfaOptions.push('Authenticator')
      }

      if (mfaSettings.sms && mfaSettings.smsEnabled) {
        state.approvalMethods.push('SMS')
        state.mfaOptions.push('SMS')
      }

      if (mfaSettings.usbTokens) {
        state.approvalMethods.push('USB Token')
        state.mfaOptions.push('USB Token')
      }

      if (mfaSettings.emails) {
        state.approvalMethods.push('Email')
        state.mfaOptions.push('Email')
      }
    }
  },
  updateLoginPolicy (state, loginPolicyOption) {
    state.twoFactorMethods = []

    state.twoFactorMethods.push({ value: null, text: 'Set By Security Policy' })

    if (loginPolicyOption.allowAuthenticator) {
      state.twoFactorMethods.push({ value: 'Authenticator', text: 'Authenticator' })
    }

    if (loginPolicyOption.allowEmail) {
      state.twoFactorMethods.push({ value: 'Email', text: 'Email' })
    }

    if (loginPolicyOption.allowSMS) {
      state.twoFactorMethods.push({ value: 'SMS', text: 'SMS' })
    }

    if (loginPolicyOption.allowUSBToken) {
      state.twoFactorMethods.push({ value: 'USB Token', text: 'USB Token' })
    }
  }
}

// actions
const actions = {
  async loadLoginPolicy ({ commit, dispatch }) {
    try {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/LoginPolicy`)
      commit('updateLoginPolicy', response.data)
      await dispatch('loadTwoFactorOptions')
    } catch (e) {
      // do nothing
    }
  },
  async loadTwoFactorOptions ({ commit }) {
    try {
      var licenceResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence`)
      commit('updateTwoFactorOptions', { ...licenceResponse.data.mfa, smsEnabled: licenceResponse.data.other.sms })
    } catch (e) {
      // do nothing
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
