<template>
  <vue-good-table mode="remote"
                  ref="table"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  @on-row-click="onRowClick"
                  @on-cell-click="onCellClick"
                  :columns="columns"
                  :rows="toTransfer"
                  :lineNumbers="true"
                  :totalRows="stagedTotal"
                  :search-options="{
                    enabled: true
                    }"
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :rowStyleClass="rowToCss"
                  :isLoading.sync="isTableLoading"
                  styleClass="vgt-table striped bordered">
    <template slot="loadingContent">
      <h1>Loading...</h1>
    </template>
    <div slot="emptystate">
      <div class="vgt-center-align vgt-text-disabled">
        No Customers Available
      </div>
    </div>
    <div slot="table-actions">
      <button
        @click.prevent="clearTableFilters"
        class="btn btn-link"
        v-b-popover.hover.top.d500="'Clear filters'"
      >
                        <span class="fa-stack" style="font-size: 10px;">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span>
      </button>
      <b-button @click.prevent="load" class="" :disabled="isLoading" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'">
        <i class="fa fa-sync pointer dimmedIcon"></i>
      </b-button>
    </div>
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field === 'status'">
        <b-badge pill
                 :variant="getBadgeClass(props.row.status)"
        >{{props.formattedRow[props.column.field]}}</b-badge>
      </span>
      <span v-else-if="props.column.field === 'reference'">
        {{props.formattedRow[props.column.field]}} <i
        v-if="exists(props.row.reference)"
        v-b-popover.hover.top.d500="'Exists in target customer'"
        class="fa fa-exclamation-circle text-danger"
      ></i>
      </span>
      <span v-else-if="props.column.field === 'buttons'">
        <b-button @click="removeSingleFromStaging(props.row)" variant="outline-secondary"  v-b-popover.hover.top.d500="'Remove customer from staging.'"><i class="fa fa-calendar mr-2"></i>Remove</b-button>
                      </span>
      <span v-else>
        {{props.formattedRow[props.column.field]}}
      </span>
    </template>
  </vue-good-table>
</template>

<script>
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import axios from "axios";
import _ from "lodash";
import {mapGetters} from "vuex";
const statusToText = (x) => {
  switch (x) {
    case -1:
      return 'Any'
    case 0:
      return 'Operational'
    case 1:
      return 'Suspended'
    case 2:
      return 'Alert'
    case 3:
      return 'Closed'
    case 4:
      return 'Incomplete'
    case 5:
      return 'Awaiting Activation'
    case 6:
      return 'Plan Suspended'
    default:
      return 'Unknown'
  }
}

const statusToBadgeVariant = (x) => {
  switch (x) {
    case 3:
      return 'secondary'
    case 2:
    case 6:
      return 'warning'
    case 1:
      return 'danger'
    case 0:
      return 'success'
    case 4:
    case 5:
      return 'info'
    default:
      return 'dark'
  }
}

export default {
  props: {
    sessionId: String,
    existingReferences: Array
  },
  mixins: [tableFilterMixin, loading],
  name: "StagingTable",
  data () {
    return {
      toTransfer: [],
      stagedTotal: 0,
      unfiltered: 0,
      columns: [
        {
          label: 'id',
          field: 'ukPayerId',
          hidden: true
        },
        {
          label: 'Reference',
          field: 'reference'
        },
        {
          label: 'Group',
          field: 'groupName',
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
          tdClass: 'text-center'
        }
      ],
    }
  },
  methods: {
    async removeSingleFromStaging (row) {
      var response = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}payermovement/${this.sessionId}/${row.ukPayerId}`, { params: { } })
      if (response) {
        this.$toastr.s('Record removed from staging.')
        this.$emit('loadSource')
        await this.load()
      }
    },
    onRowClick (event) {
      // forces the table to do pointer cursor on row etc
    },
    onCellClick (event) {
      if (event.column.field !== 'buttons') {
        this.$store.dispatch('blankPayer')
        this.$router.push({ name: 'editPayer', params: { ukPayerId: event.row.ukPayerId } })
      }
    },
    load: _.debounce(async function () {
      this.isTableLoading = true
      if(this.sessionId !== null && this.sessionId !== undefined && this.sessionId !== '00000000-0000-0000-0000-000000000000') {
        //if (this.selectedGroup.groupId !== 0) {
        // await this.$store.dispatch('getGroupConfigurationFromGroupId', { id: this.group })
        var query = this.buildGoodTableQuery()
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payermovement/${this.sessionId}/staged`, {
          params: {
            ...query,
            groupId: '00000000-0000-0000-0000-000000000000',
            customerId: this.paygateId
          }
        })
        if (response) {

          this.toTransfer = response.data.data
          this.unfiltered = response.data.unfiltered
          this.stagedTotal = response.data.count
          this.$emit('stagedTotalChanged', { total: this.stagedTotal, filtered: this.serverParams.searchKey !== '', stageCleared: this.unfiltered === 0, unfiltered: this.unfiltered })
        }
      }
      this.isTableLoading = false
      //}
    }, 300),
    async removeCurrentFromStaging () {
      this.isTableLoading = true
      var query = this.buildGoodTableQuery()
      var response = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}payermovement/${this.sessionId}`, {
        params: {
          ...query,
          groupId: '00000000-0000-0000-0000-000000000000',
          customerId: this.paygateId
        }, showload: true })
      if (response) {
        this.$toastr.s('Current search records removed from staging.')
        await this.load()
        this.$emit('loadSource')
      }
    },
    getBadgeClass (value) {
      return statusToBadgeVariant(value)
    },
    rowToCss (row) {
      if (row.isNew) {
        return 'new-item'
      } else {
        return ''
      }
    },
    exists (ref) {
      return this.existingReferences.includes(ref)
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
  }
}
</script>

<style scoped>

</style>
