<template>
  <div>
    <form @submit.prevent="checkValidation() && saveGroups()" novalidate>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Import Groups</h2>
        </div>
        <div class="idb-block-content">
          <div v-if="showDropzone" class="row form-group">
            <div id="dzUpload" class="col-md-12">
              <vue-dropzone
                id="drop1"
                ref="fileUploader"
                v-bind:use-custom-dropzone-options="true"
                :options="dropzoneOptions"
                v-on:vdropzone-success="afterSuccess"
                v-on:vdropzone-file-added="fileAdded"
                v-on:vdropzone-sending="sending"
              ></vue-dropzone>
            </div>
          </div>
          <div v-if="errors.length > 0 && !showDropzone">
            <b-card
              :border-variant="errorVariant"
              :header="errorHeader"
              :header-bg-variant="errorVariant"
              header-text-variant="white"
            >
              <div v-for="error in errors" v-bind:key="error">
                <div>{{ error }}</div>
              </div>
            </b-card>
            <br />
          </div>
          <div v-if="groupCount > 0">
            <bank-account-select v-model="$v.defaultBankAccount" :bankAccounts="bankAccounts"></bank-account-select>
            <div class="form-group row" :class="{invalid: $v.defaultGroupAdminUser.$error}">
              <label class="label-control col-md-3 required">Group Admin User</label>
              <div class="col-md-5">
                <b-form-select v-model="$v.defaultGroupAdminUser.$model" :options="users"></b-form-select>
                <validation-messages v-model="$v.defaultGroupAdminUser" name="group admin user"></validation-messages>
              </div>
            </div>
          </div>
          <div v-if="groupCount > 0" class="row form-group">
            <div class="col-md-12">
              <vue-good-table
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                @on-row-click="onRowClick"
                :columns="columns"
                :rows="groups"
                :lineNumbers="true"
                :totalRows="groupCount"
                :pagination-options="{
                  enabled: true,
                  perPage: 10,
                  dropdownAllowAll: false
                }"
                styleClass="vgt-table striped bordered"
              ></vue-good-table>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <button
            class="btn btn-primary"
            type="submit"
            v-if="canImportGroups"
            :disabled="preventSaveGroups"
          >Save Groups</button>
          <button
            class="btn btn-outline-danger pull-right ml-3"
            type="button"
            @click="cancel"
          >Cancel</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import roles from '@/Assets/Constants/roles'
import { required } from 'vuelidate/lib/validators'
import bankAccountSelect from '@/Components/Platform/Group/EditGroupTypeDetails/EditingComponents/BankAccountSelect'

export default {
  components: {
    bankAccountSelect
  },
  data: () => ({
    bearerToken: '',
    dropzoneOptions: {
      url: '',
      language: {
        dictDefaultMessage: 'Drop a file here or click to select a file to upload.'
      },
      maxNumberOfFiles: 1,
      maxFileSizeInMB: 200 // MBs
    },
    showDropzone: true,
    groupCount: 0,
    groups: [],
    columns: [
      {
        label: 'Group Name',
        field: 'name',
        width: '20%'
      },
      {
        label: 'Description',
        field: 'description',
        width: '40%'
      },
      {
        label: 'Group Type',
        field: 'groupTypeDesc',
        width: '20%'
      },
      {
        label: 'SUN',
        field: 'serviceUserNumber',
        width: '20%'
      }
    ],
    errors: [],
    errorHeader: 'Imported Group Errors',
    errorVariant: 'danger',
    users: [],
    bankAccounts: [],
    defaultBankAccount: null,
    defaultGroupAdminUser: null,
    licenceCountExceeded: false
  }),
  computed: {
    canImportGroups () {
      return this.$store.getters.isInRole(roles.LZAdmin)
    },
    preventSaveGroups () {
      return this.groupCount === 0 || this.licenceCountExceeded
    }
  },
  methods: {
    fileAdded (file) {
      this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
    },

    async afterSuccess (file, response) {
      this.showDropzone = false
      await this.importGroupsFromFile(response.filename)
    },

    async sending (file, xhr, formData) {
      formData.append('paygateId', this.$store.state.common.paygateId)
    },

    async importGroupsFromFile (filename) {
      // console.log('importGroupsFromFile: ' + filename)
      try {
        var json = JSON.stringify({
          filename: filename
        })

        const response = await axios.post(
          `${process.env.VUE_APP_PLATFORM_API_URL}Groups/importGroups`, json
        )

        this.groups = response.data.groups
        this.groupCount = this.groups.length

        if (response.data.errors.length > 0) {
          this.errors = response.data.errors
        }

        this.licenceCountExceeded = response.data.response.status === 'Failed'
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    async saveGroups () {
      // console.log('Save groups')
      try {
        this.errors = []
        let errorCount = 0
        let lastStatus = ''
        let successCount = 0

        for (let i = 0; i < this.groups.length; i++) {
          const groupViewModel = {}
          groupViewModel.name = this.groups[i].name
          groupViewModel.description = this.groups[i].description
          groupViewModel.groupType = this.groups[i].groupType
          const bacsAutoFileImportConfig = {}
          bacsAutoFileImportConfig.ignore = true
          const groupTypeDetails = {
            serviceUserNumber: this.groups[i].serviceUserNumber,
            bankAccountId: this.defaultBankAccount,
            bacsAutoFileImportConfig: bacsAutoFileImportConfig,
            tokenType: 'SmartCard',
            deletePaymentData: true,
            importSchema: 0
          }
          groupViewModel.groupTypeDetails = groupTypeDetails
          const groupUserRights = []
          const user = this.users.find(i => i.value === this.defaultGroupAdminUser)
          const userToAdd = { userId: this.defaultGroupAdminUser, userName: user.text, groupAdministrator: true }
          groupUserRights.push(userToAdd)
          groupViewModel.groupUserRights = groupUserRights
          // console.log('groupViewModels: ' + JSON.stringify(groupViewModel))

          const response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/createImportedGroups`, groupViewModel)
          // console.log('save response: ' + JSON.stringify(response.data))

          if (response.data.errors.length === 0) {
            successCount++
          }
          lastStatus = response.data.response.status
          errorCount += response.data.errors.length
          this.errors = this.errors.concat(response.data.errors)
        }

        if (successCount > 0) {
          if (lastStatus === 'Action') {
            this.$toastr.s(`${successCount} new group${successCount > 1 ? 's' : ''} need${successCount > 1 ? '' : 's'} to be approved`, 'Needs Approval')
          } else {
            this.$toastr.s(`${successCount} new group${successCount > 1 ? 's' : ''} created`, 'Created')
          }
        }
        if (errorCount === 0) {
          this.$router.push({ name: 'Groups' })
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
    },

    onPerPageChange (params) {
      this.updateParams({ perPage: params.currentPerPage })
    },

    onSortChange (params) {
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type
        }
      })
    },

    onRowClick (params) {
    },
    cancel () {
      this.$router.back()
    }
  },
  created () {
    this.dropzoneOptions.url = process.env.VUE_APP_PLATFORM_API_URL + 'Groups/uploadFile'
  },
  async mounted () {
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
      })

    try {
      const bankResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/BankAccounts`)
      this.bankAccounts = bankResponse.data.map(bankAccount => {
        return { value: bankAccount.bankAccountId, text: bankAccount.reference }
      })
    } catch (e) {
      this.$toastr.e(e.response.data, e.response.statusText)
    }

    try {
      const userResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}users`)
      this.users = userResponse.data.data.map(user => {
        return { value: user.id, text: user.email }
      })
    } catch (error) {
      console.error(error)
    }
  },
  validations () {
    return {
      defaultBankAccount: { required },
      defaultGroupAdminUser: { required }
    }
  }
}
</script>
