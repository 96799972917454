<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>Payment Detail Report</h2>
    </div>

    <div class="idb-block-content">
      <div class="row form-group">
        <div class="form-group col-md-4">
          <label for="from">From</label>
          <transition name="fadein">
            <vuejsDatepicker
              name="selectFromMonth"
              id="selectFromMonth"
              format="MMM/yyyy"
              input-class="form-control bg-white"
              :bootstrap-styling="true"
              minimumView="month"
              maximumView="year"
              initial-view="month"
              :use-utc="true"
              :disabled-dates="{
                    from: new Date()
                    }"
              :calendar-button="true"
              calendar-button-icon="fa fa-calendar"
              v-model="selectedFromDate"
              @input="updateTable()"
            ></vuejsDatepicker>
          </transition>
        </div>
        <div class="form-group col-md-4">
          <label for="from">To</label>
          <transition name="fadein">
            <vuejsDatepicker
              name="selectToMonth"
              id="selectToMonth"
              format="MMM/yyyy"
              input-class="form-control bg-white"
              :bootstrap-styling="true"
              minimumView="month"
              maximumView="year"
              initial-view="month"
              :use-utc="true"
              :disabled-dates="{
                    from: new Date()
                    }"
              :calendar-button="true"
              calendar-button-icon="fa fa-calendar"
              v-model="selectedToDate"
              @input="updateTable()"
            ></vuejsDatepicker>
          </transition>
        </div>
        <div class="form-group col-md-4">
          <label for="from">Agent</label>
          <vue-select
            v-model="tableAgentsSelect"
            :options="tableDataAgents"
            label="text"
            :closeOnSelect="true"
            @input="updateTable()"
          ></vue-select>
        </div>
      </div>

      <vue-good-table
        :paginationOptions="paginationOptions"
        :sort-options="sortOptions"
        :isLoading.sync="isTableLoading"
        :rows="rows"
        :columns="columns"
        :lineNumbers="true"
        mode="remote"
        :totalRows="totalRecords"
        @on-row-click="onRowClick"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        :search-options="{
            enabled: true
          }"
        styleClass="vgt-table striped bordered"
        ref="table"
      >
        <div slot="table-actions">
          <button
            @click.prevent="clearTableFilters"
            class="btn btn-link"
            v-b-popover.hover.top.d500="'Clear filters'"
          >
            <span class="fa-stack" style="font-size: 10px;">
              <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
              <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
            </span>
          </button>
          <button
            @click.prevent="exportData"
            class="btn btn-link"
            v-b-popover.hover.top.d500="'Export Data'"
          >
            <i class="fa fa-share-square pointer dimmedIcon"></i>
          </button>
        </div>
      </vue-good-table>
    </div>
    <div class="idb-block-footer"></div>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import VueSelect from 'vue-select'
import moment from 'moment'
import loading from '@/Assets/Mixins/LoadingMixin'
import integer from 'vuelidate/lib/validators/integer'
import _ from 'lodash'

export default {
  mixins: [loading, tableFilterMixin],
  components: { VueSelect },
  data () {
    return {
      // **** Date Selections
      selectedFromDate: new Date(),
      selectedToDate: new Date(),
      tableAgentsSelect: [],
      tableDataAgents: [],
      // **** Table Data

      rows: [],
      columns: [
        {
          label: 'agentId',
          field: 'agentId',
          hidden: true
        },
        {
          label: 'commissionId',
          field: 'commissionId',
          hidden: true
        },
        {
          label: 'paygateid',
          field: 'paygateId',
          hidden: true
        },
        {
          label: 'statementid',
          field: 'statementId',
          hidden: true
        },
        {
          label: 'Agent',
          field: 'agentName',
          filterOptions: {
            enabled: false
          },
          tdClass: this.GetFieldFormat
        },
        {
          label: 'Commission Created',
          field: 'commissionCreatedDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: true,
          tdClass: this.GetFieldFormat
        },

        {
          label: 'Customer Name',
          field: 'customerName',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Invoice',
          field: 'invoiceNumber',
          filterOptions: {
            enabled: false
          },
          tdClass: this.GetFieldFormat
        },
        {
          label: 'Service Charge',
          field: 'serviceCharge',
          sortable: true,
          formatFn: this.GetMoney,
          tdClass: this.GetFieldFormatRight
        },
        {
          label: 'Rate',
          field: 'rate',
          sortable: true,
          formatFn: this.GetRateField,
          tdClass: this.GetFieldFormat

        },
        {
          label: 'Method',
          field: 'method',
          sortable: false,
          tdClass: this.GetFieldFormat
        },

        {
          label: 'Description',
          field: 'description',
          sortable: false
        },

        {
          label: 'Overridden',
          field: 'overridden',
          sortable: true,
          formatFn: this.GetOverriddenField
        },
        {
          label: 'Manually Created',
          field: 'manualCreated',
          sortable: true,
          formatFn: this.GetManualCreationField
        },
        {
          label: 'Amount',
          field: 'amount',
          sortable: true,
          formatFn: this.GetMoney,
          tdClass: this.GetFieldFormatTextRight
        },
        {
          label: 'Notes',
          field: 'commissionNotes',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Statement Ref',
          field: 'statementSerialId',
          filterOptions: {
            enabled: false
          },
          tdClass: this.GetFieldFormat
        },
        {
          label: 'Statement Email Date',
          field: 'emailedDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: true,
          tdClass: this.GetFieldFormat
        }

      ],
      serverParams: {
        sort: [{ field: 'customerName', type: 'asc' }],
        columnFilters: []
      }

    }
  },
  async created () {
    await this.getTableOptions()
    this.SetFromDate()
  },

  methods: {
    async load () {
      await this.updateTable()
    },

    SetFromDate () {
      // sets the initial FROM date back six months
      var dateVal = new Date()
      var monthVal = dateVal.getMonth() - 6
      if (monthVal < 0) {
        monthVal = monthVal + 12
        dateVal.setMonth(monthVal)
        dateVal.setYear(dateVal.getFullYear() - 1)
      } else {
        dateVal.setMonth(monthVal)
      }
      this.selectedFromDate = dateVal
    },

    async getTableOptions () {
      console.log('Getting Data')
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionTableOptionData`,
          {
            params: { paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data for listing commissions'
          })

        console.log('table data response', response)

        this.tableDataAgents = []
        for (var i = 0; i < response.data.agentTableData.length; i++) {
          this.tableDataAgents.push({ value: response.data.agentTableData[i].agentId, text: response.data.agentTableData[i].agentName })
        }
      } catch (e) {
        console.log('e', e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    updateTable: async function () {
      try {
        console.log('updateTable')
        this.serverParams.columnFilters.datefrom = moment(this.selectedFromDate).format('DD-MM-YYYY')
        this.serverParams.columnFilters.dateto = moment(this.selectedToDate).format('DD-MM-YYYY')
        delete this.serverParams.columnFilters.AgentSelect
        if (this.tableAgentsSelect !== null && this.tableAgentsSelect !== undefined) {
          this.serverParams.columnFilters.agentid = this.tableAgentsSelect.value
        }
        // var payload =
        // {
        //   agentid: 0,
        //   dateFrom: new Date(),
        //   dateTo: new Date()
        // }
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetPaymentDetailReport`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading agents'
          })

        console.log('response', response)
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    onRowClick () {
      console.log('rowclick')
    },
    exportData: async function () {
      try {
        console.log('Get Table CSV')
        this.serverParams.columnFilters.datefrom = moment(this.selectedFromDate).format('DD-MM-YYYY')
        this.serverParams.columnFilters.dateto = moment(this.selectedToDate).format('DD-MM-YYYY')
        delete this.serverParams.columnFilters.AgentSelect
        if (this.tableAgentsSelect !== null && this.tableAgentsSelect !== undefined) {
          this.serverParams.columnFilters.agentid = this.tableAgentsSelect.value
        }

        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetPaymentDetailReport`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data'
          })

        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Agents.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {
        console.log(e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    GetOverriddenField (field) {
      if (field) {
        return 'yes'
      } else { return '' }
    },
    GetManualCreationField (field) {
      if (field) {
        return 'yes'
      } else { return '' }
    },
    GetRateField (field, row) {
      if (row.manualCreated || row.statementReconciliation) { return '' }
      if (row.fixedFeePercentage === 'Percentage') {
        return field + '%'
      } else {
        return '£' + field
      }
    },
    GetMoney (field) {
      if (field === 0) { return '' } else {
        return '£' + this.numberWithCommas((field / 1).toFixed(2))
      }
    },
    numberWithCommas (n) {
      n = n.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(n)) {
        n = n.replace(pattern, '$1,$2')
      }
      return n
    },

    GetFieldFormat (row) {
      if (row.statementReconciliation) {
        return 'text-danger'
      } else { return '' }
    },

    GetFieldFormatTextRight (row) {
      if (row.statementReconciliation) {
        return 'text-right text-danger'
      } else {
        return 'text-right'
      }
    }

  }
}
</script>
