<template>
  <div id="viewFlaggedSubmissions">
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2><help-icon docPath="/paygate-collections/submissions/" />Scheduled Collections</h2>
          </div>
          <div class="idb-block-content">

            <b-row style="margin-bottom: 0.8em;">
              <b-col md="4"><strong>Group:</strong> {{ tableData.length > 0 ? tableData[0].groupName : '' }} </b-col>
              <b-col md="4"><strong>Next Processing Date:</strong> {{ formatDate(dates.proc) }} </b-col>
              <b-col md="4"><strong>Next Collection Date:</strong> {{ formatDate(dates.coll) }} </b-col>
            </b-row>
            <vue-good-table
              ref="table"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              @on-cell-click="onCellClick"
              :columns="columns"
              :rows="tableData"
              :lineNumbers="true"
              :totalRows="totalRecords"
              :search-options="{
                enabled: true
                }"
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              @on-row-click="onRowClick"
              styleClass="vgt-table striped bordered"
              :isLoading.sync="isTableLoading"
              :row-style-class="setRowStyle">
              <template slot="table-row" slot-scope="props">
                <span v-if="selectedRow && selectedRow.paymentComposite === props.row.paymentComposite">
                  <span v-if="props.column.field === 'amount'">
                    <currency-input v-model="props.row.amount" @input="amountChanged(props)"
                      currency="GBP"
                      locale="en"/>
                  </span>
                  <span v-else-if="props.column.field === 'processingDate'">
                    <datepicker v-model="props.row.processingDate" @selected="processingDateChanged" :disabledDates="disabledDates" />
                  </span>
                  <span v-else-if="props.column.field === 'viewPayerBtn'">
                    <router-link :to="{ name: 'editPayer', params: { ukPayerId: props.row.ukPayerId } }"> <!-- eslint-disable-line -->
                        <span>View Customer</span>
                      </router-link>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </span>
                <span v-else>
                  <span v-if="props.column.field == 'viewBtn'">
                    <span style="font-weight: bold; color: blue;">View</span>
                  </span>
                  <span v-else-if="props.column.field === 'amount'">
                    <i v-if="props.row.pendingVariableAmount || props.row.amount == 0" v-b-tooltip.hover title="Variable Collection amount required, 0 amounts are invalid under BACS rules." class="fa fa-exclamation-circle text-warning mr-2"></i>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                  <span v-else-if="props.column.field === 'viewPayerBtn'">
                    <router-link :to="{ name: 'editPayer', params: { ukPayerId: props.row.ukPayerId } }"> <!-- eslint-disable-line -->
                        <span>View Customer</span>
                      </router-link>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </span>
              </template>
              <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">
                    No Collections Found
                  </div>
                </div>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'"
                  >
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span>
                  </button>
                  <b-button @click.prevent="load" class="" variant="link"  :disabled="isLoading" v-b-popover.hover.top.d500="'Refresh the data in the table'">
                    <i class="fa fa-sync pointer dimmedIcon"></i>
                  </b-button>
                  <b-button @click.prevent="printTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Print out the contents of the table'">
                    <i class="fa fa-print pointer dimmedIcon"></i>
                  </b-button>
                  <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
                    <i class="fa fa-share-square pointer dimmedIcon"></i>
                  </b-button>
                  <p-check
                    id="previewCheck"
                    class="p-switch p-fill"
                    color="primary"
                    v-model="onlyInvalidPayments"  @change="invalidChanged">Show only invalid Collections</p-check>
                </div>
              </vue-good-table>

          </div>
          <div class="idb-block-footer mt-3">
            <b-btn type="link" variant="primary" @click.prevent="back">
              <i class="fas fa-angle-left mr-2"></i> Back
            </b-btn>
            <b-btn type="link" class="float-right" variant="primary" v-if="hasChanges" :disabled="isLoading" @click.prevent="saveSubmissionChanges">
              Save Changes
            </b-btn>
            <b-btn type="link" class="float-right mr-2" variant="outline-warning" :disabled="isLoading" v-if="hasChanges" @click.prevent="cancelSubmissionChanges">
              Cancel Changes
            </b-btn>
            <b-btn class="float-right" type="link" variant="primary" v-if="!hasChanges" :disabled="isLoading" @click.prevent="process">
              Process Submission
            </b-btn>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// eslint-disable-next-line
import Vuelidate from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker'
import swal from 'sweetalert2'
import _ from 'lodash'
import PrettyCheck from 'pretty-checkbox-vue/check'
import papa from 'papaparse'
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import rulesMixin from '@/Assets/Mixins/CollectionsRulesMixin'
export default {
  mixins: [tableFilterMixin, loading, rulesMixin],
  props: {
    groupId: String
  },
  components: {
    Datepicker,
    pCheck: PrettyCheck
  },
  data () {
    return {
      columns: [
        {
          field: 'flaggedPaymentsCount',
          hidden: true
        },
        {
          label: 'First Name',
          field: 'firstName',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Surname',
          field: 'surname',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Reference',
          field: 'payerReference',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Transaction Code',
          field: 'txCode',
          width: '180px'
        },
        {
          label: 'Amount (£)',
          field: 'amount',
          formatFn: (value) => {
            return `${this.numberWithCommas((value / 1).toFixed(2))}`
          },
          tdClass: 'text-right amountField'
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          formatFn: (val) => this.formatDate(new Date(val)),
          width: '180px'
        },
        {
          label: 'Plan Reference',
          field: 'planReference'
        },
        {
          label: '',
          field: 'viewPayerBtn',
          sortable: false
        }
      ],
      serverParams: {
        sort: [{ field: 'payerName', type: 'asc' }]
      },
      dates: { proc: new Date(), coll: new Date() },
      selectedRow: null,
      originalState: [],
      tableData: [],
      hasChanges: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      onlyInvalidPayments: false
    }
  },
  methods: {
    process () {
      this.$router.push({ name: 'ProcessCollection', params: { groupId: this.groupId, mode: 'collection' } })
    },
    back () {
      this.$router.push('/collections/submissions/')
    },
    getSubmissions () {
      this.$store.dispatch('loadSubmissions')
    },
    async invalidChanged () {
      this.updateParams({ page: 1 })
      this.load()
    },
    load: async function () {
      if (this.updateTotal) {
        document.querySelector('.footer__navigation__page-info').style.display = 'none'
      }
      var query = this.buildGoodTableQuery()
      query.paygateId = this.paygateId
      try {
        const response = await axios.get(!this.onlyInvalidPayments ? `${process.env.VUE_APP_DDMS_API_URL}submissions/${this.groupId}/view` : `${process.env.VUE_APP_DDMS_API_URL}submissions/${this.groupId}/viewinvalid`, { params: query, showload: true })
        this.originalState = response.data.data
        this.tableData = _.cloneDeep(this.originalState)
        if (this.updateTotal) {
          const countResponse = await axios.get(!this.onlyInvalidPayments ? `${process.env.VUE_APP_DDMS_API_URL}submissions/${this.groupId}/count` : `${process.env.VUE_APP_DDMS_API_URL}submissions/${this.groupId}/invalidcount`, { params: query })
          this.totalRecords = countResponse.data
        } else {
          document.querySelector('.footer__navigation__page-info').style.display = 'inline-block'
        }
      } catch (e) {
        console.error(e)
        this.$toastr.e('An error occurred loading the submission')
      }
      this.updateTotal = false
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    async exportTable () {
      var query = this.buildGoodTableQuery()
      query.paygateId = this.paygateId
      try {
        const response = await axios.get(!this.onlyInvalidPayments ? `${process.env.VUE_APP_DDMS_API_URL}submissions/${this.groupId}/view` : `${process.env.VUE_APP_DDMS_API_URL}submissions/${this.groupId}/viewinvalid`, { params: query, showload: true })
        var submission = response.data.data
        var csvString = papa.unparse(submission)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, 'collections-submission.csv')
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = 'collections-submission.csv'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      } catch (e) {
        console.error(e)
        this.$toastr.e('An error occurred exporting the submission queue')
      }
    },
    onRowClick (params) {
    },
    onCellClick (params) {
      // this.selectedRow = this.tableData[params.rowIndex]
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    processingDateChanged (date) {
      var dateOffset = (24 * 60 * 60 * 1000) * 1
      let procDate = new Date(date.getTime())
      procDate = new Date(procDate.setTime(procDate.getTime() - dateOffset))
      // CORRECT DATE.
      let procDateValid = false
      while (!procDateValid) {
        if (procDate.getDay() === 6 || procDate.getDay() === 0 || this.$store.getters.nonProcessingDates.includes(procDate)) {
          procDate = new Date(procDate.setTime(procDate.getTime() - dateOffset))
        } else {
          procDateValid = true
        }
      }
      this.selectedRow.processingDate = procDate.toISOString()
      for (var i = 0; i < this.tableData.length; i++) {
        if ((this.tableData[i].paymentComposite !== '' && this.tableData[i].paymentComposite === this.selectedRow.paymentComposite) || (this.tableData[i].paymentComposite === '' && this.tableData[i].ukddmsPendingPaymentId === this.selectedRow.ukddmsPendingPaymentId)) {
          this.tableData[i].dateChanged = true
          this.hasChanges = true
        }
      }
    },
    amountChanged (props) {
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].paymentComposite === this.selectedRow.paymentComposite) {
          this.tableData[i].amount = props.row.amount
          this.tableData[i].amountChanged = true
          this.hasChanges = true
        }
      }
    },
    async saveSubmissionChanges () {
      var invalid = false
      for (var i = 0; i < this.tableData.length; i++) {
        const payment = this.tableData[i]
        if (payment.amountChanged && !payment.dateChanged) {
          invalid = true
        }
      }
      var result
      if (!invalid) {
        result = await swal({
          title: 'Save submission',
          text: 'Collection amounts and dates have been changed. Saving the Collections will remove them from the current submission.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel'
        })
        if (result) {
          var response = await this.$store.dispatch('updateSubmission', this.tableData)
          if (response) {
            this.$toastr.s('Submission updated')
          }
          this.$store.dispatch('updateCurrentCollectionsSubmissionCount')
          await this.load()
          this.hasChanges = false
          this.selectedRow = null
        }
      } else {
        result = await swal({
          title: 'Validation',
          text: 'Collection amounts have been changed without updating the processing date. Please update the processing date.',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Ok',
          cancelButtonText: 'No'
        })
        console.log(result)
      }
    },
    cancelSubmissionChanges () {
      this.$store.dispatch('resetData')
      this.tableData = _.cloneDeep(this.flaggedPayments)
      this.hasChanges = false
      this.selectedRow = null
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    setRowStyle (row) {
      return row.amount === 0 ? 'text-muted' : ''
      // return row.amount === 0 ? 'alert' : ''
    },
    amountFormatter (value) {
      return value.toFixed(2)
    }
  },
  computed: {
    selectedPaygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    customerGroups () {
      return this.$store.state.groups.groups
    },
    flaggedPayments: {
      get () {
        return this.$store.state.submissions.flaggedPayments
      },
      set (value) {
        if (value) {
          this.$store.commit('setFlagged', value)
        }
      }
    },
    disabledDates: function () {
      let maxDate = new Date()
      let minDate = new Date(this.dates.proc)
      if (this.selectedRow) {
        const start = new Date(this.selectedRow.processingDate)
        console.log(start.getDate())
        // minDate = new Date(start.getTime())
        minDate = this.$store.getters.collectionsGroupConfig.groupConfig.defaultPayerMessageType === 1 ? this.$store.getters.collectionsMinDateLetter : this.$store.getters.collectionsMinDate
        maxDate = new Date(Date.parse(this.$store.getters.scheduleMetaData.lastPossiblePaymentDate))
        console.log(minDate)
        console.log(maxDate)
        if (this.selectedRow.isNew) {
          minDate = new Date()
          maxDate = new Date(Date.parse(this.$store.getters.scheduleMetaData.lastPossiblePaymentDate))
        }
      }
      return {
        days: [0, 6],
        daysOfMonth: [29, 30, 31],
        dates: this.$store.getters.nonProcessingDates,
        to: minDate,
        from: maxDate
      }
    },
    advancedNoticeWithMedia () {
      var groupConfig = this.$store.getters.collectionsGroupConfig
      return this.getAdvancedNoticeWithMedia(groupConfig.advanceNoticeInDays, groupConfig.defaultPayerMessageType)
    },
    totalPages () {
      return this.$store.getters.submissionMetaData.totalPages
    },
    totalItems () {
      return this.$store.getters.submissionMetaData.totalItems
    }
  },
  validations: {
    group: { required }
  },
  async mounted () {
    await this.$store.dispatch('getGroupConfigurationFromGroupId', { id: this.groupId })
    var resp = await this.$store.dispatch('getScheduleProcessingDates')
    this.dates.proc = resp.proc
    this.dates.coll = resp.coll
    this.updateTotal = true
    await this.load()
  }
}
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
</style>
