<template>
  <div style="width:100%; min-width:25vw">
    <vue-select
      v-model="selectedPaygateId"
      :options="customers"
      :closeOnSelect="true"
      label="name"
      :reduce="customer => customer.paygateId"
      :clearable="false"
      :selectable="option => !option.hasOwnProperty('group')"
    >
      <template #option="{ group, name, onHold }">
        <div v-if="group" class="group">{{ group }}</div>
        {{ name }}
        <span class="pull-right badge badge-pill badge-warning" v-if="onHold">On Hold</span>
      </template>
      <template #selected-option-container="{option }">
        <div class="vs__selected" style="position: unset; flex: auto">
          {{ option.name }}
          <span
            class="badge badge-pill badge-warning"
            style="margin-left: auto;"
            v-if="option.onHold"
          >On Hold</span>
        </div>
      </template>
    </vue-select>
  </div>
</template>

<script>
import VueSelect from 'vue-select'
import customerTypes from '@/Assets/Constants/customerTypes'
import { mapGetters } from 'vuex'

function customerTypeToNumber (type, paygateId) {
  if (paygateId === process.env.VUE_APP_LANDZ_ID) {
    return 3
  }

  switch (type) {
    case customerTypes.general:
      return 0
    case customerTypes.connectedMerchant:
      return 1
    case customerTypes.master:
      return 2
    default:
      return 0
  }
}

export default {
  components: {
    VueSelect
  },
  computed: {
    selectedPaygateId: {
      get: function () {
        return this.$store.state.common.paygateId
      },
      set: function (paygateId) {
        try {
          this.$store.dispatch('setPaygateId', paygateId)
          this.$store.commit('clearTableFilters')
        } catch (error) {
          console.error(error)
        }
      }
    },
    customers () {
      var customers = this.customersOrdered

      customers = customers.sort((a, b) => {
        var typeA = customerTypeToNumber(a.type, a.paygateId)
        var typeB = customerTypeToNumber(b.type, b.paygateId)
        return (typeA > typeB) ? -1 : (typeA < typeB) ? 1 : 0
      })

      var result = []
      customers.forEach((c, i) => {
        if (i === 0) {
          if (c.paygateId === process.env.VUE_APP_LANDZ_ID) {
            result.push({ group: 'System Client', name: '' })
          } else {
            result.push({ group: `${c.type} Clients`, name: '' })
          }
        }
        if (i > 0) {
          var previousC = customers[i - 1]
          if (c.type !== previousC.type || previousC.paygateId === process.env.VUE_APP_LANDZ_ID) {
            result.push({ group: `${c.type} Clients`, name: '' })
          }
        }
        result.push(c)
      })

      return result
    },
    ...mapGetters(['customersOrdered'])
  }
}
</script>
<style lang="scss">
.vs__dropdown-option--disabled {
  background: #d1d1d1;
  color: #3b3b3b;
}

.v-select {
  font-size: 16px;
}

.vs--searching {
  .vs__search {
    flex-grow: 1 !important;
    flex: auto;
  }
}

.vs__search {
  flex: none;
  &:focus {
    flex-grow: 0;
  }
}
</style>
