<template>
  <div id="standard18">
    <div class="row form-group">
      <div class="col-md-12">
        <b-form-textarea v-model="standard18" :rows="10"></b-form-textarea>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-2">
        <button type="button" class="btn btn-primary" :disabled="this.moreStnd18 === false" v-on:click="getMoreStandard18">Get More Standard18</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsCommon from '@/Lib/BacsCommon.js'
import groupTypes from '@/Assets/Constants/groupTypes'
import roles from '@/Assets/Constants/roles'

export default {
  data () {
    return {
      submissionId: '',
      standard18: '',
      fileNumber: 1,
      fromRecordNumber: 0,
      toRecordNumber: 0,
      paymentBlockSize: 1000, // Default size in case call to get value from bacsEngineConfig fails.
      totalNumberOfPayments: 0,
      intelligibleSummaries: {},
      moreStnd18: false,
      paygateType: '',
      includeVol1AndXml: true,
      indirectGroup: false // Set to true for PGO or indirect collections group.
    }
  },

  methods: {
    getAllStandard18 () {
      this.fileNumber = 1
      this.fromRecordNumber = 1
      this.toRecordNumber = this.paymentBlockSize
      var json = this.createJson()

      return axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getStandard18',
        data: json,
        showload: true
      })
        .then(response => {
          this.standard18 = response.data
          this.$store.dispatch('setStandard18', this.standard18)
        })
        .catch(error => console.log(error))
    },

    async getMoreStandard18 () {
      const data = await this.getStandard18Block()
      this.standard18 += data
      this.$store.dispatch('setStandard18', this.standard18)
    },

    async getStandard18Block () {
      return new Promise(
        (resolve, reject) => {
          var json = this.createJson()
          return axios({
            method: 'POST',
            url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getStandard18Block',
            data: json,
            showload: true
          })
            .then(response => {
              this.updateCounters()
              resolve(response.data)
            })
            .catch(error => console.log(error))
        }
      )
    },

    updateCounters () {
      if (this.toRecordNumber > this.intelligibleSummaries[this.fileNumber - 1].totalRecordCount) {
        // Got all payment data for current file block.
        // Now check whether all files have been processed.
        if (this.fileNumber < this.intelligibleSummaries.length) {
          // Multi block file so start getting data for next file.
          this.fileNumber++
          this.fromRecordNumber = 1
          this.toRecordNumber = this.paymentBlockSize
        } else {
          // No more files to process so don't get anymore payment data.
          this.moreStnd18 = false
        }
      } else {
        // Haven't got all the payment data yet so update params to get next block of payments.
        this.fromRecordNumber += this.paymentBlockSize
        this.toRecordNumber += this.paymentBlockSize
      }

      this.updateParams()
    },

    updateParams () {
      // Update store with latest parameters.
      var viewSubmissionData = this.$store.getters.viewSubmissionData
      viewSubmissionData.fileNumber = this.fileNumber
      viewSubmissionData.fromRecordNumber = this.fromRecordNumber
      viewSubmissionData.toRecordNumber = this.toRecordNumber
      viewSubmissionData.moreStnd18 = this.moreStnd18
      this.$store.dispatch('setViewSubmissionData', viewSubmissionData)
    },

    createJson () {
      return JSON.stringify({
        submissionId: this.submissionId,
        serviceUserNumber: this.intelligibleSummaries[this.fileNumber - 1].serviceUserNumber,
        fileNumber: this.fileNumber,
        fromRecordNumber: this.fromRecordNumber,
        toRecordNumber: this.toRecordNumber,
        certificateThumbprint: '',
        paygateType: this.paygateType,
        includeVol1AndXml: this.includeVol1AndXml,
        indirectGroup: this.indirectGroup
      })
    }
  },

  async mounted () {
    var bacsEngineConfig = this.$store.getters.bacsEngineConfig
    this.paymentBlockSize = bacsEngineConfig.paymentsPerBlock

    var bacsGroup = this.$store.getters.bacsGroup
    this.paygateType = bacsCommon.getPaygateType(bacsGroup.groupType)
    // PGO or collections indirect (PGO) group.
    if (this.paygateType === 'Online' || (bacsGroup.groupType === groupTypes.collections && bacsGroup.groupTypeDetails.submissionType === 2)) {
      this.includeVol1AndXml = false
      if (this.$store.getters.isInRole(roles.SystemUser)) {
        this.includeVol1AndXml = true
      }
      this.indirectGroup = true
    }

    this.submissionId = this.$store.getters.submissionId

    var viewSubmissionData = this.$store.getters.viewSubmissionData
    this.intelligibleSummaries = this.$store.getters.intelligibleSummaries

    if (viewSubmissionData.standard18Loaded === false) {

      const canViewResponse = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/canViewStandard18?submissionId=` + this.submissionId, { showload: true })
      if (canViewResponse.data.success) {
        for (var i = 0; i < this.intelligibleSummaries.length; i++) {
          this.totalNumberOfPayments += this.intelligibleSummaries[i].totalRecordCount
        }

        viewSubmissionData.standard18Loaded = true

        // Determine whether to get all standard18 in one go or in blocks.
        if (this.totalNumberOfPayments < this.paymentBlockSize) {
          this.$store.dispatch('setViewSubmissionData', viewSubmissionData)
          this.getAllStandard18()
        } else {
          this.moreStnd18 = true
          this.fileNumber = 1
          this.fromRecordNumber = 1
          this.toRecordNumber = this.paymentBlockSize
          viewSubmissionData.moreStnd18 = this.moreStnd18
          viewSubmissionData.fileNumber = this.fileNumber
          viewSubmissionData.fromRecordNumber = this.fromRecordNumber
          viewSubmissionData.toRecordNumber = this.toRecordNumber
          this.$store.dispatch('setViewSubmissionData', viewSubmissionData)
          this.getMoreStandard18()
        }
      } else {
        this.standard18 = canViewResponse.data.errorMessage
        this.$store.dispatch('setViewSubmissionData', viewSubmissionData)
      }
    } else {
      this.standard18 = this.$store.getters.standard18
      this.fileNumber = viewSubmissionData.fileNumber
      this.fromRecordNumber = viewSubmissionData.fromRecordNumber
      this.toRecordNumber = viewSubmissionData.toRecordNumber
      this.moreStnd18 = viewSubmissionData.moreStnd18
    }
  }
}
</script>
