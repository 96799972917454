<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              List Invoices
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="from" class="required">From</label>
                <vuejsDatepicker
                  name="from"
                  id="from"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="dates.from"
                  :disabledDates="{from:dates.to}"
                  @input="load"
                ></vuejsDatepicker>
              </div>
              <div class="form-group col-md-4">
                <label for="from" class="required">To</label>
                <vuejsDatepicker
                  name="from"
                  id="from"
                  format="dd/MM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="day"
                  maximumView="year"
                  initial-view="day"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="dates.to"
                  :disabledDates="{to:dates.from}"
                  @input="load"
                ></vuejsDatepicker>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <vue-good-table
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :isLoading.sync="isTableLoading"
                  :rows="rows"
                  :columns="columns"
                  :lineNumbers="true"
                  mode="remote"
                  :totalRows="totalRecords"
                  @on-row-click="onRowClick"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  styleClass="vgt-table striped bordered"
                  ref="table"
                  class="scroll-table"
                >
                  <template slot="table-actions">
                    <div>
                      <button
                        @click.prevent="clearTableFilters"
                        class="btn btn-link"
                        v-b-popover.hover.top.d500="'Clear filters'"
                      >
                        <span class="fa-stack m-auto" style="font-size: 10px">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span>
                      </button>

                      <button
                        @click.prevent="load"
                        class="btn btn-link"
                        v-b-popover.hover.top.d500="'Refresh the data in the table'"
                      >
                        <i class="fas fa-sync-alt pointer dimmedIcon"></i>
                      </button>
                    </div>
                  </template>
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'voided'">
                      <span v-if="props.row.voided" class="badge badge-danger badge-pill">voided</span>
                    </span>
                    <span
                      v-else-if="props.column.tdClass === 'text-right'"
                      :class="tdClass(props.row, props.column.field)"
                    >{{props.formattedRow[props.column.field]}}</span>
                    <span v-else-if="props.column.field === 'actions'">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-primary mr-3 mb-2"
                        @click.prevent="downloadFrontpage($event, props.row)"
                        v-if="props.row.pdfLocation"
                      >
                        <i class="glyphicon ti-download"></i> Front Page
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-primary mb-2"
                        @click.prevent="downloadBackpage($event, props.row)"
                        v-if="props.row.excelLocation"
                      >
                        <i class="glyphicon ti-download"></i> Back Page
                      </button>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button
              type="button"
              class="btn btn-info ml-3"
              v-if=" isFinance"
              @click.prevent="sendAllEmails()"
            >
              <i class="glyphicon ti-email"></i>
              Email All Unsent Invoices
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import moment from 'moment'
import { saveAs } from 'file-saver'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import invoicingMixin from '@/Components/Platform/Customer/Invoicing/invoicingMixin'

// Constants
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [loading, tableFilterMixin, invoicingMixin],
  name: 'ListInvoices',
  computed: {

    isFinance () {
      return this.$store.getters.isInRole(roles.Finance)
    }
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          field: 'id',
          hidden: true
        },
        {
          field: 'documentNumber',
          hidden: true
        },
        {
          field: 'creditNote',
          hidden: true
        },
        {
          label: 'Number',
          field: 'documentId',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Client Name',
          field: 'clientName',
          sortable: true,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Type',
          field: 'type',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Any Type',
            filterDropdownItems: ['Credit Note', 'Invoice']
          }
        },
        {
          label: 'Invoice Date',
          field: 'date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        },

        {
          label: 'From Date',
          field: 'fromDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        },

        {
          label: 'To Date',
          field: 'toDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Sent Date',
          field: 'sentDate',
          type: 'date',
          sortable: true,
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Total (£)',
          field: 'total',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Voided',
          field: 'voided',
          sortable: true
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          width: '350px'
        }
      ],
      serverParams: {
        sort: [{ field: 'documentId', type: 'desc' }]
      },
      detailParams: null,
      dates: {
        from: null,
        to: null
      },
      sortOptions: { enabled: true, initialSortBy: { field: 'documentId', type: 'desc' } },
      shouldLoad: false
    }
  },
  methods: {
    async load () {
      // Load stuff in
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/List`, {
          params: {
            ...this.buildGoodTableQuery({
              fromDate: this.dates.from,
              toDate: this.dates.to
            }),
            fromDate: this.dates.from,
            toDate: this.dates.to
          },
          showload: true,
          showerror: true,
          errormessage: 'Invoices failed to load'
        })

        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch { }
    },
    onRowClick (params) {
      var invoiceId = params.row.id
      this.$router.push({ name: 'InvoiceDetails', params: { id: invoiceId } })
    },
    async downloadBackpage (e, row) {
      e.stopPropagation()
      try {
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/BackpageDownload`,
          {
            invoiceId: row.id
          },
          {

            responseType: 'blob',
            showload: true,
            showerror: true,
            errormessage: 'Invoice back page failed to download'
          })

        const file = new Blob([response.data])

        saveAs(file, `L&Z invoice ${row.documentId} Breakdown.xlsx`)
      } catch {
      }
    },
    async downloadFrontpage (e, row) {
      e.stopPropagation()
      try {
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/frontpageDownload`,
          {
            invoiceId: row.id
          },
          {

            responseType: 'blob',
            showload: true,
            showerror: true,
            errormessage: 'Invoice front page failed to download'
          })

        const file = new Blob([response.data])

        saveAs(file, `L&Z Invoice ${row.documentId}.pdf`)
      } catch {
      }
    },
    async sendAllEmails () {
      try {
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Invoice/SendAllInvoices`, null,
          {
            showload: true,
            showerror: true,
            errormessage: 'Invoice failed to send'
          })

        this.$toastr.s(
          'Invoices Sent',
          'Sent'
        )
      } catch {
      } finally {
        this.load()
      }
    }
  },
  async created () {
    if (this.serverParams.otherData && this.serverParams.otherData.toDate && this.serverParams.otherData.fromDate) {
      this.dates.to = moment(this.serverParams.otherData.toDate).toDate()
      this.dates.from = moment(this.serverParams.otherData.fromDate).toDate()
    } else {
      var thisMonth = moment().utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0, date: 1 }).add(1, 'month')
      this.dates.to = moment(thisMonth).subtract(1, 'day').toDate()
      this.dates.from = moment(thisMonth).subtract(1, 'month').toDate()
    }
    await this.load()
  }
}
</script>

<style lang="scss" scoped>
</style>
