<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2 id="title-of-block-check">Download Monthly Report</h2>
          </div>
          <div class="idb-block-content">
            <form novalidate v-on:submit.prevent class="col-md-12">
              <div class="form-group">
                <label class="label-control">Please choose the month you'd like to download</label>

                <vuejsDatepicker
                  name="date"
                  id="date"
                  format="MMMM yyyy"
                  input-class="form-control bg-white col-md-3"
                  :bootstrap-styling="true"
                  minimumView="month"
                  maximumView="year"
                  initial-view="month"
                  :use-utc="true"
                  v-model="selectedDate"
                  @input="getAmountOfRecords"
                  :disabled-dates="{
                    from: new Date()
                    }"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                ></vuejsDatepicker>

                <small
                  class="form-text text-muted"
                  v-if="amountOfRecords"
                >There are {{amountOfRecords}} records for this month</small>
                <small class="form-text text-info" v-else-if="isLoading">
                  Counting records ...
                  <i class="fas fa-spinner fa-pulse"></i>
                </small>
                <small class="form-text text-warning" v-else>There are no records for this month</small>
                <small class="form-text">This will download a report for all Clients</small>
              </div>
            </form>
          </div>
          <div class="idb-block-footer">
            <button class="btn btn-primary" :disabled="isLoading" @click="downloadFile">Download</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios'
import moment from 'moment'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  name: 'ValidateReport',
  mixins: [loading],
  components: {
  },
  data: function () {
    return {
      selectedDate: null,
      amountOfRecords: null
    }
  },
  created () {
    this.selectedDate = moment(new Date()).subtract(1, 'M').format('YYYY-MM')
    this.getAmountOfRecords()
  },
  methods: {
    getAmountOfRecords: _.debounce(async function () {
      var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}Stats/GetCountForMonth`, {
        params: { date: this.selectedDate }, showload: true
      })
      this.amountOfRecords = response.data
    }, 500),
    async downloadFile () {
      var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}Stats/GetMonthlyDownload`, {
        params: {
          date: new Date(this.selectedDate)
        },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/zip'
        },
        responseType: 'arraybuffer',
        showload: true
      })
      const blob = new Blob([new Uint8Array(response.data)], { type: 'application/zip' })
      saveAs(blob, `validate_${moment(this.selectedDate).format('YYYY_MM')}.zip`)
    }
  }
}
</script>
<style>
</style>
