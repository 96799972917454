<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Charge Detail</h2>
      </div>

      <div class="idb-block-content">
        <div class="form-group row">
          <label for="auditId" class="col-sm-2 text-right col-form-label">ID</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" v-model="billingRecord.id" />
          </div>
        </div>

        <div class="form-group row">
          <label for="auditCode" class="col-sm-2 text-right col-form-label">Type</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" v-model="billingRecord.type" />
          </div>
        </div>

        <div class="form-group row" v-if="billingRecord.description">
          <label for="auditCode" class="col-sm-2 text-right col-form-label">Description</label>
          <div class="col-sm-10">
            <span readonly class="form-control-plaintext">{{billingRecord.description}}</span>
          </div>
        </div>

        <div class="form-group row" v-if="showChargeSet">
          <label
            for="auditCode"
            class="col-sm-2 text-right col-form-label"
          >Charge Configuration Used</label>
          <div class="col-sm-10">
            <router-link
              :to="{ name:'HistoricChargeConfiguration', params:{ effectiveDate: billingRecord.processingDate}}"
            >Click here to view</router-link>
          </div>
        </div>

        <div class="form-group row" v-if="billingRecord.payerId">
          <label class="col-sm-2 text-right col-form-label">Customer</label>
          <div class="col-sm-10">
            <router-link
              :to="{ name: 'editPayer', params: { ukPayerId: billingRecord.payerId } }"
            >View Customer {{billingRecord.payerReference}}</router-link>
          </div>
        </div>

        <div class="form-group row" v-if="billingRecord.settlementId && isFinance">
          <label for="auditCode" class="col-sm-2 text-right col-form-label">Settlement Details</label>
          <div class="col-sm-10">
            <router-link
              :to="{ name:'SettlementReportDetails', params:{ id: billingRecord.settlementId}}"
            >Click here to view</router-link>
          </div>
        </div>

        <div class="form-group row" v-if="billingRecord.billingId && isFinance">
          <label for="auditCode" class="col-sm-2 text-right col-form-label">Billing Details</label>
          <div class="col-sm-10">
            <router-link
              :to="{ name:'BillingReportDetails', params:{ id: billingRecord.billingId}}"
            >Click here to view</router-link>
          </div>
        </div>

        <div class="form-group row" v-if="billingRecord.invoiceId && isAllowedToViewInvoice">
          <label for="auditCode" class="col-sm-2 text-right col-form-label">Invoice Details</label>
          <div class="col-sm-10">
            <router-link
              :to="{ name:'InvoiceDetails', params:{ id: billingRecord.invoiceId}}"
            >Click here to view</router-link>
          </div>
        </div>

        <div class="form-group row">
          <label for="auditType" class="col-sm-2 text-right col-form-label">Amount</label>
          <div class="col-sm-10">
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              :class="moneyClass(billingRecord.charge, true)"
              :value="moneyFormat(billingRecord.charge)"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="auditType" class="col-sm-2 text-right col-form-label">Service Charge</label>
          <div class="col-sm-10">
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              :class="moneyClass(billingRecord.serviceCharge, true)"
              :value="moneyFormat(billingRecord.serviceCharge)"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="auditType" class="col-sm-2 text-right col-form-label">VAT</label>
          <div class="col-sm-10">
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              :class="moneyClass(billingRecord.vatAmount, true)"
              :value="moneyFormat(billingRecord.vatAmount)"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="auditType" class="col-sm-2 text-right col-form-label">User</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" v-model="billingRecord.user" />
          </div>
        </div>

        <div class="form-group row">
          <label for="createdDate" class="col-sm-2 text-right col-form-label">Created Date</label>
          <div class="col-sm-10">
            <span
              type="text"
              readonly
              class="form-control-plaintext"
              id="createdDate"
            >{{billingRecord.createdDate|formatDate('DD/MM/YYYY')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label for="createdDate" class="col-sm-2 text-right col-form-label">Processing Date</label>
          <div class="col-sm-10">
            <span
              type="text"
              readonly
              class="form-control-plaintext"
              id="createdDate"
            >{{billingRecord.processingDate|formatDate('DD/MM/YYYY HH:mm')}}</span>
          </div>
        </div>
      </div>

      <div class="idb-block-footer">
        <button class="btn btn-primary" type="button" v-if="shouldShowBackButton" @click="back">Back</button>
      </div>
    </div>
    <div class="idb-block" v-if="notesAllowed">
      <div class="idb-block-title">
        <h2>Charge Notes</h2>
      </div>
      <div class="idb-block-content">
        <charge-notes :chargeId="this.id" :canLoad="true"></charge-notes>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'

// Mixins
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'

// Constants
import roles from '@/Assets/Constants/roles'

// Components
import ChargeNotes from '@/Components/Platform/Customer/Charges/ChargeNotes'

export default {
  name: 'ChargeDetail',
  props: {
    id: {
      type: String,
      required: true
    },
    showBackButton: {
      type: String,
      default: 'true'
    }
  },
  components: {
    ChargeNotes
  },
  mixins: [settlementMixin],
  computed: {
    isFinance () {
      return this.$store.getters.isInRole(roles.Finance)
    },
    isLZAdmin () {
      return this.$store.getters.isInRole(roles.LZAdmin)
    },
    notesAllowed () {
      return this.$store.getters.isInRoles([roles.Finance, roles.LZAdmin, roles.Implementations])
    },
    isAllowedToViewInvoice () {
      return this.$store.getters.isInRoles([roles.Finance, roles.Standard, roles.ClientAdmin, roles.Implementations])
    },
    showChargeSet () {
      return !this.billingRecord.isManual && moment().isAfter(moment(this.billingRecord.processingDate))
    },
    shouldShowBackButton () {
      return this.showBackButton === 'true'
    },
    ...mapGetters(['selectedCustomer'])

  },
  watch: {
    selectedCustomer () { this.$router.push({ name: 'Charges' }) }
  },
  data () {
    return {
      billingRecord: {
        id: null,
        type: null,
        processingDate: null,
        createdDate: null,
        user: null,
        serviceCharge: 0,
        isManual: true,
        charge: 0,
        vatAmount: 0,
        payerId: null,
        payerReference: null
      }
    }
  },
  mounted () {
    this.loadBillingUsageRecord()
  },
  methods: {
    async loadBillingUsageRecord () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Charge/${this.id}`, { showload: true, showerror: true, errormessage: 'Billing usage record failed to load' })
        this.billingRecord = response.data
      } catch { }
    }
  }
}
</script>
