<template>
  <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <form @submit.prevent="processValid()">
              <div class="idb-block-title">
                <h2>
                  <help-icon docPath="/paygate-collections/import-export/importpayer/" />Import Details
                </h2>
              </div>
              <div class="idb-block-content">
                <b-form-group :label-cols="4" class="col-sm-4" horizontal label="Import status">
                  <b-badge
                    :title="translateStatus(uploadHistory.uploadStatus)"
                    :variant="getSeverityClass(uploadHistory.uploadStatus)"
                  >{{ translateStatus(uploadHistory.uploadStatus) }}</b-badge>
                </b-form-group>
                <b-form-group
                  :label-cols="4"
                  class="col-sm-4"
                  horizontal
                  label="Valid rows"
                >{{ uploadHistory.validRows }}</b-form-group>
                <b-form-group
                  :label-cols="4"
                  class="col-sm-4"
                  horizontal
                  label="Invalid rows"
                >{{ uploadHistory.invalidRows }}</b-form-group>
                <b-form-group
                  :label-cols="4"
                  class="col-sm-4"
                  horizontal
                  label="Total rows"
                >{{ uploadHistory.totalRows }}</b-form-group>
                <b-form-group
                  :label-cols="4"
                  class="col-sm-4"
                  horizontal
                  label="Activation Date"
                  :class="uploadHistory.importedFileType === 0 ? 'd-block' : 'd-none'"
                >{{ formatDate(uploadHistory.activationDate) }}</b-form-group>
                <b-form-group
                  :label-cols="12"
                  class="col-sm-12"
                  label-horizontal
                  :label="truncatedMessagesLabel"
                >
                  <b-form-textarea
                    id="messagesTextArea"
                    v-model="messagesToDisplay"
                    placeholder="No validation errors found"
                    :rows="3"
                    :max-rows="6"
                    readonly
                  />
                </b-form-group>
                <b-form-group
                  :label-cols="12"
                  class="col-sm-12"
                  label-horizontal
                  :label="truncatedCsvLabel"
                >
                  <b-form-textarea
                    id="textarea1"
                    v-model="csvToDisplay"
                    placeholder="No validation errors found"
                    :rows="3"
                    :max-rows="6"
                    readonly
                  />
                </b-form-group>
              </div>
              <div class="idb-block-footer">
                <b-button
                  type="submit"
                  v-if="uploadHistory.uploadStatus !== 2 && uploadHistory.uploadStatus < 10"
                  :disabled="cannotProcessValid || isLoading"
                  variant="primary"
                >Process Valid Rows</b-button>
                <b-button
                  class="ml-2"
                  v-if="uploadHistory.invalidRows > 0"
                  @click="download"
                >Download Failed Rows</b-button>
                <b-button
                  class="ml-2"
                  variant="primary"
                  @click.prevent="removeAction"
                  v-if="actionId && actionId !== null"
                  :disabled="isLoading"
                >Clear Action</b-button>
                <b-button
                  v-if="uploadHistory.importedFileType !== 0 && uploadHistory.uploadStatus !== 10"
                  @click="undoUpload"
                  variant="danger"
                  :disabled="!uploadHistory.canCancel"
                  class="pull-right"
                >Undo Upload</b-button>
              </div>
            </form>
          </div>

          <div
            class="idb-block"
            :class="uploadHistory.importedFileType === 0 ? 'd-block' : 'd-none'"
          >
            <div class="idb-block-title">
              <h2>Imported Customers</h2>
            </div>
            <div class="idb-block-content">
              <vue-good-table
                mode="remote"
                ref="table"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                @on-row-click="onRowClick"
                :rows="rows"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :search-options="{
                                enabled: true
                                }"
                :isLoading.sync="isTableLoading"
                :paginationOptions="paginationOptions"
                styleClass="vgt-table striped bordered"
                :columns="columns"
                class="scroll-table"
              >
                <template slot="loadingContent">
                  <h1>Loading...</h1>
                </template>
                <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">No data available</div>
                </div>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'"
                  >
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span>
                  </button>
                  <b-button
                    @click.prevent="load"
                    :disabled="isLoading"
                    class
                    variant="link"
                    v-b-popover.hover.top.d500="'Refresh the data in the table'"
                  >
                    <i class="fa fa-sync pointer dimmedIcon"></i>
                  </b-button>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'status'">
                    <b-badge
                      pill
                      :variant="getBadgeClass(props.row.status)"
                    >{{props.formattedRow[props.column.field]}}</b-badge>
                  </span>
                  <span v-else-if="props.column.field === 'buttons'">
                    <b-button
                      v-if="props.row.plans.length === 1"
                      @click="goToSchedule(props.row.ukPayerId, props.row.plans[0].planId)"
                      variant="outline-secondary"
                    >
                      <i class="fa fa-calendar mr-2"></i>View Schedule
                    </b-button>
                    <b-dropdown v-if="props.row.plans.length > 1" variant="outline-secondary">
                      <!-- no-caret -->
                      <span slot="button-content">
                        <i class="fa fa-calendar mr-2"></i>View Schedule
                      </span>
                      <b-dropdown-item
                        v-for="(plan, index) in props.row.plans"
                        :key="index"
                        @click="goToSchedule(props.row.ukPayerId, plan.planId)"
                      >
                        {{plan.planName}}
                        <i
                          v-if="plan.planStatus === 2"
                          v-b-tooltip.hover
                          title="Suspended"
                          class="fa fa-exclamation-circle text-danger"
                        ></i>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                  <span v-else>{{props.formattedRow[props.column.field]}}</span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import FileSaver from 'file-saver'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import loading from '@/Assets/Mixins/LoadingMixin'
import { mapGetters } from 'vuex'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

const statusToText = (x) => {
  switch (x) {
    case -1:
      return 'Any'
    case 0:
      return 'Operational'
    case 1:
      return 'Suspended'
    case 2:
      return 'Alert'
    case 3:
      return 'Closed'
    case 4:
      return 'Incomplete'
    case 5:
      return 'Awaiting Activation'
    case 6:
      return 'Plan Suspended'
    case 7:
      return 'Deleted'
    case 8:
      return 'Lodgement Pending'
    default:
      return 'Unknown'
  }
}

const statusToBadgeVariant = (x) => {
  switch (x) {
    case 3:
      return 'secondary'
    case 2:
    case 6:
      return 'warning'
    case 1:
      return 'danger'
    case 0:
      return 'success'
    case 4:
    case 5:
    case 8:
      return 'info'
    default:
      return 'dark'
  }
}

export default {
  mixins: [loading, tableFilterMixin],
  props: {
    uploadId: String
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    csvToDisplay () {
      if (this.uploadHistory && this.uploadHistory.invalidRows > 100) {
        var msgArray = this.uploadHistory.invalidRowCsv.split('\n').slice(0, 100)
        return msgArray.join('\n')
      }
      return this.uploadHistory.invalidRowCsv
    },
    messagesToDisplay () {
      if (this.uploadHistory && this.uploadHistory.invalidRows > 100) {
        var rowArray = this.uploadHistory.messages.split('\n').slice(0, 100)
        return rowArray.join('\n')
      }
      return this.uploadHistory.messages
    },
    truncatedCsvLabel () {
      var addenda = ''
      if (this.uploadHistory && this.uploadHistory.invalidRows > 100) {
        addenda = ' (limited to 100 lines)'
      }
      return 'Failed CSV Rows' + addenda
    },
    truncatedMessagesLabel () {
      var addenda = ''
      if (this.uploadHistory && this.uploadHistory.invalidRows > 100) {
        addenda = ' (limited to 100 lines)'
      }
      return 'Validation Messages ' + addenda
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    cannotProcessValid () {
      return (this.uploadHistory.validRows === 0 && this.uploadHistory.modCheckFailRows === 0)
    }
  },
  data () {
    return {
      importUrl: '',
      confirmUrl: '',
      uploadHistory: {
        uploadedFileId: null,
        paygateId: null,
        fileName: '',
        originalFileName: '',
        uploadStatus: 23,
        messages: '',
        totalRows: 0,
        processedRows: 0,
        invalidRows: 0,
        groupId: '',
        mandateRequired: false,
        migrationRequired: false,
        fileFormat: 'csv',
        importType: 'Payer',
        group: null,
        importedPayers: null,
        uploadDate: '',
        validRows: 0,
        invalidRowCsv: '',
        canCancel: false,
        importedFileType: 0,
        modCheckFailRows: 0
      },
      actionId: null,
      uploadStatusConnection: null,
      uploadHubUrl: null,
      rows: [],
      serverParams: {
        sort: [{ field: 'reference', type: 'asc' }]
      },
      columns: [
        {
          label: 'id',
          field: 'ukPayerId',
          hidden: true
        },
        {
          label: 'Reference',
          field: 'reference'
        },
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: 'Customer Status',
          field: 'status',
          formatFn: statusToText,
          tdClass: 'text-center'
        },
        {
          label: 'Name',
          field: 'displayName'
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Mobile Number',
          field: 'mobile'
        }
      ],
      paginationOptions: {
        enabled: true,
        perPage: 10,
        setCurrentPage: 1,
        dropdownAllowAll: false,
        jumpFirstOrLast: true
      },
      urlSet: false
    }
  },
  async created () {
    window.addEventListener('beforeunload', this.stopSocketListener)
    this.uploadHubUrl = process.env.VUE_APP_DDMS_API_URL + 'hubs/uploaddetails?uploadid=' + this.uploadId
  },
  beforeRouteLeave (to, from, next) {
    this.uploadStatusConnection.stop()
    next()
  },
  async mounted () {
    this.importUrl = process.env.VUE_APP_DDMS_API_URL + 'import/' + this.uploadId
    this.confirmUrl = process.env.VUE_APP_DDMS_API_URL + 'import/' + this.uploadId + '/confirm'
    this.actionId = this.$route.query.actionId

    this.urlSet = true
    await this.load()
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        // this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
        this.uploadStatusConnection = new HubConnectionBuilder().withUrl(this.uploadHubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.uploadStatusConnection.serverTimeoutInMilliseconds = 3600000
        this.uploadStatusConnection.start()
        this.uploadStatusConnection.on('UpdateImportDetails', data => {
          this.uploadHistory = data
        })
      })
  },
  methods: {
    onRowClick (e) {
      this.$router.push({ name: 'editPayer', params: { ukPayerId: e.row.ukPayerId } })
    },
    formatDate (datestr) {
      const date = new Date(datestr)
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var h = date.getHours()
      var m = date.getMinutes()
      var s = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    async load () {
      if (this.urlSet) {
        try {
          const response = await axios.get(this.importUrl, {
            headers: { 'x-cust-meta': this.paygateId },
            params: { paygateId: this.paygateId },
            showload: true
          })
          if (response) {
            this.uploadHistory = response.data
          }
          if (response.data.importedFileType === 0) {
            const query = this.buildGoodTableQuery()
            const payerResponse = await axios.get(process.env.VUE_APP_DDMS_API_URL + 'import/' + this.uploadId + '/payers', {
              params: query,
              paygateId: this.paygateId
            })
            if (payerResponse) {
              this.rows = payerResponse.data.data
              this.totalRecords = payerResponse.data.count
            }
          }
        } catch (e) {
          this.$toastr.e('Could not load import details')
        }
      }
    },
    processValid () {
      axios.post(this.confirmUrl, {}, { headers: { 'x-cust-meta': this.paygateId }, params: { paygateId: this.paygateId }, showload: true }).then((response) => {
        this.$toastr.s('The valid rows will be imported into the live system.')
        this.$router.push({ path: '/collections/data/import/' })
      })
    },
    removeAction () {
      axios.delete(this.importUrl + '/clearaction', { headers: { 'x-cust-meta': this.paygateId }, params: { actionId: this.actionId, paygateId: this.paygateId }, showload: true }).then(
        (response) => {
          this.$toastr.s('Action cleared')
          this.actionId = null
          this.$router.push('/actions')
        }
      )
    },
    async undoUpload () {
      try {
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}import/undoupload/${this.uploadId}`, {})
        this.$toastr.s('Uploaded data removed from the system.')
        axios.get(this.importUrl, { headers: { 'x-cust-meta': this.paygateId }, params: { paygateId: this.paygateId }, showload: true }).then((response) => {
          this.uploadHistory = response.data
        })
      } catch (e) {
        console.log(e)
        this.$toastr.e('Could not undo the upload.')
      }
    },
    download () {
      const blob = new Blob([this.uploadHistory.invalidRowCsv], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      FileSaver.saveAs(blob, 'invalid-rows.csv')
      window.URL.revokeObjectURL(url)
    },
    deleteUpload () {
      axios.delete(this.importUrl, { headers: { 'x-cust-meta': this.paygateId }, params: { paygateId: this.paygateId }, showload: true }).then((response) => {
        this.$toastr.s('Record deleted, along with all staged data and uploaded files.')
        this.$router.push({ path: '/collections/data/import/' })
      })
    },
    translateStatus (status) {
      var statusStr = ''
      switch (status) {
        case 0:
          statusStr = 'Queued'
          break
        case 1:
          statusStr = 'Uploading'
          break
        case 2:
          statusStr = 'Complete'
          break
        case 3:
          statusStr = 'Error'
          break
        case 4:
          statusStr = 'Error'
          break
        case 5:
          statusStr = 'Pending'
          break
        case 6:
          statusStr = 'Processing'
          break
        case 7:
          statusStr = 'Preparing'
          break
        case 8:
          statusStr = 'Validating'
          break
        case 9:
          statusStr = 'Duplicate References'
          break
        case 10:
          statusStr = 'Deleted'
          break
        case 12:
          statusStr = 'Cancelled'
        case 13:
          statusStr = 'Uploaded'
          break
        case 14:
          statusStr = 'Processed'
          break
        case 23:
          statusStr = 'Loading...'
      }
      return statusStr
    },
    getSeverityClass (statusInt) {
      var buttonClass = ''
      switch (statusInt) {
        case 2: // Staging
        case 13:
        case 14:
          buttonClass = 'success'
          break
        case 4: // Error
        case 10:
        case 12:
          buttonClass = 'danger'
          break
        case 3: // Warning
        case 9:
          buttonClass = 'danger'
          break
        default:
          buttonClass = 'primary'
          break
      }
      return buttonClass
    },
    stopSocketListener () {
      if (this.uploadStatusConnection && this.uploadStatusConnection !== null) {
        this.uploadStatusConnection.stop()
      }
    },
    goToSchedule (payerId, planId) {
      this.$router.push({ name: 'PayerSchedule', params: { id: payerId, planId: planId } })
    },
    getBadgeClass (value) {
      return statusToBadgeVariant(value)
    }
  },
  watch: {
    selectedCustomer () {
      this.group = '00000000-0000-0000-0000-000000000000'
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      this.$router.go(-1)
    }
  }
}
</script>
