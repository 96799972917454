export default {
  AgentCreated: 'AgentCreated',
  AgentModified: 'AgentModified',
  AgentDeactivated: 'AgentDeactivated',
  AgentReactivated: 'AgentReactivated',
  ContactCreated: 'ContactCreated',
  ContactModified: 'ContactModified',
  ContactAssociationCreated: 'ContactAssociationCreated',
  ContactAssociationEdited: 'ContactAssociationEdited',
  ManualCommissionCreated: 'ManualCommissionCreated',
  StatementCreated: 'StatementCreated'
}
