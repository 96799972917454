const settlementType = {
  Net: 'Net',
  Gross: 'Gross'
}

const settlementStatus = {
  AwaitingSubmission: 'Awaiting Submission',
  Submitted: 'Submitted',
  Paid: 'Paid',
  Failed: 'Failed'

}

const paymentMethod = {
  BACS: 'BACS',
  Bankline: 'Bankline',
  ThirdPartySun: '3rd Party SUN'
}

export { settlementType, settlementStatus, paymentMethod }
