// The single source of truth for workflow node configuration
export function createWorkflowNodeProperties (name) {
  switch (name) {
    case 'csvConnector':
      return {
        id: '',
        name: name,
        title: 'CSV Mapping',
        type: 'csvMapping',
        area: 'mapping',
        props: {
          s1: { name: 'cronExpression', title: 'Cron Expression', value: '', element: 's1', isRequired: true },
          b1: { name: 'dontFireOnNonBacsDays', title: 'Don’t Fire On Non-Bacs Days', value: '', element: 'b1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 12,
          lLabels: ['Input'],
          rLabels: ['Out', 'Error'],
          headerBackColour: '#C99257',
          fontColour: '#ffffff',
          htmlLink: '/nodes/csvmapping',
          icon: '\uf017'
        }
      }
    case 'fixedLengthConnector':
      return {
        id: '',
        name: name,
        title: 'Fixed Length Mapping',
        type: 'fixedLengthMapping',
        area: 'mapping',
        props: {
          s1: { name: 'cronExpression', title: 'Cron Expression', value: '', element: 's1', isRequired: true },
          b1: { name: 'dontFireOnNonBacsDays', title: 'Don’t Fire On Non-Bacs Days', value: '', element: 'b1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 12,
          lLabels: ['Input'],
          rLabels: ['Out', 'Error'],
          headerBackColour: '#C99257',
          fontColour: '#ffffff',
          htmlLink: '/nodes/fixedlengthmapping',
          icon: '\uf017'
        }
      }
    case 'constant':
      return {
        id: '',
        name: name,
        title: 'Constant',
        type: 'mapping',
        area: 'stringOperators',
        props: {
          s1: { name: 'value', title: 'Value', value: '', element: 's1', isRequired: true }
        },
        nodeConfig: {
          n1: 0,
          n2: 1,
          lLabels: [],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/constant/',
          icon: '\uf069'
        },
        ui: {
          formData: {
            constantValue: ''
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Constant'
            },
            {
              fieldType: 'TextInput',
              placeholder: '',
              label: 'Constant Value',
              name: 's1',
              maxLength: 128,
              isRequired: true
            }
          ]
        }
      }
    case 'joiner':
      return {
        id: '',
        name: name,
        title: 'Joiner',
        type: 'mapping',
        area: 'stringOperators',
        props: {},
        nodeConfig: {
          n1: 2,
          n2: 1,
          lLabels: [],
          rLabels: ['Joined'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/joiner/',
          icon: '\uf079'
        },
        ui: {
          formData: {},
          schema: [
            {}
          ]
        }
      }
    case 'getLength':
      return {
        id: '',
        name: name,
        title: 'Get Length',
        type: 'mapping',
        area: 'stringOperators',
        props: {},
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Length'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/getlength/',
          icon: '\uf035'
        },
        ui: {
          formData: {},
          schema: [{}]
        }
      }
    case 'moneyFormatter':
      return {
        id: '',
        name: name,
        title: '£ Formatter',
        type: 'mapping',
        area: 'stringOperators',
        props: {
          s1: { name: 'symbol', title: 'Currency symbol', value: '', element: 's1', isRequired: false },
          s2: { name: 'decimal', title: 'Decimal', value: '.', element: 's2', isRequired: false },
          s3: { name: 'thousand', title: 'Thousand', value: '', element: 's3', isRequired: false },
          int1: { name: 'precision', title: 'Precision', value: '2', element: 'int1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/moneyformatter/',
          icon: '\uf042'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'TextInput',
              placeholder: '',
              label: 'Symbol',
              name: 's1',
              maxLength: 1,
              isRequired: false
            },
            {
              fieldType: 'TextInput',
              placeholder: '',
              label: 'Decimal',
              name: 's2',
              maxLength: 1,
              isRequired: false
            },
            {
              fieldType: 'TextInput',
              placeholder: '',
              label: 'Thousand',
              name: 's3',
              maxLength: 1,
              isRequired: false
            },
            {
              fieldType: 'NumberInput',
              name: 'int1',
              label: 'Precision',
              minValue: 0,
              maxValue: 100
            }
          ]
        }
      }
    case 'replace':
      return {
        id: '',
        name: name,
        title: 'Replace',
        type: 'mapping',
        area: 'stringOperators',
        props: {
          s1: { name: 'oldString', title: 'Old String', value: '', element: 's1', isRequired: true },
          s2: { name: 'newString', title: 'New String', value: '', element: 's2', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/replace/',
          icon: '\uf24d'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Replace'
            },
            {
              fieldType: 'TextInput',
              placeholder: '',
              label: 'Old String',
              name: 's1',
              maxLength: 128,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              placeholder: '',
              label: 'New String',
              name: 's2',
              maxLength: 128,
              isRequired: false
            }
          ]
        }
      }
    case 'toUpperCase':
      return {
        id: '',
        name: name,
        title: 'To Uppercase',
        type: 'mapping',
        area: 'stringOperators',
        props: {},
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/touppercase/',
          icon: '\uf15e'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {}
          ]
        }
      }
    case 'toLowerCase':
      return {
        id: '',
        name: name,
        title: 'To LowerCase',
        type: 'mapping',
        area: 'stringOperators',
        props: {},
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/tolowercase/',
          icon: '\uf15d'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {}
          ]
        }
      }
    case 'capitalise':
      return {
        id: '',
        name: name,
        title: 'Capitalise',
        type: 'mapping',
        area: 'stringOperators',
        props: {},
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/capitalise/',
          icon: '\uf031'
        },
        ui: {
          formData: {
            firstName: 'Doge'
          },
          schema: [
            {}
          ]
        }
      }
    case 'padLeft':
      return {
        id: '',
        name: name,
        title: 'Pad Left',
        type: 'mapping',
        area: 'stringOperators',
        props: {
          s1: { name: 'padCharacter', title: 'Pad Character', value: ' ', element: 's1', isRequired: true },
          int1: { name: 'padLength', title: 'Length', value: '18', element: 'int1', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/padleft/',
          icon: '\uf104'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Length'
            },
            {
              fieldType: 'TextInput',
              placeholder: '',
              label: 'Pad Character',
              name: 's1',
              maxLength: 128,
              isRequired: true
            },
            {
              fieldType: 'NumberInput',
              name: 'int1',
              label: 'Length',
              minValue: 1
            }
          ]
        }
      }
    case 'padRight':
      return {
        id: '',
        name: name,
        title: 'Pad Right',
        type: 'mapping',
        area: 'stringOperators',
        props: {
          s1: { name: 'padCharacter', title: 'Pad Character', value: '', element: 's1', isRequired: true },
          int1: { name: 'padLength', title: 'Length', value: '', element: 'int1', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/padright/',
          icon: '\uf105'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Length'
            },
            {
              fieldType: 'TextInput',
              placeholder: '',
              label: 'Pad Character',
              name: 's1',
              maxLength: 128,
              isRequired: true
            },
            {
              fieldType: 'NumberInput',
              name: 'int1',
              label: 'Length',
              minValue: 1
            }
          ]
        }
      }
    case 'trim':
      return {
        id: '',
        name: name,
        title: 'Trim',
        type: 'mapping',
        area: 'stringOperators',
        props: {},
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/trim/',
          icon: '\uf0c4'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {}
          ]
        }
      }
    case 'trimLeft':
      return {
        id: '',
        name: name,
        title: 'Trim Left',
        type: 'mapping',
        area: 'stringOperators',
        props: {},
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/trimeleft/',
          icon: '\uf0c4'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {}
          ]
        }
      }
    case 'trimRight':
      return {
        id: '',
        name: name,
        title: 'Trim Right',
        type: 'mapping',
        area: 'stringOperators',
        props: {},
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/trimeright/',
          icon: '\uf0c4'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {}
          ]
        }
      }
    case 'subStringLeft':
      return {
        id: '',
        name: name,
        title: 'Sub-String L',
        type: 'mapping',
        area: 'stringOperators',
        props: {
          int1: { name: 'characterPosition', title: 'Character Position', value: '', element: 'int1', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/substringleft/',
          icon: '\uf036'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Character Position'
            },
            {
              fieldType: 'NumberInput',
              name: 'int1',
              label: 'Length',
              minValue: 0,
              maxValue: 1024
            }
          ]
        }
      }
    case 'subStringRight':
      return {
        id: '',
        name: name,
        title: 'Sub-String R',
        type: 'mapping',
        area: 'stringOperators',
        props: {
          int1: { name: 'characterPosition', title: 'Character Position', value: '', element: 'int1', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/substringright/',
          icon: '\uf038'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Character Position'
            },
            {
              fieldType: 'NumberInput',
              name: 'int1',
              label: 'Start Position',
              minValue: 0,
              maxValue: 1024
            }
          ]
        }
      }
    case 'subString':
      return {
        id: '',
        name: name,
        title: 'Sub-String',
        type: 'mapping',
        area: 'stringOperators',
        props: {
          int1: { name: 'characterPosition', title: 'Character Position', value: '', element: 'int1', isRequired: true },
          int2: { name: 'length', title: 'Length', value: '', element: 'int2', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/substring/',
          icon: '\uf039'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Character Position'
            },
            {
              fieldType: 'NumberInput',
              name: 'int1',
              label: 'Start Position',
              minValue: 0,
              maxValue: 1024
            },
            {
              fieldType: 'NumberInput',
              name: 'int2',
              label: 'Length',
              minValue: 1,
              maxValue: 1024
            }
          ]
        }
      }
    case 'splice':
      return {
        id: '',
        name: name,
        title: 'Splice',
        type: 'mapping',
        area: 'stringOperators',
        props: {
          int1: { name: 'startPosition', title: 'Start Position', value: '', element: 'int1', isRequired: true },
          int2: { name: 'endPosition', title: 'End Position', value: '', element: 'int2', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/moneyformatter/subString/',
          icon: '\uf0c4'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Character Position'
            },
            {
              fieldType: 'NumberInput',
              name: 'int1',
              label: 'Start Position',
              minValue: 0,
              maxValue: 1024
            },
            {
              fieldType: 'NumberInput',
              name: 'int2',
              label: 'End Position',
              minValue: 1,
              maxValue: 1024
            }
          ]
        }
      }
    case 'deburr':
      return {
        id: '',
        name: name,
        title: 'Deburr',
        type: 'mapping',
        area: 'stringOperators',
        props: {},
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/deburr/',
          icon: '\uf12d'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {}
          ]
        }
      }
    case 'substitute':
      return {
        id: '',
        name: name,
        title: 'Substitute',
        type: 'mapping',
        area: 'stringOperators',
        props: {
          s1: { name: 'characterSet', title: 'Character Set', value: 'BACS', element: 's1', isRequired: false },
          s2: { name: 'substitutionCharacter', title: 'Substitution Character', value: '', element: 's2', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/substitute/',
          icon: '\uf065'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Character Set'
            },
            {
              fieldType: 'RadioButton',
              labels: ['BACS'],
              name: 's1',
              selectedValue: 'BACS'
            },
            {
              fieldType: 'TextInput',
              label: 'Substition Character',
              name: 's2',
              maxLength: 1,
              isRequired: false
            }
          ]
        }
      }
    case 'guid':
      return {
        id: '',
        name: name,
        title: 'GUID',
        type: 'mapping',
        area: 'stringOperators',
        props: {},
        nodeConfig: {
          n1: 0,
          n2: 1,
          rLabels: ['Output'],
          headerBackColour: '#6F3F81',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/stringoperators/guid/',
          icon: '\uf2c2'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: []
        }
      }
    case 'ceiling':
      return {
        id: '',
        name: name,
        title: 'Ceiling',
        type: 'mapping',
        area: 'numberOperators',
        props: {},
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#3E8E72',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/numberoperators/ceiling/',
          icon: '\uf292'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: []
        }
      }
    case 'floor':
      return {
        id: '',
        name: name,
        title: 'Floor',
        type: 'mapping',
        area: 'numberOperators',
        props: {},
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#3E8E72',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/numberoperators/floor/',
          icon: '\uf292'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: []
        }
      }
    case 'maximum':
      return {
        id: '',
        name: name,
        title: 'Maximum',
        type: 'mapping',
        area: 'numberOperators',
        props: {},
        nodeConfig: {
          n1: 2,
          n2: 1,
          lLabels: ['Input 1', 'Inpout 2'],
          rLabels: ['Output'],
          headerBackColour: '#3E8E72',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/numberoperators/maximum/',
          icon: '\uf292'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: []
        }
      }
    case 'minimum':
      return {
        id: '',
        name: name,
        title: 'Minimum',
        type: 'mapping',
        area: 'numberOperators',
        props: {},
        nodeConfig: {
          n1: 2,
          n2: 1,
          lLabels: ['Input 1', 'Inpout 2'],
          rLabels: ['Output'],
          headerBackColour: '#3E8E72',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/numberoperators/minimum/',
          icon: '\uf292'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: []
        }
      }
    case 'modulus':
      return {
        id: '',
        name: name,
        title: 'Modulus',
        type: 'mapping',
        area: 'numberOperators',
        props: {},
        nodeConfig: {
          n1: 2,
          n2: 1,
          lLabels: ['Dividend', 'Divisor'],
          rLabels: ['Out'],
          headerBackColour: '#3E8E72',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/numberoperators/modulus/',
          icon: '\uf292'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: []
        }
      }
    case 'multiply':
      return {
        id: '',
        name: name,
        title: 'Multiply',
        type: 'mapping',
        area: 'numberOperators',
        props: {},
        nodeConfig: {
          n1: 2,
          n2: 1,
          lLabels: ['Input 1', 'Input 2'],
          rLabels: ['Out'],
          headerBackColour: '#3E8E72',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/numberoperators/multiply/',
          icon: '\uf00d'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: []
        }
      }
    case 'divide':
      return {
        id: '',
        name,
        title: 'Divide',
        type: 'mapping',
        area: 'numberOperators',
        props: {},
        nodeConfig: {
          n1: 2,
          n2: 1,
          lLabels: ['Input 1', 'Input 2'],
          rLabels: ['Out'],
          headerBackColour: '#3E8E72',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/numberoperators/divide/',
          icon: '\uf529'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: []
        }
      }
    case 'sum':
      return {
        id: '',
        name: name,
        title: 'Sum',
        type: 'mapping',
        area: 'numberOperators',
        props: {},
        nodeConfig: {
          n1: 2,
          n2: 1,
          lLabels: ['Input 1', 'Input 2'],
          rLabels: ['Out'],
          headerBackColour: '#3E8E72',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/numberoperators/sum/',
          icon: '\uf067'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: []
        }
      }
    case 'subtract':
      return {
        id: '',
        name: name,
        title: 'Subtract',
        type: 'mapping',
        area: 'numberOperators',
        props: {},
        nodeConfig: {
          n1: 2,
          n2: 1,
          lLabels: ['Input 1', 'Input 2'],
          rLabels: ['Out'],
          headerBackColour: '#3E8E72',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/numberoperators/subtract/',
          icon: '\uf068'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: []
        }
      }
    case 'dateTime':
      return {
        id: '',
        name: name,
        title: 'Date / Time',
        type: 'mapping',
        area: 'dateTimeOperators',
        props: {
          s1: { name: 'template', title: 'Template', value: 'DD/MM/YYYY', element: 's1', isRequired: true },
          int1: { name: 'offset', title: 'Offset', value: '0', element: 'int1', isRequired: true }
        },
        nodeConfig: {
          n1: 0,
          n2: 1,
          rLabels: ['Out'],
          headerBackColour: '#984D43',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/dateoperators/datetime/',
          icon: '\uf133'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Length'
            },
            {
              fieldType: 'TextInput',
              label: 'Date / Time Template',
              name: 's1',
              maxLength: 128,
              isRequired: true
            },
            {
              fieldType: 'NumberInput',
              name: 'int1',
              label: 'Day Offset',
              minValue: -365,
              maxValue: 365
            }
          ]
        }
      }
    case 'reformatDate':
      return {
        id: '',
        name: name,
        title: 'Format Date',
        type: 'mapping',
        area: 'dateTimeOperators',
        props: {
          s1: { name: 'startFormat', title: 'Start Format', value: '', element: 's1', isRequired: true },
          s2: { name: 'endFormat', title: 'End Format', value: '', element: 's2', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 1,
          lLabels: ['Input'],
          rLabels: ['Output'],
          headerBackColour: '#984D43',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/dateoperators/formatdate/',
          icon: '\uf133'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'TextInput',
              label: 'Start Format',
              name: 's1',
              maxLength: 128,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'End Format',
              name: 's2',
              maxLength: 128,
              isRequired: true
            }
          ]
        }
      }
    case 'bankAccount':
      return {
        id: '',
        name: name,
        title: 'Account',
        type: 'mapping',
        area: 'dataOperators',
        props: {
          s1: { name: 'bank', title: 'Bank Account', value: '', element: 's1', isRequired: true }
        },
        nodeConfig: {
          n1: 0,
          n2: 16,
          rLabels: [
            'Sortcode',
            'Account Number',
            'BIC',
            'IBAN',
            'Bank Name',
            'Reference',
            'Acc Num 3',
            'Acc Num 4',
            'Intermediate',
            'Default Currency',
            'Bank Addr 1',
            'Bank Addr 2',
            'Bank Addr 3',
            'Bank Addr 4',
            'Bank Addr 5',
            'Bank Postcode'
          ],
          headerBackColour: '#008000',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/dataoperators/bankaccount/',
          icon: '\uf19c'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'BankAccount',
              name: 's1',
              label: 'Bank Account'
            }
          ]
        }
      }
    case 'groupDetails':
      return {
        id: '',
        name: name,
        title: 'Group',
        type: 'mapping',
        area: 'dataOperators',
        props: {
          s1: { name: 'group', title: 'Group', value: '', element: 's1', isRequired: true }
        },
        nodeConfig: {
          n1: 0,
          n2: 3,
          rLabels: [
            'Group Id',
            'Name',
            'Type'
          ],
          headerBackColour: '#008000',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/dataoperators/groupdetails/',
          icon: '\uf0c0'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'GroupDetails',
              name: 's1',
              label: 'Group'
            }
          ]
        }
      }
    case 'bureauCustomer':
      return {
        id: '',
        name: name,
        title: 'Bureau Customer',
        type: 'mapping',
        area: 'dataOperators',
        props: {
          s1: { name: 'selectedBureauCustomer', title: 'Bureau Customer', value: '', element: 's1', isRequired: true }
        },
        nodeConfig: {
          n1: 0,
          n2: 27,
          rLabels: [
            'ID',
            'Reference',
            'Name',
            'SUN',
            'Sortcode',
            'Account Number',
            'Contact First Name',
            'Contact Surname',
            'Address 1',
            'Address 2',
            'Address 3',
            'Address 4',
            'Postcode',
            'Telephone 1',
            'Telephone 2',
            'Website URL',
            'Email',
            'Payment Limit',
            'Submission Limit',
            'Contra Narrative',
            'Bank Name',
            'Bank Address 1',
            'Bank Address 2',
            'Bank Address 3',
            'Bank Address 4',
            'Bank Postcode',
            'Default Filename'
          ],
          headerBackColour: '#008000',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/dataoperators/bureaucustomer/',
          icon: '\uf007'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'BureauCustomer',
              name: 's1',
              label: 'Bureau Customer'
            }
          ]
        }
      }
  }
}

export function createPaymentConnectorProperties (name) {
  switch (name) {
    case 'BACSDEST':
      return {
        id: '',
        name: name,
        title: 'BACS Destination',
        type: 'mapping',
        area: 'connectors',
        direction: 'destination',
        props: {},
        nodeConfig: {
          n1: 33,
          n2: 0,
          lLabels: [
            'Third-party Account Name',
            'Third-party Sortcode',
            'Third-party Account Number',
            'Third-party Account Type',
            'Originating Account Name',
            'Originating Sortcode',
            'Originating Account Number',
            'Service User Reference',
            'Service User Number',
            'Numeric Reference / RTI',
            'Transaction Code',
            'Amount',
            'Date',
            'Record Id',
            'Reserved',
            'Message',
            'File Number',
            'Field 01',
            'Field 02',
            'Field 03',
            'Field 04',
            'Field 05',
            'Field 06',
            'Field 07',
            'Field 08',
            'Field 09',
            'Field 10',
            'Field 11',
            'Field 12',
            'Field 13',
            'Field 14',
            'Field 15',
            'Field 16'
          ],
          rLabels: ['Output'],
          headerBackColour: '#800000',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/paymentconnectors/bacsfpsconnector/',
          icon: '\uf1e6'
        },
        ui: {
          formData: {
            constantValue: ''
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'BACS Payment Data Destination'
            }
          ]
        }
      }
    case 'UKDDMSVAR':
      return {
        id: '',
        name: name,
        title: 'Variable Update',
        type: 'mapping',
        area: 'connectors',
        direction: 'destination',
        props: {},
        nodeConfig: {
          n1: 4,
          n2: 0,
          lLabels: [
            'Payer Reference',
            'Amount',
            'Plan Reference',
            'Groupid'
          ],
          rLabels: ['Output'],
          headerBackColour: '#800000',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/paymentconnectors/bacsfpsconnector/', // TODO: Update
          icon: '\uf1e6'
        },
        ui: {
          formData: {
            constantValue: ''
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Variable Update'
            }
          ]
        }
      }
    case 'UKDDMSPAYERIMPORT':
      return {
        id: '',
        name: name,
        title: 'Payer Import',
        type: 'mapping',
        area: 'connectors',
        direction: 'destination',
        props: {},
        nodeConfig: {
          n1: 35,
          n2: 0,
          lLabels: [
            'Group ID',
            'Sortcode',
            'Account Number',
            'Account Holder\'s Name',
            'Status',
            'Status Reason',
            'Title',
            'First Name',
            'Surname',
            'Reference',
            'Address 1',
            'Address 2',
            'Address 3',
            'Address 4',
            'Postcode',
            'Email',
            'Tel 1',
            'Tel 2',
            'Mobile',
            'Custom Field 1',
            'Custom Field 2',
            'Custom Field 3',
            'Custom Field 4',
            'Custom Field 5',
            'First Amount',
            'Last Amount',
            'Regular Amount',
            'Giftaid',
            'First Payment Date',
            'Ordinal',
            'Step',
            'Recurrence Type',
            'Recurrence End Type',
            'Until Date',
            'Total Payments'
          ],
          rLabels: ['Output'],
          headerBackColour: '#800000',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/paymentconnectors/bacsfpsconnector/', // TODO: Update
          icon: '\uf1e6'
        },
        ui: {
          formData: {
            constantValue: ''
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Variable Update'
            }
          ]
        }
      }
    case 'UKDDMSPLANIMPORT':
      return {
        id: '',
        name: name,
        title: 'Plan Import',
        type: 'mapping',
        area: 'connectors',
        direction: 'destination',
        props: {},
        nodeConfig: {
          n1: 20,
          n2: 0,
          lLabels: [
            'Payer Core Reference',
            'Recurrence Type',
            'End Type',
            'Ordinal',
            'Step',
            'First Amount',
            'Regular Amount',
            'Final Amount',
            'First Collection Date',
            'Collection Count',
            'Final Date',
            'Plan Reference Override',
            'Group Id',
            'Variable',
            'UK Payer Id',
            'Custom Field 1',
            'Custom Field 2',
            'Custom Field 3',
            'Custom Field 4',
            'Custom Field 5'
          ],
          rLabels: ['Output'],
          headerBackColour: '#800000',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/paymentconnectors/bacsfpsconnector/', // TODO: Update
          icon: '\uf1e6'
        },
        ui: {
          formData: {
            constantValue: ''
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Plan Import'
            }
          ]
        }
      }
    case 'UKDDMSCOLLECTIONSIMPORT':
      return {
        id: '',
        name: name,
        title: 'Collections Import',
        type: 'mapping',
        area: 'connectors',
        direction: 'destination',
        props: {},
        nodeConfig: {
          n1: 11,
          n2: 0,
          lLabels: [
            'Plan Reference',
            'Collection Date',
            'Processing Date',
            'Amount',
            'Group Id',
            'Plan Id',
            'Custom Field 1',
            'Custom Field 2',
            'Custom Field 3',
            'Custom Field 4',
            'Custom Field 5'
          ],
          rLabels: ['Output'],
          headerBackColour: '#800000',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/paymentconnectors/bacsfpsconnector/', // TODO: Update
          icon: '\uf1e6'
        },
        ui: {
          formData: {
            constantValue: ''
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Collections Import'
            }
          ]
        }
      }
    case 'BACSSRC':
      return {
        id: '',
        name: name,
        title: 'BACS Source',
        type: 'mapping',
        area: 'connectors',
        direction: 'source',
        props: {
        },
        nodeConfig: {
          n1: 0,
          n2: 33,
          lLabels: [],
          rLabels: [
            'Third-party Account Name',
            'Third-party Sortcode',
            'Third-party Account Number',
            'Third-party Account Type',
            'Originating Account Name',
            'Originating Sortcode',
            'Originating Account Number',
            'Service User Reference',
            'Service User Number',
            'Numeric Reference / RTI',
            'Transaction Code',
            'Amount',
            'Date',
            'Record Id',
            'Reserved',
            'Message',
            'File Number',
            'Field 01',
            'Field 02',
            'Field 03',
            'Field 04',
            'Field 05',
            'Field 06',
            'Field 07',
            'Field 08',
            'Field 09',
            'Field 10',
            'Field 11',
            'Field 12',
            'Field 13',
            'Field 14',
            'Field 15',
            'Field 16'
          ],
          headerBackColour: '#800000',
          fontColour: '#ffffff',
          htmlLink: '/mapping/nodes/connectors/bacssrc',
          icon: '\uf1e6'
        },
        ui: {
          formData: {
            constantValue: ''
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'BACS Payment Data Source'
            }
          ]
        }
      }
    case 'BACSBURCUSTDEST':
      return {
        id: '',
        name: name,
        title: 'BACS Bureau Customer',
        type: 'mapping',
        area: 'connectors',
        direction: 'destination',
        props: {},
        nodeConfig: {
          n1: 29,
          n2: 0,
          lLabels: [
            'Company Name',
            'Contact First Name',
            'Contact Surname',
            'Address 1',
            'Address 2',
            'Address 3',
            'Address 4',
            'Address 5',
            'Postcode',
            'Telephone 1',
            'Telephone 2',
            'Website URL',
            'Email',
            'SUN',
            'Payment Limit',
            'Submission Limit',
            'Contra Narrative',
            'Bank Name',
            'Bank Address 1',
            'Bank Address 2',
            'Bank Address 3',
            'Bank Address 4',
            'Bank Address 5',
            'Bank Postcode',
            'Sortcode',
            'Account Number',
            'Default Import Schema',
            'Default Import Mapping',
            'Default Filename'
          ],
          rLabels: ['Output'],
          headerBackColour: '#800000',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/paymentconnectors/bacsbureaucustomer/',
          icon: '\uf234'
        },
        ui: {
          formData: {
            constantValue: ''
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'BACS Bureau Customer Destination'
            }
          ]
        }
      }
    case 'BACSBURCUSTSRC':
      return {
        id: '',
        name: name,
        title: 'BACS Bureau Customer',
        type: 'mapping',
        area: 'connectors',
        direction: 'source',
        props: {},
        nodeConfig: {
          n1: 0,
          n2: 29,
          lLabels: ['Output'],
          rLabels: [
            'Company Name',
            'Contact First Name',
            'Contact Surname',
            'Address 1',
            'Address 2',
            'Address 3',
            'Address 4',
            'Address 5',
            'Postcode',
            'Telephone 1',
            'Telephone 2',
            'Website URL',
            'Email',
            'SUN',
            'Payment Limit',
            'Submission Limit',
            'Contra Narrative',
            'Bank Name',
            'Bank Address 1',
            'Bank Address 2',
            'Bank Address 3',
            'Bank Address 4',
            'Bank Address 5',
            'Bank Postcode',
            'Sort code',
            'Account Number',
            'Default Import Schema',
            'Default Import Mapping',
            'Default Filename'
          ],
          headerBackColour: '#800000',
          fontColour: '#ffffff',
          htmlLink: '/mapping/nodes/connectors/bacsburcustsrc',
          icon: '\uf234'
        },
        ui: {
          formData: {
            constantValue: ''
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'BACS Bureau Customer Source'
            }
          ]
        }
      }
    // case 'UKDDMSVAR':
    //   return {
    //     id: '',
    //     name: name,
    //     title: 'Variable DD Collection',
    //     type: 'mapping',
    //     area: 'connectors',
    //     direction: 'destination',
    //     props: {},
    //     nodeConfig: {
    //       n1: 4,
    //       n2: 0,
    //       lLabels: [
    //         'Payer Reference',
    //         'Amount',
    //         'Plan Name',
    //         'Group ID'
    //       ],
    //       rLabels: ['Output'],
    //       headerBackColour: '#800000',
    //       fontColour: '#ffffff',
    //       htmlLink: '/automation/paygate-mapping/mapping-nodes/operators/paymentconnectors/variableddcollections/',
    //       icon: '\uf1e6'
    //     },
    //     ui: {
    //       formData: {
    //         constantValue: ''
    //       },
    //       schema: [
    //         {
    //           fieldType: 'Label',
    //           label: 'Variable Direct Debit Collections Destination'
    //         }
    //       ]
    //     }
    //   }
  }
}
