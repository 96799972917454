<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline Payment Row Details
            <span class="pull-right">
              <a :href="`${docUrl}/automation/logging/`" target="_blank"><i class="far fa-question-circle"></i></a>
            </span>
          </h2>
        </div>
        <div class="idb-block-content">
          <div><strong>Debit Account</strong></div>
          <br/>
          <!-- Description -->
          <div class="form-group row">
            <div class="col-md-4">Description</div>
            <div class="col-md-3">
              {{banklinePayment.description}}
            </div>
          </div>
          <br/>
          <div class="form-group row">
            <div class="col-md-4">Payment Type</div>
            <div class="col-md-3">
              <span v-if="banklinePayment.recordType === '01'">
                STD
              </span>
              <span v-else-if="banklinePayment.recordType === '02'">
                URG (CHAPS)
              </span>
            </div>
          </div>
          <!-- Customer Payment Reference -->
          <div class="form-group row">
            <div class="col-md-4">Payment ID</div>
            <div class="col-md-3">
              {{banklinePayment.customerPaymentReference}}
            </div>
          </div>
          <!-- Bank Account -->
          <div class="form-group row">
            <div class="col-md-4">Sortcode</div>
            <div class="col-md-3">
              {{reformatSortcode(banklinePayment.debitAccountIdentifier)}}
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">Bank Account Number</div>
            <div class="col-md-3">
              {{reformatAccountNumber(banklinePayment.debitAccountIdentifier)}}
            </div>
          </div>
          <!-- Payment Amount -->
          <div class="form-group row">
            <div class="col-md-4 col-sm-12">
              <label>Payment Amount</label>
            </div>
            <div class="col-md-2 col-sm-12">
              <div class="input-group">
                {{reformatCurrency(banklinePayment.pa)}}
              </div>
            </div>
          </div>
          <!-- Date -->
          <div class="form-group row">
            <div class="col-md-4 col-sm-12">
              <label v-b-popover.hover.top.d500="'The date that the money should be in the beneficiaries account.'" >Arrival Date</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="input-group">
                <div class="right-inner-addon">
                  {{reformatDate2(banklinePayment.arrivalDate)}}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4 col-sm-12">
              <label v-b-popover.hover.top.d500="'The date that the payment was created.'" >Creation Date</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="input-group">
                <div class="right-inner-addon">
                  {{reformatDate(banklinePayment.creationDate)}}
                </div>
              </div>
            </div>
          </div>
          <br/>
          <br/>
          <div><strong>Beneficiary</strong></div>
          <br>
          <div class="form-group row">
            <div class="col-md-4">Charge To</div>
            <div class="col-md-4">
              {{selectedCustomerName}}
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">Beneficiary Sortcode</div>
            <div class="col-md-4">
              {{reformatSortcode2(banklinePayment.accountWithBankIdentifier)}}
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">Beneficiary Account Number</div>
            <div class="col-md-2">
              {{banklinePayment.beneficiaryAccountNumber}}
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">Beneficiary Reference</div>
            <div class="col-md-2">
              {{banklinePayment.beneficiaryReference}}
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">Beneficiary Name</div>
            <div class="col-md-3">
              {{banklinePayment.beneficiaryNameAndAddressLineNumber1}}
            </div>
          </div>
          <div class="form-group row" v-if="banklinePayment.recordType === '02'">
            <div class="col-md-4">Information for the Beneficiary</div>
            <div class="col-md-3">
              {{banklinePayment.informationForTheBeneficiaryLineNumber1}}
            </div>
          </div>
          <br/>
          <br/>
        </div>
      </div>
      <div class="idb-block" v-if="1===2" >
        <div class="idb-block-title">
          <h2>Reconciled Event</h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-1 ">
              Matched item
            </div>
            <div class="col-3">
              <!-- <span v-if="fileData.banklineAstRow.matchObj.success"><router-link to='/collections/indemnity/6b808055-c0f7-45d5-aa4b-a15271ac0b97' >{{fileData.banklineAstRow.matchObj.criteria}}</router-link></span>
              <span v-else>None</span> -->
            </div>
            <div class="col-1 offset-1">
              Match Date
            </div>
            <div class="col-3">
              <!-- {{reformatDate(fileData.banklineAstRow.matchObj.dateTime)}} -->
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-1 ">
              Match Status
            </div>
            <div class="col-3">
              <!-- <span v-if="fileData.banklineAstRow.matchObj.success">Success</span>
              <span v-else class="text-danger">Unsuccessful</span> -->
            </div>
            <div class="col-1 offset-1">
              Match Rule
            </div>
            <div class="col-3">
              <!-- {{fileData.banklineAstRow.matchObj.rule}} -->
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-1 ">
              Commentary
            </div>
            <div class="col-4">
              <!-- <span  v-if="fileData.banklineAstRow.matchObj.success">{{fileData.banklineAstRow.matchObj.commentary}}</span>
              <span class="text-warning" v-else>{{fileData.banklineAstRow.matchObj.commentary}}</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Parent Bankline File<span class="ml-2"><router-link :to="{ path: '/admin/bandr/bankline/banklinepaymentfile/'+ this.banklineFile.id}">View</router-link></span></h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-3 ">
              <strong><small>Filename</small></strong>
            </div>
            <div class="col-3">
              <strong><small>Creation Date</small></strong>
            </div>
            <div class="col-3 ">
              <strong><small>Origin</small></strong>
            </div>
            <div class="col-3 ">
              <strong><small>Status</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              {{banklineFile.originalFilename}}
            </div>
            <div class="col-3">
              {{reformatDate(banklineFile.creationDate)}}
            </div>
            <div class="col-3">
              <b-badge pill v-if = "banklineFile.description == 'upload'" variant="success">Uploaded file from Bankline</b-badge>
              <b-badge pill v-else-if = "banklineFile.description == 'outbound'" variant="success">Generated by JFTP</b-badge>
              <b-badge pill v-else-if = "banklineFile.description == 'payment'" variant="info">Payment</b-badge>
            </div>
            <div class="col-3">
              <b-badge pill v-if = "banklineFile.status == '11'" class="mr-1" variant="warning">Not Downloaded</b-badge>
              <b-badge pill v-else-if = "banklineFile.status == '12'" class="mr-1" variant="success">Downloaded</b-badge>
              <b-badge pill v-else-if = "banklineFile.status == '98'" class="mr-1" variant="info">Sent to Bankline</b-badge>
              <b-badge pill v-else-if = "banklineFile.status == '99'" class="mr-1" variant="danger">Error</b-badge>
              <b-badge pill v-if = "banklineFile.archive == true" class="mr-1" variant="info">Sent to Bankline</b-badge>
              <div class="tinyText mt-2" v-if = "banklineFile.status == '12'">Downloaded: {{reformatDate(banklineFile.downloadDate)}}</div>
              <div class="tinyText mt-2" v-if = "banklineFile.archive == true">Sent to Bankline: {{reformatDate(banklineFile.archiveDate)}}</div>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div class="btn-group mr-2" role="group" v-if="isEditable==true && banklineFile.archive == false">
                <button id="btnGroupDrop1" @click="btnEdit" type="button" class="btn btn-primary">
                  <i class="fa fa-cog mr-2"></i>Edit Payment
                </button>
              </div>
              <div v-if="loaded == true && (banklineFile.status =='12' || banklineFile.status =='99') && banklineFile.archive==false" class="btn-group pull-right">
                <button id="btnDeleteFile" @click="btnDeletePayment" type="button" class="btn btn-danger">
                  <i class="fa fa-trash mr-2"></i>Delete Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
export default {
  name: 'WorkflowLogs',
  mixins: [tableFilterMixin],
  components: {
    swal
  },
  data () {
    return {
      settings: {
      },
      loaded: false,
      isEditable: true,
      banklineFile: {},
      banklinePayment: {},
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      selectedCustomerName: ''
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async load () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}banklinepaymentrow/${this.id}`)
        if (res && res.data) {
          this.loaded = true
          this.banklineFile = res.data.banklineFile
          this.banklinePayment = res.data.banklinePayment
          await this.resolveCustomerName(this.banklinePayment.selectedCustomerId)
          // Don't want payments from Settlement to be editable
          if (res.data.banklinePayment && res.data.banklinePayment.metaData) {
            if (res.data.banklinePayment.metaData.cpr === 'SETTLEMENT') {
              this.isEditable = false
            }
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
        this.isEditable = false
      }
    },
    async btnViewAllBalances () {
      this.$router.push('/admin/bandr/balances')
    },
    async btnUploadBanklineFile () {
      this.$router.push('/admin/bandr/bankline/uploadfile')
    },
    reformatSortcode (dai) {
      if (!dai) return
      try {
        if (dai.length !== 14) return
        return `${dai[0]}${dai[1]}-${dai[2]}${dai[3]}-${dai[4]}${dai[5]}`
      } catch {
        return 'Error'
      }
    },
    reformatSortcode2 (dai) {
      if (!dai) return
      try {
        if (dai.length !== 6) return
        return `${dai[0]}${dai[1]}-${dai[2]}${dai[3]}-${dai[4]}${dai[5]}`
      } catch {
        return 'Error'
      }
    },
    async resolveCustomerName (paygateId) {
      if (!paygateId) this.selectedCustomerName = 'Error'
      let customers
      try {
        customers = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customers`)
      } catch {
        return 'Error'
      }
      let selectedCustomer = 'unknown'
      if (customers.data) {
        selectedCustomer = customers.data.find(o => o.paygateId === paygateId)
        this.selectedCustomerName = selectedCustomer.name
      }
    },
    reformatAccountNumber (dai) {
      if (!dai) return
      try {
        if (dai.length !== 14) return
        return dai.substring(6, 14)
      } catch {
        return 'Error'
      }
    },
    reformatCurrency (c) {
      // if (!c) return ''
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      })
      return formatter.format(c)
    },
    reformatDate (d) {
      if (!d) return
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    reformatDate2 (d) {
      if (!d) return
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      return dd + '/' + mm + '/' + yyyy
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    btnEdit () {
      if (this.banklinePayment.recordType === '01') {
        this.$router.push(`/admin/bandr/bankline/banklinepaymentedit/${this.id}`)
      } else {
        this.$router.push(`/admin/bandr/bankline/banklinechapspaymentedit/${this.id}`)
      }
    },
    async btnDeletePayment () {
      const result = await swal.fire({
        title: 'Are you sure you want to permanently delete this Bankline payment?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}banklinepayment/${this.id}`, { showload: true })
          if (res) {
            this.$toastr.s(`The Bankline payment '${this.banklinePayment.customerPaymentReference}' was successfully deleted.`)
            if (this.banklinePayment.standalone === true) {
              this.$router.push('/admin/bandr/bankline/banklineoutbound')
            } else {
              // Is it the last payment in a bulk file?
              console.log(res)
              console.log(res.isLast)
              if (res.data.isLast === true) {
                this.$router.push('/admin/bandr/bankline/banklineoutbound')
              } else {
                this.$router.back()
              }
            }
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this payment.')
          } else {
            this.$snapbar.e(`Could not delete this payment - ${e.message}`)
          }
        }
      }
    }
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.load()
    }
  }
}
</script>
<style scoped>
  .tinyText {
    font-size: x-small;
  }
</style>
