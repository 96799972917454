<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        {{details.areaName}} {{ formType }}
        <help-icon :docPath="docPath" />
      </h2>
    </div>
    <!-- Approval Method -->
    <div class="idb-block-content">
      <div class="form-group">
        <div class="form-check">
          <p-radio
            class="p-default p-round"
            value="Default"
            v-model="policy.behaviour.$model"
            :disabled="disabledDueToConnectedMerchant || readOnly"
          >{{ behaviourOptions[0].text }}</p-radio>
          <!-- Validation -->
        </div>
        <div class="form-check">
          <p-radio
            class="p-default p-round"
            value="Custom"
            v-model="policy.behaviour.$model"
            :disabled="disabledDueToConnectedMerchant || readOnly"
          >{{ behaviourOptions[1].text }}</p-radio>
        </div>
      </div>
      <transition name="fade">
        <div v-if="policy.behaviour.$model === 'Custom'">
          <!-- Number of approval users -->
          <div class="form-group row" :class="{invalid: policy.numberOfApprovalUsers.$error}">
            <label class="col-form-label col-md-3 offset-md-1 required">Number of approval users</label>
            <div class="col-md-2">
              <input
                type="number"
                class="form-control"
                v-model.trim="policy.numberOfApprovalUsers.$model"
                :disabled="disabledDueToConnectedMerchant || readOnly"
                min="0"
                step="1"
              />
              <!-- Validation -->
              <validation-messages v-model="policy.numberOfApprovalUsers"></validation-messages>
            </div>
          </div>
          <!-- Custom Behaviour -->
          <div class="form-group">
            <div class="form-check offset-md-1">
              <p-radio
                class="p-default p-round"
                id="customBehaviourAdministrators"
                value="Administrators"
                v-model="policy.customBehaviour.$model"
                :disabled="disabledDueToConnectedMerchant || readOnly"
              >{{ customBehaviourOptions[0].text }}</p-radio>
            </div>
            <div class="form-check offset-md-1" v-if="showSpecificRole">
              <p-radio
                class="p-default p-round"
                id="customBehaviourUserInRole"
                value="UsersInRole"
                v-model="policy.customBehaviour.$model"
                :disabled="disabledDueToConnectedMerchant || readOnly"
              >{{ customBehaviourOptions[1].text }}</p-radio>
            </div>
            <div class="form-check offset-md-1">
              <p-radio
                class="p-default p-round"
                id="customBehaviourSpecificUsers"
                value="SpecificUsers"
                v-model="policy.customBehaviour.$model"
                :disabled="disabledDueToConnectedMerchant || readOnly"
              >{{ customBehaviourOptions[2].text }}</p-radio>
            </div>
          </div>
          <!-- Specific Users -->
          <transition name="fade">
            <div
              class="form-group row"
              :class="{invalid: policy.approvalUsers.$error}"
              v-if="policy.customBehaviour.$model === 'SpecificUsers'"
            >
              <label class="col-form-label col-md-3 offset-md-1 required">Specific Users</label>
              <div class="col-md-4">
                <vue-select
                  multiple
                  v-model="policy.approvalUsers.$model"
                  :options="details.users"
                  :closeOnSelect="false"
                  append-to-body
                  :calculate-position="withPopper"
                  :disabled="disabledDueToConnectedMerchant || readOnly"
                ></vue-select>
                <!-- Validation -->
                <validation-messages v-model="policy.approvalUsers">
                  <template slot="override">
                    <small
                      class="form-text small"
                      v-if="!policy.approvalUsers.required"
                    >At least one user is required</small>
                    <small
                      class="form-text small"
                      v-if="!policy.approvalUsers.min"
                    >At least {{policy.numberOfApprovalUsers.$model}} users are required</small>
                  </template>
                </validation-messages>
              </div>
            </div>
          </transition>
          <!-- Allow Named Users to Approve Their Own Actions -->
          <div class="form-group row">
            <label
              class="col-form-label col-md-3 offset-md-1"
            >Allow Named Users to Approve Their Own Actions</label>
            <div class="col-md-6">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="policy.canApproveOwnCreation.$model"
                :disabled="disabledDueToConnectedMerchant || readOnly"
              ></p-check>
            </div>
          </div>
          <!-- Users That Can Self Approve -->
          <transition name="fade">
            <div
              class="form-group row"
              :class="{invalid: policy.selfApprovalUsers.$error}"
              v-if="policy.canApproveOwnCreation.$model"
            >
              <label
                class="col-form-label col-md-3 offset-md-1 required"
              >Users That Can Self Approve</label>
              <div class="col-md-4">
                <vue-select
                  multiple
                  v-model="policy.selfApprovalUsers.$model"
                  :options="details.users"
                  :closeOnSelect="false"
                  append-to-body
                  :calculate-position="withPopper"
                  :disabled="disabledDueToConnectedMerchant || readOnly"
                ></vue-select>
                <!-- Validation -->
                <validation-messages v-model="policy.selfApprovalUsers">
                  <template slot="override">
                    <small
                      class="form-text small"
                      v-if="!policy.selfApprovalUsers.required"
                    >At least one user is required</small>
                  </template>
                </validation-messages>
              </div>
            </div>
          </transition>
          <!-- Approval Method -->
          <div class="form-group row">
            <label class="col-form-label col-md-3 offset-md-1">Approval Method</label>
            <div class="col-md-4">
              <b-form-select
                v-model="policy.approvalMethod.$model"
                :options="approvalMethods"
                :disabled="disabledDueToConnectedMerchant || readOnly"
              />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// Third Party
import { mapGetters } from 'vuex'
import { createPopper } from '@popperjs/core'

// Components
import VueSelect from 'vue-select'
import ConnectedMerchantReadOnlyMixin from '@/Assets/Mixins/ConnectedMerchantReadOnlyMixin'

export default {
  mixins: [ConnectedMerchantReadOnlyMixin],
  computed: {
    behaviourOptions () {
      return this.$store.state.enums.behaviourOptions
    },
    ...mapGetters(['approvalMethods'])
  },
  props: {
    formType: { type: String, required: true },
    policy: { type: Object, required: true },
    details: { type: Object, required: true },
    docPath: { type: String },
    showSpecificRole: { type: Boolean, default: true },
    readOnly: { type: Boolean, default: true }
  },
  data () {
    return {
      customBehaviourOptions: [
        { text: 'Administrators', value: 'Administrators' },
        { text: 'Users in the "' + this.details.role + '" role', value: 'UsersInRole' },
        { text: 'A specific set of users', value: 'SpecificUsers' }
      ]
    }
  },
  methods: {
    // https://vue-select.org/guide/positioning.html#popper-js-integration
    withPopper (dropdownList, component, { width }) {
      dropdownList.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          }
        ]
      })

      return () => popper.destroy()
    }
  },
  components: {
    VueSelect
  }
}
</script>
