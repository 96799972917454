// dashboard views
import licence from '@/Assets/Constants/licence'
import roles from '@/Assets/Constants/roles'

const Dashboard = () => import(/* webpackChunkName: "collections-dashboard" */ '@/Views/Collections/Dashboard')

const Alerts = () => import(/* webpackChunkName: "collections-alerts" */ '@/Views/Collections/Alerts/Alerts')

// pages
const ManagePayers = () => import(/* webpackChunkName: "collections-payer" */ '@/Views/Collections/Payer/ManagePayers')
const MovePayers = () => import(/* webpackChunkName: "collections-payer" */ '@/Views/Collections/Payer/MovePayers')
const EditPayer = () => import(/* webpackChunkName: "collections-payer" */ '@/Views/Collections/Payer/EditPayer')
const PayerSchedule = () => import(/* webpackChunkName: "collections-payer" */ '@/Views/Collections/Payer/PayerSchedule')
const MergedSchedule = () => import(/* webpackChunkName: "collections-payer" */ '@/Views/Collections/Payer/MergedSchedule')
const CreatePayer = () => import(/* webpackChunkName: "collections-payer" */ '@/Views/Collections/Payer/PayerWizard')

const ViewSubmissions = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/ViewSubmissions')
const ProcessSubmission = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/ProcessSubmission')
const FlaggedSubmissions = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/FlaggedSubmissions')
const ViewSubmission = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/ViewSubmission')
const ViewAuddisSubmission = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/ViewAuddisSubmission')
const OrphanSubs = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/OrphanSubs')
const PastSubmissions = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/PastSubmissions')
const PastSubmission = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/PastSubmission')
const SubmissionHistory = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/SubmissionHistory')
const HistoricalSubmission = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/HistoricalSubmission')

const ImportPayer = () => import(/* webpackChunkName: "collections-data" */ '@/Views/Collections/Data/ImportPayer')
const ImportCollection= () => import(/* webpackChunkName: "collections-data" */ '@/Views/Collections/Data/ImportCollection')
const ImportSchedule = () => import(/* webpackChunkName: "collections-data" */ '@/Views/Collections/Data/ImportSchedule')
const ExportData = () => import(/* webpackChunkName: "collections-data" */ '@/Views/Collections/Data/Export')

const VariableAmount = () => import(/* webpackChunkName: "collections-varamount" */ '@/Views/Collections/Data/VariableAmount')
const VariableAmountImportDetails = () => import(/* webpackChunkName: "collections-varamount" */'@/Views/Collections/Data/VariableAmountImportDetails')

const MessageQueue = () => import(/* webpackChunkName: "collections-messages" */ '@/Views/Collections/Messaging/MessageQueue')
const ViewMessage = () => import(/* webpackChunkName: "collections-messages" */ '@/Views/Collections/Messaging/ViewMessage')
const PreviewDefaultMessage = () => import(/* webpackChunkName: "collections-messages" */ '@/Views/Collections/Messaging/PreviewDefaultMessage')
const DefaultEmailDesigner = () => import(/* webpackChunkName: "collections-messages" */ '@/Views/Collections/Messaging/DefaultEmailDesigner')
const DefaultLetterDesigner = () => import(/* webpackChunkName: "collections-messages" */ '@/Views/Collections/Messaging/DefaultLetterDesigner')
const DefaultSmsDesigner = () => import(/* webpackChunkName: "collections-messages" */ '@/Views/Collections/Messaging/DefaultSmsDesigner')
const EditDefaultTemplates = () => import(/* webpackChunkName: "collections-messages" */ '@/Views/Collections/Messaging/EditDefaultTemplates')

const ImportDetails = () => import(/* webpackChunkName: "collections-importdetails" */ '@/Views/Collections/Data/ImportDetails')
const PlanImportDetails = () => import(/* webpackChunkName: "collections-importdetails" */ '@/Views/Collections/Data/PlanImportDetails')
const CollectionImportDetails = () => import(/* webpackChunkName: "collections-importdetails" */ '@/Views/Collections/Data/CollectionImportDetails')

const EmailDesigner = () => import(/* webpackChunkName: "collections-emaildesigner" */ '@/Views/Collections/Messaging/EmailDesigner')

const LetterDesigner = () => import(/* webpackChunkName: "collections-letterdesigner" */ '@/Views/Collections/Messaging/LetterDesigner')

const DownloadReports = () => import(/* webpackChunkName: "collections-reports" */ '@/Views/Collections/Reconciliation/DownloadReports')
const ViewReport = () => import(/* webpackChunkName: "collections-reports" */ '@/Views/Collections/Reconciliation/ViewReport')
const SynchronisationReport = () => import(/* webpackChunkName: "collections-reports" */ '@/Views/Collections/Reconciliation/SynchronisationReport')
const IndemnityClaimsReport = () => import(/* webpackChunkName: "collections-reports" */ '@/Views/Collections/Reporting/IndemnityClaimsReport')
const IndemnitySubmissionsReport = () => import(/* webpackChunkName: "collections-reports" */ '@/Views/Collections/Reporting/IndemnitySubmissionReport')

const SmsDesigner = () => import(/* webpackChunkName: "collections-smsdesigner" */ '@/Views/Collections/Messaging/SmsDesigner')

const GroupTemplateAssignment = () => import(/* webpackChunkName: "collections-templates" */ '@/Views/Collections/Messaging/GroupTemplateAssignment')
const MessageTemplateManagement = () => import(/* webpackChunkName: "collections-templates" */ '@/Views/Collections/Messaging/MessageTemplateManagement')

const ReconciliationHistory = () => import(/* webpackChunkName: "collections-history" */ '@/Views/Collections/Reconciliation/ReconciliationHistory')
const PayerHistory = () => import(/* webpackChunkName: "collections-history" */ '@/Views/Collections/Payer/ViewHistory')

const PlanDesigner = () => import(/* webpackChunkName: "collections-plandesigner" */ '@/Views/Collections/Plans/PlanDesigner')

const ScheduledPlanChanges = () => import(/* webpackChunkName: "collections-scheduledplanchanges" */ '@/Views/Collections/Plans/ScheduledPlanChanges')

const LookAhead = () => import(/* webpackChunkName: "collections-lookahead" */ '@/Views/Collections/Reporting/LookAhead')
const LookAheadDrillDown = () => import(/* webpackChunkName: "collections-lookahead" */ '@/Views/Collections/Reporting/LookAheadDrillDown')
const Orphaned = () => import(/* webpackChunkName: "collections-lookahead" */ '@/Views/Collections/Reporting/OrphanedSubmissions')

const BulkMessageSender = () => import(/* webpackChunkName: "collections-messagesender" */ '@/Views/Collections/Messaging/BulkMessageSender')
const Suppression = () => import(/* webpackChunkName: "collections-suppression" */ '@/Views/Collections/Plans/Suppression')
const OnboardingStyles = () => import(/* webpackChunkName: "collections-onboarding" */ '@/Views/Collections/Onboarding/OnboardingStyles')
const PortalMessages = () => import(/* webpackChunkName: "collections-portal" */ '@/Views/Collections/Portal/PortalMessages')
const PortalConfiguration = () => import(/* webpackChunkName: "collections-portal" */ '@/Views/Collections/Portal/PortalConfiguration')
const PortalSendBulk = () => import(/* webpackChunkName: "collections-portal" */ '@/Views/Collections/Portal/SendBulkMessages')
const PortalMigrate = () => import(/* webpackChunkName: "collections-portal" */ '@/Views/Collections/Portal/BulkMigratePayers')
const OutTray = () => import(/* webpackChunkName: "collections-messaging" */ '@/Views/Collections/Messaging/OutTray')
const OptOut = () => import(/* webpackChunkName: "collections-messaging" */ '@/Views/Collections/Messaging/OptOut')
const MessageAction = () => import(/* webpackChunkName: "collections-cancellation" */ '@/Views/Collections/Messaging/MessageAction')
const CertificateManager = () => import(/* webpackChunkName: "collections-onboarding" */ '@/Views/Collections/Onboarding/CertificateImageManager')
const OnboardingUrls = () => import(/* webpackChunkName: "collections-onboarding" */ '@/Views/Collections/Onboarding/OnboardingUrls')

const FinalWarning = () => import(/* webpackChunkName: "collections-final" */ '@/Views/Collections/Payer/PayerExpiration')
const CancellationPerformed = () => import(/* webpackChunkName: "collections-cancellation" */ '@/Views/Collections/Submissions/CancellationPerformed')
const ReportGeneration = () => import(/* webpackChunkName: "collections-qa-tools" */ '@/Views/Collections/Tools/ReportGeneration')

const ListIndemnityReports = () => import(/* webpackChunkName: "indemnity" */ '@/Views/JFTP/Indemnity/ListReports')
const ViewIndemnityReport = () => import(/* webpackChunkName: "indemnity" */ '@/Views/JFTP/Indemnity/ViewReport')
const ListChallenges = () => import(/* webpackChunkName: "indemnity" */ '@/Views/JFTP/Indemnity/ListChallenges')
const ViewChallenge = () => import(/* webpackChunkName: "indemnity" */ '@/Views/JFTP/Indemnity/ViewChallenge')
const BankingContacts = () => import(/* webpackChunkName: "indemnity" */ '@/Views/JFTP/Indemnity/BankingContacts')
const BankingContact = () => import(/* webpackChunkName: "indemnity" */ '@/Views/JFTP/Indemnity/BankingContact')
const PlanReporting = () => import(/* webpackChunkName: "collections-reporting" */ '@/Views/Collections/Payer/PlanReporting')

const CancelSchedules = () => import(/* webpackChunkName: "indemnity" */ '@/Views/JFTP/Administration/CancelSchedules')
const SuspendSchedules = () => import(/* webpackChunkName: "indemnity" */ '@/Views/JFTP/Administration/SuspendSchedules')
const PayerMigrationReport = () => import(/* webpackChunkName: "payermigration" */ '@/Views/JFTP/Collections/PayerMigrationReport')
const RefundReport = () => import(/* webpackChunkName: "collections-reports" */ '@/Views/JFTP/Collections/Refunds' )
const SubmissionControls = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/JFTP/Collections/SubmissionControlCentre')

const CollectionsReport = () => import(/* webpackChunkName: "jftp-reports" */ '@/Views/JFTP/Collections/Reports/CollectionsReport')
const CollectionSchedulesReport = () => import(/* webpackChunkName: "jftp-reports" */ '@/Views/JFTP/Collections/Reports/CollectionSchedulesReport')
const CollectionsOverviewReport = () => import(/* webpackChunkName: "jftp-reports" */ '@/Views/JFTP/Collections/Reports/CollectionsOverviewReport')
const FailedCollectionsReport = () => import(/* webpackChunkName: "jftp-reports" */ '@/Views/JFTP/Collections/Reports/FailedCollectionsReport')
const SageReport = () => import(/* webpackChunkName: "jftp-reports" */ '@/Views/JFTP/Collections/Reports/SageReport')
const BacsDdReport = () => import(/* webpackChunkName: "jftp-reports" */ '@/Views/JFTP/Collections/Reports/BacsProcessingReport')
const BacsDdReportItems = () => import(/* webpackChunkName: "jftp-reports" */ '@/Views/JFTP/Collections/Reports/BacsProcessingReportChanges')

const ThresholdAction = () => import(/* webpackChunkName: "jftp-actions" */ '@/Views/Collections/Submissions/ThresholdAction')
const FinanceThresholdAction = () => import(/* webpackChunkName: "jftp-actions" */ '@/Views/Collections/Submissions/FinanceThresholdAction')

const SearchCustomers = () => import(/* webpackChunkName: "jftp-actions" */'@/Views/Collections/Payer/SearchPayers')

const PostworksOutput = () => import(/* webpackChunkName: "jftp-actions" */ '@/Views/Collections/Messaging/PostworksOutTray.vue')
const MessagingAdministration = () => import(/* webpackChunkName: "jftp-actions" */ '@/Views/Collections/Tools/MessagingAdministration.vue')

const ExpectedCollections = () => import(/* webpackChunkName: "jftp-reporting" */ '@/Views/Collections/Reporting/ExpectedCollections.vue')

export default [
  {
    path: '/collections',
    component: Dashboard,
    meta: {
      breadcrumb: 'Home'
    }
  },
  {
    path: '/collections/alerts/',
    component: Alerts,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Home',
      title: 'Collections / Alerts',
      auth: [ roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Finance, roles.Implementations ]
    }
  },
  {
    path: '/collections/search/searchcustomers',
    component: SearchCustomers,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Home',
      title: 'Collections / Search Customers',
      auth: [ roles.LZAdmin, roles.Finance, roles.Implementations, roles.DevOps, roles.Developer, roles.Qa ]
    }
  },
  {
    path: '/collections/customer/:ukPayerId/edit/',
    name: 'editPayer',
    props: ({ params, query: { retPath, openAt, selectedSchedule } }) => {
      return {
        ...params,
        retPath,
        payerDetailsInitiallyVisible: openAt === 'payerDetails',
        bankAccountInitiallyVisible: openAt === 'bankAccount',
        paymentPlansInitiallyVisible: (openAt === 'paymentPlans' || openAt === 'schedules'),
        messageQueueInitiallyVisible: openAt === 'messagequeue',
        indemnityInitiallyVisible: openAt === 'indemnities'
      }
    },
    component: EditPayer,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Edit Customer',
      auth: [ roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.ReadOnly, roles.Limited, roles.Developer, roles.Qa, roles.Finance, roles.Implementations ],
      title: 'Collections / Customer / Edit Customer'
    }
  },
  {
    path: '/collections/customer/:groupid/create',
    name: 'createPayer',
    component: CreatePayer,
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Create Customer',
      auth: [ roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Implementations ],
      title: 'Collections / Customer / Create Customer'
    }
  },
  {
    name: 'managePayers',
    path: '/collections/customer/manage',
    component: ManagePayers,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Manage Customers',
      auth: [ roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.ReadOnly, roles.Limited, roles.Developer, roles.Qa, roles.Finance, roles.Implementations ],
      title: 'Collections / Customer / Manage Customers'
    },
    props: ({ params, query: { search, groupid, status } }) => {
      return {
        ...params,
        prepopulatedStatus: status,
        prepopulatedSearchTerm: search,
        prepopulatedGroup: groupid
      }
    }
  },
  {
    name: 'movePayers',
    path: '/admin/collections/movecustomers',
    component: MovePayers,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Move Customers',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Implementations],
      title: 'Collections / Customer / Move Customers'
    },
    props: ({ params, query: { search, groupid, status } }) => {
      return {
        ...params,
        prepopulatedStatus: status,
        prepopulatedSearchTerm: search,
        prepopulatedGroup: groupid
      }
    }
  },
  {
    path: '/collections/customer/:id/:planId/schedule',
    component: PayerSchedule,
    name: 'PayerSchedule',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Customer',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Standard,  roles.Limited, roles.ReadOnly, roles.Finance, roles.Qa, roles.Developer, roles.Implementations],
      title: 'Collections / Customer / Customer Collection Schedule'
    }
  },
  {
    path: '/collections/customerschedule/:id',
    component: MergedSchedule,
    name: 'MergedSchedule',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Customer',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Standard,  roles.Limited, roles.ReadOnly, roles.Finance, roles.Qa, roles.Developer, roles.Implementations],
      title: 'Collections / Customer / Customer Collection Schedules'
    }
  },
  {
    path: '/collections/customer/:id/history/:historyId',
    component: PayerHistory,
    name: 'PayerHistory',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Customer',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Standard,  roles.Limited, roles.ReadOnly, roles.Finance, roles.Qa, roles.Developer, roles.Implementations],
      title: 'Collections / Customer/ Customer History'
    }
  },
  {
    path: '/collections/submissions/',
    component: ViewSubmissions,
    name: 'ViewSubmissions',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Submissions',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Standard,  roles.Limited, roles.ReadOnly, roles.Implementations],
      title: 'Collections / Submissions / View Submissions'
    }
  },
  {
    path: '/collections/message/queue',
    component: MessageQueue,
    name: 'MessageQueue',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Message Queue',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Standard,  roles.Limited, roles.ReadOnly, roles.Finance, roles.Qa, roles.Developer, roles.Implementations],
      title: 'Collections / Messaging / Message Queue'
    }
  },
  {
    path: '/collections/message/grouptemplates',
    component: GroupTemplateAssignment,
    name: 'GroupTemplateAssignment',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Assign Group Templates',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Messaging / Assign Group Templates'
    }
  },
  {
    path: '/collections/message/bulkmessaging',
    component: BulkMessageSender,
    name: 'BulkMessageSender',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Bulk Message Sender',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Messaging / Bulk Message Sender'
    }
  },
  {
    path: '/collections/message/templates',
    component: MessageTemplateManagement,
    name: 'MessageTemplateManagement',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Manage Message Templates',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Implementations],
      title: 'Collections / Messaging / Manage Message Templates'
    }
  },
  {
    path: '/collections/message/designer/email',
    component: EmailDesigner,
    name: 'EmailDesigner',
    props: false,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Email Designer',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Implementations],
      title: 'Collections / Messaging / Email Designer'
    }
  },
  {
    path: '/collections/message/designer/email/:templateid',
    component: EmailDesigner,
    name: 'SpecificEmailTemplate',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Email Designer',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Implementations],
      title: 'Collections / Messaging / Email Designer'
    }
  },
  {
    path: '/collections/message/designer/letter',
    component: LetterDesigner,
    name: 'LetterDesigner',
    props: false,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Letter Designer',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Implementations],
      title: 'Collections / Messaging / Letter Designer'
    }
  },
  {
    path: '/collections/message/designer/letter/:templateid',
    component: LetterDesigner,
    name: 'SpecificLetterTemplate',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Letter Designer',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Implementations],
      title: 'Collections / Messaging / Letter Designer'
    }
  },
  {
    path: '/collections/message/designer/sms/:templateid',
    component: SmsDesigner,
    name: 'SpecificSmsTemplate',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'SMS Designer',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Implementations],
      title: 'Collections / Messaging / SMS Designer'
    }
  },
  {
    path: '/collections/message/designer/sms',
    component: SmsDesigner,
    name: 'SmsDesigner',
    props: false,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'SMS Designer',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Implementations ],
      title: 'Collections / Messaging / SMS Designer'
    }
  },
  {
    path: '/collections/message/:messageId',
    component: ViewMessage,
    name: 'viewMessage',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Message',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Standard,  roles.Limited, roles.ReadOnly, roles.Finance, roles.Qa, roles.Developer, roles.Implementations],
      title: 'Collections / Messaging / View Message'
    }
  },
  {
    path: '/collections/letters/out',
    component: OutTray,
    name: 'outtray',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Out Tray',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Messaging / Letters Outbox'
    }
  },
  {
    path: '/collections/defaultmessage/:channel/:type',
    component: PreviewDefaultMessage,
    name: 'ViewDefaultMessage',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Preview Default Message',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Messaging / View Default Message'
    }
  },
  {
    path: '/collections/defaultmessagedesigner/',
    component: EditDefaultTemplates,
    name: 'EditDefaultTemplates',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Edit Default Templates',
      auth: [roles.SystemUser, roles.LZAdmin, roles.DevOps, roles.Implementations],
      title: 'Admin / Collections / Messaging / Default Messages'
    }
  },
  {
    path: '/collections/defaultmessagedesigner/email/:type',
    component: DefaultEmailDesigner,
    name: 'DefaultEmailDesigner',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Default Email designer',
      auth: [roles.SystemUser, roles.LZAdmin, roles.DevOps, roles.Implementations],
      title: 'Admin / Collections / Messaging / Default Messages'
    }
  },
  {
    path: '/collections/defaultmessagedesigner/letter/:type',
    component: DefaultLetterDesigner,
    name: 'DefaultLetterDesigner',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Default Letter designer',
      auth: [roles.SystemUser, roles.LZAdmin, roles.DevOps, roles.Implementations],
      title: 'Admin / Collections / Messaging / Default Messages'
    }
  },
  {
    path: '/collections/defaultmessagedesigner/sms/:type',
    component: DefaultSmsDesigner,
    name: 'DefaultSmsDesigner',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Default Sms designer',
      auth: [roles.SystemUser, roles.LZAdmin, roles.DevOps, roles.Implementations],
      title: 'Admin / Collections / Messaging / Default Messages'
    }
  },
  {
    path: '/collections/submissions/:groupId/flagged',
    component: FlaggedSubmissions,
    name: 'FlaggedSubmissions',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Flagged Submissions',
      auth: ['ProcessSubmissions', roles.Finance],
      title: 'Collections / Submission / Flagged Submissions'
    }
  },
  {
    path: '/collections/submissions/:groupId/view',
    component: ViewSubmission,
    name: 'ViewSubmission',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Submission',
      auth: ['ProcessSubmissions', roles.Finance],
      title: 'Collections / Submission / View Submission'
    }
  },
  {
    path: '/collections/submissions/:groupId/viewauddis',
    component: ViewAuddisSubmission,
    name: 'ViewAuddisSubmission',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Auddis Submission',
      auth: ['ProcessSubmissions', roles.Finance],
      title: 'Collections / Submission / View Auddis Submission'
    }
  },
  {
    path: '/collections/submissions/uncollected/:orphanWarningId',
    component: OrphanSubs,
    name: 'OrphanSubs',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Submission',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Finance, roles.Implementations],
      title: 'Collections / Submission / Uncollected Submission'
    }
  },
  {
    path: '/collections/submissions/:groupId/:mode',
    component: ProcessSubmission,
    name: 'ProcessCollection',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Submissions',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Implementations],
      title: 'Collections / Submission / Process Submission'
    }
  },
  {
    path: '/collections/cancelsubmissions',
    component: PastSubmissions,
    name: 'CancelSubmissions',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Submissions',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Finance, roles.Implementations],
      title: 'Collections / Submission /Cancel Submissions'
    }
  },
  {
    path: '/collections/cancelsubmission/:submissionId/:fileNumber',
    component: PastSubmission,
    name: 'CancelSubmission',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Past Submission',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Submission / Cancel Submission'
    }
  },
  {
    path: '/collections/data/import',
    component: ImportPayer,
    name: 'ImportPayer',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Data',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Import / Import Customers'
    }
  },
  {
    path: '/collections/data/importschedule',
    component: ImportSchedule,
    name: 'ImportSchedule',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Data',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Import / Import Schedule'
    }
  },
  {
    path: '/collections/data/importcollection/',
    component: ImportCollection,
    name: 'ImportCollection',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Data',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Import / Import Collection'
    }
  },
  {
    path: '/collections/data/import/:uploadId',
    component: ImportDetails,
    name: 'ImportDetails',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Import',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Import / Import Details'
    }
  },
  {
    path: '/collections/data/export',
    component: ExportData,
    name: 'ExportData',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Data',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Export Data'
    }
  },
  {
    path: '/collections/data/variableamount',
    component: VariableAmount,
    name: 'VariableAmount',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Data',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Import / Import Variable Amount'
    }
  },
  {
    path: '/collections/data/variableamount/:uploadId',
    component: VariableAmountImportDetails,
    name: 'VariableAmountImportDetails',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Data',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Import / Variable Amount Import Details'
    }
  },
  {
    path: '/collections/reconciliation/downloadReports',
    component: DownloadReports,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Reconciliation',
      auth: ['SynchroniseBACSData'],
      title: 'Collections / Reconciliation'
    }
  },
  {
    path: '/collections/reconciliation/viewReport',
    component: ViewReport,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reconciliation / View Reconciliation Report'
    },
    props: route => ({ ...route.params, ...route.query })
  },
  {
    path: '/collections/reconciliation/report/:syncId',
    component: SynchronisationReport,
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Reconciliation',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reconciliation / Synchronisation Report'
    }
  },
  {
    path: '/collections/reconciliation/report/',
    component: ReconciliationHistory,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Reconciliation',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reconciliation / Reconciliation History'
    }
  },
  {
    path: '/collections/reporting/lookahead/',
    component: LookAhead,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Reporting',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / Look Ahead Report'
    }
  },
  {
    path: '/collections/reporting/lookahead/drilldown/:groupId/:year/:month',
    component: LookAheadDrillDown,
    props: true,
    name: 'DrillDown',
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Reporting',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / Look Ahead Report / Drilldown'
    }
  },
  {
    path: '/collections/reporting/orphaned/',
    component: Orphaned,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Reporting',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / Orphaned Submissions Report'
    }
  },
  {
    path: '/collections/schedules/designer/',
    component: PlanDesigner,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Schedules',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Schedules / Schedule Designer'
    }
  },
  {
    path: '/collections/schedules/plannedchanges/',
    component: ScheduledPlanChanges,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Schedules',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Schedules / Planned Schedule Changes'
    }
  },
  {
    path: '/collections/schedules/suppression/',
    component: Suppression,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Schedules',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Implementations],
      title: 'Collections / Schedules / Suppression'
    }
  },
  {
    path: '/collections/onlinesignup/styles/',
    component: OnboardingStyles,
    meta: {
      licence: [licence.collectionsOnBoarding],
      breadcrumb: 'Online Signup',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Implementations],
      title: 'Collections / Online Signup / Online Signup Styles'
    }
  },
  {
    path: '/admin/customers/onlinesignup/urls/',
    component: OnboardingUrls,
    meta: {
      licence: [licence.collectionsOnBoarding],
      breadcrumb: 'Online Signup',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Implementations],
      title: 'Collections / Online Signup / Online Signup Urls'
    }
  },
  {
    path: '/admin/system/onlinesignupcertificateimage',
    component: CertificateManager,
    meta: {
      licence: [],
      breadcrumb: 'OOnline Signup',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Implementations],
      title: 'Collections / Online Signup / Manage SSL Certificate Image'
    }
  },
  /*{
    path: '/collections/portal/messages/',
    component: PortalMessages,
    meta: {
      licence: [licence.collectionsPayerPortal],
      breadcrumb: 'Portal',
      auth: [],
      title: 'Collections / Portal / Messages'
    }
  },
  {
    path: '/collections/portal/configuration/',
    component: PortalConfiguration,
    meta: {
      licence: [licence.collectionsPayerPortal],
      breadcrumb: 'Portal',
      auth: [],
      title: 'Collections / Portal / Configuration'
    }
  },*/
  {
    path: '/collections/portal/sendbulk/',
    component: PortalSendBulk,
    meta: {
      licence: [licence.collectionsPayerPortal],
      breadcrumb: 'Portal',
      auth: [],
      title: 'Collections / Portal / Send Bulk'
    }
  },
  {
    path: '/collections/portal/migrate/',
    component: PortalMigrate,
    meta: {
      licence: [licence.collectionsPayerPortal],
      breadcrumb: 'Portal',
      auth: [],
      title: 'Collections / Portal / Bulk Account Creation'
    }
  },
  {
    path: '/collections/actions/finalwarning/:ukPayerId',
    name: 'Final Warning',
    props: true,
    component: FinalWarning,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Actions',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Customers / Final Warning'
    }
  },
  {
    path: '/collections/actions/withdrawalperformed/:submissionId',
    name: 'Withdrawal Performed',
    props: true,
    component: CancellationPerformed,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Actions',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Submissions / Cancellation Performed'
    }
  },
  {
    path: '/collections/actions/messaging/:messageId/:type',
    name: 'Message Action',
    props: true,
    component: MessageAction,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Actions',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Messaging / Failure'
    }
  },
  {
    path: '/collections/messages/optout',
    name: 'Opt Out',
    props: true,
    component: OptOut,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Actions',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Developer, roles.Qa, roles.Implementations],
      title: 'Collections / Messages / Opt Out'
    }
  },
  {
    path: '/collections/indemnity',
    name: 'List Indemnity Reports',
    props: true,
    component: ListIndemnityReports,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Indemnity Reports',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Qa, roles.Developer, roles.Finance, roles.Implementations ],
      title: 'Collections / Indemnity Reports'
    }
  },
  {
    path: '/collections/indemnity/:reportId',
    name: 'View Indemnity Report',
    props: true,
    component: ViewIndemnityReport,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Indemnity Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Developer, roles.Qa, roles.Finance, roles.Implementations],
      title: 'Collections / Indemnity Reports / View Report'
    }
  },
  {
    path: '/collections/challenges/',
    name: 'View Indemnity Challenges',
    props: true,
    component: ListChallenges,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Indemnity Challenges',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Developer, roles.Qa, roles.Finance, roles.Implementations],
      title: 'Collections / Indemnity Challenges'
    }
  },
  {
    path: '/collections/challenges/:challengeId',
    name: 'View Indemnity Challenge',
    props: true,
    component: ViewChallenge,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Indemnity Challenge',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Developer, roles.Qa, roles.Finance, roles.Implementations],
      title: 'Collections / Indemnity Challenges / View Challenge'
    }
  },
  {
    path: '/collections/submissionhistory',
    name: 'Submission History',
    props: true,
    component: SubmissionHistory,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Submission History',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Finance, roles.Implementations ],
      title: 'Collections / Submissions History'
    }
  },
  {
    path: '/collections/submissionhistory/:submissionId',
    name: 'Submission History Item',
    props: true,
    component: HistoricalSubmission,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Submission History Item',
      auth: [ roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations ],
      title: 'Collections / Submissions History'
    }
  },
  {
    path: '/collections/bankingcontact',
    name: 'Banking Contacts',
    props: true,
    component: BankingContacts,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Banking Contacts',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Finance, roles.Implementations],
      title: 'Collections / Banking Contacts'
    }
  },
  {
    path: '/collections/bankingcontact/:contactId',
    name: 'Banking Contact',
    props: true,
    component: BankingContact,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Banking Contacts',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Developer, roles.Qa, roles.Finance, roles.Implementations],
      title: 'Collections / Banking Contacts'
    }
  },
  {
    path: '/collections/reporting/indemnityclaims',
    name: 'Indemnity Claims Report',
    props: true,
    component: IndemnityClaimsReport,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Indemnity Claims Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / Indemnity Claims'
    }
  },
  {
    path: '/collections/reporting/indemnitysubmissions',
    name: 'Indemnity Submissions Report',
    props: true,
    component: IndemnitySubmissionsReport,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Indemnity Submissions Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / Indemnity Submissions'
    }
  },
  {
    path: '/collections/reporting/schedules/:ukPayerId',
    name: 'Customer Schedules Report',
    props: true,
    component: PlanReporting,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Customer Schedules Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / Customer Schedules Report'
    }
  },
  {
    path: '/admin/system/cancelschedules',
    name: 'Cancel Schedules',
    props: true,
    component: CancelSchedules,
    meta: {
      title: 'Cancel Collection Schedules',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Implementations]
    },
  },
  {
    path: '/admin/system/suspendschedules',
    name: 'Suspend Schedules',
    props: true,
    component: SuspendSchedules,
    meta: {
      title: 'Suspend Collection Schedules',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Implementations]
    },
  },
  {
    path: '/admin/system/customermigrationreport',
    name: 'Customer Migration Report',
    props: true,
    component: PayerMigrationReport,
    meta: {
      title: 'Customer Migration Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Developer, roles.Qa, roles.Implementations],
    },
  },
  {
    path: '/collections/data/scheduleimport/:uploadId',
    component: PlanImportDetails,
    name: 'PlanImportDetails',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Import',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Standard, roles.Implementations],
      title: 'Collections / Import / Schedule Import Details'
    }
  },
  {
    path: '/collections/data/collectionimport/:uploadId',
    component: CollectionImportDetails,
    name: 'CollectionImportDetails',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Import',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Standard, roles.Implementations],
      title: 'Collections / Import / Collection Import Details'
    }
  },
  {
    path: '/collections/reporting/refunds',
    component: RefundReport,
    name: 'RefundReport',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Refund Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / Refunds'
    }
  },
  {
    path: '/collections/submissioncontrol',
    component: SubmissionControls,
    name: 'SubmissionControls',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Submission Controls',
      auth: [roles.Finance],
      title: 'Collections / Submissions / Control'
    }
  },
  {
    path: '/collections/reports/collectionsreport',
    component: CollectionsReport,
    name: 'CollectionsReport',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'CollectionsReport',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / Collections'
    }
  },
  {
    path: '/collections/tools/reportgeneration',
    component: ReportGeneration,
    name: 'ReportGeneration',
    props: true,
    meta: {
      licence: [],
      breadcrumb: 'Report Generation',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Qa],
      title: 'Collections / Tools / Generate BACS Reports'
    }
  },
  {
    path: '/collections/reports/collectionschedules',
    component: CollectionSchedulesReport,
    name: 'CollectionSchedulesReport',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Collection Schedules Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / Collection Schedules'
    }
  },
  {
    path: '/collections/reports/collectionsoverview',
    component: CollectionsOverviewReport,
    name: 'CollectionsOverviewReport',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Collections Overview Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / Collections Overview'
    }
  },
  {
    path: '/collections/reports/failedcollections',
    component: FailedCollectionsReport,
    name: 'FailedCollectionsReport',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Failed Collections Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / Failed Collections'
    }
  },
  {
    path: '/collections/reports/sage',
    component: SageReport,
    name: 'SageReport',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Sage Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Standard, roles.ClientAdmin, roles.Limited, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / SAGE'
    }
  },
  {
    path: '/collections/reports/bacsprocessing',
    component: BacsDdReport,
    name: 'BacsDdReport',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Sage Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.ReadOnly, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / BACS Processing'
    }
  },
  {
    path: '/collections/reports/bacsprocessing/:reportId',
    component: BacsDdReportItems,
    name: 'BacsDdReportItems',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Sage Report',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Finance, roles.Implementations],
      title: 'Collections / Reports / BACS Processing / Changes'
    }
  },
  {
    path: '/collections/actions/thresholdaction',
    component: ThresholdAction,
    name: 'ThresholdAction',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Submissions Threshold Exceeded',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Implementations],
      title: 'Collections / Submissions / Actions'
    }
  },
  {
    path: '/collections/actions/financethresholdaction',
    component: FinanceThresholdAction,
    name: 'FinanceThresholdAction',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Submissions Threshold Exceeded',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Finance, roles.Implementations],
      title: 'Collections / Submissions / Actions'
    }
  },
  {
    path: '/collections/reporting/expectedcollections',
    component: ExpectedCollections,
    name: 'ExpectedCollections',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Expected Collections',
      auth: [roles.SystemUser, roles.Finance],
      title: 'Collections / Reporting / Expected Collections'
    }
  },
  {
    path: '/collections/postworksouttray',
    component: PostworksOutput,
    name: 'PostworksOutput',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Postworks Output',
      auth: [roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.Developer, roles.DevOps ],
      title: 'Collections / Admin / Postworks'
    }
  },
  {
    path: '/collections/messagingadministration',
    component: MessagingAdministration,
    name: 'MessagingAdministration',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'MessagingAdministration',
      auth: [roles.DevOps ],
      title: 'Collections / Admin / Messaging Administration'
    }
  }
]
