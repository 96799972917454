<template>
  <div id="app-client">
    <template>
      <loading class="main" :loading="loading"></loading>
      <router-view></router-view>
      <vue-progress-bar id="loading-bar"></vue-progress-bar>
    </template>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
export default {
  name: 'app',

  computed: {
    ...mapGetters({
      getClaim: 'getClaim'
    }),

    ...mapState({
      loading: 'appLoading'
    })
  }
}
</script>
