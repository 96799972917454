var billingEnum = {
  CreditNote: 'Credit Note',
  TerminationFee: 'Termination Fee',
  AdminCharge: 'Admin Charge',
  BacsFileSubmissionCharge: 'BACS File Submission Charge',
  OsuCharge: 'OSU Charge',
  AdvanceNoticeLetter: 'Advance Notice Letter',
  AdvanceNoticeEmail: 'Advance Notice Email',
  Collections: 'Collections',
  ConfirmationLetter: 'Confirmation Letter',
  ConfirmationEmail: 'Confirmation Email',
  FailedCollections: 'Failed Collections',
  IndemnityClaims: 'Indemnity Claims',
  PayerCancellation: 'Customer Cancellation',
  PayerReinstate: 'Customer Reinstate',
  PayerSetup: 'Customer Setup',
  Refunds: 'Refunds',
  BacsMigration: 'BACS Migration',
  CustomerSetup: 'Client Setup',
  FmServiceUserSetup: 'FM Service User Setup',
  PayerMigration: 'Customer Migration',
  Reports: 'Reports',
  ServiceUserModification: 'Service User Modification',
  ValidateBankAccount: 'Validate Bank Account',
  ValidateIBAN: 'Validate IBAN',
  Netdown: 'Netdown',
  NotificationOfChange: 'Notification Of Change',
  Other: 'Other',
  BanklineSTD1Or2: 'Bankline STD 1 or 2 Day Payment',
  BanklineSTD0: 'Bankline STD 0 Day Payment',
  BanklineUrgent: 'Bankline URG Payment',
  PreCollectionNotification: 'Pre-Collection Notification'
}

var billingProperties = {
  CreditNote: 'creditNote',
  TerminationFee: 'terminationFee',
  AdminCharge: 'adminCharge',
  BacsFileSubmissionCharge: 'bacsFileSubmissionCharge',
  OsuCharge: 'osuCharge',
  AdvanceNoticeLetter: 'advanceNoticeLetter',
  AdvanceNoticeEmail: 'advanceNoticeEmail',
  Collections: 'collections',
  ConfirmationLetter: 'confirmationLetter',
  ConfirmationEmail: 'confirmationEmail',
  FailedCollections: 'failedCollections',
  IndemnityClaims: 'indemnityClaims',
  PayerCancellation: 'payerCancellation',
  PayerReinstate: 'payerReinstate',
  PayerSetup: 'payerSetup',
  Refunds: 'refunds',
  BacsMigration: 'bacsMigration',
  CustomerSetup: 'customerSetup',
  FmServiceUserSetup: 'fmServiceUserSetup',
  PayerMigration: 'payerMigration',
  Reports: 'reports',
  ServiceUserModification: 'serviceUserModification',
  ValidateBankAccount: 'validateBankAccount',
  ValidateIBAN: 'validateIBAN',
  Netdown: 'Netdown',
  NotificationOfChange: 'notificationOfChange',
  Other: 'other',
  BanklineSTD1Or2: 'banklineSTD1Or2',
  BanklineSTD0: 'banklineSTD0',
  BanklineUrgent: 'banklineUrgent',
  PreCollectionNotification: 'preCollectionNotification'
}

const billingStatus = {
  Submitted: 'Submitted',
  Settled: 'Settled',
  Failed: 'Failed',
  AwaitingPayment: 'Awaiting Payment',
  NetdownCreated: 'Netdown Created'
}

var billingTypesAsDropdown = [
  {
    label: 'Processing Charges',
    options:
      Object.keys(billingEnum).filter(b =>
        [
          'AdvanceNoticeLetter',
          'AdvanceNoticeEmail',
          'PreCollectionNotification',
          'Collections',
          'ConfirmationLetter',
          'ConfirmationEmail',
          'FailedCollections',
          'IndemnityClaims',
          'PayerCancellation',
          'PayerReinstate',
          'PayerSetup',
          'Refunds',
          'ValidateBankAccount',
          'ValidateIBAN',
          'BanklineSTD1Or2',
          'BanklineSTD0',
          'BanklineUrgent'

        ].some(bb => bb === b)
      ).map((b) => {
        return { value: b, text: billingEnum[b] }
      })

  },
  {
    label: 'Ad-Hoc Charges',
    options:
      Object.keys(billingEnum).filter(b =>
        [
          'CreditNote',
          'TerminationFee'
        ].some(bb => bb === b)
      ).map((b) => {
        return { value: b, text: billingEnum[b] }
      })

  },
  {
    label: 'PS Charges',
    options:
      Object.keys(billingEnum).filter(b =>
        [
          'BacsMigration',
          'CustomerSetup',
          'FmServiceUserSetup',
          'PayerMigration',
          'Reports',
          'ServiceUserModification'
        ].some(bb => bb === b)
      ).map((b) => {
        return { value: b, text: billingEnum[b] }
      })

  },
  {
    label: 'Periodic Charges',
    options:
      Object.keys(billingEnum).filter(b =>
        [
          'AdminCharge',
          'BacsFileSubmissionCharge',
          'OsuCharge'
        ].some(bb => bb === b)
      ).map((b) => {
        return { value: b, text: billingEnum[b] }
      })

  },
  billingEnum.Other
]

const settlementBillingTypes = [
  billingEnum.AdvanceNoticeEmail,
  billingEnum.AdvanceNoticeLetter,
  billingEnum.PreCollectionNotification,
  billingEnum.Collections,
  billingEnum.ConfirmationEmail,
  billingEnum.ConfirmationLetter,
  billingEnum.FailedCollections,
  billingEnum.IndemnityClaims,
  billingEnum.PayerCancellation,
  billingEnum.PayerReinstate,
  billingEnum.PayerSetup,
  billingEnum.Refunds,
  billingEnum.ValidateBankAccount,
  billingEnum.ValidateIBAN,
  billingEnum.BanklineSTD1Or2,
  billingEnum.BanklineSTD0,
  billingEnum.BanklineUrgent,
  billingEnum.Netdown
]

export {
  billingEnum,
  billingProperties,
  billingStatus,
  billingTypesAsDropdown,
  settlementBillingTypes
}
