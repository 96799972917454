<template>
  <div id="importedPayments">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <!-- Header -->
          <div class="idb-block-title">
            <h2>
              Submission Payments
              <span class="pull-right">
                <a :href="helpUrl" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span>
            </h2>
          </div>
          <!-- Main content -->
          <div class="idb-block-content">
            <div v-if="showImportMessages === true">
              <b-card
                :border-variant="importMessagesVariant"
                :header="messageHeader"
                :header-bg-variant="importMessagesVariant"
                header-text-variant="white"
              >
                <div
                  v-for="msg in importFileResponse.responseMessages"
                  v-bind:key="msg.messageText"
                >
                  <div>{{ msg.messageText }}</div>
                </div>
              </b-card>
              <br />
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-1"></div>
                  <div class="col-md-1 alertValueRight">
                    <strong>Items</strong>
                  </div>
                  <div class="col-md-2 alertValueRight">
                    <strong>Total (£)</strong>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <b>Group Name</b>
                  </div>
                  <div class="col-md-2">
                    <button
                      class="btn btn-link"
                      style="padding: 0 0 0 0; white-space: normal; text-align: left;"
                      v-on:click="this.viewGroupDetails"
                    >{{ submissionData.groupName }}</button>
                  </div>
                  <div class="col-md-2">
                    <b>Reference</b>
                  </div>
                  <div class="col-md-2">{{ this.submissionData.reference }}</div>
                  <div class="col-md-1">
                    <strong>Credits</strong>
                  </div>
                  <div
                    class="col-md-1 alertValueRight"
                  >{{ formatCount(importFileResponse.totalNumberOfCredits) }}</div>
                  <div
                    class="col-md-2 alertValueRight"
                  >{{ formatValue(importFileResponse.totalCreditsValue) }}</div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <b>Payment Date</b>
                  </div>
                  <div class="col-md-2">{{ displayedPaymentDate }}</div>
                  <div class="col-md-2">
                    <b>{{ submissionData.submissionType }} Processing Date</b>
                  </div>
                  <div class="col-md-2">{{ formatDate(this.selectedProcessingDate) }}</div>
                  <div class="col-md-1">
                    <strong>Debits</strong>
                  </div>
                  <div
                    class="col-md-1 alertValueRight"
                  >{{ formatCount(importFileResponse.totalNumberOfDebits) }}</div>
                  <div
                    class="col-md-2 alertValueRight"
                  >{{ formatValue(importFileResponse.totalDebitsValue) }}</div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <b>Contra Narrative</b>
                  </div>
                  <div class="col-md-2">{{ this.selectedContraNarrative }}</div>
                  <div class="col-md-2">
                    <b>Submission File</b>
                  </div>
                  <div class="col-md-2">{{ this.submissionData.filename }}</div>
                  <div class="col-md-1">
                    <strong>DDIs</strong>
                  </div>
                  <div
                    class="col-md-1 alertValueRight"
                  >{{ formatCount(importFileResponse.totalNumberOfDdis) }}</div>
                  <div class="col-md-2"></div>
                </div>
              </div>
            </div>
            <div v-if="this.fileBlocks > 1" class="row form-group">
              <div class="col-md-2">
                <b>Select File</b>
              </div>
              <div class="col-md-2">
                <b-dropdown :text="selectedFile" split variant="outline-secondary">
                  <b-dropdown-item
                    v-for="detail in fileDetails"
                    :key="detail.fileNumber"
                    :value="detail.fileNumber"
                    @click="selectFileDetail(detail)"
                  >{{ detail.filename }}</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div v-if="totalSubmissionPayments > 0" class="row form-group">
              <div class="col-md-12">
                <vue-good-table
                  ref="payments"
                  mode="remote"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-per-page-change="onPerPageChange"
                  @on-column-filter="onColumnFilter"
                  @on-row-mouseenter="onRowMouseover"
                  @on-row-mouseleave="onRowMouseleave"
                  @on-row-click="onRowClick"
                  :columns="columns"
                  :rows="rows"
                  :totalRows="totalRecords"
                  :pagination-options="{
                    enabled: true,
                    perPage: 10,
                    dropdownAllowAll: false
                  }"
                  styleClass="vgt-table striped bordered"
                >
                  <template slot="table-row" slot-scope="props">
                    <span
                      v-if="props.column.field === 'displayProcessingDate'"
                    >{{ formatDate(props.row.displayProcessingDate) }}</span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                  <div slot="emptystate">
                    Click the
                    <strong>Create Payment</strong> button or the
                    <i class="fa fa-plus-circle pointer dimmedIcon"></i> icon to create the first payment in this submission.
                  </div>
                  <div slot="table-actions">
                    <b-button
                      @click.prevent="createBacsPayment"
                      class
                      :disabled="this.groupUserRights.add === false || this.disableEditing === true"
                      variant="link"
                      v-b-popover.hover.top.d500="'Create a new payment'"
                    >
                      <i class="fa fa-plus pointer dimmedIcon"></i>
                    </b-button>
                    <b-button
                      @click.prevent="resetAmountsToZero"
                      class
                      :disabled="this.groupUserRights.edit === false || this.disableEditing === true"
                      variant="link"
                      v-b-popover.hover.top.d500="'Reset amounts to zero'"
                    >
                      <i class="fa fa-power-off dimmedIcon"></i>
                    </b-button>
                    <b-button
                      @click.prevent="saveSubmissionFile"
                      class
                      :disabled="this.bacsGroup.groupTypeDetails.importSchema === 'Standard18' || this.disableEditing === true"
                      variant="link"
                      v-b-popover.hover.top.d500="'Save submission file'"
                    >
                      <i class="fa fa-file pointer dimmedIcon"></i>
                    </b-button>
                    <b-button
                      @click.prevent="refreshGrid"
                      class
                      :disabled="this.disableEditing === true"
                      variant="link"
                      v-b-popover.hover.top.d500="'Refresh the payments in the table'"
                    >
                      <i class="fa fa-sync pointer dimmedIcon"></i>
                    </b-button>
                  </div>
                  <div slot="table-actions-bottom">
                    <div class="row" style="padding: 8px 0 8px 8px;">
                      <div class="col-md-2">
                        <button
                          class="btn btn-outline-primary"
                          :disabled="this.disableCreate === true"
                          @click.prevent="createBacsPayment"
                        >Add Payment</button>
                      </div>
                    </div>
                  </div>
                </vue-good-table>
              </div>
            </div>
            <div v-if="totalSubmissionPayments === 0">
              <br />
              <div class="row form-group">
                <div class="col-md-12">
                  Click the
                  <strong>Add Payment</strong> button to create the first payment in this submission.
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-2">
                  <button
                    class="btn btn-outline-primary"
                    :disabled="this.disableCreate === true"
                    @click.prevent="createBacsPayment"
                  >Add Payment</button>
                </div>
              </div>
            </div>
          </div>
          <!-- Footer -->
          <div class="idb-block-footer">
            <button
              type="button"
              class="btn btn-primary"
              :disabled="this.disableValidatePayments"
              v-on:click="initPresubVal"
            >Validate Payments</button>
            <button
              type="button"
              class="btn btn-outline-secondary ml-3"
              :disabled="this.validationStarted === true"
              v-on:click="changeSubmission"
            >Change Submission</button>
            <button
              type="button"
              class="btn btn-outline-secondary ml-3"
              :disabled="this.disableSubInProgress"
              v-on:click="saveInProgressSubmission"
            >Save Submission Progress</button>
            <button
              v-show="this.editAction"
              type="button"
              class="btn btn-danger pull-right ml-3"
              :disabled="this.validationStarted === true"
              v-on:click="cancelEditAction"
            >Cancel Submission</button>
            <button
              v-show="validationStarted"
              type="button"
              class="btn btn-danger pull-right"
              :disabled="this.validationCancelled === true"
              v-on:click="cancelValidation"
            >Cancel Validation</button>
            <div v-show="validationStarted" class="row form-group"></div>
            <div v-show="validationStarted" class="row form-group">
              <div class="col-md-12">
                <b-progress striped :animated="true" :max="max" show-progress class="mb-3">
                  <b-progress-bar :value="progress" :label="`${progress}%`"></b-progress-bar>
                </b-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <editPaymentModal
        v-model="isModalVisible"
        :payment="currentPayment"
        :newPayment="newPayment"
        :txCodes="txCodes"
        :isMultiDate="isMultiDate"
        :disabledDates="disabledDates"
        :selPaymentDate="selectedPaymentDate"
        :canSavePayment="canSavePayment"
        :canDeletePayment="canDeletePayment"
        :submissionReference="submissionData.reference"
        :importResponse="importFileResponse"
        :maxAmount="maxAmount"
        :networkType="submissionData.paymentNetworkType"
        @deletePayment="paymentDeleted"
        @savePayment="paymentSaved"
        @close="editCancelled"
      ></editPaymentModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import EditPaymentModal from '@/Views/Bacs/BacsPayments/EditPaymentModal.vue'
import bacsCommon from '@/Lib/BacsCommon.js'
import bacsMixin from '@/Lib/BacsMixin.js'
import moment from 'moment'
import groupTypes from '@/Assets/Constants/groupTypes'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

export default {
  name: 'viewImportedPayments',
  mixins: [
    bacsMixin
  ],
  data () {
    return {
      txCodes: {},
      bankGradeTxCodes: {},
      submissionData: {},
      importFileResponse: {},
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'No.',
          field: 'recordNumber',
          type: 'number',
          hidden: true
        },
        {
          label: 'Third Party Name',
          field: 'thirdPartyAccountName',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Sort Code',
          field: 'thirdPartySortCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Account Number',
          field: 'thirdPartyAccountNumber',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'User Reference',
          field: 'userReference',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Transaction Code',
          field: 'transactionCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Amount (£)',
          field: 'amountText',
          thClass: 'text-right',
          tdClass: 'text-right',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Processing Date',
          field: 'displayProcessingDate',
          hidden: true
        },
        {
          label: 'RTI',
          field: 'freeFormat',
          hidden: true
        },
        {
          label: 'Message',
          field: 'message',
          hidden: true
        }
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        submissionId: '',
        showPresubValMessages: this.showPresubValMessages,
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '' // 'asc' or 'desc'
        },

        page: 1, // what page I want to show
        perPage: 10 // how many items I'm showing per page
      },
      validationStarted: false,
      showPresubValMessages: false,
      editAction: false,
      validationCancelled: false,
      canStartValidation: false,
      max: 100,
      progress: 0,
      fileNumber: 1,
      fileBlocks: 0,
      fileDetails: {},
      serviceUserNumber: '',
      getTaskProgressUrl: '',
      endTaskUrl: '',
      groupBankAccount: {},
      isModalVisible: false,
      currentPayment: {},
      uneditedPayment: {},
      newPayment: false,
      isMultiDate: false,
      processingDates: {},
      selectedPaymentDate: '',
      selectedProcessingDate: '',
      displayedPaymentDate: '',
      disabledDates: {},
      disableDatePicker: true,
      selectedGroup: {},
      bacsGroup: {},
      groupUserRights: {},
      canSavePayment: false,
      canDeletePayment: false,
      row: {},
      totalSubmissionPayments: 0,
      paymentsWithPresubValIssues: 0,
      disableEditing: false,
      userDetails: {},
      paygateType: '',
      vol1ServiceUserNumber: '',
      paymentsLoaded: false,
      maxAmount: 0,
      importErrors: false,
      showImportMessages: false,
      changingSubmission: false,
      importMessagesVariant: '',
      messageHeader: '',
      messageText: '',
      helpUrl: '',
      selectedContraNarrative: '',
      savedSubmissionInProgress: false,
      selectedFile: '' // Only relevant for multi-block PGO Standard18 files.,
    }
  },
  computed: {
    disableCreate: function () {
      return this.groupUserRights.add === false || this.disableEditing === true || this.validationStarted === true || this.importErrors === true
    },
    disableSubInProgress: function () {
      return this.validationStarted || this.totalSubmissionPayments === 0 || this.importErrors
    },
    disableValidatePayments: function () {
      return this.validationStarted === true || this.totalSubmissionPayments === 0 || this.importErrors === true
    },
    displayPaymentDate: function () {
      return this.displayedPaymentDate
    }
  },
  methods: {
    formatDate: function (dateVal) {
      return moment(dateVal).format('DD/MM/YYYY')
    },

    async viewGroupDetails () {
      this.groupBankAccount = this.$store.getters.groupBankAccount
      await swal.fire({
        title: '"' + this.submissionData.groupName + '" group bank details',
        html: bacsCommon.viewGroupBankDetails(this.groupBankAccount, this.submissionData.groupName),
        type: 'info',
        animation: false
      })
    },

    initPresubVal () {
      return axios.get(this.endTaskUrl, {
      })
        .then(() => {
          this.startPresubVal()
        })
        .catch(error => console.log(error))
    },

    startPresubVal () {
      this.validationStarted = true
      this.validationCancelled = false
      this.canStartValidation = false
      this.progress = 0

      var json = JSON.stringify({
        submissionId: this.submissionData.submissionId,
        groupId: this.submissionData.groupId,
        reference: this.submissionData.reference,
        contraNarrative: this.submissionData.contraNarrative,
        paymentNetworkType: this.submissionData.paymentNetworkType,
        totalNumberOfPayments: this.importFileResponse.totalNumberOfPayments,
        paymentLimit: this.bacsGroup.groupTypeDetails.paymentLimit,
        submissionLimit: this.bacsGroup.groupTypeDetails.submissionLimit,
        serviceUserNumber: this.vol1ServiceUserNumber,
        userPaymentLimit: this.userDetails.paymentLimit,
        userSubmissionLimit: this.userDetails.submissionLimit,
        paygateType: this.paygateType,
        bankGradeGroup: this.bacsGroup.groupTypeDetails.bankGradeGroup
      })

      axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/runPresubValidation/',
        data: json
      })

      var id = setInterval(() => {
        return axios.get(this.getTaskProgressUrl, {
        })
          .then(response => {
            this.progress = response.data
            if (this.progress >= 100) {
              clearInterval(id)
              this.validationStarted = false
              return axios.get(this.endTaskUrl, {
              })
                .then(() => {
                  setTimeout(() => {
                    this.$router.push('/payments/bacspayments/preSubValidationResult').catch(err => { console.log(err) })
                  }, 1000)
                })
            } else if (this.progress === -1) {
              clearInterval(id)
              this.validationStarted = false
            }
          })
          .catch(error => console.log(error))
      }, 1000)
    },

    cancelValidation () {
      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/cancelTask?submissionId=' + this.submissionData.submissionId
      return axios.get(url, {
      })
        .then(response => {
          this.validationCancelled = true
          this.canStartValidation = true
          this.validationStarted = false
          this.$toastr.w('Pre-submission validation cancelled')
        })
    },

    async changeSubmission () {
      var result = await swal.fire({
        title: 'Change Submission?',
        text: 'Your submission details will be lost. Do you want to change the submission?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        this.changingSubmission = true
        var networkUrl = this.submissionData.submissionType === 'BACS' ? 'bacspayments' : 'fasterpayments'
        this.$router.push('/payments/' + networkUrl + '/' + this.submissionData.createPage + '?changeSubmission=true')
      }
    },

    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems(false)
    },

    onPerPageChange (params) {
      // This method is called when page mounted so only want to load items AFTER payments have been loaded and items per page changed by the user.
      if (this.paymentsLoaded === true) {
        this.rows.length = 0
        this.updateParams({ perPage: params.currentPerPage })
        this.loadItems(true)
      }
    },

    onSortChange (params) {
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type
        }
      })
      this.loadItems(true)
    },

    onColumnFilter (params) {
      this.appliedFilter = true
      this.updateParams(params)
      this.loadItems(true)
    },

    onRowMouseover (params) {
    },

    onRowMouseleave (params) {
    },

    async onRowClick (params) {
      if (this.groupUserRights.edit === false && this.groupUserRights.delete === false) {
        this.$snapbar.w('You cannot edit or delete payments in a submission linked to this group')
        return
      }

      if (this.bankGradeTxCodes.includes(params.row.transactionCode)) {
        this.$snapbar.w('You cannot edit bank grade payments')
        return
      }

      if (this.bacsGroup.groupTypeDetails.bankGradeGroup) {
        this.$snapbar.w('You cannot edit payments linked to a bank grade group')
        return
      }

      this.newPayment = false
      this.canSavePayment = this.groupUserRights.edit
      this.canDeletePayment = this.groupUserRights.delete
      this.currentPayment = params.row
      this.uneditedPayment = Object.assign({}, this.currentPayment)
      if (this.isMultiDate) {
        var paymentProcDate = this.currentPayment.processingDate.substring(3, 6) + this.currentPayment.processingDate.substring(0, 3) + this.currentPayment.processingDate.substring(6)
        var nextProcDate = this.submissionData.processingDate.substring(3, 6) + this.submissionData.processingDate.substring(0, 3) + this.submissionData.processingDate.substring(6)
        if (paymentProcDate < nextProcDate) {
          this.currentPayment.processingDate = this.submissionData.processingDate
        }
        await this.$store.dispatch('getNextPaymentDate', this.currentPayment.processingDate)
        // Date initially in mm/dd/yyyy format so need to reformat the date into dd/mm/yyyy format.
        var tmpDate = this.$store.getters.nextPaymentDate
        var ukDate = tmpDate.substring(3, 6) + tmpDate.substring(0, 3) + tmpDate.substring(6)
        this.selectedPaymentDate = new Date(ukDate)
      }

      this.isModalVisible = true
    },

    editCancelled () {
      this.currentPayment.thirdPartyAccountName = this.uneditedPayment.thirdPartyAccountName
      this.currentPayment.thirdPartyAccountNumber = this.uneditedPayment.thirdPartyAccountNumber
      this.currentPayment.thirdPartySortCode = this.uneditedPayment.thirdPartySortCode
      this.currentPayment.userReference = this.uneditedPayment.userReference
      this.currentPayment.transactionCode = this.uneditedPayment.transactionCode
      this.currentPayment.amount = this.uneditedPayment.amount
      this.currentPayment.processingDate = this.uneditedPayment.processingDate

      this.isModalVisible = false
    },

    paymentSaved () {
      if (this.newPayment) {
        this.fileDetails[this.fileNumber - 1].numberOfPayments++
        this.totalRecords = this.fileDetails[this.fileNumber - 1].numberOfPayments
        this.newPayment = false
      }
      this.loadItems(false)
      this.isModalVisible = false
      this.calcTotalPayment()
    },

    paymentDeleted () {
      this.loadItems(true)
      this.fileDetails[this.fileNumber - 1].numberOfPayments--
      this.totalRecords = this.fileDetails[this.fileNumber - 1].numberOfPayments
      this.isModalVisible = false
      this.calcTotalPayment()
    },

    async createBacsPayment () {
      if (this.groupUserRights.add === false) {
        this.$snapbar.w('You cannot add payments to a submission linked to this group')
        return
      }

      if (this.bacsGroup.groupTypeDetails.bankGradeGroup) {
        this.$snapbar.w('You cannot add payments to a submission linked to a bank grade group')
        return
      }

      this.newPayment = true
      this.canSavePayment = true
      this.canDeletePayment = false
      this.currentPayment = {}
      this.currentPayment.submissionId = this.submissionData.submissionId
      this.currentPayment.fileNumber = this.fileNumber
      this.currentPayment.recordNumber = this.fileDetails[this.fileNumber - 1].numberOfPayments + 1
      this.currentPayment.thirdPartyAccountType = '0'
      this.currentPayment.freeFormat = '0000'
      this.currentPayment.thirdPartyAccountName = ''
      this.currentPayment.thirdPartySortCode = ''
      this.currentPayment.thirdPartyAccountNumber = ''
      this.currentPayment.userReference = ''
      this.currentPayment.transactionCode = '99'
      this.currentPayment.amount = 0
      this.currentPayment.message = this.submissionData.reference

      if (this.groupBankAccount.reference === undefined) {
        this.groupBankAccount = this.$store.getters.groupBankAccount
      }

      this.currentPayment.originatorAccountName = this.groupBankAccount.reference
      this.currentPayment.originatorSortCode = this.groupBankAccount.sortCode
      this.currentPayment.originatorAccountNumber = this.groupBankAccount.accountNumber
      this.currentPayment.processingDate = this.submissionData.processingDate
      this.processingDate = this.submissionData.processingDate
      this.paymentDate = new Date(this.submissionData.paymentDate)

      if (this.isMultiDate) {
        await this.$store.dispatch('getNextPaymentDate', this.processingDate)
        var tmpDate = this.$store.getters.nextPaymentDate
        var ukDate = tmpDate.substring(3, 6) + tmpDate.substring(0, 3) + tmpDate.substring(6)
        this.selectedPaymentDate = new Date(ukDate)
      }

      this.isModalVisible = true
    },

    async resetAmountsToZero () {
      var result = await swal.fire({
        title: 'Reset Payment Amounts?',
        text: 'Do you want to reset all payment amounts to zero?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        var json = JSON.stringify({
          submissionId: this.submissionData.submissionId,
          reference: this.submissionData.reference
        })
        var resetResponse = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/payment/resetAmountsToZero/',
          data: json,
          showload: true
        })

        if (resetResponse.data.success) {
          this.$toastr.s('Payments reset to zero')
          this.importFileResponse.totalCreditsValue = 0
          this.importFileResponse.totalDebitsValue = 0
          this.$store.dispatch('setImportFileResponse', this.importFileResponse)
          this.loadItems(true)
        } else {
          this.$snapbar.e(resetResponse.data.errorMessage)
        }
      }
    },

    async saveSubmissionFile () {
      var json = JSON.stringify({
        submissionId: this.submissionData.submissionId,
        importSchema: this.bacsGroup.groupTypeDetails.importSchema,
        mappingId: this.bacsGroup.groupTypeDetails.mappingId,
        defaultFilename: this.submissionData.reference + '.txt'
      })

      try {
        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/savePaymentData/',
          data: json,
          responseType: 'blob'
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    },

    async saveInProgressSubmission () {
      if (this.submissionData.contraNarrative === null) {
        this.submissionData.contraNarrative = ''
      }

      var bacsSubmissionInProgress = JSON.stringify({
        submissionId: this.submissionData.submissionId,
        groupId: this.submissionData.groupId,
        reference: this.submissionData.reference,
        contraNarrative: this.submissionData.contraNarrative,
        paymentNetworkType: this.submissionData.paymentNetworkType,
        bankAccountId: this.groupBankAccount.bankAccountId,
        paygateType: this.paygateType,
        serviceUserNumber: this.vol1ServiceUserNumber,
        processingDate: this.submissionData.processingDate,
        paymentDate: this.submissionData.paymentDate,
        filename: this.submissionData.filename,
        vol1serviceUserNumber: this.vol1ServiceUserNumber,
        numberOfCredits: this.importFileResponse.totalNumberOfCredits,
        numberOfDebits: this.importFileResponse.totalNumberOfDebits,
        numberOfDdis: this.importFileResponse.totalNumberOfDdis,
        creditsValue: this.importFileResponse.totalCreditsValue,
        debitsValue: this.importFileResponse.totalDebitsValue,
        showPresubValMessages: this.showPresubValMessages,
        workCode: this.importFileResponse.workCode,
        selected: true
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/createInProgressSubmission/',
        data: bacsSubmissionInProgress,
        showload: true
      })

      if (response.data.status === 'Created') {
        this.$toastr.s('Saved submission progress')
        this.$store.dispatch('updateSubmissionsInProgressCount')
        this.savedSubmissionInProgress = true
      } else {
        this.$toastr.e('Failed to save submission progress')
      }
    },

    async updateInProgressSubmissionOnLeave () {
      var bacsSubmissionInProgress = JSON.stringify({
        submissionId: this.submissionData.submissionId,
        groupId: this.submissionData.groupId,
        reference: this.submissionData.reference,
        contraNarrative: this.submissionData.contraNarrative,
        paymentNetworkType: this.submissionData.paymentNetworkType,
        bankAccountId: this.groupBankAccount.bankAccountId,
        paygateType: this.paygateType,
        serviceUserNumber: this.vol1ServiceUserNumber,
        processingDate: this.submissionData.processingDate,
        paymentDate: this.submissionData.paymentDate,
        filename: this.submissionData.filename,
        vol1serviceUserNumber: this.vol1ServiceUserNumber,
        numberOfCredits: this.importFileResponse.totalNumberOfCredits,
        numberOfDebits: this.importFileResponse.totalNumberOfDebits,
        numberOfDdis: this.importFileResponse.totalNumberOfDdis,
        creditsValue: this.importFileResponse.totalCreditsValue,
        debitsValue: this.importFileResponse.totalDebitsValue,
        showPresubValMessages: this.showPresubValMessages,
        workCode: this.importFileResponse.workCode,
        selected: false
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/createInProgressSubmission/',
        data: bacsSubmissionInProgress
      })

      if (response.data.status !== 'Created') {
        console.log('Failed to save submission progress')
      }
    },

    async refreshGrid () {
      this.$refs.payments.reset()
      this.serverParams.columnFilters = {}
      this.serverParams.sort.field = ''
      this.serverParams.sort.type = ''
      this.serverParams.page = 1
      this.serverParams.perPage = 10
      await this.loadItems(true)
    },

    async loadItems (resetStart) {
      var json = JSON.stringify({
        submissionId: this.submissionData.submissionId,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        showPresubValMessages: this.showPresubValMessages,
        fileNumber: this.fileNumber,
        sortColumn: this.serverParams.sort.field,
        sortDirection: this.serverParams.sort.type,
        resetStart: resetStart,
        columnFilters: this.serverParams.columnFilters,
        editAction: this.editAction,
        paymentsWithPresubValIssues: this.paymentsWithPresubValIssues
      })

      var response = await axios.post(`${process.env.VUE_APP_BACS_API_URL}bacs/payment/get/`, json, { showload: true })
      this.rows = response.data.payments
      if (this.rows.length > 0) {
        if (response.data.filtersSet === 0) {
          this.totalRecords = this.fileDetails[this.fileNumber - 1].numberOfPayments
        } else {
          this.totalRecords = response.data.filteredRecordCount
        }

        if (this.rows[0].processingDate.length === 0) {
          this.columns[8].hidden = true
        } else {
          this.columns[8].hidden = false
          this.isMultiDate = true
        }
        if (this.rows[0].freeFormat === '0000') {
          this.columns[9].hidden = true
        } else {
          this.columns[9].hidden = false
        }

        // this.canStartValidation = true
      }

      if (this.showPresubValMessages) {
        this.columns[10].hidden = false
        this.columns[10].width = '500px'

        this.columns[2].filterOptions.enabled = false
        this.columns[3].filterOptions.enabled = false
        this.columns[4].filterOptions.enabled = false
        this.columns[5].filterOptions.enabled = false
        this.columns[6].filterOptions.enabled = false
        this.columns[7].filterOptions.enabled = false
      } else {
        this.columns[10].hidden = true
      }

      this.paymentsWithPresubValIssues = response.data.paymentsWithPresubValIssues
      this.paymentsLoaded = true
    },

    async getFileDetails () {
      if (this.submissionData.submissionId !== undefined) {
        var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/GetFileDetails?submissionId=` + this.submissionData.submissionId)
        this.fileDetails = response.data
        this.fileBlocks = this.fileDetails.length
        this.selectedContraNarrative = this.fileDetails[0].contraNarrative
        this.selectedProcessingDate = this.fileDetails[0].processingDate
        if (this.bacsGroup.groupTypeDetails.importSchema !== 'Standard18') {
          this.displayedPaymentDate = this.formatDate(this.submissionData.paymentDate)
        } else {
          await this.getPaymentDate()
        }
        if (this.fileBlocks > 1) {
          this.serviceUserNumber = this.fileDetails[0].serviceUserNumber
          this.fileNumber = this.fileDetails[0].fileNumber
          this.selectedFile = this.fileDetails[0].filename
        }
        this.calcTotalPayment()
      }
    },

    // Need to do this if editing payments after presub validation in case non DDI payments with zero amount were deleted.
    // Also call this when viewing payments from an auto imported file if payments were removed.
    async recalcTotals () {
      if (this.submissionData.submissionId !== undefined) {
        var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getIntelligibleSummaries?submissionId=` + this.submissionData.submissionId, { showload: true })
        // Recalc totals for all blocks if applicable.
        if (response !== null && response.data !== null) {
          this.importFileResponse.workCode = response.data[0].workCode
          if (!this.editAction) {
            this.importFileResponse.totalNumberOfCredits = 0
            this.importFileResponse.totalNumberOfDebits = 0
            this.importFileResponse.totalNumberOfDdis = 0
            this.importFileResponse.totalCreditsValue = 0
            this.importFileResponse.totalDebitsValue = 0
          }

          // Recalc totals for all blocks if applicable.
          for (var i = 0; i < response.data.length; i++) {
            this.importFileResponse.totalNumberOfCredits += response.data[i].creditRecordCount
            this.importFileResponse.totalNumberOfDebits += response.data[i].debitRecordCount
            this.importFileResponse.totalNumberOfDdis += response.data[i].ddiRecordCount
            this.importFileResponse.totalCreditsValue += response.data[i].creditValue
            this.importFileResponse.totalDebitsValue += response.data[i].debitValue
          }

          this.$store.dispatch('setImportFileResponse', this.importFileResponse)
        }
      }
    },

    calcTotalPayment () {
      this.totalSubmissionPayments = 0
      for (var i = 0; i < this.fileDetails.length; i++) {
        this.totalSubmissionPayments += this.fileDetails[i].numberOfPayments
      }

      this.canStartValidation = this.totalSubmissionPayments > 0 && this.importErrors === false
    },

    async selectFileDetail (detail) {
      this.serviceUserNumber = detail.serviceUserNumber
      this.fileNumber = detail.fileNumber
      this.selectedFile = detail.filename
      this.selectedContraNarrative = detail.contraNarrative
      this.selectedProcessingDate = detail.processingDate
      await this.getPaymentDate()
      this.serverParams.page = 1
      this.serverParams.columnFilters = {}
      await this.loadItems(true)
    },

    async getPaymentDate () {
      if (this.selectedProcessingDate !== null) {
        var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingDate/getPaymentDateFromProcessingDate?processingDate=` + this.selectedProcessingDate)
        this.displayedPaymentDate = response.data
      }
    },

    async getUserDetails () {
      var userId = this.$store.getters.getClaim('sub').value
      var url = process.env.VUE_APP_PLATFORM_API_URL + 'users/limits/' + userId
      var response = await axios.get(url)
      this.userDetails = response.data

      if (this.userDetails.paymentLimit === null) {
        this.userDetails.paymentLimit = 0
      }

      if (this.userDetails.submissionLimit === null) {
        this.userDetails.submissionLimit = 0
      }
    },

    async getPaygateOnlineSettings () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/admin/getPaygateOnlineServiceUserNumber?networkType=` + this.submissionData.paymentNetworkType)
      this.vol1ServiceUserNumber = response.data
    },

    async getBacsEngineConfig () {
      if (this.submissionData.paymentNetworkType !== undefined) {
        await this.$store.dispatch('getBacsEngineConfig', this.submissionData.paymentNetworkType)
        var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getPaymentLimit?paymentNetworkType=` + this.submissionData.paymentNetworkType)
        this.maxAmount = response.data
      }
    },

    async showFileImportMessages () {
      var licenceError = false

      for (var i = 0; i < this.importFileResponse.responseMessages.length; i++) {
        this.messageText += this.importFileResponse.responseMessages[i].messageText
        if (this.importFileResponse.responseMessages[i].messageTitle !== null && this.importFileResponse.responseMessages[i].messageTitle === 'LICENCE') {
          licenceError = true
          this.disableEditing = true
          this.importErrors = true
          this.$snapbar.w(this.importFileResponse.responseMessages[i].messageText)
        }
      }

      if (!licenceError) {
        this.showImportMessages = true
        if (this.importFileResponse.responseMessages[0].messageSeverity === 2) {
          this.messageHeader = 'File Import Errors'
          this.importMessagesVariant = 'danger'
          this.importErrors = true
        } else if (this.importFileResponse.responseMessages[0].messageSeverity === 1) {
          this.messageHeader = 'File Import Warnings'
          this.importMessagesVariant = 'warning'
        }
      }
    },

    async cancelEditAction () {
      var result = await swal.fire({
        title: 'Cancel Submission?',
        text: 'Are you sure you want to cancel this submission?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        var json = JSON.stringify(this.submissionData.submissionId)
        var cancelResponse = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/cancelEdit/',
          data: json,
          showload: true
        })

        if (cancelResponse.data.success) {
          this.$toastr.s('Submission edit has been cancelled')
          this.changingSubmission = true
          this.$router.push('/actions')
        } else {
          this.$snapbar.e(cancelResponse.data.errorMessage)
        }
      }
    }
  },

  async beforeRouteLeave (to, from, next) {
    if (this.validationStarted) {
      return
    }
    if (to.path.endsWith('preSubValidationResult') || this.changingSubmission) {
      next()
    } else if (this.savedSubmissionInProgress) {
      this.updateInProgressSubmissionOnLeave()
      next()
    } else {
      var result = await swal.fire({
        title: 'Navigation',
        text: 'Your submission details will be lost. Do you want to navigate away from this page?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        if (to.path.endsWith('importingpayments')) {
          this.$store.dispatch('setBrowserBackButtonClicked', true)
        }
        next()
      } else {
        next(false)
      }
    }
  },
  async mounted () {
    this.submissionData = this.$store.getters.submissionData
    this.bankGradeTxCodes = ['U1', 'U7', 'U8', 'U9', 'RA']
    if (this.submissionData.paymentNetworkType !== undefined) {
      if (this.submissionData.paymentNetworkType === 'BacstelIp' || this.submissionData.paymentNetworkType === 0) {
        this.txCodes = ['01', '17', '18', '19', '99', '0C', '0N', '0S', 'Z4', 'Z5']
      } else {
        this.txCodes = ['99', 'Z4', 'Z5']
      }
    } else {
      this.disableEditing = true
      this.canStartValidation = false
    }

    if (this.submissionData.contraNarrative !== undefined && this.submissionData.contraNarrative.length > 0) {
      this.submissionData.contraNarrative = this.submissionData.contraNarrative.toUpperCase()
    }

    this.selectedGroup = this.$store.getters.selectedGroup
    this.bacsGroup = this.$store.getters.bacsGroup
    if (this.bacsGroup.groupType !== undefined) {
      if (this.bacsGroup.groupType === groupTypes.bacs || this.bacsGroup.groupType === groupTypes.fps) {
        this.paygateType = 'Enterprise'
        this.vol1ServiceUserNumber = this.bacsGroup.groupTypeDetails.serviceUserNumber
      } else {
        this.paygateType = 'Online'
        this.getPaygateOnlineSettings()
      }
    }

    if (this.submissionData.submissionType === 'BACS') {
      if (this.submissionData.filename !== undefined && this.submissionData.filename !== null) {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/bacs-payments/createbacssubmissionfromfile/'
      } else {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/bacs-payments/createbacssubmissionusingeditor/'
      }
    } else {
      if (this.submissionData.filename !== undefined && this.submissionData.filename !== null) {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/faster-payments/createfpssubmissionfromfile/'
      } else {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/faster-payments/createfpssubmissionusingeditor/'
      }
    }

    var userId = this.$store.getters.getClaim('sub').value
    this.groupUserRights = this.bacsGroup.groupUserRights.find(i => i.userId === userId)
    var routeShowPresubValMessages = this.$route.query.showPresubValMessages
    if (routeShowPresubValMessages === undefined || routeShowPresubValMessages === 'false') {
      this.showPresubValMessages = false
    } else {
      this.showPresubValMessages = true
    }

    var routeEditAction = this.$route.query.editAction
    if (routeEditAction === undefined || routeEditAction === 'false') {
      this.editAction = false
    } else {
      this.editAction = true
    }

    this.getTaskProgressUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/GetTaskProgress?submissionId=' + this.submissionData.submissionId
    this.endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + this.submissionData.submissionId

    // Show the user any file import issues.
    this.importFileResponse = this.$store.getters.importFileResponse
    if (Object.entries(this.importFileResponse).length > 0 && this.importFileResponse.constructor === Object) {
      if (this.importFileResponse.responseMessages !== undefined &&
        this.importFileResponse.responseMessages !== null &&
        this.importFileResponse.responseMessages.length > 0 &&
        this.showPresubValMessages === false) {
        this.showFileImportMessages()
      }

      if (this.showPresubValMessages || this.editAction) {
        this.recalcTotals()
      }
    }

    await this.getBacsEngineConfig()
    await this.getUserDetails()
    await this.getFileDetails()
    await this.loadItems(true)
    this.processingDates = this.$store.getters.processingDates
    this.selectedPaymentDate = new Date(this.processingDates.minPaymentDate)
    // this.selectedProcessingDate = this.processingDates.nextProcessingDate

    if (this.processingDates.nonProcessingDates !== undefined) {
      // Disable weekends in datepicker.
      this.disabledDates.days = [6, 0]
      // Disable any dates before the earliest possible payment date.
      this.disabledDates.to = this.selectedPaymentDate
      // Disable any dates more than 31 days into the future.
      var maxDate = new Date(this.processingDates.maxPaymentDate)
      this.disabledDates.from = maxDate
      // Disable any other non-processing days i.e bank holidays.
      if (this.processingDates.nonProcessingDates.length > 0) {
        var npds = []
        for (var j = 0; j < this.processingDates.nonProcessingDates.length; j++) {
          npds.push(new Date(this.processingDates.nonProcessingDates[j]))
        }
        this.disabledDates.dates = npds
      }
    }
  },

  components: {
    editPaymentModal: EditPaymentModal
  }
}
</script>
