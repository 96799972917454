git@gitlab.com:paygateuk/jftp/platform/platform-jftp.git<template>
  <div>
    <div class="d-flex justify-content-between mb-20">
      <h5 class="m-0">Customers Added</h5>
    </div>
    <div style="position:relative; height: 350px !important">
      <line-chart :chart-data="chartData" :options="chartOptions"  :styles="{ height: '100%' }"></line-chart>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import LineChart from '@/Assets/Components/Charts/LineChart'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import roles from '@/Assets/Constants/roles'
import {mapGetters} from "vuex";

export default {
  components: {
    LineChart,
    VuePerfectScrollbar
  },
  name: 'PayersAddedOverTimeWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  watch: {
    'widget.options': {
      async handler () {
        await this.load()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    txDateRange () {
      return this.widget.options.find(o => o.label === 'Date Range').value
    },
    numberOfDays () {
      return this.widget.options.find(o => o.label === 'Number of Days').value
    },
    selectedWidgetCustomer () {
      return this.widget.options.find(o => o.label === 'Client').value !== undefined ? this.widget.options.find(o => o.label === 'Client').value : this.selectedCustomer
    },
    isSystemUser () { return this.$store.getters.isInRole(roles.SystemUser) || this.$store.getters.isInRole(roles.Finance) }
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Customers Added'
            }
          }],
          xAxes: [{
            ticks: {
              callback: function (value, index, values) {
                return value.substring(0, 5)
              }
            },
            gridLines: {
              display: false
            },
            categoryPercentage: 0.5,
            barPercentage: 0.8
          }]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return ''
            },
            title: function (chart, data) {
              // var value = chart[0].yLabel // data.datasets[chart[0].index].data[chart[0].index]
              // console.log('title value: ' + value)
              // return data.labels[chart[0].index].substring(3, 13)
              return 'New Customers: ' + chart[0].yLabel
            }
          }
        },
        onHover: (e, item) => {
          if (item.length !== 0) {
            e.target.style.cursor = 'pointer'
          } else {
            e.target.style.cursor = 'default'
          }
        }
      },
      chartData: {},
      settings: {
        maxScrollbarLength: 60
      },
      submissionCountData: {},
      chartTitle: ''
    }
  },

  methods: {
    async load () {
      if (this.selectedWidgetCustomer === null) {
        this.$toastr.e('Please select a client')
        return
      }

      if (this.numberOfDays === '') {
        this.widget.options.find(o => o.label === 'Number of Days').value = 0
      }

      if (this.numberOfDays < 0 || this.numberOfDays > 90) {
        this.$toastr.e('Number of Days must be between 0 and 90')
        return
      }

      var queryParams = `?txDateRange=${this.txDateRange}&numberOfDays=${this.numberOfDays}&selectedCustomerId=${this.selectedWidgetCustomer}`
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}widget/getpayersadded${queryParams}`)
      this.submissionCountData = response.data
      if (this.submissionCountData === null || this.submissionCountData.length === 0) {
        return
      }

      this.chartTitle = this.submissionCountData[0].chartTitle
      var labels = []
      var data = []
      this.dates = []

      for (var i = 0; i < this.submissionCountData.length; i++) {
        labels.push(this.submissionCountData[i].dateAsString + this.formatValue(this.submissionCountData[i].value))
        data.push(this.submissionCountData[i].count)
      }

      this.chartData = {
        labels: labels,
        datasets: [{
          data: data,
          label: 'New Customers', // 'Debit Transactions',
          backgroundColor: '#456aa5',
          fill: false,
          borderColor: '#456aa5',
          lineTension: 0 // Shows straight lines between the points; remove this option to show curves.
        }]
      }
    },
    formatValue (value) {
      try {
        return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } catch (e) {
        return 0
      }
    },

    formatCount (count) {
      try {
        return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '$&,')
      } catch (e) {
        return 0
      }
    }
  },

  async mounted () {
    await this.load()
  }
}
</script>
