<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Sage Report</h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Earliest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="endPaymentDatePicker"
              v-model="start"
              :format="formatDate" @input="filtersChanged()" :disabledDates="{ from: new Date(end) }" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Latest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="startPaymentDatePicker"
              v-model="end"
              :format="formatDate" :disabledDates="{ to: new Date(start) }"
              @input="filtersChanged()" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            SA Nominal Code
          </div>
          <div class="col-md-4">

              <input type="text" id="saInput"
                v-model="saCode" maxlength="8"
                @change="load()" class="form-control"
              />
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            JC Nominal Code
          </div>
          <div class="col-md-4">
              <input id="jcInput"
                v-model="jcCode" maxlength="8"
                @change="load()" class="form-control"
              />
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            JD Nominal Code
          </div>
          <div class="col-md-4">
            <input id="jdInput"
              v-model="jdCode" maxlength="8"
              @change="load()" class="form-control"
            />

          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Client Account Default
          </div>
          <div class="col-md-4">
            <input id="jdInput"
                   v-model="customerAccountDefault"
                   @change="load()" class="form-control"
            />

          </div>
        </div>
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import Datepicker from 'vuejs-datepicker'
  import papa from 'papaparse'
  import moment from 'moment'
  import {mapGetters} from "vuex";

  export default {
    mixins: [tableFilterMixin, loading],
    components: {
      Datepicker
    },
    data () {
      return {
        saCode: '',
        jcCode: '',
        jdCode: '',
        customerAccountDefault: '',
        dateOptions: {
          format: 'DD/MM/YYYY HH:mm',
          useCurrent: false,
          stepping: 30,
          sideBySide: true,
        },
        end: null,
        start: null,
        rows: [],
        columns: [
          {
            label: 'Sage Type',
            field: 'sagetype'
          },
          {
            label: 'Client Account',
            field: 'account'
          },
          {
            label: 'Nominal Code',
            field: 'nc'
          },
          {
            label: 'Blank',
            field: 'blank'
          },
          {
            label: 'Transaction Date',
            field: 'transactionDate',
            formatFn: this.formatDate
          },
          {
            label: 'Reference',
            field: 'reference'
          },
          {
            label: 'Description',
            field: 'description'
          },
          {
            label: 'Amount',
            field: 'amount',
            formatFn: this.formatAmount
          },
          {
            label: 'Tax Code',
            field: 'taxCode'
          },
          {
            label: 'VAT Code',
            field: 'vat'
          },
          {
            label: 'Currency',
            field: 'currency'
          },
          {
            label: 'Data Source',
            field: 'dataSource'
          }
        ],
        serverParams: {
          sort: [{ field: 'transactionDate', type: 'desc' }]
        }
      }
    },
    mounted () {
      this.end = new Date()
      this.end.setHours(23)
      let tempStart = new Date()
      tempStart.setDate(this.end.getDate() - 7)
      tempStart.setHours(0)
      this.start = tempStart
      this.load()
    },
    methods: {
      async filtersChanged () {
        await this.clearTableFilters()
      },
      sageTypeToText (val) {
        let retVal = ''
        switch (val) {
          case 0:
            retVal = 'SA'
            break
          case 1:
            retVal = 'JC'
            break
          case 2:
            retVal = 'JD'
            break
        }
        return retVal
      },
      load: async function () {
        try {
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/sagereport`, { params: { ...this.buildGoodTableQuery(), startDate: this.start, endDate: this.end, jcCode: this.jcCode, jdCode: this.jdCode, saCode: this.saCode, customerAccountDefault: this.customerAccountDefault }, validateStatus: () => true })
          if (response.status === 200) {
            this.rows = response.data.data
            this.totalRecords = response.data.count
          } else {
            if (response.status === 403) {
              this.$toastr.e('Not authorized to use this feature.')
              this.rows = []
              this.totalRecords = 0
            } else {
              this.$toastr.e('An error has occurred.')
            }
          }
        } catch (error) {
          console.log(error)
          this.$toastr.e(error)
        }
      },
      onRowChange (e) {
        // console.log(e)
        // this.$router.push({ path: `/collections/payer/${e.row.ukPayerId}/edit`, params: { openAt: 'messages'} })
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
      formatStatus (value) {
        let retVal = ''
        switch(value) {
          case 1:
            retVal = 'Created'
            break
          case 2:
            retVal = 'In Progress'
            break
          case 3:
            retVal = 'Resolved'
            break
          case 4:
            retVal = 'Rejected'
            break
          case 5:
            retVal = 'Action Required'
            break
          default:
            retVal = 'None'
            break
        }
        return retVal
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      formatDateWithNull (date) {
        if (date===null || date === '0001-01-01T00:00:00') {
          return 'N/A'
        } else {
          return this.formatDate(date)
        }
      },
      async exportTable () {
        var query = this.buildGoodTableQuery()
        var minDate = moment(this.end).format('DD-MM-YYYY')
        var maxDate = moment(this.start).format('DD-MM-YYYY')
        query.perPage = this.totalRecords
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/sagereport`, { params: {
          startDate: this.start,
          endDate: this.end,
          ...query, paygateId: this.paygateId, jcCode: this.jcCode, jdCode: this.jdCode, saCode: this.saCode, forExport: true, customerAccountDefault: this.customerAccountDefault }, showload: true })
        var csvString = papa.unparse(response.data, { header:true })
        var blob = new Blob([csvString])
        let filename = `sage-report-${maxDate}-to-${minDate}.csv`
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob,filename )
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = filename
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      codeToDescription (value) {
        let retval = ''
        switch (value) {
          case 1:
            retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
            break;
          case 2:
            retval = 'NO ADVANCE NOTICE WAS RECEIVED';
            break;
          case 3:
            retval = 'BANK CANCELS THE DIRECT DEBIT';
            break;
          case 4:
            retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
            break;
          case 5:
            retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
            break;
          case 6:
            retval = 'FRAUDULENT SIGNATURE';
            break;
          case 7:
            retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
            break;
          case 8:
            retval = 'SERVICE USER IS NOT RECOGNISED';
            break;
          default:
            retval = 'Invalid Code Supplied'
            break;
        }
        return retval
      },
    },
    computed: {
      ...mapGetters(['selectedCustomer']),
      startDateOptions () {
        return { ...this.dateOptions, maxDate: this.end }
      },
      endDateOptions () {
        return { ...this.dateOptions, minDate: this.start }
      },
    },
    watch: {
      selectedCustomer () {
        this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
        this.load()
      }
    }
  }
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
