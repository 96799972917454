<template>
  <div>
    <div class="alert alert-danger" v-if="hasErrors">
      <p v-for="(error, index) in errors" :key="index">{{error}}</p>
    </div>
    <form @submit.prevent="checkValidation() && save()" novalidate>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            Inactivity Timeout Policy
            <help-icon docPath="/administration/securitypolicy/inactivitytimeoutpolicy/" />
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="form-group row">
            <div class="col-md-10 offset-md-1">
              <p>If a user's session is inactive for a period of time London & Zurich will automatically log the user out of the system. This is known as the 'Inactivity Timeout' and the value can be set here</p>
              <hr />
            </div>
          </div>

          <div class="form-group row" :class="{invalid: $v.policy.timeoutMinutes.$error}">
            <label class="col-form-label col-md-3 offset-md-1">Inactivity Timeout (minutes)</label>
            <div class="col-md-2">
              <input
                type="number"
                min="1"
                class="form-control"
                v-model="$v.policy.timeoutMinutes.$model"
                :disabled="disabledDueToConnectedMerchant || readOnly "
              />
              <validation-messages v-model="$v.policy.timeoutMinutes" name="timeout"></validation-messages>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <button
            class="btn btn-primary"
            type="submit"
            :disabled="isLoading || disabledDueToConnectedMerchant || readOnly "
          >Save</button>
          <button
            class="btn btn-outline-warning ml-3"
            type="button"
            @click="reset"
            :disabled="isLoading|| disabledDueToConnectedMerchant || readOnly "
          >Reset to default</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { required, minValue, maxValue, numeric } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import loading from '@/Assets/Mixins/LoadingMixin'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import ConnectedMerchantReadOnlyMixin from '@/Assets/Mixins/ConnectedMerchantReadOnlyMixin'
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [DataLeaveMixin, loading, ConnectedMerchantReadOnlyMixin],
  computed: {
    readOnly () {
      if (this.$store.getters.isInRole(roles.DevOps)) {
        return false
      }
      return true
    },
    hasErrors () { return this.errors.length > 0 },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () {
      this.load()
    }
  },
  data () {
    return {
      errors: [],
      policy: {
        timeoutMinutes: 20
      }
    }
  },
  async created () {
    await this.load()
  },
  methods: {
    async save () {
      try {
        this.errors = []
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/InactivityTimeout`, this.policy,
          { showload: true, showerror: true, errormessage: 'Inactivity timeout failed to save' })
        this.$toastr.s('Inactivity Timeout policy changes have been saved', 'Saved')
      } catch (e) {
        if (e.response.status === 422) {
          this.errors = e.response.data
          this.$toastr.e('There are errors on the page, please see the top for more information', 'Validation Error')
        }
      } finally {
        this.$v.$reset()
      }
    },
    async reset () {
      this.$v.$touch()
      this.policy = {
        timeoutMinutes: 20
      }
      this.errors = []
      this.$toastr.i('Inactivity Timeout policy changes have been reset, please save to apply', 'Reset')
    },
    async load () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/InactivityTimeout`,
          { showload: true, showerror: true, errormessage: 'Inactivity timeout failed to load' })
        this.policy = response.data
      } catch { }
    }
  },
  validations: {
    policy: {
      timeoutMinutes: {
        required,
        numeric,
        minValue: minValue(10),
        maxValue: maxValue(240)
      }
    }
  }
}
</script>
