<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        Pre-Submission Validation for '{{ this.submissionDetails.submissionReference }}'
        <help-icon></help-icon>
        <favourite-icon></favourite-icon>
      </h2>
    </div>

    <!-- Summary Information -->
    <div class="ml-5 mt-3 mb-3">
      <b-row>
        <b-col sm="2">Submission Reference</b-col>
        <b-col sm="2">{{ this.submissionDetails.submissionReference }}</b-col>
        <b-col sm="2">Total number of debits</b-col>
        <b-col sm="2">{{ this.totalDebitCount | number }}</b-col>
        <b-col sm="2">Total number of DDI</b-col>
        <b-col sm="2">{{ this.totalDDICount | number }}</b-col>
      </b-row>
      <b-row>
        <b-col sm="2">Contra Narrative:</b-col>
        <b-col sm="2">{{ this.submissionDetails.contraNarrative }}</b-col>
        <b-col sm="2">Total value of debits</b-col>
        <b-col sm="2">{{ this.totalDebitValue | currency }}</b-col>
      </b-row>
      <b-row>
        <b-col sm="2">Payment Date</b-col>
        <b-col sm="2">{{ this.submissionDetails.paymentDate | formatDate('DD/MM/YYYY') }}</b-col>
        <b-col sm="2">Total number of credits</b-col>
        <b-col sm="2">{{ this.totalCreditCount | number }}</b-col>
      </b-row>
      <b-row>
        <b-col sm="2">Processing Date</b-col>
        <b-col sm="2">{{ this.submissionDetails.processingDate | formatDate('DD/MM/YYYY')}}</b-col>
        <b-col sm="2">Total value of credits</b-col>
        <b-col sm="2">{{ this.totalCreditValue | currency }}</b-col>
      </b-row>
      <b-row>
        <b-col sm="2">Submission Status</b-col>
        <b-col sm="2">{{ this.bureauSubmissionStatus }}</b-col>
        <!-- <b-col sm="2" v-if="bureauSubmissionStatus!='Validated'" >
          <button class="btn btn-primary btn-small" @click.stop="onValidateClick()">Click to Validate</button>
        </b-col>-->
      </b-row>
      <b-row></b-row>
      <br />
      <b-row>
        <b-col sm="2">Must Fix Errors</b-col>
        <b-col sm="2">{{ this.numberOfMustFix | number }}</b-col>
      </b-row>
      <b-row>
        <b-col sm="2">Warnings</b-col>
        <b-col sm="2">{{ this.numberOfWarnings | number }}</b-col>
      </b-row>
      <b-row>
        <b-col sm="2">Duplicates</b-col>
        <b-col sm="2">{{ this.numberOfDuplicates | number }}</b-col>
      </b-row>
      <b-row>
        <b-col sm="2">Information Messages</b-col>
        <b-col sm="2">{{ this.numberOfMessages | number }}</b-col>
      </b-row>
    </div>

    <hr class="mt-2" v-if="validationMessages.length>0" />

    <!-- Validation Messages -->
    <div class="ml-5 mr-5" v-if="validationMessages.length>0">
      <b-row>
        <b-col sm="12">
          <b-table
            class="mt-2"
            borderless
            small
            caption-top
            :striped="true"
            :items="validationMessages"
            :fields="validationFields"
            :per-page="10"
          >
            <template v-slot:cell(messageSeverity)="data">
              <span v-bind:class="data.value" class="badge badge-pill table-pill">{{ data.value }}</span>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>

    <div class="idb-block-footer">
      <b-row class="ml-2">
        <b-col sm="12">
          <button
            class="btn btn-primary ml-2"
            @click="onViewPaymentsClick()"
            :disabled="isLoading"
          >View Payments</button>

          <button
            class="btn btn-success ml-2"
            :disabled="!(numberOfMustFix==0) || isLoading"
            @click.stop="onSubmitClick()"
          >Process Submission</button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import Utility from '@/Assets/Mixins/Utility'
import loading from '@/Assets/Mixins/LoadingMixin'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'

export default {
  mixins: [Utility, loading],
  data () {
    return {
      totalDebitCount: 0,
      totalDebitValue: 0.00,
      totalCreditCount: 0,
      totalCreditValue: 0.00,
      totalDDICount: 0,
      numberOfMustFix: 0,
      numberOfWarnings: 0,
      numberOfMessages: 0,
      numberOfDuplicates: 0,
      submissionDetails: [],
      bureauSubmissionStatus: '',
      percentComplete: 0,
      submissionId: '',
      hubUrl: '',
      validationMessages: [],
      validationFields: [
        {
          key: 'fileNumber',
          label: 'File'
        },
        {
          key: 'title',
          label: 'Title'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'messageSeverity',
          label: 'Severity'
        }
      ]
    }
  },
  props: {

  },
  beforeDestroy () {
    if (this.statusConnection !== null) {
      this.statusConnection.stop()
    }
  },
  created () {
    this.submissionId = this.$route.params.id
    console.log('presubvalidation vue submissionid', this.submissionId)
  },
  async mounted () {
    this.hubUrl = process.env.VUE_APP_BUREAU_API_URL + 'hubs/bureaustatushub?paygateId=' + this.paygateId
    var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getSubmissionData/' + this.submissionId, { showload: true })
    console.log('loadSubmissionDetails response', response)
    this.submissionDetails = response.data

    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        this.statusConnection = new HubConnectionBuilder().withUrl(this.hubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.statusConnection.start()

        this.statusConnection.on('Refresh', data => {
          console.info('Refresh')
          console.info(data)
          if (data.hasOwnProperty('submissionValidationSummary')) {
            this.numberOfMustFix = data.submissionValidationSummary.numberOfMustFix
            this.numberOfWarnings = data.submissionValidationSummary.numberOfWarnings
            this.numberOfMessages = data.submissionValidationSummary.numberOfMessages
            this.numberOfDuplicates = data.submissionValidationSummary.numberOfDuplicates
            this.percentComplete = data.submissionValidationSummary.bureauSubmissionStatus
            this.percentComplete = data.submissionValidationSummary.percentComplete
            if (!data.submissionValidationSummary) {
              this.validationMessages = data.submissionValidationSummary.validationMessages
            }
          }
        })
      })

    window.addEventListener('beforeunload', this.stopSocketListener)

    await this.getSummaryInfo()
    await this.getValidationResults()
  },
  methods: {
    async getSummaryInfo () {
      try {
        console.info('getSummaryInfo')
        const getsubmissioninforesponse = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getsubmissioninfo/' + this.submissionId, { showload: true })
        console.info(getsubmissioninforesponse)
        this.totalDebitCount = getsubmissioninforesponse.data.debitCount
        this.totalDebitValue = getsubmissioninforesponse.data.debitValue
        this.totalCreditCount = getsubmissioninforesponse.data.creditCount
        this.totalCreditValue = getsubmissioninforesponse.data.creditValue
        this.totalDDICount = getsubmissioninforesponse.data.ddiCount
      } catch (error) {
        console.error('getSummaryInfo')
        console.error(error)
      }
    },
    async getValidationResults () {
      console.info('getValidationResults')
      const response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getvalidationinfo/' + this.submissionId, { showload: true })
      console.info(response)
      this.numberOfMustFix = response.data.numberOfMustFix
      this.numberOfWarnings = response.data.numberOfWarnings
      this.numberOfMessages = response.data.numberOfMessages
      this.numberOfDuplicates = response.data.numberOfDuplicates
      this.validationMessages = response.data.validationMessages
      this.bureauSubmissionStatus = response.data.bureauSubmissionStatus
    },
    async onValidateClick () {
      try {
        console.info('Validate')
        console.log(this.submission)
        const data = {
          bureauSubmissionId: this.submissionId
        }
        await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/validatesubmission', data, { showload: true })
        await this.getValidationResults()
        await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/endTask?submissionId=' + this.submissionId + '&fileNumber=0', { showload: true })
        this.$toastr.s(`Bureau Submission ${this.submissionDetails.submissionReference} validated`, 'Bureau Submission')
      } catch (error) {
        console.error('Error in validate')
        console.error(error)
      }
    },
    onViewPaymentsClick () {
      // Show validation results?
      this.$router.push({ name: 'PaymentDetails' })
    },
    async onSubmitClick () {
      console.info('Save the submission')
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/startsubmission', { submissionId: this.submissionId }, { showload: true })
      console.info('save sub response', response)
      if (response.data.toastType === 2) {
        this.$toastr.s(`Bureau Submission ${this.submissionDetails.submissionReference} created`, 'Bureau Submission')
        // Go off to the Bacs Actions here
        var actionUrl = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getAction?submissionId=' + this.submissionId, { showload: true })
        if (actionUrl.data.tag) {
          console.log('making route json')
          var routeJson = {}
          routeJson.id = actionUrl.data.messageId
          routeJson.actionedObjectId = this.submissionId
          routeJson.actionClass = 'Bacs'
          routeJson.actionSubClass = 'Sign'
          var testReturnRouteObject = returnRouteObject(routeJson)
          console.log('testReturnRouteObject', testReturnRouteObject)
          this.$router.replace(returnRouteObject(routeJson))
        } else {
          this.autoReturnToHomePage = true
          this.$router.push('/')
        }
      } else { this.$toastr.e('Could not submit Bureau Submission') }
    }
  }
}
</script>

<style scoped>
.btn-secondary,
.btn-secondary:active,
.btn-secondary:hover,
.btn-secondary:focus {
  background: linear-gradient(#f4f5f8, #f1f3f6);
  border: 0px solid #dcdfe6;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  border-radius: 0;
}

.progress,
.progress-bar {
  height: 2rem;
}
</style>
