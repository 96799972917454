<template>
    <div>
        <div class="top mt-4">
          <div class="container-fluid clearfix">
            <div class="row">
              <div class="col-sm" v-if="topButtons">
                  <template v-if="topButtons">
                    <b-button  variant="primary" class="pull-left"  v-if="currentStep.index > 0" :disabled="isLoading" @click="backStep()">
                        <i class="fas fa-angle-left mr-2"></i>
                    </b-button>
                  </template>
              </div>
              <template v-for="(step, index) in steps">
                <div class="col-sm" :class="['step', isStepActive(index, step)]" :key="index" :style="{width: `${100 / steps.length}%`}">
                    <div class="circle">
                        <i :class="step.completed ? 'fas fa-check' : step.icon"></i>
                    </div>
                    <div class="step-title mt-3">
                        <h4>{{step.title}}</h4>
                        <h5 class="step-subtitle">{{step.subtitle}}</h5>
                    </div>
                </div>
              </template>
              <div class="col-sm" v-if="topButtons">
                <b-button class="pull-right" variant="primary" v-if="topButtons" :disabled="isLoading" @click="nextStep()">
                  <i class="fas fa-angle-right ml-2"></i>
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="idb-block-content">
          <transition :enter-active-class="enterAnimation" :leave-active-class="leaveAnimation" mode="out-in">
            <!--If keep alive-->
            <keep-alive v-if="keepAliveData">
              <component :is="steps[currentStep.index].component" :clickedNext="nextButton[currentStep.name]" @can-continue="proceed" @change-next="changeNextBtnValue" :current-step="currentStep"></component>
            </keep-alive>
            <!--If not show component and destroy it in each step change-->
            <component v-else :is="steps[currentStep.index].component" :clickedNext="nextButton[currentStep.name]" @can-continue="proceed" @change-next="changeNextBtnValue" :current-step="currentStep"></component>
          </transition>
        </div>
        <div class="idb-block-footer noprint-footer clearfix mt-4">
          <b-button variant="danger" :disabled="isLoading" class="pull-left" @click="cancel()">
            <i class="fas fa-times mr-2"></i>
            <span>{{ 'Cancel' }}</span>
          </b-button>
          <b-button variant="primary" v-if="currentStep.index > 0" :disabled="isLoading" class="pull-left ml-2" @click="backStep()">
            <i class="fas fa-angle-left mr-2"></i>
            <span>{{ 'Back' }}</span>
          </b-button>
          <b-button variant="primary" :disabled="!canContinue || isLoading" class="pull-left ml-2" @click="nextStep()">
            <span>{{ (finalStep) ? 'Save and Finish' : 'Save and Continue' }}</span>
            <i class="fas fa-angle-right ml-2"></i>
          </b-button>
        </div>
    </div>
</template>

<script>
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  props: {
    locale: {
      type: String,
      default: 'en'
    },
    topButtons: {
      type: Boolean,
      default: false
    },
    steps: {
      type: Array,
      default () {
        return [
          {
            icon: 'mail',
            name: 'first',
            title: 'Sample title 1',
            subtitle: 'Subtitle sample',
            getter: 'getter'
          },
          {
            icon: 'report_problem',
            name: 'second',
            title: 'Sample title 2',
            subtitle: 'Subtitle sample',
            getter: 'getter'
          }
        ]
      }
    },
    keepAlive: {
      type: Boolean,
      default: true
    },
    reset: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      currentStep: {},
      previousStep: {},
      nextButton: {},
      //canContinue: false,
      finalStep: false,
      keepAliveData: this.keepAlive
    }
  },
  computed: {
    canContinue () {
      if (this.currentStep === null || this.currentStep === undefined) {
        return true;
      }
      return this.$store.getters[this.currentStep.getter]
    },
    enterAnimation () {
      if (this.currentStep.index < this.previousStep.index) {
        return 'animated quick fadeInLeft'
      } else {
        return 'animated quick fadeInRight'
      }
    },
    leaveAnimation () {
      if (this.currentStep.index > this.previousStep.index) {
        return 'animated quick fadeOutLeft'
      } else {
        return 'animated quick fadeOutRight'
      }
    }
  },

  methods: {
    isStepActive (index, step) {
      if (this.currentStep.index === index) {
        return 'activated'
      } else {
        return 'deactivated'
      }
    },
    activateStep (index, back = false) {
      if (this.steps[index]) {
        this.previousStep = this.currentStep
        this.currentStep = {
          name: this.steps[index].name,
          index: index,
          getter: this.steps[index].getter
        }
        if (index + 1 === this.steps.length) {
          this.finalStep = true
        } else {
          this.finalStep = false
        }
        if (!back) {
          this.$emit('completed-step', this.previousStep)
        }
      }
      this.$emit('active-step', this.currentStep)
    },
    nextStepAction () {
      this.nextButton[this.currentStep.name] = true
      if (this.canContinue) {
        if (this.finalStep) {
          this.$emit('stepper-finished', this.currentStep)
        }
        let currentIndex = this.currentStep.index + 1
        this.activateStep(currentIndex)
      }
      //this.canContinue = false
      this.$forceUpdate()
    },
    nextStep () {
      if (!this.$listeners || !this.$listeners['before-next-step']) {
        this.nextStepAction()
      }
      //this.canContinue = false
      this.$emit('before-next-step', { currentStep: this.currentStep }, (next = true) => {
        //this.canContinue = true
        if (next) {
          this.nextStepAction()
        }
      })
    },
    backStep () {
      //this.canContinue = false
      this.$emit('clicking-back')
      let currentIndex = this.currentStep.index - 1
      if (currentIndex >= 0) {
        this.activateStep(currentIndex, true)
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    proceed (payload) {
      //this.canContinue = payload.value
    },
    changeNextBtnValue (payload) {
      this.nextButton[this.currentStep.name] = payload.nextBtnValue
      this.$forceUpdate()
    },
    init () {
      // Initiate stepper
      this.activateStep(0)
      this.steps.forEach(step => {
        this.nextButton[step.name] = false
      })
    }
  },
  watch: {
    reset (val) {
      if (!val) {
        return
      }
      this.keepAliveData = false
      this.init()
      this.previousStep = {}
      this.$nextTick(() => {
        this.keepAliveData = this.keepAlive
        this.$emit('reset', true)
      })
    }
  },
  created () {
    this.init()
  }
}
</script>
<style>
  .circle {
    font-size: 1.8rem;
    display: block;
    text-align: center;
  }
    .circle i {
      background-color: #508CFC;
      border-color:#508CFC;
      color: #ffffff;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      padding-top: 8px;
    }

  .deactivated .circle i {
    background-color: #6c757d;
  }

  .step-title {
    text-align: center;
    font-size: 1.125rem;
    opacity: .35;
  }

  .divider-line {
    border-bottom: 1px solid #cccccc;
    height: 2px;
  }
 </style>
