<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Collections Report</h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Earliest Collection Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="endPaymentDatePicker"
              v-model="start"
              :format="formatDate" @input="filtersChanged()" :disabledDates="{ from: new Date(end) }" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Latest Collection Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="startPaymentDatePicker"
              v-model="end"
              :format="formatDate"
              @input="filtersChanged()" :disabledDates="{ to: new Date(start) }" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">
            Collection Status
          </div>
          <div class="col-md-4">
            <b-form-select
              v-model="statusFilter"
              :options="statae"
              required
              value-field="value"
              text-field="name"
              @change="filtersChanged()"
            />
          </div>
        </div>
        <div class="vgt-selection-info-row clearfix" v-if="representRows.length > 0">
          {{selectionInfo}}
          <a href @click.prevent="clearSelections">Clear</a>
          <div class="vgt-selection-info-row__actions vgt-pull-right">
            <slot name="extra-select-info"></slot>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          class="scroll-table"
          :columns="columns"
          @on-selected-rows-change="representRowSlected"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
            <p-check
              id="sentCheck"
              class="p-switch p-fill"
              color="primary"
              v-model="showSchedules"
              @change="load"
            >Show Schedules</p-check>
            <p-check v-if="canDoGiftAid"
              id="sentCheck"
              class="p-switch p-fill"
              color="primary"
              v-model="onlyShowGiftAid"
              @change="load"
            >Only Show Gift Aid</p-check>
          </div>
          <template slot="table-column" slot-scope="props">
           <span v-if="props.column.label === 'Represent'">
              <input type="checkbox" v-model="representable" @change="addAllToRepresent" v-b-popover.hover.top.d500="'Select representable collections.'" />
           </span>
            <span v-else>
              {{props.column.label}}
           </span>
          </template>
          <template slot="table-row" slot-scope="props">
          <span v-if="props.column.label === 'Represent'">
            <input v-if="props.row.canRepresent" type="checkbox" v-model="props.row.selected"  :disabled="!props.row.canRepresent"  @change="addToRepresent(props.row)" v-b-popover.hover.top.d500="'Represent collection.'" />
            <i  v-if="!props.row.canRepresent" class="fa fa-solid fa-ban"  v-b-popover.hover.top.d500="'Cannot represent'"></i>
          </span>
            <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
          </template>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">
        <b-btn id="addPayerButton" type="link" variant="primary" @click="rePresent" :disabled="representRows.length === 0">
          <i class="fa fa-plus mr-2"></i>Represent {{ representRows.length > 0 ? representRows.length : '' }} Selected Collections
        </b-btn>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import Datepicker from 'vuejs-datepicker'
  import papa from 'papaparse'
  import moment from 'moment'
  import PrettyCheck from 'pretty-checkbox-vue/check'
  import {mapGetters} from "vuex";
  export default {
    mixins: [tableFilterMixin, loading],
    components: {
      Datepicker,
      pCheck: PrettyCheck
    },
    data () {
      return {
        statusFilter: -1,
        statae: [
          {value: -1, name: 'All' },
          { value: 5, name:  'Being Processed' },
          /*{ value: 19,  name:  'Cancelled' },
          { value: 10, name:  'Deleted' },*/
          { value: 16, name:  'Due' },
          { value: 2, name:  'Error' },
          { value: 3, name:  'Failed' },
          /*{ value: 12, name: 'Not Attempted' },*/
          { value: 99, name:  'Pending' },
         /* { value: 15, name:  'Pending: Awaiting DDI Lodgement' },*/
          { value: 4, name:  'Submitted' },
          { value: 17, name:  'Successful' },
          /*{ value: 1, name:  'Suspended' },*/
          /* { value: 0, name:  'Closed' },*/
          /* { value: 11, name:  'Refunded' },*/
          { value: 14, name:  'Unprocessed' },
          { value: 18, name:  'Unsubmitted' },
          /*{ value: 13, name:  'Will Not Attempt' }*/
        ],
        canDoGiftAid: false,
        showSchedules: true,
        dateOptions: {
          format: 'DD/MM/YYYY HH:mm',
          useCurrent: false,
          stepping: 30,
          sideBySide: true,
        },
        end: null,
        start: null,
        onlyShowGiftAid: false,
        rows: [],
        columns: [
          {
            label: 'Represent',
            field: 'checkbox',
            sortable: false,
            tdClass: 'text-center',
            thClass: 'text-center'
          },
          {
            label: 'Customer Reference',
            field: 'payerReference',
          },
          {
            label: 'Submission',
            field: 'submissionTitle',
          },
          {
            label: 'SubmissionId',
            field: 'submissionId',
            hidden: true
          },
          {
            label: 'Amount (£)',
            field: 'amount',
            formatFn: this.formatAmount,
            tdClass: 'text-right'
          },
          {
            label: 'Submission Date',
            field: 'submissionDate',
            formatFn: this.formatDate
          },
          {
            label: 'Collection Date',
            field: 'collectionDate',
            formatFn: this.formatDate
          },
          {
            label: 'Settlement Date',
            field: 'settlementDate',
            formatFn: this.formatDateWithNull
          },
          {
            label: 'Collection Status',
            field: 'collectionStatus',
            formatFn: this.paymentStatusText
          },
          {
            label: 'Collection Identifier',
            field: 'collectionIdentifier'
          },
          {
            label: 'Indemnity',
            field: 'indemnityId',
            formatFn: this.hasIndemnity
          },
          {
            label: 'Schedule Reference',
            field: 'planReference'
          },
          {
            label: 'Schedule Status',
            field: 'planStatus',
            formatFn: this.planStatusText
          },
          {
            label: 'Customer Id',
            field: 'ukPayerId',
            hidden: true
          },
          {
            label: 'Plan Id',
            field: 'planId',
            hidden: true
          },
          {
            label: 'Schedule Type',
            field: 'planType',
            formatFn: this.recurrenceEndTypeText
          },
          {
            label: 'Failure Reason',
            field: 'collectionFailureReason'
          }
        ],
        representRows: [],
        representable: false,
        serverParams: {
          sort: [{ field: 'collectionDate', type: 'desc' }]
        }
      }
    },
    async mounted () {
      let canDoResp = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/candogiftaid`)
      if (canDoResp) {
        this.canDoGiftAid = canDoResp.data
      }
      this.end = new Date()
      let tempStart = new Date()
      tempStart.setDate(this.end.getDate() - 7)
      this.start = tempStart
    },
    methods: {
      async filtersChanged () {
        await this.clearTableFilters()
      },
      hasIndemnity (val) {
        return val === '00000000-0000-0000-0000-000000000000' ?  'False' : 'True'
      },
      recurrenceEndTypeText (val) {
        var outText = ''
        switch (val) {
          case 0:
            outText = 'One Off'
            break
          case 1:
            outText = 'Until Date'
            break
          case 2:
            outText = 'Until Number of Collections'
            break;
          case 3:
            outText = 'Ongoing'
            break
          case 4:
            outText = 'Ad Hoc'
            break
        }
        return outText
      },
      planStatusText (val) {
        var outText = ''
        switch (val) {
          case 0:
            outText = 'Closed'
            break
          case 1:
            outText = 'Alert'
            break
          case 2:
            outText = 'Suspended'
            break
          case 3:
            outText = 'Operational'
            break
        }
        return outText
      },
      paymentStatusText (val) {
        var outText = ''
        switch (val) {
          case 9:
          case 8:
          case 7:
          case 6:
            outText = 'Pending'
            break
          case 5:
            outText = 'Being Processed'
            break
          case 4:
            outText = 'Submitted'
            break
          case 3:
            outText = 'Failed'
            break
          case 2:
            outText = 'Error'
            break
          case 1:
            outText = 'Suspended'
            break
          case 0:
            outText = 'Closed'
            break
          case 10:
            outText = 'Deleted'
            break
          case 11:
            outText = 'Refunded'
            break
          case 12:
            outText = 'Not Attempted (deprecated)'
            break
          case 13:
            outText = 'Will Not Attempt'
            break
          case 14:
            outText = 'Unprocessed'
            break
          case 16:
            outText = 'Due'
            break
          case 17:
            outText = 'Successful'
            break
          case 18:
            outText = 'Unsubmitted'
            break
          case 19:
            outText = 'Cancelled'
            break
          default:
            outText = 'Value not set yet'
            break
        }
        return outText
      },
      load: async function () {
        try {
          if (this.end === null) {
            this.end = new Date()
            let tempStart = new Date()
            tempStart.setDate(this.end.getDate() - 1)
            tempStart.setHours(0,0,0,0)
            let tempEnd = new Date(this.end.setMonth(this.end.getMonth()+1));
            tempEnd.setHours(23,59,59,0)
            this.end = tempEnd
          }
          if (this.start === null) {
            let tempStart = new Date()
            tempStart.setDate(this.end.getDate() - 1)
            tempStart.setHours(0,0,0,0)
            this.start = tempStart
          }
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/collectionsreport`, { params: { ...this.buildGoodTableQuery(), startDate: this.start, endDate: this.end, giftAidOnly: this.onlyShowGiftAid, showSchedules: this.showSchedules, collectionStatus: this.statusFilter }, validateStatus: () => true, showLoad: true })
          if (response.status === 200) {
            this.rows = response.data.data
            for (let i = 0; i < this.rows.length; i++) {
              this.rows[i].vgtDisabled = !this.rows[i].canRepresent
              this.rows[i].selected = this.rows[i].canRepresent && this.representRows.some(e=> e.paymentId === this.rows[i].paymentId)
            }
            this.totalRecords = response.data.count
          } else {
            if (response.status === 403) {
              this.$toastr.e('Not authorized to use this feature.')
              this.rows = []
              this.totalRecords = 0
            } else {
              this.$toastr.e('An error has occurred.')
            }
          }
        } catch (error) {
          this.$toastr.e(error)
        }
        this.$set(this.columns[11], 'hidden', this.plansHidden)
        this.$set(this.columns[12], 'hidden', this.plansHidden)
        this.$set(this.columns[15], 'hidden', this.plansHidden)
      },
      onRowChange (e) {
        /* console.log(e)
        this.$router.push({ path: `/collections/payer/${e.row.ukPayerId}/edit`, params: { openAt: 'messages'} }) */
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
      formatStatus (value) {
        let retVal = ''
        switch(value) {
          case 1:
            retVal = 'Created'
            break
          case 2:
            retVal = 'In Progress'
            break
          case 3:
            retVal = 'Resolved'
            break
          case 4:
            retVal = 'Rejected'
            break
          case 5:
            retVal = 'Action Required'
            break
          default:
            retVal = 'None'
            break
        }
        return retVal
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      formatDateWithNull (date) {
        if (date===null || date === '0001-01-01T00:00:00') {
          return 'N/A'
        } else {
          return this.formatDate(date)
        }
      },
      async exportTable () {
        console.log('EXPORTING')
        var query = this.buildGoodTableQuery()
        var startDate = moment(this.start).format('DD-MM-YYYY')
        var endDate = moment(this.end).format('DD-MM-YYYY')
        query.perPage = this.totalRecords
        console.log('EXPORTING')
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/collectionsreport`, { params: {
          startDate: this.start, endDate: this.end, giftAidOnly: this.onlyShowGiftAid,
          ...query, paygateId: this.paygateId, forExport: true, showSchedules: this.showSchedules, collectionStatus: this.statusFilter }, showload: true })
          console.log('EXPORTING')
        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, `collections-report-${startDate}-to-${endDate}.csv`)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = `collections-report-${startDate}-to-${endDate}.csv`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      codeToDescription (value) {
        let retval = ''
        switch (value) {
          case 1:
            retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
            break;
          case 2:
            retval = 'NO ADVANCE NOTICE WAS RECEIVED';
            break;
          case 3:
            retval = 'BANK CANCELS THE DIRECT DEBIT';
            break;
          case 4:
            retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
            break;
          case 5:
            retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
            break;
          case 6:
            retval = 'FRAUDULENT SIGNATURE';
            break;
          case 7:
            retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
            break;
          case 8:
            retval = 'SERVICE USER IS NOT RECOGNISED';
            break;
          default:
            retval = 'Invalid Code Supplied'
            break;
        }
        return retval
      },
      representRowSlected (e) {
        this.representRows = e.selectedRows.filter(a => a.canRepresent)
        for (let i = 0; i < this.rows; i++){
          if (this.rows[i].vgtDisabled){
            this.rows[i].vgtSelected = false
            this.$set(this.rows[i], 'vgtSelected', false);
          }
        }
      },
      async rePresent (e) {
        console.log(this.representRows.map(x=>x.paymentId))
        try {
          let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/represent`, this.representRows.map(x => x.paymentId))
          this.$toastr.s('Collections represented')
          this.representRows.clear()
          await this.load()
        } catch (e) {
          this.$toastr.e('Could not represent collections.')
        }
      },
      addToRepresent (row) {
        if (row.selected) {
          this.representRows.push(x => row)
        } else {
          let index = this.representRows.indexOf(row)
          this.representRows.splice(index, 1)
          this.representable = false
        }
      },
      async addAllToRepresent () {
        if(this.representable) {
          var query = this.buildGoodTableQuery()
          var startDate = moment(this.end).format('DD-MM-YYYY')
          var endDate = moment(this.start).format('DD-MM-YYYY')
          query.perPage = this.totalRecords
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/collectionsreport`, { params: {
              startDate: this.start, endDate: this.end, giftAidOnly: this.onlyShowGiftAid,
              ...query, paygateId: this.paygateId, forExport: true, showSchedules: this.showSchedules, canRepresentOnly: true, }, showload: true })
          this.representRows =response.data
          if (response.data.length > 0) {
            await this.load()
            this.$toastr.s(response.data.length+' Collections added for representation')
          } else {
            this.$toastr.e('Nothing to represent')
            this.representable = false
          }
        } else {
          this.representRows = []
          this.$toastr.s('Cleared')
          await this.load()
        }
      },
      async clearSelections () {
        this.representable = false
        await this.addAllToRepresent()
      }
    },
    computed: {
      ...mapGetters(['selectedCustomer']),
      startDateOptions () {
        return { ...this.dateOptions, maxDate: this.end }
      },
      endDateOptions () {
        return { ...this.dateOptions, minDate: this.start }
      },
      plansHidden () {
        return !this.showSchedules
      },
      selectionInfo () {
        return `${this.representRows.length} representable rows selected`
      }
    },
    watch: {
      selectedCustomer () {
        this.load()
      }
    }
  }
</script>
<style scoped>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
.vgt-responsive {
  overflow: auto !important;
}
</style>
