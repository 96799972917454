<template>
  <div>
    <b-row class="mb-5">
      <b-col>
        <div ref="editor"></div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button variant="outline-primary" @click="addNote" :disabled="isEmpty">Add Note</b-button>
      </b-col>
    </b-row>
    <div v-for="(row, index) in rows" :key="row.payerNotesId" v-if="rows && rows.length > 0" class="card mt-5 mb-4">
      <div class="card-header">
        <div class="media flex-wrap align-items-center">
          <avatar
            :user-id="row.userId"
            width="30"
            height="30"
            v-b-popover.hover.top.d500="row.name"
            class="d-block ui-w-40 rounded-circle"
          />
          <div class="media-body ml-3">
            {{row.name}}
            <div class="text-muted small">{{ formatDate(row.creationDate) }}</div>
          </div>
        </div>
        <div class="card-body" v-html="row.noteText"></div>
      </div>
    </div>
  </div>
</template>
<script>
// import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { mapGetters } from 'vuex'
import Avatar from '../../../Assets/Components/Avatar.vue'
export default {
  components: { Avatar },
  props: {
    ukPayerId: String,
    canLoad: { type: Boolean, default: false }
  },
  data () {
    return {
      platformApi: process.env.VUE_APP_PLATFORM_API_URL,
      bearerToken: null,
      editor: null,
      rows: [],
      columns: [
        { label: 'User Name', field: 'userName' },
        { label: 'Message', field: 'noteText', html: true },
        { label: 'Date', field: 'creationDate' }
      ]
    }
  },
  async mounted () {
    // auth.getAccessToken()
    //   .then(token => {
    //     this.bearerToken = 'Bearer ' + token
    //   })

    this.editor = new Quill(this.$refs.editor, {
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, false] }, 'tablecontainer'],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
            [{ color: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean']
          ],
          table: true,
          handlers: {}
        },
        history: {
          delay: 2000,
          maxStack: 500,
          userOnly: true
        }
      },
      theme: 'snow'
    })
  },
  methods: {
    async load () {
      if (this.canLoad) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.ukPayerId}/notes`, {
            params: {
              paygateid: this.$store.getters.selectedCustomer,
              showload: true
            }
          })
          this.rows = response.data
        } catch (e) {
          console.log(e)
          this.$toastr.e('Could not load customer notes.')
        }
      }
    },
    async addNote (e) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.ukPayerId}/notes`, { body: this.editor.root.innerHTML }, { params: { paygateid: this.$store.getters.selectedCustomer } })
        if (response) {
          this.editor.root.innerHTML = ''
          await this.load()
          this.$toastr.s('A note has been added to the customer record')
        } else {
          this.$toastr.e('An error occurred adding the customer note.')
        }
      } catch (e) {
        this.$toastr.e('An error occurred adding the customer note.')
      }
    },
    formatDate (datestr) {
      const date = new Date(datestr)
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var h = date.getHours()
      var m = date.getMinutes()
      var s = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year} ${this.pad(h, 2)}:${this.pad(m, 2)}:${this.pad(s, 2)}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    }
  },
  computed: {
    ...mapGetters(['getAccessToken']),
    isEmpty () {
      return this.editor && this.editor.getText().trim().length == 0
    }
  }
}
</script>
<style>
.dark-mode * .ql-stroke {
  stroke: #fff;
}
.dark-mode * .ql-picker-options {
  background: #0000;
}
</style>
