<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2><help-icon docPath="/paygate-collections/hosted-forms/integration/" />Customer Online Signup URLs</h2>
    </div>

    <div class="idb-block-content">
      <vue-good-table
        mode="remote"
        :search-options="{
                    enabled: true
                    }"
        :sort-options="{
                enabled: true,
                initialSortBy: { field: 'url', type: 'desc' }
              }"
        :pagination-options="{
                    enabled: true,
                    dropdownAllowAll: false,
            jumpFirstOrLast: true
                  }"
        :totalRows="totalRecords"
        :columns="columns"
        :rows="urls"
        @on-row-click="onRowClick"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        styleClass="vgt-table striped bordered"
      >
        <div slot="table-actions">
          <b-button
            @click.prevent="loadUrls"
            class
            variant="link"
            title="Refresh Table"
            v-b-popover.hover.top.d500="'Refresh the data in the table'"
          >
            <i class="fa fa-redo pointer dimmedIcon"></i>
          </b-button>
          <b-button
            @click.prevent="printTable"
            class
            variant="link" v-if="false"
            title="Print Table"
            v-b-popover.hover.top.d500="'Print out the contents of the Allowed URLs table'"
          >
            <i class="fa fa-print pointer dimmedIcon"></i>
          </b-button>
          <b-button
            @click.prevent="exportTable"
            class
            variant="link"
            title="Export Table"
            v-b-popover.hover.top.d500="'Export the contents of the Emails table'"
          >
            <i class="fa fa-share-square pointer dimmedIcon"></i>
          </b-button>
        </div>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'verified'">
            <i class="fa fa-fw" :class="props.row.verified ? 'fa-check' : 'fa-times'"></i>
          </span>
          <span v-else-if="props.column.field == 'buttons'">
            <b-btn variant="danger" @click.stop="deleteUrl(props.row['onboardingAllowedUrlId'])">
              <i class="fa fa-trash-alt"></i>
            </b-btn>
          </span>
          <span v-else>{{props.formattedRow[props.column.field]}}</span>
        </template>
      </vue-good-table>
    </div>
    <div class="idb-block-footer">
      <button class="btn btn-primary" @click.prevent="create">Add Url</button>
    </div>
    <b-modal
      id="urlModal"
      ref="urlModal"
      title="Allowed URL"
      size="lg"
      hide-header-close
      cancel-variant="secondary-outline"
      lazy
    >
      <b-row class="mt-2">
        <b-col sm="4" class="mt-2">
          <label class="required" for="urlInputInput">Allowed URL</label>
        </b-col>
        <b-col sm="7">
          <b-form-input id="urlInput" type="text" v-model.trim="selectedUrl.url"></b-form-input>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () {
      this.loadUrls()
    }
  },
  async mounted () {
    const handleModalHide = async (bvEvent, modalId) => {
      const target = bvEvent.target.id
      switch (target) {
        case 'urlModal':
          if (bvEvent.trigger === 'ok') {
            const isNew = this.selectedUrl.onboardingAllowedUrlId === null
            if (isNew) {
              const newUrl = await this.insertUrlFromModal()
              this.urls.splice(0, 0, newUrl)
            } else {
              const updateUrl = await this.updateUrlFromModal()
              for (var i = 0; i < this.urls.length; i++) {
                if (this.urls[i].onboardingAllowedUrlId === updateUrl.onboardingAllowedUrlId) {
                  this.urls[i].url = updateUrl.url
                }
              }
            }
          }
          break
        default:
          break
      }
    }

    this.$root.$on('bv::modal::hide', handleModalHide)
    this.$once('hook:beforeDestroy', () => {
      this.$root.$off('bv::modal::hide', handleModalHide)
    })
    this.loadUrls()
  },
  data () {
    return {
      selectedUrl: {
        url: '',
        onboardingAllowedUrlId: null
      },
      urls: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: 'url',
          type: 'ASC'
        },
        page: 1,
        perPage: 10
      },
      columns: [
        {
          hidden: true,
          field: 'onboardingAllowedUrlId'
        },
        {
          label: 'URL',
          field: 'url'
        },
        {
          label: '',
          field: 'buttons',
          sortable: false
        }]
    }
  },
  methods: {
    async create () {
      this.selectedUrl = {
        url: '',
        onboardingAllowedUrlId: null
      }
      this.$refs.urlModal.show()
    },
    async insertUrlFromModal () {
      let url = process.env.VUE_APP_DDMS_API_URL + 'onboardingurl'
      const paygateId = this.$store.state.common.paygateId
      this.selectedUrl.onboardingAllowedUrlId = '00000000-0000-0000-0000-000000000000'
      if (paygateId) {
        url += `?paygateId=${paygateId}`
      }
      try {
        var response = await axios.post(url, this.selectedUrl)
        this.$toastr.s('Onboarding URL added', 'Success')
        return response.data
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to add onboarding URL', 'An error occurred')
        return null
      }
    },
    async updateUrlFromModal () {
      let url = process.env.VUE_APP_DDMS_API_URL + 'onboardingurl/' + this.selectedUrl.onboardingAllowedUrlId
      const paygateId = this.$store.state.common.paygateId
      if (paygateId) {
        url += `?paygateId=${paygateId}`
      }
      try {
        var response = await axios.put(url, this.selectedUrl)
        this.$toastr.s('Onboarding URL updated', 'Success')
        return response.data
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to update Onboarding URL', 'An error occurred')
        return null
      }
    },
    deleteUrl: _.debounce(async function (id) {
      let url = process.env.VUE_APP_DDMS_API_URL + 'onboardingurl/' + id
      const paygateId = this.$store.state.common.paygateId
      if (paygateId) {
        url += `?paygateId=${paygateId}`
      }
      try {
        var response = await axios.delete(url)
        console.log(response.data.id)
        this.urls = this.urls.filter(x => x.id !== response.data.id)
        this.$toastr.s('Onboarding URL has been deleted', 'Success')
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to delete Onboarding URL', 'An error occurred')
      }
    }, 300),
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    exportTable () {
      console.error('exportTable not implemented yet')
    },
    printTable () {
      console.log('print table')
    },
    getQuery () {
      var query = {}
      query.sort = this.serverParams.sort.field + ':' + this.serverParams.sort.type
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }
      return query
    },
    loadUrls: _.debounce(async function () {
      const getUrlsUrl = process.env.VUE_APP_DDMS_API_URL + 'onboardingurl'
      const query = this.getQuery()
      const paygateId = this.$store.state.common.paygateId
      if (paygateId) {
        query.paygateId = paygateId
      }
      const response = await axios.get(getUrlsUrl, { params: query })
      this.urls = response.data.item1
      this.totalRows = response.data.item2
    }, 300),
    onRowClick (event) {
      this.selectedUrl = Object.assign({}, event.row)
      this.$refs.urlModal.show()
    },
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadUrls()
    },
    onSortChange (params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      })
      this.loadUrls()
    },
    onColumnFilter (params) {
      this.updateParams(params)
      this.loadUrls()
    },
    onPerPageChange (params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadUrls()
    },
    onSearch (params) {
      this.serverParams.searchKey = params.searchTerm
      this.loadUrls()
    }
  },
  validations: {
    customer: {
      paygateId: { required, isGuid },
      name: { required, maxLen: maxLength(50) }
    }
  }
}

function isGuid (value) {
  var regex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$')
  var result = regex.test(value)
  return result
}

</script>
