<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>List Commissions</h2>
    </div>
    <div class="idb-block-content">
      <div class="row form-group">
        <button
          class="btn btn-secondary"
          @click="onGenerateData"
          type="button"
        >Generate Commission Data</button>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">Search Filtering</div>
        <div class="form-group col-md-4">
          <label for="from">From</label>
          <transition name="fadein">
            <vuejsDatepicker
              name="selectFromMonth"
              id="selectFromMonth"
              format="MMM/yyyy"
              input-class="form-control bg-white"
              :bootstrap-styling="true"
              minimumView="month"
              maximumView="year"
              initial-view="month"
              :use-utc="true"
              :disabled-dates="{
                    from: new Date()
                    }"
              :calendar-button="true"
              calendar-button-icon="fa fa-calendar"
              v-model="selectedFromDate"
              @input="updateTable()"
              :highlighted="highlighted"
            ></vuejsDatepicker>
          </transition>
        </div>
        <div class="form-group col-md-4">
          <label for="from">To</label>
          <transition name="fadein">
            <vuejsDatepicker
              name="selectToMonth"
              id="selectToMonth"
              format="MMM/yyyy"
              input-class="form-control bg-white"
              :bootstrap-styling="true"
              minimumView="month"
              maximumView="year"
              initial-view="month"
              :use-utc="true"
              :disabled-dates="{
                    from: new Date()
                    }"
              :calendar-button="true"
              calendar-button-icon="fa fa-calendar"
              v-model="selectedToDate"
              @input="updateTable()"
              :highlighted="highlighted"
            ></vuejsDatepicker>
          </transition>
        </div>
        <div v-if="false" class="form-group col-md-4">
          <br />
          <transition name="fadein">
            <p-check
              class="p-default p-curve"
              id="applyDateFilters"
              @change="updateTable()"
              v-model="applyDateFilter"
            >Apply Date Filter</p-check>
          </transition>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="from">Agent</label>
          <vue-select
            v-model="tableAgentsSelect"
            :options="tableDataAgents"
            label="text"
            :closeOnSelect="true"
            @input="updateTable()"
          ></vue-select>
        </div>
        <div class="form-group col-md-4">
          <label for="from">Customer</label>
          <vue-select
            v-model="tableCustomersSelect"
            :options="tableDataCustomers"
            label="text"
            :closeOnSelect="true"
            @input="updateTable()"
          ></vue-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <vue-good-table
            :paginationOptions="paginationOptions"
            :sort-options="sortOptions"
            :isLoading.sync="isTableLoading"
            :rows="rows"
            :columns="columns"
            :lineNumbers="true"
            :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true,
                      selectionInfoClass: 'report-row-selected',
                      clearSelectionText: 'Clear Checkboxes',
                      selectAllByGroup: true,
                    }"
            @on-selected-rows-change="onSelectedRowChange"
            @on-select-all="onSelectAll"
            mode="remote"
            :totalRows="totalRecords"
            @on-cell-click="onRowClick"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
            :search-options="{
            enabled: true
          }"
            styleClass="vgt-table striped bordered"
            ref="table"
          >
            <div slot="selected-row-actions">
              <button
                v-if="DisabledAgentDeactivated"
                class="btn btn-danger pull-right"
                @click="onMakeCommissionPayable"
                type="button"
              >
                <i class="glyphicon ti-trash rpad"></i>Make Payable
              </button>

              <button
                v-if="Deleted"
                class="btn btn-danger pull-right"
                @click="onMakeCommissionUnDeleted"
                type="button"
              >
                <i class="glyphicon ti-trash rpad"></i>Undelete Selected
              </button>

              <button
                v-if="CanDelete"
                class="btn btn-danger pull-right"
                @click="onMakeCommissionDeleted"
                type="button"
              >
                <i class="glyphicon ti-trash rpad"></i>Delete Selected
              </button>
            </div>

            <div slot="table-actions">
              <!-- clear -->
              <button
                @click.prevent="clearTableFilters"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Clear filters'"
              >
                <span class="fa-stack" style="font-size: 10px;">
                  <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                  <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                </span>
              </button>
              <!-- refresh -->
              <b-button
                @click.prevent="load"
                class
                variant="link"
                v-b-popover.hover.top.d500="'Refresh the data in the table'"
              >
                <i class="fa fa-sync pointer dimmedIcon"></i>
              </b-button>

              <!-- export -->
              <button
                @click.prevent="exportData"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Export Data'"
              >
                <i class="fa fa-share-square pointer dimmedIcon"></i>
              </button>
              <!-- insert -->
              <button
                @click.prevent="onInsertManualCommissionItem"
                class="btn btn-link"
                title="Create Manual Precommission Item"
                v-b-popover.hover.top.d500="'Create a new commission record manually'"
              >
                <i class="fa fa-plus dimmedIcon pointer"></i>
              </button>
            </div>
          </vue-good-table>
        </div>
      </div>
    </div>
    <div class="idb-block-footer">
      <button class="btn btn-primary" @click="onInsertManualCommissionItem" type="button">
        <i class="fa fa-plus rpad"></i>Create Manual Commission Record
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import papa from 'papaparse'

import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import integer from 'vuelidate/lib/validators/integer'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import _ from 'lodash'
// import settlementMixin from '@/Components/Platform/Customer/Billing/Settlements/SettlementMixin'
import VueSelect from 'vue-select'
import commissionAgentCommissionItemStatus from '../../../../Assets/Constants/commissionAgentCommissionItemStatus'
export default {
  props: ['status'],
  components: { VueSelect },
  //  mixins: [loading, tableFilterMixin, settlementMixin, tableFilterMixin],
  mixins: [loading, tableFilterMixin, tableFilterMixin],

  data () {
    return {
      // **** Date Selections
      selectedFromDate: new Date(),
      selectedToDate: new Date(),
      highlightDates: [],
      highlighted: {
        dates: []
      },

      // **** Table Info
      serverParams: {
        sort: [{ field: 'servicecharge', type: 'asc' }],
        columnFilters: [{ commissionItemStatus: commissionAgentCommissionItemStatus.New }]
      },
      rows: [],
      columns: [
        {
          label: 'AssociationId',
          field: 'associationId',
          hidden: true
        },
        {
          label: 'AgentId',
          field: 'agentId',
          hidden: true
        },
        {
          label: 'BillingUsageId',
          field: 'billingUsageId',
          hidden: true
        },
        {
          label: 'Agent',
          field: 'agentName',
          filterOptions: {
          }
        },
        {
          label: 'Customer',
          field: 'customerName',
          filterOptions: {

          }
        },
        {
          label: 'Service Charge Date',
          field: 'serviceChargeCreationDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: true

        },
        {
          label: 'Service Charge (£)',
          field: 'serviceCharge',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },

        {
          label: 'Commission (£)',
          field: 'commissionAmount',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Commission Type',
          field: 'fixedFeePercentage',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Overridden',
          field: 'overridden',
          sortable: true,
          formatFn: this.GetOverriddenField

        },
        {
          label: 'Manual Creation',
          field: 'manualCreated',
          sortable: true,
          formatFn: this.GetManualCreationField
        },
        {
          label: 'Status',
          field: 'commissionItemStatus',
          sortable: true,
          formatFn: this.GetCommissionItemStatusField,
          filterOptions: {
            // filterValue: 'New',
            enabled: true,
            //            placeholder: 'Options',
            defaultOption: commissionAgentCommissionItemStatus.New,
            filterDropdownItems: Object.values(commissionAgentCommissionItemStatus)
          }
        }
      ],

      // **** Table Button and dropdown selections
      tableDataAgents: [],
      tableDataCustomers: [],
      tableAgentsSelect: [],
      tableCustomersSelect: [],
      tableStatusSelect: [],
      applyDateFilter: false,

      // *************Multi Select Data Options
      // Selection contains commissions disabled due to deactivated agent at time of generation
      DisabledAgentDeactivated: false,
      // Selection contains deleted commissions
      Deleted: false,
      // Selection contains commissions that can be deleted
      CanDelete: false,
      // Tracking Selected Rows
      SelectedCommissionRows: []
    }
  },
  computed: {

  },
  mounted () {
    this.SetFromDate()
  },
  async created () {
    await this.getTableOptions()
  },

  methods: {
    async load () {
      await this.updateTable()
    },
    onSelectAll () {
      console.log('select all')
    },
    SetFromDate () {
      // sets the initial FROM date back six months
      var dateVal = new Date()
      var monthVal = dateVal.getMonth() - 6
      if (monthVal < 0) {
        monthVal = monthVal + 12
        dateVal.setMonth(monthVal)
        dateVal.setYear(dateVal.getFullYear() - 1)
      } else {
        dateVal.setMonth = monthVal
      }
      this.selectedFromDate = dateVal
    },

    onGenerateData: async function () {
      // GeneratePreCommData
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGenerateCommissions`,
          {
            params: { paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to generate Premium Commission data'
          })
        console.log('response', response)
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
      console.log('update table')
      await this.updateTable()
    },
    selectTableStatus: async function () {
      await this.updateTable
    },
    updateTable: async function () {
      console.log('this.tableStatusSelect', this.tableStatusSelect[0])
      if (this.tableStatusSelect.length > 0) {
        var commissionItemStatusArr = []
        this.serverParams.columnFilters.commissionItemStatus = []
        for (var i = 0; i < this.tableStatusSelect.length; i++) {
          console.log('this.tableStatusSelect[i].value')
          commissionItemStatusArr.push(this.tableStatusSelect[i].value)
        }
        this.serverParams.columnFilters.commissionItemStatus = commissionItemStatusArr
      }
      console.log('this.serverParams.columnFilters', this.serverParams.columnFilters)

      // Apply Box if I want to put it back in later
      // delete this.serverParams.columnFilters.commissionItemDateFrom
      // delete this.serverParams.columnFilters.commissionItemDateTo
      // if (this.applyDateFilter) {
      //   this.serverParams.columnFilters.commissionItemDateFrom = this.selectedFromDate
      //   this.serverParams.columnFilters.commissionItemDateTo = this.selectedToDate
      // }
      this.serverParams.columnFilters.commissionItemDateFrom = moment(this.selectedFromDate).format('DD-MM-YYYY')
      this.serverParams.columnFilters.commissionItemDateTo = moment(this.selectedToDate).format('DD-MM-YYYY')

      delete this.serverParams.columnFilters.agentSelect
      console.log('this.serverParams.columnFilters', this.serverParams.columnFilters)

      if (this.tableAgentsSelect !== null && this.tableAgentsSelect !== undefined) {
        this.serverParams.columnFilters.agentSelect = this.tableAgentsSelect.value
      }

      delete this.serverParams.columnFilters.customerSelect
      if (this.tableCustomersSelect !== null && this.tableCustomersSelect !== undefined) {
        this.serverParams.columnFilters.customerSelect = this.tableCustomersSelect.value
      }
      console.log('this.serverParams.columnFilters', this.serverParams.columnFilters)
      console.log('table query3', this.buildGoodTableQuery())
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionsTable`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data'
          })
        console.log('table response', response)
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch (e) {
        console.log('update table failed', e)

        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    async getTableOptions () {
      console.log('Getting Data')
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionTableOptionData`,
          {
            params: { paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data for listing commissions'
          })

        console.log('table data response', response)

        // this.tableDataAgents = response.data.agentTableData

        this.tableDataAgents = []
        for (var i = 0; i < response.data.agentTableData.length; i++) {
          this.tableDataAgents.push({ value: response.data.agentTableData[i].agentId, text: response.data.agentTableData[i].agentName })
        }

        this.tableDataCustomers = []
        for (var j = 0; j < response.data.customerTableData.length; j++) {
          this.tableDataCustomers.push({ value: response.data.customerTableData[j].paygateId, text: response.data.customerTableData[j].customerName })
        }
      } catch (e) {
        console.log('e', e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    FilterTableDataAgents (x, y) { },

    GetManualCreationField (field) {
      if (field) {
        return 'yes'
      } else { return '' }
    },
    GetOverriddenField (field) {
      if (field) {
        return 'yes'
      } else { return '' }
    },

    GetCommissionItemStatusField (field) {
      if (field === 'New') { return 'New' } else if (field === 'InStatement') { return 'In Statement' } else if (field === 'StatementSent') { return 'Statement Emailed' } else if (field === 'StatementPaid') { return 'Paid' } else if (field === 'AgentDeactivated') { return 'Not Payable' } else if (field === 'Deleted') { return 'Deleted' } else if (field === 'All') { return 'All' }
    },
    ApplyCommissionFilter (data, filterString) {
      console.log('apply commission filter:', data, filterString)
    },
    exportData: async function () {
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionsTableCSV`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data'
          })

        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Commissions.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {
        console.log(e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    onSelectedRowChange (params) {
      console.log('params', params)

      this.DisabledAgentDeactivated = _.some(params.selectedRows, function (r) {
        return r.commissionItemStatus === 'AgentDeactivated'
      })

      this.CanDelete = _.some(params.selectedRows, function (r) {
        return r.commissionItemStatus === 'InStatement' || r.commissionItemStatus === 'New'
      })

      this.Deleted = _.some(params.selectedRows, function (r) {
        return r.commissionItemStatus === 'Deleted'
      })

      this.SelectedCommissionRows = []
      for (let i = 0; i < params.selectedRows.length; i++) {
        var commissionRow = {
          id: params.selectedRows[i].id,
          commissionItemStatus: params.selectedRows[i].commissionItemStatus
        }
        this.SelectedCommissionRows.push(commissionRow)
      }
    },

    async onMakeCommissionPayable () {
      var MakePayableItems = _.map(_.filter(this.SelectedCommissionRows, function (s) { if (s.commissionItemStatus === 'AgentDeactivated') { return true } }), function (m) { return m.id })
      console.log('MakePayableItems', MakePayableItems)

      var swalText = 'These <strong>' + MakePayableItems.length + '</strong> commission records are set as not payable as the agents were deactivated when they was generated. <br/> If you continue, this will <strong>make the commission payable</strong> in the normal manner. This cannot be reversed as the commission record will become a normal payable commission record. If you later decide not to pay these commissions, you will have to delete them.'

      try {
        var swalResult = await swal.fire({
          title: 'Make Commissions Payable',
          html: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }
      if (!swalResult.isConfirmed) {
        console.log('declined to make payable')
      } else {
        try {
          var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAMakeCommissionPayableMultiple`,
            {
              commissionids: MakePayableItems,
              showerror: true,
              errormessage: 'Failed to make the Commission payable'
            })
          console.log('response', response)
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else if (response.data.toastType === 3) {
            this.$toastr.w(response.data.toastMessage)
          } else {
            this.$snapbar.e(response.data.toastMessage)
          }
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        }
        await this.load()
      }
    },

    async onMakeCommissionDeleted () {
      var MakeDeletedItems = _.map(_.filter(this.SelectedCommissionRows, function (s) { if (s.commissionItemStatus === 'InStatement' || s.commissionItemStatus === 'New') { return s.id } }), function (m) { return m.id })

      console.log('MakeDeletedItems', MakeDeletedItems)
      var swalText = 'This action will <strong>delete ' + MakeDeletedItems.length + '</strong> commission records. <br/> This can be reversed later however they will be removed from any statements in which they appear.'

      try {
        var swalResult = await swal.fire({
          title: 'Delete Commissions',
          html: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }
      if (!swalResult.isConfirmed) {
        console.log('declined to make deleted')
      } else {
        try {
          var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAMakeCommissionDeletedMultiple`,
            {
              commissionids: MakeDeletedItems,
              showerror: true,
              errormessage: 'Failed to make the Commission deleted'
            })
          console.log('response', response)
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else if (response.data.toastType === 3) {
            this.$toastr.w(response.data.toastMessage)
          } else {
            this.$snapbar.e(response.data.toastMessage)
          }
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        }
        await this.load()
      }
    },

    async onMakeCommissionUnDeleted () {
      var MakeUnDeletedItems = _.map(_.filter(this.SelectedCommissionRows, function (s) { if (s.commissionItemStatus === 'Deleted') { return s.id } }), function (m) { return m.id })

      console.log('MakeUnDeletedItems', MakeUnDeletedItems)

      var swalText = 'This action will <strong>undelete ' + MakeUnDeletedItems.length + '</strong> the commission records and make them payable. <br/>If any current statements exist then they will be added, otherwise you will need to generate a new statement for the commission month.'

      try {
        var swalResult = await swal.fire({
          title: 'Un-Delete Commissions',
          html: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }
      if (!swalResult.isConfirmed) {
        console.log('declined to make undeleted')
      } else {
        try {
          var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAMakeCommissionUnDeletedMultiple`,
            {
              commissionids: MakeUnDeletedItems,
              showerror: true,
              errormessage: 'Failed to make the Commission undeleted'
            })
          console.log('response', response)
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else if (response.data.toastType === 3) {
            this.$toastr.w(response.data.toastMessage)
          } else {
            this.$snapbar.e(response.data.toastMessage)
          }
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        }
        await this.load()
      }
    },

    onRowClick (params) {
      this.$router.push({
        name: 'CommissionAgentCommissionEdit',
        params: { commissionid: params.row.id }
      })
    },
    onInsertManualCommissionItem () {
      this.$router.push({
        name: 'CommissionAgentCommissionAdd'
      })
    }

  }
}

</script>
