<template>
  <div class="form-group row">
    <label class="col-form-label col-md-3">Linked Clients</label>
    <div class="col-md-9">
      <vue-good-table
        ref="customerContacts"
        :columns="customerColumns"
        :rows="customers"
        :totalRows="customers.length "
        :pagination-options="{
                    enabled: true,
                    perPage: 10,
                    dropdownAllowAll: false
                  }"
        styleClass="vgt-table striped bordered"
      ></vue-good-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['customers'],
  computed: {
    customersFormatted () {
      return this.customers.map(c => { return { name: c } })
    }
  },
  methods: {
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    formatAmount (value) {
      return '£' + this.numberWithCommas((value / 1).toFixed(2))
    }
  },
  data () {
    return {
      customerColumns: [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Amount Per Collection',
          field: 'maxAmountPerCollection',
          formatFn: this.formatAmount,
          tdClass: 'text-right'
        },
        {
          label: 'Amount Per Month',
          field: 'maxAmountPerMonth',
          formatFn: this.formatAmount,
          tdClass: 'text-right'
        },
        {
          label: 'Collection Count (Payer)',
          field: 'maxCollectionCountPerPayer',
          tdClass: 'text-right'
        },
        {
          label: 'Collection Count Per Month',
          field: 'maxCollectionCountPerMonth',
          tdClass: 'text-right'
        }
      ]

    }
  }
}
</script>

<style>
</style>
