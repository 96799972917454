<template>
  <div>
    <!-- Service User Number -->
    <div class="form-group row" v-if="status === 'Edit'">
      <label class="col-form-label col-md-3 required">Service User Number</label>
      <div class="col-md-5">
        <div class="form-control-plaintext">
          <span style="margin-right:5px;">{{details.serviceUserNumber.$model}}</span>
        </div>
      </div>
    </div>

    <!-- Import Schema -->
    <div class="form-group row collapse" :class="{invalid: details.importSchema.$error}">
      <label class="label-control col-md-3 required">Import Schema</label>
      <div class="col-md-5">
        <b-form-select
          v-model="details.importSchema.$model"
          :options="importSchemas"
          :disabled="disabled"
        ></b-form-select>
        <validation-messages v-model="details.importSchema" name="import schema"></validation-messages>
      </div>
    </div>

    <!-- Mapping - don't need this in JFTP but prob easier to just hide the mapping combo in case all the db platform stuff is too interconnected. -->
    <div class="form-group row collapse" v-if="details.importSchema.$model === 'Mapping'">
      <label class="label-control col-md-3">Mapping</label>
      <div class="col-md-5">
        <b-form-select v-model="details.mappingId.$model" :options="mappings" :disabled="disabled"></b-form-select>
      </div>
    </div>

    <!-- Bank Account-->
    <bank-account-view :bankAccount="details.bankAccount" v-if="status === 'Edit'"></bank-account-view>

    <!-- Payment Signing Method -->
    <div class="form-group row collapse" :class="{invalid: details.tokenType.$error}">
      <label class="label-control col-md-3 required">Payment Signing Method</label>
      <div class="col-md-5" v-if="hasHsm">
        <p-radio
          class="p-default p-round"
          value="Hsm"
          v-model="details.tokenType.$model"
          :disabled="disabled"
        >HSM</p-radio>
        <p-radio
          class="p-default p-round"
          value="SmartCard"
          v-model="details.tokenType.$model"
          :disabled="disabled"
        >Smart Card</p-radio>
        <validation-messages v-model="details.tokenType" name="payment signing method"></validation-messages>
      </div>
      <div class="col-md-5" v-else>Smart Card</div>
    </div>

    <!-- HSM Certificate -->
    <div
      class="form-group row"
      :class="{invalid: details.certificateId.$error, warn: !selectedHSMCert.valid}"
      v-if="details.tokenType.$model === 'Hsm'"
    >
      <label class="label-control col-md-3 required">HSM Certificate</label>
      <div class="col-md-5">
        <b-form-select
          v-model="details.certificateId.$model"
          :options="hsmCertificates"
          :disabled="disabled"
        ></b-form-select>
        <validation-messages v-model="details.certificateId" name="HSM certificates"></validation-messages>
        <span class="validation-messages" v-if="!selectedHSMCert.valid">
          <small class="form-text small">Certificate is expired</small>
        </span>
      </div>
    </div>

    <!-- HSM PIN required -->
    <div class="form-group row collapse">
      <label class="col-form-label col-md-3">HSM PIN Required</label>
      <div class="col-md-3">
        <p-check
          class="p-switch p-fill"
          color="primary"
          :disabled="disabled"
          v-model="details.hsmPinRequired.$model"
        ></p-check>
      </div>
    </div>

    <!-- Individual Payment Limit -->
    <div class="form-group row" :class="{invalid: details.paymentLimit.$error}">
      <label class="col-form-label col-md-3">Individual Payment Limit (£)</label>
      <div class="col-md-3">
        <input
          type="number"
          class="form-control"
          v-model.trim="details.paymentLimit.$model"
          :disabled="disabled"
        />
        <!-- Validation -->
        <validation-messages v-model="details.paymentLimit" name="Individual payment limit">
          <small
            class="form-text small"
            v-if="details.paymentLimit.wholepounds != undefined && !details.paymentLimit.wholepounds"
          >individual item limit can only be in whole pounds</small>
        </validation-messages>
        <small class="form-text text-muted">Set to 0 for no limit</small>
      </div>
    </div>

    <!-- Submission  Limit -->
    <div class="form-group row" :class="{invalid: details.submissionLimit.$error}">
      <label class="col-form-label col-md-3">Submission Limit (£)</label>
      <div class="col-md-3">
        <input
          type="number"
          class="form-control"
          v-model.trim="details.submissionLimit.$model"
          :disabled="disabled"
        />
        <!-- Validation -->
        <validation-messages v-model="details.submissionLimit" name="Submission payment limit">
          <small
            class="form-text small"
            v-if="details.paymentLimit.wholepounds != undefined && !details.paymentLimit.wholepounds"
          >submission item limit can only be in whole pounds</small>
        </validation-messages>
        <small class="form-text text-muted">Set to 0 for no limit</small>
      </div>
    </div>

    <!-- Is indirect submitter group -->
    <div v-if="false" class="form-group row">
      <label class="col-form-label col-md-3">Is Indirect Submitter Group</label>
      <div class="col-md-3">
        <p-check
          class="p-switch p-fill"
          color="primary"
          :disabled="disabled"
          v-model="details.isIndirectSubmitterGroup.$model"
        ></p-check>
      </div>
    </div>

    <!-- Bank grade group -->
    <div v-show="bacsLicenceSettings.bankGrade === true" class="form-group row collapse">
      <label class="col-form-label col-md-3">Bank Grade Group</label>
      <div class="col-md-3">
        <p-check
          class="p-switch p-fill"
          color="primary"
          :disabled="disabled"
          v-model="details.bankGradeGroup.$model"
        ></p-check>
      </div>
    </div>

    <!-- Segregate Group Roles -->
    <div class="form-group row">
      <label class="col-form-label col-md-3">Segregate Group Roles</label>
      <div class="col-md-3">
        <p-check
          class="p-switch p-fill"
          color="primary"
          :disabled="disabled"
          v-model="details.segregateGroupRoles.$model"
        ></p-check>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import roles from '@/Assets/Constants/roles'

import bankAccountView from '@/Components/Platform/Group/EditGroupTypeDetails/EditingComponents/BankAccountView'

export default {
  props: ['details', 'disabled', 'status'],
  components: {
    bankAccountView
  },
  computed: {
    hasHsm () {
      return this.$store.getters.selectedCustomer === process.env.VUE_APP_LANDZ_ID
    },
    selectedHSMCert () {
      var cert = this.hsmCertificates.find(c => c.value === this.details.certificateId.$model)

      if (cert == null) {
        return { valid: true }
      }

      return cert
    },
    canViewBanks () {
      return this.$store.getters.isInRoles([roles.Finance, roles.DevOps, roles.Qa, roles.Developer])
    }
  },
  data () {
    return {
      hsmCertificates: [],
      importSchemas: [
        { value: 'None', text: 'None' },
        { value: 'Legacy', text: 'Legacy' },
        { value: 'Standard18', text: 'Standard 18' },
        { value: 'Mapping', text: 'Mapping' }
      ],
      mappings: [],
      bacsLicenceSettings: {}
    }
  },
  async created () {
    if (!this.hasHsm) {
      this.details.tokenType.$model = 'SmartCard'
    }

    try {
      this.$Progress.start()
      var results = await Promise.allSettled([
        axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence', { showerror: true, errormessage: 'Failed to get licence' }),
        axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate`, { params: { paygateid: this.$store.state.common.paygateId }, showerror: true, errormessage: 'Failed to get Client certificates' })
      ])

      try {
        // Bacs Licence
        this.bacsLicenceSettings = results[0].value.data.bacs
      } catch { }
      try {
        // Certificates
        this.hsmCertificates = results[1].value.data.map(certificate => {
          var validTo = moment(certificate.validTo)

          var valid = true
          if (moment().isAfter(validTo)) {
            valid = false
          }

          return { value: certificate.certificateId, text: certificate.friendlyName, valid }
        })

        if (this.status === 'Create') {
          this.hsmCertificates = this.hsmCertificates.filter(c => c.valid)
          // Not sure whether new BACS groups should be created but if we can, set these defaults so validation passes.
          this.details.importSchema.$model = 'None'
          this.details.tokenType.$model = 'Hsm'
        }
      } catch { }
    } catch {
      this.$Progress.fail()
    } finally {
      this.$Progress.finish()
    }
  }
}

</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
