<template>
  <div id="arucsReportError">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block-title">
          <h2>
            {{ pageTitle }}
          </h2>
        </div>
        <div class="idb-block-content">
          <div v-if="action.id !== undefined" id="actionDetails">
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Title</strong>
              </div>
              <div class="col-md-10">
                {{ this.action.title }}
                <span class="pull-right">
                  <a href="#" target="_blank">
                    <i class="far fa-question-circle"></i>
                  </a>
                </span>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Message</strong>
              </div>
              <div class="col-md-10">{{ this.action.message }}</div>
            </div>
            <div v-if="this.action.data.details.length > 0" class="row form-group">
              <div class="col-md-2">
              </div>
              <div class="col-md-10">
                <div v-for="detail in this.action.data.details" :key="detail.messageText" :value="detail.messageText" style="padding-bottom: 15px;">
                  {{ detail.messageText }}
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <vue-good-table
                ref="bacsPaymentErrors"
                @on-row-click="onRowClick"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                @on-cell-click="onCellClick"
                :columns="columns"
                :rows="paymentErrors"
                :lineNumbers="false"
                :totalRows="totalRecords"
                :pagination-options="{
                  enabled: true,
                  perPage: rowsPerPage,
                  dropdownAllowAll: false,
                  setCurrentPage: currentPage
                }"
                :sort-options="{
                  enabled: true
                }"
                styleClass="vgt-table striped bordered"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'submissionStatus'">
                    <span
                      v-b-popover.hover.top="statusToolTip(props.row.submissionStatus)"
                      v-bind:class="getStatusClass(props.row.submissionStatus)"
                      v-bind:style="getStatusStyle(props.row.submissionStatus)"
                    ></span>
                  </span>
                  <span v-else-if="props.column.field === 'payerHyperlink'">
                    <span v-if="props.row.itemId !== '00000000-0000-0000-0000-000000000000'" class="viewLink">View Customer</span>
                  </span>
                  <span v-else>{{props.formattedRow[props.column.field]}}</span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row form-group">
            <div class="col-md-12">
              <button
                type="button"
                class="btn btn-outline-primary ml-3"
                v-on:click="viewReport"
              >{{ reportButtonText }}</button>
              <button
                id="delete"
                type="button"
                class="btn btn-danger pull-right"
                v-on:click="deleteAction"
              >Delete Action</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <viewReportModal
        v-model="isViewReportModalVisible"
        :reportData="reportContent"
        :reportFormat="selectedReportFormat"
        :reportTitle="modalTitle"
        @close="closeViewReport"
      ></viewReportModal>
    </div>
    <div>
      <bacsPaymentErrorModal
        v-model="isBacsPaymentErrorModalVisible"
        :bacsPaymentError="selectedBacsPaymentError"
        @close="closePaymentModal"
      ></bacsPaymentErrorModal>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import ViewReportModal from '@/Views/Bacs/BacsReports/ViewReportModal.vue'
import BacsPaymentErrorModal from '@/Views/Bacs/BacsActions/BacsPaymentErrorModal.vue'

export default {
  mixins: [
    bacsMixin
  ],
  components: {
    viewReportModal: ViewReportModal,
    bacsPaymentErrorModal: BacsPaymentErrorModal
  },
  computed: {
    reportButtonText: function () {
      return this.action.actionSubClass === 'AwacsReport' ? 'View AWACS Report' : 'View ARUCS Report'
    },
    pageTitle: function () {
      return this.action.actionSubClass === 'AwacsReport' ? 'AWACS Direct Credit Report Amendment' : 'ARUCS Direct Credit Report Error'
    }
  },
  data () {
    return {
      action: {},
      retrievalUrl: '',
      reportContent: '',
      modalTitle: '',
      isViewReportModalVisible: false,
      selectedReportFormat: 1,
      isBacsPaymentErrorModalVisible: false,
      selectedBacsPaymentError: {},
      currentPage: 1,
      rowsPerPage: 10,
      totalRecords: 0,
      networkType: 'BacstelIp',
      paymentErrors: [],
      columns: [
        {
          label: 'Account Name',
          field: 'thirdPartyAccountNameOrigRec'
        },
        {
          label: 'Account Number',
          field: 'thirdPartyAccountNumberOrigRec'
        },
        {
          label: 'Sort Code',
          field: 'thirdPartySortCodeOrigRec'
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'number',
          formatFn: this.formatAmount
        },
        {
          label: 'User Reference',
          field: 'userReference'
        },
        {
          label: 'Type',
          field: 'errorType'
        },
        {
          label: 'Message',
          field: 'errorMessage'
        },
        {
          label: 'Is Contra',
          field: 'isContra',
          hidden: true
        },
        {
          label: 'Payer Item ID',
          field: 'itemId',
          hidden: true
        },
        {
          label: 'Customer',
          field: 'payerHyperlink'
        }
      ]
    }
  },

  methods: {
    async getAction () {
      var actionId = this.$store.getters.actionId
      if (actionId !== undefined) {
        var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/' + actionId
        var response = await axios.get(url)
        this.action = response.data
        await this.getBacsPaymentErrors()
      }
    },

    async getBacsPaymentErrors () {
      // var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/payment/getBacsPaymentErrors?submissionId=${this.action.actionedObjectId}&fileNumber=${this.action.data.fileNumber}&bacsPaymentErrorType=Both`, { showload: true })
      var queryString = `?submissionId=${this.action.actionedObjectId}&fileNumber=${this.action.data.fileNumber}&reportId=${this.action.data.reportId}&bacsPaymentErrorType=Both`
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/payment/getBacsPaymentErrors${queryString}`, { showload: true })
      this.paymentErrors = response.data
      this.totalRecords = this.paymentErrors.length
      this.retrievalUrl = 'getReport.do?reportId=' + this.paymentErrors[0].reportId
    },

    onRowClick (params) {
    },

    async onCellClick (params) {
      if (params.column.field === 'payerHyperlink') {
        if (params.row.itemId != '00000000-0000-0000-0000-000000000000') {
          window.open(this.$router.resolve({ name: 'editPayer', params: { ukPayerId: params.row.itemId } }).href, '_blank')
        }
      } else {
        this.selectedBacsPaymentError = params.row
        this.isBacsPaymentErrorModalVisible = true
      }
    },

    onPageChange (params) {
      this.currentPage = params.currentPage
      this.$store.dispatch('setCurrentSearchResultsPage', this.currentPage)
    },

    onPerPageChange (params) {
      this.rowsPerPage = params.currentPerPage
      this.$store.dispatch('setSearchRowsPerPage', this.rowsPerPage)
      this.searchSubmissions()
    },

    async deleteAction () {
      // Delete BACS payment errors.
      var queryString = `?submissionId=${this.action.actionedObjectId}&fileNumber=${this.action.data.fileNumber}&reportId=${this.action.data.reportId}`
      await axios.delete(`${process.env.VUE_APP_BACS_API_URL}bacs/payment/deleteBacsPaymentErrors${queryString}`, { showload: true })

      // Delete the action.
      var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/deleteRejected/' + this.action.id
      await axios.delete(url)
      this.$router.push('/actions')
    },

    formatAmount (value) {
      return this.formatValue(value)
    },

    async viewReport () {
      // Most ARUCS reports have been created via a tool for testing as the Vocalink test service can't return real reports. They are saved with a dummy reportId set to a GUID.
      // Only show HTML for genuine reports i.e. where reportId is not a GUID.
      var htmlResponse = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getInputReportHtml?reportId=${this.paymentErrors[0].reportId}`, { showload: true })
      this.reportContent = htmlResponse.data
      if (this.reportContent !== '') {
        this.modalTitle = 'Report Content'
        this.isViewReportModalVisible = true
        this.selectedReportFormat = 1
      }
    },

    closeViewReport () {
      this.isViewReportModalVisible = false
    },

    closePaymentModal () {
      this.isBacsPaymentErrorModalVisible = false
    }
  },
  async created () {
    this.getAction()
  }
}
</script>
<style>
.report-row-selected {
  height: 60px;
  background-color: #f0f0f0;
  color: black;
}
.grid-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>
