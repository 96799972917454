<template>
  <div id="customerSearch">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Client Search
              <span class="pull-right"></span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <div class="idb-block-footer">
            <div class="row form-group">
              <div class="col-md-12">
                <vue-good-table
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :isLoading.sync="isTableLoading"
                  ref="table"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="false"
                  mode="remote"
                  :totalRows="totalRecords"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  @on-cell-click="onCellClick"
                  @on-row-click="onRowClick"
                  styleClass="vgt-table striped bordered"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'cancelLink'">
                      <button class="btn btn-danger">
                        <i class="fa fa-trash mr-2"></i>Cancel Collection Schedules
                      </button>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                  <div slot="table-actions">
                    <table-actions
                      :data-url="dataUrl"
                      :export-data="tableServerData"
                      :print-enabled="false"
                      :table-amount="totalRecords"
                      export-name="CustomerSearch"
                      :reload-table="load"
                      :tableColumns="columns"
                      :clear-table-filters="clearTableFilters"
                    ></table-actions>
                  </div>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import customerTypes from '@/Assets/Constants/customerTypes'
import customerStatuses from '@/Assets/Constants/customerStatuses'

export default {
  mixins: [tableFilterMixin],
  computed: {
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}customers/customerSearch`
    },
    tableServerData () {
      return this.buildGoodTableQuery()
    }
  },
  data () {
    return {
      searchComplete: false,
      currentPage: 1,
      rowsPerPage: 10,
      totalRecords: 0,
      columns: [
        {
          label: 'paygate ID',
          field: 'paygateId',
          hidden: true
        },
        {
          label: 'Client ID',
          field: 'accountId',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Primary Contact',
          field: 'primaryContact',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Postcode',
          field: 'postcode',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Phone',
          field: 'phone',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true,
            placeholder: 'All Types',
            filterDropdownItems: Object.values(customerTypes)
          }
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'All Statuses',
            filterDropdownItems: Object.values(customerStatuses)
          }
        },
        {
          label: 'Parent Name',
          field: 'parentName',
          filterOptions: {
            enabled: true
          },
          hidden: parseInt(process.env.VUE_APP_VERSION) < 110
        },
        {
          label: 'Association Name',
          field: 'associationName',
          filterOptions: {
            enabled: true
          },
          hidden: parseInt(process.env.VUE_APP_VERSION) < 110
        },
        {
          label: 'Cancel Collection Schedules',
          field: 'cancelLink',
          sortable: false
        }
      ],
      rows: [],
      isModalVisible: false
    }
  },

  methods: {
    async load () {
      try {
        this.isTableLoading = true
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customers/customerSearch`,
          {
            params: { ...this.buildGoodTableQuery() },
            showerror: true,
            errormessage: 'Customer search failed'
          })
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch { } finally {
        this.isTableLoading = false
      }
    },

    onRowClick (params) {
    },

    async onCellClick (params) {
      if (params.column.field === 'cancelLink') {
        var result = await swal.fire({
          title: 'Are you sure you want to cancel the collection schedules for this customer?',
          icon: 'warning',
          allowOutsideClick: false,
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
          confirmButtonColor: colours.danger
        })
        if (result.isConfirmed) {
          var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}bulkcancellation/${params.row.paygateId}/cancelschedules`, {}, { showload: true })
          if (response) {
            this.$toastr.s('Schedules Cancelled')
          }
        }
      } else {
        await this.$store.dispatch('setPaygateId', params.row.paygateId)
        this.$router.push({ name: 'CustomerDetails' })
      }
    }
  }
}

</script>
<style lang="scss">
.viewLink {
  color: blue;
  text-decoration: underline;
}

.dark-mode {
  .viewLink {
    color: #888888;
    text-decoration: underline;
  }
}
</style>
