<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-danger" v-if="hasErrors">
          <div v-for="(error, index) in errors" :key="index">
            <ul>
              <li v-if="error.message">{{error.type}} - {{error.message}}</li>
              <li v-else>{{error}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Processing -->
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Processing Charges</h2>
          </div>
          <div class="idb-block-content">
            <div class="row">
              <div class="col-md-4">
                <select
                  size="20"
                  style="width:100%;"
                  v-model="selectedChargeSetName"
                  @change="selectChargeSet"
                >
                  <option
                    :value="billingProperties.AdvanceNoticeLetter"
                    :class="{invalid: billing.advanceNoticeLetter.$anyError}"
                  >{{billingEnum.AdvanceNoticeLetter}}</option>
                  <option
                    :value="billingProperties.AdvanceNoticeEmail"
                    :class="{invalid: billing.advanceNoticeEmail.$anyError}"
                  >{{billingEnum.AdvanceNoticeEmail}}</option>
                  <option
                    :value="billingProperties.PreCollectionNotification"
                    :class="{invalid: billing.preCollectionNotification.$anyError}"
                  >{{billingEnum.PreCollectionNotification}}</option>
                  <option
                    :value="billingProperties.Collections"
                    :class="{invalid: billing.collections.$anyError}"
                  >{{billingEnum.Collections}}</option>
                  <option
                    :value="billingProperties.ConfirmationLetter"
                    :class="{invalid: billing.confirmationLetter.$anyError}"
                  >{{billingEnum.ConfirmationLetter}}</option>
                  <option
                    :value="billingProperties.ConfirmationEmail"
                    :class="{invalid: billing.confirmationEmail.$anyError}"
                  >{{billingEnum.ConfirmationEmail}}</option>
                  <option
                    :value="billingProperties.FailedCollections"
                    :class="{invalid: billing.failedCollections.$anyError}"
                  >{{billingEnum.FailedCollections}}</option>
                  <option
                    :value="billingProperties.IndemnityClaims"
                    :class="{invalid: billing.indemnityClaims.$anyError}"
                  >{{billingEnum.IndemnityClaims}}</option>
                  <option
                    :value="billingProperties.PayerCancellation"
                    :class="{invalid: billing.payerCancellation.$anyError}"
                  >{{billingEnum.PayerCancellation}}</option>
                  <option
                    :value="billingProperties.PayerReinstate"
                    :class="{invalid: billing.payerReinstate.$anyError}"
                  >{{billingEnum.PayerReinstate}}</option>
                  <option
                    :value="billingProperties.PayerSetup"
                    :class="{invalid: billing.payerSetup.$anyError}"
                  >{{billingEnum.PayerSetup}}</option>
                  <option
                    :value="billingProperties.ValidateBankAccount"
                    :class="{invalid: billing.validateBankAccount.$anyError}"
                  >{{billingEnum.ValidateBankAccount}}</option>
                  <option
                    :value="billingProperties.ValidateIBAN"
                    :class="{invalid: billing.validateIBAN.$anyError}"
                  >{{billingEnum.ValidateIBAN}}</option>
                  <option
                    :value="billingProperties.BanklineSTD1Or2"
                    :class="{invalid: billing.banklineSTD1Or2.$anyError}"
                  >{{billingEnum.BanklineSTD1Or2}}</option>
                  <option
                    :value="billingProperties.BanklineSTD0"
                    :class="{invalid: billing.banklineSTD0.$anyError}"
                  >{{billingEnum.BanklineSTD0}}</option>
                  <option
                    :value="billingProperties.BanklineUrgent"
                    :class="{invalid: billing.banklineUrgent.$anyError}"
                  >{{billingEnum.BanklineUrgent}}</option>
                </select>
              </div>
              <div class="col-md-8">
                <charge-sets
                  :selectedChargeSetName="selectedChargeSetName"
                  :selectedChargeSet="selectedChargeSet"
                  :read-only="readOnly"
                ></charge-sets>
              </div>
            </div>
            <!-- Ignore Processing Charge on Failure -->
            <div class="form-group row mt-2" v-if="this.vueAppVersion >= 110">
              <label class="label-control col-md-3">Ignore Processing Charge on Failure</label>
              <div class="col-md-2">
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  :readonly="readOnly"
                  v-model="billing.ignoreProcessingChargeOnFailure.value.$model"
                ></p-check>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Periodic -->
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Periodic Charges</h2>
          </div>
          <div class="idb-block-content">
            <!-- Admin charge -->
            <div class="form-group row" :class="{ invalid: billing.adminCharge.value.$error }">
              <label class="label-control col-md-3">Admin charge</label>
              <div class="col-md-9">
                <div class="input-group">
                  <span class="input-group-text">£</span>
                  <two-decimal-places-input
                    class="form-control text-right"
                    v-model.number="billing.adminCharge.value.$model"
                    :readonly="readOnly"
                  />
                </div>
                <validation-messages v-model="billing.adminCharge.value" name="Admin charge"></validation-messages>
                <small>Monthly Admin Charge</small>
              </div>
            </div>
            <!-- BACS File Submission Charge -->
            <div
              class="form-group row"
              :class="{ invalid: billing.bacsFileSubmissionCharge.value.$error }"
            >
              <label class="label-control col-md-3">BACS File Submission Charge</label>
              <div class="col-md-9">
                <div class="input-group">
                  <span class="input-group-text">£</span>
                  <two-decimal-places-input
                    class="form-control text-right"
                    v-model.number="billing.bacsFileSubmissionCharge.value.$model"
                    :readonly="readOnly"
                  />
                </div>
                <validation-messages
                  v-model="billing.bacsFileSubmissionCharge.value"
                  name="BACS File Submission Charge"
                ></validation-messages>
                <small>Charge per BACS File Submission, beyond the included number in the contract per month</small>
              </div>
            </div>
            <!-- BACS File Submission Included Files -->
            <div
              class="form-group row"
              :class="{ invalid: billing.bacsFileSubmissionIncludedFiles.value.$error }"
            >
              <label class="label-control col-md-3">BACS File Submission Included Files</label>
              <div class="col-md-9">
                <input
                  class="form-control text-right"
                  type="number"
                  step="1"
                  v-model="billing.bacsFileSubmissionIncludedFiles.value.$model"
                  :readonly="readOnly"
                />
                <validation-messages
                  v-model="billing.bacsFileSubmissionIncludedFiles.value"
                  name="BACS File Submission Included Files"
                ></validation-messages>
                <small>Amount of BACS file submissions included per month</small>
              </div>
            </div>
            <!-- OSU charge -->
            <div
              class="form-group row"
              :class="{ invalid: billing.osuCharge.value.$error }"
              v-if="this.vueAppVersion >= 110"
            >
              <label class="label-control col-md-3">OSU charge</label>
              <div class="col-md-9">
                <div class="input-group">
                  <span class="input-group-text">£</span>
                  <two-decimal-places-input
                    class="form-control text-right"
                    v-model.number="billing.osuCharge.value.$model"
                    :readonly="readOnly"
                  />
                </div>
                <validation-messages v-model="billing.osuCharge.value" name="OSU charge"></validation-messages>
                <small>Monthly OSU Charge</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import { mapGetters } from 'vuex'

// Components
import ChargeSets from '@/Components/Platform/Customer/Charges/Configuration/ChargeSets'
import TwoDecimalPlacesInput from '@/Assets/Components/TwoDecimalPlacesInput'

// Constants
import { billingProperties, billingEnum } from '@/Assets/Constants/billing'

export default {
  name: 'ChargeConfigurationControls',
  props: {
    billing: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    ChargeSets,
    TwoDecimalPlacesInput
  },
  computed: {
    hasErrors () { return this.errors.length > 0 },
    billingProperties () {
      return billingProperties
    },
    billingEnum () {
      return billingEnum
    },
    ...mapGetters(['vueAppVersion'])
  },
  data () {
    return {
      selectedChargeSetName: billingProperties.AdvanceNoticeLetter,
      selectedChargeSet: null
    }
  },
  methods: {
    selectChargeSet () {
      this.selectedChargeSet = this.billing[this.selectedChargeSetName]
    }
  },
  beforeMount () {
    this.selectChargeSet()
  }

}
</script>

<style>
</style>
