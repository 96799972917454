<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        <help-icon docPath="/paygate-collections/reporting/lookaheadreport/" />Look Ahead Report
        <favourite-icon></favourite-icon>
      </h2>
    </div>
    <div>
      <div class="idb-block-content">
        <div class="row form-group mb-3">
          <div class="col-md-2 required">Select a Group</div>
          <div class="col-md-4">
            <group-select
              v-model="group"
              :groups="customerGroups"
              @input="reportChanged"
              :clearable="false"
            ></group-select>
          </div>
        </div>
        <div class="row form-group mb-3">
          <div class="col-md-2 required">Window</div>
          <div class="col-md-4">
            <b-form-select
              class="form-control"
              :options="months"
              value-field="id"
              text-field="name"
              v-model="window"
              @input="reportChanged"
            />
          </div>
        </div>
        <div class="row form-group mb-3" v-if="group === '00000000-0000-0000-0000-000000000000'">
          <div class="col-md-2 required">Merge Groups</div>
          <div class="col-md-4">
            <p-check
              class="p-switch p-fill"
              color="primary"
              v-model="mergeGroups"
              @change="reportChanged"
            />
          </div>
        </div>
      </div>
      <div>
        <b-col>
          <b-tabs>
            <b-tab title="Monthly Collections" @click="renderMonthly">
              <div class="m-3" v-if="loaded && monthlyDisplayed">
                <look-ahead-bar-chart
                  ref="unitChart"
                  :chart-data="lookAheadData"
                  :options="chartOptions"
                ></look-ahead-bar-chart>
              </div>
            </b-tab>
            <b-tab title="Monthly Collections (Cumulative)" @click="renderCumulative">
              <div class="m-3" v-if="loaded && cumulativeDisplayed">
                <look-ahead-bar-chart
                  ref="cumulativeChart"
                  :chart-data="cumulativeData"
                  :options="chartOptions"
                ></look-ahead-bar-chart>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </div>
    </div>
    <div class="idb-block-footer">
      <!-- <b-button type="submit" variant="primary"><i class="fa fa-save mr-2" aria-hidden="true"></i>Save</b-button> -->
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import interpolateColors from '@/Assets/Helpers/ColourHelper.js'
import LookAheadBarChart from '@/Assets/Components/Charts/CollectionsLookAhead'
import Loading from '@/Assets/Components/Loading/Loading'
import { mapGetters } from 'vuex'

export default {
  components: {
    LookAheadBarChart,
    Loading
  },
  data () {
    return {
      monthlyDisplayed: false,
      cumulativeDisplayed: false,
      mergeGroups: true,
      loaded: false,
      loading: false,
      group: '00000000-0000-0000-0000-000000000000',
      window: null,
      cumulativeData: [],
      lookAheadData: [],
      months: [
        { id: 6, name: '6 months' },
        { id: 12, name: '12 months' },
        { id: 18, name: '18 months' },
        { id: 24, name: '24 months' },
        { id: 36, name: '36 months' },
        { id: 48, name: '48 months' },
        { id: 60, name: '60 months' }
      ],
      reports: [],
      chartOptions: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItems, data) {
              return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(tooltipItems.yLabel)
            }
          }
        },
        onClick: this.itemClicked,
        responsive: true,
        maintainAspectRatio: false,
        height: 200
      }
    }
  },
  async mounted () {
    this.window = 6
    await this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
    if (this.customerGroups.length > 1) {
      this.group = '00000000-0000-0000-0000-000000000000'
    }
    // var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
    // if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
    //   this.selectedGroup = this.customerGroups.find(i => i.groupId === defaultGroupId)
    //   // Default group might be a FPS group which means it can't be the BACS default group and vice versa.
    //   if (this.selectedGroup !== undefined) {
    //     this.group = defaultGroupId
    //   }
    // }

    await this.generateReport()
  },
  methods: {
    itemClicked (e, tooltipItems) {
      var l = tooltipItems[0]._model.label.split(' ')
      let month = 0
      let year = parseInt(l[1]);
      switch(l[0]){
        case 'Jan':
          month = 1
          break
        case 'Feb':
          month = 2
          break
        case 'Mar':
          month = 3
          break
        case 'Apr':
          month = 4
          break
        case 'May':
          month = 5
          break
        case 'Jun':
          month = 6
          break
        case 'Jul':
          month = 7
          break
        case 'Aug':
          month = 8
          break
        case 'Sep':
          month = 9
          break
        case 'Oct':
          month = 10
          break
        case 'Nov':
          month = 11
          break
        case 'Dec':
          month = 12
          break
      }
      this.$router.push({ name: 'DrillDown', params: { groupId: this.group, year: year, month: month } })
    },
    async generateReport () {
      this.loading = true
      this.loaded = false
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}reporting/lookahead/`, { params: { paygateid: this.paygateId }, showload: true })
      this.reports = response.data
      this.reportChanged()
      this.monthlyDisplayed = true
    },
    buildData (cumulative) {
      var dataSets = []
      var labels = []
      var colours = interpolateColors('rgb(102,255,102)', 'rgb(255,102,255)', this.reports.length + 1)
      var selectedGroupName = ''
      for (var i = 0; i < this.customerGroups.length; i++) {
        if (this.customerGroups[i].groupId === this.group) {
          selectedGroupName = this.customerGroups[i].name
        }
      }
      var merge = this.mergeGroups
      if (this.group !== '00000000-0000-0000-0000-000000000000') {
        merge = false
      }
      for (var i = 0; i < this.reports.length; i++) {
        if ((this.reports[i].groupName === selectedGroupName) || this.group === '00000000-0000-0000-0000-000000000000') {
          labels = []
          const groupName = this.reports[i].groupName
          const datasets = []
          for (var d = 0; d < this.reports[i].lookAheadResponse.length; d++) {
            if (this.reports[i].lookAheadResponse[d].window === this.window) {
              const data = this.reports[i].lookAheadResponse[d].data
              for (var a = 0; a < data.length; a++) {
                const current = data[a]
                labels.push(this.formatDatePointString(current.datePointString))
                datasets.push(cumulative ? current.cumulativeAmount : current.unitAmount)
              }
            }
          }
          dataSets.push({
            label: groupName,
            backgroundColor: colours[i],
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            data: datasets
          })
        }
      }
      if (merge) {
        const mergedData = []
        for (var l = 0; l < labels.length; l++) {
          mergedData[l] = 0
          for (var m = 0; m < dataSets.length; m++) {
            if (dataSets[m].data && dataSets[m].data.length > 0 && dataSets[m].data[l]) {
              mergedData[l] = mergedData[l] + dataSets[m].data[l]
            }
          }
        }
        dataSets = [{
          label: 'All Groups',
          backgroundColor: colours[0],
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          data: mergedData
        }]
      }
      return { labels: labels, datasets: dataSets }
    },
    formatDatePointString (input) {
      const dateSplit = input.split('/')
      const year = dateSplit[0]
      let month = dateSplit[1]
      switch (month) {
        case '01':
          month = 'Jan'
          break
        case '02':
          month = 'Feb'
          break
        case '03':
          month = 'Mar'
          break
        case '04':
          month = 'Apr'
          break
        case '05':
          month = 'May'
          break
        case '06':
          month = 'Jun'
          break
        case '07':
          month = 'Jul'
          break
        case '08':
          month = 'Aug'
          break
        case '09':
          month = 'Sep'
          break
        case '10':
          month = 'Oct'
          break
        case '11':
          month = 'Nov'
          break
        case '12':
          month = 'Dec'
          break
      }
      return `${month} ${year}`
    },
    renderCumulative () {
      this.cumulativeDisplayed = true
      this.monthlyDisplayed = false
      const temp = this.cumulativeData
      this.cumulativeData = temp
    },
    renderMonthly () {
      this.cumulativeDisplayed = false
      this.monthlyDisplayed = true
      const temp = this.lookAheadData
      this.lookAheadData = temp
    },
    reportChanged () {
      this.loading = true
      this.loaded = false
      this.cumulativeData = this.buildData(true)
      this.lookAheadData = this.buildData(false)
      this.loading = false
      this.loaded = true
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    customerGroups () {
      var ret = []
      if (this.$store.getters.rightsGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.rightsGroups).map(x => {
          const obj = {}
          obj.groupId = x.groupId
          obj.name = x.name
          obj.colour = x.colour
          return obj
        })
      }
      if (ret.length > 1) {
        ret.unshift({ description: '', groupId: '00000000-0000-0000-0000-000000000000', groupType: 'UK DDMS', name: 'All Customers', paygateId: this.paygateId, isItemActioned: false, clonedName: null, colour: null })
      }
      return ret
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    }
  },
  watch: {
    selectedCustomer () {
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      this.generateReport()
    }
  }
}
</script>
<style>
</style>
