<template>
  <vue-good-table
    ref="table"
    mode="remote"
    :search-options="{
          enabled: true,
          initialSortBy: { field: 'creationDate', type: 'desc' }
          }"
    :paginationOptions="paginationOptions"
    :sort-options="sortOptions"
    :totalRows="totalRecords"
    :columns="columns"
    :rows="messages"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
    @on-search="onSearch"
    :lineNumbers="true"
    :isLoading.sync="isTableLoading"
  >
    <template slot="loadingContent">
      <h1>Loading...</h1>
    </template>
    <div slot="emptystate">
      <div class="vgt-center-align vgt-text-disabled">No Messages</div>
    </div>

    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'ukPayerId' && !hasPayerId">
        <router-link :to="{ name: 'editPayer', params: { ukPayerId: props.row.ukPayerId } }">
          <!-- eslint-disable-line -->
          <span>View Customer</span>
        </router-link>
      </span>
      <span v-else-if="props.column.field == 'payerMessageId'">
        <router-link :to="{ name: 'viewMessage', params: { messageId: props.row.payerMessageId } }">
          <!-- eslint-disable-line -->
          <span>View Message</span>
        </router-link>
      </span>
      <span v-else-if="props.column.field === 'status'">
        <b-badge
          pill
          :variant="getVariant(props.row.status)"
        >{{props.formattedRow[props.column.field]}}</b-badge>
      </span>
      <span v-else>{{props.formattedRow[props.column.field]}}</span>
    </template>

    <div slot="table-actions">
      <button
        @click.prevent="customClearTableFilters"
        class="btn btn-link"
        v-b-popover.hover.top.d500="'Clear filters'"
      >
        <span class="fa-stack" style="font-size: 10px;">
          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
        </span>
      </button>
      <b-button
        @click.prevent="load"
        class
        variant="link"
        v-b-popover.hover.top.d500="'Refresh the data in the table'"
      >
        <i class="fa fa-sync pointer dimmedIcon"></i>
      </b-button>
      <b-button
        @click.prevent="exportTable"
        class
        variant="link"
        v-b-popover.hover.top.d500="'Export the message queue'"
      >
        <i class="fa fa-share-square pointer dimmedIcon"></i>
      </b-button>
      <p-check
        id="sentCheck"
        class="p-switch p-fill"
        color="primary"
        v-model="showSent"
        @change="load"
      >Include Sent</p-check>
    </div>
  </vue-good-table>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import EventBus from '@/Lib/eventBus'
import PrettyCheck from 'pretty-checkbox-vue/check'
import papa from 'papaparse'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import { mapGetters } from 'vuex'
import Moment from "moment/moment";
export default {
  mixins: [tableFilterMixin, loading],
  components: {
    pCheck: PrettyCheck
  },
  data () {
    return {
      currentPlanId: null,
      messages: [],
      serverParams: {
        sort: [{ field: 'creationdate', type: 'asc' }]
      },
      columns: [{
        field: 'payerReference',
        label: 'Customer Reference'
      },
      {
        field: 'planReference',
        label: 'Schedule Reference'
      },
      {
        field: 'messageType',
        label: 'Message Type',
        formatFn: (x) => {
          switch (x) {
            case 20:
              return 'Pre-Collection Notification'
            case 19:
              return 'Confirmation Letter'
            case 18:
              return 'Notice of Change (Migration)'
            case 17:
              return 'Refund Cancelled'
            case 16:
              return 'Notice of Change'
            case 15:
              return 'Customer Transferred'
            case 14:
              return 'Customer Closed'
            case 13:
              return 'Custom'
            case 12:
              return 'Cancellation Notice'
            case 11:
              return 'Refund Requested'
            case 10:
              return 'Portal Email Verification'
            case 9:
              return 'Portal Message Sent'
            case 8:
              return 'Email Verification Message'
            case 7:
              return 'Custom Message 5'
            case 6:
              return 'Custom Message 4'
            case 5:
              return 'Custom Message 3'
            case 4:
              return 'Custom Message 2'
            case 3:
              return 'Custom Message 1'
            case 2:
              return 'Multiple Schedule Update'
            case 1:
              return 'First Collections'
            case 0:
              return 'Advance Notice'
            default:
              return 'Unknown'
          }
        }
      },
      {
        field: 'channelType',
        label: 'Type',
        formatFn: (x) => {
          switch (x) {
            case 1:
            default:
              return 'Letter'
            case 0:
              return 'Email'
            case 2:
              return 'SMS'
          }
        }
      },
      {
        field: 'status',
        label: 'Status',
        tdClass: 'text-center',
        formatFn: (x) => {
          switch (x) {
            case 0:
              return 'Error'
            case 1:
              return 'Sent'
            case 2:
              return 'Queued'
            case 3:
              return 'Bounced'
            case 4:
              return 'Recalled'
          }
        }
      },
      {
        field: 'creationDate',
        label: 'Date',
        formatFn: this.formatDate
      },
      {
        field: 'ukPayerId',
        sortable: false
      },
      {
        field: 'payerMessageId',
        sortable: false
      }],
      showSent: false
    }
  },
  props: {
    payerId: {
      type: String,
      required: false
    },
    planId: {
      type: String,
      required: false
    },
    initialFilter: {
      type: String,
      required: false
    },
    canLoad: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.$store.dispatch('clearMessageDetails')
    this.$store.commit('setOnMessagePage', false)
    if (this.$route.query.searchTerm) {
      this.initialFilter = this.$route.query.searchTerm
    }
    if (this.initialFilter && this.initialFilter !== '') {
      this.$refs.table.globalSearchTerm = this.initialFilter
      this.serverParams.searchKey = this.initialFilter
    }
    this.updateTotal = true
    this.currentPlanId = this.planId
    EventBus.$on('changeMessageQueuePlan', async (args) => {
      this.currentPlanId = args
      await this.load()
    })
    this.$set(this.columns[0], 'hidden', this.hasPayerId)
    this.$set(this.columns[6], 'hidden', this.hasPayerId)
    this.showSent = this.$store.getters.includeSent
  },
  methods: {
    formatTitleCaseToSentence (input) {
      return input.replace(/([A-Z])/g, ' $1')
    },
    load: _.debounce(async function () {
      this.$store.dispatch('setIncludeSent', this.showSent)
      if (this.canLoad) {
        // if (this.updateTotal) {
        //   document.querySelector('.footer__navigation__page-info').style.display = 'none'
        // }
        var query = {}
        // query.params.paygateId = this.$store.state.common.paygateId
        query.sort = this.serverParams.sort.field + ':' + this.serverParams.sort.type
        query.perPage = this.serverParams.perPage
        query.page = this.serverParams.page
        if (this.serverParams.searchKey) {
          query.searchFilter = this.serverParams.searchKey
        }
        try {
          const response = this.showSent ? await axios.get(this.messageUrl, {
            params: { ...this.buildGoodTableQuery() },
            showload: true
          }) : await axios.get(this.messageUrl, { params: { ...this.buildGoodTableQuery() }, showload: true })
          this.messages = response.data.data
          this.totalRecords = response.data.meta.totalItems
          // if (this.updateTotal) {
          //   this.totalRecords = response.data.meta.totalItems
          //   // const countResponse = this.showSent ? await axios.get(this.countUrl, { params: { ...this.buildGoodTableQuery() } }) : await axios.get(this.countUrl, { params: { ...this.buildGoodTableQuery() } })
          //   // this.totalRecords = countResponse.data
          //   document.querySelector('.footer__navigation__page-info').style.display = 'inline-block'
          // } else {
          //   document.querySelector('.footer__navigation__page-info').style.display = 'inline-block'
          // }
        } catch (e) {
          console.error(e)
          this.$toastr.e('An error occurred loading the message queue')
        }
        this.updateTotal = false
      }
    }, 300),
    getVariant (status) {
      var variant = ''
      switch (status) {
        case 4:
        case 0:
          variant = 'danger'
          break
        case 1:
          variant = 'success'
          break
        default:
          variant = 'primary'
          break
      }
      return variant
    },
    async exportTable () {
      var query = this.buildGoodTableQuery()
      // query.params.paygateId = this.$store.state.common.paygateId
      query.perPage = this.totalRecords
      query.page = 1
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }
      try {
        const response = this.showSent ? await axios.get(this.messageUrl + '?forexport=true', { params: query }, { showload: true }) : await axios.get(this.messageUrl + '?forexport=true', { params: query }, { showload: true })
        var messages = response.data.data
        var csvString = papa.unparse(messages, { columns: this.hasPlanId ? [ 'planReference','messageType','channelType','status','creationDate','sentDate' ] : [ 'payerReference','planReference','messageType','channelType','status','creationDate','sentDate' ] })
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, 'message-queue.csv')
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = 'message-queue.csv'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      } catch (e) {
        console.error(e)
        this.$toastr.e('An error occurred exporting the message queue')
      }
    },
    customClearTableFilters () {
      this.showSent = false
      this.clearTableFilters()
    },
    onRowClick (event) {
      // forces the table to do pointer cursor on row etc
    },
    onCellClick (event) {
      if (event.column.field !== 'payerMessageId') {
        this.$router.push({ name: 'viewMessage', params: { messageId: event.row.payerMessageId } })
      }
    },
    formatDate (value) {
      return Moment(value).format('DD/MM/YYYY')
    },
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    messageUrl () {
      var url = ''
      if (this.hasPayerId && !this.hasPlanId) {
        url = `${process.env.VUE_APP_DDMS_API_URL}messagequeue${this.showSent ? 'all' : ''}/${this.payerId}`
      } else if (this.hasPayerId && this.hasPlanId) {
        url = `${process.env.VUE_APP_DDMS_API_URL}messagequeue${this.showSent ? 'all' : ''}/${this.payerId}/${this.currentPlanId}`
      } else {
        url = `${process.env.VUE_APP_DDMS_API_URL}messages${this.showSent ? '/all' : ''}`
      }
      return url
    },
    countUrl () {
      var url = ''
      if (this.hasPayerId && !this.hasPlanId) {
        url = `${process.env.VUE_APP_DDMS_API_URL}messagequeuecount${this.showSent ? 'all' : ''}/${this.payerId}`
      } else if (this.hasPayerId && this.hasPlanId) {
        url = `${process.env.VUE_APP_DDMS_API_URL}messagequeuecount${this.showSent ? 'all' : ''}/${this.payerId}/${this.currentPlanId}`
      } else {
        url = `${process.env.VUE_APP_DDMS_API_URL}messagescount${this.showSent ? '/all' : ''}`
      }
      return url
    },
    hasPayerId () {
      return this.payerId && this.payerId !== undefined && this.payerId !== null & this.payerId !== ''
    },
    hasPlanId () {
      return this.currentPlanId && this.currentPlanId !== undefined && this.currentPlanId !== null & this.currentPlanId !== ''
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  }
}
</script>
