<template>
  <div id="intelligibleSummary">
    <div class="row form-group">
      <div class="col-md-12">
        <vue-good-table ref="summaries"
          :columns="columns"
          :rows="customerSubmissions"
          @on-row-click="onRowClick"
          @on-column-filter="afterFilter"
          :pagination-options="{
            enabled: true,
            perPage: 10,
            dropdownAllowAll: false,
            jumpFirstOrLast: true
          }"
          styleClass="vgt-table striped bordered">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'creditRecordCount'">
                {{ formatCount(props.row.creditRecordCount) }}
              </span>
              <span v-else-if="props.column.field === 'creditValue'">
                {{ formatValue(props.row.creditValue) }}
              </span>
              <span v-else-if="props.column.field === 'debitRecordCount'">
                {{ formatCount(props.row.debitRecordCount) }}
              </span>
              <span v-else-if="props.column.field === 'debitValue'">
                {{ formatValue(props.row.debitValue) }}
              </span>
              <span v-else-if="props.column.field === 'ddiRecordCount'">
                {{ formatCount(props.row.ddiRecordCount) }}
              </span>
            </template>
            <div slot="table-actions">
              <button
                @click.prevent="clearTableFilters"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Clear filters'"
              >
                <span class="fa-stack" style="font-size: 10px;">
                  <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                  <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                </span>
              </button>
            </div>
        </vue-good-table>
      </div>
    </div>
    <div v-if="showTotal" class="row form-group">
      <div class="col-md-12">
        <vue-good-table ref="total"
          :columns="totalColumns"
          :rows="summaryTotals"
          :sort-options="{
            enabled: false
          }"
          styleClass="vgt-table striped bordered">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'creditRecordCount'">
                {{ formatCount(props.row.creditRecordCount) }}
              </span>
              <span v-else-if="props.column.field === 'creditValue'">
                {{ formatValue(props.row.creditValue) }}
              </span>
              <span v-else-if="props.column.field === 'debitRecordCount'">
                {{ formatCount(props.row.debitRecordCount) }}
              </span>
              <span v-else-if="props.column.field === 'debitValue'">
                {{ formatValue(props.row.debitValue) }}
              </span>
              <span v-else-if="props.column.field === 'ddiRecordCount'">
                {{ formatCount(props.row.ddiRecordCount) }}
              </span>
            </template>
        </vue-good-table>
      </div>
    </div>
    <br/>
    <div v-if="actionId !== undefined" class="row form-group">
      <div class="col-md-2">
        <button type="button" class="btn btn-outline-secondary" v-on:click="downloadReport" :disabled="isLoading">
          <span class="far fa-arrow-alt-circle-down" style="padding-right: 5px;"></span>Download Submission Report
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  mixins: [
    bacsMixin,
    loading
  ],
  data () {
    return {
      submissionId: '',
      actionId: '',
      fileNumber: 1,
      // intelligibleSummaries: [],
      customerSubmissions: [],
      columns: [
        {
          label: 'Client ID',
          field: 'accountId',
          type: 'number',
          width: '8%',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Name',
          field: 'name',
          width: '15%',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'SUN',
          field: 'serviceUserNumber',
          width: '7%',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Credit Items',
          field: 'creditRecordCount',
          type: 'number',
          formatFn: this.formatNumber,
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '14%'
        },
        {
          label: 'Credit Total (£)',
          field: 'creditValue',
          type: 'number',
          formatFn: this.formatAmount,
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '14%'
        },
        {
          label: 'Debit Items',
          field: 'debitRecordCount',
          type: 'number',
          formatFn: this.formatNumber,
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '14%'
        },
        {
          label: 'Debit Total (£)',
          field: 'debitValue',
          type: 'number',
          formatFn: this.formatAmount,
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '14%'
        },
        {
          label: 'DDIs',
          field: 'ddiRecordCount',
          type: 'number',
          formatFn: this.formatCount,
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '14%'
        }
      ],
      totalColumns: [
        {
          label: '',
          field: 'fileNumber',
          formatFn: this.formatFileColumn,
          tdClass: 'subTotals_totalCellLeft',
          thClass: 'subTotals_hideHeader',
          width: '30%'
        },
        {
          label: '',
          field: 'creditRecordCount',
          tdClass: 'subTotals_totalCell',
          thClass: 'subTotals_hideHeader',
          width: '14%'
        },
        {
          label: '',
          field: 'creditValue',
          tdClass: 'subTotals_totalCell',
          thClass: 'subTotals_hideHeader',
          width: '14%'
        },
        {
          label: '',
          field: 'debitRecordCount',
          tdClass: 'subTotals_totalCell',
          thClass: 'subTotals_hideHeader',
          width: '14%'
        },
        {
          label: '',
          field: 'debitValue',
          tdClass: 'subTotals_totalCell',
          thClass: 'subTotals_hideHeader',
          width: '14%'
        },
        {
          label: '',
          field: 'ddiRecordCount',
          tdClass: 'subTotals_totalCell',
          thClass: 'subTotals_hideHeader',
          width: '14%'
        }
      ],
      summaryTotals: [],
      defaultParams: {},
      filteredFileNumbers: [],
      showTotal: false
    }
  },

  computed: {
    submissionType: function () {
      var subType = ''
      if (this.selectedSummary.workCode !== undefined && this.selectedSummary.workCode.indexOf('FPS') > 0) {
        subType = 'Faster Payment'
      } else {
        subType = 'BACS'
      }
      return subType
    },

    processingDate: function () {
      var procDate = ''
      if (this.selectedSummary.processingDate !== undefined) {
        var date = this.selectedSummary.processingDate
        procDate = date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4)
      }
      return procDate
    },

    savedFilterName: function () {
      return `submissionTotals${this.submissionId}`
    }
  },

  methods: {
    formatAmount (value) {
      return this.formatValue(value)
    },

    formatNumber (value) {
      return this.formatCount(value)
    },

    async downloadReport () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createBacsSummaryReport?submissionId=' + this.submissionId + '&actionId=' + this.actionId
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    },

    async calcSubmissionTotals () {
      let filteredSummaries = this.$refs.summaries.filteredRows[0].children
      if (filteredSummaries.length === 0) {
        // filteredSummaries = this.intelligibleSummaries
        filteredSummaries = this.customerSubmissions
      }

      // Only want to show one totals row.
      if (this.summaryTotals.length > 0) {
        this.summaryTotals.pop(1)
      }

      const totals = {
        creditCount: 0,
        creditValue: 0,
        debitCount: 0,
        debitValue: 0,
        ddiCount: 0
      }

      this.filteredFileNumbers = []

      filteredSummaries.forEach(summary => {
        totals.creditCount += summary.creditRecordCount
        totals.creditValue += summary.creditValue
        totals.debitCount += summary.debitRecordCount
        totals.debitValue += summary.debitValue
        totals.ddiCount += summary.ddiRecordCount

        this.filteredFileNumbers.push(summary.fileNumber)
      })

      const summaryTotal = {
        fileNumber: 0,
        creditRecordCount: totals.creditCount,
        creditValue: totals.creditValue,
        debitRecordCount: totals.debitCount,
        debitValue: totals.debitValue,
        ddiRecordCount: totals.ddiCount
      }
      this.summaryTotals.push(summaryTotal)
      this.showTotal = true
    },

    formatFileColumn (value) {
      let colValue = ''
      if (this.$refs.summaries.filteredRows[0] !== undefined) {
        colValue = this.$refs.summaries.filteredRows[0].children.length === this.customerSubmissions.length ? 'SUBMISSION TOTAL' : 'SELECTED FILES TOTAL'
      } else {
        this.showTotal = false
      }
      return colValue
    },

    onRowClick (params) {
      this.$emit('fileSelectionChange', params.row.fileNumber)
    },

    clearTableFilters () {
      this.$refs.summaries.reset()
      this.$nextTick(() => {
        this.$store.commit('updateTable', { name: this.savedFilterName, filters: this.defaultParams })
        this.calcSubmissionTotals()
        this.$emit('filterChange', this.filteredFileNumbers)
      })
    },

    afterFilter (params) {
      this.$nextTick(() => {
        if (this.$refs.summaries.filteredRows[0] !== undefined) {
          this.$store.commit('updateTable', { name: this.savedFilterName, filters: params })
          this.calcSubmissionTotals()
          this.$emit('filterChange', this.filteredFileNumbers)
        }
      })
    }
  },

  mounted () {
    this.submissionId = this.$store.getters.submissionId
    this.actionId = this.$store.getters.actionId
    this.fileNumber = this.$store.getters.fileNumber
    // this.intelligibleSummaries = this.$store.getters.intelligibleSummaries
    // if (this.intelligibleSummaries.length > 0) {
    //   this.calcSubmissionTotals()
    // }

    this.customerSubmissions = this.$store.getters.customerSubmissions
    if (this.customerSubmissions.length > 0) {
      // console.log('ST customerSubmissions: ' + JSON.stringify(this.customerSubmissions))
      this.calcSubmissionTotals()
    }

    var savedParams = this.$store.getters.tableFilters(this.savedFilterName)
    if (savedParams !== undefined && savedParams.columnFilters !== undefined) {
      const columnnFilterKeys = Object.keys(savedParams.columnFilters)
      for (var i = 0; i < columnnFilterKeys.length; i++) {
        const filterKey = columnnFilterKeys[i]
        // Find the column in the list
        const column = this.columns.find(c => c.field === filterKey)
        // Apply the filter
        const filterValue = savedParams.columnFilters[filterKey]
        this.$set(column.filterOptions, 'filterValue', filterValue)
      }
    }
  }
}
</script>
<style lang="scss">
  .subTotals_hideHeader {
    display: none;
  }

  .subTotals_totalCell {
    width: 14% !important;
    text-align: right;
  }

  .subTotals_totalCellLeft {
    width: 30% !important;
    text-align: left;
  }
</style>
