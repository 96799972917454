<template>
  <div>
    <div id="synchronisationReportDetails">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>{{title}}</h2>
            </div>
            <div class="idb-block-content">
              <div v-if="!showData" v-html="body"></div>
              <div v-if="thresholdData !== ''" v-html="thresholdData"></div>
              <div v-if="showData && action.data">
                <div class="form-group row" v-for="(value, name) in extraInfo" :key="name">
                  <label
                    for="extraInfo"
                    class="col-sm-2 text-right col-form-label"
                  >{{upperCaseProperty(name)}}</label>
                  <div class="col-sm-10">
                    <input type="text" readonly class="form-control-plaintext" :value="value" />
                  </div>
                </div>
              </div>
            </div>
            <div class="idb-block-footer">
              <b-button
                variant="info"
                @click.prevent="removeAction"
                v-if="actionId && actionId !== null"
              >Clear Action</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  computed: {
    extraInfo () {
      if (this.action.data) {
        return this.action.data
      }
      return []
    }
  },
  data () {
    return {
      actionId: null,
      title: 'Loading',
      body: 'Loading',
      removed: false,
      action: null,
      showData: false,
      thresholdData: ''
    }
  },
  methods: {
    async removeAction () {
      if (!this.removed) {
        var response = await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/DeleteRejected/${this.actionId}`)
        if (response) {
          this.removed = true
          this.$toastr.s('Action cleared')
          this.$router.back()
        }
      }
    },
    async getAction () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/${this.actionId}`, { action: true })
      if (response) {
        this.title = response.data.title
        this.body = response.data.message
        if (typeof response.data.data === typeof '') {
          response.data.data = JSON.parse(response.data.data)
        }
        this.thresholdData = response.data.data.thresholdData
        if (response.data.data.error) {
          response.data.data.errorMessage = response.data.data.error.message
          delete response.data.data.error
        }

        this.action = response.data
      }

      if (this.action.actionClass === 'Settlement') {
        this.showData = true
      }
    },
    upperCaseProperty (value) {
      var arr = value.split(/(?=[A-Z])/)
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
      }
      return arr.join(' ')
    }
  },
  async mounted () {
    this.actionId = this.$route.query.actionId
    this.getAction()
  }
}
</script>
