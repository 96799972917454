<template>
  <div id="viewInputReport">
    <div style="width: 98%; margin:0 auto;">
      <p v-html="reportContent"></p>
    </div>
    <div class="idb-block-footer">
      <div class="row form-group">
        <div class="col-md-2">
          <button type="button" class="btn btn-primary" v-on:click="download">Download XML</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'

export default {
  mixins: [
    bacsMixin
  ],
  data () {
    return {
      authResponse: {},
      isLoggedIn: false,
      reportContent: '',
      submissionId: '',
      fileNumber: 0
    }
  },

  methods: {
    async getInputReport () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getInputReportHtmlBySubId?submissionId=${this.submissionId}&fileNumber=${this.fileNumber}`, { showload: true })
      this.reportContent = response.data
      // HTML report not stored so get from Vocalink.
      if (this.reportContent === '') {
        response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getSelectedDownloadedReportBySubId?submissionId=${this.submissionId}&fileNumber=${this.fileNumber}`, { showload: true })
        if (response.data !== null) {
          await this.vocalinkLogin()
          if (this.isLoggedIn) {
            await this.ViewHtmlReport(response.data.retrievalUrl)
          }
        } else {
          this.$snapbar.e('Input report has not been downloaded.')
        }
      }
    },

    async download () {
      var url = `${process.env.VUE_APP_BACS_API_URL}bacs/report/downloadInputReportXml?submissionId=${this.submissionId}&fileNumber=${this.fileNumber}`
      var response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
        showload: true
      })

      this.saveFile(response)
    }
  },

  async mounted () {
    // this.submissionId = this.$store.getters.submissionId
    if (this.submissionId === undefined || this.submissionId === null || this.submissionId === '') {
      this.submissionId = this.$route.query.submissionId
      this.fileNumber = this.$route.query.fileNumber
      // console.log('submissionId: ' + this.submissionId)
      // console.log('fileNumber: ' + this.fileNumber)
    }
    await this.getInputReport()
  }
}
</script>
<style lang="scss">
.primary_line {
  line-height: 1px;
  background-color: #993300;
}
th.voca {
  font-weight: bold;
  font-size: 8pt;
  line-height: 20px;
  background-color: #f5e0d6;
  text-align: left;
}
tr.row1 {
  height: 20px;
  background-color: #ffffff;
}
tr.row111 {
  height: 20px;
  background-color: #ffffff;
}
tr.row2 {
  height: 20px;
  background-color: #faf5f5;
}
tr.row222 {
  height: 20px;
  background-color: #faf5f5;
}
tr.row333 {
  height: 20px;
  background-color: #f5e0d6;
}

.dark-mode {
  .primary_line {
    line-height: 1px;
    background-color: #993300;
  }
  th.voca {
    font-weight: bold;
    font-size: 8pt;
    line-height: 20px;
    background-color: #333333;
    text-align: left;
  }
  tr.row1 {
    height: 20px;
    background-color: #222222;
  }
  tr.row111 {
    height: 20px;
    background-color: #222222;
  }
  tr.row2 {
    height: 20px;
    background-color: #111111;
  }
  tr.row222 {
    height: 20px;
    background-color: #111111;
  }
  tr.row333 {
    height: 20px;
    background-color: #333333;
  }
}
</style>
