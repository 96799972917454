<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        <help-icon docPath="/paygate-collections/messaging/messagetemplates/" />Email Designer
      </h2>
    </div>
    <div id="emailEditor" class="idb-block-content" v-if="!previewOpen">
      <b-row>
        <b-col cols="4" md="4">
          <b-form-group>
            <label for="emailTemplateName">
              <strong>Template Name</strong>
            </label>
            <b-form-input
              class
              id="emailTemplateName"
              v-model="templateName"
              :disabled="loading"
              type="text"
              autocomplete="off"
              :state="templateNameStateValid && !alreadyExists"
              aria-describedby="emailTemplateName emailTemplateNameFeedback"
              placeholder="Email Template Name"
              maxlength="50" @input="checkTemplateNameExists"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!templateNameStateValid" id="emailTemplateNameFeedback">
              The template name may only be up to 50 characters of the following types:
              <ul>
                <li>Upper-case letters A-Z</li>
                <li>Lower-case letters a-z</li>
                <li>Numbers 0-9</li>
                <li>Spaces, Underscores "_", or dashes "-"</li>
              </ul>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="alreadyExists" id="emailTemplateNameFeedback2">
              There is an Email template by that name already in the system.
            </b-form-invalid-feedback>
            <b-form-text id="smsTemplateNameHelp">The name of the template, as it will be appear to users.</b-form-text>
          </b-form-group>
        </b-col>
        <b-col v-if="templateid !== null && deprecatedId !== '00000000-0000-0000-0000-000000000000' && deprecatedId !== null">
          <strong style="padding-top: 2.5em; display: block;">(last approved version {{formatDate(lastModified)}})</strong>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col cols="8">
          <b-form-group>
            <label for="emailSubject">Subject</label>
            <b-form-input
              id="emailSubject"
              v-model.trim="messageTitle"
              type="text"
              autocomplete="off"
              :state="emailTitleStateValid"
              aria-describedby="emailSubjectHelp emailSubjectFeedback"
              placeholder="Enter the email subject"
              maxlength="80"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!emailTitleStateValid" id="emailTitleFeedback">
              The Subject must only have:
              <ul>
                <li>Upper-case letters A-Z</li>
                <li>Lower-case letters a-z</li>
                <li>Numbers 0-9</li>
                <li>Spaces, underscores "_" or dashes "-"</li>
                <li>Square Brackets [] for data population.</li>
              </ul>
            </b-form-invalid-feedback>
            <b-form-text id="emailTitleHelp">The subject seen by the email recipient.</b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <p-check id="templateRequiresApprovalCheck"
                  class="p-switch p-fill"
                  color="primary"
                  v-model="requiresApproval"
                ></p-check>
            <label for="templateRequiresApprovalCheck">
              <strong>This template will be used for Advance Notice/Notification Of Change/Confirmation Letter</strong>
            </label>
            <b-form-text>If a previously approved template is updated, it must undergo the approval process again. The most recent approved version will be used for messaging.</b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group>
        <label>Message Body</label>
        <wysiwyg v-model="messageBody" :upload-url="uploadUrl"></wysiwyg>
      </b-form-group>
    </div>
    <b-modal id="preview-modal" size="lg" :title="'Subject: ' + preview.title" :ok-only="true" ok-title="Close">
      <!-- <h4 v-html="preview.title"></h4> -->
      <div v-html="preview.body"></div>
    </b-modal>
    <!-- <div id="emailPreview" class="idb-block-content" v-if="previewOpen">
      <h4 v-html="preview.title"></h4>
      <div v-html="preview.body"></div>
    </div>-->

    <div class="idb-block-footer">
      <b-btn @click.prevent="saveContent" :disabled="isLoading || alreadyExists || !canEdit" variant="primary">Save</b-btn>&nbsp;
      <b-btn variant="outline-secondary" :disabled="!canEdit" @click.prevent="clearTemplate">New Template</b-btn>&nbsp;
      <!-- <b-btn
        :disabled="previewDisabled"
        @click.prevent="togglePreview"
        variant="info"
      >{{previewButtonLabel}}</b-btn>-->
      <b-btn variant="info" :disabled="isLoading" @click.prevent="togglePreview">Preview</b-btn>
    </div>
  </div>
</template>
<script>
import Wysiwyg from '@/Components/Collections/EmailWysiwyg.vue'
import EventBus from '@/Lib/eventBus'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import roles from "@/Assets/Constants/roles";
const hasNonAlphaNumOrSpaces = (str) => {
  if (!str) {
    return null
  }
  const nonAlphaNumsOrSpace = /[^A-Za-z0-9 -_+()]/
  return !nonAlphaNumsOrSpace.test(str)
}

export default {
  mixins: [loading],
  props: {
    templateid: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      templateName: '',
      previewButtonLabel: 'Preview',
      messageTitle: '',
      messageBody: '',
      loading: !!this.templateid,
      uploadUrl: process.env.VUE_APP_DDMS_API + '/designer/userimages',
      preview: { title: '', body: '' },
      previewOpen: false,
      requiresApproval: false,
      bankApproved: false,
      alreadyExists: false,
      deprecatedId: null,
      lastModified: null
    }
  },
  components: {
    Wysiwyg
  },
  computed: {
    templateNameStateValid () {
      const str = this.templateName.replace(' (last approved version)', '')
      if (!str) {
        return null
      }
      const hasNonTemplateChar = /^[\w\-_\s]*$/g
      return hasNonTemplateChar.test(str)
    },
    previewDisabled () {
      const messageBodyValid = !!this.messageBody
      return !messageBodyValid || !this.emailTitleStateValid
    },
    emailTitleStateValid () {
      const str = this.messageTitle
      if (!str) {
        return null
      }
      const nonAlphaNumsOrSpace = /[^\w \-_\s[\]]/g
      return !nonAlphaNumsOrSpace.test(str)
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    canEdit() {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin ])
    }
  },
  async mounted () {
    if (this.$store.getters.isInRoles([roles.LZAdmin, roles.Implementations, roles.ClientAdmin ]) && this.templateid !== null) {
      var pgResponse = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}helpers/gettemplatepgid/${this.templateid}`)
      this.$store.dispatch('setPaygateId', pgResponse.data)
    }
    this.$store.dispatch('setLastOpened', 'Email')
    const onPaygateChange = async (paygateId) => {
      if (this.templateid) {
        this.loading = true
        try {
          await this.loadTemplateInfo(this.templateid)
          this.loading = false
        } catch (e) {
          this.$toastr.e('An error occurred loading the email template information.', 'Error')
          this.$router.push({ name: 'EmailDesigner' })
        }
      }
    }

    EventBus.$on('paygateIdChange', onPaygateChange)

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })

    onPaygateChange()
  },
  methods: {
    formatDate (date) {
      try {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      } catch (e) {
        return ''
      }
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    async loadTemplateInfo (templateId) {
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/email/` + this.templateid, { showload: true, params: { paygateId: this.paygateId } })
      this.templateName = response.data.name
      this.messageTitle = response.data.messageTitle
      this.messageBody = response.data.messageBody
      this.requiresApproval = response.data.requireBankApproval
      this.bankApproved = response.data.bankApproved
      this.deprecatedId = response.data.deprecatedId
      this.lastModified = response.data.lastModified
    },
    async saveContent () {
      if (this.templateid) {
        await this.trySave(this.templateid, this.paygateId)
      } else {
        await this.tryCreate(this.paygateId)
      }
    },
    async trySave (templateId, paygateId) {
      try {
        const body = {
          name: this.templateName,
          messageBody: this.messageBody,
          messageTitle: this.messageTitle,
          requireBankApproval: this.requiresApproval
        }
        await axios.put(`${process.env.VUE_APP_DDMS_API_URL}designer/email/${templateId}`, body, { showload: true, params: { paygateId: this.paygateId } })
        if (this.requiresApproval) {
          this.$toastr.s('Template updated successfully, and may be used once approved.', 'Template Updated')
        } else {
          this.$toastr.s('Template updated successfully.', 'Template Updated')
        }
        this.$router.push('/collections/message/templates')
      } catch (e) {
        this.$toastr.e('Unable to save changes to the email template.', 'An Error Occurred')
      }
    },
    async tryCreate (paygateId) {
      try {
        const body = {
          name: this.templateName,
          messageBody: this.messageBody,
          messageTitle: this.messageTitle,
          requireBankApproval: this.requiresApproval
        }
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/email`, body, { showload: true, params: { paygateId: this.paygateId } })
        history.pushState({}, 'paygate Collections', window.location + '/' + response.data.templateId)
        if (this.requiresApproval) {
          this.$toastr.s('Template updated successfully, and may be used once approved.', 'Template Created')
        } else {
          this.$toastr.s('Template updated successfully.', 'Template Created')
        }
        this.$router.push('/collections/message/templates')
      } catch (e) {
        this.$toastr.e('Unable to save changes to the email template.', 'An Error Occurred')
      }
    },
    clearTemplate () {
      this.templateName = ''
      this.messageBody = ''
      this.messageTitle = ''
      this.requiresApproval = false
      this.templateId = null
    },
    async viewPreview () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/email/preview`, {
        'bodyTemplate': this.messageBody,
        'titleTemplate': this.messageTitle
      }, { showload: true, params: { paygateId: this.paygateId } })
      this.preview = response.data
    },
    async togglePreview () {
      this.preview = { bodyTemplate: 'Loading...', titleTemplate: '' }
      await this.viewPreview()
      this.$bvModal.show('preview-modal')
    },
    async checkTemplateNameExists () {
      let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/checkforduplicates`, { channel: 0, templateName: this.templateName }, { showload: true, params: { paygateId: this.paygateId } })
      this.alreadyExists = response.data
    }
  }
}
</script>

<style>
#emailTitleInput {
  min-width: 40em;
  margin-bottom: 2.5em;
}

#preview-modal {
  z-index: 1090 !important;
}

#preview-modal___BV_modal_outer_ {
  z-index: auto !important;
}
</style>
