<template>
  <b-modal v-model="value" centered size="lg" @hide="close" no-close-on-backdrop>
    <div slot="modal-header" class="w-100">BACS Payment Amendments</div>
    <div class="row form-group">
      <div class="col-md-4"></div>
      <div class="col-md-4"><strong>Original Values</strong></div>
      <div class="col-md-4"><strong>Amended/Returned Values</strong></div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><strong>Third Party Account Name</strong></div>
      <div class="col-md-4">{{ this.bacsPaymentError.thirdPartyAccountNameOrigRec }}</div>
      <div class="col-md-4">{{ this.bacsPaymentError.thirdPartyAccountNameRetRec }}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><strong>Third Party Sort Code</strong></div>
      <div class="col-md-4">{{ this.bacsPaymentError.thirdPartySortCodeOrigRec }}</div>
      <div class="col-md-4">{{ this.bacsPaymentError.thirdPartySortCodeRetRec }}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><strong>Third Party Account Name</strong></div>
      <div class="col-md-4">{{ this.bacsPaymentError.thirdPartyAccountNumberOrigRec }}</div>
      <div class="col-md-4">{{ this.bacsPaymentError.thirdPartyAccountNumberRetRec }}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><strong>Originating Account Name</strong></div>
      <div class="col-md-4">{{ this.bacsPaymentError.originatorAccountNameOrigRec }}</div>
      <div class="col-md-4">{{ this.bacsPaymentError.originatorAccountNameRetRec }}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><strong>Originating Sort Code</strong></div>
      <div class="col-md-4">{{ this.bacsPaymentError.originatorSortCodeOrigRec }}</div>
      <div class="col-md-4">{{ this.bacsPaymentError.originatorSortCodeRetRec }}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><strong>Originating Account Name</strong></div>
      <div class="col-md-4">{{ this.bacsPaymentError.originatorAccountNumberOrigRec }}</div>
      <div class="col-md-4">{{ this.bacsPaymentError.originatorAccountNumberRetRec }}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><strong>User Reference</strong></div>
      <div class="col-md-4">{{ this.bacsPaymentError.userReference }}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><strong>Amount</strong></div>
      <div class="col-md-4">£ {{ this.bacsPaymentError.amountText }}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><strong>Message</strong></div>
      <div class="col-md-8">{{ this.bacsPaymentError.errorMessage }}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><strong>Message Type</strong></div>
      <div class="col-md-4">{{ this.bacsPaymentError.errorType }}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><strong>Message Code</strong></div>
      <div class="col-md-4">{{ this.bacsPaymentError.errorCode }}</div>
    </div>
    <div slot="modal-footer" class="w-100">
      <b-row>
        <b-col cols="2">
          <b-btn size="sm" class="float-left" variant="primary" @click="close">Close</b-btn>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    value: Boolean,
    bacsPaymentError: Object
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
