<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Service User Numbers
          <favourite-icon />
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <div slot="table-actions">
            <table-actions
              :print-enabled="false"
              :export-enabled="false"
              :reload-table="load"
              :clear-table-filters="clearTableFilters"
              :create-enabled="true"
              :create="create"
              create-tooltip="Create a new Service User Number"
            ></table-actions>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="create" type="button">
          <i class="fa fa-plus rpad"></i>Create Service User Number
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  mixins: [tableFilterMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Alias',
          field: 'alias',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Number',
          field: 'number',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Type',
          field: 'type'
        }
      ],
      serverParams: {
        sort: [{ field: 'alias', type: 'asc' }]
      }
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  methods: {
    onRowClick (params) {
      var sunId = params.row.id
      this.$router.push({ name: 'SunEdit', params: { id: sunId } })
    },
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}sun/List`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading SUNs'
          })
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    create () {
      this.$router.push({ name: 'SunCreate' })
    }
  }
}

</script>
