<template>
  <div @keydown.enter.pevent="gonowhere">
    <b-form-row :class="rowClass">
      <b-form-group :label-cols="4" class="col-sm-6" horizontal label="Customer Reference Generation Pattern">
        <input-tag v-model="tags" :allow-duplicates="true" :add-tag-on-blur="true" placeholder="add pattern" :before-adding="beforeAdding" :validate="isValid" @input="cleanUp" />
        <b-col v-if="errorTriggered">
          <label
            class="text-danger small" v-if="generatedPayer.length > 6"
          >Tag removed as it would generate a reference that violates <a href="#"  style="text-decoration: underline" @click="showRulesModal">scheme rules</a>
          </label>
          <label
            class="text-danger small" v-else>
            Generated reference needs to be a minimum of 6 characters long to follow <a href="#" style="text-decoration: underline" @click="showRulesModal">scheme rules</a>
          </label>
        </b-col>
        <b-col>
          <label class="text-info small">i.e. {{generatedPayer}}</label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-form-row :class="rowClass">
      <b-form-group :label-cols="4" class="col-sm-6" horizontal label="Add pattern (Type, Length)">
        <b-input-group>
          <b-form-select v-model="selectedPattern" :options="types">
            <!-- <template v-slot:first><option :value="null" disabled>Pattern</option></template> -->
          </b-form-select>
          <template v-slot:append>
            <b-form-select v-model="selectedCount" variant="outline-secondary">
              <!-- <template v-slot:first><option :value="null" disabled>x</option></template> -->
              <option v-for="n in 6" :key="n">{{n}}</option>
            </b-form-select>
            <b-button @click="addPattern" :disabled="disableAddition">+</b-button>
          </template>
        </b-input-group>
        <b-col v-if="disableAddition">
          <label
            class="text-warning small"
            v-if="disableAddition"
          >Additional entries will create invalid references that violates <a href="#" style="text-decoration: underline" @click="showRulesModal">scheme rules</a></label>
        </b-col>
        <b-col v-else>
          <label class="small"><a href="#" style="text-decoration: underline" @click="showRulesModal">scheme rules</a></label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-form-row></b-form-row>
    <b-modal id="scheme-rules-modal" size="xl" ref="scheme-rules-modal" title="Scheme Rules" :ok-only="true">
      <ul>
        <li>A core reference may be up to 18 characters long and must be a minimum of 6 characters.</li>
        <li>Although any of the allowed Bacs characters may be included in the core reference*, only upper
          case alpha and numeric characters will be considered in checking for the minimum length of 6
          characters. Use of “DDIC” in the first four characters of the reference is prohibited. This is
          reserved for PSP use only</li>
        <li>It must not consist of all the same characters e.g. all zeros</li>
        <li>It must be left justified within field 10 of the DDI and Direct Debit record. This requires the first
          alpha/numeric character of the reference to appear in character position one of this field</li>
        <li>It must be lodged with the paying PSPs exactly as it appears on a signed DDI</li>
        <li>It must be unique for the sort code, account number and SUN to ensure that the paying PSPs can
          accurately match Direct Debits to DDIs. It must not be possible to match the core reference quoted
          in the DDI wholly or in part to the core reference of any other DDI already held by the paying PSP
          for the same sort code, account number and SUN</li>
      </ul><br/>* Other allowed characters are space, ampersand (&), hyphen, full stop and solidus (/).
    </b-modal>
  </div>
</template>
<script>
import InputTag from 'vue-input-tag'
export default {
  props: {
    value: {
      default: '[Firstname]{3}|[Surname]{3}|[0-9A-Z]{2}|[Sequence]{2}',
      type: String
    },
    rowClass: String
  },
  data () {
    return {
      tagPattern: /^\[[0-9a-zA-Z-]+\]\{[0-9]*\}$/,
      countPattern: /\{[0-9]*\}/,
      typePattern: /\[[0-9a-zA-Z-]+\]/,
      maxLength: 18,
      selectedPattern: '[Sequence]',
      selectedCount: 3,
      patterns: [],
      tags: [],
      tag: '',
      currentLength: 0,
      errorTriggered: false,
      types: [
        { text: 'First Name', value: '[Firstname]' },
        { text: 'Surname', value: '[Surname]' },
        { text: 'Next in sequence', value: '[Sequence]' },
        { text: 'Random Letter', value: '[A-Z]' },
        { text: 'Random Number', value: '[0-9]' },
        { text: 'Random Number or Letter', value: '[0-9A-Z]' }
      ]
    }
  },
  computed: {
    payerReference: {
      get () {
        return this.value || ''
      },
      set (payerReference) {
        this.$emit('input', payerReference)
      }
    },
    disableAddition () {
      return (this.currentLength + parseInt(this.selectedCount)) > this.maxLength
    },
    generatedPayer () {
      var testData = [
        { value: '[Firstname]', data: 'JOSEPH' },
        { value: '[Surname]', data: 'JOHNSON' },
        { value: '[Sequence]', data: '000000000000000000001' },
        { value: '[A-Z]', data: 'ASADFGRTRTFPOJHUHMDYYGMIHLIH' },
        { value: '[0-9]', data: '316813818833813814138138' },
        { value: '[A-Z0-9]', data: 'A3E12A3ASD5HS7XG12SSD78K12381' },
        { value: '[0-9A-Z]', data: 'A3E12A3ASD5HS7XG12SSD78K12381' }
      ]
      var output = ''
      this.tags.forEach((element) => {
        var match = element.match(this.tagPattern)
        if (match !== null) {
          var countStr = element.match(this.countPattern)[0]
          var tagStr = element.match(this.typePattern)[0]
          var data = testData.find(x => x.value === tagStr)
          if (data !== null) {
            var count = parseInt(countStr.replace('{', '').replace('}', ''))
            output += data.value === '[Sequence]' ? data.data.slice(count * -1) : data.data.substring(0, count)
          } else {
            output += element
          }
        } else {
          output += element
        }
      })
      return output
    }
  },
  methods: {
    gonowhere (e) {
      if (e.keyCode === 13) {
        e.preventDefault()
        return false;
      }
    },
    showRulesModal () {
      this.$bvModal.show('scheme-rules-modal')
      setTimeout(this.loadQuill, 500)
    },
    addPattern (e) {
      this.tags.push(`${this.selectedPattern}{${this.selectedCount}}`)
      this.currentLength += this.selectedCount
    },
    updateCount () {
      var count = 0
      this.tags.forEach((element) => {
        var match = element.match(this.tagPattern)
        if (match !== null) {
          var countStr = element.match(this.countPattern)[0]
          count += parseInt(countStr.replace('{', '').replace('}', ''))
        } else {
          count += element.length
        }
      })
      this.currentLength = count
      let payer = this.generatedPayer
      let allCharactersSame = false
      if (payer.toString().length > 0) {
         allCharactersSame = this.allCharactersSame(payer.toString())
      }
      this.errorTriggered = payer.length < 6 || payer.length > 18 || allCharactersSame || payer.toString().startsWith('DDIC')
      this.$emit('invalidReference', this.errorTriggered)
    },
    isValid (inputString) {
      let matches = inputString.match(/^[A-Za-z0-9&.\-\s/]+$/g)
      let strL = inputString.length
      let curr = this.currentLength
      let allowAddition = !this.disableAddition
      let matchCount  = matches !== null ? matches.length : 0
      let val = allowAddition && strL > 0 && (strL + curr) < 18 && matchCount > 0
      if(matches === null) {
        var tagList = document.getElementsByClassName('new-tag')
        for(var i = 0; i < tagList.length; i++) {
          console.log(tagList[i])
          tagList[i].value = ''
        }

      }
      this.$emit('invalidReference', !val)
      return val
    },
    cleanUp (e) {
      console.log(e)
    },
    beforeAdding (tag) {
      if (!tag.startsWith('[')){
        tag = tag.toUpperCase()
      }
      return tag
    },
    allCharactersSame (s) {
      if (s.length !== undefined || s.length !== null) {
        let n = s.length
        for (let i = 1; i < n; i++)
          if (s[i] !== s[0]) {
            return false
          }
      }
      return true
    }
  },
  watch: {
    tags () {
      this.$emit('input', this.tags.join('|'))
      this.updateCount()
    },
    payerReference (value, oldValue) {
      if (value !== oldValue) {
        let splitVal = value.split('|')
        if (splitVal.length === 1 && splitVal[0] === '') {
          splitVal = []
        }
        this.tags = splitVal
        this.updateCount()
      }
    },
    currentLength (value) {
      if (value > this.maxLength) {
        this.tags.pop()
        this.errorTriggered = true
        setTimeout(() => { this.errorTriggered = false }, 5000)
      }
    }
  },
  components: {
    InputTag
  },
  mounted () {
    this.tags = this.payerReference.split('|')
    this.updateCount()
  }
}
</script>
<style>
.dark-mode .vue-input-tag-wrapper .input-tag {
  background-color: #508cfc !important;
  border: 1px solid #508cfc !important;
}
</style>
