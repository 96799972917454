<template>
  <div class="mb-6">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            Workflow Triggers
            <span class="pull-right">
              <a :href="`${docUrl}/automation/paygate-workflow/triggers/`" target="_blank">
                <i class="far fa-question-circle"></i>
              </a>
            </span>
            <favourite-icon></favourite-icon>
          </h2>
        </div>
        <div class="idb-block-content">
          <div class>
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              ref="table"
              :columns="columns"
              :rows="rows"
              :lineNumbers="true"
              :search-options="{ enabled: true }"
              @on-row-click="onRowClick"
              @on-cell-click="onCellClick"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              styleClass="vgt-table striped bordered table-hover"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'enabled'">
                  <span class="text-success" v-if="props.row.enabled == true">
                    <i class="glyphicon ti-check"></i>
                  </span>
                  <span class="text-danger" v-else>
                    <i class="glyphicon ti-close"></i>
                  </span>
                </span>
                <span v-else-if="props.column.field == 'triggerType'">
                  <span v-if="props.row.triggerType == 'scheduleTrigger'">
                    <i class="far fa-calendar-alt"></i> Schedule Trigger
                  </span>
                  <span v-else-if="props.row.triggerType == 'fileTrigger'">
                    <i class="far fa-file"></i> File Trigger
                  </span>
                  <span v-else-if="props.row.triggerType == 'cronTrigger'">
                    <i class="far fa-clock"></i> Cron Trigger
                  </span>
                  <span v-else>Unknown</span>
                </span>
                <span v-else-if="props.column.field == 'data'">
                  <span v-if="props.row.triggerType == 'scheduleTrigger'"></span>
                  <span
                    v-else-if="props.row.triggerType == 'fileTrigger'"
                  >{{props.row.configuration.watchFolder}}</span>
                  <span
                    v-else-if="props.row.triggerType == 'cronTrigger'"
                  >{{props.row.configuration.cronExpression}}</span>
                  <span v-else>Unknown</span>
                </span>
                <span v-else-if="props.column.field == 'workflows'">
                  <span>{{props.row.boundWorkflows.filter(v => v.enabled).length}}</span>
                </span>
                <span v-else-if="props.column.field == 'buttons'">
                  <b-button
                    @click.prevent="btnTelemetry(props)"
                    variant="outline-info"
                    class="pointer"
                    v-b-popover.hover.top.d500="'View Telemetry'"
                  >
                    <i class="glyphicon ti-list"></i>
                  </b-button>
                  <b-button
                    v-if="props.row.triggerType === 'scheduleTrigger' && props.row.enabled == true"
                    @click.prevent="btnViewSchedule(props)"
                    variant="outline-info"
                    class="pointer ml-2"
                    v-b-popover.hover.top.d500="'View Schedule'"
                  >
                    <i class="glyphicon ti-calendar"></i>
                  </b-button>
                  <b-button
                    v-if="props.row.triggerType === 'cronTrigger' && props.row.enabled == true"
                    @click.prevent="btnViewCronSchedule(props)"
                    variant="outline-info"
                    class="pointer ml-2"
                    v-b-popover.hover.top.d500="'View Schedule'"
                  >
                    <i class="glyphicon ti-calendar"></i>
                  </b-button>
                </span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
              </template>
              <div slot="table-actions">
                <button
                  @click.prevent="clearTableFilters"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Clear filters'">
                  <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                  </span>
                </button>
                <b-button
                  @click.prevent="load"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Refresh the data in the table'"
                >
                  <i class="fas fa-sync-alt pointer dimmedIcon pointer"></i>
                </b-button>
              </div>
            </vue-good-table>
          </div>
        </div>
        <div class="idb-block-footer mb-3">
          <div>
            <div class="btn-group" role="group">
              <button
                id="btnGroupDrop1"
                type="button"
                class="btn btn-primary mr-2 dropdown-toggle"
                v-b-popover.hover.top.d500="'Create a new trigger'"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >New Trigger</button>
              <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <a class="dropdown-item" @click.prevent="newCronTrigger" href="#">
                  <i class="far fa-clock"></i> Cron Trigger
                </a>
                <a class="dropdown-item" @click.prevent="newScheduleTrigger" href="#">
                  <i class="far fa-calendar-alt"></i> Schedule Trigger
                </a>
                <a class="dropdown-item" @click.prevent="newFileTrigger" href="#">
                  <i class="far fa-file"></i> File Trigger
                </a>
              </div>
            </div>
            <b-button
              @click.prevent="viewAllTelemetry"
              class="mr-3"
              variant="outline-primary"
              v-b-popover.hover.top.d500="'View all telemetry across all trigger\'s.'"
            >View All Telemetry</b-button>
            <b-button
              @click.prevent="triggerExplorer"
              class="mr-3"
              variant="outline-primary"
              v-b-popover.hover.top.d500="'View the trigger scheduler queue.'"
            >Trigger Explorer</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import swal from 'sweetalert2'
export default {
  name: 'my-component',
  components: {
    swal
  },
  mixins: [tableFilterMixin, loading],
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      // licence: {},
      columns: [
        {
          label: 'Id',
          field: '_id',
          hidden: true
        },
        {
          label: 'Title',
          field: 'title',
          sortable: true,
          filterOptions: { enabled: false }
        },
        {
          label: 'Description',
          field: 'description',
          sortable: true,
          filterOptions: { enabled: false }
        },
        {
          label: 'Type',
          field: 'triggerType',
          sortable: true,
          filterOptions: { enabled: false }
        },
        {
          label: 'Data',
          field: 'data',
          sortable: false,
          filterOptions: { enabled: false }
        },
        {
          label: 'Workflows',
          field: 'workflows',
          sortable: false,
          filterOptions: { enabled: false }
        },
        {
          label: 'Enabled',
          field: 'enabled',
          type: 'bool',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
          filterOptions: { enabled: false }
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
          filterOptions: { enabled: false }
        }
      ],
      row_data: [],
      serverParams: {
        sort: [{ field: 'title', type: 'asc' }]
      }
    }
  },
  computed: {
    rows: function () {
      return this.row_data
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    triggerExplorer () {
      this.$router.push('/automation/system/triggerexplorer')
    },
    async load () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}triggers`, { showload: true })
        if (res && res.data && res.data.triggers) {
          this.row_data = res.data.triggers
        } else {
          this.$snapbar.e('Could not get a list of triggers.')
          this.row_data = []
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Triggers.')
        } else {
          this.$snapbar.e(`Could not get a list of Triggers - ${e.message}`)
        }
        this.row_data = []
      }
    },
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
    },
    onCellClick (params) {
      if (params.column.field !== 'buttons') {
        this.$router.push(`/automation/workflow/triggerconfig/${params.row.triggerType}/${params.row._id}`)
      }
    },
    onRowClick (params) {
    },
    btnTelemetry (props) {
      this.$router.push(`/automation/workflow/triggerTelemetry/${props.row._id}`)
    },
    viewAllTelemetry () {
      this.$router.push('/automation/workflow/triggerTelemetry')
    },
    newScheduleTrigger () {
      this.$router.push('/automation/workflow/triggerConfig/scheduleTrigger/')
    },
    newCronTrigger () {
      this.$router.push('/automation/workflow/triggerConfig/cronTrigger/')
    },
    newFileTrigger () {
      this.$router.push('/automation/workflow/triggerConfig/fileTrigger/')
    },
    async btnViewCronSchedule (props) {
      const that = this
      let resTrigger
      let trigger
      try {
        resTrigger = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}trigger/${props.row._id}`)
        trigger = resTrigger.data.trigger
      } catch (e) {
        this.$snapbar.e(`Could not get schedule data from PayGate - ${e.message}`)
        return
      }

      if (trigger) {
        const postObj = {
          cronExpression: trigger.configuration.cronExpression
        }
        let res
        try {
          res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}cronToSchedule`, postObj)
          if (res) {
            let html = '<hr><ul class="list-unstyled">'
            res.data.forEach(function (element) {
              const md = that.reformatDate(element)
              html += `<li>${md}</li>`
            })
            html += '</ul>'
            const result = await swal.fire({
              title: 'Cron Schedule for ' + trigger.configuration.cronExpression,
              html: html,
              showCancelButton: false
            })
          }
        } catch (e) {
          this.$snapbar.e(`Could not get schedule data from PayGate - ${e.message}`)
        }
      }
    },
    async btnViewSchedule (props) {
      const that = this
      let resTrigger
      let trigger

      try {
        resTrigger = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}trigger/${props.row._id}`)
        trigger = resTrigger.data.trigger
      } catch (e) {
        this.$snapbar.e(`Could not get schedule data from PayGate - ${e.message}`)
        return
      }

      if (trigger) {
        const res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}getscheduletriggerschedule`, trigger)
        if (res) {
          let html = '<hr>'
          res.data.forEach(function (element) {
            const nd = that.reformatDate(element)
            html += `<h4>${nd}</h4>`
          })
          const result = await swal.fire({
            title: 'Trigger Schedule',
            html: html,
            showCancelButton: false
          })
        }
      }
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1
      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      // let ms = this.pad(myDate.getMilliseconds(), 3, '0')
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    pad (n, width, z) {
      z = z || '0'
      n += ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    }
    // async loadLicence () {
    //   try {
    //     const response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence')
    //     this.licence = response.data.automation
    //     if (!this.licence.workflow) this.$snapbar.w('You are not licensed to use Workflows.')
    //   } catch { }
    // }
  },
  created: async function () {
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      await this.load()
    }
  }
}
</script>
<style scoped>
  td {
    cursor: pointer;
  }
</style>
