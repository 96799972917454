<template>
  <vue-good-table mode="remote"
                  ref="table"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="true"
                  :totalRows="totalRecords"
                  :search-options="{
                    enabled: true
                    }"
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :isLoading.sync="isTableLoading"
                  styleClass="vgt-table striped bordered">
    <template slot="loadingContent">
      <h1>Loading...</h1>
    </template>
    <div slot="emptystate">
      <div class="vgt-center-align vgt-text-disabled">
        No Custom Templates Available
      </div>
    </div>
    <div slot="table-actions">
      <button
        @click.prevent="clearTableFilters"
        class="btn btn-link"
        v-b-popover.hover.top.d500="'Clear filters'"
      >
                        <span class="fa-stack" style="font-size: 10px;">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span>
      </button>
      <b-button @click.prevent="load" class="" :disabled="isLoading" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'">
        <i class="fa fa-sync pointer dimmedIcon"></i>
      </b-button>
    </div>
    <template v-b-tooltip="'ToolTip!'" slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'downloadUrl'">
                <b-btn
                  variant="outline-primary"
                  @click.prevent="downloadDocx(props.row.templateId)"
                  title="Download Template Document"
                >
                  <i class="fa fa-download"></i>
                </b-btn>
              </span>
      <span v-else-if="props.column.field === 'deleteUrl'">
                <b-btn
                  variant="outline-danger"
                  @click.prevent="deleteTemplate(props.row)"
                  title="Delete Template"
                >
                  <i class="fa fa-trash"></i>
                </b-btn>
              </span>
      <span v-else-if="props.column.field === 'preview'">
                <router-link
                  :to="{ name: 'SpecificLetterTemplate', params: { templateid: props.row.templateId }  }"
                  class="btn btn-primary"
                  title="View / Edit Template"
                >{{ canEdit ? 'View / Edit' : 'View'}}</router-link>
              </span>
      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
    </template>
  </vue-good-table>
</template>

<script>
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import axios from "axios"
import _ from "lodash"
import {mapGetters} from "vuex"
import swal from "sweetalert2"
import colours from "@/Assets/Constants/colours"
import FileSaver from 'file-saver'
import roles from "@/Assets/Constants/roles";

export default {
  async mounted () {
    this.load()
  },
  props: {
    sessionId: String
  },
  mixins: [tableFilterMixin, loading],
  name: "LetterTable",
  data () {
    return {
      totalRecords: 0,
      rows: [],
      columns: [
        {
          hidden: true,
          field: 'templateId'
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Last Modified',
          field: 'lastModified',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }
        },
        {
          label: '',
          field: 'preview',
          width: '5rem',
          sortable: false
        },
        {
          field: 'downloadUrl',
          sortable: false,
          width: '2rem'
        },
        {
          field: 'deleteUrl',
          sortable: false,
          width: '2rem'
        }
      ],
      serverParams: {
        sort: [{ field: 'name', type: 'asc' }],
      }
    }
  },
  methods: {
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    onRowClick (event) {
      // forces the table to do pointer cursor on row etc
    },
    onCellClick (event) {
      //
    },
    async downloadDocx (templateId) {
      try {
        let url = `${this.baseDownloadUri}/${templateId}/docx`
        const response = await axios.get(url, {
          responseType: 'blob',
          headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          }, params: { paygateId: this.paygateId }
        })
        FileSaver.saveAs(response.data, `${templateId}-${new Date().toISOString()}.docx`)
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to download template.', 'An error occurred.')
      }
    },
    load: _.debounce(async function () {
      this.isTableLoading = true
      try {
        const query = this.buildGoodTableQuery()
        let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/letter`, { params: { ...query }})
        this.totalRecords = response.data.totalItems
        this.rows = response.data.data
      } catch (e) {
        console.log(e)
        this.$toastr.e('Unable to get Letter Templates', 'Error')
      }
      this.isTableLoading = false
    }, 300),
    async deleteTemplate (row) {
      const { templateId, name } = row

      var result = await swal.fire({
        title: `Delete '${name}'?`,
        text: 'You will not be able to recover the template. Do you want to delete this template?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        try {
          await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/${templateId}`, { params: { paygateId: this.paygateId } })
          this.$toastr.s(`Template '${name}' has been deleted.`, 'Template Deleted')
          this.load()
        } catch (e) {
          this.$toastr.e('Unable to delete the template.', 'Error Occurred')
        }
      }
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    baseDownloadUri () {
      return process.env.VUE_APP_DDMS_API_URL + 'designer/letter'
    },
    canEdit() {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin ])
    }
  },
  watch: {
    selectedCustomer () {
      this.load()
    }
  }
}
</script>

<style scoped>

</style>
