<template>
  <div style="height:100%">
    <div class="row">
      <div class="col-md-12">
        <h5 class="unpad">Trigger Explorer
          <span>
            <b-button @click.prevent="getTriggerData" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the Trigger Explorer data.  Note the data is already automatically updated in the background.'" ><i class="fas fa-sync-alt pointer"></i></b-button>
          </span>
          <span class="validationText">
            {{errorMessage}}
          </span>
        </h5>
      </div>
      <div class="col-md-9">
      </div>
    </div>
    <div class="row">
      <div class="col font-weight-bold small">Name</div>
      <div class="col font-weight-bold small">Type</div>
      <div class="col font-weight-bold small">Next Invocation</div>
      <div v-if="widget.w>2" class="col font-weight-bold small">Bound Workflows</div>
      <div class="col font-weight-bold small"></div>
    </div>
    <hr>
    <VuePerfectScrollbar class="scroll-area" :settings="settings">
      <div v-for="(item, index) in triggerData" :key="index">
        <div class="row"  >
          <div class="col small">
            <span v-if="item.trigger.triggerType == 'scheduleTrigger'">
              <router-link tag="a" :to="'/automation/workflow/triggerconfig/scheduleTrigger/' + item.id" class="">{{item.trigger.title}}</router-link>
            </span>
            <span v-if="item.trigger.triggerType == 'cronTrigger'">
              <router-link tag="a" :to="'/automation/workflow/triggerconfig/cronTrigger/' + item.id" class="">{{item.trigger.title}}</router-link>
            </span>
          </div>
          <div class="col small">
            <span  v-if="item.trigger.triggerType == 'scheduleTrigger'"><i class="far fa-calendar-alt"></i> Schedule Trigger</span>
            <span  v-else-if="item.trigger.triggerType == 'cronTrigger'"><i class="far fa-clock"></i> Cron Trigger</span>
          </div>
          <div class="col small">
            {{reformatDate(item.nextInvocation)}}
          </div>
          <div class="col small" v-if="widget.w>2">
            <div class="mb-2" v-for="(wf, index) in item.trigger.boundWorkflows" :key="index">
              <span v-if="wf.enabled"><i class="far fa-check-circle text-success"></i></span>
              <span v-else><i class="far fa-times-circle text-danger"></i></span>
              {{wf.Title}} (
              <router-link tag="a" :to="'/automation/workflow/workflowdetail/' + wf.id" class="">Edit</router-link>
              )
            </div>
          </div>
          <div class="col small">
            <div class="btn-group" role="group" aria-label="Basic example">
            <button @click="btnCancelNext(item)" class="btn btn-small btn-link text-danger" v-b-popover.hover.top.d500="'Cancel next trigger'"><i class="fas fa-times"></i></button>
            <button @click="btnReset(item)" class="btn btn-small btn-link text-muted " v-b-popover.hover.top.d500="'Reset cancelled triggers'"><i class="fas fa-redo"></i> </button>
            <button @click="btnTelemetry(item)" class="btn btn-small btn-link text-muted" v-b-popover.hover.top.d500="'View Logs'"><i class="fas fa-list"></i> </button>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
  name: 'WorkflowTriggerExplorerWidget',
  components: {
    VuePerfectScrollbar
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  watch: {
    'widget.options': {
      async handler () {
        this.getTriggerData()
      },
      deep: true
    }
  },
  data () {
    return {
      // workflow: {},
      interval: null,
      triggerData: [],
      settings: {
        maxScrollbarLength: 60
      },
      errorMessage: ''
    }
  },
  created: async function () {
    await this.getTriggerData()
    console.log('w: ' + this.widget.w)
  },
  mounted: function () { // Don't use an arrow function
    this.interval = setInterval(function () {
      this.getTriggerData()
    }.bind(this), 30000)
  },
  destroyed: function () {
    clearInterval(this.interval)
  },
  methods: {
    async btnReset (trigger) {
      try {
        await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}resettrigger/${trigger.id}`)
        await this.getTriggerData()
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.errorMessage = 'You are not authorised to reset this trigger.'
        } else {
          this.errorMessage = `Could not reset this trigger - ${e.message}`
        }
      }
    },
    async btnCancelNext (trigger) {
      try {
        await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}cancelnexttrigger/${trigger.id}`)
        await this.getTriggerData()
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.errorMessage = 'You are not authorised to cancel this trigger.'
        } else {
          this.errorMessage = `Could not cancel this trigger - ${e.message}`
        }
      }
    },
    btnTelemetry (trigger) {
      this.$router.push(`/automation/workflow/triggerTelemetry/${trigger.id}`)
    },
    async getTriggerData () {
      console.log('getTriggerData')
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}triggerexplorer`)
        if (res) {
          this.triggerData = res.data
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.errorMessage = 'You are not authorised to view this trigger.'
        } else {
          this.errorMessage = `Could not get this trigger - ${e.message}`
        }
      }
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1
      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    pad (n, width, z) {
      z = z || '0'
      n += ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    }
  }
}
</script>

<style lang="scss" scoped>
  .scroll-area {
    position: relative;
    height: 300px;
  }
</style>
