<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Collections Template Approval<favourite-icon></favourite-icon></h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :columns="columns"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
            enabled: true
            }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered">
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">
              No templates are awaiting approval.
            </div>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'buttons'">
              <b-button class="mr-2" @click="downloadTemplate(props.row.templateId, props.row)" variant="outline-secondary">
                <i class="fas fa-save mr-2"></i>View Template
              </b-button>
              <b-button class="mr-2" @click="approveTemplate(props.row.templateId, props.row)" variant="outline-success">
                <i class="fas fa-check mr-2"></i>Approve
              </b-button>
              <b-button class="mr-2" @click="rejectTemplate(props.row.templateId, props.row)" variant="outline-danger">
                <i class="fas fa-times mr-2"></i>Reject
              </b-button>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
            <span class="fa-stack" style="font-size: 10px;">
              <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
              <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
            </span>
            </button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer" v-if="actionId && actionId !== null">
        <b-button class="ml-2" variant="danger" @click.prevent="removeAction" v-if="actionId && actionId !== null">Clear Action</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import moment from 'moment'
import swal from 'sweetalert2'
export default {
  mixins: [tableFilterMixin, loading],
  data () {
    return {
      actionId: null,
      rows: [],
      columns: [
        {
          label: 'id',
          field: 'templateId',
          hidden: true
        },
        {
          label: 'Template Name',
          field: 'templateName'
        },
        {
          label: 'Customer',
          field: 'customerName'
        },
        {
          label: 'Last Modified',
          field: 'lastModified',
          formatFn: this.formatDate,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
          tdClass: 'text-center'
        }
      ],
      serverParams: {
        sort: [{ field: 'lastModifed', type: 'asc' }],
      }
    }
  },
  methods: {
    load: _.debounce(async function () {
      // await this.$store.dispatch('getGroupConfigurationFromGroupId', { id: this.group })
      var query = this.buildGoodTableQuery()
      let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}templateapproval`, { params: { ...query }})
      this.rows = response.data.data
      this.totalRecords = response.data.totalRecords
    }, 300),
    removeAction () {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}messages/clearaction/${this.actionId}`).then(
        (response) => {
          this.$toastr.s('Action cleared')
          this.actionId = null
          this.$router.push('/actions')
        }
      )
    },
    async approveTemplate (templateId, rowObject) {
      try {
        rowObject.bankApprovalStatus = 2
        let response = await axios.put(`${process.env.VUE_APP_DDMS_API_URL}templateapproval/${templateId}`, rowObject)
        this.$toastr.s('Template Flagged as approved.')
        await this.load()
      } catch (e) {
        this.$toastr.e('Failed to approve template')
      }
    },
    async rejectTemplate (templateId, rowObject) {
      try {
        rowObject.bankApprovalStatus = 3
        const result = await swal.fire({
          title: 'Reject Template',
          text: 'Please provide a rejection reason:',
          type: 'warning',
          input: 'textarea',
          animation: true,
          showCancelButton: true
        })
        if (result.isConfirmed) {
          rowObject.notes = result.value
          let response = await axios.put(`${process.env.VUE_APP_DDMS_API_URL}templateapproval/${templateId}`, rowObject)
          this.$toastr.s('Template Flagged as rejected.')
          await this.load()
        }
      } catch (e) {
        this.$toastr.e('Failed to reject template')
      }
    },
    async downloadTemplate (templateId, rowObject) {
      let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}templateapproval/${templateId}/download`)
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(this.base64ImageToBlob(response.data))
      a.download = `${rowObject.templateName}-${rowObject.customerName}.pdf`
      // Append anchor to body.
      document.body.appendChild(a)
      a.click()
      // Remove anchor from body
      document.body.removeChild(a)
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onRowClick (event) {
      // forces the table to do pointer cursor on row etc
    },
    onCellClick (event) {
      //
    },
    base64ImageToBlob (str) {
      // extract content type and base64 payload from original string
      var imageContent = atob(str)

      // create an ArrayBuffer and a view (as unsigned 8-bit)
      var buffer = new ArrayBuffer(imageContent.length)
      var view = new Uint8Array(buffer)

      // fill the view, using the decoded base64
      for (var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }

      // convert ArrayBuffer to Blob
      var blob = new Blob([buffer], { type: 'application/pdf' })
      return blob
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
  },
  computed: {

  },
  async mounted () {
    this.actionId = this.$route.query.actionId
    this.load()
  }
}
</script>
