<template>
  <span v-if="value.$error" class="validation-messages">
    <slot name="override">
      <!-- maxLength -->
      <small
        class="form-text small"
        v-if="value.maxLength != undefined && !value.maxLength"
      >Please enter {{aOrAn}} {{name}} shorter than or equal to {{ value.$params.maxLength.max }} characters</small>

      <!-- minLength -->
      <small
        class="form-text small"
        v-if="value.minLength != undefined && !value.minLength"
      >Please enter {{aOrAn}} {{name}} longer than or equal to {{ value.$params.minLength.min }} characters</small>

      <!-- exactLength -->
      <small
        class="form-text small"
        v-if="value.exactLength != undefined && !value.exactLength"
      >Please enter {{aOrAn}} {{name}} equal to {{ value.$params.exactLength.exact }} characters</small>

      <!-- maxValue -->
      <small
        class="form-text small"
        v-if="value.maxValue != undefined && !value.maxValue"
      >Please enter {{aOrAn}} {{name}} less than or equal to {{ value.$params.maxValue.max }}</small>

      <!-- minValue -->
      <small
        class="form-text small"
        v-if="value.minValue != undefined && !value.minValue"
      >Please enter {{aOrAn}} {{name}} greater than or equal to {{ value.$params.minValue.min }}</small>

      <!-- required -->
      <small
        class="form-text small"
        v-if="value.required != undefined && !value.required"
      >Please enter {{aOrAn}} {{name}}</small>

      <!-- select (Works on required with a slightly different error text) -->
      <small
        class="form-text small"
        v-if="value.select != undefined && !value.select"
      >Please select {{aOrAn}} {{name}}</small>

      <!-- Numeric -->
      <small
        class="form-text small"
        v-if="value.numeric != undefined && !value.numeric"
      >{{name}} can only be numeric</small>

      <!-- Numeric -->
      <small
        class="form-text small"
        v-if="value.integer != undefined && !value.integer"
      >{{name}} can only be a whole number</small>

      <!-- Website -->
      <small
        class="form-text small"
        v-if="value.url != undefined && !value.url"
      >Please enter {{aOrAn}} valid web address that starts with http or https</small>

      <!-- Email -->
      <small
        class="form-text small"
        v-if="value.email != undefined && !value.email"
      >Please enter {{aOrAn}} valid email address</small>

      <!-- IP Address -->
      <small
        class="form-text small"
        v-if="value.ipAddress != undefined && !value.ipAddress"
      >Please enter {{aOrAn}} valid IP address</small>

      <!-- int32 -->
      <small
        class="form-text small"
        v-if="value.int32 != undefined && !value.int32"
      >Please enter {{aOrAn}} number smaller than 2147483648</small>

      <!-- Email -->
      <small
        class="form-text small"
        v-if="value.alphaNum != undefined && !value.alphaNum"
      >{{name}} may only contain letters or numbers, special characters and spaces are not allowed</small>

      <!-- bacsField -->
      <small
        class="form-text small"
        v-if="value.bacsField != undefined && !value.bacsField"
      >{{name}} may only contain characters that are allowed by BACS</small>
    </slot>
    <slot></slot>
  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      default: 'value'
    },
    an: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    aOrAn () {
      if (this.an) {
        return 'an'
      }
      return 'a'
    }
  }
}
</script>
