<template>
  <div style="margin: 0 30px;" class="maintenance-messages" v-if="maintenanceMessages.length > 0">
    <div class="bd-callout bg-white" :class="maintenanceCurrentClass">
      <b-carousel
        id="maintenance-messages"
        :interval="10000"
        indicators
        @sliding-start="pageChangeEvent"
      >
        <b-carousel-slide
          v-for="maintenanceMessage in maintenanceMessages"
          :key="maintenanceMessage.id"
        >
          <h4>London & Zurich will be unavailable from {{maintenanceMessage.start | formatDate('DD/MM/YYYY HH:mm')}} until {{maintenanceMessage.end | formatDate('DD/MM/YYYY HH:mm')}}</h4>
          <p
            style="overflow-y:auto; min-height:170px;max-height:170px;"
            v-html="maintenanceMessage.description"
          ></p>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'MaintenanceMessaagesSlide',
  components: {
  },
  beforeDestroy () {
    window.clearInterval(window.maintenanceMessagesInterval)
  },
  async mounted () {
    this.loadMaintenanceMessages()
    if (window.maintenanceMessagesInterval) {
      window.clearInterval(window.maintenanceMessagesInterval)
    }
    window.maintenanceMessagesInterval = setInterval(this.loadMaintenanceMessages, 600000)
  },

  data () {
    return {
      maintenanceMessages: [],
      maintenanceCurrentClass: 'bd-callout-primary',
      interval: null
    }
  },
  methods: {
    async loadMaintenanceMessages () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}MaintenanceWindow/List`, { showerror: true, errormessage: 'Failed to load maintenance windows' })
        this.maintenanceMessages = response.data
        if (this.maintenanceMessages.length > 0) {
          this.pageChangeEvent(0)
        }
      } catch { }
    },
    pageChangeEvent (index) {
      var type = this.maintenanceMessages[index].type
      var result = 'bd-callout-primary'
      switch (type) {
        case 'Planned Maintenance':
          result = 'bd-callout-danger'
          break
        case 'Upgrade':
          result = 'bd-callout-success'
          break
        case 'Info':
          result = 'bd-callout-info'
      }

      this.maintenanceCurrentClass = result
    }
  }
}
</script>
