<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        <span class="pull-right">
          <a :href="`${docUrl}/gettingstarted/`" target="_blank">
            <i class="far fa-question-circle"></i>
          </a>
        </span> Contact Support
        <favourite-icon></favourite-icon>
      </h2>
    </div>
    <div class="idb-block-content">
      <div class="pl-3">
        <strong>Client Support</strong>
      </div>
      <div class="pl-3">London &amp; Zurich</div>
      <div class="pl-3">Headley Technology Park</div>
      <div class="pl-3">Middle Lane</div>
      <div class="pl-3">Headley Heath</div>
      <div class="pl-3">Birmingham</div>
      <div class="pl-3">B38 0DS</div>
      <!-- <div class="pl-3 pt-3">Tel:
        <a
          href="tel:+441234567890"
      >+44 (0) 1234 567 890</a></div>-->
      <div class="pl-3 pt-1">
        Email:
        <a href="mailto:customer.services@landz.co.uk">customer.services@landz.co.uk</a>
      </div>
      <div class="pl-3 pt-5">
        <strong>Other useful links</strong>
      </div>
      <!-- <div class="pl-3 pt-3">
        Service Status:
        <a
          href="https://status.paygate.cloud/"
          target="_blank"
        >status.landzservice.com</a>
        <span class="badge badge-warning ml-2">To Do: JFTP-3429</span>
      </div> -->
      <!-- <div class="pl-3 pt-2">
        Online help guide:
        <a
          :href="`${docUrl}/gettingstarted/`"
          target="_blank"
        >help.landzservice.com</a>
      </div> -->
      <div class="pl-3 pt-2">
        Company website:
        <a href="https://www.londonandzurich.co.uk/" target="_blank">https://www.londonandzurich.co.uk/</a>
      </div>
      <!-- <div class="pl-3 pt-2" v-if="hasAgent">Agent Installer:
      <a :href="`${cdnUrl}/downloads/Paygate.Agent.Installer.msi`" target="_blank">Paygate.Agent.Installer.msi (v.3.1.0.0)</a></div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Licence from '@/Assets/Constants/licence'
export default {
  computed: {
    ...mapGetters(['selectedCustomer']),
    hasAgent () {
      return this.$store.getters.hasLicence(Licence.agent)
    }
  },
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      cdnUrl: process.env.VUE_APP_CDN_LOCATION_ROOT
    }
  }
}
</script>

<style scoped>
address {
  white-space: pre-line;
}
</style>
