<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            Workflow Manager
            <favourite-icon></favourite-icon>
          </h2>
        </div>
        <div class="idb-block-content">
          <!-- <div class="row">
            <div class="col-md-3">
              <div class="card mb-4">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <i class="fas fa-tachometer-alt fa-2x text-warning" style="line-height: 30px;"></i>
                    <div class="ml-3">
                      <div class="text-muted small">Workflow Load</div>
                      <div class="text-large">70%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card mb-4">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <i class="far fa-hourglass fa-2x text-info" style="line-height: 30px;"></i>
                    <div class="ml-3">
                      <div class="text-muted small">Triggers / minute</div>
                      <div class="text-large">21</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card mb-4">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <i class="far fa-calendar-check fa-2x text-success" style="line-height: 30px;"></i>
                    <div class="ml-3">
                      <div class="text-muted small">Daily Successful</div>
                      <div class="text-large">32</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card mb-4">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <i class="far fa-calendar-times fa-2x text-danger" style="line-height: 30px;"></i>
                    <div class="ml-3">
                      <div class="text-muted small">Daily failed</div>
                      <div class="text-large">2</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
          <div class="row no-gutters row-bordered">
            <div class="col-md-6 col-lg-6 col-sm-12">
              <b-card-body>
                <load-chart :height="375" frequency="hour" title="Concurrent Workflows / Hour" />
              </b-card-body>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
              <b-card-body>
                <load-chart :height="375" frequency="day" title="Concurrent Workflows / Day" />
              </b-card-body>
            </div>
          </div>
          <div class="row no-gutters row-bordered">
            <div class="col-md-12 col-lg-12 col-xl-12">
              <b-card-body>
                <load-chart :height="375" frequency="minute" title="Concurrent Workflows / Minute" />
              </b-card-body>
            </div>
          </div>
          <br />
        </div>
      </div>
      <div class="idb-block mb-5" v-if="displaySystemItems===true">
        <div class="idb-block-title">
          <h2>Global Workflow Control</h2>
        </div>
        <div class="idb-block-content">
          <div class="row mb-5">
            <div class="col-md-4">Enabled Workflow Processor</div>
            <div class="col-md-2">
              <p-check
                name="check"
                class="p-switch"
                color="primary "
                v-model="enabledWorkflowProcessor"
              ></p-check>
            </div>
            <div
              class="col-md-6"
            >When disabled, workflows that are currently running will be allowed to complete but no new workflows will start.</div>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-md-12 col-sm-3">
              <b-button
                @click.prevent="btnUpdate"
                variant="primary"
                title="Update"
                v-b-popover.hover.top.d500="'Update the current workflow manager configuration'"
              >
                <i class="fa fa-save mr-2"></i>Save
              </b-button>
              <!-- <b-button @click.prevent="deleteDupes" v-if="!commsError" variant="danger" class="pull-right" title="Reset Duplication Tracking" v-b-popover.hover.top.d500="'Permanently delete all duplication entries.'"><i class="fa fa-undo mr-2"></i>Reset Duplication Tracking</b-button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from '@/Store/index'
import roles from '@/Assets/Constants/roles'
import axios from 'axios'
import Vue from 'vue'
import VueChartJs from 'vue-chartjs'

const options = {
  responsive: true,
  maintainAspectRatio: false
}

Vue.component('load-chart', {
  extends: VueChartJs.Line,
  props: {
    frequency: String,
    title: String
  },
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      labels: [],
      chartData: []
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.getChartData()
    }
  },
  methods: {
    async getChartData () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowload?frequency=${this.frequency}`)
        if (res && res.data) {
          this.labels = res.data.map(x => x._id)
          this.chartData = res.data.map(x => x.count)
        }
      } catch (e) {
        this.$snapbar.e('Could not get workflow load data.')
        console.log(e)
      }
      let backgroundColor = 'rgba(65, 102, 137, 0.15)'
      let borderColor = 'rgba(65, 102, 137, .5)'
      if (this.$store.getters.getClaim('theme').value === 'dark') {
        backgroundColor = 'rgba(65, 102, 137, 0.9)'
        borderColor = 'rgba(65, 102, 137, 1)'
      }
      this.renderChart({
        labels: this.labels,
        datasets: [
          {
            label: this.title,
            data: this.chartData,
            borderWidth: 1,
            steppedLine: 'middle',
            backgroundColor,
            borderColor
          }
        ]
      }, options)
    }
  },
  async mounted () {
    if (Store.getters.isInRoles([roles.SystemUser])) {
      await this.getChartData()
    }
  }
})

export default {
  name: 'my-component',
  data () {
    return {
      selectedLoadFrequency: 'Daily',
      columns: [
        {
          label: 'Id',
          field: '_id',
          hidden: true
        },
        {
          label: 'Title',
          field: 'metaData.title',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Description',
          field: 'metaData.description',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Type',
          field: 'metaData.type',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Tags',
          field: 'metaData.tags',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: '',
          field: 'buttons'
        }
      ],
      row_data: [],
      btnLatch: false,
      totalRows: 0,
      enabledWorkflowProcessor: true,
      displaySystemItems: false,
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  computed: {
    rows: function () {
      return this.row_data
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async getWorkflowLoadData () {
      // let res
      // try {
      //   res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowload`)
      //   if (res && res.data) {
      //     console.log(res.data)
      //     // this.enabledWorkflowProcessor = res.data.workflowsEnabled
      //   }
      // } catch (e) {
      //   this.$snapbar.e('Could not get workflow load data.')
      // }
    },
    async getWorkflowControlConfig () {
      if (!this.displayCustomerSelector) return
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowcontrol`)
        if (res && res.data) {
          console.log(res.data)
          this.enabledWorkflowProcessor = res.data.workflowsEnabled
        }
      } catch (e) {
        this.$snapbar.e('Could not get workflow subsystem configuration.')
      }
    },
    async btnUpdate () {
      const workflowControl = {
        workflowsEnabled: this.enabledWorkflowProcessor
      }
      let res
      try {
        res = await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowcontrol`, workflowControl)
        if (res) {
          console.log(res.data)
          this.$toastr.s('Workflow control settings have been updated', 'Workflow Control')
        }
      } catch (e) {
        this.$snapbar.e('Could not update workflow subsystem configuration.')
      }
    },
    async getWorkflows () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflows`)
        if (res) {
          this.row_data = res.data.workflows
          this.totalRows = this.row_data.length
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Workflows.')
        } else {
          this.$snapbar.e(`Could not get a list of Workflows - ${e.message}`)
        }
      }
    },
    onRowClick (params) {
      if (!this.btnLatch) this.$router.push(`workflowdetail/${params.row._id}`)
    },
    btnNewWorkflow () {
      this.$router.push('designer')
    },
    btnCancel () {
      this.$router.go(-1)
    },
    reformatType (type) {
      if (type === 'bureauCustomerImporter') return 'Bureau Customer Importer'
      if (type === 'bacs') return 'BACS'
      if (type === 'fps') return 'Faster Payments'
      if (type === 'ukBureau') return 'UK BACS Bureau'
      if (type === 'ukddmsPayers') return 'UK DDMS Payers'
      if (type === 'sepa') return 'SEPA'
    },
    reformatTags (t) {
      // Simple visual reformat from ["one","two"] to one, two
      const nt = JSON.stringify(t)
      let ntr = nt.replace(/"/g, '')
      // eslint-disable-next-line
      ntr = ntr.replace(/\,/g, ', ')
      ntr = ntr.replace(/\[/g, '')
      ntr = ntr.replace(/\]/g, '')
      return ntr
    },
    btnEditWorkflow (params) {
      this.btnLatch = true
      this.$router.push(`designer/${params.row._id}`)
    }
  },
  created: async function () {
    this.$snapbar.hide()
    this.displaySystemItems = Store.getters.isInRoles([roles.SystemUser])
    if (this.displaySystemItems) {
      await this.getWorkflows()
      await this.getWorkflowLoadData()
      await this.getWorkflowControlConfig()
    } else {
      this.$snapbar.e('You are not permitted to view this page.')
    }
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      await this.getWorkflows()
    }
  }
}
</script>

<style scoped>
td {
  cursor: pointer;
}
</style>
