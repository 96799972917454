<template>
    <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
                <h2><help-icon docPath="/paygate-collections/import-export/importpayer/" />Template rejected by bank</h2>
              </div>
              <div class="idb-block-content">
                <p><strong>Title:</strong><br/>{{templateName}}</p>
                <p><strong>Last Modified:</strong><br/>{{formatDateTime (lastModified)}}</p>
                <p><strong>Notes:</strong><br/>{{templateNotes}}</p>
              </div>
              <div class="idb-block-footer">
                <b-button variant="primary" @click.prevent="downloadTemplate" ><i class="fas fa-save mr-2"></i>Download Template</b-button>
                <b-button class="ml-2" variant="secondary" @click.prevent="editTemplate" ><i class="fas fa-edit mr-2"></i>Edit Template</b-button>
                <b-button class="ml-2" variant="danger" @click.prevent="removeAction" v-if="actionId && actionId !== null">Clear Action</b-button>
              </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import auth from '@/Assets/Components/Authentication/auth.js'
import moment from 'moment'
export default {
  props: {
    templateId: String,
    type: String
  },
  data () {
    return {
      actionId: null,
      templateNotes: '',
      templateName: '',
      lastModified: '',
      channelType: 0
    }
  },
  methods: {
    removeAction () {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}messages/clearaction/${this.actionId}`).then(
      (response) => {
        this.$toastr.s('Action cleared')
        this.actionId = null
        this.$router.push('/actions')
      }
      )
    },
    async loadTemplateData () {
      let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}templateapproval/${this.templateId}/details`)
      this.templateNotes = response.data.notes
      this.templateName = response.data.templateName
      this.channelType = response.data.channelType
      this.lastModified = response.data.lastModified
    },
    async downloadTemplate() {
      let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}templateapproval/${this.templateId}/download`)
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(this.base64ImageToBlob(response.data))
      a.download = `${this.templateName}.pdf`
      // Append anchor to body.
      document.body.appendChild(a)
      a.click();
      // Remove anchor from body
      document.body.removeChild(a)
    },
    base64ImageToBlob (str) {
      // extract content type and base64 payload from original string
      var imageContent = atob(str)

      // create an ArrayBuffer and a view (as unsigned 8-bit)
      var buffer = new ArrayBuffer(imageContent.length)
      var view = new Uint8Array(buffer)

      // fill the view, using the decoded base64
      for(var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }

      // convert ArrayBuffer to Blob
      var blob = new Blob([buffer], { type: 'application/pdf' });
      return blob;
    },
    formatDateTime (datestr) {
      let date = new Date(datestr)
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var h = date.getHours()
      var m = date.getMinutes()
      var s = date.getSeconds()
      return `${this.pad(day,2)}/${this.pad(monthIndex, 2)}/${year} ${this.pad(h,2)}:${this.pad(m,2)}:${this.pad(s,2)}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    editTemplate () {
      switch (this.channelType) {
        case 0:
          this.$router.push('/collections/message/designer/email/'+this.templateId)
          break
        case 1:
          this.$router.push('/collections/message/designer/letter/'+this.templateId)
          break
        case 2:
          this.$router.push('/collections/message/designer/sms/'+this.templateId)
          break
        default:
          this.$toastr.e('Channel Not Defined')
          break
      }
    }
  },
  computed: {},
  async mounted () {
    this.actionId = this.$route.query.actionId
    this.bearerToken = await auth.getAccessToken()
    try {
      this.loadTemplateData()
    } catch (e) {
      this.$toastr.e('Unable to load template details.', 'An error occurred')
    }
  },
  async created () {
    try {
      this.loadTemplateData()
    } catch (e) {
      this.$toastr.e('Unable to load template details.', 'An error occurred')
    }
  }
}
</script>
