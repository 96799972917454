<template>
  <div id="customerLedgerTransactions">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Ledger Transactions
              <span class="pull-right">
              </span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2">
                <strong>From Transaction Date</strong>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <strong>To Transaction Date</strong>
              </div>
              <div v-if="this.isFinanceUser" class="col-md-2">
                <strong>Clients</strong>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdFrom"
                  name="createdFrom"
                  id="createdFrom"
                  v-model="createdFrom"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  :monday-first="true"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdTo"
                  name="createdTo"
                  id="createdTo"
                  v-model="createdTo"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  :monday-first="true"
                ></vuejsDatepicker>
              </div>
              <div v-if="this.isFinanceUser" class="col-md-2">
                <b-select v-model.trim="selectedCustomerType" :options="customerTypes" @change="changeCustomerType" />
              </div>
            </div>
            <div v-if="invalidDateRange === true" class="row form-group">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <span class="mandatory">The "From Date" cannot be after the "To Date"</span>
              </div>
            </div>
            <div v-show="selectedCustomerType === 1" class="row form-group">
              <label class="label-control col-md-3">Current Balance for Selected Customer</label>
              <label class="label-control col-md-3">
                <strong>£{{ parseFloat(this.ledgerBalance.currentBalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</strong>
              </label>
            </div>
          </div>
          <div class="idb-block-footer">
            <div class="row form-group">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="load"
                  :disabled="disableSearch"
                >Search</button>
              </div>
              <div class="col-md-10">
                <button
                  type="button"
                  class="btn btn-outline-primary pull-right"
                  v-on:click="resetSelections()"
                >Reset Selections</button>
              </div>
            </div>
            <div v-show="searchComplete === true" class="row form-group">
              <div class="col-md-12">
                <b-tabs>
                  <b-tab title="View Ledger Transactions">
                    <vue-good-table
                      :paginationOptions="paginationOptions"
                      :sort-options="sortOptions"
                      :isLoading.sync="isTableLoading"
                      ref="table"
                      :columns="columns"
                      :rows="rows"
                      :lineNumbers="false"
                      mode="remote"
                      :totalRows="totalRecords"
                      @on-page-change="onPageChange"
                      @on-sort-change="onSortChange"
                      @on-column-filter="onColumnFilter"
                      @on-per-page-change="onPerPageChange"
                      @on-search="onSearch"
                      @on-row-click="onRowClickViewTab"
                      @on-cell-click="onCellClick"
                      @on-selected-rows-change="selectionChangedViewTab"
                      @on-select-all="onSelectAllViewTab"
                      styleClass="vgt-table striped bordered ledgerTxTable"
                      :select-options="{
                        enabled: true,
                        selectOnCheckboxOnly: true,
                        disableSelectInfo: true
                      }"
                    >
                    <template slot="table-row" slot-scope="props">
                      <span v-if = "props.column.field === 'reconciliationStatus'">
                        <span v-b-popover.hover.top="statusToolTip(props.row.unreconciledReason)">
                          <b-badge pill v-bind:variant="getStatusVariant(props.row.reconciliationStatus)">{{ props.row.reconciliationStatus }}</b-badge>
                        </span>
                      </span>
                      <span v-else-if="props.column.field === 'xref'">
                        <span v-bind:class="getXrefClass(props)">{{props.formattedRow[props.column.field]}}</span>
                      </span>
                      <span v-else-if = "props.column.field == 'buttons'">
                        <b-dropdown right variant="outline-primary" split text="Options" class="m-2">
                          <b-dropdown-item v-if="showEditTxOption(props)" @click.prevent="editTransaction(props)">Edit TX</b-dropdown-item>
                          <b-dropdown-item v-if="showViewBanklineFileOption(props)" @click.prevent="viewBanklineFile(props)">View Bankline File</b-dropdown-item>
                        </b-dropdown>
                      </span>
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>
                      <div slot="table-actions">
                        <table-actions
                          :data-url="dataUrl"
                          :export-data="tableServerData"
                          :print-enabled="false"
                          :table-amount="totalRecords"
                          :export-name="exportFilename"
                          :reload-table="load"
                          :table-columns="columns"
                          :clear-table-filters="clearTableFilters"
                          :create-enabled="true"
                          :create="createLedgerTransaction"
                          create-tooltip="Create a new ledger transaction"
                        >
                        </table-actions>
                      </div>
                    </vue-good-table>
                    <button v-show="searchComplete === true" id="btnLedgerTx" @click="createLedgerTransaction" type="button" class="btn btn-primary">
                      <i class="fa fa-plus rpad"></i>Create Ledger Transaction
                    </button>
                  </b-tab>
                  <b-tab title="Selected Ledger Transactions">
                    <vue-good-table
                      :paginationOptions="paginationOptions"
                      :sort-options="sortOptions"
                      ref="selectedTxs"
                      :columns="columns"
                      :rows="selectedTxsToShow"
                      :lineNumbers="false"
                      :totalRows="totalSelected"
                      @on-row-click="onRowClickSelectedTab"
                      @on-select-all="onSelectAllSelectedTab"
                      @on-selected-rows-change="selectedTxsChanged"
                      :select-options="{
                        enabled: true,
                        selectOnCheckboxOnly: true,
                        disableSelectInfo: true
                      }"
                      styleClass="vgt-table striped bordered ledgerTxTable"
                    >
                    <template slot="table-row" slot-scope="props">
                      <span v-if = "props.column.field === 'reconciliationStatus'">
                        <span v-b-popover.hover.top="statusToolTip(props.row.unreconciledReason)">
                          <b-badge pill v-bind:variant="getStatusVariant(props.row.reconciliationStatus)">{{ props.row.reconciliationStatus }}</b-badge>
                        </span>
                      </span>
                      <span v-else-if = "props.column.field == 'buttons'">
                        <button class="btn btn-outline-primary" @click.prevent="editTransaction(props)">Edit TX</button>
                      </span>
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>
                    </vue-good-table>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <editLedgerTransaction
      :isVisible="isModalVisible"
      :isNewTx="isNewTx"
      :paygateId="this.selectedCustomer"
      :bankAccountId="this.bankAccountId"
      :sunId="this.sunId"
      :disabledDates="disabledDates"
      :ledgerTx="selectedLedgerTx"
      :bankAccounts="bankAccounts"
      :defaultNominalCodes="defaultNominalCodes"
      :generalClients="generalClients"
      @close="editCancelled"
      @savedLedgerTransaction="savedLedgerTransaction">
    </editLedgerTransaction>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'
import bacsMixin from '@/Lib/BacsMixin.js'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import EditLedgerTransaction from '@/Views/JFTP/Reconcilliation/Ledger/EditLedgerTransaction'
import ledgerTransactionTypes from '@/Assets/Constants/ledgerTransactionTypes'
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [
    bacsMixin,
    tableFilterMixin
  ],
  components: {
    editLedgerTransaction: EditLedgerTransaction
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    isFinanceUser () { return this.$store.getters.isInRole(roles.Finance) },
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}ledger/customerLedgerTransactions`
    },
    tableServerData () {
      return this.buildGoodTableQuery()
    },
    exportFilename () {
      return 'LedgerTransactions ' + this.defaultFromDate + ' to ' + this.defaultToDate
    },
    totalSelected () {
      this.selectedLedgerTxs.length
    },
    selectedTxsToShow () {
      return this.selectedLedgerTxs
    }
  },
  data () {
    return {
      searchComplete: false,
      createdFrom: '',
      createdTo: '',
      totalRecords: 0,
      disabledDates: {},
      invalidDateRange: false,
      defaultFromDate: '',
      defaultToDate: '',
      columns: [
        {
          label: 'TX Date',
          field: 'transactionDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy',
          filterOptions: {
            enabled: true
          },
          tdClass: 'ledgerTxTablePtr'
        },
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true
          },
          tdClass: 'ledgerTxTablePtr'
        },
        {
          label: 'Client ID',
          field: 'accountId',
          filterOptions: {
            enabled: true
          },
          tdClass: 'ledgerTxTablePtr'
        },
        {
          label: 'Bank Account ID',
          field: 'bankAccountId',
          hidden: true
        },
        {
          label: 'Bank Account',
          field: 'reference',
          tdClass: 'ledgerTxWordwrap',
          hidden: false,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Service User Name',
          field: 'bankStatementText',
          tdClass: 'ledgerTxWordwrap',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account',
          field: 'clientOrBankTx',
          filterOptions: {
            enabled: true,
            placeholder: 'All Accounts',
            filterDropdownItems: [
              { value: 'Bank', text: 'Bank' },
              { value: 'Client', text: 'Client' },
              { value: 'Connected Merchant', text: 'Connected Merchant' },
              { value: 'Master Client', text: 'Master Client' },
              { value: 'Payer', text: 'Payer' }
            ]
          },
          tdClass: 'ledgerTxTablePtr'
        },
        {
          label: 'Created By',
          field: 'userName',
          tdClass: 'ledgerTxWordwrap',
          hidden: true,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Nom Code',
          field: 'nominalCode',
          filterOptions: {
            enabled: true
          },
          tdClass: 'ledgerTxTablePtr'
        },
        {
          label: 'Ledger TX Type',
          field: 'ledgerTxType',
          filterOptions: {
            enabled: true,
            placeholder: 'All Types',
            filterDropdownItems: Object.values(ledgerTransactionTypes)
          },
          tdClass: 'ledgerTxTablePtr'
        },
        {
          label: 'XRef',
          field: 'xref',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'XRefID',
          field: 'xrefId',
          filterOptions: {
            enabled: true
          },
          tdClass: 'ledgerTxTablePtr'
        },
        {
          label: 'Debit (£)',
          field: 'debit',
          type: 'decimal',
          formatFn: this.formatAmount,
          filterOptions: {
            enabled: true
          },
          tdClass: 'ledgerTxTablePtr'
        },
        {
          label: 'Credit (£)',
          field: 'credit',
          type: 'decimal',
          formatFn: this.formatAmount,
          filterOptions: {
            enabled: true
          },tdClass: 'ledgerTxTablePtr'
        },
        {
          label: 'Rec Status',
          field: 'reconciliationStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'All Status',
            filterDropdownItems: [
              { value: '0', text: 'Unprocessed' },
              { value: '1', text: 'Unreconciled' },
              { value: '2', text: 'Reconciled' },
              { value: '3', text: 'Duplicate Match' },
              { value: '4', text: 'Processed' }
            ]
          },
          tdClass: 'ledgerTxTablePtr'
        },
        {
          field: 'buttons',
          sortable: false,
          filterOptions: { enabled: false },
          tdClass: 'text-center',
          thClass: 'text-center',
          hidden: false
        },
        {
          label: 'BankLine FileId',
          field: 'bankLineFileId',
          hidden: true
        },
        {
          label: 'BankLine TxId',
          field: 'bankLineTxId',
          hidden: true
        },
        {
          label: 'Unreconciled Reason',
          field: 'unreconciledReason',
          hidden: true
        }
      ],
      rows: [],
      isModalVisible: false,
      bankAccountId: '',
      sunId: '',
      ledgerBalance: { currentBalance: 0.00 },
      datesInit: false,
      customerTypes: [],
      selectedCustomerType: 0,
      isNewTx: false,
      selectedLedgerTx: {},
      bankAccounts: [],
      defaultNominalCodes: [],
      disableSearch: true,
      generalClients: [],
      allSelectedTxIds: [],
      currentPageSelectedTxIds: [],
      selectedLedgerTxs: [],
      bankAccountColumn: 4
    }
  },

  methods: {
    async load () {
      if (!this.checkDates() || !this.datesInit) {
        return
      }

      try {
        this.copyPageTxIds()
        this.isTableLoading = true
        const response = await axios.get(this.dataUrl,
          {
            params: { ...this.tableServerData },
            showerror: true,
            errormessage: 'Ledger Transactions search failed',
            showload: true
          })

        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
        await this.setSelectedRows()
        this.searchComplete = true
      } catch { 
        this.$snapbar.e('Ledger Transactions search failed')
      } finally {
        this.isTableLoading = false
      }
    },

    // Override mixin version of buildGoodTableQuery since adding extra date params.
    buildGoodTableQuery () {
      const query = { ...this.serverParams.columnFilters }

      Object.keys(query).forEach((key) => { 
        // Remove wildcard option from 'Account Type' filter so it's queried as "=" rather than "ilike".
        if (key !== 'clientOrBankTx' && key !== 'reference') {
          query[key] = `*${query[key]}*` 
        }
      })

      //Object.keys(query).forEach((key) => { query[key] = `*${query[key]}*` })
      query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }

      query.fromDate = moment(this.createdFrom).format('YYYY-MM-DD')
      query.toDate = moment(this.createdTo).format('YYYY-MM-DD')
      query.customerType = this.selectedCustomerType

      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
      return query
    },

    async resetSelections () {
      // await this.initSearchDates()
      // this.totalRecords = 0
      // this.rows = []
      // this.searchComplete = false
      // this.selectedCustomerType = 0
      for (let i = 0; i < this.selectedLedgerTxs.length; i++) {
        this.$set(this.selectedLedgerTxs[i], 'vgtSelected', true) 
      }

      await this.$nextTick()
      for (let i = 0; i < this.selectedLedgerTxs.length; i++) {
        this.$set(this.selectedLedgerTxs[i], 'vgtSelected', false) 
      }

      for (let i = 0; i < this.rows.length; i++) {
        if (this.allSelectedTxIds.includes(this.rows[i].id)) {
          this.$set(this.rows[i], 'vgtSelected', true) 
          await this.$nextTick()
          this.$set(this.rows[i], 'vgtSelected', false) 
        }
      }

      this.selectedLedgerTxs = []
      this.allSelectedTxIds = []
      this.currentPageSelectedTxIds = []
    },

    checkDates () {
      var datesOk = true
      if (this.createdFrom !== '' && this.createdTo !== '') {
        var fromDate = new Date(this.createdFrom)
        var toDate = new Date(this.createdTo)

        if (fromDate > toDate) {
          this.invalidDateRange = true
          datesOk = false
        } else {
          this.invalidDateRange = false
        }

        this.defaultFromDate = moment(this.createdFrom).format('DD-MM-YYYY')
        this.defaultToDate = moment(this.createdTo).format('DD-MM-YYYY')
      }

      return datesOk
    },

    async initSearchDates () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ledger/initDates?mode=bacsprocessingdate`)
      this.defaultFromDate = response.data.defaultFromDate
      this.defaultToDate = response.data.defaultToDate

      this.createdFrom = new Date(this.$moment(this.defaultFromDate, 'DD/MM/YYYY'))
      this.createdTo = new Date(this.$moment(this.defaultToDate, 'DD/MM/YYYY'))
      // NB. Don't want to disable any future dates since the TX date could be at least a couple of days in the future for BACS payments; depends on weekends bank holidays etc.
      this.datesInit = true
    },

    formatAmount (value) {
      return this.formatValue(value)
    },

    createLedgerTransaction () {
      this.isNewTx = true
      this.isModalVisible = true
    },

    editTransaction (params) {
      this.selectedLedgerTx = params.row
      this.isNewTx = false
      this.isModalVisible = true
    },

    editCancelled () {
      this.isModalVisible = false
    },

    async savedLedgerTransaction () {
      this.isModalVisible = false
      await this.load()
      if (this.isNewTx) {
        await this.loadLedgerBalance()
      }

      // Refresh selected TXs if applicable. "View Ledger TX" tab will be refreshed via the "load" method.
      // Can't just refresh via loaded rows since selected rows could include TXs not on current page of loaded TXs being shown on the "View Ledger TX" tab.
      // Perhaps make new call to API and get the updated selected TXs from the db. 
      if (this.allSelectedTxIds.length > 0) {
        await this.getRefreshedSelectedTxs()
      }
    },

    async getRefreshedSelectedTxs () {
      console.log('getRefreshedSelectedTxs')
      let response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_PLATFORM_API_URL + 'ledger/getRefreshedSelectedTxs',
          data: JSON.stringify(this.allSelectedTxIds),
          showload: true
        })
      this.selectedLedgerTxs = response.data
      // console.log('selrow0: ' + JSON.stringify(this.selectedLedgerTxs[0]))
      // VGT seems to have a bug which unchecks the checkbox of the TX after the one deleted. The data value is still "true" but the checkbox isn't being rendered as selected.
      for (let i = 0; i < this.selectedLedgerTxs.length; i++) {
        this.$set(this.selectedLedgerTxs[i], 'vgtSelected', false) 
      }

      await this.$nextTick()
      for (let i = 0; i < this.selectedLedgerTxs.length; i++) {
        this.$set(this.selectedLedgerTxs[i], 'vgtSelected', true) 
      }
    },

    async getEnums () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/search/getDropdownValues`)
      this.customerTypes = response.data.customerTypes
    },

    changeCustomerType () {
      this.searchComplete = false
    },

    async loadCustomer () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customers/${this.selectedCustomer}`, { showload: true, showerror: true, errormessage: 'Customer failed to load' })
      this.sunId = response.data.sunId
      // The customer's bankAccountId SHOULD be the same as the customer's SUN's bankAccountId. However get the SUN's bankAccountId just to be sure.
      response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}sun/WithBankAccount/${this.sunId}`, { showload: true, showerror: true, errormessage: 'Customer SUN failed to load' })
      this.bankAccountId = response.data.bankAccountId
    },

    async loadLedgerBalance () {
      try {
        var queryParams = `?bankAccountId=${this.bankAccountId}&paygateId=${this.selectedCustomer}`
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ledger/customerLedgerBalance${queryParams}`, { showerror: true, errormessage: 'Failed to get ledger balance' })
        this.ledgerBalance = response.data
        if (this.ledgerBalance === null || this.ledgerBalance === undefined) {
          this.ledgerBalance = { currentBalance: 0.00 }
        }
      } catch { }
    },

    async loadBankAccounts () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}bankAccounts/DropdownOfType?bankAccountType=0`, { showload: true })
      this.bankAccounts = response.data
      let bankAccountsDropdown = []
      this.bankAccounts.forEach(bankAccount => {
        bankAccountsDropdown.push({ value: bankAccount.bankAccountId, text: bankAccount.reference })
      });
      this.$set(this.columns[this.bankAccountColumn], 'filterOptions', { enabled: true, placeholder: 'All Banks', filterDropdownItems: bankAccountsDropdown })
    },

    async loadDefaultNominalCodes () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}nominalCode/allNominalCodes?nominalCodeType=0`, { showload: true })
      this.defaultNominalCodes = response.data
    },

    getStatusVariant (reconciliationStatus) {
      var variant = ''
      switch (reconciliationStatus) {
        case 'Unprocessed':
          variant = 'secondary'
          break
        case 'Processed':
          variant = 'info'
          break
        case 'Unreconciled':
          variant = 'danger'
          break
        case 'Reconciled':
          variant = 'success'
          break
        case 'Duplicate Match':
          variant = 'warning'
          break
      }
      return variant
    },

    getXrefClass (props) {
      let className = 'ledgerTxWordwrap' 
      switch (props.row.ledgerTxType) {
        case 'Collection':
        case 'BAC':
        case 'Settlement':
        case 'EBP':
        case 'D/D':
        case 'U/D':
        case 'Failed DD':
          className = 'ledgerTxTableXref'
          break;
      }
      return className
    },

    showEditTxOption (props) {
      return props.row.nominalCode === '9999' || props.row.paygateId === '00000000-0000-0000-0000-000000000000' || props.row.bankAccountId === '00000000-0000-0000-0000-000000000000' ||
        (props.row.reconciliationStatus !== 'Reconciled' && props.row.reconciliationStatus !== 'Processed')
    },

    showViewBanklineFileOption (props) {
      return props.row.bankLineFileId !== null && props.row.bankLineFileId !== undefined && props.row.clientOrBankTx !== 'Client'
    },

    onRowClickViewTab (params) {
      // console.log('onRowClickViewTab')
      let ledgerTxId = params.row.id
      // console.log('selected tx: ' + JSON.stringify(params.row))
      let isSelected = params.row.vgtSelected
      // console.log('is row selected (View): ' + isSelected)

      if (isSelected) {
        if (!this.allSelectedTxIds.includes(ledgerTxId)) {
          this.allSelectedTxIds.push(ledgerTxId)
          this.selectedLedgerTxs.push(params.row)
        }
        if (!this.currentPageSelectedTxIds.includes(ledgerTxId)) {
          this.currentPageSelectedTxIds.push(ledgerTxId)
        }
      } else {
        let idx = this.selectedLedgerTxs.map(i => i.id).indexOf(ledgerTxId)
        // console.log('idx1: ' + idx)
        if (idx > -1) {
          this.selectedLedgerTxs.splice(idx, 1)
        }

        //remove from id arrays
        idx = this.allSelectedTxIds.indexOf(ledgerTxId)
        // console.log('idx2: ' + idx)
        if (idx > -1) {
          this.allSelectedTxIds.splice(idx, 1)
        }

        idx = this.currentPageSelectedTxIds.indexOf(ledgerTxId)
        // console.log('idx3: ' + idx)
        if (idx > -1) {
          this.currentPageSelectedTxIds.splice(idx, 1)
        }
      }
    },

    async onCellClick (params) {
      if (params.column.field === 'xref') {
        let routeData = ''
        switch (params.row.ledgerTxType) {
          case 'Collection':
          case 'BAC':
            routeData = this.$router.resolve({ path: '/payments/search/submissionWrapper', query: { returnPage: '', submissionId: params.row.entityId, fileNumber: 0 } })
            break
          case 'Settlement':
          case 'EBP':
          case 'D/D':
            if (params.row.xref.startsWith('LZ')) {
              routeData = this.$router.resolve({ path: `/admin/settlements/report/${params.row.entityId}` })
            } else {
              routeData = this.$router.resolve({ path: '/payments/search/submissionWrapper', query: { returnPage: '', submissionId: params.row.entityId, fileNumber: 0 } })
            }
            break
          case 'U/D':
          case 'Failed DD':
            routeData = this.$router.resolve({ name: 'editPayer', params: { ukPayerId: params.row.entityId } })
            break
        }

        if (routeData !== '') {
          window.open(routeData.href, '_blank')
        }
      }
    },

    onSelectAllViewTab(params) {
      // console.log('onSelectAllView: ' + params.selected)
      // let selectedRows = params.selectedRows
      // let selectedRowIds = params.selectedRows.map(i => i.id)
      // console.log('rowIds2: ' + JSON.stringify(selectedRowIds))    
      if (params.selected) { 
        for (let i = 0; i < params.selectedRows.length; i++) {
          let ledgerTxId = params.selectedRows[i].id
          if (!this.allSelectedTxIds.includes(ledgerTxId)) {
            this.allSelectedTxIds.push(ledgerTxId)
            this.selectedLedgerTxs.push(params.selectedRows[i])
          }
          if (!this.currentPageSelectedTxIds.includes(ledgerTxId)) {
            this.currentPageSelectedTxIds.push(ledgerTxId)
          }
        } 
        // console.log('Select page ID count: ' + this.currentPageSelectedTxIds.length)
      } else {
        // console.log('Deselect page ID count: ' + this.currentPageSelectedTxIds.length)
        for (let i = 0; i < this.currentPageSelectedTxIds.length; i++) {
          let ledgerTxId = this.currentPageSelectedTxIds[i]
          let idx = this.selectedLedgerTxs.map(i => i.id).indexOf(ledgerTxId)
          if (idx > -1) {
            this.selectedLedgerTxs.splice(idx, 1)
          }

          //remove from id arrays
          idx = this.allSelectedTxIds.indexOf(ledgerTxId)
          if (idx > -1) {
            this.allSelectedTxIds.splice(idx, 1)
          }
        }
          
        this.currentPageSelectedTxIds = []
      }
    },

    async onRowClickSelectedTab(params) {
      // console.log('onRowClickSelectedTab')
      let ledgerTxId = params.row.id
      // console.log('selected tx (selected): ' + JSON.stringify(params.row))
      let isSelected = params.row.vgtSelected
      // console.log('is row selected (selected): ' + isSelected)
      if (!isSelected) {
        let idx = this.selectedLedgerTxs.map(i => i.id).indexOf(ledgerTxId)
        // console.log('Vidx1: ' + idx)
        if (idx > -1) {
          // VGT seems to have a bug which unchecks the checkbox of the TX after the one deleted. The data value is still "true" but the checkbox isn't being rendered as selected.
          // Workaround seems to be to set all rows to deselected, wait for nextTick, then reselect relevant rows.
          this.selectedLedgerTxs.splice(idx, 1)
          for (let i = 0; i < this.selectedLedgerTxs.length; i++) {
            this.$set(this.selectedLedgerTxs[i], 'vgtSelected', false) 
          }

          await this.$nextTick()
          for (let i = 0; i < this.selectedLedgerTxs.length; i++) {
            this.$set(this.selectedLedgerTxs[i], 'vgtSelected', true) 
          }
        }

        //remove from id arrays
        idx = this.allSelectedTxIds.indexOf(ledgerTxId)
        if (idx > -1) {
          this.allSelectedTxIds.splice(idx, 1)
        }

        idx = this.currentPageSelectedTxIds.indexOf(ledgerTxId)
        if (idx > -1) {
          this.currentPageSelectedTxIds.splice(idx, 1)
        }

        // await this.$nextTick()
        // Update checkboxes on "View Ledger Transactions" tab.
        await this.setSelectedRows()
      }
    },

    async onSelectAllSelectedTab(params) {
      if (!params.selected) {
        for (let i = 0; i < this.rows.length; i++) {
          this.$set(this.rows[i], 'vgtSelected', true) 
        }

        await this.$nextTick()
        for (let i = 0; i < this.rows.length; i++) {
          this.$set(this.rows[i], 'vgtSelected', false) 
        }

        for (let i = 0; i < this.selectedLedgerTxs.length; i++) {
          let ledgerTxId = this.selectedLedgerTxs[i].id

          let idx = this.allSelectedTxIds.indexOf(ledgerTxId)
          if (idx > -1) {
            this.allSelectedTxIds.splice(idx, 1)
          }

          idx = this.currentPageSelectedTxIds.indexOf(ledgerTxId)
          if (idx > -1) {
            this.currentPageSelectedTxIds.splice(idx, 1)
          }
        }

        this.selectedLedgerTxs = []
      }
    },

    viewBanklineFile (params) {
      if (params.row.bankLineFileId !== null && params.row.bankLineFileId !== undefined) {
        const routeData = this.$router.resolve({ path: `/admin/bandr/banklinetransactions/${params.row.bankLineFileId}`, query: { } })
        window.open(routeData.href, '_blank')
      }
    },

    statusToolTip (unreconciledReason) {
      return unreconciledReason
    },

    selectionChangedViewTab(selectedTxs) {
      // console.log('VIEW selectionChanged')
      // this.currentPageSelectedTxIds = selectedTxs.selectedRows.map(i => i.id)
      // selectedTxs.selectedRows.forEach(row => {
      //   console.log('CURRENT row ID: ' + row.id)
      // })
    },

    selectedTxsChanged(txs) {
      // console.log('SELECTED selectedTxsChanged')
      // VGT seems to have a bug which unchecks the checkbox of some remaining selected TXs. The data value is still "true" but the checkbox isn't being rendered as selected.
      // Workaround seems to be to set all rows to deselected, wait for nextTick, then reselect relevant rows.
      // for (var i = 0; i < this.selectedLedgerTxs.length; i++) {
      //   let isSelected = this.selectedLedgerTxs[i].vgtSelected
      //   this.$set(this.selectedLedgerTxs[i], 'vgtSelected', true) 
      // }
    },

    // Note any selected rows before navigating to another page. This will allow the rows to be reselected if user returns to the page.
    copyPageTxIds() {
      for (var i = 0; i < this.currentPageSelectedTxIds.length; i++) {
        var currentTxId = this.currentPageSelectedTxIds[i]
        console.log('Processing ID: ' + currentTxId)
        if (!this.allSelectedTxIds.includes(currentTxId)) {
          this.allSelectedTxIds.push(currentTxId)
        }
      }

      this.currentPageSelectedTxIds = []
    },

    // When returning to a page set any rows that were selected before navigating to another page.
    async setSelectedRows() {
      // console.log('setSelectedRows')
      for (let i = 0; i < this.rows.length; i++) {
        // console.log('rowId4: ' + this.rows[i].id)
        if (this.allSelectedTxIds.includes(this.rows[i].id)) {
          this.$set(this.rows[i], 'vgtSelected', false) 
          await this.$nextTick()
          this.$set(this.rows[i], 'vgtSelected', true) 
          this.currentPageSelectedTxIds.push(this.rows[i].id)
        } else {
          this.$set(this.rows[i], 'vgtSelected', true) 
          await this.$nextTick()
          this.$set(this.rows[i], 'vgtSelected', false) 
        }
      }
    }
  },
  watch: {
    async selectedCustomer () {
      this.resetSelections()
      await this.loadCustomer()
      await this.loadLedgerBalance()
      this.selectedCustomerType = 1
    }
  },

  async mounted () {
    document.getElementById('createdFrom').style.backgroundColor = 'white'
    document.getElementById('createdTo').style.backgroundColor = 'white'
    await this.getEnums()
    await this.initSearchDates()
    await this.loadCustomer()
    await this.loadLedgerBalance()
    await this.loadBankAccounts()
    await this.loadDefaultNominalCodes()
    this.$nextTick(() => {
      this.disabledDates.from = new Date()
      this.disableSearch = false
    })

    this.generalClients = this.$store.getters.customersOrdered.filter((customer) => {
      return customer.type === 'General'
    })
    // console.log('ledger TX customers: ' + JSON.stringify(this.generalClients))
  }
}
</script>
<style lang="scss">
.ledgerTxTablePtr {
  cursor: default !important;
}

.ledgerTxTableXref {
  overflow-wrap: break-word; 
  word-wrap: break-word; 
  word-break: break-all !important; 
  cursor: pointer !important;
  text-decoration: underline;
  color: blue;
}

.ledgerTxWordwrap {
  overflow-wrap: break-word; 
  word-wrap: break-word; 
  word-break: break-all !important; 
  cursor: default !important;
}

.ledgerTxTable {
  display: block;
  overflow-x: auto;
}
</style>
