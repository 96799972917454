<template>
  <div id="viewDownloadedReports">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              View Downloaded Reports
              <span class="pull-right">
              </span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row">
              <div class="col-md-2">
                <strong>Download From Date</strong>
              </div>
              <div class="col-md-2">
                <strong>Download To Date</strong>
              </div>
              <div class="col-md-2">
                <strong>Service User Number</strong>
              </div>
              <div class="col-md-2">
                <strong>Report Type</strong>
              </div>
              <div class="col-md-2">
                <strong>Report Processed</strong>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="downloadedFrom"
                  name="downloadedFrom"
                  id="downloadedFrom"
                  v-model="downloadedFrom"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  :monday-first="true"
                  :disabledDates="disabledDates"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="downloadedTo"
                  name="downloadedTo"
                  id="downloadedTo"
                  v-model="downloadedTo"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  :monday-first="true"
                  :disabledDates="disabledDates"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-2">
                <b-form-select v-model.trim="selectedSun" :options="suns" text-field="label" value-field="id"></b-form-select>
              </div>
              <div class="col-md-2">
                <b-select v-model.trim="selectedReportType" :options="reportTypeLookups" />
              </div>
              <div class="col-md-2">
                <b-select v-model.trim="selectedReportProcessed" :options="reportProcessedLookups" />
              </div>
            </div>
            <div v-if="invalidDateRange === true" class="row form-group">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <span class="mandatory">The "From Date" cannot be after the "To Date"</span>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <div class="row form-group">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="getReports"
                >Search</button>
              </div>
              <div class="col-md-8">
                <button
                  type="button"
                  class="btn btn-outline-primary pull-right"
                  v-on:click="clearSearchParams()"
                >Clear Search Criteria</button>
              </div>
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-outline-secondary pull-right"
                  v-on:click="runReportProcessor"
                  :disabled="startedReportDownload"
                >Download New Reports</button>
              </div>
            </div>
            <div v-show="searchComplete === true" class="row form-group">
              <div class="col-md-12">
                <vue-good-table
                  ref="reportList"
                  @on-row-click="onRowClick"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPerPageChange"
                  @on-cell-click="viewReportModal"
                  :columns="columns"
                  :rows="rows"
                  :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: true,
                    selectionInfoClass: 'report-custom-class',
                    clearSelectionText: ''
                  }"
                  :lineNumbers="false"
                  :pagination-options="{
                    enabled: true,
                    perPage: rowsPerPage,
                    dropdownAllowAll: false,
                    setCurrentPage: currentPage,
                    jumpFirstOrLast: true
                  }"
                  :sort-options="{
                    enabled: true
                  }"
                  styleClass="vgt-table striped bordered"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'viewReportXml'">
                      <span class="grid-link">XML</span>
                    </span>
                    <span v-if="props.column.field === 'viewReportHtml'">
                      <span class="grid-link">HTML</span>
                    </span>
                    <span v-else-if="props.column.field === 'messagesLinkText'">
                      <span v-bind:class="getLinkClass(props.row.messagesLinkText)">{{props.formattedRow[props.column.field]}}</span>
                    </span>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                  <div slot="table-actions">
                    <button
                      @click.prevent="clearTableFilters"
                      class="btn btn-link"
                      v-b-popover.hover.top.d500="'Clear filters'"
                    >
                      <span class="fa-stack" style="font-size: 10px;">
                        <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                        <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                      </span>
                    </button>
                  </div>
                  <div slot="selected-row-actions" style="width: 800px;">
                    <div class="row">
                      <div class="col-md-4"></div>
                      <div class="col-md-3">Download Format</div>
                      <div class="col-md-2">
                        <b-select
                          v-model.trim="selectedDownloadFormat"
                          :options="downloadFormats"
                        />
                      </div>
                      <div class="col-md-3">
                        <button
                          type="button"
                          class="btn btn-primary pull-right"
                          v-on:click="downloadReports"
                          :disabled="this.selectedDownloadFormat === ''"
                        >Download</button>
                      </div>
                    </div>
                  </div>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <viewReportModal
        v-model="isViewReportModalVisible"
        :reportData="reportContent"
        :reportFormat="selectedReportFormat"
        :reportTitle="modalTitle"
        @close="closeViewReport"
      ></viewReportModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ViewReportModal from '@/Views/Bacs/BacsReports/ViewReportModal.vue'
import bacsMixin from '@/Lib/BacsMixin.js'

export default {
  mixins: [
    bacsMixin
  ],

  components: {
    viewReportModal: ViewReportModal
  },

  data () {
    return {
      searchComplete: false,
      currentPage: 1,
      rowsPerPage: 10,
      downloadedFrom: '',
      downloadedTo: '',
      totalRecords: 0,
      disabledDates: {},
      invalidDateRange: false,
      defaultFromDate: '',
      defaultToDate: '',
      reportContent: '',
      modalTitle: '',
      isViewReportModalVisible: false,
      selectedReportFormat: 0,
      networkType: 'BacstelIp',
      authResponse: {},
      isLoggedIn: false,
      selectedDownloadFormat: '',
      downloadFormats: [],
      columns: [
        {
          label: 'Report Id',
          field: 'reportId',
          hidden: false,
          filterOptions: {
            enabled: true
            // filterFn: this.postFilterUpdate
          },
          tdClass: 'pagepointer'
        },
        {
          label: 'Retrieval URL',
          field: 'retrievalUrl',
          hidden: true
        },
        {
          label: 'Report Type',
          field: 'reportType',
          hidden: true
        },
        {
          label: 'Report Name',
          field: 'reportName',
          filterOptions: {
            enabled: true
            // filterFn: this.postFilterUpdate
          },
          tdClass: 'pagepointer'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber',
          filterOptions: {
            enabled: true
            // filterFn: this.postFilterUpdate
          },
          tdClass: 'pagepointer'
        },
        {
          label: 'Download Date',
          field: 'displayDownloadDate',
          filterOptions: {
            enabled: true
            // filterFn: this.postFilterUpdate
          },
          tdClass: 'pagepointer'
        },
        {
          label: 'Processed',
          field: 'reportProcessedText',
          filterOptions: {
            enabled: true
            // filterFn: this.postFilterUpdate
          },
          tdClass: 'pagepointer'
        },
        {
          label: 'JFTP Processed',
          field: 'displayJftpProcessingDate',
          filterOptions: {
            enabled: true
            // filterFn: this.postFilterUpdate
          },
          tdClass: 'pagepointer'
        },
        {
          label: 'Processed by BACS',
          field: 'displayBacsProcessedDate',
          hidden: true
        },
        {
          label: 'Report Generated',
          field: 'displayBacsReportGenerationDate',
          hidden: true
        },
        {
          label: 'View Report',
          field: 'viewReportXml',
          sortable: false
        },
        {
          label: 'View Report',
          field: 'viewReportHtml',
          sortable: false
        },
        {
          label: 'View Messages',
          field: 'messagesLinkText',
          sortable: false
        }
      ],
      rows: [],
      suns: [],
      reportTypeLookups: [],
      reportProcessedLookups: [],
      selectedSun: '',
      selectedReportType: '',
      selectedReportProcessed: '',
      startedReportDownload: false
    }
  },

  methods: {
    async getReports () {
      if (this.checkDates() === false) {
        return
      }

      var fromDate = this.downloadedFrom
      var toDate = this.downloadedTo

      if (fromDate === '') {
        fromDate = '01/01/0001'
      }

      if (toDate === '') {
        toDate = '01/01/0001'
      }

      var searchParams = JSON.stringify({
        fromDownloadDate: fromDate,
        toDownloadDate: toDate,
        sunId: this.selectedSun,
        reportProcessedFlag: this.selectedReportProcessed,
        reportType: this.selectedReportType
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/getDownloadedReports/',
        data: searchParams,
        showload: true
      })

      if (response.data !== null) {
        this.totalRecords = response.data.length
        this.rows = response.data
        this.searchComplete = true
      }
    },

    async clearSearchParams () {
      await this.initSearchDates()
      this.totalRecords = 0
      this.rows = []
      this.selectedSun = ''
      this.selectedReportType = ''
      this.selectedReportProcessed = ''
      this.searchComplete = false
    },

    clearTableFilters () {
      this.$refs.reportList.reset()
    },

    onRowClick (params) {
    },

    onPageChange (params) {
      this.currentPage = params.currentPage
    },

    async onPerPageChange (params) {
      this.rowsPerPage = params.currentPerPage
      await this.getReports()
    },

    checkDates () {
      var datesOk = true
      if (this.downloadedFrom !== '' && this.downloadedTo !== '') {
        var fromDate = new Date(this.downloadedFrom)
        var toDate = new Date(this.downloadedTo)

        if (fromDate > toDate) {
          this.invalidDateRange = true
          datesOk = false
        } else {
          this.invalidDateRange = false
        }
      }

      return datesOk
    },

    async getEnums () {
      // var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getDropdownValues?networkType=BacstelIp`, { showload: true })
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/initDownloadedValues`)
      this.defaultFromDate = response.data.defaultFromDate
      this.defaultToDate = response.data.defaultToDate
      this.downloadFormats = response.data.downloadFormatLookups
      this.suns = response.data.suns
      this.reportTypeLookups = response.data.reportTypeLookups
      this.reportProcessedLookups = response.data.reportProcessedLookups
      this.downloadFormats = response.data.downloadFormatLookups
    },

    initSearchDates () {
      var mm = this.defaultFromDate.substring(0, 2)
      var dd = this.defaultFromDate.substring(3, 5)
      var yyyy = this.defaultFromDate.substring(6, 10)
      this.downloadedFrom = dd + '/' + mm + '/' + yyyy

      mm = this.defaultToDate.substring(0, 2)
      dd = this.defaultToDate.substring(3, 5)
      yyyy = this.defaultToDate.substring(6, 10)
      this.downloadedTo = dd + '/' + mm + '/' + yyyy
    },

    async viewReportModal (params) {
      var cellClicked = params.column.field

      if (cellClicked === 'viewReportXml' || (cellClicked === 'messagesLinkText' && params.row.messagesLinkText.length > 0)) {
        if (cellClicked === 'viewReportXml') {
          this.selectedReportFormat = 0
        } else {
          this.selectedReportFormat = 1
        }

        var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getSelectedDownloadedReport?reportId=${params.row.reportId}`)
        this.reportContent = cellClicked === 'viewReportXml' ? response.data.reportXml : response.data.messages
        this.modalTitle = cellClicked === 'viewReportXml' ? 'Report Content' : 'Processing Messages'
        this.isViewReportModalVisible = true
      } else if (cellClicked === 'viewReportHtml') {
        var htmlResponse = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getInputReportHtml?reportId=${params.row.reportId}`, { showload: true })
        this.reportContent = htmlResponse.data
        if (this.reportContent !== '') {
          this.modalTitle = 'Report Content'
          this.isViewReportModalVisible = true
          this.selectedReportFormat = 1
        }
      }
    },

    closeViewReport () {
      this.isViewReportModalVisible = false
    },

    getLinkClass (messagesLinkText) {
      var linkClass = ''
      if (messagesLinkText !== undefined && messagesLinkText !== null && messagesLinkText.length > 0) {
        linkClass = 'grid-link'
      }
      return linkClass
    },

    async runReportProcessor () {
      var json = JSON.stringify({
        // Ideally this would be false for speed but problem is that some other system could download or access a report meaning that JFTP wouldn't get those report.
        downloadPrevAccReports: true
      })

      this.startedReportDownload = true
      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/runReportProcessor',
        data: json,
        showload: true
      })

      if (response.data.success) {
        this.$toastr.i('Reports are being downloaded which may take some time.')
      } else {
        this.$snapbar.w(response.data.errorMessage)
      }
      window.setTimeout(() => {
        this.startedReportDownload = false
      }, 5000)
    },

    async downloadReports () {
      // eslint-disable-next-line
      var selectedReports = this.$refs['reportList'].selectedRows
      var selectedReportIds = []
      for (var i = 0; i < selectedReports.length; i++) {
        selectedReportIds.push(selectedReports[i].reportId)
      }

      var json = JSON.stringify({
        reportIds: selectedReportIds,
        downloadFormat: this.selectedDownloadFormat
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/downloadReports',
        data: json,
        responseType: 'blob',
        showload: true
      })

      this.saveFile(response)
    }
  },

  created () {
    this.disabledDates.from = new Date()
  },

  async mounted () {
    document.getElementById('downloadedFrom').style.backgroundColor = 'white'
    document.getElementById('downloadedTo').style.backgroundColor = 'white'
    await this.getEnums()
    this.initSearchDates()
  }
}
</script>
<style lang="scss">
.report-custom-class {
  height: 60px;
  background-color: #f0f0f0;
  color: black;
}
.dark-mode {
  .report-custom-class {
    height: 60px;
    background-color: #333333;
    color: white;
  }
}
.grid-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.pagepointer {
  cursor: default !important;
}
</style>
