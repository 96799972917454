export default [
  {
    menu_title: 'Bureau Customer',
    menu_icon: 'ti-user',
    severityData: 'bureauTopSeverityLevel',

    child_routes: [
      {
        path: '/bureau/bureaucustomers/create',
        menu_title: 'Create'
      },
      {
        path: '/bureau/bureaucustomers/list',
        menu_title: 'Manage'
      },
      {
        path: '/bureau/bureaucustomers/import',
        menu_title: 'Import',
        counterData: 'bureauCustomerImportPill'
      }
    ]
  },
  {
    menu_title: 'Bureau Jobs Old',
    menu_icon: 'ti-panel',
    child_routes: [
      {
        path: '/bureau/bureaujobs/create',
        menu_title: 'Create Job'
      },
      {
        path: '/bureau/bureaujobs/list',
        menu_title: 'Manage Jobs'
      },
      {
        path: '/bureau/bureaujobs/start',
        menu_title: 'Start Submission'
      },
      {
        path: '/bureau/bureaujobs/pending',
        menu_title: 'Pending Submissions'
      }
    ]
  },
  {
    menu_title: 'Jobs Administration',
    menu_icon: 'ti-panel',
    child_routes: [
      {
        path: '/bureau/bureaujobs/create',
        menu_title: 'Create Bureau Job'
      },
      {
        path: '/bureau/bureaujobs/list',
        menu_title: 'Manage Bureau Job'
      }
    ]
  },
  {
    menu_title: 'BACS Submission',
    menu_icon: 'ti-panel',
    child_routes: [
      {
        path: 'bureau/bureauJob/automatedbacssubmission',
        menu_title: 'Automated Submission'
      },
      {
        path: 'bureau/bureauBuilder/ManualBacsSubmission',
        menu_title: 'Manual Submission'
      }

    ]
  },
  {
    menu_title: 'FPS Submission',
    menu_icon: 'ti-panel',
    child_routes: [
      {
        path: 'bureau/bureauJob/automatedfpssubmission',
        menu_title: 'Automated Submission'
      },
      {
        path: 'bureau/bureauBuilder/ManualFPSSubmission',
        menu_title: 'Manual Submission'
      }

    ]
  },
  {
    menu_icon: 'ti-panel',
    path: 'bureau/bureaujobs/submissionsinprogress',
    menu_title: 'Submissions In Progress',
    counterData: 'bureauSubmissionPill'
  },
  {
    menu_title: 'Reports',
    menu_icon: 'ti-receipt',
    child_routes: [
      {
        path: '/bureau/bureaureports/bacsbureaureports?networkType=BacstelIp',
        menu_title: 'Bureau BACS Reports'
      },
      {
        path: '/bureau/bureaureports/bacsbureaureports?networkType=SecureIp',
        menu_title: 'Bureau FPS Reports'
      },
      {
        path: '/bureau/bureaureports/bureaucustomerreport',
        menu_title: 'Bureau Customer Report'
      }
    ]
  }
]
