<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bank Ledger Balances<span class="pull-right"></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div class="row form-group">
            <div class="col-md-2">
              <strong>From Balance Date</strong>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-2">
              <strong>To Balance Date</strong>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2">
              <vuejsDatepicker
                ref="createdFrom"
                name="createdFrom"
                id="createdFrom"
                v-model="createdFrom"
                format="dd/MM/yyyy"
                :bootstrap-styling="true"
                v-on:input="checkDates"
                :monday-first="true"
              ></vuejsDatepicker>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-2">
              <vuejsDatepicker
                ref="createdTo"
                name="createdTo"
                id="createdTo"
                v-model="createdTo"
                format="dd/MM/yyyy"
                :bootstrap-styling="true"
                v-on:input="checkDates"
                :monday-first="true"
              ></vuejsDatepicker>
            </div>
            <div v-if="invalidDateRange === true" class="col-md-6">
              <span class="mandatory">The "From Date" cannot be after the "To Date"</span>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row form-group">
            <div class="col-md-2">
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="startSearch"
                :disabled="disableSearch"
              >Search</button>
            </div>
            <div class="col-md-10">
              <button
                type="button"
                class="btn btn-outline-primary pull-right"
                v-on:click="clearSearchParams()"
              >Clear Search Criteria</button>
            </div>
          </div>
          <div v-show="searchComplete === true" class="row form-group">
            <div class="col-md-12">
              <vue-good-table
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                :isLoading.sync="isTableLoading"
                :rows="rows"
                :columns="columns"
                :lineNumbers="false"
                mode="remote"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                styleClass="vgt-table striped bordered table-hover"
                ref="table"
                @on-row-click="onRowClick"
                @on-cell-click="onCellClick">
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'lastNightLedgerBalance'">
                    <span v-bind:class="getBalanceClass(props.row.lastNightLedgerBalance)">{{formatBalance(props.row.lastNightLedgerBalance)}}</span>
                  </span>
                  <span v-else-if="props.column.field === 'todayLedgerBalance'">
                    <span v-bind:class="getBalanceClass(props.row.todayLedgerBalance)">{{formatBalance(props.row.todayLedgerBalance)}}</span>
                  </span>
                  <span v-else-if="props.column.field === 'lastNightClearedBalance'">
                    <span v-bind:class="getBalanceClass(props.row.lastNightClearedBalance)">{{formatBalance(props.row.lastNightClearedBalance)}}</span>
                  </span>
                  <span v-else-if="props.column.field === 'todayClearedBalance'">
                    <span v-bind:class="getBalanceClass(props.row.todayClearedBalance)">{{formatBalance(props.row.todayClearedBalance)}}</span>
                  </span>
                  <span v-else-if="props.column.field === 'startDayLedgerBalance'">
                    <span v-bind:class="getBalanceClass(props.row.startDayLedgerBalance)">{{formatBalance(props.row.startDayLedgerBalance)}}</span>
                  </span>
                  <span v-else-if="props.column.field === 'startDayClearedBalance'">
                    <span v-bind:class="getBalanceClass(props.row.startDayClearedBalance)">{{formatBalance(props.row.startDayClearedBalance)}}</span>
                  </span>
                  <span v-else-if="props.column.field === 'totalClientBalance'">
                    <span v-bind:class="getBalanceClass(props.row.totalClientBalance)">{{formatBalance(props.row.totalClientBalance)}}</span>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
              </template>
                <div slot="table-actions">
                  <table-actions
                    :data-url="dataUrl"
                    :export-data="tableServerData"
                    :print-enabled="false"
                    :table-amount="totalRecords"
                    :export-name="exportFilename"
                    :reload-table="load"
                    :table-columns="columns"
                    :clear-table-filters="clearTableFilters"
                  >
                  </table-actions>
                </div>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
export default {
  name: 'Balances',
  mixins: [tableFilterMixin, bacsMixin],
  components: {
    Datepicker
  },
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      columns: [
        {
          label: 'Id',
          field: 'bankAccountId',
          hidden: true
        },
        {
          label: 'Reference',
          field: 'reference',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Sortcode',
          field: 'sortCode',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account Number',
          field: 'accountNumber',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Balance Date',
          field: 'transactionDate',
          filterOptions: {
            enabled: true
          },
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Last Night\'s Ledger (£)',
          field: 'lastNightLedgerBalance',
          type: 'number',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Today\'s Ledger (£)',
          field: 'todayLedgerBalance',
          type: 'number',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Last Night\'s Cleared (£)',
          field: 'lastNightClearedBalance',
          type: 'number',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Today\'s Cleared (£)',
          field: 'todayClearedBalance',
          type: 'number',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Start of Day Ledger (£)',
          field: 'startDayLedgerBalance',
          type: 'number',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Start of Day Cleared (£)',
          field: 'startDayClearedBalance',
          type: 'number',
          filterOptions: {
            enabled: false
          }
        }
      ],
      rows: [],
      totalRecords: 0,
      bankAccountColumn: 1,
      createdFrom: '',
      createdTo: '',
      disabledDates: {},
      invalidDateRange: false,
      defaultFromDate: '',
      defaultToDate: '',
      disableSearch: true,
      searchComplete: false,
      datesInit: false,
      canStartSearch: false
    }
  },
  computed: {
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}ledger/getAllBankLedgerBalances`
    },
    tableServerData () {
      return this.buildGoodTableQuery()
    },
    exportFilename () {
      return 'BankLedgerBalances ' + this.defaultFromDate + ' to ' + this.defaultToDate
    }    
  },
  methods: {
    async startSearch () {
      this.canStartSearch = true
      await this.load()
    },

    async load () {
      if (!this.checkDates() || !this.canStartSearch) {
        return
      }

      try {
        this.isTableLoading = true
        const response = await axios.get(this.dataUrl,
        {
          params: { ...this.tableServerData },
          showerror: true,
          errormessage: 'Bank Account Balances search failed'
        })

        // console.log(JSON.stringify(response.data))
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
        this.searchComplete = true
      } catch (e) {
        if (e.response && e.response.status === 403) {
        this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
        this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
        this.rows = []
        this.totalRecords = 0
      } finally {
        this.isTableLoading = false
      }
    },

    // Override mixin version of buildGoodTableQuery due to 'reference' filter.
    buildGoodTableQuery () {
      const query = { ...this.serverParams.columnFilters }
      Object.keys(query).forEach((key) => { 
        // Remove wildcard option from 'reference' so it's queried as "=" rather than "ilike".
        if (key !== 'reference') {
          query[key] = `*${query[key]}*` 
        }
      })
      query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }

      query.fromDate = moment(this.createdFrom).format('YYYY-MM-DD')
      query.toDate = moment(this.createdTo).format('YYYY-MM-DD')

      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
      return query
    },

    async clearSearchParams () {
      this.defaultFromDate = ''
      await this.initSearchDates()
      this.totalRecords = 0
      this.rows = []
      this.searchComplete = false
    },

    checkDates () {
      var datesOk = true
      if (this.createdFrom !== '' && this.createdTo !== '') {
        var fromDate = new Date(this.createdFrom)
        var toDate = new Date(this.createdTo)

        if (fromDate > toDate) {
          this.invalidDateRange = true
          datesOk = false
        } else {
          this.invalidDateRange = false
        }

        this.defaultFromDate = moment(this.createdFrom).format('DD-MM-YYYY')
        this.defaultToDate = moment(this.createdTo).format('DD-MM-YYYY')
      }

      return datesOk
    },

    async initSearchDates () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ledger/initDates?mode=currentmonth`)
      this.defaultFromDate = response.data.defaultFromDate
      this.defaultToDate = response.data.defaultToDate

      this.createdFrom = new Date(this.$moment(this.defaultFromDate, 'DD/MM/YYYY'))
      this.createdTo = new Date(this.$moment(this.defaultToDate, 'DD/MM/YYYY'))
      // NB. Don't want to disable any future dates since the TX date could be at least a couple of days in the future for BACS payments; depends on weekends bank holidays etc.
      this.datesInit = true
    },

    onRowClick (params) {
    },
    onCellClick (params) {
      this.$router.push({ name: 'BankAccountEdit', params: { id: params.row.bankAccountId } })
    },

    formatBalance (balance) {
      var formatedBalance = this.formatValue(balance)
      return formatedBalance
    },

    getBalanceClass (balance) {
      return balance >= 0 ? 'body' : 'text-danger'
    },
    
    async loadBankAccounts () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}bankAccounts/DropdownOfType?bankAccountType=0`, { showload: true })
      this.bankAccounts = response.data
      // console.log('banks: ' + JSON.stringify(this.bankAccounts))
      let bankAccountsDropdown = []
      this.bankAccounts.forEach(bankAccount => {
        bankAccountsDropdown.push({ value: bankAccount.bankAccountId, text: bankAccount.reference })
      });
      // console.log('banks: ' + JSON.stringify(bankAccountsDropdown))
      this.$set(this.columns[this.bankAccountColumn], 'filterOptions', { enabled: true, placeholder: 'All Banks', filterDropdownItems: bankAccountsDropdown })
    }    
  },
  async mounted () {
    document.getElementById('createdFrom').style.backgroundColor = 'white'
    document.getElementById('createdTo').style.backgroundColor = 'white'
    await this.initSearchDates()
    await this.loadBankAccounts()
    this.$nextTick(() => {
      this.disabledDates.from = new Date()
      this.disableSearch = false
    })
  }
}
</script>
<style scoped lang="scss">
  td {
    cursor: pointer;
  }

  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
    background-color: #ffffff !important
  }
  .dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
    background-color: transparent !important
  }
  .dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
    background-color: #424242 !important
  }
  .dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
    color: #fff !important
  }
  .right-inner-addon {
    position: relative;
  }
  .right-inner-addon  input {
    padding-left: 8px;
  }
  .right-inner-addon i {
    position: absolute;
    top: 11px;
    right: 15px;
  }

  .fadein-enter {
    opacity: 0;
  }
  .fadein-enter-active {
    transition: opacity 0.6s;
  }
  .fadein-leave-active {
    transition: opacity 0.6s;
    opacity: 0;
  }

  .positiveBalance {
    color: green !important;
  }

  .negativeBalance {
    color: red !important;
  }
</style>
