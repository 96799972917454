<template>
  <form @submit.prevent="checkValidation() && saveChildCustomers()">
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Child Clients for {{selectedCustomerObject.name}}</h2>
      </div>

      <div class="idb-block-content">
        <!-- Clients -->
        <div class="form-group row" v-if="isSystemAdmin">
          <label class="col-form-label col-md-3 required">Clients</label>
          <div class="col-md-6">
            <vue-select
              v-model="selectedCustomerToAdd"
              label="name"
              :options="customers"
              append-to-body
            ></vue-select>
          </div>
          <div class="col-md-1">
            <button
              class="btn btn-success"
              type="button"
              @click="addChild"
              :disabled="selectedCustomerToAdd == null"
            >Add As Child</button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <vue-good-table
              ref="customerContacts"
              :columns="childColumns"
              :rows="$v.selectedCustomers.$model"
              :totalRows="selectedCustomers.length "
              :pagination-options="{
                    enabled: true,
                    perPage: 10,
                    dropdownAllowAll: false
                  }"
              styleClass="vgt-table striped bordered"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'actions'">
                  <button
                    v-if="isSystemAdmin"
                    class="btn btn-danger"
                    type="button"
                    @click.prevent="removeChild(props.row,$event)"
                  >
                    <a class="fa fa-times"></a>
                  </button>
                </span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          :disabled=" isLoading"
          type="submit"
          v-if="isSystemAdmin"
        >Save</button>

        <button
          class="btn btn-outline-danger pull-right ml-3"
          type="button"
          @click="back"
          v-if="isSystemAdmin"
        >Cancel</button>
        <button class="btn btn-primary" type="button" @click="back" v-if="!isSystemAdmin">Back</button>
      </div>
    </div>
  </form>
</template>

<script>
// Third Party
import axios from 'axios'
import VueSelect from 'vue-select'
import { mapGetters } from 'vuex'

// Mixins
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

// Constants
import roles from '@/Assets/Constants/roles'
import customerTypes from '@/Assets/Constants/customerTypes'

export default {
  name: 'ManageCustomerChildren',
  mixins: [DataLeaveMixin, loading],
  components: {
    VueSelect
  },
  computed: {
    isSystemAdmin () {
      return this.$store.getters.isInRole(roles.SystemUser)
    },
    childColumns () {
      if (this.isSystemAdmin) {
        return [
          {
            label: 'Name',
            field: 'name'
          },
          {
            label: '',
            field: 'actions'
          }
        ]
      } else {
        return [
          {
            label: 'Name',
            field: 'name'
          }]
      }
    },
    ...mapGetters(['selectedCustomer', 'selectedCustomerObject'])
  },
  data () {
    return {
      selectedCustomers: [],
      selectedCustomerToAdd: null,
      customers: []
    }
  },
  methods: {
    async load () {
      const childResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ChildCustomer/${this.selectedCustomer}`,
        { showload: true, showerror: true, errormessage: 'Child Clients failed to load' })
      this.selectedCustomers = childResponse.data

      if (this.isSystemAdmin) {
        const availableResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ChildCustomer/available`,
          { showload: true, showerror: true, errormessage: 'Child Clients failed to load' })

        this.customers = availableResponse.data
      }

      this.$v.$reset()
    },
    async saveChildCustomers () {
      try {
        await axios.post(
          `${process.env.VUE_APP_PLATFORM_API_URL}ChildCustomer/${this.selectedCustomer}`,
          {
            childIds: this.$v.selectedCustomers.$model.map(c => c.paygateId)
          },
          { showload: true, showerror: true, errormessage: 'Child Clients failed to save' })
        this.$v.$reset()
        this.$toastr.s(
          'Child Clients Saved',
          'Saved'
        )
      } catch { } finally {
        this.$store.dispatch('loadCustomersList')
      }
    },
    addChild () {
      var index = this.customers.indexOf(this.selectedCustomerToAdd)
      this.customers.splice(index, 1)
      this.$v.selectedCustomers.$touch()
      this.$v.selectedCustomers.$model.push(this.selectedCustomerToAdd)

      this.selectedCustomerToAdd = null
    },
    removeChild (row, event) {
      event.stopPropagation()
      this.customers.push({ name: row.name, paygateId: row.paygateId })
      this.$v.selectedCustomers.$touch()
      this.$v.selectedCustomers.$model.splice(row.originalIndex, 1)
    }
  },
  async mounted () {
    if (this.selectedCustomerObject.type === customerTypes.general) {
      this.$router.replace({ name: 'Unauthorized' })
      return
    }

    await this.load()
  },
  watch: {
    async selectedCustomerObject () {
      if (this.selectedCustomerObject.type === customerTypes.general) {
        this.back()
      } else {
        this.load()
      }
    }
  },
  validations: {
    selectedCustomers: {}
  }
}
</script>

<style>
</style>
