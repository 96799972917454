<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2><help-icon docPath="/paygate-collections/messaging/messagetemplates/" />Letter Designer</h2>
    </div>
    <div class="idb-block-content">
      <b-row>
        <b-col cols="4" md="4">
          <b-form-group>
            <label for="letterTemplateNameControl">
              <strong>Template Name</strong>
            </label>
            <b-form-input
              class
              id="letterTemplateNameControl"
              v-model.trim="templateName"
              type="text"
              :disabled="loading"
              autocomplete="off"
              :state="templateNameStateValid && !alreadyExists"
              aria-describedby="letterTemplateNameHelp letterTemplateNameFeedback"
              placeholder="Letter Template Name"
              maxlength="50" @input="checkTemplateNameExists"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!templateNameStateValid" id="letterTemplateNameFeedback">
              The template name must only consist of the following character types:
              <ul>
                <li>Upper-case letters A-Z</li>
                <li>Lower-case letters a-z</li>
                <li>Numbers 0-9</li>
                <li>Spaces, underscores "_" or dashes "-"</li>
              </ul>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="alreadyExists" id="letterTemplateNameFeedback2">
              There is a Letter template by that name already in the system.
            </b-form-invalid-feedback>
            <b-form-text id="smsTemplateNameHelp">The name of the template, as it will be appear to users.</b-form-text>
          </b-form-group>
        </b-col>
        <b-col v-if="templateid !== null && deprecatedId !== '00000000-0000-0000-0000-000000000000' && deprecatedId !== null && bankApprovalStatus !== 0">
          <strong style="padding-top: 2.5em; display: block;">(last approved version {{formatDate(lastModified)}})</strong>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <p-check id="templateRequiresApprovalCheck"
                  class="p-switch p-fill"
                  color="primary"
                  v-model="requiresApproval"
                ></p-check>
            <label for="templateRequiresApprovalCheck">
              <strong>This template will be used for Advance Notice/Notification Of Change/Confirmation Letter</strong>
            </label>
            <b-form-text>If a previously approved template is updated, it must undergo the approval process again. The most recent approved version will be used for messaging.</b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <br />
      <div class="container" v-if="canEdit">
        <vue-dropzone
          id="drop1"
          ref="fileUploader"
          :options="dropzoneOptions"
          @vdropzone-success="afterSuccess"
          @vdropzone-file-added="fileAdded"
          @vdropzone-removed-file="fileRemoved"
          @vdropzone-sending="sendingEvent"
          :use-custom-slot="true"
          :accepted-file-types="dropzoneOptions.acceptedFiles"
          :max-file-size-in-m-b="200"
          :useCustomSlot="true">
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">{{dropzoneOptions.title}}</h3>
            <div class="subtitle">{{dropzoneOptions.subtitle}}</div>
          </div>
        </vue-dropzone>
      </div>
      <br />
      <transition name="fade">
        <div class="container" v-if="internalTemplateId">
          <b-row>
            <b-col style="width: 105% !important">
              <div style="padding-top: 110%; position: relative;">
                <iframe
                  id="previewFrame"
                  frameborder="0"
                  ref="previewFrame"
                  height="100%"
                  width="100%"
                  style="position: absolute; top: 0; left: 0;"
                ></iframe>
              </div>
            </b-col>
          </b-row>
        </div>
      </transition>
    </div>
    <div class="idb-block-footer">
      <b-btn variant="primary" @click.prevent="saveClick" :disabled="isLoading || !canEdit || ((!templateid || templateid === null) && uploadCount === 0) || templateName === '' || !templateNameStateValid || alreadyExists">Save</b-btn>&nbsp;
      <b-btn variant="outline-secondary" :disabled="!canEdit" @click.prevent="clearTemplate">New Template</b-btn>&nbsp;
      <b-btn variant="secondary" @click.prevent="downloadEmptyTemplate" class="pull-right"><i class="fas fa-file mr-2"></i>Download Empty Template</b-btn>
    </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import EventBus from '@/Lib/eventBus'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import { mapGetters } from 'vuex'
import roles from "@/Assets/Constants/roles";
const hasNonAlphaNumOrSpaces = (str) => {
  if (!str) {
    return null
  }
  const nonAlphaNumsOrSpace = /[^A-Za-z0-9\s\w -_]/gi
  return !nonAlphaNumsOrSpace.test(str)
}

export default {
  mixins: [loading],
  props: {
    templateid: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      currentFile: null,
      loading: !!this.templateid,
      editMode: !this.templateid,
      internalTemplateId: this.templateid,
      uploaded: false,
      templateName: '',
      previewIsLoaded: false,
      wysiwygHtml: '',
      copyFromGroup: '',
      dropzoneOptions: {
        url: this.templateid ? `${process.env.VUE_APP_DDMS_API_URL}designer/letter/${this.templateid}?paygateid=${this.paygateId}` : `${process.env.VUE_APP_DDMS_API_URL}designer/letter?paygateid=${this.paygateId}`,
        withCredentials: true,
        autoProcessQueue: false,
        maxFilesize: 5,
        maxFiles: 1,
        acceptedFiles: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx',
        dictDefaultMessage: '<i class="fa fa-cloud-upload fa-4x"></i><br/><br/><h3 class="dropzone-custom-title">Drop a .docx file here.</h3><div class="subtitle">Or click to select a file to upload.</div>',
        title: 'Drop a .docx file here.',
        subtitle: 'Or click to select a file to upload. Press "Save" to upload the template.',
        previewTemplate: this.previewTemplate()
      },
      uploadCount: 0,
      requiresApproval: false,
      bankApproved: false,
      bankApprovalStatus: 0,
      alreadyExists: false,
      deprecatedId: null,
      lastModified: null
    }
  },
  methods: {
    async downloadEmptyTemplate (){
      try {
        let resp = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}messagetemplates/defaultdocx`, { params: { paygateId: this.paygateId } })
        if (resp) {
          let a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(this.base64ImageToBlob(resp.data))
          a.download = 'emptytemplate.docx'
          // Append anchor to body.
          document.body.appendChild(a)
          a.click();
          // Remove anchor from body
          document.body.removeChild(a)
        }
      } catch (err) {
        console.log(err)
        this.$toastr.e('Could not download empty template')
      }
    },
    base64ImageToBlob (str) {
      // extract content type and base64 payload from original string
      var imageContent = atob(str)

      // create an ArrayBuffer and a view (as unsigned 8-bit)
      var buffer = new ArrayBuffer(imageContent.length)
      var view = new Uint8Array(buffer)

      // fill the view, using the decoded base64
      for(var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }

      // convert ArrayBuffer to Blob
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessing' });
      return blob;
    },
    formatDate (date) {
      try {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      } catch (e) {
        return ''
      }
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    clearTemplate () {
      this.$router.push({ name: 'LetterDesigner', params: { templateid: null } }).catch(()=>{
        this.templateName = ''
        this.requiresApproval = false
        this.clearCurrentFile()
      })
    },
    fileAdded (file) {
      this.$refs['fileUploader'].setOption('headers', { 'Authorization': 'Bearer ' + this.bearerToken })
      this.$refs['fileUploader'].setOption('url', this.dropzoneOptions.url)
      this.uploadCount++
      this.$toastr.s('File ready to upload, press "Save" to upload the template.')
    },
    fileRemoved (file, error, xhr) {
      this.uploadCount--
    },
    sendingEvent (file, xhr, formData) {
      formData.append('paygateId', this.paygateId)
      formData.append('groupId', this.groupId)
      formData.append('name', this.templateName)
      formData.append('requireBankApproval', this.requiresApproval)
      if (this.templateid) {
        formData.append('templateId', this.templateid)
      }
    },
    async saveClick () {
      if (this.uploadCount > 0) {
        this.$refs.fileUploader.processQueue()
      } else {
        if (this.templateid) {
          await this.trySaveChanges(this.templateid, this.paygateId)
        } else {
          await this.tryCreate(this.paygateId)
        }
      }
    },
    async trySaveChanges (templateId, paygateId) {
      this.loading = true
      const file = this.currentFile
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', this.templateName)
      formData.append('templateId', templateId)
      formData.append('requireBankApproval', this.requiresApproval)
      formData.append('paygateId', this.paygateId)
      try {
        const headers = { headers: { 'Content-Type': 'multipart/form-data' }, showload: true, params: { paygateId: this.paygateId } }
        const response = await axios.post(this.dropzoneOptions.url, formData, headers)
        this.internalTemplateId = null
        this.internalTemplateId = response.data.templateId
        document.getElementById('previewFrame').src = this.previewUrl + '&tmstmp='+Date.now()
        // const iframe = this.$refs['previewFrame']
        // iframe.src = this.previewUrl + '&tmstmp='+Date.now()
        if (this.requiresApproval) {
          this.$toastr.s('Template updated successfully, and may be used once approved.', 'Template Updated')
        } else {
          this.$toastr.s('Template updated successfully.', 'Template Updated')
        }
        this.clearCurrentFile()
        this.uploaded = true
        // this.$router.push('/collections/message/templates')
      } catch (e) {
        this.$toastr.e('Failed to save template.', 'Error Occurred')
      } finally {
        this.loading = false
      }
    },
    async tryCreate (paygateId) {
      this.loading = true
      const file = this.currentFile
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', this.templateName)
      formData.append('templateId', this.templateid)
      formData.append('requireBankApproval', this.requiresApproval)
      formData.append('paygateId', this.paygateId)
      this.previewIsLoaded = false
      try {
        const headers = { headers: { 'Content-Type': 'multipart/form-data' }, showload: true, params: { paygateId: this.paygateId } }
        const response = await axios.post(this.dropzoneOptions.url, formData, headers)
        this.internalTemplateId = response.data.templateId
        if (this.requiresApproval) {
          this.$toastr.s('Template created successfully, and may be used once approved.', 'Template Created')
        } else {
          this.$toastr.s('Template created successfully.', 'Template Created')
        }
        this.$router.push({ name: 'SpecificLetterTemplate', params: { templateid: response.data.templateId } })
        // this.$router.push({DDMS', window.location + '/' + response.data.templateId)

        this.clearCurrentFile()
        this.uploaded = true
        this.$router.push('/collections/message/templates')
      } catch (e) {
        this.$toastr.e('Failed to save template.', 'Error Occurred')
      } finally {
        this.loading = false
      }
      this.previewIsLoaded = true
    },
    async afterSuccess (file, response) {
      if (this.requiresApproval) {
        if(this.templateid == null) {
          this.$toastr.s('Template created successfully, and may be used once approved.', 'Template Created')
        } else {
          this.$toastr.s('Template updated successfully, and may be used once approved.', 'Template Updated')
        }
      } else {
        if(this.templateid == null) {
          this.$toastr.s('Template created successfully.', 'Template Created')
        } else {
          this.$toastr.s('Template updated successfully.', 'Template Updated')
        }
      }
      if (this.templateid == null) {
        this.$router.push({ name: 'SpecificLetterTemplate', params: { templateid: response.templateId } })
        // this.$router.push({DDMS', window.location + '/' + response.data.templateId)
        this.internalTemplateId = response.templateId
      }
      //this.$toastr.s('Template updated successfully.', 'Template Updated')
      this.clearCurrentFile()
      this.uploaded = true
      // const { pdfFile } = response
      const viewer = process.env.VUE_APP_PDFVIEWER_URL
      const api = process.env.VUE_APP_DDMS_URL
      //this.preview.src = `${viewer}?file=${api}/${this.templateId}?token=${this.bearerToken}`
      document.getElementById('previewFrame').src = this.previewUrl + '&tmstmp='+Date.now()
      this.previewIsLoaded = true
    },
    loadGroups (paygateId) {
      const groups = this.$store.getters.customerGroups
      const paygateIdNotDefault = (paygateId && paygateId !== '00000000-0000-0000-0000-000000000000')
      const groupsNull = (groups === undefined || groups === null)
      if (paygateIdNotDefault && groupsNull) {
        this.$store.dispatch('loadCustomerGroups', paygateId)
      }
    },
    async viewPreview () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/letter/preview/LetterTemplateTest.docx`, { responseType: 'blob', showload: true, params: { paygateId: this.paygateId } })
      window.open('~/viewer.html?file=' + response.data)
    },
    async loadTemplateInfo (templateId) {
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/letter/${this.templateid}`, { params: { paygateId: this.paygateId } })
      this.requiresApproval = response.data.requireBankApproval
      this.bankApproved = response.data.bankApproved
      this.deprecatedId = response.data.deprecatedId
      this.lastModified = response.data.lastModified
      this.bankApprovalStatus = response.data.bankApprovalStatus
      console.log(response)
      this.templateName = response.data.name
      const iframe = this.$refs['previewFrame']
      iframe.src = this.previewUrl +'&tmstmp='+Date.now()
    },
    clearCurrentFile () {
      this.$refs.fileUploader.removeAllFiles()
      this.uploadCount = 0
    },
    previewTemplate () {
        return `<div class="dz-preview dz-file-preview">
                  <div class="dz-image">
                    <img data-dz-thumbnail="">
                  </div>
                  <div class="dz-details">
                    <div class="dz-size">
                      <span data-dz-size=""></span>
                    </div>
                    <div class="dz-filename">
                      <span data-dz-name="">
                      </span>
                    </div>
                  </div>
                  <div class="dz-progress">
                    <span class="dz-upload" data-dz-uploadprogress=""></span>
                  </div>
                  <div class="dz-error-message">
                    <span data-dz-errormessage=""></span>
                  </div>
                  <div class="dz-success-mark">
                  </div>
                  <div class="dz-error-mark"></div>
                  <a class="dropzone-remove-button-letters" data-dz-remove="">X</a>
                </div>`;
    },
    async checkTemplateNameExists () {
      let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/checkforduplicates`, { channel: 1, templateName: this.templateName }, { showload: true, params: { paygateId: this.paygateId } })
      this.alreadyExists = response.data
    },
    async initialLoad () {

      if (this.templateid) {
        this.loading = true
        try {
          await this.loadTemplateInfo(this.templateid)
          this.previewIsLoaded = true
          this.loading = false
        } catch (e) {
          this.$toastr.e('An error occurred loading the letter template information.', 'Error')
          // this.$router.push({ name: 'LetterDesigner' })
        }
      }
    }
  },
  async mounted () {
    if (this.$store.getters.isInRoles([roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard ]) && this.templateid !== null) {
      var pgResponse = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}helpers/gettemplatepgid/${this.templateid}`)
      this.$store.dispatch('setPaygateId', pgResponse.data)
    }
    this.$store.dispatch('setLastOpened', 'Letter')
    this.bearerToken = await auth.getAccessToken()
    await this.initialLoad()
    this.dropzoneOptions.url = this.templateid ? `${process.env.VUE_APP_DDMS_API_URL}designer/letter/${this.templateid}?paygateid=${this.paygateId}` : `${process.env.VUE_APP_DDMS_API_URL}designer/letter?paygateid=${this.paygateId}`
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    canEdit() {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin ])
    },
    truncatedFileName () {
      return this.currentFile.name
      // if (this.currentFile.name.length > 15) {
      //   return this.currentFile.name.substring(0, 15) + '…'
      // } else {
      //   return this.currentFile.name
      // }
    },
    previewUrl () {
      if (this.internalTemplateId) {
        const pdfUrl = encodeURI(`${process.env.VUE_APP_DDMS_API_URL}designer/letter/preview/${this.internalTemplateId}`)
        var previewUrl = `${process.env.VUE_APP_PDFVIEWER_URL}?file=${pdfUrl}&token=${this.bearerToken}`
        const paygateId = this.paygateId
        if (paygateId) {
          previewUrl += `&paygateId=${paygateId}`
        }
        return previewUrl+'&tmstmp='+Date.now()
      } else {
        return process.env.VUE_APP_PDFVIEWER_URL
      }
    },
    templateNameStateValid () {
      const str = this.templateName.replace(' (last approved version)', '')
      if (!str) {
        return null
      }
      const hasNonTemplateChar = /^[\w-_\s]*$/g
      let test = hasNonTemplateChar.test(str)
      return test
    },
    group: {
      get () {
        return this.$store.state.payer.currentPayer.groupId
      },
      set (value) {
        this.$store.commit('updateGroup', value)
      }
    },
    customerGroups () {
      return this.$store.getters.customerGroups || []
    },
    fileReady () {
      return this.currentFile !== undefined && this.currentFile !== null && this.currentFile !== ''
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    }
  },
  watch: {
    selectedCustomer () {
      this.dropzoneOptions.url = this.templateid ? `${process.env.VUE_APP_DDMS_API_URL}designer/letter/${this.templateid}?paygateid=${this.paygateId}` : `${process.env.VUE_APP_DDMS_API_URL}designer/letter?paygateid=${this.paygateId}`
      this.initialLoad()
    }
  }
}
</script>

<style>
.dropzone-remove-button-letters {
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  color: #ffffff !important;
}
  .dropzone-remove-button-letters:hover {
    cursor: pointer !important;
  }
</style>
