var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"idb-block"},[_c('div',{staticClass:"idb-block-title"},[_c('h2',[_vm._v(" Client Certificates "),_c('help-icon',{attrs:{"docPath":"/administration/customer/certificates/"}}),_c('favourite-icon')],1)]),_c('div',{staticClass:"idb-block-content"},[(_vm.areErrors)?_c('div',{staticClass:"alert alert-warning",attrs:{"id":"errors"}},[_c('p',[_vm._v("The certificate cannot be deleted due to these errors")]),_c('ul',_vm._l((_vm.errors),function(error){return _c('li',{key:error},[_vm._v(_vm._s(error))])}),0)]):_vm._e(),_c('vue-good-table',{attrs:{"paginationOptions":{
      enabled: true,
      perPage: 10,
                  jumpFirstOrLast:true

    },"rows":_vm.rows,"columns":_vm.columns,"searchOptions":{
      enabled: true
    },"lineNumbers":true,"styleClass":"vgt-table striped bordered"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'isValid')?_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.top.hover",value:('Is the date inbetween the valid from and valid to dates?'),expression:"'Is the date inbetween the valid from and valid to dates?'",modifiers:{"top":true,"hover":true}}]},[_c('tick',{model:{value:(props.row.valid),callback:function ($$v) {_vm.$set(props.row, "valid", $$v)},expression:"props.row.valid"}})],1):(props.column.field == 'delete')?_c('span',[_c('button',{staticClass:"btn btn-danger",attrs:{"disabled":_vm.isLoading || _vm.disabledDueToConnectedMerchant},on:{"click":function($event){return _vm.deleteCertificate(props.row)}}},[_vm._v("Delete")])]):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}])})],1),_c('div',{staticClass:"idb-block-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.isLoading || _vm.disabledDueToConnectedMerchant},on:{"click":_vm.createCertificate}},[_vm._v("Add Certificate")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }