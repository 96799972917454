<template>
  <div>
    <transition name="fade">
      <div v-if="addressesSet || !!this.address1">
        <b-form-row>
          <b-form-group
            :label-cols="5"
            class="col-sm-5"
            label-class="required"
            horizontal
            label="Address"
          >
            <b-form-input
              id="address1Input"
              type="text"
              class="form-control"
              required
              v-model="address1"
              :disabled="!editable"
            />
            <b-col v-if="$v.address1.$dirty">
              <label class="text-danger small" v-if="$v.address1.$invalid">Address required</label>
            </b-col>
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group :label-cols="5" class="col-sm-5" horizontal label="Address line 2">
            <b-form-input
              type="text"
              class="form-control"
              v-model="address2"
              :disabled="!editable"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group :label-cols="5" class="col-sm-5" horizontal label="Address line 3">
            <b-form-input
              type="text"
              class="form-control"
              v-model="address3"
              :disabled="!editable"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group :label-cols="5" class="col-sm-5" horizontal label="Address line 4">
            <b-form-input
              type="text"
              class="form-control"
              v-model="address4"
              :disabled="!editable"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group :label-cols="5" class="col-sm-5" horizontal label="Town">
            <b-form-input id="townInput" type="text" class="form-control" v-model="town" :disabled="!editable" />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group :label-cols="5" class="col-sm-5" horizontal label="County">
            <b-form-input id="countyInput" type="text" class="form-control" v-model="county" :disabled="!editable" />
          </b-form-group>
        </b-form-row>
      </div>
    </transition>
    <b-form-row>
      <b-form-group
        :label-cols="5"
        class="col-sm-5"
        label-class="required"
        horizontal
        label="Postcode"
      >
        <b-form-input
          id="postcodeInput"
          type="text"
          required
          class="form-control"
          @input="addressOptions = []"
          v-model="postcode"
          :disabled="!editable"
        />
        <b-col v-if="$v.postcode.$dirty && !$v.postcode.required">
          <label class="text-danger small">Postcode required</label>
        </b-col>
        <b-col v-if="!$v.postcode.postcodeVal"><label class="text-danger small">Valid postcode required</label></b-col>
      </b-form-group>&nbsp; &nbsp;
      <div class="form-row">
        <b-btn
          id="addressLookupButton"
          class="form-control"
          variant="info"
          :disabled="$v.postcode.$model === '' || $v.postcode.$model === null || $v.postcode.$model === undefined || !editable || isLoading || !$v.postcode.postcodeVal"
          @click="getAddress"
        >Lookup Address</b-btn>
      </div>
    </b-form-row>
    <transition name="fade">
      <b-form-row
        class="mt-2"
        v-if="addressOptionsSelect.length > 0 && !addressLookupInProgress && !addressesSet"
      >
        <b-form-group label-cols="5" class="col-sm-5" horizontal label="Addresses">
          <b-form-select
            id="addressSelect"
            @change="addressesSet = true"
            v-model="selectedAddress"
            :options="addressOptionsSelect"
            :disabled="!editable"
          ></b-form-select>
        </b-form-group>
      </b-form-row>
    </transition>
  </div>
</template>
<script>
import { required, helpers } from 'vuelidate/lib/validators'
import axios from 'axios'
import swal from 'sweetalert2'
import loading from '@/Assets/Mixins/LoadingMixin'

const postcodeVal = helpers.regex('postcodeVal', /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/i )

export default {
  mixins: [loading],
  props: {
    open: Boolean,
    clickedNext: Boolean,
    currentStep: Object
  },
  data () {
    return {
      existingEmailUsers: false,
      addressLookupInProgress: false,
      addressOptions: [],
      addressesSet: false
    }
  },
  methods: {
    dirtyTouch (callingModel) {
      callingModel.$touch()
    },
    async getAddress () {
      this.addressesSet = false
      if (this.$v.postcode.$invalid) {
        console.log('Postcode Invalid, aborting address lookup.')
        return
      }
      this.addressLookupInProgress = true
      var data = { postcode: this.postcode }
      try {
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}addresslookup`, data, { showload: true, params: { paygateId: this.paygateId } })
        console.log(response.data)
        if (response.data.addresses.length === 0) {
          try {
            this.$toastr.w('Postcode Not Found', 'Sorry, we could not find that address, please manually enter the address details')
          // var result = await swal.fire({
          //   title: 'Postcode not found',
          //   text: 'Sorry we could not locate an address using the information you provided, do you want to enter the address manually?',
          //   icon: 'question',
          //   showCancelButton: true,
          //   cancelButtonText: 'No',
          //   showConfirmButton: true,
          //   confirmButtonText: 'Yes'
          //   })
            this.addressesSet = true
          } catch (e) {
            console.error(e)
          }
        }
        else if (response.data.addresses.length === 1) {
          this.selectedAddress = response.data.addresses[0]
          this.lat = response.data.latitude
          this.lon = response.data.longitude
          this.addressesSet = false
        } else {
          this.addressOptions = response.data.addresses
        }
      } catch (e) {
        console.error(e)
        try {
          this.$toastr.w('Postcode Not Found', 'Sorry, we could not find that address, please manually enter the address details')
          this.addressesSet = true
        } catch (e) {
          console.error(e)
        }
      } finally {
        this.addressLookupInProgress = false
      }
    },
    validate () {
      this.$emit('can-continue', { value: !this.$v.$invalid })
    }
  },
  components: {},
  validations: {
    address1: { required },
    address2: {},
    address3: {},
    address4: {},
    town: {},
    county: {},
    postcode: { required, postcodeVal }
  },
  computed: {
    editable () {
      return this.$store.state.payer.currentPayer.editable
    },
    addressOptionsSelect () {
      if (this.addressOptions.length > 0) {
        const addresses = this.addressOptions.map(x => {
          let addressFields = [
            x.addressLine1,
            x.addressLine2,
            x.addressLine3,
            x.addressLine4,
            x.addressLocality,
            x.addressTown,
            x.addressCounty,
            x.addressPostCode
          ].filter(x => x)
          return { text: addressFields.join(', '), value: x }
        })
        return addresses
      }
      return []
    },
    selectedAddress: {
      get () {
        return this.$store.state.payer.currentPayer.payerDetails.address
      },
      set (value) {
        if (value) {
          const { addressLine1, addressLine2, addressLine3, addressLine4, addressPostCode, addressTown, addressLocality, addressCounty } = value
          this.$store.commit('setCurrentPayerAddress', { address1: addressLine1, address2: addressLine2, address3: addressLine3, address4: addressLine4 === '' ? addressLocality : addressLine4 + ', ' + addressLocality, postcode: addressPostCode, town: addressTown, county: addressCounty })
        }
      }
    },
    groupId () {
      return this.$store.state.payer.currentPayer.groupId
    },
    address1: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.address1 },
      set (value) { this.$store.commit('updateAddress1', value) }
    },
    address2: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.address2 },
      set (value) { this.$store.commit('updateAddress2', value) }
    },
    address3: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.address3 },
      set (value) { this.$store.commit('updateAddress3', value) }
    },
    address4: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.address4 },
      set (value) { this.$store.commit('updateAddress4', value) }
    },
    town: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.town },
      set (value) { this.$store.commit('updatetown', value) }
    },
    county: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.county },
      set (value) { this.$store.commit('updateCounty', value) }
    },
    postcode: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.postcode },
      set (value) { this.$store.commit('updatePostcode', value) }
    },
    lat: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.lat },
      set (value) { this.$store.commit('updateLat', value) }
    },
    lon: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.lon },
      set (value) { this.$store.commit('updateLon', value) }
    }
  },
  watch: {
    $v: {
      handler (val) {
        this.$store.commit('setAddressDone', !this.$v.$invalid)
        this._self.$emit('can-continue', { value: !this.$v.$invalid })
        this.$store.commit('setAddressCompleted', !this.$v.$invalid)
      },
      deep: true
    },
    clickedNext (val) {
      this.$emit('can-continue', { value: !this.$v.$invalid })
    },
    currentStep (val) {
      this.$emit('can-continue', { value: !this.$v.$invalid })
    }
  },
  async mounted () {
    this.$emit('can-continue', { value: !this.$v.$invalid })
  }
}
</script>
