<template>
  <div id="presubValResults">
    <div class="row form-group">
      <div class="col-md-12">
        <vue-good-table
          ref="messages"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
          @on-column-filter="onColumnFilter"
          @on-row-click="onRowClick"
          @on-cell-click="onCellClick"
          :columns="columns"
          :rows="rows"
          :lineNumbers="false"
          :totalRows="totalRecords"
          :paginationOptions="paginationOptions"
          :sort-options="{
            enabled: false
          }"
          styleClass="vgt-table striped bordered">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'messageSeverity'">
              <span v-bind:class="getSeverityClass(props.row.messageSeverity)" class="badge badge-pill pull-left">{{props.row.messageSeverity}}</span>
            </span>
            <span v-else-if="props.column.field === 'payerHyperlink'">
              <span v-if="props.row.recordNumber > 0" class="viewLink">View Customer</span>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
          <div slot="table-actions">
            <b-button
              @click.prevent="createPresubPdfReport"
              variant="link"
              title="Create Message Report"
              v-b-popover.hover.top.d500="'Shows a summary of the validation messages'"
            >
              <i class="fa fa-file-pdf pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="createPresubCsvReport"
              variant="link"
              title="Create Message CSV File"
              v-b-popover.hover.top.d500="'Export validation messages to Excel'"
            >
              <i class="fas fa-file-export pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'

export default {
  props: ['action'],
  mixins: [
    bacsMixin
  ],
  computed: {
    subParamsRoutePath () {
      return `${this.$route.path}_messages_${this.submissionId}`
    }
  },
  data () {
    return {
      currentPage: 1,
      rowsPerPage: 10,
      totalRecords: 0,
      rows: [],
      columns: [
        {
          label: 'Client',
          field: 'customerName'
        },
        {
          label: 'File No.',
          field: 'fileNumber',
          type: 'number',
          hidden: true
        },
        {
          label: 'Record No.',
          field: 'recordNumber',
          type: 'number'
        },
        {
          label: 'Severity',
          field: 'messageSeverity'
        },
        {
          label: 'Title',
          field: 'title'
        },
        {
          label: 'Description',
          field: 'description'
        },
        {
          label: 'Area',
          field: 'presubValArea',
          type: 'number'
        },
        {
          label: 'Customer Item ID',
          field: 'itemId',
          hidden: true
        },
        {
          label: 'Customer',
          field: 'payerHyperlink'
        }
      ],
      serverParams: {
        submissionId: '',
        showPresubValMessages: this.showPresubValMessages,
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '' // 'asc' or 'desc'
        },

        page: 1, // what page I want to show
        perPage: 10 // how many items I'm showing per page
      },
      defaultParams: {},
      paginationOptions: {
        enabled: true,
        perPage: 10,
        setCurrentPage: 1,
        dropdownAllowAll: false,
        jumpFirstOrLast: true
      }
    }
  },

  methods: {
    getSeverityClass (severity) {
      if (severity === 'Fix') {
        return 'badge-danger'
      } else if (severity === 'Duplicate') {
        return 'badge-warning'
      } else {
        return 'badge-' + severity.toLowerCase()
      }
    },

    getButtonClass () {
      if (this.presubValResponse.validationResult === 'Warnings') {
        return 'btn btn-light'
      } else {
        return 'btn btn-primary'
      }
    },

    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange (params) {
      this.rows.length = 0
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange (params) {
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type
        }
      })
      this.loadItems()
    },

    onColumnFilter (params) {
      this.updateParams(params)
      this.loadItems()
    },

    onRowClick (params) {
      // window.open(this.$router.resolve({ name: 'editPayer', params: { ukPayerId: params.row.itemId } }).href, '_blank')
    },

    async onCellClick (params) {
      // console.log('cell clicked: ' + params.column.field)
      // console.log('selected row: ' + JSON.stringify(params.row))

      if (params.column.field === 'payerHyperlink') {
        window.open(this.$router.resolve({ name: 'editPayer', params: { ukPayerId: params.row.itemId } }).href, '_blank')
      } else {
        if (params.row.presubValArea !== 'Amount') {
          // console.log('navigateToPayment aka changeTab')
          this.$emit('navToPayment', params.row)
        } else {
          this.$snapbar.w('Cannot navigate to this payment as it has been removed from the submission.')
        }
      }
    },

    async savedParamsToObjects (savedParams) {
      // Get the field names from the column filters
      const columnnFilterKeys = Object.keys(savedParams.columnFilters)
      for (var i = 0; i < columnnFilterKeys.length; i++) {
        const filterKey = columnnFilterKeys[i]
        // Find the column in the list
        const column = this.columns.find(c => c.field === filterKey)
        // Apply the filter
        column.filterOptions.filterValue = savedParams.columnFilters[filterKey]
      }
      // Set per page
      this.paginationOptions.perPage = savedParams.perPage
      if (this.$refs.messages) {
        this.$refs.messages.$refs.paginationBottom.perPage = savedParams.perPage
        this.$refs.messages.$refs.paginationBottom.handlePerPage()
      }
      // Set page
      this.paginationOptions.setCurrentPage = savedParams.page
    },

    async loadItems () {
      // console.log('messages loadItems')
      this.serverParams.submissionId = this.action.actionedObjectId

      var json = JSON.stringify({
        submissionId: this.action.actionedObjectId,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        showPresubValMessages: false,
        fileNumber: 0,
        sortColumn: this.serverParams.sort.field,
        sortDirection: this.serverParams.sort.type,
        resetStart: false
      })

      var response = await axios.post(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getPresubValidationMessages/`, json, { showload: true })
      this.rows = response.data.data
      this.totalRecords = response.data.count
      if (this.showPresubValResult === true) {
        this.showPresubValResult = false
      }

      this.$store.commit('updateTable', { name: this.subParamsRoutePath, filters: this.serverParams })
    },

    async createPresubPdfReport () {
      try {
        // this.action.data.reference
        // var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPresubValidationReport?submissionId=' + this.action.actionedObjectId + '&submissionReference=' + this.subReference
        var url = `${process.env.VUE_APP_BACS_API_URL}bacs/report/createPresubValidationReport?submissionId=${this.action.actionedObjectId}&submissionReference=${this.action.data.reference}`
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    },

    async createPresubCsvReport () {
      try {
        var url = `${process.env.VUE_APP_BACS_API_URL}bacs/report/createPresubValidationCsvFile?submissionId=${this.action.actionedObjectId}&submissionReference=${this.action.data.reference}`
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    }
  },
  async created () {
    console.log('created')
    this.defaultParams = JSON.parse(JSON.stringify(this.serverParams))
    var savedParams = this.$store.getters.tableFilters(this.subParamsRoutePath)
    // console.log('savedparams: ' + JSON.stringify(savedParams))
    if (savedParams) {
      // If there are, apply them!
      await this.savedParamsToObjects(savedParams)
      this.updateParams(savedParams)
    }

    await this.loadItems()
  }
}
</script>
<style lang="scss">
.report-row-selected {
  height: 60px;
  background-color: #f0f0f0;
  color: black;
}
.grid-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.viewLink {
  color: blue;
  text-decoration: underline;
}
.dark-mode {
  .viewLink {
    color: #888888;
    text-decoration: underline;
  }
}
</style>
