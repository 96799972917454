<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Settlement Details</h2>
      </div>

      <div class="idb-block-content">
        <form>
          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Settlement ID</label>
            <div class="col-sm-10">
              <input type="text" readonly class="form-control-plaintext" v-model="reportDetails.id" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Client</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                v-model="reportDetails.clientName"
              />
            </div>
          </div>

          <!-- <div class="form-group row" v-if="reportDetails.banklinePaymentId && isFinance">
            <label class="col-sm-2 text-right col-form-label">Bankline Payment</label>
            <div class="col-sm-10">
              <router-link
                :to="{ path: `/admin/bandr/bankline/banklinepaymentrow/${reportDetails.banklinePaymentId}`}"
              >View Bankline Payment</router-link>
            </div>
          </div>-->

          <div class="form-group row" v-if="reportDetails.submissionId && isFinance">
            <label class="col-sm-2 text-right col-form-label">BACS Submission</label>
            <div class="col-sm-10">
              <router-link
                :to="{ path: '/payments/search/submissionWrapper', query: { returnPage: 'searchSubmissions', submissionId: reportDetails.submissionId, fileNumber:1 }}"
              >View Submission</router-link>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Creation Date</label>
            <div class="col-sm-10">
              <span
                class="form-control-plaintext"
              >{{reportDetails.creationDate | formatDate('DD/MM/YYYY HH:mm')}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Type</label>
            <div class="col-sm-10">
              <span class="form-control-plaintext">{{reportDetails.type}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Payment Method</label>
            <div class="col-sm-10">
              <span class="form-control-plaintext">{{reportDetails.paymentMethod}}</span>
            </div>
          </div>

          <div class="form-group row" v-if="reportDetails.submissionDate">
            <label class="col-sm-2 text-right col-form-label">Submission Date</label>
            <div class="col-sm-10">
              <span
                class="form-control-plaintext"
              >{{reportDetails.submissionDate | formatDate('DD/MM/YYYY')}}</span>
            </div>
          </div>

          <div class="form-group row" v-if="reportDetails.settlementDate">
            <label class="col-sm-2 text-right col-form-label">Settlement Date</label>
            <div class="col-sm-10">
              <span
                class="form-control-plaintext"
              >{{reportDetails.settlementDate | formatDate('DD/MM/YYYY')}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Status</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                v-model="reportDetails.status"
              />
            </div>
          </div>

          <div
            v-if="reportDetails.status === 'Failed' || reportDetails.status === 'Netdown Created'"
          >
            <div class="form-group row" v-if="reportDetails.reasonCode">
              <label class="col-sm-2 text-right col-form-label">Reason Code</label>
              <div class="col-sm-10">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  v-model="reportDetails.reasonCode"
                />
              </div>
            </div>
            <div class="form-group row" v-if="reportDetails.reasonDescription">
              <label class="col-sm-2 text-right col-form-label">Reason Description</label>
              <div class="col-sm-10">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  v-model="reportDetails.reasonDescription"
                />
              </div>
            </div>
          </div>

          <div class="form-group row" v-if="reportDetails.paidByName">
            <label class="col-sm-2 text-right col-form-label">Settled By</label>
            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                v-model="reportDetails.paidByName"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 text-right col-form-label">Settlement Payment Details</label>

            <div class="col-md-6">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Collection Amount</td>
                    <td
                      :class="moneyClass(reportDetails.collectionAmount)"
                    >{{moneyFormat(reportDetails.collectionAmount)}}</td>
                  </tr>

                  <tr>
                    <td>Failed Collection Amount</td>
                    <td
                      :class="moneyClass(reportDetails.failedCollectionAmount)"
                    >{{moneyFormat(reportDetails.failedCollectionAmount)}}</td>
                  </tr>

                  <tr>
                    <td>Indemnity Claim Amount</td>
                    <td
                      :class="moneyClass(reportDetails.indemnityClaimAmount)"
                    >{{moneyFormat(reportDetails.indemnityClaimAmount)}}</td>
                  </tr>

                  <tr>
                    <td>Netdown Amount</td>
                    <td
                      :class="moneyClass(reportDetails.netdownAmount)"
                    >{{moneyFormat(reportDetails.netdownAmount)}}</td>
                  </tr>
                  <!--
                  <tr>
                    <td>Refund Amount</td>
                    <td
                      :class="moneyClass(reportDetails.refundAmount)"
                    >{{moneyFormat(reportDetails.refundAmount)}}</td>
                  </tr>-->
                  <!-- <tr>
                    <td>Service Charges</td>
                    <td
                      :class="moneyClass(reportDetails.serviceCharges)"
                    >{{moneyFormat(reportDetails.serviceCharges)}}</td>
                  </tr>
                  <tr>
                    <td>VAT</td>
                    <td
                      :class="moneyClass(reportDetails.vatAmount)"
                    >{{moneyFormat(reportDetails.vatAmount)}}</td>
                  </tr>-->
                  <tr>
                    <td>Client Settlement Payment</td>
                    <td
                      :class="moneyClass(reportDetails.clientSettlementPayment)"
                    >{{moneyFormat(reportDetails.clientSettlementPayment)}}</td>
                  </tr>
                </tbody>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Settlement Billing Details</h2>
      </div>

      <div class="idb-block-content">
        <div class="form-group row">
          <label class="col-form-label col-md-3">Exclude Zero Value Items</label>
          <div class="col">
            <p-check class="p-switch p-fill" color="primary" v-model="excludeZero" @change="load"></p-check>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              :rows="rows"
              :columns="columns"
              :lineNumbers="true"
              mode="remote"
              :totalRows="totalRecords"
              styleClass="vgt-table striped bordered"
              ref="table"
              @on-row-click="gotoDetails"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
            >
              <template slot="table-row" slot-scope="props">
                <span
                  v-if="props.column.field !== 'name'"
                  :class="tdClass(props.row, props.column.field)"
                >{{props.formattedRow[props.column.field]}}</span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="$router.go(-1)">Back</button>
        <button
          class="btn btn-success ml-2"
          v-if="reportDetails.okayToManuallyMark && isFinance"
          @click="markAsPaid"
        >Mark As Settled</button>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'

// Mixins
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

// Constants
import roles from '@/Assets/Constants/roles'
import { settlementBillingTypes as billing } from '@/Assets/Constants/billing'

export default {
  name: 'SettlementReportDetails',
  props: ['id'],
  mixins: [settlementMixin, tableFilterMixin],
  computed: {
    isFinance () {
      return this.$store.getters.isInRole(roles.Finance)
    }
  },
  data () {
    return {
      reportDetails: {
        collectionAmount: 0,
        failedCollectionAmount: 0,
        indemnityClaimAmount: 0,
        netdownAmount: 0,
        refundAmount: 0,
        serviceCharges: 0,
        clientSettlementPayment: 0
      },
      excludeZero: false,
      columns: [
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter by type',
            filterDropdownItems: Object.values(billing)
          }
        },
        {
          label: 'Customer Reference',
          field: 'customerReference',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
        },
        {
          label: 'Amount (£)',
          field: 'charge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },

        {
          label: 'Service Charges (£)',
          field: 'serviceCharge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          hidden: true
        },
        {
          label: 'VAT (£)',
          field: 'vatAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          hidden: true
        }
      ],
      rows: [],
      serverParams: {
        sort: [{ field: 'processingDate', type: 'desc' }]
      }
    }
  },
  methods: {
    async gotoDetails (params) {
      var billingUsageId = params.row.id
      await this.$store.dispatch('setPaygateId', this.reportDetails.paygateId)
      window.open(this.$router.resolve({ name: 'ChargeDetail', params: { id: billingUsageId, showBackButton: false } }).href, '_blank')
    },
    async loadReport () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/Report/${this.id}`, {
        showload: true,
        showerror: true,
        errormessage: 'Settlement details failed to load'
      })

      this.reportDetails = response.data
    },
    async load () {
      try {
        this.isTableLoading = true

        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/Report/Table`, {
          params: {
            ...this.buildGoodTableQuery({ excludeZero: this.excludeZero }),
            settlementId: this.id,
            excludeZero: this.excludeZero
          },
          showerror: true,
          errormessage: 'Settlement report details failed to load'
        })

        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch {

      } finally {
        this.isTableLoading = false
      }
    },

    async markAsPaid () {
      try {
        await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/MarkAsPaid/${this.id}`, null, {
          showload: true,
          showerror: true,
          errormessage: 'Settlement failed to mark as Settled'
        })

        this.$toastr.s(
          'Settlement marked as Settled',
          'Settled'
        )
        await this.loadReport()
      } catch { }
    }
  },
  async created () {
    await this.loadReport()
    await this.load()
  }
}
</script>

<style>
</style>
