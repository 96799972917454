import axios from "axios";
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  mounted () {
    console.log('store')
    console.log(this.$store.getters.currentPayer)
    if (this.$store.getters.currentPayer !== undefined && this.$store.getters.currentPayer.ukPayerId !== undefined && this.$store.getters.currentPayer.ukPayerId !== null && this.$store.getters.currentPayer.ukPayerId !== '00000000-0000-0000-0000-000000000000') {
      this.$store.dispatch('getAdvancedNoticeSettingsForPayer', { ukPayerId: this.$store.getters.currentPayer.ukPayerId })
    } else {
      this.$store.dispatch('getAdvancedNoticeSettings')
    }
    this.$store.dispatch('getNonProcessingDates')
    this.$store.dispatch('getSecureLimits')
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    minDate () {
      return this.$store.getters.collectionsMinDate
    },
    minDateNoDdi () {
      return this.$store.getters.collectionsMinDateNoDdi
    },
    minDateLetter () {
      return this.$store.getters.collectionsMinDateLetter
    },
    minDateLetterNoDdi () {
      return this.$store.getters.collectionsMinDateLetterNoDdi
    },
    minUntil () {
      return this.$store.getters.collectionsMinUntil
    },
    minUntilLetter () {
      return this.$store.getters.collectionsMinUntilLetter
    },
    minUntilNoDdi () {
      return this.$store.getters.collectionsMinUntilNoDdi
    },
    minUntilLetterNoDdi () {
      return this.$store.getters.collectionsMinUntilLetterNoDdi
    },
    nonProcessingDays () {
      return this.$store.getters.nonProcessingDates
    },
    secureLimits () {
      return this.$store.getters.collectionsSecureLimitsLookup
    }
  },
  watch: {
    selectedCustomer() {
      this.$store.dispatch('getAdvancedNoticeSettings')
      this.$store.dispatch('getSecureLimits')
    }
  },
  methods: {
    getAdvancedNoticeWithMedia(advanceNoticeInDays, messageType) {
      // as it is the collection date being set, it needs at least one day:
      let  notice = 1
      if (advanceNoticeInDays) {
        notice = notice + advanceNoticeInDays
      }
      if (messageType === 1) {
        //notice = notice + 5
        notice = notice + 3
      }
      let date = new Date()

      // set to midnight
      date.setHours(0, 0, 0, 0)

      //starting from tomorrow
      date.setDate(date.getDate() + 1)
      let noticeBase = notice;
      for (var i = 1; i < noticeBase; i++) {
        date.setDate(date.getDate() + 1)
        if (date.getDay() === 6 || date.getDay() === 0) {
          notice = notice + 1
        }
      }
      return notice
    },
    nudgeDateIfWeekend (date) {
      let retDate = new Date(date)
      let delta = 0
      if(retDate.getDay() === 6) {
        delta =2
      }
      if(retDate.getDay() === 0) {
        delta = 1
      }
      retDate = retDate.setDate(retDate.getDate() + delta)
      retDate = new Date (this.adjustForNpd(new Date(retDate)))

      return retDate
    },
    adjustForNpd (date) {
      if(date.getFullYear === undefined || date.getFullYear() === null) {
        date = new Date()
      }
      let comparisondate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
      for (let i = 0; i < this.nonProcessingDays.length; i++) {
        let npdDate = new Date(this.nonProcessingDays[i].getFullYear(), this.nonProcessingDays[i].getMonth(), this.nonProcessingDays[i].getDate())
        if (comparisondate === npdDate) {
          comparisondate.setDate(comparisondate.getDate() + 1)
          comparisondate = this.nudgeDateIfWeekend(comparisondate)
        }
      }
      return comparisondate
    },
    isNpd (date) {
      if(date.getFullYear === undefined || date.getFullYear() === null) {
        date = new Date()
      }
      let npd = false
      let comparisondate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
      for (let i = 0; i < this.nonProcessingDays.length; i++) {
        let npdDate = new Date(this.nonProcessingDays[i].getFullYear(), this.nonProcessingDays[i].getMonth(), this.nonProcessingDays[i].getDate())
        if (comparisondate === npdDate || comparisondate.getDay() === 0 || comparisondate.getDay() === 6) {
          npd=true
        }
      }
      return npd
    }
  }
}

