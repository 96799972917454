<template>
  <div id="viewFlaggedSubmissions">
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2><help-icon docPath="/paygate-collections/submissions/" />Pending overdue submission</h2>
          </div>
          <div class="idb-block-content">
            <p>A submission is due to leave the collection window, the processing date can be updated using the "New Submission Date" field below.</p>
            <p>Individual collections can have their date modified using the detail tab.</p>
            <b-tabs nav-wrapper-class="no-print-tabs">
              <b-tab title="Summary">
                <table>
                  <tr><td class="pr-5 font-weight-bold">Group</td><td>{{submissionResponse ? submissionResponse.groupName : ''}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Planned Processing Date</td><td>{{submissionResponse ? formatDate(submissionResponse.plannedProcessingDate) : ''}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Count</td><td>{{submissionResponse ? submissionResponse.totalResults : '0'}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Total</td><td>£{{submissionResponse ? numberWithCommas(submissionResponse.submissionTotal) : '0.00'}}</td></tr>
                  <tr><td>&nbsp;</td></tr>
                  <tr><td class="pr-5 font-weight-bold">New Submission Date:</td><td><datepicker v-model="newSubmissionDate" :disabledDates="disabledProcDates" @input="dateChanged = true" /></td></tr>
                </table>
              </b-tab>
              <b-tab title="Detail">
                <vue-good-table
                ref="table"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                @on-cell-click="onCellClick"
                :columns="columns"
                :rows="tableData"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :search-options="{
                  enabled: true
                  }"
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                @on-row-click="onRowClick"
                :isLoading.sync="isTableLoading"
                styleClass="vgt-table striped bordered"
                :row-style-class="setRowStyle">
                <template slot="table-row" slot-scope="props">
                  <span v-if="selectedRow && selectedRow.paymentComposite === props.row.paymentComposite">
                    <span v-if="props.column.field === 'amount'">
                      <currency-input type="number" style="width:100px;" v-model="props.row.amount" @input="amountChanged(props)"
                        currency="GBP"
                        locale="en"/>
                    </span>
                    <span v-else-if="props.column.field === 'processingDate'">
                      <datepicker v-model="props.row.processingDate" @selected="processingDateChanged" :disabledDates="disabledDates" />
                    </span>
                    <span v-else-if="props.column.field === 'viewPayerBtn'">
                      <router-link :to="{ name: 'editPayer', params: { ukPayerId: props.row.ukPayerId } }"> <!-- eslint-disable-line -->
                          <span>View Customer</span>
                        </router-link>
                    </span>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                  </span>
                  <span v-else>
                    <span v-if="props.column.field == 'viewBtn'">
                      <span style="font-weight: bold; color: blue;">View</span>
                    </span>
                    <span v-else-if="props.column.field === 'viewPayerBtn'">
                      <router-link :to="{ name: 'editPayer', params: { ukPayerId: props.row.ukPayerId } }"> <!-- eslint-disable-line -->
                          <span>View Customer</span>
                        </router-link>
                    </span>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                  </span>
                </template>
                <div slot="emptystate">
                    <div class="vgt-center-align vgt-text-disabled">
                      No Flagged Collections Found
                    </div>
                  </div>
                  <div slot="table-actions">
                    <button
                      @click.prevent="clearTableFilters"
                      class="btn btn-link"
                      v-b-popover.hover.top.d500="'Clear filters'"
                    >
                      <span class="fa-stack" style="font-size: 10px;">
                        <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                        <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                      </span>
                    </button>
                    <b-button @click.prevent="load" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Refresh the data in the table'">
                      <i class="fa fa-sync pointer dimmedIcon"></i>
                    </b-button>
                    <b-button @click.prevent="printTable" v-if="false" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Print out the contents of the table'">
                      <i class="fa fa-print pointer dimmedIcon"></i>
                    </b-button>
                    <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
                      <i class="fa fa-share-square pointer dimmedIcon"></i>
                    </b-button>
                  </div>
                </vue-good-table>
              </b-tab>
            </b-tabs>
          </div>
          <div class="idb-block-footer mt-3">
                <b-btn type="link" variant="outline-secondary" @click.prevent="back">
                  View Submissions
                </b-btn>
                <b-button variant="secondary" class="ml-2" @click.prevent="removeAction" v-if="actionId && actionId !== null">Clear Action</b-button>
                <b-button variant="info" class="ml-2" @click.prevent="ignoreSubmissionWarning">Ignore</b-button>
                <b-btn type="link" class="float-right ml-2" variant="primary" v-if="hasChanges" @click.prevent="saveSubmissionChanges" :disabled="isLoading">
                  Save Changes
                </b-btn>
                <b-btn type="link" class="float-right ml-2" variant="primary" v-if="dateChanged" @click.prevent="updateBulkSubmission" :disabled="isLoading">
                  Update Submission Date
                </b-btn>
                <b-btn type="link" class="float-right mr-2" variant="outline-warning" v-if="hasChanges" @click.prevent="cancelSubmissionChanges" :disabled="isLoading">
                  Cancel Changes
                </b-btn>
                <!-- <b-btn class="float-right" type="link" variant="primary" v-if="!hasChanges" @click.prevent="process">
                  Process Submission
                </b-btn> -->
              </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// eslint-disable-next-line
import Vuelidate from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker'
import swal from 'sweetalert2'
import _ from 'lodash'
import axios from 'axios'
import papa from 'papaparse'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import rulesMixin from '@/Assets/Mixins/CollectionsRulesMixin'
export default {
  mixins: [tableFilterMixin, loading, rulesMixin],
  props: {
    orphanWarningId: String
  },
  components: {
    Datepicker
  },
  data () {
    return {
      columns: [
        {
          field: 'flaggedPaymentsCount',
          hidden: true
        },
        {
          label: 'First Name',
          field: 'firstName',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Surname',
          field: 'surname',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Reference',
          field: 'payerReference',
          formatFn: (x) => x.toUpperCase()
        },
        {
          label: 'Transaction Code',
          field: 'txCode',
          width: '180px'
        },
        {
          label: 'Amount (£)',
          field: 'amount',
          formatFn: (value) => {
            return `${this.numberWithCommas((value / 1).toFixed(2))}`
          },
          tdClass: 'text-right amountField'
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          formatFn: (val) => new Date(val).toLocaleDateString(),
          width: '180px'
        },
        {
          label: 'Plan Reference',
          field: 'planReference'
        },
        {
          label: '',
          field: 'viewPayerBtn',
          sortable: false
        }
      ],
      serverParams: {
        sort: [{ field: 'payerName', type: 'asc' }]
      },
      dates: { proc: new Date(), coll: new Date() },
      selectedRow: null,
      originalState: [],
      tableData: [],
      hasChanges: false,
      startDate: new Date(Date.now() - 24 * 3600 * 1000),
      endDate: new Date(),
      group: null,
      actionId: null,
      submissionResponse: null,
      newSubmissionDate: new Date(),
      groupConfig: null,
      dateChanged: false
    }
  },
  methods: {
    back () {
      this.$router.push('/collections/submissions/')
    },
    getSubmissions () {
      this.$store.dispatch('loadSubmissions')
    },
    load: async function () {
      try {
        var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/orphans/${this.orphanWarningId}`, {}, { params: {
          ...this.buildGoodTableQuery(), paygateId: this.paygateId },
          showload: true
        })
        this.submissionResponse = response.data
        this.originalState = this.flaggedPayments
        this.tableData = _.cloneDeep(this.submissionResponse.orphanedSubmissions)
        this.totalRecords = this.submissionResponse.totalResults
        this.groupConfig = this.submissionResponse.config
        this.newSubmissionDate = this.submissionResponse.plannedProcessingDate
      } catch (error) {
        this.$toastr.e(error)
      }
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    async exportTable () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/orphans/${this.orphanWarningId}`, {
        perPage: this.totalRecords,
        currentOffset: 1
      }, { showload: true, params: { paygateId: this.paygateId } })
      var csvString = papa.unparse(response.data.orphanedSubmissions)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, this.orphanWarningId + '-orphaned-submissions.csv')
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = this.orphanWarningId + '-orphaned-submissions.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    processingDateChanged (date) {
      var dateOffset = (24 * 60 * 60 * 1000) * 1
      let procDate = new Date(date.getTime())
      procDate = new Date(procDate.setTime(procDate.getTime() - dateOffset))
      // CORRECT DATE.
      let procDateValid = false
      while (!procDateValid) {
        if (procDate.getDay() === 6 || procDate.getDay() === 0 || this.$store.getters.nonProcessingDates.includes(procDate)) {
          procDate = new Date(procDate.setTime(procDate.getTime() - dateOffset))
        } else {
          procDateValid = true
        }
      }
      this.selectedRow.processingDate = procDate.toISOString()
      for (var i = 0; i < this.tableData.length; i++) {
        if ((this.tableData[i].paymentComposite !== '' && this.tableData[i].paymentComposite === this.selectedRow.paymentComposite) || (this.tableData[i].paymentComposite === '' && this.tableData[i].ukddmsPendingPaymentId === this.selectedRow.ukddmsPendingPaymentId)) {
          this.tableData[i].dateChanged = true
          this.hasChanges = true
        }
      }
    },
    amountChanged (props) {
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].paymentComposite === this.selectedRow.paymentComposite) {
          this.tableData[i].amount = props.row.amount
          this.tableData[i].amountChanged = true
          this.hasChanges = true
        }
      }
    },
    async saveSubmissionChanges () {
      var invalid = false
      for (var i = 0; i < this.tableData.length; i++) {
        const payment = this.tableData[i]
        if (payment.amountChanged && !payment.dateChanged) {
          invalid = true
        }
      }
      var result
      if (!invalid) {
        result = await swal({
          title: 'Save submission',
          text: 'Collection amounts and dates have been changed. Saving the Collections will remove them from the current submission.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel'
        })
        if (result) {
          var response = await this.$store.dispatch('updateSubmission', this.tableData)
          if (response) {
            this.$toastr.s('Submission updated')
          }
          this.$store.dispatch('updateCurrentCollectionsSubmissionCount')
          await this.load()
          this.hasChanges = false
          this.selectedRow = null
        }
      } else {
        result = await swal({
          title: 'Validation',
          text: 'Collection amounts have been changed without updating the processing date. Please update the processing date.',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
        console.log(result)
      }
    },
    cancelSubmissionChanges () {
      this.$store.dispatch('resetData')
      this.data = _.cloneDeep(this.flaggedPayments)
      this.hasChanges = false
      this.selectedRow = null
    },
    numberWithCommas (x) {
      x = Number(x).toFixed(2)
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    setRowStyle (row) {
      return row.amount === 0 ? 'alert' : ''
    },
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
      if (this.$store.getters.customerGroups !== null && this.$store.getters.customerGroups.length > 0) {
        this.group = this.$store.getters.customerGroups[0].groupId
      }
    },
    async customerGroupsChanged () {
      await this.load()
    },
    removeAction () {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}submissions/clearaction`, { params: { paygateid: this.paygateId, actionId: this.actionId }, showload: true }).then(
        (response) => {
          this.$toastr.s('Action cleared')
          this.actionId = null
        }
      )
    },
    ignoreSubmissionWarning () {
      axios.put(`${process.env.VUE_APP_DDMS_API_URL}submissions/ignoresubmissionwarning`, { orphanWarningId: this.orphanWarningId }, { showload: true, params: { paygateId: this.paygateId } }).then(
        (response) => {
          this.$toastr.s('Future warnings prevented on this batch of submissions.')
        }
      )
    },
    async updateBulkSubmission () {
      await axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/updateoverduesubmission`, { orphanWarningId: this.orphanWarningId, newDate: this.newSubmissionDate, actionId: this.$route.query.actionId }, { showload: true, params: { paygateId: this.paygateId } })
      await this.load()
      this.actionId = null
      this.dateChanged = false
      this.$toastr.s('The submission date has been updated for this collection of Collections.')
    },
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
    }
  },
  computed: {
    flaggedPayments: {
      get () {
        return this.$store.state.submissions.flaggedPayments
      },
      set (value) {
        if (value) {
          this.$store.commit('setFlagged', value)
        }
      }
    },
    disabledDates: function () {
      let maxDate = new Date()
      let minDate = new Date(this.dates.proc)
      if (this.selectedRow) {
        const start = new Date(this.selectedRow.processingDate)
        console.log(start.getDate())
        // minDate = new Date(start.getTime())
        minDate = new Date()
        minDate.setDate(minDate.getDate() + this.advancedNoticeWithMedia)
        // maxDate = new Date(start.getTime())
        // maxDate.setDate(maxDate.getDate() + 3)
        maxDate = new Date(Date.parse(this.submissionResponse.lastPossiblePaymentDate))
        console.log(minDate)
        console.log(maxDate)
        if (this.selectedRow.isNew) {
          minDate = new Date()
          maxDate = new Date(Date.parse(this.submissionResponse.lastPossiblePaymentDate))
        }
      }
      return {
        days: [0, 6],
        daysOfMonth: [29, 30, 31],
        dates: this.$store.getters.nonProcessingDates,
        to: minDate,
        from: maxDate
      }
    },
    disabledProcDates: function () {
      let maxDate = new Date()
      let minDate = new Date(this.dates.proc)

      const start = new Date(minDate)
      console.log(start.getDate())
      // minDate = new Date(start.getTime())
      minDate = new Date()
      minDate.setDate(minDate.getDate() + this.advancedNoticeWithMedia)
      // maxDate = new Date(start.getTime())
      // maxDate.setDate(maxDate.getDate() + 3)
      maxDate = new Date(Date.parse(this.submissionResponse.lastPossiblePaymentDate))
      console.log(minDate)
      console.log(maxDate)
      return {
        days: [0, 6],
        daysOfMonth: [29, 30, 31],
        dates: this.$store.getters.nonProcessingDates,
        to: minDate,
        from: maxDate
      }
    },
    advancedNoticeWithMedia () {
      return this.getAdvancedNoticeWithMedia(this.groupConfig.advanceNoticeInDays, this.groupConfig.defaultPayerMessageType)
    },
    totalPages () {
      return this.$store.getters.submissionMetaData.totalPages
    },
    totalItems () {
      return this.$store.getters.submissionMetaData.totalItems
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = this.$store.getters.customerGroups
      }
      return ret
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    disabledRangeDates: function () {
      const maxDate = new Date(Date.now() - 24 * 3600 * 1000)
      const minDate = new Date('0001-01-01T00:00:00Z')
      return {
        to: minDate,
        from: maxDate
      }
    }
  },
  validations: {
    group: { required }
  },
  async mounted () {
    await this.loadGroups(this.paygateId)
    this.actionId = this.$route.query.actionId
    await this.$store.dispatch('getGroupConfigurationFromGroupId', { id: this.groupId })
    var resp = await this.$store.dispatch('getScheduleProcessingDates')
    this.dates.proc = resp.proc
    this.dates.coll = resp.coll
    this.endDate = this.endDate.setMonth(this.endDate.getMonth() - 6)
    await this.load()
  }
}
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
</style>
