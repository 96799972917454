
import { mapGetters } from 'vuex'

export default {
  computed: {
    disabledDueToConnectedMerchant () {
      return this.isParentConnectedMerchant && !this.isAllowedToEdit
    },
    ...mapGetters(['isParentConnectedMerchant', 'isAllowedToEdit'])
  }
}
