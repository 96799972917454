<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline Notifications<span class="pull-right"><a :href="`${docUrl}/automation/logging/`" target="_blank"><i class="far fa-question-circle"></i></a></span><favourite-icon></favourite-icon></h2>
          <!-- <div>All Bankline Account Statement Notifications</div> -->
        </div>
        <div class="idb-block-content">

          <div class="row form-group mb-3">
            <div class="col-lg-1 col-md-2 col-sm-6">
              <div class="form-group">
                <p-radio value="all" color="primary" v-model="notificationFilter.filterType" @change="changedFilter()">All</p-radio>
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6">
              <div class="form-group">
                <p-radio value="customer" color="primary" v-model="notificationFilter.filterType" @change="changedFilter()">Customer</p-radio>
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6">
              <div class="form-group">
                <p-radio value="bankAccount" color="primary" v-model="notificationFilter.filterType" @change="changedFilter()">Bank Account</p-radio>
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6">
              <div v-if="notificationFilter.filterType === 'bankAccount'">
                <transition name="fadein">
                  <b-form-select
                    v-model="notificationFilter.bankAccountId"
                    :options="bankAccounts"
                    @change="changedFilter()"
                  ></b-form-select>
                </transition>
              </div>
            </div>
            <div class="col-lg-5 col-md-1 col-sm-6">
              <button id="btnGroupDrop1"  v-b-popover.hover.top.d500="'Resets the filter back to default.'" @click="clearFilter" type="button" class="btn btn-outline-secondary float-right">X</button>
            </div>
            <div class="col-md-1 col-sm-6" v-if="notificationFilter.filterType === 'bankAccount'">
              <div v-if="isBanksLoading" class="spinner-border spinner-border-sm mt-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
            <div class="idb-block">
              <div
                class="idb-block-title titleHover"
                v-b-toggle.advancedFilterCollapse>
                Advanced Filter
                <i class="fa fa-chevron-down"></i>
                <i class="fa fa-chevron-up"></i>
              </div>
              <b-collapse v-model="advancedFilter.visible" id="advancedFilterCollapse">
                <div class="idb-block-content">
                  <div v-if="advancedFilter.show">
                    <div class="row">
                      <div class="col-md-12">
                        <b-form-checkbox v-model="advancedFilter.showOnlyUnmatched" @change="changedFilter()" >
                          Show Only Unreconcilled
                        </b-form-checkbox>
                      </div>
                    </div>
                    <br/>
                    <div class="row">
                      <div class="col-md-2 col-sm-12">
                        <b-form-checkbox v-model="advancedFilter.dateFilter" @change="changedFilter()" >
                            Date Filter
                          </b-form-checkbox>
                      </div>
                      <div class="col-md-1 col-sm-12" v-if="advancedFilter.dateFilter">
                        <span class="float-right">From:</span>
                      </div>
                      <div class="col-md-2 col-sm-12" v-if="advancedFilter.dateFilter">
                        <div class="input-group">
                          <div class="right-inner-addon">
                            <datepicker
                              id="fromDatePicker"
                              format="dd/MM/yyyy"
                              :bootstrap-styling="bootstrapStyling"
                              :typeable="typeable"
                              v-model="advancedFilter.fromDate"
                              @input="changeFromDate()"
                            />
                            <i class="fa fa-calendar form-control-feedback"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1 col-sm-12" v-if="advancedFilter.dateFilter">
                        <span class="float-right">To:</span>
                      </div>
                      <div class="col-md-2 col-sm-12" v-if="advancedFilter.dateFilter">
                        <div class="input-group">
                          <div class="right-inner-addon">
                            <datepicker
                              id="toDatePicker"
                              format="dd/MM/yyyy"
                              :bootstrap-styling="bootstrapStyling"
                              :typeable="typeable"
                              v-model="advancedFilter.toDate"
                              @input="changeToDate()"
                            />
                            <i class="fa fa-calendar form-control-feedback"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          <div>
            <VuePerfectScrollbar class="scroll-area" :settings="settings">
              <vue-good-table
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                :isLoading.sync="isTableLoading"
                :rows="rows"
                :columns="columns"
                mode="remote"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :lineNumbers="true"
                styleClass="vgt-table striped bordered table-hover"
                ref="table"
                @on-row-click="onRowClick">
                  <template slot="table-row" slot-scope="props">
                    <span v-if = "props.column.field == 'entryType'">
                      <b-badge pill v-if = "props.row.entryType == 'Credit'" variant="success">{{props.row.entryType}}</b-badge>
                      <b-badge pill v-else-if = "props.row.entryType == 'Debit'" variant="danger">{{props.row.entryType}}</b-badge>
                      <span v-else>{{props.row.entryType}}</span>
                    </span>
                    <span v-if = "props.column.field == 'status'">
                      <b-badge pill v-if = "props.row.status == '1'" variant="danger">Unprocessed</b-badge>
                      <b-badge pill v-else-if = "props.row.status == '2'" variant="info">Processing</b-badge>
                      <b-badge pill v-else-if = "props.row.status == '3'" variant="success">Matched</b-badge>
                      <b-badge pill v-else-if = "props.row.status == '4'" variant="danger">Unmatched</b-badge>
                      <b-badge pill v-else-if = "props.row.status == '5'" variant="dark">Unmatchable Type</b-badge>
                      <b-badge pill v-else-if = "props.row.status == '6'" variant="danger">Unknown Type</b-badge>
                      <b-badge pill v-else-if = "props.row.status == '7'" variant="warning">Ambiguous</b-badge>
                      <b-badge pill v-else-if = "props.row.status == '8'" variant="danger">Invalid Date</b-badge>
                      <span v-else>{{props.row.entryType}}</span>
                    </span>
                    <span v-else-if = "props.column.field == 'data.Debit'">
                      {{ reformatCurrency(props.formattedRow[props.column.field])}}
                    </span>
                    <span v-else-if = "props.column.field == 'data.Credit'">
                      {{ reformatCurrency(props.formattedRow[props.column.field])}}
                    </span>
                    <span v-else-if = "props.column.field == 'buttons'">
                        <b-dropdown  right variant="outline-primary" @click.prevent="btnViewItem(props)" split text="View" class="m-2">
                          <b-dropdown-item @click.prevent="btnViewParentFile(props)"><i class="glyphicon ti-archive mr-1"></i>View Parent File</b-dropdown-item>
                          <b-dropdown-item v-if = "props.row.status == '4'" @click.prevent="btnReconcileItem(props)"><i class="glyphicon ti-archive mr-1"></i>Reconcile</b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item active @click.prevent="btnInDeleteRow(props.row.id)"><i class="glyphicon ti-trash mr-1"></i>Delete</b-dropdown-item>
                        </b-dropdown>
                    </span>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'">
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span>
                  </button>
                  <b-button @click.prevent="load" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the table data.'" ><i class="fas fa-sync-alt pointer dimmedIcon"></i></b-button>
                </div>
            </vue-good-table>
            </VuePerfectScrollbar>
        </div>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div class="btn-group mr-2" role="group">
                <button id="btnGroupDrop1" @click="btnUploadBanklineFile" type="button" class="btn btn-primary">
                  <i class="fa fa-upload mr-2"></i>Upload Bankline File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Datepicker from 'vuejs-datepicker'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
export default {
  name: 'WorkflowLogs',
  mixins: [tableFilterMixin],
  components: {
    VuePerfectScrollbar,
    swal,
    Datepicker
  },
  data () {
    return {
      settings: {
      },
      btnLatch: false,
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      notificationFilter: {
        filterType: 'all',
        bankAccountId: '',
        paygateId: ''
      },
      advancedFilter: {
        show: true,
        showOnlyUnmatched: false,
        dateFilter: false,
        fromDate: new Date(),
        toDate: new Date(),
        visible: true,
        age: new Date()
      },
      isBanksLoading: true,
      bankAccounts: [],
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Sort Code',
          field: 'data.SortCode',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account Number',
          field: 'data.AccountNumber',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Account Alias',
          field: 'data.AccountAlias',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Account Short Name',
          field: 'data.AccountShortName',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Currency',
          field: 'data.Currency',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Account Type',
          field: 'data.AccountType',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'BIC',
          field: 'data.BIC',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Bank Name',
          field: 'data.BankName',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Branch Name',
          field: 'data.BranchName',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Date',
          field: 'data.Date',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Nar #1',
          field: 'data.Narrative1',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Nar #2',
          field: 'data.Narrative2',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Nar #3',
          field: 'data.Narrative3',
          hidden: false,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Narrative #4',
          field: 'data.Narrative4',
          hidden: true,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Narrative #5',
          field: 'data.Narrative5',
          filterOptions: {
            enabled: true
          },
          hidden: true
        },
        {
          label: 'Type',
          field: 'data.Type',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Debit',
          field: 'data.Debit',
          type: 'decimal',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Credit',
          field: 'data.Credit',
          type: 'decimal',
          filterOptions: {
            enabled: true
          }
        },
        {
          field: 'buttons',
          sortable: false,
          filterOptions: { enabled: false },
          tdClass: 'text-center',
          thClass: 'text-center',
          hidden: false
        }
      ],
      row_data: [],
      totalRecords: 0,
      paginationOptions: { enabled: true, perPage: 10, dropdownAllowAll: false, jumpFirstOrLast: true },
      serverParams: {
        sort: [{ field: 'data.Date', type: 'desc' }]
      },
      typeable: false,
      bootstrapStyling: true
    }
  },
  computed: {
    rows: function () {
      return this.row_data
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async changedFilter () {
      // console.log(this.advancedFilter.fromDate)
      localStorage.notificationFilter = JSON.stringify(this.notificationFilter)
      localStorage.advancedFilter = JSON.stringify(this.advancedFilter)
      this.load()
    },
    async changeFromDate () {
      // Don;t allow the from date to be > to date
      console.log('from:', this.advancedFilter.fromDate)
      const jsToDate = new Date(this.advancedFilter.toDate)
      console.log('to:', jsToDate)
      if (this.advancedFilter.fromDate > jsToDate) {
        console.log('bad')
        this.advancedFilter.fromDate = jsToDate
      }
      this.changedFilter()
    },
    async changeToDate () {
      // Don;t allow the from date to be > to date
      const jsFromDate = new Date(this.advancedFilter.fromDate)
      console.log('from:', jsFromDate)
      console.log('to:', this.advancedFilter.toDate)
      if (this.advancedFilter.toDate < jsFromDate) {
        console.log('bad')
        this.advancedFilter.toDate = jsFromDate
      }
      this.changedFilter()
    },
    async load () {
      const postObj = {
        serverParams: this.serverParams,
        id: this.id,
        notificationFilter: this.notificationFilter,
        advancedFilter: this.advancedFilter
      }
      this.$snapbar.hide()
      this.isTableLoading = true
      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineastfile`, postObj)
        if (res) {
          this.row_data = res.data.resObj.data
          this.totalRecords = res.data.resObj.count
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
        this.row_data = []
        this.totalRecords = 0
      } finally {
        this.isTableLoading = false
      }
    },
    async loadBankAccounts () {
      try {
        this.isBanksLoading = true
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/Dropdown`, { showerror: true, errormessage: 'Failed to get bank accounts' })
        this.isBanksLoading = false
        this.bankAccounts = response.data.map(bankAccount => {
          return { value: bankAccount.bankAccountId, text: bankAccount.reference }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async btnUploadBanklineFile () {
      this.$router.push('/admin/bandr/bankline/uploadfile')
    },
    btnViewItem (props) {
      console.log(props)
      this.btnLatch = true
      this.$router.push(`/admin/bandr/bankline/banklineastrow/${props.row.id}`)
      this.btnLatch = false
    },
    reformatCurrency (c) {
      if (!c) return ''
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      })
      return formatter.format(c)
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    onRowClick (params) {
      if (!this.btnLatch) {
        console.log(params)
        this.$router.push(`/admin/bandr/bankline/banklineastrow/${params.row.id}`)
      }
    },
    clearFilter () {
      this.notificationFilter = {
        filterType: 'all',
        bankAccountId: ''
      }
      this.advancedFilter = {
        show: true,
        showOnlyUnmatched: false,
        fromDate: new Date(),
        toDate: new Date(),
        age: new Date()
      }
      this.advancedFilter.fromDate.setDate(1)
      this.advancedFilter.fromDate.setHours(0, 0, 0, 0)
      this.advancedFilter.toDate.setHours(23, 59, 59, 999)
      this.changedFilter()()
    },
    async btnReconcileItem (props) {
      this.btnLatch = true
      this.$router.push(`/admin/bandr/bankline/banklineastrow/edit/${props.row.id}`)
    },
    async btnViewParentFile (props) {
      this.btnLatch = true
      this.$router.push(`/admin/bandr/bankline/banklineastfile/${props.row.parentFileId}`)
    },
    async btnInDeleteRow (id) {
      this.btnLatch = true
      const result = await swal.fire({
        title: 'Are you sure you want to permanently delete this Bankline item?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineAstRow/${id}`, { showload: true })
          if (res) {
            this.$toastr.s('The Bankline item was successfully deleted.')
            this.load()
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this item.')
          } else {
            console.log(e)
            this.$snapbar.e(`Could not delete this item - ${e.message}`)
          }
        }
      }
      this.btnLatch = false
    },
    lessThanOneHourAgo (date) {
      let hour = 1000 * 60 * 60
      let anHourAgo = Date.now() - hour
      if (date < anHourAgo) {
        this.clearFilter()
        console.log('clearFilter')
      }
    }
  },
  async mounted () {
    await this.loadBankAccounts()
    if (localStorage.notificationFilter) {
      this.notificationFilter = JSON.parse(localStorage.notificationFilter)
      console.log(this.notificationFilter)
    }
    if (localStorage.advancedFilter) {
      this.advancedFilter = JSON.parse(localStorage.advancedFilter)
      this.lessThanOneHourAgo()
      // if the age of the filter older than 1 hour?
    } else {
      this.advancedFilter.fromDate = new Date()
      this.advancedFilter.fromDate.setDate(1)
      this.advancedFilter.fromDate.setHours(0, 0, 0, 0)

      this.advancedFilter.toDate = new Date()
      this.advancedFilter.toDate.setHours(23, 59, 59, 999)
    }
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.load()
    },
    'advancedFilter.visible': function (val) {
      this.changedFilter()
    }
  }
}
</script>
<style lang="scss" scoped>
  td {
    cursor: pointer;
  }
  /* Transision */
  .fadein-enter {
    opacity: 0;
  }
  .fadein-enter-active {
    transition: opacity 0.6s;
  }
  .fadein-leave-active {
    transition: opacity 0.6s;
    opacity: 0;
  }
  /* Accordian */
  .fa-chevron-up,
  .fa-chevron-down {
    float: right;
  }

  .dark-mode {
    .idb-block-title:hover {
      background-color: #353535;
    }
  }
.idb-block-title:hover {
  background-color: #eeeeee;
}
.collapsed > .fa-chevron-up,
:not(.collapsed) > .fa-chevron-down {
  display: none;
}
.fa-info-circle {
  margin-left: 5px;
}
.side-icon {
  display: flex;
  i {
    height: 15px;
  }
}
.currentDefault {
  color: #004085;
  background-color: #b8daff;
}
</style>
