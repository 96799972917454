<template>
  <div style="height: 100%;overflow-y: scroll;overflow-x: hidden;">
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0">
        <span v-if="elAdvancedFilter.showRange == 'today'">Today's Events</span>
        <span v-else>Events</span>
        <span class="ml-2">
          <button id="btnGroupDrop1" @click="btnRefresh" type="button" class="btnLink" v-b-popover.hover.top.d500="'Refresh the event log data.'"><i class="fas fa-sync mr-2"></i></button>
        </span>
        <span v-if="errorMessage !==''" class="text-danger ml-2">{{errorMessage}}</span>
      </h4>
      <span class="float-right pr-1"><button @click="btnNavEventLog" type="button" class="btn btn-outline-secondary btn-sm" v-b-popover.hover.top.d500="'Navigate to the full event log.'"><i class="fas fa-link mr-2"></i>Event Log</button></span>
    </div>
    <div class="progress">
      <div class="progress-bar" role="progressbar" :style=getCountdown() aria-valuemax="100"></div>
    </div>
    <br>
    <div class="" v-for="row in row_data" :key="row.index">
      <h5>
        <span v-if="row.eventType === 'Info'" class="badge badge-pill badge-info">Info</span>
        <span v-else-if="row.eventType === 'Warning' && row.level === 'High'" class="badge badge-pill badge-danger mr-1">Critical Warning</span>
        <span v-else-if="row.eventType === 'Warning' && row.level !== 'High'" class="badge badge-pill badge-warning mr-1">Warning</span>
        <span v-else-if="row.eventType === 'Error'" class="badge badge-pill badge-danger">Error</span>
        <span v-else class="badge badge-pill badge-info">Message</span>
        {{row.title}} <span class="ml-3 mr-3 dateText" v-b-popover.hover.top.d500="'The date and time that the event was raised.'">{{reformatDate(row.creationDate)}}</span>
      </h5>
      <div v-html="row.message"></div>
      <!-- <button  v-if="row.contextId && row.area !== '1'" @click="btnLink(row)" type="button" class="btn btn-link btnLink">
        <i class="fas fa-link mr-2"></i>More Information
      </button> -->
      <div v-if="row_data.indexOf(row) < datalength">
        <hr/>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
  name: 'EventsWidget',
  components: {
    VuePerfectScrollbar
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      errorMessage: '',
      row_data: [],
      totalRecords: 0,
      typeable: false,
      bootstrapStyling: true,
      serverParams: {
        sort: [{ field: 'expirationDate', type: 'desc' }]
      },
      pageSizePptions: [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
      options: [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '50', value: 50 },
        { text: '100', value: 50 }
      ],
      progressBarValue: 100,
      timerValue: 0,
      refreshPeriod: 30000,
      timerInterval: 1000,
      isLoading: false,
      elAdvancedFilter: {
        show: true,
        dateFilter: false,
        fromDate: new Date(),
        toDate: new Date(),
        visible: true,
        age: new Date(),
        perPage: 20,
        page: 1,
        showRange: 'today'
      }
    }
  },
  computed: {
    paygateId () {
      return this.$store.getters.selectedCustomer
    },
    datalength () {
      return (this.row_data.length - 1)
    }
  },
  methods: {
    async load () {
      this.errorMessage = ''
      this.isLoading = true
      const today = this.widget.options[0].value
      if (today) {
        this.elAdvancedFilter.showRange = 'today'
      } else {
        this.elAdvancedFilter.showRange = 'all'
      }

      const postObj = {
        serverParams: this.serverParams,
        elAdvancedFilter: this.elAdvancedFilter
      }
      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}events`, postObj)
        if (res) {
          this.row_data = res.data.data
          this.totalRecords = res.data.count
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.errorMessage = 'You are not authorised to view events data.'
        } else {
          this.errorMessage = `Could not get the events data - ${e.message}`
        }
        this.row_data = []
        this.totalRecords = 0
      } finally {
        this.isLoading = false
      }
    },
    async changedFilter () {
      localStorage.elAdvancedFilter = JSON.stringify(this.elAdvancedFilter)
      this.load()
    },
    async btnRefresh () {
      this.timerValue = this.refreshPeriod
      this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
      this.load()
    },
    async btnNavEventLog () {
      this.$router.push('admin/system/eventlog/eventlog')
    },
    getImageSrc (originator) {
      return '/img/sharedimages/events/icons/' + originator + '.png'
    },
    getCountdown () {
      return `width: ${this.progressBarValue}%`
    },
    showPerPage () {
      if (this.elAdvancedFilter.perPage > this.totalRecords) {
        return this.totalRecords
      } else {
        return this.elAdvancedFilter.perPage
      }
    },
    lessThanOneHourAgo (date) {
      const hour = 1000 * 60 * 60
      const anHourAgo = Date.now() - hour
      if (date < anHourAgo) {
        this.clearFilter()
        console.log('clearFilter')
      }
    },
    reformatJustDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      return dd + '/' + mm + '/' + yyyy
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    }
    // async btnLink (row) {
    //   console.log(row)
    //   switch (row.area) {
    //     case '2':
    //       this.$router.push({
    //         path: '/payments/common/viewSubmission',
    //         query: { submissionId: row.contextId }
    //       })
    //       break
    //     case '3':
    //       this.$router.push('/payments/bacsreports/viewDownloadedReports')
    //       break
    //     default:
    //       this.$toastr.e('Unknown or unavailable link.')
    //   }
    // }
  },
  watch: {
    'elAdvancedFilter.visible': function (val) {
      this.changedFilter()
    }
  },
  async mounted () {
    if (localStorage.elAdvancedFilter) {
      this.elAdvancedFilter = JSON.parse(localStorage.elAdvancedFilter)
      this.lessThanOneHourAgo()
      // if the age of the filter older than 1 hour?
    } else {
      this.elAdvancedFilter.fromDate = new Date()
      this.elAdvancedFilter.fromDate.setDate(-30)
      this.elAdvancedFilter.fromDate.setHours(0, 0, 0, 0)
      this.elAdvancedFilter.toDate = new Date()
      this.elAdvancedFilter.toDate.setHours(23, 59, 59, 999)
      localStorage.elAdvancedFilter = JSON.stringify(this.elAdvancedFilter)
    }
    await this.load()
    // The countdown time
    this.timerValue = this.refreshPeriod
    this.timer = setInterval(() => {
      this.timerValue -= this.timerInterval
      this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
      if (this.progressBarValue <= 0) {
        // The settimeout is used to sync the UI
        setTimeout(() => {
          this.timerValue = this.refreshPeriod
          this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
          this.load()
        }, 1000)
      }
    }, this.timerInterval)
    this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
  },
  destroyed () {
    clearTimeout(this.timer)
  }
}
</script>

<style scoped>
  /* Nice thin progressbar */
  .progress {
    height: 2px;
    line-height: 2px;
    background-color: #eeeeee;
  }
  .progress-bar {
    height: 2px;
    line-height: 2px;
    background-color: #aaaaaa;
  }
  .textMuted {
    font-size: small;
    color: #bbbbbb;
  }
  .dateText {
    font-size: small;
    color: #aaaaaa;
  }

.btnLink {
  color: #999999;
  border-style: none;
  background: none;
}

/* mouse over link */
.btnLink:hover {
  color: #555599;
}

</style>
