<template>
  <div>
     <b-form-row>
          <b-form-group
             :label-cols="5" class="col-sm-5"
            label-class="required"
            horizontal
            label="Schedule Type"
          >
        <b-form-select
          id="selectPlanInput"
          class="form-control"
          @keyup.enter="addNewPaymentPlan"
          @change="addNewPaymentPlan"
          type="text"
          placeholder="New plan name..."
          :options="planNames"
          text-field="name"
          value-field="name"
          :disabled="outOfPlanNames"
          v-model="selectedPlanName"
        >
          <!-- This slot appears above the options from 'options' prop -->
          <template slot="first">
            <option :value="null" disabled>-- Please select an option --</option>
          </template>
        </b-form-select>
      </b-form-group>
    </b-form-row>
    <payment-plan-input
      v-if="selectedPaymentPlan !== null && selectedPaymentPlan !== undefined"
        :plan="selectedPaymentPlan" ref="planControl"
        :createMode="true" @input="setValid"
        :label-cols="5" column-class="col-sm-5"
        :limitFirstPaymentDate="true"
        @isinvalid="setValidity"
        :canCreateOwnReference="true"
      ></payment-plan-input>
    <div class="float-right pb-1 mr-2">
      Add another Customer on completion
      <p-check id="addAnotherCheckBox"
        class="p-switch p-fill"
        color="primary"
        v-model="addAnother"
      ></p-check>
    </div>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import Components from '@/Components/Collections'
import PrettyCheck from 'pretty-checkbox-vue/check'
import rulesMixin from '@/Assets/Mixins/CollectionsRulesMixin'
import _ from 'lodash'
import {firstBy} from "thenby";
import Moment from "moment";
export default {
  mixins: [rulesMixin],
  data () {
    return {
      planNames: [],
      outOfPlanNames: false,
      selectedPlanName: null,
      selectedPaymentPlan: null,
      planValid: true
    }
  },
  methods: {
    checkPlanNames (input) {
      var plans = this.$store.state.payer.currentPayer.paymentPlans
      var usableNames = input
      for (var i = 0; i < plans.length; plans++) {
        if (usableNames.find(val => val.name === plans[i].planTemplateName)) {
          usableNames = usableNames.filter(item => item.name !== plans[i].planTemplateName)
        }
      }
      this.outOfPlanNames = usableNames.length === 0
      return usableNames
    },
    addNewPaymentPlan () {
      this.$store.state.payer.currentPayer.paymentPlans = []
      this.selectedPaymentPlan = null
      let selectedPlan
      let plan
      let date = this.groupConfig.defaultPayerMessageType === 1 ? this.$store.getters.collectionsMinDateLetter : this.$store.getters.collectionsMinDate
      if (date < this.activationDate){
        date = this.$store.state.payer.currentPayer.activationDate
        date = this.adjustForNpd(date)
      }
      let untilDate = this.groupConfig.defaultPayerMessageType === 1 ? this.$store.getters.collectionsMinUntilLetter : this.$store.getters.collectionsMinUntil
      for (var i = 0; i < this.planNames.length; i++) {
        plan = this.planNames[i]
        if (plan && plan.name === this.selectedPlanName) {
          selectedPlan = _.cloneDeep(this.planNames[i].plan)
          selectedPlan.step = selectedPlan.step === null ? 0 : selectedPlan.step
          selectedPlan.reference = this.allowMultiplePlans && this.$store.state.payer.reference.length <= 14 ? (this.requiresMigration ? this.$store.state.payer.reference : `${this.$store.state.payer.reference}-001`) : this.$store.state.payer.reference
          selectedPlan.firstPaymentDate = date
          if (selectedPlan.friendlyRecurrenceType < 2) {
            selectedPlan.ordinal = date.getDay()
          }
          this.historicPlanCount = this.historicPlanCount + 1
          selectedPlan.futurePlanChanges = []
          selectedPlan.planTemplateName = plan.name
          selectedPlan.planStatus = 3
          selectedPlan.groupId = this.$store.state.payer.currentPayer.groupId
          selectedPlan.lastAmount = isNaN(selectedPlan.lastAmount) || selectedPlan.lastAmount === null ? 0 : selectedPlan.lastAmount
          selectedPlan.firstAmount = isNaN(selectedPlan.firstAmount) || selectedPlan.firstAmount === null ? 0 : selectedPlan.firstAmount
          selectedPlan.regularAmount = isNaN(selectedPlan.regularAmount) || selectedPlan.regularAmount === null ? 0 : selectedPlan.regularAmount
          selectedPlan.untilDate = untilDate
        }
      }
      if (this.$store.state.payer.currentPayer.paymentPlans.length === 0) {
        this.$store.state.payer.currentPayer.paymentPlans.push(selectedPlan)
      } else {
        this.$store.state.payer.currentPayer.paymentPlans.pop()
        this.$store.state.payer.currentPayer.paymentPlans.push(selectedPlan)
      }
      this.selectedPaymentPlan = this.$store.state.payer.currentPayer.paymentPlans[0]
      this.$store.commit('setPlanCompleted', !this.$v.$invalid && this.planValid)
      this.$nextTick(() => {
        if (this.$refs.planControl) {
        this.$refs.planControl.initialize()
        }
      })
      this.$store.commit('setHasPlan', true)
      this.$store.commit('setPlanDone', !this.$v.$invalid)
      this._self.$emit('can-continue', { value: !this.$v.$invalid && this.planValid })
    },
    setValid (val) {
      this.planValid = val
      this.$store.commit('setPlanCompleted', !this.$v.$invalid && this.planValid)
    },
    setValidity (e) {
      this.planValid = e.valid
      this.$store.commit('setPlanDone', e.valid)
    }
  },
  watch: {
    $v: {
      handler (val) {
        this.$store.commit('setPlanDone', !this.$v.$invalid)
        this._self.$emit('can-continue', { value: !this.$v.$invalid && this.planValid })
        this.$store.commit('setPlanCompleted', !this.$v.$invalid && this.planValid)
      },
      deep: true
    },
    planIsValid () {
      this.$emit('can-continue', { value: this.planIsValid })
    },
    clickedNext (val) {
      this.$store.commit('setPlanCompleted', !this.$v.$invalid && this.planValid)
      this.$emit('can-continue', { value: this.planIsValid })
    },
    currentStep (val) {
      this.$emit('can-continue', { value: this.planIsValid })
    },
    selectedPaymentPlan: {
      handler (val) {
        this.$store.state.payer.currentPayer.paymentPlans[0] = val
        this.planValid = false
        if (!val.variable && val.recurrenceEndType < 4) {
          this.planValid = parseInt(val.regularAmount) > 0
        } else {
          this.planValid = true
        }
        if (val.recurrenceEndType < 4 && val.firstPaymentDate < this.minDate) {
          this.planValid = false
        }
        this.$store.commit('setPlanCompleted', !this.$v.$invalid && this.planValid)
      },
      deep: true
    }
  },
  validations: {
    selectedPlanName: { required }
  },
  async mounted () {
    this.$emit('can-continue', { value: this.planIsValid })
    this.planNames = this.checkPlanNames(this.$store.state.payer.planNames)
  },
  components: {
    paymentPlanInput: Components.Payer.PaymentPlan,
    pCheck: PrettyCheck
  },
  props: {
    currentStep: Object
  },
  computed: {
    planIsValid () {
      return this.$store.state.payer.currentPayer.planValid || this.$store.state.payer.currentPayer.paymentPlans.length === 0
    },
    advancedNoticeWithMedia () {
      // as it is the collection date being set, it needs at least one day:
      return this.getAdvancedNoticeWithMedia(this.groupConfig.advanceNoticeInDays, this.groupConfig.defaultPayerMessageType)
    },
    addAnother: {
      get () { return this.$store.state.payer.addAnother },
      set (value) { this.$store.commit('updateAddAnother', value) }
    },
    allowMultiplePlans () {
      var groupConfig = this.$store.getters.collectionsGroupConfig
      return groupConfig.allowMultiplePlans
    },
    minDate () {
      let minDate = this.groupConfig.defaultPayerMessageType === 1 ? this.$store.getters.collectionsMinDateLetter : this.$store.getters.collectionsMinDate
      return minDate
    },
    activationDate () {
      return (this.$store.state.payer.currentPayer !== undefined && this.$store.state.payer.currentPayer !== null) ?  this.$store.state.payer.currentPayer.activationDate : new Date()
    },
    requiresMigration : {
      get () {
        return this.$store.state.payer.currentPayer.requiresMigration
      },
      set (value) {
        this.$store.state.payer.currentPayer.requiresMigration = value
      }
    },
    groupConfig() {
      return this.$store.getters.collectionsGroupConfig
    }
  }
}
</script>
