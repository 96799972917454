<template>
  <select multiple="multiple" class="form-control ms-options-wrap"></select>
</template>

<script>

// Third Party
import _ from 'lodash'
import '@nobleclem/jquery-multiselect'

export default {
  name: 'MultiSelect',
  watch: {
    values () {
      window.$(this.$el).multiselect('loadOptions', this.values, true, true)
    }
  },
  props: {
    value: {},
    values: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      default () {
        return {
          search: true,
          selectAll: true
        }
      }
    }
  },
  mounted () {
    var that = this
    var realOptions = {
      ...this.options,
      maxHeight: 300,
      onOptionClick: (element, option) => {
        const thisOpt = window.$(option)
        const thisVal = thisOpt.val()
        const checked = thisOpt.prop('checked')

        if (checked) {
          that.value.push(thisVal)
        } else {
          that.value.splice(that.value.indexOf(thisVal), 1)
        }
        that.$emit('change')
      },
      onSelectAll: (element, selected) => {
        that.value.splice(0)
        that.value.push(..._.union(that.value, window.$(element).val()))
        that.$emit('change')
      }
    }
    window.$(this.$el).multiselect(realOptions)
  },
  methods: {
    reset () {
      window.$(this.$el).multiselect('reset')
    }
  }
}

</script>

<style lang="scss">
@import "~/node_modules/@nobleclem/jquery-multiselect/jquery.multiselect";
</style>
