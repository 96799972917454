<template>
  <b-modal
    id="settlementModal"
    ref="settlementModal"
    variant="primary"
    centered
    hide-header-close
    :title="`${type} Details For ${name}`"
    size="xl"
    @shown="load()"
    @hide="resetDetails()"
  >
    <div class="row">
      <div class="col-md-12">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          styleClass="vgt-table striped bordered"
          ref="table"
          @on-row-click="gotoDetails"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
        >
          <template slot="table-row" slot-scope="props">
            <span
              v-if="props.column.field !== 'name'"
              :class="tdClass(props.row, props.column.field)"
            >{{props.formattedRow[props.column.field]}}</span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
        </vue-good-table>
      </div>
    </div>

    <template slot="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        @click="hideModal"
        :disabled="isTableLoading"
      >Close</button>
    </template>
  </b-modal>
</template>
<script>

// Third Party
import axios from 'axios'

// Mixins
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

// Constants
import { billingEnum as billing } from '@/Assets/Constants/billing'

export default {
  name: 'settlementDetails',
  props: {
    params: {
      type: Object
    },
    model: {
      type: Object
    },
    loadUrl: {
      type: String
    },
    name: {
      type: String
    },
    type: {
      type: String,
      default: 'Settlement'
    }
  },
  mixins: [settlementMixin, tableFilterMixin],
  data () {
    return {
      columns: [
        {
          label: 'Client',
          field: 'clientName'
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter by type',
            filterDropdownItems: Object.values(billing).sort()
          }
        },
        {
          label: 'Customer Reference',
          field: 'customerReference',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Amount (£)',
          field: 'charge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Service Charges (£)',
          field: 'serviceCharge',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          hidden: this.type === 'Settlement'
        },
        {
          label: 'VAT (£)',
          field: 'vatAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          hidden: this.type === 'Settlement'
        }
      ],
      serverParams: {
        sort: [{ field: 'processingDate', type: 'desc' }]
      },
      rows: [],
      shouldLoad: false
    }
  },
  methods: {
    hideModal () {
      this.$refs.settlementModal.hide()
    },
    async load () {
      try {
        this.isTableLoading = true

        var response = await axios.get(this.loadUrl, {
          params: {
            ...this.buildGoodTableQuery(),
            ...this.params
          },
          showerror: true,
          errormessage: `${this.type} Details failed to load`
        })

        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch (e) {
        console.error(e)
      } finally {
        this.isTableLoading = false
      }
    },
    buildGoodTableQuery () {
      var columnFilters = { ...this.serverParams.columnFilters }
      Object.keys(columnFilters).forEach((key) => { columnFilters[key] = `*${columnFilters[key]}*` })
      const query = { ...columnFilters, ...this.model }
      query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }
      return query
    },

    resetDetails () {
      this.rows = []
      this.$refs.table.reset()
      this.serverParams = this.defaultParams
    },

    async gotoDetails (params) {
      var billingUsageId = params.row.id
      await this.$store.dispatch('setPaygateId', this.params.paygateid)
      this.$nextTick(() => {
        window.open(this.$router.resolve({ name: 'ChargeDetail', params: { id: billingUsageId, showBackButton: false } }).href, '_blank')
      })
    }
  }
}
</script>
