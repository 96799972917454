<template>
  <div>
    <b-row class="mt-2" hidden>
      <b-col sm="3" class="mt-2"
        ><label for="jobDetailRecord.bureauCustomerId">Id</label></b-col
      >
      <b-col sm="8">
        <b-form-input
          readonly
          type="text"
          v-model.trim="jobDetailRecord.bureauCustomerId"
        ></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-2"
        ><label for="jobDetailRecord.customerName">Customer Name</label></b-col
      >
      <b-col sm="8">
        <b-form-input
          readonly
          type="text"
          v-model.trim="jobDetailRecord.customerName"
        ></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-2"
        ><label for="jobDetailRecord.customerReference"
          >Customer Reference</label
        ></b-col
      >
      <b-col sm="8">
        <b-form-input
          readonly
          type="text"
          v-model.trim="jobDetailRecord.customerReference"
        ></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-2"
        ><label for="jobDetailRecord.paymentfile">Payment File</label></b-col
      >
      <b-col sm="8">
        <b-form-input
          ref="filename"
          type="text"
          @input="onFilenameChanged()"
          v-model.trim="$v.jobDetailRecord.filename.$model"
        ></b-form-input>
        <span class="small" v-if="jobDetailRecord.filename.length > 0"
          >Example: {{ parsedFilename }}</span
        >
        <span v-if="!filenameValid">
          <br />
          <span class="text-danger small">This filename is not valid</span>
        </span>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="9" offset-sm="3">
        <label
          ><a class="text-primary small" @click="addLookup('[CUSTOMERREF]')"
            >[CUSTOMERREF]</a
          >
          will be replace with Customer Reference</label
        >
      </b-col>
      <b-col sm="9" offset-sm="3">
        <label
          ><a class="text-primary small" @click="addLookup('[SUN]')">[SUN]</a>
          will be replace with customers Service Reference Number</label
        >
      </b-col>
      <b-col sm="9" offset-sm="3">
        <label
          ><a
            class="text-primary small"
            @click="addLookup('[DATETIME, DD-MM-YYYY]')"
            >[DATETIME, FORMAT]</a
          >
          will be replaced with the current formatted date/time</label
        >
      </b-col>
      <b-col sm="9" offset-sm="3">
        <label class="small">* and ? can be used as a wildcards</label>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-2"
        ><label for="jobDetailRecord.importSchema">Import Schema</label></b-col
      >
      <b-col sm="5"
        ><b-form-select
          v-model="jobDetailRecord.importSchema"
          :options="this.schemas"
        ></b-form-select
      ></b-col>
    </b-row>

    <b-row class="mt-2" v-if="jobDetailRecord.importSchema == 'Mapping'">
      <b-col sm="3" class="mt-2"
        ><label for="jobDetailRecord.importMapping">Mapping</label></b-col
      >
      <b-col sm="5"
        ><b-form-select
          v-model="jobDetailRecord.importMapping"
          :options="this.mappings"
        ></b-form-select
      ></b-col>
    </b-row>

    <hr />

    <b-row class="mt-3 mb-0">
      <b-col>
        <b-btn class="mr-2"
          variant="primary"
          :disabled="$v.$invalid || !filenameValid || isLoading"
          @click="onClose"
          >Close</b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Utility from '@/Assets/Mixins/Utility'
import { maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  async created () {
    this.schemas = this.loadSchemas()
    this.mappings = await this.loadMappings('')
  },
  computed: {
    paygateId () {
      return this.$store.state.common.paygateId
    },
    bureauJob () {
      return this.$store.state.bureauJob.bureauJob
    }
  },
  mixins: [Utility, loading],
  data () {
    return {
      selectedJobDetailRecord: {},
      mappings: [],
      parsedFilename: '',
      filenameValid: false
    }
  },
  props: ['jobDetailRecord'],
  methods: {
    onClose () {
      console.log('saving')
      console.log(this.selectedJobDetailRecord)
      this.$store.commit('setBureauJobDetailsReturnType', 'Ok')
      this.$emit('hideJobDetail')
    },
    onFilenameChanged () {
      console.info(`onFilenameChanged`)
      let filename = this.jobDetailRecord.filename
      // Customer reference
      filename = filename.replace(/\[CUSTOMERREF\]/g, this.jobDetailRecord.customerReference)
      // Service user number
      filename = filename.replace(/\[SUN\]/g, this.jobDetailRecord.bureauCustomer.serviceUserNumber)
      // Date/Time
      if (filename.includes('[DATETIME,')) {
        let start = filename.indexOf('[DATETIME,')
        let end = filename.indexOf(']', start + 10)
        let stringToReplace = filename.substr(start, end - start + 1)
        console.info(`string to replace is: ${stringToReplace}`)
        let format = filename.substr(start + 11, end - start - 11)
        console.info(`format is "${format}"`)
        let value = moment().format(format)
        filename = filename.replace(stringToReplace, value)
        console.info(`${value}`)
      }
      this.parsedFilename = filename
      this.filenameValid = this.validateFilename(filename)
      console.info(`Parsed filename is: ${filename}`)
    },
    addLookup (lookup) {
      this.jobDetailRecord.filename += lookup
      this.onFilenameChanged()
      this.$refs.filename.$el.focus()
    },
    validateFilename (filename) {
      // Check length
      if (!filename || filename.length > 255) {
        return false
      }

      // Check for ..
      if (filename.includes('..')) {
        return false
      }

      // Check for \\
      if (filename.includes('\\\\')) {
        return false
      }

      // Check for ports
      if (/^(con|prn|aux|nul|com[0-9]|lpt[0-9])$/i.test(filename)) {
        return false
      }

      return true
    }
  },
  validations: {
    jobDetailRecord: {
      filename: { maxLength: maxLength(255) }
    }
  }
}

</script>
