<template>
  <div>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-content">
            <div v-if="story.enabled" class="media pb-1 mb-3">
              <img
                :src="story.imageUrl"
                width="48px"
                height="48px"
                class="d-block ui-w-40 rounded-circle"
                alt
              />
              <div class="media-body ml-3">
                <div class="text-dark">{{story.title}}</div>
                <div
                  class="float-left text-muted small"
                >{{story.authorName}} - {{new Date(story.createdDate).toLocaleString()}}</div>
                <br />
                <div class="mt-2 ql-editor" v-html="story.body"></div>
                <div class="clearfix mt-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  data () {
    return {
      id: this.$route.params.id,
      story: {}
    }
  },
  async created () {
    if (this.id) {
      await this.getStory()
    }
  },
  methods: {
    async getStory () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}newsStory/${this.id}`, {
          showerror: true, errormessage: 'Failed to get news story'
        })
        this.story = response.data
      } catch { }
    }
  }
}
</script>
