<template>
  <b-modal
    id="bulk-date-editor"
    ref="bulkDateEditorModal"
    title="Bulk Date Editor"
    size="xl"
    hide-header-close
    @shown="load"
    :ok-only="true"
  >
    <div slot="modal-ok">Close</div>
    <div class="row form-group">
      <div class="col-md-8">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :isLoading.sync="isTableLoading"
          @on-search="onSearch"
          :rows="rows"
          :columns="columns"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'checkbox'">
              <p-check class="p-default p-curve" @change="rowClick(props.row)"></p-check>
            </span>
          </template>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
          </div>
        </vue-good-table>
      </div>
      <div class="row form-group col-md-4">
        <b-col sm="5" class="mt-2">
          <label for="paymentDate">Payment Date:</label>
        </b-col>

        <b-col sm="7">
          <vuejsDatepicker
            ref="paymentDate"
            name="paymentDate"
            id="paymentDate"
            v-model="paymentDate"
            format="dd/MM/yyyy"
            input-class="form-control"
            :bootstrap-styling="true"
            :disabledDates="disabledDates"
            v-on:selected="paymentDateChanged"
            @focusin.native="dateOnfocus"
            :monday-first="true"
          ></vuejsDatepicker>
        </b-col>
        <b-col sm="5" class="mt-2">
          <label for="processingDate">Processing Date:</label>
        </b-col>
        <b-col sm="7" class="mt-2">{{ processingDate }}</b-col>
        <b-col sm="12">
          <button
            :disabled="this.selectedFilenumbers.length === 0"
            id="applyDates"
            style="color: blue; padding: 0; border: none; background: none"
            title="Apply Dates"
            @click="applyDates"
          >{{buttonText}}</button>
        </b-col>
      </div>
    </div>
  </b-modal>
</template>

<script>
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import Utility from '@/Assets/Mixins/Utility'
import _ from 'lodash'

export default {
  mixins: [tableFilterMixin, Utility, bacsMixin],
  props: {
    submissionId: String
  },
  data: function () {
    return {
      processingDate: '',
      paymentDate: '',
      disabledDates: {},
      selectedFilenumbers: [],
      rows: [],
      columns: [
        {
          label: 'File No.',
          field: 'fileNumber'
        },
        {
          label: 'Filename',
          field: 'fileNameDisplay'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber'
        },
        {
          label: 'Processing Date',
          field: 'processingDateDisplay'
        },
        {
          label: 'Payment Date',
          field: 'paymentDateDisplay'
        },
        {
          label: '',
          field: 'checkbox',
          sortable: false
        }
      ],
      serverParams: {
        sort: [{ field: 'fileNumber', type: 'asc' }]
      }
    }
  },
  computed: {
    buttonText: function () {
      if (this.selectedFilenumbers.length === 1) {
        return 'Apply date to 1 block'
      } else if (this.selectedFilenumbers.length > 1) {
        return 'Apply date to ' + this.selectedFilenumbers.length + ' blocks'
      } else {
        return 'No blocks selected'
      }
    }
  },
  async created () {
    console.log('bulk date editor modal, ', this.submissionId)
    this.disabledDates = await this.setUpBACSDatePicker()
    this.paymentDate = await this.getNextPaymentDate()
    this.selectedFilenumbers = []
  },

  async mounted () {
    var dateElement = document.getElementById('paymentDate')
    dateElement.style.backgroundColor = 'white'
    var dateResponse = await axios.get(`${process.env.VUE_APP_BACS_API_URL}/bacs/processingDate`)
    console.log('date responses', dateResponse)
    this.processingDates = dateResponse.data
    this.paymentDate = new Date(this.processingDates.minPaymentDate)
    this.processingDate = this.processingDates.nextProcessingDate
    // Disable weekends in datepicker.
    this.disabledDates.days = [6, 0]
    // Disable any dates before the earliest possible payment date.
    this.disabledDates.to = this.paymentDate
    // Disable any dates more than 31 days into the future.
    var maxDate = new Date(this.processingDates.maxPaymentDate)
    this.disabledDates.from = maxDate
    // Disable any other non-processing days i.e bank holidays.
    if (this.processingDates.nonProcessingDates.length > 0) {
      var npds = []
      for (var i = 0; i < this.processingDates.nonProcessingDates.length; i++) {
        npds.push(new Date(this.processingDates.nonProcessingDates[i]))
      }
      this.disabledDates.dates = npds
    }
  },

  methods: {
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getBulkDateEditor`, { params: { ...this.buildGoodTableQuery(), submissionId: this.submissionId }, showerror: true, errormessage: 'Failed loading bulk date editor' })
        this.rows = response.data.data
      } catch { } finally {
        this.isTableLoading = false
        console.log('bulk date editor response', response)
        console.log('bulk date editor rows', this.rows)
      }
    },
    async paymentDateChanged (value) {
      var valasjson = value.toJSON()
      console.log('valasjson', valasjson)
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingdate/getProcessingDateFromPaymentDate?paymentDate=` + value.toJSON())
      console.log('paymentDateChangedProcessingDate', response.data)
      this.processingDate = response.data
    },
    rowClick (item) {
      console.log('rowClick item', item)
      var fileNumber = item.fileNumber
      if (!_.includes(this.selectedFilenumbers, fileNumber)) {
        this.selectedFilenumbers.push(item.fileNumber)
      } else {
        var i = 0
        var Arr = []
        for (i = 0; i < this.selectedFilenumbers.length; i++) {
          if (this.selectedFilenumbers[i] !== fileNumber) {
            Arr.push(this.selectedFilenumbers[i])
          }
        }
        this.selectedFilenumbers = Arr
      }
      console.log('current selected file numbers', this.selectedFilenumbers)
    },
    async applyDates () {
      var itemIds = []
      var i = 0
      for (i = 0; i < this.rows.length; i++) {
        if (_.includes(this.selectedFilenumbers, this.rows[i].fileNumber)) {
          itemIds.push(this.rows[i].itemId)
        }
      }
      var requestModel = {
        itemIds: itemIds,
        processingDate: this.processingDate,
        bureauSubmissionId: this.submissionId
      }
      console.log('payload for axios call', requestModel)
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/bulkdateeditorprocesschange', requestModel, { showload: true })
      console.log('change response', response)
      if (response.data.toastType === 2) {
        this.$toastr.s(response.data.toastMessage)
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
      await this.load()
      this.$emit('submit')
    }
  }

}

</script>
<style lang="scss">
.modal .modal-bulkdateeditor {
  max-width: 95%;
}
</style>
