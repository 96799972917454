import axios from 'axios'
import ConstantPaygateId from '@/Assets/Constants/paygateId'
import { vueInstance } from '@/main'
import auth from '@/Assets/Components/Authentication/auth'

// initial state
const state = {
  paygateId: null,
  customers: [],
  idToken: null,
  corvidUser: true,
  vueAppVersion: process.env.VUE_APP_VERSION,
  licences: null,
  unauthorized: false,
  clearDate: '2023-07-17'
}

// getters
const getters = {
  customersOrdered: state => {
    return state.customers.sort((a, b) => {
      var textA = a.name.toUpperCase()
      var textB = b.name.toUpperCase()
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
    })
  },
  customers: state => {
    return state.customers
  },
  selectedCustomer: state => {
    return state.paygateId
  },
  isLandZCustomer: state => {
    return state.paygateId === ConstantPaygateId.paygateId
  },
  vueAppVersion: state => {
    return parseInt(state.vueAppVersion)
  },
  licences: state => {
    return state.licences
  },
  unauthorized: state => {
    return state.unauthorized
  }
}

// actions
const actions = {
  async setPaygateId ({ commit, dispatch, state }, paygateId) {
    if (state.customers.some(c => c.paygateId === paygateId)) {
      commit('setPaygateId', paygateId)
      await dispatch('getCustomer', paygateId)
      await auth.isLoggedIn(vueInstance.$router.currentRoute, vueInstance.$router.currentRoute, () => { }, vueInstance)
    }
  },
  resetPaygateId ({ commit, dispatch, state }) {
    commit('resetPaygateId')
    dispatch('loadUser', 'force')
  },
  checkPaygateId ({ dispatch, state }) {
    if (state.paygateId !== null && state.paygateId !== undefined) {
      if (!state.customers.some((c) => c.paygateId === state.paygateId)) {
        dispatch('setPaygateId', this.state.common.customers[0].paygateId)
      }
    }
  },
  async loadCustomersList ({ commit, state, dispatch }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Customers`)
      await commit('addCustomersToList', response.data)
      await dispatch('checkPaygateId')
      if (state.paygateId === null) {
        if (response.data.some((c) => c.paygateId === process.env.VUE_APP_LANDZ_ID)) {
          dispatch('setPaygateId', process.env.VUE_APP_LANDZ_ID)
        } else {
          dispatch('setPaygateId', response.data[0].paygateId)
        }
      } else {
        dispatch('setPaygateId', state.paygateId)
      }
    } catch (error) {
      console.error(error)
    }
  },
  addCustomerToList ({ commit }, customer) {
    commit('addCustomerToList', customer)
  },
  removeCustomerFromList ({ commit }, paygateId) {
    commit('removeCustomerFromList', paygateId)
  },
  updateCustomerInList ({ commit }, customer) {
    commit('updateCustomerInList', customer)
  }
}

// mutations
const mutations = {
  setPaygateId (state, paygateId) {
    state.paygateId = paygateId
  },
  resetPaygateId (state) {
    if (state.customers.length > 1) {
      state.paygateId = process.env.VUE_APP_LANDZ_ID
    } else {
      state.paygateId = state.customers[0].paygateId
    }
  },
  addCustomersToList (state, customers) {
    state.customers = customers
  },
  addCustomerToList (state, customer) {
    state.customers.push(customer)
  },
  removeCustomerFromList (state, paygateId) {
    const index = state.customers.findIndex(x => x.paygateId === paygateId)
    if (typeof index !== 'undefined') {
      state.customers.splice(index, 1)
    }
  },
  updateCustomerInList (state, customer) {
    const index = state.customers.findIndex(x => x.paygateId === customer.paygateId)
    if (typeof index !== 'undefined') {
      state.customers[index].name = customer.name
    }
  },
  setLicence (state, licences) {
    state.licences = licences
  },
  setUnauthorized (state, unauthorized) {
    state.unauthorized = unauthorized
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
