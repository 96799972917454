<template>
  <div>
    <div id="payerSetupForm" class="mb-4 pb-4">
      <div class="row  mb-4 pb-4">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4 pb-4">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2><help-icon docPath="/paygate-collections/payers/managepayers/" />Move Customers<favourite-icon></favourite-icon></h2>
            </div>
              <div class="idb-block-content">

              <div class="groupSelector">
                <div class="row form-group">
                  <div class="col-md-2 required">
                    Select a Group
                  </div>
                  <div class="col-md-4">
                    <vue-select
                      v-model="selectedGroup"
                      :options="customerGroups"
                      @input="groupChanged"
                      value="id"
                      label="name"
                      :clearable="false"
                    ></vue-select>
                  </div>
                </div>
                <div class="row form-group mb-3">
                  <div class="col-md-3">
                    <div class="form-group">
                      <p-check
                        id="dateFilterCheck"
                        class="p-switch p-fill"
                        color="primary" @click.native="load()"
                        v-model="useDates"
                      >Filter by Creation Date</p-check>
                    </div>
                  </div>
                </div>
                <div class="row form-group mb-3" v-if="useDates">
                  <div class="col-md-2 required">
                    Earliest Date
                  </div>
                  <div class="col-md-4">
                    <div class="right-inner-addon">
                      <datepicker id="moveStartDatePicker"
                      v-model="start"  :disabledDates="{ from: new Date(end) }"
                      :format="formatDate" @input="load()" input-class="form-control datepicker"
                      /><i class="fa fa-calendar form-control-feedback"></i>
                    </div>
                  </div>
                </div>
                <div class="row form-group mb-3" v-if="useDates">
                  <div class="col-md-2 required">
                    Latest Date
                  </div>
                  <div class="col-md-4">
                    <div class="right-inner-addon">
                      <datepicker id="moveEndDatePicker"
                      v-model="end"
                      :format="formatDate" :disabledDates="{ to: new Date(start) }"
                      @input="load()" input-class="form-control datepicker"
                      /><i class="fa fa-calendar form-control-feedback"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <vue-good-table mode="remote"
                  ref="table"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  :columns="columns"
                  :rows="sourceRows"
                  :lineNumbers="true"
                  :totalRows="totalRecords"
                  :search-options="{
                    enabled: true
                    }"
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :rowStyleClass="rowToCss"
                  :isLoading.sync="isTableLoading"
                  styleClass="vgt-table striped bordered">
                  <template slot="loadingContent">
                    <h1>Loading...</h1>
                  </template>
                  <div slot="emptystate">
                      <div class="vgt-center-align vgt-text-disabled">
                        No Customers Available
                      </div>
                    </div>
                    <div slot="table-actions">
                      <button
                        @click.prevent="clearTableFilters"
                        class="btn btn-link"
                        v-b-popover.hover.top.d500="'Clear filters'"
                      >
                        <span class="fa-stack" style="font-size: 10px;">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span>
                      </button>
                      <b-button @click.prevent="load" class="" :disabled="isLoading" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'">
                        <i class="fa fa-sync pointer dimmedIcon"></i>
                      </b-button>
                    </div>
                     <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'status'">
                          <b-badge pill
                            :variant="getBadgeClass(props.row.status)"
                          >{{props.formattedRow[props.column.field]}}</b-badge>
                        </span>
                        <span v-else-if="props.column.field === 'buttons'">
                          <b-button @click="addSingleToStaging(props.row.ukPayerId)" variant="outline-secondary" v-b-popover.hover.top.d500="'Transfer customer to Staging.'"><i class="fa fa-calendar mr-2"></i>Transfer</b-button>
                        </span>
                        <span v-else>
                          {{props.formattedRow[props.column.field]}}
                        </span>
                     </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
            <div class="idb-block-footer">
              <b-btn id="addPayerButton" type="link" variant="primary" @click="addCurrentToStaging" :disabled="isLoading || isTableLoading" v-b-popover.hover.top.d500="allGroups ? 'Transfer customers matching current criteria.' : 'Transfer customers matching current criteria.'">
                <i class="fa fa-plus mr-2"></i>Add All
              </b-btn>
            </div>
          </div>
          <div class="idb-block mt-3" :style="showStaging ? 'display: block' : 'display: none'" > <!--  -->
            <div class="idb-block-title">
              <h2>Staging ({{totalStaged}} Customer{{totalStaged > 1 ? 's' : ''}})</h2>
            </div>
              <div class="idb-block-content">

              <div class="row form-group">
                <div class="col-md-12">
                  <staging-table ref="stagingTable" :sessionId="sessionId" @loadSource="load" :existingReferences="existingReferences" @stagedTotalChanged="stagedChanged"/>
                </div>
              </div>
            </div>
              <div class="idb-block-footer">
              <b-btn id="removePayerButton" type="link" variant="primary" @click="removeCurrentFromStaging" :disabled="isLoading" v-b-popover.hover.top.d500="'Remove Currently Staged Customers'">
                  <i class="fa fa-plus mr-2"></i>Remove Currently Staged Customers
                </b-btn>
              </div>
          </div>
          <div class="idb-block mt-3 mb-4"   :style="showStaging ? 'display: block' : 'display: none'" > <!-- -->
            <div class="idb-block-title">
              <h2>Transfer</h2>
            </div>
            <div class="idb-block-content">
            <div class="row form-group mb-3">
              <div class="col-md-3">
                <div class="form-group">
                  <p-check
                    id="MoveCheckCheck"
                    class="p-switch p-fill"
                    color="primary"
                    v-model="copyToCustomer"
                    @input="copyToCustomerChanged"
                  >Move To Client in System</p-check>
                </div>
              </div>
            </div>
              <div class="row form-group mb-3" v-if="!copyToCustomer">
                <div class="col-md-2 required">
                  To Whom
                </div>
                <div class="col-md-4">
                  <input type="text" v-model="newOwner" />
                </div>
              </div>
            <div class="row form-group mb-3" v-if="!copyToCustomer">
              <div class="col-md-2 required">
                New Service User Name
              </div>
              <div class="col-md-4">
                <input type="text" v-model="newServiceUserName" />
              </div>
            </div>
            <div class="row form-group mb-3">
              <div class="col-md-2 required">
                Migration Date
              </div>
              <div class="col-md-4">
                <div class="right-inner-addon">
                  <datepicker id="endPaymentDatePicker"
                  v-model="migrationDate"
                  :format="formatDate" input-class="form-control datepicker"
                  /><i class="fa fa-calendar form-control-feedback"></i>
                </div>
              </div>
            </div>
              <div class="row form-group" v-if="copyToCustomer">
                <div class="col-md-2 required">
                  Destination Client
                </div>
                <div class="col-md-4">
                  <vue-select
                    v-model="targetCustomerId"
                    :options="destinationCustomers"
                    :closeOnSelect="true"
                    label="name"
                    :reduce="customer => customer.paygateId"
                    :clearable="false"
                    :selectable="option => !option.hasOwnProperty('group')"
                    @input="changeTargetCustomer"
                  >
                    <template #option="{ group, name }">
                      <div v-if="group" class="group">{{ group }}</div>
                      {{ name }}
                    </template>
                  </vue-select>
                </div>
              </div>
              <div class="row form-group" v-if="copyToCustomer">
                  <div class="col-md-2 required">
                    Destination Group
                  </div>
                  <div class="col-md-4">
                    <vue-select
                      v-model="targetGroup"
                      value="id"
                      label="label"
                      :options="targetGroups"
                      :clearable="false"
                    ></vue-select>
                  </div>
              </div>
              <div class="row form-group mb-3" v-if="copyToCustomer">
                <div class="col-md-3">
                  <div class="form-group">
                    <p-check
                      id="previewCheck"
                      class="p-switch p-fill"
                      color="primary"
                      v-model="copyHistory"
                    >Move Existing Customer History/Alerts</p-check>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <p-check
                      id="previewCheck"
                      class="p-switch p-fill"
                      color="primary"
                      v-model="sendAuddis0N"
                    >Queue 0N Instructions</p-check>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <p-check
                      id="previewCheck"
                      class="p-switch p-fill"
                      color="primary"
                      v-model="sendAuddis0C"
                    >Queue 0C Instructions</p-check>
                  </div>
                </div>
              </div>
              <div class="row form-group mb-3" v-if="copyToCustomer">
                <div class="col-md-3">
                  <div class="form-group">
                    <p-check
                      id="previewCheck"
                      class="p-switch p-fill"
                      color="primary"
                      v-model="copyMessaging"
                    >Move Previous/Pending Messages</p-check>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <p-check
                      id="previewCheck"
                      class="p-switch p-fill"
                      color="primary"
                      v-model="copyCollections"
                    >Move Previous/Pending Collections</p-check>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <p-check
                      id="previewCheck"
                      class="p-switch p-fill"
                      color="primary"
                      v-model="sendFirstPaymentMessage"
                    >Queue 'Confirmation' Messages</p-check>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <p-check
                      id="previewCheck"
                      class="p-switch p-fill"
                      color="primary"
                      v-model="sendClosureMessage"
                    >Queue 'Customer Closed' Messages</p-check>
                  </div>
                </div>
              </div>
              <div class="row form-group mb-3"  v-if="copyToCustomer && false">
              <div class="col-md-3">
                <div class="form-group">
                  <p-check
                    id="previewCheck"
                    class="p-switch p-fill"
                    color="primary"
                    v-model="createBillingRecord"
                  >Create Billing Records For New Customers</p-check>
                </div>
              </div>
            </div>
            </div>
            <div class="idb-block-footer">
            <b-button variant="primary" @click="exportSession" v-if="!copyToCustomer"><i class="fa fa-save mr-2"></i>Export Current Session</b-button>
            <b-button variant="danger" @click="movePayers" v-if="!copyToCustomer" class="ml-2"><i class="fa fa-times mr-2"></i>Close Selected Customers</b-button>
            <b-btn id="copyToCustomerButton" v-if="copyToCustomer" :disabled="!ableToTransfer" type="link" variant="primary" @click="movePayers" >
              <i class="fa fa-exchange-alt  mr-2"></i>Transfer
            </b-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import EventBus from '@/Lib/eventBus'
import _ from 'lodash'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import axios from 'axios'
import customerTypes from '@/Assets/Constants/customerTypes'
import VueSelect from 'vue-select'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import PrettyCheck from 'pretty-checkbox-vue/check'
import papa from 'papaparse'
import Datepicker from 'vuejs-datepicker'
import { mapGetters } from 'vuex'
import StagingTable from '@/Views/Collections/Payer/Movement/StagingTable'

const statusToText = (x) => {
  switch (x) {
    case -1:
      return 'Any'
    case 0:
      return 'Operational'
    case 1:
      return 'Suspended'
    case 2:
      return 'Alert'
    case 3:
      return 'Closed'
    case 4:
      return 'Incomplete'
    case 5:
      return 'Awaiting Activation'
    case 6:
      return 'Plan Suspended'
    default:
      return 'Unknown'
  }
}

const statusToBadgeVariant = (x) => {
  switch (x) {
    case 3:
      return 'secondary'
    case 2:
    case 6:
      return 'warning'
    case 1:
      return 'danger'
    case 0:
      return 'success'
    case 4:
    case 5:
      return 'info'
    default:
      return 'dark'
  }
}

function customerTypeToNumber (type) {
  switch (type) {
    case customerTypes.general:
      return 0
    case customerTypes.connectedMerchant:
      return 1
    case customerTypes.master:
      return 2
    default:
      return 0
  }
}

export default {
  mixins: [tableFilterMixin, loading],
  components: {
    VueSelect,
    pCheck: PrettyCheck,
    Datepicker,
    StagingTable
  },
  name: 'MovePayers',
  async mounted () {
    if(this.selectedCustomer === '0a4703c6-d43f-4b12-90fa-eb6d172f3703'){
      this.$router.push({path: '/'})
    }
    await this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
    this.selectedGroup = this.customerGroups[0]
    if (this.$store.getters.rightsGroups !== null) {
      const initiallySelectedGroup = this.prepopulatedGroup ? this.prepopulatedGroup : '00000000-0000-0000-0000-000000000000'
      const table = this.$refs['table']
      const searchTerm = this.prepopulatedSearchTerm ? this.prepopulatedSearchTerm : ''
      table.globalSearchTerm = searchTerm
      table.$emit('on-search', {
        searchTerm: searchTerm
      })
    }

    this.destCustomers = _.cloneDeep(this.$store.getters.customersOrdered)
    this.destCustomers = this.destCustomers.filter(function (c) { return c.type !== 'Connected Merchant' }) // Remove Connected Merchants from the list.
    this.end = new Date()
    this.migrationDate = new Date()
    let tempStart = new Date()
    tempStart = tempStart.setDate(tempStart.getDate() - 7)
    this.start = tempStart
    this.updateTotal = true
    if (this.sessionId === null) {
      console.log('Create session')
      await this.createSession()
      console.log('Session created')
    } else {
      this.showStaging = this.$store.getters.currentStagedCount > 0
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    sessionId () {
      return this.$store.getters.movePayerSessionId
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.rightsGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.rightsGroups).map(x => {
          let obj = {}
          obj.groupId = x.groupId
          obj.name = x.name
          obj.colour = x.colour
          return obj
        })
      }
      if(ret.length > 1){
        ret.unshift({
          'description': '', 'groupId': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'name': 'All Customers', 'paygateId': this.paygateId, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      }
      return ret
    },
    targetGroups () {
      var ret = []
      if (this.targetGroupsTemp !== null) {
        ret = _.cloneDeep(this.targetGroupsTemp)
      }
      ret.unshift({
        'description': '', 'id': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'label': 'No Group', 'paygateId': this.paygateId, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      return ret
    },
    sourceGroups () {
      var ret = []
      if (this.sourceGroupsTemp !== null) {
        ret = _.cloneDeep(this.sourceGroupsTemp)
      }
      ret.unshift({
        'description': '', 'id': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'label': 'All Customers', 'paygateId': this.targetCustomerId, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      return ret
    },
    inactivePayers () {
      return this.$store.state.payer.inactivePayers
    },
    group: {
      get () {
        return this.$store.state.payer.currentPayer.groupId
      },
      set (value) {
        if (value) {
          this.$store.commit('updateGroup', value)
        }
      }
    },
    allGroups () {
      return !this.group || this.group === undefined || this.group === null || this.group === '00000000-0000-0000-0000-000000000000'
    },
    customers () {
      var customers = this.$store.getters.customersOrdered

      customers = customers.sort((a, b) => {
        var typeA = customerTypeToNumber(a.type)
        var typeB = customerTypeToNumber(b.type)
        return (typeA > typeB) ? -1 : (typeA < typeB) ? 1 : 0
      })

      var result = []
      customers.forEach((c, i) => {
        if (i === 0) {
          result.push({ group: `${c.type} Customers`, name: '' })
        }
        if (i > 0) {
          var previousC = customers[i - 1]
          if (c.type !== previousC.type) {
            result.push({ group: `${c.type} Customers`, name: '' })
          }
        }
        result.push(c)
      })

      return result
    },
    destinationCustomers () {
      var customers = this.destCustomers

      customers = customers.sort((a, b) => {
        var typeA = customerTypeToNumber(a.type)
        var typeB = customerTypeToNumber(b.type)
        return (typeA > typeB) ? -1 : (typeA < typeB) ? 1 : 0
      })

      var result = []
      customers.forEach((c, i) => {
        if (i === 0) {
          result.push({ group: `${c.type} Customers`, name: '' })
        }
        if (i > 0) {
          var previousC = customers[i - 1]
          if (c.type !== previousC.type) {
            result.push({ group: `${c.type} Customers`, name: '' })
          }
        }
        result.push(c)
      })

      return result
    },
    startDateOptions () {
      return { ...this.dateOptions, maxDate: this.end }
    },
    endDateOptions () {
      return { ...this.dateOptions, minDate: this.start }
    },
    ableToTransfer () {
      return this.targetGroup !== undefined && this.targetGroup !== null && this.targetGroup.id !== '00000000-0000-0000-0000-000000000000' && ((this.copyToCustomer && this.targetCustomerId !== null && this.targetCustomerId  !== '00000000-0000-0000-0000-000000000000') || !this.copyToCustomer) //this.copyToCustomer ? this.targetCustomerId : '00000000-0000-0000-0000-000000000000'
    }
  },
  props: {
    prepopulatedGroup: String,
    prepopulatedSearchTerm: String,
    prepopulatedStatus: String
  },
  data () {
    return {
      statusFilter: parseInt(this.prepopulatedStatus, 10) ? parseInt(this.prepopulatedStatus, 10) : -1, // if fails to parse to int w/ radix 10 then default to value '3'
      statuses: [-1, 0, 1, 2, 3, 4, 5].map(i => ({ 'name': statusToText(i), 'value': i })), // Server side enum values
      selectedUkPayer: {},
      groupValid: false,
      ukPayerSelected: false,
      currentCustomerId: null,
      targetCustomerId: null,
      columns: [
        {
          label: 'id',
          field: 'ukPayerId',
          hidden: true
        },
        {
          label: 'Reference',
          field: 'reference'
        },
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Created',
          field: 'creationDate',
          formatFn: this.formatDate
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
          tdClass: 'text-center'
        }
      ],
      sourceRows: [],
      serverParams: {
        sort: [{ field: 'reference', type: 'asc' }],
      },
      toTransfer: [],
      stagedTotal: 0,
      sourceGroupsTemp: [],
      targetGroupsTemp: [],
      selectedGroup: null,
      targetGroup: null,
      copyHistory: false,
      sendAuddis0N: false,
      sendAuddis0C: false,
      copyMessaging: false,
      copyCollections:false,
      sendFirstPaymentMessage: false,
      sendClosureMessage: false,
      copyToCustomer: true,
      createBillingRecord: false,
      dateOptions: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        stepping: 30,
        sideBySide: true,
      },
      end: new Date(),
      start: new Date(),
      useDates: false,
      migrationDate: null,
      newServiceUserName: '',
      newOwner: '',
      destCustomers: [],
      stageFiltered: false,
      showStaging: false,
      totalStaged: 0,
      existingReferences: []
    }
  },
  methods: {
    copyToCustomerChanged () {
      if(this.copyToCustomer === false) {
        this.createBillingRecord = false
        this.copyMessaging = false
        this.copyCollections = false
      }
    },
    async createSession () {
      try {
        let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payermovement/createsession`)
        this.$store.commit('setMovePayerSessionId', response.data)
        await this.load()
      }
      catch (e) {
        console.log(e)
        this.$toastr.e('Could not create session')
      }
    },
    formatValidationBool (row, col) {
      if (['payerDetailsValidated', 'bankAccountValidated', 'paymentPlansValidated'].indexOf(col.field) > -1) {
        return row[col.field] ? 'Complete' : 'More details required...'
      } else {
        return row[col.field]
      }
    },
    rowToCss (row) {
      if (row.isNew) {
        return 'new-item'
      } else {
        return ''
      }
    },
    addNewPayer () {
      this.$router.push({ name: 'createPayer', params: { groupid: this.group } })
    },
    confirmActivation (ukPayerId) {
      this.$store.dispatch('confirmActivation', { ukPayerId })
    },
    groupChanged () {
      this.updateParams({ page: 1 })
    },
    load: _.debounce(async function () {
      if (this.sessionId !== null && this.sessionId !== '00000000-0000-0000-0000-000000000000') {
        this.isTableLoading = true
        let group = '00000000-0000-0000-0000-000000000000'
        if (this.selectedGroup && this.selectedGroup !== undefined && this.selectedGroup.groupId !== 0) {
          group = this.selectedGroup.groupId
        }
        // await this.$store.dispatch('getGroupConfigurationFromGroupId', { id: this.group })
        var query = this.buildGoodTableQuery()
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payermovement/${this.sessionId}`, {
          params: {
            ...query,
            groupId: group,
            customerId: this.paygateId,
            paygateid: this.paygateId,
            startDate: new Date(this.start),
            endDate: new Date(this.end),
            useDates: this.useDates
          }, showload: true
        })
        if (response) {
          this.sourceRows = response.data.data
          this.totalRecords = response.data.count
        }
        this.isTableLoading = false
      }
    }, 300),
    loadStaged(){
      this.$refs.stagingTable.load()
    },
    async stagedChanged(total) {
      this.$store.commit('setCurrentStagedCount', total.total)
      this.stagedTotal = total.total
      this.totalStaged = total.unfiltered
      if (total.stageCleared === true) {
        this.showStaging = false
      }
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onRowClick (event) {
      // forces the table to do pointer cursor on row etc
    },
    onCellClick (event) {
      if (event.column.field !== 'buttons') {
        this.$store.dispatch('blankPayer')
        this.$router.push({ name: 'editPayer', params: { ukPayerId: event.row.ukPayerId } })
      }
    },
    dirtyGroup () {
      this.$v.group.$touch()
      this.groupValid = !this.$v.group.$invalid
    },
    goToSchedule (payerId, planId) {
      this.$router.push({ name: 'PayerSchedule', params: { id: payerId, planId: planId } })
    },
    getBadgeClass (value) {
      return statusToBadgeVariant(value)
    },
    toStaging (row) {
      this.toStaging.push(row)
    },
    addCurrentToStaging: _.debounce(async function() {
      this.isTableLoading = true
      var query = this.buildGoodTableQuery()
        var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payermovement/${this.sessionId}`, {}, { params: { ...this.buildGoodTableQuery(), groupId: this.selectedGroup.groupId, customerId: this.paygateId, paygateid: this.paygateId, startDate: this.start, endDate: this.end, useDates: this.useDates }, showload: true })
        if (response.status === 200) {
          this.$toastr.s('Current search records added to staging.')
          await this.loadStaged()
          await this.load()
          this.showStaging = true
        } else {
          this.$toastr.e('Something went wrong adding records to staging.')
        }
    }, 300),
    async removeCurrentFromStaging () {
      this.$refs.stagingTable.removeCurrentFromStaging()
    },
    addSingleToStaging: _.debounce(async function (ukPayerId) {
        this.isTableLoading = true
        var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payermovement/${this.sessionId}/${ukPayerId}`, { params: { }, showload: true })
        if (response) {
          this.$toastr.s('Record added to staging.')
          await this.load()
          await this.loadStaged()
          this.showStaging = true
        }
    }, 300),
    async removeSingleFromStaging (row) {
        this.isTableLoading = true
        var response = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}payermovement/${this.sessionId}/${row.ukPayerId}`, { params: { }, showload: true })
        if (response) {
          this.$toastr.s('Record removed from staging.')
          await this.load()
          await this.loadStaged()
        }
    },
    async sourceCustomerChanged () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}/groups/dropdown/${this.currentCustomerId}/2/`, { params: { paygateid: this.currentCustomerId }, showload: true })
      this.sourceGroupsTemp = response.data
      await this.load()
    },
    async changeTargetCustomer () {
      // get the groups
      const ukddmsEnumType = 2
      var url = process.env.VUE_APP_PLATFORM_API_URL + 'groups/dropdown/' + this.targetCustomerId + '/' + ukddmsEnumType
      var response = await axios.get(url, { params: { rights: [] } })

      this.targetGroupsTemp = response.data
      this.targetGroup = this.targetGroups[0]
    },
    async exportSession () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payermovement/${this.sessionId}/export`)
      var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        let filename = `customer-payer-export.csv`
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = filename
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
    },
    async movePayers () {
      var result = null
      try {
        result = await swal.fire({
          icon: 'warning',
          title: 'Move Customers',
          text: this.copyToCustomer ? `This will transfer all customers to the target group.` : `This will remove selected customers from the system, sending 0C messages to BACS.`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel',
        })
      } catch (err) {
        result = false
      }
      if (result.isConfirmed) {
        try {
          let targetCustName = ''
          for(var i = 0; i < this.customers.length; i ++){
            if (this.customers[i].id === this.targetCustomerId) {
              targetCustName = this.customers[i].name
            }
          }
          let request = {
            copyHistory: this.copyHistory,
            sendAuddis0N: this.sendAuddis0N,
            sendAuddis0C: this.sendAuddis0C,
            copyMessaging: this.copyMessaging,
            copyCollections: this.copyCollections,
            sendFirstPaymentMessage: this.sendFirstPaymentMessage,
            sendClosureMessage: this.sendClosureMessage,
            destinationGroupId: this.copyToCustomer ? this.targetGroup.id : '00000000-0000-0000-0000-000000000000',
            destinationCustomerId: this.copyToCustomer ? this.targetCustomerId : '00000000-0000-0000-0000-000000000000',
            copyToCustomer: this.copyToCustomer,
            migrationDate: this.migrationDate,
            newServiceUserName: this.copyToCustomer ? targetCustName : this.newServiceUserName,
            createBillingRecord: this.createBillingRecord,
            toWhom: this.newOwner
          }
          var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payermovement/${this.sessionId}/transfer`,
          request
          ,
          { showload: true })
          if(response && !response.data.referenceExists) {
            this.existingReferences = []
            this.$toastr.s('Customers successfully transferred')
            this.load()
            this.loadStaged()
            this.showStaging = false
          } else {
            if (response.data.references) {
              this.existingReferences = response.data.references
            }
            this.$toastr.e(response.data.message)
          }
        } catch (e) {
          console.log(e)
          this.$toastr.e('Failed to move Customers')
        }
      } else {
        console.log('NO')
      }

    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    }
  },
  validations: {
    group: { required }
  },
  watch: {
    selectedCustomer () {
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      this.selectedGroup = this.customerGroups[0]
      if(this.selectedCustomer === '0a4703c6-d43f-4b12-90fa-eb6d172f3703'){
        this.$router.push({path: '/'})
      }
      this.load()
    }
  }
}
</script>

<style>
@keyframes yellowfade {
    from { background: yellow }
    to { background: transparent; }
}

.new-item {
    animation-name: yellowfade;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
}

.groupSelector {
  margin-top: 1em;
  margin-left: 1em;
}
.deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}

.dark-mode * .vs__dropdown-option--disabled {
  background-color: #303030;
}
</style>
