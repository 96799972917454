import axios from 'axios'
// default state
const state = {
  availableTemplates: [],
  groupBankAccounts: [],
  planTemplateTree: [],
  bankAccounts: [],
  defaultPlan: {}
}

// mutators
const mutations = {
  setAvailableTemplates (state, value) {
    state.availableTemplates = value
  },
  setGroupBankAccounts (state, value) {
    state.groupBankAccounts = value
  },
  setPlanTemplateTree (state, value) {
    state.planTemplateTree = value
  },
  setAvailableBankAccounts (state, value) {
    state.bankAccounts = value
  },
  setDefaultPlan (state, value) {
    state.defaultPlan = value
  },
  addPlanToTemplateTree (state, payload) {
    if (state.planTemplateTree[payload.groupId] === undefined || state.planTemplateTree[payload.groupId] === null) {
      state.planTemplateTree[payload.groupId] = []
    }
    state.planTemplateTree[payload.groupId].unshift(payload.template)
  }
}

// getters
const getters = {
  availableTemplates: (state) => {
    return state.availableTemplates
  },
  groupBankAccounts: (state) => {
    return state.groupBankAccounts
  },
  planTemplateTree: (state) => {
    return state.planTemplateTree
  },
  planTemplatesFlat: (state) => {
    let flat = []
    for(var group in state.planTemplateTree) {
      for(var template in state.planTemplateTree[group]){
        flat.push({ name: state.planTemplateTree[group][template].name.toUpperCase(), paymentPlanTemplateId: state.planTemplateTree[group][template].paymentPlanTemplateId })
      }
    }
    return flat
  },
  availableBankAccounts: (state) => {
    return state.bankAccounts
  },
  defaultPlan: (state) => {
    return state.defaultPlan
  }
}

// actions
const actions = {
  updateAvailablePlanTemplates: async ({ commit }, groupId) => {
    var result = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/${groupId}`, { showload: true })
    commit('setAvailableTemplates', result.data)
  },
  getGroupBankAccounts: async ({ commit }, groupId) => {
    var result = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/BankAccounts`)
    commit('setGroupBankAccounts', result.data.map(bankAccount => {
      return { value: bankAccount.bankAccountId, text: bankAccount.reference }
    }))
  },
  getAvailableBankAccounts: async ({ commit }) => {
    try {
      let response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/BankAccounts`)
      let bankAccounts = response.data.map(bankAccount => {
        return { value: bankAccount.bankAccountId, text: bankAccount.reference }
      })
      commit('setAvailableBankAccounts', bankAccounts)
    } catch {}
  },
  buildPlanTemplateTree: async ({ commit }) => {
    var result = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/tree`, { showload: true })
    // when data is retrieved, make sure parent template parameters are applied to the child plan templates.
    for(var key in result.data.tree) {
      for(var i =0; i < result.data.tree[key].length; i++) {
        result.data.tree[key][i].plan.allowDateChoice = result.data.tree[key][i].allowDateChoice
        result.data.tree[key][i].plan.dateRangeAllowed = result.data.tree[key][i].dateRangeAllowed
        result.data.tree[key][i].plan.overrideGroupReference = result.data.tree[key][i].overrideGroupReference
      }
    }
    commit('setPlanTemplateTree', result.data.tree)
    commit('setDefaultPlan', result.data.defaultPlan)
  }
}

export default { state, getters, actions, mutations }
