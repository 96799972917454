<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>About: Direct Debit Manager</h2>
      </div>
      <div class="idb-block-content">
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Version</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <div style="margin-right:5px">1.1.0 beta.</div>
              <div style="margin-right:5px" class="mt-1">Build: 07022024-1</div>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="isSystemUser">
          <label for="description" class="col-sm-2 text-right col-form-label">Commit Hash</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.commit}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="isSystemUser">
          <label for="description" class="col-sm-2 text-right col-form-label">Build Date</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.buildDate | formatDate('DD/MM/YYYY HH:mm')}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Client Code</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.customerCode}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Client Name</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.customerName}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Id</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.customerId}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="isSystemUser">
          <label for="description" class="col-sm-2 text-right col-form-label">Instance</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.instance}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="isSystemUser">
          <label for="description" class="col-sm-2 text-right col-form-label">Server Time</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.time | formatDate('DD/MM/YYYY HH:mm')}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AboutInfo from '@/Components/About/AboutInfo'
import roles from '@/Assets/Constants/roles'

export default {
  name: 'about',
  computed: {
    isSystemUser () { return this.$store.getters.isInRole(roles.LZAdmin, roles.DevOps, roles.Qa, roles.Developer) }
  },
  data () {
    return {
      about: {
        customerId: null,
        instance: null,
        time: null
      }
    }
  },
  async created () {
    var aboutResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}about`)
    this.about = aboutResponse.data
    this.about = { ...AboutInfo, ...this.about }
  }
}
</script>

<style>
</style>
