<template>
  <div class="pu">
    <folderBrowser v-if="showFolderBrowser" @close="showFolderBrowser = false; closeSourcePopup()"></folderBrowser>
    <fileBrowser v-if="showModalFileBrowser" @close="showModalFileBrowser = false; closePopup()"></fileBrowser>
    <fileBrowser v-if="showRuleFileBrowser" @close="showRuleFileBrowser = false; closeRulePopup()"></fileBrowser>
    <h3>
      File Splitter
      <span class="pull-right">
        <a
          :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/fileoperations/filesplitter/`"
          target="_blank"
        >
          <i class="far fa-question-circle"></i>
        </a>
      </span>
    </h3>
    <hr />
    <div class="row">
      <!-- <div class="col-md-2 col-sm-12 ">
      <label class="required">Group</label>
    </div>
    <div class="col-md-4 col-sm-12">
      <group-select v-model="selectedNode.props.s1.value" :groups="groupOptions" :class="{invalid: $v.selectedNode.props.s1.value.$error}"></group-select>
      </div>-->
      <div class="col-md-2 col-sm-12">
        <label
          class="required"
          title="dataType"
          v-b-popover.hover.top.d500="'Sets the data type used for auto-mapping.'"
        >Importer</label>
      </div>
      <div class="col-md-10 col-sm-12">
        <select v-model="selectedNode.props.s2.value" class="form-control">
          <option
            v-for="option in importerOptions"
            :key="option.index"
            :value="option._id"
          >{{ option.title }}</option>
        </select>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-2">
        <label class="required">Source Filename</label>
      </div>
      <div class="col-md-10">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            :class="{invalid: $v.selectedNode.props.s4.value.$error}"
            @input="$v.selectedNode.props.s4.value.$touch()"
            v-model="selectedNode.props.s4.value"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              @click.prevent="btnFileBrowser"
              type="button"
            >...</button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-2">
        <label class="required">Source Folder</label>
      </div>
      <div class="col-md-10">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            :class="{invalid: $v.selectedNode.props.s3.value.$error}"
            @input="$v.selectedNode.props.s3.value.$touch()"
            v-model="selectedNode.props.s3.value"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              @click.prevent="btnPathBrowser"
              type="button"
            >...</button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="idb-block">
      <div class="idb-block-title">Output Files</div>
      <div class="idb-block-content">
        <div class="form-group">
          <div class="row">
            <div class="col-md-2">File Number</div>
            <div class="col-md-9">
              <strong>Path</strong>
            </div>
          </div>
          <hr />
          <div class="row mb-4" v-for="(file, index) in filesCollection" :key="file.index">
            <div class="col-md-2">{{index + 1}}</div>
            <div class="col-md-9">
              <div class="input-group">
                <input type="text" class="form-control" v-model="file.filePath" />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    @click.prevent="btnRuleFileBrowser(rule, index)"
                    type="button"
                  >...</button>
                </div>
              </div>
            </div>
            <div class="col-md-1">
              <b-button
                class="float-right"
                variant="outline-danger"
                @click.prevent="btnRemoveFile(index)"
              >x</b-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-button class="float-right" variant="outline-primary" @click.prevent="btnAddFile">Add</b-button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="idb-block">
      <div class="idb-block-title">Rules</div>
      <div class="idb-block-content">
        <div v-for="(rule, index) in rulesCollection" :key="rule.index">
          <div class="row">
            <div class="col-md-3">
              <strong>Rule: {{index + 1}}</strong>
              <i v-if="!rule.isValid" class="fas fa-exclamation-triangle ml-2"></i>
            </div>
            <div class="col-md-9">
              <b-button
                class="float-right"
                variant="outline-danger"
                @click.prevent="btnRemoveRule(index)"
              >x</b-button>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-1">Item</div>
            <div class="col-md-5">
              <div v-if="selectedDataType === 'bacsPayments'" class>
                <v-select
                  v-model="rule.item"
                  :clearable="false"
                  @input="selectedDataTypeChanged"
                  :options="bacsDataTypes"
                ></v-select>
              </div>
              <div v-else-if="selectedDataType === 'bacsBureauCustomers'" class>
                <v-select
                  v-model="rule.type"
                  :clearable="false"
                  @input="selectedDataTypeChanged"
                  :options="bureauCustomerDataTypes"
                ></v-select>
              </div>
            </div>
            <div class="col-md-1">Condition</div>
            <div class="col-md-2">
              <v-select
                v-model="rule.condition"
                @input="selectedConditionChanged"
                :clearable="false"
                :options="conditions"
              ></v-select>
            </div>
            <div class="col-md-1">Value</div>
            <div class="col-md-2">
              <input type="text" @change="valueChanged" class="form-control" v-model="rule.value" />
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-3 offset-md-2">If true, use output file</div>
            <div class="col-md-2">
              <input
                type="number"
                class="form-control"
                @change="trueChangeHandler(rule)"
                v-model="rule.trueFile"
              />
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-3 offset-md-2">If false, use output file</div>
            <div class="col-md-2">
              <input
                type="number"
                class="form-control"
                @change="falseChangeHandler(rule)"
                v-model="rule.falseFile"
              />
            </div>
          </div>
          <hr />
        </div>
        <br />
        <div class="row">
          <div class="col-md-12">
            <b-button class="float-right" variant="outline-primary" @click.prevent="btnAddRule">Add</b-button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <hr />
    <div class="pull-right">
      <b-button variant="danger" class="btnPad" @click.prevent="btnCancel">Cancel</b-button>
      <b-button class variant="success" :disabled="!areAllRulesValid" @click.prevent="btnSave">OK</b-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
// import { all } from 'q'
export default {
  components: {
    'v-select': vSelect,
    folderBrowser: folderBrowser,
    fileBrowser: fileBrowser
  },
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s2: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s3: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s4: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        }
      }
    }
  },
  computed: {
    areAllRulesValid: function () {
      // Check rule validity
      let allValid = true
      for (const rule of this.rulesCollection) {
        console.log(rule)
        if (!rule.isValid) allValid = false
      }
      return allValid
    }
  },
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      selectedFileIndex: 0,
      showFolderBrowser: false,
      showModalFileBrowser: false,
      showRuleFileBrowser: false,
      // groupOptions: [],
      importerOptions: [],
      mappingOptions: [],
      selectedNode: {},
      rollbackState: '',
      rulesCollection: [],
      filesCollection: [],
      selectedDataType: 'bacsPayments',
      conditions: [
        {
          label: '=',
          value: 'equalTo',
          requiresValue: true,
          supportedTypes: ['string', 'number', 'date']
        },
        {
          label: '!=',
          value: 'notEqualTo',
          requiresValue: true,
          supportedTypes: ['string', 'number', 'date']
        },
        {
          label: '<',
          value: 'LessThan',
          requiresValue: true,
          supportedTypes: ['number', 'date']
        },
        {
          label: '<=',
          value: 'lessThanOrEqualTo',
          requiresValue: true,
          supportedTypes: ['number', 'date']
        },
        {
          label: '>',
          value: 'moreThan',
          requiresValue: true,
          supportedTypes: ['number', 'date']
        },
        {
          label: '>=',
          value: 'moreThanOrEqualTo',
          requiresValue: true,
          supportedTypes: ['number', 'date']
        },
        {
          label: 'Starts with',
          value: 'startsWith',
          requiresValue: true,
          supportedTypes: ['string']
        },
        {
          label: 'Ends with',
          value: 'endsWith',
          requiresValue: true,
          supportedTypes: ['string']
        },
        {
          label: 'Contains',
          value: 'contains',
          requiresValue: true,
          supportedTypes: ['string']
        },
        {
          label: 'Matches',
          value: 'matches',
          requiresValue: true,
          supportedTypes: ['string', 'number', 'date']
        },
        {
          label: 'Is Today',
          value: 'isToday',
          requiresValue: false,
          supportedTypes: ['date']
        },
        {
          label: 'Is in past',
          value: 'isInPast',
          requiresValue: false,
          supportedTypes: ['date']
        },
        {
          label: 'Is in future',
          value: 'isInFuture',
          requiresValue: false,
          supportedTypes: ['date']
        }
      ],
      bacsDataTypes: [
        {
          label: 'Third-party Sortcode',
          value: 'thirdPartySortcode',
          type: 'string'
        },
        {
          label: 'Third-party Account Number',
          value: 'thirdPartyAccountNumber',
          type: 'string'
        },
        {
          label: 'Third-party Account Type',
          value: 'thirdPartyAccountType',
          type: 'string'
        },
        {
          label: 'Third-party Account Name',
          value: 'thirdPartyAccountName',
          type: 'string'
        },
        {
          label: 'Originating Sortcode',
          value: 'originatingSortcode',
          type: 'string'
        },
        {
          label: 'Originating Account Number',
          value: 'originatingAccountNumber',
          type: 'string'
        },
        {
          label: 'Originating Account Name',
          value: 'originatingAccountName',
          type: 'string'
        },
        {
          label: 'Service User Reference',
          value: 'Service User Reference',
          type: 'string'
        },
        {
          label: 'Transaction Code',
          value: 'transactionCode',
          type: 'string'
        },
        {
          label: 'Amount',
          value: 'amount',
          type: 'number'
        },
        {
          label: 'Date',
          value: 'processingdate',
          type: 'date'
        },
        {
          label: 'RTI Reference',
          value: 'rtiReference',
          type: 'string'
        },
        {
          label: 'Service User Number',
          value: 'serviceUserNumber',
          type: 'string'
        },
        {
          label: 'Numeric Reference',
          value: 'numericReference',
          type: 'number'
        },
        {
          label: 'File Number',
          value: 'fileNumber',
          type: 'number'
        }
      ],
      bureauCustomerDataTypes: [
        {
          label: 'Company Name',
          value: 'companyName',
          type: 'string'
        },
        {
          label: 'Contact First Name',
          value: 'contactFirstName',
          type: 'string'
        },
        {
          label: 'Contact Surname',
          value: 'contactSurname',
          type: 'string'
        },
        {
          label: 'Address 1',
          value: 'address1',
          type: 'string'
        },
        {
          label: 'Address 2',
          value: 'address2',
          type: 'string'
        },
        {
          label: 'Address 3',
          value: 'address3',
          type: 'string'
        },
        {
          label: 'Address 4',
          value: 'address4',
          type: 'string'
        },
        {
          label: 'Address 5',
          value: 'address5',
          type: 'string'
        },
        {
          label: 'Postcode',
          value: 'postcode',
          type: 'string'
        },
        {
          label: 'Telephone 1',
          value: 'telephone1',
          type: 'string'
        },
        {
          label: 'Telephone 2',
          value: 'telephone2',
          type: 'string'
        },
        {
          label: 'Website URL',
          value: 'websiteUrl',
          type: 'string'
        },
        {
          label: 'Email',
          value: 'email',
          type: 'string'
        },
        {
          label: 'SUN',
          value: 'sun',
          type: 'string'
        },
        {
          label: 'Payment Limit',
          value: 'paymentLimit',
          type: 'string'
        },
        {
          label: 'Submission Limit',
          value: 'submissionLimit',
          type: 'string'
        },
        {
          label: 'Contra Narrative',
          value: 'contraNarrative',
          type: 'string'
        },
        {
          label: 'Bank Name',
          value: 'bankName',
          type: 'string'
        },
        {
          label: 'Bank Address 1',
          value: 'bankAddress1',
          type: 'string'
        },
        {
          label: 'Bank Address 2',
          value: 'bankAddress2',
          type: 'string'
        },
        {
          label: 'Bank Address 3',
          value: 'bankAddress3',
          type: 'string'
        },
        {
          label: 'Bank Address 4',
          value: 'bankAddress4',
          type: 'string'
        },
        {
          label: 'Bank Address 5',
          value: 'bankAddress5',
          type: 'string'
        },
        {
          label: 'Bank Postcode',
          value: 'bankPostcode',
          type: 'string'
        },
        {
          label: 'Sortcode',
          value: 'sortcode',
          type: 'string'
        },
        {
          label: 'Account Number',
          value: 'accountNumber',
          type: 'string'
        },
        {
          label: 'Default Export Schema',
          value: 'defaultExportSchema',
          type: 'string'
        },
        {
          label: 'Default Export Mapping',
          value: 'defaultExportMapping',
          type: 'string'
        },
        {
          label: 'Default Filename',
          value: 'defaultFilename',
          type: 'string'
        }
      ]
    }
  },
  methods: {
    falseChangeHandler (rule) {
      if (rule.falseFile < 0) {
        rule.falseFile = 0
      }

      if (rule.falseFile > this.filesCollection.length) {
        rule.falseFile = this.filesCollection.length
      }
    },
    trueChangeHandler (rule) {
      if (rule.trueFile < 0) {
        rule.trueFile = 0
      }

      if (rule.trueFile > this.filesCollection.length) {
        rule.trueFile = this.filesCollection.length
      }
    },
    btnRuleFileBrowser (rule, index) {
      this.selectedFileIndex = index
      console.log('index:', index)
      this.showRuleFileBrowser = true
    },
    btnFileBrowser () {
      this.property = this.selectedNode.props.s4.value
      this.$store.commit('setModalPopupString', this.selectedNode.props.s4.value)
      this.showModalFileBrowser = true
    },
    btnPathBrowser () {
      console.log('showFolderBrowser()')
      this.$store.commit('setModalPopupString', this.selectedNode.props.s3.value)
      this.showFolderBrowser = true
    },
    closePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s4.value = filename
      if (!this.selectedNode.props.s3.value) {
        let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
        if (folder.endsWith('/') || folder.endsWith('\\')) {
          folder = folder.slice(0, -1)
        }
        this.selectedNode.props.s3.value = folder
      }
    },
    closeSourcePopup () {
      this.selectedNode.props.s3.value = this.$store.state.mapping.modalPopupString
    },
    closeRulePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
      if (folder.endsWith('/') || folder.endsWith('\\')) {
        folder = folder.slice(0, -1)
      }
      folder += '\\'
      this.filesCollection[this.selectedFileIndex].filePath = folder + filename
    },
    btnSave: function () {
      // Check rule validity
      let allValid = true
      for (const rule of this.rulesCollection) {
        console.log(rule)
        if (!rule.isValid) allValid = false
      }

      if (!allValid) {
        console.log('Wow invalid')
        return
      }

      if (this.$v.$invalid) {
        console.log('invalid')
        this.$v.$touch()
        return
      }

      const data = {
        rulesData: this.rulesCollection,
        filesData: this.filesCollection
      }
      const fsData = JSON.stringify(data)
      console.log('fsData')
      console.log(fsData)
      this.selectedNode.props.s5.value = fsData

      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnAddRule: function () {
      this.rulesCollection.push({
        isValid: false
      })
    },
    btnAddFile: function () {
      console.log('btnAddFile')
      this.filesCollection.push({
        isValid: false,
        filePath: ''
      })
      this.isRuleValid()
    },
    btnRemoveRule: function (index) {
      console.log(index)
      this.rulesCollection.splice(index, 1)
    },
    btnRemoveFile: function (index) {
      console.log(index)
      this.filesCollection.splice(index, 1)
      this.isRuleValid()
    },
    dataTypeChanged: function () {
      if (this.rulesCollection.length > 0) {
        for (const i of this.rulesCollection) {
          console.log(i)
          if (i.item) i.item = ''
        }
      }
    },
    selectedDataTypeChanged: function () {
      this.isRuleValid()
    },
    selectedConditionChanged: function () {
      this.isRuleValid()
    },
    valueChanged: function () {
      this.isRuleValid()
    },
    isRuleValid: function () {
      let found = false
      for (let i = 0; i < this.rulesCollection.length; i++) {
        const rule = this.rulesCollection[i]
        console.log('rule.type: ' + rule.item.type)
        if (rule.condition) {
          for (const st of rule.condition.supportedTypes) {
            console.log('st: ' + st)
            if (st === rule.item.type) {
              console.log('Found: ' + st)
              found = true
              break
            }
          }
          if (rule.condition.requiresValue && !rule.value) {
            console.log('rule value!')
            found = false
          }
        }
        console.log('return: ' + found)
        this.rulesCollection[i].isValid = found

        if (rule.falseFile > this.filesCollection.length) {
          console.log('falseFile > ' + this.filesCollection.length)
          this.rulesCollection[i].isValid = false
        }
        if (rule.trueFile > this.filesCollection.length) {
          this.rulesCollection[i].isValid = false
        }
      }
    }
  },
  created: async function () {
    this.$snapbar.hide()
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    // if (!this.paygateId) {
    //   this.$toastr.e('Error getting list of available groups. (001)', 'Error', 'error')
    //   return
    // }
    // let res
    // try {
    //   res = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups`)
    //   this.groupOptions = res.data.data
    // } catch (e) {
    //   console.log(e)
    //   this.$snapbar.e('Error getting list of available groups. (002)')
    // }

    let importersRes
    try {
      importersRes = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}importers`)
      // console.log(importersRes)
      this.importerOptions = importersRes.data.importers
    } catch (e) {
      this.$snapbar.e('Error getting list of available importers.')
    }
    console.log(this.selectedNode)

    if (this.selectedNode.props.s5.value) {
      const fsdata = JSON.parse(this.selectedNode.props.s5.value)
      this.rulesCollection = fsdata.rulesData
      this.filesCollection = fsdata.filesData
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    // console.log('selectedNode')
    // console.log(this.selectedNode)
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
.modal-container {
  width: 90%;
  height: 90%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-height: 90vh;
  overflow-y: auto;
}

@media (min-width: 992px) {
  .modal .modal-huge {
    max-width: 90% !important;
    width: 90% !important;
  }
}

.invalid {
  border-color: red;
  border-width: 2px;
}

.fadein-enter {
  opacity: 0;
}

.fadein-enter-active {
  transition: opacity 0.6s;
}

.fadein-leave-active {
  transition: opacity 0.6s;
  opacity: 0;
}

.fa-exclamation-triangle {
  color: #bc0000;
}
</style>
