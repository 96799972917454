<template>
  <div>
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0">Look Ahead Report {{ (this.cumulative ? '(Cumulative)' : '') }}</h4>
    </div>
    <div style="position:relative; height: 350px !important">
      <look-ahead-chart
        :chart-data="chartData"
        :options="chartOptions"
        v-if="loaded"
        :styles="{ height: '100%' }"
      ></look-ahead-chart>
      <div v-if="!loaded">Loading...</div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import LookAheadChart from '@/Assets/Components/Charts/CollectionsLookAhead'
import Loading from '@/Assets/Components/Loading/Loading'
import interpolateColors from "@/Assets/Helpers/ColourHelper";
import {mapGetters} from "vuex";
import LineChart from "@/Assets/Components/Charts/LineChart";

export default {
  name: 'LookAheadReportWidget',
  components: {
    LineChart,
    LookAheadChart,
    Loading
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      chartData: [],
      chartOptions: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItems, data) {
              let amount = parseFloat(tooltipItems.yLabel).toFixed(2)
              return '£' + amount
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 150
      }
    }
  },
  methods: {
    buildData (data, label) {
      var datasets = []
      var labels = []
      for (var i = 0; i < data.length; i++) {
        let current = data[i]
        labels.push(this.formatDatePointString(current.datePointString))
        datasets.push(this.cumulative ? current.cumulativeAmount : current.unitAmount)
      }
      return {
        labels: labels,
        datasets: [
          {
            label: label,
            backgroundColor: '#f87979',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            data: datasets
          }]
      }
    },
    buildMerged (report) {
      var dataSets = []
      var labels = []
      var colours = interpolateColors('rgb(102,255,102)', 'rgb(255,102,255)', report.length + 1)
      var selectedGroupName = ''
      for (var i = 0; i < report.length; i++) {
        labels = []
        const groupName = report[i].groupName
        const datasets = []
        for (var d = 0; d < report[i].lookAheadResponse.length; d++) {
          if (report[i].lookAheadResponse[d].window === this.window) {
            const data = report[i].lookAheadResponse[d].data
            for (var a = 0; a < data.length; a++) {
              const current = data[a]
              labels.push(this.formatDatePointString(current.datePointString))
              datasets.push(this.cumulative ? current.cumulativeAmount : current.unitAmount)
            }
          }
        }
        dataSets.push({
          label: groupName,
          backgroundColor: colours[i],
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          data: datasets
        })
      }
      if (this.merged) {
        const mergedData = []
        for (var l = 0; l < labels.length; l++) {
          mergedData[l] = 0
          for (var m = 0; m < dataSets.length; m++) {
            if (dataSets[m].data && dataSets[m].data.length > 0 && dataSets[m].data[l]) {
              mergedData[l] = mergedData[l] + dataSets[m].data[l]
            }
          }
        }
        dataSets = [{
          label: 'All Groups',
          backgroundColor: colours[0],
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          data: mergedData
        }]
      }
      return { labels: labels, datasets: dataSets }
    },
    groupChanged (e) {
      this.populate()
    },
    populate () {
      if (!this.noGroup) {
        try {
          axios.get(`${process.env.VUE_APP_DDMS_API_URL}reporting/lookahead/${this.group}/${this.window}`, {params: {paygateid: this.paygateId}})
            .then((response) => {
              this.loaded = true
              var data = response.data.data
              this.chartData = this.buildData(data, response.data.groupName)
            })
        } catch (er) {
          axios.get(`${process.env.VUE_APP_DDMS_API_URL}reporting/lookahead/`, { params: { paygateid: this.paygateId }, showload: true })
            .then ((response) => {
              this.loaded = true
              this.chartData = this.buildMerged(response.data, true)
            })
        }
      } else {
        axios.get(`${process.env.VUE_APP_DDMS_API_URL}reporting/lookahead/`, { params: { paygateid: this.paygateId }, showload: true })
          .then ((response) => {
            this.loaded = true
            this.chartData = this.buildMerged(response.data, true)
          })
      }
    },
    formatDatePointString (input) {
      let dateSplit = input.split('/')
      let year = dateSplit[0]
      let month = dateSplit[1]
      switch (month) {
        case '01':
          month = 'Jan'
          break
        case '02':
          month = 'Feb'
          break
        case '03':
          month = 'Mar'
          break
        case '04':
          month = 'Apr'
          break
        case '05':
          month = 'May'
          break
        case '06':
          month = 'Jun'
          break
        case '07':
          month = 'Jul'
          break
        case '08':
          month = 'Aug'
          break
        case '09':
          month = 'Sep'
          break
        case '10':
          month = 'Oct'
          break
        case '11':
          month = 'Nov'
          break
        case '12':
          month = 'Dec'
          break
      }
      return `${month} ${year}`
    }
  },
  mounted () {
    this.populate()
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    window () {
      return this.widget.options.find(o => o.label === 'Window').value
    },
    merged () {
      return this.widget.options.find(o => o.label === 'Merge Groups').value
    },
    group () {
      return this.widget.options.find(o => o.label === 'GroupId').value
    },
    cumulative () {
      return this.widget.options.find(o => o.label === 'Cumulative').value
    },
    paygateId () {
      return this.widget.options.find(o => o.label === 'Client').value !== undefined ? this.widget.options.find(o => o.label === 'Client').value : this.selectedCustomer
    },
    noGroup () {
      return this.group === undefined || this.group === null || this.group === '00000000-0000-0000-0000-000000000000'
    }
  },
  watch: {
    group () {
      this.populate()
    },
    cumulative () {
      this.populate()
    },
    merged () {
      this.populate()
    },
    selectedCustomer () {
      this.populate()
    }
  }
}
</script>
<style>
</style>
