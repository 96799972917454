import axios from 'axios'
// default state
const state = {
  bacsReportStartDate: null,
  bacsReportEndDate: null,
  failedCollectionStartDate: null,
  failedCollectionEndDate: null,
  failedCollectionsFilterColumn : 'Collection Date'
}

// mutators
const mutations = {
  setBacsReportStartDate (state, value) {
    state.bacsReportStartDate = value
  },

  setBacsReportEndDate (state, value) {
    state.bacsReportEndDate = value
  },

  setFailedCollectionStartDate (state, value) {
    state.failedCollectionStartDate = value
  },

  setFailedCollectionEndDate (state, value) {
    state.failedCollectionEndDate = value
  },

  setFailedCollectionFilterColumn (state, value) {
    state.failedCollectionsFilterColumn = value
  }
}

// getters
const getters = {
  collectionsBacsReportStartDate: state => {
    return state.bacsReportStartDate
  },

  collectionsBacsReportEndDate: state => {
    return state.bacsReportEndDate
  },

  collectionsFailedCollectionStartDate: state => {
    return state.failedCollectionStartDate
  },

  collectionsFailedCollectionEndDate: state => {
    return state.failedCollectionEndDate
  },

  collectionsFailedCollectionFilterColumn: state => {
    return state.failedCollectionsFilterColumn
  }
}

// actions
const actions = {
  setBacsReportStartDate:  async ({ commit }, request) => {
    commit('setBacsReportStartDate', request)
  },

  setBacsReportEndDate:  async ({ commit }, request) => {
    commit('setBacsReportEndDate', request)
  },

  setFailedCollectionStartDate:  async ({ commit }, request) => {
    commit('setFailedCollectionStartDate', request)
  },

  setFailedCollectionEndDate:  async ({ commit }, request) => {
    commit('setFailedCollectionEndDate', request)
  },

  setFailedCollectionFilterColumn:  async ({ commit }, request) => {
    commit('setFailedCollectionFilterColumn', request)
  },
}

export default { state, getters, actions, mutations }
