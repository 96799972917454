<template>
  <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <form @submit.prevent="processValid()">
              <div class="idb-block-title">
                <h2>
                  <help-icon docPath="/paygate-collections/import-export/importpayer/" />Import Details
                </h2>
              </div>
              <div class="idb-block-content">
                <b-form-group :label-cols="4" class="col-sm-4" horizontal label="Import status">
                  <b-badge
                    :title="translateStatus(uploadHistory.uploadStatus)"
                    :variant="getSeverityClass(uploadHistory.uploadStatus)"
                  >{{ translateStatus(uploadHistory.uploadStatus) }}</b-badge>
                </b-form-group>
                <b-form-group
                  :label-cols="4"
                  class="col-sm-4"
                  horizontal
                  label="Valid rows"
                >{{ uploadHistory.validRows }}</b-form-group>
                <b-form-group
                  :label-cols="4"
                  class="col-sm-4"
                  horizontal
                  label="Invalid rows"
                >{{ uploadHistory.invalidRows }}</b-form-group>
                <b-form-group
                  :label-cols="4"
                  class="col-sm-4"
                  horizontal
                  label="Total rows"
                >{{ uploadHistory.totalRows }}</b-form-group>
                <b-form-group
                  :label-cols="12"
                  class="col-sm-12"
                  label-horizontal
                  :label="truncatedMessagesLabel"
                >
                  <b-form-textarea
                    id="messagesTextArea"
                    v-model="messagesToDisplay"
                    placeholder="No validation errors found"
                    :rows="3"
                    :max-rows="6"
                    readonly
                  />
                </b-form-group>
                <b-form-group
                  :label-cols="12"
                  class="col-sm-12"
                  label-horizontal
                  :label="truncatedCsvLabel"
                >
                  <b-form-textarea
                    id="textarea1"
                    v-model="csvToDisplay"
                    placeholder="No failed rows found"
                    :rows="3"
                    :max-rows="6"
                    readonly
                  />
                </b-form-group>
                <b-form-group
                  v-if="hasCancelledRows"
                  :label-cols="12"
                  class="col-sm-12"
                  label-horizontal
                  label="Cancelled CSV Rows"
                >
                  <b-form-textarea
                    id="textarea1"
                    v-model="cancelledCsvToDisplay"
                    placeholder="No cancelled rows found"
                    :rows="3"
                    :max-rows="6"
                    readonly
                  />
                </b-form-group>
              </div>
              <div class="idb-block-footer">
                <b-button
                  type="submit"
                  v-if="uploadHistory.uploadStatus === 3 || uploadHistory.uploadStatus === 4"
                  :disabled="uploadHistory.validRows === 0 || isLoading"
                  variant="primary"
                >Process Valid Rows</b-button>
                <b-button
                  class="ml-2"
                  :disabled="uploadHistory.invalidRows === 0"
                  @click="download"
                >Download Failed Rows</b-button>
                <b-button
                  variant="primary"
                  @click.prevent="removeAction"
                  v-if="actionId && actionId !== null"
                  :disabled="isLoading"
                >Clear Action</b-button>
                <b-button
                  v-if="uploadHistory.importedFileType !== 0 && uploadHistory.uploadStatus !== 10"
                  @click="undoUpload"
                  variant="danger"
                  :disabled="!uploadHistory.canCancel"
                  class="pull-right"
                >Undo Upload</b-button>
              </div>
            </form>
          </div>
          <div class="idb-block mb-20">
            <div class="idb-block-title">
              <h2>Imported Collections</h2>
            </div>
            <div class="idb-block-content">
              <vue-good-table
                mode="remote"
                ref="table"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :columns="columns"
                :rows="uploads"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :search-options="{
                    enabled: false
                    }"
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                :isLoading.sync="isTableLoading"
                styleClass="vgt-table striped bordered"
                class="scroll-table"
              >
                <template slot="loadingContent">
                  <h1>Loading...</h1>
                </template>
                <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">No Customers Available</div>
                </div>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'"
                  >
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span>
                  </button>
                  <b-button
                    @click.prevent="load"
                    :disabled="isLoading"
                    class
                    variant="link"
                    v-b-popover.hover.top.d500="'Refresh the data in the table'"
                  >
                    <i class="fa fa-sync pointer dimmedIcon"></i>
                  </b-button>
                  <b-button
                    v-if="false"
                    @click.prevent="deleteRecords"
                    class
                    variant="link"
                    v-b-popover.hover.top.d500="'Clear incomplete uploads'"
                  >
                    <i class="fa fa-times pointer dimmedIcon"></i>
                  </b-button>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'buttons'">
                    <b-dropdown variant="outline-secondary">
                      <!-- no-caret -->
                      <span slot="button-content">
                        <i class="fa fa-calendar mr-2"></i>Go To
                      </span>
                      <b-dropdown-item
                        :key="index"
                        @click="goToPayer(props.row.ukPayerId, props.row.paymentPlanId)"
                      >Customer</b-dropdown-item>
                      <b-dropdown-item
                        :key="index"
                        @click="goToSchedule(props.row.ukPayerId, props.row.paymentPlanId)"
                      >Schedule</b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.row.processed"
                        :key="index"
                        @click="goToSubmission(props.row.submissionId)"
                      >Submission</b-dropdown-item>
                    </b-dropdown>
                  </span>
                  <span v-else-if="props.column.field === 'processed'">
                    <i v-if="props.row.processed" class="fa fa-check text-success"></i>
                    <i v-if="!props.row.processed" class="fa fa-times"></i>
                  </span>
                  <span v-else-if="props.column.label === 'Status'">
                    <b-badge :variant="getStatusVariant(props.row)" pill>
                      <span v-html="props.formattedRow[props.column.field]"></span>
                    </b-badge>
                  </span>
                  <span v-else>
                    <div class="fileNameAligner">{{props.formattedRow[props.column.field]}}</div>
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div class="idb-block-footer" v-if="false">
              <b-button
                type="submit"
                :disabled="uploadHistory.cancelledCount > 0"
                variant="danger"
                @click="removeUnsubmitted"
              >Remove Unsubmitted</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import FileSaver from 'file-saver'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  mixins: [loading, tableFilterMixin],
  props: {
    uploadId: String
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    hasCancelledRows () {
      return this.uploadHistory && this.uploadHistory.cancelledRowCsv !== ''
    },
    csvToDisplay () {
      if (this.uploadHistory && this.uploadHistory.invalidRows > 100) {
        var msgArray = this.uploadHistory.invalidRowCsv.split('\n').slice(0, 100)
        return msgArray.join('\n')
      }
      return this.uploadHistory.invalidRowCsv
    },
    cancelledCsvToDisplay () {
      if (this.uploadHistory && this.uploadHistory.cancelledRowCsv !== '') {
        var msgArray = this.uploadHistory.cancelledRowCsv.split('\n')
        return msgArray.join('\n')
      }
      return this.uploadHistory.invalidRowCsv
    },
    messagesToDisplay () {
      if (this.uploadHistory && this.uploadHistory.invalidRows > 100) {
        var rowArray = this.uploadHistory.messages.split('\n').slice(0, 100)
        return rowArray.join('\n')
      }
      return this.uploadHistory.messages
    },
    truncatedCsvLabel () {
      var addenda = ''
      if (this.uploadHistory && this.uploadHistory.invalidRows > 100) {
        addenda = ' (limited to 100 lines)'
      }
      return 'Failed CSV Rows' + addenda
    },
    truncatedMessagesLabel () {
      var addenda = ''
      if (this.uploadHistory && this.uploadHistory.invalidRows > 100) {
        addenda = ' (limited to 100 lines)'
      }
      return 'Validation Messages ' + addenda
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    }
  },
  data () {
    return {
      importUrl: '',
      confirmUrl: '',
      uploadHistory: {
        uploadedFileId: null,
        paygateId: null,
        fileName: '',
        originalFileName: '',
        uploadStatus: 0,
        messages: '',
        totalRows: 0,
        processedRows: 0,
        invalidRows: 0,
        groupId: '',
        mandateRequired: false,
        migrationRequired: false,
        fileFormat: 'csv',
        importType: 'Payer',
        group: null,
        importedPayers: null,
        uploadDate: '',
        validRows: 0,
        invalidRowCsv: '',
        canCancel: false,
        importedFileType: 0,
        submittedRows: 0,
        cancelledCount: 0,
        cancelledRowCsv: ''
      },
      actionId: null,
      uploadStatusConnection: null,
      uploadHubUrl: null,
      uploads: [],
      columns: [
        {
          label: 'uploadedFileId',
          field: 'uploadedFileId',
          hidden: true
        },
        {
          label: 'Customer Reference',
          field: 'payerReference'
        },
        {
          label: 'Schedule Reference',
          field: 'planReference'
        },
        {
          label: 'Customer Name',
          field: 'payerName'
        },
        {
          label: 'Collection Identifier',
          field: 'collectionIdentifier'
        },
        {
          label: 'Amount',
          field: 'amount',
          formatFn: (value) => {
            return `${this.numberWithCommas((value / 1).toFixed(2))}`
          },
          tdClass: 'text-right'
        },
        {
          label: 'Status',
          field: 'status',
          formatFn: (value) => {
            const retVal = this.statusDescription(value)
            return retVal
          },
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'Processed',
          field: 'processed',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'groupId',
          field: 'groupId',
          hidden: true
        },
        {
          label: 'ukpayerId',
          field: 'ukpayerId',
          hidden: true
        },
        {
          label: 'paygateId',
          field: 'paygateId',
          hidden: true
        },
        {
          label: 'Submission Id',
          field: 'submissionId',
          hidden: true
        },
        {
          label: 'Collection Composite',
          field: 'paymentComposite',
          hidden: true
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }
        },
        {
          label: 'Collection Date',
          field: 'collectionDate',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
          tdClass: 'text-center'
        }
      ],
      serverParams: {
        sort: [{ field: 'uploadDate', type: 'desc' }]
      },
      urlSet: false
    }
  },
  async created () {
    window.addEventListener('beforeunload', this.stopSocketListener)
    this.uploadHubUrl = process.env.VUE_APP_DDMS_API_URL + 'hubs/uploaddetails?uploadid=' + this.uploadId
  },
  async mounted () {
    this.importUrl = process.env.VUE_APP_DDMS_API_URL + 'import/' + this.uploadId
    this.confirmUrl = process.env.VUE_APP_DDMS_API_URL + 'collectionupload/' + this.uploadId + '/confirm'
    this.actionId = this.$route.query.actionId
    axios.get(this.importUrl, { headers: { 'x-cust-meta': this.paygateId }, params: { paygateId: this.paygateId }, showload: true }).then((response) => {
      this.uploadHistory = response.data
    })
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        // this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
        this.uploadStatusConnection = new HubConnectionBuilder().withUrl(this.uploadHubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.uploadStatusConnection.serverTimeoutInMilliseconds = 3600000
        this.uploadStatusConnection.start()
        this.uploadStatusConnection.on('UpdateImportDetails', data => {
          this.uploadHistory = data
        })
      })
    this.urlSet = true
    await this.load()
  },
  methods: {
    async undoUpload () {
      try {
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}import/undoupload/${this.uploadId}`, {})
        this.uploadHistory.uploadStatus = 12
        axios.get(this.importUrl, { headers: { 'x-cust-meta': this.paygateId }, params: { paygateId: this.paygateId }, showload: true }).then((response) => {
          this.uploadHistory = response.data
        })
        await this.load()
        this.$toastr.s('Uploaded data removed from the system.')
      } catch (e) {
        console.log(e)
        this.$toastr.e('Could not undo the upload.')
      }
    },
    statusDescription (status) {
      let desc = ''
      switch (status) {
        case 9:
          desc = 'Pending First Submission'
          break
        case 8:
          desc = 'Pending Regular Submission'
          break
        case 7:
          desc = 'Pending Re Presentation'
          break
        case 6:
          desc = 'Pending Final Submission'
          break
        case 5:
          desc = 'Being Processed'
          break
        case 4:
          desc = 'Submitted'
          break
        case 3:
          desc = 'Failed'
          break
        case 2:
          desc = 'Error'
          break
        case 1:
          desc = 'Suspended'
          break
        case 0:
          desc = 'Closed'
          break
        case 10:
          desc = 'Deleted'
          break
        case 11:
          desc = '<i class="fas fa-share mr-2"></i>Refunded'
          break
        case 12:
          desc = 'Not Attempted (deprecated)'
          break
        case 13:
          desc = 'Will Not Attempt'
          break
        case 14:
          desc = 'Unprocessed'
          break
        case 16:
          desc = 'Due'
          break
        case 17:
          desc = 'Successful'
          break
        case 18:
          desc = 'Unsubmitted'
          break
        case 19:
          desc = 'Cancelled'
          break
        default:
          desc = 'Value not set yet'
          break
      }
      return desc
    },
    goToSchedule (payerId, planId) {
      this.$router.push({ name: 'PayerSchedule', params: { id: payerId, planId: planId } })
    },
    goToPayer (payerId) {
      this.$router.push({ name: 'editPayer', params: { ukPayerId: payerId } })
    },
    goToSubmission (submissionId) {
      this.$router.push({ name: 'Submission History Item', params: { submissionId } })
    },
    processValid () {
      axios.post(this.confirmUrl, {}, { headers: { 'x-cust-meta': this.paygateId }, params: { paygateId: this.paygateId }, showload: true }).then((response) => {
        this.$toastr.s('The valid rows will be imported into the live system.')
        this.$router.push({ path: '/collections/data/importcollection/' })
      })
    },
    removeAction () {
      axios.delete(this.importUrl + '/clearaction', { headers: { 'x-cust-meta': this.paygateId }, params: { actionId: this.actionId, paygateId: this.paygateId }, showload: true }).then(
        (response) => {
          this.$toastr.s('Action cleared')
          this.actionId = null
          this.$router.push('/actions')
        }
      )
    },
    download () {
      const blob = new Blob([this.uploadHistory.invalidRowCsv], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      FileSaver.saveAs(blob, 'invalid-rows.csv')
      window.URL.revokeObjectURL(url)
    },
    deleteUpload () {
      axios.delete(this.importUrl, { headers: { 'x-cust-meta': this.paygateId }, params: { paygateId: this.paygateId }, showload: true }).then((response) => {
        this.$toastr.s('Record deleted, along with all staged data and uploaded files.')
        this.$router.push({ path: '/collections/data/importcollection/' })
      })
    },
    formatDate (dateString) {
      return moment(dateString).format('DD/MM/yyyy')
    },
    translateStatus (status) {
      var statusStr = ''
      switch (status) {
        case 0:
          statusStr = 'Queued'
          break
        case 1:
          statusStr = 'Uploading'
          break
        case 2:
          statusStr = 'Complete'
          break
        case 3:
          statusStr = 'Warning'
          break
        case 4:
          statusStr = 'Error'
          break
        case 5:
          statusStr = 'Pending'
          break
        case 6:
          statusStr = 'Processing'
          break
        case 7:
          statusStr = 'Preparing'
          break
        case 8:
          statusStr = 'Validating'
          break
        case 9:
          statusStr = 'Duplicate References'
          break
        case 10:
          statusStr = 'Deleted'
        case 12:
          statusStr = 'Cancelled'
          break
        case 13:
          statusStr = 'Uploaded'
          break
        case 14:
          statusStr = 'Processed'
          break
      }
      return statusStr
    },
    getSeverityClass (statusInt) {
      var buttonClass = ''
      switch (statusInt) {
        case 2: // Staging
        case 13:
        case 14:
          buttonClass = 'success'
          break
        case 4: // Error
        case 10: // Error
        case 12: // Error
          buttonClass = 'danger'
          break
        case 3: // Warning
        case 9:
          buttonClass = 'warning'
          break
        default:
          buttonClass = 'primary'
          break
      }
      return buttonClass
    },
    stopSocketListener () {
      if (this.uploadStatusConnection && this.uploadStatusConnection !== null) {
        this.uploadStatusConnection.stop()
      }
    },
    async removeUnsubmitted () {
      const response = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}collectionupload/${this.uploadId}/unsubmitted`)
      if (response) {
        // this.uploadHistory.uploadStatus = 10
        this.$toastr.s('Unsubmitted Collections cancelled.')
      } else {
        this.$toastr.e('Failed to cancel Unsubmitted Collections.')
      }
      await this.load()
    },
    async load () {
      if (this.urlSet) {
        axios.get(`${process.env.VUE_APP_DDMS_API_URL}collectionupload/` + this.uploadId, {
          headers: { 'x-cust-meta': this.paygateId },
          params: { ...this.buildGoodTableQuery(), paygateId: this.paygateId },
          showload: true
        }).then((response) => {
          this.uploads = response.data.data
          // state.metaData.tableLinks = response.data.links
          // this.serverParams.totalPages = response.data.meta.totalPages
          this.totalRecords = response.data.count
        })
      }
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    getStatusVariant (rowObj) {
      let variant = ''
      switch (rowObj.status) {
        case 4:
          variant = 'success'
          break
        case 2:
        case 3:
        case 10:
          variant = 'danger'
          break
        case 12:
          variant = 'outline-warning'
        case 13:
          variant = 'warning'
          break
        default:
          variant = 'info'
          break
      }
      return variant
    }
  },
  watch: {
    selectedCustomer () {
      this.group = '00000000-0000-0000-0000-000000000000'
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      this.$router.go(-1)
    }
  }
}
</script>
