<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <div class="form-group">
          <div class="row">
            <h2 class="col-md-10">Edit Commission Item</h2>
            <h2 v-if="InStatement" class="col-md-2 pull-right">
              Statement Reference:
              <strong>{{Statement.statementserialid}}</strong>
              <br />Statement Date:
              <strong>{{StatementMonthYear}}</strong>
            </h2>
          </div>
        </div>
      </div>
      <div class="idb-block-content">
        <div class="form-group row">
          <div v-if="DisabledAgentDeactivated" class="col-md-10">
            <h6 class="text-warning">
              The commission is not payable as the agent was deactivated at the point of generation. If you still want to pay this commission, click the
              Make Payable button below
            </h6>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-3">Agent</label>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              v-model="commissionItem.agentName"
              :disabled="true"
            />
          </div>
          <div class="col-md-2 col-sm-4">
            <h6 v-show="ManualAdd" class="text-warning">This commission record was added manually</h6>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-3">Customer</label>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              v-model="commissionItem.customerName"
              :disabled="true"
            />
          </div>
        </div>

        <div v-if="!ManualAdd" class="form-group row">
          <label class="col-form-label col-md-3">Service Charge Creation Date</label>
          <div class="col-md-6">
            <input
              type="date"
              class="form-control"
              v-model="formattedServiceChargeCreationDate"
              :disabled="true"
            />
          </div>
        </div>
        <div v-else class="form-group row">
          <label class="col-form-label col-md-3">Manual Commission Creation Date</label>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              v-model="formattedServiceChargeCreationDate"
              :disabled="true"
            />
          </div>
        </div>

        <div v-if="!ManualAdd" class="form-group row">
          <label class="col-form-label col-md-3">Service Charge</label>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              v-model="commissionItem.serviceCharge"
              :disabled="true"
            />
          </div>
          <button class="btn btn-default btn-sm" @click="onGoToSettlement()">
            <i class="fa fa-cog"></i> Go To Settlement
          </button>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-3">Billing Usage Type</label>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              v-model="commissionItem.billingUsageType"
              :disabled="true"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-3">Commission Qualifier</label>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              v-model="commissionItem.commissionQualifier"
              :disabled="true"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-3">Commission Status</label>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              v-model="commissionItem.commissionItemStatus"
              :disabled="true"
            />
          </div>
        </div>

        <div v-if="!ManualAdd" class="form-group row">
          <label class="col-form-label col-md-3">Fixed Amount Or Percentage</label>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              v-model="commissionItem.fixedFeePercentage"
              :disabled="true"
            />
          </div>
        </div>

        <div v-if="commissionItem.fixedFeePercentage === 'Percentage' && !ManualAdd">
          <div class="form-group row">
            <label class="col-form-label col-md-3">Percentage-based commission</label>
            <div class="col-md-6">
              <button class="btn btn-default btn-sm" @click="onGoToAssociation()">
                <i class="fa fa-cog"></i> Go To Association
              </button>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-3">Percentage</label>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                v-model="commissionItem.percentage"
                :disabled="true"
              />
            </div>
          </div>
        </div>
        <div v-if="commissionItem.fixedFeePercentage === 'FixedFee' && !ManualAdd">
          <div class="form-group row">
            <label class="col-form-label col-md-3">Fixed fee commission</label>
            <div class="col-md-6">
              <button class="btn btn-default btn-sm" @click="onGoToAssociation()">
                <i class="fa fa-cog"></i> Go To Association
              </button>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-3">Fixed Fee</label>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                v-model="commissionItem.fixedFee"
                :disabled="true"
              />
            </div>
          </div>
        </div>

        <div v-if="!ManualAdd" class="form-group row">
          <label class="col-form-label col-md-3">Commission Value:</label>
          <div class="col-md-3">
            <input
              type="text"
              class="form-control"
              v-model="commissionItem.commissionAmount"
              :disabled="true"
            />
          </div>
          <label class="col-form-label col-md-1">Override:</label>
          <div class="col-md-2">
            <CurrencyInput
              v-model="OnScreenOverrideValue"
              currency="GBP"
              class="form-control"
              :disabled="!CanOverride"
            ></CurrencyInput>
          </div>
          <button v-if="CanOverride" class="btn btn-primary" @click="onUpdateOverride">Update</button>
        </div>

        <div v-else class="form-group row">
          <label class="col-form-label col-md-3">Commission Value:</label>
          <div class="col-md-3">
            <CurrencyInput
              v-model="commissionItem.commissionAmount"
              currency="GBP"
              class="form-control"
              :disabled="!CanModifyCommissionAmount"
            ></CurrencyInput>
          </div>
          <button
            v-if="CanModifyCommissionAmount"
            class="btn btn-primary"
            @click="onManualAmount"
          >Update</button>
        </div>
        <div class="row form-group">
          <label class="col-form-label col-md-3">Notes</label>
          <div class="col-md-6">
            <textarea
              class="form-control"
              rows="3"
              v-model.trim="commissionItem.commissionNotes"
              placeholder="Please add a note here"
            ></textarea>
          </div>
          <button class="btn btn-default btn-sm" @click="onSaveNotesField()">
            <i class="fas fa-save"></i> Save Notes
          </button>
        </div>
      </div>
      <div class="idb-block-footer">
        <button
          v-if="InStatement && !Deleted"
          class="btn btn-primary"
          @click="onGoToStatement"
        >Go To Statement</button>

        <button
          v-if="DisabledAgentDeactivated"
          class="btn btn-danger pull-right"
          @click="onMakeCommissionPayable"
          type="button"
        >
          <i class="glyphicon ti-trash rpad"></i>Make Payable
        </button>

        <button
          v-if="Deleted"
          class="btn btn-danger pull-right"
          @click="onMakeCommissionUndeleted"
          type="button"
        >
          <i class="glyphicon ti-trash rpad"></i>Undelete
        </button>

        <button
          v-if="CanDelete"
          class="btn btn-danger pull-right"
          @click="onMakeCommissionDeleted"
          type="button"
        >
          <i class="glyphicon ti-trash rpad"></i>Delete
        </button>
      </div>
    </div>
    <!-- Commission Audit Trail Table -->
    <div class="idb-block">
      <div class="idb-block-title">
        <h3>Audit Trail</h3>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :search-options="{
            enabled: true
          }"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <button
              @click.prevent="exportData"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Export Data'"
            >
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import { CurrencyDirective } from 'vue-currency-input'
import colours from '@/Assets/Constants/colours'
import CommissionAgentCommissionItemStatus from '@/Assets/Constants/commissionAgentCommissionItemStatus'
import moment from 'moment'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  directives: {
    currency: CurrencyDirective
  },

  props: {
    commissionid: String
  },

  mixins: [tableFilterMixin],
  data () {
    return {
      commissionItem: {},
      OnScreenOverrideValue: 0,
      ManualAdd: false,
      CanModifyCommissionAmount: false,
      CanOverride: false,
      InStatement: false,
      StatementId: '',
      DisabledAgentDeactivated: false,
      Deleted: false,
      CanDelete: false,
      Statement: {},

      // table
      rows: [],
      columns: [
        {
          label: 'id',
          field: 'id',
          hidden: true
        },
        {
          label: 'CommissionRecordId',
          field: 'commissionRecordId',
          hidden: true
        },
        {
          label: 'AgentId',
          field: 'agentId',
          hidden: true
        },

        {
          label: 'AssociationId',
          field: 'associationId',
          hidden: true
        },

        {
          label: 'Event',
          field: 'serialId',
          filterOptions: {
            enabled: false
          }
        },

        {
          label: 'Event Type',
          field: 'eventType',
          filterOptions: {
            enabled: false
          }
        },

        {
          label: 'Description',
          field: 'rubric',
          filterOptions: {
            enabled: false
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'serialId', type: 'asc' }]
      }
    }
  },
  computed: {
    formattedServiceChargeCreationDate () {
      if (this.ManualAdd) {
        return moment(this.commissionItem.createdDate).format('yyyy-MM-DD')
      } else {
        return moment(this.commissionItem.serviceChargeCreationDate).format('yyyy-MM-DD')
      }
    },

    StatementMonthYear () {
      if (this.Statement.statementmonth < 10) {
        return '0' + this.Statement.statementmonth + '/' + this.Statement.statementyear
      } else {
        return this.Statement.statementmonth + '/' + this.Statement.statementyear
      }
    }

  },
  created: async function () {
    console.log('commissionid', this.commissionid)
    await this.load()
  },
  methods: {

    load: async function () {
      await this.GetCommissionData()
      await this.GetCommissionItemAuditTrail()
      console.log('commissionItem.manualCreated', this.commissionItem.manualCreated)
      if (this.InStatement) {
        await this.GetStatement()
      }
    },

    GetCommissionData: async function () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionItem/${this.commissionid}`,
          {
            showerror: true,
            errormessage: 'Failed loading commission item'
          })

        console.log('response get commission item', response)
        this.commissionItem = response.data
        this.ManualAdd = response.data.manualCreated

        this.CanModifyCommissionAmount = response.data.commissionItemStatus === CommissionAgentCommissionItemStatus.New
        this.CanOverride = response.data.commissionItemStatus === CommissionAgentCommissionItemStatus.New || response.data.commissionItemStatus === CommissionAgentCommissionItemStatus.InStatement
        this.InStatement = (response.data.commissionItemStatus === CommissionAgentCommissionItemStatus.InStatement ||
          response.data.commissionItemStatus === CommissionAgentCommissionItemStatus.StatementSent ||
          response.data.commissionItemStatus === CommissionAgentCommissionItemStatus.StatementPaid)

        this.DisabledAgentDeactivated = response.data.commissionItemStatus === CommissionAgentCommissionItemStatus.AgentDeactivated
        this.Deleted = response.data.commissionItemStatus === CommissionAgentCommissionItemStatus.Deleted
        this.CanDelete = response.data.commissionItemStatus === CommissionAgentCommissionItemStatus.New || response.data.commissionItemStatus === CommissionAgentCommissionItemStatus.InStatement

        if (this.commissionItem.overridden) {
          this.OnScreenOverrideValue = this.commissionItem.commissionAmountOverride
        }

        if (!this.InStatement) {
          this.StatementId = response.data.statementId
        }
      } catch (e) {
        console.log('get commission item error', e)
      } finally {

      }
    },

    GetStatement: async function () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetSingleStatement/${this.commissionItem.statementId}`,
          {
            showerror: true,
            errormessage: 'Failed loading statement data'
          })

        console.log('response', response)
        this.Statement = response.data
      } catch { } finally {

      }
    },
    GetCommissionItemAuditTrail: async function () {
      try {
        this.isTableLoading = true
        console.log('here')
        console.log(this.buildGoodTableQuery())
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionItemAuditTrail/${this.commissionid}`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading agents'
          })

        console.log('response', response)
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    exportData: async function () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionItemAuditTrailCSV/${this.commissionid}`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading agents'
          })

        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Agents.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {
        console.log(e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    onGoToAssociation () {
      // TODO
      console.log('To Do', this.commissionItem.associationId
        , this.commissionItem.id)
      // Pass the Commission Edit Id AND the ASsociation ID so you can get back
      this.$router.push({
        name: 'CommissionAgentViewAssociationCommissionEdit',
        params: { associationid: this.commissionItem.associationId, commissionId: this.commissionItem.id }
      })
    },

    onGoToSettlement () {
      console.log('To Do', this.commissionItem.settlementId
        , this.commissionItem.id)
      this.$router.push({
        name: 'SettlementReportDetails',
        params: { id: this.commissionItem.settlementId }
      })
    },

    onSaveNotesField: async function () {
      try {
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAUpdateCommissionNotes`, this.commissionItem, { showload: true, showerror: true, errormessage: 'Failed to save notes field' })

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
        await this.GetCommissionData()
        await this.GetCommissionItemAuditTrail()
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    onGoToStatement () {
      this.$router.push({
        name: 'CommissionAgentListStatementsSelect',
        params: { statementid: this.commissionItem.statementId }
      })
    },
    onUpdateOverride: async function () {
      if (this.commissionItem.commissionItemStatus === CommissionAgentCommissionItemStatus.StatementSent ||
        this.commissionItem.commissionItemStatus === CommissionAgentCommissionItemStatus.StatementPaid) {
        var DeclineText = 'The override cannot be entered as the commission item is part of statement which has been sent or already paid'
        try {
          var declineSwalResult = await swal.fire({
            title: 'Override Commission Value',
            text: DeclineText,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'Go To Statement (TODO)',
            cancelButtonText: 'Cancel'
          })
        } catch {
          return
        }
        if (!declineSwalResult.isConfirmed) {

        } else { console.log('ToDo Go To Statement') }
      } else {
        try {
          var swalText = 'This action will override the calculated commission value to £' + this.OnScreenOverrideValue
          var swalResult = await swal.fire({
            title: 'Override Commission Value',
            text: swalText,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          })
        } catch {
          return
        }

        if (!swalResult.isConfirmed) {
          if (this.commissionItem.commissionAmountOverride === 0) {
            this.OnScreenOverrideValue = ''
            this.commissionItem.overridden = false
          } else {
            this.OnScreenOverrideValue = this.commissionItem.commissionAmountOverride
          }
        } else {
          try {
            this.commissionItem.commissionAmountOverride = this.OnScreenOverrideValue
            console.log('this.commissionItem.commissionAmountOverride', this.commissionItem.commissionAmountOverride)
            var overrideResponse = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAUpdateCommissionOverride`, this.commissionItem, { showload: true, showerror: true, errormessage: 'Failed to update commission item override' })

            console.log('overrideResponse', overrideResponse)
            if (overrideResponse.data.toastType === 2) {
              this.$toastr.s(overrideResponse.data.toastMessage)
            } else {
              this.$toastr.e(overrideResponse.data.toastMessage)
            }
            await this.load()
          } catch (e) {
            this.$toastr.e(e.response.data, e.response.statusText)
          }
        }
      }
    },
    onManualAmount: async function () {
      try {
        var swalText = ' This action will override the manual commission value to £' + this.commissionItem.commissionAmount
        var swalResult = await swal.fire({
          title: 'Change Commission Value',
          text: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {

      }

      if (!swalResult.isConfirmed) {

      } else {
        try {
          this.commissionItem.commissionAmountOverride = this.OnScreenOverrideValue
          console.log('this.commissionItem.commissionAmount', this.commissionItem.commissionAmount)
          var overrideResponse = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAUpdateCommissionValue`, this.commissionItem, { showload: true, showerror: true, errormessage: 'Failed to update commission item override' })

          console.log('overrideResponse', overrideResponse)
          if (overrideResponse.data.toastType === 2) {
            this.$toastr.s(overrideResponse.data.toastMessage)
          } else {
            this.$toastr.e(overrideResponse.data.toastMessage)
          }
          await this.GetCommissionData()
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        }
      }
    },

    onMakeCommissionPayable: async function () {
      var swalText = 'This commission record is set as not payable as the agent was deactivated when it was generated. <br/> If you continue, this will <strong>make the commission payable</strong> in the normal manner. This cannot be reversed as the commission record will become a normal payable commission record. If you later decide not to pay this commission, you will have to delete it.'
      try {
        var swalResult = await swal.fire({
          title: 'Make this commission payable',
          html: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }
      if (!swalResult.isConfirmed) {
        console.log('declined to make payable')
      } else {
        try {
          var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAMakeCommissionPayable/${this.commissionid}`,
            {
              showerror: true,
              errormessage: 'Failed to make the Commission payable'
            })
          console.log('response', response)
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$snapbar.e(response.data.toastMessage)
          }
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        }
        await this.load()
      }
    },

    onMakeCommissionDeleted: async function () {
      var swalText = ''

      if (this.commissionItem.commissionItemStatus === CommissionAgentCommissionItemStatus.StatementSent ||
        this.commissionItem.commissionItemStatus === CommissionAgentCommissionItemStatus.StatementPaid ||
        this.commissionItem.commissionItemStatus === CommissionAgentCommissionItemStatus.AgentDeactivated
      ) {
        swalText = 'You cannot delete this commission item'
        try {
          var swalResultDeclined = await swal.fire({
            title: 'Make this commission payable',
            html: swalText,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'OK'
          })
        } catch {

        }
      } else {
        if (this.commissionItem.commissionItemStatus === CommissionAgentCommissionItemStatus.New) {
          swalText = 'This action will <strong>delete</strong> the commission record. <br/> This can be reversed later.'
        } else if (this.commissionItem.commissionItemStatus === CommissionAgentCommissionItemStatus.InStatement) {
          swalText = 'This action will <strong>delete</strong> the commission record. <br/> This can be reversed later however it will be removed from any statement in which it appears.'
        }

        try {
          var swalResult = await swal.fire({
            title: 'Make this commission payable',
            html: swalText,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          })
        } catch {
          return
        }
        if (!swalResult.isConfirmed) {
          console.log('declined to delete')
        } else {
          try {
            var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAMakeCommissionDeleted/${this.commissionid}`,
              {
                showerror: true,
                errormessage: 'Failed to delete the Commission'
              })
            console.log('response', response)
            if (response.data.toastType === 2) {
              this.$toastr.s(response.data.toastMessage)
            } else {
              this.$snapbar.e(response.data.toastMessage)
            }
          } catch (e) {
            this.$toastr.e(e.response.data, e.response.statusText)
          }
          await this.load()
        }
      }
    },

    onMakeCommissionUndeleted: async function () {
      var swalText = ''

      if (this.commissionItem.commissionItemStatus !== CommissionAgentCommissionItemStatus.Deleted
      ) {
        swalText = 'You cannot Undelete this commission item'
        try {
          var swalResultDeclined = await swal.fire({
            title: 'Make this commission payable',
            html: swalText,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'OK'
          })
        } catch {

        }
      } else {
        if (this.commissionItem.commissionItemStatus === CommissionAgentCommissionItemStatus.New) {
          swalText = 'This action will <strong>undelete</strong> the commission record and make it payable. <br/>If any current statements exist then it will be added, otherwise you will need to generate a new statement for the commission month.'
        }

        try {
          var swalResult = await swal.fire({
            title: 'Make this commission payable',
            html: swalText,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          })
        } catch {
          return
        }
        if (!swalResult.isConfirmed) {
          console.log('declined to undelete')
        } else {
          try {
            var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAMakeCommissionUndeleted/${this.commissionid}`,
              {
                showerror: true,
                errormessage: 'Failed to delete the Commission'
              })
            console.log('response', response)
            if (response.data.toastType === 2) {
              this.$toastr.s(response.data.toastMessage)
            } else {
              this.$snapbar.e(response.data.toastMessage)
            }
          } catch (e) {
            this.$toastr.e(e.response.data, e.response.statusText)
          }
          await this.load()
        }
      }
    }

  }

}
</script>
