<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          News Stories
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div class="idb-block-content">
        <div>
          <vue-good-table
            :paginationOptions="paginationOptions"
            :sort-options="sortOptions"
            :isLoading.sync="isTableLoading"
            :rows="rows"
            :columns="columns"
            :lineNumbers="true"
            mode="remote"
            :totalRows="totalRecords"
            @on-row-click="onRowClick"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
            styleClass="vgt-table striped bordered"
            ref="table"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'enabled' || props.column.field === 'sticky'">
                <span class="text-success" v-if="props.row[props.column.field] == true">
                  <i class="glyphicon ti-check"></i>
                </span>
                <span class="text-danger" v-else>
                  <i class="glyphicon ti-close"></i>
                </span>
              </span>
              <span v-else>{{props.formattedRow[props.column.field]}}</span>
            </template>
            <div slot="table-actions">
              <b-button
                @click.prevent="load"
                class
                variant="link"
                title="Refresh Table"
                v-b-popover.hover.top.d500="'Refresh the data in the table'"
              >
                <i class="fas fa-sync-alt pointer dimmedIcon pointer"></i>
              </b-button>
              <b-button
                @click.prevent="createNewsStory"
                class
                variant="link"
                v-b-popover.hover.top.d500="'Create a new story'"
              >
                <i class="fa fa-plus dimmedIcon pointer"></i>
              </b-button>
            </div>
          </vue-good-table>
        </div>
        <br />
        <div class="row col-12">
          <div class="btn-group" role="group">
            <button
              id="btnGroupDrop1"
              @click="createNewsStory"
              type="button"
              class="btn btn-primary"
            >
              <i class="fa fa-plus rpad"></i>New Story
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Third Party
import axios from 'axios'

// Mixins
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

// Constants
import newStoryTypes from '@/Assets/Constants/newsStoryType'

export default {
  mixins: [tableFilterMixin],
  name: 'ListNewsStories',
  data () {
    return {
      columns: [
        {
          label: 'Title',
          field: 'title',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true,
            placeholder: 'All Types',
            filterDropdownItems: Object.values(newStoryTypes)
          }
        },
        {
          label: 'Enabled',
          field: 'enabled',
          filterOptions: {
            enabled: true,
            filterDropdownItems: ['True', 'False']
          }
        },
        {
          label: 'Sticky',
          field: 'sticky',
          filterOptions: {
            enabled: true,
            filterDropdownItems: ['True', 'False']
          }
        },
        {
          label: 'Order',
          field: 'order'
        },
        {
          label: 'Author',
          field: 'authorName',
          filterOptions: {
            enabled: true
          }
        }
      ],
      rows: [],
      paginationOptions: {
        rowsPerPageLabel: 'Stories per page'
      },
      serverParams: {
        sort: [{ field: 'order', type: 'asc' }]
      }
    }
  },
  methods: {
    async load () {
      try {
        this.isTableLoading = true
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}newsstory/List`, {
          params: { ...this.buildGoodTableQuery() },
          showerror: true,
          errormessage: 'Failed loading news stories'
        })
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    onRowClick (params) {
      this.$router.push({ name: 'EditNewsStory', params: { id: params.row.id } })
    },
    createNewsStory () {
      this.$router.push({ name: 'CreateNewsStory' })
    }
  }
}
</script>

<style scoped>
td {
  cursor: pointer;
}
</style>
