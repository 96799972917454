<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          View Expiring USB Tokens
          <help-icon docPath="/administration/usbtokens/expiringusbtokens/" />
          <favourite-icon />
        </h2>
      </div>
      <div class="idb-block-content">
        <div class="row mb-1">
          <div class="col-md-2">
            <div class="form-group">
              <label class="form-label">From Date</label>
              <vuejsDatepicker
                name="from"
                id="from"
                format="dd/MM/yyyy"
                input-class="form-control bg-white"
                :bootstrap-styling="true"
                minimumView="day"
                maximumView="year"
                initial-view="day"
                :use-utc="true"
                v-model="fromDate"
                :disabledDates="fromDisabledDates"
                @input="loadTokens"
              ></vuejsDatepicker>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="form-label">To Date</label>
              <vuejsDatepicker
                name="to"
                id="to"
                format="dd/MM/yyyy"
                input-class="form-control bg-white"
                :bootstrap-styling="true"
                minimumView="day"
                maximumView="year"
                initial-view="day"
                :use-utc="true"
                v-model="toDate"
                :disabledDates="toDisabledDates"
                @input="loadTokens"
              ></vuejsDatepicker>
            </div>
          </div>
        </div>
        <vue-good-table
          :paginationOptions="{
        enabled: true,
        perPage: 10,
      }"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          :searchOptions="{
        enabled: false
      }"
          styleClass="vgt-table striped bordered"
        ></vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  computed: {
    toDisabledDates () {
      return { to: moment(this.fromDate).toDate() }
    },
    fromDisabledDates () {
      return { from: moment(this.toDate).toDate() }
    },
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'User Name',
          field: 'userName'
        },
        {
          label: 'Valid From',
          field: 'certificateValidFrom',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
        },
        {
          label: 'Valid To',
          field: 'certificateValidTo',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
        },
        {
          label: 'Token Supplier',
          field: 'tokenSupplier'
        },
        {
          label: 'Token Serial Number',
          field: 'tokenSerialNumber'
        }
      ],
      searchOptions: {
        enabled: true
      },
      fromDate: new Date(),
      toDate: new Date(new Date().setMonth(new Date().getMonth() + 3)),
      dateOptions: {
        format: 'DD/MM/YYYY',
        useCurrent: false
      }
    }
  },
  watch: {
    selectedCustomer () { this.loadTokens() }
  },
  async created () {
    await this.loadTokens()
  },
  methods: {
    async loadTokens () {
      // var paygateId = this.selectedCustomer
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}usbtoken/Expiring`, { params: { sort: 'reference:asc', fromDate: this.fromDate.toISOString(), toDate: this.toDate.toISOString() } })
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch (e) {
        console.log(e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    }
  }
}

</script>
