<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline Row Details
            <span class="pull-right">
              <a :href="`${docUrl}/automation/logging/`" target="_blank"><i class="far fa-question-circle"></i></a>
            </span>
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-10">
              <!-- <strong><small>Type</small></strong> -->
            </div>
            <div class="col-2">
              <strong><small>Status</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-10">
              <!-- {{fileData.banklineAbsRow.data.Type}} ({{fileData.metaData.TypeDescription}}) -->
            </div>
            <div class="col-2">
              <b-badge pill v-if = "fileData.banklineAbsRow.status == '1'" variant="secondary">Unprocessed</b-badge>
              <b-badge pill v-else-if = "fileData.banklineAbsRow.status == '2'" variant="info">Processing</b-badge>
              <b-badge pill v-else-if = "fileData.banklineAbsRow.status == '3'" variant="success">Reconciled</b-badge>
              <b-badge pill v-else-if = "fileData.banklineAbsRow.status == '4'" variant="warning">Unreconciled </b-badge>
              <b-badge pill v-else-if = "fileData.banklineAbsRow.status == '5'" variant="warning">Unreconcilable </b-badge>
              <b-badge pill v-else-if = "fileData.banklineAbsRow.status == '8'" variant="danger">Invalid Date</b-badge>
              <b-badge pill v-else-if = "fileData.banklineAbsRow.status == '99'" variant="danger">Error</b-badge>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-2 ">
              <strong><small>Sortcode</small></strong>
            </div>
            <div class="col-2">
              <strong><small>Account Number</small></strong>
            </div>
            <div class="col-2 ">
              <strong><small>Account Alias</small></strong>
            </div>
            <div class="col-2 ">
              <strong><small>Account Short Name</small></strong>
            </div>
            <div class="col-2">
              <strong><small>Date</small></strong>
            </div>
            <div class="col-2">
              <strong><small>Currency</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              {{reformatSortcode2(fileData.banklineAbsRow.data.SortCode)}}
            </div>
            <div class="col-2">
              {{fileData.banklineAbsRow.data.AccountNumber}}
            </div>
            <div class="col-2">
              {{fileData.banklineAbsRow.data.AccountAlias}}
            </div>
            <div class="col-2">
              {{fileData.banklineAbsRow.data.AccountShortName}}
            </div>
            <div class="col-2">
              {{fileData.banklineAbsRow.data.Date}}
            </div>
            <div class="col-2">
              {{fileData.banklineAbsRow.data.Currency}}
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-2">
              <strong><small>Last Night's Ledger</small></strong>
            </div>
            <div class="col-2">
              <strong><small>Last Night's Cleared</small></strong>
            </div>
            <div class="col-2">
              <strong><small>Start of Day Ledger</small></strong>
            </div>
            <div class="col-2">
              <strong><small>Start of Day Cleared</small></strong>
            </div>
            <div class="col-2">
              <strong><small>Day's Ledger</small></strong>
            </div>
            <div class="col-2">
              <strong><small>Day's Cleared</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              {{fileData.banklineAbsRow.data.LastNightsLedger | currency}}
            </div>
            <div class="col-2">
              {{fileData.banklineAbsRow.data.LastNightsCleared | currency}}
            </div>
            <div class="col-2">
              {{fileData.banklineAbsRow.data.StartOfDayLedger | currency}}
            </div>
            <div class="col-2">
              {{fileData.banklineAbsRow.data.StartOfDayCleared | currency}}
            </div>
            <div class="col-2">
              {{fileData.banklineAbsRow.data.TodaysLedger | currency}}
            </div>
            <div class="col-2">
              {{fileData.banklineAbsRow.data.TodaysCleared | currency}}
            </div>
          </div>
        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Reconciled Event</h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-1 ">
              Event
            </div>
            <div class="col-3">
              {{fileData.banklineFile.originalFilename}}
            </div>
            <div class="col-1 offset-1">
              Rule
            </div>
            <div class="col-3">
              {{reformatDate(fileData.banklineFile.creationDate)}}
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-1 ">
              Origin
            </div>
            <div class="col-3">
              <b-badge pill v-if = "fileData.description == 'upload'" variant="success">Uploaded from Bankline</b-badge>
              <b-badge pill v-else-if = "fileData.description == 'payment'" variant="info">Generated by JFTP</b-badge>
            </div>
            <div class="col-1 offset-1">
              Status
            </div>
            <div class="col-3">
              <b-badge pill v-if = "fileData.status == '2'" variant="secondary">Unprocessed</b-badge>
              <b-badge pill v-else-if = "fileData.status == '3'" variant="success">Processed</b-badge>
            </div>
          </div>
        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Parent Bankline File<span class="ml-2"><router-link :to="{ path: '/admin/bandr/bankline/banklineabsfile/'+ this.fileData.banklineFile.id}">View</router-link></span></h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-3 ">
              <strong><small>Filename</small></strong>
            </div>
            <div class="col-3">
              <strong><small>Upload Date</small></strong>
            </div>
            <div class="col-3 ">
              <strong><small>Origin</small></strong>
            </div>
            <div class="col-3 ">
              <strong><small>Status</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              {{fileData.banklineFile.originalFilename}}
            </div>
            <div class="col-3">
              {{reformatDate(fileData.banklineFile.creationDate)}}
            </div>
            <div class="col-3">
              <b-badge pill v-if = "fileData.banklineFile.description == 'upload'" variant="success">Uploaded from Bankline</b-badge>
              <b-badge pill v-else-if = "fileData.banklineFile.description == 'payment'" variant="info">Generated by JFTP</b-badge>
            </div>
            <div class="col-3">
              {{fileData.banklineFile.status}}
              <b-badge pill v-if = "fileData.banklineFile.status == '1'" variant="secondary">Unprocessed</b-badge>
              <b-badge pill v-else-if = "fileData.banklineFile.status == '2'" variant="info">Processing</b-badge>
              <b-badge pill v-else-if = "fileData.banklineFile.status == '3'" variant="success">Processed</b-badge>
              <b-badge pill v-else-if = "fileData.banklineFile.status == '8'" variant="danger">Invalid Date</b-badge>
              <b-badge pill v-else-if = "fileData.banklineFile.status == '98'" variant="success">Archived </b-badge>
              <b-badge pill v-else-if = "fileData.banklineFile.status == '99'" variant="danger">Error</b-badge>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-3 ">
              <strong><small>Position in file</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              Row {{fileData.banklineAbsRow.row + 1}} of {{fileData.banklineFile.numItems}}
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <!-- <div class="btn-group mr-2" role="group">
                <button id="btnGroupDrop1" @click="btnAutomaticReconcileBanklineRow" type="button" class="btn btn-primary">
                  <i class="fa fa-play mr-2"></i>Automatic Reconcile
                </button>
              </div> -->
              <div class="btn-group mr-2" role="group">
                <button id="btnManuallyReconcile" @click="btnManuallyReconcileBanklineRow" type="button" class="btn btn-primary">
                  <i class="fa fa-user mr-2"></i>Manually Reconcile
                </button>
              </div>
              <!-- <div class="btn-group mr-2" role="group">
                <button id="btnGroupDrop1" @click="btnViewAllBalances" type="button" class="btn btn-outline-primary">
                  <i class="fa fa-list mr-2"></i>View Bank Balances
                </button>
              </div> -->
              <div class="btn-group pull-right">
                <button id="btnDeleteRow" @click="btnDeleteRow" type="button" class="btn btn-danger">
                  <i class="fa fa-trash mr-2"></i>Delete Item
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
export default {
  name: 'WorkflowLogs',
  mixins: [tableFilterMixin],
  components: {
    swal
  },
  data () {
    return {
      settings: {
      },
      fileData: {},
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async load () {
      const postObj = {
        serverParams: this.serverParams,
        id: this.id
      }
      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineAbsRow/${this.id}`, postObj)
        if (res) {
          console.log(res)
          this.fileData = res.data
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
      } finally {
      }
    },
    async btnViewAllBalances () {
      this.$router.push('/admin/bandr/balances')
    },
    async btnUploadBanklineFile () {
      this.$router.push('/admin/bandr/bankline/uploadfile')
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    reformatSortcode2 (dai) {
      if (!dai) return
      try {
        if (dai.length !== 6) return
        return `${dai[0]}${dai[1]}-${dai[2]}${dai[3]}-${dai[4]}${dai[5]}`
      } catch {

        return 'Error'
      }
    },
    async btnDeleteRow () {
      const result = await swal.fire({
        title: 'Are you sure you want to permanently delete this Bankline item?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineAbsRow/${this.id}`, { showload: true })
          if (res) {
            this.$toastr.s('The Bankline item was successfully deleted.')
            this.$router.push(`/admin/bandr/bankline/banklineabsfile/${this.fileData.banklineFile.id}`)
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this item.')
          } else {
            this.$snapbar.e(`Could not delete this item - ${e.message}`)
          }
        }
      }
    },
    async btnManuallyReconcileBanklineRow () {
      console.log('btnManuallyReconcile')
      const postObj = {
        id: this.id
      }
      let res

      this.$snapbar.e('btnManuallyReconcile() - TODO.')

      try {

      } catch (e) {
        // if (e.response && e.response.status === 403) {
        //   this.$snapbar.e('You are not authorised to reconcile Bankline file data.')
        // } else {
        //   this.$snapbar.e(`Could not reconcile file data - ${e.message}`)
        // }
      }
    },
    async btnAutomaticReconcileBanklineRow () {
      console.log('btnReconcileBanklineFile')
      const postObj = {
        id: this.id
      }
      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}reconcileBlRow`, postObj)
        if (res) {

        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to reconcile Bankline file data.')
        } else {
          this.$snapbar.e(`Could not reconcile file data - ${e.message}`)
        }
      }
    },
    onRowClick (params) {
    },
    onCellClick (params) {
      if (params.column.field !== 'id') {
        this.$router.push(`/admin/bandr/bankline/banklinerow/${params.row.id}`)
      }
    }
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.load()
    }
  }
}
</script>
<style scoped>
  td {
    cursor: pointer;
  }
</style>
