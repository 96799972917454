<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        <help-icon docPath="/paygate-collections/messaging/assigntemplates/" />Manage Message Templates
        <favourite-icon></favourite-icon>
      </h2>
    </div>
    <div class="idb-block-content">
      <b-tabs>
        <b-tab title="Email" @click="setLastOpenedTab('Email')" :active="lastOpenedTab === 'Email'">
          <emailTable />
        </b-tab>
        <b-tab title="Letter" v-if="lettersAllowed" @click="setLastOpenedTab('Letter')" :active="lastOpenedTab === 'Letter'">
          <letterTable />
        </b-tab>
        <b-tab title="SMS" v-if="smsAllowed" @click="setLastOpenedTab('Sms')" :active="lastOpenedTab === 'Sms'">
          <smsTable />
        </b-tab>
      </b-tabs>
    </div>
    <div class="idb-block-footer">
      <b-dropdown variant="primary" :disabled="!canEdit">
        <!-- no-caret -->
        <span slot="button-content">
          <i class="fa fa-plus rpad"></i>Create Template
        </span>
        <b-dropdown-item @click="goToTemplate('EmailDesigner')">
          <i class="fa fa-at rpad" title="Email"></i>Email
        </b-dropdown-item>
        <b-dropdown-item v-if="lettersAllowed" @click="goToTemplate('LetterDesigner')">
          <i class="fa fa-envelope rpad" title="Letter"></i>Letter
        </b-dropdown-item>
        <b-dropdown-item v-if="smsAllowed" @click="goToTemplate('SmsDesigner')">
          <i class="fa fa-mobile rpad" title="SMS"></i>SMS
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>
<script>
import EventBus from '@/Lib/eventBus'
import axios from 'axios'
import _ from 'lodash'
import FileSaver from 'file-saver'
import loading from '@/Assets/Mixins/LoadingMixin'
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import emailTable from '@/Views/Collections/Messaging/TemplateTables/EmailTemplates'
import letterTable from '@/Views/Collections/Messaging/TemplateTables/LetterTemplates'
import smsTable from '@/Views/Collections/Messaging/TemplateTables/SmsTemplates'
import roles from "@/Assets/Constants/roles";

export default {
  mixins: [loading],
  components: {
    emailTable, letterTable, smsTable
  },
  mounted () {
    this.$store.dispatch('getMessageChannels')
    this.lastOpenedTab = this.$store.getters.currentMessagingTab
  },
  methods: {
    setLastOpenedTab (tab) {
      this.$store.dispatch('setLastOpened', tab)
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    goToTemplate (route) {
      this.$router.push({ name: route })
    },
  },
  data () {
    return {
      lastOpenedTab: 'Email'
    }
  },
  computed: {
    lettersAllowed (){
      let availMessageChannels = this.$store.getters.messageChannels
      return availMessageChannels.includes(1)
    },
    smsAllowed () {
      return parseInt(process.env.VUE_APP_VERSION) >=  130
    },
    canEdit() {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin ])
    }
  }
}
</script>
<style scoped>
.table-create-new {
  margin-bottom: 1.4rem;
}

.newTemplateLinks > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.newTemplateLinks li {
  display: inline;
  margin: 0.5rem;
}

.newTemplateLinks i {
  margin-right: 1%;
}

.newTemplateLinks p {
  float: left;
}
</style>
