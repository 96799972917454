<template>
  <div class="idb-block">
    <!-- <loading v-bind:loading="loading"></loading> -->
    <div class="idb-block-title">
      <h2>
        <!-- Select payment files for '{{ this.submission.submissionReference }}' -->
        <help-icon></help-icon>
        <favourite-icon></favourite-icon>
      </h2>
    </div>

    <!-- <div class="ml-5 mt-3" hidden>
      <b-row>Job Id = {{ this.submission.bureauJobId }}</b-row>
      <b-row>Job Name = {{ this.submission.bureauJobName }}</b-row>
      <b-row>Submission Id = {{ this.submission.bureauSubmissionId }}</b-row>
      <b-row>Submission Reference = {{ this.submission.submissionReference }}</b-row>
      <b-row>Contra Narrative = {{ this.submission.contraNarrative }}</b-row>
      <b-row>Payment Date = {{ this.submission.paymentDate }}</b-row>
      <b-row>Processing Date = {{ this.submission.processingDate }}</b-row>
      <b-row>Paygate Id = {{ this.paygateId }}</b-row>
      <b-row>Group Id = {{ this.submission.groupId }}</b-row>
    </div>-->

    <div class="idb-block-content">
      <b-row>
        <b-col sm="12">
          <dropzone
            id="my-dropzone"
            ref="fileUploader"
            :options="dropzoneOptions"
            @vdropzone-success="afterSuccess"
            @vdropzone-file-added="fileAdded"
            @vdropzone-removed-file="fileRemoved"
            @vdropzone-sending="sendingEvent"
            :use-custom-slot="true"
            :max-file-size-in-m-b="200"
            :useCustomSlot="true"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">Drag and drop to upload your files for processing</h3>
              <div class="subtitle">...or click to select a file from your computer</div>
            </div>
          </dropzone>
        </b-col>
      </b-row>

      <br />

      <vue-good-table
        ref="submissionFilesTable"
        :paginationOptions="{
                        enabled: true,
                        perPage: 10,
                      }"
        :rows="submissionDetails"
        :columns="columns"
        :lineNumbers="true"
        :sort-options="{
                        enabled: true,
                        initialSortBy: {field: 'paymentFilename', type: 'asc'}
                      }"
        styleClass="vgt-table striped bordered"
      >
        <template slot="loadingContent">
          <h1>Loading...</h1>
        </template>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'bureauCustomerName'">
            <b-row>
              <b-col sm="11">
                <model-list-select
                  :id="'customerSelector-'+props.row.bureauSubmissionDetailId"
                  :list="bureauCustomers"
                  v-model="props.row.bureauJobDetailId"
                  option-value="bureauJobDetailId"
                  option-text="customerName"
                  placeholder="Select Customer"
                  :isDisabled="props.row.status != 'Uploaded' || props.row.schema == 'Standard18'"
                  @input="updateSubmissionDetail(props.row)"
                ></model-list-select>
              </b-col>
              <a
                class="btn btn-sm primary mt-1 reset-customer"
                :hidden="props.row.status != 'Uploaded' || props.row.schema == 'Standard18'"
                :disabled="isLoading"
                @click="onResetSelectedCustomer(props.row)"
              >
                <i class="fa fa-times"></i>
              </a>
            </b-row>
          </span>
          <span v-else-if="props.column.field == 'schema'">
            <b-row>
              <b-col sm="12">
                <model-list-select
                  :id="'schemaSelector-'+props.row.bureauSubmissionDetailId"
                  :list="schemas"
                  option-value="value"
                  option-text="text"
                  v-model="props.row.schema"
                  :isDisabled="props.row.status != 'Uploaded'"
                  placeholder="Schema"
                  @input="updateSubmissionDetail(props.row)"
                ></model-list-select>
              </b-col>
            </b-row>
          </span>
          <span v-else-if="props.column.field == 'mapping'">
            <b-row>
              <b-col sm="12">
                <model-list-select
                  :id="'mappingSelector-'+props.row.bureauSubmissionDetailId"
                  :list="mappings"
                  option-value="value"
                  option-text="text"
                  v-model="props.row.mapping"
                  :isDisabled="props.row.status != 'Uploaded' || props.row.schema != 'Mapping' || props.row.bureauJobDetailId == '00000000-0000-0000-0000-000000000000'"
                  placeholder="Mapping"
                  @input="updateSubmissionDetail(props.row)"
                ></model-list-select>
              </b-col>
            </b-row>
          </span>
          <span v-else-if="props.column.field == 'delete'">
            <a
              class="btn btn-sm primary mt-1"
              @click.stop="onDeleteFileClick(props.row)"
              :disabled="isLoading"
            >
              <i class="fa fa-trash"></i>
            </a>
          </span>
          <span v-else>
            <b-row>
              <b-col sm="12" class="mt-2">{{ props.formattedRow[props.column.field] }}</b-col>
            </b-row>
          </span>
        </template>

        <div slot="table-actions">
          <b-button
            @click.prevent="load"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Refresh the data in the table'"
          >
            <i class="fa fa-sync pointer dimmedIcon"></i>
          </b-button>
          <b-button
            @click.prevent="printTable"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Print out the contents of the Logs table'" v-if="false"
          >
            <i class="fa fa-print pointer dimmedIcon"></i>
          </b-button>
          <b-button
            @click.prevent="exportTable"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
          >
            <i class="fa fa-file-export pointer dimmedIcon"></i>
          </b-button>
        </div>
      </vue-good-table>
    </div>

    <div class="idb-block-footer">
      <button
        class="btn btn-primary ml-1"
        @click="onImportClick()"
        :disabled="isLoading"
      >Import/Validate</button>
      <button
        class="btn btn-primary ml-2"
        @click="onSubmissionDetailsClick()"
        :disabled="isLoading"
      >Submission Details</button>
      <button
        class="btn btn-primary ml-2"
        @click="onViewPaymentsClick()"
        :disabled="isLoading"
      >View Payments</button>
      <button
        class="btn btn-success ml-2"
        @click.stop="onStartSubmissionClick()"
        :disabled="isLoading"
      >Start Submission Process</button>
    </div>

    <b-modal
      id="submissionDetailsModal"
      ref="submissionDetailsModal"
      title="Submission Details"
      size="lg"
      hide-header-close
      lazy
    >
      <!-- <submissionDetails
        ref="submissionDetailsComponent"
        :submissionReferenceProperty="submission.submissionReference"
        :contraNarrativeProperty="submission.contraNarrative"
        :processingDateProperty="submission.processingDate"
        :paymentDateProperty="submission.paymentDate"
      ></submissionDetails>-->
    </b-modal>

    <br />
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import axios from 'axios'
import auth from '@/Assets/Components/Authentication/auth.js'
import Dropzone from 'vue2-dropzone'
import swal from 'sweetalert2'
// import SubmissionDetails from '@/Components/Bureau/BureauJob/JobSubmissionDetailsComponent.vue'
import Utility from '@/Assets/Mixins/Utility'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import colours from '@/Assets/Constants/colours'

var GUID_EMPTY = '00000000-0000-0000-0000-000000000000'

export default {
  data () {
    return {
      dropzoneOptions: {
        url: process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/uploadpaymentfile'
      },
      submissionDetails: [],
      bureauCustomers: [],
      columns: [
        {
          label: 'Filename',
          field: 'paymentFilename',
          sortable: true
        },
        {
          label: 'Customer',
          field: 'bureauCustomerName',
          sortable: true
        },
        {
          label: 'Schema',
          field: 'schema',
          sortable: false
        },
        {
          label: 'Mapping',
          field: 'mapping',
          sortable: false
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'From',
          field: 'fromFileNumber',
          hidden: false
        },
        {
          label: 'To',
          field: 'toFileNumber',
          hidden: false
        },
        {
          field: 'delete',
          sortable: false
        }
      ],
      mappings: [],
      objectItem: {},
      submissionId: ''
    }
  },
  computed: {
    // paygateId () { return this.$store.state.common.paygateId },
    // submission () { return this.$store.state.bureau.submission }
  },
  components: {
    Dropzone,
    ModelListSelect //,
    // submissionDetails: SubmissionDetails
  },
  mixins: [Utility, loading, tableFilterMixin],
  async created () {
    console.log('upload files')
    this.submissionId = this.$route.params.id
    console.log('upload files submission id', this.submissionId)

    // load this submission details
    await this.loadSubmissionDetails()

    // load available bureau customers
    await this.loadBureauCustomers()

    // load mappings
    await this.loadMappingsOnLoad()
    // Load current files in submission
    await this.load()
  },
  methods: {
    loadSubmissionDetails: async function () {
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getSubmissionData/' + this.submissionId, { showload: true })
      console.log('loadSubmissionDetails response', response)
      this.submissionDetails = response.data
    },
    loadBureauCustomers: async function () {
      console.log('load bureau customers')

      try {
        // Load customers in job
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getbureaucustomersforsubmission', { showload: true })
        this.bureauCustomers = response.data
      } catch (error) {
        console.error('Error in UploadFiles.Create')
        console.error(error)
      }
      return true
    },

    loadMappingsOnLoad: async function () {
      console.log('load mappings')
      this.mappings = await this.loadMappings()
      console.info('Mappings:', this.mappings)
      this.schemas = this.loadSchemas()
      return true
    },

    async afterSuccess (file, response) {
      var submissionDetail = response
      this.autoMatch(submissionDetail)
      this.submissionDetails.push(submissionDetail)
      file.previewElement.outerHTML = ''
    },
    async fileAdded (file) {
      this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
    },
    fileRemoved (file, error, xhr) {
      console.log(file.name)
    },
    sendingEvent (file, xhr, formData) {
      formData.append('bureauSubmissionId', this.submissionId)
      formData.append('paygateId', this.paygateId)
    },
    dropZoneError (file, error, request) {
      this.$toastr.e('File upload ' + file.name + ' failed', 'Bureau Submission')
      console.error('Error in UploadFiles.DropZoneError')
      console.error(error)
    },
    template () {
      return `<div class="dz-preview dz-file-preview" style="width: 100%;">
                  <div class="dz-details">
                    <div class="dz-size">
                      <span data-dz-name style="padding: 1px 20px 0px;"></span><span data-dz-size></span>
                      <span data-dz-errormessage style="color: yellow;"></span>
                      <span><i class="fa fa-trash float-right" data-dz-remove></i></span>
                    </div>
                    <div class="dz-progress">
                      <span class="dz-upload" data-dz-uploadprogress></span>
                    </div>
                  </div>
                  <div class="dz-error-mark"><span><i class="fa fa-times"></i></span></div>
              </div>`
    },
    async onDeleteFileClick (row) {
      const swalResult = await swal.fire({
        title: 'Delete Payment File',
        text: 'Are you sure you want to delete the file: ' + row.paymentFilename + ', this will delete the file from PayGate',
        icon: 'question',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        try {
          const data = {
            submissionId: row.bureauSubmissionId,
            fileNumber: row.fromFileNumber,
            itemId: row.bureauSubmissionDetailId
          }

          const response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/deleteBlock', data, { showload: true })
          console.log(response)

          // Remove from grid
          this.submissionDetails = this.submissionDetails.filter(function (submissionRow) {
            return submissionRow.bureauSubmissionDetailId !== row.bureauSubmissionDetailId
          })
        } catch (error) {
          console.error('Error in UploadFiles.onDeleteFileClick.DeleteSubmissionDetail')
          console.error(error)
        }
        this.$toastr.s('Removed file ' + row.paymentFilename, 'Bureau Submission')
      }
    },
    onImportClick () {
      this.$router.push({ name: 'ImportValidateProgress' })
    },
    onViewPaymentsClick () {
      // Show validation results?
      this.$router.push({ name: 'PaymentDetails', params: { id: this.submissionId } })
    },
    async load () {
      console.log('load load')
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getsubmissiondetails/' + this.submissionId, { showload: true })
        this.submissionDetails = response.data.data
      } catch (error) {
        console.error('Error in UploadFiles.load')
        console.error(error)
      }
    },
    autoMatch (submissionDetail) {
      var submissionFilename = submissionDetail.paymentFilename
      this.bureauCustomers.forEach(customer => {
        console.log('Checking')
        console.log(customer)
        if (submissionDetail.bureauCustomerId === customer.bureauCustomerId || submissionFilename.toUpperCase() === customer.defaultFilename.toUpperCase()) {
          console.log('Automatch')
          console.log(customer)
          submissionDetail.bureauJobDetailId = customer.bureauJobDetailId
          submissionDetail.bureauCustomerId = customer.bureauCustomerId
          submissionDetail.schema = customer.defaultSchema
          submissionDetail.mapping = customer.defaultMapping
          this.updateSubmissionDetail(submissionDetail)
        }
      })
    },
    async updateSubmissionDetail (submissionDetail) {
      try {
        if (submissionDetail.schema !== 'Mapping') {
          submissionDetail.mapping = ''
        }
        submissionDetail.bureauSubmissionId = this.submissionId
        var response = await axios.put(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/updatesubmissiondetail', submissionDetail, { showload: true })
        // this.$toastr.s(`Updated ${submissionDetail.paymentFilename}`, 'Update Bureau Submission')
        console.log(response)
      } catch (error) {
        this.$toastr.e(`Failed to update ${submissionDetail.paymentFilename}`, 'Update Bureau Submission')
        console.error('Error in UploadFiles.UpdateSubmissionDetail')
        console.error(error)
      }
    },
    async onResetSelectedCustomer (submissionDetail) {
      submissionDetail.bureauJobDetailId = GUID_EMPTY
      submissionDetail.schema = ''
      submissionDetail.mapping = ''
      this.updateSubmissionDetail(submissionDetail)
    },
    getSeverityClass (severity) {
      return severity
    },
    onSubmissionDetailsClick () {
      this.$refs.submissionDetailsModal.show()
    },
    onStartSubmissionClick () {
      this.$router.push({ name: 'PreSubmissionValidation' })
    }
  },
  beforeDestroy () {
    this.$root.$off('bv::modal::hide')
  },
  mounted () {
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
      })

    // this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
    //   if (bvEvent.trigger === 'ok') {
    //     console.log(this.$refs.submissionDetailsComponent)

    //     // this.submission.submissionReference = this.$refs.submissionDetailsComponent.submissionReference
    //     // this.submission.contraNarrative = this.$refs.submissionDetailsComponent.contraNarrative
    //     // this.submission.paymentDate = this.$refs.submissionDetailsComponent.paymentDate
    //     // this.submission.processingDate = this.$refs.submissionDetailsComponent.processingDate

    //     // try {
    //     //   axios.put(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/updatesubmission', this.submission, { showload: true })
    //     //   this.$toastr.s(`${this.submission.submissionReference} updated`, 'Bureau Submission')
    //     // } catch (error) {
    //     //   this.$toastr.e(`${this.submission.submissionReference} failed ${error}`, 'Bureau Submission')
    //     // }
    //   }
    // })
  }
}
</script>

<style scoped>
.dz-preview {
  min-height: 60px !important;
  margin: 5px !important;
}

.dz-details {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  height: 60px !important;
}

.dz-remove {
  visibility: hidden !important;
}

.vue-dropzone .dz-preview .dz-error-mark,
.vue-dropzone .dz-preview .dz-error-mark i {
  font-size: 1rem !important;
  margin-top: -3px !important;
  margin-left: 7px !important;
  color: red !important;
}

.dropzone .dz-preview .dz-progress {
  left: 80%;
}
</style>
