<template>
    <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
                <h2>Trigger Submission Process</h2>
              </div>
              <div class="idb-block-content">
                <p>Manually Trigger Collection Generation and Submission Process.</p>
                <p v-if="!canRun && !hideMessage" class="text-danger">A submission is already in process, please check the event log and ensure that no submissions are awaiting approval.</p>
              </div>
              <div class="idb-block-footer">
                <b-button variant="primary" @click.prevent="startProcess" :disabled="started || !loggedInAsLnZ || !canRun" ><i class="fas fa-save mr-2"></i>Start</b-button>
              </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import auth from '@/Assets/Components/Authentication/auth.js'
import roles from "@/Assets/Constants/roles";
export default {
  props: {
  },
  data () {
    return {
      started: false,
      canRun: false,
      hideMessage: true,
    }
  },
  methods: {
    async startProcess () {
      let response = axios.post(`${process.env.VUE_APP_DDMS_API_URL}jftpsubmission/trigger`, {})
      this.started = true
      this.canRun = false
      this.$toastr.s('Submission Process Started.')
    },
    async canStart() {
      try {
        let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/canrunsubmission`)
        this.canRun = response.data
        this.hideMessage = false
        if (this.$route.query.override === 'true') {
          this.canRun = true
          this.hideMessage = false
        }
      } catch (e) {
        this.$toastr.e('Cannot check to see if submissions can run.')
      }
    }
  },
  computed: {
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    loggedInAsLnZ(){
      return this.paygateId === '0a4703c6-d43f-4b12-90fa-eb6d172f3703'
    }
  },
  async mounted () {
    await this.canStart()
  },
  async created () {
    await this.canStart()
  }
}
</script>
