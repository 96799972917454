<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>View Indemnity Claims<favourite-icon></favourite-icon></h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          @on-row-click="onRowChange"
          :rows="indemnities"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  export default {
    mixins: [tableFilterMixin, loading],
    data () {
      return {
        indemnities: [],
        columns: [
          {
            label: 'id',
            field: 'indemnityReportId',
            hidden: true
          },
          {
            label: 'Production Date',
            field: 'productionDate',
            formatFn: this.formatDate
          },
          {
            label: 'Title',
            field: 'title'
          },
          {
            label: 'SUN',
            field: 'sun'
          },
          {
            label: 'New Advice Count',
            field: 'newAdviceCount'
          },
          {
            label: 'Total New Advice Value',
            field: 'totalDebitValue',
            formatFn: this.formatAmount
          },
          {
            label: 'Cancellation Count',
            field: 'cancellationCount'
          },
          {
            label: 'Total Cancellation Value',
            field: 'totalCancellationValue'  ,
            formatFn: this.formatAmount
          },
        ],
        serverParams: {
          sort: [{ field: 'productionDate', type: 'desc' }],
        },
      }
    },
    mounted () {
      this.load()
    },
    methods: {
      load: async function () {
        try {
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}indemnity`, { params: this.buildGoodTableQuery() })
          if (response) {
            this.indemnities = response.data.data
            this.totalRecords = response.data.count
          }
        } catch (error) {
          this.$toastr.e(error)
        }
      },
      onRowChange (e) {
        console.log(e)
        this.$router.push({ path: `/collections/indemnity/${e.row.indemnityReportId}` })
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      }
    }
  }
</script>
