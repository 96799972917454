<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        Payment files for '{{ submissionReference }}'
        <help-icon></help-icon>
        <favourite-icon></favourite-icon>
      </h2>
    </div>

    <div class="ml-5 mt-3">
      <b-row>Job Id = {{ jobId }}</b-row>
      <b-row>Bureau Submission Id = {{ bureauSubmissionId }}</b-row>
      <b-row>Submission Reference = {{ submissionReference }}</b-row>
      <b-row>Contra Narrative = {{ contraNarrative }}</b-row>
      <b-row>Payment Date = {{ paymentDate }}</b-row>
      <b-row>Processing Date = {{ processingDate }}</b-row>
      <b-row>Paygate Id = {{ paygateId }}</b-row>
    </div>

    <div class="idb-block-content overflowedtable">
      <vue-good-table
        ref="submissionFilesTable"
        :paginationOptions="{
                        enabled: true,
                        perPage: 10,
                      }"
        :rows="submissionDetails"
        :columns="columns"
        :lineNumbers="true"
        styleClass="vgt-table striped bordered"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'bureauCustomerName'">
            <b-row>
              <b-col sm="11">
                <model-list-select
                  :list="bureauCustomerOptions"
                  v-model="props.row.bureauJobDetailId"
                  option-value="bureauJobDetailId"
                  option-text="bureauCustomerName"
                  placeholder="Select Customer"
                  @input="onSelectCustomerChange(props.row)"
                ></model-list-select>
              </b-col>
              <a
                class="btn btn-sm primary mt-1 reset-customer"
                @click="onResetSelectedCustomer(props.row.bureauSubmissionDetailId)"
                :disabled="isLoading"
              >
                <i class="fa fa-times"></i>
              </a>
            </b-row>
            <!-- <span style="font-weight: bold; color: blue;">{{props.row.bureauCustomerName}}</span>  -->
          </span>
          <span v-else-if="props.column.field == 'delete'">
            <a
              class="btn btn-sm primary"
              @click="onDeleteFileClick(props.row.bureauSubmissionDetailId)"
              :disabled="isLoading"
            >
              <i class="fa fa-trash"></i>
            </a>
          </span>
          <span v-else>{{props.formattedRow[props.column.field]}}</span>
        </template>

        <div slot="table-actions">
          <b-button
            @click.prevent="load"
            class
            variant="link"
            title="Refresh Table"
            :disabled="isLoading"
            v-b-popover.hover.top.d500="'Refresh the data in the table'"
          ><i class="fa fa-refresh pointer dimmedIcon"></i></b-button>
          <b-button
            @click.prevent="printTable"
            class
            variant="link"
            :disabled="isLoading"
            title="Print Table"
            v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
          ><i class="fa fa-print pointer dimmedIcon"></i></b-button>
          <b-button
            @click.prevent="exportTable"
            class
            variant="link"
            :disabled="isLoading"
            title="Export Table"
            v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
          ><i class="fa fa-share-square-o pointer dimmedIcon"></i></b-button>
        </div>
      </vue-good-table>
    </div>

    <div class="idb-block-footer">
      <button class="btn btn-primary"
              @click="onBackClick"
              :disabled="isLoading"
              >Back</button>
      <button class="btn btn-primary ml-2"
              @click="onNextClick"
              :disabled="isLoading">Next</button>
    </div>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'

var GUID_EMPTY = '00000000-0000-0000-0000-000000000000'

export default {
  data () {
    return {
      jobId: '',
      bureauSubmissionId: '',
      submissionReference: '',
      contraNarrative: '',
      paymentDate: '',
      processingDate: '',
      paygateId: '',
      bureauCustomerOptions: [],
      submissionDetails: [],
      columns: [
        {
          label: 'Filename',
          field: 'paymentFilename',
          width: '50%',
          sortable: true
        },
        {
          label: 'Customer',
          field: 'bureauCustomerName',
          width: '50%',
          sortable: true
        },
        {
          label: 'Bureau Submission Detail Id',
          field: 'bureauSubmissionDetailId',
          hidden: true
        },
        {
          label: 'Bureau Job Detail Id',
          field: 'bureauJobDetailId',
          hidden: true
        },
        {
          field: 'delete'
        }
      ],
      allFilesLinked: false
    }
  },
  mixins: [loading],
  components: {
    ModelListSelect
  },
  computed: {
  },
  created () {
    if (this.$route.params !== undefined) {
      this.bureauSubmissionId = this.$route.params.bureauSubmissionId
      this.submissionReference = this.$route.params.submissionReference
      this.jobId = this.$route.params.jobId
      this.contraNarrative = this.$route.params.contraNarrative
      this.paymentDate = this.$route.params.paymentDate
      this.processingDate = this.$route.params.processingDate
      this.paygateId = this.$route.params.paygateId
    }
    this.load()
  },
  methods: {
    onNextClick () {
      var bureauSubmission = {
        bureauSubmissionId: this.bureauSubmissionId,
        submissionReference: this.submissionReference,
        step: this.step,
        jobId: this.jobId,
        paygateId: this.paygateId,
        contraNarrative: this.contraNarrative,
        paymentDate: this.paymentDate,
        processingDate: this.processingDate,
        submissionDetails: this.$refs.submissionFilesTable.filteredRows[0].children
      }
      this.$router.push({ name: 'ImportValidateProgress', params: bureauSubmission })
    },
    onBackClick () {
      var bureauSubmission = {
        bureauSubmissionId: this.bureauSubmissionId,
        submissionReference: this.submissionReference,
        step: this.step,
        jobId: this.jobId,
        paygateId: this.paygateId,
        contraNarrative: this.contraNarrative,
        paymentDate: this.paymentDate,
        processingDate: this.processingDate
      }
      this.$router.push({ name: 'UploadPaymentFiles', params: bureauSubmission })
    },
    async onSelectCustomerChange (row) {
      console.log(`setting bureau submission detail ${row.bureauSubmissionDetailId} to ${row.bureauJobDetailId}`)
      console.log(row)
      await axios.put(`${process.env.VUE_APP_BUREAU_API_URL}/bureauSubmission/UpdateSubmissionDetail`, { bureauSubmissionDetailId: row.bureauSubmissionDetailId, bureauJobDetailId: row.bureauJobDetailId }, { showload: true })
        .then(response => console.log(response))
        .catch(error => console.error(error))
    },
    onResetSelectedCustomer (bureauSubmissionDetailId) {
      var rows = this.$refs.submissionFilesTable.filteredRows[0].children
      rows.forEach(row => {
        if (row.bureauSubmissionDetailId === bureauSubmissionDetailId) {
          row.bureauJobDetailId = GUID_EMPTY
          row.bureauCustomerId = GUID_EMPTY
          row.bureauCustomerName = ''
          this.onSelectCustomerChange(row.bureauSubmissionDetailId, GUID_EMPTY)
        }
      })
    },
    onDeleteFileClick (bureauSubmissionDetailId) {
      console.log('remove file ' + bureauSubmissionDetailId)
    },
    onCellClick (params) {
      console.log('cell click')
      console.log(params)
      // params.row - row object
      // params.column - column object
      // params.rowIndex - index of this row on the current page.
      // params.event - click event
    },
    async load () {
      await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauSubmission/getsubmissiondetails/${this.bureauSubmissionId}`, { showload: true })
        .then(response => {
          this.submissionDetails = response.data.data
        })
        .catch(error => console.error(error))

      await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauJob/getbureaucustomerlistforjob/${this.jobId}`, { showload: true })
        .then(response => {
          console.log(response)
          this.bureauCustomerOptions = response.data
        })
        .catch(error => console.error(error))

      this.submissionDetails.forEach(submissionDetail => {
        var submissionFilename = submissionDetail.paymentFilename
        this.bureauCustomerOptions.forEach(bureauCustomerOption => {
          if (submissionDetail.bureauCustomerId === bureauCustomerOption.bureauCustomerId || submissionFilename.toUpperCase() === bureauCustomerOption.defaultFilename.toUpperCase()) {
            if (submissionDetail.bureauJobDetailId !== bureauCustomerOption.bureauJobDetailId) {
              axios.put(`${process.env.VUE_APP_BUREAU_API_URL}bureauSubmission/UpdateSubmissionDetail`, { bureauSubmissionDetailId: submissionDetail.bureauSubmissionDetailId, bureauJobDetailId: bureauCustomerOption.bureauJobDetailId }, { showload: true })
                .then(response => console.log(response))
                .catch(error => console.error(error))
            }
            submissionDetail.bureauJobDetailId = bureauCustomerOption.bureauJobDetailId
            submissionDetail.bureauCustomerName = bureauCustomerOption.bureauCustomerName
            submissionDetail.bureauCustomerId = bureauCustomerOption.bureauCustomerId
          }
        })
      })
    }
  }
}

</script>
<style lang="scss">
.reset-customer {
  margin-left: -10px;
}
</style>
