<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>BACS Direct Debit Processing Report</h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table mode="remote"
          ref="table"
          :columns="columns"
          :rows="rows"
          @on-row-click="viewHistory"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :totalRows="totalRecords"
          :isLoading.sync="isTableLoading"
          :search-options="{
              enabled: true
              }"
          :pagination-options="{
              enabled: true,
              perPage: 10,
              dropdownAllowAll: false
            }"
          styleClass="vgt-table striped bordered"
        >
        </vue-good-table>
      </div>
      <div class="idb-block-footer">

      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '@/Lib/eventBus'
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [ tableFilterMixin ],
  props: {
    reportId: String
  },
  data () {
    return {
      rows: [],
      columns: [
        { label: 'Message Type', field: 'payerHistoryMessageType', formatFn: this.messageTypeToText },
        { label: 'Reference', field: 'payerReference' },
        { label: 'Title', field: 'title' },
        { label: 'Body', field: 'body' },
        { label: 'Date', field: 'generationDate', formatFn: this.formatDateWithNull },
        { label: 'Source', field: 'source', formatFn: this.sourceToText }],
      serverParams: {
        sort: [{ field: 'generationDate', type: 'asc' }]
      }
    }
  },
  async mounted () {
    //await this.load()
    EventBus.$on('refreshHistory', (payload) => { this.load() })
  },
  methods: {
    pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
    formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      formatDateWithNull (date) {
        if (date===null || date === '0001-01-01T00:00:00') {
          return 'N/A'
        } else {
          return this.formatDate(date)
        }
      },
    viewHistory (e) {
      console.log(e)
      this.$router.push({ path: `/collections/customer/${e.row.ukPayerId}/history/${e.row.payerHistoryId}` })
    },
    async load () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/bacsprocessingreport/${this.reportId}/records`, { params: this.buildGoodTableQuery(), paygateid: this.$store.getters.selectedCustomer, showLoad: true })
      if (response) {
        this.rows = response.data.item1
        this.totalRecords = response.data.item2
      }
    },
    messageTypeToText (typeCode) {
      let retVal = ''
      switch (typeCode) {
        case 0:
          retVal = 'AUDDIS'
          break
        case 1 :
          retVal ='First Collection'
          break
        case 2 :
          retVal ='Regular Collection'
          break
        case 3 :
          retVal ='Last Collection'
          break
        case 4 :
          retVal ='Re-Presentation Collection'
          break
        case 5 :
          retVal ='Account'
          break
        case 6 :
          retVal ='Collection Error'
          break
        case 7 :
          retVal ='ARUDD'
          break
        case 8 :
          retVal ='ADDACS'
          break
        case 9 :
          retVal ='AWACS'
          break
        case 10 :
          retVal ='DDIC'
          break
        case 11 :
          retVal ='Message Sent'
          break
        case 12 :
          retVal ='Planned Regular Amount Change'
          break
        case 13 :
          retVal ='Ad-Hoc Customer Expiry'
          break
        case 14 :
          retVal ='Portal Message Sent'
          break
        case 15 :
          retVal ='Portal Message Receieved'
          break
        case 16 :
          retVal ='Refund Requested'
          break
        case 17 :
          retVal ='Status Changed'
          break
        case 18 :
          retVal ='Schedule Status Changed'
          break
        case 19 :
          retVal ='Collection Added'
          break
        case 20 :
          retVal ='Schedule Added'
          break
        case 21 :
          retVal ='New Indemnity Advice'
          break
        case 22 :
          retVal ='New Indemnity Cancellation'
          break
        case 23 :
          retVal ='Customer Updated'
          break
        case 24 :
          retVal ='Collection Not Processed'
          break
        case 25 :
          retVal ='Refund Cancelled'
          break
      }
      return retVal
    },
    sourceToText (sourceCode) {
      let retVal = ''
      switch (sourceCode) {
        case 0:
          retVal = 'System'
          break
        case 1:
          retVal = 'User'
          break
        case 2:
          retVal = 'Api'
          break
      }
      return retVal
    }
  }
}
</script>
