<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h3>Edit Contact ({{agentname}})</h3>
      </div>

      <div class="idb-block-content">
        <div class="form-group row" :class="{ invalid: $v.name.$error }">
          <label class="col-form-label col-md-3 required">Name</label>
          <div class="col-md-6">
            <input v-focus type="text" class="form-control" v-model.trim="$v.name.$model" />
            <!-- Validation -->
            <validation-messages v-model="$v.name" :an="true" name="agent name"></validation-messages>
          </div>
        </div>

        <div class="form-group row">
          <label class="label-control col-md-3">Postcode</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="postcode" />
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-info"
              @click="getAddress"
              type="button"
              :disabled="postcode === '' || postcode  === null || postcode=== undefined"
            >Lookup Address</button>
          </div>
        </div>

        <transition name="fade">
          <div
            class="form-group row"
            v-if="foundAddresses.length > 0 && !this.addressLookupInProgress"
          >
            <label class="label-control col-md-3">Select an Address</label>
            <div class="col-md-6">
              <select
                class="form-control"
                aria-label="Please select an address"
                v-model="selectedAddress"
                @change="setAddress"
              >
                <option selected>Please select an address</option>
                <option
                  v-for="foundAddress in foundAddresses"
                  :key="foundAddress.addressLine1"
                  :value="foundAddress"
                >{{foundAddress.addressLine1}}, {{foundAddress.addressTown}}, {{foundAddress.addressCounty}}</option>
              </select>
            </div>
          </div>
        </transition>

        <div class="form-group row">
          <label class="label-control col-md-3">Address 1</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="address1" />
          </div>
        </div>

        <div class="form-group row">
          <label class="label-control col-md-3">Address 2</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="address2" />
          </div>
        </div>

        <div class="form-group row">
          <label class="label-control col-md-3">Address 3</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="address3" />
          </div>
        </div>

        <div class="form-group row">
          <label class="label-control col-md-3">Address 4</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="address4" />
          </div>
        </div>

        <div class="form-group row">
          <label class="label-control col-md-3">Address 5</label>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="address5" />
          </div>
        </div>

        <div class="form-group row" :class="{ invalid: $v.email.$error }">
          <label class="label-control col-md-3 required">Email Address</label>
          <div class="col-md-5">
            <input v-focus type="text" class="form-control" v-model.trim="$v.email.$model" />
            <validation-messages v-model="$v.email" name="email"></validation-messages>
          </div>
          <span class="col-md-1">
            <p-check class="p-switch p-fill" color="primary" v-model="emailcc">CC Field</p-check>
          </span>
          <div></div>
        </div>
      </div>

      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          type="submit"
          id="submit-btn"
          :disabled="disableSave"
          @click="saveContact()"
        >Save</button>

        <button
          v-if="showAuditTrailButton"
          class="btn btn-primary"
          type="submit"
          id="submit-btn"
          @click="onGoToAuditTrail()"
        >Back To AuditTrail</button>
        <button
          class="btn btn-primary pull-right"
          type="submit"
          id="submit-btn"
          @click="onGoToAgentAdministration()"
        >Agent Administration</button>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import axios from 'axios'
import {
  required,
  maxLength
} from 'vuelidate/lib/validators'
import { email } from '@/Assets/Validators'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'

export default {
  props: {
    agentid: String,
    contactid: String,
    status: ''
  },
  watch: {

    name: function () { this.runValidate() },
    email: function () { this.runValidate() }
  },
  mixins: [loading],
  data () {
    return {
      // Agent Details
      agentname: null,
      // Contact Details
      name: null,
      address1: null,
      address2: null,
      address3: null,
      address4: null,
      address5: null,
      postcode: null,
      email: null,
      emailcc: false,
      // Postcode Lookup
      addressLookupInProgress: false,
      foundAddresses: [],
      selectedAddress: null,

      // Page Control
      disableSave: true,
      showAuditTrailButton: false
    }
  },
  async created () {
    if (this.status === 'FromAgentAuditTrail') {
      console.log('From The Audit Trail')
      this.showAuditTrailButton = true
    }
    await this.load()
  },
  methods: {
    async load () {
      var agentResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetAgent/${this.agentid}`)
      console.log('agentResponse', agentResponse)
      this.agentname = agentResponse.data.name

      var contactResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetContact/${this.contactid}`)
      console.log('contactResponse', contactResponse)
      this.name = contactResponse.data.name
      this.address1 = contactResponse.data.address1
      this.address2 = contactResponse.data.address2
      this.address3 = contactResponse.data.address3
      this.address4 = contactResponse.data.address4
      this.address5 = contactResponse.data.address5
      this.postcode = contactResponse.data.postcode
      this.email = contactResponse.data.email
      this.emailcc = contactResponse.data.emailCC
    },

    async saveContact () {
      var payload = {
        id: this.contactid,
        name: this.name,
        address1: this.address1,
        address2: this.address2,
        address3: this.address3,
        address4: this.address4,
        address5: this.address5,
        postcode: this.postcode,
        email: this.email,
        emailcc: this.emailcc,
        agentid: this.agentid
      }
      try {
        this.$nextTick(() => {
          this.$v.$reset()
        })
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAUpdateContact`, payload, { showload: true, showerror: true, errormessage: 'Failed to Create New Contact' })
        console.log('response', response)
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$snapbar.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    onGoToAuditTrail () {
      this.$router.push({ name: 'CommissionAgentAuditTrailAgent', params: { id: this.agentid } })
    },
    onGoToAgentAdministration () {
      this.$router.push({ name: 'CommissionAgentAdministration', params: { id: this.agentid } })
    },

    async getAddress () {
      this.foundAddresses = []
      this.addressLookupInProgress = true
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}addresslookup`, {
          params: { postcode: this.postcode }
        })
        console.log(response)
        if (response.data.addresses.length === 0) {
          this.$toastr.w('Postcode Not Found', 'Sorry, we could not find that address')
        } else if (response.data.addresses.length === 1) {
          this.foundAddresses = response.data.addresses[0]
        } else {
          this.foundAddresses = response.data.addresses
        }
      } catch (e) {
        this.$toastr.w('Postcode Not Found', 'Sorry, we could not find that address')
      } finally {
        this.addressLookupInProgress = false
      }
    },
    setAddress () {
      if (this.selectedAddress != null) {
        this.address1 = this.selectedAddress.addressLine1
        this.address2 = this.selectedAddress.addressLine2
        this.address3 = this.selectedAddress.addressLine3
        this.address4 = this.selectedAddress.addressTown
        this.address5 = this.selectedAddress.addressCounty
      }
    },

    runValidate: function () {
      if (!this.$v.name.$invalid && !this.$v.email.$invalid) {
        this.disableSave = false
      } else {
        this.disableSave = true
      }
    }

  },
  validations: {
    email: { required, email },
    name: { required, maxLength: maxLength(50) }
  }

}

</script>
