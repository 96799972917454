<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2><help-icon docPath="/paygate-collections/portal/" />Bulk Customer Creation<favourite-icon></favourite-icon></h2>
      </div>
      <div class="idb-block-content">
        <b-row class="mb-3"><b-col>Select a Group. A Portal Account will be created for each Customer currently without one, where possible.</b-col></b-row>
        <b-row class="mb-3"><b-col>Valid Customers are classified as those who have no Portal Account already, and a recorded email address.</b-col></b-row>

        <b-form-row>
          <b-form-group :label-cols="5"
              class="col-sm-5" horizontal label="Group" label-class="required">
            <group-select id="groupSelect" v-model="groupId" :groups="customerGroups"></group-select>
          </b-form-group>
        </b-form-row>
        <b-form-row v-if="count !== null">There are {{ count }} Customers for which a Portal Account will be created.</b-form-row>
      </div>
      <div class="idb-block-footer">
        <b-button variant="primary" :disabled="!count || isLoading" @click.prevent="bulkCreateForGroup(groupId)">Create Accounts</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import swal from 'sweetalert2'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
export default {
  mixins: [loading],
  components: {
  },
  watch: {
    groupId: function (newVal) {
      this.count = null
      this.getWarningStats(newVal)
    }
  },
  data () {
    return {
      groupId: '00000000-0000-0000-0000-000000000000',
      count: null
    }
  },
  computed: {
    customerGroups () {
      return this.$store.getters.customerGroups !== null ? this.$store.getters.customerGroups : []
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    }
  },
  methods: {
    async getWarningStats (groupId) {
      if (groupId !== null && groupId !== undefined) {
        const res = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}portalbulkpayer/warningstats?groupId=${groupId}`, {params: { paygateId: this.paygateId }})
        this.count = res.data.count
      }
    },
    async bulkCreateForGroup (groupId) {
      var result = await this.confirm()
      if (result) {
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}portalbulkpayer`, {
          groupId: this.groupId
        }, { showload: true, params: { paygateId: this.paygateId } })
        if (response.data.success) {
          this.$toastr.s('Portal Accounts created.')
          this.count = null
        } else {
          this.$toastr.e('Portal Account creation failed.')
        }
      }
    },
    loadGroups (paygateId) {
      this.$store.dispatch('loadCustomerGroups', this.paygateId)
    },
    async confirm () {
      var result = null
      try {
        result = await swal({
          title: 'Confirm',
          text: `This will create a Portal Accounts for each Payer${this.groupId !== '00000000-0000-0000-0000-000000000000' ? ' in the group. ' : ''}`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.primary,
          confirmButtonText: 'Proceed',
          cancelButtonText: 'Cancel'
        })
      } catch (err) {
        result = false
      }
      return result
    }
  },
  async mounted () {
    this.loadGroups()
  }
}
</script>
