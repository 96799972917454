<template>
  <div>
    <b-button @click="showComposeModal">Compose</b-button>
    <!-- BEGIN COMPOSE MESSAGE -->
    <b-modal id="compose-modal" size="xl" ref="compose-modal">
      <template v-slot:modal-title>
        <h4 class="modal-title">Compose New Message</h4>
      </template>
      <div class="form-group" v-if="plans.length > 1">
        <select placeholder="plan" class="form-control" id="selectSchedule" v-model="planId">
          <option value="-1" disabled>Plan this message is regarding</option>
          <option v-for="(plan, i) in plans" :key="i" :value="plan.planId">{{plan.planReference}}</option>
        </select>
      </div>
      <div class="form-group">
        <input name="subject" type="email" v-model="messageTitle" class="form-control" placeholder="Subject">
      </div>
      <b-form-group>
        <label>Message Body</label>
        <wysiwyg ref="messageBodyControl" :suppressLoad="true" v-model="messageBody"></wysiwyg>
      </b-form-group>
      <template v-slot:modal-footer>
        <button type="button" class="btn btn-danger" @click="closeComposeModal"><i class="fa fa-trash"></i> Discard</button>
        <button type="button" class="btn btn-primary pull-right" @click="send" :disabled="isLoading"><i class="fa fa-envelope"></i> Send Message</button>
      </template>
    </b-modal>
    <!-- END COMPOSE MESSAGE -->
  </div>
</template>
<script>
import axios from 'axios'
import Wysiwyg from '@/Components/Collections/EmailWysiwyg.vue'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  components: { Wysiwyg },
  props: {
    plans: Array,
    ukPayerId: String,
    replyId: String,
    id: {
      type: String,
      default: 'compose-modal'
    }
  },
  data () {
    return {
      messageTitle: '',
      messageBody: '',
      planId: '00000000-0000-0000-0000-000000000000'
    }
  },
  watch: {
    plans (value) {
      if (value.length > 0) {
        this.planId = value[0]
      }
    }
  },
  methods: {
    showComposeModal () {
      this.$bvModal.show('compose-modal')
      setTimeout(this.loadQuill, 500)
    },
    loadQuill () {
      this.$refs.messageBodyControl.loadQuill()
    },
    closeComposeModal () {
      this.messageTitle = ''
      this.messageBody = ''
      this.$bvModal.hide('compose-modal')
    },
    async send () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}portalmessaging/${this.ukPayerId}/send`, {
        planId: this.planId.paymentPlanId,
        subject: this.messageTitle,
        body: this.messageBody
      }, { showload: true })
      if (response) {
        this.$toastr.s('Message sent successfully')
        this.$bvModal.hide('compose-modal')
        this.$emit('input', {})
      } else {
        this.$toastr.e('Could not send message')
      }
    }
  }
}
</script>
