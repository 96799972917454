<template>
  <div id="submissionsInProgress">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <!-- Header -->
          <div class="idb-block-title">
            <h2>Submissions In Progress <span class="pull-right"><a :href="helpUrl" target="_blank"><i class="far fa-question-circle"></i></a></span><favourite-icon></favourite-icon></h2>
          </div>
          <!-- Main content -->
          <div class="idb-block-content">
            <div v-show="submissionsInProgressLoaded=true" class="col-md-12">
              <vue-good-table
                ref="submissionsInProgress"
                @on-cell-click="onCellClick"
                :columns="columns"
                :rows="submissionsInProgress"
                :pagination-options="{
                  enabled: true,
                  perPage: 10,
                  dropdownAllowAll: false
                }"
                styleClass="vgt-table striped bordered"
              >
                <div slot="emptystate">There are no submissions in progress.</div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'deleteButton'">
                    <button class="btn btn-danger">Delete</button>
                  </span>
                  <span v-else>
                    <span style="cursor: pointer;">{{props.formattedRow[props.column.field]}}</span>
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
          <!-- Footer -->
          <div class="idb-block-footer"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  data () {
    return {
      submissionsInProgressLoaded: false,
      submissionsInProgress: [],
      canContinue: false,
      userId: '',
      helpUrl: '',
      columns: [
        {
          label: 'Submission ID',
          field: 'submissionId',
          hidden: true
        },
        {
          label: 'Reference',
          field: 'reference'
        },
        {
          label: 'GroupId',
          field: 'groupId',
          hidden: true
        },
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: 'Created',
          field: 'createdDate'
        },
        {
          label: 'Network',
          field: 'paymentNetworkType',
          hidden: true
        },
        {
          label: 'Submission',
          field: 'submissionType'
        },
        {
          label: 'Paygate Type',
          field: 'paygateType',
          hidden: true
        },
        {
          label: 'SUN',
          field: 'vol1ServiceUserNumber',
          hidden: true
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          hidden: true
        },
        {
          label: 'Payment Date',
          field: 'paymentDate',
          hidden: true
        },
        {
          label: 'Filename',
          field: 'filename',
          hidden: true
        },
        {
          label: 'NumberOfCredits',
          field: 'numberOfCredits',
          hidden: true
        },
        {
          label: 'NumberOfDebits',
          field: 'numberOfDebits',
          hidden: true
        },
        {
          label: 'NumberOfDdis',
          field: 'numberOfDDis',
          hidden: true
        },
        {
          label: 'CreditsValue',
          field: 'creditsValue',
          hidden: true
        },
        {
          label: 'DebitsValue',
          field: 'debitsValue',
          hidden: true
        },
        {
          label: 'ShowPresubValMessages',
          field: 'showPresubValMessages',
          hidden: true
        },
        {
          label: 'Contra Narrative',
          field: 'contraNarrative'
        },
        {
          label: 'Work Code',
          field: 'workCode',
          hidden: true
        },
        {
          label: '',
          field: 'deleteButton'
        }
      ]
    }
  },

  methods: {
    async getSubmissionsInProgress () {
      try {
        this.submissionsInProgressLoaded = false
        var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getSubmissionsInProgress`, { showload: true })
        this.submissionsInProgress = response.data
        this.submissionsInProgressLoaded = true
      } catch (e) {
        console.log(e)
      }
    },

    async onCellClick (params) {
      this.canContinue = false
      if (params.column.field !== 'deleteButton') {
        await this.setSelectedFlag(params.row)
        if (this.canContinue) {
          await this.continueSubmisson(params)
        }
      } else {
        await this.getSelectedSubmissionInProgress(params.row)
        if (this.canContinue) {
          this.deleteSubmission(params.row)
        } else {
          this.$toastr.w('You cannot delete this submission because it is being edited')
        }
      }
    },

    async getSelectedSubmissionInProgress (selectedSubmissionInProgress) {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getSelectedSubmissionInProgress?submissionId=` + selectedSubmissionInProgress.submissionId)
      if (response.data !== null && (!response.data.selected || response.data.userId === this.userId)) {
        this.canContinue = true
      }
    },

    async setSelectedFlag (selectedSubmissionInProgress) {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getSelectedSubmissionInProgress?submissionId=` + selectedSubmissionInProgress.submissionId)
      if (response.data !== null) {
        if (!response.data.selected || response.data.userId === this.userId) {
          selectedSubmissionInProgress.selected = true

          response = await axios({
            method: 'POST',
            url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/updateInProgressSubmission/',
            data: JSON.stringify(selectedSubmissionInProgress)
          })

          this.canContinue = true
        } else {
          this.$toastr.w('This submission is already being edited by ' + response.data.userName)
        }
      } else {
        this.$toastr.w('This submission has now been processed')
        await this.getSubmissionsInProgress()
      }
    },

    async continueSubmisson (params) {
      var submissionData = {}
      submissionData.submissionId = params.row.submissionId
      submissionData.reference = params.row.reference
      submissionData.processingDate = params.row.processingDate
      submissionData.paymentDate = params.row.paymentDate
      submissionData.paymentNetworkType = params.row.paymentNetworkType
      submissionData.contraNarrative = params.row.contraNarrative
      submissionData.paygateType = params.row.paygateType
      submissionData.groupId = params.row.groupId
      submissionData.groupName = params.row.groupName
      submissionData.serviceUserNumber = params.row.serviceUserNumber
      submissionData.filename = params.row.filename
      submissionData.createPage = 'submissionsInProgress'
      submissionData.submissionType = submissionData.paymentNetworkType === 0 ? 'BACS' : 'FPS'

      var importFileResponse = {}
      importFileResponse.totalNumberOfCredits = params.row.numberOfCredits
      importFileResponse.totalNumberOfDebits = params.row.numberOfDebits
      importFileResponse.totalNumberOfDdis = params.row.numberOfDdis
      importFileResponse.totalCreditsValue = params.row.creditsValue
      importFileResponse.totalDebitsValue = params.row.debitsValue
      importFileResponse.workCode = params.row.workCode

      await this.$store.dispatch('getSubBacsGroup', submissionData.groupId)
      var payload = { groupId: submissionData.groupId }
      await this.$store.dispatch('getGroupBankAccount', payload)

      this.$store.dispatch('setSubmissionData', submissionData)
      this.$store.dispatch('setImportFileResponse', importFileResponse)
      this.$router.push('/payments/bacspayments/viewImportedPayments?showPresubValMessages=' + params.row.showPresubValMessages)
    },

    deleteSubmission (selectedSubmissionInProgress) {
      const vm = this
      this.$swal({
        title: 'Are you sure?',
        text: 'Do you want to delete this submission?',
        type: 'warning',
        animation: false,
        showCancelButton: true,
        cancelButtonClass: 'btn btn-light',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete',
        confirmButtonClass: 'btn btn-danger'
      }).then(function () {
        var json = JSON.stringify(selectedSubmissionInProgress.submissionId)
        return axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/deleteInProgressSubmission/',
          data: json,
          showload: true
        })
          .then(response => {
            if (response.data.status === 'Deleted') {
              vm.$toastr.s('Deleted submission in progress')
              vm.getSubmissionsInProgress()
              vm.$store.dispatch('updateSubmissionsInProgressCount')
            } else {
              vm.$toastr.e('Failed to delete submission')
            }
          })
      }, function (dismiss) {
        if (dismiss === 'cancel') {
          // do nothing.
        }
      })
    }
  },

  async mounted () {
    await this.getSubmissionsInProgress()
    this.userId = this.$store.getters.getClaim('sub').value
    this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/bacs-payments/submissionsinprogress/'
  }
}
</script>
