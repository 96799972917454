<template>
  <actions-block
    v-bind:action="action"
    :status="'Edit'"
    :from="from"
    name="Client"
    v-if="action.id"
  >
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Client Information</h2>
      </div>
      <div class="idb-block-content">
        <div class="alert alert-secondary" role="alert">
          The previous details are shown in
          <span class="del">red</span>, the new details in
          <span class="ins">green</span>.
        </div>
        <!-- Name -->
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Name</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.name"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>

        <!-- Legal Name -->
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Legal Name</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.legalName"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>

        <!-- Trading Name -->
        <!-- <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Trading Name</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.tradingName"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>-->

        <!-- Company Number -->
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Company Number</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.companyNumber"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>

        <!-- Telephone-->
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Telephone</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.telephone"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Settlement Information</h2>
      </div>
      <div class="idb-block-content">
        <!-- Credit Bank Sort Code -->
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Credit Bank Sort Code</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.creditBankSortCode"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>

        <!-- Credit Bank Account Number -->
        <div class="form-group row">
          <label
            for="description"
            class="col-sm-2 text-right col-form-label"
          >Credit Bank Account Number</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.creditBankAccountNumber"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>

        <!-- Credit Bank Account Name -->
        <div class="form-group row">
          <label
            for="description"
            class="col-sm-2 text-right col-form-label"
          >Credit Bank Account Name</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.creditBankAccountName"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>

        <!-- Debit Bank Sort Code -->
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Debit Bank Sort Code</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.debitBankSortCode"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>

        <!-- Debit Bank Account Number -->
        <div class="form-group row">
          <label
            for="description"
            class="col-sm-2 text-right col-form-label"
          >Debit Bank Account Number</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.debitBankAccountNumber"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>

        <!-- Debit Bank Account Name -->
        <div class="form-group row">
          <label
            for="description"
            class="col-sm-2 text-right col-form-label"
          >Debit Bank Account Name</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.debitBankAccountName"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>

        <hr />

        <!-- Settlement Type -->
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Settlement Type</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.settlementType"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>

        <!-- Gross Settlement Method -->
        <div class="form-group row" v-if="action.data.settlementType === 'Gross'">
          <label
            for="description"
            class="col-sm-2 text-right col-form-label"
          >Gross Settlement Method</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.grossSettlementMethod"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Contacts</h2>
      </div>
      <div class="idb-block-content">
        <!-- Contacts -->
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Contacts</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.contacts"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </actions-block>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */

import { diffSentences } from 'diff'
import axios from 'axios'
import ActionsBlock from '@/Components/Platform/Actions/ActionsBlock'

function nullOrUndefinedToEmptyString (value) {
  if (value == null || value === undefined) {
    return ''
  }
  return value
}

export default {
  props: ['actionId', 'customerId'],
  components: { 'actions-block': ActionsBlock },
  computed: {
    difference () {
      if (!this.action.data || !this.customer) {
        return {}
      }

      var result = Object.keys(this.action.data).reduce((a, v) => {
        if (v === 'isItemActioned' || v === 'modifiedBy') {
          return a
        }

        var tempObj = {}
        if (v === 'contacts') {
          const removed = this.customerContacts.filter((v) => !this.action.data.contacts.some((t) => `${nullOrUndefinedToEmptyString(t.firstName)}${nullOrUndefinedToEmptyString(t.lastName)}${nullOrUndefinedToEmptyString(t.address1)}${nullOrUndefinedToEmptyString(t.email)}${nullOrUndefinedToEmptyString(t.phone)}${nullOrUndefinedToEmptyString(t.postcode)}${nullOrUndefinedToEmptyString(t.title)}` === `${nullOrUndefinedToEmptyString(v.firstName)}${nullOrUndefinedToEmptyString(v.lastName)}${nullOrUndefinedToEmptyString(v.address1)}${nullOrUndefinedToEmptyString(v.email)}${nullOrUndefinedToEmptyString(v.phone)}${nullOrUndefinedToEmptyString(v.postcode)}${nullOrUndefinedToEmptyString(v.title)}`))
          const added = this.action.data.contacts.filter((v) => !this.customerContacts.some((t) => `${nullOrUndefinedToEmptyString(t.firstName)}${nullOrUndefinedToEmptyString(t.lastName)}${nullOrUndefinedToEmptyString(t.address1)}${nullOrUndefinedToEmptyString(t.email)}${nullOrUndefinedToEmptyString(t.phone)}${nullOrUndefinedToEmptyString(t.postcode)}${nullOrUndefinedToEmptyString(t.title)}` === `${nullOrUndefinedToEmptyString(v.firstName)}${nullOrUndefinedToEmptyString(v.lastName)}${nullOrUndefinedToEmptyString(v.address1)}${nullOrUndefinedToEmptyString(v.email)}${nullOrUndefinedToEmptyString(v.phone)}${nullOrUndefinedToEmptyString(v.postcode)}${nullOrUndefinedToEmptyString(v.title)}`))
          const same = this.customerContacts.filter((v) => this.action.data.contacts.some((t) => `${nullOrUndefinedToEmptyString(t.firstName)}${nullOrUndefinedToEmptyString(t.lastName)}${nullOrUndefinedToEmptyString(t.address1)}${nullOrUndefinedToEmptyString(t.email)}${nullOrUndefinedToEmptyString(t.phone)}${nullOrUndefinedToEmptyString(t.postcode)}${nullOrUndefinedToEmptyString(t.title)}` === `${nullOrUndefinedToEmptyString(v.firstName)}${nullOrUndefinedToEmptyString(v.lastName)}${nullOrUndefinedToEmptyString(v.address1)}${nullOrUndefinedToEmptyString(v.email)}${nullOrUndefinedToEmptyString(v.phone)}${nullOrUndefinedToEmptyString(v.postcode)}${nullOrUndefinedToEmptyString(v.title)}`))
          const diffObject = []

          if (removed.length > 0) {
            diffObject.push({
              removed: true,
              value: removed.map(v => `
            Title: ${v.title}
            Name: ${v.firstName} ${v.lastName}
            Email: ${v.email}
            Phone: ${v.phone} 
            Address: ${v.address1} 
            Postcode: ${v.postcode}`).join(', ')
            })
          }
          if (added.length > 0) {
            diffObject.push({
              added: true,
              value: added.map(v => `
            Title: ${v.title}
            Name: ${v.firstName} ${v.lastName}
            Email: ${v.email}
            Phone: ${v.phone} 
            Address: ${v.address1} 
            Postcode: ${v.postcode}`).join(', ')
            })
          }
          if (same.length > 0) {
            diffObject.push({
              value: same.map(v => `
            Title: ${v.title}
            Name: ${v.firstName} ${v.lastName}
            Email: ${v.email}
            Phone: ${v.phone} 
            Address: ${v.address1} 
            Postcode: ${v.postcode}`).join(', ')
            })
          }
          tempObj.contacts = diffObject
          return Object.assign(a, tempObj)
        } else {
          if (v === 'companyNumber') {
            console.log(this.customer[v])
            console.log(this.action.data[v])
          }

          if (this.customer[v] === null || this.customer[v] === undefined) {
            this.customer[v] = ''
          }
          if (this.action.data[v] === null || this.action.data[v] === undefined) {
            this.action.data[v] = ''
          }
        }

        tempObj[v] = diffSentences(this.customer[v].toString(), this.action.data[v].toString())
        return Object.assign(a, tempObj)
      }, {})

      return result
    }
  },
  data () {
    return {
      customer: null,
      customerContacts: [],
      action: { data: null },
      status: '',
      loading: true,
      from: {}
    }
  },
  async mounted () {
    try {
      this.$Progress.start()
      const actionResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/${this.actionId}`, { action: true })

      this.action = actionResponse.data
      this.status = this.action.actionSubClass

      const customerResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Customers/${this.customerId}`, { showerror: true, errormessage: 'Failed to get customer' })

      this.customer = customerResponse.data

      const customerContactsResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Customers/Contacts/${this.customerId}`, { showerror: true, errormessage: 'Failed to get customer contacts' })

      this.customerContacts = customerContactsResponse.data

      this.$Progress.finish()
    } catch {
      this.$Progress.fail()
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.from = from
    })
  }
}
</script>

<style>
</style>
