var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"presubValResults"}},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('div',{staticClass:"idb-block"},[_c('div',{staticClass:"idb-block-title"},[_c('h2',[_vm._v(" Pre-Submission Validation Results "),_c('span',{staticClass:"pull-right"},[_c('a',{attrs:{"href":_vm.helpUrl,"target":"_blank"}},[_c('i',{staticClass:"far fa-question-circle"})])])])]),_c('div',{staticClass:"idb-block-content"},[_c('div',{staticClass:"row form-group"},[(this.totalRecords > 0)?_c('div',{staticClass:"col-md-12"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"lineNumbers":false,"totalRows":_vm.totalRecords,"pagination-options":{
                  enabled: true,
                  perPage: 10,
                  dropdownAllowAll: false
                },"sort-options":{
                  enabled: false
                },"styleClass":"vgt-table striped bordered"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"on-column-filter":_vm.onColumnFilter},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'messageSeverity')?_c('span',[_c('span',{staticClass:"badge badge-pill pull-left",class:_vm.getSeverityClass(props.row.messageSeverity)},[_vm._v(_vm._s(props.row.messageSeverity))])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2532307069)},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.d500",value:('Shows a summary of the validation messages'),expression:"'Shows a summary of the validation messages'",modifiers:{"hover":true,"top":true,"d500":true}}],attrs:{"variant":"link","title":"Create Message Report"},on:{"click":function($event){$event.preventDefault();return _vm.createPresubPdfReport.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-file-pdf pointer dimmedIcon"})]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.d500",value:('Export validation messages to Excel'),expression:"'Export validation messages to Excel'",modifiers:{"hover":true,"top":true,"d500":true}}],attrs:{"variant":"link","title":"Create Message CSV File"},on:{"click":function($event){$event.preventDefault();return _vm.createPresubCsvReport.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-file-excel pointer dimmedIcon"})])],1)])],1):_vm._e()])]),_c('div',{staticClass:"idb-block-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"id":"returnToSummary","type":"button"},on:{"click":_vm.returnToSummary}},[_vm._v("Back to Result Summary")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }