<template>
  <div id="bacsConfig">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>BACS Config</h2>
        </div>
        <div class="idb-block-content">
          <b-tabs v-if="isLoaded" v-on:input="tabSelected" lazy v-model="tabIndex">
            <b-tab title="Payment Network" active>
              <paymentNetwork></paymentNetwork>
            </b-tab>
            <b-tab title="Service Window">
              <serviceWindow></serviceWindow>
            </b-tab>
            <b-tab title="Non-Processing Days">
              <nonProcessingDays></nonProcessingDays>
            </b-tab>
            <b-tab title="Performance Tuning">
              <performanceTuning></performanceTuning>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentNetwork from '@/Views/Bacs/BacsAdmin/PaymentNetwork.vue'
import ServiceWindow from '@/Views/Bacs/BacsAdmin/ServiceWindow.vue'
import NonProcessingDays from '@/Views/Bacs/BacsAdmin/NonProcessingDays.vue'
import PerformanceTuning from '@/Views/Bacs/BacsAdmin/PerformanceTuning.vue'

export default {
  components: {
    paymentNetwork: PaymentNetwork,
    serviceWindow: ServiceWindow,
    nonProcessingDays: NonProcessingDays,
    performanceTuning: PerformanceTuning
  },
  data () {
    return {
      tabIndex: 0,
      isLoaded: true
    }
  },

  methods: {
    tabSelected (tabIndex) {
    }
  },

  async mounted () {
  },

  destroyed () {
    this.$store.dispatch('setPaymentNetworkData', null)
    this.$store.dispatch('setServiceWindowData', null)
    this.$store.dispatch('setEiscdData', null)
    this.$store.dispatch('setPerformanceTuningData', null)
  }
}
</script>
