<template>
  <div id="viewSubmission">
    <div v-show="action.id !== undefined">
      <div v-show="action.id !== '00000000-0000-0000-0000-000000000000'" id="actionDetails">
        <div class="idb-block-title">
          <h2>
            {{ this.action.title }}
            <span class="pull-right">
              <a :href="helpUrl" target="_blank">
                <i class="far fa-question-circle"></i>
              </a>
            </span>
          </h2>
        </div>
      </div>
      <div class="idb-block-content">
        <div v-if="action.id !== '00000000-0000-0000-0000-000000000000'">
          <div class="row form-group">
            <div class="col-md-2">
              <strong>Message</strong>
            </div>
            <div class="col-md-10">{{ this.action.message }}</div>
          </div>
        </div>
        <div v-if="validationWarnings" class="row form-group">
          <div class="col-md-2">
            <strong>Validation Messages</strong>
          </div>
          <div class="col-md-2">
            <span class="badge badge-warning badge-pill">Submission contains {{ this.totalPresubValWarnings }} {{ this.validationTotalText }}</span>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-2">
            <strong>Submission Reference</strong>
          </div>
          <div class="col-md-10">{{ this.submissionReference }}</div>
        </div>
        <div class="row form-group">
          <div class="col-md-2">
            <strong>Group Name</strong>
          </div>
          <div class="col-md-10">{{ this.groupName }}</div>
        </div>
        <div v-if="this.isMultiFile && adminUser && viewMode === 1" class="row form-group">
          <div class="col-md-2">
            <b>Select File</b>
          </div>
          <div class="col-md-6">
            <vue-select
              v-model="selected"
              :options="intelligibleSummaries"
              :closeOnSelect="true"
              :clearable="false"
              label="serviceUserNumber"
              @option:selected="selectSummary()">
            </vue-select>
          </div>
        </div>

        <b-tabs v-if="isLoaded" v-on:input="tabSelected" lazy v-model="tabIndex">
          <b-tab v-if="adminUser && viewMode === 1" title="Submission Totals">
            <submissionTotals @fileSelectionChange="updateFileSelectionChange" @filterChange="filterDropdown"></submissionTotals>
          </b-tab>
          <b-tab :title="summaryTabTitle">
            <intelligibleSummary></intelligibleSummary>
          </b-tab>
          <b-tab :title="paymentsTabTitle">
            <payments></payments>
          </b-tab>
          <b-tab v-if="adminUser && viewMode === 1" title="Standard18">
            <standard18></standard18>
          </b-tab>
          <b-tab title="Submission History">
            <submissionHistory></submissionHistory>
          </b-tab>
          <b-tab v-if="numberOfWarnings > 0">
            <template slot="title">
              Validation Messages <span class="badge badge-warning badge-pill">{{ this.numberOfWarnings }}</span>
            </template>
            <validationWarnings></validationWarnings>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SubmissionTotals from '@/Views/Bacs/Common/SubmissionTotals.vue'
import IntelligibleSummary from '@/Views/Bacs/Common/IntelligibleSummary.vue'
import Payments from '@/Views/Bacs/Common/Payments.vue'
import Standard18 from '@/Views/Bacs/Common/Standard18.vue'
import SubmissionHistory from '@/Views/Bacs/Common/SubmissionHistory.vue'
import ValidationWarnings from '@/Views/Bacs/Common/ValidationWarnings.vue'
import bacsMixin from '@/Lib/BacsMixin.js'
import roles from '@/Assets/Constants/roles'
import VueSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  components: {
    submissionTotals: SubmissionTotals,
    intelligibleSummary: IntelligibleSummary,
    payments: Payments,
    standard18: Standard18,
    submissionHistory: SubmissionHistory,
    validationWarnings: ValidationWarnings,
    VueSelect
  },
  mixins: [
    bacsMixin
  ],
  computed: {
    validationText: function () {
      if (this.numberOfWarnings === 1) {
        return 'validation message'
      } else {
        return 'validation messages'
      }
    },
    validationTotalText: function () {
      if (this.totalPresubValWarnings === 1) {
        return 'validation message'
      } else {
        return 'validation messages'
      }
    },
    adminUser () { return this.$store.getters.isInRole(roles.Finance) || this.$store.getters.isInRole(roles.LZAdmin) || this.$store.getters.isInRole(roles.Implementations) },
    summaryTabTitle () { return this.isMultiFile && this.adminUser ? 'Selected File Summary' : 'Summary' },
    paymentsTabTitle () { return this.isMultiFile && this.adminUser ? 'Selected File Transactions' : 'Transactions' },
    ...mapGetters(['selectedCustomer']),
    submissionReference () { return this.bacsSubmission !== null ? this.bacsSubmission.reference : '' }
  },
  data () {
    return {
      submissionData: {},
      submissionId: '',
      selectedFileNumber: 1,
      serviceUserNumber: '',
      isMultiFile: false,
      intelligibleSummaries: [],
      allIntelligibleSummaries: [],
      contraNarratives: {},
      // Tabs are only part of the index collection if they are visible. So totals tab is tab 0 when all tabs visibile. Intel Summary tab is tab 0 when only showing non L&Z sub contents.
      tabIndex: 0,
      totalsTabIndex: 0,
      summaryTabIndex: 1,
      transactionsTabIndex: 2,
      standard18TabIndex: 3,
      historyTabIndex: 4,
      valMessagesTabIndex: 5,
      validationWarnings: false,
      numberOfWarnings: 0,
      totalPresubValWarnings: 0,
      bacsSubmission: {},
      isLoaded: false,
      viewSubmissionData: {},
      action: {},
      groupName: '',
      tokenType: '',
      messageCountPerFile: {},
      helpUrl: '',
      // selectedFile: '',
      processingAction: true,
      submissionFileDetails: {},
      fileNumber: 0,
      selected: {},
      filterChanged: false,
      customerSubmissions: [],
      viewMode: 1
    }
  },

  methods: {
    tabSelected (tabIndex) {
    },

    updateFileSelectionChange (fileNumber) {
      this.intelligibleSummaries = this.intelligibleSummaries.sort((a, b) => a.fileNumber - b.fileNumber)
      this.selected = this.intelligibleSummaries.find(i => i.fileNumber === fileNumber)
      this.selectSummary()
    },

    filterDropdown (filteredFileNumbers) {
      // console.log('filteredFileNumbers: ' + filteredFileNumbers)
      if (filteredFileNumbers.length > 0) {
        this.intelligibleSummaries = this.allIntelligibleSummaries
        var filteredSummaries = []
        for (var i = 0; i < filteredFileNumbers.length; i++) {
          var fn = filteredFileNumbers[i]
          var tmpSummary = this.intelligibleSummaries.find(i => i.fileNumber === fn)
          // console.log('tmpSummary: ' + JSON.stringify(tmpSummary))
          if (tmpSummary !== undefined) {
            var selectedSub = filteredSummaries.find(i => i.fileNumber === fn)
            if (selectedSub === undefined) {
              filteredSummaries.push(tmpSummary)
            }
          }
        }
        // console.log('filteredSummaries: ' + JSON.stringify(filteredSummaries))
        this.intelligibleSummaries = filteredSummaries
        // console.log('intelligibleSummaries: ' + JSON.stringify(this.intelligibleSummaries))
        this.filterChanged = true

        // console.log('current selected file number: ' + this.selected.fileNumber)
        if (filteredFileNumbers.includes(this.selected.fileNumber)) {
          this.updateFileSelectionChange(this.selected.fileNumber)
        } else {
          this.updateFileSelectionChange(filteredFileNumbers[0])
        }
      }
    },

    selectSummary () {
      // console.log('selectSummary for FN: ' + this.selected.fileNumber)
      this.serviceUserNumber = this.selected.serviceUserNumber
      // this.selectedFile = this.selected.displayName
      this.$store.dispatch('setFileNumber', this.selected.fileNumber)
      this.viewSubmissionData = this.$store.getters.viewSubmissionData
      this.viewSubmissionData.paymentsLoaded = false
      this.viewSubmissionData.presubValMessagesLoaded = false
      this.numberOfWarnings = this.messageCountPerFile[this.selected.fileNumber - 1]
      this.$store.dispatch('setViewSubmissionData', this.viewSubmissionData)

      if (this.adminUser && this.viewMode === 1) {
        if (!this.filterChanged) {
          // Force intelligible summary to reload if user already on the summary tab when a diff SUN selected in dropdown.
          setTimeout(() => {
            this.changeTab(0)
          }, 100)
          setTimeout(() => {
            this.changeTab(1)
          }, 100)
        } else {
          this.changeTab(this.totalsTabIndex)
          this.filterChanged = false
        }
      } else {
      // Tabs are only part of the index collection if they are visible. So totals tab is tab 0 when all tabs visibile. Intel Summary tab is tab 0 when only showing non L&Z sub contents.
      this.changeTab(0)
      }
    },

    changeTab (newTabIndex) {
      this.tabIndex = newTabIndex
    },

    async getContraNarratives () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getContraNarratives?submissionId=${this.submissionId}`, { showload: true })
      this.contraNarratives = response.data
      if (this.contraNarratives.length > 0) {
        this.$store.dispatch('setContraNarratives', this.contraNarratives)
      }
    },

    async getIntelligibleSummaries () {
      var url = `${process.env.VUE_APP_BACS_API_URL}bacs/submission/getBacsIntelligibleSummaries?submissionId=${this.submissionId}`
      var response = await axios.get(url, { showload: true })
      this.intelligibleSummaries = response.data
      this.allIntelligibleSummaries = response.data
      // console.log('summaries: ' + JSON.stringify(this.intelligibleSummaries))
      if (this.intelligibleSummaries.length > 0) {
        this.$store.dispatch('setIntelligibleSummaries', this.intelligibleSummaries)
        this.isMultiFile = this.intelligibleSummaries.length > 1
        this.serviceUserNumber = this.intelligibleSummaries[0].serviceUserNumber
        // this.selectedFile = this.intelligibleSummaries[0].serviceUserNumber
        this.viewSubmissionData.summariesLoaded = true
        this.$store.dispatch('setViewSubmissionData', this.viewSubmissionData)
      }
    },

    async getBacsCustomerSubmissions () {
      var url = `${process.env.VUE_APP_BACS_API_URL}bacs/submission/getBacsCustomerSubmissions?submissionId=${this.submissionId}`
      var response = await axios.get(url, { showload: true })
      this.customerSubmissions = response.data
      if (this.customerSubmissions.length > 0) {
        this.$store.dispatch('setCustomerSubmissions', this.customerSubmissions)
        // console.log('VS customerSubmissions: ' + JSON.stringify(this.customerSubmissions))
      } else {
        // Older submissions viewed via Search Submissions won't have bacscustomersubmission records so populate the sub totals tab with intelligible summary data for now.
        url = `${process.env.VUE_APP_BACS_API_URL}bacs/submission/getBacsSubmissionTotals?submissionId=${this.submissionId}&orderBy=displayname`
        response = await axios.get(url, { showload: true })
        this.customerSubmissions = response.data
        this.$store.dispatch('setCustomerSubmissions', this.customerSubmissions)
      }
    },

    async getGroupName () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getGroupName?submissionId=${this.submissionId}`, { showload: true })
      this.groupName = response.data

      // if (this.action.id !== '00000000-0000-0000-0000-000000000000' || this.adminUser) {
      //   var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getGroupName?submissionId=${this.submissionId}`, { showload: true })
      //   this.groupName = response.data
      // } else {
      //   var response2 = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getFileGroupName?submissionId=${this.submissionId}&fileNumber=${this.fileNumber}`, { showload: true })
      //   this.groupName = response2.data
      // }
    },

    async getBacsSubmission () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getBacsSubmission?submissionId=${this.submissionId}`, { showload: true })
      this.bacsSubmission = response.data
      if (this.bacsSubmission === null) {
        this.$snapbar.e('Failed to return all submission data')
      }
      this.$store.dispatch('setBacsSubmission', this.bacsSubmission)
    },

    async getPresubValidationResult () {
      var presubValResponse = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getPresubValidationResult?submissionId=${this.submissionId}`, { showload: true })
      this.validationWarnings = presubValResponse.data.validationResult === 'Warnings'
      this.messageCountPerFile = presubValResponse.data.presubValMessagesForFile
      // console.log('selectedFileNumber 2: ' + this.selectedFileNumber)
      // this.numberOfWarnings = this.messageCountPerFile[this.selectedFileNumber]
      for (var i = 0; i < this.messageCountPerFile.length; i++) {
        this.totalPresubValWarnings += this.messageCountPerFile[i]
      }
    },

    async getAction () {
      if (this.$store.getters.isInRole(roles.Finance) && this.processingAction) {
        var tmpPaygateId = this.selectedCustomer
        if (tmpPaygateId !== this.bacsSubmission.paygateId) {
          this.$snapbar.w('Please select the London & Zurich customer to process this action.')
        }
      }
      var actionId = this.$store.getters.actionId
      if (actionId !== undefined && actionId !== '') {
        if (actionId !== '00000000-0000-0000-0000-000000000000') {
          var url = `${process.env.VUE_APP_PLATFORM_API_URL}actions/${actionId}`
          var response = await axios.get(url, { action: true })
          this.action = response.data
        } else {
          this.action.id = '00000000-0000-0000-0000-000000000000'
        }
      } else {
        this.action.id = '00000000-0000-0000-0000-000000000000'
      }
    },

    async getBacsEngineConfig () {
      if (this.bacsSubmission !== null) {
        await this.$store.dispatch('getBacsEngineConfig', this.bacsSubmission.paymentNetworkType)
      }
    },

    async getGroupDetails () {
      try {
        // console.log('groupid: ' + this.bacsSubmission.groupId)
        if (this.bacsSubmission !== null) {
          await this.$store.dispatch('getSubBacsGroup', this.bacsSubmission.groupId)
          var bacsGroup = this.$store.getters.bacsGroup
          this.groupName = bacsGroup.name
          this.tokenType = bacsGroup.groupTypeDetails.tokenType
        }
      } catch (err) {
        console.log(err)
      }
    },

    async init () {
      // console.log('init')
      this.$store.dispatch('setParentDataLoaded', false)
      this.submissionId = this.$store.getters.submissionId
      if (this.submissionId === undefined || this.submissionId === null || this.submissionId === '') {
        this.submissionId = this.$route.query.submissionId
        // this.selectedFile = this.$route.query.fileNumber
      }
      this.$store.dispatch('setSubmissionId', this.submissionId)

      this.viewSubmissionData = {
        summariesLoaded: false,
        paymentsLoaded: false,
        standard18Loaded: false,
        moreStnd18: false,
        fileNumber: 0,
        fromRecordNumber: 0,
        toRecordNumber: 0,
        presubValMessagesLoaded: false,
        historyLoaded: false
      }

      this.$store.dispatch('setViewSubmissionData', this.viewSubmissionData)
      this.$store.dispatch('setStandard18', '')
      this.processingAction = this.$route.query.fileNumber === undefined
      await this.getContraNarratives()
      await this.getBacsCustomerSubmissions()
      await this.getIntelligibleSummaries()
      await this.getBacsSubmission()
      await this.getPresubValidationResult()
      await this.getBacsEngineConfig()
      await this.getAction()
      // console.log('actionid: ' + this.action.id)
      if (this.action.id !== '00000000-0000-0000-0000-000000000000') {
        await this.getGroupDetails()
      }
      this.isLoaded = true
      this.$store.dispatch('setParentDataLoaded', true)

      // console.log('sub ID: ' + this.submissionId)
      // console.log('route file number: ' + this.$route.query.fileNumber)
      if (this.$route.query.fileNumber !== undefined) {
        // Accessed page via search submissions or search payments.
        this.processingAction = false
        this.fileNumber = this.$route.query.fileNumber * 1
        if (this.fileNumber > 0) {
          this.viewMode = 2
          // Selected customer is not L&Z. TODO: What happens with connected merchants?
          this.selectedFileNumber = this.fileNumber
          // this.selectedFile = this.intelligibleSummaries.find(i => i.fileNumber === this.fileNumber)
          this.selected = this.intelligibleSummaries.find(i => i.fileNumber === this.fileNumber)
          // console.log('selectedFile: ' + JSON.stringify(this.selectedFile))
          this.selectSummary()
          this.getGroupName()
        } else {
          // Selected customer is L&Z.
          this.selectedFileNumber = this.intelligibleSummaries[0].fileNumber
          this.selected = this.intelligibleSummaries[0]
          this.getGroupName()
        }
        this.numberOfWarnings = this.messageCountPerFile[this.selectedFileNumber - 1]
      } else {
        // Accessed page via an approval action.
        this.selectedFileNumber = this.intelligibleSummaries[0].fileNumber
        // this.selected = this.intelligibleSummaries[0]
        // console.log('selectedFileNumber 1: ' + this.selectedFileNumber)
        this.numberOfWarnings = this.messageCountPerFile[this.selectedFileNumber - 1]
        this.selected = this.intelligibleSummaries[0]
      }

      // console.log('selectedFileNumber: ' + this.selectedFileNumber)
      this.$store.dispatch('setFileNumber', this.selectedFileNumber)

      if (this.action.actionSubClass === 'Sign' || this.action.actionSubClass === 'Commit') {
        if (this.tokenType === 'Hsm') {
          this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/signsubmissionwithhsm/'
        } else if (this.tokenType === 'SmartCard') {
          this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/signsubmissionwithsmartcard/'
        } else if (this.tokenType === 'SecurityCode') {
          this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/commitsms/'
        } else if (this.tokenType === 'UsbToken') {
          this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/commitusbtoken/'
        } else if (this.tokenType === 'None') {
          this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/commitwithoutauthentication/'
        }
      } else if (this.action.actionSubClass === 'Approve') {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/approvesubmission/'
      } else if (this.action.actionSubClass === 'Send') {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/actions/sendsubmission/'
      }
    }
  },
  watch: {
    async selectedCustomer () {
      console.log('changed customer')
      await this.init()
    }
  },
  async created () {
    await this.init()
  }
}
</script>
