<template>
<div class="row">
  <div class="col-md-12">
    <div class="form-group">
      <label>{{label}}</label>
      <input class="form-control" type="number"
        :name="name"
        :value="value"
        :class="{invalid: $v.value.$error}"
        @input="$emit('input',$event.target.value); $v.value.$touch() "
        :placeholder="placeholder">
      <p class="validationText" v-if="!$v.value.numeric && $v.value.$dirty">Numeric only!</p>
      <p class="validationText" v-if="!$v.value.maxValue && $v.value.$dirty">Max Value is {{this.maxValue}}</p>
      <p class="validationText" v-if="!$v.value.minValue && $v.value.$dirty">Min Value is {{this.minValue}}</p>
    </div>
  </div>
</div>
</template>
<script>
import { numeric, maxValue, minValue } from 'vuelidate/lib/validators'
export default {
  name: 'NumberInput',
  props: ['placeholder', 'label', 'name', 'value', 'validationType', 'integer', 'maxValue', 'minValue'],
  validations () {
    const o = {}
    o.value = {}

    // Max length
    if (this.maxValue) {
      o.value.maxValue = maxValue(this.maxValue)
    } else {
      o.value.maxValue = false
    }

    // Min value
    if (this.minValue) {
      o.value.minValue = minValue(this.minValue)
    } else {
      o.value.minValue = false
    }

    // Numeric
    if (this.validationType === 'numeric') {
      o.value.numeric = numeric
    } else {
      o.value.numeric = false
    }
    return o
  },
  mounted () {
    console.log('mounted')
    console.log('value: ' + this.value)
    console.log('minValue: ' + this.minValue)
    console.log('maxValue: ' + this.maxValue)
  }
}
</script>
