<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Service Accounts
          <help-icon docPath="/administration/serviceaccounts/" />
          <favourite-icon />
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :isLoading.sync="isTableLoading"
          :paginationOptions="{
            enabled: true,
            perPage: 10,
            jumpFirstOrLast:true
          }"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          @on-row-click="onRowClick"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <div slot="table-actions">
            <table-actions
              :print-enabled="false"
              :export-enabled="false"
              :reload-table="load"
              :clear-table-filters="clearTableFilters"
              :create-enabled="true"
              :create="create"
              create-tooltip="Create a new service account"
            ></table-actions>
          </div>
        </vue-good-table>
      </div>

      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          @click="create"
          :disabled="disabledDueToConnectedMerchant"
          type="button"
        >
          <i class="fa fa-plus rpad"></i>Create Service Account
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import ConnectedMerchantReadOnlyMixin from '@/Assets/Mixins/ConnectedMerchantReadOnlyMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  mixins: [ConnectedMerchantReadOnlyMixin, tableFilterMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      isTableLoading: true,
      rows: [],
      columns: [
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Username',
          field: 'username',
          filterOptions: {
            enabled: true
          }
        }
      ],
      serverSideTable: false
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  created () {
    this.load()
  },
  methods: {
    onRowClick (params) {
      var serviceAccountId = params.row.serviceAccountId
      this.$router.push({ name: 'ServiceAccountEdit', params: { serviceAccountId } })
    },
    async load () {
      try {
        this.isTableLoading = true
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}serviceaccount/List`, { params: { paygateid: this.selectedCustomer }, showerror: true, errormessage: 'Failed to load Service Accounts' })
        this.rows = response.data
      } finally {
        this.isTableLoading = false
      }
    },
    create () {
      this.$router.push({ name: 'ServiceAccountCreate' })
    }
  }
}

</script>
