export default [
  {
    menu_title: 'Contact Support',
    menu_icon: 'ti-headphone-alt',
    path: '/about/contactsupport'
  },
  {
    menu_title: 'Privacy Policy',
    menu_icon: 'ti-shield',
    path: '/about/privacypolicy'
  },
  // {
  //   menu_title: 'Help',
  //   menu_icon: 'ti-help-alt',
  //   path: '/about/help'
  // },
  {
    menu_title: 'About',
    menu_icon: 'ti-info',
    path: '/about/about'
  }

]
