import axios from 'axios'
export default {
  methods: {
    dateOnfocus () {
      setTimeout((ev) => {
        this.$refs.paymentDate.isOpen || this.$refs.paymentDate.showCalendar(ev)
      }, 250)
    },

    fromDateOnfocus () {
      setTimeout((ev) => {
        this.$refs.createdFrom.isOpen || this.$refs.createdFrom.showCalendar(ev)
      }, 250)
    },

    toDateOnfocus () {
      setTimeout((ev) => {
        this.$refs.createdTo.isOpen || this.$refs.createdTo.showCalendar(ev)
      }, 250)
    },

    saveFile (response, defaultFilename) {
      const blob = new Blob([response.data], { type: 'who/cares' })
      const downloadUrl = window.URL.createObjectURL(blob)
      let filename = ''
      const disposition = response.headers['content-disposition']

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      // The platform API doesn't return the response headers for some reason so can't get the default filename returned by the API.
      // So use a default filename passed into this helper method instead.
      if (filename === '') {
        filename = defaultFilename
      }

      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // Works in IE and Edge.
        window.navigator.msSaveBlob(blob, filename)
      } else {
        // Uses HTML5 file attribute. Works in Chrome, Firefox and Edge.
        var a = document.createElement('a')
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      }
    },

    formatValue (value) {
      try {
        return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } catch (e) {
        return 0
      }
    },

    formatCount (count) {
      try {
        return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '$&,')
      } catch (e) {
        return 0
      }
    },

    async vocalinkLogin () {
      // console.log('mixin vocalinkLogin: ' + this.isLoggedIn)
      if (!this.isLoggedIn) {
        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/vocalinkGetChallengeAndLogin',
          showload: true
        })

        this.authResponse = response.data
      }

      if (this.authResponse.success === true) {
        this.isLoggedIn = true
      } else {
        this.$snapbar.e(this.authResponse.errorMessage)
      }
    },

    async vocalinkLogoff () {
      // console.log('mixin vocalinkLogoff')
      var json = JSON.stringify({
        networkType: this.networkType,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/vocalinkLogoff',
        data: json,
        showload: true
      })

      this.authResponse = response.data
      this.isLoggedIn = false
    },

    async ViewHtmlReport (retrievalUrl) {
      // console.log('mixin ViewHtmlReport: ' + retrievalUrl)
      this.selectedReportFormat = 1
      var json = JSON.stringify({
        networkType: 'BacstelIp',
        downloadUrl: retrievalUrl, // 'getReport.do?reportId=' + reportId,
        downloadFormat: 1,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/viewReport',
        data: json,
        showload: true
      })

      if (response.data !== null && response.data.length > 0) {
        this.reportContent = response.data
        this.modalTitle = 'Report Content'
        this.isViewReportModalVisible = true
      } else {
        this.$snapbar.w('Selected HTML report was not downloaded')
      }
    }
  }
}
