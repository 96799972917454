<template>
  <div id="intelligibleSummary">
    <div class="row form-group">
      <div class="col-md-2"></div>
      <div class="col-md-1 alertValueRight"><strong>Items</strong></div>
      <div class="col-md-2 alertValueRight"><strong>Total</strong></div>
    </div>
    <div class="row form-group">
      <div class="col-md-2"><strong>Credits</strong></div>
      <div class="col-md-1 alertValueRight">{{ formatCount(selectedSummary.creditRecordCount) }}</div>
      <div class="col-md-2 alertValueRight">£{{ formatValue(selectedSummary.creditValue) }}</div>
      <div class="col-md-1"></div>
      <div class="col-md-2"><strong>Submission Type</strong></div>
      <div class="col-md-2">{{submissionType}}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-2"><strong>Debits</strong></div>
      <div class="col-md-1 alertValueRight">{{ formatCount(selectedSummary.debitRecordCount) }}</div>
      <div class="col-md-2 alertValueRight">£{{ formatValue(selectedSummary.debitValue) }}</div>
      <div class="col-md-1"></div>
      <div class="col-md-2"><strong>Service User Number</strong></div>
      <div class="col-md-2">{{selectedSummary.serviceUserNumber}}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-2"><strong>Credit Contras</strong></div>
      <div class="col-md-1 alertValueRight">{{ formatCount(selectedSummary.creditContraCount) }}</div>
      <div class="col-md-2 alertValueRight">£{{ formatValue(selectedSummary.creditContraValue) }}</div>
      <div class="col-md-1"></div>
      <div class="col-md-2"><strong>Work Code</strong></div>
      <div class="col-md-2">{{selectedSummary.workCode}}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-2"><strong>Debit Contras</strong></div>
      <div class="col-md-1 alertValueRight">{{ formatCount(selectedSummary.debitContraCount) }}</div>
      <div class="col-md-2 alertValueRight">£{{ formatValue(selectedSummary.debitContraValue) }}</div>
      <div class="col-md-1"></div>
      <div class="col-md-2"><strong>Processing Date</strong></div>
      <div class="col-md-2">{{processingDate}}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-2"><strong>DDIs</strong></div>
      <div class="col-md-1 alertValueRight">{{ formatCount(selectedSummary.ddiRecordCount) }}</div>
      <div class="col-md-3"></div>
      <div class="col-md-2"><strong>Contra Narrative</strong></div>
      <div class="col-md-2">{{selectedContraNarrative}}</div>
    </div>
  </div>
</template>

<script>
import bacsMixin from '@/Lib/BacsMixin.js'
import loading from '@/Assets/Mixins/LoadingMixin'
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [
    bacsMixin,
    loading
  ],
  data () {
    return {
      submissionId: '',
      actionId: '',
      fileNumber: 1,
      intelligibleSummaries: {},
      contraNarratives: {},
      selectedSummary: {},
      selectedContraNarrative: ''
    }
  },

  computed: {
    // isSystemUser () { return this.$store.getters.isInRole(roles.SystemUser) || this.$store.getters.isInRole(roles.Finance) },
    isSystemUser () { return this.$store.getters.isInRole(roles.LZAdmin) || this.$store.getters.isInRole(roles.Finance) || this.$store.getters.isInRole(roles.Implementations) },    
    submissionType: function () {
      var subType = ''
      if (this.selectedSummary.workCode !== undefined && this.selectedSummary.workCode.indexOf('FPS') > 0) {
        subType = 'Faster Payment'
      } else {
        subType = 'BACS'
      }
      return subType
    },

    processingDate: function () {
      var procDate = ''
      if (this.selectedSummary.processingDate !== undefined) {
        var date = this.selectedSummary.processingDate
        procDate = date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4)
      }
      return procDate
    }
  },

  methods : {
    async setCustomerSubmission () {
      // Just show selected the client user's summary not the whole Stnd18 file block summary.
      var paygateId = this.$store.getters.getClaim('paygate_id').value
      var customerPaygateId = this.$store.getters.customerPaygateId
      // console.log('customerPaygateId: ' + customerPaygateId)
      var customerSubmissions = this.$store.getters.customerSubmissions
      var bacsCustomerSubmission = customerSubmissions.find(i => i.paygateId === customerPaygateId)
      if (bacsCustomerSubmission !== undefined && bacsCustomerSubmission !== null) {
        this.selectedSummary.creditRecordCount = bacsCustomerSubmission.creditRecordCount
        this.selectedSummary.creditValue = bacsCustomerSubmission.creditValue
        this.selectedSummary.creditContraValue = bacsCustomerSubmission.creditValue
        this.selectedSummary.debitRecordCount = bacsCustomerSubmission.debitRecordCount
        this.selectedSummary.debitValue = bacsCustomerSubmission.debitValue
        this.selectedSummary.debitContraValue = bacsCustomerSubmission.debitValue
        this.selectedSummary.ddiRecordCount = bacsCustomerSubmission.ddiRecordCount
      }
    }
  },

  async mounted () {
    this.submissionId = this.$store.getters.submissionId
    this.actionId = this.$store.getters.actionId
    this.fileNumber = this.$store.getters.fileNumber
    this.intelligibleSummaries = this.$store.getters.intelligibleSummaries
    this.selectedSummary = this.intelligibleSummaries.find(i => i.fileNumber === this.fileNumber)
    // console.log('FN: ' + this.fileNumber + ' selectedSummary: ' + JSON.stringify(this.selectedSummary))

    var routeFileNumber = 0
    if (this.$route.query.fileNumber !== undefined) {
      routeFileNumber = this.$route.query.fileNumber * 1
      // console.log('routeFileNumber: ' + routeFileNumber)

      if (routeFileNumber > 0) {
        await this.setCustomerSubmission()
      }
    }

    this.contraNarratives = this.$store.getters.contraNarratives
    // DDI only submissions won't have any contra records.
    if (this.contraNarratives !== undefined && this.contraNarratives.length > 0) {
      const idx = this.fileNumber > 0 ? this.fileNumber - 1 : 0
      this.selectedContraNarrative = this.contraNarratives[idx]
    }
  }
}
</script>
