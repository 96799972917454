import Store from '@/Store/index'
import roles from '@/Assets/Constants/roles'
import licence from "@/Assets/Constants/licence";
export default [
  // Collections starts here
  {
    menu_title: 'Alerts',
    menu_icon: 'ti-alert',
    path: '/collections/alerts',
    counterData: 'collectionsAlertCount',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.ClientAdmin, roles.Standard, roles.Finance, roles.Implementations])
    }
  },
  {
    menu_title: 'Customers',
    menu_icon: 'ti-user',
    path: '/collections/customer/manage',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.Standard, roles.ReadOnly, roles.Finance, roles.Qa, roles.Developer])
    }
  },
  {
    menu_title: 'Submissions',
    menu_icon: 'ti-export',
    path: '/collections/submissions/',
    counterData: 'collectionsSubmissionCount',
    severityData: 'submissionSeverityLevel',
    showMethod: async () => {
      return false
    }
  },
  {
    menu_title: 'Submission History',
    menu_icon: 'ti-time',
    path: '/collections/submissionhistory',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.Finance])
    }
  },
  {
    menu_title: 'Cancel Submission',
    menu_icon: 'ti-time',
    path: '/collections/cancelsubmissions',
    showMethod: async () => {
      return false
    }
  },
  {
    menu_title: 'Messages',
    menu_icon: 'ti-email',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance, roles.Developer, roles.Qa])
    },
    child_routes: [
      {
        path: '/collections/message/queue',
        menu_title: 'Message Queue',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance, roles.Developer, roles.Qa])
        }
      },
      {
        path: '/collections/message/templates',
        menu_title: 'Message Templates',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly])
        }
      },
      {
        path: '/collections/message/grouptemplates',
        menu_title: 'Assign Templates',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin])
        }
      },
      {
        path: '/collections/message/bulkmessaging',
        menu_title: 'Bulk Messaging',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin])
        }
      },
      {
        path: '/collections/letters/out',
        menu_title: 'Letters Outbox',
        showMethod: async () => {
          return false
        }
      },
      {
        path: '/collections/messages/optout',
        menu_title: 'Opt Out',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.Qa, roles.Developer])
        }
      }
    ]
  },
  {
    menu_title: 'Reconciliation',
    menu_icon: 'ti-loop',
    showMethod: async () => {
      return false
      //return Store.getters.isInRole(roles.SystemUser)
    },
    child_routes: [{
      path: '/collections/reconciliation/downloadReports?networkType=BacstelIp',
      menu_title: 'Synchronise BACS Data'
    },
    {
      path: '/collections/reconciliation/report/',
      menu_title: 'History',
      showMethod: async () => {
        return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.Developer, roles.Qa])
      }
    }
    ]
  },
  {
    menu_title: 'Indemnity Challenges',
    menu_icon: 'ti-flag-alt',
    path: '/collections/challenges',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Developer, roles.Qa, roles.Finance])
    }
  },
  {
    menu_title: 'Banking Contacts',
    menu_icon: 'ti-headphone-alt',
    path: '/collections/bankingcontact',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.Finance])
    }
  },
  {
    menu_title: 'Import / Export',
    menu_icon: 'ti-exchange-vertical',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin])
    },
    child_routes: [{
      path: '/collections/data/import',
      menu_title: 'Import Customers',
      showMethod: async () => {
        return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard]) && Store.getters.selectedCustomer !== process.env.VUE_APP_LANDZ_ID && Store.getters.selectedCustomerObject.status !== 'Incomplete'
      }
    },
    {
      path: '/collections/data/importschedule',
      menu_title: 'Import Schedules',
      showMethod: async () => {
        return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard])
      }
    },
    {
      path: '/collections/data/importcollection',
      menu_title: 'Import Collections',
      showMethod: async () => {
        return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard])
      }
    },
    {
      path: '/collections/data/export',
      menu_title: 'Export Data',
      showMethod: async () => {
        return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard])
      }
    },
    {
      path: '/collections/data/variableamount',
      menu_title: 'Update Variable Amounts',
      showMethod: async () => {
        return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard]) && false
      }

    }
    ]
  },
  {
    menu_title: 'Schedules',
    menu_icon: 'ti-calendar',
    showMethod: async () => {
      return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard])
    },
    child_routes: [
      {
        path: '/collections/schedules/designer',
        menu_title: 'Schedule Designer'
      },
      {
        path: '/collections/schedules/plannedchanges',
        menu_title: 'Planned Changes'
      },
      {
        path: '/collections/schedules/suppression',
        menu_title: 'Suppression'
      }
    ]
  },
  {
    menu_title: 'Reports',
    menu_icon: 'ti-bar-chart',
    child_routes: [
      {
        path: '/collections/reporting/lookahead/',
        menu_title: 'Look Ahead Report',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance])
        }
      },
      {
        path: '/collections/reporting/orphaned/',
        menu_title: 'Orphaned Submissions',
        showMethod: async () => {
          return false
        }
      },
      {
        path: '/collections/reporting/indemnityclaims',
        menu_title: 'Indemnity Claims',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance])
        }
      },
      {
        path: '/collections/reporting/indemnitysubmissions',
        menu_title: 'Indemnity Submissions',
        showMethod: async () => {
          // Hiding this as of JP-461, they might ask for it back, so I'm just hiding it for now.
          // I believe it was redundant given that you can already search submissions etc through Mark's stuff.
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance]) && false
        }
      },
      {
        path: '/collections/reporting/refunds',
        menu_title: 'Refunds',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance]) && Store.getters.hasLicence(licence.collectionsRefunds) && false
        }
      },
      {
        path: '/collections/reports/collectionsreport',
        menu_title: 'Collections',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance])
        }
      },
      {
        path: '/collections/reports/collectionschedules',
        menu_title: 'Schedules',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance])
        }
      },
      {
        path: '/collections/reports/collectionsoverview',
        menu_title: 'Collections Overview',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance])
        }
      },
      {
        path: '/collections/reports/failedcollections',
        menu_title: 'Failed Collections',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance])
        }
      },
      {
        path: '/collections/reports/bacsprocessing',
        menu_title: 'BACS Processing',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.Finance])
        }
      },
      {
        path: '/collections/reports/sage',
        menu_title: 'Sage',
        showMethod: async () => {
          return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance])
        }
      }
    ],
    showMethod: async () => {
      return Store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard, roles.Limited, roles.ReadOnly, roles.Finance])
    }
  },
  {
    menu_title: 'Portal',
    menu_icon: 'ti-comments',
    child_routes: [
      {
        path: '/collections/portal/messages/',
        menu_title: 'Inbox',
        counterData: 'collectionsPortalMessages'
      },
      {
        path: '/collections/portal/sendbulk/',
        menu_title: 'Send Bulk Messages'
      },
      {
        path: '/collections/portal/migrate/',
        menu_title: 'Bulk Account Creation'
      }
    ],
    showMethod: async () => {
      return Store.getters.hasLicence(licence.collectionsPayerPortal) && false
    }
  }
]
