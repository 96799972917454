<template>
  <div class="fillHeightContainer">
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0">Customer Map</h4>
    </div>
    <div class="mapContainer">
      <b-container fluid class="flex-grow" style="min-height:100%; height:100%">
        <b-row class="align-self-stretch d-flex flex-grow" style="min-height:100%; height:100%">
          <b-col class="growBox" v-if="!loading">
            <l-map
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              class="mapBox"
              @update:center="centerUpdate"
              @update:zoom="zoomUpdate"
              ref="payerMap"
            >
              <l-tile-layer :url="baseLayer" :attribution="attribution" />
            </l-map>
          </b-col>
          <b-col class="growBox" v-if="loading">Loading...</b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import L, { latLngBounds, latLng } from 'leaflet'
import axios from 'axios'
import 'leaflet.heat'
import { setTimeout, clearInterval } from 'timers'
import Loading from '@/Assets/Components/Loading/Loading'
export default {
  name: 'CustomerMapWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  components: {
    Loading
  },
  data () {
    return {
      layerId: 'mapbox.streets',
      token: 'pk.eyJ1IjoiZGF2aWRob29kY29ydmlkIiwiYSI6ImNqdnhsMHJjbjA1cjY0M21ydGExNnJjcmgifQ.zpMhPUPQfzkLD9NQnCPHrA',
      zoom: 13,
      center: latLng(51.5074, 0.1278),
      url: `https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={token}`,
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>',
      withPopup: latLng(0, 0),
      withTooltip: latLng(0, 0),
      currentZoom: 11.5,
      currentCenter: latLng(0, 0),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        preferCanvas: true
      },
      payers: [],
      heatMapArray: [[50.5, 30.5, 0.2]],
      loading: true,
      heatLayer: null
    }
  },
  async mounted () {
    this.$nextTick(async () => {
      await this.populate()
      this.loading = false
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 250)
    })
  },
  computed: {
    baseLayer () {
      return `https://api.mapbox.com/styles/v1/davidhoodcorvid/cjwg8lne82irk1co87dr5b7j8/tiles/256/{z}/{x}/{y}@2x?access_token=${this.token}`
      // return `https://api.tiles.mapbox.com/v4/${this.layerId}/{z}/{x}/{y}.png?access_token=${this.token}`
    },
    groupId () {
      return this.widget.options.find(o => o.label === 'GroupId').value
    },
    heatMap () {
      return this.widget.options.find(o => o.label === 'Heat Map').value
    }
  },
  methods: {
    async populate () {
      this.loading = true
      let url = this.groupId ? `${process.env.VUE_APP_DDMS_API_URL}widget/payerlocations/${this.groupId}` : `${process.env.VUE_APP_DDMS_API_URL}widget/payerlocations`
      axios.get(url, { params: { paygateid: this.paygateId } })
        .then((response) => {
          this.payers = response.data.payerLocations
          this.$refs['payerMap'].fitBounds(latLngBounds(latLng(response.data.latMin, response.data.lonMin), latLng(response.data.latMax, response.data.lonMax)))
          if (this.heatMap) {
            this.heatMapArray = this.payers.map(x => { return L.latLng(x.lat, x.lon) })
            var zoom = this.$refs.payerMap.mapObject.getZoom()
            zoom = zoom === 0 ? zoom++ : zoom
            this.heatLayer = L.heatLayer(this.heatMapArray, { radius: (10 * zoom) })
            this.heatLayer.addTo(this.$refs.payerMap.mapObject)
          } else {
            var markers = L.markerClusterGroup({ chunkedLoading: true })
            for (var i = 0; i < this.payers.length; i++) {
              var a = this.payers[i]
              var marker = L.marker(L.latLng(a.lat, a.lon), { ukPayerId: a.ukPayerId })
              marker.bindPopup(`<a href="/customer/${a.ukPayerId}/edit" >${a.payerName} (${a.reference})</a>`)
              markers.addLayer(marker)
            }
            this.$refs.payerMap.mapObject.addLayer(markers)
          }
        })
    },
    zoomUpdate (zoom) {
      if (this.heatLayer) {
        this.heatLayer.setOptions({ radius: (10 * this.$refs.payerMap.mapObject.getZoom()) })
        // this.heatLayer.redraw()
      }
      this.currentZoom = zoom
    },
    centerUpdate (center) {
      this.currentCenter = center
    },
    showLongText () {
      this.showParagraph = !this.showParagraph
    },
    innerClick () {
      // click method
    },
    latLong (input) {
      return latLng(input.lat, input.lon)
    },
    getContent (p) {
      return p.ukPayerId
    }
  },
  watch: {
    groupId () {
      this.populate()
    },
    heatMap () {
      this.populate()
    }
  }
}
</script>
<style scoped>
.fillHeightContainer {
  height: 100%;
  flex-direction: column;
  display: flex;
  flex-flow: column;
  width: 100%;
  position: relative;
}
.mapContainer {
  display: flex;
  flex-grow: 1;
}
.mapBox,
.growBox {
  display: flex;
}

.dark-mode .leaflet-popup-content-wrapper, .dark-mode .leaflet-popup-tip {
  background-color: #000000
}
</style>
