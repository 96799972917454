<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>Agents</h2>
    </div>

    <div class="idb-block-content">
      <vue-good-table
        :paginationOptions="paginationOptions"
        :sort-options="sortOptions"
        :isLoading.sync="isTableLoading"
        :rows="rows"
        :columns="columns"
        :lineNumbers="true"
        mode="remote"
        :totalRows="totalRecords"
        @on-row-click="onRowClick"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        :search-options="{
            enabled: true
          }"
        styleClass="vgt-table striped bordered"
        ref="table"
      >
        <div slot="table-actions">
          <button
            @click.prevent="clearTableFilters"
            class="btn btn-link"
            v-b-popover.hover.top.d500="'Clear filters'"
          >
            <span class="fa-stack" style="font-size: 10px;">
              <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
              <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
            </span>
          </button>
          <button
            @click.prevent="exportData"
            class="btn btn-link"
            v-b-popover.hover.top.d500="'Export Data'"
          >
            <i class="fa fa-share-square pointer dimmedIcon"></i>
          </button>
          <button
            @click.prevent="onCreateAgent"
            class="btn btn-link"
            v-b-popover.hover.top.d500="'Create New Agent'"
          >
            <i class="fa fa-plus dimmedIcon pointer"></i>
          </button>
        </div>
      </vue-good-table>
    </div>
    <div class="idb-block-footer">
      <button class="btn btn-primary" @click="onCreateAgent" type="button">
        <i class="fa fa-plus rpad"></i>Create Agent
      </button>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import { mapGetters } from 'vuex'
export default {
  mixins: [tableFilterMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'id',
          field: 'id',
          hidden: true
        },
        {
          label: 'Agent',
          field: 'name',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Active',
          field: 'active',
          sortable: true,
          formatFn: this.FormatActive

        }
      ],
      serverParams: {
        sort: [{ field: 'name', type: 'asc' }]
      }
    }
  },
  methods: {
    async load () {
      try {
        this.isTableLoading = true
        console.log('here')
        console.log(this.buildGoodTableQuery())
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetAgents`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading agents'
          })

        console.log('response', response)
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    onCreateAgent () {
      this.$router.push({ name: 'CommissionAgentCreateAgent' })
    },
    onRowClick (params) {
      var agentid = params.row.id
      console.log(agentid)
      this.$router.push({ name: 'CommissionAgentAdministration', params: { id: agentid } })
    },
    exportData: async function () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetAgentsCSV`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data'
          })

        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Agents.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {
        console.log(e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    FormatActive (field) {
      if (field) {
        return 'yes'
      } else { return 'no' }
    }

  }
}
</script>
