<template>
  <div>
    <div class="row mb-5">
      <div class="col">
        <div ref="editor"></div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <button
          class="btn btn-outline-primary mr-3"
          @click="addNote"
          :disabled="isEmpty"
          type="button"
        >Add Note</button>
      </div>
    </div>

    <transition name="slide-fade">
      <div id="customerNotes">
        <div v-for="(row, index) in rows" :key="index" class="card mb-1">
          <div class="card-header">
            <div class="media flex-wrap align-items-center">
              <avatar
                width="30"
                height="30"
                class="d-block ui-w-40 rounded-circle"
                v-b-popover.hover.top.d500="row.username"
                :user-id="row.userId"
              />
              <div class="media-body ml-3">
                <span>{{row.username}}</span>
                <div
                  class="text-muted small"
                >{{ row.creationDate | formatDate('DD/MM/YYYY HH:mm') }}</div>
              </div>
            </div>
            <div class="card-body" v-html="row.noteText"></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>

import axios from 'axios'
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { mapGetters } from 'vuex'
export default {
  props: {
    chargeId: String,
    canLoad: { type: Boolean, default: false }
  },
  data () {
    return {
      platformApi: process.env.VUE_APP_PLATFORM_API_URL,
      bearerToken: null,
      editor: null,
      rows: []
    }
  },
  async mounted () {
    this.editor = new Quill(this.$refs.editor, {
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, false] }, 'tablecontainer'],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ color: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean']
          ],
          table: true,
          handlers: {}
        },
        history: {
          delay: 2000,
          maxStack: 500,
          userOnly: true
        }
      },
      theme: 'snow'
    })
    await this.load()
  },
  methods: {
    async load () {
      if (this.canLoad) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}charge/notes/${this.chargeId}`, { showload: true, showerror: true, errormessage: 'Charge notes failed to load' })
          this.rows = response.data
        } catch (e) {
          this.$toastr.e('Could not load Charge notes.')
        }
      }
    },
    async addNote (e) {
      try {
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}charge/notes`, { chargeId: this.chargeId, noteText: this.editor.root.innerHTML }, { showload: true, showerror: true, errormessage: 'Charge notes failed to load' })
        this.$toastr.s('A note has been added to the Charge record')
        await this.load()
      } catch (e) {
        this.$toastr.e('An error occurred adding the Charge note.')
      } finally {
        this.editor.setText('')
      }
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    }
  },
  computed: {
    ...mapGetters(['getAccessToken']),
    isEmpty () {
      return this.editor && this.editor.getText().trim().length === 0
    }
  }
}
</script>
<style>
.dark-mode * .ql-stroke {
  stroke: #fff;
}
.dark-mode * .ql-picker-options {
  background: #0000;
}

.slide-fade-enter-active {
  transition: all 0.4s ease-in;
}
.slide-fade-leave-active {
  transition: all 0.2s ease-in;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
