import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'

import currencies from '@/Store/Modules/currencies'
import roles from '@/Store/Modules/roles'
import enums from '@/Store/Modules/enums'
import payer from '@/Store/Modules/payer'
import groups from '@/Store/Modules/groups'
import schedule from '@/Store/Modules/schedule'
import submissions from '@/Store/Modules/submissions'
import messages from '@/Store/Modules/messages'
import collectionsBacs from '@/Store/Modules/collectionsBacsStore'
import bureauCustomer from '@/Store/Modules/bureauCustomer'
import bureauJob from '@/Store/Modules/bureauJob'
import bureau from '@/Store/Modules/bureau'
import workflow from '@/Store/Modules/workflow'
import mapping from '@/Store/Modules/mapping'
import bacsStore from '@/Store/Modules/bacsStore'
import bacsAdmin from '@/Store/Modules/bacsAdminStore'
import tour from '@/Store/Modules/tour'
import collectionsGroupStore from '@/Store/Modules/collectionsGroupStore'
import menuDataStore from '@/Store/Modules/menuDataStore'
import importedCustomerStore from '@/Store/Modules/importedCustomerStore'
import antiFraudListStore from '@/Store/Modules/antiFraudListStore'
import planTemplates from '@/Store/Modules/planTemplates'
import customer from '@/Store/Modules/customer'
import user from '@/Store/Modules/user'
import payerWizard from '@/Store/Modules/collectionsWizardStore'
import collectionsReportStore from '@/Store/Modules/collectionsReportStore'

import common from '@/Assets/Stores'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...common,
    currencies,
    roles,
    enums,
    payer,
    groups,
    schedule,
    submissions,
    messages,
    collectionsBacs,
    bureauCustomer,
    bureauJob,
    bureau,
    workflow,
    mapping,
    bacsStore,
    bacsAdmin,
    tour,
    collectionsGroupStore,
    menuDataStore,
    importedCustomerStore,
    antiFraudListStore,
    planTemplates,
    customer,
    payerWizard,
    collectionsReportStore,
    users: user
  },
  state: {
    appLoading: true
  },
  mutations: {
    initialiseStore (state) {
      // Check if the ID exists
      if (localStorage.getItem('store')) {
        // Replace the state object with the stored item
        var jsonStoreObject = JSON.parse(localStorage.getItem('store'))

        if (!jsonStoreObject.common.clearDate) {
          return
        } else {
          var localStorageDate = moment(jsonStoreObject.common.clearDate)
          var checkClearDate = moment(state.common.clearDate)

          if (localStorageDate.isBefore(checkClearDate)) {
            return
          }
        }

        var storeObject = { common: { ...state.common, ...jsonStoreObject.common } }
        storeObject.common.vueAppVersion = process.env.VUE_APP_VERSION

        this.replaceState(
          Object.assign(state, storeObject)
        )
        this.dispatch('checkPaygateId')
      }
    },
    appFinishedLoading (state) {
      state.appLoading = false
    }
  }
})
