<template>
  <!-- Bank Account-->
  <div class="form-group row">
    <label class="label-control col-md-3 required">Bank Account</label>
    <div class="col-md-5">
      <div class="form-control-plaintext">
        <span style="margin-right:5px;">{{bankAccount.reference.$model}}</span>
      </div>
    </div>
    <div class="col-md-2" v-if="canViewBanks">
      <button class="btn btn-outline-secondary" type="button" @click="viewGroupBankDetails">View</button>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import roles from '@/Assets/Constants/roles'
import bacsCommon from '@/Lib/BacsCommon.js'

export default {
  props: ['bankAccount'],
  computed: {
    canViewBanks () {
      return this.$store.getters.isInRoles([roles.Finance, roles.DevOps, roles.Qa, roles.Developer])
    }
  },
  methods: {
    async viewGroupBankDetails () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.bankAccount.bankAccountId}`, { showload: true, showerror: true, errormessage: 'Failed to get bank account' })
        var bankAccount = response.data
        this.$swal({
          title: 'Bank details',
          html: bacsCommon.viewGroupBankDetails(bankAccount, ''),
          type: 'info',
          animation: false
        })
      } catch { }
    }
  }
}
</script>
