<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Processed Settlements
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row">
              <div class="col-md-12">
                <vue-good-table
                  :pagination-options="{
                      enabled:true,
                      jumpFirstOrLast:true,
                    }"
                  :isLoading.sync="isTableLoading"
                  :rows="rows"
                  :columns="columns"
                  :lineNumbers="true"
                  styleClass="vgt-table striped bordered"
                  ref="table"
                  class="scroll-table"
                >
                  <template slot="table-row" slot-scope="props">
                    <span
                      v-if="props.column.field !== 'name' && props.column.field !== 'actions' && props.column.field !== 'override'"
                      :class="tdClass(props.row, props.column.field)"
                    >{{props.formattedRow[props.column.field]}}</span>
                    <span v-else-if="props.column.field === 'override'">
                      <select
                        v-model="rows[props.row.originalIndex].selectedPaymentMethod"
                        class="form-control"
                      >
                        <option selected="selected" value>Default</option>
                        <option
                          v-for="type in props.row.allowedPaymentMethods"
                          :key="type"
                          :value="type"
                        >{{ type }}</option>
                      </select>
                    </span>
                    <span v-else-if="props.column.field === 'actions'">
                      <button
                        class="btn btn-outline-danger"
                        type="button"
                        @click.prevent="reverse(props.row,$event)"
                      >Reverse</button>
                      <button
                        class="btn btn-link"
                        type="button"
                        @click.prevent="details(props.row,$event)"
                      >Details</button>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </div>
            <div class="row"></div>
          </div>
          <div class="idb-block-footer">
            <button
              class="btn btn-primary"
              type="submit"
              id="submit-btn"
              @click="processSettlements"
              :disabled="isLoading"
            >Process Settlements</button>
          </div>
        </div>
      </div>
    </div>
    <settlement-details :params="detailParams" :loadUrl="detailLoadUrl" :name="name"></settlement-details>
    <reverse-settlement-details :settlement="selectedSettlement" @reversed="reversed"></reverse-settlement-details>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'

// Modals
import SettlementDetails from '@/Components/Platform/Customer/Settlements/Details/SettlementDetails'
import ReverseSettlementDetails from '@/Components/Platform/Customer/Settlements/Details/ReverseSettlementDetails'

export default {
  mixins: [loading, settlementMixin],
  components: {
    SettlementDetails, ReverseSettlementDetails
  },
  name: 'Settlement',
  computed: {
    detailLoadUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}Settlement/Processed/Details`
    }
  },
  data () {
    return {
      date: null,
      rows: [],
      columns: [
        {
          label: 'Client Name',
          field: 'clientName'
        },
        {
          label: 'Settlement Type',
          field: 'type',
          sortable: true
        },
        {
          label: 'Created Date',
          field: 'creationDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Collection Amount (£)',
          field: 'collectionAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Failed Collection Amount (£)',
          field: 'failedCollectionAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Indemnity Claim Amount (£)',
          field: 'indemnityClaimAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Netdown Amount (£)',
          field: 'netdownAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        // {
        //   label: 'Refund Amount (£)',
        //   field: 'refundAmount',
        //   formatFn: this.moneyFormat,
        //   tdClass: 'text-right'
        // },
        {
          label: 'Connected Merchant Charges (£)',
          field: 'connectedMerchantCharges',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          hidden: true
        },
        {
          label: 'Service Charges (£)',
          field: 'serviceCharges',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          hidden: true
        },
        {
          label: 'VAT (£)',
          field: 'vatAmount',
          formatFn: this.moneyFormat,
          tdClass: 'text-right',
          type: 'number',
          hidden: true
        },
        {
          label: 'Client Settlement Payment (£)',
          field: 'clientSettlementPayment',
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Payment Settlement Method',
          field: 'override',
          sortable: false
        },
        {
          label: '',
          field: 'actions',
          sortable: false
        }
      ],
      disableSubmission: true,
      serverParams: {
        sort: [{ field: 'creationDate', type: 'desc' }]
      },
      detailParams: null,
      selectedSettlement: null,
      name: null,
      loaded: false
    }
  },
  methods: {
    async load () {
      // Load stuff in
      try {
        this.$snapbar.hide()
        this.isTableLoading = true
        this.loaded = false
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/Processed/List`, {
          showload: true,
          showerror: true,
          errormessage: 'Settlements failed to load'
        })

        this.rows = response.data.map(d => { return { ...d, selectedPaymentMethod: '' } })

        this.loaded = true
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    details (row, event) {
      event.stopPropagation()
      this.detailParams = {
        settlementId: row.id,
        paygateid: row.paygateId
      }
      this.name = row.clientName

      this.$bvModal.show('settlementModal')
    },
    reverse (row, event) {
      event.stopPropagation()
      this.selectedSettlement = row
      this.$bvModal.show('reverseSettlementModal')
    },
    reversed () {
      var index = this.rows.findIndex((r) => r.id === this.selectedSettlement.id)
      if (index > -1) {
        this.rows.splice(index, 1)
      }
      this.selectedSettlement = null
    },
    async processSettlements () {
      var requestData = this.rows.map(r => {
        return {
          id: r.id,
          paymentMethod: r.selectedPaymentMethod === '' ? null : r.selectedPaymentMethod
        }
      })
      await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Settlement/Processed/Process`, { SettlementsWithPaymentTypes: requestData }, {
        showload: true,
        showerror: true,
        errormessage: 'Settlements failed to process'
      })
      await this.load()
    }

  },
  async created () {
    this.load()
  }
}

</script>

<style lang="scss" scoped>
</style>
