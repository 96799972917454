<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Edit Bankline Ad-hoc Payment<span class="pull-right"><a :href="`${docUrl}/automation/logging/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h2>
        </div>
        <div class="idb-block-content">
          <div><strong>Debit Account</strong></div>
          <br/>
          <!-- Bank Account -->
          <div class="form-group row">
            <label class="label-control col-md-3 required">Debit Bank Account</label>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
              <b-form-select
                v-model="banklinePayment.bankAccountId"
                :options="bankAccounts"
              ></b-form-select>
            </div>
            <div class="col-md-1">
              <div v-if="isBanksLoading" class="spinner-border spinner-border-sm mt-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <br/>
          <div class="form-group row"  :class="{invalid: $v.banklinePayment.description.$error}">
            <div class="col-md-3 col-sm-12">
              <label v-b-popover.hover.top.d500="'Optional, freeform text that describes the payment.  The text is not sent to Bankline but can be used to provide details about the payment in reports, etc.'" >Payment Description</label>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <b-form-textarea
                  type="text"
                  rows="4"
                  placeholder=""
                  v-model.trim="banklinePayment.description"
                ></b-form-textarea>
              <p class="validationText" v-if="!$v.banklinePayment.description.maxLength">Maximum length is {{ $v.banklinePayment.description.$params.maxLength.max }} characters!</p>
              </div>
          </div>
          <br/>
          <div class="form-group row">
            <label class="label-control col-md-3">Payment ID</label>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              {{ banklinePayment.customerPaymentReference }}
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-3">Payment Type</div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <span v-if="banklinePayment.recordType === '01'">
                STD
              </span>
              <span v-else-if="banklinePayment.recordType === '02'">
                URG (CHAPS)
              </span>
            </div>
          </div>
          <div class="form-group row" :class="{invalid: $v.banklinePayment.paymentAmount.$error}">
            <div class="col-md-3 col-sm-12">
              <label class="required">Payment Amount</label>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <div class="input-group">
                <span class="input-group-text">£</span>
                <input
                  type="number"
                  class="form-control text-right"
                  min="0"
                  step="any"
                  @input="$v.banklinePayment.paymentAmount.$touch(); updateAmt();"
                  v-model.trim.number="banklinePayment.paymentAmount"
                />
                </div>
                <p class="validationText" v-if="!$v.banklinePayment.paymentAmount.required && $v.banklinePayment.paymentAmount.$dirty ">The payment amount is required!</p>
                <p class="validationText" v-if="!$v.banklinePayment.paymentAmount.minValue && $v.banklinePayment.paymentAmount.$dirty ">The minimum payment value is £{{ $v.banklinePayment.paymentAmount.$params.minValue.min }}</p>
                <p class="validationText" v-if="!$v.banklinePayment.paymentAmount.maxValue && $v.banklinePayment.paymentAmount.$dirty ">The maximum payment value is £{{ $v.banklinePayment.paymentAmount.$params.maxValue.max }}</p>
            </div>
          </div>
          <br/>
          <div class="form-group row" :class="{invalid: $v.banklinePayment.arrivalDate.$error}">
            <div class="col-md-3 col-sm-12">
              <label class="required" v-b-popover.hover.top.d500="'The date that the money should be in the beneficiaries account.'" >Arrival Date</label>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <div class="input-group">
                <div class="right-inner-addon">
                  <datepicker
                    id="paymentDatePicker"
                    format="dd/MM/yyyy"
                    :bootstrap-styling="bootstrapStyling"
                    :typeable="typeable"
                    v-model="banklinePayment.arrivalDate"
                    :disabled-dates="disabledDates"
                  />
                  <i class="fa fa-calendar form-control-feedback"></i>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <br/>
          <div><strong>Beneficiary</strong></div>
          <br>
          <div class="form-group row">
            <label class="label-control col-md-3 required">Charge to</label>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <b-form-select
                v-model="banklinePayment.selectedCustomerId"
                :options="customers"
                @input="$v.banklinePayment.selectedCustomerId.$touch()">
              </b-form-select>
              <p class="validationText" v-if="!$v.banklinePayment.selectedCustomerId.required && $v.banklinePayment.selectedCustomerId.$dirty">A customer selection is required!</p>
            </div>
          </div>
          <div class="form-group row" :class="{invalid: $v.banklinePayment.accountWithBankIdentifier.$error}">
            <label class="label-control col-md-3 required">Beneficiary Sortcode</label>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-9">
              <the-mask
                type="text"
                class="form-control"
                v-model="banklinePayment.accountWithBankIdentifier"
                :mask="['##-##-##']"
                :guide="true"
                @input="$v.banklinePayment.accountWithBankIdentifier.$touch()"
                ref="sortCode" />
              <validation-messages
                v-model="$v.banklinePayment.accountWithBankIdentifier"
                name="beneficiary sort code"
              ></validation-messages>
            </div>
            <div v-if="bankAccountValidation != null">
              <div
                v-if="bankAccountValidation.valid || bankAccountValidation.errorCode === '1502'"
                class="col-md-1">
                <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
              </div>
              <div v-else class="col-md-1">
                <span class="text-warning small text-nowrap">{{bankAccountValidation.errorText}}</span>
              </div>
            </div>
          </div>
          <div class="form-group row" :class="{invalid: $v.banklinePayment.beneficiaryAccountNumber.$error}">
            <label class="label-control col-md-3 required">Beneficiary Account Number</label>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-9">
              <the-mask
                type="text"
                class="form-control"
                v-model="banklinePayment.beneficiaryAccountNumber"
                :mask="['########']"
                :guide="false"
                @input="$v.banklinePayment.beneficiaryAccountNumber.$touch()"
                ref="accountNumber" />
              <validation-messages
                v-model="$v.banklinePayment.beneficiaryAccountNumber"
                name="beneficiary account number"
              ></validation-messages>
            </div>
            <div v-if="bankAccountValidation != null">
              <div v-if="bankAccountValidation.valid" class="col-md-1">
                <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
              </div>
              <div v-else-if="bankAccountValidation.errorCode === '1502'" class="col-md-1">
                <span class="text-warning small text-nowrap">{{bankAccountValidation.errorText}}</span>
              </div>
            </div>
          </div>
          <div class="form-group row" :class="{invalid: $v.banklinePayment.beneficiaryReference.$error}">
            <label class="label-control col-md-3">Beneficiary Reference</label>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <input
                id="name"
                type="text"
                class="form-control"
                @input="$v.banklinePayment.beneficiaryReference.$touch()"
                v-model.trim="$v.banklinePayment.beneficiaryReference.$model" />
              <p class="validationText" v-if="!$v.banklinePayment.beneficiaryReference.maxLength">Maximum length is {{ $v.banklinePayment.beneficiaryReference.$params.maxLength.max }} characters!</p>
              <p class="validationText" v-if="!$v.banklinePayment.beneficiaryReference.regexValidator">The field includes invalid characters! Allowed characters are A-Z 0-9 . - / &</p>
            </div>
          </div>
          <div class="form-group row" :class="{invalid: $v.banklinePayment.beneficiaryNameAndAddressLineNumber1.$error}">
            <label class="label-control col-md-3 required">Beneficiary Name</label>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <input
                id="name"
                type="text"
                class="form-control"
                @input="$v.banklinePayment.beneficiaryNameAndAddressLineNumber1.$touch()"
                v-model.trim="$v.banklinePayment.beneficiaryNameAndAddressLineNumber1.$model" />
              <p class="validationText" v-if="!$v.banklinePayment.beneficiaryNameAndAddressLineNumber1.required && $v.banklinePayment.beneficiaryNameAndAddressLineNumber1.$dirty ">The Beneficiary Name field is required!</p>
              <p class="validationText" v-if="!$v.banklinePayment.beneficiaryNameAndAddressLineNumber1.maxLength">Maximum length is {{ $v.banklinePayment.beneficiaryNameAndAddressLineNumber1.$params.maxLength.max }} characters!</p>
              <p class="validationText" v-if="!$v.banklinePayment.beneficiaryNameAndAddressLineNumber1.regexValidator">The field includes invalid characters! Allowed characters are A-Z 0-9 . - / &</p>
            </div>
          </div>
          <br/>
          <br/>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div class="btn-group" role="group" v-if="isEditable">
                <button :disabled="processing" @click="btnUpdateBanklinePayment(false)" type="button" class="btn btn-primary">
                  Update Bankline Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Datepicker from 'vuejs-datepicker'
import { TheMask } from 'vue-the-mask'
import _ from 'lodash'
import { required, maxLength, helpers, minValue, maxValue } from 'vuelidate/lib/validators'
import { exactLength } from '@/Assets/Validators'
import CustomerSelector from './../../../../Assets/Components/CustomerSelector.vue'
import customerTypes from '@/Assets/Constants/customerTypes'
const regexValidator = helpers.regex('regexval', /^[A-Z0-9./&\s-]*$/i)

function customerTypeToNumber (type) {
  switch (type) {
    case customerTypes.general:
      return 0
    case customerTypes.connectedMerchant:
      return 1
    case customerTypes.master:
      return 2
    default:
      return 0
  }
}

export default {
  name: 'banklinepayment',
  components: {
    TheMask,
    CustomerSelector,
    Datepicker
  },
  data () {
    return {
      processing: false,
      isEditable: true,
      id: this.$route.params.id,
      isCustomer: false,
      isBanksLoading: true,
      bankAccounts: [],
      customers: [],
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      disabledDates: this.getDisabledDays(),
      typeable: false,
      bootstrapStyling: true,
      banklineFile: {},
      banklinePayment: {},
      bankAccountValidation: null,
      banklineConfig: {},
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '£ ',
        precision: 2,
        masked: false
      }
    }
  },
  validations () {
    return {
      banklinePayment: {
        paymentAmount: {
          required,
          minValue: minValue(0.01),
          maxValue: maxValue(this.banklineConfig.maximumStdIndividualAmount)
        },
        arrivalDate: {
          required
        },
      accountWithBankIdentifier: { required, exactLength: exactLength(6) },
      beneficiaryAccountNumber: { required, exactLength: exactLength(8) },
        customerPaymentReference: { required, maxLength: maxLength(18), regexValidator },
        beneficiaryNameAndAddressLineNumber1: { required, maxLength: maxLength(35), regexValidator },
        beneficiaryReference: { maxLength: maxLength(18), regexValidator },
        description: { maxLength: maxLength(1824) },
        selectedCustomerId: { required }
      }
    }
  },
  methods: {
    updateAmt () {
      // bs function to keep the amt to 2 places of decimal and just generally behave.
      const dp = this.banklinePayment.paymentAmount.toString().indexOf('.')
      if (dp < 0) {
        this.banklinePayment.paymentAmount = this.banklinePayment.paymentAmount.toFixed(2)
        return
      }
      const ol = this.banklinePayment.paymentAmount.toString().length - dp
      if (ol === 2) {
        this.payment.paymentAmount = this.payment.paymentAmount.toFixed(2)
      }
      if (ol > 2) {
        this.banklinePayment.paymentAmount = +(this.banklinePayment.paymentAmount.toString().substr(0, dp + 3))
        this.banklinePayment.paymentAmount = this.banklinePayment.paymentAmount.toFixed(2)
      }
    },
    async load () {
      let res
      try {
        console.log('id', this.id)
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}banklinepaymentrow/${this.id}`)
        // console.log(res)
        if (res && res.data) {
          this.banklineFile = res.data.banklineFile
          this.banklinePayment = res.data.banklinePayment

          console.log(this.banklinePayment)
          this.banklinePayment.paymentAmount = this.banklinePayment.pa

          if (this.banklineFile.status === 12) {
            this.$snapbar.w('Warning: This payment has previously been downloaded!')
          }
          if (this.banklineFile.archive === true) {
            this.isEditable = false
            this.$snapbar.e('This payment is not editable because it has been flagged as being sent to Bankline.')
          }

          // Don't want payments from Settlement to be editable
          if (res.data.banklinePayment && res.data.banklinePayment.metaData) {
            if (res.data.banklinePayment.metaData.cpr === 'SETTLEMENT') {
              this.isEditable = false
              this.$snapbar.e('This payment is not editable.')
            }
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
      } finally {
      }
    },
    async getBanklineConfig () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineconfig`)
        if (res) {
          console.log(res)
          this.banklineConfig = res.data.responseObj.banklineEngineConfig
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline config data.')
        } else {
          this.$snapbar.e(`Could not get bankline configuration - ${e.message}`)
        }
      }
    },
    getDisabledDays () {
      // Date range disabling for the date picker
      let to = new Date()
      to.setDate(to.getDate() - 1)
      let from = new Date()
      // Not mentioned in the requirements by setting a 31 days future date seems appropriate.  31 is totally arbitary and can be changed
      from.setDate(from.getDate() + 31)
      return {
        to,
        from
      }
    },
    async loadBankAccounts () {
      try {
        this.isBanksLoading = true
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/Dropdown`, { showerror: true, errormessage: 'Failed to get bank accounts' })
        this.isBanksLoading = false
        this.bankAccounts = response.data.map(bankAccount => {
          return { value: bankAccount.bankAccountId, text: bankAccount.reference }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async loadCustomers () {
      // Mostly stolen from the customerSelector component
      try {
        let customers = this.$store.getters.customersOrdered
        customers = customers.sort((a, b) => {
          var typeA = customerTypeToNumber(a.type)
          var typeB = customerTypeToNumber(b.type)
          return (typeA > typeB) ? -1 : (typeA < typeB) ? 1 : 0
        })

        var result = []
        customers.forEach((c, i) => {
          if (i === 0) {
            result.push({ group: `${c.type} Customers`, name: '' })
          }
          if (i > 0) {
            var previousC = customers[i - 1]
            if (c.type !== previousC.type) {
              result.push({ group: `${c.type} Customers`, name: '' })
            }
          }
          result.push(c)
        })
        this.customers = customers.map(customer => {
          return { value: customer.paygateId, text: customer.name, type: customer.type }
        })
      } catch (e) {
        console.log(e)
      }
    },
    reformatCurrency (c) {
      // if (!c) return ''
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      })
      return formatter.format(c)
    },
    digitsOrDashes: function () {
      // Modchecking
      if (!this.$v.banklinePayment.accountWithBankIdentifier.$invalid && !this.banklinePayment.beneficiaryAccountNumber.$invalid) {
        this.validate()
      }
    },
    validate: _.debounce(async function () {
      console.log('validate')
      // Modchecking
      const data = { sortCode: this.banklinePayment.accountWithBankIdentifier, accountNumber: this.banklinePayment.beneficiaryAccountNumber }
      try {
        const response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/ValidateBankAccount`, data, { showerror: true, errormessage: 'Failed to get validate bank account' })

        this.bankAccountValidation = response.data
        console.log(this.bankAccountValidation)
      } catch { }
    }, 800),
    async setArrivalDate () {
      console.log('h1')
      this.banklinePayment.arrivalDate = new Date()
    },
    async btnUpdateBanklinePayment (standalone) {
      this.$snapbar.hide()
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$toastr.e('There are validation errors on the form', 'Validation')
        return
      }
      this.processing = true
      this.banklinePayment.paymentAmount = +(this.banklinePayment.paymentAmount)
      console.log('patch')
      try {
        const response = await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}banklinepayment`, this.banklinePayment)
        this.processing = false
        if (response.data.success === true) {
          this.$snapbar.s('Success.  The Bankline payment was updated.')
        } else {
          this.$snapbar.e('Error.  The Bankline payment was not updated.')
          console.log(response)
        }
      } catch (e) {
        this.processing = false
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to update a bankline payment.')
        } else if (e.response && e.response.status === 400) {
          this.$snapbar.e('Error updating Bankline Payment: ' + e.response.data.errorMessage)
        } else {
          this.$snapbar.e(`Error - ${e.message}`)
        }
      }
    }
  },
  async created () {
    await this.getBanklineConfig()
  },
  async mounted () {
    await this.loadBankAccounts()
    await this.loadCustomers()
    await this.setArrivalDate()
    await this.load()
  },
  watch: {
    'banklinePayment.accountWithBankIdentifier': function () {
      this.digitsOrDashes()
    },
    'banklinePayment.beneficiaryAccountNumber': function () {
      this.digitsOrDashes()
    }
  }
}
</script>

<style scoped>
  .validation-messages {
    color: red;
    font-size: medium;
    font-weight:800;
  }
  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
  .right-inner-addon {
    position: relative;
  }
  .right-inner-addon input {
    padding-left: 1px;
  }
  .right-inner-addon i {
    position: absolute;
    top: 6px;
    right: 15px;
  }

  input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type="number"]{
        -moz-appearance: textfield;
    }
</style>
