<template>
  <div>
    <div id="payerSetupForm">
      <div class="row mb-4">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>
                <help-icon docPath="/paygate-collections/payers/managepayers/" />Search Customers
                <favourite-icon></favourite-icon>
              </h2>
            </div>
            <div class="idb-block-content">
              <b-form-row>
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  horizontal
                  label="Criteria"
                  label-for="groupInput"
                >
                  <b-form-input id="groupInput" v-model="searchText" type="text" />
                </b-form-group>
              </b-form-row>
              <div class="row form-group">
                <div class="col-md-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="search"
                  >Search</button>
                </div>
                <div class="col-md-10">
                  <button
                    type="button"
                    class="btn btn-outline-primary pull-right"
                    v-on:click="clearSearch"
                  >Clear Search Criteria</button>
                </div>
              </div>
              <div class="row form-group" :style="showResults ? 'display: block;' : 'display:none'">
                <div class="col-md-12">
                  <vue-good-table
                    mode="remote"
                    ref="table"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="preOnColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"
                    @on-row-click="onRowClick"
                    @on-cell-click="onCellClick"
                    :columns="columns"
                    :rows="inactivePayers"
                    :lineNumbers="true"
                    :totalRows="totalRecords"
                    :search-options="{
                    enabled: false
                    }"
                    :paginationOptions="paginationOptions"
                    :sort-options="sortOptions"
                    :rowStyleClass="rowToCss"
                    :isLoading.sync="isTableLoading"
                    styleClass="vgt-table striped bordered"
                    class="scroll-table"
                  >
                    <template slot="loadingContent">
                      <h1>Loading...</h1>
                    </template>
                    <div slot="emptystate">
                      <div class="vgt-center-align vgt-text-disabled">No Customers Available</div>
                    </div>
                    <div slot="table-actions">
                      <button
                        @click.prevent="clearTableFilters"
                        class="btn btn-link"
                        v-b-popover.hover.top.d500="'Clear filters'"
                      >
                        <span class="fa-stack" style="font-size: 10px;">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span>
                      </button>
                      <b-button
                        @click.prevent="load"
                        class
                        :disabled="isLoading"
                        variant="link"
                        v-b-popover.hover.top.d500="'Refresh the data in the table'"
                      >
                        <i class="fa fa-sync pointer dimmedIcon"></i>
                      </b-button>
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field === 'payerStatus'">
                        <b-badge
                          pill
                          :variant="getBadgeClass(props.row.payerStatus)"
                        >{{props.formattedRow[props.column.field]}}</b-badge>
                      </span>
                      <span v-else-if="props.column.field === 'lastActivity'">
                        <router-link :to="`/collections/customer/${props.row.ukPayerId}/history/${props.row.payerHistoryId}`">{{props.formattedRow[props.column.field]}}</router-link>
                      </span>
                      <span v-else-if="props.column.field === 'buttons'">
                        <b-button
                          v-if="props.row.plans.length === 1"
                          @click="goToSchedule(props.row.ukPayerId, props.row.plans[0].planId)"
                          variant="outline-secondary" class="btn-block"
                        >
                          <i class="fa fa-calendar mr-2"></i>View Schedule
                        </b-button>
                        <b-button
                          v-if="props.row.plans.length > 1"
                          @click="goToSchedules(props.row.ukPayerId, props.row.plans)"
                          variant="outline-secondary" class="btn-block"
                        >
                          <i class="fa fa-calendar mr-2"></i>View Schedules
                        </b-button>

                         <b-dropdown v-if="props.row.plans.length > 1 && false" variant="outline-secondary">
                          <!-- no-caret -->
                          <span slot="button-content">
                            <i class="fa fa-calendar mr-2"></i>View Schedules
                          </span>
                          <b-dropdown-item
                            v-for="(plan, index) in props.row.plans"
                            :key="index"
                            @click="goToSchedule(props.row.ukPayerId, plan.planId)"
                          >
                            {{plan.planName}}
                            <i
                              v-if="plan.planStatus === 2"
                              v-b-tooltip.hover
                              title="Suspended"
                              class="fa fa-exclamation-circle text-danger"
                            ></i>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                      <span class="tab-scroll" v-else>{{props.formattedRow[props.column.field]}}</span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import _ from 'lodash'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import axios from 'axios'
import { mapGetters } from 'vuex'
import roles from "@/Assets/Constants/roles"
import swal from 'sweetalert2'
import colours from "@/Assets/Constants/colours";
const statusToText = (x) => {
  switch (x) {
    case -1:
      return 'Any'
    case 0:
      return 'Operational'
    case 1:
      return 'Suspended'
    case 2:
      return 'Alert'
    case 3:
      return 'Closed'
    case 4:
      return 'Incomplete'
    case 5:
      return 'Awaiting Activation'
    case 6:
      return 'Plan Suspended'
    case 7:
      return 'Deleted'
    case 8:
      return 'Lodgement Pending'
    default:
      return 'Unknown'
  }
}

const sourceToText = (x) => {
  switch (x) {
    case 0:
      return 'Customer Wizard'
    case 1:
      return 'File Import'
    case 2:
      return 'Public Api'
    case 3:
      return 'Online Signup Form'
    case 4:
      return 'Unknown'
    case 5:
      return 'Migration'
    default:
      return 'Unknown'
  }
}

const statusToBadgeVariant = (x) => {
  switch (x) {
    case 3:
      return 'secondary'
    case 2:
    case 6:
      return 'warning'
    case 1:
    case 7:
      return 'danger'
    case 0:
      return 'success'
    case 4:
    case 5:
    case 8:
      return 'info'
    default:
      return 'dark'
  }
}

export default {
  mixins: [tableFilterMixin, loading],
  name: 'payerSearch',
  async mounted () {
    this.showResults = false
    await this.$store.dispatch('setPaygateId', process.env.VUE_APP_LANDZ_ID)
    var savedParams = this.$store.getters.tableFilters(this.$route.path)
    if (savedParams) {
      // If there are, apply them!
      this.savedParamsToObjects(savedParams)
      this.serverParams = Object.assign({}, this.serverParams, savedParams)
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    clientIsInactive () {
      return this.$store.getters.selectedCustomerObject.status === 'Incomplete'
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.rightsGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.rightsGroups).map(x => {
          const obj = {}
          obj.groupId = x.groupId
          obj.name = x.name
          obj.colour = x.colour
          return obj
        })
      }

      if (ret.length > 1) {
        ret.unshift({ description: '', groupId: '00000000-0000-0000-0000-000000000000', groupType: 'UK DDMS', name: 'All Customers', paygateId: this.paygateId, isItemActioned: false, clonedName: null, colour: null })
      }
      if (ret.length === 0) {
        ret.unshift({ description: '', groupId: '00000000-0000-0000-0000-000000000000', groupType: 'UK DDMS', name: 'No Collections Groups Available', paygateId: this.paygateId, isItemActioned: false, clonedName: null, colour: null })
      }
      return ret
    },
    inactivePayers () {
      return this.$store.state.payer.inactivePayers
    },
    group: {
      get () {
        return this.$store.state.payer.currentPayer.groupId
      },
      set (value) {
        if (value) {
          this.$store.commit('updateGroup', value)
        }
      }
    },
    allGroups () {
      return !this.group || this.group === undefined || this.group === null || this.group === '00000000-0000-0000-0000-000000000000' || this.customerGroups.find(x=>x.groupId === this.group) === undefined
    },
    ...mapGetters(['isParentConnectedMerchant']),
    canCreate () {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard])
    }
  },
  props: {
    prepopulatedGroup: String,
    prepopulatedSearchTerm: String,
    prepopulatedStatus: String
  },
  data () {
    return {
      statusFilter: parseInt(this.prepopulatedStatus, 10) ? parseInt(this.prepopulatedStatus, 10) : -1, // if fails to parse to int w/ radix 10 then default to value '3'
      statuses: [-1, 0, 1, 2, 3, 4, 5, 8].map(i => ({ name: statusToText(i), value: i })), // Server side enum values
      searchText: '',
      selectedUkPayer: {},
      groupValid: false,
      ukPayerSelected: false,
      columns: [
        {
          label: 'id',
          field: 'ukPayerId',
          hidden: true
        },
        {
          label: 'Client',
          field: 'clientName'
        },
        {
          label: 'Reference',
          field: 'reference'
        },
        {
          label: 'Setup Date',
          field: 'setupDate',
          formatFn: this.formatDate
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Phone',
          field: 'mobileNumber'
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Address',
          field: 'address'
        },
        {
          label: 'Postcode',
          field: 'postCode'
        },
        {
          label: 'Customer Status',
          field: 'payerStatus',
          formatFn: statusToText,
          tdClass: 'text-center'
        },
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
          tdClass: 'text-center'
        }
      ],
      incompleteCount: 0,
      serverParams: {
        sort: [{ field: 'setupDate', type: 'desc' }]
      },
      showResults: false,
    }
  },
  methods: {
    async filtersChanged () {
      this.updateTotal = true
      await this.clearTableFilters()
    },
    async statusFilterChanged () {
      this.updateTotal = true
      this.$refs.table.reset()
      this.updateParams({ setCurrentPage: 1, page: 1})
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    formatDateWithNull (date) {
      if (date===null || date === '0001-01-01T00:00:00') {
        return ''
      } else {
        return this.formatDate(date)
      }
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatValidationBool (row, col) {
      if (['payerDetailsValidated', 'bankAccountValidated', 'paymentPlansValidated'].indexOf(col.field) > -1) {
        return row[col.field] ? 'Complete' : 'More details required...'
      } else {
        return row[col.field]
      }
    },
    rowToCss (row) {
      if (row.isNew) {
        return 'new-item'
      } else {
        return ''
      }
    },
    onSortChange (params) {
      console.log(params)
      if (params[0].field !== this.serverParams.sort[0].field || params[0].type !== this.serverParams.sort[0].type) {
        this.updateParams({
          sort: params,
          page: 1
        })
      }
    },
    load: _.debounce(async function () {
      if (this.showResults) {
        var query = this.buildGoodTableQuery()
        query.searchFilter = this.searchText
        var response = await this.$store.dispatch('getAllJftpPayersAcrossProductPaged', {serverParams: query})
        this.totalRecords = response.count
        this.updateTotal = false
        this.showResults = true
      }
    }, 300),
    async search () {
      this.isTableLoading = true
      this.clearTableFilters()
      this.$store.commit('setInactivePayers', [])
      this.showResults = true
      await this.load()
    },
    clearSearch () {
      this.$store.commit('setInactivePayers', [])
      this.searchText = ''
      this.showResults = false
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    preOnColumnFilter (event) {
      this.updateTotal = true
      this.onColumnFilter(event)
    },
    onRowClick (event) {
      // forces the table to do pointer cursor on row etc
    },
    onCellClick (event) {
      if (event.column.field !== 'buttons' && event.column.field !== 'lastActivity') {
        this.showResults = false
        this.$store.commit('setInactivePayers', [])
        this.searchText = ''
        this.$store.dispatch('blankPayer')
        this.$router.push({ name: 'editPayer', params: { ukPayerId: event.row.ukPayerId } })
      }
    },
    dirtyGroup () {
      this.$v.group.$touch()
      this.groupValid = !this.$v.group.$invalid
    },
    goToSchedule (payerId, planId) {
      this.$router.push({ name: 'PayerSchedule', params: { id: payerId, planId: planId } })
    },
    async goToSchedules (payerId, plans) {
      let obj = {}
      for( var i = 0; i < plans.length; i++)  {
          obj[plans[i].planId]= plans[i].planName
      }
      var result = await swal.fire({
        title: 'Select Schedule',
        text: 'Select an option from the dropdown below',
        input: 'select',
        inputOptions: obj ,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: colours.success,
        confirmButtonText: 'Go to Schedule',
        cancelButtonText: 'Cancel'
      })
      if(result.isConfirmed) {
        this.goToSchedule(payerId, result.value)
      }
    },
    getBadgeClass (value) {
      return statusToBadgeVariant(value)
    },
    hasSuspendedPlan (rowObject) {
      for (let i = 0; i < rowObject.plans.length; i++) {
        if (rowObject.plans[i].status === 2) {
          return true
        }
      }
      return false
    }
  },
  validations: {
    group: { required }
  }
}
</script>

<style scoped>
@keyframes yellowfade {
  from {
    background: yellow;
  }
  to {
    background: transparent;
  }
}

.new-item {
  animation-name: yellowfade;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
}

.groupSelector {
  margin-top: 1em;
  margin-left: 1em;
}

.dark-mode * .potentiallyLongText span a, .dark-mode * .potentiallyLongText span a:link, .dark-mode * .potentiallyLongText span a:visited, .dark-mode * .potentiallyLongText span a:active {
  color: #ffffff !important;
  text-decoration: underline;
}
</style>
