<template>
  <div>
    <form @submit.prevent>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Recurring Charges Settings</h2>
        </div>
        <div class="idb-block-content">
          <table class="table">
            <thead>
              <tr class>
                <th>
                  <span>Charge Type</span>
                </th>
                <th>
                  <span>Recurring</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(group, key) in groupedSettings">
                <tr class="thead" :key="key">
                  <th colspan="2">{{key}}</th>
                </tr>
                <tr v-for="setting in group" :key="setting.type.$model">
                  <td>{{setting.type.$model}}</td>
                  <td>
                    <p-check class="p-default p-curve" v-model="setting.recurring.$model"></p-check>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="idb-block-footer">
          <button
            class="btn btn-primary"
            :disabled="isLoading "
            type="submit"
            @click="checkValidation() && save()"
          >Save</button>
          <button
            class="btn btn-danger pull-right ml-3"
            type="button"
            :disabled="isLoading"
            @click="back"
          >Cancel</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'

// Mixins
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  name: 'RecurringChargesSettings',
  mixins: [loading, DataLeaveMixin],
  data () {
    return {
      settings: []
    }
  },
  computed: {
    groupedSettings () {
      var unsorted = Object.values(this.$v.settings.$each.$iter).reduce((rv, x) => {
        if (x.$model.type === 'Refunds') {
          return rv
        }

        if (x.$model.type === 'OSU Charge') {
          return rv
        }

        (rv[x.$model.typeGroup] = rv[x.$model.typeGroup] || []).push(x)
        return rv
      }, {})

      var ordered = Object.keys(unsorted).sort((a, b) => {
        if (a === 'Processing Charges') {
          return -1
        } else if (b === 'Processing Charges') {
          return 1
        }

        if (a === 'Ad-Hoc Charges') {
          return -1
        } else if (b === 'Ad-Hoc Charges') {
          return 1
        }

        if (a === 'Professional Services') {
          return -1
        } else if (b === 'Professional Services') {
          return 1
        }

        if (a === 'Periodic') {
          return -1
        } else if (b === 'Periodic') {
          return 1
        }

        return 1
      }).reduce((obj, key) => {
        obj[key] = unsorted[key]
        return obj
      }, {})

      return ordered
    },
    ...mapGetters(['vueAppVersion'])

  },
  methods: {
    async load () {
      try {
        var settings = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Charge/RecurringSettings`,
          { showload: true, showerror: true, errormessage: 'Recurring settings failed to load' })
        this.settings = settings.data
      } catch {

      }
    },
    async save () {
      try {
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Charge/RecurringSettings`, { ChargeTypeSettings: this.settings },
          { showload: true, showerror: true, errormessage: 'Recurring charge settings failed to save' })
        this.$toastr.s('Recurring Charge Settings updated', 'Updated')
        await this.load()
        this.$v.$reset()
      } catch { }
    }
  },
  created () {
    this.load()
  },
  validations: {
    settings: {
      $each: {
        type: {},
        recurring: {}
      }
    }
  }
}
</script>

<style>
</style>
