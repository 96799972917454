<template>
  <div style="height:100%">
    <div class="d-flex justify-content-between mb-20">
      <h5 class="m-0">News</h5>
    </div>
    <vue-perfect-scrollbar class="scroll-area" :settings="settings">
      <div v-for="(story, index) in stories" :key="story.id">
        <div class="media pb-1 mb-3">
          <img
            :src="story.imageUrl"
            width="48px"
            height="48px"
            class="d-block ui-w-40 rounded-circle"
            alt
          />
          <div class="media-body ml-3">
            <div>
              <router-link
                tag="a"
                class="text-dark"
                class-active="active"
                :to="'/news/' + story.id"
                exact
              >{{story.title}}</router-link>
            </div>
            <small class="text-muted">{{new Date(story.createdDate).toLocaleString()}}</small>
            <div v-html="story.intro"></div>
            <div class="clearfix mt-2">
              <a
                href="javascript:void(0)"
                class="float-left text-lightest small"
                @click="more(story)"
              >
                <span class>More</span>
              </a>
            </div>
          </div>
        </div>
        <hr v-if="story.enabled && index < stories.length-1" />
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>

import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'NewsWidget',
  components: {
    VuePerfectScrollbar
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      stories: [],
      settings: {
        maxScrollbarLength: 60
      }
    }
  },
  methods: {
    more (story) {
      this.$router.push(`/news/${story.id}`)
    },
    async load () {
      let res
      try {
        var data = {
          newFeatures: this.widget.options.find(o => o.label === 'New Features').value,
          events: this.widget.options.find(o => o.label === 'Events').value,
          tips: this.widget.options.find(o => o.label === 'Tips').value,
          companyNews: this.widget.options.find(o => o.label === 'Company News').value,
          other: this.widget.options.find(o => o.label === 'Other').value
        }

        res = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}NewsStory/Get`, data)
        if (res.data) {
          this.stories = res.data
        }
      } catch (e) {
        this.$snapbar.e(`Could not return the list of stories. ${e.message}`)
        this.stories = []
        if (e.response && e.response.data && e.response.data.errorMessage) {
          this.$snapbar.e(`Could not return the list of news stories. ${e.response.data.errorMessage}`)
        } else {
          this.$snapbar.e(`Could not return the list of news stories. ${e.message}`)
        }
      }
    }
  },
  async mounted () {
    this.load()
  },
  watch: {
    widget () {
      this.load()
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-area {
  height: calc(100% - 25px);
  min-height: unset;
}
</style>
