<template>
  <div id="alertsDiv">
    <vue-good-table
      ref="table"
      mode="remote"
      :columns="columns"
      :rows="rows"
      :totalRows="totalRecords"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-search="onSearch"
      @on-row-click="viewAlert"
      :search-options="{
        enabled: true
      }"
      :paginationOptions="paginationOptions"
      :sort-options="sortOptions"
      :isLoading.sync="isTableLoading"
      styleClass="vgt-table striped bordered"
    >
    <template slot="loadingContent">
      <h1>Loading...</h1>
    </template>
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'alertLevel'" class="alertContainer">
        <b-badge pill
          :variant="getSeverityClass(props.row.alertLevel)"
        >{{getMessage(props.row.alertLevel)}}</b-badge>
      </span>
      <span v-else>{{props.formattedRow[props.column.field]}}</span>
    </template>
    <div slot="table-actions">
      <button
        @click.prevent="clearTableFilters"
        class="btn btn-link"
        v-b-popover.hover.top.d500="'Clear filters'"
      >
            <span class="fa-stack" style="font-size: 10px;">
              <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
              <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
            </span>
      </button>
      <b-button @click.prevent="load" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'">
        <i class="fa fa-sync pointer dimmedIcon"></i>
      </b-button>
      <b-button @click.prevent="printTable" v-if="false" class="" variant="link" v-b-popover.hover.top.d500="'Print out the contents of the Alerts table'">
        <i class="fa fa-print pointer dimmedIcon"></i>
      </b-button>
      <b-button @click.prevent="exportTable" class="" variant="link" v-b-popover.hover.top.d500="'Export the contents of the Alerts table'">
        <i class="fa fa-share-square pointer dimmedIcon"></i>
      </b-button>
    </div>
    </vue-good-table>
  </div>
</template>
<script>
import axios from 'axios'
import papa from 'papaparse'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [tableFilterMixin, loading],
  props: {
    ukPayerId: String,
    reference: String,
    canLoad: { type: Boolean, default: false }
  },
  data () {
    return {
      totalRecords: 0,
      rows: [],
      columns: [
        { label: 'Alert Level', field: 'alertLevel', tdClass: 'text-center', width: '120px' },
        { label: 'Title', field: 'title' },
        { label: 'Message Type', field: 'payerHistoryMessageType' },
        {
          label: 'Date',
          field: 'generationDate',
          formatFn: this.formatDate,
          sortable: true,
          sortFn: (x, y) => {
            x = new Date(x)
            y = new Date(y)
            return x > y ? -1 : x < y ? 1 : 0
          }
        }
      ],
      enablePagination: true,
      serverParams: {
        sort: [{ field: 'generationDate', type: 'asc' }]
      }
    }
  },
  async mounted () {
  },
  methods: {
    viewAlert (e) {
      this.$router.push({ path: `/collections/customer/${this.ukPayerId}/history/${e.row.payerHistoryId}` })
    },
    getSeverityClass (value) {
      let retVal = ''
      switch (value) {
        case 'High':
          retVal = 'danger'
          break
        default:
          retVal = 'info'
          break
      }
      return retVal
    },
    formatDate (val) {
      var date = new Date(val)
      var day = date.getUTCDate()
      var monthIndex = date.getUTCMonth() + 1
      var year = date.getUTCFullYear()
      var h = date.getHours()
      var m = date.getMinutes()
      var s = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year} ${this.pad(h, 2)}:${this.pad(m, 2)}:${this.pad(s, 2)}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    getMessage (value) {
      let retVal = ''
      switch (value) {
        case 'High':
          retVal = 'High'
          break
        case 'Low':
          retVal = 'Low'
          break
        default:
          retVal = value
          break
      }
      return retVal
    },
    async printTable () {
      this.enablePagination = false
      await this.reloadTable()
      window.print()
      this.enablePagination = true
    },
    async exportTable () {
      let query = this.buildGoodTableQuery()
      query.exportMode = true
      query.page= 1
      query.perPage= this.totalRecords
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.ukPayerId}/alerts`, { params: { ...query }, showLoad: true, paygateid: this.$store.getters.selectedCustomer })
      let rows = response.data.data

      var csvString = papa.unparse(rows)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, this.reference + '-customer-alert.csv')
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = this.reference + '-customer-alert.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    async reloadTable () {
      await this.load()
    },
    async load () {
      if(this.canLoad) {
        this.isTableLoading = true
        try {
          let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.ukPayerId}/alerts`, {
            params: {...this.buildGoodTableQuery()},
            showload: true,
            paygateid: this.$store.getters.selectedCustomer
          })
          this.rows = response.data.data
          this.totalRecords = response.data.count
        } catch (e) {
          this.$toastr.e('Could not load alerts.')
        }
        this.isTableLoading = false
      }
    }
  }
}
</script>
