<template>
  <div id="customerLedgerTransactions">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Nominal Code Totals
              <span class="pull-right">
              </span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2">
                <strong>From Transaction Date</strong>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <strong>To Transaction Date</strong>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdFrom"
                  name="createdFrom"
                  id="createdFrom"
                  v-model="createdFrom"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  :monday-first="true"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdTo"
                  name="createdTo"
                  id="createdTo"
                  v-model="createdTo"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  :monday-first="true"
                ></vuejsDatepicker>
              </div>
            </div>
            <div v-if="invalidDateRange === true" class="row form-group">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <span class="mandatory">The "From Date" cannot be after the "To Date"</span>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <div class="row form-group">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="load"
                  :disabled="disableSearch"
                >Search</button>
              </div>
              <div class="col-md-10">
                <button
                  type="button"
                  class="btn btn-outline-primary pull-right"
                  v-on:click="clearSearchParams()"
                >Clear Search Criteria</button>
              </div>
            </div>
            <div v-show="searchComplete === true" class="row form-group">
              <div class="col-md-12">
                <vue-good-table
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :isLoading.sync="isTableLoading"
                  ref="table"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="false"
                  mode="remote"
                  :totalRows="totalRecords"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  styleClass="vgt-table striped bordered"
                >
                  <div slot="table-actions">
                    <table-actions
                      :data-url="dataUrl"
                      :export-data="tableServerData"
                      :print-enabled="false"
                      :table-amount="totalRecords"
                      :export-name="exportFilename"
                      :reload-table="load"
                      :table-columns="columns"
                      :clear-table-filters="clearTableFilters"
                    >
                    </table-actions>
                  </div>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'
import bacsMixin from '@/Lib/BacsMixin.js'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import EditLedgerTransaction from '@/Views/JFTP/Reconcilliation/Ledger/EditLedgerTransaction'
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [
    bacsMixin,
    tableFilterMixin
  ],
  computed: {
    ...mapGetters(['selectedCustomer']),
    isFinanceUser () { return this.$store.getters.isInRole(roles.Finance) },
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}ledger/nominalCodeTotals`
    },
    tableServerData () {
      return this.buildGoodTableQuery()
    },
    exportFilename () {
      return 'NominalCodeTotals ' + this.defaultFromDate + ' to ' + this.defaultToDate
    }    
  },
  data () {
    return {
      searchComplete: false,
      createdFrom: '',
      createdTo: '',
      totalRecords: 0,
      disabledDates: {},
      invalidDateRange: false,
      defaultFromDate: '',
      defaultToDate: '',
      columns: [
        {
          label: 'Nominal Code',
          field: 'nominalCode',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Total Debit (£)',
          field: 'totalDebit',
          type: 'decimal',
          formatFn: this.formatAmount,
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Total Credit (£)',
          field: 'totalCredit',
          type: 'decimal',
          formatFn: this.formatAmount,
          hidden: false,
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Total Balance (£)',
          field: 'currentBalance',
          type: 'decimal',
          formatFn: this.formatAmount,
          filterOptions: {
            enabled: false
          }
        }
      ],
      rows: [],
      isModalVisible: false,
      bankAccountId: '',
      sunId: '',
      ledgerBalance: { currentBalance: 0.00 },
      datesInit: false,
      customerTypes: [],
      selectedCustomerType: 0,
      isNewTx: false,
      selectedLedgerTx: {},
      bankAccounts: [],
      nominalCodes: [],
      disableSearch: true,
      bankAccountColumn: 3
    }
  },

  methods: {
    async load () {
      if (!this.checkDates() || !this.datesInit) {
        return
      }

      try {
        this.isTableLoading = true
        const response = await axios.get(this.dataUrl,
          {
            params: { ...this.tableServerData },
            showerror: true,
            errormessage: 'Ledger transactions search failed',
            showload: true
          })

        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
        this.searchComplete = true
      } catch { } finally {
        this.isTableLoading = false
      }
    },

    // Override mixin version of buildGoodTableQuery since adding extra date params.
    buildGoodTableQuery () {
      const query = { ...this.serverParams.columnFilters }
      Object.keys(query).forEach((key) => { query[key] = `*${query[key]}*` })
      query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }

      query.fromDate = moment(this.createdFrom).format('YYYY-MM-DD')
      query.toDate = moment(this.createdTo).format('YYYY-MM-DD')

      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
      return query
    },

    async clearSearchParams () {
      this.defaultFromDate = ''
      this.defaultToDate = ''
      await this.initSearchDates()
      this.totalRecords = 0
      this.rows = []
      this.searchComplete = false
    },

    checkDates () {
      var datesOk = true
      if (this.createdFrom !== '' && this.createdTo !== '') {
        var fromDate = new Date(this.createdFrom)
        var toDate = new Date(this.createdTo)

        if (fromDate > toDate) {
          this.invalidDateRange = true
          datesOk = false
        } else {
          this.invalidDateRange = false
        }

        this.defaultFromDate = moment(this.createdFrom).format('DD-MM-YYYY')
        this.defaultToDate = moment(this.createdTo).format('DD-MM-YYYY')
      }

      return datesOk
    },

    async initSearchDates () {
      if (this.defaultFromDate === '' || this.defaultToDate === '') {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ledger/initDates`)
        this.defaultFromDate = response.data.defaultFromDate
        this.defaultToDate = response.data.defaultToDate
      }
      this.createdFrom = new Date(this.$moment(this.defaultFromDate, 'DD/MM/YYYY'))
      this.createdTo = new Date(this.$moment(this.defaultToDate, 'DD/MM/YYYY'))
      // NB. Don't want to disable any future dates since the TX date could be at least a couple of days in the future for BACS payments; depends on weekends bank holidays etc.
      this.datesInit = true
    },

    formatAmount (value) {
      return this.formatValue(value)
    },

    async getEnums () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/search/getDropdownValues`)
      this.customerTypes = response.data.customerTypes
    },

    changeCustomerType () {
      this.searchComplete = false
    }
  },
  watch: {
    async selectedCustomer () {
      this.clearSearchParams()
      this.selectedCustomerType = 1
    }
  },

  async mounted () {
    document.getElementById('createdFrom').style.backgroundColor = 'white'
    document.getElementById('createdTo').style.backgroundColor = 'white'
    await this.getEnums()
    await this.initSearchDates()
    this.$nextTick(() => {
      this.disabledDates.from = new Date()
      this.disableSearch = false
    })
  }
}
</script>
<style lang="scss">
.pagepointer {
  cursor: default !important;
}

.ledgerTxWordwrap {
  overflow-wrap: break-word; word-wrap: break-word; word-break: break-all !important;
}

</style>
