<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              PS and Ad-Hoc Fees Report
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row mb-1">
              <div class="col-md-2">
                <div class="form-group">
                  <label class="form-label">From Date</label>
                  <div class="input-group date">
                    <date-picker v-model="fromDate" :config="fromDateOptions" :wrap="true"></date-picker>
                    <div class="input-group-addon">
                      <span class="far fa-calendar"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label class="form-label">To Date</label>
                  <div class="input-group date">
                    <date-picker v-model="toDate" :config="toDateOptions" :wrap="true"></date-picker>
                    <div class="input-group-addon">
                      <span class="far fa-calendar"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>Clients</label>

                <multi-select
                  v-model="selectedCustomers"
                  :values="customers"
                  :options="customerOptions"
                ></multi-select>
              </div>
              <div class="form-group col-md-3">
                <label>Type</label>
                <div>
                  <b-form-select v-model="chargeType" :options="availableTypes" title="Type"></b-form-select>
                </div>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button
              type="submit"
              class="btn btn-primary"
              @click="load()"
              :disabled="isLoading"
            >Load PS and Ad-Hoc Fees</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loaded">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>PS and Ad-Hoc Fees</h2>
          </div>
          <div class="idb-block-content">
            <div class="row">
              <div class="col-md-12">
                <vue-good-table
                  :rows="rows"
                  :columns="columns"
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :isLoading.sync="isTableLoading"
                  :totalRows="totalRecords"
                  mode="remote"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  styleClass="vgt-table striped bordered"
                  ref="table"
                >
                  <template slot="table-actions">
                    <table-actions
                      :data-url="dataUrl"
                      :export-data="tableServerData"
                      :print-enabled="false"
                      :table-amount="totalRecords"
                      :export-name="exportName"
                      :reload-table="load"
                      :table-columns="columns"
                      :clear-table-filters="clearTableFilters"
                    ></table-actions>
                  </template>
                  <template slot="table-row" slot-scope="props">
                    <span
                      v-if="props.column.field === 'serviceCharge'"
                      :class="tdClass(props.row, props.column.field)"
                    >{{props.formattedRow[props.column.field]}}</span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

// Components
import MultiSelect from '@/Assets/Components/MultiSelect'
import datePicker from '@/Assets/Components/DateTimePicker/DatePicker'

// Constants
import { billingEnum as billing } from '@/Assets/Constants/billing'

export default {
  name: 'PsAdHocFeesReport',
  mixins: [loading, settlementMixin, tableFilterMixin],
  components: {
    MultiSelect,
    datePicker
  },
  computed: {
    fromDateOptions () {
      return {
        ...this.dateOptions,
        maxDate: this.toDate
      }
    },
    toDateOptions () {
      return {
        ...this.dateOptions,
        minDate: this.fromDate,
        maxDate: new Date()
      }
    },
    availableTypes () {
      var result = [
        { value: null, text: 'All' },
        {
          label: 'Ad-Hoc Charges',
          options:
            Object.keys(billing).filter(b =>
              [
                'CreditNote',
                'TerminationFee'
              ].some(bb => bb === b)
            ).map((b) => {
              return { value: b, text: billing[b] }
            })

        },
        {
          label: 'PS Charges',
          options:
            Object.keys(billing).filter(b =>
              [
                'BacsMigration',
                'CustomerSetup',
                'FmServiceUserSetup',
                'PayerMigration',
                'Reports',
                'ServiceUserModification'
              ].some(bb => bb === b)
            ).map((b) => {
              return { value: b, text: billing[b] }
            })

        }

      ]

      return result
    },
    tableServerData () {
      return {
        ...this.buildGoodTableQuery(this.otherData),
        ...this.otherData
      }
    },
    otherData () {
      return {
        customerIds: this.selectedCustomers,
        type: this.chargeType,
        fromDate: this.fromDate.toISOString(),
        toDate: this.toDate.hour(23).minute(59).second(59).millisecond(999).toISOString()
      }
    },
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}Report/PSAdhocFees`
    },
    exportName () {
      return `${moment().format('DD/MM/YYYY')}-ps-and-adhoc-fees-report`
    },
    ...mapGetters(['customersOrdered'])
  },
  data () {
    return {
      fromDate: moment().subtract(1, 'months').millisecond(0).second(0).minute(0).hour(0),
      toDate: moment(),
      dateOptions: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        sideBySide: true
      },
      loaded: false,
      shouldLoad: false,
      rows: [],
      selectedCustomers: [],
      customers: [],
      customerOptions: {
        search: true,
        selectAll: true,
        texts: {
          placeholder: 'Select Clients',
          search: 'Search Clients'
        }
      },
      chargeType: null,

      columns: [
        {
          label: 'Account Id',
          field: 'accountId',
          sortable: true
        },
        {
          label: 'Client Name',
          field: 'clientName',
          sortable: true
        },
        {
          label: 'Type',
          field: 'type',
          sortable: true
        },
        {
          label: 'Charge',
          field: 'serviceCharge',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          sortable: true,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy'
        }
      ],
      serverParams: {
        sort: [{ field: 'processingDate', type: 'desc' }]
      },
      paginationOptions: {
        dropdownAllowAll: false
      }
    }
  },
  mounted () {
    this.customers = this.customersOrdered.map(co => ({ value: co.paygateId, name: co.name }))
  },
  methods: {
    async load () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Report/PSAdhocFees`, {
        params: this.tableServerData,
        showload: true,
        showerror: true,
        errormessage: 'PS and Ad-Hoc Fees Report failed to load'
      })

      this.rows = response.data.data
      this.totalRecords = response.data.count
      this.loaded = true
    }
  }
}

</script>
<style lang="scss">
</style>
