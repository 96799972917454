<template>
    <div style="height:90%">
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0 w-100">Collections: <router-link to="collections/alerts">{{totalRecords}} alerts require attention</router-link> <i @click="load" role="button" class="fas fa-sync-alt pull-right"></i></h4>
    </div>
    <vue-perfect-scrollbar class="scroll-area" :settings="{maxScrollbarLength: 60}">
      <vue-good-table
        :isLoading.sync="isTableLoading"
        mode="remote"
        ref="table"
        :columns="columns"
        :rows="rows"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        @on-row-click="onRowClick"
        :totalRows="totalRecords"
        :search-options="{
            enabled: false
          }"
        :paginationOptions="paginationOptions"
        :sort-options="sortOptions"
        styleClass="vgt-table striped bordered"
      >
        <template slot="loadingContent">
          <h1>Loading...</h1>
        </template>
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'alertLevel'" class="alertContainer">
              <b-badge
                pill
                :variant="getSeverityClass(props.row.alertLevel)"
              >{{props.formattedRow[props.column.field]}}</b-badge>
            </span>
          <span v-else>{{props.formattedRow[props.column.field]}}</span>
        </template>
      </vue-good-table>
    </vue-perfect-scrollbar>
  </div>
</template>
<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Loading from '@/Assets/Components/Loading/Loading'
import {mapGetters} from "vuex";
import tableFilterMixin from "@/Assets/Mixins/TableFilterMixin";
import loading from "@/Assets/Mixins/LoadingMixin";

export default {
  mixins: [tableFilterMixin, loading],
  name: 'CurrentAlertsWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Loading
  },
  data: function () {
    return {
      originalOrder: [],
      alerts: [],
      alertCount: 0,
      sortField: 'generationDate',
      sortDirection: 'desc',
      loading: true,
      intervalId: null,
      rows: [],
      columns: [
        { label: 'Alert Level', field: 'alertLevel', tdClass: 'text-center', width: '120px' },
        { label: 'Title', field: 'title' },
        {
          label: 'Date',
          field: 'generationDate',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'generationDate', type: 'asc' }]
      },
      paginationOptions: {
        enabled: false,
        perPage: 5,
        setCurrentPage: 1,
        dropdownAllowAll: false,
        jumpFirstOrLast: true
      },
    }
  },
  async mounted () {
    await this.load()
    this.sortField = 'generationDate'
    this.sortDirection = 'asc'
    // this.intervalId = setInterval(this.populate, 30000) // 30s
    // this.$once('hook:beforeDestroy', () => {
    //   window.clearInterval(this.intervalId)
    // })
  },
  beforeRouteLeave(to, from, next) {
    // window.clearInterval(this.intervalId)
    next();
  },
  methods: {
    alertClicked (alert) {
      let historyUrl = `/collections/customer/${alert.ukPayerId}/history/${alert.payerHistoryId}`
      this.$router.push(historyUrl)
    },
    getSeverityClass (value) {
      let retVal = ''
      switch (value) {
        case 'High':
          retVal = 'danger'
          break
        default:
          retVal = 'info'
          break
      }
      return retVal
    },
    formatDate (datestr) {
      let date = new Date(datestr)
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var h = date.getHours()
      var m = date.getMinutes()
      var s = date.getSeconds()
      return `${this.pad(day,2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    truncate (input) {
      var output = input
      if (input.length > 100) {
        output = input.substring(0, 90) + '...'
      }
      return output
    },
    async onRowClick (e) {
      console.log(e.row)
      this.$router.push({ path: `/collections/customer/${e.row.ukPayerId}/history/${e.row.payerHistoryId}` })
    },
    async load () {
      var query = this.buildGoodTableQuery()
      query.highOnly = this.highLevelOnly
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}alerts/`, {
          params: query,
          paygateid: this.paygateId,
          showload: true
        })

        this.rows = response.data.item1
        this.totalRecords = response.data.item2
      } catch (e) { console.log('Could not load alerts') }
    },
    sort (field, order) {
      this.sortField = field
      this.sortDirection = order
      this.alerts = this.alerts.sort((a, b) => a[field].localeCompare(b[field]))
      if (order === 'desc') {
        this.alerts.reverse()
      }
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    highLevelOnly () {
      return this.widget.options.find(o => o.label === 'HighLevelOnly').value
    },
    levelSort () {
      return this.sortField === 'alertLevel' && this.sortDirection === 'desc' ? 'asc' : 'desc'
    },
    titleSort () {
      return this.sortField === 'title' && this.sortDirection === 'desc' ? 'asc' : 'desc'
    },
    dateSort () {
      return this.sortField === 'generationDate' && this.sortDirection === 'desc' ? 'asc' : 'desc'
    }
  },
  watch: {
    highLevelOnly () {
     this.load()
    },
    selectedCustomer () {
      //this.load()
    }
  }
}
</script>

<style lang="scss" scoped>
  .textRow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 10px;
  }

  .scroll-area {
    margin-top: 20px;
    height: calc(100% - 25px);
    min-height: unset;
    border-top: solid 1px #eeeeee;
  }

  .alertsRow {
    border-bottom: solid 1px #dddddd;
  }

  .alertsRow:hover  {
    cursor: pointer
  }

  .alertsRow:hover td {
    background-color: #f1f5fd;
  }
</style>
