<template>
<div>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        <span class="mr-2">Event log Configuration</span>
      </h2>
    </div>
    <div class="idb-block-content">
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-2 ">Info</div>
        <div class="col-md-2 ">Warning</div>
        <div class="col-md-2 ">Critical Warning</div>
        <div class="col-md-2 ">Error</div>
      </div>
      <div class="row" v-for="user in eventLogEmails" :key="user.userId">
        <div class="col-md-4">{{user.userName}}</div>
        <div class="col-md-2"><b-form-checkbox v-model="user.emailOnInfo"></b-form-checkbox></div>
        <div class="col-md-2"><b-form-checkbox v-model="user.emailOnWarning"></b-form-checkbox></div>
        <div class="col-md-2"><b-form-checkbox v-model="user.emailOnCriticalWarning"></b-form-checkbox></div>
        <div class="col-md-2"><b-form-checkbox v-model="user.emailonError"></b-form-checkbox></div>
      </div>
    </div>
    <div class="idb-block-footer">
      <div class="row">
        <div class="col-12">
          <span class="btn-group mr-2" role="group">
            <button id="btnGroupDrop1" @click="btnSave" type="button" class="btn btn-primary">
              <i class="fa fa-reload mr-2"></i>Save
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  components: { },

  data () {
    return {
      eventLogEmails: {}
    }
  },
  computed: {
    paygateId () {
      return this.$store.getters.selectedCustomer
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async load () {
      console.log('Load')
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}eventemailusers`)
        if (res) {
          this.eventLogEmails = res.data.users
          console.log(this.eventLogEmails)
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view events configuration data.')
        } else {
          console.log(e)
          this.$snapbar.e(`Could not get the events configuration data - ${e.message}`)
        }
        this.row_data = []
        this.totalRecords = 0
      } finally {
      }
    },
    async btnSave () {
      console.log('Save')
      let res
      try {
        res = await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}eventemailusers`, this.eventLogEmails)
        console.log(res)
        if (res && res.data) {
          console.log(res.data)
          this.$toastr.s('The event Log configuration data has been saved', 'Success')
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to save Event Log configuration data.')
        } else {
          this.$snapbar.e(`Could not save Event Log configuration data - ${e.message}`)
        }
      }
    }
  },
  watch: {
    paygateId: function (val) {
      this.load()
    }
  },
  mounted () {
    this.load()
  }

}
</script>

<style scoped>
</style>
