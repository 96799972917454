<template>
  <actions-block
    v-bind:action="action"
    :status="status"
    :from="from"
    name="Bureau Customer"
    v-if="action.id"
  >
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Bureau Customer Details</h2>
      </div>
      <div class="idb-block-content">
        <div class="alert alert-secondary" role="alert" v-if="status === 'Edit'">
          The previous details are shown in
          <span class="del">red</span>, the new details in
          <span class="ins">Green</span>.
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Name</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.name"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Service User Number</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.serviceUserNumber"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Contra Narrative</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.contraNarrative"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label
            for="description"
            class="col-sm-2 text-right col-form-label"
          >Default Import Filename</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.defaultImportFilename"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Default Import Mapping</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.defaultImportMapping"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Default Import Schema</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.defaultImportSchema"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Payment Limit</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.paymentLimit"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Submission Limit</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.submissionLimit"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Tags</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.tags"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Address</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.addressLine1"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">-</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.addressLine2"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">-</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.addressLine3"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">-</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.addressLine4"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Postcode</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.addressPostcode"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Town</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.addressTown"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Telephone</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.telephone"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Telephone 2</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.telephone2"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Website</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.websiteUrl"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Contact Forename</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.contactFirstName"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">Contact Surname</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.contactSurname"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
          <label for="description" class="col-sm-2 text-right col-form-label">E-Mail</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.emailAddress"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Bank Name and Address</h2>
      </div>
      <div class="idb-block-content">
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Bank Reference</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.bankReference"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Sort Code</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.sortCode"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Account Number</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.accountNumber"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Bank Name</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.bankName"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Address</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.bankAddressLine1"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.bankAddressLine2"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.bankAddressLine3"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.bankAddressLine4"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.bankTown"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Post Code</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right: 5px"
                v-for="diff in difference.bankAddressPostcode"
                :class="{ ins: diff.added, del: diff.removed }"
                :key="diff.value"
              >{{ diff.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </actions-block>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { diffSentences } from 'diff'
import axios from 'axios'
import ActionsBlock from '@/Components/Platform/Actions/ActionsBlock'

export default {
  props: ['actionId', 'bankAccountId'],
  components: { 'actions-block': ActionsBlock },
  computed: {
    difference () {
      if (!this.bureauCustomer || !this.action.data) {
        if (this.status === 'Create') {
          this.bureauCustomer = this.action.data
        } else if (this.status === 'Delete' && this.bureauCustomer) {
          this.action.data = this.bureauCustomer
        } else {
          return {}
        }
      }
      return Object.keys(this.bureauCustomer).reduce((a, v) => {
        if (v === 'isItemActioned' || v === 'modifiedBy') {
          return a
        }

        if (this.bureauCustomer[v] === null || this.bureauCustomer[v] === undefined) {
          this.bureauCustomer[v] = ''
        }
        if (this.action.data[v] === null || this.action.data[v] === undefined) {
          this.action.data[v] = ''
        }

        var tempObj = {}
        tempObj[v] = diffSentences(this.bureauCustomer[v].toString(), this.action.data[v].toString())
        return Object.assign(a, tempObj)
      }, {})
    }
  },
  data () {
    return {
      bureauCustomer: null,
      action: { data: null },
      status: '',
      loading: true,
      bureauCustomerId: '',
      from: {}
    }
  },

  async mounted () {
    try {
      // Get Action Detail
      var actionResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/${this.actionId}`, { action: true })
      this.action = actionResponse.data
      console.log('action', this.action)
      this.bureauCustomerId = this.action.data.bureauCustomerId
      this.status = this.action.actionSubClass

      try {
        if (this.action.data.country) {
          const countryResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Lookup/Country`, { params: { code: this.action.data.country } })
          this.action.data.country = countryResponse.data.description
        }
      } catch (e) { }
    } catch (e) {

    }

    // Get Bureau Customer
    try {
      if (this.status !== 'Create') {
        var bureauCustomerResponse = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}BureauCustomer/${this.bureauCustomerId}`)
        this.bureauCustomer = bureauCustomerResponse.data

        try {
          if (this.bureauCustomer.country) {
            const countryResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Lookup/Country`, { params: { code: this.bankAccount.country } })
            this.bureauCustomer.country = countryResponse.data.description
          }
        } catch (e) { }
      }
    } catch (e) {
      // This will fail on a create
    }
    this.loading = false
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.from = from
    })
  }
}
</script>
