export default {
  CustomerAdministrator: 'CustomerAdministrator',
  ViewAuditLog: 'ViewAuditLog',
  ViewBanks: 'ViewBanks',
  ViewUsers: 'ViewUsers',
  ImportCustomer: 'ImportCustomer',
  AgentConfigure: 'AgentConfigure',
  SystemUser: 'PaygateSystemAdmin',
  ManageAntiFraud: 'ManageAntiFraud',
  CustomerReports: 'CustomerReports',
  ViewWorkflow: 'ViewWorkflow',
  ManageWorkflow: 'ManageWorkflow',
  ProcessSubmissions: 'ProcessSubmissions',
  ActionsReports: 'ActionsReports',
  ManageBureauCustomers: 'ManageBureauCustomers',
  ManageChildCustomers: 'ManageChildCustomers',
  MasterCustomerAdmin: 'MasterCustomerAdmin',
  ConnectedMerchantAdmin: 'ConnectedMerchantAdmin',
  ViewPayers: 'ViewPayers',
  ManagePayers: 'ManagePayers',
  ViewSubmissions: 'ViewSubmissions',
  ViewMessages: 'ViewMessages',
  ViewMessageTemplates: 'ViewMessageTemplates',
  ViewIndemnityClaims: 'ViewIndemnityClaims',
  ImportPayers: 'ImportPayers',
  ImportSchedules: 'ImportSchedules',
  ImportCollections: 'ImportCollections',
  ExportData: 'ExportData',
  ImportVariableAmounts: 'ImportVariableAmounts',
  ViewPlanTemplates: 'ViewPlanTemplates',
  EditPlanTemplates: 'EditPlanTemplates',
  ViewReports: 'ViewReports',
  TestMode: 'TestMode',

  // New Roles are here
  Limited: 'Limited',
  ReadOnly: 'Read-Only',
  Standard: 'Standard',
  ClientAdmin: 'Client Admin',

  LZAdmin: 'L&Z Admin',
  Finance: 'FinanceNew',
  Marketing: 'MarketingNew',
  DevOps: 'DevOpsNew',
  Qa: 'QA',
  Developer: 'Developer',
  Implementations: 'Implementations'

}
