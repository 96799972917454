<template>
    <div class="idb-block">
        <div class="idb-block-title">
          <h2>Banking <favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-md-12">
              <vue-good-table
                mode="remote"
                ref="table"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                @on-row-click="onRowClick"
                :columns="contactColumns"
                :rows="contacts"
                :totalRows="totalRecords"
                :pagination-options="{
                    enabled: true,
                    perPage: 10,
                    dropdownAllowAll: false,
            jumpFirstOrLast: true
                  }"
                  :search-options="{
              enabled: true
              }"
                styleClass="vgt-table striped bordered"
                class="scroll-table"
              >
                <div slot="table-actions">
                <button
                  @click.prevent="clearTableFilters"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Clear filters'"
                >
                  <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                  </span>
                </button>
                <b-button
                  @click.prevent="load" :disabled="isLoading"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Refresh the data in the table'"
                >
                  <i class="fa fa-sync pointer dimmedIcon"></i>
                </b-button>
                  <b-button
                    @click.prevent="openAddContactModal"
                    class
                    variant="link" :disabled="!isFinance"
                    v-b-popover.hover.top.d500="'Add a New Contact'"
                  >
                    <i class="fa fa-plus pointer dimmedIcon"></i>
                  </b-button>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'actions'">
                    <button
                      class="btn btn-danger"
                      type="button"
                      @click.prevent="deleteContact(props.row,$event)" :disabled="!isFinance"
                    >
                      <a class="fa fa-trash"></a>
                    </button>
                  </span>
                  <span v-else>{{props.formattedRow[props.column.field]}}</span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <button
              class="btn btn-primary"
              @click.prevent="openAddContactModal" :disabled="!isFinance"
            >Add a New Contact</button>
        </div>
        <banking-contact-modal
          ref="bankingContactModal"
          :type="contactModalType"
          :contacts="contacts"
          @edit="editContact"
        ></banking-contact-modal>
      </div>
</template>
<script>
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import axios from 'axios'
import BankingContactModal from './Modals/BankingContactModal'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import {mapGetters} from "vuex";
import Store from "@/Store";
import licence from "@/Assets/Constants/licence";
import roles from "@/Assets/Constants/roles";

export default {
  components: {
    BankingContactModal
  },
  mixins: [tableFilterMixin, loading],
  data () {
    return {
      contacts: [],
      serverParams: {
        sort: [{ field: 'name', type: 'asc' }]
      },
      contactModalType: 'Create',
      contactColumns: [
        {
          label: 'Title',
          field: 'title'
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Institution',
          field: 'institutionName'
        },
        {
          label: 'Branch Name',
          field: 'branchName'
        },
        {
          label: 'Branch Title',
          field: 'branchTitle'
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Phone',
          field: 'phone'
        },
        {
          label: 'Mobile',
          field: 'mobile'
        },
        {
          label: 'Job Title',
          field: 'jobTitle'
        },
        {
          label: 'Contact Type',
          field: 'contactType',
          formatFn: this.formatType
        },
        {
          label: '',
          field: 'actions',
          sortable: false
        },
        {
          field: 'notes',
          hidden: true
        },
        {
          field: 'address1',
          hidden: true
        },
        {
          field: 'address2',
          hidden: true
        }, {
          field: 'address3',
          hidden: true
        }, {
          field: 'address4',
          hidden: true
        }, {
          field: 'address5',
          hidden: true
        }
      ]
    }
  },
  methods: {
    formatType (value) {
      let retVal = ''
      switch (value) {
        case 0:
          retVal = 'General'
          break
        case 1:
          retVal = 'Technical'
          break
        case 2:
          retVal = 'Contractual'
          break
        case 3:
          retVal = 'Billing'
          break
      }
      return retVal
    },
    async load () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}bankingcontact`, { params: this.buildGoodTableQuery() })
        if (response) {
          this.contacts = response.data.data
          this.totalRecords = response.data.count
        }
      } catch (error) {
        if (error.response.status === 403) {
          this.$toastr.Add({
            name: 'banking contacts error',
            msg: 'You are not authorised to view these records.',
            preventDuplicates: true,
            type: 'error'
          })
        } else {
          this.$toastr.Add({
            name: 'banking contacts error',
            msg: 'Could not load banking contacts.',
            preventDuplicates: true,
            type: 'error'
          })
        }
      }
    },
    async openAddContactModal () {
      this.contactModalType = 'Create'
      this.$bvModal.show('bankingContactModal')
    },
    onRowClick (params) {
      this.openAddContactModal()
      this.contactModalType = 'Edit'
      this.$refs.bankingContactModal.populateContact(params.row)
    },
    async deleteContact (row, event) {
      event.stopPropagation()

      var result = await swal.fire({
        title: 'Delete Banking Contact?',
        text: 'Do you want to delete this banking contact?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}bankingcontact/${row.bankingContactId}`, { params: this.buildGoodTableQuery() })
        this.load()
      }
    },
    async editContact (contact) {
      if (this.isFinance) {
        await axios.post(`${process.env.VUE_APP_DDMS_API_URL}bankingcontact/${contact.bankingContactId}`, contact, {params: this.buildGoodTableQuery()})
        await this.load()
      } else {
        this.$toastr.e('Only Finance users, Implementations or Customer Service can edit banking contacts')
      }
    }
  },
  mounted: async function () {
    await this.load()
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    isFinance () {
      return this.$store.getters.isInRoles([roles.Finance, roles.LZAdmin, roles.Implementations])
    }
  },
  watch: {
    selectedCustomer () {
      this.contacts = []
      this.totalRecords = 0
      this.load()
    }
  }
}
</script>
