import roles from '@/Assets/Constants/roles'
import Store from '@/Store/index'
import licence from '@/Assets/Constants/licence'

import Bacs from '@/Menu/Modules/bacs'
import Collections from '@/Menu/Modules/collections'
import Bureau from '@/Menu/Modules/bureau'
import Validate from '@/Menu/Modules/validate'
import Platform from '@/Menu/Modules/platform'
import About from '@/Menu/Modules/about'
import Search from '@/Menu/Modules/search'

export default {
  routes: [
    {
      menu_title: 'Collections',
      menu_icon: 'ti-cloud-down',
      routes: Collections,
      path: '/collections/',
      showMethod: async () => {
        return Store.getters.hasLicence(licence.collections)
      }
    },
    {
      menu_title: 'Search',
      menu_icon: 'ti-search',
      routes: Search,
      path: '/search/',
      showMethod: async () => {
        return Store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.DevOps, roles.Developer, roles.Qa, roles.Implementations ])
      }
    },
    {
      menu_title: 'Bureau',
      menu_icon: 'ti-archive',
      routes: Bureau,
      path: '/bureau/',
      showMethod: async () => {
        return false
      }
    },
    {
      menu_title: 'Payments',
      menu_icon: 'ti-cloud-up',
      routes: Bacs,
      path: '/payments/',
      showMethod: async () => {
        return Store.getters.isInRoles([roles.Finance, roles.LZAdmin, roles.Qa, roles.Developer, roles.DevOps, roles.Implementations])
      }
    },
    {
      menu_title: 'Validate',
      menu_icon: 'ti-check-box',
      routes: Validate,
      path: '/validate/',
      showMethod: async () => {
        return Store.getters.hasLicence(licence.validate) && (Store.getters.hasLicence(licence.validate_modcheck) || Store.getters.hasLicence(licence.validate_iban))
      }

    },
    // {
    //   menu_title: 'Automation',
    //   menu_icon: 'ti-loop',
    //   routes: Workflow,
    //   path: '/automation/',
    //   showMethod: async () => {
    //     return true
    //   }
    // },
    {
      menu_title: 'Admin',
      menu_icon: 'ti-settings',
      routes: Platform,
      path: '/admin',
      name: 'Admin'
    },
    {
      menu_title: 'About',
      menu_icon: 'ti-info-alt',
      routes: About,
      path: '/about'
    }
  ]
}
