<template>
  <div>
    <vue-good-table mode="remote"
      ref="table"
      :columns="columns"
      :rows="rows"
      @on-row-click="viewHistory"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-cell-click="viewHistory"
      @on-search="onSearch"
      :totalRows="totalRecords"
      :isLoading.sync="isTableLoading"
      :paginationOptions="paginationOptions"
      :sort-options="sortOptions"
      :search-options="{
        enabled: true
      }"
      styleClass="vgt-table striped bordered"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'source'">
          <template v-if="!isSystemUser || props.row.userId === '00000000-0000-0000-0000-000000000000'">
            {{props.formattedRow[props.column.field]}}
          </template>
          <template v-else>
            {{props.row.userName}}
          </template>
        </span>
        <span v-else>{{props.formattedRow[props.column.field]}}</span>
      </template>
      <div slot="table-actions">
        <button
          @click.prevent="clearTableFilters"
          class="btn btn-link"
          v-b-popover.hover.top.d500="'Clear filters'"
        >
            <span class="fa-stack" style="font-size: 10px;">
              <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
              <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
            </span>
        </button>
        <b-button @click.prevent="load" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'">
          <i class="fa fa-sync pointer dimmedIcon"></i>
        </b-button>
        <b-button @click.prevent="exportTable" class="" variant="link" v-b-popover.hover.top.d500="'Export customer history'">
          <i class="fa fa-share-square pointer dimmedIcon"></i>
        </b-button>
      </div>
    </vue-good-table>
  </div>
</template>
<script>
import EventBus from '@/Lib/eventBus'
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import papa from 'papaparse'
import roles from "@/Assets/Constants/roles";
export default {
  mixins: [ tableFilterMixin ],
  props: {
    ukPayerId: String,
    canLoad: { type: Boolean, default: false }
  },
  data () {
    return {
      rows: [],
      columns: [
        { label: 'Message Type', field: 'payerHistoryMessageType', formatFn: this.messageTypeToText },
        { label: 'Title', field: 'title' },
        { label: 'Body', field: 'body' },
        { label: 'Date', field: 'generationDate', formatFn: this.formatDate},
        { label: 'Source', field: 'source', formatFn: this.sourceToText }
      ],
      serverParams: {
        sort: [{ field: 'generationDate', type: 'desc' }]
      }
    }
  },
  async mounted () {
    // await this.load()
    // EventBus.$on('refreshHistory', (payload) => { this.load() })
  },
  computed: {
    isSystemUser () {
      return this.$store.getters.isInRole(roles.SystemUser)
    }
  },
  methods: {
    viewHistory (e) {
      console.log(e)
      this.$router.push({ path: `/collections/customer/${this.ukPayerId}/history/${e.row.payerHistoryId}` })
    },
    async load () {
      if (this.canLoad) {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.ukPayerId}/history`, {
          params: this.buildGoodTableQuery(),
          paygateid: this.$store.getters.selectedCustomer,
          showload: true
        })
        if (response) {
          this.rows = response.data.data
          this.totalRecords = response.data.count
        }
      }
    },
    async exportTable () {
      var query = this.buildGoodTableQuery()
      // query.params.paygateId = this.$store.state.common.paygateId
      query.perPage = this.totalRecords
      query.page = 1
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }
      query.forexport = true
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.ukPayerId}/history`, { params: query, paygateid: this.$store.getters.selectedCustomer, showload: true })
      if (response) {
        var csvString = papa.unparse(response.data.data)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, 'customerhistory.csv')
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = 'customerhistory.csv'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      }
    },
    messageTypeToText (typeCode) {
      let retVal = ''
      switch (typeCode) {
        case 0:
          retVal = 'AUDDIS'
          break
        case 1 :
          retVal ='First Collections'
          break
        case 2 :
          retVal ='Regular Collections'
          break
        case 3 :
          retVal ='Last Collections'
          break
        case 4 :
          retVal ='Re-Presentation Collections'
          break
        case 5 :
          retVal ='Account'
          break
        case 6 :
          retVal ='Collection Error'
          break
        case 7 :
          retVal ='ARUDD'
          break
        case 8 :
          retVal ='ADDACS'
          break
        case 9 :
          retVal ='AWACS'
          break
        case 10 :
          retVal ='DDIC'
          break
        case 11 :
          retVal ='Message Sent'
          break
        case 12 :
          retVal ='Planned Regular Amount Change'
          break
        case 13 :
          retVal ='Ad-Hoc Customer Expiry'
          break
        case 14 :
          retVal ='Portal Message Sent'
          break
        case 15 :
          retVal ='Portal Message Receieved'
          break
        case 16 :
          retVal ='Refund Requested'
          break
        case 17 :
          retVal ='Status Changed'
          break
        case 18 :
          retVal ='Schedule Status Changed'
          break
        case 19 :
          retVal ='Collection Added'
          break
        case 20 :
          retVal ='Schedule Added'
          break
        case 21 :
          retVal ='New Indemnity Advice'
          break
        case 22 :
          retVal ='New Indemnity Cancellation'
          break
        case 23 :
          retVal ='Customer Updated'
          break
        case 24 :
          retVal ='Collection Not Processed'
          break
        case 25 :
          retVal ='Refund Cancelled'
          break
        case 26 :
          retVal ='Schedule Changed'
          break
        case 27 :
          retVal ='Schedule Removed'
          break
        case 28 :
          retVal = 'Collection Removed'
          break
        case 29 :
          retVal = 'Customer Created'
          break
        case 30 :
          retVal = 'Customer Suspended'
          break
        case 31 :
          retVal = 'Customer Unsuspended'
          break
        case 32 :
          retVal = 'Customer Closed'
          break
        case 33:
          retVal = 'Message Recalled'
          break
      }
      return retVal
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var hour = date.getHours()
      var minute = date.getMinutes()
      var seconds = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year} ${this.pad(hour, 2)}:${this.pad(minute, 2)}:${this.pad(seconds, 2)}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    sourceToText (sourceCode) {
      let retVal = ''
      switch (sourceCode) {
        case 0:
          retVal = 'System'
          break
        case 1:
          retVal = 'User'
          break
        case 2:
          retVal = 'Api'
          break
      }
      return retVal
    }
  }
}
</script>
