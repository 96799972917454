<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Indemnity Report Details</h2>
      </div>
      <div class="idb-block-content">
      <b-tabs content-class="mt-3">
        <b-tab title="Details">
          <div class="vgt-wrap">
            <table class="vgt-table striped ia-table">
              <tbody>
                <tr><td>Title</td><td>{{report.title}}</td></tr>
                <tr><td>Report Created</td><td>{{formatDate(report.createdDate)}}</td></tr>
                <tr><td>Report Produced</td><td>{{formatDate(report.productionDate)}}</td></tr>
                <tr><td>Service User Number</td><td>{{report.sun}}</td></tr>
                <tr><td>Service User Name</td><td>{{report.serviceUserName}}</td></tr>
                <tr><td>Number of Advices</td><td>{{(report.newAdviceCount+report.cancellationCount)}}</td></tr>
                <tr><td>Number of Unmatched Advices</td><td>{{report.unmatchedNewAdviceCount}}</td></tr>
                <tr><td>Total Debit Value</td><td>£{{formatAmount(report.totalDebitValue)}}</td></tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="New Advice Summary">
          <div class="vgt-wrap">
            <table class="vgt-table striped ia-table">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th class="amountCell">Matched</th>
                  <th class="amountCell">Unmatched</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>New Advice Count</td>
                  <td class="amountCell">{{report.matchedNewAdviceCount}}</td>
                  <td class="amountCell">{{report.unmatchedNewAdviceCount}}</td>
                </tr>
                <tr>
                  <td>New Advice Value</td>
                  <td class="amountCell">£{{formatAmount(report.matchedNewAdviceValue)}}</td>
                  <td class="amountCell">£{{formatAmount(report.unmatchedNewAdviceValue)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="Cancellation Summary">
          <div class="vgt-wrap">
        <table class="vgt-table striped ia-table">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th class="amountCell">Matched</th>
                <th class="amountCell">Unmatched</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cancellation Count</td>
                <td class="amountCell">{{report.matchedCancellationCount}}</td>
                <td class="amountCell">{{report.unmatchedCancellationCount}}</td>
              </tr>
              <tr>
                <td>Cancellation Value</td>
                <td class="amountCell">£{{formatAmount(report.matchedCancellationValue)}}</td>
                <td class="amountCell">£{{formatAmount(report.unmatchedCancellationValue)}}</td>
              </tr>
            </tbody>
          </table>
          </div>
        </b-tab>
      </b-tabs>

        </div>
      <div class="idb-block-footer">
        <b-button variant="primary" @click.prevent="downloadReport"><i class="fas fa-save mr-2"></i>Download Report File</b-button>
        <b-button class="ml-2" variant="danger" @click.prevent="removeAction" v-if="actionId && actionId !== null">Clear Action</b-button>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>New Advice</h2>
      </div>
      <div class="idb-block-content">
        <b-tabs  pills card vertical nav-wrapper-class="col-3 vertical-tab-container" nav-class="w-100 vertical-tab-list indemnityTabs">
          <b-tab  v-for="newAdvice in report.newAdvices" :key="newAdvice.adviceId" :title="newAdvice.payingBankReference">
            <template #title>
              {{newAdvice.payingBankReference}}<i v-if="newAdvice.isMatchedToPayer" v-b-popover.hover.top.d500="'Matched To Payer'" class="fas fa-check-circle ml-2 text-success"></i><i v-if="!newAdvice.isMatchedToPayer" v-b-popover.hover.top.d500="'Not Matched To Payer'" class="fas fa-times-circle ml-2 text-danger"></i>
            </template>
            <indemnity-advice :advice="newAdvice" />
            <b-button v-if="!newAdvice.hasChallenge && canChallenge && !newAdvice.thirdPartySun && newAdvice.reasonCode !== 4" @click.prevent="createChallenge(newAdvice)" variant="primary">Create Challenge</b-button>
            <b-button v-if="newAdvice.hasChallenge" @click="viewChallenge(newAdvice.challengeId)" variant="primary">View Challenge</b-button>
          </b-tab>
          <template slot="empty">
            There are no new advices in this report.
          </template>
        </b-tabs>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Cancellations</h2>
      </div>
      <div class="idb-block-content">
      <b-tabs  pills card vertical nav-wrapper-class="col-3 vertical-tab-container" nav-class="w-100 vertical-tab-list indemnityTabs">
          <b-tab v-for="cancellation in report.cancellations" :key="cancellation.adviceId" :title="cancellation.payingBankReference">
            <template #title>
              {{cancellation.payingBankReference}}<i v-if="cancellation.isMatchedToPayer" v-b-popover.hover.top.d500="'Matched To Payer'" class="fas fa-check-circle ml-2 text-success"></i><i v-if="!cancellation.isMatchedToPayer" v-b-popover.hover.top.d500="'Not Matched To Payer'" class="fas fa-times-circle ml-2 text-danger"></i>
            </template>
            <indemnity-advice :advice="cancellation" />
          </b-tab>
          <template slot="empty">
            There are no cancellations in this report.
          </template>
        </b-tabs>
      </div>
      </div>
  </div>
</template>
<script>
import axios from 'axios'
import indemnityAdvice from '@/Components/Collections/Indemnities/IndemnityAdvice'
import roles from "@/Assets/Constants/roles"
import colours from "@/Assets/Constants/colours"
import swal from 'sweetalert2'
export default {
  components: { indemnityAdvice },
  props: {
    reportId: String
  },
  data () {
    return {
      actionId: null,
      report: {
        indemnityReportId: '',
        paygateId: '',
        groupId: '',
        createdDate: '',
        productionDate: '',
        title: '',
        sun: '',
        serviceUserName: '',
        adviceCount: 0,
        totalDebitValue: 0,
        newAdviceCount: 0,
        dateOfDebit: '',
        cancellationCount: 0,
        totalCancellationValue: 0,
        signature: '',
        advices: [],
        cancellations: [],
        }
    }
  },
  mounted () {
    this.actionId = this.$route.query.actionId
    this.load()
  },
  computed: {
    canChallenge() {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Finance])
    }
  },
  methods: {
    removeAction () {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}indemnity/clearaction`, { headers: { 'x-cust-meta': this.paygateId }, params: { actionId: this.actionId, paygateId: this.paygateId  }, showload: true }).then(
        (response) => {
          this.$toastr.s('Action cleared')
          this.actionId = null
          this.$router.push('/actions')
        }
      )
    },
    async downloadReport () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}indemnity/${this.reportId}/file`)
        var blob = new Blob([response.data])
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, this.reportId + '-indemnity-report.xml')
          } else {
            var a = window.document.createElement('a')
            a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
            a.download = this.reportId + '-indemnity-report.xml'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
      } catch (err) {
        this.$toastr.e('Could not download file.')
      }
    },
    goToSchedule(payerId,planId) {
      this.$router.push({ name: 'PayerSchedule', params: { id: payerId, planId: planId } })
    },
    async createChallenge(advice){
      let adviceId = advice.adviceId
      console.log(advice)
      if (advice.productionDate.getTime === undefined || advice.productionDate === null) {
        advice.productionDate = new Date(advice.productionDate)
      }

      var nowDate = new Date();
      var diffDays = nowDate.getDate() - advice.productionDate.getDate();
      if (advice.reasonCode !== 7 && diffDays > 9) {
        let basis = await swal.fire({
          title: 'Challenge Window Elapsed',
          icon: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: 'Ok',
          confirmButtonColor: colours.success,
        });
      } else {
          let basis = await swal.fire({
            title: 'Create Challenge for this Indemnity Claim?',
            icon: 'warning',
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            confirmButtonColor: colours.success,
            html: '<input id="swal-input2" class="swal2-input" placeholder="Provide reason for Challenge" required/>',
            preConfirm: () => {
              if (document.getElementById('swal-input2').value !== '') {
                return document.getElementById('swal-input2').value
              } else {
                swal.showValidationMessage('Please provide a reason for the challenge.')
              }
            }
          });
          if (basis.value !== '' && basis.isConfirmed) {
            try {
              var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}indemnity/reportchallenge`, {
                adviceCollectionId: adviceId,
                basis: basis.value
              })
              if (response) {
                for (let i = 0; i < this.report.newAdvices.length; i++) {
                  if (this.report.newAdvices[i].adviceId === adviceId) {
                    this.report.newAdvices[i].challengeId = response.data
                    this.report.newAdvices[i].hasChallenge = true
                    this.$toastr.s('Challenge Created for ' + this.report.newAdvices[i].payingBankReference)
                  }
                }
              }
            } catch (e) {
              this.$toastr.e('Could not create Challenge')
            }
          }
      }
    },
    viewChallenge(challengeId) {
      this.$router.push({ name: 'View Indemnity Challenge', params: { challengeId } })
    },
    async load() {
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}indemnity/${this.reportId}`)
        this.report = response.data
        this.report.newAdvices.sort((a, b) => a.payingBankReference.localeCompare(b.payingBankReference))
        this.report.cancellations.sort((a, b) => a.payingBankReference.localeCompare(b.payingBankReference))
      } catch (err) {
        this.$toastr.e('an error occurred.')
      }
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    formatAmount (value) {
      return this.numberWithCommas((value / 1).toFixed(2))
    },
    codeToDescription (value) {
      let retval = ''
      switch (value) {
        case 1:
          retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
           break;
        case 2:
          retval = 'NO ADVANCE NOTICE WAS RECEIVED';
           break;
        case 3:
          retval = 'BANK CANCELS THE DIRECT DEBIT';
          break;
        case 4:
          retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
          break;
        case 5:
          retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
          break;
        case 6:
          retval = 'FRAUDULENT SIGNATURE';
          break;
        case 7:
          retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
          break;
        case 8:
          retval = 'SERVICE USER IS NOT RECOGNISED';
          break;
        default:
          retval = 'Invalid Code Supplied'
          break;
      }
      return retval
    }
  }
}
</script>
<style scoped>
  .clickableRow {
    cursor: pointer;
  }
  .vertical-tab-list {
    border-bottom: solid 0px black !important;
    border-right: 0px solid #ddd;
  }

  .vertical-tab-container {
    border-bottom: solid 0px black !important;
    border-right: 1px solid #ddd;
    padding-right: 0px;
  }

  .vertical-tab-list .nav-item .active, .vertical-tab-list .nav-item .nav-link:hover {
    border: 1px solid #ddd;
    border-right: solid 1px transparent;
    border-top-left-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem;
    border-bottom-right-radius: 0rem;
    border-top-right-radius: 0rem;
    margin: 0
  }

.indemnityTabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  max-width: 500px;
  max-height: 500px;
  overflow: auto;
  min-height: 500px;
}
.amountCell {
    text-align: right;
  }

  .ia-table, .ia-table thead th td, .ia-table tr td {
    border-style: none;
  }
  .ia-table thead th, .ia-table thead th td {
    background: transparent;
    padding: 0;
  }
</style>
