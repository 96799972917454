<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>Commission Audit Trail Administration</h2>
    </div>
    <div class="idb-block-content">
      <vue-good-table
        :paginationOptions="paginationOptions"
        :sort-options="sortOptions"
        :isLoading.sync="isTableLoading"
        :rows="rows"
        :columns="columns"
        :lineNumbers="true"
        mode="remote"
        :totalRows="totalRecords"
        @on-row-click="onRowClick"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        :search-options="{
            enabled: true
          }"
        styleClass="vgt-table striped bordered"
        ref="table"
      >
        <div slot="table-actions">
          <button
            @click.prevent="clearTableFilters"
            class="btn btn-link"
            v-b-popover.hover.top.d500="'Clear filters'"
          >
            <span class="fa-stack" style="font-size: 10px;">
              <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
              <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
            </span>
          </button>
        </div>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import statementStatusType from '@/Assets/Constants/commissionAgentStatementStatusType'

export default {
  mixins: [loading, tableFilterMixin],
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'AuditTrailId',
          field: 'id',
          hidden: true
        },
        {
          label: 'Reference',
          field: 'statementserialid'
        },
        {
          label: 'Agent',
          field: 'agentName'
        },
        {
          label: 'Statement Total',
          field: 'statementtotal'
        },
        {
          label: 'Statement Created Date',
          field: 'statementCreatedDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: true
        },

        {
          label: 'Statement Status',
          field: 'commissionStatementStatus',
          type: 'StatementTypes'
        }
      ],
      serverParams: {
        sort: [{ field: 'agentName', type: 'asc' }]
      }

    }
  },
  computed: {
    StatementTypes () {
      return Object.values(statementStatusType)
    }
  },
  async created () {
    console.log('commission admin loaded')
    await this.load()
  },
  methods: {
    async load () {
      await this.updateTable()
    },
    updateTable: async function () {
      try {
        var response = await axios.post(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetAuditTrailTable`,
          this.detail,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data'
          })
        console.log('table response', response)
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    onRowClick (params) {
      console.log('params', params)

      this.$router.push({ name: 'CommissionAgentAuditTrailStatement', params: { id: params.row.id } })
    }
  }

}
</script>
