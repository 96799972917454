<template>
  <div id="approveSubmission">
    <div v-show="actionLoaded === true">
      <div class="row form-group">
        <div class="col-md-2">Submission Type</div>
        <div class="col-md-2">
          <b-select v-model.trim="selectedSubType" :options="submissionTypes" />
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-primary"
          v-on:click="startApproveSubmission"
          :disabled="this.enableApprove === false"
        >Approve Submission</button>
        <button
          type="button"
          class="btn btn-danger pull-right"
          v-on:click="cancelSubmission"
          :disabled="this.enableApprove === false && this.canCancel === false"
        >Cancel Submission</button>
      </div>
    </div>
    <div v-show="showSmsRequest" class="row form-group"></div>
    <div v-show="showSmsRequest">
      <div class="row form-group">
        <div class="col-md-2">
          <strong>Security Code</strong>
        </div>
        <div class="col-md-2">
          <input ref="securityCodeFocus" name="securityCode" v-model="securityCode" class="form-control" @keyup.enter="verifySecurityCode" />
        </div>
        <div class="col-md-2">
          <button
            type="button"
            class="btn btn-light pull-right"
            v-on:click="verifySecurityCode"
            :disabled="this.verifyingCode === true"
          >Verify Code</button>
        </div>
      </div>
    </div>
    <plugin @installCancelled="pluginNotInstalled"></plugin>
  </div>
</template>

<script>
import axios from 'axios'
import bacsCommon from '@/Lib/BacsCommon.js'
import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
import Plugin from '@/Assets/Components/UsbTokens/Plugin'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'
// import groupTypes from '@/Assets/Constants/groupTypes'
import moment from 'moment'

export default {
  components: {
    plugin: Plugin
  },
  computed: {
    enableApprove: function () {
      return this.isLoaded === true && this.canProceed === true
    },
    actionLoaded () { return this.$store.getters.parentDataLoaded }
  },
  data () {
    return {
      submissionId: '',
      actionId: '',
      isLoaded: false,
      bacsGroup: {},
      paygateType: '',
      submissionTypes: {},
      selectedSubType: 0,
      password: '',
      paymentNetworkType: '',
      canProceed: true,
      canCancel: true,
      browser: '',
      deviceType: 'USB Token',
      pluginUpdateNotInstalled: false,
      securityCode: '',
      showSmsRequest: false,
      verifyingCode: false,
      indirectGroup: false // Set to true for PGO or indirect collections group.
    }
  },
  methods: {
    async startApproveSubmission () {
      // First check that the action can proceed i.e. someone else hasn't got in there first and cancelled the submission or something!
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        nextStatus: 'Approved'
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
        data: json
      })

      if (response.data.success) {
        if (this.bacsGroup.groupTypeDetails.approvalMethod === 'USB Token') {
          // Check for web extension and plugin...
          try {
            await this.$store.commit('setDeviceType', this.deviceType)
            await this.$store.dispatch('checkPlugin')
          } catch (error) {
            this.$toastr.w(error.message, 'Warning')
          }

          this.getUsbTokenThumbprint()
        } else if (this.bacsGroup.groupTypeDetails.approvalMethod === 'SMS') {
          this.requestSecurityCode()
        } else if (this.bacsGroup.groupTypeDetails.approvalMethod === 'Email') {
          this.emailSecurityCode()
        } else if (this.bacsGroup.groupTypeDetails.approvalMethod === 'Authenticator') {
          this.showSmsRequest = true
        } else if (this.bacsGroup.groupTypeDetails.approvalMethod === 'Password') {
          var pwdValid = await this.checkPassword()
          if (pwdValid) {
            await this.approveSubmission()
          } else {
            this.$toastr.e('Invalid approval password')
            this.canProceed = true
            this.canCancel = true
          }
        } else {
          await this.approveSubmission()
        }
      } else {
        this.$snapbar.w(response.data.errorMessage)
        this.canProceed = false
        this.canCancel = false
      }
    },

    async checkPassword () {
      this.password = await this.enterApprovalPassword()

      var json = JSON.stringify({
        userPin: this.password,
        hsmPinRequired: true
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/checkApprovalPassword',
        data: json,
        showload: true
      })

      return response.data.success
    },

    async enterApprovalPassword () {
      var enteredPassword = ''

      await this.$swal({
        title: 'Approval Password',
        type: 'info',
        animation: false,
        input: 'password',
        inputPlaceholder: 'Approval Password'
      }).then((result) => {
        enteredPassword = JSON.stringify(result)
      })

      return enteredPassword
    },

    getUsbTokenThumbprint () {
      if (this.browser === 'IE') {
        this.plugin = document.PayGatePlugin
        this.usbTokenCertificateThumbprint = this.plugin.GetCertificateThumbprint('eTPKCS11.dll')
        if (this.usbTokenCertificateThumbprint.indexOf('Error') === -1) {
          this.approveWithUsbToken()
        } else {
          this.$toastr.e(this.usbTokenCertificateThumbprint)
          this.canCancel = true
          this.canProceed = true
        }
      } else if (this.browser === 'Firefox') {
        return new Promise((resolve, reject) => {
          document.addEventListener('getPluginResponseWebPage', (response) => {
            var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
            if (pluginResponse.CertificateThumbprint !== null) {
              this.usbTokenCertificateThumbprint = pluginResponse.CertificateThumbprint
              this.approveWithUsbToken()
              resolve()
            } else {
              this.$toastr.e(pluginResponse.ErrorMessage)
              this.canProceed = true
              this.canCancel = true
              reject(new Error(pluginResponse.ErrorMessage))
            }
          }, { once: true })
          // eslint-disable-next-line
          var pluginEvent = new CustomEvent('clientCallPlugin', { 'detail': { PluginTask: 'GetThumbprint', DllName: 'eTPKCS11.dll' } })
          document.dispatchEvent(pluginEvent)
        })
      } else if (this.browser === 'Chrome') {
        return new Promise((resolve, reject) => {
          // eslint-disable-next-line
          chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'GetThumbprint', DllName: 'eTPKCS11.dll' }, (response) => {
            var pluginResponse = common.parsePluginResponse(response.response)
            if (pluginResponse.CertificateThumbprint !== null) {
              this.usbTokenCertificateThumbprint = pluginResponse.CertificateThumbprint
              this.approveWithUsbToken()
            } else if (pluginResponse.ErrorMessage !== null) {
              this.$toastr.e(pluginResponse.ErrorMessage)
              this.canProceed = true
              this.canCancel = true
            }
          })
          resolve()
        })
      }
    },

    async approveSubmission () {
      this.canProceed = false
      this.canCancel = false

      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        selectedSubType: this.selectedSubType,
        fileNumber: 0,
        fromRecordNumber: 0,
        toRecordNumber: 0,
        createAction: true,
        deletePaymentData: this.bacsGroup.groupTypeDetails.deletePaymentData
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/approve',
        data: json,
        showload: true
      })

      if (response.data.errorMessage === null || response.data.errorMessage.length === 0) {
        this.clearSubmissionFilter()
        var bacsSubmission = this.$store.getters.bacsSubmission
        var bespokeText = bacsSubmission.paymentNetworkType === 0 ? 'BACS' : 'Faster Payments'
        var msg = 'Your submission will be automatically sent to ' + bespokeText + '.<br /><br />If you don\'t receive a confirmation email within the next hour please contact London & Zurich support.'
        this.$toastr.i(msg)
        // }
      } else {
        this.$toastr.w(response.data.errorMessage)
      }
      if (response.data.tag) {
        this.navigateToNextPage(response)
      } else {
        const actionLoadedFromPath = this.$store.getters.actionLoadedFromPath
        if (actionLoadedFromPath === '/actions') {
          this.$router.push('/actions')
        } else {
          this.$router.push('/')
        }
      }
    },

    async approveWithUsbToken () {
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        thumbprint: this.usbTokenCertificateThumbprint,
        signAction: false
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/verifyUsbTokenCertificate',
        data: json,
        showload: true
      })

      if (response.data.success === true) {
        await this.approveSubmission()
      } else {
        this.$toastr.e(response.data.errorMessage)
        this.canCancel = true
        this.canProceed = true
      }
    },

    async requestSecurityCode () {
      this.canProceed = false
      this.canCancel = false
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/requestSecurityCode`, { showload: true })
      if (response.data.success) {
        this.showSmsRequest = true
        this.$nextTick(() => {
          this.$refs.securityCodeFocus.focus()
        })
      } else {
        this.$toastr.e(response.data.errorMessage)
        this.canProceed = true
        this.canCancel = true
      }
    },

    async verifySecurityCode () {
      this.verifyingCode = true
      this.canProceed = false
      this.canCancel = false

      if (this.bacsGroup.groupTypeDetails.approvalMethod === 'SMS' || this.bacsGroup.groupTypeDetails.approvalMethod === 'Email') {
        var json = JSON.stringify({
          submissionId: this.submissionId,
          actionId: this.actionId,
          securityCode: this.securityCode,
          actionSubClass: 'Approve'
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/verifySecurityCode',
          data: json,
          showload: true
        })

        if (response.data.success === true) {
          await this.approveSubmission()
        } else {
          this.verifyingCode = false
          this.$toastr.e(response.data.errorMessage)
          if (response.data.tag !== 'Invalid') {
            this.showSmsRequest = false
            this.securityCode = ''
          }
          this.canCancel = true
          this.canProceed = true
        }
      } else {
        this.authenticatorSecurityCode()
      }
    },

    async emailSecurityCode () {
      this.canProceed = false
      this.canCancel = false

      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/requestSecurityCodeViaEmail`, { showload: true })
      if (response.data.success) {
        this.showSmsRequest = true
      } else {
        this.$toastr.e(response.data.errorMessage)
        this.canProceed = true
        this.canCancel = true
      }
    },

    async authenticatorSecurityCode () {
      this.canProceed = false
      this.canCancel = false
      var json = JSON.stringify({
        Code: this.securityCode,
        Provider: 'Authenticator'
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_PLATFORM_API_URL + 'user/confirmConfirmCode',
        data: json,
        showload: true
      })

      if (response.data === true) {
        await this.approveSubmission()
      } else {
        this.verifyingCode = false
        this.$toastr.e('Incorrect security code, please try again')
        this.showSmsRequest = false
        this.securityCode = ''
        this.canCancel = true
        this.canProceed = true
      }
    },

    async cancelSubmission () {
      this.isLoaded = false
      var cancelReponse = await bacsCommon.cancelSubmissionDialog()
      if (cancelReponse.cancel === true) {
        var json = JSON.stringify({
          submissionId: this.submissionId,
          actionId: this.actionId,
          serviceUserNumber: this.bacsGroup.groupTypeDetails.serviceUserNumber,
          fileNumber: 0,
          fromRecordNumber: 0,
          toRecordNumber: 0,
          deletePaymentData: this.bacsGroup.groupTypeDetails.deletePaymentData,
          cancellationReason: cancelReponse.cancellationReason
        })

        this.clearSubmissionFilter()
        await bacsCommon.confirmCancellation(json, axios, this.$toastr, this.$router)
        await this.$store.dispatch('cancelCollectionsSubmission', this.submissionId)
      } else {
        this.isLoaded = true
      }
    },

    clearSubmissionFilter () {
      const savedFilterName = `submissionTotals${this.submissionId}`
      const defaultParams = {}
      this.$store.commit('updateTable', { name: savedFilterName, filters: defaultParams })
    },

    async getSubmissionTypes () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/getSubmissionTypes?submissionId=` + this.submissionId)
      this.submissionTypes = response.data
      this.selectedSubType = this.submissionTypes[0].value
    },

    async isServiceWindowOpen () {
      var bacsSubmission = this.$store.getters.bacsSubmission
      this.paymentNetworkType = bacsSubmission.paymentNetworkType
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingDate/isServiceWindowOpen?paymentNetworkType=` + this.paymentNetworkType)
      if (response.data.isWindowOpen === false) {
        this.canProceed = false
        var networkType = this.paymentNetworkType === 0 ? 'BACS' : 'Faster Payments'
        this.$toastr.w('You cannot approve your submission at this time as the ' + networkType + ' service window is closed.')
      }
    },

    downloadMsi () {
      bacsCommon.downloadPayGatePluginMsi(axios)
    },

    async waitForParent () {
      var looping = true
      while (looping) {
        if (this.actionLoaded === false) {
          await new Promise(resolve => setTimeout(resolve, 500))
        } else {
          looping = false
        }
      }
    },

    navigateToNextPage (response) {
      if (response.data.tag) {
        var routeJson = {}
        routeJson.id = response.data.messageId
        routeJson.actionedObjectId = this.submissionId
        routeJson.actionClass = 'Bacs'
        routeJson.actionSubClass = 'Send'
        this.$router.replace(returnRouteObject(routeJson))
      } else {
        this.$router.push('/')
      }
    },

    async doesUserHaveToken () {
      var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/submission/doesUserHaveToken')
      if (!response.data.success) {
        this.$snapbar.w('You are not assigned to a USB token, please contact your system administrator')
        this.canProceed = false
      }
    },

    pluginNotInstalled () {
      var pluginUpdateRequired = this.$store.getters.setPluginUpdateRequired
      this.pluginUpdateNotInstalled = pluginUpdateRequired === undefined ? false : pluginUpdateRequired
      this.canCancel = true
      this.canProceed = true
    },

    async checkFpsSubmission () {
      if (this.paymentNetworkType === 1) {
        var today = new Date()
        var todayDateFormatted = moment(today).format('YYYY-MM-DD')
        var outOfDateSubmission = false
        var intelligibleSummaries = this.$store.getters.intelligibleSummaries
        intelligibleSummaries.forEach(element => {
          var procDate = moment(element.processingDate).format('YYYY-MM-DD')

          if (todayDateFormatted > procDate) {
            outOfDateSubmission = true
          }
        })

        if (outOfDateSubmission) {
          this.canProceed = false
          this.$snapbar.w('This submission cannot be sent to Vocalink because the processing date for this Faster Payment submission has passed.')
        }
      }
    }
  },

  async mounted () {
    await this.waitForParent()
    this.submissionId = this.$store.getters.submissionId
    this.actionId = this.$store.getters.actionId
    this.bacsGroup = this.$store.getters.bacsGroup
    // console.log('group: ' + JSON.stringify(this.bacsGroup))
    var paygateId = this.$store.getters.getClaim('paygate_id').value
    this.$store.dispatch('setCustomerPaygateId', paygateId)

    if (this.bacsGroup.groupTypeDetails.approvalMethod === 'USB Token') {
      await this.doesUserHaveToken()
    }

    this.paygateType = bacsCommon.getPaygateType(this.bacsGroup.groupType)
    await this.getSubmissionTypes()
    await this.isServiceWindowOpen()

    window.setTimeout(() => {
      this.isLoaded = true
    }, 1000)

    this.browser = bacsCommon.getBrowser()

    var extLoaded = this.$route.query.pluginAccessible
    if (extLoaded === 'true') {
      if (this.browser === 'Firefox') {
        document.addEventListener('getExtensionResponseWebPage', this.initExtensionEvent)
        document.addEventListener('getPluginResponseWebPage', this.initPluginEvent)
      }

      this.pluginCheckComplete = true
      this.isLoggedIn = false
      this.isLoaded = true

      this.newPluginRequired = bacsCommon.isLatestPlugin(this.componentId, this.expectedVersion, this.deviceType)
      if (this.newPluginRequired === false) {
        this.startApproveSubmission()
      } else {
        this.pluginCheckComplete = false
        this.$toastr.w('Updated PayGate Plugin has not been installed so cannot use the ' + this.deviceType)
      }
    }

    this.isLoaded = true
  },

  destroyed () {
    if (this.browser === 'Firefox') {
      document.removeEventListener('getPluginResponseWebPage', this.initPluginEvent)
      document.removeEventListener('getExtensionResponseWebPage', this.initExtensionEvent)
    }
  }
}
</script>
