<template>
  <div>
    <div id="payerSetupForm">
      <div class="row mb-4">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>Inject BACS Reports (FOR QA PURPOSES ONLY)</h2>
            </div>
            <div class="idb-block-content" v-if="!payerSelected">
              <div class="groupSelector">
                <div class="row form-group">
                  <div class="col-md-2 required">Select a Group</div>
                  <div class="col-md-4">
                    <group-select
                      v-model="group"
                      :groups="customerGroups"
                      @input="groupChanged"
                      :clearable="false"
                    ></group-select>
                    <p v-if="allGroups">
                      <small
                        >Customers can only be created once a group is
                        selected.</small
                      >
                    </p>
                  </div>
                  <b-form-group
                    :label-cols="2"
                    class="col-sm-4"
                    horizontal
                    label="Status"
                  >
                    <b-form-select
                      v-model="statusFilter"
                      :options="statuses"
                      required
                      value-field="value"
                      text-field="name"
                      @change="load"
                    />
                  </b-form-group>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <vue-good-table
                    mode="remote"
                    ref="table"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"
                    @on-row-click="onRowClick"
                    @on-cell-click="onCellClick"
                    :columns="columns"
                    :rows="inactivePayers"
                    :lineNumbers="true"
                    :totalRows="totalRecords"
                    :search-options="{
                      enabled: true,
                    }"
                    :paginationOptions="paginationOptions"
                    :sort-options="sortOptions"
                    :rowStyleClass="rowToCss"
                    :isLoading.sync="isTableLoading"
                    styleClass="vgt-table striped bordered"
                  >
                    <template slot="loadingContent">
                      <h1>Loading...</h1>
                    </template>
                    <div slot="emptystate">
                      <div class="vgt-center-align vgt-text-disabled">
                        No Customers Available
                      </div>
                    </div>
                    <div slot="table-actions">
                      <button
                        @click.prevent="clearTableFilters"
                        class="btn btn-link"
                        v-b-popover.hover.top.d500="'Clear filters'"
                      >
                        <span class="fa-stack" style="font-size: 10px">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span>
                      </button>
                      <b-button
                        @click.prevent="addNewPayer"
                        class=""
                        :disabled="allGroups || isLoading"
                        variant="link"
                        v-b-popover.hover.top.d500="
                          allGroups
                            ? 'A group must be selected to add a customer'
                            : 'Create a new customer in the current group.'
                        "
                      >
                        <i class="fa fa-plus pointer dimmedIcon"></i>
                      </b-button>
                      <b-button
                        @click.prevent="load"
                        class=""
                        :disabled="isLoading"
                        variant="link"
                        v-b-popover.hover.top.d500="
                          'Refresh the data in the table'
                        "
                      >
                        <i class="fa fa-sync pointer dimmedIcon"></i>
                      </b-button>
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field === 'status'">
                        <b-badge
                          pill
                          :variant="getBadgeClass(props.row.status)"
                          >{{ props.formattedRow[props.column.field] }}</b-badge
                        >
                      </span>
                      <span v-else-if="props.column.field === 'buttons'">
                        <b-button
                          v-if="props.row.plans.length === 1"
                          @click="
                            goToSchedule(
                              props.row.ukPayerId,
                              props.row.plans[0].planId
                            )
                          "
                          variant="outline-secondary"
                          ><i class="fa fa-calendar mr-2"></i>View
                          Schedule</b-button
                        >
                        <b-dropdown
                          v-if="props.row.plans.length > 1"
                          variant="outline-secondary"
                        >
                          <!-- no-caret -->
                          <span slot="button-content"
                            ><i class="fa fa-calendar mr-2"></i>View
                            Schedule</span
                          >
                          <b-dropdown-item
                            v-for="(plan, index) in props.row.plans"
                            :key="index"
                            @click="
                              goToSchedule(props.row.ukPayerId, plan.planId)
                            "
                            ><i
                              v-if="plan.planStatus === 2"
                              v-b-tooltip.hover
                              title="Suspended"
                              class="fa fa-exclamation-circle text-danger"
                            ></i
                          ></b-dropdown-item>
                        </b-dropdown>
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
            <div class="idb-block-content" v-if="payerSelected">
              <div class="row form-group">
                  <div class="col-md-2 required">Customer Reference</div>
                  <div class="col-md-4">
                  <b-input type="text" :disabled="true" v-model="selectedPayer.reference" />
                  </div>
               </div>
               <div class="row form-group">
                  <div class="col-md-2 required">Plan</div>
                  <div class="col-md-4">
                  <vue-select
                      v-model="selectedPlan"
                      :options="selectedPayer.plans"
                      :closeOnSelect="true"
                      label="planName"
                      @input="showSelectables">
                    </vue-select>
                  </div>
               </div>
               <div class="row form-group">
                  <div class="col-md-2 required">Report Type</div>
                  <div class="col-md-4">
                    <vue-select
                      v-model="selectedReportType"
                      :options="reportTypes"
                      :closeOnSelect="true"
                      label="label"
                      @input="showSelectables"
                    >
                    </vue-select>
                  </div>
               </div>
               <div class="row form-group" v-if="reportsEtcSelected">
                  <div class="col-md-2 required">Error Codes</div>
                  <div class="col-md-4">
                    <vue-select
                      v-model="selectedErrorCode"
                      :options="errorCodes"
                      :closeOnSelect="true"
                      label="label"
                    >
                    </vue-select>
                  </div>
               </div>
               <div class="row form-group" v-if="reportsEtcSelected">
                  <div class="col-md-2">Collections Affected</div>
                  <div class="col-md-4">
                    <vue-select multiple
                      v-model="selectedPayments"
                      :options="paymentSelect"
                      :closeOnSelect="true"
                      label="title"
                    >
                    </vue-select>
                  </div>
               </div>
              <div class="row form-group" v-if="reportsEtcSelected">
                <div class="col-md-2">Use Wrong Data?</div>
                <div class="col-md-4">
                  <input type="checkbox" v-model="useWrong" />
                </div>
              </div>
              <div class="row form-group" v-if="reportsEtcSelected && useWrong">
                <div class="col-md-2">Wrong Reference</div>
                <div class="col-md-4">
                  <b-input type="text" v-model="wrongPayerReference" />
                </div>
              </div>
              <div class="row form-group" v-if="reportsEtcSelected && useWrong">
                <div class="col-md-2">Wrong Amount (please use numbers)</div>
                <div class="col-md-4">
                  <b-input type="text" v-model="wrongAmount" />
                </div>
              </div>
              <div class="row form-group" v-if="reportsEtcSelected && useWrong">
                <div class="col-md-2">Wrong Payer Bank Account</div>
                <div class="col-md-4">
                  <b-input type="text" v-model="wrongPayerBankAccount" />
                </div>
              </div>
              <div class="row form-group" v-if="reportsEtcSelected && useWrong">
                <div class="col-md-2">Wrong Payer Sort Code</div>
                <div class="col-md-4">
                  <b-input type="text" v-model="wrongPayerSortCode" />
                </div>
              </div>
              <div class="row form-group" v-if="reportsEtcSelected && useWrong">
                <div class="col-md-2">Wrong SUN</div>
                <div class="col-md-4">
                  <b-input type="text" v-model="wrongSun" />
                </div>
              </div>
              <div class="row form-group" v-if="reportsEtcSelected && useWrong">
                <div class="col-md-2">Wrong Service User Bank Account</div>
                <div class="col-md-4">
                  <b-input type="text" v-model="wrongSunBankAccount" />
                </div>
              </div>
              <div class="row form-group" v-if="reportsEtcSelected && useWrong">
                <div class="col-md-2">Wrong Service User Sort Code</div>
                <div class="col-md-4">
                  <b-input type="text" v-model="wrongSunSortCode" />
                </div>
              </div>
              <div class="row form-group" v-if="reportsEtcSelected && useWrong">
                <div class="col-md-2">Wrong Processing Date DD/MM/YYYY (if you use a format other than this, it'll ignore it)</div>
                <div class="col-md-4">
                  <b-input type="text" v-model="wrongCollectionDate" />
                </div>
              </div>
              <div class="row form-group" v-if="reportsEtcSelected && useWrong && false">
                <div class="col-md-2">New Payer Bank Account</div>
                <div class="col-md-4">
                  <b-input type="text" v-model="newPayerBankAccount" />
                </div>
              </div>
              <div class="row form-group" v-if="reportsEtcSelected && useWrong && false">
                <div class="col-md-2">New Payer Sort Code</div>
                <div class="col-md-4">
                  <b-input type="text" v-model="newPayerSortCode" />
                </div>
              </div>
               <div class="row form-group" v-if="reportsEtcSelected && selectedReportType && selectedReportType !== null">
                  <div class="col-md-2">Advices to Cancel</div>
                  <div class="col-md-4">
                    <vue-select multiple
                      v-model="selectedAdvices"
                      :options="advices"
                      :closeOnSelect="true"
                      label="date"
                    >
                    </vue-select>
                  </div>
               </div>
               <div class="row form-group" v-if="reportsEtcSelected && selectedReportType && selectedReportType !== null">
                <div class="col-md-2">Process created report?</div>
                <div class="col-md-4">
                  <input type="checkbox" v-model="processReport" />
                  <small>If unprocessed, the report will be processed during the daily report processing task.</small>
                </div>
               </div>
            </div>
            <div class="idb-block-footer" v-if="payerSelected">
                <b-btn
                  id="addPayerButton"
                  :disabled="selectedPayments.length === 0 && selectedAdvices.length === 0 && selectedErrorCode !== null"
                  type="link"
                  variant="primary"
                  @click="generateReport"
                  v-b-popover.hover.top.d500="
                    allGroups
                      ? 'A group must be selected to add a customer'
                      : 'Create a new customer in the current group.'
                  "
                 class="pull-right">
                  <i class="fa fa-plus mr-2"></i>Create BACS Report
                </b-btn>
                <b-btn type="link" v-if="payerSelected" variant="danger" @click="cancel">Cancel</b-btn>
              </div>
          </div>
          <div class="idb-block mt-5">
            <div class="idb-block-title">
              <h2>Manually Cancel (AGAIN, this is for QA reasons only) 🤫</h2>
            </div>
            <div class="idb-block-content">
              <b-form-row>
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  horizontal
                  label="DDIC Reference (to cancel)"
                  label-for="groupInput"                >
                  <b-form-input id="groupInput" v-model="cancellationRef" type="text" />
                </b-form-group>
              </b-form-row>
              <b-form-row>
                <b-form-group :label-cols="5" class="col-sm-6" horizontal label-for="businessCheck" label="Process Immediately?">
                  <p-check
                    id="businessCheck"
                    class="p-switch p-fill"
                    color="primary"
                    v-model="processCancellationImmediately"
                  ></p-check>
                </b-form-group>
              </b-form-row>
            </div>
            <div class="idb-block-footer">
              <button
                type="button"
                class="btn btn-warning"
                v-on:click="cancelThing"
              >🤔 Canc3l</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import EventBus from "@/Lib/eventBus";
import _ from "lodash";
import tableFilterMixin from "@/Assets/Mixins/TableFilterMixin";
import loading from "@/Assets/Mixins/LoadingMixin";
import axios from "axios";
import { mapGetters } from "vuex";
import VueSelect from 'vue-select'
const statusToText = (x) => {
  switch (x) {
    case -1:
      return "Any";
    case 0:
      return "Operational";
    case 1:
      return "Suspended";
    case 2:
      return "Alert";
    case 3:
      return "Closed";
    case 4:
      return "Incomplete";
    case 5:
      return "Awaiting Activation";
    case 6:
      return "Plan Suspended";
    case 7:
      return "Deleted";
    default:
      return "Unknown";
  }
};

const statusToBadgeVariant = (x) => {
  switch (x) {
    case 3:
      return "secondary";
    case 2:
    case 6:
      return "warning";
    case 1:
    case 7:
      return "danger";
    case 0:
      return "success";
    case 4:
    case 5:
      return "info";
    default:
      return "dark";
  }
};

export default {
  mixins: [tableFilterMixin, loading],
  components: {
    VueSelect
  },
  name: "payerSetup",
  async mounted() {
    const onPaygateChange = (paygateId) => {
      const paygateIdNotDefault =
        paygateId && paygateId !== "00000000-0000-0000-0000-000000000000";
      if (paygateIdNotDefault) {
        this.$store.dispatch("loadCustomerGroups", paygateId);
      }
    };
    EventBus.$on("paygateIdChange", onPaygateChange);
    this.$once("hook:beforeDestroy", () => {
      EventBus.$off("paygateIdChange", onPaygateChange);
    });
    if (this.$store.getters.customerGroups === null) {
      await this.$store.dispatch("loadCustomerGroups", this.paygateId);
      if (this.$store.getters.customerGroups !== null) {
        const initiallySelectedGroup = this.prepopulatedGroup
          ? this.prepopulatedGroup
          : "00000000-0000-0000-0000-000000000000";
        this.group = initiallySelectedGroup;
        const table = this.$refs["table"];
        const searchTerm = this.prepopulatedSearchTerm
          ? this.prepopulatedSearchTerm
          : "";
        table.globalSearchTerm = searchTerm;
        table.$emit("on-search", {
          searchTerm: searchTerm,
        });
      }
    } else {
      this.group = "00000000-0000-0000-0000-000000000000";
    }
    this.updateTotal = true;
    this.load();
  },
  computed: {
    paygateId() {
      return this.$store.getters.selectedCustomer !== undefined
        ? this.$store.getters.selectedCustomer
        : this.$store.state.common.customers.data[0].paygateId;
    },
    customerGroups() {
      var ret = [];
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups);
      }
      ret.unshift({
        description: "",
        groupId: "00000000-0000-0000-0000-000000000000",
        groupType: "UK DDMS",
        name: "All Customers",
        paygateId: this.paygateId,
        isItemActioned: false,
        clonedName: null,
        colour: null,
      });
      return ret;
    },
    errorCodes() {
      let retVal = [];
      if (this.selectedReportType && this.selectedReportType !== null) {
        switch (this.selectedReportType.type) {
        case 4: // ADDACS
          retVal = [
            { code: "0", label: "0 - Instruction Cancelled" },
            { code: "1", label: "1 - Instruction Cancelled by customer" },
            { code: "2", label: "2 - Customer Deceased" },
            { code: "3", label: "3 - Account Transferred" },
            // { code:'4', label:'4 - ' },
            { code: "B", label: "B - Account Closed" },
            { code: "C", label: "C - Account Transferred" },
            { code: "D", label: "D - Advance Notice Disbuted" },
            { code: "E", label: "E - Instruction Amended" },
            { code: "R", label: "R - Instruction re-instated" },
          ];
          break
        case 1: // ARUDD
          retVal = [
            { code: "0", label: "0 - REFER TO CUSTOMER" },
            { code: "1", label: "1 - INSTRUCTION CANCELLED" },
            { code: "2", label: "2 - CUSTOMER DECEASED" },
            { code: "3", label: "3 - ACCOUNT TRANSFERRED" },
            { code: "4", label: "4 - ADVANCE NOTICE DISPUTED" },
            { code: "5", label: "5 - NO ACCOUNT" },
            { code: "6", label: "6 - NO INSTRUCTION" },
            { code: "7", label: "7 - AMOUNT DIFFERS" },
            { code: "8", label: "8 - AMOUNT NOT YET DUE" },
            { code: "9", label: "9 - PRESENTATION OVERDUE" },
            { code: "A", label: "A - SERVICE USER DIFFERS" },
            { code: "B", label: "B - ACCOUNT CLOSED" },
          ];
          break
        case 6: // AUDDIS
          retVal = [
            { code: "1", label: "1 - Instruction Cancelled by custoemr" },
            { code: "2", label: "2 - Customer Deceased" },
            { code: "3", label: "3 - Account Transferred" },
            { code: "5", label: "5 - No Account" },
            { code: "6", label: "6 - No Instruction" },
            { code: "7", label: "5 - DDI Amount not zero" },
            { code: "B", label: "B - Account Closed" },
            { code: "C", label: "C - Account Transferred" },
            { code: "F", label: "F - Invalid Account Type" },
            {
              code: "G",
              label: "G - Bank will not accept direct debits on this account",
            },
            { code: "H", label: "H - Instruction has expired" },
            { code: "I", label: "I - Customers reference is not unique" },
            // { code:'J', label: '' },
            { code: "K", label: "K - Instruction cancelled by paying bank" },
            { code: "L", label: "L - Incorrect customers account details" },
            {
              code: "M",
              label: "M - Transaction code / user status incompatible",
            },
            { code: "N", label: "N - Transaction Disallowed at customers branch" },
            { code: "O", label: "O - Invalid Reference" },
            { code: "P", label: "P - Customers name not present" },
            { code: "Q", label: "Q - Service Users name blank" },
          ];
          break
        case 8: // DDIC
          retVal = [
            {
              code: "1",
              label:
                "CODE 1: Amount and / or date of Direct Debit differ from Advance Notice",
            },
            { code: "2", label: "CODE 2: No Advance Notice received by Payer" },
            { code: "3", label: "CODE 3: DDI cancelled by paying bank" },
            {
              code: "4",
              label: "CODE 4: Customer has cancelled DDI direct with service user",
            },
            {
              code: "5",
              label:
                "CODE 5: AUDDIS service users only - No Instruction held. Customer disputes having given authority",
            },
            {
              code: "6",
              label:
                "CODE 6: AUDDIS service users only - Signature on DDI is fraudulent or not in accordance with account authorised signature(s)",
            },
            {
              code: "7",
              label:
                "CODE 7: Claim raised at service users request after Direct Debit applied to customers account",
            },
            {
              code: "8",
              label:
                "CODE 8: Service user name disputed. Customer does not recognise service user collecting Direct Debit",
            },
          ];
          break
        case 10:
          retVal = ['N/A']
          break
        default:
          retVal = [];
          break;
      }
      }
      return retVal;
    },
    group: {
      get() {
        return this.$store.state.payer.currentPayer.groupId;
      },
      set(value) {
        if (value) {
          this.$store.commit("updateGroup", value);
        }
      },
    },
    allGroups() {
      return (
        !this.group ||
        this.group === undefined ||
        this.group === null ||
        this.group === "00000000-0000-0000-0000-000000000000"
      )
    },
    ...mapGetters(["isParentConnectedMerchant"]),
    paymentSelect () {
      let formatDate = this.formatDate
      return this.payments.map(function (payment) {
        var dateString = ''
        if (!payment.collectionDate.getDate) {
          payment.collectionDate = new Date(payment.collectionDate)
        }
        var day = payment.collectionDate.getDate()
        var monthIndex = payment.collectionDate.getMonth() + 1
        var year = payment.collectionDate.getFullYear()
        dateString =`${day}/${monthIndex}/${year}`
        return { title: `${dateString} - £${payment.amount}`, value: payment.paymentId }
      })
    }
  },
  props: {
    prepopulatedGroup: String,
    prepopulatedSearchTerm: String,
    prepopulatedStatus: String,
  },
  data() {
    return {
      inactivePayers: [],
      statusFilter: parseInt(this.prepopulatedStatus, 10)
        ? parseInt(this.prepopulatedStatus, 10)
        : -1, // if fails to parse to int w/ radix 10 then default to value '3'
      statuses: [-1, 0, 1, 2, 3, 4, 5].map((i) => ({
        name: statusToText(i),
        value: i,
      })), // Server side enum values
      selectedUkPayer: {},
      groupValid: false,
      ukPayerSelected: false,
      columns: [
        {
          label: "id",
          field: "ukPayerId",
          hidden: true,
        },
        {
          label: "Reference",
          field: "reference",
        },
        {
          label: "Merchant Name",
          field: "merchantName",
          sortable: false,
          hidden: this.isParentConnectedMerchant,
        },
        {
          label: "Group",
          field: "groupName",
          sortable: false,
        },
        {
          label: "Customer Status",
          field: "status",
          formatFn: statusToText,
          tdClass: "text-center",
        },
        {
          label: "Name",
          field: "displayName",
        },
        // {
        //   label: 'First Name',
        //   field: 'firstName'
        // },
        // {
        //   label: 'Surname',
        //   field: 'surname'
        // },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Mobile Number",
          field: "mobile",
        },
      ],
      incompleteCount: 0,
      serverParams: {
        sort: [{ field: "reference", type: "asc" }],
      },
      reportTypes: [
        { label: "ADDACS", type: 4 },
        { label: "ARUDD", type: 1 },
        { label: "AUDDIS", type: 6 },
        { label: "DDIC", type: 8 },
        { label: "Withdrawal Report", type: 10 },
      ],
      selectedPayer: null,
      payerSelected: false,
      selectedPlan: null,
      selectedReportType: null,
      reportsEtcSelected: false,
      selectedErrorCode: null,
      selectedPayments: [],
      selectedAdvices: [],
      advices: [],
      payments: [],
      auddisInstructions: [],
      processReport: false,
      wrongPayerReference: '',
      useWrong: false,
      wrongAmount: '',
      wrongSun: '',
      wrongSunBankAccount: '',
      wrongSunSortCode: '',
      wrongCollectionDate: '',
      wrongPayerBankAccount: '',
      wrongPayerSortCode: '',
      newPayerBankAccount:'',
      newPayerSortCode:'',
      processCancellationImmediately: false,
      cancellationRef: ''
    };
  },
  methods: {
    async cancelThing () {
      try {
        var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}testdata/cancelfromddic`, { cancellationRef: this.cancellationRef, cancelImmediately: this.processCancellationImmediately },
          { showLoad: true })
        if (response.status === 200) {
          this.cancellationRef = false
          if (this.processCancellationImmediately) {
            this.$toastr.s('Cancellation added to the system, then processed.')
            this.processCancellationImmediately = false
          } else {
            this.$toastr.s('Cancellation added to the system.')
          }


        } else {
          this.$toastr.e('An error occurred, apparently')
        }
      } catch (err) {
        this.$toastr.e('Could not cancel instruction')
      }
    },
    async generateReport () {
      var advicePayments = []
      for(var i = 0; i < this.payments.length; i++){
        for(let o = 0; o < this.selectedPayments.length; o++) {
          if(this.payments[i].paymentId === this.selectedPayments[o].value) {
            advicePayments.push({ paymentId: this.payments[i].paymentId, reason: this.selectedErrorCode.code })
          }
        }
      }
      var advicesToCancel = []
      for(var i = 0; i < this.advices.length; i++){
        for(let o = 0; o < this.selectedAdvices.length; o++) {
          console.log(this.selectedAdvices[o])
          if(this.selectedAdvices[o].paymentId === this.advices[i].paymentId) {
            advicesToCancel.push({paymentId: this.advices[i].paymentId, reason: this.selectedErrorCode.code})
          }
        }
      }
      try {
        var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}testdata/generatetestdata`,
        {
          ukPayerId: this.selectedPayer.ukPayerId,
          errorCodes: [ this.selectedErrorCode.code ],
          processReports: this.processReport,
          reportType: this.selectedReportType.type,
          advicePayments: advicePayments,
          advicesToCancel: advicesToCancel,
          paymentPlanId: this.selectedPlan.planId,
          wrongReference: this.wrongPayerReference,
          useWrongReference: this.useWrong,
          wrongAmount: this.wrongAmount,
          wrongSun: this.wrongSun,
          wrongSunBankAccount: this.wrongSunBankAccount,
          wrongSunSortCode: this.wrongSunSortCode,
          wrongCollectionDate: this.wrongCollectionDate,
          wrongPayerBankAccount: this.wrongPayerBankAccount,
          wrongPayerSortCode: this.wrongPayerSortCode,
          newPayerBankAccount: this.newPayerBankAccount,
          newPayerSortCode: this.newPayerSortCode
        },
        { showLoad: true })
        if (response.status === 200) {
          this.$toastr.s('Generated data.')
          this.reportsEtcSelected = false
          this.advices = []
          this.payments = []
          this.selectedUkPayer = null
          this.selectedPlan = null
          this.selectedErrorCode = null
          this.selectedReportType = null
          this.payerSelected = false
          this.selectedPayer = null
          this.$store.dispatch('updateCurrentCollectionsAlertCount')
        }
      } catch {
        this.$toastr.e('Could not generate data.')
      }

    },
    cancel () {
      this.reportsEtcSelected = false
        this.advices = []
        this.payments = []
        this.selectedUkPayer = null
        this.selectedPlan = null
        this.selectedErrorCode = null
        this.selectedReportType = null
        this.payerSelected = false
        this.selectedPayer = null
    },
    async showSelectables () {
      this.reportsEtcSelected = false
      if (this.selectedPlan !== null & this.selectedReportType !== null) {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}testdata/${this.selectedPlan.planId}`, { params: { }, showLoad: true })
        this.selectedAdvices = []
        this.selectedPayments = []
        this.advices = response.data.advices
        this.payments = response.data.paymentData
        this.auddisInstructions = response.data.auddisInstructions
        this.reportsEtcSelected = true
      }
    },
    formatValidationBool(row, col) {
      if (
        [
          "payerDetailsValidated",
          "bankAccountValidated",
          "paymentPlansValidated",
        ].indexOf(col.field) > -1
      ) {
        return row[col.field] ? "Complete" : "More details required...";
      } else {
        return row[col.field];
      }
    },
    rowToCss(row) {
      if (row.isNew) {
        return "new-item";
      } else {
        return "";
      }
    },
    addNewPayer() {
      this.$router.push({
        name: "createPayer",
        params: { groupid: this.group },
      });
    },
    confirmActivation(ukPayerId) {
      this.$store.dispatch("confirmActivation", { ukPayerId });
    },
    groupChanged() {
      this.updateParams({ page: 1 });
    },
    load: _.debounce(async function () {
      if (this.group !== 0) {
        // await this.$store.dispatch('getGroupConfigurationFromGroupId', { id: this.group })
        var query = this.buildGoodTableQuery();
        query.payerStatus = this.statusFilter === -1 ? null : this.statusFilter; // if -1 ('Any'), send null to server
        query.groupId = this.group;
        query.paygateId = this.paygateId;
        const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}jftppayers/withsubs`, { params: query, showLoad: true })
        this.inactivePayers = response.data.data
        this.totalRecords = response.data.count
      }
    }, 300),
    printTable() {
      console.log("printTable not yet implemented");
    },
    exportTable() {
      console.log("exportTable not yet implemented");
    },
    onRowClick(event) {
      // forces the table to do pointer cursor on row etc
    },
    onCellClick(event) {
      this.selectedPayer = event.row
      this.payerSelected = true
    },
    dirtyGroup() {
      this.$v.group.$touch();
      this.groupValid = !this.$v.group.$invalid;
    },
    goToSchedule(payerId, planId) {
      this.$router.push({
        name: "PayerSchedule",
        params: { id: payerId, planId: planId },
      });
    },
    getBadgeClass(value) {
      return statusToBadgeVariant(value);
    },
    hasSuspendedPlan(rowObject) {
      for (let i = 0; i < rowObject.plans.length; i++) {
        if (rowObject.plans[i].status === 2) {
          return true;
        }
      }
      return false;
    },
    formatDate (date) {
      if (date !== null && date !== undefined) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      }
      return ''
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
  },
  validations: {
    group: { required },
  },
};
</script>

<style>
@keyframes yellowfade {
  from {
    background: yellow;
  }
  to {
    background: transparent;
  }
}

.new-item {
  animation-name: yellowfade;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
}

.groupSelector {
  margin-top: 1em;
  margin-left: 1em;
}
</style>
