<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <form novalidate v-on:submit.prevent>
            <div class="idb-block-title">
              <h2 id="title-of-block-check">Change Password</h2>
            </div>
            <div class="idb-block-content">
              <div class="alert alert-danger" v-if="anyErrors" role="alert">
                <span v-for="error in errors" v-bind:key="error">{{error}}</span>
              </div>
              <div
                class="form-group col-md-5 col-sm-10"
                :class="{'warn': $v.currentPassword.$error}"
              >
                <!-- Label -->
                <label class="label-control required">Current Password</label>
                <!-- Input -->
                <input
                  v-model="$v.currentPassword.$model"
                  class="form-control"
                  type="password"
                  v-focus
                />
                <!-- Validation Messages -->
                <span v-if="$v.currentPassword.$dirty">
                  <small
                    class="form-text text-warning small"
                    v-if="!$v.currentPassword.required"
                  >You must enter your current password</small>
                </span>
              </div>
              <change-password-inputs v-model="passwords" v-bind:user-id="userId"></change-password-inputs>
            </div>
            <div class="idb-block-footer">
              <button
                class="btn btn-primary"
                @click="submit"
                type="submit"
                :disabled="!passwords.valid || $v.invalid"
              >Submit</button>
              <button type="button" class="btn btn-danger pull-right" @click="cancel">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import changePasswordInputs from '@/Assets/Components/UserSettings/ChangePassword/ChangePasswordInputs'

export default {
  name: 'changePassword',
  components: {
    'change-password-inputs': changePasswordInputs
  },
  computed: {
    anyErrors () {
      // some with this will return true if anything exists in JS
      return this.errors.some(x => x)
    },
    userId () {
      return this.$store.getters.getUserId
    }
  },
  data () {
    return {
      passwords: {
        password: '',
        confirmPassword: '',
        valid: false
      },
      currentPassword: '',
      errors: []
    }
  },
  methods: {
    async submit () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        var requestData = { OldPassword: this.currentPassword, Password: this.passwords.password, ConfirmPassword: this.passwords.confirmPassword }
        var response = await axios.post(process.env.VUE_APP_PLATFORM_API_URL + 'User/ChangePassword', requestData)

        if (response.data.success) {
          this.$router.push({ name: 'ChangeSettings', params: { passwordChanged: true } })
        } else {
          this.errors = response.data.errors
        }
      }
    },
    cancel () {
      this.$router.replace({ name: 'ChangeSettings' })
    }
  },
  validations () {
    return {
      currentPassword: { required }
    }
  }
}
</script>
