<template>
  <actions-block
    v-bind:action="action"
    :status="status"
    :from="from"
    name="Anti-Fraud List"
    v-if="action.id"
  >
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Anti-Fraud List Details</h2>
      </div>
      <div class="idb-block-content">
        <div class="alert alert-secondary" role="alert" v-if="status === 'Edit'">
          The previous details are shown in
          <span class="del">red</span>, the new details in
          <span class="ins">green</span>.
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Name</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.name"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Description</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px;"
                v-for="diff in difference.description"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">List Type</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                v-for="diff in difference.listType"
                :class="{ins:diff.added,del:diff.removed}"
                :key="diff.value"
              >{{diff.value}}</span>
            </div>
          </div>
        </div>
        <div v-if="itemCount > 0" class="row">
          <label class="col-sm-2 text-right col-form-label">Anti-Fraud List Items</label>
        </div>
        <div v-if="itemCount > 0" class="row form-group">
          <div class="col-md-12">
            <vue-good-table
              ref="localItems"
              :columns="columns"
              :rows="pendingItems"
              :lineNumbers="true"
              :totalRows="itemCount"
              :pagination-options="{
                enabled: true,
                perPage: 10,
                dropdownAllowAll: false
              }"
              styleClass="vgt-table striped bordered"
            ></vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </actions-block>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { diffSentences } from 'diff'
import axios from 'axios'
import ActionsBlock from '@/Components/Platform/Actions/ActionsBlock'

export default {
  props: ['actionId', 'antiFraudListId'],
  components: { 'actions-block': ActionsBlock },
  computed: {
    difference () {
      if (!this.antiFraudList || !this.action.data) {
        if (this.status === 'Create') {
          this.antiFraudList = this.action.data
        } else if (this.status === 'Delete' && this.antiFraudList) {
          this.action.data = this.antiFraudList
        } else {
          return {}
        }
      }
      return Object.keys(this.antiFraudList).reduce((a, v) => {
        if (v === 'isItemActioned' || v === 'modifiedBy') {
          return a
        }

        if (this.antiFraudList[v] === null || this.antiFraudList[v] === undefined) {
          this.antiFraudList[v] = ''
        }
        if (this.action.data[v] === null || this.action.data[v] === undefined) {
          this.action.data[v] = ''
        }

        var tempObj = {}
        tempObj[v] = diffSentences(this.antiFraudList[v].toString(), this.action.data[v].toString())
        return Object.assign(a, tempObj)
      }, {})
    }
  },
  data () {
    return {
      antiFraudList: null,
      action: { data: null },
      status: '',
      itemCount: 0,
      pendingItems: [],
      columns: [
        {
          label: 'Account Name',
          field: 'thirdPartyAccountName',
          width: '50%'
        },
        {
          label: 'Sort Code',
          field: 'thirdPartySortCode',
          width: '25%'
        },
        {
          label: 'Account Number',
          field: 'thirdPartyAccountNumber',
          width: '25%'
        }
      ],
      from: {}
    }
  },

  async mounted () {
    try {
      // Get Action Detail
      var actionResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/${this.actionId}`, { action: true })
      this.action = actionResponse.data
      this.status = this.action.actionSubClass
    } catch {
    }

    // Get anti-fraud list
    try {
      if (this.status !== 'Create') {
        const response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/${this.antiFraudListId}`,
          { params: { paygateId: this.$store.state.common.paygateId }, showload: true, showerror: true, errormessage: 'Unable to get Anti-Fraud List' }
        )
        this.antiFraudList = response.data
      }
    } catch { }

    try {
      // Get pending items
      var itemResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/getpendingItems/${this.antiFraudListId}`, { showload: true, showerror: true, errormessage: 'Unable to get pending items' })
      this.pendingItems = itemResponse.data
      if (this.pendingItems !== undefined && this.pendingItems !== null) {
        this.itemCount = this.pendingItems.length
      }
    } catch { }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.from = from
    })
  }
}
</script>
