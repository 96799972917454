<template>
  <div v-if="advice !== undefined && advice.seqNo !== undefined">
    <b-tabs content-class="mt-3">
      <b-tab title="Claim Details">
        <div class="vgt-wrap">
        <table class="vgt-table striped ia-table border-0">
          <tbody>
          <tr><td class="w-25">Report Created</td><td>{{formatDate(advice.productionDate)}}</td></tr>
          <tr v-if="showType"><td>Advice Type</td><td>{{advice.adviceType === 0 ? 'New Advice' : 'Cancellation'}}</td></tr>
          <tr><td>Status</td><td>{{formatClaimStatus(advice.status)}}</td></tr>
          <tr v-if="false"><td>Required Date</td><td>{{formatDate(advice.suReqDate)}}</td></tr>
          <tr v-if="false"><td>DDIC Cancel Date</td><td>{{formatDate(advice.ddiCancelDate)}}</td></tr>
          <tr><td>Date Of Debit</td><td>{{formatDate(advice.dateOfDebit)}}</td></tr>
          <tr><td>Advice For Claim Count</td><td>{{advice.adviceForClaimCount}}</td></tr>
          <tr><td>Total Amount</td><td>£{{formatAmount(advice.totalAmount)}}</td></tr>
           <tr><td>Reason Code</td><td>{{advice.reasonCode}} ({{codeToDescription(advice.reasonCode)}})</td></tr>
          <tr><td>Description</td>
            <td v-if="advice.reasonCode === 1"><p>The amount and / or date of the Direct Debit differ from the advance notice issued to a customer by a service user.</p></td>
            <td v-else-if="advice.reasonCode === 2"><p>No advance notice was received by a customer.</p></td>
            <td v-else-if="advice.reasonCode === 3"><p>Direct Debit Instruction (DDI) cancelled by a paying bank. Where there is proof an ADDACS (Automated Direct Debit Amendment and Cancellation Service) cancellation advice has been sent by a paying bank to a service user on or before the debiting day.</p></td>
            <td v-else-if="advice.reasonCode === 4"><p>Where a customer has cancelled a Direct Debit Instruction (DDI) direct with a service user, even if they have not cancelled the DDI with the bank.</p></td>
            <td v-else-if="advice.reasonCode === 5"><p>A paying bank may request a copy of the customer’s authority before refunding the customer (applicable to AUDDIS service users), or they may contact the service user for further information, although there is no requirement for them to do so.</p></td>
            <td v-else-if="advice.reasonCode === 6"><p>A signature on a Direct Debit Instruction (DDI) is fraudulent or not in accordance with the account authorised signature(s) held by the paying bank.</p></td>
            <td v-else-if="advice.reasonCode === 7">
              <p>An indemnity claim is raised at a service user’s request. The request will not be accepted by a paying bank until after Collection has been debited to a customer’s account. No action will be taken on any request until it has been confirmed in writing to the paying bank e.g. by fax / email. Details of the confirmation should be submitted with the indemnity claim. Should a service user wish to raise such an indemnity claim a list of paying bank contact details can be found at www.bacs.co.uk/ddicbankcontacts  When submitting an indemnity claim a service user needs to provide: </p>
              <ul>
                <li>Customer's name and account details </li>
                <li>Amount and date of Direct Debit </li>
                <li>Direct Debit reference </li>
                <li>Service user number (SUN) </li>
                <li>Service user contact name. </li>
              </ul>
            </td>
            <td v-else-if="advice.reasonCode === 8"><p>A customer does not recognise the service user collecting a Direct Debit. Where a paying bank is unable to identify and consequently action a customer’s request to cancel a Direct Debit Instruction (DDI) as a result of a service user using one of the set-up exceptions in respect of trading names or facilities management</p></td>
            <td v-else><p>No action to take</p></td>
          </tr>
          <tr><td>Potential Valid Challenges</td>
            <td v-if="advice.reasonCode === 1">
              <p>Where a service user can produce either: </p>
              <ul>
                <li>A copy of the advance notice, or </li>
                <li>A copy of a contract signed by the customer with details of the amounts to be collected and collection dates. </li>
              </ul>
              <p v-if="advice.isMatchedToPayer"><router-link :to="{ name: advice.isMatchedToPayer ? `editPayer` : `managePayers`, params: { ukPayerId: advice.ukPayerId, openAt:'messagequeue', prepopulatedSearchTerm: advice.serviceUserReference, adviceId: advice.adviceId }, query:{ openAt:'messagequeue' } }">View Customer Messages</router-link>
              </p>
            </td>
            <td v-else-if="advice.reasonCode === 2">
              <p>Where a service user can produce a copy of a contract signed by the Customer, which includes the advance notice.</p>
              <p v-if="advice.isMatchedToPayer"><router-link :to="{ name: advice.isMatchedToPayer ? `editPayer` : `managePayers`, params: { ukPayerId: advice.ukPayerId, openAt:'messagequeue', prepopulatedSearchTerm: advice.serviceUserReference, adviceId: advice.adviceId }, query:{ openAt:'messagequeue' } }">View Customer Messages</router-link></p></td>
            <td v-else-if="advice.reasonCode === 3">
              <p>Where a paying bank fails to send a cancellation advice to a service user on or before the date the debit was presented against the Customer’s account. Or in the case of a paper indemnity claim, a paying bank fails to include the date of cancellation on the form.</p>
              <p v-if="advice.isMatchedToPayer"><router-link :to="{ name: advice.isMatchedToPayer ? `editPayer` : `managePayers`, params: { ukPayerId: advice.ukPayerId, openAt:'messagequeue', prepopulatedSearchTerm: advice.serviceUserReference, adviceId: advice.adviceId }, query:{ openAt:'messagequeue' } }">View Customer Messages</router-link></p>
            </td>
            <td v-else-if="advice.reasonCode === 4"><p>None.</p></td>
            <td v-else-if="advice.reasonCode === 5">
              <p>Applicable to AUDDIS service users – where one or more of the following can be provided: </p>
              <ul>
                <li>A valid Instruction (e.g. one retained by a service user as required by AUDDIS) or establish one has already been provided and all other requirements complied with.</li>
                <li>Evidence of a contract signed by the customer specifically referencing Collection by Direct Debit.</li>
                <li>Evidence of a contract specifically referencing Collection by Direct Debit – this need not be signed by the customer where they don’t dispute the existence of the contract, merely the Collection method.</li>
                <li>Completion of the customer verification measures previously agreed between the service user and their sponsor. </li>
              </ul>
              <p>Applicable to non-AUDDIS service users – where a paying bank has either: </p>
              <ul>
                <li>Returned a Direct Debit with a 01 transaction code “refer to Customer” and subsequently raised an indemnity claim for the reason “Customer disputes having given authority” for a re-presentation with an 18 transaction code, or,</li>
                <li>Directed an indemnity claim to a non-AUDDIS service user. </li>
              </ul>
              <p v-if="advice.isMatchedToPayer"><router-link :to="{ name: advice.isMatchedToPayer ? `editPayer` : `managePayers`, params: { ukPayerId: advice.ukPayerId, openAt:'messagequeue', prepopulatedSearchTerm: advice.serviceUserReference, adviceId: advice.adviceId }, query:{ openAt:'messagequeue' } }">View Customer Messages</router-link></p>
            </td>
            <td v-else-if="advice.reasonCode === 6">
              <p>Applicable to AUDDIS service users where no request has previously been received from a paying bank to provide a copy of the DDI.</p>
              <p>Applicable to non-AUDDIS service users where a paying bank has directed an indemnity claim to a non-AUDDIS service user.</p>
            </td>
            <td v-else-if="advice.reasonCode === 7">
              <p>Where a service user disputes the indemnity claim, the paying bank is required to uphold the challenge if the fax / email provided by the service user cannot be produced</p>
              <p v-if="advice.isMatchedToPayer"><router-link :to="{ name: advice.isMatchedToPayer ? `editPayer` : `managePayers`, params: { ukPayerId: advice.ukPayerId, openAt:'messagequeue', prepopulatedSearchTerm: advice.serviceUserReference, adviceId: advice.adviceId }, query:{ openAt:'messagequeue' } }">View Customer Messages</router-link></p>
            </td>
            <td v-else-if="advice.reasonCode === 8">
              <p>Applicable to AUDDIS service users – where one or more of the following can be provided:</p>
              <ul>
                <li>A valid Instruction (e.g. one retained by a service user as required by AUDDIS) or establish one has already been provided and all other requirements complied with.</li>
                <li>Evidence of a contract signed by the customer specifically referencing Collection by Direct Debit.</li>
                <li>Evidence of a contract specifically referencing Collection by Direct Debit – this need not be signed by the customer where they don’t dispute the existence of the contract, merely the Collection method.</li>
                <li>Completion of the customer verification measures previously agreed between the service user and their sponsor. </li>
              </ul>
            </td>
            <td v-else><p>No action to take.</p></td>
          </tr>
          </tbody>
      </table>
        </div>
      </b-tab>
      <b-tab title="Service User Details">
        <div class="vgt-wrap">
        <table class="vgt-table striped ia-table border-0">
            <tbody>
            <tr v-if="showCustGroup"><td>Client</td><td>{{advice.customerName}}</td></tr>
            <tr v-if="showCustGroup"><td>Group</td><td>{{advice.groupname}}</td></tr>
            <tr><td>Service User Number</td><td>{{advice.sun}}</td></tr>
            <tr><td>Paying Bank Name</td><td>{{advice.payingBankName}}</td></tr>
            <tr><td>Service User Contact Name</td><td>{{advice.suContactName}}</td></tr>
            </tbody>
        </table>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          Customer Details<i v-if="!advice.isMatchedToPayer || !advice.matchedAccountNumber || !advice.matchedSortCode" v-b-popover.hover.top.d500="'Details not matched to Customer'" class="fas fa-times-circle ml-2 text-danger"></i>
        </template>
        <div class="vgt-wrap">
        <table class="vgt-table striped ia-table border-0">
            <tbody>
              <tr><td>Schedule Reference</td><td><router-link :is="payerView ? 'span' : 'router-link'" :to="{ name: advice.isMatchedToPayer ? `editPayer` : `managePayers`, params: { ukPayerId: advice.ukPayerId, openAt:'indemnities', prepopulatedSearchTerm: advice.serviceUserReference, adviceId: advice.adviceId } }">{{advice.serviceUserReference}}</router-link><i v-if="advice.isMatchedToPayer" v-b-popover.hover.top.d500="'Matched To Customer'" class="fas fa-check-circle ml-2 text-success"></i><i v-if="!advice.isMatchedToPayer" v-b-popover.hover.top.d500="'Not Matched to Customer'" class="fas fa-times-circle ml-2 text-danger"></i></td></tr>
              <tr><td>Customer Name</td><td><router-link :is="payerView ? 'span' : 'router-link'" :to="{ name: advice.isMatchedToPayer ? `editPayer` : `managePayers`, params: { ukPayerId: advice.ukPayerId, openAt:'indemnities', prepopulatedSearchTerm: advice.payerName, adviceId: advice.adviceId } }">{{advice.payerName}}</router-link></td></tr>
              <tr><td>Customer Sort Code</td><td><router-link :is="payerView ? 'span' : 'router-link'" :to="{ name: advice.isMatchedToPayer ? `editPayer` : `managePayers`, params: { ukPayerId: advice.ukPayerId, openAt:'indemnities', prepopulatedSearchTerm: advice.payerSortCode, adviceId: advice.adviceId } }">{{pad(advice.payerSortCode,6)}}</router-link><i v-if="advice.matchedSortCode" v-b-popover.hover.top.d500="'Matched To Customer'" class="fas fa-check-circle ml-2 text-success"></i><i v-if="!advice.matchedSortCode" v-b-popover.hover.top.d500="'Not Matched to Customer'" class="fas fa-times-circle ml-2 text-danger"></i></td></tr>
              <tr><td>Customer Account</td><td><router-link :is="payerView ? 'span' : 'router-link'" :to="{ name: advice.isMatchedToPayer ? `editPayer` : `managePayers`, params: { ukPayerId: advice.ukPayerId, openAt:'indemnities', prepopulatedSearchTerm: advice.payerAccount, adviceId: advice.adviceId } }">{{pad(advice.payerAccount, 8)}}</router-link><i v-if="advice.matchedAccountNumber" v-b-popover.hover.top.d500="'Matched To Customer'" class="fas fa-check-circle ml-2 text-success"></i><i v-if="!advice.matchedAccountNumber" v-b-popover.hover.top.d500="'Not Matched to Customer'" class="fas fa-times-circle ml-2 text-danger"></i></td></tr>
            </tbody>
        </table>
        </div>
      </b-tab>
      <b-tab title="Bank Details">
        <div class="vgt-wrap">
        <table class="vgt-table striped ia-table border-0">
          <tbody>
            <tr><td>Customer Bank</td><td>{{advice.bankName}}</td></tr>
            <tr><td>Bank Branch</td><td>{{advice.branchTitle}}</td></tr>
            <tr><td>Branch Name</td><td>{{advice.branchName}}</td></tr>
            <tr><td>Bank Reference</td><td>{{advice.payingBankReference}}</td></tr>
          </tbody>
        </table>
        </div>
      </b-tab>
      <b-tab title="Affected Collections">
        <p>Below are the Collections referenced in the Indemnity Claim.</p>
        <div class="vgt-wrap">
        <table class="vgt-table striped ia-table table-hover mt-2 border-0">
          <thead>
            <tr>
              <th scope="col">Collection Date</th>
              <th class="amountCell" scope="col">Collection Amount</th>
              <th class="matchedCell" scope="col">Matched</th>
            </tr>
          </thead>
          <tbody>
            <tr :class="collection.isMatched ? 'clickableRow' : null"  v-for="(collection, p) in advice.collections" :key="p">
              <td @click="collection.isMatched ? goToSchedule(collection.ukPayerId,collection.paymentPlanId) : null">{{formatDate(collection.collectionDate)}}</td>
              <td class="amountCell" @click="collection.isMatched ? goToSchedule(collection.ukPayerId,collection.paymentPlanId) : null">£{{formatAmount(collection.collectionAmount)}}</td>
              <td class="matchedCell"><i v-if="!collection.isMatched" v-b-popover.hover.top.d500="'Not matched to Collection'" class="fas fa-times-circle mr-2 text-danger"></i><i v-if="collection.isMatched" v-b-popover.hover.top.d500="'Matched to Collection'" class="fas fa-check-circle mr-2 text-success"></i><router-link v-if="collection.isMatched" :to="{ name: 'Submission History Item', params: { submissionId:collection.submissionId } }">View Submission</router-link></td>
            </tr>
          </tbody>
        </table>
        </div>
      </b-tab>
      <b-tab title="Notes">
        <notes :adviceId="advice.adviceId" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import notes from './AdviceNotes'
export default {
  components: { notes },
  props: {
    advice: Object,
    showType: Boolean,
    showCustGroup: { type: Boolean, default: true },
    payerView: { type: Boolean, default: false }
  },
  methods: {
    goToSubmission(submissionId) {
      this.$router.push({ name: 'Submission History Item', params: { submissionId } })
    },
    goToSchedule(payerId,planId) {
      this.$router.push({ name: 'PayerSchedule', params: { id: payerId, planId: planId } })
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    formatAmount (value) {
      return this.numberWithCommas((value / 1).toFixed(2))
    },
    formatClaimStatus (value) {
      let retVal = ''
      switch (value) {
        case 1:
          retVal = 'Challenged'
          break
        case 2:
          retVal = 'Recouped'
          break
        case 3:
          retVal = 'Completed'
          break
        case 4:
          retVal = 'Cancelled'
          break
        case 5:
          retVal = 'Cancelled Completed'
          break
        default:
          retVal = 'Received'
          break
      }
      return retVal
    },
    codeToDescription (value) {
      let retval = ''
      switch (value) {
        case 1:
          retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
           break;
        case 2:
          retval = 'NO ADVANCE NOTICE WAS RECEIVED';
           break;
        case 3:
          retval = 'BANK CANCELS THE DIRECT DEBIT';
          break;
        case 4:
          retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
          break;
        case 5:
          retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
          break;
        case 6:
          retval = 'FRAUDULENT SIGNATURE';
          break;
        case 7:
          retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
          break;
        case 8:
          retval = 'SERVICE USER IS NOT RECOGNISED';
          break;
        default:
          retval = 'Invalid Code Supplied'
          break;
      }
      return retval
    }
  }
}
</script>
<style>
  .amountCell {
    text-align: right;
  }
  .matchedCell {
    text-align: center;
  }
  .ia-table, .ia-table thead th td, .ia-table tr td {
    border-style: none;
  }
  .ia-table thead th, .ia-table thead th td {
    background: transparent;
    padding: 0;
  }
</style>
