<template>
  <div>
    <vue-good-table
      ref="table"
      mode="remote"
      :columns="columns"
      :rows="variableAmountPayers"
      :lineNumbers="true"
      :totalRows="totalRecords"
      :search-options="{
                    enabled: true
                    }"
      :pagination-options="paginationOptions"
      styleClass="vgt-table striped bordered"
    >
      <template slot="loadingContent">
        <h1>Loading...</h1>
      </template>
      <div slot="table-actions">
        <button
          @click.prevent="clearTableFilters"
          class="btn btn-link"
          v-b-popover.hover.top.d500="'Clear filters'"
        >
                        <span class="fa-stack" style="font-size: 10px;">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span>
        </button>
        <b-button
          @click.prevent="load"
          class
          :disabled="isLoading"
          variant="link"
          v-b-popover.hover.top.d500="'Refresh the data in the table'"
        >
          <i class="fa fa-sync pointer dimmedIcon"></i>
        </b-button>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import _ from 'lodash'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import axios from 'axios'
import { mapGetters } from 'vuex'
import papa from 'papaparse'
export default {
  mixins: [tableFilterMixin, loading],
  name: "VariableAmountPayers",
  props: {
    groupId: { type: String }
  },
  data () {
    return {
      variableAmountPayers: [],
      columns: [
        {
          label: 'id',
          field: 'ukPayerId',
          hidden: true
        },
        {
          label: 'planid',
          field: 'paymentPlanId',
          hidden: true
        },
        {
          label: 'Reference',
          field: 'coreReference'
        },
        {
          label: 'Plan',
          field: 'planReference',
        },
        {
          label: 'Next Collection Date',
          field: 'nextPaymentDate'
        }
      ],
      totalRecords: 0
    }
  },
  methods: {
    async load () {
      console.log('load clicked')
      var query = this.buildGoodTableQuery()
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}variableamount/payers/${this.groupId}`, { params: query })
      this.variableAmountPayers = response.data.data
      this.totalRecords = response.data.count
    },
    async export () {
      var query = this.buildGoodTableQuery()
      // query.params.paygateId = this.$store.state.common.paygateId
      query.perPage = this.totalRecords
      query.page = 1
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}variableamount/payers/${this.groupId}?forexport=true`, { params: query })

        var csvString = papa.unparse(response.data, { columns: this.hasPlanId ? [ 'planReference','messageType','channelType','status','creationDate','sentDate' ] : [ 'payerReference','planReference','messageType','channelType','status','creationDate','sentDate' ] })
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, 'va-friendly-customers.csv')
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = 'va-friendly-customers.csv'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      } catch (e) {
        this.$toastr.e('Could not export')
      }
    }
  },
  watch: {
    groupId () {
      this.load()
    }
  }
}
</script>

<style scoped>

</style>
