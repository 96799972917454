import axios from 'axios'
import customerTypes from '@/Assets/Constants/customerTypes'
import roles from '@/Assets/Constants/roles'

// initial state
const state = {
  customer: {}
}

const getters = {
  selectedCustomerObject: state => {
    return state.customer
  },
  isParentConnectedMerchant: state => {
    return state.customer.parentCustomerType === customerTypes.connectedMerchant
  },
  isAllowedToEdit: (_, getters) => {
    return (getters.isParentConnectedMerchant && getters.isInRole(roles.ConnectedMerchantAdmin)) || getters.isInRole(roles.LZAdmin)
  },
  isParentMaster: state => {
    return state.customer.parentCustomerType === customerTypes.master
  },
  hasChildren: state => {
    return state.customer.type === customerTypes.master || state.customer.type === customerTypes.connectedMerchant
  }
}

// actions
const actions = {
  async getCustomer ({ commit, dispatch }, paygateId) {
    const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customers/${paygateId}`)
    commit('getCustomer', response.data)
    dispatch('updateMenu')
  }
}

// mutations
const mutations = {
  getCustomer (state, customer) {
    state.customer = customer
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
