<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Charge Detail</h2>
      </div>

      <div class="idb-block-content">
        <div class="container-fluid">
          <!-- Group Type -->
          <div class="form-group row" :class="{ invalid: $v.billingRecord.type.$error }">
            <label class="col-form-label col-md-2 required">Type</label>
            <div class="col-md-6">
              <b-form-select
                v-model="$v.billingRecord.type.$model"
                :options="availableTypes"
                title="Type"
                @input="$v.$reset()"
              ></b-form-select>
              <!-- Validation -->
              <validation-messages v-model="$v.billingRecord.type" name="type"></validation-messages>
            </div>
          </div>

          <!-- Date -->
          <div class="form-group row" :class="{ invalid: $v.billingRecord.date.$error }">
            <label class="col-form-label col-md-2 required">Date</label>
            <div class="col-md-6">
              <div class="input-group date">
                <date-picker
                  v-model="$v.billingRecord.date.$model"
                  :config="dateOptions"
                  :wrap="true"
                ></date-picker>
                <div class="input-group-addon">
                  <span class="far fa-calendar"></span>
                </div>
              </div>

              <!-- Validation -->
              <validation-messages v-model="$v.billingRecord.date" name="date"></validation-messages>
            </div>
          </div>

          <!-- Description -->
          <div class="form-group row" :class="{ invalid: $v.billingRecord.description.$error }">
            <label class="col-form-label col-md-2">Description</label>
            <div class="col-md-6">
              <textarea class="form-control" v-model="$v.billingRecord.description.$model"></textarea>

              <!-- Validation -->
              <validation-messages v-model="$v.billingRecord.description" name="description"></validation-messages>
            </div>
          </div>

          <!-- Amount -->
          <div class="form-group row" :class="{ invalid: $v.billingRecord.serviceCharge.$error }">
            <label class="col-form-label col-md-2 required">Amount</label>
            <div class="col-md-6">
              <div class="input-group">
                <span class="input-group-text">£</span>
                <two-decimal-places-input
                  class="form-control text-right"
                  v-model.number="$v.billingRecord.serviceCharge.$model"
                />
              </div>

              <!-- Validation -->
              <validation-messages v-model="$v.billingRecord.serviceCharge" name="amount"></validation-messages>
              <small>VAT will be calculated after the charge has been picked up by either the settlement or billing process</small>
            </div>
          </div>
        </div>
      </div>

      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          @click="checkValidation() && createBillingRecord()"
          :disabled="isLoading"
        >Create</button>
        <button
          class="btn btn-outline-danger pull-right ml-3"
          type="button"
          @click="back"
          :disabled="isLoading"
        >Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'

// Validators
import { required } from 'vuelidate/lib/validators'

// Constants
import { billingEnum as billing } from '@/Assets/Constants/billing'

// Components
import datePicker from '@/Assets/Components/DateTimePicker/DatePicker'
import TwoDecimalPlacesInput from '@/Assets/Components/TwoDecimalPlacesInput'

// Mixins
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  mixins: [loading],
  props: ['id'],
  components: {
    datePicker,
    TwoDecimalPlacesInput
  },
  computed: {
    availableTypes () {
      var result = [
        {
          label: 'Processing Charges',
          options:
            Object.keys(billing).filter(b =>
              [
                'AdvanceNoticeLetter',
                'AdvanceNoticeEmail',
                'PreCollectionNotification',
                'Collections',
                'ConfirmationLetter',
                'ConfirmationEmail',
                'FailedCollections',
                'IndemnityClaims',
                'PayerCancellation',
                'PayerReinstate',
                'PayerSetup',
                'ValidateBankAccount',
                'ValidateIBAN',
                'BanklineSTD1Or2',
                'BanklineSTD0',
                'BanklineUrgent'

              ].some(bb => bb === b)
            ).map((b) => {
              return { value: b, text: billing[b] }
            })

        },
        {
          label: 'Ad-Hoc Charges',
          options:
            Object.keys(billing).filter(b =>
              [
                'CreditNote',
                'TerminationFee'
              ].some(bb => bb === b)
            ).map((b) => {
              return { value: b, text: billing[b] }
            })

        },
        {
          label: 'PS Charges',
          options:
            Object.keys(billing).filter(b =>
              [
                'BacsMigration',
                'CustomerSetup',
                'FmServiceUserSetup',
                'PayerMigration',
                'Reports',
                'ServiceUserModification'
              ].some(bb => bb === b)
            ).map((b) => {
              return { value: b, text: billing[b] }
            })

        },
        {
          label: 'Periodic Charges',
          options:
            Object.keys(billing).filter(b =>
              [
                'AdminCharge',
                'BacsFileSubmissionCharge'
              ].some(bb => bb === b)
            ).map((b) => {
              return { value: b, text: billing[b] }
            })

        },
        billing.Other
      ]

      return result
    },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () { this.$router.push({ name: 'Charges' }) }
  },
  data () {
    return {
      billingRecord: {
        type: '',
        date: null,
        serviceCharge: 0,
        description: ''
      },
      dateOptions: {
        format: 'DD/MM/YYYY HH:mm',
        sideBySide: true

      },
      creating: false
    }
  },
  mounted () {
    this.billingRecord.date = new Date()
  },
  methods: {
    async createBillingRecord () {
      if (this.creating) {
        return
      } else {
        this.creating = true
      }
      try {
        // Need to convert data to this format :)
        this.billingRecord.date = new Date(this.billingRecord.date)

        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Charge`, this.billingRecord, { showload: true, showerror: true, errormessage: 'Charge record failed to create' })
        this.$v.$reset()
        this.$toastr.s('Charge Record Created', 'Created')
        this.$router.push({ name: 'Charges' })
      } catch { } finally {
        this.creating = false
      }
    }
  },
  validations: {
    billingRecord: {
      type: { required },
      description: {},
      date: { required },
      serviceCharge: { required }
    }
  }
}
</script>
