<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline Row Details
            <span class="pull-right">
              <a :href="`${docUrl}/automation/logging/`" target="_blank"><i class="far fa-question-circle"></i></a>
            </span>
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-9 ">
              <strong><small>Message Type</small></strong>
            </div>
            <div class="col-3 ">
              <strong><small>Status</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              {{fileData.banklineAstRow.data.Type}} ({{fileData.metaData.TypeDescription}})
            </div>
            <div class="col-3">
              <b-badge pill v-if = "fileData.banklineAstRow.status == '1'" variant="danger">Unprocessed</b-badge>
              <b-badge pill v-else-if = "fileData.banklineAstRow.status == '2'" variant="info">Processing</b-badge>
              <b-badge pill v-else-if = "fileData.banklineAstRow.status == '3'" variant="success">Matched</b-badge>
              <b-badge pill v-else-if = "fileData.banklineAstRow.status == '4'" variant="danger">Unmatched </b-badge>
              <b-badge pill v-else-if = "fileData.banklineAstRow.status == '5'" variant="dark">Unmatchable Type </b-badge>
              <b-badge pill v-else-if = "fileData.banklineAstRow.status == '6'" variant="danger">Unknown Type</b-badge>
              <b-badge pill v-else-if = "fileData.banklineAstRow.status == '7'" variant="warning">Ambiguous </b-badge>
              <b-badge pill v-else-if = "fileData.banklineAstRow.status == '8'" variant="danger">Invalid Date </b-badge>
              <b-badge pill v-else-if = "fileData.banklineAstRow.status == '99'" variant="danger">Error</b-badge>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-3 ">
              <strong><small>Sortcode</small></strong>
            </div>
            <div class="col-3">
              <strong><small>Account Number</small></strong>
            </div>
            <div class="col-3 ">
              <strong><small>Account Alias</small></strong>
            </div>
            <div class="col-3 ">
              <strong><small>Account Short Name</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              {{reformatSortcode2(fileData.banklineAstRow.data.SortCode)}}
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.data.AccountNumber}}
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.data.AccountAlias}}
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.data.AccountShortName}}
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-3 ">
              <strong><small>Account Type</small></strong>
            </div>
            <div class="col-3 ">
              <strong><small>Bank Name</small></strong>
            </div>
            <div class="col-3">
              <strong><small>Branch Name</small></strong>
            </div>
            <div class="col-3">
              <strong><small>BIC</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              {{fileData.banklineAstRow.data.AccountType}}
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.data.BankName}}
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.data.BranchName}}
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.data.BIC}}
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-3">
              <strong><small>Date</small></strong>
            </div>
            <div class="col-3">
              <strong><small>Currency</small></strong>
            </div>
            <div class="col-3">
              <strong><small>Credit</small></strong>
            </div>
            <div class="col-3">
              <strong><small>Debit</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              {{fileData.banklineAstRow.data.Date}}
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.data.Currency}}
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.data.Credit | currency}}
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.data.Debit | currency}}
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-3">
              <strong><small>Narrative 1</small></strong>
            </div>
            <div class="col-3">
              <strong><small>Narrative 2</small></strong>
            </div>
            <div class="col-3">
              <strong><small>Narrative 3</small></strong>
            </div>
            <div class="col-3">
              <strong><small>Narrative 4</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              {{fileData.banklineAstRow.data.Narrative1}}
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.data.Narrative2}}
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.data.Narrative3}}
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.data.Narrative4}}
            </div>
          </div>
        </div>
      </div>
      <div class="idb-block" v-if="fileData.banklineAstRow.matchObj && editMode === false">
        <div class="idb-block-title">
          <h2>Reconciled Event
            <button id="btnManuallyReconcile" v-if="!editMode && fileData.banklineAstRow.status != 8 && (fileData.banklineAstRow.status === 4 || fileData.banklineAstRow.status === 7)" @click="btnManuallyReconcileBanklineRow" type="button" class="btn btn-outline-primary btn-sm float-right">
              <i class="fa fa-cog mr-2"></i>Manually Reconcile
            </button>
            <button id="btnManuallyReconcile" v-if="!editMode  && fileData.banklineAstRow.status === 3" @click="btnRemoveManuallyReconciliation" type="button" class="btn btn-outline-danger btn-sm float-right">
              <i class="fa fa-trash mr-2"></i>Remove Reconciliation
            </button>
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-2 ">
              Matched item
            </div>
            <div class="col-5">
          <!-- <span v-if="fileData.banklineAstRow.matchObj.success"><router-link :to='fileData.banklineAstRow.matchObj.link' >{{fileData.banklineAstRow.matchObj.criteria}}</router-link></span> -->
          <span v-if="fileData.banklineAstRow.matchObj.success">
          <a :href="fileData.banklineAstRow.matchObj.link" target="_blank">{{ fileData.banklineAstRow.matchObj.criteria }}</a>
          </span>
          <span v-else>{{fileData.banklineAstRow.matchObj.criteria}}</span>
            </div>
            <div class="col-1 offset-1">
              Match Date
            </div>
            <div class="col-3">
              {{reformatDate(fileData.banklineAstRow.matchObj.dateTime)}}
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-2 ">
              Match Status
            </div>
            <div class="col-5">
              <span v-if="fileData.banklineAstRow.matchObj.success">Success</span>
              <span v-else class="text-danger">Unsuccessful</span>
            </div>
            <div class="col-1 offset-1">
              Match Rule
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.matchObj.rule}}
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-2 ">
              Commentary
            </div>
            <div class="col-5">
              <span  v-if="fileData.banklineAstRow.matchObj.success">{{fileData.banklineAstRow.matchObj.commentary}}</span>
              <span class="text-warning" v-else>{{fileData.banklineAstRow.matchObj.commentary}}</span>
            </div>
            <div class="col-1 offset-1">
              Customer
            </div>
            <div class="col-3">
              <button v-if="fileData.banklineAstRow.matchObj.customerName" id="btnUpdateReconcile" @click="btnCustomerLink" type="button" class="btn btn-link">
                {{fileData.banklineAstRow.matchObj.customerName}}
              </button>
              <span v-else>Unknown</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit Reconciled Event -->
      <div class="idb-block" v-if="fileData.banklineAstRow.matchObj && editMode === true">
        <div class="idb-block-title">
          <h2><i class="fa fa-cog mr-2"></i> Edit Reconciled Event</h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <label class="label-control col-md-2 required">Matched item</label>
            <div class="col-5">
              <b-form-input v-model="fileData.banklineAstRow.matchObj.criteria" placeholder="Enter a criteria here"></b-form-input>
            </div>
            <div class="col-1 offset-1">
              Match Date
            </div>
            <div class="col-3">
              {{reformatDate(fileData.banklineAstRow.matchObj.dateTime)}}
            </div>
          </div>
          <br/>
          <div class="row">
            <label class="label-control col-md-2">Matched item URL</label>
            <div class="col-5">
              <b-form-input v-model="fileData.banklineAstRow.matchObj.link" placeholder="Enter a link here"></b-form-input>
            </div>
          </div>
          <br/>
          <div class="row">
          <label class="label-control col-md-2 required">Matched Status</label>
            <div class="col-3">
              <select v-model="selectedMatchStatus" class="form-control">
                <option v-for="option in matchStatus" v-bind:value="option.value" :key="option.index">
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="col-1 offset-3">
              Match Rule
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.matchObj.rule}}
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-2">
              Commentary
            </div>
            <div class="col-5">
              <!-- <div v-if="editMode === false"> -->
                <span  v-if="fileData.banklineAstRow.matchObj.success">{{fileData.banklineAstRow.matchObj.commentary}}</span>
                <span class="text-warning" v-else>{{fileData.banklineAstRow.matchObj.commentary}}</span>
              <!-- </div>
              <div v-else>
                <b-form-textarea
                  id="commentTextarea"
                  v-model="fileData.banklineAstRow.matchObj.commentary"
                  rows="5"
                  max-rows="8"
                ></b-form-textarea>
              </div> -->
            </div>
            <div class="col-1 offset-1">
              Customer
            </div>
            <div class="col-3">
              {{fileData.banklineAstRow.matchObj.customer}}
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <button id="btnUpdateReconcile" @click="btnUpdateBanklineRow" type="button" class="btn btn-primary">
                <i class="fa fa-cog mr-2"></i>Update
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Parent Bankline File<span class="ml-2"><router-link v-if="this.fileData.banklineFile" :to="{ path: '/admin/bandr/bankline/banklineastfile/' + this.fileData.banklineFile.id}">View</router-link></span></h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-3 ">
              <strong><small>Filename</small></strong>
            </div>
            <div class="col-3">
              <strong><small>Upload Date</small></strong>
            </div>
            <div class="col-3 ">
              <strong><small>Origin</small></strong>
            </div>
            <div class="col-3 ">
              <strong><small>Status</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <span v-if="this.fileData.banklineFile">{{fileData.banklineFile.originalFilename}}</span>
            </div>
            <div class="col-3">
              <span v-if="this.fileData.banklineFile">{{reformatDate(fileData.banklineFile.creationDate)}}</span>
            </div>
            <div class="col-3">
              <b-badge pill v-if = "fileData.banklineFile && fileData.banklineFile.description == 'upload'" variant="success">Uploaded from Bankline</b-badge>
              <b-badge pill v-else-if = "fileData.banklineFile && fileData.banklineFile.description == 'payment'" variant="info">Payment</b-badge>
            </div>
            <div class="col-3">
              <b-badge pill v-if = "fileData.banklineFile && fileData.banklineFile.status == '1'" variant="secondary">Unprocessed</b-badge>
              <b-badge pill v-else-if = "fileData.banklineFile && fileData.banklineFile.status == '2'" variant="info">Processing</b-badge>
              <b-badge pill v-else-if = "fileData.banklineFile && fileData.banklineFile.status == '3'" variant="success">Processed</b-badge>
              <b-badge pill v-else-if = "fileData.banklineFile && fileData.banklineFile.status == '98'" variant="success">Archived </b-badge>
              <b-badge pill v-else-if = "fileData.banklineFile && fileData.banklineFile.status == '99'" variant="danger">Error</b-badge>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-3 ">
              <strong><small>Position in file</small></strong>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <span v-if="this.fileData.banklineFile">Row {{fileData.banklineAstRow.row + 1}} of {{fileData.banklineFile.numItems}}</span>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div class="btn-group mr-2" role="group">
                <!-- <button id="btnManuallyReconcile" v-if="!editMode" @click="btnManuallyReconcileBanklineRow" type="button" class="btn btn-primary">
                  <i class="fa fa-user mr-2"></i>Manually Reconcile
                </button> -->
              </div>
              <div class="btn-group pull-right">
                <button id="btnDeleteRow" @click="btnDeleteRow" type="button" class="btn btn-danger">
                  <i class="fa fa-trash mr-2"></i>Delete Item
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import roles from '@/Assets/Constants/roles'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
export default {
  name: 'BanklineAstRow',
  mixins: [tableFilterMixin],
  components: {
    swal
  },
  data () {
    return {
      editMode: false,
      settings: {
      },
      fileData: {},
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      selectedMatchStatus: false,
      matchStatus: [
        { text: 'Success', value: true },
        { text: 'Unsuccessful', value: false }
      ]
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async load () {
      const postObj = {
        serverParams: this.serverParams,
        id: this.id
      }
      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineastrow/${this.id}`, postObj)
        if (res && res.data) {
          this.fileData = res.data
          // console.log(this.fileData)
          this.selectedMatchStatus = this.fileData.banklineAstRow.matchObj.success
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bankline file data.')
        } else {
          this.$snapbar.e(`Could not get Bankline file data - ${e.message}`)
        }
      }
    },
    reformatDate (d) {
      if (!d) return
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    isToday (d) {
      // d is a string in dd/MM/yyyy format
      try {
        const dDay = parseInt(d.substring(0, 2))
        const dMonth = parseInt(d.substring(3, 5)) - 1
        const dYear = parseInt(d.substring(6, 10))

        const cd = new Date(dYear, dMonth, dDay)
        const today = new Date()
        const isToday = cd.getDate() === today.getDate() &&
          cd.getMonth() === today.getMonth() &&
          cd.getFullYear() === today.getFullYear()
        return isToday
      } catch {
        return false
      }
    },
    reformatSortcode2 (dai) {
      if (!dai) return
      try {
        if (dai.length !== 6) return
        return `${dai[0]}${dai[1]}-${dai[2]}${dai[3]}-${dai[4]}${dai[5]}`
      } catch {

        return 'Error'
      }
    },
    async btnDeleteRow () {
      const result = await swal.fire({
        title: 'Are you sure you want to permanently delete this Bankline item?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineAstRow/${this.id}`, { showload: true })
          if (res) {
            this.$toastr.s(`The Bankline item '${this.fileData.originalFilename}' was successfully deleted.`)
            if (this.fileData.banklineFile) {
              this.$router.push(`/admin/bandr/bankline/banklineastfile/${this.fileData.banklineFile.id}`)
            } else {
              this.$snapbar.e('Missing or invalid parent file.')
            }
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this item.')
          } else {
            this.$snapbar.e(`Could not delete this item - ${e.message}`)
          }
        }
      }
    },
    async btnManuallyReconcileBanklineRow () {
      this.editMode = true
    },
    async btnRemoveManuallyReconciliation () {
      // Remove the reconciliation information

      const result = await swal.fire({
        title: 'Are you sure you want to permanently remove the reconciliation details?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        console.log('btnRemoveManuallyReconciliation')
        this.fileData.banklineAstRow.matchObj = {
          link: '',
          criteria: '',
          commentary: 'Manually unmatched',
          success: false,
          matched: false,
          rule: '',
          paygateId: '',
          customerId: '',
          customerName: ''
        }
        this.fileData.paygateId = ''
        this.fileData.banklineAstRow.status = 4

        try {
          const response = await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineastrow/${this.id}`, this.fileData)
          if (response.data.success === true) {
            // this.$snapbar.s('Success.  The item was updated.')
          } else {
            this.$snapbar.e('Error.  The item was not updated.')
            return
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to update this item.')
          } else if (e.response && e.response.status === 400) {
            this.$snapbar.e('Error updating the item: ' + e.response.data.errorMessage)
          } else {
            this.$snapbar.e(`Error - ${e.message}`)
          }
        }
      }
    },
    async btnCustomerLink () {
      // Set the selected PaygateId to the record paygateid
      if (this.$store.getters.isInRole(roles.Finance)) {
        await this.$store.dispatch('setPaygateId', this.fileData.banklineAstRow.paygateId)
        this.$router.push('/admin/customers/details')

        // Could open in a new tab:
        // const routeData = this.$router.resolve({ name: 'CustomerDetails' })
        // window.open(routeData.href, '_blank')
      } else {
        this.$snapbar.e('You do not have the rights to do that.')
      }
    },
    async btnUpdateBanklineRow () {
      // First update the record
      try {
        const response = await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}banklineastrow/${this.id}`, this.fileData)
        if (response.data.success === true) {
          // this.$snapbar.s('Success.  The item was updated.')
        } else {
          this.$snapbar.e('Error.  The item was not updated.')
          return
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to update this item.')
        } else if (e.response && e.response.status === 400) {
          this.$snapbar.e('Error updating the item: ' + e.response.data.errorMessage)
        } else {
          this.$snapbar.e(`Error - ${e.message}`)
        }
        return
      }
      // Second, call the manual reconciliation endpoint
      const payload = {
        _id: this.fileData.banklineAstRow._id,
        matched: this.selectedMatchStatus
      }
      try {
        const response = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}reconcileitem`, payload)
        if (response.data.success === true) {
          this.$toastr.s('Success.  The item was reconciled.')
        } else {
          if (response.data && response.data.errors && response.data.errors.length > 0) {
            for (let c = 0; c < response.data.errors.length; c++) {
              this.$snapbar.e(`Error.  The item was not updated. ${response.data.errors[c]}`)
            }
          } else {
            this.$snapbar.e('Error.  The item was not updated.')
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to reconcile this item.')
        } else if (e.response && e.response.status === 400) {
          this.$snapbar.e('Error reconciling the item: ' + e.response.data.errorMessage)
        } else {
          this.$snapbar.e(`Error - ${e.message}`)
        }
      }
      // Reload the form so the user can see any changes
      this.editMode = false
      this.load()
    }
  },
  created () {
    if (this.$route.name === 'Bankline Row Edit') {
      this.editMode = true
    }
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.load()
    }
  }
}
</script>
<style scoped>
  td {
    cursor: pointer;
  }
</style>
