import { render, staticRenderFns } from "./SmsDesigner.vue?vue&type=template&id=1c863baa&scoped=true&"
import script from "./SmsDesigner.vue?vue&type=script&lang=js&"
export * from "./SmsDesigner.vue?vue&type=script&lang=js&"
import style0 from "./SmsDesigner.vue?vue&type=style&index=0&id=1c863baa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c863baa",
  null
  
)

export default component.exports