<template>
  <div>
    <div class="alert alert-danger" v-if="hasErrors">
      <p v-for="(error, index) in errors" :key="index">{{error}}</p>
    </div>
    <form @submit.prevent="checkValidation() && save()" novalidate>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            IP Address Restrictions
            <help-icon docPath="/administration/securitypolicy/ipaddressrestrictions/" />
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="form-group row">
            <div class="col-md-10 offset-md-1">
              <p>
                When a user tries to authenticate (Login) to London & Zurich and 'Enforce IP Address Restrictions' is checked, the rules are checked from top to bottom.
                <br />The first rule to match will be enforced. If no match is found the user will not be allowed to authenticate.
              </p>
              <p>
                Examples
                <br />Single Address, e.g. 192.168.100.25
                <br />Subnet, e.g. 192.168.0.5/24 or 192.168.0.5/255.255.255.0
              </p>
              <hr />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-3 offset-md-1">Enforce IP Address Restrictions</label>
            <div class="col-md-2">
              <p-check
                class="p-switch p-fill"
                color="primary"
                v-model="$v.policy.enforceIpRestrictions.$model"
                :disabled="disabledDueToConnectedMerchant || readOnly "
              ></p-check>
            </div>
          </div>

          <transition name="fade">
            <div class="row" v-if="policy.enforceIpRestrictions">
              <div
                class="col-md-10 offset-md-1 pl-5"
                :class="{invalid: !$v.policy.ipAddressItem.atLeastOne}"
              >
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Active</th>
                      <th scope="col">IP Address (V4 format) with or without a CIDR</th>
                      <th scope="col">Allow/Disallow</th>
                      <th scope="col">
                        <button
                          type="button"
                          class="btn btn-success"
                          @click="addNewAddress"
                          :disabled="disabledDueToConnectedMerchant || readOnly "
                        >Add New Address</button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(ipAddress,index) in $v.policy.ipAddressItem.$each.$iter"
                      :key="index"
                    >
                      <td scope="row">
                        <p-check v-model="ipAddress.active.$model"></p-check>
                      </td>
                      <td :class="{invalid: ipAddress.ipText.$error}">
                        <input
                          type="text"
                          class="form-control"
                          v-model.trim="ipAddress.ipText.$model"
                          :disabled="disabledDueToConnectedMerchant || readOnly "
                        />
                        <validation-messages v-model="ipAddress.ipText"></validation-messages>
                      </td>
                      <td>
                        <b-form-select
                          v-model="ipAddress.restrictionType.$model"
                          :options="restrictionTypes"
                          :disabled="disabledDueToConnectedMerchant || readOnly "
                        ></b-form-select>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-danger"
                          @click="removeAddress(index)"
                          :disabled="disabledDueToConnectedMerchant || readOnly "
                        >Delete</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <validation-messages v-model="$v.policy.ipAddressItem">
                  <template slot="override">
                    <small
                      class="form-text small"
                      v-if="!$v.policy.ipAddressItem.atLeastOne"
                    >Please enter at least one IP address restriction</small>
                  </template>
                </validation-messages>
              </div>
            </div>
          </transition>
        </div>
        <div class="idb-block-footer">
          <button
            class="btn btn-primary"
            type="submit"
            :disabled="isLoading || disabledDueToConnectedMerchant || readOnly "
          >Save</button>
          <button
            @click="reset"
            class="btn btn-outline-warning ml-3"
            type="button"
            :disabled="isLoading || disabledDueToConnectedMerchant || readOnly "
          >Reset to default</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import loading from '@/Assets/Mixins/LoadingMixin'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import ConnectedMerchantReadOnlyMixin from '@/Assets/Mixins/ConnectedMerchantReadOnlyMixin'
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [DataLeaveMixin, loading, ConnectedMerchantReadOnlyMixin],
  computed: {
    readOnly () {
      if (this.$store.getters.isInRole(roles.DevOps)) {
        return false
      }
      return true
    },
    hasErrors () { return this.errors.length > 0 },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () {
      this.load()
    }
  },
  data () {
    return {
      policy: {
        enforceIpRestrictions: false,
        ipAddressItem: []
      },
      restrictionTypes: ['Allow', 'Disallow'],
      errors: []
    }
  },
  async created () {
    await this.load()
  },
  methods: {
    async save () {
      try {
        this.errors = []
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/IPAddressRestriction`, this.policy,
          { showload: true, showerror: true, errormessage: 'IP Address restrictions failed to save' })
        this.$toastr.s('IP Address Restriction policy changes have been saved', 'Saved')
      } catch (e) {
        if (e.response.status === 422) {
          this.errors = e.response.data
          this.$toastr.e('There are errors on the page, please see the top for more information', 'Validation Error')
        }
      } finally {
        this.$v.$reset()
      }
    },
    async reset () {
      this.$v.$touch()
      this.policy = {
        enforceIpRestrictions: false,
        ipAddressItem: []
      }
      this.errors = []
      this.$toastr.i('IP Address Restriction policy changes have been reset, please save to apply', 'Reset')
    },
    async load () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/IPAddressRestriction`,
          { showload: true, showerror: true, errormessage: 'IP Address restrictions failed to load' })
        this.policy = response.data
      } catch { }
    },
    addNewAddress () {
      this.$v.policy.ipAddressItem.$model.push({
        ipText: '',
        restrictionType: 'Allow',
        active: true
      })
    },
    removeAddress (index) {
      this.$v.policy.ipAddressItem.$model.splice(index, 1)
      this.$v.policy.ipAddressItem.$touch()
    }
  },
  validations () {
    return {
      policy: {
        enforceIpRestrictions: {},
        ipAddressItem: {
          atLeastOne: (value, model) => {
            var result = true
            if (model.enforceIpRestrictions) {
              result = _.some(value)
            }

            return result
          },
          $each: {
            ipText: {
              required,
              ipAddress: (value) => /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/igm.test(value)
            },
            restrictionType: {},
            active: {}
          }
        }
      }
    }
  }
}
</script>
