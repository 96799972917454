<template>
  <div>
    <div class="idb-block">
      <!-- Header -->
      <div class="idb-block-title">
        <h2>
          Delete Group Payment Data
          <help-icon docPath="/administration/gdpr/deletegroupdata/" />
        </h2>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">
        <div class="form-group row">
          <label class="label-control col-md-3">Groups</label>
          <div class="col-md-5">
            <group-select
              v-model="selectedGroups"
              :groups="groups"
              :clearable="false"
              :multiple="true"
            ></group-select>
          </div>
        </div>

        <div class="form-group row">
          <label class="label-control col-md-3">Delete data older than</label>
          <div class="col-md-2">
            <b-form-select v-model="selectedDeletionPeriod" :options="timePeriods"></b-form-select>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <div class="idb-block-footer">
        <button
          type="button"
          class="btn btn-primary"
          :disabled="disableButtons"
          v-on:click="deleteData"
        >Delete</button>
        <button
          type="button"
          class="btn btn-outline-primary ml-3"
          :disabled="disableButtons"
          v-on:click="clear"
        >Clear</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import roles from '@/Assets/Constants/roles'
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

export default {
  computed: {
    ...mapGetters(['selectedCustomer']),
    disableButtons: function () {
      return this.deletingData || (this.selectedGroups && this.selectedGroups.length === 0)
    }
  },
  data () {
    return {
      timePeriods: [
        { value: '0', text: 'All' },
        { value: '3', text: '3 Months' },
        { value: '6', text: '6 Months' },
        { value: '12', text: '12 Months' }
      ],
      selectedDeletionPeriod: '0',
      deletingData: false,
      groups: [],
      selectedGroups: [],
      paygateId: ''
    }
  },

  methods: {
    async deleteData () {
      try {
        // console.log('selected groups: ' + JSON.stringify(this.selectedGroups))
        var swalResult = await swal.fire({
          title: 'Delete Group Data?',
          html: `<div>This will permanently delete the payments but unsubmitted payments will not be deleted.</div>
          <div class="mb-1"><strong>Are you REALLY sure you want to do this?</strong></div>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Delete Data',
          cancelButtonText: 'Cancel'
        })

        if (!swalResult.isConfirmed) {
          throw new Error('Cancelled')
        }

        var groupsToProcess = this.selectedGroups
        this.deletingData = true
        if (this.selectedGroups.includes('00000000-0000-0000-0000-000000000000')) {
          groupsToProcess = this.groups.map(i => i.groupId)
        }

        var json = JSON.stringify({
          selectedGroups: groupsToProcess,
          selectedDeletionPeriod: this.selectedDeletionPeriod
        })

        try {
          var response = await axios({
            method: 'DELETE',
            url: `${process.env.VUE_APP_PLATFORM_API_URL}GDPR/DeleteGroupPayments`,
            data: json,
            showload: true
          })
          if (response.status >= 200 && response.status <= 206) {
            this.$toastr.s('Group payment deletion job has been started.')
          } else {
            this.$toastr.e('There has been a problem starting the group payment deletion job. ' + response.statusText)
          }
        } catch (error) {
          console.log('error: ' + JSON.stringify(error))
          this.$toastr.e('Failed to run group payment deletion.')
        }
      } catch (error) {
        console.log('error: ' + JSON.stringify(error))
        this.$toastr.w('Group payment deletion has been cancelled', 'Cancelled')
      }

      this.deletingData = false
    },

    clear () {
      this.selectedGroups = []
      this.selectedDeletionPeriod = '0'
    },

    async loadGroups () {
      this.groups = []
      this.selectedGroups = []
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/Dropdown/${this.paygateId}`, { showload: true, showerror: true, errormessage: 'Failed to get groups' })
      this.groups = response.data.map(g => { return { name: g.label, groupId: g.id, colour: g.colour } })
      if (response.data.length > 1) {
        this.groups.splice(0, 0, { name: 'All', groupId: '00000000-0000-0000-0000-000000000000' })
      }
    },

    async setPaygateId () {
      if (this.$store.getters.isInRole(roles.LZAdmin)) {
        this.paygateId = this.selectedCustomer
      } else {
        this.paygateId = this.$store.getters.getClaim('paygate_id').value
      }
    }
  },

  watch: {
    selectedCustomer () {
      this.setPaygateId()
      this.loadGroups()
    }
  },
  async created () {
    await this.setPaygateId()
    await this.loadGroups()
  }
}
</script>
