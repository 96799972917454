<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h3>Associations for {{agentname}}</h3>
      </div>

      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :search-options="{
            enabled: true
          }"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <button
              @click.prevent="exportData"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Export Data'"
            >
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </button>
            <button
              @click.prevent="onCreateAssociation"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Create a new contact association'"
            >
              <i class="fa fa-plus dimmedIcon pointer"></i>
            </button>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="onCreateAssociation">Create Association</button>
        <button class="btn btn-primary pull-right" @click="onBrowseToAgent">Back To Agent Detail</button>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import axios from 'axios'
import {
  required,
  maxLength
} from 'vuelidate/lib/validators'
import { email } from '@/Assets/Validators'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  props: {
    agentid: String
  },

  mixins: [loading, tableFilterMixin],
  data () {
    return {
      // Agent Details
      agentname: null,
      // Contact Details
      name: null,
      address1: null,
      address2: null,
      address3: null,
      address4: null,
      address5: null,
      postcode: null,
      email: null,
      // Postcode Lookup
      addressLookupInProgress: false,
      foundAddresses: [],
      selectedAddress: null,

      // Table Data

      rows: [],
      columns: [
        {
          label: 'associationid',
          field: 'id',
          hidden: true
        },
        {
          label: 'Description',
          field: 'associationName',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Customer Name',
          field: 'customerName',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Active',
          field: 'activeStr',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'EffectiveDate',
          field: 'effectiveDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: true
        },

        {
          label: 'EndDate',
          field: 'endDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: true
        }
      ],
      serverParams: {
        sort: [{ field: 'customerName', type: 'asc' }]
      },

      // Page Control
      disableSave: true

    }
  },
  methods: {
    async load () {
      var agentResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetAgent/${this.agentid}`)
      console.log('agentResponse', agentResponse)
      this.agentname = agentResponse.data.name

      await this.updateTable()
    },

    updateTable: async function () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetContactAssociations/${this.agentid}`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading commission agent contacts'
          })

        console.log('response', response)
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    },

    onRowClick (params) {
      this.$router.push({
        name: 'CommissionAgentViewAssociation',
        params: { associationid: params.row.associationId }
      })
    },

    onCreateAssociation () {
      this.$router.push({
        name: 'CommissionAgentCreateAssociation',
        params: { agentid: this.agentid }
      })
    },

    exportData: async function () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetContactAssociationsCSV/${this.agentid}`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data'
          })

        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Associations.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {
        console.log(e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    onBrowseToAgent () {
      this.$router.push({ name: 'CommissionAgentAdministration', params: { id: this.agentid } })
    }

  }

}

</script>
