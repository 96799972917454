<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Historic Charge Configurations
          <favourite-icon />
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'isCurrent'">
              <tick v-model="props.row.isCurrent"></tick>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
          <div slot="table-actions">
            <table-actions
              :print-enabled="false"
              :export-enabled="false"
              :reload-table="load"
              :clear-table-filters="clearTableFilters"
            ></table-actions>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'

// Mixins
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  mixins: [tableFilterMixin],
  name: 'HistoricChargeSetList',
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Is Current',
          field: 'isCurrent',
          type: 'bool',
          tdClass: 'text-center',
          sortable: false
        },
        {
          label: 'Effective Date',
          field: 'effectiveDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
        }

      ],
      serverParams: {
        sort: [{ field: 'effectiveDate', type: 'desc' }]
      }
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  methods: {
    onRowClick (params) {
      var effectiveDate = moment(params.row.effectiveDate).format('YYYY-MM-DDTHH:mm:ss')

      this.$router.push({ name: 'HistoricChargeConfiguration', params: { effectiveDate } })
    },
    async load () {
      try {
        this.isTableLoading = true
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}HistoricChargeSets/List`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading Historic Charge Configurations'
          })
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch { } finally {
        this.isTableLoading = false
      }
    }
  }
}
</script>

<style>
</style>
