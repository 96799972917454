const state = {
  hasPlan: false,
  detailsDone: false,
  bankingDone: false,
  planDone: false,
  addressDone: false,
  secureLimitsExceeded: false,
  thresholdExceeded: false,
  payerCreated: false
}

const mutations = {
  setPayerCreated (state) {
    state.payerCreated = true
  },
  resetWizard (state) {
      state.payerCreated=false
      state.hasPlan=false
      state.detailsDone=false
      state.bankingDone=false
      state.planDone=false
      state.addressDone=false
      state.secureLimitsExceeded=false
      state.thresholdExceeded=false
  },
  setHasPlan (state, value) {
    state.hasPlan = value
  },
  setDetailsDone (state, value) {
    state.detailsDone = value
  },
  setBankingDone (state, value) {
    state.bankingDone = value
  },
  setPlanDone (state, value) {
    state.planDone = value
  },
  setAddressDone (state, value) {
    state.addressDone = value
  },
  setSecureLimitsExceeded (state, value) {
    state.secureLimitsExceeded = value
  },
  setThresholdExceeded (state, value) {
    state.thresholdExceeded = value
  }
}

const actions = {

}

const getters = {
  wizardPayerCreated: state => {
    return state.payerCreated
  },
  wizardComplete: state => {
    return state.detailsDone && state.planDone && state.hasPlan && state.addressDone && state.bankingDone
  },
  wizardCompleteNoPlan: state => {
    return state.detailsDone && !state.hasPlan && state.addressDone && state.bankingDone
  },
  hasPlan: state => {
    return state.hasPlan
  },
  detailsDone: state => {
    return state.detailsDone
  },
  bankingDone: state => {
    return state.bankingDone
  },
  planDone: state => {
    return !state.hasPlan || state.planDone
  },
  addressDone: state => {
    return state.addressDone
  },
  secureLimitsExceeded: state => {
    return state.secureLimitsExceeded
  },
  wizardThresholdExceeded: state => {
    return state.thresholdExceeded
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
