<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Nominal Codes<span class="pull-right"></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div>
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              ref="table"
              :columns="columns"
              :rows="rows"
              :lineNumbers="false"
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              @on-row-click="onRowClick"
              styleClass="vgt-table striped bordered table-hover"
            >
              <div slot="table-actions">
                <table-actions
                  :data-url="dataUrl"
                  :export-data="tableServerData"
                  :print-enabled="false"
                  :table-amount="totalRecords"
                  export-name="NominalCodes"
                  :reload-table="load"
                  :tableColumns="columns"
                  :clear-table-filters="clearTableFilters"
                  :create-enabled="true"
                  :create="createNominalCode"
                  create-tooltip="Create a new Nominal Code"
                >
                </table-actions>
              </div>
            </vue-good-table>
          </div>
        </div>
        <div class="idb-block-footer">
          <button id="btnAddNominalCode" @click="createNominalCode" type="button" class="btn btn-primary">
            <i class="fa fa-plus rpad"></i>Create Nominal Code
          </button>
        </div>
      </div>
    </div>
    <editNominalCode
      :isVisible="isModalVisible"
      :newNominalCode="isNewNominalCode"
      :nominalCode="currentNominalCode"
      :bankAccounts="bankAccounts"
      @close="editCancelled"
      @savedNominalCode="savedNominalCode"
    ></editNominalCode>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import nominalCodeTypes from '@/Assets/Constants/nominalCodeTypes'
import EditNominalCode from '@/Views/JFTP/Reconcilliation/NominalCodes/EditNominalCode'

export default {
  name: 'nominalCodes',
  mixins: [tableFilterMixin],
  components: {
    editNominalCode: EditNominalCode
  },
  computed: {
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}nominalCode`
    },
    tableServerData () {
      return this.buildGoodTableQuery()
    }
  },
  data () {
    return {
      loaded: false,
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      columns: [
        {
          label: 'Code',
          field: 'code',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Nominal Code Type',
          field: 'nominalCodeType',
          filterOptions: {
            enabled: true,
            placeholder: 'All Types',
            filterDropdownItems: Object.values(nominalCodeTypes)
          }
        },
        {
          label: 'Bank Account ID',
          field: 'bankAccountId',
          hidden: true
        },
        {
          label: 'Linked Bank Account',
          field: 'reference',
          hidden: false
        },
        {
          label: 'Created',
          field: 'dateCreated',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',
          dateOutputFormat: 'dd/MM/yyyy',
          filterOptions: {
            enabled: true
          }
        }
      ],
      rows: [],
      totalRecords: 0,
      isModalVisible: false,
      isNewNominalCode: false,
      currentNominalCode: {},
      bankAccounts: [],
      bankAccountColumn: 4
    }
  },
  methods: {
    async load () {
      try {
        this.isTableLoading = true
        const response = await axios.get(this.dataUrl,
          {
            params: { ...this.tableServerData },
            showerror: true,
            errormessage: 'Nominal Codes search failed'
          })

        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch { } finally {
        this.isTableLoading = false
      }
    },

        // Override mixin version of buildGoodTableQuery due to bank reference filter.
        buildGoodTableQuery () {
      const query = { ...this.serverParams.columnFilters }
      Object.keys(query).forEach((key) => { 
        // Remove wildcard option from 'reference' so it's queried as "=" rather than "ilike".
        if (key !== 'reference') {
          query[key] = `*${query[key]}*` 
        }
      })
      query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }

      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
      return query
    },

    async createNominalCode () {
      this.isNewNominalCode = true
      this.isModalVisible = true
    },

    onRowClick (params) {
      this.isNewNominalCode = false
      this.currentNominalCode = params.row
      this.isModalVisible = true
    },

    editCancelled () {
      this.isModalVisible = false
    },

    async savedNominalCode () {
      this.isModalVisible = false
      // this.clearTableFilters()
      await this.load()
    },

    async loadBankAccounts () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}bankAccounts/DropdownOfType?bankAccountType=0`, { showload: true })
      this.bankAccounts = response.data
      let bankAccountsDropdown = []
      this.bankAccounts.forEach(bankAccount => {
        bankAccountsDropdown.push({ value: bankAccount.bankAccountId, text: bankAccount.reference })
      });
      this.$set(this.columns[this.bankAccountColumn], 'filterOptions', { enabled: true, placeholder: 'All Banks', filterDropdownItems: bankAccountsDropdown })    }
  },
  async mounted () {
    await this.loadBankAccounts()
  }
}
</script>
<style scoped>
td {
  cursor: pointer;
}
.tinyText {
  font-size: x-small;
}
</style>
