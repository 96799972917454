<template>
  <div class="idb-block">
      <div class="idb-block-title">
        <h2><help-icon docPath="/paygate-collections/payers/editpayer/" />Submission Cancelled at BACS</h2>
      </div>
      <div class="idb-block-content">
          <p>A submission has been cancelled as the result of a BACS Withdrawal Report, it can be resubmitted.</p>
      </div>
      <div class="idb-block-footer noprint-footer">
          <b-button variant="primary" @click.prevent="removeAction" :disabled="isLoading" v-if="actionId && actionId !== null">Clear Action</b-button>
      </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      actionId: null      
    }
  },
  mounted () {
    this.actionId = this.$route.query.actionId
  },
  methods: {
    async removeAction () {
      var response = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}submissionactions/withdrawal/${this.actionId}`, {params: { paygateId: this.paygateId }})
      if (response) {
        this.$toastr.s('Action Cleared')
      }
    }
  }
}
</script>