<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2><help-icon docPath="/paygate-collections/messaging/lettersout/" />Letters Outbox<favourite-icon></favourite-icon></h2>
    </div>
    <div class="idb-block-content">
      <div class="row form-group">
        <div class="col-md-12">
          <vue-good-table
            ref="table" mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
            @on-cell-click="onCellClick"
            :columns="columns"
            :rows="tableData"
            :lineNumbers="true"
            :totalRows="totalRecords"
            :search-options="{
              enabled: true
              }"
            :paginationOptions="paginationOptions"
            :sort-options="sortOptions"
            @on-row-click="onRowClick"
            :isLoading.sync="isTableLoading"
            styleClass="vgt-table striped bordered">
            <div slot="table-actions">
              <button
                @click.prevent="clearTableFilters"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Clear filters'"
              >
                <span class="fa-stack" style="font-size: 10px;">
                  <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                  <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                </span>
              </button>
              <b-button @click.prevent="load" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Refresh the data in the table'">
                <i class="fa fa-sync pointer dimmedIcon"></i>
              </b-button>
              <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
                <i class="fa fa-share-square pointer dimmedIcon"></i>
              </b-button>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'buttons'">
                <b-button @click.stop="deleteFromQueue(props.row.lettersOutId)" variant="danger"><i class="fa fa-trash mr-2"></i>Remove</b-button>
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
    <div class="idb-block-footer mt-3">
    </div>
  </div>
</template>
<script>
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import axios from 'axios'
import papa from 'papaparse'
export default {
  mixins: [tableFilterMixin, loading],
  data () {
    return {
      serverParams: {
        sort: [{ field: 'createdDate', type: 'desc' }]
      },
      tableData: [],
      totalRecords: 0,
      columns: [        
        {
          label: 'Title',
          field: 'title'
        },
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: 'Created',
          field: 'createdDate',
          formatFn: (date) => new Date(date).toLocaleDateString()
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
          tdClass: 'text-center'
        }
      ]
    }
  },
  methods: {
    async load () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}letters/outbox`, { params:  { ...this.buildGoodTableQuery(), paygateId: this.paygateId } })
      this.tableData = response.data.item1
      this.totalRecords = response.data.item2
    },
    async exportTable () {
      var query = this.buildGoodTableQuery()
      query.perPage = this.totalRecords
      query.page = 1
      query.paygateId= this.paygateId
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}letters/outbox`, { params: query })
        var messages = response.data.item1
        var csvString = papa.unparse(messages)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, 'out-tray.csv')
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = 'out-tray.csv'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      } catch (e) {
        console.error(e)
        this.$toastr.e('An error occurred exporting the message queue')
      }
    },
    printTable () {

    },
    async onCellClick (e) {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}letters/outbox/${e.row.lettersOutId}`, { params: { paygateId: this.paygateId } })      
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(this.base64ImageToBlob(response.data))
      a.download = e.row.outputPath
      // Append anchor to body.
      document.body.appendChild(a)
      a.click();
      // Remove anchor from body
      document.body.removeChild(a)
    },
    async onRowClick (e){
    },
    async deleteFromQueue (id) {
      try {
      var response = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}letters/outbox/${id}`, { params: { paygateId: this.paygateId } })
      this.$toastr.s('Message deleted.')
      await this.load()
      } catch {
        this.$toastr.e('An error has occurred, message could not be deleted.')
      }
    },
    base64ImageToBlob (str) {
      // extract content type and base64 payload from original string
      var imageContent = atob(str)

      // create an ArrayBuffer and a view (as unsigned 8-bit)
      var buffer = new ArrayBuffer(imageContent.length)
      var view = new Uint8Array(buffer)

      // fill the view, using the decoded base64
      for(var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }

      // convert ArrayBuffer to Blob
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessing' });
      return blob;
    }
  }
}
</script>