<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>Commission Comparison Report</h2>
    </div>
    <div class="idb-block-content">
      <div class="row form-group">
        <div class="form-group col-md-4">
          <label for="from">Agent</label>
          <vue-select
            v-model="tableAgentsSelect"
            :options="tableDataAgents"
            label="text"
            :closeOnSelect="true"
            @input="updateTable()"
          ></vue-select>
        </div>

        <div class="form-group col-md-4">
          <label for="from">Initial</label>
          <transition name="fadein">
            <vuejsDatepicker
              name="SelectDateInitial"
              id="SelectDateInitial"
              format="MMM/yyyy"
              input-class="form-control bg-white"
              :bootstrap-styling="true"
              minimumView="month"
              maximumView="year"
              initial-view="month"
              :use-utc="true"
              :disabled-dates="{
                    from: new Date()
                    }"
              :calendar-button="true"
              calendar-button-icon="fa fa-calendar"
              v-model="SelectDateInitial"
              @input="updateTable()"
            ></vuejsDatepicker>
          </transition>
        </div>

        <div class="form-group col-md-4">
          <label for="from">Comparison</label>
          <transition name="fadein">
            <vuejsDatepicker
              name="SelectDateComp"
              id="SelectDateComp"
              format="MMM/yyyy"
              input-class="form-control bg-white"
              :bootstrap-styling="true"
              minimumView="month"
              maximumView="year"
              initial-view="month"
              :use-utc="true"
              :disabled-dates="{
                    from: new Date()
                    }"
              :calendar-button="true"
              calendar-button-icon="fa fa-calendar"
              v-model="SelectDateComp"
              @input="updateTable()"
            ></vuejsDatepicker>
          </transition>
        </div>
      </div>

      <vue-good-table
        :paginationOptions="paginationOptions"
        :sort-options="sortOptions"
        :isLoading.sync="isTableLoading"
        :rows="rows"
        :columns="columns"
        :lineNumbers="true"
        mode="remote"
        :totalRows="totalRecords"
        @on-row-click="onRowClick"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        :search-options="{
            enabled: true
          }"
        styleClass="vgt-table striped bordered"
        ref="table"
      >
        <div slot="table-actions">
          <button
            @click.prevent="clearTableFilters"
            class="btn btn-link"
            v-b-popover.hover.top.d500="'Clear filters'"
          >
            <span class="fa-stack" style="font-size: 10px;">
              <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
              <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
            </span>
          </button>
          <button
            @click.prevent="exportData"
            class="btn btn-link"
            v-b-popover.hover.top.d500="'Export Data'"
          >
            <i class="fa fa-share-square pointer dimmedIcon"></i>
          </button>
        </div>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import VueSelect from 'vue-select'
import moment from 'moment'
import loading from '@/Assets/Mixins/LoadingMixin'
import integer from 'vuelidate/lib/validators/integer'
import _ from 'lodash'

export default {
  mixins: [loading, tableFilterMixin],
  components: { VueSelect },
  data () {
    return {
      // **** Date Selections
      SelectDateInitial: new Date(),
      SelectDateComp: new Date(),
      tableAgentsSelect: [],
      tableDataAgents: [],
      // **** Table Data

      rows: [],
      columns: [
        {
          label: 'initalPaygateId',
          field: 'initialPaygateId',
          hidden: true
        },
        {
          label: 'compPaygateId',
          field: 'compPaygateId',
          hidden: true
        },
        {
          label: 'agentId',
          field: 'agentId',
          hidden: true
        },
        {
          label: 'paygateId',
          field: 'paygateId',
          hidden: true
        },
        {
          label: 'initalStatementId',
          field: 'initalStatementId',
          hidden: true
        },
        {
          label: 'compStatementId',
          field: 'compStatementId',
          hidden: true
        },

        {
          label: 'Customer Name',
          field: 'customerNameInitial',
          sortable: true,
          formatFn: this.GetCustomerName

        },
        {
          label: 'Method',
          field: 'method',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Commission Payments Inital',
          field: 'paymentsInitial',
          sortable: true,
          formatFn: this.GetMoney,
          tdClass: 'text-right'
        },
        {
          label: 'Commission Payments Comp',
          field: 'paymentsComp',
          sortable: true,
          formatFn: this.GetMoney,
          tdClass: 'text-right'
        },

        {
          label: 'Clawbacks Initial',
          field: 'clawbacksInitial',
          sortable: true,
          formatFn: this.GetMoney,
          tdClass: 'text-right'
        }, {
          label: 'Clawbacks Comp',
          field: 'clawbacksComp',
          sortable: true,
          formatFn: this.GetMoney,
          tdClass: 'text-right'
        },
        {
          label: 'Commission Items Initial',
          field: 'commissionItemsInitial',
          sortable: true,

          tdClass: this.GetFieldFormat
        }, {
          label: 'Commission Items Comp',
          field: 'commissionItemsComp',
          sortable: true
        },

        {
          label: 'Commission Amount Initial',
          field: 'commissionAmountInitial',
          sortable: true,
          formatFn: this.GetMoney,
          tdClass: 'text-right'
        }, {
          label: 'Commission Amount Comp',
          field: 'commissionAmountComp',
          sortable: true,
          formatFn: this.GetMoney,
          tdClass: 'text-right'
        }

      ],
      serverParams: {
        sort: [{ field: 'customerName', type: 'asc' }],
        columnFilters: []
      }

    }
  },
  async created () {
    await this.getTableOptions()
    this.SetFromDate()
  },

  methods: {
    async load () {
      this.SetSelectDateInitial()
      this.SetSelectDateComp()
      await this.updateTable()
    },

    async getTableOptions () {
      console.log('Getting Data')
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionTableOptionData`,
          {
            params: { paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data for listing commissions'
          })

        console.log('table data response', response)

        this.tableDataAgents = []
        for (var i = 0; i < response.data.agentTableData.length; i++) {
          this.tableDataAgents.push({ value: response.data.agentTableData[i].agentId, text: response.data.agentTableData[i].agentName })
        }
      } catch (e) {
        console.log('e', e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    updateTable: async function () {
      try {
        console.log('updateTable')
        this.serverParams.columnFilters.dateinitial = moment(this.SelectDateInitial).format('DD-MM-YYYY')
        this.serverParams.columnFilters.datecomp = moment(this.SelectDateComp).format('DD-MM-YYYY')
        delete this.serverParams.columnFilters.AgentSelect
        if (this.tableAgentsSelect !== null && this.tableAgentsSelect !== undefined) {
          this.serverParams.columnFilters.agentid = this.tableAgentsSelect.value
        }
        // var payload =
        // {
        //   agentid: 0,
        //   dateFrom: new Date(),
        //   dateTo: new Date()
        // }
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionComparisonReport`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading agents'
          })

        console.log('response', response)
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    },

    SetSelectDateInitial () {
      // sets the initial Select One date back one months
      var dateVal = new Date()
      var monthVal = dateVal.getMonth() - 1
      console.log('monthVal', monthVal)
      if (monthVal < 0) {
        monthVal = monthVal + 12
        dateVal.setMonth(monthVal)
        dateVal.setYear(dateVal.getFullYear() - 1)
      } else {
        dateVal.setMonth(monthVal)
      }
      console.log('Initial Date Val', dateVal)

      this.SelectDateInitial = dateVal
    },

    SetSelectDateComp () {
      // sets the initial Select One date back two  months
      var dateVal = new Date()
      var monthVal = dateVal.getMonth() - 2
      if (monthVal < 0) {
        monthVal = monthVal + 12
        dateVal.setMonth(monthVal)
        dateVal.setYear(dateVal.getFullYear() - 1)
      } else {
        dateVal.setMonth(monthVal)
      }
      console.log('Comp Date Val', dateVal)
      this.SelectDateComp = dateVal
    },

    onRowClick () {
      console.log('rowclick')
    },
    exportData: async function () {
      try {
        console.log('Get Table CSV')
        this.serverParams.columnFilters.dateinitial = moment(this.SelectDateInitial).format('DD-MM-YYYY')
        this.serverParams.columnFilters.datecomp = moment(this.SelectDateComp).format('DD-MM-YYYY')
        delete this.serverParams.columnFilters.AgentSelect
        if (this.tableAgentsSelect !== null && this.tableAgentsSelect !== undefined) {
          this.serverParams.columnFilters.agentid = this.tableAgentsSelect.value
        }

        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetPaymentDetailReport`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data'
          })

        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Agents.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {
        console.log(e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    GetCustomerName (field, row) {
      if (row.customerNameInitial === null) {
        return row.customerNameComp
      } else {
        return row.customerNameInitial
      }
    },

    GetMoney (field) {
      if (field === 0) { return '-' } else {
        return '£' + this.numberWithCommas((field / 1).toFixed(2))
      }
    },
    numberWithCommas (n) {
      n = n.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(n)) {
        n = n.replace(pattern, '$1,$2')
      }
      return n
    },

    GetFieldFormat (row) {
      if (row.statementReconciliation) {
        return 'text-danger'
      } else { return '' }
    }

  }
}
</script>
