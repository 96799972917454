<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Workflows<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/`" target="_blank"><i class="far fa-question-circle"></i></a></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div>
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              ref="table"
              :columns="columns"
              :rows="rows"
              :lineNumbers="true"
              :search-options="{ enabled: true }"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              @on-row-click="onRowClick"
              styleClass="vgt-table striped bordered table-hover">
              <template slot="table-row" slot-scope="props">
                <span v-if = "props.column.field == 'metaData.type'">
                  {{ reformatType(props.formattedRow[props.column.field]) }}
                </span>
                <span v-else-if = "props.column.field == 'buttons'">
                  <b-button @click.prevent="btnEditWorkflow(props)" variant="outline-info" class="pointer" v-b-popover.hover.top.d500="'Edit Workflow'"><i class="glyphicon ti-pencil"></i></b-button>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
              <div slot="table-actions">
                <button
                  @click.prevent="clearTableFilters"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Clear filters'">
                  <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                  </span>
                </button>
                <b-button @click.prevent="load" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'" ><i class="fas fa-sync-alt pointer dimmedIcon pointer"></i></b-button>
                <b-button @click.prevent="btnNewWorkflow" class="" variant="link" v-b-popover.hover.top.d500="'Create a new workflow'" ><i class="fa fa-plus dimmedIcon pointer"></i></b-button>
              </div>
            </vue-good-table>
          </div>
          <br>
          <div class="row col-12">
            <div class="btn-group" role="group">
              <button id="btnGroupDrop1" @click="btnNewWorkflow" type="button" class="btn btn-primary">
                <i class="fa fa-plus mr-2"></i>New Workflow
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  name: 'Workflows',
  mixins: [tableFilterMixin, loading],
  data () {
    return {
      licence: {},
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      columns: [
        {
          label: 'Id',
          field: '_id',
          hidden: true
        },
        {
          label: 'Title',
          field: 'metaData.title',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Description',
          field: 'metaData.description',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Type',
          field: 'metaData.type',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Edit Workflow',
          field: 'buttons',
          sortable: false,
          filterOptions: {
            enabled: false
          },
          tdClass: 'text-center',
          thClass: 'text-center'
        }
      ],
      row_data: [],
      btnLatch: false,
      totalRows: 0,
      serverParams: {
        sort: [{ field: 'metaData.title', type: 'asc' }]
      }
    }
  },
  computed: {
    rows: function () {
      return this.row_data
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async load () {
      this.$snapbar.hide()
      let res
      this.isTableLoading = true
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflows`, { showload: true })
        if (res) {
          this.row_data = res.data.workflows
          this.totalRows = this.row_data.length
        }
      } catch (e) {
        this.row_data = []
        if (e.response && e.response.data && e.response.data.errorMessage) {
          this.$snapbar.e(`Could not return the list of workflows. ${e.response.data.errorMessage}`)
        } else {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to view Workflows.')
          } else {
            this.$snapbar.e(`Could not get a list of Workflows - ${e.message}`)
          }
        }
      } finally {
        // this.isTableLoading = false
      }
    },
    onRowClick (params) {
      if (!this.btnLatch) this.$router.push(`workflowdetail/${params.row._id}`)
    },
    btnNewWorkflow () {
      this.$router.push('designer')
    },
    btnCancel () {
      this.$router.go(-1)
    },
    reformatType (type) {
      if (type === 'bureauCustomerImporter') return 'Bureau Customer Importer'
      if (type === 'bacs') return 'BACS'
      if (type === 'fps') return 'Faster Payments'
      if (type === 'ukBureau') return 'UK BACS Bureau'
      if (type === 'ukddmsPayers') return 'UK DDMS Payers'
      if (type === 'sepa') return 'SEPA'
    },
    reformatTags (t) {
      // Simple visual reformat from ["one","two"] to one, two
      const nt = JSON.stringify(t)
      let ntr = nt.replace(/"/g, '')
      // eslint-disable-next-line
      ntr = ntr.replace(/\,/g, ', ')
      ntr = ntr.replace(/\[/g, '')
      ntr = ntr.replace(/\]/g, '')
      return ntr
    },
    btnEditWorkflow (params) {
      this.btnLatch = true
      this.$router.push(`designer/${params.row._id}`)
    },
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
    },
    async loadLicence () {
      const response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence')
      this.licence = response.data.automation
      if (!this.licence.workflow) this.$snapbar.w('You are not licensed to use Workflows.')
    }
  },
  created: async function () {
    // await this.loadLicence()
    // if (this.licence.workflow) {
    // await this.load()
    // this.defaultParams = JSON.parse(JSON.stringify(this.serverParams))
    // var savedParams = this.$store.getters.tableFilters('Workflows')
    // if (savedParams) {
    //   this.savedParamsToObjects(savedParams)
    //   this.updateParams(savedParams)
    // }
    // this.doneParams = true
    // }
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      await this.load()
    }
  }
}
</script>

<style scoped>
  td {
    cursor: pointer;
  }
</style>
