const state = {
  submission: null
}

// actions
const actions = {
  setSubmission ({ commit }, submission) {
    commit('setSubmission', submission)
  }
}

// mutations
const mutations = {
  setSubmission (state, submission) {
    console.log('setting submission')
    state.submission = submission
  }
}

export default {
  state,
  actions,
  mutations
}
