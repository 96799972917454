<template>
  <div id="inputReportErrorAction">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block-title">
          <h2>Submission Processing Errors</h2>
        </div>
        <div class="idb-block-content">
          <div v-if="action.id !== undefined" id="actionDetails">
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Title</strong>
              </div>
              <div class="col-md-10">
                {{ this.action.title }}
                <span class="pull-right">
                  <a href="#" target="_blank">
                    <i class="far fa-question-circle"></i>
                  </a>
                </span>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Message</strong>
              </div>
              <div class="col-md-10">{{ this.action.message }}</div>
            </div>
            <div class="row form-group"></div>
          </div>
          <b-tabs v-if="isLoaded" v-on:input="tabSelected" lazy v-model="tabIndex">
            <b-tab title="Pre-Sub Validation Messages">
              <preSubValidationMessages :action="action" @navToPayment="navigateToPayment"></preSubValidationMessages>
            </b-tab>
            <b-tab title="Submission Payments">
              <submissionPayments
                :selectedFileNumber="selectedFileNumber"
                :selectedRecordNumber="selectedRecordNumber"
              ></submissionPayments>
            </b-tab>
          </b-tabs>
        </div>
        <div class="idb-block-footer">
          <div v-if="this.signFailed" class="row form-group">
            <div
              class="col-md-12"
            >Submission failed due to an HSM signing issue. Click 'Accept and continue' to retry signing the submission.</div>
          </div>
          <div v-else-if="this.validationResult !== 'Fixes'" class="row form-group">
            <div
              class="col-md-12"
            >Click 'Accept and continue' if the pre-sub validation issues can be ignored.</div>
          </div>
          <div v-else class="row form-group">
            <div
              class="col-md-12"
            >There are serious issues with some of the payments in this submission. Fix the affected payments in the source data and create a new submission.</div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <button
                id="restartSubmission"
                type="button"
                class="btn btn-primary"
                :disabled="this.restarted || this.validationResult === 'Fixes'"
                v-on:click="restartSubmission"
              >Accept and continue</button>
              <button
                id="delete"
                type="button"
                class="btn btn-danger pull-right"
                :disabled="this.restarted"
                v-on:click="cancelSubmission"
              >Cancel Submission</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import PreSubValidationMessages from '@/Views/Bacs/BacsPayments/PreSubValidationMessages.vue'
import SubmissionPayments from '@/Views/Bacs/BacsPayments/SubmissionPayments.vue'

export default {
  mixins: [
    bacsMixin
  ],
  components: {
    preSubValidationMessages: PreSubValidationMessages,
    submissionPayments: SubmissionPayments
  },
  data () {
    return {
      validationResult: '',
      tabIndex: 0,
      restarted: false,
      action: {},
      subReference: '',
      isLoaded: false,
      selectedFileNumber: 1,
      selectedRecordNumber: 0,
      signFailed: false
    }
  },

  methods: {
    tabSelected (tabIndex) {
      // console.log('tab index selected: ' + tabIndex)
      this.tabIndex = tabIndex
      if (this.tabIndex === 0) {
        this.selectedFileNumber = 1
        this.selectedRecordNumber = 0
      }
    },

    navigateToPayment (params) {
      // console.log('navigateToPayment: ' + JSON.stringify(params))
      this.tabIndex = this.tabIndex === 0 ? 1 : 0
      if (this.tabIndex === 1) {
        this.selectedFileNumber = params.fileNumber
        this.selectedRecordNumber = params.recordNumber
      }
    },

    async getAction () {
      // console.log('subProcError getAction')
      var actionId = this.$store.getters.actionId
      if (actionId !== undefined) {
        var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/' + actionId
        var response = await axios.get(url)
        this.action = response.data
        // console.log('subProcError action: ' + JSON.stringify(this.action))
        // console.log('group type: ' + this.action.data.groupType)
        this.subReference = encodeURIComponent(this.action.data.reference)
        this.signFailed = this.action.data.signFailed
      }
    },

    async cancelSubmission () {
      var result = await swal.fire({
        title: 'Cancel Submission?',
        text: 'The submission contents will be deleted. Do you want to cancel this submission?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        var json = JSON.stringify({
          submissionId: this.action.actionedObjectId,
          actionId: this.action.id
        })
        await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/cancelSubmissionProcessingError/',
          data: json,
          showload: true
        })
        // await axios.delete(url)
        this.$router.push('/actions')
      }
    },

    async restartSubmission () {
      this.restarted = true
      var json = JSON.stringify({
        submissionId: this.action.actionedObjectId,
        ignorePresubWarnings: true,
        retrySignSubmission: this.signFailed
      })

      var apiMethod = this.action.data.groupType === 0 ? 'createProcessingSubmission' : 'createSettlementSubmission'
      var url = `${process.env.VUE_APP_BACS_API_URL}bacs/submission/${apiMethod}`
      var response = await axios({
        method: 'POST',
        url: url,
        data: json
      })
      if (response.data.success) {
        this.$router.back()
      }
    },

    async getPresubValResult () {
      // console.log('subProcError getPresubValResult')
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getPresubValidationResult?submissionId=${this.action.actionedObjectId}`, { showload: true })
      this.validationResult = response.data.validationResult
    }
  },

  async created () {
    this.downloadUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getPresubValidationMessages'
    await this.getAction()
    await this.getPresubValResult()
    this.isLoaded = true
  }
}
</script>
<style lang="scss">
.report-row-selected {
  height: 60px;
  background-color: #f0f0f0;
  color: black;
}
.grid-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.viewLink {
  color: blue;
  text-decoration: underline;
}
.dark-mode {
  .viewLink {
    color: #888888;
    text-decoration: underline;
  }
}
</style>
