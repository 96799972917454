<template>
  <b-modal
    id="statement-paid-dialog"
    ref="StatementPaidDialog"
    title="Statement Paid"
    size="md"
    hide-header-close
    @ok="handleOk"
    @cancel="handleCancel"
    @shown="loadModal"
    :ok-disabled="disableOk"
  >
    <div class="row form-group">
      <label class="col-form-label col-md-3">Invoice Number</label>
      <div class="col-md-6">
        <input v-focus type="text" class="form-control" v-model.trim="$v.invoiceNumber.$model" />
      </div>
      <b-col offset-sm="3" class="pl-3" v-if="$v.invoiceNumber.$dirty">
        <label
          class="form-text text-danger small text-nowrap"
          v-if="!$v.invoiceNumber.required"
        >An invoice number is required</label>
      </b-col>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
export default {
  props: {
    currentStatementId: String
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      invoiceNumber: '',
      disableOk: true
    }
  },
  validations: {
    invoiceNumber: { required }
  },

  watch: {
    invoiceNumber: function () {
      if (!this.$v.invoiceNumber.$invalid) {
        this.disableOk = false
      } else {
        this.disableOk = true
      }
    }
  },

  methods: {
    loadModal () {
      this.invoiceNumber = ''
    },
    handleCancel () {
      console.log('cancelled invoice entry')
      this.$toastr.w('Cancelled setting the statement to paid')
      this.$emit('submit')
    },
    handleOk: async function () {
      try {
        console.log('setting to paid')
        var response = await axios.post(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CASetStatementToPaid/`,
          {
            StatementId: this.currentStatementId,
            InvoiceNumber: this.invoiceNumber,
            params: {

              paygateid: this.selectedCustomer
            },
            showerror: true,
            errormessage: 'Failed to set Commission Statement to Paid'
          })

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
      this.$emit('submit')
    }
  }
}
</script>
