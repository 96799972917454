export default {
  New: 'New',

  All: 'All',
  InStatement: 'In Statement',
  StatementSent: 'Statement Sent',
  StatementPaid: 'Statement Paid',
  AgentDeactivated: 'Agent Deactivated',
  Deleted: 'Deleted',
  Rollback: 'Rollback'

}
