<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Logging Settings</h2>
      </div>
      <div class="idb-block-content">
        <table class="table table-header-rotated">
          <thead>
            <tr class>
              <th style="width:50px;">
                <div>
                  <span>Application</span>
                </div>
              </th>
              <th style="width:50px;">
                <div>
                  <span>Trace</span>
                </div>
              </th>
              <th style="width:50px;">
                <div>
                  <span>Debug</span>
                </div>
              </th>
              <th style="width:50px;">
                <div>
                  <span>Information</span>
                </div>
              </th>
              <th style="width:50px;">
                <div>
                  <span>Warning</span>
                </div>
              </th>
              <th style="width:50px;">
                <div>
                  <span>Error</span>
                </div>
              </th>
              <th style="width:50px;">
                <div>
                  <span>Critical</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="loggingSetting in loggingSettings" :key="loggingSetting.application">
              <td>{{loggingSetting.application}}</td>
              <td>
                <p-check class="p-default p-curve" v-model="loggingSetting.trace"></p-check>
              </td>
              <td>
                <p-check class="p-default p-curve" v-model="loggingSetting.debug"></p-check>
              </td>
              <td>
                <p-check class="p-default p-curve" v-model="loggingSetting.info"></p-check>
              </td>
              <td>
                <p-check class="p-default p-curve" v-model="loggingSetting.warning"></p-check>
              </td>
              <td>
                <p-check class="p-default p-curve" v-model="loggingSetting.error"></p-check>
              </td>
              <td>
                <p-check class="p-default p-curve" v-model="loggingSetting.critical"></p-check>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="save" type="submit" :disabled="isLoading">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  mixins: [loading],
  name: 'LoggingSettings',
  data () {
    return {
      loggingSettings: []
    }
  },
  async mounted () {
    await this.loadSettings()
  },
  methods: {
    async loadSettings () {
      try {
        var loggingSettingResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Logging`,
          { showload: true, showerror: true, errormessage: 'Logging failed to load' })
        this.loggingSettings = loggingSettingResponse.data.sort((a, b) => a.application.localeCompare(b.application))
      } catch { }
    },
    async save () {
      try {
        await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}Logging`, this.loggingSettings,
          { showload: true, showerror: true, errormessage: 'Logging failed to save' })
        this.$toastr.s('Logging Settings updated', 'Updated')
        await this.loadSettings()
      } catch { }
    }
  }

}
</script>
<style lang="scss" scoped>
tr {
  th {
    background-color: white;
    white-space: nowrap;
    border: none !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
  }
}
.small-text {
  height: 24px;
}

.scrollable {
  overflow: auto;
}
</style>
