<template>
    <div>
        <div class="idb-block">
            <h2 v-if="messageError" class="idb-block-content">Cannot load message.</h2>
            <h2 v-if="!messageError && (!message || message.payerMessageId === null)" class="idb-block-content">Loading message.</h2>
            <div :class="type === 0 ? 'd-block' : 'd-none'"> <!-- Email -->
              <div class="idb-block-title">
                <h2 style="margin-bottom: 5px;"><help-icon docPath="/paygate-collections/messaging/messagequeue/" />View Email Message</h2>
              </div>
              <div class="idb-block-content">
                <div>Subject:</div>
                <div class="mb-4">{{ messageTitle }}</div>
                <div v-html="message && messageBody"></div>
              </div>
            </div>
            <div :class="type === 1 ? 'd-block' : 'd-none'"> <!-- Letter -->
              <div class="idb-block-title">
                <h2 id="title-of-block-check"><span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span>View Letter Message</h2>
              </div>
              <b-row class="idb-block-content">
                <!-- <b-col offset-lg="2" cols="12" sm="12" offset="0" offset-sm="0" lg="8">
                  <iframe frameBorder="0" v-if="message && message.payerMessageId" ref="previewFrame" :src="previewUrl" width="100%" height="100%"></iframe>
                </b-col> -->

                <b-col style="width: 105% !important">
                  <div style="padding-top: 110%; position: relative;">
                    <iframe :key="frameKey"
                      frameborder="0"
                      ref="previewFrame"
                      height="100%"
                      width="100%"
                      :style="message && message.payerMessageId ? 'position: absolute; top: 0; left: 0;' : 'visibility: hidden;'"
                      :src="previewUrl"
                    ></iframe> <!-- //:src="previewUrl" -->
                  </div>
                </b-col>
              </b-row>
            </div>
            <div :class="type === 2 ? 'd-block' : 'd-none'"> <!-- SMS -->
              <div v-if="message && senderName" class="idb-block-title">
                  <h2 style="margin-bottom: 5px;"><span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span>View SMS Message</h2>
              </div>
              <div class="idb-block-content">
                <div>Sender Name</div>
                <div class="mb-4">{{ senderName }}</div>
                <div v-html="message && messageBody"></div>
              </div>
            </div>
            <div class="idb-block-footer" v-if="canDelete">
                <b-btn :disabled="!message || isLoading" @click.prevent="deleteMessageCheck" variant="danger">Delete Message</b-btn>
              <b-btn :disabled="!message || isLoading" v-if="!sent && !sendButtonPressed" @click.prevent="requeueMessage" class="ml-2" variant="success">Send Message Immediately</b-btn>
            </div>
            </div>
            <div class="idb-block" v-if="status === 0">
              <div class="idb-block-title">Error Message</div>
              <div class="idb-block-content">{{errorText}}</div>
              <div class="idb-block-footer">
                <b-btn :disabled="!message || isLoading" v-if="!sent && !sendButtonPressed" @click.prevent="requeueMessage" variant="success">Resend Message Immediately</b-btn>
              </div>
            </div>
            <div class="idb-block" v-if="status === 3">
              <div class="idb-block-title">Error Message</div>
              <div class="idb-block-content">The recipient email address used for this message triggered a bounce warning, indicating that the recipient mailbox is inaccessible, or the address doesn't exist.</div>
              <div class="idb-block-footer">
                <b-btn :disabled="!message || isLoading" @click.prevent="goToPayer" variant="outline-success">Go To Customer</b-btn>
                <b-btn :disabled="!message || isLoading"  v-if="!sent && !sendButtonPressed" @click.prevent="requeueMessage" class="ml-2" variant="success">Resend Message Immediately</b-btn>
              </div>
            </div>
    </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import axios from 'axios'
import roles from "@/Assets/Constants/roles";
export default {
  mixins: [loading],
  data () {
    return {
      frameKey: 0,
      sendButtonPressed: false
    }
  },
  computed: {
    sent () {
      return this.$store.state.messages.currentMessage.status === 1 || this.$store.state.messages.currentMessage.status === 3 || this.$store.state.messages.currentMessage.status === 4
    },
    type () {
      if (this.$store.state.messages.currentMessage) {
        return this.$store.state.messages.currentMessage.channelType
      } else {
        return null
      }
    },
    messageTitle () {
      return this.$store.state.messages.currentMessage.messageTitle
    },
    senderName () {
      return this.$store.state.messages.currentMessage.senderName
    },
    messageBody () {
      return this.$store.state.messages.currentMessage.messageBody
    },
    message () {
      return this.$store.state.messages.currentMessage
    },
    errorText () {
      return this.$store.state.messages.currentMessage ? this.$store.state.messages.currentMessage.errorMessage : ''
    },
    status () {
      return this.$store.state.messages.currentMessage ? this.$store.state.messages.currentMessage.status : ''
    },
    messageError () {
      return this.$store.state.messages.messageError
    },
    canDelete () {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin])
    },
    previewUrl () {
      if (this.message && this.message.payerMessageId) {
        const pdfUrl = encodeURI(`${process.env.VUE_APP_DDMS_API_URL}messages/letterpreview/${this.message.payerMessageId}`)
        var previewUrl = `${process.env.VUE_APP_PDFVIEWER_URL}?file=${pdfUrl}&token=${this.bearerToken}`
        console.log(previewUrl)
        return previewUrl
      } else {
        return '#'
      }
    }
  },
  props: {
    messageId: String
  },
  methods: {
    async deleteMessageCheck () {
      let swalWarningText
      if (this.message.messagePriority === 0) { // MessagePriority.DontRemove - can cause legal problems if not sent
        swalWarningText = 'Only continue if you really mean to delete this message. This action can breach the Direct Debit indemnity clause. You will not be able to recover the deleted message.'
      } else {
        swalWarningText = 'You will not be able to recover the deleted message.'
      }
      await this.$swal({
        title: 'Delete Message',
        text: swalWarningText,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Delete'
      })
      await this.$store.dispatch('deleteMessage', this.messageId)
      //this.$router.push({ name: 'MessageQueue' })
      this.$router.go(-1)
    },
    goToPayer () {
      this.$router.push('/collections/customer/'+this.message.ukPayerId+'/edit')
    },
    async requeueMessage () {
      let result = await this.$swal({
        title: 'Send Message Immediately',
        text: 'This will bypass the message queue and send the message immediately.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Send'
      })
      if (result) {
        this.sendButtonPressed = true
        try {
          let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}messages/resend/${this.messageId}`, { params: { paygateId: this.paygateId } })
          this.$toastr.s('Message Queued')
        } catch (err) {
          this.$toastr.e('Failed to send message')
        }
         await this.$store.dispatch('loadMessageDetails', this.messageId)
      }
    }
  },
  async mounted () {
    this.bearerToken = await auth.getAccessToken()
    try {
      await this.$store.dispatch('loadMessageDetails', this.messageId)
    } catch (e) {
      this.$toastr.e('Unable to load message details.', 'An error occurred')
    }
    this.frameKey++

  }
}
</script>
<style type="text/css">
  .swal2-icon.swal2-info, .swal2-icon.swal2-warning {
    font-size: 60px !important;
    line-height: 80px;
    text-align: center;
  }
</style>
