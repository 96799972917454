<template>
  <div>
    <folderBrowser v-if="showFolderBrowser" @close="showFolderBrowser = false; closeFolderPopup()"></folderBrowser>
    <fileBrowser v-if="showFileBrowser" @close="showFileBrowser = false; closeFilePopup()"></fileBrowser>
    <h3>
      Zip File
      <span class="pull-right">
        <a href="#" target="_blank">
          <i class="far fa-question-circle"></i>
        </a>
      </span>
    </h3>
    <hr />
    <br />
    <label class="required">Filename</label>
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s2.value.$error}"
        @input="$v.selectedNode.props.s2.value.$touch()"
        v-model="selectedNode.props.s2.value"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFileBrowser" type="button">...</button>
      </div>
    </div>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty "
    >The filename field is required!</p>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s2.value.maxLength"
    >Maximum length is {{ $v.selectedNode.props.s2.value.$params.maxLength.max }} characters!</p>
    <br />
    <label class="required">Path</label>
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        @input="$v.selectedNode.props.s1.value.$touch()"
        v-model="selectedNode.props.s1.value"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary"
          @click.prevent="btnFolderBrowser"
          type="button"
        >...</button>
      </div>
    </div>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty "
    >The Folder field is required!</p>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s1.value.maxLength"
    >Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    <br />
    <div class="form-group">
      <p-check
        name="check"
        class="p-switch"
        color="primary"
        v-model="selectedNode.props.b1.value"
      >Encrypt Zip File</p-check>
    </div>
    <br />
    <div class="form-group" v-if="selectedNode.props.b1.value">
      <label class="required">Password</label>
      <input
        type="password"
        :class="{invalid: $v.selectedNode.props.pw1.value.$error}"
        class="form-control"
        v-model="selectedNode.props.pw1.value"
      />
      <p
        class="validationText"
        v-if="!$v.selectedNode.props.pw1.value.required && $v.selectedNode.props.pw1.value.$dirty "
      >The Password field is required when 'Encrypt Zip File' is selected!</p>
      <p
        class="validationText"
        v-if="!$v.selectedNode.props.pw1.value.maxLength"
      >Maximum length is {{ $v.selectedNode.props.pw1.value.$params.maxLength.max }} characters!</p>
    </div>
    <br />
    <hr />
    <div class="pull-right">
      <b-button variant="danger" class="btnPad" @click.prevent="btnCancel">Cancel</b-button>
      <b-button class variant="success" @click.prevent="btnSave">OK</b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  components: {
    folderBrowser: folderBrowser,
    fileBrowser: fileBrowser
  },
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s2: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        pw1: {
          value: {
            required: requiredIf(function () {
              // Conditional:  Only required if encryption is selected
              return this.selectedNode.props.b1.value
            }),
            maxLength: maxLength(64)
          }
        }
      }
    }
  },
  data () {
    return {
      showFolderBrowser: false,
      showFileBrowser: false,
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s1.value = utils.sanitisePath(this.selectedNode.props.s1.value)
      // this.selectedNode.props.s2.value = utils.sanitiseFilename(this.selectedNode.props.s2.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnFileBrowser () {
      this.property = this.selectedNode.props.s2.value
      this.$store.commit('setModalPopupString', this.selectedNode.props.s2.value)
      this.showFileBrowser = true
    },
    btnFolderBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s1.value)
      this.showFolderBrowser = true
    },
    closeFilePopup () {
      console.log('Closed closeFilePopup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s2.value = filename
      if (!this.selectedNode.props.s1.value) {
        console.log('falsey')
        let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
        if (folder.endsWith('/') || folder.endsWith('\\')) {
          folder = folder.slice(0, -1)
        }
        this.selectedNode.props.s1.value = folder
      }
      // console.log(folder)
    },
    closeFolderPopup () {
      console.log('closeFolderPopup popup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      this.selectedNode.props.s1.value = this.$store.state.mapping.modalPopupString
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
.invalid {
  border-color: red;
  border-width: 2px;
}
</style>
