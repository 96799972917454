<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <div class="form-group">
        <div class="row">
          <h2 class="col-md-10">
            List Commissions for Statement
            <strong>{{Statement.statementserialid}}</strong>
            for
            <strong>{{Statement.agentname}}</strong>
          </h2>
          <h2 class="col-md-2 pull-right">
            Statement Date:
            <strong>{{StatementMonthYear}}</strong>
          </h2>
        </div>
      </div>
    </div>
    <div class="idb-block-content">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="from">Customer</label>
          <vue-select
            v-model="tableCustomersSelect"
            :options="tableDataCustomers"
            label="text"
            :closeOnSelect="true"
            @input="updateTable()"
          ></vue-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <vue-good-table
            :paginationOptions="paginationOptions"
            :sort-options="sortOptions"
            :isLoading.sync="isTableLoading"
            :rows="rows"
            :columns="columns"
            :lineNumbers="true"
            mode="remote"
            :totalRows="totalRecords"
            @on-row-click="onRowClick"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
            :search-options="{
            enabled: true
          }"
            styleClass="vgt-table striped bordered"
            ref="table"
          >
            <div slot="table-actions">
              <!-- clear -->
              <button
                @click.prevent="clearTableFilters"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Clear filters'"
              >
                <span class="fa-stack" style="font-size: 10px;">
                  <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                  <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                </span>
              </button>
              <!-- refresh -->
              <b-button
                @click.prevent="load"
                class
                variant="link"
                v-b-popover.hover.top.d500="'Refresh the data in the table'"
              >
                <i class="fa fa-sync pointer dimmedIcon"></i>
              </b-button>

              <!-- export -->
              <button
                @click.prevent="exportData"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Export Data'"
              >
                <i class="fa fa-share-square pointer dimmedIcon"></i>
              </button>
              <!-- insert -->
              <button
                @click.prevent="onInsertManualCommissionItem"
                class="btn btn-link"
                title="Create Manual Precommission Item"
                v-b-popover.hover.top.d500="'Create a new commission record manually'"
              >
                <i class="fa fa-plus dimmedIcon pointer"></i>
              </button>
            </div>
          </vue-good-table>
        </div>
      </div>
    </div>
    <div class="idb-block-footer">
      <button class="btn btn-primary" @click="onInsertManualCommissionItem" type="button">
        <i class="fa fa-plus rpad"></i>Create Manual Commission Record
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import papa from 'papaparse'

import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import integer from 'vuelidate/lib/validators/integer'
import settlementMixin from '@/Components/Platform/Customer/Settlements/SettlementMixin'
import VueSelect from 'vue-select'
import commissionAgentCommissionItemStatus from '../../../../Assets/Constants/commissionAgentCommissionItemStatus'
export default {
  props: ['statementid'],
  components: { VueSelect },
  mixins: [loading, tableFilterMixin, settlementMixin, tableFilterMixin],
  data () {
    return {
      props: {
        statementid: String
      },
      serverParams: {
        sort: [{ field: 'servicecharge', type: 'asc' }]
      },
      rows: [],
      columns: [
        {
          label: 'AssociationId',
          field: 'associationId',
          hidden: true
        },
        {
          label: 'AgentId',
          field: 'agentId',
          hidden: true
        },
        {
          label: 'BillingUsageId',
          field: 'billingUsageId',
          hidden: true
        },
        {
          label: 'Agent',
          field: 'agentName',
          filterOptions: {
          },
          hidden: true
        },
        {
          label: 'Customer',
          field: 'customerName',
          filterOptions: {

          }
        },
        {
          label: 'Service Charge Date',
          field: 'serviceChargeCreationDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: true

        },
        {
          label: 'Service Charge (£)',
          field: 'serviceCharge',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },

        {
          label: 'Commission (£)',
          field: 'commissionAmount',
          sortable: true,
          formatFn: this.moneyFormat,
          tdClass: 'text-right'
        },
        {
          label: 'Commission Type',
          field: 'fixedFeePercentage',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Overridden',
          field: 'overridden',
          sortable: true,
          formatFn: this.GetOverriddenField

        },
        {
          label: 'Manual Creation',
          field: 'manualCreated',
          sortable: true,
          formatFn: this.GetManualCreationField
        },
        {
          label: 'Status',
          field: 'commissionItemStatus',
          sortable: true,
          formatFn: this.GetCommissionItemStatusField,
          filterOptions: {
            enabled: true,
            placeholder: 'All',
            filterDropdownItems: Object.values(commissionAgentCommissionItemStatus)
          }
        }
      ],
      tableDataCustomers: [],
      tableAgentsSelect: [],
      tableCustomersSelect: [],
      tableStatusSelect: [],
      Statement: {}
    }
  },
  computed: {
    StatementMonthYear () {
      if (this.Statement.statementmonth < 10) {
        return '0' + this.Statement.statementmonth + '/' + this.Statement.statementyear
      } else {
        return this.Statement.statementmonth + '/' + this.Statement.statementyear
      }
    }
  },
  async created () {
    await this.GetStatement()
    await this.getTableOptions()
    await this.updateTable()
  },

  methods: {
    async load () {

    },

    GetStatement: async function () {
      console.log('GetStatement', this.FromStatementMode)
      //      if (!this.FromStatementMode) { return }
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetSingleStatement/${this.statementid}`,
          {
            showerror: true,
            errormessage: 'Failed loading statement data'
          })

        console.log('response', response)
        this.Statement = response.data
      } catch { } finally {

      }
    },

    selectTableStatus: async function () {
      await this.updateTable
    },

    updateTable: async function () {
      console.log('this.tableStatusSelect', this.tableStatusSelect)
      if (this.tableStatusSelect.length > 0) {
        var commissionItemStatusArr = []
        this.serverParams.columnFilters.commissionItemStatus = []
        for (var i = 0; i < this.tableStatusSelect.length; i++) {
          commissionItemStatusArr.push(this.tableStatusSelect[i].value)
        }
        this.serverParams.columnFilters.commissionItemStatus = commissionItemStatusArr
      }

      // Deleting here in case carried over from the show commission page
      delete this.serverParams.columnFilters.agentSelect

      delete this.serverParams.columnFilters.customerSelect
      if (this.tableCustomersSelect !== null && this.tableCustomersSelect !== undefined) {
        this.serverParams.columnFilters.customerSelect = this.tableCustomersSelect.value
      }

      this.serverParams.columnFilters.statementid = this.statementid

      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionsTable`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data'
          })
        console.log('table response', response)
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch (e) {
        console.log('update table failed', e)

        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    async getTableOptions () {
      console.log('Getting Data')
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetStatementCommissionTableOptionData/${this.statementid}`,
          {
            params: { paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data for listing commissions'
          })

        console.log('table data response', response)

        this.tableDataCustomers = []
        for (var j = 0; j < response.data.customerTableData.length; j++) {
          this.tableDataCustomers.push({ value: response.data.customerTableData[j].paygateId, text: response.data.customerTableData[j].customerName })
        }
      } catch (e) {
        console.log('e', e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    GetManualCreationField (field) {
      if (field) {
        return 'yes'
      } else { return '' }
    },
    GetOverriddenField (field) {
      if (field) {
        return 'yes'
      } else { return '' }
    },

    GetCommissionItemStatusField (field) {
      if (field === commissionAgentCommissionItemStatus.New) { return 'New' } else if (field === commissionAgentCommissionItemStatus.InStatement) { return 'In Statement' } else if (field === commissionAgentCommissionItemStatus.StatementSent) { return 'Statement Emailed' } else if (field === commissionAgentCommissionItemStatus.StatementPaid) { return 'Paid' } else if (field === commissionAgentCommissionItemStatus.AgentDeactivated) { return 'Not Payable' } else if (field === commissionAgentCommissionItemStatus.Deleted) { return 'Deleted' }
    },
    ApplyCommissionFilter (data, filterString) {
      console.log('apply commission filter:', data, filterString)
    },
    exportData: async function () {
      try {
        var response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}CommissionProcessing/CAGetCommissionsTableCSV`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed to get table data'
          })

        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Commissions.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {
        console.log(e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    onRowClick (params) {
      this.$router.push({
        name: 'CommissionAgentCommissionEdit',
        params: { commissionid: params.row.id }
      })
    },
    onInsertManualCommissionItem () {
      this.$router.push({
        name: 'CommissionAgentStatmentCommissionAdd',
        params: { statementid: this.statementid }
      })
    }

  }
}

</script>
