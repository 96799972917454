<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Manage HSM</h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="{
            enabled: true,
            perPage: 10,
                    jumpFirstOrLast:true
          }"
          :rows="rows"
          :columns="columns"
          :searchOptions="{
            enabled: true
          }"
          :lineNumbers="true"
          @on-row-click="onRowClick"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'enabled'">
              <span :class="{'text-success':props.row.enabled, 'text-danger':!props.row.enabled}">
                <i
                  class="glyphicon"
                  :class="{'ti-check':props.row.enabled, 'ti-close':!props.row.enabled}"
                ></i>
              </span>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'HSM',
          field: 'hsmType',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Enabled',
          field: 'enabled'
        }
      ]
    }
  },
  created () {
    this.loadHsms()
  },
  methods: {
    onRowClick (params) {
      var hsmType = params.row.hsmType
      try {
        // this.$store.dispatch('setPaygateId', paygateId)
        // this.$store.dispatch('getCustomer', paygateId)
      } catch (error) {
        console.error(error)
      }
      this.$router.push({ name: 'ManageHsm', query: { hsmType } })
    },
    async loadHsms () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Hsm`)
        this.rows = response.data
      } catch (e) {
        console.error(e)
      }
    }
  }
}

</script>
