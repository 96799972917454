<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Customer Migration Report</h2>
      </div>
      <div class="idb-block-content">
      <div class="row form-group mb-3">
        <div class="col-md-3">
          <div class="form-group">
            <p-check
              id="previewCheck"
              class="p-switch p-fill"
              color="primary"
              v-model="useDateFilter"  @change="load()"
            >Filter By Migration Date</p-check>
          </div>
        </div>
      </div>
        <div class="row form-group mb-3" v-if="useDateFilter">
          <div class="col-md-2 required">
            Earliest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="endPaymentDatePicker"
              v-model="start"
              :format="formatDate" @input="load()" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <div class="row form-group mb-3" v-if="useDateFilter">
          <div class="col-md-2 required">
            Latest Date
          </div>
          <div class="col-md-4">
            <div class="right-inner-addon">
              <datepicker id="startPaymentDatePicker"
              v-model="end"
              :format="formatDate"
              @input="load()" input-class="form-control datepicker"
              /><i class="fa fa-calendar form-control-feedback"></i>
            </div>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          @on-row-click="onRowChange"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'buttons'">
              <b-button variant="outline-secondary" @click="download(props.row)"><i class="fa fa-calendar mr-2"></i>Get Data</b-button>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load" :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">

      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import Datepicker from 'vuejs-datepicker'
  import papa from 'papaparse'
  import moment from 'moment'

  export default {
    mixins: [tableFilterMixin, loading],
    components: {
      Datepicker
    },
    data () {
      return {
        dateOptions: {
          format: 'DD/MM/YYYY HH:mm',
          useCurrent: false,
          stepping: 30,
          sideBySide: true,
        },
        end: null,
        start: null,
        useDateFilter: false,
        rows: [],
        columns: [
          {
            label: 'moveLogId',
            field: 'moveLogId',
            hidden: true
          },
          {
            label: 'Created Date',
            field: 'moveDate',
            formatFn: this.formatDate
          },
          {
            label: 'Schedule Count',
            field: 'planCount'
          },
          {
            label: 'transferringUserId',
            field: 'transferringUserId',
            hidden: true
          },
          {
            label: 'Migrating User',
            field: 'transferringUserName'
          },
          {
            label: 'Migration Date',
            field: 'migrationDate',
            formatFn: this.formatDate
          },
          {
            label: 'destinationCustomerId',
            field: 'destinationCustomerId',
            hidden: true
          },
          {
            label: 'From',
            field: 'sourceCustomerName'
            },
          {
            label: 'sourceCustomerId',
            field: 'sourceCustomerId',
            hidden: true
          },
          {
            label: 'To',
            field: 'destinationCustomerName'
			    },
          {
            label: '',
            field: 'buttons',
            sortable: false,
            tdClass: 'text-center'
          }
        ]
      }
    },
    mounted () {
      this.end = new Date()
      let tempStart = new Date()
      tempStart.setDate(this.end.getDate() - 7)
      this.start = tempStart
      this.load()
    },
    methods: {
      load: async function () {
        try {
          var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payermovementreport`, { params: { ...this.buildGoodTableQuery(), startDate: this.start, endDate: this.end, useDateFiltering: this.useDateFilter  } })
          if (response) {
            this.rows = response.data.data
            this.totalRecords = response.data.count
          }
        } catch (error) {
          this.$toastr.e(error)
        }
      },
      onRowChange (e) {
        console.log(e)
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      numberWithCommas (x) {
        x = x.toString()
        var pattern = /(-?\d+)(\d{3})/
        while (pattern.test(x)) {
          x = x.replace(pattern, '$1,$2')
        }
        return x
      },
      formatAmount (value) {
        return '£' + this.numberWithCommas((value / 1).toFixed(2))
      },
      formatStatus (value) {
        let retVal = ''
        switch(value) {
          case 1:
            retVal = 'Created'
            break
          case 2:
            retVal = 'In Progress'
            break
          case 3:
            retVal = 'Resolved'
            break
          case 4:
            retVal = 'Rejected'
            break
          case 5:
            retVal = 'Action Required'
            break
          default:
            retVal = 'None'
            break
        }
        return retVal
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      },
      formatDateWithNull (date) {
        if (date===null || date === '0001-01-01T00:00:00') {
          return 'N/A'
        } else {
          return this.formatDate(date)
        }
      },
      async download(row) {
        let id = row.moveLogId
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payermovementreport/${id}/download`)
        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        let date = moment(row.moveDate).format('DD-MM-YYYY')
        let fileName = `migration-payers-${row.sourceCustomerName}-to-${row.destinationCustomerName}-${date}.csv`
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      async exportTable () {
        var query = this.buildGoodTableQuery()
        query.perPage = this.totalRecords
        var startDate = moment(this.start).format('DD-MM-YYYY')
        var endDate = moment(this.end).format('DD-MM-YYYY')
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payermovementreport`, { params: {
          startDate: this.start, endDate: this.end, useDateFiltering: this.useDateFilter,
          ...query, paygateId: this.paygateId, forExport:true }, showload: true })
        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        let filename =  this.useDateFilter ? `movement-report-${startDate}-to-${endDate}.csv` : `movement-report.csv`
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = filename
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
      codeToDescription (value) {
        let retval = ''
        switch (value) {
          case 1:
            retval = 'DETAILS DIFFER FROM THE ADVANCE NOTICE';
            break;
          case 2:
            retval = 'NO ADVANCE NOTICE WAS RECEIVED';
            break;
          case 3:
            retval = 'BANK CANCELS THE DIRECT DEBIT';
            break;
          case 4:
            retval = 'CUSTOMER CANCELS THE DIRECT DEBIT';
            break;
          case 5:
            retval = 'CUSTOMER DISPUTES HAVING GIVEN AUTHORITY';
            break;
          case 6:
            retval = 'FRAUDULENT SIGNATURE';
            break;
          case 7:
            retval = 'SERVICE USER REQUESTS INDEMNITY CLAIM';
            break;
          case 8:
            retval = 'SERVICE USER IS NOT RECOGNISED';
            break;
          default:
            retval = 'Invalid Code Supplied'
            break;
        }
        return retval
      },
    },
    computed: {
      startDateOptions () {
        return { ...this.dateOptions, maxDate: this.end }
      },
      endDateOptions () {
        return { ...this.dateOptions, minDate: this.start }
      },
    }
  }
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
