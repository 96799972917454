<template>
  <form @submit.prevent="saveHsm">
    <div class="idb-block">
      <!-- <loading v-bind:loading="loading"></loading> -->
      <div class="idb-block-title">
        <h2>Manage HSM</h2>
      </div>

      <div class="idb-block-content">
        <!-- Library Path -->
        <div class="form-group row" :class="{invalid: $v.hsm.cryptoLibrary.$error}">
          <label class="col-form-label col-md-3 required">Library Path</label>
          <div class="col-md-9">
            <input type="text" class="form-control" v-model.trim="$v.hsm.cryptoLibrary.$model" />
            <!-- Validation -->
            <span v-if="$v.hsm.cryptoLibrary.$error" class="validation-messages">
              <small
                class="form-text small"
                v-if="!$v.hsm.cryptoLibrary.required"
              >Please enter a path to the HSM crytographic library</small>
            </span>
          </div>
        </div>

        <!-- Enabled -->
        <div class="form-group row">
          <label class="col-form-label col-md-3">Enabled</label>
          <div class="col-md-6">
            <p-check class="p-switch p-fill" color="primary" v-model="$v.hsm.enabled.$model"></p-check>
          </div>
        </div>

        <!-- PIN -->
        <div class="form-group row">
          <label class="col-form-label col-md-3">PIN</label>
          <div class="col-md-6">
            <input
              :type="hsmPinToggled? 'text': 'password'"
              class="form-control"
              v-model.trim="$v.hsm.pin.$model"
            />
            <span
              :class="{'fa-eye-slash': hsmPinToggled, 'fa-eye': !hsmPinToggled }"
              class="fa fa-fw field-icon"
              @mousedown="toggleHSMPin(true)"
              @mouseup="toggleHSMPin(false)"
            ></span>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" type="submit">Save</button>
        <button class="btn btn-danger pull-right" type="button" @click="cancel">Cancel</button>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
// Change
export default {
  props: ['hsmType'],
  data () {
    return {
      hsm: {
        cryptoLibrary: null,
        enabled: false,
        pin: null
      },
      loading: true,
      hsmPinToggled: false
    }
  },
  async mounted () {
    await this.loadHsm()
  },
  methods: {
    async loadHsm () {
      try {
        this.loading = true
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Hsm/${this.hsmType}`, {
          showload: true,
          showerror: true,
          errormessage: 'HSM failed to load'
        })
        this.hsm = response.data
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      } finally {
        this.loading = false
      }
    },
    async saveHsm () {
      try {
        this.loading = true
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Hsm`, this.hsm, {
          showload: true,
          showerror: true,
          errormessage: 'HSM failed to save'
        })
        this.$toastr.s('HSM settings has been saved', 'Saved')
        await this.loadHsm()
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      } finally {
        this.loading = false
      }
    },
    toggleHSMPin (value) {
      this.hsmPinToggled = value
    },
    cancel () {
      this.$router.back()
    }
  },
  validations: {
    hsm: {
      cryptoLibrary: { required },
      enabled: {},
      pinRequired: {},
      pin: {}
    }
  }
}
</script>
<style scoped>
.field-icon {
  position: absolute;
  top: 12px;
  right: 32px;
  z-index: 3;
  cursor: pointer;
}
</style>
