<template>
  <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>
                <help-icon docPath="/paygate-collections/payers/viewschedule/" />View Schedule
              </h2>
            </div>
            <div class="idb-block-content">
              <b-row class="mb-3">
                <b-col>
                  <strong class="mr-2">Name:</strong>
                  <router-link :to="'/collections/customer/'+id+'/edit'">{{payerName}}</router-link>
                </b-col>
                <b-col>
                  <strong class="mr-2">Reference:</strong>
                  <router-link :to="'/collections/customer/'+id+'/edit?openAt=schedules&selectedSchedule='+planTemplateName">{{reference}}</router-link>
                </b-col>
                <b-col>
                  <strong class="mr-2">Schedule Status:</strong>
                  {{planStatus}}
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <strong class="mr-2">Template Name:</strong>
                  {{planTemplateName}}
                </b-col>
                <b-col>
                  <strong class="mr-2">Direct Debit Type:</strong>
                  {{type}}
                </b-col>
                <b-col v-if="!oneOff && !adHoc">
                  <strong class="mr-2">Frequency:</strong>
                  {{friendlyFrequency}}
                </b-col>
                <b-col v-if="false">
                  <strong class="mr-2">Frequency:</strong>
                  {{frequency}}
                </b-col>
                <b-col v-if="false">
                  <strong class="mr-2">Step:</strong>
                  {{currentPlan !== null ? (currentPlan.recurrenceEndType === 0 || currentPlan.recurrenceEndType === 4 ? 'N/A' : step) : 'N/A'}}
                </b-col>
              </b-row>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <p-check
                      class="p-switch p-fill"
                      color="primary"
                      v-model="createPayerMessages"
                    >Create Customer Messages</p-check>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <p-check
                      id="previewCheck"
                      class="p-switch p-fill"
                      color="primary"
                      v-model="removeQueued"
                      v-if="createPayerMessages"
                    >Remove Queued Messages on change</p-check>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <transition name="fade">
                      <div class="form-horizontal">
                        <b-button
                          @click="clearAdvanceNotices"
                          :disabled="isLoading"
                          variant="outline-danger"
                        >Remove Advance Notice Messages</b-button>
                      </div>
                    </transition>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <div class="form-horizontal">
                      <b-button @click="goToQueue" variant="outline-secondary">View Queued Messages</b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row form-group mb-3">
                <div class="col-md-3">
                  <div class="form-group">
                    <p-check
                      id="previewCheck"
                      class="p-switch p-fill"
                      color="primary"
                      v-model="useDateFilter"
                      @change="load()"
                    >Filter By Date</p-check>
                  </div>
                </div>
              </div>
              <div class="row form-group mb-3" v-if="useDateFilter">
                <div class="col-md-2 required">Earliest Collection Date</div>
                <div class="col-md-3">
                  <div class="right-inner-addon">
                    <datepicker
                      id="endPaymentDatePicker"
                      v-model="start"
                      :disabledDates="{ from: new Date(end) }"
                      :format="formatDate"
                      @input="load()"
                      input-class="form-control datepicker"
                    />
                    <i class="fa fa-calendar form-control-feedback"></i>
                  </div>
                </div>
                <div class="col-md-2 required">Latest Collection Date</div>
                <div class="col-md-3">
                  <div class="right-inner-addon">
                    <datepicker
                      id="startPaymentDatePicker"
                      v-model="end"
                      :format="formatDate"
                      :disabledDates="{ to: new Date(start) }"
                      @input="load()"
                      input-class="form-control datepicker"
                    />
                    <i class="fa fa-calendar form-control-feedback"></i>
                  </div>
                </div>
              </div>

              <b-row>
                <b-col md="12">
                  <vue-good-table
                    mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"
                    :columns="columns"
                    :rows="schedule"
                    :lineNumbers="true"
                    :totalRows="totalRecords"
                    :rowStyleClass="rowStyler"
                    :search-options="{
                    enabled: true
                    }"
                    :isLoading.sync="isTableLoading"
                    :paginationOptions="paginationOptions"
                    :sort-options="sortOptions"
                    @on-row-click="onRowClick"
                    styleClass="vgt-table striped bordered"
                    ref="table"
                  >
                    <template slot="loadingContent">
                      <h1>Loading...</h1>
                    </template>
                    <div slot="emptystate">
                      <div class="vgt-center-align vgt-text-disabled">No Schedule Available</div>
                    </div>
                    <div slot="table-actions">
                      <p-check
                        class="p-switch p-fill"
                        color="primary"
                        v-model="includeHistoric"
                        @change="load"
                      >Include Historic</p-check>
                      <button
                        @click.prevent="customClearTableFilters"
                        class="btn btn-link"
                        v-b-popover.hover.top.d500="'Clear filters'"
                      >
                        <span class="fa-stack" style="font-size: 10px;">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span>
                      </button>
                      <b-button
                        @click.prevent="load"
                        class
                        variant="link"
                        :disabled="isLoading"
                        v-b-popover.hover.top.d500="'Refresh the data in the table'"
                      >
                        <i class="fa fa-sync pointer dimmedIcon"></i>
                      </b-button>
                      <b-button v-if="false"
                        @click.prevent="printTable"
                        class
                        variant="link"
                        v-b-popover.hover.top.d500="'Print out the current view of the schedule'"
                      >
                        <i class="fa fa-print pointer dimmedIcon"></i>
                      </b-button>
                      <b-button
                        @click.prevent="exportTable"
                        class
                        variant="link"
                        :disabled="isLoading"
                        v-b-popover.hover.top.d500="'Export the schedule'"
                      >
                        <i class="fa fa-share-square pointer dimmedIcon"></i>
                      </b-button>
                      <b-button
                        @click.prevent="addPayment"
                        class
                        variant="link"
                        :disabled="isLoading"
                        v-if="allowInsert && !oneOff"
                        v-b-popover.hover.top.d500="'Add Collection'"
                      >
                        <i class="fa fa-plus pointer dimmedIcon"></i>
                      </b-button>
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <span
                        v-if="selectedRow && (props.row.paymentComposite !== '' && props.row.paymentComposite === selectedRow.paymentComposite) || (props.row.paymentComposite === '' && props.row.ukddmsPendingPaymentId == selectedRow.ukddmsPendingPaymentId)"
                      >
                         <span v-if="props.column.label === 'Status'">
                          <b-badge :variant="getStatusVariant(props.row)" pill>
                            <span v-html="props.formattedRow[props.column.field]"></span>
                          </b-badge>
                        </span>
                        <span v-if="props.column.label==='Statusx'">
                          <b-input-group>
                            <b-select
                              v-model="props.row.status"
                              @change="statusChanged(props.row, props)" :disabled="props.row.status !==19"
                            >
                              <option :value="9"  v-if="!tx01Sent">Pending First Submission</option>
                              <option :value="8">Pending Regular Submission</option>
                              <option :value="7">Pending Re Presentation</option>
                              <option :value="6">Pending Final Submission</option>
                              <option :value="19">Cancelled</option>
                              <option :value="10">Deleted</option>
                            </b-select>
                            <b-input-group-append v-if="allowDelete">
                              <b-button
                                variant="danger"
                                @click="deleteSchedule(props.row, props)"
                                :disabled="isLoading"
                                v-if="props.row.status ===19 && allowDelete"
                              >
                                <i class="fa fa-ban mr-1"></i>Delete
                              </b-button>
                              <b-button
                                variant="danger"
                                @click="undeleteSchedule(props.row, props)"
                                :disabled="isLoading"
                                v-if="props.row.status===10 && allowDelete"
                              >
                                <i class="fa fa-check mr-1"></i>Undelete
                              </b-button>
                              <b-button v-if="props.row.status !== 10 && props.row.status !== 19"
                                variant="danger"
                                @click="cancelCollection(props.row, props)"
                                :disabled="isLoading"
                              >
                                <i class="fa fa-ban mr-1"></i>Cancel
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </span>
                        <span v-if="props.column.field==='buttons'">
                          <b-button v-if="props.row.status !== 19"
                                    variant="danger"
                                    @click="cancelCollection(props.row, props)"
                                    :disabled="isLoading"
                          >
                                <i class="fa fa-ban mr-1"></i>Cancel
                              </b-button>
                          <b-button v-if="props.row.status === 19"
                                    variant="success"
                                    @click="undeleteSchedule(props.row, props)"
                                    :disabled="isLoading"
                          >
                                <i class="fa fa-check mr-1"></i>Restore
                              </b-button>
                        </span>
                        <span v-if="props.column.field==='amount'">
                          <span class="text-nowrap">
                            <i
                              v-if="props.row.pendingVariableAmount && props.row.amount === 0"
                              v-b-popover.hover.top.d500="'Variable Collection amount required!'"
                              class="fa fa-exclamation-circle text-warning mr-2"
                            ></i>
                            <currency-input
                              v-model="props.row.amount"
                              @input="amountChanged(props)"
                              currency="GBP"
                              locale="en"
                            />
                          </span>
                        </span>
                        <span v-if="props.column.field==='transactionCode'">
                          <b-select
                            type="text"
                            v-model="props.row.transactionCode"
                            @change="transactionCodeChanged(props.row, props)"
                          >
                            <option :value="'01'" v-if="!tx01Sent">01</option>
                            <option :value="'17'">17</option>
                            <option :value="'18'">18</option>
<!--                            <option :value="'19'">19</option>-->
                          </b-select>
                        </span>
                        <span v-if="props.column.label==='Collection Date'">
                          <datepicker
                            v-model="props.row.collectionDate"
                            @selected="collectionDateChanged"
                            :disabledDates="disabledDates"
                          />
                        </span>
                        <span
                          v-if="props.column.label==='Processing Date'"
                        >{{props.formattedRow[props.column.field]}}</span>
                        <span v-if="props.column.field === 'editMode'">
                          <input type="checkbox" v-model="props.row.editMode" />
                        </span>
                        <span
                          v-if="props.column.label==='Settlement Date'"
                        >{{props.formattedRow[props.column.field]}}</span>
                        <span
                          v-if="props.column.label==='Description'"
                        >{{props.formattedRow[props.column.field]}}</span>
                        <span
                          v-if="props.column.label==='Collection Identifier'"
                        >{{props.formattedRow[props.column.field]}}</span>
                      </span>
                      <span v-else>
                        <span v-if="props.column.field === 'amount'">
                          <i
                            v-if="props.row.pendingVariableAmount && props.row.amount === 0"
                            v-b-popover.hover.top.d500="'Variable Collection amount required!'"
                            class="fa fa-exclamation-circle text-danger"
                          ></i>
                          {{props.formattedRow[props.column.field]}}
                        </span>
                        <span v-else-if="props.column.label === 'Description'" class="align-middle">
                          <span v-html="props.formattedRow[props.column.field]"></span>
                          <i
                            v-if="props.row.status === 12 || props.row.status === 13"
                            v-b-popover.hover.top.d500="props.row.suspensionReason"
                            class="fa fa-question-circle ml-3"
                          ></i>
                          <i
                            v-if="props.row.collectionFailureReason !== null && props.row.collectionFailureReason !== undefined"
                            v-b-popover.hover.top.d500="props.row.collectionFailureReason"
                            class="fa fa-question-circle ml-3"
                          ></i>
                          <i
                            v-if="hasMessage(props.row.paymentComposite)"
                            @click.self="selectMessage(props.row.paymentComposite)"
                            v-b-popover.hover.top.d500="'Message'"
                            class="fa fa-envelope ml-3"
                          ></i>
                          <i
                            v-if="props.row.userAdded && props.row.uploadedFromFile"
                            v-b-popover.hover.top.d500="'Uploaded from file'"
                            class="fa fa-file-csv ml-3"
                          ></i>
                          <i
                            v-if="props.row.userAdded && !props.row.uploadedFromFile"
                            v-b-popover.hover.top.d500="'Added by User'"
                            class="fa fa-user ml-3"
                          ></i>
                          <i
                            v-if="!props.row.userAdded && !props.row.uploadedFromFile && props.row.paymentAmendmentId !== null && !props.row.isRepresentation"
                            v-b-popover.hover.top.d500="'Edited by User'"
                            class="fa fa-user ml-3"
                          ></i>
                          <i
                            v-if="props.row.hasIndemnity && !props.row.hasIndemnityCancellation"

                            @click.self.stop="goToIndemnity(props.row.indemnityId)"
                            v-b-popover.hover.top.d500="'Has Indemnity'"
                            class="fa fa-frown-open ml-3"
                          ></i>
                          <i
                            v-if="props.row.hasIndemnity && props.row.hasIndemnityCancellation"
                            @click.self.stop="goToIndemnity(props.row.indemnityCancellationId)"
                            v-b-popover.hover.top.d500="'Indemnity Cancelled'"
                            class="fa fa-smile-beam ml-3"
                          ></i>
                          <i
                            v-if="props.row.represented"
                            v-b-popover.hover.top.d500="'Represented'"
                            class="fa fa-arrow-down ml-3"
                            @click.self.stop="goToPayment(props.row.originalIdentifier)"
                          ></i>
                          <i
                            v-if="props.row.isRepresentation"
                            v-b-popover.hover.top.d500="'Is Represented'"
                            class="fa fa-arrow-up ml-3"
                            @click.self.stop="goToPayment(props.row.representedIdentifier)"
                          ></i>
                          <b-button class="pull-right"
                            variant="outline-danger"
                            @click.self.stop="rePresentPayment(props.row)"
                            v-if="props.row.canRepresent"
                            :disabled="isLoading"
                          >
                            <i class="fa fa-redo mr-1"></i>Re Present
                          </b-button>
                        </span>
                        <span v-else-if="props.column.label === 'Status'">
                          <b-badge :variant="getStatusVariant(props.row)" pill>
                            <span v-html="props.formattedRow[props.column.field]"></span>
                          </b-badge>
                        </span>
                        <span v-else>{{props.formattedRow[props.column.field]}}</span>
                      </span>
                    </template>
                  </vue-good-table>
                </b-col>
              </b-row>
            </div>
            <div class="idb-block-footer">
              <b-btn
                type="link"
                variant="primary"
                :disabled="isLoading"
                v-if="allowInsert"
                @click.prevent="saveSchedule"
              >
                <i class="fa fa-save mr-2" aria-hidden="true"></i>Save Changes
              </b-btn>
              <b-btn
                type="link"
                class="ml-2"
                variant="outline-primary"
                v-if="allowInsert && !oneOff"
                :disabled="isLoading"
                @click.prevent="addPayment"
              >
                <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Collection
              </b-btn>
              <b-btn
                type="link"
                class="float-right"
                variant="danger"
                :disabled="isLoading"
                v-if="allowInsert"
                @click.prevent="cancelSchedule"
              >
                <i class="fa fa-times mr-2"></i>Cancel Changes
              </b-btn>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- BEGIN VIEW MESSAGE -->
    <b-modal id="message-modal" ref="message-modal">
      <template v-slot:modal-title>
        <h4 class="modal-title">
          <i class="fa fa-envelope"></i>
          {{selectedMessage.messageTitle}}
        </h4>
      </template>
      {{selectedMessage.messageBody}}
      <template v-slot:modal-footer>
        <button type="button" class="btn btn-default" @click="$bvModal.hide('message-modal')">
          <i class="fa fa-times"></i> Close
        </button>
      </template>
    </b-modal>
    <!-- END VIEW MESSAGE -->
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import _ from 'lodash'
import uuidv4 from 'uuid/v4'
import papa from 'papaparse'
import axios from 'axios'
import PrettyCheck from 'pretty-checkbox-vue/check'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import rulesMixin from '@/Assets/Mixins/CollectionsRulesMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import roles from '@/Assets/Constants/roles'
import Store from "@/Store"
import {mapGetters} from "vuex"
export default {
  mixins: [tableFilterMixin, loading, rulesMixin],
  async created () {

  },
  components: {
    Datepicker,
    pCheck: PrettyCheck
  },
  props: {
    // payer ID
    id: String,
    // plan ID
    planId: String
  },
  data () {
    return {
      schedule: [],
      changes: [],
      columns: [
        {
          label: 'Description',
          field: this.statusDescription
        },
        {
          label: 'Collection Identifier',
          field: 'readableIdentifier'
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-center',
          thClass: 'text-center',
          formatFn: this.statusText
        },
        {
          label: 'Amount (£)',
          field: 'amount',
          formatFn: (value) => {
            return `${this.numberWithCommas((value / 1).toFixed(2))}`
          },
          tdClass: 'text-right'
        },
        {
          label: 'Submission Date',
          field: 'submissionDate',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }
        },
        {
          label: 'Collection Date',
          field: 'collectionDate',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }
        },
        {
          label: 'Settlement Date',
          field: 'settlementDate',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }
        },
        {
          label: '',
          field: 'buttons',
          hidden: true
        }
      ],
      serverParams: {
        sort: [{ field: 'collectiondate', type: 'asc' }]
      },
      selectedRow: null,
      statae: [
        { value: 9, text: 'Pending First Submission' },
        { value: 8, text: 'Pending Regular Submission' },
        { value: 7, text: 'Pending Re Presentation' },
        { value: 6, text: 'Pending Final Submission' },
        { value: 10, text: 'Deleted' },
        { value: 13, text: 'Will Not Attempt' },
        { value: 14, text: 'Unprocessed' }
      ],
      txCodes: ['01', '17', '18', '19'],
      groupConfig: null,
      messages: [],
      selectedMessage: {
        messageTitle: '',
        messageBody: ''
      },
      actioncomposite: '',
      createPayerMessages: true,
      removeQueued: false,
      includeHistoric: true,
      dateOptions: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        stepping: 30,
        sideBySide: true
      },
      end: new Date(),
      start: new Date(),
      useDateFilter: false,
      currentEndPoint: 'jftppayers',
      leadin: 0,
      leadout: 0,
      selectedSchedule: ''
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    hasPayerPortal () {
      return this.$store.getters.hasLicence('LicenceCollectionsPayerPortal')
    },
    firstPaymentEnabled () {
      return this.$store.getters.scheduleMetaData.firstPaymentTxTotal === 0
    },
    lastPaymentEnabled () {
      return this.$store.getters.scheduleMetaData.lastPaymentTxTotal === 0
    },
    actionColHidden: function () {
      let hidden = true
      for (var i = 0; i < this.schedule.length; i++) {
        if (this.schedule[i].editMode) {
          hidden = false
        }
      }
      console.log('hidden:' + hidden)
      return hidden
    },
    disabledDates: function () {
      let maxDate = new Date()
      let minDate = new Date()
      if (this.selectedRow) {
        const start = this.defaultCollectionDate
        // minDate = new Date(start.getTime())
        if (this.defaultCollectionDate.getHours() > 0) {
          this.defaultCollectionDate.setHours(0)
        }
        minDate = this.defaultCollectionDate
        maxDate = new Date(Date.parse(this.$store.getters.scheduleMetaData.lastPossiblePaymentDate))
        if (this.selectedRow.isNew) {
          minDate = this.defaultCollectionDate
          maxDate = new Date(Date.parse(this.$store.getters.scheduleMetaData.lastPossiblePaymentDate))
        }
      }
      return {
        days: [0, 6],
        // daysOfMonth: [29, 30, 31],
        dates: this.$store.getters.nonProcessingDates,
        to: minDate,
        from: maxDate
      }
    },
    allowInsert () {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard]) && !this.isClosed && this.$store.getters.selectedCustomerObject.paygateId !== process.env.VUE_APP_LANDZ_ID
    },
    allowAmendment () {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard]) && !this.isClosed && this.$store.getters.selectedCustomerObject.paygateId !== process.env.VUE_APP_LANDZ_ID
    },
    allowDelete () {
      return this.$store.getters.isInRoles([roles.SystemUser, roles.LZAdmin, roles.Implementations, roles.ClientAdmin, roles.Standard]) && !this.isClosed && this.$store.getters.selectedCustomerObject.paygateId !== process.env.VUE_APP_LANDZ_ID
    },
    advancedNoticeWithMedia () {
      return this.getAdvancedNoticeWithMedia(this.groupConfig.advanceNoticeInDays, this.groupConfig.defaultPayerMessageType)
    },
    defaultCollectionDate () {
      return this.groupConfig.defaultPayerMessageType === 1 ? this.$store.getters.collectionsMinDateLetter : this.$store.getters.collectionsMinDate
    },
    defaultProcessingDate () {
      var earliest = _.clone(this.defaultCollectionDate)
      earliest = new Date(earliest.setDate(earliest.getDate() - 1))
      if (earliest.getDay() === 6) {
        earliest = new Date(earliest.setDate(earliest.getDate() - 1))
      }
      if (earliest.getDay() === 0) {
        earliest = new Date(earliest.setDate(earliest.getDate() - 2))
      }
      return earliest
    },
    defaultSettlementDate () {
      var earliest = _.clone(this.defaultCollectionDate)
      earliest = new Date(earliest.setDate(earliest.getDate() + (this.leadin + this.leadout)))
      if (earliest.getDay() === 6) {
        earliest = new Date(earliest.setDate(earliest.getDate() + 2))
      }
      if (earliest.getDay() === 0) {
        earliest = new Date(earliest.setDate(earliest.getDate() + 1))
      }
      return earliest
    },
    payerName () {
      return this.$store.state.payer.currentPayer.bankAccountDetails.accountHoldersName
    },
    tx01Sent () {
      return this.$store.state.payer.currentPayer.tx01Sent
    },
    frequency () {
      var freq = ''
      if (this.currentPlan !== undefined && this.currentPlan !== null) {
        switch (this.currentPlan.recurrenceType) {
          case 0:
            freq = 'Weekly'
            break
          case 1:
            freq = 'Monthly'
            break
          case 2:
            freq = 'Yearly'
            break
        }
        if (this.currentPlan.recurrenceEndType && (this.currentPlan.recurrenceEndType === 0 || this.currentPlan.recurrenceEndType === 4)) {
          freq = 'N/A'
        }
      }
      return freq
    },
    friendlyFrequency () {
      var freq = ''
      console.log(this.currentPlan)
      if (this.currentPlan !== undefined && this.currentPlan !== null) {
        switch (this.currentPlan.friendlyRecurrenceType) {
          case 0:
            freq = 'Weekly'
            break
          case 1:
            freq = 'Fortnightly'
            break
          case 2:
            freq = 'Four Weekly'
            break
          case 3:
            freq = 'Monthly'
            break
          case 4:
            freq = 'Two Monthly'
            break
          case 5:
            freq = 'Quarterly'
            break
          case 6:
            freq = 'Six Monthly'
            break
          case 7:
            freq = 'Yearly'
            break
        }
        if (this.currentPlan.recurrenceEndType && (this.currentPlan.recurrenceEndType === 0 || this.currentPlan.recurrenceEndType === 4)) {
          freq = 'N/A'
        }
      }
      return freq
    },
    reference () {
      return this.currentPlan !== undefined && this.currentPlan !== null ? this.currentPlan.reference : ''
    },
    type () {
      var type = ''
      if (this.currentPlan !== undefined && this.currentPlan !== null) {
        switch (this.currentPlan.recurrenceEndType) {
          case 0:
            type = 'One Off'
            break
          case 1:
            type = 'Until Date'
            break
          case 2:
            type = `Until Number Of Collections ${this.totalPayments}`
            break
          case 3:
            type = 'Ongoing'
            break
          case 4:
            type = 'Ad Hoc'
            break
        }
      }
      return type
    },
    oneOff () {
      let oneOff = false
      if (this.currentPlan !== undefined && this.currentPlan !== null) {
        oneOff = this.currentPlan.recurrenceEndType === 0
      }
      return oneOff
    },
    adHoc () {
      let adHoc = false
      if (this.currentPlan !== undefined && this.currentPlan !== null) {
        adHoc = this.currentPlan.recurrenceEndType === 4
      }
      return adHoc
    },
    step () {
      return this.currentPlan !== undefined && this.currentPlan !== null ? this.currentPlan.step : 1
    },
    totalPayments () {
      return this.currentPlan !== undefined && this.currentPlan !== null ? this.currentPlan.totalPayments : 0
    },
    currentPlan () {
      var plan = null
      for (var i = 0; i < this.$store.state.payer.currentPayer.paymentPlans.length; i++) {
        if (this.$store.state.payer.currentPayer.paymentPlans[i].paymentPlanId === this.planId) {
          plan = this.$store.state.payer.currentPayer.paymentPlans[i]
        }
      }
      return plan
    },
    payer () {
      return this.$store.state.payer.currentPayer
    },
    payerStatus () {
      return this.payer.status
    },
    planStatus () {
      return this.isClosed ? 'Closed' : (this.clientIsInactive ? 'Suspended' : (this.currentPlan !== null && this.currentPlan !== undefined ? this.planStatusText(this.currentPlan.planStatus) : ''))
    },
    planTemplateName () {
      return this.currentPlan !== null && this.currentPlan !== undefined ? this.currentPlan.planTemplateName : 'N/A'
    },
    isClosed () {
      return this.payerStatus === 3 || (this.currentPlan !== null && this.currentPlan.planStatus === 0)
    },
    startDateOptions () {
      return { ...this.dateOptions, maxDate: this.end }
    },
    endDateOptions () {
      return { ...this.dateOptions, minDate: this.start }
    },
    clientIsInactive () {
      return this.$store.getters.selectedCustomerObject.status === 'Inactive'
    },
  },
  mounted: async function () {
    if (this.$store.getters.isInRoles([roles.LZAdmin, roles.Finance, roles.Implementations, roles.DevOps, roles.Developer, roles.Qa ])) {
      var pgResponse = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}helpers/getpgid/${this.id}`)
      this.$store.dispatch('setPaygateId', pgResponse.data)
    }
    await this.loadPayerAndConfig()
    this.end = new Date()
    this.end = this.end.setDate(this.end.getDate() + 7)
    this.migrationDate = new Date()
    let tempStart = new Date()
    tempStart = tempStart.setDate(tempStart.getDate() - 7)
    this.start = tempStart
    if (this.$route.query.actioncomposite) {
      this.actioncomposite = this.$route.query.actioncomposite
      var index = this.$store.getters.schedule.findIndex(x => x.paymentComposite === this.actioncomposite)
      while (index === -1) {
        this.paginationOptions.setCurrentPage++
        this.updateParams({ page: this.paginationOptions.setCurrentPage })
        await this.load()
        index = this.$store.getters.schedule.findIndex(x => x.paymentComposite === this.actioncomposite)
      }
    }
  },
  methods: {
    planStatusText (val) {
      var outText = ''
      switch (val) {
        case 0:
          outText = 'Closed'
          break
        case 1:
          outText = 'Alert'
          break
        case 2:
          outText = 'Suspended'
          break
        case 3:
          outText = 'Operational'
          break
      }
      return outText
    },
    async rePresentPayment (row) {
      try {
        let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}jftpreporting/represent`, [ row.ukddmsPendingPaymentId ])
        this.$toastr.s('Collections represented')
        await this.load()
      } catch (e) {
        this.$toastr.e('Could not represent collections.')
      }
    },
    async loadPayerAndConfig () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}${this.currentEndPoint}/${this.id}`)
        this.groupConfig = response.data.config
        this.$store.commit('setPayerFromUkPayer', response.data.payer)
        this.$store.commit('setConnectedMerchant', response.data.connectedMerchantName)
        this.$store.commit('setMerchant', response.data.merchantName)
        this.$store.dispatch('getAdvancedNoticeSettingsForPayer', { ukPayerId: this.id })
        this.allowedMessageTypes = response.data.optOuts
        this.leadout = response.data.leadOutDays
        this.leadin = response.data.leadInDays
        this.groupName = response.data.groupName
        try {
          this.$store.dispatch('getNonProcessingDates')
        } catch (er) {
          this.$toastr.e('Could not get processing dates from BACS API')
        }
        try {
          await this.getPayerMessages()
        } catch (err) {
          console.log('could not load customer messages.')
        }
      } catch (e) {
        this.$toastr.e('Could not load customer.')
      }
    },
    goToIndemnity (indemnityId) {
      const groupId = this.payer.groupId
      this.$router.push({ path: `/collections/indemnity/${groupId}/${indemnityId}` })
    },
    splitProcessingDate (rowObj) {
      // return rowObj.processingDate.split('T')[0]
      var date = Date.parse(rowObj.processingDate)
      return date
    },
    splitCollectionDate (rowObj) {
      // return rowObj.collectionDate.split('T')[0]
      return Date.parse(rowObj.collectionDate)
    },
    statusDescription (rowObj) {
      let desc = ''
      switch (rowObj.status) {
        case 9:
          desc = 'Pending First Submission'
          break
        case 8:
          desc = 'Pending Regular Submission'
          break
        case 7:
          desc = 'Pending Re Presentation'
          break
        case 6:
          desc = 'Pending Final Submission'
          break
        case 5:
          desc = 'Being Processed'
          break
        case 4:
          desc = 'Submitted'
          break
        case 3:
          desc = 'Failed'
          break
        case 2:
          desc = 'Error'
          break
        case 1:
          desc = 'Suspended'
          break
        case 0:
          desc = 'Closed'
          break
        case 10:
          desc = 'Deleted'
          break
        case 11:
          desc = '<i class="fas fa-share mr-2"></i>Refunded'
          break
        case 12:
          //desc = 'Not Attempted'
          //break
        case 13:
          desc = 'Will Not Attempt'
          break
        case 14:
          desc = 'Unsubmitted'
          break
        case 15:
          desc = 'Pending: Awaiting DDI Lodgement'
          break
        case 16:
          desc = 'Due'
          break
        case 17:
          desc = 'Successful'
          break
        case 18:
          desc = 'Unsubmitted'
          break
        case 19:
          desc = 'Cancelled'
          break
        default:
          desc = 'Value not set yet'
          break
      }
      if (rowObj.suppressed) {
        desc += ' (suppressed)'
      }
      return desc
    },
    statusText (val) {
      var outText = ''
      switch (val) {
        case 9:
        case 8:
        case 7:
        case 6:
        case 15:
          outText = 'Pending'
          break
        case 5:
          outText = 'Being Processed'
          break
        case 4:
          outText = 'Submitted'
          break
        case 3:
          outText = 'Failed'
          break
        case 2:
          outText = 'Error'
          break
        case 1:
          outText = 'Suspended'
          break
        case 0:
          outText = 'Closed'
          break
        case 10:
          outText = 'Deleted'
          break
        case 11:
          outText = 'Refunded'
          break
        case 12:
          //outText = 'Not Attempted'
          //break
        case 13:
          outText = 'Will Not Attempt'
          break
        case 14:
          outText = 'Unsubmitted'
          break
        case 16:
          outText = 'Due'
          break
        case 17:
          outText = 'Successful'
          break
        case 18:
          outText = 'Unsubmitted'
          break
        case 19:
          outText = 'Cancelled'
          break
        default:
          outText = 'Value not set yet'
          break
      }
      return outText
    },
    load: async function () {
      if (this.planId === '00000000-0000-0000-0000-000000000000') {
        this.$toastr.e('Invalid plan id specified.')
        this.$router.push({ path: '/collections/customer/manage' })
      } else {
        try {
          var response = await this.$store.dispatch('getCurrentPayerScheduleFromId', { id: this.id, planId: this.planId, serverParams: this.buildGoodTableQuery(), includeHistoric: this.includeHistoric, startDate: new Date(this.start), endDate: new Date(this.end), useDateFilter: this.useDateFilter })
          if (response) {
            this.schedule = this.$store.getters.schedule
            var meta = this.$store.getters.scheduleMetaData
            this.totalRecords = meta.totalItems
          }
        } catch (error) {
          this.$toastr.e(error)
        }
      }
    },
    async printTable () {
      this.enablePagination = false
      await this.load()
      window.print()
      this.enablePagination = true
    },
    async exportTable () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}schedule/${this.id}/${this.planId}/`, {
          params: {
            page: 1,
            perPage: this.totalRecords,
            columnFilters: this.serverParams.columnFilters,
            sort: this.serverParams.sort,
            searchKey: this.serverParams.searchKey,
            paygateid: this.serverParams.paygateId,
            exportMode: true, includeHistoric: this.includeHistoric
          },
          showload: true
        })
        if (response) {
          var csvString = papa.unparse(response.data.data)
          var blob = new Blob([csvString])
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, this.id + '-Customer-schedule.csv')
          } else {
            var a = window.document.createElement('a')
            a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
            a.download = this.reference + '-Customer-schedule.csv'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        }
      } catch {
        this.$toastr.e('Could not export schedule.')
      }
    },
    onRowClick (params) {
      if (params.row.collectionFailed && params.row.latestHistoryId !== undefined && params.row.latestHistoryId !== null) {
        this.$router.push({ path: `/collections/customer/${params.row.ukPayerId}/history/${params.row.latestHistoryId}` })
      }
      if (this.allowAmendment && !this.isClosed && params.row.status !== 7) {
        if (this.selectedRow) {
          if ((params.row !== this.selectedRow && ((params.row.status > 5 && params.row.status < 11) || params.row.status === 13 || params.row.status === 15 || params.row.status === 19 || params.row.status !== 7))) {
            this.schedule = this.$store.getters.schedule
            if (params.row.originalAmount === null) {
              params.row.originalAmount = params.row.amount
            }
            this.selectedRow = params.row
            this.selectedRow.editMode = true
          }
        } else {
          if ((params.row.status > 5 && params.row.status < 11) || params.row.status === 13 || params.row.status === 15 || params.row.status === 19) {
            this.selectedRow = params.row
            this.selectedRow.editMode = true
          }
        }
        this.$set(this.columns[7], 'hidden', false)
      }

      // this.$router.push({ name: 'EditPayer', params: { id: id } })
    },
    findUpTag (el, tag) {
      while (el.parentNode) {
        el = el.parentNode
        if (el.tagName === tag) {
          return el
        }
      }
      return null
    },
    async saveSchedule () {
      var success = await this.$store.dispatch('updateEntireSchedule', { schedule: this.schedule, createPayerMessages: this.createPayerMessages, removeQueued: this.removeQueued })
      if (!this.$store.getters.amendmentFailed) {
        this.$toastr.s('Schedule Updated')
        if (this.selectedRow !== null && this.selectedRow !== undefined) {
          this.selectedRow.pendingVariableAmount = false
        }
        this.selectedRow = null
        this.$set(this.columns[7], 'hidden', true)
        await this.load()
      } else {
        if (this.$store.state.schedule.badDate) {
          this.$toastr.e('That date falls within advance notice period, pick a later date.')
        } else if (this.$store.state.schedule.noViableAmendments) {
          if (this.$store.state.schedule.breaksUniqueCollectionRule) {
            this.$toastr.e('Could not update the schedule, specified Collection amount and date breaks unique Collection rule.')
          } else if (this.$store.state.schedule.thresholdExceeded) {
            this.$toastr.e('Could not update the schedule, customer Collection count per month exceeded.')
          } else {
            this.$toastr.e('Could not update the schedule, please ensure amounts and collection dates are correct and try again.')
          }
        } else if (this.$store.state.schedule.thresholdExceeded) {
          this.$toastr.e('Could not update the schedule, customer collection count per month exceeded.')
        } else if (this.$store.state.schedule.tooManyFirsts) {
          this.$toastr.e('Could not update the schedule, too many First Collections (transaction code 01) set. Only one is allowed.')
        } else if (this.$store.state.schedule.tooManyFinals) {
          this.$toastr.e('Could not update the schedule, too many Final Collections (transaction code 19) set. Only one is allowed.')
        } else if (this.$store.state.schedule.breaksUniqueCollectionRule) {
          this.$toastr.e('Could not update the schedule, specified Collection amount and date breaks unique collection rule.')
        } else if (this.$store.state.schedule.mustBeGreaterThanZero) {
          this.$toastr.e('Could not update the schedule, specified Collection amount must be greater than zero.')
        } else {
          this.$toastr.e('Schedule failed to update')
        }
      }
    },
    cancelSchedule () {
      this.selectedRow = null
      this.$set(this.columns[7], 'hidden', true)
      this.load()
    },
    async deleteSchedule (e, props) {
      e.status = 10
      this.selectedRow = e
      this.schedule[props.index] = e
      this.schedule[props.index].changed = true
    },
    goToQueue () {
      this.$router.push(`/collections/customer/${this.id}/edit?openAt=messagequeue`)
    },
    async clearAdvanceNotices () {
      try {
        var result = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}messages/advancenotices/${this.id}/${this.planId}`, { showload: true, params: { paygateId: this.paygateId } })
        if (result) {
          this.$toastr.s('Queued Advance Notice messages for this Schedule have been deleted.')
        }
      } catch {
        this.$toastr.e('Failed to remove Advance Notices.')
      }
    },
    async undeleteSchedule (e, props) {
      e.status = e.originalStatus
      switch (e.transactionCode) {
        case '01':
          e.status = 9
          break
        case '17':
          e.status = 8
          break
        case '18':
          e.status = 7
          break
        case '19':
          e.status = 6
          break
      }
      e.changed = true
      this.selectedRow = e
      this.schedule[props.index] = e
      this.schedule[props.index].changed = true
    },
    async cancelCollection (e, props) {
      e.status = 19
      this.selectedRow = e
      this.schedule[props.index] = e
      this.schedule[props.index].changed = true
    },
    async addPayment () {
      if (this.selectedRow !== null) {
        await this.saveSchedule()
      }
      var examplePayment
      if (this.schedule.length > 0) {
        examplePayment = _.clone(this.schedule[0], true)
        examplePayment.paymentAmendmentId = null
        examplePayment.ukddmsPendingPaymentId = uuidv4()
        examplePayment.originalDate = _.clone(this.defaultCollectionDate)
        examplePayment.collectionDate = _.clone(this.defaultCollectionDate)
        examplePayment.processingDate = _.clone(this.defaultProcessingDate)
        examplePayment.settlementDate = _.clone(this.defaultSettlementDate)
        examplePayment.originalAmount = this.schedule[0].amount
        examplePayment.collectionFailed = false
        examplePayment.latestHistoryId = null
      } else {
        examplePayment = {
          ukddmsPendingPaymentId: uuidv4(),
          ukPayerId: this.id,
          groupId: this.groupConfig.groupId,
          paymentPlanId: this.planId,
          paymentComposite: '',
          amount: 0,
          originalAmount: 0,
          originalDate: _.clone(this.defaultCollectionDate),
          collectionDate: _.clone(this.defaultCollectionDate),
          processingDate: _.clone(this.defaultProcessingDate),
          settlementDate: _.clone(this.defaultSettlementDate),
          suppressed: false,
          pendingVariableAmount: false,
          userAdded: true
        }
      }
      examplePayment.paymentComposite = ''
      examplePayment.isNew = true
      examplePayment.editMode = true
      examplePayment.status = this.schedule.length === 0 ? 9 :8
      examplePayment.transactionCode = this.schedule.length === 0 ? '01' : '17'
      this.schedule.unshift(_.clone(examplePayment))
      console.log(this.schedule)
      this.selectedRow = this.schedule[0]
    },
    collectionDateChanged (date) {
      if (this.selectedRow.isNew) {
        this.selectedRow.originalDate = new Date(date.getTime()).toISOString()
      }
      var dateOffset = (24 * 60 * 60 * 1000) * 1
      let collDate = new Date(date.getTime())
      // collDate = new Date(collDate.setTime(collDate.getTime() - dateOffset))
      // CORRECT DATE.
      let collDateValid = false
      while (!collDateValid) {
        if (collDate.getDay() === 6 || collDate.getDay() === 0 || (this.$store.getters.nonProcessingDates !== undefined && this.$store.getters.nonProcessingDates.includes(collDate))) {
          collDate = new Date(collDate.setTime(collDate.getTime())) //  - dateOffset
        } else {
          collDateValid = true
        }
      }
      var procDate = new Date(collDate.getTime())
      procDate = new Date(procDate.setDate(procDate.getDate() - 1))
      if (procDate.getDay() === 6) {
        procDate = new Date(procDate.setDate(procDate.getDate() - 1))
      }
      if (procDate.getDay() === 0) {
        procDate = new Date(procDate.setDate(procDate.getDate() - 2))
      }
      var settlementDate = new Date(collDate.getTime())
      let diff = this.leadin + this.leadout
      settlementDate = new Date(settlementDate.setDate(settlementDate.getDate() + diff))
      if (settlementDate.getDay() === 6) {
        settlementDate = new Date(settlementDate.setDate(settlementDate.getDate() + 2))
      }
      if (settlementDate.getDay() === 0) {
        settlementDate = new Date(settlementDate.setDate(settlementDate.getDate() + 1))
      }
      this.selectedRow.collectionDate = collDate.toISOString()
      this.selectedRow.settlementDate = settlementDate.toISOString()
      for (var i = 0; i < this.schedule.length; i++) {
        if ((this.schedule[i].paymentComposite !== '' && this.schedule[i].paymentComposite === this.selectedRow.paymentComposite) || (this.schedule[i].paymentComposite === '' && this.schedule[i].ukddmsPendingPaymentId === this.selectedRow.ukddmsPendingPaymentId)) {
          this.schedule[i].collectionDate = collDate.toISOString()
          this.schedule[i].processingDate = procDate.toISOString()
          this.schedule[i].settlementDate = settlementDate.toISOString()
          this.schedule[i].changed = true
        }
      }
    },
    amountChanged (props) {
      for (var i = 0; i < this.schedule.length; i++) {
        if (this.schedule[i].paymentComposite === this.selectedRow.paymentComposite) {
          this.schedule[i].amount = props.row.amount
          this.schedule[i].changed = true
        }
      }
    },
    rowStyler (row) {
      let statae = [16, 17, 18, 14, 12, 11, 0, 1, 2, 3, 4, 5, 7]
      var cssClass = ''
      if (row.status === 10 && (row !== this.selectedRow)) {
        cssClass = 'deleted'
      }
      if (row.suppressed && (row !== this.selectedRow)) {
        cssClass = 'suppressed'
      }
      if (row.paymentComposite === this.actioncomposite) {
        cssClass += ' messageSelected'
      }
      if (statae.includes(row.status) || !this.allowAmendment) {
        cssClass += ' notClickable'
      }
      return cssClass
    },
    transactionCodeChanged (e, props) {
      switch (e.transactionCode) {
        case '01':
          e.status = 9
          this.setOtherRowsToUndeleted(e)
          break
        case '17':
          e.status = 8
          this.setOtherRowsToUndeleted(e)
          break
        case '18':
          e.status = 7
          this.setOtherRowsToUndeleted(e)
          break
        case '19':
          e.status = 6
          this.setOtherRowsToDeleted(e)
          break
      }
      e.changed = true
      this.selectedRow = e
      this.schedule[props.index] = e
      this.schedule[props.index].changed = true
    },
    statusChanged (e, props) {
      switch (e.status) {
        case 9:
          e.transactionCode = '01'
          this.setOtherRowsToUndeleted(e)
          break
        case 8:
          e.transactionCode = '17'
          this.setOtherRowsToUndeleted(e)
          break
        case 7:
          e.transactionCode = '18'
          this.setOtherRowsToUndeleted(e)
          break
        case 6:
          e.transactionCode = '19'
          this.setOtherRowsToDeleted(e)
          break
      }
      e.changed = true
      this.selectedRow = e
      this.schedule[props.index] = e
      this.schedule[props.index].changed = true
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    setOtherRowsToDeleted (row) {
      for (var i = 0; i < this.schedule.length; i++) {
        if (new Date(this.schedule[i].processingDate) > new Date(row.processingDate)) {
          if (this.schedule[i].status !== 10) {
            this.schedule[i].originalStatus = this.schedule[i].status
          }
          this.schedule[i].status = 10
          this.schedule[i].changed = true
        }
      }
    },
    setOtherRowsToUndeleted (row) {
      for (var i = 0; i < this.schedule.length; i++) {
        if (this.schedule[i].originalStatus && this.schedule[i].originalStatus !== 10) {
          this.schedule[i].status = this.schedule[i].originalStatus
          this.schedule[i].changed = true
        }
      }
    },
    isInPast (row) {
      return new Date(row.processingDate) < new Date()
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    async getPayerMessages () {
      if (this.hasPayerPortal && parseInt(process.env.VUE_APP_VERSION) >= 120) {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}portalmessaging/customer/${this.id}`, {
          showload: true,
          params: { paygateId: this.paygateId }
        })
        if (response.data) {
          this.messages = response.data
        }
      }
    },
    hasMessage (paymentComposite) {
      return this.messages.some(msg => msg.paymentComposite === paymentComposite)
    },
    selectMessage (paymentComposite) {
      this.selectedMessage = this.messages.find((msg) => { return msg.paymentComposite === paymentComposite })
      this.$bvModal.show('message-modal')
    },
    amountFormatter (value) {
      return value.toFixed(2)
    },
    getStatusVariant (rowObj) {
      let variant = ''
      switch (rowObj.status) {
        case 4:
        case 11:
        case 16:
        case 17:
          variant = 'success'
          break
        case 2:
        case 3:
        case 10:
        case 19:
          variant = 'danger'
          break
        case 12:
          variant = 'outline-warning'
        case 13:
          variant = 'warning'
          break
        default:
          variant = 'info'
          break
      }
      return variant
    },
    customClearTableFilters () {
      this.includeHistoric = true
      this.clearTableFilters()
    },
    goToPayment(composite) {
      const table = this.$refs.table
      const searchTerm = composite
      table.globalSearchTerm = searchTerm
      table.$emit('on-search', {
        searchTerm: searchTerm
      })
    }
  },
  async beforeRouteLeave (to, from, next) {
    await this.$store.dispatch('clearSchedule')
    next()
  },
  watch: {
    selectedCustomer () {
      this.$router.push({path: '/collections/customer/manage'})
    },
  }
}
</script>
<style>
.deleted * {
  color: #cccccc;
}
.suppressed * {
  color: #cccccc;
}
.schedule-border-alert {
  border: solid 1px red;
}
/*.messageSelected td {*/
/*  background-color: #17a2b8 !important;*/
/*  color: #ffffff !important;*/
/*}*/
.dark-mode #firstPaymentDatePicker,
.dark-mode #endPaymentDatePicker,
.dark-mode .datepicker,
.dark-mode .datepicker:disabled {
  background-color: transparent !important;
}
.dark-mode .vdp-datepicker,
.dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important;
}
.dark-mode .vdp-datepicker__calendar * .next,
.dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important;
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
.notClickable {
  cursor: default !important;
}
</style>
