<template>
    <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
                <h2><help-icon docPath="/paygate-collections/import-export/importpayer/" />Template requires approval by bank</h2>
              </div>
              <div class="idb-block-content">
                <p><strong>Title:</strong><br/>{{templateName}}</p>
                <p><strong>Customer Name:</strong><br/>{{customerName}}</p>
                <p><strong>Last Modified:</strong><br/>{{lastModified !== '' ? formatDate(lastModified) : ''}}</p>
                <p><strong>Notes:</strong><br/>{{templateNotes}}</p>
                <p><strong>Channel:</strong><br/>{{formatChannel(channelType)}}</p>
                <p><strong>Status:</strong><br/>{{formatStatus(status)}}</p>
              </div>
              <div class="idb-block-footer">
                <b-button class="mr-2" @click="approveTemplate" v-if="status === 1" variant="success">
                  <i class="fas fa-check mr-2"></i>Approve
                </b-button>
                <b-button class="mr-2" @click="rejectTemplate" v-if="status === 1" variant="outline-danger">
                  <i class="fas fa-times mr-2"></i>Reject
                </b-button>
                <b-button variant="primary" @click.prevent="downloadTemplate" ><i class="fas fa-save mr-2"></i>Download Template</b-button>
                <b-button class="ml-2" variant="secondary" @click.prevent="editTemplate" ><i class="fas fa-edit mr-2"></i>Edit Template</b-button>
                <b-button class="ml-2" variant="danger" @click.prevent="removeAction" v-if="actionId && actionId !== null">Clear Action</b-button>
              </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import auth from '@/Assets/Components/Authentication/auth.js'
import swal from "sweetalert2";
export default {
  props: {
    templateId: String,
    type: String
  },
  data () {
    return {
      actionId: null,
      templateNotes: '',
      templateName: '',
      customerName: '',
      lastModified: '',
      channelType: 0,
      status: 0,
      record: null
    }
  },
  methods: {
    async approveTemplate () {
      try {
        this.record.bankApprovalStatus = 2
        let response = await axios.put(`${process.env.VUE_APP_DDMS_API_URL}templateapproval/${this.templateId}`, this.record, { params: { showLoad: true }})
        this.$toastr.s('Template Flagged as approved.')
        this.status = 2
        this.actionId = null
        await this.loadTemplateData()
      } catch (e) {
        this.$toastr.e('Failed to approve template')
      }
    },
    async rejectTemplate () {
      try {
        const result = await swal.fire({
          title: 'Reject Template',
          text: 'Please provide a rejection reason:',
          type: 'warning',
          input: 'textarea',
          animation: true,
          showCancelButton: true
        })
        if (result.isConfirmed) {
          this.record.bankApprovalStatus = 3
          this.record.notes = result.value
          //rowObject.notes = result.value
          let response = await axios.put(`${process.env.VUE_APP_DDMS_API_URL}templateapproval/${this.templateId}`, this.record, { params: { showLoad: true }})
          this.status = 3
          this.actionId = null
          this.$toastr.s('Template Flagged as rejected.')
        }
      } catch (e) {
        this.$toastr.e('Failed to reject template')
      }
      await this.loadTemplateData()
    },
    removeAction () {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}messages/clearaction/${this.actionId}`, { params: { showLoad: true }}).then(
      (response) => {
        this.$toastr.s('Action cleared')
        this.actionId = null
        this.$router.push('/actions')
      }
      )
    },
    formatChannel (channel) {
      return channel === 0 ? 'Email' : 'Letter'
    },
    formatStatus (status){
      let statusStr = ''
      switch (status) {
        case 0 :
          statusStr = 'Not Needed'
          break
        case 1 :
          statusStr = 'Required'
          break
        case 2 :
          statusStr = 'Approved'
          break
        case 3 :
          statusStr = 'Rejected'
          break
      }
      return statusStr
    },
    async loadTemplateData () {
      let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}templateapproval/${this.templateId}/details`, { showLoad: true })
      this.templateNotes = response.data.notes
      this.templateName = response.data.templateName
      this.channelType = response.data.channelType
      this.lastModified = response.data.lastModified
      this.customerName = response.data.customerName
      this.record = response.data
      this.status = response.data.bankApprovalStatus
    },
    async downloadTemplate() {
      let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}templateapproval/${this.templateId}/download`)
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(this.base64ImageToBlob(response.data))
      a.download = `${this.templateName}.pdf`
      // Append anchor to body.
      document.body.appendChild(a)
      a.click();
      // Remove anchor from body
      document.body.removeChild(a)
    },
    base64ImageToBlob (str) {
      // extract content type and base64 payload from original string
      var imageContent = atob(str)

      // create an ArrayBuffer and a view (as unsigned 8-bit)
      var buffer = new ArrayBuffer(imageContent.length)
      var view = new Uint8Array(buffer)

      // fill the view, using the decoded base64
      for(var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }

      // convert ArrayBuffer to Blob
      var blob = new Blob([buffer], { type: 'application/pdf' });
      return blob;
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    editTemplate () {
      switch (this.channelType) {
        case 0:
          this.$router.push('/collections/message/designer/email/'+this.templateId)
          break
        case 1:
          this.$router.push('/collections/message/designer/letter/'+this.templateId)
          break
        case 2:
          this.$router.push('/collections/message/designer/sms/'+this.templateId)
          break
        default:
          this.$toastr.e('Channel Not Defined')
          break
      }
    }
  },
  computed: {},
  async mounted () {
    this.actionId = this.$route.query.actionId
    this.bearerToken = await auth.getAccessToken()
    try {
      this.loadTemplateData()
    } catch (e) {
      this.$toastr.e('Unable to load template details.', 'An error occurred')
    }
  },
  async created () {
    try {
      this.loadTemplateData()
    } catch (e) {
      this.$toastr.e('Unable to load template details.', 'An error occurred')
    }
  }
}
</script>
