import Vue from 'vue'

Vue.filter('number', function (amt) {
  if (!amt) return ''
  var amountAsNumber = Number(amt)
  if (amountAsNumber < 0) {
    return amountAsNumber.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).substring(1)
  }
  return amountAsNumber.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
})
