<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Historic Charge Configurations For - {{effectiveDate | formatDate('DD/MM/YYYY HH:mm:ss')}}
          <favourite-icon />
        </h2>
      </div>
    </div>
    <charge-configuration-controls :billing="$v.billing" :errors="[]" :read-only="true"></charge-configuration-controls>
    <div class="idb-block">
      <div class="idb-block-footer">
        <button class="btn btn-primary" type="button" @click="back">Back</button>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'

// Mixins
import loading from '@/Assets/Mixins/LoadingMixin'

// Components
import ChargeConfigurationControls from '@/Components/Platform/Customer/Charges/Configuration/ChargeConfigurationControls'

export default {
  props: ['effectiveDate'],
  mixins: [loading],
  name: 'HistoricChargeSet',
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  components: {
    ChargeConfigurationControls
  },
  data () {
    return {
      billing: {
        // Periodic
        adminCharge: { value: 0 },
        bacsFileSubmissionCharge: { value: 0 },
        bacsFileSubmissionIncludedFiles: { value: 2 },
        osuCharge: { value: 0 },

        // Processing
        advanceNoticeLetter: { value: 0 },
        advanceNoticeEmail: { value: 0 },
        preCollectionNotification: { value: 0 },
        collections: {
          minimum: 0,
          maximum: 1,
          ranges: [{ amount: 0, rate: 0 }],
          type: 'SteppedRate'
        },
        confirmationLetter: { value: 0 },
        confirmationEmail: { value: 0 },
        failedCollections: { value: 0 },
        indemnityClaims: { value: 0 },
        payerCancellation: { value: 0 },
        payerReinstate: { value: 0 },
        payerSetup: { value: 0 },
        validateBankAccount: { value: 0 },
        validateIBAN: { value: 0 },
        banklineSTD1Or2: { value: 0 },
        banklineSTD0: { value: 0 },
        banklineUrgent: { value: 0 },
        ignoreProcessingChargeOnFailure: { value: true }

      }
    }
  },
  methods: {
    async load () {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}HistoricChargeSets/ForDate`,
          {
            showload: true,
            showerror: true,
            errormessage: 'Billing failed to load',
            params: { effectiveDate: this.effectiveDate }
          }
        )
        this.billing = response.data
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      } finally {
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    }
  },
  async mounted () {
    await this.load()
  },
  watch: {
    selectedCustomer () { this.$router.push({ name: 'HistoricChargeSetsList' }) }
  },
  validations () {
    return {
      billing: {
        // Periodic
        adminCharge: {
          value: {
          }
        },
        bacsFileSubmissionCharge: {
          value: {
          }
        },
        bacsFileSubmissionIncludedFiles: {
          value: {
          }
        },
        osuCharge: {
          value: {
          }
        },

        // Processing
        advanceNoticeLetter: {
          value: {
          }
        },
        advanceNoticeEmail: {
          value: {
          }
        },
        preCollectionNotification: {
          value: {
          }
        },
        collections: {
          minimum: {
          },
          maximum: {
          },
          ranges: {
            $each: {
              amount: {
              },
              rate: {
              }
            }
          },
          type: {}
        },
        confirmationLetter: {
          value: {
          }
        },
        confirmationEmail: {
          value: {
          }
        },
        failedCollections: {
          value: {
          }
        },
        indemnityClaims: {
          value: {
          }
        },
        payerCancellation: {
          value: {
          }
        },
        payerReinstate: {
          value: {
          }
        },
        payerSetup: {
          value: {
          }
        },
        validateBankAccount: {
          value: {
          }
        },
        validateIBAN: {
          value: {
          }
        },
        banklineSTD1Or2: {
          value: {
          }
        },
        banklineSTD0: {
          value: {
          }
        },
        banklineUrgent: {
          value: {
          }
        },
        ignoreProcessingChargeOnFailure: {
          value: {}
        }
      }
    }
  }
}
</script>

<style>
</style>
