<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          <help-icon docPath="/paygate-collections/hosted-forms/urls/" />Online Signup URLs
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group">
          <div class="col-md-3 required">Select a Group</div>
          <div class="col-md-4">
            <group-select v-model="group" :groups="customerGroups" :clearable="false"></group-select>
          </div>
        </div>
      </div>
      <div class="idb-block-footer noprint-footer">
        <b-button
          @click="generateOnboardingUrl"
          :disabled="!canCreateUrl"
          variant="primary"
        >Generate Online Signup URL</b-button>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Active URLs</h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :rows="rows"
          :lineNumbers="true"
          :totalRows="totalRecords"
          :search-options="{
              enabled: true
              }"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
          :columns="columns"
        >
          <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>
          <div slot="emptystate">
            <div class="vgt-center-align vgt-text-disabled">No data available</div>
          </div>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
            <b-button
              @click.prevent="exportTable"
              class
              variant="link"
              :disabled="isLoading"
              v-b-popover.hover.top.d500="'Export the contents of the table'"
            >
              <i class="fa fa-share-square pointer dimmedIcon"></i>
            </b-button>
            <b-button
              @click.prevent="load"
              :disabled="isLoading"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'buttons'">
              <b-button @click="deleteUrl(props.row.onboardingApiKeyId)" variant="danger">
                <i class="fa fa-trash mr-2"></i>Delete
              </b-button>
              <b-button @click="copyUrl(props.row.url)" variant="primary" class="ml-2">
                <i class="fa fa-copy mr-2"></i>Copy URL to clipboard
              </b-button>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <div class="idb-block" v-if="approvalFile === null">
      <div class="idb-block-title">
        <h2>Bank Approval Document</h2>
      </div>
      <div class="idb-block-content">
        <p>No Approval Document exists for this Client. Add an Online Signup URL to generate a file to send to the Bank.</p>
        <p>Online Signup will not be usable until the document has been approved.</p>
      </div>
    </div>
    <div
      v-if="approvalFile !== null && approvalFile.documentFailed"
      class="idb-block"
    >
      <div class="idb-block-title">
        <h2>Bank Approval Document</h2>
      </div>
      <div class="idb-block-content">
        <p>The document failed to generate. Please upload a new stylesheet and try again. If this continues, please contact an administrator.</p>
        <p>Online Signup will not be usable until the document has been approved.</p>
      </div>
    </div>
    <div
      v-if="approvalFile !== null && !approvalFile.documentFailed && approvalFile.approvalDocumentFileName === ''"
      class="idb-block"
    >
      <div class="idb-block-title">
        <h2>Bank Approval Document</h2>
      </div>
      <div class="idb-block-content">
        <p>Document is being generated. This may take a while, you may navigate away from this page.</p>
        <p>OSU will not be usable until the document has been approved.</p>
      </div>
    </div>
    <div
      v-if="approvalFile !== null && !approvalFile.documentFailed && approvalFile.approvalDocumentFileName !== ''"
      class="idb-block"
    >
      <div class="idb-block-title">
        <h2>Bank Approval Document</h2>
      </div>
      <div class="idb-block-content" v-if="!approvalFile.documentApproved">
        <p>The document below can be sent to the sponsoring bank provider for approval.</p>
        <p>Online Signup will not be usable until the document has been approved.</p>
      </div>
      <div class="idb-block-content" v-if="approvalFile.documentApproved">
        <p>
          The document below has been approved by the bank
          <span
            v-if="approvalFile.approvedDocument !== null && approvalFile.approvedDocument !== ''"
          >({{formatDate(approvalFile.approvalDate)}})</span>.
        </p>
      </div>
      <div class="idb-block-footer">
        <b-button
          variant="primary"
          class="pull-right mr-2"
          @click="approve"
          :disabled="approvalFile.documentApproved"
        >Mark As Approved</b-button>
        <b-button variant="success" class="mr-2" v-if="!approvalFile.documentApproved" @click="saveDocument">
          <i class="fas fa-file mr-2"></i>Download Approval Document
        </b-button>
        <b-button variant="warning" class="mr-2" @click="recreateDocument">
          <i class="fas fa-file mr-2"></i>Regenerate Document
        </b-button>
        <b-button
          variant="success"
          v-if="approvalFile.approvedDocument !== null && approvalFile.approvedDocument !== ''"
          @click="saveApprovedDocument"
        >
          <i class="fas fa-file mr-2"></i>Currently Approved Document
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import dropzoneLike from '@/Components/Shared/DropzoneLike.vue'
import EventBus from '@/Lib/eventBus'
import loading from '@/Assets/Mixins/LoadingMixin'
import { mapGetters } from 'vuex'
import moment from 'moment/moment'
import papa from 'papaparse'
import _ from 'lodash'
import app from '@/App.vue'
import App from '@/App.vue'
export default {
  mixins: [loading, tableFilterMixin],
  data () {
    return {
      updateStatusConnection: null,
      updateHubUrl: '',
      bearerToken: null,
      group: null,
      rows: [],
      columns: [
        {
          label: 'onboardingapikeyid',
          field: 'onboardingApiKeyId',
          hidden: true
        },
        {
          label: 'api key',
          field: 'apiKey',
          hidden: true
        },
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: 'Token',
          field: 'urlToken'
        },
        {
          label: 'URL',
          field: 'url'
        },
        {
          label: 'Last Visited',
          field: 'lastVisited',
          formatFn: this.formatDate
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
          tdClass: 'text-center'
        }
      ],
      serverParams: {
        sort: [{ field: 'groupName', type: 'asc' }]
      },
      approvalFile: {
        approvedDocument: null
      },
      fileGenerated: false,
      documentApproved: false,
      documentFileName: '',
      fileFailed: false
    }
  },
  computed: {
    App () {
      return App
    },
    ...mapGetters(['selectedCustomer']),
    customerGroups () {
      var ret = []
      if (this.$store.getters.rightsGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.rightsGroups).map(x => {
          const obj = {}
          obj.groupId = x.groupId
          obj.name = x.name
          obj.colour = x.colour
          return obj
        })
      }

      if (ret.length > 1) {
        ret.unshift({ description: '', groupId: '00000000-0000-0000-0000-000000000000', groupType: 'UK DDMS', name: 'Select a Group', paygateId: this.paygateId, isItemActioned: false, clonedName: null, colour: null })
      }
      if (ret.length === 0) {
        ret.unshift({ description: '', groupId: '00000000-0000-0000-0000-000000000000', groupType: 'UK DDMS', name: 'No Collections Groups Available', paygateId: this.paygateId, isItemActioned: false, clonedName: null, colour: null })
      }
      return ret
    },
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    canCreateUrl () {
      return this.group !== '00000000-0000-0000-0000-000000000000'
    }
  },
  async created () {
    this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
    this.updateHubUrl = process.env.VUE_APP_DDMS_API_URL + 'hubs/onboardingdocument'
    window.addEventListener('beforeunload', this.stopSocketListener)
    const paygateId = this.paygateId
    this.updateHubUrl += '?paygateId=' + this.paygateId
  },
  async mounted () {
    if (this.$store.getters.selectedCustomer === process.env.VUE_APP_LANDZ_ID) {
      this.$router.push('/')
    }
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        // this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
        this.updateStatusConnection = new HubConnectionBuilder().withUrl(this.updateHubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.updateStatusConnection.serverTimeoutInMilliseconds = 3600000
        this.updateStatusConnection.start()
        this.updateStatusConnection.on('DocumentReady', async (data) => {
          this.$toastr.Add({
            name: 'uploadComplete',
            msg: 'Approval Document successfully uploaded.',
            preventDuplicates: true,
            type: 'success'
          })
          await this.load()
        })
        this.updateStatusConnection.on('DocumentFailed', async (data) => {
          this.$toastr.Add({
            name: 'uploadfailed',
            msg: 'Approval Document failed to generate.',
            preventDuplicates: true,
            type: 'danger'
          })
          await this.load()
        })
      })
    await this.populateGroups()
    await this.load()
  },
  methods: {
    stopSocketListener () {
      this.updateStatusConnection.stop()
    },
    async populateGroups () {
      this.group = '00000000-0000-0000-0000-000000000000'
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
      if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
        this.selectedGroup = this.customerGroups.find(i => i.groupId === defaultGroupId)
        // Default group might be a FPS group which means it can't be the BACS default group and vice versa.
        if (this.selectedGroup !== undefined) {
          this.group = defaultGroupId
        }
      }
    },
    async load () {
      var query = this.buildGoodTableQuery()
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}onboardingurl/planless/`, { showload: true, params: { paygateId: this.paygateId, ...query } })
      if (response) {
        this.totalRecords = response.data.count
        this.rows = response.data.data
      }
      var approvalResponse = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}onboardingurl/isapproved`, { showload: true, params: { paygateId: this.paygateId } })
      if (approvalResponse) {
        this.approvalFile = approvalResponse.data
      } else {
        this.$toastr.e('Could not load Approval Document information')
      }
    },
    async approve () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/approvaldocument`, { approved: true }, { showload: true, params: { paygateId: this.paygateId } })
      this.approvalFile.documentApproved = true
      this.$toastr.s('Document Approved.')
    },
    async saveDocument () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/approvaldocument`, { showload: true, params: { paygateId: this.paygateId } })
      var blob = this.base64ImageToBlob(response.data)
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, `${this.$store.getters.selectedCustomerObject.name}-approvaldocument.docx`)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
        a.download = `${this.$store.getters.selectedCustomerObject.name}-approvaldocument.docx`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    async saveApprovedDocument () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/approveddocument`, { showload: true, params: { paygateId: this.paygateId } })
      var blob = this.base64ImageToBlob(response.data)
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, `${this.$store.getters.selectedCustomerObject.name}-approveddocument.docx`)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
        a.download = `${this.$store.getters.selectedCustomerObject.name}-approveddocument.docx`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    base64ImageToBlob (str) {
      // extract content type and base64 payload from original string
      var imageContent = atob(str)

      // create an ArrayBuffer and a view (as unsigned 8-bit)
      var buffer = new ArrayBuffer(imageContent.length)
      var view = new Uint8Array(buffer)

      // fill the view, using the decoded base64
      for (var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }

      // convert ArrayBuffer to Blob
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessing' })
      return blob
    },
    async deleteUrl (id) {
      try {
        var response = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}onboardingurl/planless/${id}`, { showload: true, params: { paygateId: this.paygateId } })
        if (response) {
          this.$toastr.s('URL deleted.')
          await this.load()
        }
      } catch (e) {
        this.$toastr.s('Failed to delete URL.')
      }
    },
    copyUrl (url) {
      navigator.clipboard.writeText(url)
      this.$toastr.s('The Url has been copied', 'URL copied to the clipboard')
    },
    async generateOnboardingUrl () {
      try {
        await axios.post(`${process.env.VUE_APP_DDMS_API_URL}onboardingurl/planless/`, { groupId: this.group }, {
          showload: true,
          params: { paygateId: this.paygateId }
        })
        this.$toastr.s('Onboarding URL successfully generated.')
        await this.load()
      } catch (e) {
        this.$toastr.e('Could not generate Onboarding URL.')
      }
    },
    async recreateDocument () {
      try {
        await axios.post(`${process.env.VUE_APP_DDMS_API_URL}onboardingurl/recreatedocument`, {}, { showload: true })
        this.$toastr.s('Generating document')
        await this.load()
      } catch (e) {
        this.$toastr.e('Failed to trigger document generation')
      }
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    async exportTable () {
      console.log('EXPORTING')
      var query = this.buildGoodTableQuery()
      query.perPage = this.totalRecords
      console.log('EXPORTING')
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}onboardingurl/planless/`, {
        params: { ...query, paygateId: this.paygateId, forExport: true },
        showload: true
      })
      console.log('EXPORTING')
      var csvString = papa.unparse(response.data)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'onboardingURLs.csv')
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = 'onboardingURLs.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    }
  },
  watch: {
    async selectedCustomer () {
      if (this.$store.getters.selectedCustomer === process.env.VUE_APP_LANDZ_ID) {
        this.$router.push('/')
      }
      await this.populateGroups()
      await this.load()
    }
  }
}
</script>
