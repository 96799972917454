<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Create Test Data</h2>
      </div>
      <div class="idb-block-content">
        <div class="form-row">
          <div class="form-group col-md-3" :class="{invalid: $v.clientAmount.$error}">
            <label for="clientAmount" class="required">Client Amount</label>
            <input
              id="clientAmount"
              class="form-control"
              type="number"
              v-model.number="$v.clientAmount.$model"
            />
            <validation-messages v-model="$v.clientAmount" name="client amount"></validation-messages>
          </div>
          <div class="form-group col-md-3" :class="{invalid: $v.customersPerClient.$error}">
            <label for="customersPerClient" class="required">Customers Per Client</label>
            <input
              id="customersPerClient"
              class="form-control"
              type="number"
              v-model.number="$v.customersPerClient.$model"
            />
            <validation-messages v-model="$v.customersPerClient" name="customers per client"></validation-messages>
          </div>
          <div class="form-group col-md-3" :class="{invalid: $v.collectionsPerCustomer.$error}">
            <label for="collectionsPerCustomer" class="required">Collections Per Customer</label>
            <input
              id="collectionsPerCustomer"
              class="form-control"
              type="number"
              v-model.number="$v.collectionsPerCustomer.$model"
            />
            <validation-messages
              v-model="$v.collectionsPerCustomer"
              name="collections per customer"
            ></validation-messages>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <button
          class="btn btn-success"
          @click="checkValidation() && createTestData()"
          type="button"
          :disabled=" isLoading"
        >
          <i class="fas fa-plus"></i> Create Test Data
        </button>
        <br />
        <small class="help-text">This process can take a long time</small>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Test Data</h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
            </button>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer" v-if="totalRecords > 0">
        <button class="btn btn-danger" @click="deleteTestData" type="button" :disabled=" isLoading">
          <i class="fas fa-trash"></i> Delete Test Data
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'

// Validations
import { required, minValue } from 'vuelidate/lib/validators'

// Mixins
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  name: 'CreateTestData',
  mixins: [tableFilterMixin, loading],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Customer Name',
          field: 'name',
          filterOptions: {
            enabled: true
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'name', type: 'desc' }]
      },
      clientAmount: 0,
      customersPerClient: 0,
      collectionsPerCustomer: 0
    }
  },
  methods: {
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}testdata`,
          {
            params: { ...this.buildGoodTableQuery() },
            showerror: true,
            errormessage: 'Failed loading test data'
          })
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    async deleteTestData () {
      try {
        await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}testdata`, {
          showload: true,
          showerror: true,
          errormessage: 'Failed deleting test data'
        })
        this.load()
      } catch { }
    },
    async createTestData () {
      try {
        await axios.post(`${process.env.VUE_APP_DDMS_API_URL}testdata`, {
          clientAmount: this.clientAmount,
          customersPerClient: this.customersPerClient,
          collectionsPerCustomer: this.collectionsPerCustomer
        }, {
          showload: true, showerror: true, errormessage: 'Failed creating test data'
        })
      } catch { }
    }
  },
  mounted () {
    this.load()
  },
  validations () {
    return {
      clientAmount: {
        required,
        minValue: minValue(1)
      },
      customersPerClient: {
        required,
        minValue: minValue(1)
      },
      collectionsPerCustomer: {
        required,
        minValue: minValue(1)
      }
    }
  }
}

</script>
