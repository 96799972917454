<template>
    <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
                <h2><help-icon docPath="/paygate-collections/import-export/importpayer/" />Messages failed to deliver</h2>
              </div>
              <div class="idb-block-content">
                <p>There was a problem with the messaging process that resulted in some messages failing to be sent.</p>
                <vue-good-table
                  mode="remote"
                  ref="table"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  :rows="rows"
                  :lineNumbers="true"
                  :totalRows="totalRecords"
                  :search-options="{
                    enabled: true
                  }"
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :isLoading.sync="isTableLoading"
                  styleClass="vgt-table striped bordered"
                  :columns="columns"
                >
                  <template slot="loadingContent">
                    <h1>Loading...</h1>
                  </template>
                  <div slot="emptystate">
                    <div class="vgt-center-align vgt-text-disabled">No data available</div>
                  </div>
                  <div slot="table-actions">
                    <button
                      @click.prevent="clearTableFilters"
                      class="btn btn-link"
                      v-b-popover.hover.top.d500="'Clear filters'"
                    >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
                    </button>
                    <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
                      <i class="fa fa-share-square pointer dimmedIcon"></i>
                    </b-button>
                    <b-button
                      @click.prevent="load" :disabled="isLoading"
                      class
                      variant="link"
                      v-b-popover.hover.top.d500="'Refresh the data in the table'"
                    >
                      <i class="fa fa-sync pointer dimmedIcon"></i>
                    </b-button>
                  </div>
                </vue-good-table>
              </div>
              <div class="idb-block-footer">
                <b-button class="ml-2" variant="danger" @click.prevent="removeAction" v-if="actionId && actionId !== null">Clear Action</b-button>
              </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import auth from '@/Assets/Components/Authentication/auth.js'
import {mapGetters} from "vuex"
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import moment from "moment/moment";
import papa from "papaparse";
export default {
  mixins: [tableFilterMixin, loading],
  props: {
    messageId: String,
    type: String
  },
  data () {
    return {
      actionId: null,
      sent: false,
      messageLoaded: false,
      rows: [],
      serverParams: {
        sort: [{ field: 'creationdate', type: 'asc' }]
      },
      columns: [{
        field: 'payerReference',
        label: 'Customer Reference'
      },
        {
          field: 'planReference',
          label: 'Schedule Reference'
        },
        {
          field: 'messageType',
          label: 'Message Type',
          formatFn: (x) => {
            switch (x) {
              case 20:
                return 'Pre-Collection Notification'
              case 19:
                return 'Confirmation Letter'
              case 18:
                return 'Notice of Change (Migration)'
              case 17:
                return 'Refund Cancelled'
              case 16:
                return 'Notice of Change'
              case 15:
                return 'Customer Transferred'
              case 14:
                return 'Customer Closed'
              case 13:
                return 'Custom'
              case 12:
                return 'Cancellation Notice'
              case 11:
                return 'Refund Requested'
              case 10:
                return 'Portal Email Verification'
              case 9:
                return 'Portal Message Sent'
              case 8:
                return 'Email Verification Message'
              case 7:
                return 'Custom Message 5'
              case 6:
                return 'Custom Message 4'
              case 5:
                return 'Custom Message 3'
              case 4:
                return 'Custom Message 2'
              case 3:
                return 'Custom Message 1'
              case 2:
                return 'Multiple Schedule Update'
              case 1:
                return 'First Collections'
              case 0:
                return 'Advance Notice'
              default:
                return 'Unknown'
            }
          }
        },
        {
          field: 'channelType',
          label: 'Type',
          formatFn: (x) => {
            switch (x) {
              case 1:
              default:
                return 'Letter'
              case 0:
                return 'Email'
              case 2:
                return 'SMS'
            }
          }
        },
        {
          field: 'errorMessage',
          label: 'Error',
          tdClass: 'text-center',
          formatFn: (x) => {
            switch (x) {
              case 0:
                return 'Error'
              case 1:
                return 'Sent'
              case 2:
                return 'Queued'
              case 3:
                return 'Bounced'
              case 4:
                return 'Recalled'
            }
          }
        },
        {
          field: 'creationDate',
          label: 'Date',
          formatFn: this.formatDate
        },
        {
          field: 'ukPayerId',
          sortable: false,
          hidden: true
        },
        {
          field: 'paymentPlanId',
          sortable: false,
          hidden: true
        },
        {
          field: 'payerMessageId',
          sortable: false,
          hidden: true
        }]
    }
  },
  methods: {
    removeAction () {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}messages/clearaction/${this.actionId}`, { params: { paygateId: this.paygateId } }).then(
      (response) => {
        this.$toastr.s('Action cleared')
        this.actionId = null
        this.$router.push('/actions')
      }
      )
    },
    resendMessage () {
      axios.post(`${process.env.VUE_APP_DDMS_API_URL}messages/resend/${this.messageId}`, {params: { paygateId: this.paygateId }}).then(
      (response) => {
        this.$toastr.s('Message Sent')
        this.sent = true
      }
      )
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    load: async function () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}failedmessages/`, { params: { ...this.buildGoodTableQuery() }, validateStatus: () => true })
        if (response.status === 200) {
          this.rows = response.data.data
          this.totalRecords = response.data.count
        } else {
          if (response.status === 403) {
            this.$toastr.e('Not authorized to use this feature.')
            this.rows = []
            this.totalRecords = 0
          } else {
            this.$toastr.e('An error has occurred.')
          }
        }
      } catch (error) {
        this.$toastr.e(error)
      }
    },
    onRowChange (e) {
      // console.log(e)
      // this.$router.push({ path: `/collections/payer/${e.row.ukPayerId}/edit`, params: { openAt: 'messages'} })
    },
    formatDateWithNull (date) {
      if (date===null || date === '0001-01-01T00:00:00') {
        return 'N/A'
      } else {
        return this.formatDate(date)
      }
    },
    async exportTable () {
      var query = this.buildGoodTableQuery()
      query.perPage = this.totalRecords
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}failedmessages/`, { params: {
          ...query, paygateId: this.paygateId, forExport: true }, showload: true })
      var csvString = papa.unparse(response.data)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, `failed-messages.csv`)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = `failed-messages.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    formatAmount (value) {
      return '£' + this.numberWithCommas((value / 1).toFixed(2))
    },
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
  },
  async mounted () {
    this.messageLoaded = false
    this.actionId = this.$route.query.actionId
    this.bearerToken = await auth.getAccessToken()
  },
  watch: {
    selectedCustomer () { this.load() }
  }
}
</script>
