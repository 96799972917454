<template>
  <div>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Report Subscriptions
              <span class="pull-right">
                <a :href="helpUrl" target="_blank">
                  <i class="far fa-question-circle d-none"></i>
                </a>
              </span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-12"></div>
            </div>
            <div v-if="subscribedUsers !== undefined && subscribedUsers.length > 0" class="row form-group">
              <div class="col-md-12">
                <div class="alert alert-danger" v-if="!isValid">
                  <ul>
                    <div v-for="user in subscribedUserErrors" :key="user.userId">
                      <li>{{user.userName}} must be subscribed to at least 1 report</li>
                    </div>
                  </ul>
                </div>
                <table class="table table-header-rotated">
                  <thead>
                    <tr>
                      <!-- Users Name -->
                      <th style="width:300px;"></th>
                      <th class="rotate" style="width:80px;">
                        <div>
                          <span>Submision Summary Report</span>
                        </div>
                      </th>
                      <th class="rotate" style="width:80px;">
                        <div>
                          <span>Collections Report</span>
                        </div>
                      </th>
                      <th class="rotate" style="width:80px;">
                        <div>
                          <span>Failed Collections Report</span>
                        </div>
                      </th>
                      <th class="rotate" style="width:80px;">
                        <div>
                          <span>Collection Overview Report</span>
                        </div>
                      </th>
                      <th class="rotate" style="width:80px;">
                        <div>
                          <span>Collection Schedule Report</span>
                        </div>
                      </th>
                      <th class="rotate" style="width:80px;">
                        <div>
                          <span>Settlements Report</span>
                        </div>
                      </th>
                      <th class="rotate" style="width:80px;">
                        <div>
                          <span>Invoicing Report</span>
                        </div>
                      </th>
                      <th style="width:80px;"></th>
                      <!-- Delete link -->
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in subscribedUsers" :key="user.userId">
                      <td class="row-header" style="width:300px;">{{ user.userName }}</td>
                      <td>
                        <p-check class="p-default p-curve" v-model="user.submissionSummaryReport" :disabled="disabled" @change="reportChanged()"></p-check>
                      </td>
                      <td>
                        <p-check class="p-default p-curve" v-model="user.collectionsReport" :disabled="disabled" @change="reportChanged()"></p-check>
                      </td>
                      <td>
                        <p-check class="p-default p-curve" v-model="user.failedCollectionsReport" :disabled="disabled" @change="reportChanged()"></p-check>
                      </td>
                      <td>
                        <p-check class="p-default p-curve" v-model="user.collectionOverviewReport" :disabled="disabled" @change="reportChanged()"></p-check>
                      </td>
                      <td>
                        <p-check class="p-default p-curve" v-model="user.collectionScheduleReport" :disabled="disabled" @change="reportChanged()"></p-check>
                      </td>
                      <td>
                        <p-check class="p-default p-curve" v-model="user.settlementsReport" :disabled="disabled" @change="reportChanged()"></p-check>
                      </td>
                      <td>
                        <p-check class="p-default p-curve" v-model="user.invoicingReport" :disabled="disabled" @change="reportChanged()"></p-check>
                      </td>
                      <td style="width:20px;">
                        <a
                          class="fa fa-trash"
                          role="button"
                          @click="removeUser(user.userId)"
                          :disabled="disabled"
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2"><strong>Unsubscribed Users</strong></div>
              <div class="col-md-6">
                <b-form inline>
                  <b-form-select class="col-md-5" v-model="selectedUnsubscribedUser" :options="unsubscribedUsers" @change="selectUser()"></b-form-select>
                  <b-button variant="primary" style="margin-left: 10px;" :disabled="disabled" @click="addUser" >Add</b-button>
                </b-form>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <div class="row form-group">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="save"
                >Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { requiredIf } from 'vuelidate/lib/validators'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'

export default {
  mixins: [DataLeaveMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },

  data () {
    return {
      unsubscribedUsers: [],
      subscribedUsers: [],
      // subscriptions: {
      //   subscribedUsers: []
      // },
      // subscribedUsers: [
      //   {
      //     userId: {},
      //     userName: '',
      //     paygateId: {},
      //     submissionSummaryReport: false,
      //     collectionsReport: false,
      //     failedCollectionsReport: false,
      //     collectionOverviewReport: false,
      //     collectionScheduleReport: false,
      //     settlementsReport: false,
      //     invoicingReport: false
      //   }
      // ],
      selectedUnsubscribedUser: {},
      subscribedUserErrors: [],
      helpUrl: '',
      disabled: false,
      selectedUserId: '00000000-0000-0000-0000-000000000000',
      isValid: true
    }
  },

  methods: {
    async getSubscribedUsers () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Subscription`)
      this.subscribedUsers = response.data
    },

    async populateUserCombo () {
      var response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'users/userSelect', { showload: true })
      var tmpUser = {}
      for (var i = 0; i < response.data.length; i++) {
        tmpUser = {}
        tmpUser.value = response.data[i].id
        tmpUser.text = response.data[i].label
        var subUser = this.subscribedUsers.find(i => i.userId === tmpUser.value)
        if (subUser === undefined) {
          this.unsubscribedUsers.push(tmpUser)
        }
      }

      this.unsubscribedUsers.sort((a, b) => a.text.localeCompare(b.text))
    },

    selectUser () {
    },

    addUser () {
      var selectedUser = this.unsubscribedUsers.find(i => i.value === this.selectedUnsubscribedUser)
      if (selectedUser !== undefined) {
        var userToAdd = {
          userId: selectedUser.value,
          userName: selectedUser.text,
          paygateId: this.selectedCustomer,
          submissionSummaryReport: false,
          collectionsReport: false,
          failedCollectionsReport: false,
          collectionOverviewReport: false,
          collectionScheduleReport: false,
          settlementsReport: false,
          invoicingReport: false
        }
        this.$v.$touch()
        this.subscribedUsers.push(userToAdd)
        this.subscribedUsers.sort((a, b) => a.userName.localeCompare(b.userName))

        // Remove user from unsubsribed users dropdown
        var i = -1
        this.unsubscribedUsers.forEach(user => {
          i++
          if (user.value === this.selectedUnsubscribedUser) {
            this.unsubscribedUsers.splice(i, 1)
            if (this.unsubscribedUsers.length > 0) {
              this.selectedUnsubscribedUser = this.unsubscribedUsers[0].value
            } else {
              this.selectedUnsubscribedUser = null
            }
          }
        })
      }
    },

    removeUser (userId) {
      var i = -1
      this.subscribedUsers.forEach(user => {
        i++
        if (user.userId === userId) {
          // Add user to unsubscribed dropdown array and sort
          this.unsubscribedUsers.push({ value: user.userId, text: user.userName })
          this.unsubscribedUsers.sort((a, b) => a.text.localeCompare(b.text))
          if (this.unsubscribedUsers.length === 1) {
            this.selectedUnsubscribedUser = this.unsubscribedUsers[0].value
          }
          // Remove user from subscribed users
          this.subscribedUsers.splice(i, 1)
          this.$v.$touch()
        }
      })
    },

    async save () {
      try {
        this.validateSubscribedUsers()
        if (this.isValid) {
          var response = await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_PLATFORM_API_URL}Subscription`,
            data: this.subscribedUsers,
            showload: true
          })
          if (response.data.status !== 'Failed') {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$snapbar.e(response.data.toastMessage)
          }

          this.$nextTick(() => {
            this.$v.$reset()
          })
        }
      } catch (err) {
        console.log(err)
      }
    },

    reportChanged () {
      this.validateSubscribedUsers()
      this.$v.$touch()
    },

    validateSubscribedUsers () {
      this.isValid = true
      this.subscribedUserErrors = []
      this.subscribedUsers.forEach(user => {
        const isValid = user.submissionSummaryReport || user.collectionsReport || user.failedCollectionsReport || user.collectionOverviewReport ||
          user.collectionScheduleReport || user.settlementsReport || user.invoicingReport
        if (!isValid) {
          var errorUuserToAdd = {
            userId: user.userId,
            userName: user.userName
          }

          this.subscribedUserErrors.push(errorUuserToAdd)
          this.isValid = false
        }
      })
    }
  },

  watch: {
    async selectedCustomer () {
      this.subscribedUsers = []
      this.unsubscribedUsers = []
      await this.getSubscribedUsers()
      await this.populateUserCombo()
    }
  },

  async mounted () {
    await this.getSubscribedUsers()
    await this.populateUserCombo()
  },

  // Not using vuelidate on the array of subscribedUsers as the model refuses to be populated. The dummy validation is required for the isDirty flag to trigger for beforeRouteLeave.
  validations () {
    return {
      subscribedUsers: {
        $each: {
          required: requiredIf(() => {
            return false
          })
        }
      }
    }
  }
}
</script>

<style scoped>
th {
  white-space: nowrap;
  border: none !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}

th.rotate {
  /* Something you can count on */
  height: 180px;
}

th.rotate > div {
  transform:
    /* Magic Numbers */ translate(0px, 0px)
    /* 45 is really 360 - 45 */ rotate(315deg);
  width: 30px;
}

.small-text {
  height: 24px;
}

.scrollable {
  overflow: auto;
}
</style>
