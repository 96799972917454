import axios from 'axios'

export default {
  methods: {
    // Get Bureau Mappings
    async loadMappings (mappingType) {
      try {
        const url = process.env.VUE_APP_WORKFLOW_API_URL + 'mappingTitles/' + mappingType
        const response = await axios.get(url)
        console.info('Response', url)
        return response.data.mappings.map((mapping) => {
          return { value: mapping._id, text: mapping.title }
        })
      } catch (error) {
        console.log(error)
      }
    },
    // Get Bureau Schemas
    loadSchemas () {
      return [
        { value: 'Standard18', text: 'Standard 18' },
        { value: 'Legacy', text: 'Legacy' },
        { value: 'Mapping', text: 'Mapping' }
      ]
    },
    // Get Bureau Import Statuses
    loadBureauImportStatus () {
      return [
        { value: 0, text: 'Editing' },
        { value: 1, text: 'Queued' },
        { value: 2, text: 'Verifying' },
        { value: 3, text: 'Importing' },
        { value: 4, text: 'Complete' },
        { value: 5, text: 'Failed' },
        { value: 6, text: 'VerificationFailed' }
      ]
    },
    // Get next BACS processing date from payment date
    async getProcessingDateFromPaymentDate (paymentDate) {
      try {
        var params = { params: { paymentDate: paymentDate } }
        var response = await axios.get(
          process.env.VUE_APP_BACS_API_URL + 'bacs/processingDate/getProcessingDateFromPaymentDate',
          params
        )
        var processingDate = new Date(this.$moment(response.data, 'DD/MM/YYYY'))
        return processingDate
      } catch (error) {
        console.log(error)
      }
    },
    // Setup BACS disabled dates for date picker
    async setUpBACSDatePicker () {
      try {
        console.info('setUpBACSDatePicker')
        var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/processingDate')
        var bacsDisabledDates = response.data
        var disabledDates = {}
        disabledDates.days = [6, 0]
        disabledDates.to = new Date(this.$moment(bacsDisabledDates.minPaymentDate).format())
        disabledDates.from = new Date(this.$moment(bacsDisabledDates.maxPaymentDate).format())
        disabledDates.nonProcessingDates = []
        bacsDisabledDates.nonProcessingDates.forEach((item) => {
          disabledDates.nonProcessingDates.push(new Date(this.$moment(item).format()))
        })
        return disabledDates
      } catch (error) {
        console.error('Failed to get disabled dates from BACS')
        console.error(error)
      }
    },
    // Format date into a humanized form
    customFormatter (date) {
      return this.$moment(date).format(this.defaultDateFormat())
    },
    defaultDateFormat () {
      return 'dd/MM/yyyy'
    },
    // Get next BACS payment date
    async getNextPaymentDate () {
      try {
        var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/processingDate')
        return response.data.minPaymentDate
      } catch (error) {
        console.log(error)
      }
    },
    generateUUID () {
      // Public Domain/MIT
      var d = new Date().getTime() // Timestamp
      var d2 = (performance && performance.now && performance.now() * 1000) || 0 // Time in microseconds since page-load or 0 if unsupported
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 // random number between 0 and 16
        if (d > 0) {
          // Use timestamp until depleted
          r = ((d + r) % 16) | 0
          d = Math.floor(d / 16)
        } else {
          // Use microseconds since page-load if supported
          r = ((d2 + r) % 16) | 0
          d2 = Math.floor(d2 / 16)
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      })
    }
  }
}
