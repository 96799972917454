export default {
  data () {
    return {
      totalSelected: 0,
      totalToClient: 0,
      totalDifference: 0,
      totalAmount: 0
    }
  },
  methods: {
    tdClass (row, field) {
      return this.moneyClass(row[field])
    },
    moneyClass (amount, noRight) {
      let right = 'text-right'
      if (noRight) { right = '' }
      if (amount < 0) {
        return `text-danger ${right}`
      }
      return right
    },
    moneyFormat (value) {
      if (value < 0) {
        return `(${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).substring(1)})`
      } else {
        return `${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      }
    },
    getTotalSelection (selectedRows) {
      this.$nextTick(() => {
        var selectedRecords = selectedRows
        this.totalSelected = selectedRecords.reduce((a, v) => {
          if (v.isConnectedMerchantSettlement) {
            return a + v.connectedMerchantCharges
          }
          return a + v.collectionAmount
        }, 0)
        this.totalToClient = selectedRecords.reduce((a, v) => a + v.clientSettlementPayment, 0)
        this.totalDifference = selectedRecords.reduce((a, v) => {
          if (v.isConnectedMerchantSettlement) {
            return a + v.clientSettlementPayment - v.connectedMerchantCharges
          }
          return a + v.clientSettlementPayment - v.collectionAmount
        }, 0)
        this.totalAmount = selectedRecords.reduce((a, v) => a + v.amount, 0)
      })
    },
    sumCount (rowObj) {
      let sum = 0
      var selectedChildren = rowObj.children.filter(c => c.vgtSelected)
      for (let i = 0; i < selectedChildren.length; i++) {
        sum += selectedChildren[i].amount
      }
      return sum
    },

    handleSelectedRowChange (selectedRows, settlement) {
      if (settlement) {
        selectedRows.selectedRows.forEach(row => {
          if (row.clientSettlementPayment < 0) {
            this.$set(row, 'vgtSelected', false)
          }
        })

        selectedRows.selectedRows = selectedRows.selectedRows.filter(row => row.clientSettlementPayment > 0)
      }
      this.getTotalSelection(selectedRows.selectedRows)
      var selectedRecords = selectedRows.selectedRows
      if (selectedRecords.length > 0) {
        this.disableSubmission = false
      } else {
        this.disableSubmission = true
      }
    },
    settlementTypeSort (x, y) {
      x === 'Net' ? x = 0 : x = 1
      y === 'Net' ? y = 0 : y = 1

      return (x < y ? -1 : (x > y ? 1 : 0))
    }
  }
}
