<template>
  <div>
    <!-- <folderBrowser v-if="showFolderBrowser" @close="showFolderBrowser = false; closeFolderPopup()">
    </folderBrowser>-->
    <fileBrowser v-if="showFileBrowser" @close="showFileBrowser = false; closeFilePopup()"></fileBrowser>
    <h3>
      Send Submission
      <span class="pull-right">
        <a href="#" target="_blank">
          <i class="far fa-question-circle"></i>
        </a>
      </span>
    </h3>
    <hr />
    <!-- <br />
    <div class="form-group">
      <label class="required">Group</label>
      <br />
      <group-select v-model="selectedNode.props.s1.value" :groups="options" :class="{invalid: $v.selectedNode.props.s1.value.$error}"></group-select>
      <p
        class="validationText"
        v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty "
      >A valid PayGate group must be selected!</p>
    </div>-->
    <br />
    <div class="form-group">
      <label>Service</label>
      <br />
      <p-radio value="BACS" color="primary" v-model="selectedNode.props.s2.value">BACS</p-radio>
      <p-radio
        value="Faster Payments"
        color="primary"
        v-model="selectedNode.props.s2.value"
      >Faster Payments</p-radio>
    </div>
    <br />
    <div class="form-group">
      <label class="required">Submission Type</label>
      <br />
      <select class="form-control" v-model="selectedNode.props.s3.value">
        <option>Live</option>
        <option>Structural Test</option>
        <option v-if="selectedNode.props.s2.value === 'BACS'">Full Test</option>
      </select>
      <p
        class="validationText"
        v-if="!$v.selectedNode.props.s3.value.required && $v.selectedNode.props.s3.value.$dirty "
      >A valid Submission Type is required!</p>
    </div>
    <br />
    <div class="form-group">
      <label>Submission Summary</label>
    </div>
    <div class="form-group">
      <label>Download Format</label>
      <br />
      <p-radio value="XML" color="primary" v-model="selectedNode.props.s5.value">XML</p-radio>
      <p-radio value="HTML" color="primary" v-model="selectedNode.props.s5.value">HTML</p-radio>
      <p-radio value="PDF" color="primary" v-model="selectedNode.props.s5.value">PDF</p-radio>
    </div>

    <br />
    <label class="required">Save path (Including the filename)</label>
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s6.value.$error}"
        @input="$v.selectedNode.props.s6.value.$touch()"
        v-model="selectedNode.props.s6.value"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFileBrowser" type="button">...</button>
      </div>
    </div>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s6.value.required && $v.selectedNode.props.s6.value.$dirty "
    >The Save Path field is required!</p>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s6.value.maxLength"
    >Maximum length is {{ $v.selectedNode.props.s3.value.$params.maxLength.max }} characters!</p>

    <!-- <div class="form-group">
      <label class="required">Save Path</label>
      <input type="text" class="form-control" v-model="selectedNode.props.s6.value">
      <p class="validationText" v-if="!$v.selectedNode.props.s6.value.required && $v.selectedNode.props.s6.value.$dirty ">The Save Path field is required!</p>
      <p class="validationText" v-if="!$v.selectedNode.props.s6.value.maxLength">Maximum length is {{ $v.selectedNode.props.s3.value.$params.maxLength.max }} characters!</p>
    </div>-->
    <br />
    <hr />
    <div class="pull-right">
      <b-button variant="danger" class="btnPad" @click.prevent="btnCancel">Cancel</b-button>
      <b-button class variant="success" @click.prevent="btnSave">OK</b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength } from 'vuelidate/lib/validators'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  components: {
    fileBrowser
  },
  validations: {
    selectedNode: {
      props: {
        s2: {
          value: {
            required,
            maxLength: maxLength(18)
          }
        },
        s3: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s6: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        }
      }
    }
  },
  data () {
    return {
      showFileBrowser: false,
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s6.value = utils.sanitisePath(this.selectedNode.props.s6.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnFileBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s6.value)
      this.showFileBrowser = true
    },
    closeFilePopup () {
      console.log('closeFilePopup popup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      this.selectedNode.props.s6.value = this.$store.state.mapping.modalPopupString
    }
  },
  created: async function () {
    // this.paygateId = this.$store.getters.getClaim('paygate_id').value
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  },
  watch: {
    selectedNode: {
      handler: function (val, oldVal) {
        // Used a deep watch because p-radio does not support an @click event.
        if (val.props.s2.value === 'Faster Payments') {
          // Prevent selecting an unsupported report format in Faster Payments.
          if (this.selectedNode.props.s3.value === 'Full Test') {
            this.selectedNode.props.s3.value = ''
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.invalid {
  border-color: red;
  border-width: 2px;
}

.fadein-enter {
  opacity: 0;
}

.fadein-enter-active {
  transition: opacity 0.6s;
}

.fadein-leave-active {
  transition: opacity 0.6s;
  opacity: 0;
}
</style>
