<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>{{status}} Client Association</h2>
      </div>

      <div class="idb-block-content">
        <!-- Name -->
        <div class="form-group row" :class="{ invalid: $v.customerAssociation.name.$error }">
          <label class="col-form-label col-md-3 required">Name</label>
          <div class="col-md-6">
            <input
              v-focus
              type="text"
              class="form-control"
              v-model.trim="$v.customerAssociation.name.$model"
            />
            <!-- Validation -->
            <validation-messages v-model="$v.customerAssociation.name" name="name"></validation-messages>
          </div>
        </div>

        <!-- Description -->
        <div class="form-group row" :class="{ invalid: $v.customerAssociation.description.$error }">
          <label class="col-form-label col-md-3">Description</label>
          <div class="col-md-6">
            <textarea
              class="form-control"
              rows="3"
              v-model.trim="$v.customerAssociation.description.$model"
            ></textarea>
            <!-- Validation -->
            <validation-messages v-model="$v.customerAssociation.description"></validation-messages>
          </div>
        </div>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Associated Clients</h2>
      </div>
      <div class="idb-block-content">
        <div class="form-group row">
          <label class="col-form-label col-md-3">Client To Add</label>
          <div class="col-md-4">
            <vue-select
              v-model="selectedCustomer"
              :options="customers"
              :closeOnSelect="true"
              append-to-body
              :selectable="o => true"
              ref="select"
              @option:selected="$refs.selectAss.clearSelection()"
            ></vue-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-3">Association To Add</label>
          <div class="col-md-4">
            <vue-select
              v-model="selectedAssociation"
              :options="associations"
              :closeOnSelect="true"
              append-to-body
              :selectable="o => true"
              ref="selectAss"
              @option:selected="$refs.select.clearSelection()"
            ></vue-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <customer-association-tree
              v-for="node in customerAssociation.customers"
              :key="node.objectId"
              :nodes="node"
              :label="node.name"
              :parent="customerAssociation"
              :depth="0"
              :selectedCustomer="selectedCustomer"
              :selectedAssociation="selectedAssociation"
              @add="innerAdd"
              @remove="remove"
            ></customer-association-tree>
            <button
              class="btn btn-outline-success btn-sm ml-1"
              @click="add()"
            >Add Selected Client/Association</button>
          </div>
        </div>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Client Association Contacts</h2>
      </div>
      <div class="idb-block-content">
        <div class="row">
          <div class="col-md-12">
            <vue-good-table
              ref="customerContacts"
              @on-row-click="onRowClick"
              :columns="contactColumns"
              :rows="$v.customerAssociation.contacts.$model"
              :totalRows="customerAssociation.contacts.length "
              :pagination-options="{
                    enabled: true,
                    perPage: 10,
                    dropdownAllowAll: false
                  }"
              styleClass="vgt-table striped bordered"
            >
              <div slot="table-actions">
                <b-button
                  @click.prevent="openAddContactModal"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Add a New Contact'"
                >
                  <i class="fa fa-plus pointer dimmedIcon"></i>
                </b-button>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'actions'">
                  <button
                    class="btn btn-danger"
                    type="button"
                    @click.prevent="deleteContact(props.row,$event)"
                  >
                    <a class="fa fa-trash"></a>
                  </button>
                </span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
              </template>
              <div slot="table-actions-bottom">
                <div class="row" style="padding: 8px 0 8px 8px;">
                  <div class="col-md-2">
                    <button
                      class="btn btn-outline-primary"
                      @click.prevent="openAddContactModal"
                    >Add a New Contact</button>
                  </div>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          :disabled=" isLoading"
          @click="checkValidation() && saveCustomerAssociation()"
          type="submit"
        >{{ status === "Edit" ? "Save" : status }}</button>

        <button class="btn btn-outline-danger pull-right ml-3" type="button" @click="back">Cancel</button>

        <button
          v-if="status === 'Edit'"
          class="btn btn-danger pull-right"
          @click="deleteCustomerAssociation"
          :disabled=" isLoading"
          type="button"
        >
          <i class="glyphicon ti-trash rpad"></i>Delete Client Association
        </button>
      </div>
    </div>

    <contact-modal
      ref="customerAssociationContactModal"
      :type="contactModalType"
      :object="$v.customerAssociation.contacts"
      :require-address="false"
      :allowMultipleContactTypes="true"
      :contactTypes="['Operations','Finance', 'Technical', 'Marketing', 'Invoicing']"
      @edit="editContact"
    ></contact-modal>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import swal from 'sweetalert2'

// Mixins
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

// Constants
import colours from '@/Assets/Constants/colours'

// Components
import ContactModal from '@/Components/Shared/Contact.vue'
import CustomerAssociationTree from '@/Components/Platform/Customer/Associations/CustomerAssociationTree'
import VueSelect from 'vue-select'

// Validations
import { required } from 'vuelidate/lib/validators'

function filterAssociations (associations) {
  var result = []

  associations.forEach(association => {
    // Add association to result
    result.push(association)

    var otherAssociations = association.customers.filter(c => c.association)

    var results = filterAssociations(otherAssociations)

    result = result.concat(results)
  })

  return result
}

function filterUsedAssociations (objectId, association) {
  var result = association.objectId === objectId || association.id === objectId

  if (result === false) {
    association.customers.forEach(customerAssociation => {
      result = filterUsedAssociations(objectId, customerAssociation)
    })
  }

  return result
}

function setAssociation (customers) {
  customers.forEach(c => {
    c.underAssociation = true
    setAssociation(c.customers)
  })
}

export default {
  name: 'EditCustomerAssociation',
  mixins: [DataLeaveMixin, loading],
  props: ['status', 'id'],
  components: {
    CustomerAssociationTree,
    ContactModal,
    VueSelect
  },
  computed: {
    usedCustomers () {
      return this.customerAssociation.customers.filter(c => !c.association)
    },
    customers () {
      const usedCustomers = this.usedCustomers
      return this.localCustomersOrdered.map(c => {
        return { id: c.paygateId, label: c.name }
      }).filter(c => !usedCustomers.some(cc => c.id === cc.objectId))
    },
    usedAssociations () {
      return filterAssociations(this.customerAssociation.customers.filter(c => c.association))
    },
    associations () {
      const usedAssociations = this.usedAssociations
      return this.localAssociations.map(a => {
        return { id: a.id, label: a.name, customers: a.customers, association: true }
      }).filter(a => !usedAssociations.some(aa => filterUsedAssociations(aa.objectId, a)))
    }
  },
  data () {
    return {
      customerAssociation: {
        name: '',
        description: '',
        customers: [],
        contacts: []
      },
      contactColumns: [
        {
          label: 'Title',
          field: 'title'
        },
        {
          label: 'First Name',
          field: 'firstName'
        },
        {
          label: 'Last Name',
          field: 'lastName'
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Phone',
          field: 'phone'
        },
        {
          label: 'Mobile',
          field: 'mobile'
        },
        {
          label: 'Job Title',
          field: 'jobTitle'
        },
        {
          label: 'Contact Type',
          field (rowObj) {
            return rowObj.contactType.join(', ')
          }
        },
        {
          label: '',
          field: 'actions',
          sortable: false
        },
        {
          field: 'notes',
          hidden: true
        },
        {
          field: 'address1',
          hidden: true
        },
        {
          field: 'address2',
          hidden: true
        }, {
          field: 'address3',
          hidden: true
        }, {
          field: 'address4',
          hidden: true
        }, {
          field: 'address5',
          hidden: true
        }

      ],
      contactModalType: 'Create',
      selectedCustomer: null,
      localCustomersOrdered: [],
      localAssociations: [],
      selectedAssociation: null
    }
  },
  async mounted () {
    if (this.status === 'Edit') {
      await this.loadCustomerAssociation()
    }

    await this.loadCustomers()
    await this.loadAssociations()
  },
  methods: {
    async saveCustomerAssociation () {
      try {
        if (this.status === 'Create') {
          await axios.post(
            `${process.env.VUE_APP_PLATFORM_API_URL}CustomerAssociations`,
            this.customerAssociation,
            { showload: true, showerror: true, errormessage: 'Client Association failed to create' })
          this.$v.$reset()
          this.$toastr.s('New customer association created', 'Created')
          this.$router.push({ name: 'CustomerAssociations' })
        } else {
          await axios.put(
            `${process.env.VUE_APP_PLATFORM_API_URL}CustomerAssociations`,
            {
              ...this.customerAssociation
            },
            { showload: true, showerror: true, errormessage: 'Client Association failed to update' })
          this.$toastr.s('Customer association updated', 'Updated')
          await this.loadCustomerAssociation()
          this.$v.$reset()
        }
      } catch { }
    },
    async loadCustomerAssociation () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customerassociations/${this.id}`, {
          showload: true,
          showerror: true,
          errormessage: 'Failed to load Client Association'
        })
        this.customerAssociation = response.data
        this.$v.$reset()
      } catch { }
    },
    async deleteCustomerAssociation () {
      try {
        var swalResult = await swal.fire({
          title: 'Delete Client Association',
          text: 'Are you sure you want to delete this Client association?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch { return }

      if (!swalResult.isConfirmed) {
        return
      }

      try {
        await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}CustomerAssociations/${this.id}`,
          { showload: true, showerror: true, errormessage: 'Client association failed to delete' })
        this.$v.$reset()

        this.$toastr.s('Client Association deleted', 'Deleted')
        this.$router.push({ name: 'CustomerAssociations' })
      } catch { }
    },
    async openAddContactModal () {
      this.contactModalType = 'Create'
      this.$bvModal.show('contactModal')
    },
    onRowClick (params) {
      this.openAddContactModal()
      this.contactModalType = 'Edit'
      this.$refs.customerAssociationContactModal.populateContact(params.row)
    },
    deleteContact (row, event) {
      event.stopPropagation()
      this.$v.customerAssociation.contacts.$model.splice(row.originalIndex, 1)
      this.$v.customerAssociation.contacts.$touch()
    },
    editContact (contact) {
      this.$set(this.$v.customerAssociation.contacts.$model, contact.originalIndex, contact)
      this.$v.customerAssociation.contacts.$touch()
    },
    add () {
      if (!this.selectedCustomer && !this.selectedAssociation) {
        return
      }
      var selectedObject = {}

      if (this.selectedCustomer) {
        selectedObject = this.selectedCustomer
        selectedObject.customers = []
      } else {
        selectedObject = this.selectedAssociation
        selectedObject.customers.forEach(c => {
          c.parentObjectId = selectedObject.id
        })
        setAssociation(selectedObject.customers)
      }

      var customerToAdd = {
        name: selectedObject.label,
        objectId: selectedObject.id,
        customers: selectedObject.customers,
        association: !!selectedObject.association
      }

      this.customerAssociation.customers.push(customerToAdd)

      this.$refs.select.clearSelection()
      this.$refs.selectAss.clearSelection()
      this.$v.customerAssociation.customers.$touch()
    },
    innerAdd () {
      this.$refs.select.clearSelection()
      this.$refs.selectAss.clearSelection()
      this.$v.customerAssociation.customers.$touch()
    },
    remove () {
      this.$v.customerAssociation.customers.$touch()
    },
    async loadAssociations () {
      var result = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customerassociations/dropdownfull`, {
        params: {
          customerAssociationId: this.customerAssociation.id
        },
        showerror: true,
        errormessage: 'Failed to load Client Associations'
      })

      this.localAssociations = result.data
    },
    async loadCustomers () {
      var result = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customerassociations/customers`, {
        params: {
          customerAssociationId: this.customerAssociation.id
        },
        showerror: true,
        errormessage: 'Failed to load Clients'
      })

      this.localCustomersOrdered = result.data
    }
  },
  validations: {
    customerAssociation: {
      name: { required },
      description: {},
      customers: { $each: {} },
      contacts: { $each: {} }
    }
  }
}
</script>
<style lang="scss" scoped>
.vs__dropdown-option--selected {
  display: none;
}

.tree-icon {
  display: none;
}
</style>
