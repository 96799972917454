<template>
  <div class="tree-menu">
    <div v-if="label">
      <div class="label-wrapper" @click="toggleChildren">
        <div :style="indent" :class="labelClasses">
          <i v-if="nodes" class="fa" :class="iconClasses"></i>
          <i v-if="nodes.association" class="fa fa-people-arrows mr-1" />
          <span v-b-popover.hover.top="hoverText">{{ label }}</span>
          <button
            class="btn btn-outline-danger btn-sm"
            v-if="!nodes.underAssociation"
            @click="remove(nodes,$event)"
          >-</button>
        </div>
      </div>
      <div v-if="showChildren">
        <customer-association-tree
          v-for="node in nodes.customers"
          :key="node.objectId"
          :nodes="node"
          :depth="depth + 1"
          :label="node.name"
          :parent="nodes"
          :selectedCustomer="selectedCustomer"
          @add="$emit('add')"
          @remove="$emit('remove')"
        ></customer-association-tree>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerAssociationTree from '@/Components/Platform/Customer/Associations/CustomerAssociationTree'

export default {
  name: 'CustomerAssociationTree',
  components: { CustomerAssociationTree },
  props: { nodes: [Array, Object], label: String, depth: Number, parent: Object, selectedCustomer: Object, selectedAssociation: Object },
  data () {
    return {
      showChildren: false
    }
  },
  computed: {
    iconClasses () {
      return {
        'fa-plus-square mr-1': !this.showChildren && this.hasChildren,
        'fa-minus-square mr-1': this.showChildren && this.hasChildren,
        'mr-3': !this.hasChildren

      }
    },
    labelClasses () {
      return { 'has-children': this.hasChildren }
    },
    indent () {
      return { transform: `translate(${this.depth * 50}px)` }
    },
    hasChildren () {
      return this.nodes.customers.length > 0
    },
    hoverText () {
      if (this.nodes.association) {
        return 'Association'
      }
      return 'Client'
    }
  },
  methods: {
    toggleChildren () {
      this.showChildren = !this.showChildren
    },
    remove (node, e) {
      e.stopPropagation()

      var index = this.parent.customers.indexOf(node)
      this.parent.customers.splice(index, 1)

      this.$emit('remove')
    },
    add (node) {
      if (!this.selectedCustomer) {
        return
      }

      var customerToAdd = {
        name: this.selectedCustomer.label,
        objectId: this.selectedCustomer.id,
        customers: []
      }

      if (!node) {
        this.parent.customers = customerToAdd
      } else {
        customerToAdd.parentObjectId = node.objectId
      }
      node.customers.push(customerToAdd)

      this.$emit('add')
    }
  }

}
</script>

<style lang="scss" scoped>
.tree-menu {
  .label-wrapper {
    padding-bottom: 10px;
    margin-bottom: 10px;
    .has-children {
      cursor: pointer;
    }
  }
}
</style>
