<template>
  <div>
    <div class="d-flex justify-content-between mb-20">
      <h5 class="m-0">Bureau Task Summary</h5>
    </div>

    <hr />

    <div>
      <b-row v-for="item in items" v-bind:key="item.id">
        <b-col>
          <strong>{{ item.status }}</strong>
          {{ item.paymentFilename }}
        </b-col>
        <b-col>
          <b-progress :value="item.percentComplete" :max="100" variant="success"></b-progress>
        </b-col>
      </b-row>
      <b-row v-if="items.length === 0">
        <b-col>You do not currently have any active tasks</b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'BureauTaskProgressWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  async created () {
    this.intervalBit = setInterval(this.removeExpiredItems, 30000)
  },
  beforeRouteLeave () {
    clearInterval(this.intervalBit)
  },
  data () {
    return {
      hubUrl: '',
      getTaskProgressUrl: '',
      items: [],
      expiredItemsDelay: 5
    }
  },
  computed: {
    paygateId () { return this.$store.state.common.paygateId }
  },
  watch: {
    'widget.options': {
      async handler () {
        // await this.load()
      },
      deep: true
    }
  },
  beforeDestroy () {
    if (this.statusConnection !== null) {
      this.statusConnection.stop()
    }
  },
  methods: {
    removeExpiredItems () {
      const expiry = moment().add(this.expiredItemsDelay, 'seconds')
      if (this.items.length > 0) {
        const notExpired = _.map(this.items, function (item) {
          if (moment(item.lastUpdate).isAfter(expiry)) {
            var expiryStatus = ['Validated', 'Imported', 'Uploaded', 'Signed', 'Approved', 'Complete']
            if (!_.includes(expiryStatus, item.status)) {
              return item
            }
          }
        })
        this.items = _.without(notExpired, undefined)
      }
    }
  },
  async mounted () {
    this.getTaskProgressUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/GetTaskProgress'
    this.hubUrl = process.env.VUE_APP_BUREAU_API_URL + 'hubs/bureaustatushub?paygateId=' + this.paygateId

    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        this.statusConnection = new HubConnectionBuilder().withUrl(this.hubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.statusConnection.start()

        this.statusConnection.on('Refresh', data => {
          if (data.hasOwnProperty('bureauSubmissionDetail')) {
            let found = false
            this.items.forEach(item => {
              if (item.id === data.bureauSubmissionDetail.bureauSubmissionDetailId) {
                item.percentComplete = data.bureauSubmissionDetail.percentComplete
                item.status = data.bureauSubmissionDetail.status
                item.lastUpdate = moment().format()
                found = true
              }
            })
            if (!found) {
              this.items.push({
                id: data.bureauSubmissionDetail.bureauSubmissionDetailId,
                paymentFilename: data.bureauSubmissionDetail.paymentFilename,
                status: data.bureauSubmissionDetail.status,
                source: 'hub',
                lastUpdate: moment().format(),
                percentComplete: data.bureauSubmissionDetail.percentComplete
              })
            }
          }
        })
      })
  }
}
</script>
