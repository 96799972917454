<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Bankline Ad-hoc URG (CHAPS) Payment<span class="pull-right"><a :href="`${docUrl}/automation/logging/`" target="_blank"><i class="far fa-question-circle d-none"></i></a></span><favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div><strong>Payment Details</strong></div>
          <br/>
          <!-- Bank Account -->
          <div class="form-group row" :class="{invalid: $v.payment.bankAccountId.$error}">
            <label class="label-control col-md-3 required">Debit Bank Account</label>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <b-form-select
                v-model="payment.bankAccountId"
                :options="bankAccounts"
                @input="$v.payment.bankAccountId.$touch()"
              ></b-form-select>
              <p class="validationText" v-if="!$v.payment.bankAccountId.required && $v.payment.bankAccountId.$dirty">A Debit Bank Account is required!</p>
            </div>
            <div class="col-md-1">
              <div v-if="isBanksLoading" class="spinner-border spinner-border-sm mt-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <br/>
          <b-form-row :class="{invalid: $v.payment.description.$error}">
            <div class="col-md-3 col-sm-12">
              <label v-b-popover.hover.top.d500="'Optional, freeform text that describes the payment.  The text is not sent to Bankline but can be used to provide details about the payment in reports, etc.'" >Payment Description</label>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <b-form-textarea
                  type="text"
                  rows="4"
                  placeholder=""
                  v-model.trim="payment.description"
                ></b-form-textarea>
              <p class="validationText" v-if="!$v.payment.description.maxLength">Maximum length is {{ $v.payment.description.$params.maxLength.max }} characters!</p>
              </div>
          </b-form-row>
          <br/>
          <b-form-row :class="{invalid: $v.payment.paymentAmount.$error}">
            <div class="col-md-3 col-sm-12">
              <label class="required">Payment Amount</label>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <div class="input-group">
                <span class="input-group-text">£</span>
                <input
                  type="number"
                  class="form-control text-right"
                  min="0"
                  step="0.01"
                  @input="$v.payment.paymentAmount.$touch(); updateAmt();"
                  v-model.trim.number="payment.paymentAmount"
                />
              </div>
              <p class="validationText" v-if="!$v.payment.paymentAmount.required && $v.payment.paymentAmount.$dirty ">The payment amount is required!</p>
              <p class="validationText" v-if="!$v.payment.paymentAmount.minValue && $v.payment.paymentAmount.$dirty ">The minimum payment value is £{{ $v.payment.paymentAmount.$params.minValue.min }}</p>
              <p class="validationText" v-if="!$v.payment.paymentAmount.maxValue && $v.payment.paymentAmount.$dirty ">The maximum payment value is £{{ $v.payment.paymentAmount.$params.maxValue.max }}</p>
            </div>
          </b-form-row>
          <br/>
          <b-form-row :class="{invalid: $v.payment.arrivalDate.$error}">
            <div class="col-md-3 col-sm-12">
              <label class="required" v-b-popover.hover.top.d500="'The date that the money should be in the beneficiaries account.'" >Date</label>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <div class="right-inner-addon">
                <datepicker
                  v-model="payment.arrivalDate"
                  input-class="form-control datepicker undis"
                  :typeable="typeable"
                  :disabled-dates="disabledDates"
                  @change="$v.payment.arrivalDate.$touch()"
                />
                <i class="far fa-calendar form-control-feedback"></i>
              </div>
              <p class="validationText" v-if="!$v.payment.arrivalDate.required && $v.payment.arrivalDate.$dirty ">A valide date must be supplied!</p>
            </div>
          </b-form-row>
          <br/>
          <br/>
          <div><strong>Beneficiary</strong></div>
          <br>
          <div class="form-group row" :class="{invalid: $v.payment.selectedCustomerId.$error}">
            <label class="label-control col-md-3 required">Charge to</label>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <b-form-select
                v-model="payment.selectedCustomerId"
                :options="customers"
                @input="$v.payment.selectedCustomerId.$touch()">
              </b-form-select>
              <p class="validationText" v-if="!$v.payment.selectedCustomerId.required && $v.payment.selectedCustomerId.$dirty">A customer selection is required!</p>
            </div>
          </div>
          <div class="form-group row" :class="{invalid: $v.payment.accountWithBankIdentifier.$error}">
            <label class="label-control col-md-3 required">Beneficiary Sortcode</label>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-9">
              <the-mask
                type="text"
                class="form-control"
                v-model="payment.accountWithBankIdentifier"
                :mask="['##-##-##']"
                :guide="true"
                @input="$v.payment.accountWithBankIdentifier.$touch()"
                ref="sortCode"
              />
              <validation-messages
                v-model="$v.payment.accountWithBankIdentifier"
                name="Beneficiary Sort Code"
              ></validation-messages>
            </div>
            <div v-if="bankAccountValidation != null">
              <div
                v-if="bankAccountValidation.valid || bankAccountValidation.errorCode === '1502'"
                class="col-md-1">
                <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
              </div>
              <div v-else class="col-md-1">
                <span class="text-warning small text-nowrap">{{bankAccountValidation.errorText}}</span>
              </div>
            </div>
          </div>
          <div class="form-group row" :class="{invalid: $v.payment.beneficiaryAccountNumber.$error}">
            <label class="label-control col-md-3 required">Beneficiary Account Number</label>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-9">
              <the-mask
                type="text"
                class="form-control"
                v-model="payment.beneficiaryAccountNumber"
                :mask="['########']"
                :guide="false"
                @input="$v.payment.beneficiaryAccountNumber.$touch()"
                ref="accountNumber" />
              <validation-messages
              class="validationText"
                v-model="$v.payment.beneficiaryAccountNumber"
                name="Beneficiary Account Number"
              ></validation-messages>
            </div>
            <div v-if="bankAccountValidation != null">
              <div v-if="bankAccountValidation.valid" class="col-md-1">
                <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
              </div>
              <div v-else-if="bankAccountValidation.errorCode === '1502'" class="col-md-1">
                <span class="text-warning small text-nowrap">{{bankAccountValidation.errorText}}</span>
              </div>
            </div>
          </div>
          <div class="form-group row" :class="{invalid: $v.payment.beneficiaryReference.$error}">
            <label class="label-control col-md-3">Beneficiary Reference</label>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <input
                id="name"
                type="text"
                class="form-control"
                @input="$v.payment.beneficiaryReference.$touch()"
                v-model.trim="$v.payment.beneficiaryReference.$model" />
              <p class="validationText" v-if="!$v.payment.beneficiaryReference.maxLength">Maximum length is {{ $v.payment.beneficiaryReference.$params.maxLength.max }} characters!</p>
              <p class="validationText" v-if="!$v.payment.beneficiaryReference.regexValidator">The field includes invalid characters! Allowed characters are A-Z a-z 0-9 . - / ? : ( ) + '</p>
            </div>
          </div>
          <div class="form-group row" :class="{invalid: $v.payment.beneficiaryNameAndAddressLineNumber1.$error}">
            <label class="label-control col-md-3 required">Beneficiary Name</label>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <input
                id="name"
                type="text"
                class="form-control"
                @input="$v.payment.beneficiaryNameAndAddressLineNumber1.$touch()"
                v-model.trim="$v.payment.beneficiaryNameAndAddressLineNumber1.$model" />
              <p class="validationText" v-if="!$v.payment.beneficiaryNameAndAddressLineNumber1.required && $v.payment.beneficiaryNameAndAddressLineNumber1.$dirty ">The Beneficiary Name field is required!</p>
              <p class="validationText" v-if="!$v.payment.beneficiaryNameAndAddressLineNumber1.maxLength">Maximum length is {{ $v.payment.beneficiaryNameAndAddressLineNumber1.$params.maxLength.max }} characters!</p>
              <p class="validationText" v-if="!$v.payment.beneficiaryNameAndAddressLineNumber1.regexValidator">The field includes invalid characters! Allowed characters are A-Z a-z 0-9 . - / ? : ( ) + '</p>
            </div>
          </div>
          <div class="form-group row" :class="{invalid: $v.payment.informationForTheBeneficiaryLineNumber1.$error}">
            <label class="label-control col-md-3"><span v-b-popover.hover.top.d500="'Optional text for CHAPS payments. e.g. the invoice number.'">Information for the Beneficiary</span></label>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-9">
              <input
                id="iftbln1"
                type="text"
                class="form-control"
                @input="$v.payment.informationForTheBeneficiaryLineNumber1.$touch()"
                v-model.trim="$v.payment.informationForTheBeneficiaryLineNumber1.$model"
                />
              <p class="validationText" v-if="!$v.payment.informationForTheBeneficiaryLineNumber1.maxLength">Maximum length is {{ $v.payment.informationForTheBeneficiaryLineNumber1.$params.maxLength.max }} characters!</p>
              <p class="validationText" v-if="!$v.payment.informationForTheBeneficiaryLineNumber1.regexValidator">The field includes invalid characters! Allowed characters are A-Z a-z 0-9 . - / ? : ( ) + '</p>
            </div>
          </div>
          <br/>
          <br/>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-12">
              <div class="btn-group" role="group">
                <button :disabled="processing" @click="btnCreateBanklinePayment(false)"  type="button" class="btn btn-primary">
                  Add to Bulk Bankline File
                </button>
              </div>
              <div class="btn-group" role="group">
                <button :disabled="processing" @click="btnCreateBanklinePayment(true)" type="button" class="btn btn-outline-primary ml-2">
                  Create as Standalone File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Datepicker from 'vuejs-datepicker'
import { TheMask } from 'vue-the-mask'
import _ from 'lodash'
import { required, maxLength, helpers, minValue, maxValue } from 'vuelidate/lib/validators'
import { exactLength } from '@/Assets/Validators'
// import CustomerSelector from './../../../../Assets/Components/CustomerSelector.vue'
import customerTypes from '@/Assets/Constants/customerTypes'
const regexValidator = helpers.regex('regexval', /^[A-Za-z0-9./?:()+'\s-]*$/i) // Note regex for CHAPS is slighly different to Standard. JFTP-38 removed

function customerTypeToNumber (type) {
  switch (type) {
    case customerTypes.general:
      return 0
    case customerTypes.connectedMerchant:
      return 1
    case customerTypes.master:
      return 2
    default:
      return 0
  }
}

export default {
  name: 'banklinepayment',
  components: {
    TheMask,
    Datepicker
  },
  data () {
    return {
      processing: false,
      isCustomer: false,
      isBanksLoading: true,
      bankAccounts: [],
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      disabledDates: this.getDisabledDays(),
      typeable: false,
      bootstrapStyling: false,
      minDate: new Date(),
      maxDate: new Date(),
      payment: {
        paymentType: 'chaps',
        paymentAmount: 0,
        description: '',
        debitAccountIdentifier: '',
        accountWithBankIdentifier: '',
        beneficiaryAccountNumber: '',
        customerPaymentReference: '',
        beneficiaryNameAndAddressLineNumber1: '',
        informationForTheBeneficiaryLineNumber1: '',
        informationForTheBeneficiaryLineNumber2: '',
        informationForTheBeneficiaryLineNumber3: '',
        informationForTheBeneficiaryLineNumber4: '',
        beneficiaryReference: '',
        arrivalDate: new Date(),
        recordType: '02',
        standalone: false,
        bankAccountId: '',
        selectedCustomerId: ''
      },
      bankAccountValidation: null
    }
  },
  validations: {
    payment: {
      paymentAmount: {
        required,
        minValue: minValue(0.01),
        maxValue: maxValue(350000)
      },
      arrivalDate: {
        required
      },
      bankAccountId: {
        required
      },
      selectedCustomerId: {
        required
      },
      accountWithBankIdentifier: { required, exactLength: exactLength(6) },
      beneficiaryAccountNumber: { required, exactLength: exactLength(8) },
      beneficiaryNameAndAddressLineNumber1: { required, maxLength: maxLength(35), regexValidator },
      beneficiaryReference: {
        regexValidator,
        maxLength: maxLength(18)
      },
      description: { maxLength: maxLength(1824) },
      informationForTheBeneficiaryLineNumber1: {
        regexValidator,
        maxLength: maxLength(35)
      }
    }
  },
  methods: {
    updateAmt () {
      // bs function to keep the amt to 2 places of decimal and just generally behave.
      const dp = this.payment.paymentAmount.toString().indexOf('.')
      if (dp < 0) {
        this.payment.paymentAmount = this.payment.paymentAmount.toFixed(2)
        return
      }
      const ol = this.payment.paymentAmount.toString().length - dp
      if (ol === 2) {
        this.payment.paymentAmount = this.payment.paymentAmount.toFixed(2)
      }
      if (ol > 2) {
        this.payment.paymentAmount = +(this.payment.paymentAmount.toString().substr(0, dp + 3))
        this.payment.paymentAmount = this.payment.paymentAmount.toFixed(2)
      }
    },
    getDisabledDays () {
      this.minDate = new Date()
      this.minDate.setDate(this.minDate.getDate())
      this.minDate.setHours(0, 0, 0, 0)
      this.maxDate = new Date()
      this.maxDate.setDate(this.maxDate.getDate() + 31)
      this.maxDate.setHours(23, 59, 59, 999)
      console.log('this.minDate:', this.minDate)
      console.log('this.maxDate:', this.maxDate)
      return {
        to: this.minDate,
        from: this.maxDate
      }
    },
    async loadBankAccounts () {
      try {
        this.isBanksLoading = true
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/Dropdown`, { showerror: true, errormessage: 'Failed to get bank accounts' })
        this.isBanksLoading = false
        this.bankAccounts = response.data.map(bankAccount => {
          return { value: bankAccount.bankAccountId, text: bankAccount.reference }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async loadCustomers () {
      // Mostly stolen from the customerSelector component
      try {
        let customers = this.$store.getters.customersOrdered
        customers = customers.sort((a, b) => {
          var typeA = customerTypeToNumber(a.type)
          var typeB = customerTypeToNumber(b.type)
          return (typeA > typeB) ? -1 : (typeA < typeB) ? 1 : 0
        })

        var result = []
        customers.forEach((c, i) => {
          if (i === 0) {
            result.push({ group: `${c.type} Customers`, name: '' })
          }
          if (i > 0) {
            var previousC = customers[i - 1]
            if (c.type !== previousC.type) {
              result.push({ group: `${c.type} Customers`, name: '' })
            }
          }
          result.push(c)
        })
        this.customers = customers.map(customer => {
          return { value: customer.paygateId, text: customer.name, type: customer.type }
        })
      } catch (e) {
        console.log(e)
      }
    },
    digitsOrDashes: function () {
      if (!this.$v.payment.accountWithBankIdentifier.$invalid && !this.payment.beneficiaryAccountNumber.$invalid) {
        this.validate()
      }
    },
    validate: _.debounce(async function () {
      const data = { sortCode: this.payment.accountWithBankIdentifier, accountNumber: this.payment.beneficiaryAccountNumber }
      try {
        const response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/ValidateBankAccount`, data, { showerror: true, errormessage: 'Failed to get validate bank account' })

        this.bankAccountValidation = response.data
      } catch { }
    }, 800),
    async setArrivalDate () {
      this.payment.arrivalDate = new Date()
      this.payment.arrivalDate.setDate(this.payment.arrivalDate.getDate() + 0)
    },
    async btnCreateBanklinePayment (standalone) {
      this.$snapbar.hide()
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$toastr.e('There are validation errors on the form', 'Validation')
        return
      }
      this.processing = true
      this.payment.paymentAmount = +(this.payment.paymentAmount)
      this.payment.standalone = standalone

      try {
        const response = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}banklinepaymentchaps`, this.payment)
        this.processing = false
        if (response.data.success === true) {
          this.$snapbar.s('Success.  The Bankline payment was created.')
          // Null Form
          this.payment.paymentType = 'chaps'
          this.payment.paymentAmount = 0
          this.payment.description = ''
          this.payment.debitAccountIdentifier = ''
          this.payment.accountWithBankIdentifier = ''
          this.payment.beneficiaryAccountNumber = ''
          this.payment.customerPaymentReference = ''
          this.payment.beneficiaryNameAndAddressLineNumber1 = ''
          this.payment.beneficiaryNameAndAddressLineNumber2 = ''
          this.payment.beneficiaryNameAndAddressLineNumber3 = ''
          this.payment.beneficiaryNameAndAddressLineNumber4 = ''
          this.payment.informationForTheBeneficiaryLineNumber1 = ''
          this.payment.beneficiaryReference = ''
          this.payment.arrivalDate = new Date()
          this.payment.recordType = '02'
          this.payment.standalone = false
          this.payment.bankAccountId = ''
          this.payment.selectedCustomerId = ''
          this.setArrivalDate()
          this.$nextTick(() => {
            this.$v.$reset()
            this.bankAccountValidation = null
          })
        }
      } catch (e) {
        this.processing = false
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to make a bankline payment.')
        } else if (e.response && e.response.status === 400) {
          this.$snapbar.e('Error creating Bankline Payment: ' + e.response.data.response.errorMessage)
        } else {
          this.$snapbar.e(`Error - ${e.message}`)
        }
      }
    },
    onInboundCellClick (params) {
      if (params.column.field !== 'id') {
        this.$router.push(`/admin/bandr/bankline/bankline${params.row.type.toLowerCase()}file/${params.row.id}`)
      }
    },
    onOutboundCellClick (params) {
      if (params.column.field !== 'id') {
        this.$router.push(`/admin/bandr/bankline/bankline${params.row.type.toLowerCase()}file/${params.row.id}`)
      }
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    formatDateWithNull (date) {
      if (date === null || date === '0001-01-01T00:00:00') {
        return 'N/A'
      } else {
        return this.formatDate(date)
      }
    },
    pad (num, size) {
      const s = '00' + num
      return s.substr(s.length - size)
    }
  },
  async mounted () {
    await this.loadBankAccounts()
    await this.loadCustomers()
    await this.setArrivalDate()
  },
  watch: {
    'payment.accountWithBankIdentifier': function () {
      this.digitsOrDashes()
    },
    'payment.beneficiaryAccountNumber': function () {
      this.digitsOrDashes()
    }
  }
}
</script>

<style scoped>
  .validation-messages {
  color: red;
  font-size: medium;
  font-weight:800;
  }
  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }

  /* #paymentDatePicker,
  .datepicker,
  .datepicker:disabled {
    background-color: #ffffff !important;
  }
  .dark-mode #paymentDatePicker,
  .dark-mode #endPaymentDatePicker,
  .dark-mode .datepicker,
  .dark-mode .datepicker:disabled {
    background-color: transparent !important;
  }
  .dark-mode .vdp-datepicker,
  .dark-mode .vdp-datepicker__calendar {
    background-color: #424242 !important;
  }
  .dark-mode .vdp-datepicker__calendar * .next,
  .dark-mode .vdp-datepicker__calendar * .prev {
    color: #fff !important;
  } */
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}

input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type="number"]{
        -moz-appearance: textfield;
    }

</style>
