<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Create Association for {{AgentName}}</h2>
      </div>
      <div class="idb-block-content">
        <!-- Customer and date selection -->

        <div class="row form-group">
          <label class="col-form-label col-md-3">Description</label>
          <div class="col-md-6">
            <input
              v-focus
              type="text"
              class="form-control"
              v-model.trim="$v.detail.AssociationName.$model"
            />
            <!-- Validation -->
            <validation-messages
              v-model="$v.detail.AssociationName"
              :an="true"
              name="association description"
            ></validation-messages>
          </div>
        </div>

        <!-- Customer and date selection -->

        <div class="row form-group">
          <label class="col-form-label col-md-3">Customers</label>
          <div class="col-md-6">
            <vue-select
              v-model="$v.selectedCustomer.$model"
              :options="customers"
              label="customerName"
              :closeOnSelect="true"
              @input="selectCustomer()"
            ></vue-select>
          </div>
        </div>

        <!-- Existing Association Warning -->
        <div class="row form-group" v-if="ShowExistingAssociationWarning">
          <label class="col-form-label col-md-3 text-danger">Warning</label>

          <div class="col-md-6">
            <h5 class="text-danger">{{ExistingAssociationWarningText}}</h5>
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-primary"
              @click="onGoToExistingAssociation"
            >Go To This Association</button>
          </div>
        </div>

        <!-- Dates -->

        <div class="row form-group">
          <label class="col-form-label col-md-3">Effective Date</label>
          <div class="col-md-4 col-sm-6">
            <transition name="fadein">
              <div v-if="dateType=='date'" class="right-inner-addon">
                <datepicker
                  id="datePicker"
                  v-model="selectedDate"
                  :disabled-dates="disabledDates"
                  format="dd/MM/yyyy"
                  input-class="form-control datepicker"
                  @input="onUpdateModelDates()"
                />
                <i class="fa fa-calendar"></i>
              </div>
            </transition>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-3">Add an End Date to this Commission Record</label>
          <div class="col-md-6">
            <p-check class="p-switch p-fill" color="primary" v-model="EndDateEnable"></p-check>
          </div>
        </div>

        <div class="row form-group" v-if="EndDateEnable">
          <label class="col-form-label col-md-3">End Date</label>
          <div class="col-md-4 col-sm-6">
            <transition name="fadein">
              <div v-if="dateType=='date'" class="right-inner-addon">
                <datepicker
                  id="endDatePicker"
                  v-model="selectedEndDate"
                  :disabled-dates="disabledEndDates"
                  format="dd/MM/yyyy"
                  input-class="form-control datepicker"
                  @input="onUpdateModelDates()"
                />
                <i class="fa fa-calendar"></i>
              </div>
            </transition>
          </div>
          <div class="col-md-2 col-sm-12">
            <h6
              v-show="endDateWarnVisible"
              class="text-warning"
            >Please verify this date as it has changed</h6>
          </div>
        </div>

        <div class="row form-group" v-if="CommittedAssociationErrorsWarning">
          <label class="col-form-label col-md-3 text-warning">Warning</label>
          <div class="col-md-6">
            <h5
              class="text-warning"
            >There are existing associations for the date range specified which values entered mean that some items will exceed 100% in value. Although you can continue, this is not advisable!</h5>
          </div>
        </div>

        <!-- Percentage and fixed fee selections -->
        <div role="tablist">
          <!-- Processing Fees -->

          <b-card no-body>
            <b-card-header header-tag="header" class="p-2" role="tab">
              <b-row v-b-toggle="'processing'" class="payment-header pt-0">
                <b-col label cols="12">Processing Fees</b-col>
              </b-row>
            </b-card-header>

            <b-collapse
              :id="'processing'"
              accordion="my-accordion"
              @show="onShowProcessing()"
              @hide="onHideProcessing()"
              role="tabpanel"
            >
              <!-- Advance Notice Letter -->
              <div class="row form-group">
                <label class="label-control col-md-3">Advance Notice Letter</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.AdvanceNoticeLetterPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="AdvanceNoticeLetterPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.AdvanceNoticeLetterFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="AdvanceNoticeLetterFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>

                <div v-if="AdvanceNoticeLetterPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{AdvanceNoticeLetterPercentageValueText}}</h6>
                </div>
                <div v-else-if="AdvanceNoticeLetterPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{AdvanceNoticeLetterPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>

                <div v-if="AdvanceNoticeLetterFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{AdvanceNoticeLetterFixedFeeValueText}}</h6>
                </div>
              </div>
              <!-- Advance Notice Email -->
              <div class="row form-group">
                <label class="label-control col-md-3">Advance Notice Email</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.AdvanceNoticeEmailPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="AdvanceNoticeEmailPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.AdvanceNoticeEmailFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
                <div v-if="AdvanceNoticeEmailFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="AdvanceNoticeEmailPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{AdvanceNoticeEmailPercentageValueText}}</h6>
                </div>
                <div v-else-if="AdvanceNoticeEmailPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{AdvanceNoticeEmailPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="AdvanceNoticeEmailFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{AdvanceNoticeEmailFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Advance Notice SMS -->
              <div class="row form-group">
                <label class="label-control col-md-3">Advance Notice SMS</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.AdvanceNoticeSMSPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="AdvanceNoticeSMSPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.AdvanceNoticeSMSFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="AdvanceNoticeSMSFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="AdvanceNoticeSMSPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{AdvanceNoticeSMSPercentageValueText}}</h6>
                </div>
                <div v-else-if="AdvanceNoticeSMSPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{AdvanceNoticeSMSPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="AdvanceNoticeSMSFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{AdvanceNoticeSMSFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Collections -->
              <div class="row form-group">
                <label class="label-control col-md-3">Collections</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.CollectionsPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="CollectionsPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.CollectionsFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="CollectionsFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="CollectionsPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{CollectionsPercentageValueText}}</h6>
                </div>
                <div v-else-if="CollectionsPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{CollectionsPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="CollectionsFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{CollectionsFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Confirmation Letter -->
              <div class="row form-group">
                <label class="label-control col-md-3">Confirmation Letter</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.ConfirmationLetterPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="ConfirmationLetterPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.ConfirmationLetterFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="ConfirmationLetterFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="ConfirmationLetterPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{ConfirmationLetterPercentageValueText}}</h6>
                </div>
                <div v-else-if="ConfirmationLetterPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{ConfirmationLetterPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="ConfirmationLetterFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{ConfirmationLetterFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Failed Collections -->
              <div class="row form-group">
                <label class="label-control col-md-3">Failed Collections</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.FailedCollectionsPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="FailedCollectionsPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.FailedCollectionsFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
                <div v-if="FailedCollectionsFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="FailedCollectionsPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{FailedCollectionsPercentageValueText}}</h6>
                </div>
                <div v-else-if="FailedCollectionsPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{FailedCollectionsPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="FailedCollectionsFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{FailedCollectionsFixedFeeValueText}}</h6>
                </div>
              </div>
              <!-- Indemnity Claims -->
              <div class="row form-group">
                <label class="label-control col-md-3">Indemnity Claims</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.IndemnityClaimsPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="IndemnityClaimsPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.IndemnityClaimsFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="IndemnityClaimsFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="IndemnityClaimsPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{IndemnityClaimsPercentageValueText}}</h6>
                </div>
                <div v-else-if="IndemnityClaimsPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{IndemnityClaimsPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="IndemnityClaimsFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{IndemnityClaimsFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Payer Cancellation -->
              <div class="row form-group">
                <label class="label-control col-md-3">Customer Cancellation</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.PayerCancellationPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="PayerCancellationPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.PayerCancellationFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="PayerCancellationFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="PayerCancellationPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{PayerCancellationPercentageValueText}}</h6>
                </div>
                <div v-else-if="PayerCancellationPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{PayerCancellationPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="PayerCancellationFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{PayerCancellationFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Payer Reinstate -->
              <div class="row form-group">
                <label class="label-control col-md-3">Customer Reinstate</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.PayerReinstatePercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="PayerReinstatePercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.PayerReinstateFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="PayerReinstateFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="PayerReinstatePercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{PayerReinstatePercentageValueText}}</h6>
                </div>
                <div v-else-if="PayerReinstatePercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{PayerReinstatePercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="PayerReinstateFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{PayerReinstateFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Payer Setup -->
              <div class="row form-group">
                <label class="label-control col-md-3">Customer Setup</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.PayerSetupPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="PayerSetupPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.PayerSetupFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="PayerSetupFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="PayerSetupPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{PayerSetupPercentageValueText}}</h6>
                </div>
                <div v-else-if="PayerSetupPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{PayerSetupPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="PayerSetupFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{PayerSetupFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Refunds -->
              <div class="row form-group">
                <label class="label-control col-md-3">Refunds</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.RefundsPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="RefundsPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.RefundsFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="RefundsFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="RefundsPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{RefundsPercentageValueText}}</h6>
                </div>
                <div v-else-if="RefundsPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{RefundsPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="RefundsFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{RefundsFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Validate Bank Account -->
              <div class="row form-group">
                <label class="label-control col-md-3">Validate Bank Account</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.ValidateBankAccountPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="ValidateBankAccountPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.ValidateBankAccountFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="ValidateBankAccountFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="ValidateBankAccountPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{ValidateBankAccountPercentageValueText}}</h6>
                </div>
                <div v-else-if="ValidateBankAccountPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{ValidateBankAccountPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="ValidateBankAccountFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{ValidateBankAccountFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Validate IBAN -->
              <div class="row form-group">
                <label class="label-control col-md-3">Validate IBAN</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.ValidateIBANPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="ValidateIBANPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.ValidateIBANFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="ValidateIBANFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="ValidateIBANPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{ValidateIBANPercentageValueText}}</h6>
                </div>
                <div v-else-if="ValidateIBANPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{ValidateIBANPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="ValidateIBANFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{ValidateIBANFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Fill All Processing Fees-->
              <div class="row form-group">
                <label class="label-control col-md-3">
                  <strong>Fill All Processing Fees</strong>
                </label>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="ProcessingFeesPercentageFill"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="ProcessingFeesFill"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-1">
                  <button
                    class="btn btn-secondary"
                    @click="onFillProcessingFeesPercentageFill"
                    type="button"
                  >
                    Fill
                    <i class="far fa-arrow-alt-circle-up"></i>
                  </button>
                </div>

                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="ProcessingFeesFixedFeeFill"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
                <div class="col-sm-1">
                  <button
                    class="btn btn-secondary"
                    @click="onFillProcessingFeesFixedFeeFill"
                    type="button"
                  >
                    Fill
                    <i class="far fa-arrow-alt-circle-up"></i>
                  </button>
                </div>
              </div>
            </b-collapse>
          </b-card>
          <!-- Add Hoc -->
          <b-card no-body>
            <b-card-header header-tag="header" class="p-2" role="tab">
              <b-row v-b-toggle="'adhoc'" class="payment-header pt-0">
                <b-col label cols="12">Ad-Hoc Fees</b-col>
              </b-row>
            </b-card-header>

            <b-collapse
              :id="'adhoc'"
              accordion="my-accordion"
              @show="onShowAdhoc()"
              @hide="onHideAdhoc()"
              role="tabpanel"
            >
              <!-- Credit Note -->
              <div class="row form-group">
                <label class="label-control col-md-3">Credit Note</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.CreditNotePercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="CreditNotePercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.CreditNoteFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="CreditNoteFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="CreditNotePercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{CreditNotePercentageValueText}}</h6>
                </div>
                <div v-else-if="CreditNotePercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{CreditNotePercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="CreditNoteFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{CreditNoteFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Termination Fee -->
              <div class="row form-group">
                <label class="label-control col-md-3">Termination Fee</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.TerminationFeePercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="TerminationFeePercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.TerminationFeeFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="TerminationFeeFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="TerminationFeePercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{TerminationFeePercentageValueText}}</h6>
                </div>
                <div v-else-if="TerminationFeePercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{TerminationFeePercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="TerminationFeeFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{TerminationFeeFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Bankline STD 1 or 2 day payment -->
              <div class="row form-group">
                <label class="label-control col-md-3">Bankline STD 1 or 2 day payment</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.BanklineSTD1Or2Percentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="BanklineSTD1Or2Percentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.BanklineSTD1Or2FixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
                <div v-if="BanklineSTD1Or2FieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="BanklineSTD1Or2PercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{BanklineSTD1Or2PercentageValueText}}</h6>
                </div>
                <div v-else-if="BanklineSTD1Or2PercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{BanklineSTD1Or2PercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="BanklineSTD1Or2FixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{BanklineSTD1Or2FixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Bankline STD 0 day payment -->
              <div class="row form-group">
                <label class="label-control col-md-3">Bankline STD 0 day payment</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.BanklineSTD0Percentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="BanklineSTD0Percentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.BanklineSTD0FixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="BanklineSTD0FieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="BanklineSTD0PercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{BanklineSTD0PercentageValueText}}</h6>
                </div>
                <div v-else-if="BanklineSTD0PercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{BanklineSTD0PercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="BanklineSTD0FixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{BanklineSTD0FixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Bankline URG Payment -->
              <div class="row form-group">
                <label class="label-control col-md-3">Bankline URG Payment</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.BanklineUrgentPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="BanklineUrgentPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.BanklineUrgentFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="BanklineUrgentFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="BanklineUrgentPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{BanklineUrgentPercentageValueText}}</h6>
                </div>
                <div v-else-if="BanklineUrgentPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{BanklineUrgentPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="BanklineUrgentFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{BanklineUrgentFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Fill All Ad Hoc Fees-->
              <div class="row form-group">
                <label class="label-control col-md-3">
                  <strong>Fill All Ad-Hoc Fees</strong>
                </label>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="AdHocPercentageFill"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="ProcessingFeesFill"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-1">
                  <button
                    class="btn btn-secondary"
                    @click="onFillAdHocFeesPercentageFill"
                    type="button"
                  >
                    Fill
                    <i class="far fa-arrow-alt-circle-up"></i>
                  </button>
                </div>

                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput v-model="AdHocFixedFeeFill" currency="GBP" class="form-control"></CurrencyInput>
                  </div>
                </div>
                <div class="col-sm-1">
                  <button
                    class="btn btn-secondary"
                    @click="onFillAdHocFeesFixedFeeFill"
                    type="button"
                  >
                    Fill
                    <i class="far fa-arrow-alt-circle-up"></i>
                  </button>
                </div>
              </div>
            </b-collapse>
          </b-card>

          <!-- Setup Fees -->
          <b-card no-body>
            <b-card-header header-tag="header" class="p-2" role="tab">
              <b-row v-b-toggle="'setupfees'" class="payment-header pt-0">
                <b-col label cols="12">Setup Fees</b-col>
              </b-row>
            </b-card-header>

            <b-collapse
              :id="'setupfees'"
              accordion="my-accordion"
              @show="onShowSetupFees()"
              @hide="onHideSetupFees()"
              role="tabpanel"
            >
              <!-- BACS Migration -->
              <div class="row form-group">
                <label class="label-control col-md-3">BACS Migration</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.BACSMigrationPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="BACSMigrationPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.BACSMigrationFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="BACSMigrationFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="BACSMigrationPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{BACSMigrationPercentageValueText}}</h6>
                </div>
                <div v-else-if="BACSMigrationPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{BACSMigrationPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="BACSMigrationFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{BACSMigrationFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Customer Set Up -->
              <div class="row form-group">
                <label class="label-control col-md-3">Customer Set Up</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.CustomerSetupPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="CustomerSetupPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.CustomerSetupFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="CustomerSetupFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="CustomerSetupPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{CustomerSetupPercentageValueText}}</h6>
                </div>
                <div v-else-if="CustomerSetupPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{CustomerSetupPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="CustomerSetupFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{CustomerSetupFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- FM Service User Setup -->
              <div class="row form-group">
                <label class="label-control col-md-3">FM Service User Setup</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.FMServiceUserSetupPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="FMServiceUserSetupPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.FMServiceUserSetupFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="FMServiceUserFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="FMServiceUserSetupPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{FMServiceUserSetupPercentageValueText}}</h6>
                </div>
                <div v-else-if="FMServiceUserSetupPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{FMServiceUserSetupPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="FMServiceUserSetupFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{FMServiceUserSetupFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Payer Migration -->
              <div class="row form-group">
                <label class="label-control col-md-3">Customer Migration</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.PayerMigrationPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="PayerMigrationPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.PayerMigrationFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="PayerMigrationFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="PayerMigrationPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{PayerMigrationPercentageValueText}}</h6>
                </div>
                <div v-else-if="PayerMigrationPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{PayerMigrationPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="PayerMigrationFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{PayerMigrationFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Reports -->
              <div class="row form-group">
                <label class="label-control col-md-3">Reports</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.ReportsPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="ReportsPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.ReportsFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="ReportsFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="ReportsPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{ReportsPercentageValueText}}</h6>
                </div>
                <div v-else-if="ReportsPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{ReportsPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="ReportsFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{ReportsFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Service User Modification -->
              <div class="row form-group">
                <label class="label-control col-md-3">Service User Modification</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.ServiceUserModificationPercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="ServiceUserModificationPercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.ServiceUserModificationFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="ServiceUserModificationFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="ServiceUserModificationPercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{ServiceUserModificationPercentageValueText}}</h6>
                </div>
                <div v-else-if="ServiceUserModificationPercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{ServiceUserModificationPercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="ServiceUserModificationFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{ServiceUserModificationFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Fill All Set Up Fees-->
              <div class="row form-group">
                <label class="label-control col-md-3">
                  <strong>Fill All Setup Fees</strong>
                </label>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="SetupFeesPercentageFill"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="SetupFeesFill"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-1">
                  <button
                    class="btn btn-secondary"
                    @click="onFillSetupFeesPercentageFill"
                    type="button"
                  >
                    Fill
                    <i class="far fa-arrow-alt-circle-up"></i>
                  </button>
                </div>

                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="SetupFeesFixedFeeFill"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
                <div class="col-sm-1">
                  <button
                    class="btn btn-secondary"
                    @click="onFillSetupFeesFixedFeeFill"
                    type="button"
                  >
                    Fill
                    <i class="far fa-arrow-alt-circle-up"></i>
                  </button>
                </div>
              </div>
            </b-collapse>
          </b-card>

          <!-- Periodic Charges -->
          <b-card no-body>
            <b-card-header header-tag="header" class="p-2" role="tab">
              <b-row v-b-toggle="'periodiccharges'" class="payment-header pt-0">
                <b-col label cols="12">Periodic Charges</b-col>
              </b-row>
            </b-card-header>

            <b-collapse
              :id="'periodiccharges'"
              accordion="my-accordion"
              @show="onShowPeriodicCharges()"
              @hide="onHidePeriodicCharges()"
              role="tabpanel"
            >
              <!-- Admin Charge -->
              <div class="row form-group">
                <label class="label-control col-md-3">Admin Charge</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.AdminChargePercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="AdminChargePercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.AdminChargeFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="AdminChargeFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="AdminChargePercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{AdminChargePercentageValueText}}</h6>
                </div>
                <div v-else-if="AdminChargePercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{AdminChargePercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="AdminChargeFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{AdminChargeFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- BACS File Submission Charge -->
              <div class="row form-group">
                <label class="label-control col-md-3">BACS File Submission Charge</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.BACSFileSubmissionChargePercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="BACSFileSubmissionChargePercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.BACSFileSubmissionChargeFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
                <div v-if="BACSFileSubmissionChargeFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="BACSFileSubmissionChargePercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{BACSFileSubmissionChargePercentageValueText}}</h6>
                </div>
                <div v-else-if="BACSFileSubmissionChargePercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{BACSFileSubmissionChargePercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="BACSFileSubmissionChargeFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{BACSFileSubmissionChargeFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- OSU Charge -->
              <div class="row form-group">
                <label class="label-control col-md-3">OSU Charge</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="$v.detail.OSUChargePercentage.$model"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="OSUChargePercentage"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="$v.detail.OSUChargeFixedFee.$model"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>

                <div v-if="OSUChargeFieldControl" class="col-sm-3">
                  <h6 class="text-danger">{{FieldsWarning}}</h6>
                </div>
                <div v-else class="col-sm-3"></div>
                <div v-if="OSUChargePercentageError" class="col-sm-4">
                  <h6 class="text-danger">{{OSUChargePercentageValueText}}</h6>
                </div>
                <div v-else-if="OSUChargePercentageWarn" class="col-sm-4">
                  <h6 class="text-warning">{{OSUChargePercentageValueText}}</h6>
                </div>
                <div v-else class="col-sm-4"></div>
                <div v-if="OSUChargeFixedFeeWarn" class="col-sm-4 pull-right">
                  <h6 class="text-warning">{{OSUChargeFixedFeeValueText}}</h6>
                </div>
              </div>

              <!-- Fill All Periodic Charges-->
              <div class="row form-group">
                <label class="label-control col-md-3">
                  <strong>Fill All Periodic Charges</strong>
                </label>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-text">%</span>
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model.trim="PeriodicChargesPercentageFill"
                      :mask="['#%','##%']"
                      :guide="true"
                      ref="PeriodicChargesFill"
                    ></the-mask>
                  </div>
                </div>
                <div class="col-sm-1">
                  <button
                    class="btn btn-secondary"
                    @click="onFillPeriodicChargesPercentageFill"
                    type="button"
                  >
                    Fill
                    <i class="far fa-arrow-alt-circle-up"></i>
                  </button>
                </div>

                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-text">£</span>

                    <CurrencyInput
                      v-model="PeriodicChargesFixedFeeFill"
                      currency="GBP"
                      class="form-control"
                    ></CurrencyInput>
                  </div>
                </div>
                <div class="col-sm-1">
                  <button
                    class="btn btn-secondary"
                    @click="onFillPeriodicChargesFixedFeeFill"
                    type="button"
                  >
                    Fill
                    <i class="far fa-arrow-alt-circle-up"></i>
                  </button>
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-footer">
          <button class="btn btn-primary" :disabled="disableSave" @click="onSaveDetailClick">Save</button>

          <button class="btn btn-danger pull-right" @click="onCanceDetailClick" type="button">
            <i class="glyphicon ti-trash rpad"></i>Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import {
  required,
  maxLength,
  numeric,
  minValue,
  maxValue,
  between
} from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import VueSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
export default {
  mixins: [loading, tableFilterMixin, bacsMixin],
  components: { VueSelect, TheMask, Datepicker },
  props: {
    agentid: String
  },
  data () {
    return {

      // Date picker stuff
      dateType: 'date',
      selectedDate: new Date(),
      selectedEndDate: new Date(),
      EndDateEnable: false,
      disabledDates: {

      },
      disabledEndDates: {
        to: new Date(2021, 0, 1),
        from: this.selectedDate
      },
      endDateWarnVisible: false,
      AgentName: '',
      showWarn: false,
      // Association Detail
      detail: {
        AgentId: '',
        CustomerName: '',
        PaygateId: '',
        EffectiveDate: new Date(),
        EndDate: '',
        AssociationName: '',

        CreditNotePercentage: '',
        TerminationFeePercentage: '',
        BanklineSTD1Or2Percentage: '',
        BanklineSTD0Percentage: '',
        BanklineUrgentPercentage: '',
        OSUChargePercentage: '',
        BACSFileSubmissionChargePercentage: '',
        AdminChargePercentage: '',
        BACSMigrationPercentage: '',
        CustomerSetupPercentage: '',
        FMServiceUserSetupPercentage: '',
        PayerMigrationPercentage: '',
        ReportsPercentage: '',
        ServiceUserModificationPercentage: '',

        AdvanceNoticeLetterPercentage: '',
        AdvanceNoticeEmailPercentage: '',
        AdvanceNoticeSMSPercentage: '',
        CollectionsPercentage: '',
        ConfirmationLetterPercentage: '',
        FailedCollectionsPercentage: '',
        IndemnityClaimsPercentage: '',
        PayerCancellationPercentage: '',
        PayerReinstatePercentage: '',
        PayerSetupPercentage: '',
        RefundsPercentage: '',
        ValidateBankAccountPercentage: '',
        ValidateIBANPercentage: '',

        AdvanceNoticeLetterFixedFee: '',
        AdvanceNoticeEmailFixedFee: '',
        AdvanceNoticeSMSFixedFee: '',
        CollectionsFixedFee: '',
        ConfirmationLetterFixedFee: '',
        FailedCollectionsFixedFee: '',
        IndemnityClaimsFixedFee: '',
        PayerCancellationFixedFee: '',
        PayerReinstateFixedFee: '',
        PayerSetupFixedFee: '',
        RefundsFixedFee: '',
        ValidateBankAccountFixedFee: '',
        ValidateIBANFixedFee: '',

        CreditNoteFixedFee: '',
        TerminationFeeFixedFee: '',
        BanklineSTD1Or2FixedFee: '',
        BanklineSTD0FixedFee: '',
        BanklineUrgentFixedFee: '',
        OSUChargeFixedFee: '',
        BACSFileSubmissionChargeFixedFee: '',
        AdminChargeFixedFee: '',
        BACSMigrationFixedFee: '',
        CustomerSetupFixedFee: '',
        FMServiceUserSetupFixedFee: '',
        PayerMigrationFixedFee: '',
        ReportsFixedFee: '',
        ServiceUserModificationFixedFee: ''

      },

      // Customer Details
      selectedCustomer: {},
      customers: [],

      // ***** Committed Associations
      // Percentages
      committedAssociations: {},
      CommittedAssociationErrorsWarning: false,
      ExistingAssociationWarningText: '',
      ShowExistingAssociationWarning: false,
      AdvanceNoticeLetterPercentageValueText: '',
      AdvanceNoticeLetterPercentageWarn: false,
      AdvanceNoticeLetterPercentageError: false,
      AdvanceNoticeEmailPercentageWarn: false,
      AdvanceNoticeEmailPercentageError: false,
      AdvanceNoticeEmailPercentageValueText: '',
      AdvanceNoticeSMSPercentageWarn: false,
      AdvanceNoticeSMSPercentageError: false,
      AdvanceNoticeSMSPercentageValueText: '',
      CollectionsPercentageWarn: false,
      CollectionsPercentageError: false,
      CollectionsPercentageValueText: '',
      ConfirmationLetterPercentageWarn: false,
      ConfirmationLetterPercentageError: false,
      ConfirmationLetterPercentageValueText: '',
      FailedCollectionsPercentageWarn: false,
      FailedCollectionsPercentageError: false,
      FailedCollectionsPercentageValueText: '',
      IndemnityClaimsPercentageWarn: false,
      IndemnityClaimsPercentageError: false,
      IndemnityClaimsPercentageValueText: '',
      PayerCancellationPercentageWarn: false,
      PayerCancellationPercentageError: false,
      PayerCancellationPercentageValueText: '',
      PayerReinstatePercentageWarn: false,
      PayerReinstatePercentageError: false,
      PayerReinstatePercentageValueText: '',
      PayerSetupPercentageWarn: false,
      PayerSetupPercentageError: false,
      PayerSetupPercentageValueText: '',
      RefundsPercentageWarn: false,
      RefundsPercentageError: false,
      RefundsPercentageValueText: '',
      ValidateBankAccountPercentageWarn: false,
      ValidateBankAccountPercentageError: false,
      ValidateBankAccountPercentageValueText: '',
      ValidateIBANPercentageWarn: false,
      ValidateIBANPercentageError: false,
      ValidateIBANPercentageValueText: '',
      CreditNotePercentageWarn: false,
      CreditNotePercentageError: false,
      CreditNotePercentageValueText: '',
      TerminationFeePercentageWarn: false,
      TerminationFeePercentageError: false,
      TerminationFeePercentageValueText: '',
      BanklineSTD1Or2PercentageWarn: false,
      BanklineSTD1Or2PercentageError: false,
      BanklineSTD1Or2PercentageValueText: '',
      BanklineSTD0PercentageWarn: false,
      BanklineSTD0PercentageError: false,
      BanklineSTD0PercentageValueText: '',
      BanklineUrgentPercentageWarn: false,
      BanklineUrgentPercentageError: false,
      BanklineUrgentPercentageValueText: '',
      BACSMigrationPercentageWarn: false,
      BACSMigrationPercentageError: false,
      BACSMigrationPercentageValueText: '',
      CustomerSetupPercentageWarn: false,
      CustomerSetupPercentageError: false,
      CustomerSetupPercentageValueText: '',
      FMServiceUserSetupPercentageWarn: false,
      FMServiceUserSetupPercentageError: false,
      FMServiceUserSetupPercentageValueText: '',
      PayerMigrationPercentageWarn: false,
      PayerMigrationPercentageError: false,
      PayerMigrationPercentageValueText: '',
      ReportsPercentageWarn: false,
      ReportsPercentageError: false,
      ReportsPercentageValueText: '',
      ServiceUserModificationPercentageWarn: false,
      ServiceUserModificationPercentageError: false,
      ServiceUserModificationPercentageValueText: '',
      OSUChargePercentageWarn: false,
      OSUChargePercentageError: false,
      OSUChargePercentageValueText: '',
      BACSFileSubmissionChargePercentageWarn: false,
      BACSFileSubmissionChargePercentageError: false,
      BACSFileSubmissionChargePercentageValueText: '',
      AdminChargePercentageWarn: false,
      AdminChargePercentageError: false,
      AdminChargePercentageValueText: '',

      // Fixed Fee
      AdvanceNoticeLetterFixedFeeWarn: false,
      AdvanceNoticeLetterFixedFeeValueText: '',
      AdvanceNoticeEmailFixedFeeWarn: false,
      AdvanceNoticeEmailFixedFeeValueText: '',
      AdvanceNoticeSMSFixedFeeWarn: false,
      AdvanceNoticeSMSFixedFeeValueText: '',
      CollectionsFixedFeeWarn: false,
      CollectionsFixedFeeValueText: '',
      ConfirmationLetterFixedFeeWarn: false,
      ConfirmationLetterFixedFeeValueText: '',
      FailedCollectionsFixedFeeWarn: false,
      FailedCollectionsFixedFeeValueText: '',
      IndemnityClaimsFixedFeeWarn: false,
      IndemnityClaimsFixedFeeValueText: '',
      PayerCancellationFixedFeeWarn: false,
      PayerCancellationFixedFeeValueText: '',
      PayerReinstateFixedFeeWarn: false,
      PayerReinstateFixedFeeValueText: '',
      PayerSetupFixedFeeWarn: false,
      PayerSetupFixedFeeValueText: '',
      RefundsFixedFeeWarn: false,
      RefundsFixedFeeValueText: '',
      ValidateBankAccountFixedFeeWarn: false,
      ValidateBankAccountFixedFeeValueText: '',
      ValidateIBANFixedFeeWarn: false,
      VlidateIBANFixedFeeValueText: '',
      CreditNoteFixedFeeWarn: false,
      CreditNoteFixedFeeValueText: '',
      TerminationFeeFixedFeeWarn: false,
      TerminationFeeFixedFeeValueText: '',
      BanklineSTD1Or2FixedFeeWarn: false,
      BanklineSTD1Or2FixedFeeValueText: '',
      BanklineSTD0FixedFeeWarn: false,
      BanklineSTD0FixedFeeValueText: '',
      BanklineUrgentFixedFeeWarn: false,
      BanklineUrgentFixedFeeValueText: '',
      OSUChargeFixedFeeWarn: false,
      OSUChargeFixedFeeValueText: '',
      BACSFileSubmissionChargeFixedFeeWarn: false,
      BACSFileSubmissionChargeFixedFeeValueText: '',
      AdminChargeFixedFeeWarn: false,
      AdminChargeFixedFeeValueText: '',
      BACSMigrationFixedFeeWarn: false,
      BACSMigrationFixedFeeValueText: '',
      CustomerSetupFixedFeeWarn: false,
      CustomerSetupFixedFeeValueText: '',
      FMServiceUserSetupFixedFeeWarn: false,
      FMServiceUserSetupFixedFeeValueText: '',
      PayerMigrationFixedFeeWarn: false,
      PayerMigrationFixedFeeValueText: '',
      ReportsFixedFeeWarn: false,
      ReportsFixedFeeValueText: '',
      ServiceUserModificationFixedFeeWarn: false,
      ServiceUserModificationFixedFeeValueText: '',

      // Fill Down Variables
      ProcessingFeesPercentageFill: '',
      ProcessingFeesFixedFeeFill: '',

      AdHocPercentageFill: '',
      AdHocFixedFeeFill: '',

      SetupFeesPercentageFill: '',
      SetupFeesFixedFeeFill: '',

      PeriodicChargesPercentageFill: '',
      PeriodicChargesFixedFeeFill: '',

      // Page Control
      disableSave: true,

      // Field Warnings
      FieldsWarning: 'You must enter only either the percentage or the fixed fee field',
      CreditNoteFieldControl: false,
      TerminationFeeFieldControl: false,
      BanklineSTD1Or2FieldControl: false,
      BanklineSTD0FieldControl: false,
      BanklineUrgentFieldControl: false,
      OSUChargeFieldControl: false,
      BACSFileSubmissionChargeFieldControl: false,
      AdminChargeFieldControl: false,
      BACSMigrationFieldControl: false,
      CustomerSetupFieldControl: false,
      FMServiceUserFieldControl: false,
      PayerMigrationFieldControl: false,
      ReportsFieldControl: false,
      ServiceUserModificationFieldControl: false,
      AdvanceNoticeLetterFieldControl: false,
      AdvanceNoticeEmailFieldControl: false,
      AdvanceNoticeSMSFieldControl: false,
      CollectionsFieldControl: false,
      ConfirmationLetterFieldControl: false,
      FailedCollectionsFieldControl: false,
      IndemnityClaimsFieldControl: false,
      PayerCancellationFieldControl: false,
      PayerReinstateFieldControl: false,
      PayerSetupFieldControl: false,
      RefundsFieldControl: false,
      ValidateBankAccountFieldControl: false,
      ValidateIBANFieldControl: false

    }
  },
  watch: {
    selectedCustomer: function () {
      console.log('running validate after select customer')
      this.runValidate()
    },

    EffectiveDate: function () {
      this.runValidate()
    },
    EndDate: function () {
      this.runValidate()
    },

    'detail.CreditNotePercentage': function () { this.runValidate() },
    'detail.TerminationFeePercentage': function () { this.runValidate() },
    'detail.BanklineSTD1Or2Percentage': function () { this.runValidate() },
    'detail.BanklineSTD0Percentage': function () { this.runValidate() },
    'detail.BanklineUrgentPercentage': function () { this.runValidate() },
    'detail.OSUChargePercentage': function () { this.runValidate() },
    'detail.BACSFileSubmissionChargePercentage': function () { this.runValidate() },
    'detail.AdminChargePercentage': function () { this.runValidate() },
    'detail.BACSMigrationPercentage': function () { this.runValidate() },
    'detail.CustomerSetupPercentage': function () { this.runValidate() },
    'detail.FMServiceUserSetupPercentage': function () { this.runValidate() },
    'detail.PayerMigrationPercentage': function () { this.runValidate() },
    'detail.ReportsPercentage': function () { this.runValidate() },
    'detail.ServiceUserModificationPercentage': function () { this.runValidate() },
    'detail.AdvanceNoticeLetterPercentage': function () { this.runValidate() },
    'detail.AdvanceNoticeEmailPercentage': function () { this.runValidate() },
    'detail.AdvanceNoticeSMSPercentage': function () { this.runValidate() },
    'detail.CollectionsPercentage': function () { this.runValidate() },
    'detail.ConfirmationLetterPercentage': function () { this.runValidate() },
    'detail.FailedCollectionsPercentage': function () { this.runValidate() },
    'detail.IndemnityClaimsPercentage': function () { this.runValidate() },
    'detail.PayerCancellationPercentage': function () { this.runValidate() },
    'detail.PayerReinstatePercentage': function () { this.runValidate() },
    'detail.PayerSetupPercentage': function () { this.runValidate() },
    'detail.RefundsPercentage': function () { this.runValidate() },
    'detail.ValidateBankAccountPercentage': function () { this.runValidate() },
    'detail.ValidateIBANPercentage': function () { this.runValidate() },
    'detail.CreditNoteFixedFee': function () { this.runValidate() },
    'detail.TerminationFeeFixedFee': function () { this.runValidate() },
    'detail.BanklineSTD1Or2FixedFee': function () { this.runValidate() },
    'detail.BanklineSTD0FixedFee': function () { this.runValidate() },
    'detail.BanklineUrgentFixedFee': function () { this.runValidate() },
    'detail.OSUChargeFixedFee': function () { this.runValidate() },
    'detail.BACSFileSubmissionChargeFixedFee': function () { this.runValidate() },
    'detail.AdminChargeFixedFee': function () { this.runValidate() },
    'detail.BACSMigrationFixedFee': function () { this.runValidate() },
    'detail.CustomerSetupFixedFee': function () { this.runValidate() },
    'detail.FMServiceUserSetupFixedFee': function () { this.runValidate() },
    'detail.PayerMigrationFixedFee': function () { this.runValidate() },
    'detail.ReportsFixedFee': function () { this.runValidate() },
    'detail.ServiceUserModificationFixedFee': function () { this.runValidate() },
    'detail.AdvanceNoticeLetterFixedFee': function () { this.runValidate() },
    'detail.AdvanceNoticeEmailFixedFee': function () { this.runValidate() },
    'detail.AdvanceNoticeSMSFixedFee': function () { this.runValidate() },
    'detail.CollectionsFixedFee': function () { this.runValidate() },
    'detail.ConfirmationLetterFixedFee': function () { this.runValidate() },
    'detail.FailedCollectionsFixedFee': function () { this.runValidate() },
    'detail.IndemnityClaimsFixedFee': function () { this.runValidate() },
    'detail.PayerCancellationFixedFee': function () { this.runValidate() },
    'detail.PayerReinstateFixedFee': function () { this.runValidate() },
    'detail.PayerSetupFixedFee': function () { this.runValidate() },
    'detail.RefundsFixedFee': function () { this.runValidate() },
    'detail.ValidateBankAccountFixedFee': function () { this.runValidate() },
    'detail.ValidateIBANFixedFee': function () { this.runValidate() }
  },
  methods: {
    async load () {
      this.detail.AgentId = this.agentid

      //  await this.GetScreenControl()
      await this.GetAgent()
      await this.GetCustomerListings()

      this.$v.$reset()
    },

    GetCustomerListings: async function () {
      // Get customer listings
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetCustomers`,
          {
            showerror: true,
            errormessage: 'Failed loading commission agent associations'
          })

        console.log('response', response)
        this.customers = response.data
      } catch { } finally {

      }
    },

    GetAgent: async function () {
      var agentResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetAgent/${this.agentid}`)
      console.log('agentResponse', agentResponse)
      this.AgentName = agentResponse.data.name
    },

    GetScreenControl: async function () {
      // Get customer listings
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CASCGetAssociationScreen/${this.agentid}/${this.contactid}`,
          {
            showerror: true,
            errormessage: 'Failed loading commission agent associations'
          })

        console.log('response', response)

        this.AgentName = response.data.agentName
        this.ContactName = response.data.contactName
      } catch { } finally {

      }
    },

    async onUpdateModelDates () {
      console.log('update model dates')
      // Get the dates from the date picker and get them into the model
      var showWarn = false
      this.detail.EffectiveDate = this.selectedDate
      if (this.EndDateEnable) {
        if (this.selectedDate > this.selectedEndDate) {
          console.log('here', moment(this.selectedDate).format('DD/MM/yyyy'))
          this.selectedEndDate = this.selectedDate
          showWarn = true
        }

        this.detail.EndDate = this.selectedEndDate
      } else {
        this.detail.EndDate = ''
      }

      if (showWarn) {
        this.endDateWarnVisible = true
        setTimeout(() => { this.endDateWarnVisible = false }, 5000)
      }

      await this.UpdateCurrentCommitment()
    },

    onGoToExistingAssociation () {
      console.log('Go To associationId', this.committedAssociations.currentAgentAssociationId)

      this.$router.push({
        name: 'CommissionAgentViewAssociation',
        params: { associationid: this.committedAssociations.currentAgentAssociationId }
      })
    },

    onSaveDetailClick: async function () {
      var swalText = 'Save this association?'
      if (this.CommittedAssociationErrorsWarning) {
        swalText = 'There are existing associations which in combination with this association mean that some items will <strong>exceed 100%</strong> in commission value. Are you sure you want to do this?'
      }

      try {
        var swalResult = await swal.fire({
          title: 'Add New Commission Association',
          html: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }

      if (!swalResult.isConfirmed) {
        console.log('declined')
      } else {
        console.log('onSaveDetailClick', this.detail)
        this.detail.PaygateId = this.selectedCustomer.paygateId
        this.detail.AgentId = this.agentid
        this.detail.CustomerName = this.selectedCustomer.customerName

        try {
          this.$nextTick(() => {
            this.$v.$reset()
          })
          var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CASaveContactAssociation`, this.detail, { showload: true, showerror: true, errormessage: 'Agent Association failed to create' })
          console.log('response', response)

          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
            this.$router.push({
              name: 'CommissionAgentListAssociations',
              params: { agentid: this.agentid }
            })
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        }
      }
    },
    onCanceDetailClick () {
      this.$router.push({
        name: 'CommissionAgentListAssociations',
        params: { agentid: this.agentid }
      })
    },

    onFillProcessingFeesPercentageFill () {
      if (this.ProcessingFeesPercentageFill === 0 || this.ProcessingFeesPercentageFill === '') {
        this.detail.AdvanceNoticeLetterPercentage = ''
        this.detail.AdvanceNoticeEmailPercentage = ''
        this.detail.AdvanceNoticeSMSPercentage = ''
        this.detail.CollectionsPercentage = ''
        this.detail.ConfirmationLetterPercentage = ''
        this.detail.FailedCollectionsPercentage = ''
        this.detail.IndemnityClaimsPercentage = ''
        this.detail.PayerCancellationPercentage = ''
        this.detail.PayerReinstatePercentage = ''
        this.detail.PayerSetupPercentage = ''
        this.detail.RefundsPercentage = ''
        this.detail.ValidateBankAccountPercentage = ''
        this.detail.ValidateIBANPercentage = ''
      } else {
        this.detail.AdvanceNoticeLetterPercentage = this.ProcessingFeesPercentageFill
        this.detail.AdvanceNoticeEmailPercentage = this.ProcessingFeesPercentageFill
        this.detail.AdvanceNoticeSMSPercentage = this.ProcessingFeesPercentageFill
        this.detail.CollectionsPercentage = this.ProcessingFeesPercentageFill
        this.detail.ConfirmationLetterPercentage = this.ProcessingFeesPercentageFill
        this.detail.FailedCollectionsPercentage = this.ProcessingFeesPercentageFill
        this.detail.IndemnityClaimsPercentage = this.ProcessingFeesPercentageFill
        this.detail.PayerCancellationPercentage = this.ProcessingFeesPercentageFill
        this.detail.PayerReinstatePercentage = this.ProcessingFeesPercentageFill
        this.detail.PayerSetupPercentage = this.ProcessingFeesPercentageFill
        this.detail.RefundsPercentage = this.ProcessingFeesPercentageFill
        this.detail.ValidateBankAccountPercentage = this.ProcessingFeesPercentageFill
        this.detail.ValidateIBANPercentage = this.ProcessingFeesPercentageFill
      }
    },
    onFillProcessingFeesFixedFeeFill () {
      if (this.ProcessingFeesFixedFeeFill === 0 || this.ProcessingFeesFixedFeeFill === '') {
        this.detail.AdvanceNoticeLetterFixedFee = ''
        this.detail.AdvanceNoticeEmailFixedFee = ''
        this.detail.AdvanceNoticeSMSFixedFee = ''
        this.detail.CollectionsFixedFee = ''
        this.detail.ConfirmationLetterFixedFee = ''
        this.detail.FailedCollectionsFixedFee = ''
        this.detail.IndemnityClaimsFixedFee = ''
        this.detail.PayerCancellationFixedFee = ''
        this.detail.PayerReinstateFixedFee = ''
        this.detail.PayerSetupFixedFee = ''
        this.detail.RefundsFixedFee = ''
        this.detail.ValidateBankAccountFixedFee = ''
        this.detail.ValidateIBANFixedFee = ''
      } else {
        this.detail.AdvanceNoticeLetterFixedFee = this.ProcessingFeesFixedFeeFill
        this.detail.AdvanceNoticeEmailFixedFee = this.ProcessingFeesFixedFeeFill
        this.detail.AdvanceNoticeSMSFixedFee = this.ProcessingFeesFixedFeeFill
        this.detail.CollectionsFixedFee = this.ProcessingFeesFixedFeeFill
        this.detail.ConfirmationLetterFixedFee = this.ProcessingFeesFixedFeeFill
        this.detail.FailedCollectionsFixedFee = this.ProcessingFeesFixedFeeFill
        this.detail.IndemnityClaimsFixedFee = this.ProcessingFeesFixedFeeFill
        this.detail.PayerCancellationFixedFee = this.ProcessingFeesFixedFeeFill
        this.detail.PayerReinstateFixedFee = this.ProcessingFeesFixedFeeFill
        this.detail.PayerSetupFixedFee = this.ProcessingFeesFixedFeeFill
        this.detail.RefundsFixedFee = this.ProcessingFeesFixedFeeFill
        this.detail.ValidateBankAccountFixedFee = this.ProcessingFeesFixedFeeFill
        this.detail.ValidateIBANFixedFee = this.ProcessingFeesFixedFeeFill
      }
    },

    onFillAdHocFeesPercentageFill () {
      if (this.AdHocPercentageFill === 0 || this.AdHocPercentageFill === '') {
        this.detail.CreditNotePercentage = ''
        this.detail.TerminationFeePercentage = ''
        this.detail.BanklineSTD1Or2Percentage = ''
        this.detail.BanklineSTD0Percentage = ''
        this.detail.BanklineUrgentPercentage = ''
      } else {
        this.detail.CreditNotePercentage = this.AdHocPercentageFill
        this.detail.TerminationFeePercentage = this.AdHocPercentageFill
        this.detail.BanklineSTD1Or2Percentage = this.AdHocPercentageFill
        this.detail.BanklineSTD0Percentage = this.AdHocPercentageFill
        this.detail.BanklineUrgentPercentage = this.AdHocPercentageFill
      }
    },
    onFillAdHocFeesFixedFeeFill () {
      if (this.AdHocFixedFeeFill === 0 || this.AdHocFixedFeeFill === '') {
        this.detail.CreditNoteFixedFee = ''
        this.detail.TerminationFeeFixedFee = ''
        this.detail.BanklineSTD1Or2FixedFee = ''
        this.detail.BanklineSTD0FixedFee = ''
        this.detail.BanklineUrgentFixedFee = ''
      } else {
        this.detail.CreditNoteFixedFee = this.AdHocFixedFeeFill
        this.detail.TerminationFeeFixedFee = this.AdHocFixedFeeFill
        this.detail.BanklineSTD1Or2FixedFee = this.AdHocFixedFeeFill
        this.detail.BanklineSTD0FixedFee = this.AdHocFixedFeeFill
        this.detail.BanklineUrgentFixedFee = this.AdHocFixedFeeFill
      }
    },

    onFillSetupFeesPercentageFill () {
      if (this.SetupFeesPercentageFill === 0 || this.SetupFeesPercentageFill === '') {
        this.detail.BACSMigrationPercentage = ''
        this.detail.CustomerSetupPercentage = ''
        this.detail.FMServiceUserSetupPercentage = ''
        this.detail.PayerMigrationPercentage = ''
        this.detail.ReportsPercentage = ''
        this.detail.ServiceUserModificationPercentage = ''
      } else {
        this.detail.BACSMigrationPercentage = this.SetupFeesPercentageFill
        this.detail.CustomerSetupPercentage = this.SetupFeesPercentageFill
        this.detail.FMServiceUserSetupPercentage = this.SetupFeesPercentageFill
        this.detail.PayerMigrationPercentage = this.SetupFeesPercentageFill
        this.detail.ReportsPercentage = this.SetupFeesPercentageFill
        this.detail.ServiceUserModificationPercentage = this.SetupFeesPercentageFill
      }
    },
    onFillSetupFeesFixedFeeFill () {
      if (this.SetupFeesFixedFeeFill === 0 || this.SetupFeesFixedFeeFill === '') {
        this.detail.BACSMigrationFixedFee = ''
        this.detail.CustomerSetupFixedFee = ''
        this.detail.FMServiceUserSetupFixedFee = ''
        this.detail.PayerMigrationFixedFee = ''
        this.detail.ReportsFixedFee = ''
        this.detail.ServiceUserModificationFixedFee = ''
      } else {
        this.detail.BACSMigrationFixedFee = this.SetupFeesFixedFeeFill
        this.detail.CustomerSetupFixedFee = this.SetupFeesFixedFeeFill
        this.detail.FMServiceUserSetupFixedFee = this.SetupFeesFixedFeeFill
        this.detail.PayerMigrationFixedFee = this.SetupFeesFixedFeeFill
        this.detail.ReportsFixedFee = this.SetupFeesFixedFeeFill
        this.detail.ServiceUserModificationFixedFee = this.SetupFeesFixedFeeFill
      }
    },

    onFillPeriodicChargesPercentageFill () {
      if (this.PeriodicChargesPercentageFill === 0 || this.PeriodicChargesPercentageFill === '') {
        this.detail.OSUChargePercentage = ''
        this.detail.BACSFileSubmissionChargePercentage = ''
        this.detail.AdminChargePercentage = ''
      } else {
        this.detail.OSUChargePercentage = this.PeriodicChargesPercentageFill
        this.detail.BACSFileSubmissionChargePercentage = this.PeriodicChargesPercentageFill
        this.detail.AdminChargePercentage = this.PeriodicChargesPercentageFill
      }
    },
    onFillPeriodicChargesFixedFeeFill () {
      if (this.PeriodicChargesFixedFeeFill === 0 || this.PeriodicChargesFixedFeeFill === '') {
        this.detail.OSUChargeFixedFee = ''
        this.detail.BACSFileSubmissionChargeFixedFee = ''
        this.detail.AdminChargeFixedFee = ''
      } else {
        this.detail.OSUChargeFixedFee = this.PeriodicChargesFixedFeeFill
        this.detail.BACSFileSubmissionChargeFixedFee = this.PeriodicChargesFixedFeeFill
        this.detail.AdminChargeFixedFee = this.PeriodicChargesFixedFeeFill
      }
    },

    onShowProcessing () { },
    onHideProcessing () { },
    onShowAdhoc () { },
    onHideAdhoc () { },
    onShowSetupFees () { },
    onHideSetupFees () { },
    onShowPeriodicCharges () { },
    onHidePeriodicCharges () { },
    async selectCustomer () {
      this.detail.PaygateId = this.selectedCustomer.paygateId
      await this.UpdateCurrentCommitment()
      this.runValidate()
    },

    async UpdateCurrentCommitment () {
      // Get the current customer commitment
      try {
        var payload = {
          AgentId: this.agentid,
          PaygateId: this.detail.PaygateId,
          EffectiveDate: this.detail.EffectiveDate,
          EndDate: this.detail.EndDate
        }
        console.log('update commitment payload', payload)

        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}CommissionAgents/CAGetCurrentCustomerAssociationValues`, payload, { data: payload, showload: true, showerror: true, errormessage: 'Agent Association failed to get current commitment' })
        console.log('update commitment response', response)

        this.committedAssociations = response.data
        if (this.committedAssociations.alreadyHasAssociation) {
          // Agent already has an association for this customer
          this.ExistingAssociationWarningText = this.committedAssociations.alreadyHasAssociationRubric
          this.ShowExistingAssociationWarning = true
        } else {
          this.ShowExistingAssociationWarning = false
          this.ExistingAssociationWarningText = ''
        }
      } catch (e) {
      }
    },

    runValidate: function () {
      console.log('Running Validation')
      if (!this.$v.selectedCustomer.$invalid) {
        console.log('selected customer false')
        this.disableSave = false
      } else {
        console.log('selected customer true')
        this.disableSave = true
      }

      if (!this.$v.detail.AssociationName.$invalid) {
        console.log('description check false')
        this.disableSave = false
      } else {
        console.log('description check true')
        this.disableSave = true
      }

      if (this.detail.CreditNotePercentage.length > 0 && this.detail.CreditNoteFixedFee > 0) {
        this.CreditNoteFieldControl = true
        this.disableSave = true
      } else {
        this.CreditNoteFieldControl = false
      }

      if (this.detail.TerminationFeePercentage.length > 0 && this.detail.TerminationFeeFixedFee > 0) {
        this.TerminationFeeFieldControl = true
        this.disableSave = true
      } else {
        this.TerminationFeeFieldControl = false
      }

      if (this.detail.BanklineSTD1Or2Percentage.length > 0 && this.detail.BanklineSTD1Or2FixedFee > 0) {
        this.BanklineSTD1Or2FieldControl = true
        this.disableSave = true
      } else {
        this.BanklineSTD1Or2FieldControl = false
      }

      if (this.detail.BanklineSTD0Percentage.length > 0 && this.detail.BanklineSTD0FixedFee > 0) {
        this.BanklineSTD0FieldControl = true
        this.disableSave = true
      } else {
        this.BanklineSTD0FieldControl = false
      }

      if (this.detail.BanklineUrgentPercentage.length > 0 && this.detail.BanklineUrgentFixedFee > 0) {
        this.BanklineUrgentFieldControl = true
        this.disableSave = true
      } else {
        this.BanklineUrgentFieldControl = false
      }

      if (this.detail.OSUChargePercentage.length > 0 && this.detail.OSUChargeFixedFee > 0) {
        this.OSUChargeFieldControl = true
        this.disableSave = true
      } else {
        this.OSUChargeFieldControl = false
      }

      if (this.detail.BACSFileSubmissionChargePercentage.length > 0 && this.detail.BACSFileSubmissionChargeFixedFee > 0) {
        this.BACSFileSubmissionChargeFieldControl = true
        this.disableSave = true
      } else {
        this.BACSFileSubmissionChargeFieldControl = false
      }

      if (this.detail.AdminChargePercentage.length > 0 && this.detail.AdminChargeFixedFee > 0) {
        this.AdminChargeFieldControl = true
        this.disableSave = true
      } else {
        this.AdminChargeFieldControl = false
      }

      if (this.detail.BACSMigrationPercentage.length > 0 && this.detail.BACSMigrationFixedFee > 0) {
        this.BACSMigrationFieldControl = true
        this.disableSave = true
      } else {
        this.BACSMigrationFieldControl = false
      }

      if (this.detail.CustomerSetupPercentage.length > 0 && this.detail.CustomerSetupFixedFee > 0) {
        this.CustomerSetupFieldControl = true
        this.disableSave = true
      } else {
        this.CustomerSetupFieldControl = false
      }

      if (this.detail.FMServiceUserSetupPercentage.length > 0 && this.detail.FMServiceUserSetupFixedFee > 0) {
        this.FMServiceUserFieldControl = true
        this.disableSave = true
      } else {
        this.FMServiceUserFieldControl = false
      }

      if (this.detail.PayerMigrationPercentage.length > 0 && this.detail.PayerMigrationFixedFee > 0) {
        this.PayerMigrationFieldControl = true
        this.disableSave = true
      } else {
        this.PayerMigrationFieldControl = false
      }

      if (this.detail.ReportsPercentage.length > 0 && this.detail.ReportsFixedFee > 0) {
        this.ReportsFieldControl = true
        this.disableSave = true
      } else {
        this.ReportsFieldControl = false
      }

      if (this.detail.ServiceUserModificationPercentage.length > 0 && this.detail.ServiceUserModificationFixedFee > 0) {
        this.ServiceUserModificationFieldControl = true
        this.disableSave = true
      } else {
        this.ServiceUserModificationFieldControl = false
      }

      if (this.detail.AdvanceNoticeLetterPercentage.length > 0 && this.detail.AdvanceNoticeLetterFixedFee > 0) {
        this.AdvanceNoticeLetterFieldControl = true
        this.disableSave = true
      } else {
        this.AdvanceNoticeLetterFieldControl = false
      }
      if (this.detail.AdvanceNoticeEmailPercentage.length > 0 && this.detail.AdvanceNoticeEmailFixedFee > 0) {
        this.AdvanceNoticeEmailFieldControl = true
        this.disableSave = true
      } else {
        this.AdvanceNoticeEmailFieldControl = false
      }

      if (this.detail.AdvanceNoticeSMSPercentage.length > 0 && this.detail.AdvanceNoticeSMSFixedFee > 0) {
        this.AdvanceNoticeSMSFieldControl = true
        this.disableSave = true
      } else {
        this.AdvanceNoticeSMSFieldControl = false
      }

      if (this.detail.CollectionsPercentage.length > 0 && this.detail.CollectionsFixedFee > 0) {
        this.CollectionsFieldControl = true
        this.disableSave = true
      } else {
        this.CollectionsFieldControl = false
      }

      if (this.detail.ConfirmationLetterPercentage.length > 0 && this.detail.ConfirmationLetterFixedFee > 0) {
        this.ConfirmationLetterFieldControl = true
        this.disableSave = true
      } else {
        this.ConfirmationLetterFieldControl = false
      }

      if (this.detail.FailedCollectionsPercentage.length > 0 && this.detail.FailedCollectionsFixedFee > 0) {
        this.FailedCollectionsFieldControl = true
        this.disableSave = true
      } else {
        this.FailedCollectionsFieldControl = false
      }

      if (this.detail.IndemnityClaimsPercentage.length > 0 && this.detail.IndemnityClaimsFixedFee > 0) {
        this.IndemnityClaimsFieldControl = true
        this.disableSave = true
      } else {
        this.IndemnityClaimsFieldControl = false
      }

      if (this.detail.PayerCancellationPercentage.length > 0 && this.detail.PayerCancellationFixedFee > 0) {
        this.PayerCancellationFieldControl = true
        this.disableSave = true
      } else {
        this.PayerCancellationFieldControl = false
      }

      if (this.detail.PayerReinstatePercentage.length > 0 && this.detail.PayerReinstateFixedFee > 0) {
        this.PayerReinstateFieldControl = true
        this.disableSave = true
      } else {
        this.PayerReinstateFieldControl = false
      }

      if (this.detail.PayerSetupPercentage.length > 0 && this.detail.PayerSetupFixedFee > 0) {
        this.PayerSetupFieldControl = true
        this.disableSave = true
      } else {
        this.PayerSetupFieldControl = false
      }

      if (this.detail.RefundsPercentage.length > 0 && this.detail.RefundsFixedFee > 0) {
        this.RefundsFieldControl = true
        this.disableSave = true
      } else {
        this.RefundsFieldControl = false
      }

      if (this.detail.ValidateBankAccountPercentage.length > 0 && this.detail.ValidateBankAccountFixedFee > 0) {
        this.ValidateBankAccountFieldControl = true
        this.disableSave = true
      } else {
        this.ValidateBankAccountFieldControl = false
      }

      if (this.detail.ValidateIBANPercentage.length > 0 && this.detail.ValidateIBANFixedFee > 0) {
        this.ValidateIBANFieldControl = true
        this.disableSave = true
      } else {
        this.ValidateIBANFieldControl = false
      }

      if (this.detail.AdvanceNoticeLetterPercentage > 0 && this.committedAssociations.advanceNoticeLetterPercentage > 0) {
        var AdvanceNoticeLetterPercentageValue = parseInt(this.detail.AdvanceNoticeLetterPercentage) + parseInt(this.committedAssociations.advanceNoticeLetterPercentage)
        console.log('AdvanceNoticeLetterPercentageValue when added', AdvanceNoticeLetterPercentageValue)
        this.AdvanceNoticeLetterPercentageValueText = this.GetPercentValueText(AdvanceNoticeLetterPercentageValue)
        if (AdvanceNoticeLetterPercentageValue > 100) {
          this.AdvanceNoticeLetterPercentageWarn = false
          this.AdvanceNoticeLetterPercentageError = true
        } else {
          this.AdvanceNoticeLetterPercentageWarn = true
          this.AdvanceNoticeLetterPercentageError = false
        }
      } else {
        this.AdvanceNoticeLetterPercentageValueText = ''
        this.AdvanceNoticeLetterPercentageWarn = false
        this.AdvanceNoticeLetterPercentageError = false
      }

      if (this.detail.AdvanceNoticeEmailPercentage > 0 && this.committedAssociations.advanceNoticeEmailPercentage > 0) {
        var AdvanceNoticeEmailPercentageValue = parseInt(this.detail.AdvanceNoticeEmailPercentage) +
          parseInt(this.committedAssociations.advanceNoticeEmailPercentage)
        console.log('AdvanceNoticeEmailPercentageValue when added', AdvanceNoticeEmailPercentageValue)
        this.AdvanceNoticeEmailPercentageValueText = this.GetPercentValueText(AdvanceNoticeEmailPercentageValue)
        if (AdvanceNoticeEmailPercentageValue > 100) {
          this.AdvanceNoticeEmailPercentageWarn = false
          this.AdvanceNoticeEmailPercentageError = true
        } else {
          this.AdvanceNoticeEmailPercentageWarn = true
          this.AdvanceNoticeEmailPercentageError = false
        }
      } else {
        this.AdvanceNoticeEmailPercentageValueText = ''
        this.AdvanceNoticeEmailPercentageWarn = false
        this.AdvanceNoticeEmailPercentageError = false
      }

      if (this.detail.AdvanceNoticeSMSPercentage > 0 && this.committedAssociations.advanceNoticeSMSPercentage > 0) {
        var AdvanceNoticeSMSPercentageValue = parseInt(this.detail.AdvanceNoticeSMSPercentage) + parseInt(this.committedAssociations.advanceNoticeSMSPercentage)
        console.log('AdvanceNoticeSMSPercentageValue when added', AdvanceNoticeSMSPercentageValue)
        this.AdvanceNoticeSMSPercentageValueText = this.GetPercentValueText(AdvanceNoticeSMSPercentageValue)
        if (AdvanceNoticeSMSPercentageValue > 100) {
          this.AdvanceNoticeSMSPercentageWarn = false
          this.AdvanceNoticeSMSPercentageError = true
        } else {
          this.AdvanceNoticeSMSPercentageWarn = true
          this.AdvanceNoticeSMSPercentageError = false
        }
      } else {
        this.AdvanceNoticeSMSPercentageValueText = ''
        this.AdvanceNoticeSMSPercentageWarn = false
        this.AdvanceNoticeSMSPercentageError = false
      }

      if (this.detail.CollectionsPercentage > 0 && this.committedAssociations.collectionsPercentage > 0) {
        var CollectionsPercentageValue = parseInt(this.detail.CollectionsPercentage) + parseInt(this.committedAssociations.collectionsPercentage)
        console.log('CollectionsPercentageValue when added', CollectionsPercentageValue)
        this.CollectionsPercentageValueText = this.GetPercentValueText(CollectionsPercentageValue)
        if (CollectionsPercentageValue > 100) {
          this.CollectionsPercentageWarn = false
          this.CollectionsPercentageError = true
        } else {
          this.CollectionsPercentageWarn = true
          this.CollectionsPercentageError = false
        }
      } else {
        this.CollectionsPercentageValueText = ''
        this.CollectionsPercentageWarn = false
        this.CollectionsPercentageError = false
      }

      if (this.detail.ConfirmationLetterPercentage > 0 && this.committedAssociations.confirmationLetterPercentage > 0) {
        var ConfirmationLetterPercentageValue = parseInt(this.detail.ConfirmationLetterPercentage) + parseInt(this.committedAssociations.confirmationLetterPercentage)
        console.log('ConfirmationLetterPercentageValue when added', ConfirmationLetterPercentageValue)
        this.ConfirmationLetterPercentageValueText = this.GetPercentValueText(ConfirmationLetterPercentageValue)
        if (ConfirmationLetterPercentageValue > 100) {
          this.ConfirmationLetterPercentageWarn = false
          this.ConfirmationLetterPercentageError = true
        } else {
          this.ConfirmationLetterPercentageWarn = true
          this.ConfirmationLetterPercentageError = false
        }
      } else {
        this.ConfirmationLetterPercentageValueText = ''
        this.ConfirmationLetterPercentageWarn = false
        this.ConfirmationLetterPercentageError = false
      }

      if (this.detail.FailedCollectionsPercentage > 0 && this.committedAssociations.failedCollectionsPercentage > 0) {
        var FailedCollectionsPercentageValue = parseInt(this.detail.FailedCollectionsPercentage) + parseInt(this.committedAssociations.failedCollectionsPercentage)
        console.log('FailedCollectionsPercentageValue when added', FailedCollectionsPercentageValue)
        this.FailedCollectionsPercentageValueText = this.GetPercentValueText(FailedCollectionsPercentageValue)
        if (FailedCollectionsPercentageValue > 100) {
          this.FailedCollectionsPercentageWarn = false
          this.FailedCollectionsPercentageError = true
        } else {
          this.FailedCollectionsPercentageWarn = true
          this.FailedCollectionsPercentageError = false
        }
      } else {
        this.FailedCollectionsPercentageValueText = ''
        this.FailedCollectionsPercentageWarn = false
        this.FailedCollectionsPercentageError = false
      }

      if (this.detail.IndemnityClaimsPercentage > 0 && this.committedAssociations.indemnityClaimsPercentage > 0) {
        var IndemnityClaimsPercentageValue = parseInt(this.detail.IndemnityClaimsPercentage) + parseInt(this.committedAssociations.indemnityClaimsPercentage)
        console.log('IndemnityClaimsPercentageValue when added', IndemnityClaimsPercentageValue)
        this.IndemnityClaimsPercentageValueText = this.GetPercentValueText(IndemnityClaimsPercentageValue)
        if (IndemnityClaimsPercentageValue > 100) {
          this.IndemnityClaimsPercentageWarn = false
          this.IndemnityClaimsPercentageError = true
        } else {
          this.IndemnityClaimsPercentageWarn = true
          this.IndemnityClaimsPercentageError = false
        }
      } else {
        this.IndemnityClaimsPercentageValueText = ''
        this.IndemnityClaimsPercentageWarn = false
        this.IndemnityClaimsPercentageError = false
      }

      if (this.detail.PayerCancellationPercentage > 0 && this.committedAssociations.payerCancellationPercentage > 0) {
        var PayerCancellationPercentageValue = parseInt(this.detail.PayerCancellationPercentage) + parseInt(this.committedAssociations.payerCancellationPercentage)
        console.log('PayerCancellationPercentageValue when added', PayerCancellationPercentageValue)
        this.PayerCancellationPercentageValueText = this.GetPercentValueText(PayerCancellationPercentageValue)
        if (PayerCancellationPercentageValue > 100) {
          this.PayerCancellationPercentageWarn = false
          this.PayerCancellationPercentageError = true
        } else {
          this.PayerCancellationPercentageWarn = true
          this.PayerCancellationPercentageError = false
        }
      } else {
        this.PayerCancellationPercentageValueText = ''
        this.PayerCancellationPercentageWarn = false
        this.PayerCancellationPercentageError = false
      }

      if (this.detail.PayerReinstatePercentage > 0 && this.committedAssociations.payerReinstatePercentage > 0) {
        var PayerReinstatePercentageValue = parseInt(this.detail.PayerReinstatePercentage) + parseInt(this.committedAssociations.payerReinstatePercentage)
        console.log('PayerReinstatePercentageValue when added', PayerReinstatePercentageValue)
        this.PayerReinstatePercentageValueText = this.GetPercentValueText(PayerReinstatePercentageValue)
        if (PayerReinstatePercentageValue > 100) {
          this.PayerReinstatePercentageWarn = false
          this.PayerReinstatePercentageError = true
        } else {
          this.PayerReinstatePercentageWarn = true
          this.PayerReinstatePercentageError = false
        }
      } else {
        this.PayerReinstatePercentageValueText = ''
        this.PayerReinstatePercentageWarn = false
        this.PayerReinstatePercentageError = false
      }

      if (this.detail.PayerSetupPercentage > 0 && this.committedAssociations.payerSetupPercentage > 0) {
        var PayerSetupPercentageValue = parseInt(this.detail.PayerSetupPercentage) + parseInt(this.committedAssociations.payerSetupPercentage)
        console.log('PayerSetupPercentageValue when added', PayerSetupPercentageValue)
        this.PayerSetupPercentageValueText = this.GetPercentValueText(PayerSetupPercentageValue)
        if (PayerSetupPercentageValue > 100) {
          this.PayerSetupPercentageWarn = false
          this.PayerSetupPercentageError = true
        } else {
          this.PayerSetupPercentageWarn = true
          this.PayerSetupPercentageError = false
        }
      } else {
        this.PayerSetupPercentageValueText = ''
        this.PayerSetupPercentageWarn = false
        this.PayerSetupPercentageError = false
      }

      if (this.detail.RefundsPercentage > 0 && this.committedAssociations.refundsPercentage > 0) {
        var RefundsPercentageValue = parseInt(this.detail.RefundsPercentage) + parseInt(this.committedAssociations.refundsPercentage)
        console.log('RefundsPercentageValue when added', RefundsPercentageValue)
        this.RefundsPercentageValueText = this.GetPercentValueText(RefundsPercentageValue)
        if (RefundsPercentageValue > 100) {
          this.RefundsPercentageWarn = false
          this.RefundsPercentageError = true
        } else {
          this.RefundsPercentageWarn = true
          this.RefundsPercentageError = false
        }
      } else {
        this.RefundsPercentageValueText = ''
        this.RefundsPercentageWarn = false
        this.RefundsPercentageError = false
      }

      if (this.detail.ValidateBankAccountPercentage > 0 && this.committedAssociations.validateBankAccountPercentage > 0) {
        var ValidateBankAccountPercentageValue = parseInt(this.detail.ValidateBankAccountPercentage) + parseInt(this.committedAssociations.validateBankAccountPercentage)
        console.log('ValidateBankAccountPercentageValue when added', ValidateBankAccountPercentageValue)
        this.ValidateBankAccountPercentageValueText = this.GetPercentValueText(ValidateBankAccountPercentageValue)
        if (ValidateBankAccountPercentageValue > 100) {
          this.ValidateBankAccountPercentageWarn = false
          this.ValidateBankAccountPercentageError = true
        } else {
          this.ValidateBankAccountPercentageWarn = true
          this.ValidateBankAccountPercentageError = false
        }
      } else {
        this.ValidateBankAccountPercentageValueText = ''
        this.ValidateBankAccountPercentageWarn = false
        this.ValidateBankAccountPercentageError = false
      }

      if (this.detail.ValidateIBANPercentage > 0 && this.committedAssociations.validateIBANPercentage > 0) {
        var ValidateIBANPercentageValue = parseInt(this.detail.ValidateIBANPercentage) + parseInt(this.committedAssociations.validateIBANPercentage)
        console.log('ValidateIBANPercentageValue when added', ValidateIBANPercentageValue)
        this.ValidateIBANPercentageValueText = this.GetPercentValueText(ValidateIBANPercentageValue)
        if (ValidateIBANPercentageValue > 100) {
          this.ValidateIBANPercentageWarn = false
          this.ValidateIBANPercentageError = true
        } else {
          this.ValidateIBANPercentageWarn = true
          this.ValidateIBANPercentageError = false
        }
      } else {
        this.ValidateIBANPercentageValueText = ''
        this.ValidateIBANPercentageWarn = false
        this.ValidateIBANPercentageError = false
      }

      if (this.detail.CreditNotePercentage > 0 && this.committedAssociations.creditNotePercentage > 0) {
        var CreditNotePercentageValue = parseInt(this.detail.CreditNotePercentage) + parseInt(this.committedAssociations.creditNotePercentage)
        console.log('CreditNotePercentageValue when added', CreditNotePercentageValue)
        this.CreditNotePercentageValueText = this.GetPercentValueText(CreditNotePercentageValue)
        if (CreditNotePercentageValue > 100) {
          this.CreditNotePercentageWarn = false
          this.CreditNotePercentageError = true
        } else {
          this.CreditNotePercentageWarn = true
          this.CreditNotePercentageError = false
        }
      } else {
        this.CreditNotePercentageValueText = ''
        this.CreditNotePercentageWarn = false
        this.CreditNotePercentageError = false
      }

      if (this.detail.TerminationFeePercentage > 0 && this.committedAssociations.terminationFeePercentage > 0) {
        var TerminationFeePercentageValue = parseInt(this.detail.TerminationFeePercentage) + parseInt(this.committedAssociations.terminationFeePercentage)
        console.log('TerminationFeePercentageValue when added', TerminationFeePercentageValue)
        this.TerminationFeePercentageValueText = this.GetPercentValueText(TerminationFeePercentageValue)
        if (TerminationFeePercentageValue > 100) {
          this.TerminationFeePercentageWarn = false
          this.TerminationFeePercentageError = true
        } else {
          this.TerminationFeePercentageWarn = true
          this.TerminationFeePercentageError = false
        }
      } else {
        this.TerminationFeePercentageValueText = ''
        this.TerminationFeePercentageWarn = false
        this.TerminationFeePercentageError = false
      }

      if (this.detail.BanklineSTD1Or2Percentage > 0 && this.committedAssociations.banklineSTD1Or2Percentage > 0) {
        var BanklineSTD1Or2PercentageValue = parseInt(this.detail.BanklineSTD1Or2Percentage) + parseInt(this.committedAssociations.banklineSTD1Or2Percentage)
        console.log('BanklineSTD1Or2PercentageValue when added', BanklineSTD1Or2PercentageValue)
        this.BanklineSTD1Or2PercentageValueText = this.GetPercentValueText(BanklineSTD1Or2PercentageValue)
        if (BanklineSTD1Or2PercentageValue > 100) {
          this.BanklineSTD1Or2PercentageWarn = false
          this.BanklineSTD1Or2PercentageError = true
        } else {
          this.BanklineSTD1Or2PercentageWarn = true
          this.BanklineSTD1Or2PercentageError = false
        }
      } else {
        this.BanklineSTD1Or2PercentageValueText = ''
        this.BanklineSTD1Or2PercentageWarn = false
        this.BanklineSTD1Or2PercentageError = false
      }

      if (this.detail.BanklineSTD0Percentage > 0 && this.committedAssociations.banklineSTD0Percentage > 0) {
        var BanklineSTD0PercentageValue = parseInt(this.detail.BanklineSTD0Percentage) + parseInt(this.committedAssociations.banklineSTD0Percentage)
        console.log('BanklineSTD0PercentageValue when added', BanklineSTD0PercentageValue)
        this.BanklineSTD0PercentageValueText = this.GetPercentValueText(BanklineSTD0PercentageValue)
        if (BanklineSTD0PercentageValue > 100) {
          this.BanklineSTD0PercentageWarn = false
          this.BanklineSTD0PercentageError = true
        } else {
          this.BanklineSTD0PercentageWarn = true
          this.BanklineSTD0PercentageError = false
        }
      } else {
        this.BanklineSTD0PercentageValueText = ''
        this.BanklineSTD0PercentageWarn = false
        this.BanklineSTD0PercentageError = false
      }

      if (this.detail.BanklineUrgentPercentage > 0 && this.committedAssociations.banklineUrgentPercentage > 0) {
        var BanklineUrgentPercentageValue = parseInt(this.detail.BanklineUrgentPercentage) + parseInt(this.committedAssociations.banklineUrgentPercentage)
        console.log('BanklineUrgentPercentageValue when added', BanklineUrgentPercentageValue)
        this.BanklineUrgentPercentageValueText = this.GetPercentValueText(BanklineUrgentPercentageValue)
        if (BanklineUrgentPercentageValue > 100) {
          this.BanklineUrgentPercentageWarn = false
          this.BanklineUrgentPercentageError = true
        } else {
          this.BanklineUrgentPercentageWarn = true
          this.BanklineUrgentPercentageError = false
        }
      } else {
        this.BanklineUrgentPercentageValueText = ''
        this.BanklineUrgentPercentageWarn = false
        this.BanklineUrgentPercentageError = false
      }

      if (this.detail.BACSMigrationPercentage > 0 && this.committedAssociations.bacsMigrationPercentage > 0) {
        var BACSMigrationPercentageValue = parseInt(this.detail.BACSMigrationPercentage) + parseInt(this.committedAssociations.bacsMigrationPercentage)
        console.log('BACSMigrationPercentageValue when added', BACSMigrationPercentageValue)
        this.BACSMigrationPercentageValueText = this.GetPercentValueText(BACSMigrationPercentageValue)
        if (BACSMigrationPercentageValue > 100) {
          this.BACSMigrationPercentageWarn = false
          this.BACSMigrationPercentageError = true
        } else {
          this.BACSMigrationPercentageWarn = true
          this.BACSMigrationPercentageError = false
        }
      } else {
        this.BACSMigrationPercentageValueText = ''
        this.BACSMigrationPercentageWarn = false
        this.BACSMigrationPercentageError = false
      }

      if (this.detail.CustomerSetupPercentage > 0 && this.committedAssociations.customerSetupPercentage > 0) {
        var CustomerSetupPercentageValue = parseInt(this.detail.CustomerSetupPercentage) + parseInt(this.committedAssociations.customerSetupPercentage)
        console.log('CustomerSetupPercentageValue when added', CustomerSetupPercentageValue)
        this.CustomerSetupPercentageValueText = this.GetPercentValueText(CustomerSetupPercentageValue)
        if (CustomerSetupPercentageValue > 100) {
          this.CustomerSetupPercentageWarn = false
          this.CustomerSetupPercentageError = true
        } else {
          this.CustomerSetupPercentageWarn = true
          this.CustomerSetupPercentageError = false
        }
      } else {
        this.CustomerSetupPercentageValueText = ''
        this.CustomerSetupPercentageWarn = false
        this.CustomerSetupPercentageError = false
      }

      if (this.detail.FMServiceUserSetupPercentage > 0 && this.committedAssociations.fmServiceUserSetupPercentage > 0) {
        var FMServiceUserSetupPercentageValue = parseInt(this.detail.FMServiceUserSetupPercentage) + parseInt(this.committedAssociations.fmServiceUserSetupPercentage)
        console.log('FMServiceUserSetupPercentageValue when added', FMServiceUserSetupPercentageValue)
        this.FMServiceUserSetupPercentageValueText = this.GetPercentValueText(FMServiceUserSetupPercentageValue)
        if (FMServiceUserSetupPercentageValue > 100) {
          this.FMServiceUserSetupPercentageWarn = false
          this.FMServiceUserSetupPercentageError = true
        } else {
          this.FMServiceUserSetupPercentageWarn = true
          this.FMServiceUserSetupPercentageError = false
        }
      } else {
        this.FMServiceUserSetupPercentageValueText = ''
        this.FMServiceUserSetupPercentageWarn = false
        this.FMServiceUserSetupPercentageError = false
      }
      if (this.detail.PayerMigrationPercentage > 0 && this.committedAssociations.payerMigrationPercentage > 0) {
        var PayerMigrationPercentageValue = parseInt(this.detail.PayerMigrationPercentage) + parseInt(this.committedAssociations.payerMigrationPercentage)
        console.log('PayerMigrationPercentageValue when added', PayerMigrationPercentageValue)
        this.PayerMigrationPercentageValueText = this.GetPercentValueText(PayerMigrationPercentageValue)
        if (PayerMigrationPercentageValue > 100) {
          this.PayerMigrationPercentageWarn = false
          this.PayerMigrationPercentageError = true
        } else {
          this.PayerMigrationPercentageWarn = true
          this.PayerMigrationPercentageError = false
        }
      } else {
        this.PayerMigrationPercentageValueText = ''
        this.PayerMigrationPercentageWarn = false
        this.PayerMigrationPercentageError = false
      }

      if (this.detail.ReportsPercentage > 0 && this.committedAssociations.reportsPercentage > 0) {
        var ReportsPercentageValue = parseInt(this.detail.ReportsPercentage) + parseInt(this.committedAssociations.reportsPercentage)
        console.log('ReportsPercentageValue when added', ReportsPercentageValue)
        this.ReportsPercentageValueText = this.GetPercentValueText(ReportsPercentageValue)
        if (ReportsPercentageValue > 100) {
          this.ReportsPercentageWarn = false
          this.ReportsPercentageError = true
        } else {
          this.ReportsPercentageWarn = true
          this.ReportsPercentageError = false
        }
      } else {
        this.ReportsPercentageValueText = ''
        this.ReportsPercentageWarn = false
        this.ReportsPercentageError = false
      }

      if (this.detail.ServiceUserModificationPercentage > 0 && this.committedAssociations.serviceUserModificationPercentage > 0) {
        var ServiceUserModificationPercentageValue = parseInt(this.detail.ServiceUserModificationPercentage) + parseInt(this.committedAssociations.serviceUserModificationPercentage)
        console.log('ServiceUserModificationPercentageValue when added', ServiceUserModificationPercentageValue)
        this.ServiceUserModificationPercentageValueText = this.GetPercentValueText(ServiceUserModificationPercentageValue)
        if (ServiceUserModificationPercentageValue > 100) {
          this.ServiceUserModificationPercentageWarn = false
          this.ServiceUserModificationPercentageError = true
        } else {
          this.ServiceUserModificationPercentageWarn = true
          this.ServiceUserModificationPercentageError = false
        }
      } else {
        this.ServiceUserModificationPercentageValueText = ''
        this.ServiceUserModificationPercentageWarn = false
        this.ServiceUserModificationPercentageError = false
      }

      if (this.detail.OSUChargePercentage > 0 && this.committedAssociations.osuChargePercentage > 0) {
        var OSUChargePercentageValue = parseInt(this.detail.OSUChargePercentage) + parseInt(this.committedAssociations.osuChargePercentage)
        console.log('OSUChargePercentageValue when added', OSUChargePercentageValue)
        this.OSUChargePercentageValueText = this.GetPercentValueText(OSUChargePercentageValue)
        if (OSUChargePercentageValue > 100) {
          this.OSUChargePercentageWarn = false
          this.OSUChargePercentageError = true
        } else {
          this.OSUChargePercentageWarn = true
          this.OSUChargePercentageError = false
        }
      } else {
        this.OSUChargePercentageValueText = ''
        this.OSUChargePercentageWarn = false
        this.OSUChargePercentageError = false
      }

      if (this.detail.BACSFileSubmissionChargePercentage > 0 && this.committedAssociations.bacsFileSubmissionChargePercentage > 0) {
        var BACSFileSubmissionChargePercentageValue = parseInt(this.detail.BACSFileSubmissionChargePercentage) + parseInt(this.committedAssociations.bacsFileSubmissionChargePercentage)
        console.log('BACSFileSubmissionChargePercentageValue when added', BACSFileSubmissionChargePercentageValue)
        this.BACSFileSubmissionChargePercentageValueText = this.GetPercentValueText(BACSFileSubmissionChargePercentageValue)
        if (BACSFileSubmissionChargePercentageValue > 100) {
          this.BACSFileSubmissionChargePercentageWarn = false
          this.BACSFileSubmissionChargePercentageError = true
        } else {
          this.BACSFileSubmissionChargePercentageWarn = true
          this.BACSFileSubmissionChargePercentageError = false
        }
      } else {
        this.BACSFileSubmissionChargePercentageValueText = ''
        this.BACSFileSubmissionChargePercentageWarn = false
        this.BACSFileSubmissionChargePercentageError = false
      }

      if (this.detail.AdminChargePercentage > 0 && this.committedAssociations.adminChargePercentage > 0) {
        var AdminChargePercentageValue = parseInt(this.detail.AdminChargePercentage) + parseInt(this.committedAssociations.adminChargePercentage)
        console.log('AdminChargePercentageValue when added', AdminChargePercentageValue)
        this.AdminChargePercentageValueText = this.GetPercentValueText(AdminChargePercentageValue)
        if (AdminChargePercentageValue > 100) {
          this.AdminChargePercentageWarn = false
          this.AdminChargePercentageError = true
        } else {
          this.AdminChargePercentageWarn = true
          this.AdminChargePercentageError = false
        }
      } else {
        this.AdminChargePercentageValueText = ''
        this.AdminChargePercentageWarn = false
        this.AdminChargePercentageError = false
      }

      // Give an overall warning about a percentage error
      if (
        this.AdvanceNoticeLetterPercentageError ||
        this.AdvanceNoticeEmailPercentageError ||
        this.AdvanceNoticeSMSPercentageError ||
        this.CollectionsPercentageError ||
        this.ConfirmationLetterPercentageError ||
        this.FailedCollectionsPercentageError ||
        this.IndemnityClaimsPercentageError ||
        this.PayerCancellationPercentageError ||
        this.PayerReinstatePercentageError ||
        this.PayerSetupPercentageError ||
        this.RefundsPercentageError ||
        this.ValidateBankAccountPercentageError ||
        this.ValidateIBANPercentageError ||
        this.CreditNotePercentageError ||
        this.TerminationFeePercentageError ||
        this.BanklineSTD1Or2PercentageError ||
        this.BanklineSTD0PercentageError ||
        this.BanklineUrgentPercentageError ||
        this.BACSMigrationPercentageError ||
        this.CustomerSetupPercentageError ||
        this.FMServiceUserSetupPercentageError ||
        this.PayerMigrationPercentageError ||
        this.ReportsPercentageError ||
        this.ServiceUserModificationPercentageError ||
        this.OSUChargePercentageError ||
        this.BACSFileSubmissionChargePercentageError ||
        this.AdminChargePercentageError
      ) {
        this.CommittedAssociationErrorsWarning = true
        console.log('CommittedAssociationErrorsWarning', this.CommittedAssociationErrorsWarning)
      } else {
        this.CommittedAssociationErrorsWarning = false
        console.log('CommittedAssociationErrorsWarning', this.CommittedAssociationErrorsWarning)
      }

      // Fixed Fee Warnings
      if (this.detail.AdvanceNoticeLetterFixedFee > 0 && this.committedAssociations.advanceNoticeLetterFixedFee > 0) {
        var AdvanceNoticeLetterFixedFeeValue = parseInt(this.detail.AdvanceNoticeLetterFixedFee) + parseInt(this.committedAssociations.advanceNoticeLetterFixedFee)
        this.AdvanceNoticeLetterFixedFeeValueText = this.GetFixedFeeValueText(AdvanceNoticeLetterFixedFeeValue)
        this.AdvanceNoticeLetterFixedFeeWarn = true
      } else {
        this.AdvanceNoticeLetterFixedFeeValueText = ''
        this.AdvanceNoticeLetterFixedFeeWarn = false
      }

      if (this.detail.AdvanceNoticeEmailFixedFee > 0 &&
        this.committedAssociations.advanceNoticeEmailFixedFee > 0) {
        var AdvanceNoticeEmailFixedFeeValue = parseInt(this.detail.AdvanceNoticeEmailFixedFee) + parseInt(this.committedAssociations.advanceNoticeEmailFixedFee)
        this.AdvanceNoticeEmailFixedFeeValueText =
          this.GetFixedFeeValueText(AdvanceNoticeEmailFixedFeeValue)
        this.AdvanceNoticeEmailFixedFeeWarn = true
      } else {
        this.AdvanceNoticeEmailFixedFeeValueText = ''
        this.AdvanceNoticeEmailFixedFeeWarn = false
      }

      if (this.detail.AdvanceNoticeSMSFixedFee > 0 &&
        this.committedAssociations.advanceNoticeSMSFixedFee > 0) {
        var AdvanceNoticeSMSFixedFeeValue = parseInt(this.detail.AdvanceNoticeSMSFixedFee) + parseInt(this.committedAssociations.advanceNoticeSMSFixedFee)
        this.AdvanceNoticeSMSFixedFeeValueText =
          this.GetFixedFeeValueText(AdvanceNoticeSMSFixedFeeValue)
        this.AdvanceNoticeSMSFixedFeeWarn = true
      } else {
        this.AdvanceNoticeSMSFixedFeeValueText = ''
        this.AdvanceNoticeSMSFixedFeeWarn = false
      }
      if (this.detail.CollectionsFixedFee > 0 &&
        this.committedAssociations.collectionsFixedFee > 0) {
        var CollectionsFixedFeeValue = parseInt(this.detail.CollectionsFixedFee) + parseInt(this.committedAssociations.collectionsFixedFee)
        this.CollectionsFixedFeeValueText =
          this.GetFixedFeeValueText(CollectionsFixedFeeValue)
        this.CollectionsFixedFeeWarn = true
      } else {
        this.CollectionsFixedFeeValueText = ''
        this.CollectionsFixedFeeWarn = false
      }
      if (this.detail.ConfirmationLetterFixedFee > 0 &&
        this.committedAssociations.confirmationLetterFixedFee > 0) {
        var ConfirmationLetterFixedFeeValue = parseInt(this.detail.ConfirmationLetterFixedFee) + parseInt(this.committedAssociations.confirmationLetterFixedFee)
        this.ConfirmationLetterFixedFeeValueText =
          this.GetFixedFeeValueText(ConfirmationLetterFixedFeeValue)
        this.ConfirmationLetterFixedFeeWarn = true
      } else {
        this.ConfirmationLetterFixedFeeValueText = ''
        this.ConfirmationLetterFixedFeeWarn = false
      }
      if (this.detail.FailedCollectionsFixedFee > 0 &&
        this.committedAssociations.failedCollectionsFixedFee > 0) {
        var FailedCollectionsFixedFeeValue = parseInt(this.detail.FailedCollectionsFixedFee) + parseInt(this.committedAssociations.failedCollectionsFixedFee)
        this.FailedCollectionsFixedFeeValueText =
          this.GetFixedFeeValueText(FailedCollectionsFixedFeeValue)
        this.FailedCollectionsFixedFeeWarn = true
      } else {
        this.FailedCollectionsFixedFeeValueText = ''
        this.FailedCollectionsFixedFeeWarn = false
      }
      if (this.detail.IndemnityClaimsFixedFee > 0 &&
        this.committedAssociations.indemnityClaimsFixedFee > 0) {
        var IndemnityClaimsFixedFeeValue = parseInt(this.detail.IndemnityClaimsFixedFee) + parseInt(this.committedAssociations.indemnityClaimsFixedFee)
        this.IndemnityClaimsFixedFeeValueText =
          this.GetFixedFeeValueText(IndemnityClaimsFixedFeeValue)
        this.IndemnityClaimsFixedFeeWarn = true
      } else {
        this.IndemnityClaimsFixedFeeValueText = ''
        this.IndemnityClaimsFixedFeeWarn = false
      }
      if (this.detail.PayerCancellationFixedFee > 0 &&
        this.committedAssociations.payerCancellationFixedFee > 0) {
        var PayerCancellationFixedFeeValue = parseInt(this.detail.PayerCancellationFixedFee) + parseInt(this.committedAssociations.payerCancellationFixedFee)
        this.PayerCancellationFixedFeeValueText =
          this.GetFixedFeeValueText(PayerCancellationFixedFeeValue)
        this.PayerCancellationFixedFeeWarn = true
      } else {
        this.PayerCancellationFixedFeeValueText = ''
        this.PayerCancellationFixedFeeWarn = false
      }
      if (this.detail.PayerReinstateFixedFee > 0 &&
        this.committedAssociations.payerReinstateFixedFee > 0) {
        var PayerReinstateFixedFeeValue = parseInt(this.detail.PayerReinstateFixedFee) + parseInt(this.committedAssociations.payerReinstateFixedFee)
        this.PayerReinstateFixedFeeValueText =
          this.GetFixedFeeValueText(PayerReinstateFixedFeeValue)
        this.PayerReinstateFixedFeeWarn = true
      } else {
        this.PayerReinstateFixedFeeValueText = ''
        this.PayerReinstateFixedFeeWarn = false
      }
      if (this.detail.PayerSetupFixedFee > 0 &&
        this.committedAssociations.payerSetupFixedFee > 0) {
        var PayerSetupFixedFeeValue = parseInt(this.detail.PayerSetupFixedFee) + parseInt(this.committedAssociations.payerSetupFixedFee)
        this.PayerSetupFixedFeeValueText =
          this.GetFixedFeeValueText(PayerSetupFixedFeeValue)
        this.PayerSetupFixedFeeWarn = true
      } else {
        this.PayerSetupFixedFeeValueText = ''
        this.PayerSetupFixedFeeWarn = false
      }
      if (this.detail.RefundsFixedFee > 0 &&
        this.committedAssociations.refundsFixedFee > 0) {
        var RefundsFixedFeeValue = parseInt(this.detail.RefundsFixedFee) + parseInt(this.committedAssociations.refundsFixedFee)
        this.RefundsFixedFeeValueText =
          this.GetFixedFeeValueText(RefundsFixedFeeValue)
        this.RefundsFixedFeeWarn = true
      } else {
        this.RefundsFixedFeeValueText = ''
        this.RefundsFixedFeeWarn = false
      }
      if (this.detail.ValidateBankAccountFixedFee > 0 &&
        this.committedAssociations.validateBankAccountFixedFee > 0) {
        var ValidateBankAccountFixedFeeValue = parseInt(this.detail.ValidateBankAccountFixedFee) + parseInt(this.committedAssociations.validateBankAccountFixedFee)
        this.ValidateBankAccountFixedFeeValueText =
          this.GetFixedFeeValueText(ValidateBankAccountFixedFeeValue)
        this.ValidateBankAccountFixedFeeWarn = true
      } else {
        this.ValidateBankAccountFixedFeeValueText = ''
        this.ValidateBankAccountFixedFeeWarn = false
      }
      if (this.detail.ValidateIBANFixedFee > 0 &&
        this.committedAssociations.validateIBANFixedFee > 0) {
        var ValidateIBANFixedFeeValue = parseInt(this.detail.ValidateIBANFixedFee) + parseInt(this.committedAssociations.validateIBANFixedFee)
        this.ValidateIBANFixedFeeValueText =
          this.GetFixedFeeValueText(ValidateIBANFixedFeeValue)
        this.ValidateIBANFixedFeeWarn = true
      } else {
        this.ValidateIBANFixedFeeValueText = ''
        this.ValidateIBANFixedFeeWarn = false
      }
      if (this.detail.CreditNoteFixedFee > 0 &&
        this.committedAssociations.creditNoteFixedFee > 0) {
        var CreditNoteFixedFeeValue = parseInt(this.detail.CreditNoteFixedFee) + parseInt(this.committedAssociations.creditNoteFixedFee)
        this.CreditNoteFixedFeeValueText =
          this.GetFixedFeeValueText(CreditNoteFixedFeeValue)
        this.CreditNoteFixedFeeWarn = true
      } else {
        this.CreditNoteFixedFeeValueText = ''
        this.CreditNoteFixedFeeWarn = false
      }
      if (this.detail.TerminationFeeFixedFee > 0 &&
        this.committedAssociations.terminationFeeFixedFee > 0) {
        var TerminationFeeFixedFeeValue = parseInt(this.detail.TerminationFeeFixedFee) + parseInt(this.committedAssociations.terminationFeeFixedFee)
        this.TerminationFeeFixedFeeValueText =
          this.GetFixedFeeValueText(TerminationFeeFixedFeeValue)
        this.TerminationFeeFixedFeeWarn = true
      } else {
        this.TerminationFeeFixedFeeValueText = ''
        this.TerminationFeeFixedFeeWarn = false
      }
      if (this.detail.BanklineSTD1Or2FixedFee > 0 &&
        this.committedAssociations.banklineSTD1Or2FixedFee > 0) {
        var BanklineSTD1Or2FixedFeeValue = parseInt(this.detail.BanklineSTD1Or2FixedFee) + parseInt(this.committedAssociations.banklineSTD1Or2FixedFee)
        this.BanklineSTD1Or2FixedFeeValueText =
          this.GetFixedFeeValueText(BanklineSTD1Or2FixedFeeValue)
        this.BanklineSTD1Or2FixedFeeWarn = true
      } else {
        this.BanklineSTD1Or2FixedFeeValueText = ''
        this.BanklineSTD1Or2FixedFeeWarn = false
      }
      if (this.detail.BanklineSTD0FixedFee > 0 &&
        this.committedAssociations.banklineSTD0FixedFee > 0) {
        var BanklineSTD0FixedFeeValue = parseInt(this.detail.BanklineSTD0FixedFee) + parseInt(this.committedAssociations.banklineSTD0FixedFee)
        this.BanklineSTD0FixedFeeValueText =
          this.GetFixedFeeValueText(BanklineSTD0FixedFeeValue)
        this.BanklineSTD0FixedFeeWarn = true
      } else {
        this.BanklineSTD0FixedFeeValueText = ''
        this.BanklineSTD0FixedFeeWarn = false
      }
      if (this.detail.BanklineUrgentFixedFee > 0 &&
        this.committedAssociations.banklineUrgentFixedFee > 0) {
        var BanklineUrgentFixedFeeValue = parseInt(this.detail.BanklineUrgentFixedFee) + parseInt(this.committedAssociations.banklineUrgentFixedFee)
        this.BanklineUrgentFixedFeeValueText =
          this.GetFixedFeeValueText(BanklineUrgentFixedFeeValue)
        this.BanklineUrgentFixedFeeWarn = true
      } else {
        this.BanklineUrgentFixedFeeValueText = ''
        this.BanklineUrgentFixedFeeWarn = false
      }
      if (this.detail.OSUChargeFixedFee > 0 &&
        this.committedAssociations.oSUChargeFixedFee > 0) {
        var OSUChargeFixedFeeValue = parseInt(this.detail.OSUChargeFixedFee) + parseInt(this.committedAssociations.oSUChargeFixedFee)
        this.OSUChargeFixedFeeValueText =
          this.GetFixedFeeValueText(OSUChargeFixedFeeValue)
        this.OSUChargeFixedFeeWarn = true
      } else {
        this.OSUChargeFixedFeeValueText = ''
        this.OSUChargeFixedFeeWarn = false
      }
      if (this.detail.BACSFileSubmissionChargeFixedFee > 0 &&
        this.committedAssociations.bACSFileSubmissionChargeFixedFee > 0) {
        var BACSFileSubmissionChargeFixedFeeValue = parseInt(this.detail.BACSFileSubmissionChargeFixedFee) + parseInt(this.committedAssociations.bACSFileSubmissionChargeFixedFee)
        this.BACSFileSubmissionChargeFixedFeeValueText =
          this.GetFixedFeeValueText(BACSFileSubmissionChargeFixedFeeValue)
        this.BACSFileSubmissionChargeFixedFeeWarn = true
      } else {
        this.BACSFileSubmissionChargeFixedFeeValueText = ''
        this.BACSFileSubmissionChargeFixedFeeWarn = false
      }
      if (this.detail.AdminChargeFixedFee > 0 &&
        this.committedAssociations.adminChargeFixedFee > 0) {
        var AdminChargeFixedFeeValue = parseInt(this.detail.AdminChargeFixedFee) + parseInt(this.committedAssociations.adminChargeFixedFee)
        this.AdminChargeFixedFeeValueText =
          this.GetFixedFeeValueText(AdminChargeFixedFeeValue)
        this.AdminChargeFixedFeeWarn = true
      } else {
        this.AdminChargeFixedFeeValueText = ''
        this.AdminChargeFixedFeeWarn = false
      }
      if (this.detail.BACSMigrationFixedFee > 0 &&
        this.committedAssociations.bACSMigrationFixedFee > 0) {
        var BACSMigrationFixedFeeValue = parseInt(this.detail.BACSMigrationFixedFee) + parseInt(this.committedAssociations.bACSMigrationFixedFee)
        this.BACSMigrationFixedFeeValueText =
          this.GetFixedFeeValueText(BACSMigrationFixedFeeValue)
        this.BACSMigrationFixedFeeWarn = true
      } else {
        this.BACSMigrationFixedFeeValueText = ''
        this.BACSMigrationFixedFeeWarn = false
      }
      if (this.detail.CustomerSetupFixedFee > 0 &&
        this.committedAssociations.customerSetupFixedFee > 0) {
        var CustomerSetupFixedFeeValue = parseInt(this.detail.CustomerSetupFixedFee) + parseInt(this.committedAssociations.customerSetupFixedFee)
        this.CustomerSetupFixedFeeValueText =
          this.GetFixedFeeValueText(CustomerSetupFixedFeeValue)
        this.CustomerSetupFixedFeeWarn = true
      } else {
        this.CustomerSetupFixedFeeValueText = ''
        this.CustomerSetupFixedFeeWarn = false
      }
      if (this.detail.FMServiceUserSetupFixedFee > 0 &&
        this.committedAssociations.fMServiceUserSetupFixedFee > 0) {
        var FMServiceUserSetupFixedFeeValue = parseInt(this.detail.FMServiceUserSetupFixedFee) + parseInt(this.committedAssociations.fMServiceUserSetupFixedFee)
        this.FMServiceUserSetupFixedFeeValueText =
          this.GetFixedFeeValueText(FMServiceUserSetupFixedFeeValue)
        this.FMServiceUserSetupFixedFeeWarn = true
      } else {
        this.FMServiceUserSetupFixedFeeValueText = ''
        this.FMServiceUserSetupFixedFeeWarn = false
      }
      if (this.detail.PayerMigrationFixedFee > 0 &&
        this.committedAssociations.payerMigrationFixedFee > 0) {
        var PayerMigrationFixedFeeValue = parseInt(this.detail.PayerMigrationFixedFee) + parseInt(this.committedAssociations.payerMigrationFixedFee)
        this.PayerMigrationFixedFeeValueText =
          this.GetFixedFeeValueText(PayerMigrationFixedFeeValue)
        this.PayerMigrationFixedFeeWarn = true
      } else {
        this.PayerMigrationFixedFeeValueText = ''
        this.PayerMigrationFixedFeeWarn = false
      }
      if (this.detail.ReportsFixedFee > 0 &&
        this.committedAssociations.reportsFixedFee > 0) {
        var ReportsFixedFeeValue = parseInt(this.detail.ReportsFixedFee) + parseInt(this.committedAssociations.reportsFixedFee)
        this.ReportsFixedFeeValueText =
          this.GetFixedFeeValueText(ReportsFixedFeeValue)
        this.ReportsFixedFeeWarn = true
      } else {
        this.ReportsFixedFeeValueText = ''
        this.ReportsFixedFeeWarn = false
      }
      if (this.detail.ServiceUserModificationFixedFee > 0 &&
        this.committedAssociations.serviceUserModificationFixedFee > 0) {
        var ServiceUserModificationFixedFeeValue = parseInt(this.detail.ServiceUserModificationFixedFee) + parseInt(this.committedAssociations.serviceUserModificationFixedFee)
        this.ServiceUserModificationFixedFeeValueText =
          this.GetFixedFeeValueText(ServiceUserModificationFixedFeeValue)
        this.ServiceUserModificationFixedFeeWarn = true
      } else {
        this.ServiceUserModificationFixedFeeValueText = ''
        this.ServiceUserModificationFixedFeeWarn = false
      }

      // Final Check

      if (this.CreditNotePercentage.length === 0 &&
        this.TerminationFeePercentage.length === 0 &&
        this.BanklineSTD1Or2Percentage.length === 0 &&
        this.BanklineSTD0Percentage.length === 0 &&
        this.BanklineUrgentPercentage.length === 0 &&
        this.OSUChargePercentage.length === 0 &&
        this.BACSFileSubmissionChargePercentage.length === 0 &&
        this.AdminChargePercentage.length === 0 &&
        this.BACSMigrationPercentage.length === 0 &&
        this.CustomerSetupPercentage.length === 0 &&
        this.FMServiceUserSetupPercentage.length === 0 &&
        this.PayerMigrationPercentage.length === 0 &&
        this.ReportsPercentage.length === 0 &&
        this.ServiceUserModificationPercentage.length === 0 &&

        this.AdvanceNoticeLetterPercentage.length === 0 &&
        this.AdvanceNoticeEmailPercentage.length === 0 &&
        this.AdvanceNoticeSMSPercentage.length === 0 &&
        this.CollectionsPercentage.length === 0 &&
        this.ConfirmationLetterPercentage.length === 0 &&
        this.FailedCollectionsPercentage.length === 0 &&
        this.IndemnityClaimsPercentage.length === 0 &&
        this.PayerCancellationPercentage.length === 0 &&
        this.PayerReinstatePercentage.length === 0 &&
        this.PayerSetupPercentage.length === 0 &&
        this.RefundsPercentage.length === 0 &&
        this.ValidateBankAccountPercentage.length === 0 &&
        this.ValidateIBANPercentage.length === 0 &&

        this.CreditNoteFixedFee.length === 0 &&
        this.TerminationFeeFixedFee.length === 0 &&
        this.BanklineSTD1Or2FixedFee.length === 0 &&
        this.BanklineSTD0FixedFee.length === 0 &&
        this.BanklineUrgentFixedFee.length === 0 &&
        this.OSUChargeFixedFee.length === 0 &&
        this.BACSFileSubmissionChargeFixedFee.length === 0 &&
        this.AdminChargeFixedFee.length === 0 &&
        this.BACSMigrationFixedFee.length === 0 &&
        this.CustomerSetupFixedFee.length === 0 &&
        this.FMServiceUserSetupFixedFee.length === 0 &&
        this.PayerMigrationFixedFee.length === 0 &&
        this.ReportsFixedFee.length === 0 &&
        this.ServiceUserModificationFixedFee.length === 0 &&

        this.AdvanceNoticeLetterFixedFee.length === 0 &&
        this.AdvanceNoticeEmailFixedFee.length === 0 &&
        this.AdvanceNoticeSMSFixedFee.length === 0 &&
        this.CollectionsFixedFee.length === 0 &&
        this.ConfirmationLetterFixedFee.length === 0 &&
        this.FailedCollectionsFixedFee.length === 0 &&
        this.IndemnityClaimsFixedFee.length === 0 &&
        this.PayerCancellationFixedFee.length === 0 &&
        this.PayerReinstateFixedFee.length === 0 &&
        this.PayerSetupFixedFee.length === 0 &&
        this.RefundsFixedFee.length === 0 &&
        this.ValidateBankAccountFixedFee.length === 0 &&
        this.ValidateIBANFixedFee.length === 0) {
        this.disableSave = true
      }
    },
    GetPercentValueText (value) {
      var s = ''
      if (value > 100) {
        s = 'The current commitment amongst existing associations in this period exceeds 100%. The current commitment is ' + value + '%.'
      } else {
        s = 'The current commitment amongst existing associations in this period is now a total of ' + value + '%.'
      }
      return s
    },
    GetFixedFeeValueText (value) {
      return 'The current fixed fee commitment amongst existing associations in this period is £' + value + '.'
    }

  },

  validations: {
    selectedCustomer: { required }, // Added this last
    detail: {
      AssociationName: { required },
      PaygateId: { required },
      CreditNotePercentage: { numeric },
      TerminationFeePercentage: { numeric },
      BanklineSTD1Or2Percentage: { numeric },
      BanklineSTD0Percentage: { numeric },
      BanklineUrgentPercentage: { numeric },

      OSUChargePercentage: { numeric },
      BACSFileSubmissionChargePercentage: { numeric },
      AdminChargePercentage: { numeric },

      BACSMigrationPercentage: { numeric },
      CustomerSetupPercentage: { numeric },
      FMServiceUserSetupPercentage: { numeric },
      PayerMigrationPercentage: { numeric },
      ReportsPercentage: { numeric },
      ServiceUserModificationPercentage: { numeric },
      AdvanceNoticeLetterPercentage: { numeric },
      AdvanceNoticeEmailPercentage: { numeric },
      AdvanceNoticeSMSPercentage: { numeric },
      CollectionsPercentage: { numeric },
      ConfirmationLetterPercentage: { numeric },
      FailedCollectionsPercentage: { numeric },
      IndemnityClaimsPercentage: { numeric },
      PayerCancellationPercentage: { numeric },
      PayerReinstatePercentage: { numeric },
      PayerSetupPercentage: { numeric },
      RefundsPercentage: { numeric },
      ValidateBankAccountPercentage: { numeric },
      ValidateIBANPercentage: { numeric },

      CreditNoteFixedFee: { numeric },
      TerminationFeeFixedFee: { numeric },
      BanklineSTD1Or2FixedFee: { numeric },
      BanklineSTD0FixedFee: { numeric },
      BanklineUrgentFixedFee: { numeric },
      OSUChargeFixedFee: { numeric },
      BACSFileSubmissionChargeFixedFee: { numeric },
      AdminChargeFixedFee: { numeric },
      BACSMigrationFixedFee: { numeric },
      CustomerSetupFixedFee: { numeric },
      FMServiceUserSetupFixedFee: { numeric },
      PayerMigrationFixedFee: { numeric },
      ReportsFixedFee: { numeric },
      ServiceUserModificationFixedFee: { numeric },

      AdvanceNoticeLetterFixedFee: { numeric },
      AdvanceNoticeEmailFixedFee: { numeric },
      AdvanceNoticeSMSFixedFee: { numeric },
      CollectionsFixedFee: { numeric },
      ConfirmationLetterFixedFee: { numeric },
      FailedCollectionsFixedFee: { numeric },
      IndemnityClaimsFixedFee: { numeric },
      PayerCancellationFixedFee: { numeric },
      PayerReinstateFixedFee: { numeric },
      PayerSetupFixedFee: { numeric },
      RefundsFixedFee: { numeric },
      ValidateBankAccountFixedFee: { numeric },
      ValidateIBANFixedFee: { numeric }

    }
  }
}
</script>
<style scoped>
td {
  cursor: pointer;
}

#firstPaymentDatePicker,
#endPaymentDatePicker,
.datepicker,
.datepicker:disabled {
  background-color: #ffffff !important;
}
.dark-mode #firstPaymentDatePicker,
.dark-mode #endPaymentDatePicker,
.dark-mode .datepicker,
.dark-mode .datepicker:disabled {
  background-color: transparent !important;
}
.dark-mode .vdp-datepicker,
.dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important;
}
.dark-mode .vdp-datepicker__calendar * .next,
.dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important;
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}

.fadein-enter {
  opacity: 0;
}
.fadein-enter-active {
  transition: opacity 0.6s;
}
.fadein-leave-active {
  transition: opacity 0.6s;
  opacity: 0;
}
</style>
