<template>
  <div>
    <!-- <loading v-bind:loading="loading"></loading> -->
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Imported Bureau Customer Information
          <help-icon></help-icon>
          <favourite-icon></favourite-icon>
        </h2>
      </div>

      <div class="idb-block-content">
        <b-tabs>
          <b-tab
            title="Bureau Customer Details"
            active
            :title-link-class="{
              invalid: $v.importedBureauCustomer.detailsValidation.$invalid,
            }"
          >
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label class="required" for="importedBureauCustomer.name">Name</label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.name.$model"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-col offset-sm="3" class="pl-3" v-if="$v.importedBureauCustomer.name.$dirty">
              <label
                class="text-danger small"
                v-if="!$v.importedBureauCustomer.name.required"
              >A Bureau Customer Name is required</label>
            </b-col>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label
                  class="required"
                  for="importedBureauCustomer.serviceUserNumber"
                >Service User Number</label>
              </b-col>
              <b-col sm="3">
                <b-form-input
                  type="text"
                  :maxlength="6"
                  v-model.trim="
                    $v.importedBureauCustomer.serviceUserNumber.$model
                  "
                  @input="$v.$touch()"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-col
              offset-sm="3"
              class="pl-3"
              v-if="$v.importedBureauCustomer.serviceUserNumber.$dirty"
            >
              <label
                class="text-danger small"
                v-if="!$v.importedBureauCustomer.serviceUserNumber.required"
              >A Service User Number is required</label>
              <label
                class="text-danger small"
                v-if="!$v.importedBureauCustomer.serviceUserNumber.sunValidator"
              >The Service User Number is not valid</label>
            </b-col>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.defaultImportFilename">Default Import Filename</label>
              </b-col>
              <b-col sm="6">
                <b-form-input
                  type="text"
                  :maxlength="250"
                  v-model.trim="
                    $v.importedBureauCustomer.defaultImportFilename.$model
                  "
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.defaultImportSchema">Default Import Schema</label>
              </b-col>
              <b-col sm="4">
                <b-form-select
                  v-model="$v.importedBureauCustomer.defaultImportSchema.$model"
                  :options="schemas"
                ></b-form-select>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="importedBureauCustomer.defaultImportSchema == 'Mapping'">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.defaultImportMapping">Default Import Mapping</label>
              </b-col>
              <b-col sm="4">
                <b-form-select
                  v-model="
                    $v.importedBureauCustomer.defaultImportMapping.$model
                  "
                  :options="mappings"
                ></b-form-select>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.contraNarrative">Contra Narrative</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  :maxlength="255"
                  v-model.trim="
                    $v.importedBureauCustomer.contraNarrative.$model
                  "
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.tags">Tags</label>
              </b-col>
              <b-col sm="6">
                <input-tag v-model="$v.importedBureauCustomer.tags.$model"></input-tag>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab
            title="Bank Details"
            :title-link-class="{
              invalid: $v.importedBureauCustomer.bankDetailsValidation.$invalid,
            }"
          >
            <!-- Bank Reference -->
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label class="required" for="importedBureauCustomer.bankReference">Reference</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.bankReference.$model"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-col
              offset-sm="3"
              class="pl-3"
              v-if="$v.importedBureauCustomer.bankReference.$invalid"
            >
              <label
                class="text-danger small"
                v-if="!$v.importedBureauCustomer.bankReference.$required"
              >A Bank Reference is required</label>
            </b-col>

            <!-- Sort Code -->
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label class="required">Sort Code</label>
              </b-col>
              <b-col sm="2">
                <the-mask
                  type="text"
                  class="form-control"
                  v-model.trim="$v.importedBureauCustomer.sortCode.$model"
                  v-focus
                  :mask="['##-##-##']"
                  :guide="true"
                  ref="sortCode"
                ></the-mask>
                <!-- Validation -->
                <span v-if="$v.importedBureauCustomer.sortCode.$dirty">
                  <small
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.importedBureauCustomer.sortCode.required"
                  >Sort Code is required</small>
                  <small
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.importedBureauCustomer.sortCode.minLength"
                  >
                    Sort Code must be at least
                    {{
                    $v.importedBureauCustomer.sortCode.$params.minLength.min
                    }}
                    numbers
                  </small>
                  <small
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.importedBureauCustomer.sortCode.maxLength"
                  >
                    Sort Code must be less than
                    {{
                    $v.importedBureauCustomer.sortCode.$params.maxLength.max
                    }}
                    numbers
                  </small>
                  <small
                    class="form-text text-warning small text-nowrap"
                    v-if="
                      !sortCodeValid &&
                      $v.importedBureauCustomer.sortCode.required
                    "
                  >{{ sortCodeErrorMessage }}</small>
                </span>
              </b-col>
              <span>
                <b-col v-if="sortCodeValid">
                  <i class="fas fa-check mt-2"></i>
                </b-col>
              </span>
            </b-row>

            <!-- Account Number -->
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label class="required" for="importedBureauCustomer.accountNumber">Account Number</label>
              </b-col>
              <b-col sm="2">
                <the-mask
                  type="text"
                  class="form-control"
                  v-model.trim="$v.importedBureauCustomer.accountNumber.$model"
                  :mask="['########']"
                  :guide="false"
                  ref="originatingAccountNumber"
                ></the-mask>
                <!-- Validation -->
                <span v-if="$v.importedBureauCustomer.accountNumber.$dirty">
                  <small
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.importedBureauCustomer.accountNumber.required"
                  >Account Number is required</small>
                  <small
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.importedBureauCustomer.accountNumber.minLength"
                  >
                    Account Number must be at least
                    {{
                    $v.importedBureauCustomer.accountNumber.$params.minLength
                    .min
                    }}
                    numbers
                  </small>
                  <small
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.importedBureauCustomer.accountNumber.maxLength"
                  >
                    Account Number must be less than
                    {{
                    $v.importedBureauCustomer.accountNumber.$params.maxLength
                    .max
                    }}
                    numbers
                  </small>
                  <small
                    class="form-text text-warning small text-nowrap"
                    v-if="
                      !accountNumberValid &&
                      $v.importedBureauCustomer.accountNumber.required
                    "
                  >{{ accountNumberErrorMessage }}</small>
                </span>
              </b-col>
              <span>
                <b-col v-if="accountNumberValid == true">
                  <i class="fas fa-check mt-2"></i>
                </b-col>
              </span>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.bankName">Bank Name</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.bankName.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.bankAddress1">Address</label>
              </b-col>
              <b-col sm="6">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.bankAddress1.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="6" offset-sm="3">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.bankAddress2.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="6" offset-sm="3">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.bankAddress3.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="6" offset-sm="3">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.bankAddress4.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.bankAddress5"></label>
              </b-col>
              <b-col sm="6">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.bankAddress5.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.bankAddressTown">Town</label>
              </b-col>
              <b-col sm="5">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="
                    $v.importedBureauCustomer.bankAddressTown.$model
                  "
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.bankAddressCounty">County</label>
              </b-col>
              <b-col sm="5">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="
                    $v.importedBureauCustomer.bankAddressCounty.$model
                  "
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.bankPostcode">Post Code</label>
              </b-col>
              <b-col sm="2">
                <b-form-input
                  type="text"
                  :maxlength="10"
                  v-model.trim="$v.importedBureauCustomer.bankPostcode.$model"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Payment Limits">
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.paymentLimit">Payment Limit</label>
              </b-col>
              <b-col sm="2">
                <currency-input type="text" v-model="$v.importedBureauCustomer.paymentLimit.$model"></currency-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.submissionLimit">Submission Limit</label>
              </b-col>
              <b-col sm="2">
                <currency-input
                  type="text"
                  v-model="$v.importedBureauCustomer.submissionLimit.$model"
                ></currency-input>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Contact Info">
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.contactFirstName">Forename</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="
                    $v.importedBureauCustomer.contactFirstName.$model
                  "
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.contactSurname">Surname</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.contactSurname.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.address1">Address</label>
              </b-col>
              <b-col sm="6">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.address1.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="6" offset-sm="3">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.address2.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="6" offset-sm="3">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.address3.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="6" offset-sm="3">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.address4.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.addressLocality"></label>
              </b-col>
              <b-col sm="6">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="
                    $v.importedBureauCustomer.addressLocality.$model
                  "
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.addressTown">Town</label>
              </b-col>
              <b-col sm="5">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.addressTown.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.addressCounty">County</label>
              </b-col>
              <b-col sm="5">
                <b-form-input
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.importedBureauCustomer.addressCounty.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.addressPostcode">Postcode</label>
              </b-col>
              <b-col sm="2">
                <b-form-input
                  type="text"
                  maxlength="10"
                  v-model.trim="
                    $v.importedBureauCustomer.addressPostcode.$model
                  "
                ></b-form-input>
              </b-col>
              <b-col sm="2">
                <button
                  class="btn btn-info"
                  :disabled="
                    this.importedBureauCustomer.addressPostcode == '' ||
                    this.importedBureauCustomer.addressPostcode == null
                  "
                  @click="getAddress()"
                >Lookup Address</button>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="this.addressOptions.length > 0 && !this.addressFound">
              <b-col sm="3" class="mt-2">
                <label for="addressOptions">Addresses</label>
              </b-col>
              <b-col sm="8">
                <b-form-select v-model="selectedAddress" :options="this.addressOptions" class="mb3"></b-form-select>
              </b-col>
            </b-row>

            <hr />

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.telephone">Telephone</label>
              </b-col>
              <b-col sm="3">
                <b-form-input type="text" v-model.trim="$v.importedBureauCustomer.telephone.$model"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.emailAddress">Email</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  v-model.trim="$v.importedBureauCustomer.emailAddress.$model"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="importedBureauCustomer.websiteUrl">Web Site</label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  v-model.trim="$v.importedBureauCustomer.websiteUrl.$model"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div class="idb-block-footer">
        <button
          class="btn btn-primary"
          :disabled="$v.importedBureauCustomer.$invalid"
          @click="onUpdateClick()"
        >Save</button>
        <button class="btn btn-primary" @click="onBackButton()">Back To Import</button>

        <button
          v-if="importedBureauCustomer.deleted"
          class="btn btn-danger float-right"
          @click="onRestoreClick()"
        >Restore Bureau Customer to Import</button>
        <button
          v-else
          class="btn btn-danger float-right"
          @click="onRemoveClick()"
        >Remove Bureau Customer from Import</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import swal from 'sweetalert2'
import { required, requiredIf, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
import Utility from '@/Assets/Mixins/Utility'
import { TheMask } from 'vue-the-mask'
import colours from '@/Assets/Constants/colours'

const sunValidator = helpers.regex('alpha', /^\d{6}$/)
const touchMap = new WeakMap()

function bankRequireCheck () {
  var bankref = !!this.importedBureauCustomer.bankReference
  var sortcode = !!this.importedBureauCustomer.sortCode
  var accnum = !!this.importedBureauCustomer.accountNumber

  if ((bankref && sortcode && accnum) || (!bankref && !sortcode && !accnum)) {
    return false
  }
  if (bankref || sortcode || accnum) {
    return true
  } else {
    return true
  }
}

export default {
  props: ['item'],
  mixins: [Utility],
  data () {
    return {
      sortCodeValid: false,
      accountNumberValid: false,
      sortCodeErrorMessage: '',
      accountNumberErrorMessage: '',
      addressFound: true,
      addressOptions: [],
      bankToggle: false,
      contactToggle: false,
      paymentToggle: false,
      importSchemaName: 'No mapping selected',
      BureauCustomerId: '',
      ImportSessionId: '',
      importedBureauCustomer: {},
      editedBureauCustomer: {}, // Any edits from the front page
      importedCustomer: false,
      fieldLabels: {},
      addImportCustomer: false,
      status: 'Edit'
    }
  },

  watch: {
    sortCode: function (oldVal, newVal) {
    }
  },
  async created () {
    var parameterBureauCustomerId = this.$route.params.id

    if (parameterBureauCustomerId === '00000000-0000-0000-0000-000000000000') {
      this.newCustomer()
    } else {
      this.BureauCustomerId = parameterBureauCustomerId
      this.GetImportedBureauCustomer()
    }
    this.schemas = this.loadSchemas()
    this.mappings = await this.loadMappings('')
    this.$v.$reset()
  },

  methods: {
    onBackButton: async function () {
      if (this.$v.$anyDirty) {
        try {
          var result = await swal.fire({
            title: 'Exit without saving Bureau Customer in Import',
            text: 'Do you want to save this bureau customer\'s details in the import before leaving?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          })
          if (result) {
            await this.doSave()
            this.$router.push({ name: 'ImportBureauCustomerVerify', params: { id: this.ImportSessionId } })
          }
        } catch {
          this.$router.push({ name: 'ImportBureauCustomerVerify', params: { id: this.ImportSessionId } })
        }
      } else {
        this.$router.push({ name: 'ImportBureauCustomerVerify', params: { id: this.ImportSessionId } })
      }
    },

    toggleElement: function (element) {
      element = !element
    },
    buttonToggleText: function (toggleState) {
      if (toggleState) {
        return '-'
      } else {
        return '+'
      }
    },
    resetData () {
      this.importSchemaName = 'No mapping selected'
      this.importedBureauCustomerInternalId = 0
      this.importedBureauCustomer = {}
      this.importedCustomer = false
      this.fieldLabels = {}
      this.addImportCustomer = false
    },
    addBureauCustomer: function () {
      this.resetData()
      // this.importedBureauCustomer = this.$store.dispatch('currentimportedBureauCustomer')
      this.addImportCustomer = true
      this.importedBureauCustomer = this.$store.getters.currentimportedBureauCustomer
      // this.createItems()
      this.importedCustomer = true
      this.$v.$reset()
    },
    loadCustomer: function () {
      this.resetData()
      this.addImportCustomer = false
      this.importedBureauCustomerInternalId = this.$route.params.id
      this.reloadItems()
      this.$v.$reset()
    },
    delayTouch: function ($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 4000))
    },

    onUpdateClick: async function () {
      try {
        var result = await swal.fire({
          title: 'Update Bureau Customer in Import',
          text: 'Are you sure you want to update this bureau customer\'s details in the import?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
        if (result) {
          await this.doSave()
        }
      } catch {
        // consume... dang swal is shonky!
      }
    },

    doSave: async function () {
      var params = {
        importSessionId: this.ImportSessionId,
        bureauCustomerId: this.BureauCustomerId,
        importedBureauCustomer: this.importedBureauCustomer
      }

      try {
        var response = await axios.put(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/updateImportedBureauCustomer', params, { showload: true })
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
          this.$v.$reset()
        } else { this.$toastr.e(response.data.toastMessage) }
      } catch (error) {
        this.$toastr.e(error)
      }
    },

    onRestoreClick: async function () {
      var result = await swal.fire({
        title: 'Restore Bureau Customer into Import',
        text: 'Are you sure you want to restore this bureau customer back into the import?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result) {
        await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/restoreBureauCustomerToImport',
          { importSessionId: this.ImportSessionId, bureauCustomerId: this.BureauCustomerId }, { showload: true })
          .then(response => {
            if (response.data.toastType === 2) {
              this.$toastr.s(response.data.toastMessage)
            } else {
              this.$toastr.e(response.data.toastMessage)
            }
            this.$router.push({ name: 'ImportBureauCustomerVerify', params: { id: this.ImportSessionId } })
          })
      }
    },

    onRemoveClick: async function () {
      var result = await swal.fire({
        title: 'Remove this Bureau Customer',
        text: 'Are you sure you want to remove this bureau customer from the import?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result) {
        await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/removeBureauCustomerFromImport',
          { importSessionId: this.ImportSessionId, bureauCustomerId: this.BureauCustomerId }, { showload: true })
          .then(response => {
            if (response.data.toastType === 2) {
              this.$toastr.s(response.data.toastMessage)
            } else {
              this.$toastr.e(response.data.toastMessage)
            }
            this.$router.push({ name: 'ImportBureauCustomerVerify', params: { id: this.ImportSessionId } })
          })
      }
    },
    createItems: async function () {
      // this.importedBureauCustomer = this.$store.getters.currentimportedBureauCustomer
      this.importedCustomer = true
      // this.changeItem(this.importedBureauCustomer.defaultImportSchema)
      // await this.$store.dispatch('getFieldLabels')
      //   .then(response => {
      //     this.fieldLabels = this.$store.getters.fieldLabels
      //     this.$v.$reset()
      //   })
    },
    GetImportedBureauCustomer: async function () {
      try {
        const response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/getimportedBureauCustomer/' + this.BureauCustomerId, { showload: true })
        this.importedCustomer = true
        this.importedBureauCustomer = response.data
        this.ImportSessionId = response.data.importSessionId
      } catch (error) {
        this.$toastr.e(error)
      } finally {
        this.$nextTick(() => {
          this.$v.$reset()
        })
        this.loading = false
      }
    },
    updateimportedBureauCustomer: async function () {
      await this.$store.dispatch('updateimportedBureauCustomer', this.importedBureauCustomer)
        .then(response => {
          this.importedBureauCustomer = this.$store.getters.currentimportedBureauCustomer
          this.importedCustomer = true
          // this.changeItem(this.importedBureauCustomer.defaultImportSchema)
        })
    },
    createNewimportedBureauCustomer: async function () {
      this.importedBureauCustomer.PaygateId = this.$store.state.common.paygateId
      await this.$store.dispatch('createNewimportedBureauCustomer', this.importedBureauCustomer)
        .then(response => {
          this.$router.push({ name: 'ListimportedBureauCustomers' })
        })
    },

    validate: _.debounce(async function () {
      const data = { sortCode: this.bureauCustomer.sortCode }

      if (this.bureauCustomer.accountNumber.length === 8) {
        data.accountNumber = this.bureauCustomer.accountNumber
      }

      try {
        const validateUrl = process.env.VUE_APP_VALIDATE_API_URL + 'BankAccount'
        const response = await axios.get(validateUrl, { params: data }, { showload: true })
        this.validationResult = response.data
        this.sortCodeErrorMessage = ''
        this.accountNumberErrorMessage = ''
        if (this.validationResult.valid) {
          console.info('Sort code and account number are valid')
          this.sortCodeValid = true
          this.accountNumberValid = true
        } else {
          if (this.validationResult.errorCode === '1502') {
            console.info('Sort code is valid')
            this.sortCodeValid = true
            this.accountNumberValid = false
            this.accountNumberErrorMessage = this.validationResult.errorText
          } else {
            console.info('Sort code and account number are invalid')
            this.sortCodeValid = false
            this.sortCodeErrorMessage = this.validationResult.errorText
            this.accountNumberValid = false
            this.accountNumberErrorMessage = this.validationResult.errorText
          }
        }

        if (this.validationResult.valid || this.validationResult.errorCode === '1502') {
          this.bureauCustomer.bankName = this.validationResult.institutionName
          this.bureauCustomer.bankAddressLine1 = this.validationResult.branchTitle
          this.bureauCustomer.bankAddressLine2 = this.validationResult.address1
          this.bureauCustomer.bankAddressLine3 = this.validationResult.address2
          this.bureauCustomer.bankAddressLine4 = this.validationResult.address3
          this.bureauCustomer.bankAddressLine5 = this.validationResult.address4
          this.bureauCustomer.bankAddressTown = this.validationResult.town + this.validationResult.city
          this.bureauCustomer.bankAddressCounty = this.validationResult.county
          this.bureauCustomer.bankAddressPostcode = this.validationResult.postcode
        }
      } catch (e) {
        console.log(e)
      }
    }, 800)
  },
  components: {
    TheMask
  },
  validations: {
    importedBureauCustomer: {
      detailsValidation: ['importedBureauCustomer.name', 'importedBureauCustomer.serviceUserNumber'],
      bankDetailsValidation: ['importedBureauCustomer.bankReference', 'importedBureauCustomer.accountNumber', 'importedBureauCustomer.sortCode'],
      name: { required },
      serviceUserNumber: { minLength: 6, sunValidator, required },
      sortCode: {
        minLength: minLength(6), maxLength: maxLength(6), required: requiredIf(bankRequireCheck)
      },
      accountNumber: {
        minLength: minLength(8), maxLength: maxLength(8), required: requiredIf(bankRequireCheck)
      },
      bankReference: { required: requiredIf(bankRequireCheck) },
      contactFirstName: {},
      contactSurname: {},
      defaultImportFilename: {},
      defaultImportSchema: {},
      defaultImportMapping: {},
      contraNarrative: {},
      tags: {},
      bankAddressTown: {},
      bankAddressCounty: {},
      address1: {},
      address2: {},
      address3: {},
      address4: {},
      address5: {},
      telephone: {},
      addressLocality: {},
      addressTown: {},
      addressCounty: {},
      addressPostcode: {},
      fax: {},
      websiteUrl: {},
      emailAddress: {},
      paymentLimit: {},
      submissionLimit: {},
      bankName: {},
      bankAddress1: {},
      bankAddress2: {},
      bankAddress3: {},
      bankAddress4: {},
      bankAddress5: {},
      bankPostcode: {}
    }
  }
}
</script>
